import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueAnalytics from 'vue-analytics'
import VueGtm from '@gtm-support/vue2-gtm'
import i18n from '@/lang'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import App from '@/app/App.saverium_pl.vue'
import store from '@/store/saverium_pl'
import router from '@/router/saverium_pl'
import { routes } from '@/router/saverium_pl/routes'
import SaveriumAPI from '@/saverium/SaveriumApiClass'
import settings from '@/settings/settings.js'

import 'animate.css'
const oidcSettings = require('../settings/settings').oidcSettings

Vue.use(BootstrapVue)
Vue.use(SaveriumAPI, { config: settings, oidcConfig: oidcSettings })
if (settings.SENTRY_DSN !== '') {
  Sentry.init({
    Vue,
    environment: settings.SENTRY_ENV,
    dsn: settings.SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['saverium.pl', 'stage.saverium.pl', /^\//],
        attachProps: true,
      }),
    ],
  })
}
// Load tracking/logging stuff if ID:s are defined

if (settings.GOOGLE_ANALYTICS !== '') {
  Vue.use(VueAnalytics, {
    id: settings.GOOGLE_ANALYTICS,
    router,
  })
}

if (settings.GTM_ID !== '') {
  Vue.use(VueGtm, {
    id: settings.GTM_ID,
    vueRouter: router,
  })
}

const rn = routes[settings.LOCALE]
rn.paths.TosPDF = '/static/pdf/saverium_pl/1_Warunki Świadczenia Usług Saverium.pdf'
rn.paths.PrivacyPolicyPDF = '/static/pdf/saverium_pl/2_Zgody.pdf'
rn.paths.ConsentsPDF = '/static/pdf/saverium_pl/3_Zgody Marketingowe.pdf'
rn.paths.MarketingConsentsPDF =
  '/static/pdf/saverium_pl/4_Polityka Prywatności Serwisu.pdf'
rn.paths.PEPPDF = '/static/pdf/saverium_pl/PEP.pdf'
rn.paths.ZPFPDF = '/static/pdf/saverium_pl/ZPF_zasady_dobrych_praktyk_09.2020.pdf'
rn.paths.ZPFZDP = '/static/pdf/saverium_pl/certyfikat-audytu-etycznego-zpf.pdf'

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  data: {
    rn: rn.paths,
    brand: settings.BRAND,
  },
  router,
  store,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
})
