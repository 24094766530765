import { render, staticRenderFns } from "./CompanyLoanCard.vue?vue&type=template&id=72f3378e&scoped=true&"
import script from "./CompanyLoanCard.vue?vue&type=script&lang=js&"
export * from "./CompanyLoanCard.vue?vue&type=script&lang=js&"
import style0 from "./CompanyLoanCard.vue?vue&type=style&index=0&id=72f3378e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72f3378e",
  null
  
)

export default component.exports