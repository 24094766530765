<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån penge til en hest</h1>
      <div class="top-text text-container">
        <p>
          Står du og mangler et lån til en hest, eller en hoppe? Vi ønsker at hjælpe dig
          med at få overblik over det danske lånemarked, så du ikke tager det første, men
          derimod det bedste tilbud du får. Udfyld en gratis ansøgning igennem os, så vil
          vi indhente forskellige tilbud fra flere anerkendte banker, så du kan vælge det
          bedste hestelån.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#information-omkring-et-heste-laan"
                    >Information omkring et heste lån</a
                  >
                </li>
                <li>
                  <a href="#find-dit-nye-laan-til-hest-online"
                    >Find dit nye lån til hest online</a
                  >
                </li>
                <li>
                  <a href="#laan-til-den-hest-du-oensker-dig"
                    >Lån til den hest du ønsker dig</a
                  >
                </li>
                <li>
                  <a href="#sammenlign-for-finde-et-billigt-hestelaan"
                    >Sammenlign for finde et billigt hestelån</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="information-omkring-et-heste-laan">Information omkring et heste lån</h2>
          <p>
            Har du eller måske din datter fundet sin drømmehest, men står og mangler penge
            til at kunne købe den? Så behøver du ikke at lede længe, for at finde et
            <a
              href="https://www.saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtigt lån</a
            >, som du kan bruge til at <strong>finansiere </strong>købet af den ønskede
            hest. Uanset om du vil bruge lånet til at købe en shetlandspony eller købe en
            ny grime til din pony, så vil vi gøre vores bedste for at finde de
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >billigste lånetilbud</a
            >, der passer til din økonomi. Når du udfylder en gratis
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låneansøgning</a
            >
            igennem os på vores hjemmeside, sørger vi for at lave det hårde arbejde for
            dig, så du kun skal bruge kræfter på at sammenligne de tilbud, du modtager på
            din brugerkonto.
          </p>
        </div>

        <div class="text-container">
          <h2 id="find-dit-nye-laan-til-hest-online">Find dit nye lån til hest online</h2>
          <p>
            At skulle få overblik over hvilket lån og hvilken udbydere, der passer bedst
            til dig, kan være tidskrævende og være besværligt. I stedet for at kontakte
            hver enkelt
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbyder</a
            >
            der findes, vil vi anbefale at du undersøger dine
            <a
              href="https://www.saverium.dk/laan-penge/bolig/laanemuligheder"
              rel="noopener noreferrer"
              target="_blank"
              >lånemuligheder</a
            ><strong> </strong>ved at udfylde én enkelt ansøgning via vores hjemmeside. Så
            kontakter vi de udbydere, som vi samarbejder med, så vi kan finde dit
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >nye lån</a
            >
            til hest. Du skal kun tænke på, hvilken hest eller pony du ønsker at bruge
            lånet på, og om det kan være en nordbakke/fjordhest, en Shire eller en helt
            tredje. Hvilken hesterace eller hvilket hesteudstyr, som du vil bruge pengene
            på, afgør du helt selv. så længe du kan betale lånet tilbage, indenfor den
            aftalte løbetid på lånet, er banken tilfreds.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-den-hest-du-oensker-dig">Lån til den hest du ønsker dig</h2>
          <p>
            Når du vælger at ansøge om et
            <a
              href="https://www.saverium.dk/laan-penge/online-laan"
              rel="noopener noreferrer"
              target="_blank"
              >lån online</a
            >
            til en hest, er en af de store fordele, at selve udbyderen ikke behøver vide,
            hvilken hest eller hvad for noget udstyr, du vil bruge de lånte penge på. I
            Danmark findes der en masse populære hesteracer så som Dansk Varmblod, Jysk
            hest, Beliger, Holstener, Pinto samt flere. Det er bare eksempler på hvad du
            kan anvende dit lån på.
          </p>
          <p>
            Udover de mange forskellige racer af heste, skal der os en masse udstyr til,
            når man skal enten gå til ridning, eller hvis man har en hest hjemme i en
            fold. Ifølge
            <a
              href="https://www.hesteviden.dk/faa-styr-paa-dit-hestebudget/#:~:text=En%20god%20allround%20hest%20til,opstaldning%2C%20grovfoder%20og%20lign)."
              rel="noopener noreferrer"
              target="_blank"
              >Hestevide.dk</a
            >, koster det ca. 2.500 kr. om måneden at have en hest, hvilket skal dække
            over udgifter så som opstaldning, sadelmager, foder og kosttilskud. Derfor kan
            det være, at det ikke er nok at kun at låne til selve hesten, men måske også
            at låne til noget af det, der følger med til at eje en hest.
          </p>
          <p>
            Der er selvføligelig også forskel på, om du har din hest derhjemme på din
            gård, eller den befinder sig i en stald ved en anden person, hvor du skal
            betale for opstaldning. Hvis du har muligheden for at have din hest derhjemme,
            vil det spare dig nogle penge i opstaldning, samt at du skal ikke til
            transportere dig selv hen til hesten, for at passe på den.
          </p>
          <p>
            Hvis du får lagt dig et budget over, hvad der skal købes for at have en hest,
            vil du nemmere kunne finde ud af hvilket
            <a
              href="https://www.saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              >lånebeløb</a
            >, du skal søge efter, når du ansøger om dét
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >, der skal bruges til din kommende hest.
          </p>
        </div>

        <div class="text-container">
          <h2 id="sammenlign-for-finde-et-billigt-hestelaan">
            Sammenlign for finde et billigt hestelån
          </h2>
          <p>
            For at være sikker på du finder det
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/uden-sikkerhed"
              rel="noopener noreferrer"
              target="_blank"
              >billigste lån uden sikkerhed</a
            >, som du kan bruge til at købe din hest, er det en god ide at sammenligne de
            forskellige muligheder, som du har. Siden der findes mange forskellige
            pengeinstitutter og banker, kan det være svært at finde ud af, hvor man skal
            starte. Vælger du at bruge vores gratis lånesammenligningstjeneste, sørger vi
            for finde skræddersyet tilbud, så du kan låne til din hest, på den billigste
            og nemmeste måde.
          </p>
          <p>
            Det eneste du skal gøre er at have adgang til internettet, og udfyld vores
            ansøgning med dine oplysninger. Efter du har udfyldt din ansøgning, som tager
            nogle få minutter, vil du allerede med det samme modtage tilbud, som du kan
            begynde at sammenligne. Det er nu op til dig at vælge det tilbud, der passer
            bedst til din situation og økonomi.
          </p>
          <p>
            Efter du har valgt et af de modtagende tilbud, videresender vi dig videre til
            selve udbyderens hjemmeside. Her skal du skal give samtykke med
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >NemID/MitID</a
            >, for de kan lave en kreditvurdering af din privatøkonomi. Bliver du godkendt
            af udbyderen til at kunne optage lånet, vil udbyderen samt os sende dig en
            bekræftelse på, at lånet bliver udbetalt til din konto hurtigst muligt.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
