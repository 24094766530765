<template>
  <header class="header-dk">
    <template v-if="showAlert">
      <b-alert class="alert-box" dismissible show variant="warning"
        >{{ $t('stop_using_ie_and_update_browser') }}
      </b-alert>
    </template>
    <b-navbar toggleable="lg" type="dark" variant="saverium-dark">
      <b-navbar-brand :to="$root.rn.MainView">
        <img
          height="100"
          width="250"
          src="/static/img/saverium_dk/Saverium_white_rgb.png"
          alt="Saverium.dk Logo"
          class="main-logo"
      /></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" class="dk-toggler">
        <div class="nav-toggle">
          <i class="far fa-bars"></i>
          <p>menu</p>
        </div></b-navbar-toggle
      >
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto nav-links">
          <b-nav-item :to="$root.rn.FaqView"> {{ $t('faq') }}</b-nav-item>
          <b-nav-item :to="$root.rn.MyAccountView"> {{ $t('my_account') }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
import * as Cookies from 'tiny-cookie'

export default {
  created() {
    function isItIE() {
      const user_agent = navigator.userAgent
      const is_it_ie =
        user_agent.indexOf('MSIE ') > -1 || user_agent.indexOf('Trident/') > -1
      return is_it_ie
    }
    if (isItIE()) {
      this.showAlert = true
    } else {
      this.showAlert = false
    }
  },
  mounted() {
    const consent = Cookies.get('accept_cookies')
    if (consent) {
      fbq('track', 'PageView')
    }
  },
  data() {
    return {
      showAlert: false,
    }
  },
}
</script>
<style lang="scss" scoped>
.header-dropdown {
  .dropdown-link {
    text-align: end;
  }
}
.dropdown-menu:active,
.dropdown-menu:focus {
  border: none;
}
.nav-toggle {
  color: white;
  p {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
}
</style>
