<template>
  <main>
    <section id="ingress">
      <div class="container">
        <div class="content">
          <p v-html="$t('you_have_unsubscribed')"></p>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  mounted() {
    if (this.$route.params.draft_uuid) {
      this.saverium.cancelDraft(this.$route.params.draft_uuid)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
