import Vue from 'vue'
import router from '@/router'
import settings from '@/settings/settings'
import { exception } from 'vue-analytics'

// Common authentication store for all saverium brands

const RN = router.routes
const brand = settings.BRAND

// Initial state
const state = {
  token: null,
  sessionKey: null,
  loginError: false,
  username: '',
  password: '',
  loginLoading: false,
  loggedIn: false,
  cookieConsent: false,
  refreshToken: null,
  versionId: null,
}

const getters = {
  loggedIn: state => state.loggedIn,
}

const actions = {
  async login({ commit, state, dispatch }, payload) {
    commit('setLoginLoading', true)
    try {
      const user = await Vue.saverium.login(payload)
      commit('setLoggedIn', true)
      commit('setLoginLoading', false)
      commit('setLoginError', false)
      return true
    } catch (e) {
      console.log('Login error:', e)
      commit('setLoginError', true)
      commit('setLoginLoading', false)
      return false
    }
  },
  async isLoggedIn({ commit }) {
    const user = await Vue.saverium.oidcUserManager.getUser()
    if (user) {
      commit('setLoggedIn', true)
    } else {
      commit('setLoggedIn', false)
    }
  },
  async login_from_magic_link({ commit, dispatch }, payload) {
    commit('setLoginLoading', true)
    try {
      const user = await Vue.saverium.login_from_magic_link(payload)
      if (!user) {
        throw exception('Invalid payload')
      }
      commit('setLoggedIn', true)
      commit('setLoginLoading', false)
      commit('setLoginError', false)
      return true
    } catch (e) {
      console.log('Login error:', e)
      commit('setLoginError', true)
      commit('setLoginLoading', false)
      return false
    }
  },
  async checkCookieConsent({ commit }) {
    const cookieConsent = await Vue.saverium.checkCookieConsent()
    commit('setCookieConsent', cookieConsent)
  },
  async acceptCookies({ commit }, value) {
    const cookieConsent = await Vue.saverium.acceptCookies(value)
    commit('setCookieConsent', cookieConsent)
  },

  async logout({ commit, dispatch }) {
    commit('setLoggedIn', false)
    const user = await Vue.saverium.oidcUserManager.getUser()
    if (user) {
      await Vue.saverium.logout()
      router.replace(RN.paths.MainView)
    } else {
      router.replace(RN.paths.MainView)
    }
    router.replace(RN.paths.LogoutView)
  },

  async getSessionKey({ commit }) {
    const sessionKey = await Vue.saverium.getSessionKeyValue()
    commit('setSessionKey', sessionKey)
  },

  async setSessionKey({ state }) {
    let sessionKey
    if (state.sessionKey) {
      sessionKey = state.sessionKey
    } else {
      sessionKey = await Vue.saverium.getSessionKeyValue()
    }
    return sessionKey
  },

  async getVersionId({ state, commit }) {
    let versionId
    if (state.versionId) {
      versionId = state.versionId
    } else {
      versionId = await Vue.saverium.getVersionId()
    }
    commit('setVersionId', versionId)
  },

  setUsername({ commit }, username) {
    commit('updateCredentials', { key: 'username', value: username })
  },

  async resetPassword({ commit }, formData) {
    try {
      await Vue.saverium.resetPassword(formData)
      return true
    } catch (e) {
      return false
    }
  },

  async setPassword({ commit }, formData) {
    try {
      await Vue.saverium.setPassword(formData)
      return { success: true }
    } catch (e) {
      if (e.response && [404, 401, 400].includes(e.response.status)) {
        return e.response.data.errors
      } else {
        return { key: 'expired' }
      }
    }
  },
}

const mutations = {
  setCookieConsent(state, cookieConsent) {
    state.cookieConsent = cookieConsent
  },
  setSessionKey(state, key) {
    state.sessionKey = key
  },
  setToken(state, token) {
    state.token = token
  },
  setLoginError(state, error) {
    state.loginError = error
  },
  clearLoginError(state) {
    state.loginError = false
  },
  updateCredentials(state, { key, value }) {
    state[key] = value
  },
  setLoginLoading(state, value) {
    state.loginLoading = value
  },
  showLogin(state, value) {
    state.showLogin = value
  },
  setLoggedIn(state, value) {
    state.loggedIn = value
  },
  setVersionId(state, value) {
    state.versionId = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
