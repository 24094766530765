<template>
  <div class="main-container">
    <div class="main">
      <main-form-view />
    </div>
    <div class="main-text">
      <b-container class="main-container">
        <paragraph-header :value="$t('company_loan_without_guarantees')" />
        <p>{{ $t('company_loan_without_guarantees_text') }}</p>
        <div class="secondary-title">{{ $t('finnvera_granted_loans') }}</div>
        <p>{{ $t('finnvera_granted_loans_text_1') }}</p>
        <p>{{ $t('finnvera_granted_loans_text_2') }}</p>
        <p>{{ $t('finnvera_granted_loans_text_3') }}</p>
        <p>{{ $t('finnvera_granted_loans_text_4') }}</p>
        <div class="secondary-title">{{ $t('internal_financing_as_financing') }}</div>
        <p>{{ $t('internal_financing_as_financing_text_1') }}</p>
        <p>{{ $t('internal_financing_as_financing_text_2') }}</p>
        <div class="secondary-title">{{ $t('company_loan_with_consumer_credit') }}</div>
        <p>{{ $t('company_loan_with_consumer_credit_text_1') }}</p>
        <p>{{ $t('company_loan_with_consumer_credit_text_2') }}</p>
        <p>{{ $t('company_loan_with_consumer_credit_text_3') }}</p>
        <p>{{ $t('company_loan_with_consumer_credit_text_4') }}</p>
        <p>{{ $t('company_loan_with_consumer_credit_text_5') }}</p>
        <p>{{ $t('company_loan_with_consumer_credit_text_6') }}</p>
      </b-container>
    </div>
  </div>
</template>

<script>
import ParagraphHeader from '@/components/lainaayritykselle_fi/ParagraphHeader'
import MainFormView from '@/components/lainaayritykselle_fi/newForm/MainFormView'

export default {
  metaInfo: {
    meta: [
      {
        name: 'description',
        content:
          'Vakuudeton yrityslaina - Yrityslainaa ilman vakuuksia. Vertailemme ja kilpailutamme yli 10 rahoitusyhtiötä'
      },
      { name: 'keywords', content: 'vakuudeton yrityslaina, kilpailuttaminen' }
    ]
  },
  components: {
    ParagraphHeader,
    MainFormView
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  width: 100%;
  position: relative;
}

.secondary-title {
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 10px;
  font-weight: 500;
}
</style>
