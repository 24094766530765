<template>
  <div>
    <div class="article">
      <h1 class="center-title">Unilån lån 20.000 – 50.000 kr.</h1>
      <div class="top-text text-container">
        <ul>
          <li>Lån 20.000 til 50.000 kr.</li>
          <li>Hurtig udbetaling.</li>
          <li>Lån uden sikkerhed.</li>
          <li>Mulighed for at indfri lånet før tid</li>
        </ul>
        <p>
          Unilån ApS er grundlagt i 2019, som tidligere har gået under navnet Mobillån.dk
          ApS. Unilån tilbyder online
          <a
            href="https://www.saverium.dk/laan-penge/forbrugslaan"
            rel="noopener noreferrer"
            target="_blank"
            >forbrugslån</a
          >
          til folk som bor i Danmark mellem 20.000 kr. og 50.000 kr. Uanset om det er et
          nyt gulv til <strong>køkkenet</strong> eller en <strong>ny garage</strong>, så
          tjekker Unilån ikke efter hvad du skal bruge låne pengene til.
        </p>
        <p>
          Dermed bestemmer du selv, hvad du vil bruge dit
          <a
            href="https://saverium.dk/laan-penge/laaneudbydere"
            rel="noopener noreferrer"
            target="_blank"
            >nye lån</a
          >
          på, om det er en gammel <strong>brugt bil</strong> eller du vil ud at
          <strong>rejse</strong>. Fordi Unilån ikke ikke tjekker hvad pengene skal bruges
          til betyder det også, at lånet er uden sikkerhed.
        </p>
        <p>
          At optage et lån hos Unilån, vi give dig muligheden for at få lånet udbetalt
          hurtigt til din konto, allerede næste hverdag hvis du skulle blive godkendt
          efter de har lavet en kreditvurdering af dig.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#unilaans-rente-og-laanevilkaar"
                    >Unilåns rente og lånevilkår</a
                  >
                </li>
                <li>
                  <a href="#saadan-ansoeger-du-om-et-laan-hos-unilaan"
                    >Sådan ansøger du om et lån hos Unilån</a
                  >
                </li>
                <li><a href="#om-unilaan">Om Unilån</a></li>
                <li>
                  <a href="#vilkaar-for-laan-hos-unilaan">Vilkår for lån hos Unilån</a>
                </li>
                <li>
                  <a href="#laaneeksempel-paa-et-laan-fra-unilaan"
                    >Låneeksempel på et lån fra Unilån</a
                  >
                </li>
                <li><a href="#unilaans-kundeservice">Unilåns kundeservice</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="fellow-bank-rente-og-laanevilkaar">Fellow Bank rente og lånevilkår</h2>
          <p><strong>Lånebeløb: </strong>4.000 – 100.000 kr</p>
          <p><strong>Låneperiode: </strong>1 og et halvt år til 10 år</p>
          <p><strong>Variabel rente: </strong>Fra 2,95%, afdrag fra 150 kroner</p>
          <p><strong>Behandlingstid: </strong>Indenfor 24 timer på hverdage</p>
          <p><strong>Flexible lånevilkår: </strong>Ja</p>
          <p></p>
        </div>

        <div class="text-container">
          <h2 id="unilaans-rente-og-laanevilkaar">Unilåns rente og lånevilkår</h2>
          <p><strong>Lånebeløb: </strong>20.000 – 50.000 kr.</p>
          <p><strong>Låneperiode: </strong>2 til 8 år</p>
          <p><strong>Fast debitorrente: </strong>16,20%</p>
          <p>
            <strong>Andre udgifter: </strong>Mdl. adviseringsgebyr 33 kr. Etableringsgebyr
            8%
          </p>
          <p>
            <strong>Behandlingstid </strong>Svar indenfor 15 minutter og pengene på din
            konto næste hverdag
          </p>
          <p><strong>Medansøger: </strong>Nej</p>
        </div>

        <div class="text-container">
          <h2 id="saadan-ansoeger-du-om-et-laan-hos-unilaan">
            Sådan ansøger du om et lån hos Unilån
          </h2>
          <p>
            Udfyld din låneansøgning på få minutter, og tjek de lånetilbud du skulle
            modtage på din bruger konto.
          </p>
          <ol>
            <li>
              <strong
                >Udfyld låneansøgningen med det ønskede lånebeløb og løbetid.</strong
              >
              Vælg
              <a
                href="https://www.saverium.dk/laan-penge/beloeb"
                rel="noopener noreferrer"
                target="_blank"
                >lånebeløb</a
              >
              og den låneperiode du ønsker for dit kommende lån.
            </li>
            <li>
              <strong>Angiv dine andre oplysninger.</strong> Udfyld din låneansøgning med
              de andre nødvendige oplysninger.
            </li>
            <li>
              <strong>Send ansøgning online</strong>. Send den udfyldte låneansøgning. Vi
              indhenter derefter de skræddersyet
              <a
                href="https://www.saverium.dk/laan-penge/sammenligning"
                rel="noopener noreferrer"
                target="_blank"
                >lånetilbud</a
              >
              til dig, og du får svar meget hurtigt på din låneansøgning.
            </li>
            <li>
              <strong>Accepter det ønskede lånetilbud</strong>. Sammenlign dine tilbud og
              accepter det ønskede lånetilbud der passer bedst til dig og din økonomi.
            </li>
            <li>
              <strong>Lånet vil blive overført din konto</strong>. Det ønskede tilbud du
              har valgt, vil blive overført til din konto indenfor næste hverdag, hvis du
              vælger et online lån hos Unilån.
            </li>
          </ol>
        </div>

        <div class="text-container">
          <h2 id="om-unilaan">Om Unilån</h2>
          <p>
            Unilån er en dansk
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbyder</a
            >, som blev stiftet i 2019. Deres mål er at tilbyde private et online
            låneprodukt, som er enkelt, nemt og hurtigt, så alle kan være med. De går
            meget op i at besvare din ansøgning hurtigst muligt, for at gøre det nemt for
            dig som låntager. På grund af deres hurtige service, vil det betyde at dit nye
            lån vil blive overført straks til din konto, allerede hverdagen efter hvis du
            skulle blive godkendt af Unilån til at optage et lån hos dem.
          </p>
          <p>
            Når du laver en låneansøgning igennem os, kan du ansøge om
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >billige lån</a
            >
            fra mere end 10 forskellige banker og låneudbydere udover Unilån. Det koster
            ingenting, og du forpligter dig ikke til at acceptere nogle af de modtagende
            tilbud. Du kan indsende en gratis og uforpligtende
            <strong>låneansøgning</strong> online på få minutter. Se derefter om et af
            dine tilbud skulle leve op til dine krav, og dermed bestemme om du ønsker at
            gå videre med et af dine tilbud eller ej.
          </p>
          <p>
            Når du acceptere et lån fra Unilån, vil vi sende dig videre til deres
            hjemmeside, hvor du skal give samtykke via
            <a
              href="https://www.saverium.dk/laan-penge/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >NemID/MitID</a
            >
            for at Unilån må lave en kreditvurdering af dig. Efter det er sket får du så
            den endelig låneafgørelse hvor du får af vide om du bliver godkendt eller
            afvist. Skulle du blive godkendt, vil du få lånet fra Unilån blive udbetalt
            hurtigst muligt, allerede hverdagen efter du skulle blive godkendt til at
            optage et lån hos Unilån.
          </p>
        </div>

        <div class="text-container">
          <h2 id="vilkaar-for-laan-hos-unilaan">Vilkår for lån hos Unilån</h2>
          <p>
            For at kunne blive godkendt til et lån hos Unilån, skal du leve op til disse 4
            krav:
          </p>
          <ol>
            <li>Du skal have fast bopæl i Danmark</li>
            <li>Du skal være fyldt 18 år</li>
            <li>Du må du ikke være registreret i Debitor Registret eller RKI</li>
            <li>Du skal have et dansk CPR-nummer</li>
          </ol>
          <p>
            Udover det vil der blive foretaget en kreditvurdering af dig og din
            privatøkonomi, for at være sikker på, at du kan betale lånet tilbage til
            Unilån. Efter kreditvurdering er færdig vil du få tilbudt det endelig
            lånetilbud som du så kan acceptere hvis det ønskes, eller du vil blive afvist
            hvis du ikke lever op til et lås hos Unilån.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laaneeksempel-paa-et-laan-fra-unilaan">
            Låneeksempel på et lån fra Unilån
          </h2>
          <p>
            <strong>Låneeksempel</strong>: Samlet kreditbeløb: Ved et samlet kreditbeløb
            på 30.000 kr. Over 5 år, der tilbagebetales via Betalingservice. Månedlig
            ydelse: 806 kr. Debitorrente: 16,2% (variabel i lånets løbetid). ÅOP: 22,7 %.
            Samlede kreditomkostninger: 18.340 kr. Samlet tilbagebetaling: 48.340 kr.
            Stiftelsesomkostninger: 2.400 kr. Månedsgebyr: 33 kr. Fortrydelsesret: Du kan
            fortryde den indgåede kreditaftale indenfor 14 dage.
          </p>
        </div>

        <div class="text-container">
          <h2 id="unilaans-kundeservice">Unilåns kundeservice</h2>
          <p>
            Du kan kontakte Unilån ved at ringe til deres kundeservice alle hverdage fra
            kl. 9 – 12:30. Ring på tlf.: 88 44 25 26. Du kan os kontakte Unilån ved at
            skrive til dem på adm@unilaan.dk.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
