import MainView from '@/views/saverium_es/MainView'
import LoanView from '@/views/saverium_es/LoanView'
import OurPartnersView from '@/views/saverium_es/OurPartnersView'
import FaqView from '@/views/saverium_es/FaqView'
import LoginView from '@/views/LoginView'
import ResetPasswordView from '@/views/ResetPasswordView'
import SetPasswordView from '@/views/SetPasswordView'
import MyAccountView from '@/views/MyAccountView'
import LogoutView from '@/views/LogoutView'
import TokenLoginView from '@/views/TokenLoginView'
import CancelDraftView from '@/views/CancelDraftView'

import routeUtils from '@/router/routeUtils'

// Translations for paths, will be used as link urls
const localizedPaths = {
  MainView: '/',
  LoanView: '/solicitud',
  OurPartnersView: '/nuestros-companeros',
  FaqView: '/faq',
  LoginView: '/login',
  ResetPasswordView: '/reset_password',
  SetPasswordView: '/set_password/:pk/:key/:ts',
  MyAccountView: '/account',
  MyAccountTokenLoginView: '/account/:token',
  LogoutView: '/logout',
  CancelDraftView: '/cancel/:draft_uuid',
}

/**
 * This object defains what site spesific routes you have and translations for those.
 * All routes must also have corresponding translation
 */
let langRoutes = [
  {
    name: 'CancelDraftView',
    component: CancelDraftView,
  },
  {
    name: 'MainView',
    component: MainView,
    meta: {
      isPublic: true,
      title: 'Saverium.es 100 - 60.000€ - Un préstamo al consumo',
    },
  },
  {
    name: 'LoanView',
    component: LoanView,
    meta: {
      isPublic: true,
      title: 'Solicitud - Saverium.es',
    },
  },
  {
    name: 'OurPartnersView',
    component: OurPartnersView,
    meta: {
      title: 'Nuestros compañeros - Saverium.es',
      isPublic: true,
    },
  },
  {
    name: 'FaqView',
    component: FaqView,
    meta: {
      title: 'Preguntas frecuentes - Saverium.es',
      isPublic: true,
    },
  },
  {
    name: 'LoginView',
    component: LoginView,
    meta: {
      title: 'Iniciar sesión - Saverium.es',
      isPublic: true,
    },
  },
  {
    name: 'ResetPasswordView',
    component: ResetPasswordView,
    meta: {
      title: 'Restablecer la contraseña - Saverium.es',
      isPublic: true,
    },
  },
  {
    name: 'SetPasswordView',
    component: SetPasswordView,
    meta: {
      title: 'Configurar la clave - Saverium.es',
      isPublic: true,
    },
  },
  {
    name: 'MyAccountView',
    component: MyAccountView,
    meta: {
      title: 'Mi cuenta - Saverium.es',
      requireLogin: true,
    },
  },
  {
    name: 'MyAccountTokenLoginView',
    component: TokenLoginView,
    meta: {
      title: 'Mi cuenta - Saverium.es',
      isPublic: true,
    },
  },
  {
    name: 'LogoutView',
    component: LogoutView,
    meta: {
      title: 'Saverium.es',
      isPublic: true,
    },
  },
]

let rawRoutes = routeUtils.createRawRoutes(langRoutes, localizedPaths)

export default rawRoutes
