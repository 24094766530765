<template lang="html">
  <div class="mb-5" ref="header">
    <h2 v-if="fullView" class="mb-3 text-center">
      {{ step }}.
      {{ $t('address_and_contact_information') }}
    </h2>
    <h1 v-else class="mb-3 text-center">
      {{ $t('address_and_contact_information') }}
    </h1>

    <div class="row align-items-end">
      <div class="form-group col-sm-6">
        <form-input type="dropdown" :items="regionOptions" storekey="region" id="region">
          {{ $t('region') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input type="text" storekey="street" id="street">
          {{ $t('street') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6">
        <form-input type="text" storekey="houseNumber" id="housenumber">
          {{ $t('house_number') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6">
        <form-input type="text" storekey="apartmentNumber" id="apartmentnumber">
          {{ $t('apartment_number') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6">
        <form-input
          type="postal-code"
          storekey="zipCode"
          :placeholder="'np. 00-000'"
          id="zipcode"
          :maxlength="6"
        >
          {{ $t('zip_code') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6">
        <form-input type="text" storekey="city" id="city">
          {{ $t('city') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6">
        <form-input
          :items="dwellingOptions"
          storekey="dwelling"
          type="dropdown"
          id="dwelling"
        >
          {{ $t('housing_situation') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6">
        <form-input
          :items="dwellingTypeOptions"
          storekey="dwellingType"
          type="dropdown"
          id="dwellingType"
        >
          {{ $t('property_type') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6">
        <form-input
          type="date"
          storekey="dwellingDate"
          id="dwellingdate"
          placeholder="dd.mm.rrrr"
        >
          {{ $t('living_at_current_address_since') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6">
        <form-input
          storekey="numberOfPeople"
          type="dropdown"
          :items="numberOfPeope"
          id="numberOfPeople"
        >
          {{ $t('number_of_people_in_the_household') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input type="checkbox" storekey="hasSummerHouse" id="hassummerhouse">
          {{ $t('i_have_a_summer_house') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input
          type="checkbox"
          storekey="hasRegisteredAddress"
          id="hasRegisteredAddress"
        >
          {{ $t('i_am_registered_to_a_different_address') }}
        </form-input>
      </div>

      <template v-if="this.hasRegisteredAddress == true">
        <div class="form-group col-sm-6">
          <form-input type="dropdown" :items="regionOptions" storekey="registeredRegion">
            {{ $t('region') }}
          </form-input>
        </div>
        <div class="form-group col-sm-6">
          <form-input type="text" storekey="registeredStreet" id="registeredStreet">
            {{ $t('street') }}
          </form-input>
        </div>
        <div class="form-group col-sm-6">
          <form-input
            type="text"
            storekey="registeredHouseNumber"
            id="registeredHouseNumber"
          >
            {{ $t('house_number') }}
          </form-input>
        </div>
        <div class="form-group col-sm-6">
          <form-input
            type="text"
            storekey="registeredApartmentNumber"
            id="registeredApartmentNumber"
          >
            {{ $t('apartment_number') }}
          </form-input>
        </div>

        <div class="form-group col-sm-6">
          <form-input
            type="regex"
            regex="^\d{2}-\d{3}$"
            storekey="registeredZipCode"
            :placeholder="'np. 00-000'"
            id="registeredZipCode"
          >
            {{ $t('zip_code') }}
          </form-input>
        </div>

        <div class="form-group col-sm-6">
          <form-input type="text" storekey="registeredCity" id="registeredCity">
            {{ $t('city') }}
          </form-input>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import FormInput from '@/components/form/FormInput'

export default {
  props: ['fullView', 'step'],
  components: {
    FormInput,
  },
  data() {
    return {
      requiredFields: [
        'region',
        'street',
        'houseNumber',
        'zipCode',
        'city',
        'dwelling',
        'dwellingDate',
      ],
    }
  },
  computed: {
    numberOfPeope() {
      const range = (start, stop, step) =>
        Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)
      const options = range(0, 19, 1)
      return options
    },
    dwellingTypeOptions() {
      return this.$store.state.enums.dwellingTypes
    },
    dwellingOptions() {
      return this.$store.state.enums.dwellingOptions
    },
    regionOptions() {
      return this.$store.state.enums.regionOptions
    },
    stepErrors() {
      return this.$store.state.application.stepErrors
    },
    hasRegisteredAddress: {
      get() {
        return this.$store.state.application.hasRegisteredAddress
      },
      set(hasRegisteredAddress) {
        this.$store = hasRegisteredAddress
      },
    },
  },
  watch: {
    stepErrors(newV) {
      if (this.fullView && newV[0] === parseInt(this.step, 10)) {
        this.$refs.header.scrollIntoView()
      }
    },
  },
  methods: {
    isValid(id) {
      return !Object.keys(this.$store.state.application.errors).includes(id)
    },
  },
}
</script>

<style lang="scss" scoped></style>
