export const loanAmounts = []
let i = 0
while (i < 5000) {
  for (i = 100; i <= 5000; i += 100) {
    loanAmounts.push(i)
  }
}

while (i >= 5000 && i <= 60000) {
  for (i = 5000; i <= 60000; i += 500) {
    loanAmounts.push(i)
  }
}

export const loanTimeOptions = [
  { value: '1', text: '1 mes' },
  { value: '2', text: '2 meses' },
  { value: '3', text: '3 meses' },
  { value: '4', text: '4 meses' },
  { value: '5', text: '5 meses' },
  { value: '6', text: '6 meses' },
  { value: '7', text: '7 meses' },
  { value: '8', text: '8 meses' },
  { value: '9', text: '9 meses' },
  { value: '10', text: '10 meses' },
  { value: '11', text: '11 meses' },
  { value: '12', text: '12 meses' },
  { value: '13', text: '13 meses' },
  { value: '14', text: '14 meses' },
  { value: '15', text: '15 meses' },
  { value: '16', text: '16 meses' },
  { value: '17', text: '17 meses' },
  { value: '18', text: '18 meses' },
  { value: '19', text: '19 meses' },
  { value: '20', text: '20 meses' },
  { value: '21', text: '21 meses' },
  { value: '22', text: '22 meses' },
  { value: '23', text: '23 meses' },
  { value: '24', text: '24 meses' },
  { value: '25', text: '25 meses' },
  { value: '26', text: '26 meses' },
  { value: '27', text: '27 meses' },
  { value: '28', text: '28 meses' },
  { value: '29', text: '29 meses' },
  { value: '30', text: '30 meses' },
  { value: '31', text: '31 meses' },
  { value: '32', text: '32 meses' },
  { value: '33', text: '33 meses' },
  { value: '34', text: '34 meses' },
  { value: '35', text: '35 meses' },
  { value: '36', text: '36 meses' },
  { value: '37', text: '37 meses' },
  { value: '38', text: '38 meses' },
  { value: '39', text: '39 meses' },
  { value: '40', text: '40 meses' },
  { value: '41', text: '41 meses' },
  { value: '42', text: '42 meses' },
  { value: '43', text: '43 meses' },
  { value: '44', text: '44 meses' },
  { value: '45', text: '45 meses' },
  { value: '46', text: '46 meses' },
  { value: '47', text: '47 meses' },
  { value: '48', text: '48 meses' },
  { value: '49', text: '49 meses' },
  { value: '50', text: '50 meses' },
  { value: '51', text: '51 meses' },
  { value: '52', text: '52 meses' },
  { value: '53', text: '53 meses' },
  { value: '54', text: '54 meses' },
  { value: '55', text: '55 meses' },
  { value: '56', text: '56 meses' },
  { value: '57', text: '57 meses' },
  { value: '58', text: '58 meses' },
  { value: '59', text: '59 meses' },
  { value: '60', text: '60 meses' },
  { value: '61', text: '61 meses' },
  { value: '62', text: '62 meses' },
  { value: '63', text: '63 meses' },
  { value: '64', text: '64 meses' },
  { value: '65', text: '65 meses' },
  { value: '66', text: '66 meses' },
  { value: '67', text: '67 meses' },
  { value: '68', text: '68 meses' },
  { value: '69', text: '69 meses' },
  { value: '70', text: '70 meses' },
  { value: '71', text: '71 meses' },
  { value: '72', text: '72 meses' },
  { value: '73', text: '73 meses' },
  { value: '74', text: '74 meses' },
  { value: '75', text: '75 meses' },
  { value: '76', text: '76 meses' },
  { value: '77', text: '77 meses' },
  { value: '78', text: '78 meses' },
  { value: '79', text: '79 meses' },
  { value: '80', text: '80 meses' },
  { value: '81', text: '81 meses' },
  { value: '82', text: '82 meses' },
  { value: '83', text: '83 meses' },
  { value: '84', text: '84 meses' },
  { value: '85', text: '85 meses' },
  { value: '86', text: '86 meses' },
  { value: '87', text: '87 meses' },
  { value: '88', text: '88 meses' },
  { value: '89', text: '89 meses' },
  { value: '90', text: '90 meses' },
  { value: '91', text: '91 meses' },
  { value: '92', text: '92 meses' },
  { value: '93', text: '93 meses' },
  { value: '94', text: '94 meses' },
  { value: '95', text: '95 meses' },
  { value: '96', text: '96 meses' },
]
