<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lånetilbud</h1>
      <div class="top-text text-container">
        <p>
          Udvalget af lånetilbud er nærmest uendeligt. Det er ensbetydende med, at du har
          utallige muligheder for at låne penge på nettet, uanset dit formål.
        </p>
        <p>
          I dag fås online lån i mange størrelsesordener, hvorfor du kan låne fra 100 kr.
          til 500.000 kr. Derfor kan de fleste behov opfyldes af et online lån, hvad enten
          formålet er at købe nye møbler, renovere boligen eller betale en uforudset
          regning.
        </p>
        <p>
          Vær dog opmærksom på, at der er stor forskel på de dyreste og
          <b-link :to="$root.rn.CheapLoan">billigste lån</b-link>, hvorfor det er
          essentielt at få lånetilbud fra flere banker, med henblik på at lave en grundig
          sammenligning af de modtagne lånetilbud. Kun på denne måde kan du sikre, at du
          får det billigste lån.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul v-for="(text, i) in contents" :key="i">
                <li>
                  <a :href="text.link">
                    {{ text.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">Indhent lånetilbud fra flere banker hos Saverium.dk</h2>
          <p>
            Det behøver ikke nødvendigvis at være besværligt og tidskrævende at få
            lånetilbud fra forskellige låneudbydere.
          </p>
          <p>
            Hos Saverium.dk skal du blot udfylde en enkelt låneansøgning, også får du
            indhentet lånetilbud fra flere af vores samarbejdspartnere. Blandt vores
            samarbejdspartnere finder du bl.a. LånLet, Basisbank, Express Bank, Resurs
            Bank, Fellow Finance m.fl.
          </p>
          <p>
            Når du indsender en låneansøgning via Saverium, får du øjeblikkeligt svar på,
            hvorvidt din ansøgning er godkendt eller ej. Derfor skal du ikke vente
            utålmodigt på svar, som det kan gøre sig gældende i de traditionelle
            pengeinstitutter.
          </p>
          <p>
            Desuden præsenteres du for alle de
            <b-link :to="$root.rn.CheapLoan">billige lån</b-link> på én side, hvorved du
            nemt kan sammenligne lån fra udbyderne. Det medfører selvfølgelig, at du får
            betydeligt lettere ved at udvælge det mest optimale lån, set med dine øjne.
          </p>
          <p>
            Derudover er det helt uforpligtende at bruge Saverium.dk til at indhente
            lånetilbud, hvorfor du ikke er forpligtet til at acceptere et af de modtagne
            tilbud. Det vidner naturligvis om, at ønsker du at
            <b-link :to="$root.rn.MainView">låne penge nu</b-link>, så er du havnet det
            helt rette sted.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">
            Hvorfor er det essentielt at indhente flere forskellige lånetilbud?
          </h2>
          <p>
            Står du overfor at skulle låne penge, vil du sandsynligvis gerne sikre, at du
            accepteret det bedste lånetilbud. Det er dog vanskeligt at garantere dette,
            hvis ikke du udfører en grundig sammenligning af banker og modtagne tilbud.
          </p>
          <p>
            For at kunne sammenligne banker og tilbud, kræver det dog, at du indhenter en
            håndfuld forskellige tilbud. Der er intet problematisk forbundet med at få
            flere tilbud på lette lån, da tilbuddene ikke er forpligtende. Du kan derfor
            indhente lige så mange tilbud, du har lyst til.
          </p>
          <p>
            Du kan finde
            <b-link :to="$root.rn.CheapLoan">mange billige lån online</b-link>. Derfor er
            det bestemt ikke umuligt at få et let lån, uden at rente- og gebyromkostninger
            er høje. Desværre er der også mange lån, der udbydes af onlinebaserede
            lånevirksomheder, der ikke kan betragtes som værende billige.
          </p>
          <h3>Store prisforskelle nødvendiggør sammenligning af lån</h3>
          <p>
            Årsagen til, at det er vigtigt at få flere lånetilbud, før du sætter din
            underskrift på en endegyldig låneaftale er, at du skal udføre en grundig lån
            sammenligning.
          </p>
          <p>
            En sammenligning udføres naturligvis med henblik på at undgå de dyreste
            aktører på markedet. Når du udfører din sammenligning er det dog vigtigt, at
            du ved, hvad du skal sammenligne ud fra – og om de modtagne tilbud overhovedet
            kan sammenlignes.
          </p>
          <p>
            Som udgangspunkt kan alle lånetilbud sammenlignes, men ikke alle tilbud kan
            holdes direkte op mod hinanden. Er der eksempelvis forskel på løbetiden på de
            to lånetilbud, kan de ikke sammenlignes direkte med hinanden.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">Sammenlign lånetilbud med afsæt i ÅOP</h2>
          <p>
            Når du skal sammenligne lånetilbud, så sammenlign altid med afsæt i ÅOP. Mange
            begår den fejltagelse, at de tror, at renten er lånets eneste omkostning.
            Sådan forholder det sig dog ikke.
          </p>
          <p>
            Derfor er det ikke tilstrækkeligt at sammenligne lånetilbud med afsæt i
            renten. Der er nemlig adskillige andre låneomkostninger, der også skal
            inddrages i sammenligningen. Heldigvis behøver man dog ikke sammenligne hver
            enkelt omkostning enkeltvis.
          </p>
          <p>
            I stedet skal sammenligningen laves med afsæt i ÅOP, som er en forkortelse for
            Årlige Omkostninger i Procent. Det er et tal, der samlede lånets totale
            omkostninger på årlig basis og fremviser det i form af et procenttal.
          </p>
          <h3>Sammenlign lån med en samme lånebeløb og løbetid</h3>
          <p>
            Når du skal sammenligne priser på lån med afsæt i ÅOP, er det essentielt, at
            de lånetilbud, der sammenlignes, har ens lånebeløb og løbetid. Er det ikke
            tilfældet, er det ikke muligt at sammenligne dem en til en.
          </p>
          <p>
            Årsagen til dette er, at begge parametre har direkte indvirkning på ÅOP'en.
            Hvis løbetiden forlænges, deles omkostningerne også ud over en længere
            periode. Det vil medføre, at lånets ÅOP kan virke mindre, selvom de totale
            omkostninger er højere.
          </p>
          <p>
            Lånebeløbet har selvsagt også indflydelse på ÅOP, da et større lånebeløb vil
            resultere i højere omkostninger – blandt andet fordi en rente på lån beregnes
            med udgangspunkt i lånebeløbet. Derfor vil en rente på 7 % koste dig dyrere på
            et lån på <b-link @click="setLoan(10000)">10.000 kr.</b-link>, end på
            <b-link @click="setLoan(3000)">3.000 kr.</b-link>
          </p>
          <p>
            Når du indhenter dine tilbud, er det derfor vigtigt at specificere samme
            lånebeløb og løbetid over for låneudbyderne. Selvom du husker at gøre dette,
            skal du fortsat læse lånetilbuddene nøje, for at sikre, at der faktisk er tale
            om et tilbud med ønsket løbetid og lånebeløb.
          </p>
          <h3>Kig også efter den totale tilbagebetaling</h3>
          <p>
            Der findes rigtig mange
            <b-link :to="$root.rn.LoansWithLowRate">billige lån med lav rente</b-link>,
            der virker attraktive. I hvert fald indtil man ser på den totale
            tilbagebetaling. Netop derfor er det essentielt, at du gør dette, da det giver
            dig et bedre billede af, hvorvidt et lån er dyrt eller billigt.
          </p>
          <p>
            Desværre er der mange, der blot har fokus på at
            <b-link :to="$root.rn.LoanImmediately">få penge nu</b-link>, hvorfor de
            udelukkende holder øje med renten eller ÅOP. I nogle tilfælde kan sidstnævnte
            være tilstrækkeligt, men det er sjældent tilfældet. Derfor rådes du til altid
            at holde øje med de totale omkostninger.
          </p>
          <p>
            Desuden kan det også være en god måde at sammenligne lånetilbud med
            forskellige løbetider, hvilket ikke er en mulighed, hvis du udelukkende
            sammenligner med afsæt i ÅOP.
          </p>
          <p>
            Hvad det også vil sige er, når du skal finde et ideelt lån, så ansøg om lån
            med omhu, og først efter du har skabt et overblik over de samlede
            omkostninger.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">Prisen er ikke det eneste afgørende aspekt af et lånetilbud</h2>
          <p>
            Når du skal finde et ideelt og appellerende finans lån for dit vedkommende, er
            det vigtigt at understrege, at det bedste lånetilbud ikke udelukkende afhænger
            af det finansielle aspekt.
          </p>
          <p>
            Det bedste lånetilbud er det, der passer bedst til dig. Derfor kommer det også
            meget an på din individuelle situation, hvilket lån, der egner sig bedst til
            dig.
          </p>
          <p>
            Det kan være, at du ønsker at få et lånetilbud til bolig uden om banken, men
            det kan også være, at du prioriterer et
            <b-link :to="$root.rn.ConsumerLoans">forbrugslån</b-link> med en hurtig
            udbetaling. For andre handler det måske om at kunne få et lån med medansøger,
            der resulterer i lavere låneomkostninger.
          </p>
          <p>
            Det bedste lån er derfor ikke blot et lån med en lave låne rente. Der er flere
            andre faktorer, der også kan være vigtige for dig. Blandt andet er
            nedenstående parametre værd at overveje:
          </p>
          <ul>
            <li>Mulighed for pause eller afdragsfrie måneder</li>
            <li>Mulighed for at forhøje det oprindelige lån</li>
            <li>Mulighed for tidlig indfrielse uden omkostninger hertil</li>
            <li>Mulighed for låneforsikring</li>
            <li>Mulighed for at tilføje en medansøger</li>
          </ul>
          <h3>Hvad kan jeg låne til?</h3>
          <p>
            Fordelene ved at optage et lån på nettet er mange. En af de mest fremtrædende
            fordele er, at lånevirksomhederne ingen interesse har i, hvad pengene bruges
            til.
          </p>
          <p>
            Derfor vil der ikke være nogen, der snager i, hvordan de lånte penge
            spenderes. Af denne årsag har du friheden til at bruge dem til alt fra
            shoppeture til betaling af uforudsete regninger.
          </p>
          <p>
            I denne sammenhæng bør det også nævnes, at flere lånevirksomheder tillader
            <b-link @click="setLoan(500000)">lån op til 500.000 kr.</b-link>, hvorfor du
            kan låne penge til de fleste formål på nettet. Pas dog på, at du ikke lader
            dig rive med, når du indhenter lånetilbud – du bør nemlig ikke låne mere, end
            du har brug for.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">Hvad har indflydelse på mine lånetilbud?</h2>
          <p>
            Der er mange faktorer, der har indflydelse på dine lånetilbud. Netop derfor
            har lånevirksomhederne ikke faste listepriser, men beregner derimod altid
            personlige og individuelle lånetilbud ud fra en kreditvurdering.
          </p>
          <p>
            Det er også ensbetydende med, at du ikke undgår en kreditvurdering, når du
            <b-link :to="$root.rn.LoanView">ansøger om et lån</b-link>. Havde du gjort dig
            forhåbninger om dette, bliver du skuffet. Alt fra traditionelle
            pengeinstitutter til moderne lånevirksomheder foretager nemlig kreditvurdering
            af deres låntagere.
          </p>
          <p>
            Når en långiver foretager en kreditvurdering, er der flere faktorer, der
            bliver inddraget. Det er ganske vist forskelligt fra udbyder til udbyder, men
            nedenstående faktorer er altid inkluderet i kreditvurderingen.
          </p>
          <h3>1. Indkomst</h3>
          <p>
            Din indkomst er en af de mest afgørende aspekter af din kreditvurdering. Hvis
            ikke din indkomst er tilstrækkeligt højt til, at den kan garantere långiveren,
            at du har råd til den samlede tilbagebetaling, får du naturligvis ikke
            bevilliget et lån eller tilsendt et lånetilbud.
          </p>
          <p>
            Derfor afhænger det maksimale lånebeløb direkte af din indkomst. Har du en høj
            indkomst, kan du naturligvis låne et højere beløb. Det er dog ikke udelukkende
            din indkomst, der definerer dit potentielle lånebeløb – uanset hvilken
            lånetype, der er tale om.
          </p>
          <p>
            Vær dog opmærksom på, at det ikke er ensbetydende med, at du skal have et job.
            Din indkomst kan derfor sagtens være i form af SU, kontanthjælp, dagpenge mv.
            Enkelte långivere kræver dog et fast arbejde, om end det ofte er i forbindelse
            med større lånebeløb.
          </p>
          <h3>2. RKI</h3>
          <p>
            Er du registreret som dårlig betaler i RKI, er der ingen potentiel mulighed
            for at optage et lån – heller ikke i tilfælde af, at det er et mini lån på
            1.000 kr. En registrering i RKI (Ribers Kredit Information) er en direkte
            afvisning hos samtlige långivere.
          </p>
          <p>
            Skal du gøre dig forhåbninger om at låne penge, er du derfor nødt til at få
            slettet din registrering, hvilket kun kan lade sig gøre på to måder:
          </p>
          <ol>
            <li>Du kan betale den gæld, der har resulteret i din registrering</li>
            <li>Du kan vente i fem år, hvorefter din registrering automatisk slettes</li>
          </ol>
          <p>
            I denne sammenhæng er det dog vigtigt, at du er opmærksom på, at det ikke er
            ensbetydende med, at din gæld slettes, hvis du vælger løsning nr. to.
          </p>
          <p>
            Derimod betyder det blot, at din registrering i RKI fjernes. Derfor rådes du
            selvfølgelig også til at vælge løsning nr. et. Det medfører ikke blot, at din
            registrering fjernes, men også, at din gæld er ude af verden.
          </p>
          <h3>3. Rådighedsbeløb</h3>
          <p>
            Til trods for en høj indkomst, er du ikke garanteret at kunne få bevilliget et
            stort lån. Dit rådighedsbeløb er nemlig også afgørende. Det er nemlig det, der
            er afgørende for, hvorvidt du har råd til tilbagebetalingen af dit lån.
          </p>
          <p>
            Dette er ensbetydende med, at der også tages højde for din eksisterende gæld.
            Derfor er det ofte også noget, du vil blive bedt om at oplyse, når du ansøger
            om om at få et lånetilbud.
          </p>
          <p>
            At der tages højde for dette, er dog udelukkende en fordel. Det sikrer mod, at
            du optager et lån, du ikke har råd til. Angiv derfor altid korrekt tal, når du
            skal oplyse om nuværende gæld, rådighedsbeløb mv. Det er nemlig lige så meget
            for din egen skyld.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">Sådan forøger du dine chancer for at blive godkendt</h2>
          <p>
            Når du skal ansøge om at få et lånetilbud, handler det ikke blot om at finde
            den bedste bank i Danmark, hvor du kan ansøge om et lån. Det handler også om
            at give sig selv de bedst mulige forudsætninger for at få bevilliget et lån.
          </p>
          <p>
            Du finder både mange gamle og
            <b-link :to="$root.rn.NewLoan">nye lån</b-link> i Danmark, som adskiller sig
            på baggrund af flere forskellige punkter. Fælles for de fleste er dog, at
            nedenstående forbedrer chancerne for at blive godkendt hos lånevirksomhederne.
          </p>
          <h3>Få en medansøger på dit lån</h3>
          <p>
            Er I to om at ansøge om lånet, kan långiverne være mere villige til at
            godkende låneansøgningen. Ligeledes vil de ofte også sænke renterne på lånet,
            da I er to om at dele betalingen. Det er en fordel for långiver, da det
            minimerer risikoen for, at pengene ikke tilbagebetales.
          </p>
          <h3>Forlæng lånets løbetid</h3>
          <p>
            At forlænge lånets løbetid kan ligeledes have indflydelse på, hvorvidt du
            bliver godkendt eller ej. Det vil resultere i, at den månedlig ydelse bliver
            mindre, hvorfor tilbagebetalingen bliver mere overskuelig. Dog vil det også
            betyde, at de totale omkostninger stiger.
          </p>
          <h3>Afdrag på lånet via Betalingsservice</h3>
          <p>
            Der er flere fordele ved at tilmelde din betaling til Betalingsservice. Du
            undgår nemlig ikke blot omkostninger til girokort og administration, men øger
            også chancerne for, at långiver godkender din ansøgning, hvis du accepteret
            betaling via Betalingsservice på forhånd.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  data() {
    return {
      contents: [
        {
          title: 'Indhent lånetilbud fra flere banker hos Saverium.dk',
          link: '#1',
        },
        {
          title: 'Hvorfor er det essentielt at indhente flere forskellige lånetilbud?',
          link: '#2',
        },
        {
          title: 'Sammenlign lånetilbud med afsæt i ÅOP',
          link: '#3',
        },
        {
          title: 'Prisen er ikke det eneste afgørende aspekt af et lånetilbud',
          link: '#4',
        },
        {
          title: 'Hvad har indflydelse på mine lånetilbud?',
          link: '#5',
        },
        {
          title: 'Sådan forøger du dine chancer for at blive godkendt',
          link: '#6',
        },
      ],
    }
  },
  methods: {
    setLoan(value) {
      this.loanamount = value
      const key = 'amount'
      this.$store.commit('updateApplication', { key, value })
      this.$router
        .push({
          name: 'DynamicLoanPage',
          params: { loanamount: this.loanamount },
        })
        .catch(() => {})
    },
  },
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
