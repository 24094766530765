<template lang="html">
  <div class="container-fluid column">
    <loan-application-header />
    <second-form />
  </div>
</template>

<script>
import SecondForm from '@/components/lainaayritykselle_fi/newForm/SecondForm'
import LoanApplicationHeader from '@/components/lainaayritykselle_fi/LoanApplicationHeader'

export default {
  components: {
    SecondForm,
    LoanApplicationHeader,
  },
  computed: {
    step() {
      return this.$store.state.application.step
    },
  },
  methods: {
    submitFirst() {
      this.$store.commit('setStep', 2)
    },
    submitSecond() {},
  },
}
</script>

<style lang="sass" scoped>
.column
  padding-bottom: 50px
  max-width: 830px
  margin-left: auto
  margin-right: auto
</style>
