<template>
  <div>
    <label :for="id" v-if="labelVisible">
      <slot></slot>
    </label>
    <b-input
      :id="id"
      :value="value"
      @input="updateValue"
      class="form-input"
      :type="type"
      :min="min"
      :max="max"
      :step="step"
      :state="isValid"
      :placeholder="placeholder"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: [
    'value',
    'group',
    'label',
    'type',
    'id',
    'update',
    'min',
    'max',
    'step',
    'state',
    'placeholder',
    'disabled',
    'showLabel',
    'hideErrorMessage',
  ],
  computed: {
    error() {
      return this.state === false
    },
    labelVisible() {
      return !this.showLabel
    },
    isValid() {
      if (Object.keys(this.$store.state.application.errors).includes(this.id))
        return false
      return null
    },
    errorMessage() {
      return this.$store.state.application.errors[this.id]
    },
  },
  methods: {
    updateValue(value) {
      this.update(this.id, value, this.group)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
