<template>
  <div id="app">
    <Header />
    <div :class="mainPage ? '' : 'app-wrapper'">
      <router-view />
    </div>
    <CookiePopup
      v-if="cookieConsent === false"
      :consentUrl="$root.rn.MarketingConsentsPDF"
    />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/saverium_pl/FooterComponent'
import Header from '@/components/saverium_pl/HeaderComponent'
import CookiePopup from '@/components/CookiePopup'

export default {
  name: 'App',
  computed: {
    mainPage() {
      return this.$route.path === '/'
    },
    cookieConsent() {
      return this.$store.state.user.cookieConsent
    },
  },
  components: {
    Header,
    Footer,
    CookiePopup,
  },
  created() {
    this.$store.dispatch('getEnums')
    this.$store.dispatch('checkCookieConsent')
  },

  mounted() {
    const version = process.env.VUE_APP_VERSION_TAG
    const built = process.env.VUE_APP_BUILD_TIME
    console.log(`Saverium Front version ${version} built ${built}`)
  },
}
</script>

<style lang="scss">
@import '@/assets/sass/app.scss';
@import '@/assets/sass/pl.scss';
</style>
