<template>
  <div>
    <div class="container col-8 mt-5 mb-5">
      <h1>{{ $t('faq_long') }}</h1>
      <p>
        {{ $t('familiarize_with_faq') }}
      </p>

      <h3 class="mt-4 mb-2">{{ $t('loan_application_faq') }}</h3>
      <div class="accordion" role="tablist">
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-1
              >{{ $t('who_can_apply_loan_faq')
              }}<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>{{ $t('18_year_old_faq') }}</b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-2
              >{{ $t('can_pensioner_faq')
              }}<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>{{ $t('retirement_faq') }}</b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-3
              >{{ $t('can_unemployed_faq')
              }}<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>{{ $t('if_unemployed_faq') }}</b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <h3 class="mt-4 mb-2">{{ $t('credit_decision_faq') }}</h3>
      <div class="accordion" role="tablist">
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-4
              >{{ $t('why_loan_application_rejected_faq')
              }}<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>{{ $t('rejected_faq') }}</b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-5
              >{{ $t('can_with_poor_credit_rating')
              }}<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-5" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>{{ $t('poor_rating_faq') }}</b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-6
              >{{ $t('what_age_limit_faq')
              }}<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-6" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>{{ $t('age_limit_faq') }}</b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <div class="text-container mt-5">
        <p>{{ $t('you_should_think_before_applying') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jsonld: {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          {
            '@type': 'Question',
            name: 'Kto może wnioskować o pożyczkę?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Każdy, kto ma co najmniej 18 lat i posiada obywatelstwo polskie oraz polskie konto bankowe.',
            },
          },
          {
            '@type': 'Question',
            name: 'Czy emeryt może wnioskować o pożyczkę?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Po prostu wybierz emeryturę jako źródło dochodów i wypełnij datę przejścia na emeryturę.',
            },
          },
          {
            '@type': 'Question',
            name: 'Jestem bezrobotny; czy mogę wnioskować o pożyczkę?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Jeśli jesteś bezrobotny, nadal możesz ubiegać się o pożyczkę. Jednak wiele banków wymaga pewnego poziomu stałych dochodów, aby móc spłacić kredyt.',
            },
          },
          {
            '@type': 'Question',
            name: 'Dlaczego mój wniosek o pożyczkę został odrzucony?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Saverium.pl tworzy zapytania o oferty kredytowe i wysyła je do wielu banków. Każdy bank podejmuje własną decyzję dotyczącą danej pożyczki, i nie możemy mieć wpływu na te decyzje. Niektóre wnioski są odrzucane z nieznanych dla nas powodów, tak jak nie znamy powodów akceptacji niektórych z wniosków. Częstym powodem odrzucenia wniosku o pożyczkę jest niespełnienie przez wnioskodawce kryteriów dotyczących dochodów lub poziomu zadłużenia.',
            },
          },
          {
            '@type': 'Question',
            name: 'Czy mogę otrzymać pożyczkę mając słabą ocenę zdolności kredytowej?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Możesz ubiegać się o pożyczkę nawet przy słabej ocenie zdolności kredytowej (scoringu kredytowego). Jednak banki zazwyczaj nie oferują pożyczki takim wnioskodawcom.',
            },
          },
          {
            '@type': 'Question',
            name: 'Jaki jest limit wiekowy w Saverium.pl?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Każdy kto ma przynajmniej 18 lat może ubiegać się o pożyczkę. Niektóre banki mogą mieć różne ograniczenia wiekowe i mogą na ich podstawie podjąć decyzję dotyczącą przyznawania pożyczki.',
            },
          },
        ],
      },
    }
  },
  created() {
    const scriptExists = document.getElementById('structuredData')
    if (!scriptExists) {
      const script = document.createElement('script')
      script.type = 'application/ld+json'
      script.innerHTML = JSON.stringify(this.jsonld)
      script.id = 'structuredData'
      document.head.appendChild(script)
    }
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
}
</script>

<style lang="scss" scoped>
.faq-card {
  border: 1px solid rgb(5, 8, 60);
  border-left: none;
  border-right: none;
  &:not(:first-child):not(:last-child) {
    border-top: 1px solid rgb(5, 8, 60);
  }
  &:not(:last-child) {
    border-bottom: none;
  }
  .collapser {
    float: right;
  }
}
</style>
