<template>
  <div>
    <div class="article">
      <h1 class="center-title">Express Bank lån 10.000 – 300.000 kr.</h1>
      <div class="top-text text-container">
        <ul>
          <li>Lån mellem 10.000 – 300.000 kr.</li>
          <li>Udbetaling efter 1-2 hverdage til din NemKonto.</li>
          <li>Lån uden sikkerhed.</li>
          <li>Mange års erfaring samt kredit sikkerhed.</li>
        </ul>
        <p>
          Express Bank blev grundlagt i 1987, hvilket giver dem mere end 20 års erfaring
          på lånemarkedet. De er ejet af BNP Paribas Personal Finance, som findes i mange
          lande rundt om i verden, hvilket gør at de har en meget høj kreditsikkerhed, som
          er en stor fordel, når du skal låne fra en online
          <a
            href="https://www.saverium.dk/laan-penge/laaneudbydere"
            rel="noopener noreferrer"
            target="_blank"
            >låneudbyder</a
          >. Du kan søge om et Express Bank
          <a
            href="https://www.saverium.dk/laan-penge/samlelaan"
            rel="noopener noreferrer"
            target="_blank"
            >samlelån</a
          >
          samt andre låneprodukter, så som
          <a
            href="https://www.saverium.dk/laan-penge/forbrugslaan"
            rel="noopener noreferrer"
            target="_blank"
            >billige forbrugslån</a
          >, fra mere end 10 andre forskellige velkendte udbydere af lån igennem vores
          online tjeneste.
        </p>
        <p>
          Grundet deres mange års erfaring kan du med ro i kroppen være sikker på, at der
          altid vil blive sat fokus på dig som kunde, og hvad der er bedst for dig og din
          økonomi. Med hurtig svartid på din ansøgning og hurtig udbetaling, er der stor
          fokus på servicen omkring dig når du optager et lånet.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#laanevilkaar-og-rente-hos-express-bank"
                    >Lånevilkår og rente hos Express Bank</a
                  >
                </li>
                <li>
                  <a href="#saadan-ansoeger-du-om-et-laan-hos-express-bank"
                    >Sådan ansøger du om et lån hos Express Bank</a
                  >
                </li>
                <li><a href="#express-bank">Express Bank</a></li>
                <li>
                  <a href="#krav-for-et-laan-hos-express-bank"
                    >Krav for et lån hos Express Bank</a
                  >
                </li>
                <li>
                  <a href="#eksempel-paa-et-laan-hos-express-bank"
                    >Eksempel på et lån hos Express Bank</a
                  >
                </li>
                <li>
                  <a href="#express-banks-kontaktoplysninger"
                    >Express Banks kontaktoplysninger</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="laanevilkaar-og-rente-hos-express-bank">
            Lånevilkår og rente hos Express Bank
          </h2>
          <p><strong>Lånebeløb: </strong>10.000 - 300 000 kr.</p>
          <p><strong>Låneperiode: </strong>2 til 12 år</p>
          <p><strong>Variabel debitorrente: </strong>3,55% - 18,38%</p>
          <p>
            <strong>Andre udgifter: </strong>Mdl. Adm. gebyr 25 kr. Stiftelsesgebyr 1,7%
            ved lån over 75.000 kr.
          </p>
          <p>
            <strong>Behandlingstid: </strong>Svar indenfor 2-15 minutter og pengene på din
            konto indenfor 1-2 hverdage
          </p>
          <p><strong>Medansøger: </strong>Ja</p>
          <p></p>
        </div>

        <div class="text-container">
          <h2 id="saadan-ansoeger-du-om-et-laan-hos-express-bank">
            Sådan ansøger du om et lån hos Express Bank
          </h2>
          <p>
            Brug vores online <strong>låneberegner</strong> og udfyld din
            <strong>låneansøgning </strong>på få minutter. Tjek herefter de tilbud du
            skulle modtage på din brugerkonto.
          </p>
          <p>
            1. <strong>Udfyld ansøgningen med beløb og løbetid.</strong> Vælg dit
            foretrukne
            <a
              href="https://www.saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              >lånebeløb</a
            >
            og den låneperiode du ønsker for dit lån.
          </p>
          <p>
            2. <strong>Oplys dine andre oplysninger.</strong> Udfyld de resterende
            nødvendige oplysninger i ansøgningen.
          </p>
          <p>
            3. <strong>Send ansøgning online</strong>. Send den udfyldte ansøgning. Vi
            indhenter skræddersyet tilbud baseret på dig og din økonomi, og du får hurtigt
            svar på din ansøgning.
          </p>
          <p>
            4. <strong>Accepter det ønskede tilbud</strong>.
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >Sammenlign lånetilbud</a
            >
            og accepter derefter det ønskede tilbud, som passer til dig og din økonomi.
            Giv samtykke til SKAT, så Express Bank kan lave en kreditvurdering af dig. På
            lån over over 75.000 kr. skal du fremvise dokumentation for din indkomst.
          </p>
          <p>
            5. <strong>Lånet vil blive overført din konto</strong>. Hvis du vælger Express
            Bank og dit lån bliver godkendt, vil pengene blive overført til din bankkonto
            indenfor 1-2 hverdage.
          </p>
        </div>

        <div class="text-container">
          <h2 id="express-bank">Express Bank</h2>
          <p>
            Express Bank er en moderne bank, der på samme tid er forholdsvis klassik i
            deres strukture med mange års erfaring indenfor deres fag. Express Banks lån
            er meget traditionelle, men de holder sig altid opdateret på markedet og
            tilpasser sig til punkt og prikke. De tilbyder alt fra
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan"
              rel="noopener noreferrer"
              target="_blank"
              >privatlån</a
            >
            og forsikring til kreditkort og henvender sig til privatpersoner. Derfor kan
            du med sikkerhed føle dig i trygge hænder når du accepterer et nyt tilbud fra
            Express Bank.
          </p>
          <p>
            Fordi Express Bank tilbyder
            <a
              href="https://saverium.dk/laan-penge/privatlaan/uden-sikkerhed"
              rel="noopener noreferrer"
              target="_blank"
              >lån uden sikkerhed</a
            >, behøver du ikke skal stille noget værdi til rådighed. Du behøver ikke
            fortælle hvad netop dit
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >nye lån</a
            >
            skal bruges på, om lånet skal bruges til en
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan/baad"
              rel="noopener noreferrer"
              target="_blank"
              >ny båd</a
            >, eller hvis du har tænkt dig at
            <a
              href="https://saverium.dk/laan-penge/billaan/motorcykel"
              rel="noopener noreferrer"
              target="_blank"
              >låne til en ny MC</a
            >, så gælder det bare om for Express Bank, at du kan tilbagebetale lånet
            indenfor den aftale låneperiode.
          </p>
          <p>
            Når du udfylder en gratis online låneansøgning igennem vores platform, kan du
            få tilbud fra mere end 10 forskellige anerkendte
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere online</a
            >
            udover tilbud fra Express Bank. Det er meget nemt samt gratis, og du
            forpligter dig ikke til at acceptere nogle af de tilbud, som du skulle
            modtage. Du kan indsende en gratis og uforpligtende ansøgning på få minutter
            til os, og du vil straks modtage svar på din ansøgning.
          </p>
        </div>

        <div class="text-container">
          <h2 id="krav-for-et-laan-hos-express-bank">Krav for et lån hos Express Bank</h2>
          <p>
            For at kunne få et lån hos Express Bank, skal du leve op til disse 4 krav:
          </p>
          <ul>
            <li>Du skal have fast bopæl i Danmark.</li>
            <li>Du skal være fyldt 18 år.</li>
            <li>Du skal have permanent opholdstilladelse i Danmark.</li>
            <li>Du må du ikke være registeret i hverken Debitor Registret eller RKI.</li>
          </ul>
          <p>
            Du har 14 dages fortrydelsesret hvis du godkender lånet, hvilket du ikke skal
            betale en krone for. Rentesatsen bliver bestemt ud fra din indkomst og
            økonomi, som bliver vurderet individuelt via SKAT, hvilket du giver tilladelse
            at Express Bank må indhente via et login med
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >NemID/MitID</a
            >.
          </p>
          <p>På den måde vil der kunne laves en kreditvurdering af din økonomi.</p>
        </div>

        <div class="text-container">
          <h2 id="eksempel-paa-et-laan-hos-express-bank">
            Eksempel på et lån hos Express Bank
          </h2>
          <p>
            <strong>Låneeksempel</strong>: Lånebeløb: 50.000 kr. Løbetid: 5 år Månedligt
            ydelse: 1.030 kr. – 1.282 kr. ÅOP før skat: 9,02% - 20,11% Samlet
            tilbagebetaling: 61.789 kr. – 76.942 kr.
          </p>
        </div>

        <div class="text-container">
          <h2 id="express-banks-kontaktoplysninger">Express Banks kontaktoplysninger</h2>
          <p>
            Du kan ringe til Express kundeservice mandag-fredag fra kl. 9 – 17:00. Ring på
            Express Banks telefon: 70 23 58 00. Du kan også skrive til Express Bank på
            deres mail: kundeservice@expressbankmail.dk.
          </p>
          <p>
            Har du spørgsmål vedrørende din ansøgning kan du kontakte os på hverdage
            mellem 09:00 - 15:00 på telefon: 93 75 99 59. Du kan os skrive til os på:
            <a
              href="mailto:kundeservice@saverium.dk"
              rel="noopener noreferrer"
              target="_blank"
              >kundeservice@saverium.dk</a
            >.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },

  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
