<template>
  <div class="row article">
    <h1 class="center-title">
      Jakie dokumenty są potrzebne do wnioskowania o kredyt gotówkowy?
    </h1>

    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            Każdy kredytobiorca doskonale zdaje sobie sprawę z tego, że wnioskowanie o
            kredyt gotówkowy nigdy nie było i prawdopodobnie nie będzie należało do
            najłatwiejszych zadań. Szczególnie odnosi się to do kredytobiorców, którzy
            ubiegają się o zobowiązanie na wysoką kwotę pieniężną. Choć formalności
            związane z zaciągnięciem kredytu i tak zostały ograniczone do niezbędnego
            minimum, to jednak dalej muszą one zostać dopełnione. W tym celu kredytobiorcy
            powinni przygotować sobie odpowiednie dokumenty, dzięki którym cały proces
            wnioskowania o
            <a
              href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-na-dowolny-cel/"
              rel="noopener noreferrer"
              target="_blank"
              ><strong>kredyt gotówkowy na dowolny cel</strong></a
            >
            przebiegnie znacznie szybciej. Jakie dokumenty należy więc skompletować, by
            ubiegać się o
            <a
              href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-kalkulator-i-oferty-pozyczek-gotowkowych/"
              rel="noopener noreferrer"
              target="_blank"
              ><strong>kredyt gotówkowy</strong></a
            >?
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/what-documents-for-cash-loan-tiny.webp"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/what-documents-for-cash-loan.webp"
            alt="Jakie dokumenty są potrzebne do wnioskowania o kredyt gotówkowy?"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul class="table-of-contents">
              <li>
                <a
                  href="#jakie-dokumenty-trzeba-przygotowac-by-wnioskowac-o-kredyt-gotowkowy"
                  >Jakie dokumenty trzeba przygotować, by wnioskować o kredyt
                  gotówkowy?</a
                >
              </li>
              <li>
                <a
                  href="#co-jeszcze-moze-byc-wymagane-przez-banki-w-momencie-wnioskowania-o-kredyt-gotowkowy"
                  >Co jeszcze może być wymagane przez banki w momencie wnioskowania o
                  kredyt gotówkowy?</a
                >
              </li>
              <li>
                <a href="#jakie-warunki-trzeba-spelnic-by-ubiegac-sie-o-kredyt-gotowkowy"
                  >Jakie warunki trzeba spełnić, by ubiegać się o kredyt gotówkowy?</a
                >
              </li>
              <li>
                <a href="#kredyt-gotowkowy-na-dowod-co-jest-potrzebne"
                  >Kredyt gotówkowy na dowód - co jest potrzebne?</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container">
          <h2 id="jakie-dokumenty-trzeba-przygotowac-by-wnioskowac-o-kredyt-gotowkowy">
            Jakie dokumenty trzeba przygotować, by wnioskować o kredyt gotówkowy?
          </h2>
          <p>
            Procedury wnioskowania o
            <a
              href="https://www.saverium.pl/artykuly/kredyt-ratalny/"
              rel="noopener noreferrer"
              target="_blank"
              ><strong>kredyt gotówkowy ratalny online</strong></a
            >
            w obecnych czasach i tak zostały mocno uproszczone. Niemniej jednak nie
            oznacza to, że kredytobiorcy powinni udać się do banku jedynie z wnioskiem.
            Jest to jeden z koniecznych dokumentów, jednocześnie powinien on zostać
            uzupełniony także:
          </p>
          <ul>
            <li>
              <strong>dowodem osobistym </strong>- jest to dokument podstawowy przy
              zaciąganiu kredytu gotówkowego. Przedstawiciel bankowy spisze z naszego
              dowodu PESEL, numer i serię dokumentu, a także nasze imię i nazwisko.
            </li>
            <li>
              <strong>wniosek o kredyt gotówkowy </strong>- mowa tu oczywiście o wniosku,
              który będzie poprawnie wypełniony. Wielu kredytobiorców bowiem stawia się w
              placówce bankowej z błędnie uzupełnionym wnioskiem, przez co cała procedura
              musi być rozpoczynana od nowa.
            </li>
            <li>
              <strong>zaświadczenie o zatrudnieniu </strong>- jest to nic innego jak
              dokument potwierdzający nasz stosunek pracy, a także nasze dochody. Dla
              banku jest to swoista gwarancja tego, że posiadamy odpowiednie środki na
              spłatę zobowiązania, a co więcej, że będziemy w stanie wywiązać się z tego w
              terminie.
            </li>
          </ul>
          <p>
            Warto jednak wspomnieć, że zaświadczenie o zatrudnieniu nie zawsze może być
            wymagane. Zwykle potrzebują go banki, które udzielają kredytów opiewających na
            znacznie większe kwoty. W przypadku
            <a
              href="https://www.saverium.pl/artykuly/pozyczki-pozabankowe-online-kredyty-pozabankowe-prezez-internet/"
              rel="noopener noreferrer"
              target="_blank"
              ><strong>pożyczek pozabankowych</strong></a
            >, najczęściej wystarczy jedynie dowód osobisty i wniosek, który z reguły
            wypełniany jest online.
          </p>
        </div>

        <div class="text-container">
          <h2
            id="co-jeszcze-moze-byc-wymagane-przez-banki-w-momencie-wnioskowania-o-kredyt-gotowkowy"
          >
            Co jeszcze może być wymagane przez banki w momencie wnioskowania o kredyt
            gotówkowy?
          </h2>
          <p>
            Zdarza się, że w przypadku niektórych banków, powyższe dokumenty nie są
            wystarczające. Warto więc wiedzieć, że w przypadku wnioskowania o wysokie
            kwoty kredytu (np.
            <a
              href="https://www.saverium.pl/artykuly/kredyt-100-tys-zlotych-najlepsze-oferty-pozyczek-gotowkowych/"
              rel="noopener noreferrer"
              target="_blank"
              ><strong>kredyt 100 000 zł</strong></a
            >,
            <a
              href="https://www.saverium.pl/artykuly/kredyt-150-tys-online-warunki-i-raty-jak-dostac-kredyt-150-000-zl/"
              rel="noopener noreferrer"
              target="_blank"
              ><strong>kredyt 150 000 zł</strong></a
            >,
            <a
              href="https://www.saverium.pl/artykuly/kredyt-200-tys-zlotych-najlepsze-oferty-pozyczek-gotowkowych/"
              rel="noopener noreferrer"
              target="_blank"
              ><strong>kredyt 200 000 zł</strong></a
            >), bank będzie mógł wymagać od nas zaświadczenia odnośnie tego, że nie ciążą
            na nas żadne inne zobowiązania, a także zaświadczenia o wierzycielach. Są to
            osoby, które zdecydowały się na tzw. poręczenie kredytu i stanowią dla banku
            gwarancję tego, że jeśli my nie będziemy w stanie samodzielnie wywiązać się ze
            spłaty zadłużenia, to dług przejdzie na ręce wierzycieli.
          </p>
        </div>

        <div class="text-container">
          <h2 id="jakie-warunki-trzeba-spelnic-by-ubiegac-sie-o-kredyt-gotowkowy">
            Jakie warunki trzeba spełnić, by ubiegać się o kredyt gotówkowy?
          </h2>
          <p>
            Nie można zapomnieć także o tym, że by ubiegać się o<a
              href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-na-oswiadczenie-online/"
              rel="noopener noreferrer"
              target="_blank"
              ><strong> kredyt gotówkowy na oświadczenie online</strong></a
            >, konieczne jest spełnienie także określonych kryteriów. Każdy bank, choć w
            dość indywidualny sposób ustala warunki przyznawania kredytów gotówkowych, to
            jednak w dużej mierze ich większość jest wspólna. Wobec tego, jakie warunki
            musi spełnić każdy kredytobiorca, by w ogóle zawnioskować o kredyt? Otóż:
          </p>
          <ul>
            <li>
              konieczne jest posiadanie polskiego obywatelstwa- czyli ważnego dowodu
              osobistego, który jednocześnie będzie potwierdzeniem tożsamości;
            </li>
            <li>konieczne jest ukończenie 18 roku życia;</li>
            <li>konieczne jest posiadanie adresu zameldowania na terenie polski.</li>
          </ul>
          <p>
            Oczywiście kluczowe jest tutaj również posiadanie stałego źródła dochodów oraz
            stosunku pracy. To właśnie na tej podstawie wyliczana jest
            <a
              href="https://www.saverium.pl/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/"
              rel="noopener noreferrer"
              target="_blank"
              ><strong>zdolność kredytowa</strong></a
            >, a następnie oceniana jest sama wartość kredytobiorcy. Warto również
            pamiętać, że praktycznie każda instytucja bankowa sprawdza także wpisy w
            <a
              href="https://www.saverium.pl/artykuly/bik-big-krd-erif-rdn-co-znacza-te-skroty-jak-sprawdzic-rejestry-dluznikow/"
              rel="noopener noreferrer"
              target="_blank"
              ><strong>BIK i KRD</strong></a
            >. To, czy kredyt faktycznie zostanie udzielony, czy też nie, zależy w dużej
            mierze od przeszłości kredytowej wnioskodawcy. Każdy negatywny wpis, odnośnie
            zalegania ze spłatą zobowiązań czy nieterminowym uregulowaniem należności, są
            zwykle największą przeszkodą w staraniu się o kredyt gotówkowy.
          </p>
        </div>

        <div class="text-container">
          <h2 id="kredyt-gotowkowy-na-dowod-co-jest-potrzebne">
            Kredyt gotówkowy na dowód - co jest potrzebne?
          </h2>
          <p>
            Obecnie zobowiązania na niewielkie kwoty, udzielane są w postaci szybkich
            kredytów gotówkowych. W tym przypadku mówi się o kredycie gotówkowym na dowód,
            gdzie jak sama nazwa wskazuje, niezbędne jest jedynie okazanie dokumentu
            tożsamości. Z uwagi na to, że są to kredyty w większości udzielane przez
            Internet, cały proces wypełniania wniosku i formalności jest ograniczony jest
            do niezbędnego minimum. W niektórych przypadkach jednak może być wymagany
            kontakt z konsultantem, celem omówienia szczegółów i telefonicznej weryfikacji
            tożsamości.
          </p>
          <p><strong>Sprawdź inne nasze artykuły:</strong></p>
          <ul>
            <li>
              <a
                href="https://www.saverium.pl/artykuly/kredyt-na-umowe-zlecenie/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>Kredyt na umowę zlecenie</strong></a
              >
            </li>
            <li>
              <a
                href="https://www.saverium.pl/artykuly/jak-wziac-kredyt-gotowkowy-krok-po-kroku/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>Kredyt gotówkowy - jak wziąć krok po kroku</strong></a
              >
            </li>
            <li>
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-majac-kredyt-hipoteczny/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>Kredyt gotówkowy mając kredyt hipoteczny</strong></a
              >
            </li>
            <li>
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-bez-zaswiadczen-online/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>Kredyt gotówkowy bez zaświadczeń online</strong></a
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'

export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
