<template>
  <transition name="fade">
    <div
      id="containerScroller"
      v-if="showContainerScroller"
      class="scroller-wrapper"
      @click="goToTop"
    >
      <div class="arrow-up">
        <i class="far fa-chevron-double-up"></i>
      </div>
      <div class="floating-scroller">
        <p>{{ $t('apply_for_a_loan') }}</p>
      </div>
    </div>
  </transition>
</template>

<script>
import { checkIfInViewport } from '@/utils/utils'
export default {
  props: { elementId: { type: String } },
  data() {
    return {
      showContainerScroller: false,
    }
  },
  created() {
    window.addEventListener('scroll', this.showPopup)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.showPopup)
  },
  methods: {
    goToTop() {
      window.scrollTo({ top: 100, behavior: 'smooth' })
    },
    showPopup() {
      const element = document.getElementById(this.elementId)
      this.showContainerScroller = !checkIfInViewport(element)
    },
  },
}
</script>

<style lang="scss" scoped>
.scroller-wrapper {
  z-index: 10;
  cursor: pointer;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: white;
  .arrow-up {
    width: 10rem;
    height: 0;
    position: relative;
    border-radius: 4rem 4rem 0px 0px;
    border-bottom: 1.5rem solid #096e00da;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    margin: auto;
  }
  .floating-scroller {
    background: #096e00da;

    height: 2rem;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: transform 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(2rem);
  transition: 0.2s;
}
</style>
