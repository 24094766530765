<template>
  <div class="row article">
    <h1 class="center-title">
      Kredyt gotówkowy dla jednoosobowej działalności gospodarczej
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >Wiele wskazuje na to, że mali przedsiębiorcy wolą zaciągać prywatne
              zobowiązania niż kredyty firmowe. Czy naprawdę kredyt dla jednoosobowej
              działalności gospodarczej jest tak trudny do dostania? Jakie warunki trzeba
              spełnić, aby go dostać? Czy możliwe jest otrzymanie takiej
              <b-link :to="$root.rn.MainView">pożyczki online</b-link>? Podpowiadamy!</i
            >
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/loan-for-entrepreneurs-tiny.jpeg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/loan-for-entrepreneurs.jpeg"
            alt="Kredyt gotówkowy dla jednoosobowej działalności gospodarczej"
          />

          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            Pożyczka na działalność gospodarczą może być przeznaczona na dowolny cel. Nie
            tylko ten związany z działalnością firmy, ale także ten całkowicie prywatny.
            Czym w takim razie różni się od zwykłego
            <b-link :to="$root.rn.CashLoan">kredytu gotówkowego</b-link>?
          </p>
          <p>
            Osoby prowadzące jednoosobową działalność gospodarczą, jeżeli nie są
            jednocześnie zatrudnione na etacie, mogą mieć problem z uzyskaniem pożyczki.
            Paradoksalnie ich zarobki mogą być wyższe, jednak banki i firmy pozabankowe
            patrzą bardziej surowym okiem na osoby samozatrudnione. Nieregularne przychody
            nie są bowiem dla nich synonimem bezpieczeństwa.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Już na wstępie warto przygotować się na to, że otrzymanie pożyczki dla firm
            może być trudniejsze i bardziej czasochłonne niż w przypadku prywatnych
            zobowiązań osób na etacie. Dlaczego tak się dzieje? Przede wszystkim
            instytucja finansowa bierze wszystkie nasze zobowiązania – zarówno te firmowe,
            jak i prywatne. We wniosku trzeba umieścić wszystkie ponoszone w ciągu
            miesiąca stałe wydatki.
          </p>
          <p>
            Wielu przedsiębiorców w obawie przed restrykcyjną działalnością banków
            decyduje się na współpracę z parabankami.
            <b-link :to="$root.rn.OnlineNonbankLoans">Pożyczki pozabankowe</b-link> są w
            teorii łatwiejsze do dostania w takiej sytuacji, jednak warto pamiętać, że
            firmy pożyczkowe także weryfikują naszą
            <b-link :to="$root.rn.CreditWorthiness">zdolność kredytową</b-link>.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Warto zaznaczyć, że podczas ubiegania się o kredyt na jednoosobową działalność
            gospodarczą należy przygotować się na stosunkowo dużą ilość formalności.
            Podstawą będą oczywiście dane osobiste oraz przychody firmy. Co jeszcze będzie
            trzeba przygotować?
          </p>
          <ul>
            <li>zaświadczenie o zobowiązaniach finansowych</li>
            <li>zaświadczenie o niezaleganiu ze składanki w ZUS i US</li>
            <li>PIT z ostatniego roku podatkowego</li>
            <li>wyciąg z osobistego i firmowego rachunku</li>
            <li>zaświadczenie o wpisie do CEDIG</li>
          </ul>
          <p>
            Są to oczywiście tylko przykłady dokumentów, ponieważ zakres formalności różni
            się od siebie w zależności od danej instytucji finansowej. Nie da się jednak
            ukryć, że jest ich naprawdę dużo.
          </p>
          <p>
            Co ciekawe ważny jest także staż prowadzenia działalności gospodarczej.
            Wprawdzie niektóre banki mają w ofercie tak zwane kredyty gotówkowe dla nowych
            firm, zazwyczaj wymagana jest przynajmniej dwunastomiesięczna nieprzerwana
            działalność.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Osoby, które nie chcą lub z jakiegoś powodu nie mogą skorzystać z oferty
            bankowej mogą zainteresować się alternatywą.
            <b-link :to="$root.rn.LoansForCompanies"
              >Pozabankowa pożyczka przez Internet dla firm</b-link
            >
            jest jak najbardziej możliwa także w przypadku przedsiębiorców.
          </p>
          <p>
            Przede wszystkim jest to o wiele szybsze niż w przypadku banków, dlatego
            idealnie sprawdzi się w sprawach osób, którym zależy na czasie.
            <b-link :to="$root.rn.InstantInternetLoans"
              >Pożyczka od ręki przez Internet</b-link
            >
            ma także wiele innych zalet. Przed jej zaciągnięciem warto jednak dobrze
            przemyśleć wszystkie za i przeciw.
          </p>
          <p>
            Przede wszystkim warto sprawdzić każdą firmę, z którą rozważamy współpracę. Na
            polskim rynku istnieje mnóstwo parabanków ze świetnymi opiniami, które cieszą
            się szacunkiem klientów. Nie brakuje jednak także oszustów, dlatego trzeba być
            ostrożnym. Sprawdzajmy opinie o firmach i porównujmy ich oferty.
          </p>
          <p>
            Bardzo istotne jest także to, aby dobrze zaplanować wydatki związany z
            pożyczką i zastanowić się, czy wysokość raty nie zaszkodzi płynności
            finansowej mikro przedsiębiorstwa.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            <b-link :to="$root.rn.LoanInstallment">Pożyczki online na raty</b-link> mogą
            być bardzo atrakcyjne dla przedsiębiorców. Wiele osób zastanawia się jednak,
            czy są to bardzo drogie zobowiązania. Całkowitą wysokość pożyczki bardzo łatwo
            obliczyć z pomocą prostych narzędzi, takich jak
            <b-link :to="$root.rn.LoanCalculator">kalkulatory kredytów gotówkowych</b-link
            >.
          </p>
          <p>
            Warunki różnią się od siebie w wielu instytucjach, dlatego decyzja powinna być
            dobrze przemyślana. Oczywiście niejednokrotnie zależy nam na czasie, wtedy
            najlepiej kierować się opiniami, które można z łatwością znaleźć w Internecie.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            Zaciągniecie kredytu na działalność gospodarczą online nie różni się zbytnio
            od zaciągania analogicznych
            <b-link :to="$root.rn.PrivateLoans">pożyczek prywatnych</b-link>. Potrzebne
            jest do tego konto na stronie internetowej instytucji pozabankowej. Jest ono
            proste do założenia. Następnie wybieramy ofertę, która nas interesuje i
            wypełniamy formularz.
          </p>
          <p>
            Zdolność kredytowa zostaje przeanalizowana automatycznie w procesie
            internetowym. Na decyzję nie trzeba długo czekać, jednak gdyby nie była dla
            nas satysfakcjonująca, można skorzystać z telefonu do konsultanta, który być
            może będzie w stanie zaoferować lepsze warunki lub zaproponować inny kredyt.
          </p>
          <p>
            Po raz kolejny warto podkreślić zasady bezpieczeństwa. Branie pożyczki przez
            Internet jest bezpieczne, w momencie, kiedy mamy możliwość potwierdzenia
            danych za pomocą przelewu na symboliczną kwotę lub skorzystania ze specjalnej
            aplikacji.
          </p>
        </div>
        <div class="text-container">
          <h2 id="7">{{ contents[6].title }}</h2>
          <p>
            Kredyt dla działalności gospodarczej może być przeznaczony na dowolnie wybrany
            przez nas cel. Niestety, formalności potrzebne do jego otrzymania są dużo
            bardziej skomplikowane niż w przypadku prywatnych pożyczek. Być może dlatego
            statystyki pokazują, że większość mikro przedsiębiorców korzysta raczej z
            kredytów gotówkowych dla osób prywatnych.
          </p>
          <p>
            Alternatywą dla propozycji bankowych mogą być
            <b-link :to="$root.rn.LoanOnDeclaration">pożyczki na oświadczenie</b-link>
            nazywane potocznie
            <b-link :to="$root.rn.OnlinePaydayLoans">chwilówkami online</b-link>. Z
            pewnością w ich przypadkach pieniądze trafiają na nasze konto szybciej. Mimo
            wszystko takie rozwiązanie powinno być dobrze przemyślane. Bardzo istotne jest
            to, aby rata nie obciążała budżetu w taki sposób, że płynność finansowa firmy
            stanie zagrożona. Zarówno w przypadku prywatnych osób jak i mikro
            przedsiębiorców, zobowiązanie finansowe to poważne przedsięwzięcie, do którego
            trzeba podchodzić rozsądnie. Wnioskowanie o pożyczkę powinniśmy przeprowadzić
            tylko wtedy, gdy mamy całkowitą pewność, że będziemy w stanie terminowo
            spłacić zobowiązanie wraz z odsetkami i prowizjami. W przeciwnym razie, nie
            powinniśmy w ogóle starać się o kredyt lub
            <b-link :to="$root.rn.LoansForProof">pożyczkę na dowód osobisty</b-link>.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'Kredyt na działalność gospodarczą - jak to działa?',
          link: '#1',
        },
        {
          title: 'Kredyt dla jednoosobowej działalności gospodarczej',
          link: '#2',
        },
        {
          title: 'Kredyt gotówkowy dla firm',
          link: '#3',
        },
        {
          title: 'Pożyczka gotówkowa dla firm',
          link: '#4',
        },
        {
          title: 'Kredyt na działalność gospodarczą - jakie warunki?',
          link: '#5',
        },
        {
          title: 'Jak wziąć kredyt na działalność gospodarczą online?',
          link: '#6',
        },
        {
          title: 'Kredyt dla działalności gospodarczej - podsumowanie',
          link: '#7',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped></style>
