<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til kørekort</h1>

      <div class="top-text text-container">
        <p>
          Ønsker du at låne penge til dit kørekort, så er du havnet det rigtige sted. Vi
          kan hurtigt og nemt hjælpe dig med at finde den bedste og billigste finansiering
          af dit kørekort. Udfyld vores ansøgning her på siden, så indhenter vi med det
          samme en lang række tilbud på lån til kørekort til dig.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#saadan-finder-vi-det-billigste-laan-til-koerekort-til-dig"
                    >Sådan finder vi det billigste lån til kørekort til dig</a
                  >
                </li>
                <li>
                  <a href="#hvad-kan-et-laan-til-koerekortet-daekke-over"
                    >Hvad kan et lån til kørekortet dække over?</a
                  >
                </li>
                <li>
                  <a href="#laan-til-koerekort-hvis-du-staar-i-rki"
                    >Lån til kørekort hvis du står i RKI</a
                  >
                </li>
                <li>
                  <a href="#hvordan-undgaar-du-at-laane-penge-til-koerekortet"
                    >Hvordan undgår du at låne penge til kørekortet</a
                  >
                </li>
                <li>
                  <a href="#laan-til-alle-slags-koerekort">Lån til alle slags kørekort</a>
                </li>
                <li><a href="#find-den-rette-bil">Find den rette bil</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="saadan-finder-vi-det-billigste-laan-til-koerekort-til-dig">
            Sådan finder vi det billigste lån til kørekort til dig
          </h2>
          <p>
            Når du ansøger om et lån til kørekort igennem vores hjemmeside, hvilket tager
            2 minutter, udfylder du nogle oplysninger om din økonomi og situation. Denne
            information fremsender vi til en lang række banker og långivere, som herefter
            vender tilbage til os, med
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >tilbud på forbrugslån</a
            >, som du kan benytte som et lån til dit kørekort.
          </p>
          <h3>Sådan vælger du det rette lån</h3>
          <p>
            Herefter er det blot op til dig at vælge hvilken udbyder, som du ønsker skal
            finansiere dit kørekort. Når man låner penge til kørekortet, vil det typisk
            være ÅOPén og udbetalingshastigheden (hvis du
            <strong>mangler penge</strong> nu), som er værd at overveje. ÅOPén kan dog kun
            benyttes såfremt løbetiden på lånene er identiske. Hvis dette ikke er
            tilfældet, så anbefaler vi, at du
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligner lånene</a
            >
            på baggrund af de totale låneomkostninger og den månedlige ydelse.
          </p>
          <p>
            Til sidst er det blot op til dig at trykke på den valgte udbyder, hvorefter du
            vidrestilles til udbyderens hjemmeside, som kan foretage den endelige
            verificering af din data, før lånet til kørekortet udbetales, så du kan
            påbegynde teoriundervisningen.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-kan-et-laan-til-koerekortet-daekke-over">
            Hvad kan et lån til kørekortet dække over?
          </h2>
          <p>
            Alt efter om du er ny bilist og skal have et nyt kørekort for første gang,
            eller om du er blevet frakendt dit kørekort og dermed skal generhverve
            kørekortet, kan det være forskellig, hvad lånet til kørekortet skal
            finansiere. Herunder vil vi gennemgå, hvad et lån til et almindeligt nyt
            kørekort skal dække.
          </p>
          <h3>Teori</h3>
          <p>
            Først og fremmest skal du have 29 teorilektioner, hvor du vil få gennemgået
            færdselsloven, hvilket indebær vigepligtsregler, fartgrænser m.v., samt
            gennemgå en del teoriprøver. Her vil også førstehjælpskurset blive gennemgået,
            samt de lovpligtige genstande, som skal opbevares i bilen, hvilket i Danmark,
            som minimum, vil være en advarselstrekant, samt en refleksvest.
          </p>
          <p>
            Når alt dette er overstået, kan du i princippet tage teoriprøven, som koster
            ca. <strong>1.000 kr.</strong>
          </p>
          <p>
            Du kan finde de opdaterede
            <a
              href="https://www.borger.dk/transport-trafik-rejser/Biler-og-koerekort/Koerekort/Koereundervisning-og-koereproeve"
              rel="noopener noreferrer"
              target="_blank"
              >priser vedr. køre- og teoriprøve på borger.dk</a
            >, hvilket kan hjælpe dig med at få et overblik over, om du har behov for at
            låne til dit kørekort.
          </p>
          <p>Manøvrebane</p>
          <p>
            På manøvrebanen, på det køretekniske anlæg (kravlegården), får du den første
            rigtige køreundervisning samt dit glatbanekursus, så du lærer at styre kontra,
            hvis bilen skrider ud på glat underlag. Denne del består af 4 lektioner, som
            alle er obligatoriske for at erhverve sig et nyt kørekort. Ligeledes behøver
            du, i de fleste tilfælde, ikke
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >
            til denne del af kørekortet, hvis du er i gang med generhvervelse.
          </p>
          <p>Kørelektioner</p>
          <p>
            Køretimerne skal du have 16 af, hvis du er i gang med dit første kørekort.
            Mange vælger dog at låne lidt ekstra til kørekortet, da det kan være godt med
            en buffer til fx ekstra kørelektioner. Mange som tager et kørekort for første
            gang føler nemlig, at de ikke er klar til den endelige køreprøve og at komme
            ud i trafikken med blot 16 kørelektioner. Ekstra kørelektioner bliver ofte
            relevante, hvis der er langt mellem dine sidste kørelektioner og selve
            køreprøven.
          </p>
          <p>
            I denne del af undervisningen vil du både skulle undervises i bykørsel,
            motorvej, landevej, i mørkekørsel samt standsning og parkering - herunder den
            berygtede parallelparkering.
          </p>
          <p>
            Denne del af kørekortet vil der ofte ikke være behov for at låne til, hvis du
            er blevet frakendt dit kørekort, da der sjældent stilles krav til
            kørelektioner i forbindelse med generhvervelse.
          </p>
          <p>Køreprøve</p>
          <p>
            Når du skal have finansieret dit kørekort, er køreprøven også en del af den
            udgift, som du måske også får behov for at låne til. Køreprøven koster i sig
            selv det samme som teoriprøven, og hvis du er uheldig at få en prøvesagkyndig,
            som ikke lader dig slippe med en let omgang motorvejskørsel og et simpelt
            check af dæktryk eller oliemåling, så er der en god sandsynlighed for at du
            kommer til at tage prøven om.
          </p>
          <p>
            I følge BT, dumpede
            <a
              href="https://www.bt.dk/samfund/her-dumper-flest-koereproeven#:~:text=Danmarks%20strengeste%20pr%C3%B8vesagkyndige%20dumper%2074,af%20en%20aktindsigt%20fra%20Rigspolitiet."
              rel="noopener noreferrer"
              target="_blank"
              >landets strengeste prøvesagkyndige 74% af sine elever, hvor den mildeste
              dumpede blot 12%</a
            >. Gennemsnittet ligger omkring 50% på landsplan.
          </p>
          <p>Andet</p>
          <p>
            Ud over ovennævnte punkter, skal du også have et lovpligtigt
            førstehjælpskursus og en lægeerklæring, som er lægens ord for at du fysisk
            (høre- og synstest) og mentalt er egnet til at få et kørekort, samt at du ikke
            har et handikap, som gør dig uegnet til at køre bil. Som regl er
            førstehjælpskurset en del af pakken, som du køber, når du køber dit forløb hos
            køreskolen, men lægeerklæringen skal du ofte selv betale for ved siden af.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-koerekort-hvis-du-staar-i-rki">
            Lån til kørekort hvis du står i RKI
          </h2>
          <p>
            Kan man låne penge til kørekort, hvis man står i RKI? I de fleste tilfælde kan
            du ikke låne til kørekortet, hvis du står i RKI. Det samme gælder, hvis du
            står registreret i Debitor Registret, som blot er et alternativ register for
            dårlige betalere.
          </p>
          <p>
            Dermed ikke sagt at det er umuligt at få et lån til kørekortet, hvis du har
            misligholdt gæld. Det mest normale eksempel på lån med RKI er, at du har en
            parkeringsbøde, som du er uenig i, og derfor er i en process hvor du og
            parkeringsselskabet er ved at få afklaret, om afgiften reelt skal betales
            eller bortfalde. Dette tilfælde er nok ikke så relevant, da du sjældent får en
            parkeringsafgift uden et kørekort. Dette kan dog være en hvilken som helst
            anden registrering i RKI, som du reelt godt kan forklare dig ud af, hvis du
            ønsker at låne til dit kørekort.
          </p>
          <p>
            Den nemmeste måde at finde ud af, om du kan låne penge til kørekortet, er at
            udfylde vores ansøgningsskema. Det tager kun to minutter, og i mange tilfælde
            finder vores brugere ud af, at de slet ikke er registreret som dårlig betaler
            og derfor godt kan få finansieret deres kørekort med et lån
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvordan-undgaar-du-at-laane-penge-til-koerekortet">
            Hvordan undgår du at låne penge til kørekortet
          </h2>
          <p>
            De fleste som ikke ønsker at låne penge til kørekortet har to muligheder, som
            enten er at spare op, eller få / låne penge til kørekortet af deres forældre.
          </p>
          <h3>Spar op til kørekort</h3>
          <p>
            Et kørekort koster ofte i omegnen af
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/10000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >10.000 kr.</a
            >
            Hvis du er i gang med en ungdomsuddannelse, og derfor kun kan få ufaglært
            arbejde, kan du forvente en løn i omegnen af 125 kr. (hvis du er fyldt 18 år).
          </p>
          <p>
            Hvis du ikke arbejder mere, end at du kan holde dig indenfor dit frikort, skal
            du kun betale arbejdsmarkedsbidrag, og dermed arbejde lidt under 90 timer, for
            at undgå at skulle låne til kørekortet.
          </p>
          <h3>Få forældre til at finansiere dit kørekort</h3>
          <p>
            Hvis du kan få dit kørekort finansieret af dine forældre, fx fordi du ikke er
            begyndt at ryge, så slipper du helt uden om at låne til kørekortet. Hvis dine
            forældre ikke kan finansiere dit kørekort 100%, får mange unge også et
            tilskud, så man ikke behøver at låne til kørekortet i samme udstrækning som
            ellers. Dette falder ofte sammen med en 18-års fødselsdag.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-alle-slags-koerekort">Lån til alle slags kørekort</h2>
          <p>
            Hvis dit behov ikke er at låne til et normalt korekort (kategori B), så kan vi
            også hjælpe dig. Uanset om du mangler kørekort til et stort påhængskøretøj, en
            bus, lastbil,
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan/scooter"
              rel="noopener noreferrer"
              target="_blank"
              >knallert / scooter</a
            >
            eller
            <a
              href="https://www.saverium.dk/laan-penge/billaan/motorcykel"
              rel="noopener noreferrer"
              target="_blank"
              >motorcycle</a
            >, så hjælper vi dig gerne med at finde lånet til dit kørekort.
          </p>
          <p>
            Hvis du skal benytte lånet til kørekortet til erhvervskørsel, og du har en
            arbejdsgiver, som får gavn af dette, så kan din arbejdsgiver evt.
            <a
              href="https://www.saverium.dk/laan-penge/erhvervslaan"
              rel="noopener noreferrer"
              target="_blank"
              >ansøge om et erhvervslån</a
            ><strong> </strong>i stedet for.
          </p>
        </div>

        <div class="text-container">
          <h2 id="find-den-rette-bil">Find den rette bil</h2>
          <p>
            Når du er færdig med at tage kørekortet, så har du måske brug for en bil. Hvis
            du ønsker at finde et
            <a
              href="https://www.saverium.dk/laan-penge/billaan"
              rel="noopener noreferrer"
              target="_blank"
              >billigt billån</a
            >, så kan vi også hjælpe dig. Her ansøger du blot om et større lån, end lånet
            til dit kørekort, med mindre du har fundet et godt tilbud på en brugt bil.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
