<template>
  <div class="row article">
    <h1 class="center-title">
      Pożyczki online dla zadłużonych – czy można ubiegać się o kredyt?
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >Portfele Polaków mocno odczuły koronawirusowy kryzys. Lockdown z wiosny
              2020 roku i kolejne ograniczenia sanitarne wprowadzone jesienią bieżącego
              roku spowodowały, że nasze zadłużenie wzrosło. Jak pokazał raport InfoDług:
              Indeks Zaległych Płatności Polaków z lipca 2020 roku tworzony przez Biuro
              Informacji Kredytowej, nieopłacone rachunki i raty kredytów w kwietniu i
              maju wzrosły o ponad 1,2 mld zł. Od marca do końca maja przybyło 30,5 tys.
              nowych, niesolidnych dłużników. Na koniec maja zaległości miało ponad 2,86
              mln Polaków. Czy takie osoby mają szansę zaciągnąć kredyt lub pożyczkę?</i
            >
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/thinking-man-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/thinking-man.jpg"
            alt="Pożyczki chwilówki online – kredyty chwilówki przez internet"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
          <h2 id="1">
            {{ contents[0].title }}
          </h2>
          <p>
            W ofertach firm pozabankowych można znaleźć propozycje pożyczek dla
            zadłużonych. Osoby mające problem z płynnością finansową nie mogą liczyć na
            uzyskanie kredytu w bankach, ale mogą postarać się o pożyczkę bez BIK-u w
            firmie pozabankowej. Z racji pozostawania poza sektorem bankowym takie firmy
            nie mają obowiązku sprawdzania swojego klienta w bazach Biura Informacji
            Kredytowej przed wydaniem decyzji o udzieleniu zobowiązania.
          </p>
          <p>
            Czym w istocie są szybkie kredyty dla zadłużonych? Błędem jest nazywanie ich
            kredytami. To oferty pozabankowe, a nie produkty stricte bankowe. Pożyczka dla
            zadłużonych jest alternatywą wobec oferty kredytowej banków i firm, które
            udzielają zobowiązań z weryfikacją baz BIK i baz dłużników. Przynajmniej z
            definicji taki produkt może zostać udzielany osobom, które mają długi
            wynikające z wcześniej zaciągniętych kredytów czy pożyczek niespłacanych
            regularnie lub długi z tytułu nieopłaconych rachunków czy np. świadczeń
            alimentacyjnych. Tacy klienci nie mają możliwości zaciągnięcia kredytu w
            banku, a i w firmach pozabankowych może być z tym problem, o ile nie udzielają
            one pożyczek dla zadłużonych bez BIK-u.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Obecnie produkty pożyczkowe dostępne są dla klientów, którzy aktualne
            zadłużenie spłacają terminowo, zgodnie z harmonogramem spłaty, choć w
            przeszłości mieli z tym problemy. Muszą oni liczyć się z tym, że pożyczkodawca
            sprawdzi, czy wnioskujący wpisany jest do rejestru biur informacji
            gospodarczej, a jeśli tak, to czy spłaca regularnie swoje zadłużenie. Jeśli
            dług istnieje, ale klient nie interesuje się nim i nie negocjuje warunków
            spłaty, nie otrzyma nawet chwilówki online przeznaczonej dla osób zadłużonych.
          </p>
          <p>
            Pożyczki dla zadłużonych często pozostają przysłowiową ostatnią deską ratunku
            dla klienta, który nie może liczyć na zewnętrzne finansowanie w banku, z uwagi
            na prowadzoną przez bank restrykcyjną politykę kredytową.
          </p>
          <p>
            W firmie pozabankowej, która deklaruje, że udziela szybkich pożyczek
            gotówkowych dla zadłużonych, zawsze przed przyznaniem takiego finansowania
            przeprowadza się weryfikację klienta. Sprawdzany jest on zwykle w rejestrach
            dłużników, takich jak:
          </p>
          <ul>
            <li>Biuro Informacji Gospodarczej InfoMonitor S.A.,</li>
            <li>Krajowy Rejestr Długów Biura Informacji Gospodarczej S.A. (KRD BIG),</li>
            <li>ERIF Biuro Informacji Gospodarczej S.A.,</li>
            <li>
              Krajowa Informacja Długów Telekomunikacyjnych Biuro Informacji Gospodarczej
              S.A. (KIDT BIG),
            </li>
            <li>Krajowe Biuro Informacji Gospodarczej S.A. (KBIG).</li>
          </ul>
          <p>
            Jeśli klient ma negatywny wpis w rejestrze dłużników, to może mieć problem z
            uzyskaniem pożyczki pozabankowej. Złożenie wniosku o zobowiązanie jest
            konieczne dla uruchomienia procesu weryfikacji potencjalnego klienta. Za
            każdym razem decyzja o udzieleniu pożyczki dla zadłużonych jest podejmowana
            przez pracowników firmy pozabankowej na podstawie indywidualnej oceny
            wiarygodności wnioskującego.
          </p>
          <p>
            Poza tym każdy klient, który chce zaciągnąć zobowiązanie online, czy będzie to
            pożyczka klasyczna czy dla zadłużonych, musi spełnić takie wymagania jak:
          </p>
          <ul>
            <li>Mieć ukończony odpowiedni wiek – co najmniej 18 lat.</li>
            <li>Posiadać pełną zdolność do czynności prawnych.</li>
            <li>Mieć aktywny numer telefonu w polskiej sieci.</li>
            <li>Mieć swój adres poczty mailowej.</li>
            <li>Mieć konto bankowe założone na własne nazwisko w polskim banku.</li>
            <li>
              Pozytywnie przejść proces weryfikacji tożsamości w wymagany przez firmę
              sposób.
            </li>
          </ul>
          <p>
            Niektóre marki pożyczkowe wymagają również złożenia oświadczenia o wysokości
            uzyskiwanych dochodów, wraz ze wskazaniem kontaktu do pracodawcy, celem
            zweryfikowania tej informacji, choć ma to miejsce rzadko.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Jeśli dana osoba potrzebuje zaciągnąć szybką pożyczkę na dowód dla
            zadłużonych, a czas nagli, wówczas powinna ona sięgnąć po zobowiązanie online.
            Pożyczki dla zadłużonych są dostępne bez wychodzenia z domu, co oznacza, że
            klient może o nie wnioskować z wykorzystaniem elektronicznych kanałów
            komunikacji. Taki produkt udzielany jest na zasadach właściwych dla wszystkich
            klientów danej firmy pożyczkowej. Jednak fakt, że wnioskujący ma zadłużenie,
            jest dla niego pewnym obciążeniem i musi liczyć się on z możliwością uzyskania
            odpowiedzi odmownej na złożony wniosek.
          </p>
          <p>
            By szybka pożyczka online dla zadłużonych została przyznana klientowi, musi on
            złożyć formalny wniosek wraz ze wszystkimi wymaganymi załącznikami, na
            podstawie których firma pożyczkowa zbada jego zdolność kredytową i oceni
            czynniki ryzyka dotyczącego udzielenia mu danej kwoty zobowiązania.
          </p>
          <p>
            Nieodłącznym elementem ubiegania się o pożyczki online dla zadłużonych jest
            weryfikacja tożsamości potencjalnego pożyczkobiorcy. Mamy w tym przypadku
            najczęściej do czynienia z pożyczkami na dowód, bez zaświadczeń, dlatego firma
            pozabankowa musi w jakiś sposób sprawdzić, czy osoba wpisana we wniosku jest
            na pewno tym, za kogo się podaje. Bardzo często wymagane są przelewy
            weryfikacyjne, które polegają na przelaniu symbolicznej kwoty 1 zł lub 1 gr z
            konta zarejestrowanego na klienta na rachunek pożyczkodawcy. Jeśli dane na
            przelewie i na wniosku są ze sobą zgodne, weryfikacja jest poprawna.
          </p>
          <p>
            W niektórych firmach istnieje też możliwość zaciągnięcia pożyczki dla
            zadłużonych z weryfikacją za pośrednictwem specjalnych aplikacji, takich jak
            GroszConnect czy Kontomatik. Nie trzeba w ich przypadku przelewać
            jakiejkolwiek kwoty na rachunek pożyczkodawcy, ale klient musi za
            pośrednictwem zaakceptowanej przez firmę aplikacji zalogować się do swojej
            bankowości elektronicznej, przy użyciu indywidualnie nadanego loginu i hasła.
            W ten sposób pożyczkodawca uzyska dostęp do konta klienta w banku i będzie
            mógł sprawdzić nie tylko jego tożsamość, ale również historię rachunku,
            upewniając się, czy stać go na zaciągnięcie zobowiązania.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Dla osoby zadłużonej pożyczka bez zaświadczeń może być jedynym sposobem na
            sfinansowanie nagłych wydatków. Osoby zadłużone muszą regulować na bieżąco
            swoje zobowiązania wobec firmy windykacyjnej czy windykacyjnych działów innych
            instytucji, by mieć szansę na pomocną pożyczkę, dzięki której będą mogli np.
            spłacić swoje zaległości, unikając odcięcia mediów czy nawet eksmisji z tytułu
            braku opłacania czynszu.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            Możliwość skorzystania w danej firmie z pożyczki dla zadłużonych, nie oznacza
            to, że w ich przypadku proces weryfikacji wiarygodności kredytowej nie ma
            miejsca. Jeśli jednak pożyczkodawca deklaruje, że udzieli zobowiązania bez
            zaświadczeń z baz BIK, to wówczas nie będzie sięgała po indywidualny raport na
            temat potencjalnego pożyczkobiorcy z Biura Informacji Kredytowej. Nie oznacza
            to automatycznie, że klient nie zostanie sprawdzony w bazach biur informacji
            gospodarczej, które wykażą jego aktualne zadłużenie.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            Chcąc zaciągnąć pożyczkę dedykowaną dla osób zadłużonych, trzeba pamiętać, że
            jest ona przeznaczona dla dłużników, którzy przystąpili do spłaty długów. Nie
            mogą oni ignorować konieczności spłaty zadłużenia wynikającego z różnych
            tytułów, ponieważ stracą szansę na pożyczkę, nawet taką dla zadłużonych.
            Pożyczki na dowód mogą być im przyznawane, jeśli dopełnią formalności
            związanych z wnioskowaniem o pożyczkę. Ponadto klienci muszą zweryfikować
            swoją tożsamość w wymagany przez danę firmę pożyczkową sposób.
          </p>
          <p>
            Niewywiązanie się z jednego z warunków spowoduje, że spotka ich odmowa
            udzielenia finansowania. Jeśli pożyczka dla zadłużonych zostanie zaakceptowana
            w trybie online, najpewniej wpłynie ona na konto klienta wskazane w procesie
            wnioskowania i będzie można nią dowolnie rozporządzać. Obowiązkiem
            pożyczkobiorcy jest spłata zobowiązania zgodnie z harmonogramem – w ratach lub
            jednorazowo na koniec okresu kredytowania.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'Czym są pożyczki dla zadłużonych?',
          link: '#1',
        },
        {
          title: 'Jakie są warunki otrzymania pożyczki, będąc zadłużonym?',
          link: '#2',
        },
        {
          title: 'Jak otrzymać szybką pożyczkę online, będąc zadłużonym?',
          link: '#3',
        },
        {
          title: 'Pomocna pożyczka dla zadłużonych',
          link: '#4',
        },
        {
          title: 'Pożyczki dla zadłużonych bez BIK-u',
          link: '#5',
        },
        {
          title: 'Pożyczka dla zadłużonych – o czym należy pamiętać?',
          link: '#6',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped></style>
