<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lånebeløb</h1>
      <div class="top-text text-container">
        <p>
          Uanset hvilket lånebeløb du leder efter, kan vi hjælpe dig. Hvis du udfylder
          vores ansøgningsformular her på siden, indenter vi med det samme tilbud fra en
          lang række banker og långivere til dig, så du kan vælge det bedste og billigste
          tilbud.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#find-det-rette-laanebeloeb-via-vores-platform"
                    >Find det rette lånebeløb via vores platform</a
                  >
                </li>
                <li><a href="#smaa-laanebeloeb">Små lånebeløb</a></li>
                <li><a href="#mellemstore-laanebeloeb">Mellemstore lånebeløb</a></li>
                <li><a href="#store-laanebeloeb">Store lånebeløb</a></li>
                <li><a href="#tilpasset-til-dit-behov">Tilpasset til dit behov</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="find-det-rette-laanebeloeb-via-vores-platform">
            Find det rette lånebeløb via vores platform
          </h2>
          <p>
            Leder du efter det helt rette lånebeløb? Det finder du her. Vi kan hjælpe dig
            med at finde dit maksimale lånebeløb eller lige præcis det lånebeløb, som du
            står og mangler.
          </p>
          <p>
            Vi hjælper dig med at sammenligne lånetilbud fra en lang række banker og
            långiver, så du på få minutter og med én enkelt online ansøgning, kan få et
            overblik over dine lånemuligheder, og vælge dét lån, som passer til dig og dit
            behov. Benyt vores låneberegner nu, og find ud af, hvilke banker der kan
            tilbyde dig et passende lånebeløb til en fornuftig rente.
          </p>
        </div>

        <div class="text-container">
          <h2 id="smaa-laanebeloeb">Små lånebeløb</h2>
          <p>
            De små lånebeløb er dem, som i stor stil tidligere blev dækket af
            <strong>SMS-lånene</strong> og
            <a
              href="https://saverium.dk/laan-penge/kvilaan"
              rel="noopener noreferrer"
              target="_blank"
              >kviklånene</a
            >. Disse lån eksisterer i dag ikke på samme måde som tidligere grundet
            teknologisk udvikling og regulering på lånemarkedet.
          </p>
          <p>
            Hvis du
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låner penge</a
            >
            i denne kategori, har du med stor sandsynlighed et akut økonomisk problem. Det
            kan være, at en uforudset regning er dumpet ind af brevsprækken, eller at en
            esentiel genstand, som får din hverdag til at hænge sammen, er blevet stjålet,
            mistet eller gået i stykker og behøver at blive erstattet her og nu.
          </p>
          <p>
            Nedenfor finder du de typiske små lånebeløb, som vores brugere ansøger om:
          </p>
          <ul>
            <li>
              <a
                href="https://saverium.dk/laan-penge/beloeb/3000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 3.000 kr.</a
              >
            </li>
            <li><strong>lån 4.000 kr.</strong></li>
            <li>
              <a
                href="https://saverium.dk/laan-penge/beloeb/5000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 5.000 kr.</a
              >
            </li>
            <li>
              <a
                href="https://saverium.dk/laan-penge/beloeb/10000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 10.000 kr.</a
              >
            </li>
            <li>
              <a
                href="https://saverium.dk/laan-penge/beloeb/15000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 15.000 kr.</a
              >
            </li>
            <li>
              <a
                href="https://saverium.dk/laan-penge/beloeb/20000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 20.000 kr.</a
              >
            </li>
          </ul>
        </div>

        <div class="text-container">
          <h2 id="mellemstore-laanebeloeb">Mellemstore lånebeløb</h2>
          <p>
            De mellemstore lånebeløb er dem, som de fleste betegner som
            <a
              href="https://saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >. De dækker sjældent pludseligt opståede problemer, men derimod realisering
            af drømme, som i øjeblikket er uden for ens økonomiske rækkevidde. Drømmene
            kan variere meget i omfang, og derfor ser du også et stort spænd i
            lånebeløbene i denne kategori.
          </p>
          <p>
            Det er sandsynligt, at du allerede har lagt penge til side til at dække en del
            af købet, hvis du søger indenfor denne kategori, da din drøm nok ikke er
            opstået natten over. Hvis du har haft det samme ønske i lang tid og mulighed
            for at lægge mange penge til side, har du måske kun behov for et af de små
            lånebeløb. Det kan også være at du er i en helt anden situation, hvor du først
            lige blevet opmærksom på din drøm, og har dermed ikke haft tid eller økonomi
            til at lægge meget til side endnu. Vi kan hjælpe dig, uanset hvor langt du er
            i processen.
          </p>
          <p>
            Nedenfor finder du de typiske lånebeløb, som vi ser, at vores brugere ansøger
            om i kategorien for mellemstore lånebeløb. Disse lånebeløb kommer ofte i
            intervaller af 5.000 kr. eller 10.000 kr.:
          </p>
          <ul>
            <li>
              <a
                href="https://saverium.dk/laan-penge/beloeb/25000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 25.000 kr.</a
              >
            </li>
            <li>
              <a
                href="https://saverium.dk/laan-penge/beloeb/30000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 30.000 kr.</a
              >
            </li>
            <li><strong>lån 35.000 kr.</strong></li>
            <li>
              <a
                href="https://saverium.dk/laan-penge/beloeb/20000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 40.000 kr.</a
              >
            </li>
            <li><strong>lån 45.000 kr.</strong></li>
            <li>
              <a
                href="https://saverium.dk/laan-penge/beloeb/50000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 50.000 kr.</a
              >
            </li>
            <li><strong>lån 60.000 kr.</strong></li>
            <li><strong>lån 70.000 kr.</strong></li>
            <li>
              <a
                href="http://https//saverium.dk/laan-penge/beloeb/75000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 75.000 kr.</a
              >
            </li>
            <li><strong>lån 80.000 kr.</strong></li>
            <li><strong>lån 90.000 kr.</strong></li>
          </ul>
        </div>

        <div class="text-container">
          <h2 id="store-laanebeloeb">Store lånebeløb</h2>
          <p>
            De store lånebeløb er sjovt nok til dem, som har behov for et stort lånebeløb.
            Inden for denne kategori er behovet typisk
            <a
              href="https://saverium.dk/laan-penge/samlelaan"
              rel="noopener noreferrer"
              target="_blank"
              >samlelån</a
            >,
            <a
              href="https://saverium.dk/laan-penge/billaan"
              rel="noopener noreferrer"
              target="_blank"
              >lån til bil</a
            >
            eller et
            <a
              href="https://saverium.dk/laan-penge/erhvervslaan"
              rel="noopener noreferrer"
              target="_blank"
              >erhvervslån</a
            >, da disse poster ikke altid, men ofte, kan løbe op i flere hundredetusinde
            kroner.
          </p>
          <p>
            Førstnævnte eksempel er lidt speciel, da der netto ikke stiftes ny gæld, og
            man ikke står med et brændende ønske om at købe en genstand, service eller
            oplevelse. Derimod har man blot et ønske om at spare penge, i modsætning til
            næsten alle andre tidspunkter, hvor man kunne finde på at optage lån.
          </p>
          <p>
            Herunder finder du de typiske lånebeløb, som vi kan se, at vores brugere
            ansøger om inden for denne kategori. Lånebeløbene kommer desuden ofte i
            intervaller af 50.000 kr.:
          </p>
          <ul>
            <li>
              <a
                href="https://saverium.dk/laan-penge/beloeb/100000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 100.000 kr.</a
              >
            </li>
            <li>
              <a
                href="https://saverium.dk/laan-penge/beloeb/150000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 150.000 kr.</a
              >
            </li>
            <li>
              <a
                href="https://saverium.dk/laan-penge/beloeb/200000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 200.000 kr.</a
              >
            </li>
            <li><strong>lån 250.000 kr.</strong></li>
            <li>
              <a
                href="https://saverium.dk/laan-penge/beloeb/300000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 300.000 kr.</a
              >
            </li>
            <li>
              <a
                href="https://saverium.dk/laan-penge/beloeb/400000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 400.000 kr.</a
              >
            </li>
            <li>
              <a
                href="https://saverium.dk/laan-penge/beloeb/500000-kr"
                rel="noopener noreferrer"
                target="_blank"
                >lån 500.000 kr.</a
              >
            </li>
          </ul>
        </div>

        <div class="text-container">
          <h2 id="tilpasset-til-dit-behov">Tilpasset til dit behov</h2>
          <p>
            Som du kan se ovenfor, er det din opsparing, og hvad du låner til, som
            definerer det lånebeløb, som du har behov for. Når du har overblik over din
            opsparing, ansøger du blot om det resterende lånebeløb, hvilket du kan gøre
            her på siden, hvis du ønsker et nemt og hurtigt overblik over dine muligheder
            på lånemarkedet.
          </p>
          <p>
            Bankerne vil ud fra din ansøgning og din nuværende økonomi vurdere, om du kan
            få et lånebeløb, som helt eller delvist dækker dit behov. Dernæst vil de
            fastsætte en rente, ud fra hvor sandsynligt det er, at du kan tilbagebetale
            lånet. Denne risikovurdering er i stor stil baseret på din nuværende økonomi,
            samt hvor risikabel de mener at din investering er. Hvis du låner til en en
            oplevelse, er pengene væk så snart oplevelsen er betalt, hvis du derimod låner
            til en genstand, som långiveren kan tage pant i, så vil sikkerheden være meget
            større for banken.
          </p>
          <p>
            Det kan lyde omstændigt, men de fleste af ovenstående elementer i
            låneprocessen, har du nok allerede afklaret. Alt omkring din økonomiske
            situation er information, som banken enten kan trække fra din netbank, via et
            <a
              href="https://saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >NemID / MitID</a
            >
            login, eller via et simpelt spørgeskema. Hele risikovurderingen kræver ikke
            noget af dig, da det er bankens ansvar. Det er også i stor stil banken, som
            løber en risiko, ved at tildele dig lånebeløbet, og ikke omvendt.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },

  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
