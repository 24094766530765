<template>
  <div class="card-body text-left">
    <CardInformation :offer="offer" :currency="currency" />
    <div class="mb-3">
      <p class="small mb-2">
        {{ businessDescription }}
      </p>
    </div>
  </div>
</template>
<script>
import CardInformation from '@/components/account_view_components/CardInformation.vue'

export default {
  props: { offer: Object, currency: String, businessDescription: String },
  data() {
    return { toggle: false }
  },
  components: {
    CardInformation,
  },
}
</script>
<style lang="scss" scoped>
.btn-block {
  @media (max-width: 995px) {
    width: 100%;
    margin: auto;
  }
}

.btn-row {
  display: flex;
  height: 65px;
  justify-content: flex-end;
}
.small {
  text-align: center;
  max-width: 100%;
  @media (max-width: 480px) {
    text-align: left;
  }
}
</style>
