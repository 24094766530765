<template>
  <div>
    <div class="row" :key="i" v-for="(option, i) in offer.options">
      <div class="col-lg-6 col-12 main-info">
        <span>{{ $t('loan_amount') }}</span>
        <p class="loan-offer-big-text mt-0 mb-2">
          {{ $n(option.approved_amount, 'currency') }}
          <small>{{ currency }}</small>
        </p>

        {{ $t('repayment_period') }}
        <p class="loan-offer-big-text">{{ option.loan_period }} {{ $t('months') }}</p>
        <template v-if="offer.company.name === 'Raiffeisen' && option.apr > 0">
          <span> {{ $t('apr') }}</span>
          <p class="loan-offer-big-text">{{ $n(option.apr, 'decimal') }} %</p>
        </template>
        <div v-if="option.monthly_payment > 0 && brand !== 'saverium_pl'">
          <span>{{ $t('currency_per_month') }}</span>
          <p class="loan-offer-big-text">
            {{ $n(option.monthly_payment, 'currency') }} <small>{{ currency }}</small>
          </p>
        </div>
      </div>
      <!-- Leaving this here to wait when SME loans go back live. -->
      <div class="col-12 col-lg-6" v-if="offer.offer_type !== 2">
        <div class="col-12 row" v-if="brand !== 'saverium_pl'">
          <div class="col-12 col-sm-6 extra-info" v-if="currency === 'kr.'">
            <div v-if="option.opening_fee > 0">
              <span>{{ $t('opening_fee') }}</span>
              <p class="loan-offer-big-text">
                {{ $n(option.opening_fee) }}
                {{ currency }}
              </p>
            </div>
            <div
              v-if="
                (option.approved_amount > 0 && option.monthly_payment > 0) ||
                option.total_payment > 0
              "
            >
              <span>{{ $t('total_expenses') }}</span>
              <p class="loan-offer-big-text">
                {{
                  $n(
                    option.total_payment ||
                      option.fixed_installment ||
                      parseInt(option.loan_period) * parseInt(option.monthly_payment) +
                        parseInt(option.opening_fee),
                    'decimal'
                  )
                }}
                {{ currency }}
              </p>
            </div>
            <div v-if="option.monthly_fee > 0">
              <span>{{ $t('monthly_fee') }}</span>
              <p class="loan-offer-big-text">
                {{ $n(option.monthly_fee) }}
                {{ currency }}
              </p>
            </div>
          </div>

          <div class="col-12 col-sm-8 col-md-6 extra-info">
            <div v-if="option.nominal_interest > 0">
              <span> {{ $t('nominal_interest') }}</span>
              <p>{{ $n(option.nominal_interest, 'decimal') }} %</p>
            </div>
            <div v-if="option.apr > 0">
              <span> {{ $t('apr') }}</span>
              <p>{{ $n(option.apr, 'decimal') }} %</p>
            </div>
          </div>
        </div>
      </div>
      <div class="reminder-text" v-if="offer.status === 5 && brand !== 'saverium_pl'">
        <p>{{ $t('you_have_to_remember_accept_the_offer') }}</p>
      </div>
      <div class="text-cRenter btn-row my-2 col-12">
        <template v-if="action === 'accept'">
          <div class="spinner-wrapper" v-if="loading">
            <i class="fa fa-spinner fa-spin"></i>
          </div>

          <button
            v-else
            class="btn btn-block mb-4 btn-primary accept-offer"
            @click="acceptOffer(option)"
          >
            {{ $t('accept_loan_offer') }}
          </button>
        </template>
        <template v-if="action === 'eskat'">
          <div class="col-12">
            <p>{{ $t('login_with_eskat_to_get_offer') }}:</p>
            <button class="btn btn-block mb-4 btn-primary accept-offer float-right">
              <a style="color: white" :href="offer.action_required.url">{{
                $t('eskat_login')
              }}</a>
            </button>
          </div>
        </template>
        <template v-if="status === 'email_and_phone_contact'">
          <div class="col-12">
            <p>{{ $t('thank_you_for_selecting_this_product') }} {{ productName }}!</p>
            <p>{{ $t('you_will_be_contacted_by_email_and_phone') }}</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    brand() {
      return this.$root.brand
    },
  },

  props: ['offer', 'action', 'currency', 'productName', 'status'],
  methods: {
    acceptOffer(option) {
      const uuid = option.uuid
      const esign = option.esign
      this.loading = true
      if (esign) {
        window.open(esign, '_blank')
        this.$store.dispatch('acceptOffer', {
          uuid,
        })
        this.loading = false
      } else {
        this.$store
          .dispatch('acceptOffer', {
            uuid,
          })
          .then(response => {
            this.loading = false
            if (response.redirect) {
              window.open(response.redirect, '_blank')
            }
          })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.btn-block {
  max-height: 65px;
  @media (max-width: 995px) {
    width: 100%;
    margin: auto;
  }
}
.btn-row {
  display: flex;
  justify-content: flex-end;
}
.btn-primary {
  a {
    color: white;
  }
}
.reminder-text {
  text-align: center;
  width: 100%;
  font-size: 1rem;
}
</style>
