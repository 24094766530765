<template>
  <div>
    <div class="header-image-container image-fluid">
      <div v-if="false" class="form-background column"></div>
    </div>
    <div class="container-fluid column">
      <loan-application-header />
      <CompanyLoanForm
        :apiUrl="api"
        tosUrl="/kayttoehdot"
        buttonVariant="loan"
        :initialStep="1"
        :initialForm="initialFormData"
        :errors="errors"
        @update="updateForm"
        @nextStep="nextStep"
        @submit="sendForm"
      />
    </div>
  </div>
</template>

<script>
import LoanApplicationHeader from '../LoanApplicationHeader'
import CompanyLoanForm from '@lvsbrokers/vue-company-loan-form'
import settings from '@/settings/settings'

export default {
  components: {
    CompanyLoanForm,
    LoanApplicationHeader,
  },
  data() {
    return {
      api: settings.API,
    }
  },
  methods: {
    sendForm() {
      this.$store.dispatch('sendApplication')
    },
    nextStep() {
      // pass
    },
    updateForm(form) {
      this.$store.commit('updateApplication', form)
    },
  },
  computed: {
    initialFormData() {
      return this.$store.getters.getInitialForm
    },
    errors() {
      let errors = []
      for (const error in this.$store.state.application.errors) {
        let val = error
        switch (error) {
          case 'amount':
            val = 'loanAmount'
            break
          case 'time_term':
            val = 'loanTime'
            break
          case 'business_name':
            val = 'companyName'
            break
          case 'business_id':
            val = 'businessID'
            break
          case 'street':
            val = 'address'
            break
          case 'zip_code':
            val = 'postalCode'
            break
          case 'yearly_turnover':
            val = 'turnover'
            break
          case 'year_founded':
            val = 'yearFounded'
            break
          case 'first_name':
            val = 'firstName'
            break
          case 'last_name':
            val = 'lastName'
            break
          case 'tos_accepted':
            val = 'tos'
            break
          case 'selling_bill':
            val = 'sellingBill'
            break
        }
        errors.push(val)
      }
      return errors
    },
  },
}
</script>

<style lang="scss">
.column {
  max-width: 830px;
  margin-left: auto;
  margin-right: auto;
}

.image-container {
  height: 40vw;
  max-height: 600px;
  width: 100%;
  overflow: hidden;
}

.header-image-container {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  bottom: -50px;
  background-image: url('/static/img/lainaayritykselle_fi/Main-background.jpg');
  background-repeat: no-repeat;
  background-position: 60% 0%;
  background-size: cover;
  z-index: -1;
  @media screen and (max-width: 990px) {
    bottom: 332px;
    background: none;
    background-color: #ffffff;
  }
  @media screen and (max-width: 820px) {
    bottom: 334px;
  }
  @media screen and (max-width: 620px) {
    bottom: 336px;
  }
  @media screen and (max-width: 560px) {
    bottom: 338px;
  }
  @media screen and (max-width: 458px) {
    bottom: 362px;
  }
  @media screen and (max-width: 344px) {
    bottom: 386px;
  }
  @media screen and (max-width: 330px) {
    bottom: 410px;
  }
  @media screen and (max-width: 328px) {
    bottom: 434px;
  }
}

.form-background {
  background-color: #333333;
  z-index: 0;
  height: calc(100% - 40px);
  margin-top: 20px;
  margin-right: 100px;
  opacity: 0.7;
  border-radius: 10px;
  @media screen and (max-width: 990px) {
    margin-top: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    opacity: 0.6;
    border-radius: 0px;
  }
}
.btn-loan {
  background-color: #2a76bc;
  color: #f2f2f2;
  font-weight: bold;
  font-size: 20px;
}
.btn-loan:hover {
  background-color: #2f8ade;
  color: #f2f2f2;
}
.btn-loan:active {
  background-color: #25629a;
  color: #f2f2f2;
}
</style>

<style lang="sass" scoped>
.blue-font
  color: #41a4ff
  font-size: 30px
a
  color: #41a4ff

.column
  margin-right: 100px
  max-width: 600px
  @media screen and (max-width: 990px)
    margin-top: 0
    height: 100%
    width: 100%
    margin-right: auto
</style>
