<template lang="html">
  <div class="mb-5" ref="header">
    <h2 v-if="fullView" class="mb-3 text-center">
      {{ step }}.
      {{ $t('housing_and_employment') }}
    </h2>
    <h1 v-else class="mb-3 text-center">
      {{ $t('housing_and_employment') }}
    </h1>

    <div class="row align-items-end">
      <div class="form-group col-sm-6">
        <form-input storekey="street" type="text" id="address">
          {{ $t('street_address') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input
          storekey="postalCode"
          type="regex"
          regex="^(?:[1-24-9]\d{3}|3[0-8]\d{2})$"
          inputmode="numeric"
          id="postalCode"
        >
          {{ $t('postal_code') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input storekey="city" type="text" id="city">
          {{ $t('city') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input
          :items="dwellingOptions"
          storekey="dwelling"
          type="dropdown"
          id="dwelling"
        >
          {{ $t('housing_situation') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6">
        <form-input
          type="month"
          storekey="dwellingDate"
          id="dwellingdate"
          placeholder="fx. 01.2012"
        >
          {{ $t('living_at_current_address_since') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input
          :items="civilstatusOptions"
          storekey="civilstatus"
          type="dropdown"
          id="civilstatus"
        >
          {{ $t('civilstatus') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input
          :items="educationOptions"
          storekey="education"
          type="dropdown"
          id="education"
        >
          {{ $t('education') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6" @change="updateOccupation">
        <form-input
          :items="occupationOptions"
          storekey="occupation"
          type="dropdown"
          id="occupation"
        >
          {{ $t('occupation') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input
          type="month"
          storekey="employedSince"
          id="employedSince"
          placeholder="fx. 01.2012"
        >
          {{ $t(dynamicOccupationTitle) }}
        </form-input>
      </div>
      <div class="form-group col-sm-6" v-if="this.occupation === '4'">
        <form-input
          storekey="businessId"
          type="identity"
          identifier="cvr"
          inputmode="numeric"
          id="business_id"
        >
          {{ $t('business_id') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <label for="unemploymentFund">
          {{ $t('i_am_member_of_unemployment_fund') }}</label
        >
        <div
          class="radio-toolbar"
          name="unemploymentFund"
          id="unemploymentFund"
          @change="updateInput"
          :value="value"
        >
          <input
            type="radio"
            id="fund1"
            name="unemploymentFund"
            :value="true"
            :checked="this.unemploymentFund === true"
          />
          <label class="radiolabel" for="fund1">{{ $t('yes') }}</label>

          <input
            type="radio"
            id="fund2"
            name="unemploymentFund"
            :value="false"
            :checked="this.unemploymentFund === false"
          />
          <label class="radiolabel" for="fund2">{{ $t('no') }}</label>
        </div>
        <form-input
          type="month"
          storekey="memberOfUnemploymentFundSince"
          id="memberOfUnemploymentFundSince"
          inputmode="numeric"
          :class="{ hidden: this.unemploymentFund === false }"
          placeholder="fx. 09.2008"
        >
          {{ $t('member_since') }}
        </form-input>
      </div>

      <div class="form-group col-sm-12">
        <div class="dependants" id="children">
          <label for="children">{{ $t('number_of_children') }}</label>
          <div class="radio-toolbar" @change="updateInput" :value="value" role="group">
            <input
              type="radio"
              id="child0"
              name="dependants"
              value="0"
              :checked="this.dependants == 0"
              @keyup.enter="() => setDependantsArray(0)"
            />
            <label class="radiolabel" for="child0" @click="() => setDependantsArray(0)"
              >0</label
            >
            <input
              type="radio"
              id="child1"
              name="dependants"
              value="1"
              :checked="this.dependantAges.length == 1"
              @keyup.enter="() => setDependantsArray(1)"
            />
            <label class="radiolabel" for="child1" @click="() => setDependantsArray(1)"
              >1</label
            >
            <input
              type="radio"
              id="child2"
              name="dependants"
              value="2"
              :checked="this.dependantAges.length == 2"
              @keyup.enter="() => setDependantsArray(2)"
            />
            <label class="radiolabel" for="child2" @click="() => setDependantsArray(2)"
              >2</label
            >
            <input
              type="radio"
              id="child3"
              name="dependants"
              value="3"
              :checked="this.dependantAges.length == 3"
              @keyup.enter="() => setDependantsArray(3)"
            />
            <label class="radiolabel" for="child3" @click="() => setDependantsArray(3)"
              >3</label
            >
            <input
              type="radio"
              id="child4"
              name="dependants"
              value="4"
              :checked="this.dependantAges.length == 4 || this.dependantAges.length > 4"
              @keyup.enter="() => setDependantsArray(4)"
            />
            <label class="radiolabel" for="child4" @click="() => setDependantsArray(4)"
              >4+</label
            >
          </div>
        </div>

        <div
          class="form-group col-sm-12"
          :key="i"
          v-for="(depAge, i) in dependantAges"
          storekey="kidsAges"
        >
          <label for="agesDependants">{{ $t('age_of_dependant') }} {{ i + 1 }}</label>
          <span v-if="errorChild" class="invalid-feedback" style="display: block">
            {{ $t('required') }}
          </span>
          <div
            class="radio-toolbar"
            @change="updateInput"
            :value="value"
            role="group"
            :id="`agesDependants-${i}`"
          >
            <input
              type="radio"
              :id="`${i}0`"
              :name="`agesDependants-${i}`"
              value="0-2"
              :checked="depAge == 2"
              @keyup.enter="() => setDependantAge(i, 2)"
            />
            <label
              class="radiolabel ages"
              :for="`${i}0`"
              @click="() => setDependantAge(i, 2)"
              >0-2 {{ $t('year') }}</label
            >
            <input
              type="radio"
              :id="`${i}1`"
              :name="`agesDependants-${i}`"
              value="3-6"
              :checked="depAge == 6"
              @keyup.enter="() => setDependantAge(i, 6)"
            />
            <label
              class="radiolabel ages"
              :for="`${i}1`"
              @click="() => setDependantAge(i, 6)"
              >3-6 {{ $t('year') }}</label
            >
            <input
              type="radio"
              :id="`${i}2`"
              :name="`agesDependants-${i}`"
              value="7-10"
              :checked="depAge == 10"
              @keyup.enter="() => setDependantAge(i, 10)"
            />
            <label
              class="radiolabel ages"
              :for="`${i}2`"
              @click="() => setDependantAge(i, 10)"
              >7-10 {{ $t('year') }}</label
            >
            <input
              type="radio"
              :id="`${i}3`"
              :name="`agesDependants-${i}`"
              value="11-14"
              :checked="depAge == 14"
              @keyup.enter="() => setDependantAge(i, 14)"
            />
            <label
              class="radiolabel ages"
              :for="`${i}3`"
              @click="() => setDependantAge(i, 14)"
              >11-14 {{ $t('year') }}</label
            >
            <input
              type="radio"
              :id="`${i}4`"
              :name="`agesDependants-${i}`"
              :checked="depAge == 17"
              @keyup.enter="() => setDependantAge(i, 17)"
            />
            <label
              class="radiolabel ages"
              :for="`${i}4`"
              @click="() => setDependantAge(i, 17)"
              >15-17 {{ $t('year') }}</label
            >
          </div>
        </div>
        <div
          class="form-group col-sm-12 add-children"
          v-if="this.dependantAges.length >= 4"
          @click="() => setDependantsArray(this.dependantAges.length + 1)"
        >
          <label for="plus" style="cursor: pointer">
            <i class="fal fa-plus" name="plus"></i>{{ $t('add_more_children') }}
          </label>
        </div>
      </div>

      <div class="form-group col-sm-12">
        <div class="cars" id="cars">
          <label for="car">{{ $t('number_of_cars') }}</label>
          <div class="radio-toolbar" :value="value" role="group">
            <input
              type="radio"
              id="car0"
              name="cars"
              value="0"
              :checked="this.cars.length == 0"
              @keyup.enter="() => setCarsArray(0)"
            />
            <label class="radiolabel" for="car0" @click="() => setCarsArray(0)">0</label>
            <input
              type="radio"
              id="car1"
              name="cars"
              value="1"
              :checked="this.cars.length == 1"
              @keyup.enter="() => setCarsArray(1)"
            />
            <label class="radiolabel" for="car1" @click="() => setCarsArray(1)">1</label>
            <input
              type="radio"
              id="car2"
              name="cars"
              value="2"
              :checked="this.cars.length == 2"
              @keyup.enter="() => setCarsArray(2)"
            />
            <label class="radiolabel" for="car2" @click="() => setCarsArray(2)">2</label>
            <input
              type="radio"
              id="car3"
              name="cars"
              value="3"
              :checked="this.cars.length == 3"
              @keyup.enter="() => setCarsArray(3)"
            />
            <label class="radiolabel" for="car3" @click="() => setCarsArray(3)">3</label>
            <input
              type="radio"
              id="car4"
              name="cars"
              value="4"
              :checked="this.cars.length >= 4"
              @keyup.enter="() => setCarsArray(4)"
            />
            <label class="radiolabel" for="car4" @click="() => setCarsArray(4)">4+</label>
          </div>
        </div>
        <div
          class="form-group col-sm-12"
          :key="j"
          v-for="(car, j) in cars"
          storekey="carTypes"
        >
          <label for="typesCars"
            >{{ $t('type_of_car') }} {{ j + 1 }}
            <span v-if="errorCar" class="invalid-feedback" style="display: block">
              {{ $t('required') }}
            </span></label
          >

          <div
            class="radio-toolbar"
            @change="updateInput"
            :value="value"
            role="group"
            :id="`typesCars-${j}`"
          >
            <input
              type="radio"
              :id="`${j}10`"
              :name="`typesCars-${j}`"
              value="Ejet"
              :checked="car.type == 'Ejet'"
              @keyup.enter="() => setCarType(j, 'Ejet')"
            />
            <label
              class="radiolabel ages"
              :for="`${j}10`"
              @click="() => setCarType(j, 'Ejet')"
              >{{ $t('owned') }}</label
            >
            <input
              type="radio"
              :id="`${j}11`"
              :name="`typesCars-${j}`"
              value="Firmabil"
              :checked="car.type == 'Firmabil'"
              @keyup.enter="() => setCarType(j, 'Firmabil')"
            />
            <label
              class="radiolabel ages"
              :for="`${j}11`"
              @click="() => setCarType(j, 'Firmabil')"
              >{{ $t('company_car') }}</label
            >
            <input
              type="radio"
              :id="`${j}12`"
              :name="`typesCars-${j}`"
              value="Leaset"
              :checked="car.type == 'Leaset'"
              @keyup.enter="() => setCarType(j, 'Leaset')"
            />
            <label
              class="radiolabel ages"
              :for="`${j}12`"
              @click="() => setCarType(j, 'Leaset')"
              >{{ $t('leased') }}</label
            >
          </div>
        </div>
        <div
          class="form-group col-sm-12 add-children"
          v-if="this.cars.length >= 4"
          @click="() => setCarsArray(this.cars.length + 1)"
        >
          <label for="plus" style="cursor: pointer">
            <i class="fal fa-plus" name="plus"></i>{{ $t('add_more_cars') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import FormInput from '@/components/form/FormInput'
import { mapState } from 'vuex'
export default {
  props: ['fullView', 'step', 'value'],
  components: {
    FormInput,
  },
  data() {
    return {
      requiredFields: [
        'dwellingDate',
        'civilstatus',
        'address',
        'city',
        'postalCode',
        'occupation',
        'employedSince',
        'education',
      ],
      active: '',
      count: 0,
    }
  },
  computed: {
    ...mapState({
      occupationOptions: state => state.enums.occupationOptions,
      civilstatusOptions: state => state.enums.maritalStatuses,
      dwellingOptions: state => state.enums.dwellingOptions,
      stepErrors: state => state.application.stepErrors,
      dependants: state => state.application.dependants,
      dwellingType: state => state.application.dwelling,
      errorCar: state => state.application.carTypesError,
      errorChild: state => state.application.kidsAgesError,
      errors: state => state.application.errors,
      occupation: state => state.application.occupation,
      educationOptions: state => state.enums.educationOptions,
    }),
    unemploymentFund: {
      get() {
        return this.$store.state.application.unemploymentFund
      },
      set(unemploymentFund) {
        this.$store.state.application.unemploymentFund = unemploymentFund
      },
    },
    hasOwnHouse: {
      get() {
        return this.$store.state.application.hasOwnHouse
      },
      set(hasOwnHouse) {
        this.$store.state.application.hasOwnHouse = hasOwnHouse
      },
    },
    monthlyPropertyTax: {
      get() {
        return this.$store.state.application.monthlyPropertyTax
      },
      set(monthlyPropertyTax) {
        this.$store.state.application.monthlyPropertyTax = monthlyPropertyTax
      },
    },
    housingBenefit: {
      get() {
        return this.$store.state.application.housingBenefit
      },
      set(housingBenefit) {
        this.$store.state.application.housingBenefit = housingBenefit
      },
    },
    businessId: {
      get() {
        return this.$store.state.application.businessId
      },
      set(businessId) {
        this.$store.state.application.businessId = businessId
      },
    },

    dependantAges: {
      get() {
        return this.$store.state.application.dependantAges
      },
      set(dependantAges) {
        this.$store.state.application.dependants = dependantAges.length
        this.$store.state.application.dependantAges = dependantAges
      },
    },

    cars: {
      get() {
        return this.$store.state.application.cars
      },
      set(cars) {
        this.$store.state.application.cars = cars
      },
    },

    dynamicOccupationTitle() {
      const titleMapping = {
        5: 'pensioner_since',
        6: 'student_since',
        7: 'unemployed_since',
      }
      return titleMapping[this.occupation]
        ? titleMapping[this.occupation]
        : 'employed_since'
    },
  },
  watch: {
    stepErrors(newV) {
      if (this.fullView && newV[0] === parseInt(this.step, 10)) {
        this.$refs.header.scrollIntoView()
      }
    },
    dwellingType() {
      if (this.dwellingType === '2') {
        this.hasOwnHouse = true
      } else {
        this.hasOwnHouse = false
      }
      if (this.dwellingType === '11') {
        this.monthlyPropertyTax = true
      } else {
        this.monthlyPropertyTax = false
      }
      if (this.dwellingType === '14') {
        this.housingBenefit = true
      } else {
        this.housingBenefit = false
      }
    },
  },
  methods: {
    isValid(id) {
      return !Object.keys(this.errors).includes(id)
    },

    updateInput(value) {
      let thisValue = value.target.value
      if (value.srcElement.name === 'unemploymentFund') {
        thisValue = value.target._value
      }
      this.$store.commit('updateApplication', {
        key: value.target.name,
        value: thisValue,
      })
      this.$store.dispatch('updateOrCreateDraft')
    },

    setDependantsArray(n) {
      const currDependantAges = this.dependantAges.slice()

      let nextDependantAges = currDependantAges

      const nDiff = n - currDependantAges.length
      if (nDiff > 0) {
        const arr = new Array(nDiff)
        arr.fill(null)
        nextDependantAges.push(...arr)
      }
      if (nDiff < 0) {
        nextDependantAges = currDependantAges.slice(0, n)
      }

      this.dependantAges = nextDependantAges
      this.$store.commit('updateApplication', {
        key: 'dependants',
        value: this.dependantAges.length,
      })
      this.$store.dispatch('updateOrCreateDraft')
    },

    setDependantAge(i, ageGroup) {
      const nextDependantAges = this.dependantAges.slice()
      nextDependantAges[i] = ageGroup

      this.dependantAges = nextDependantAges
      this.$store.commit('setKidError', false)
      this.$store.dispatch('removeError', 'kidsAges')
      this.$store.commit('updateApplication', {
        key: 'dependantAges',
        value: this.dependantAges,
      })
      this.$store.dispatch('updateOrCreateDraft')
    },

    setCarsArray(n) {
      const currCars = this.cars.slice()
      let cars = currCars
      const nDiff = n - currCars.length

      if (nDiff > 0) {
        const arr = new Array(nDiff)
        arr.fill({ type: null })
        cars.push(...arr)
      }
      if (nDiff < 0) {
        cars = currCars.slice(0, n)
      }
      this.cars = cars

      this.$store.commit('updateApplication', {
        key: 'cars',
        value: this.cars,
      })
      this.$store.commit('setCarError', false)
      this.$store.dispatch('removeError', 'carTypes')
      this.$store.dispatch('updateOrCreateDraft')
    },
    setCarType(j, type) {
      const prevCars = this.cars.slice()
      const nextCars = prevCars?.map((car, i) => {
        if (i === j) {
          return {
            type,
          }
        }
        return car
      })

      this.cars = nextCars

      this.count = _.filter(nextCars, ['type', 'Leaset']).length
      this.$store.commit('setValue', { key: 'numberOfLeasedCars', value: this.count })
      this.$store.commit('updateApplication', {
        key: 'cars',
        value: nextCars,
      })
      this.$store.commit('setCarError', false)

      this.$store.dispatch('removeError', 'carTypes')
      this.$store.dispatch('updateOrCreateDraft')
    },

    updateOccupation() {
      this.$store.commit('removeError', 'businessId')
      if (this.occupation !== '4') {
        this.$store.commit('setValue', { key: 'businessId', value: '' })
        this.$store.commit('updateApplication', { key: 'businessId', value: '' })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.active {
  background-color: #05083c;
  color: white;
}
.add-children {
  text-align: left;
  font-size: small;
}
.hidden {
  display: none;
}
</style>
