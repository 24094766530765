<template>
  <div class="flex-fill front-loan-select" style="width: 100%">
    <div v-if="versionId == 1">
      <div class="form-group separate">
        <form-input
          type="dropdown"
          class="label-separate"
          :items="monthlyCostOptions"
          storekey="repayment_time"
          id="repayment_time_dropdown"
          suffix=" år"
          >{{ $t('per_month') }}
        </form-input>
      </div>
    </div>
    <div v-else>
      <label class="monthly-rate" for="repayment_time">
        {{ $t('repayment_period') }}
      </label>
      <div class="loan-range">
        <vue-numeric
          :class="`${variant} w-100 loanamount mt-0`"
          @input="changeLoanPeriod"
          inputmode="numeric"
          :value="this.loanTime"
          currency-symbol-position="suffix"
          :currency="suffix"
        />
      </div>

      <div class="time-range-dk mt-4">
        <div>
          <slider-input
            id="repayment_time"
            type="range"
            min="1"
            :value="data.repayment_time"
            :update="update"
            @click="update"
            aria-hidden="true"
            :max="this.loanTimeOptions.length"
            showLabel="false"
          ></slider-input>
        </div>
      </div>

      <template v-if="type === 'consumerloan'">
        <div class="d-flex justify-content-between">
          <span class="min">1 {{ $t('year') }}</span>
          <span class="max">{{ this.loanTimeOptions.length }} {{ $t('year') }}</span>
        </div>

        <label class="monthly-rate" for="monthly_rate">{{ $t('per_month') }}</label>
        <div class="text-wrapper">
          <vue-numeric
            class="monthly_rate"
            separator="."
            :read-only="true"
            :value="computedLoans.costMonthly.toFixed(0)"
            currency="kr."
            currency-symbol-position="suffix"
          />
        </div>
      </template>
      <template v-else>
        <div class="d-flex justify-content-between">
          <span class="min">1 {{ $t('month') }}</span>
          <span class="max">{{ this.loanTimeOptions.length }} {{ $t('months') }}</span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { gsap } from 'gsap'
import {
  getApproximateLoanValues,
  findMatchAmount,
  calculateInputRangeStep,
  getMonthlyCosts,
  setSliderColor,
  getApproximateSmeLoanValues,
} from '@/utils/utils'
import Input from '@/components/form/Input'
import FormInput from '@/components/form/FormInput'
import VueNumeric from 'vue-numeric'
export default {
  mounted() {
    this.createSlider()
  },
  beforeDestroy() {
    this.createSlider()
  },
  data() {
    return {
      costMonthly: 0,
      cost: 0,
      step: 100,
      current: 0,
      visible: false,
      amount: 0,
      amount_index: 0,
    }
  },
  components: {
    VueNumeric,
    'slider-input': Input,
    FormInput,
  },
  props: ['StepOne', 'loanTimeOptions', 'loanAmounts', 'versionId', 'variant', 'suffix'],
  watch: {
    variant(variant, oldVariant) {
      if (variant !== oldVariant) {
        this.createSlider()
      }
    },
  },
  computed: {
    data() {
      const data = this.$store.state.application
      const valid_amount = findMatchAmount(this.$store.state.application.amount)
      this.setValues(valid_amount)
      return Object.assign(data, this.$data)
    },
    type() {
      return this.$store.state.applicationType.type
    },
    loanTime: {
      get() {
        return this.$store.state.application.repayment_time
      },
      set() {
        this.$store.state.application.repayment_time = this.loanTime
      },
    },

    loanAmount() {
      return this.$store.state.application.amount
    },

    monthlyCostOptions() {
      return getMonthlyCosts(this.loanAmount, this.loanTimeOptions.length)
    },

    smeLoanAmount() {
      return this.$store.state.application.smeloan.amount
    },

    smeMonthlyCostOptions() {
      return getMonthlyCosts(this.smeLoanAmount, this.loanTimeOptions.length)
    },

    computedLoans() {
      const duration = 0.2
      let computed
      if (this.type === 'companyloan') {
        computed = getApproximateSmeLoanValues(this.smeLoanAmount, this.loanTime)
      }
      computed = getApproximateLoanValues(this.loanAmount, this.loanTime)
      gsap.to(this.$data, { costMonthly: computed.costMonthly, duration: duration })
      gsap.to(this.$data, { cost: computed.cost, duration: duration })
      return this.data
    },
    variantStyle() {
      const variant = this.variant
      const styleMapping = {
        dark: { first: 'rgb(5, 8, 60)', second: 'rgb(90 93 144)' },
        light: { first: '#ffff', second: '#ffff' },
      }
      return styleMapping[variant]
    },
  },

  methods: {
    setValues(valid_amount) {
      this.step = calculateInputRangeStep(valid_amount)
      this.amount = valid_amount
      this.amount_index = this.loanAmounts.indexOf(valid_amount)
    },
    update(key, value) {
      if (key === 'loanrange') {
        key = 'amount_index'
      }
      this.createSlider(value)
      this.$store.commit('updateApplication', { key, value })
    },

    changeLoanPeriod(value) {
      const key = 'repayment_time'
      if (value <= 15 && value >= 3) {
        this.$store.commit('updateApplication', { key, value })
      } else if (value > 15) {
        value = 15
        this.$store.commit('updateApplication', { key, value })
      } else if (value < 1) {
        value = 1
        this.$store.commit('updateApplication', { key, value })
      }
      this.loanTime = value
      this.createSlider(value)
    },
    createSlider(value) {
      let val
      if (value) {
        val = value
      } else {
        val = this.data.repayment_time
      }
      this.oldVariant = this.variantStyle

      setSliderColor({
        elementId: 'repayment_time',
        min: 1,
        max: this.loanTimeOptions.length,
        value: val,
        firstColor: this.variantStyle.first,
        secondColor: this.variantStyle.second,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.loan-range {
  font-size: 46px;
  @media (max-width: 500px) {
    font-size: 36px;
  }
}
</style>
