<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til uddannelse</h1>

      <div class="top-text text-container">
        <p>
          Hvis du har brug for at låne penge til studiet, så er du havnet det rigtige
          sted. Uanset om du har brug for et lån til pilotuddannelsen, uddannelse i
          udlandet, eller en anden selvbetalt uddannelse, så kan vi hjælpe dig. Udfyld
          vores ansøgningsformular på 2 minutter, så indhenter vi en lang række tilbud til
          dig, som kan finansiere din uddannelse.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#saadan-finder-vi-de-bedste-laan-til-din-uddannelse"
                    >Sådan finder vi de bedste lån til din uddannelse</a
                  >
                </li>
                <li>
                  <a href="#laan-til-selvbetalte-uddannelser"
                    >Lån til selvbetalte uddannelser</a
                  >
                </li>
                <li><a href="#laan-til-pilotuddannelsen">Lån til pilotuddannelsen</a></li>
                <li>
                  <a href="#laan-til-andre-selvbetalte-uddannelser"
                    >Lån til andre selvbetalte uddannelser</a
                  >
                </li>
                <li><a href="#uddannelse-i-udlandet">Uddannelse i udlandet</a></li>
                <li>
                  <a href="#skal-vi-hjaelpe-dig-med-at-finde-laanet-til-din-uddannelse"
                    >Skal vi hjælpe dig med at finde lånet til din uddannelse?</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="saadan-finder-vi-de-bedste-laan-til-din-uddannelse">
            Sådan finder vi de bedste lån til din uddannelse
          </h2>
          <p>
            Hvis du ønsker vores hjælp til at finde det rette lån til din uddannelse, så
            har vi blot behov for at du udfylder vores ansøgningsformular. Som nævnt tager
            ansøgningen blot få minutter at udfylde og giver os den information, som
            bankerne har brug for, for at kunne vurdere, hvilket lån de kan tilbyde dig.
          </p>
          <p>
            På få sekunder, vil bankerne automatisk kreditvurdere dig, og vende tilbage
            med tilbud, som vi opstiller på en nem og overskuelig side, så du uden
            problemer kan finde og vælge det bedste lån til at dække udgifterne til din
            uddannelse.
          </p>
          <p>
            Når du har valgt, hvilken udbyder, som skal finansiere din uddannelse, skal du
            blot verificere dine oplysninger på udbyderens hjemmeside via
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >MitID</a
            >. Herefter udbetales dit lån til din konto, så du kan tilmelde dig første
            semester på studiet og købe studiebøgerne.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-selvbetalte-uddannelser">Lån til selvbetalte uddannelser</h2>
          <p>
            Hvis du ikke har sparet op, så har du måske behov for at låne til din
            selvbetalt uddannelse. Det er nemlig Ikke alle uddannelser, som giver ret til
            SU eller bliver betalt af staten, hvilket begge dele kan være en grund til at
            låne til uddannelsen. Hvis du endnu ikke har undersøgt, om du kan få SU eller
            <strong>SU-lån</strong>, så vil vi anbefale, at du undersøger dette i første
            omgang, da dette ofte er den billigste måde at låne til uddannelsen.
          </p>
          <p>
            Fælles for de fleste selvbetalte uddannelser er, at de bliver betegnet som
            kurser eller som alternativ behandling, hvilket i praksis betyder, at den
            ydelse som man efterfølgende kan yde er uden dokumenteret effekt. Dermed ikke
            sagt, at behandlingen ikke har nogen effekt. En af de mest velkendte
            selvbetalte uddannelser er pilotuddannelsen, som mange også vælger at låne
            til, hvilket måske falder lidt uden for disse to kategorier.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-pilotuddannelsen">Lån til pilotuddannelsen</h2>
          <p>
            Hvis du ønsker at uddanne dig til pilot, så har du måske brug for et lån.
            Pilotuddannelsen er en af de kendte selvbetalte uddannelser, som oven i købet
            er meget dyr.
          </p>
          <p>
            En pilotuddannelse koster typisk 650.000 kr. til 800.000 kr., samtidig med at
            uddannelsen ikke er SU-berettiget. Derfor er det heller ikke så overraskende
            at mange vælger at låne til pilotuddannelsen.
          </p>
          <p>
            De færreste låner hele beløbet til pilotuddannelsen. Ofte arbejder man selv et
            år eller to for at spare op, hvorefter man supplerer med et politlån. Hvis du
            har brug for at låne de sidste
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/100000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >100.000 kr.</a
            >,
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/200000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >200.000 kr.</a
            >
            eller
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/500000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >500.000 kr.</a
            >, så hjælper vi dig gerne med at finde et godt lån til pilotuddannelsen.
          </p>
          <p>
            I og med at det ofte vil være et større beløb, som man ønsker at låne til
            pilotuddannelsen, kan det være en fordel, at ansøge om
            <strong>lån med en medansøger</strong>, hvis det er muligt. Når du ansøger
            sammen med en anden person, vil dine vilkår og muligheder for at blive
            godkendt stige markant. Dette gælder såvel for pilotlån, såvel som andre lån,
            men er især relevant ved store
            <a
              href="https://www.saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              >lånebeløb</a
            >.
          </p>
          <p>
            Hvis du ønsker at låne penge til pilotuddannelsen, så send en ansøgning nu. Så
            finder vi med det samme en lang række
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >lånetilbud</a
            >
            til dig, så du på et oplyst grundlag kan vælge det bedste og
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >billigste lån</a
            >
            til din uddannelse.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-andre-selvbetalte-uddannelser">
            Lån til andre selvbetalte uddannelser
          </h2>
          <p>
            Pilotuddannelsen er ikke den eneste sted, hvor vi kan hjælpe med lån til
            uddannelse. Der findes mange andre uddannelser, som heller ikke er finansieret
            af det offentlige, hvoraf vi vil gennemgå nogle af de udbrede herunder.
          </p>
          <p>
            Disse uddannelser er langt fra lige så dyre som pilotuddannelsen, og det vil
            derfor det heller ikke altid være relevant at låne til disse uddannelser. Hvis
            du ikke har sparet en krone op, så kommer du nok desværre ikke uden om en
            eller anden form for uddannelseslån.
          </p>
          <h3>Indretningskonsulent uddannelse</h3>
          <p>
            Har du en drøm om at indrette kontorer, forretninger eller folks hjem, så er
            indretningskonsluentuddannelsen et oplagt valg. Her kan du komme igennem et 24
            måneders forløb for knap
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/20000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >20.000 kr.</a
            >
            og efterfølgende kalde dig for indretningskonsulent.
          </p>
          <h3>Zoneterapeutuddannelse</h3>
          <p>
            Hvis du er mere til de asiatiske traditioner og glad for at arbejde med
            fødder, så kan zoneterapeutuddannelsen være et oplagt valg. Denne uddannelse
            koster ofte i omegnen af
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/50000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >50.000 kr.</a
            >
            alt efter hvor du vælger at tage uddannelsen.
          </p>
          <h3>Yogalæreruddannelse</h3>
          <p>
            Uddannelsen til yogalærer / yogainstruktør koster omkring
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/15000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >15.000 kr.</a
            >
            for grundforløbet. Her vil du typisk Yogastillinger (Asana), Åndedrætsøvelser
            (Pranayama), Afspænding (Savasana) samt Hatha Yoga, Hot Yoga, Vinyasa Yoga og
            Yin Yoga. Du vil også efterfølgende kunne undervise elever i netop Yoga, hvis
            det er det, som du ønsker dig. Senere hen kan du tage en overbygning på nogle
            af ovenstående Yogaformer, samt Yoga Flow.
          </p>
          <h3>Makeup artist uddannelse</h3>
          <p>
            Vil du være professionel makeup artist, så har du også behov for et bevis på,
            at du er uddannet makeup artist. Denne uddannelse kræver ikke en frisør,
            kosmetolog eller anden forudgående uddannelse fra skønhedsbranchen.
          </p>
          <p>
            Uddannelsen som makeup artist koster i omegnen af
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/25000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >25.000 kr.</a
            >
          </p>
          <h3>Massøruddannelse</h3>
          <p>
            Hvis du drømmer om at bruge musklerne, men ikke ønsker en traditionel
            håndværkeruddannelse, så kan massøruddannelsen være et oplagt valg. Her får du
            lov at nemlig både lov at bruge hænder og kræfter.
          </p>
          <p>
            Alt efter om du ønske at blive helse- og sportsmassør, fysiurgisk massør og
            sportsbehandler eller hele pakken vil prisen variere fra ca.
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/10000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >10.000 kr.</a
            >
            til 25.000 kr.
          </p>
          <h3>Akupunkturuddannelse</h3>
          <p>
            Ønsker du at blive RAB-godkendt akupunktør, skal du forvente et
            uddannelsesforløb på 1-2½ år, alt efter hvor du vælger at uddanne dig henne,
            hvor du med en nål (acus) vil lære at stikke (punktura), de helt rigtige
            steder, for at opnå det ønskede resultat.
          </p>
          <p>
            Prisen på akupunktøruddannelsen varierer en del, hvilket bla. skyldes den
            store variation i længden på uddannelsesforløbet, men kan oftes fås for under
            50.000 kr.
          </p>
        </div>

        <div class="text-container">
          <h2 id="uddannelse-i-udlandet">Uddannelse i udlandet</h2>
          <p>
            Hvis du ønsker at blive optaget på et studie i udlandet, så kan du enten vælge
            at søge relevante legater, eller låne penge til uddannelsen. I nogle tilfælde
            kan du få uddannelsen i udlandet dækket af den danske stat, men i mange
            tilfælde vil du selv skulle betale.
          </p>
          <p>
            Hvis du blot ønsker et semester i udlandet, så vil det ofte kun være rejsen
            frem og tilbage fra landet, som vil være en ekstraordinær omkostning for dig,
            da du alligevel skulle have betale for bøger og studiebolig på dit studie i
            Danmark. Her vælger mange dog også at optage et
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >
            til blot at have lidt ekstra penge på lommen, så de undgår at arbejde, mens de
            er ude at
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan/ferie"
              rel="noopener noreferrer"
              target="_blank"
              >rejse</a
            >.
          </p>
          <p>
            Hvis du ønsker at låne penge til uddannelse i udlandet, så hjælper vi dig også
            gerne med at finde det rette lån.
          </p>
        </div>

        <div class="text-container">
          <h2 id="skal-vi-hjaelpe-dig-med-at-finde-laanet-til-din-uddannelse">
            Skal vi hjælpe dig med at finde lånet til din uddannelse?
          </h2>
          <p>
            Hvis du på nuværende tidspunkt er interesseret i at låne til din uddannelse,
            så tøv ikke med at ansøge på vores side. Vi hjælper dagligt vores brugere med
            at finde diverse lån til studier, uddannelser og til at dække diverse
            studierelaterede udgifter.
          </p>
          <p>
            Ved at benytte vores tjeneste, får hurtigt og nemt et bredt udsnit af de
            tilbud, som du kan få på lånemarkedet, så du undgår at blive snydt af det
            første og “bedste” tilbud, som du ellers ville støde på.
          </p>
          <p>
            Hvis du har brug for vores hjælp, kan du også kontakte vores kundeservice på
            telefon og email.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
