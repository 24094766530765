<template>
  <div>
    <LoanAmountSlider
      :currency="currency"
      :separator="separator"
      :loanAmounts="this.loanAmounts"
      variant="dark"
    />
    <MonthlyAmountDk
      :loanAmounts="this.loanAmounts"
      v-if="this.$root.brand === 'saverium_dk'"
      :loanTimeOptions="this.loanTimeOptions"
      class="small-monthly"
      variant="dark"
      suffix="år"
    />
    <MonthlyAmountPl
      :loanAmounts="this.loanAmounts"
      v-if="this.$root.brand === 'saverium_pl'"
      class="small-monthly"
    />
    <div class="col-md-12 apply-button">
      <b-link
        :to="$root.rn.LoanView"
        class="btn btn-primary btn-saverium btn-wide next-btn btn btn-front mt-2"
        id="apply"
      >
        {{ $t('get_a_loan') }}
      </b-link>
    </div>
    <span class="disclaimer">
      {{ $t('the_apr_is') }}
    </span>
  </div>
</template>
<script>
import LoanAmountSlider from '@/components/LoanAmountSlider'
import MonthlyAmountDk from '@/components/saverium_dk/loan_amount_components/MonthlyAmount'
import MonthlyAmountPl from '@/components/saverium_pl/loan_amount_components/MonthlyAmount.vue'
import { loanAmounts as loanAmountsDk, loanTimeOptions } from '@/utils/saverium_dk/const'
import { loanAmounts as loanAmountsPl } from '@/utils/saverium_pl/const'

export default {
  components: {
    LoanAmountSlider,
    MonthlyAmountDk,
    MonthlyAmountPl,
  },
  data() {
    return {
      props: ['currency', 'separator', 'loanAmounts'],
    }
  },
  mounted() {
    this.setApplicationType('consumerloan')
  },
  computed: {
    loanAmounts() {
      if (this.$root.brand === 'saverium_dk') {
        return loanAmountsDk
      } else if (this.$root.brand === 'saverium_pl') {
        return loanAmountsPl
      } else {
        return ''
      }
    },
    loanTimeOptions() {
      return loanTimeOptions
    },
    currency() {
      if (this.$root.brand === 'saverium_dk') {
        return 'kr.'
      } else if (this.$root.brand === 'saverium_pl') {
        return 'zł'
      } else {
        return ''
      }
    },
    separator() {
      if (this.$root.brand === 'saverium_dk') {
        return '.'
      } else if (this.$root.brand === 'saverium_pl') {
        return 'space'
      } else {
        return ''
      }
    },
  },
  methods: {
    setApplicationType(value) {
      this.$store.commit('setApplicationType', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.disclaimer {
  @media (max-width: 600px) {
    font-size: 0.7rem;
    line-height: 1.2;
  }
}
</style>
