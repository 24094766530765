<template>
  <b-container class="container">
    <b-row class="row">
      <b-col sm="6" offset="3">
        <h1 class="page-header">Uusi salasana</h1>
        <div class="well">
          <div
            class="alert alert-danger alert-dismissable"
            v-if="
              Object.keys(this.errors).length > 0 &&
              !this.errors.new_password1 &&
              !this.errors.new_password2
            "
          >
            <button class="close" type="button" v-on:click="error = false">×</button>
            Salasanan nollausavain on saattanut vanheta.
          </div>
          <form method="post" @submit.prevent="Sent()" v-if="!this.success">
            <input type="hidden" name="pk" :value="this.$route.params.pk" />
            <input type="hidden" name="key" :value="this.$route.params.key" />
            <input type="hidden" name="ts" :value="this.$route.params.ts" />
            <label for="new_password1">Uusi salasana</label>
            <b-form-input
              type="password"
              :class="{ 'is-invalid': this.errors.new_password1 }"
              name="new_password1"
              id="new_password1"
            ></b-form-input>
            <small class="form-text text-muted">
              <ul>
                <li>Salasana pitää sisältää vähintään 8 merkkiä.</li>
                <li>Salasana ei saa olla liian samanlainen muihin tietoihin.</li>
                <li>Salasana ei saa olla liian yleinen.</li>
                <li>Salasana ei saa sisältää pelkästään numeroita.</li>
              </ul>
            </small>
            <label for="new_password2">Vahvista salasana</label>
            <b-form-input
              type="password"
              name="new_password2"
              :class="{ 'is-invalid': this.errors.new_password2 }"
              id="new_password2"
            ></b-form-input>
            <hr />
            <button type="submit" class="btn btn-primary btn-lg btn-block">
              <i class="fa fa-fw fa-sign-in"></i> Lähetä
            </button>
          </form>
        </div>
        <hr />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      success: false,
      errors: {},
    }
  },
  computed: {},
  methods: {
    async Sent() {
      const formData = new FormData(document.querySelector('form'))

      this.success = false
      this.errors = {}

      const resp = await this.$store.dispatch('setPassword', formData)
      if (resp.success) {
        this.success = true
      } else {
        this.errors = resp
      }
    },
  },
}
</script>

<style lang="css" scoped></style>
