<template>
  <div class="row article">
    <h1 class="center-title">Pożyczki prywatne – szybka pożyczka prywatna</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >Pożyczka według Kodeksu cywilnego może mieć formę finansową, ale nie musi.
              Opiera się na umowie, jaką zawierają ze sobą dwie strony. Wcale nie jest
              powiedziane, że pożyczkodawcą musi być firma trudniąca się zawodowo
              udzielaniem kredytów gotówkowych czy pożyczek online na raty. Przykładem
              takich produktów, w przypadku których jest wręcz odwrotnie, są pożyczki
              prywatne. Czym one są i czy warto z nich korzystać?
            </i>
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/private-loans-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/private-loans.jpg"
            alt="Pożyczki prywatne – szybka pożyczka prywatna"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container mt-5">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            Pożyczka prywatna to każda forma zobowiązania, która polega na udostępnieniu
            określonych kwot pieniężnych z własnych zasobów osobom trzecim przez
            instytucje pozabankowe lub osoby fizyczne. Odbiorca środków może przeznaczyć
            uzyskaną kwotę na dowolny cel. Właściwie prywatne pożyczki gotówkowe to
            wszystkie te zobowiązania, które nie są udzielane przez banki.
          </p>
          <p>
            Zobowiązania te mogą mieć różny charakter. W grupie tej wyróżnia się między
            innymi pożyczki od osób prywatnych, które polegają na tym, że korzystającym ze
            środków jest znajomy, przyjaciel czy ktoś z rodziny pożyczkobiorcy.
          </p>
          <p>
            Do pożyczek prywatnych zalicza się także produkty społecznościowe, które są
            zaciągane za pośrednictwem platform kojarzących ze sobą osoby skłonne pożyczyć
            pieniądze z osobami, które potrzebują finansowania zewnętrznego.
          </p>
          <p>
            Powszechną formą zobowiązań prywatnych są
            <b-link :to="$root.rn.OnlineNonbankLoans">pozabankowe pożyczki</b-link>
            prywatne udzielane przez firmy pozabankowe. Potocznie nazywane są one
            chwilówkami i znajdują się w ofertach prywatnych przedsiębiorstw.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Z pozoru wydaje się, że pożyczka i kredyt właściwie sprowadzają się do tego
            samego, mianowicie do udzielenia zobowiązania danej osobie, z określonym
            terminem spłaty. Jednak istnieją pewne różnice pomiędzy tymi pojęciami i w
            związku z tym nie mogą one być traktowane jako tożsame względem siebie.
          </p>
          <p>
            Przede wszystkim prywatna pożyczka i wszelkie kwestie z nią związane
            regulowane są przepisami Kodeksu cywilnego, podczas gdy kredyt, jako produkt
            stricte bankowy, podlega przepisom ustawy Prawo bankowe.
          </p>
          <p>
            Uprawnionymi do udzielania kredytów są tylko instytucje bankowe. Jest to
            zobowiązanie udostępniane klientowi przez bank. Ma ono postać pieniężną, a
            podstawą jego udzielenia jest umowa.
          </p>
          <p>
            Pożyczka pozabankowa prywatna jest szerszym pojęciem niż kredyt. To także
            forma udostępniania środków finansowych, ale możliwe jest przyznanie pożyczki
            nawet bez pisemnej umowy, w dowolnym celu – oprocentowanej lub
            nieoprocentowanej. Pożyczka prywatna – online i stacjonarna – udzielana jest
            na zasadach akceptowanych i kształtowanych pomiędzy stronami umowy.
            Indywidualnie ustalają oni, na jakich warunkach pożyczka ma zostać przekazana.
          </p>
          <p>
            Szybka pożyczka prywatna udzielana np. przez osobę fizyczną innej osobie, za
            pośrednictwem portalu kojarzącego ze sobą udostępniających środki i tych,
            którzy ich potrzebują, opiera się na zupełnie innych zasadach niż kredyt
            bankowy. Nie ma tu na przykład limitu kosztów odsetkowych i pozaodsetkowych,
            które właściwe są dla kredytów, jak i dla pożyczek pozabankowych. Prywatni
            inwestorzy, którzy wykorzystują posiadany kapitał w celach dawania zobowiązań
            innym, nie muszą przestrzegać limitów obowiązujących w Ustawie z dnia 12 maja
            2011 roku o kredycie konsumenckim czy w Ustawie z dnia 29 sierpnia 1997 roku
            Prawo bankowe. Osoby decydujące się zaciągnąć tego rodzaju produkty
            pozabankowe, ale nie w firmie pożyczkowej, muszą być tego świadomi.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Możliwość uzyskania pieniędzy online sprawia, że w dowolnym momencie, nawet w
            środku nocy i w dni wolne od pracy, można ubiegać się o pożyczki prywatne
            online i otrzymać je w formie bezgotówkowej – z wypłatą na wskazane konto
            bankowe. Związane jest to z uproszczonymi zasadami wnioskowania o takie
            zobowiązanie.
          </p>
          <p>
            Wygląda to tak, że zwykle portale dotyczące pożyczek przez internet prywatnie
            pozwalają na publikowanie ogłoszeń przez potencjalnych klientów, zaś
            pożyczkodawcy zgłaszają się do nich i ustalają, na jakich warunkach mogą
            udzielić pożądanego finansowania. Zgodnie z prawem, nawet jeśli chodzi o
            pożyczkę prywatną online, to prawo cywilne obliguje strony do sporządzenia w
            formie pisemnej umowy, jeśli transakcja opiewa na kwotę powyżej 1000 zł.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Chcąc zaciągnąć pożyczkę prywatną, warto skorzystać z portali, które kojarzą
            ze sobą udzielających środków i korzystających. Po wstępnej weryfikacji klient
            może określić, ile pieniędzy chce uzyskać, na jak długo i wskazać, jak wysoką
            stawkę oprocentowania jest w stanie zapłacić w formie wynagrodzenia dla
            pożyczkodawcy. Czasem możliwe jest też ustalenie, że udzielona pożyczka
            prywatna ma być spłacana na raty.
          </p>
          <p>
            W zawieranej umowie o pożyczkę prywatną, która zwykle akceptowana jest przez
            strony w trybie online, powinny znaleźć się dane tych osób, wszystkie
            parametry pożyczki prywatnej online oraz informacje o konsekwencjach
            nieuregulowania należności w terminie.
          </p>
          <p>
            Udzielona pożyczka wypłacana jest klientowi na wskazane w portalu konto
            bankowe, w ramach którego też sprawdzana jest na ogół tożsamość wnioskującego.
            Jeśli dobrowolnie zdecyduje się on na weryfikację w bazach danych dłużników
            czy też na dodanie do swojego profilu zaświadczenia o wysokości i źródłach
            pozyskiwanych dochodów, to będzie miał mocniejszą pozycję w negocjowaniu
            warunków oferty.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            Dla kogo kredyty prywatne będą najlepszym rozwiązaniem? Przede wszystkim dla
            tych osób, którym zależy na czasie, a z różnych powodów nie mogą zaciągnąć
            kredytu w banku.
            <b-link :to="$root.rn.OnlinePaydayLoans">Chwilówki online</b-link>
            także mogą okazać się dla nich nieosiągalne, jeśli są udzielane po weryfikacji
            w BIK-u (Biurze Informacji Kredytowej) czy w innych biurach, takich jak BIG
            InfoMonitor. Oferty prywatne to zdecydowanie
            <b-link :to="$root.rn.LoanWithoutBik">pożyczki bez BIK-u</b-link>, które
            teoretycznie przynajmniej może zaciągnąć nawet osoba zadłużona. Decydując się
            na nie, nikomu nie trzeba pokazywać zaświadczenia o zatrudnieniu czy o
            wysokości uzyskiwanych dochodów. Pożyczkobiorca i pożyczkodawca umawiają się
            ze sobą na warunki kredytowania i mogą dojść do porozumienia w kilka minut,
            czego efektem będzie wypłacenie zobowiązania. Dla osób z trudną sytuacją
            finansową
            <b-link :to="$root.rn.LoanWithoutBik">szybka pożyczka online</b-link>
            jest bardzo wygodną możliwością otrzymania natychmiastowego zastrzyku
            pieniędzy na konto.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            Pożyczki prywatne przez internet mogą uzupełnić budżet domowy każdej osoby,
            która znajdzie oferenta, który zaproponuje jej udzielenie zobowiązania na
            ustalonych warunkach. Zadłużenie w bankach czy w firmach pożyczkowych zwykle
            nie jest w tym przypadku brane pod uwagę. Pożyczki prywatne mogą być udzielane
            przez znajomych, osoby z rodziny, przyjaciół, ale i przez osoby, które nic nie
            łączy z korzystającym ze środków. Istnieją platformy online, które łączą
            pożyczkodawców z klientami. Transakcja zawierana jest prywatnie, na podstawie
            umowy cywilnej, a portal pobierać może tylko niewielkie prowizje za swoje
            pośrednictwo. Pożyczkodawcy nie są zobowiązani do tego, by honorować limity
            kosztów odsetkowych i pozaodsetkowych w pożyczkach prywatnych, dlatego mogą
            być one wyższe, niż na przykład w przypadku pozabankowych pożyczek na raty w
            firmach pożyczkowych czy przy kredytach bankowych. My, zachęcamy do
            skorzystania z naszej platformy – wypełniając jeden prosty wniosek, możesz
            usykać szybką pożyczkę online przez Internet. Naszymi partnerami są renomowane
            firmy pożyczkowe, z ugruntowaną pozycją na rynku pożyczkowym.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'Co to jest pożyczka prywatna?',
          link: '#1',
        },
        {
          title: 'Czym różni się pożyczka prywatna od kredytu?',
          link: '#2',
        },
        {
          title: 'Pożyczki prywatne pozabankowe online',
          link: '#3',
        },
        {
          title: 'Jak wziąć pożyczkę prywatną przez internet?',
          link: '#4',
        },
        {
          title: 'Pożyczka prywatna – dla kogo?',
          link: '#5',
        },
        {
          title: 'Pożyczka prywatna – podsumowanie',
          link: '#6',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped></style>
