<template lang="html">
  <div class="mb-5" ref="header">
    <h2 v-if="fullView" class="mb-4 text-center">
      {{ step }}.
      {{ $t('bank_details') }}
    </h2>
    <h1 v-else class="mb-4 text-center">
      {{ $t('bank_details') }}
    </h1>

    <div class="row">
      <div class="form-group col-sm-6">
        <form-input
          type="prependregex"
          prepend="PL"
          placeholder="np. 61 1090 1014 0000 0712 1981 2874"
          storekey="bankAccount"
          id="bankAccount"
        >
          {{ $t('bank_account_number') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input
          :items="accountTypeOptions"
          type="dropdown"
          storekey="accountType"
          id="accountType"
        >
          {{ $t('account_type') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6">
        <form-input
          :items="bankOptions"
          type="dropdown"
          storekey="bankName"
          id="bankName"
        >
          {{ $t('bank_name') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input type="text" storekey="accountHolder" id="accountHolder">
          {{ $t('account_holder') }}
        </form-input>
      </div>

      <div class="form-check col-12 mb-3">
        <b-form-checkbox
          @change="acceptConsents"
          :unchecked-value="false"
          :value="true"
          class="form-input"
          id="acceptAll"
          storekey="acceptAll"
          v-model="allAccepted"
        >
          <div v-if="consentError === false">
            {{ $t('i_accept_all_statements') }}
          </div>
          <div v-if="consentError === true" class="error-all">
            {{ $t('i_accept_all_statements') }}
          </div>
          <div
            v-if="consentError === true"
            class="invalid-feedback"
            style="display: block"
          >
            {{ $t('field_is_required') }}
          </div>
        </b-form-checkbox>
      </div>
      <div
        @click="visible = !visible"
        :class="visible ? null : 'collapsed'"
        class="collapse-wrapper col-12 mb-3"
        id="toggle-collapse"
        :aria-expanded="visible ? 'true' : 'false'"
      >
        <span> {{ $t('expand') }} </span>
        <span class="when-opened">
          <i class="far fa-angle-double-down close-collapse" aria-hidden="true"></i
        ></span>
        <span class="when-closed">
          <i
            class="far fa-angle-double-down toggle-collapse"
            data-fa-transform="rotate-90"
            aria-hidden="true"
          ></i>
        </span>
      </div>
      <b-collapse id="collapse-consents" v-model="visible">
        <div class="form-check col-12 mb-3 pl-5" @click="toggleConsents">
          <form-input
            type="checkbox"
            storekey="personalDataPolicy"
            id="personalDataPolicy"
          >
            {{ $t('i_declare_for_pp_2') }} {{ ' ' }}
            <b-link :to="$root.rn.PrivacyPolicyPDF" class="tos-link" target="_blank">
              {{ $t('privacy_policy_2') }}
            </b-link>
          </form-input>
        </div>
        <div class="form-check col-12 mb-3 pl-5" @click="toggleConsents">
          <form-input type="checkbox" storekey="consents" id="consents">
            {{ $t('i_declare_that_i_have_read_the') }}
            <b-link :to="$root.rn.ConsentsPDF" class="tos-link" target="_blank">{{
              $t('consents_small')
            }}</b-link
            >{{ ' ' }}
            {{ $t('needed_by_partners_to_assess_creditworthiness') }}
          </form-input>
        </div>

        <div class="form-check col-12 mb-3 pl-5" @click="toggleConsents">
          <form-input type="checkbox" storekey="marketingConsents" id="marketingConsents">
            {{ $t('i_declare_that_i_have_read_and_accept_the') }} {{ ' ' }}
          </form-input>

          <p class="mt-2">
            {{ $t('disclosure') }}
            <b-link :to="$root.rn.MarketingConsentsPDF" class="tos-link" target="_blank">
              {{ $t('marketing_consents') }}
            </b-link>
            {{ $t('marketing_consents_text') }}
          </p>
        </div>
      </b-collapse>
    </div>
    <div class="mt-3 alert alert-danger" role="alert" v-if="hasErrors && fullView">
      {{ $t('check_the_fields_marked_in_red') }}
    </div>
  </div>
</template>

<script>
import FormInput from '@/components/form/FormInput'

export default {
  props: ['fullView', 'step'],
  components: {
    FormInput,
  },
  computed: {
    accountTypeOptions() {
      return this.$store.state.enums.accountTypeOptions
    },
    bankOptions() {
      return this.$store.state.enums.bankOptions
    },
    errors() {
      return this.$store.state.application.errors
    },
    loading() {
      return this.$store.state.application.sendingApplication
    },
    hasErrors() {
      return Object.keys(this.errors).length
    },
    stepErrors() {
      return this.$store.state.application.stepErrors
    },
    personalDataPolicy() {
      return this.$store.state.application.personalDataPolicy
    },
    consents() {
      return this.$store.state.application.consents
    },
    marketingConsents() {
      return this.$store.state.application.marketingConsents
    },
    consentError: {
      get() {
        return this.$store.state.application.consentError
      },
      set(consentError) {
        this.$store.state.application.consentError = consentError
      },
    },
    allAccepted: {
      get() {
        return this.$store.state.application.allAccepted
      },
      set(allAccepted) {
        this.$store.state.application.allAccepted = allAccepted
      },
    },
  },
  watch: {
    stepErrors(newV) {
      if (this.fullView && newV[0] === parseInt(this.step, 10)) {
        this.$refs.header.scrollIntoView()
      }
      if (
        this.errors.consents ||
        this.errors.marketingConsents ||
        this.errors.personalDataPolicy
      ) {
        this.visible = true
      }
    },
  },
  data() {
    return {
      requiredFields: [
        'bankAccount',
        'bankName',
        'accountType',
        'personalDataPolicy',
        'consents',
        'marketingConsents',
        'acceptAll',
      ],
      input: true,
      error: false,

      visible: false,
    }
  },
  methods: {
    acceptConsents(value) {
      this.$store.commit('setValue', { key: 'consentError', value: value })
      if (value !== false) {
        this.$store.commit('removeError', 'acceptAll')
        this.$store.commit('removeError', 'personalDataPolicy')
        this.$store.commit('removeError', 'consents')
        this.$store.commit('removeError', 'marketingConsents')
        this.$store.commit('setValue', { key: 'consentError', value: false })
      }
      this.$store.commit('updateApplication', { key: 'acceptAll', value: value })
      this.$store.commit('updateApplication', {
        key: 'personalDataPolicy',
        value: value,
      })
      this.$store.commit('updateApplication', {
        key: 'consents',
        value: value,
      })
      this.$store.commit('updateApplication', {
        key: 'marketingConsents',
        value: value,
      })
    },
    toggleConsents() {
      if (
        this.personalDataPolicy !== false ||
        this.consents !== false ||
        this.marketingConsents !== false
      ) {
        this.$store.commit('setValue', { key: 'allAccepted', value: false })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.spinner-wrapper {
  font-size: 60px;
  text-align: center;
}
.toggle-collapse {
  cursor: pointer;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.close-collapse {
  cursor: pointer;
  transform: rotate(-90deg);
}

.collapse-wrapper {
  text-align: right;
  cursor: pointer;
}

.error-all {
  color: #dc3545;
}
.tos-link {
  color: rgb(5, 8, 60);
  font-weight: 400;
  background-image: linear-gradient(transparent calc(100% - 1px), rgb(5, 8, 60) 1px);
  background-size: 100% 100%;
  background-position: 100%;
  position: relative;
  background-repeat: no-repeat;
  transition: all 0.2s;
  &:hover {
    transition: all 0.2s;
    text-decoration: none;
    background-size: 0 100%;
  }
}
</style>
