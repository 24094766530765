import MainView from '@/views/saverium_pl/MainView'
import MyAccountView from '@/views/MyAccountView'
import FormView from '@/views/saverium_pl/FormView'
import FormWrapperView from '@/views/saverium_pl/FormWrapperView'
import FaqView from '@/views/saverium_pl/FaqView'
import LoginView from '@/views/LoginView'
import ResetPasswordView from '@/views/ResetPasswordView'
import SetPasswordView from '@/views/SetPasswordView'
import OurPartnersView from '@/views/saverium_pl/OurPartnersView'
import SmsVerificationView from '@/views/saverium_pl/SmsVerificationView'
import TokenLoginView from '@/views/TokenLoginView'
import LogoutView from '@/views/LogoutView'
import CancelDraftView from '@/views/CancelDraftView'
import ArticleView from '@/views/ArticleMenuView'
import OnlinePaydayLoans from '@/views/saverium_pl/articles/OnlinePaydayLoans.vue'
import CashLoan from '@/views/saverium_pl/articles/CashLoan'
import LoanForInDebt from '@/views/saverium_pl/articles/LoanForInDebt'
import routeUtils from '@/router/routeUtils'
import LoanWithoutBik from '@/views/saverium_pl/articles/LoanWithoutBik'
import LoansForProof from '@/views/saverium_pl/articles/LoansForProof'
import OnlineNonbankLoans from '@/views/saverium_pl/articles/OnlineNonbankLoans'
import LoanInstallment from '@/views/saverium_pl/articles/LoanInstallment'
import LoansWithoutCertificates from '@/views/saverium_pl/articles/LoansWithoutCertificates'
import FreeLoan from '@/views/saverium_pl/articles/FreeLoan'
import SocialLoans from '@/views/saverium_pl/articles/SocialLoans'
import LoansToHome from '@/views/saverium_pl/articles/LoansToHome'
import LoansToUnemployed from '@/views/saverium_pl/articles/LoansToUnemployed'
import LoanForTwoMonths from '@/views/saverium_pl/articles/LoanForTwoMonths'
import LoansToAccount from '@/views/saverium_pl/articles/LoansToAccount'
import PrivateLoans from '@/views/saverium_pl/articles/PrivateLoans'
import CreditWorthiness from '@/views/saverium_pl/articles/CreditWorthiness'
import WhatIsCredit from '@/views/saverium_pl/articles/WhatIsCredit'
import TenThousandLoan from '@/views/saverium_pl/articles/TenThousandLoan'
import LoanForThreeMonths from '@/views/saverium_pl/articles/LoanForThreeMonths'
import LoanForHolidays from '@/views/saverium_pl/articles/LoanForHolidays'
import InstantInternetLoans from '@/views/saverium_pl/articles/InstantInternetLoans'
import LoansInFifteenMinutes from '@/views/saverium_pl/articles/LoansInFifteenMinutes'
import LoansForCompanies from '@/views/saverium_pl/articles/LoansForCompanies'
import OnlineLongtermLoans from '@/views/saverium_pl/articles/OnlineLongtermLoans'
import LoanForYoungPeople from '@/views/saverium_pl/articles/LoanForYoungPeople'
import NewNonbankLoans from '@/views/saverium_pl/articles/NewNonbankLoans'
import LoanWithoutEmploymentContract from '@/views/saverium_pl/articles/LoanWithoutEmploymentContract'
import ShortTermLoans from '@/views/saverium_pl/articles/ShortTermLoans'
import BestPlaceToTakeLoan from '@/views/saverium_pl/articles/BestPlaceToTakeLoan'
import LoanForYear from '@/views/saverium_pl/articles/LoanForYear'
import LoanFor1200 from '@/views/saverium_pl/articles/LoanFor1200'
import LoanFor20YearOlds from '@/views/saverium_pl/articles/LoanFor20YearOlds'
import LoanForWedding from '@/views/saverium_pl/articles/LoanForWedding'
import LoanForPensioners from '@/views/saverium_pl/articles/LoanForPensioners'
import LoanOnDeclaration from '@/views/saverium_pl/articles/LoanOnDeclaration'
import ResponsibleBorrowing from '@/views/saverium_pl/articles/ResponsibleBorrowing'
import DebtSpiral from '@/views/saverium_pl/articles/DebtSpiral'
import SafeOnlineBorrowing from '@/views/saverium_pl/articles/SafeOnlineBorrowing'
import WhatIsRRSO from '@/views/saverium_pl/articles/WhatIsRRSO'
import WhatAreTheseAbbreviations from '@/views/saverium_pl/articles/WhatAreTheseAbbreviations'
import LoanCalculator from '@/views/saverium_pl/articles/LoanCalculator'
import LoanFor20000 from '@/views/saverium_pl/articles/LoanFor20000'
import LoanFor100000 from '@/views/saverium_pl/articles/LoanFor100000'
import LoanFor200000 from '@/views/saverium_pl/articles/LoanFor200000'
import LoanForEntrepreneurs from '@/views/saverium_pl/articles/LoanForEntrepreneurs'
import ConsumerCredit from '@/views/saverium_pl/articles/ConsumerCredit'
import VacationLoan from '@/views/saverium_pl/articles/VacationLoan'
import Parabanks from '@/views/saverium_pl/articles/Parabanks'
import LoanFor50000 from '@/views/saverium_pl/articles/LoanFor50000'
import LoanFor30000 from '@/views/saverium_pl/articles/LoanFor30000'
import LoanFor10000 from '@/views/saverium_pl/articles/LoanFor10000'
import LoanFor150000 from '@/views/saverium_pl/articles/LoanFor150000'
import LoanFor15000 from '@/views/saverium_pl/articles/LoanFor15000'
import LoanFor40000 from '@/views/saverium_pl/articles/LoanFor40000'
import LoanFor5000 from '@/views/saverium_pl/articles/LoanFor5000'
import LoanFor60000 from '@/views/saverium_pl/articles/LoanFor60000'
import LoanFor25000 from '@/views/saverium_pl/articles/LoanFor25000'
import LoanFor80000 from '@/views/saverium_pl/articles/LoanFor80000'
import LoanFor70000 from '@/views/saverium_pl/articles/LoanFor70000'
import LoanFor65000 from '@/views/saverium_pl/articles/LoanFor65000'
import LoanFor35000 from '@/views/saverium_pl/articles/LoanFor35000'
import LoanFor90000 from '@/views/saverium_pl/articles/LoanFor90000'
import LoanForEveryone from '@/views/saverium_pl/articles/LoanForEveryone'
import Loan3000 from '@/views/saverium_pl/articles/Loan3000'
import LoanFor30Thousand from '@/views/saverium_pl/articles/LoanFor30Thousand'
import UrgentLoanToday from '@/views/saverium_pl/articles/UrgentLoanToday'
import LoanFor6Months from '@/views/saverium_pl/articles/LoanFor6Months'
import AQuickLoanOnMandate from '@/views/saverium_pl/articles/AQuickLoanOnMandate'
import GoodLoan from '@/views/saverium_pl/articles/GoodLoan'
import LoanFor50Thousand from '@/views/saverium_pl/articles/LoanFor50Thousand'
import LoanFor20Thousand from '@/views/saverium_pl/articles/LoanFor20Thousand'
import CarLoan from '@/views/saverium_pl/articles/CarLoan'
import ConsolidationLoan from '@/views/saverium_pl/articles/ConsolidationLoan'
import BusinessLoan from '@/views/saverium_pl/articles/BusinessLoan'
import RevolvingLoan from '@/views/saverium_pl/articles/RevolvingLoan'
import RefinancingLoan from '@/views/saverium_pl/articles/RefinancingLoan'
import LoanForFinishingHouse from '@/views/saverium_pl/articles/LoanForFinishingHouse'
import LoanFor45Days from '@/views/saverium_pl/articles/LoanFor45Days'
import InstallmentLoanNoVerification from '@/views/saverium_pl/articles/InstallmentLoanNoVerification'
import CreditForStatement from '@/views/saverium_pl/articles/CreditForStatement'
import ApartmentRenovationLoan from '@/views/saverium_pl/articles/ApartmentRenovationLoan'
import LoanForFinishingApartment from '@/views/saverium_pl/articles/LoanForFinishingApartment'
import GiroCheckLoan from '@/views/saverium_pl/articles/GiroCheckLoan'
import AQuickLoanForNow from '@/views/saverium_pl/articles/AQuickLoanForNow'
import GrantableLoan from '@/views/saverium_pl/articles/GrantableLoan'
import OnlineNonbankCreditCard from '@/views/saverium_pl/articles/OnlineNonbankCreditCard'
import CreditCardWhatIsIt from '@/views/saverium_pl/articles/CreditCardWhatIsIt'
import ALoanImmediately from '@/views/saverium_pl/articles/ALoanImmediately'
import WhereToBorrowMoney from '@/views/saverium_pl/articles/WhereToBorrowMoney'
import LoansWithoutCreditWorthiness from '@/views/saverium_pl/articles/LoansWithoutCreditWorthiness'
import WhereToGetMoney from '@/views/saverium_pl/articles/WhereToGetMoney'
import FastOnlineCheckout from '@/views/saverium_pl/articles/FastOnlineCheckout'
import PayByCardOverInternet from '@/views/saverium_pl/articles/PayByCardOverInternet'
import InstallmentLoanNoBik from '@/views/saverium_pl/articles/InstallmentLoanNoBik'
import PaydayLoansNoDatabases from '@/views/saverium_pl/articles/PaydayLoansNoDatabases'
import AccountBalance from '@/views/saverium_pl/articles/AccountBalance'
import LoanWithoutPhoneVerification from '@/views/saverium_pl/articles/LoanWithoutPhoneVerification'
import Loans24Hours from '@/views/saverium_pl/articles/Loans24Hours'
import LoanWithNoIncome from '@/views/saverium_pl/articles/LoanWithNoIncome'
import BiggestCashLoan from '@/views/saverium_pl/articles/BiggestCashLoan'
import CashLoanAndFixedContract from '@/views/saverium_pl/articles/CashLoanAndFixedContract'
import LoanAfterOneMonth from '@/views/saverium_pl/articles/LoanAfterOneMonth'
import CashLoanForAnyPurpose from '@/views/saverium_pl/articles/CashLoanForAnyPurpose'
import WhoCanGetALoan from '@/views/saverium_pl/articles/WhoCanGetALoan'
import CashLoanForOnlineStatement from '@/views/saverium_pl/articles/CashLoanForOnlineStatement'
import UsedCarCashLoan from '@/views/saverium_pl/articles/UsedCarCashLoan'
import CashLoanWithoutCreditWorthiness from '@/views/saverium_pl/articles/CashLoanWithoutCreditWorthiness'
import CashLoanProofNoEmployment from '@/views/saverium_pl/articles/CashLoanProofNoEmployment'
import CashLoanWithoutOnlineCertificates from '@/views/saverium_pl/articles/CashLoanWithoutOnlineCertificates'
import LoanOverInternet from '@/views/saverium_pl/articles/LoanOverInternet'
import OnlineInstallmentLoan from '@/views/saverium_pl/articles/OnlineInstallmentLoan'
import CashLoanForHomeRenovation from '@/views/saverium_pl/articles/CashLoanForHomeRenovation'
import CashLoanWithDatabaseEntries from '@/views/saverium_pl/articles/CashLoanWithDatabaseEntries'
import RevolvingLoanWhatIsIt from '@/views/saverium_pl/articles/RevolvingLoanWhatIsIt'
import CashLoanWithMortgage from '@/views/saverium_pl/articles/CashLoanWithMortgage'
import CanYouLoanWithMortgage from '@/views/saverium_pl/articles/CanYouLoanWithMortgage'
import CashLoanStepByStep from '@/views/saverium_pl/articles/CashLoanStepByStep'
import CashLoanFromAbroad from '@/views/saverium_pl/articles/CashLoanFromAbroad'
import JointCashLoan from '@/views/saverium_pl/articles/JointCashLoan'
import CarOrCashLoan from '@/views/saverium_pl/articles/CarOrCashLoan'
import LoanWithoutPartnerConsent from '@/views/saverium_pl/articles/LoanWithoutPartnerConsent'
import CashLoanForMortgage from '@/views/saverium_pl/articles/CashLoanForMortgage'
import CashLoanFixedInterest from '@/views/saverium_pl/articles/CashLoanFixedInterest'
import ContractLoan from '@/views/saverium_pl/articles/ContractLoan'
import EarnHowMuchForLoan from '@/views/saverium_pl/articles/EarnHowMuchForLoan'
import HowMuchWaitingCashLoan from '@/views/saverium_pl/articles/HowMuchWaitingCashLoan'
import IsItWorthTakingCashLoanNow from '@/views/saverium_pl/articles/IsItWorthTakingCashLoanNow'
import DoInterestRatesAffectLoan from '@/views/saverium_pl/articles/DoInterestRatesAffectLoan'
import WhatDocumentsForCashLoan from '@/views/saverium_pl/articles/WhatDocumentsForCashLoan'
let form = FormView

form = FormWrapperView

// Translations for paths, will be used as link urls
const localizedPaths = {
  MainView: '/',
  LogoutView: '/logout',
  LoginView: '/login',
  ResetPasswordView: '/reset_password',
  SetPasswordView: '/set_password/:pk/:key/:ts',
  FaqView: '/faq/',
  LoanView: '/wniosek/',
  CancelDraftView: '/cancel/:draft_uuid',
  MyAccountView: '/account/',
  MyAccountTokenLoginView: '/account/:token',
  OurPartnersView: '/nasi-partnerzy/',
  SmsVerificationView: '/wniosek/sms',
  ArticleView: '/artykuly/',
  OnlinePaydayLoans:
    '/artykuly/pozyczki-chwilowki-online-redyty-chwilowki-przez-internet/',
  CashLoan: '/artykuly/kredyt-gotowkowy-kalkulator-i-oferty-pozyczek-gotowkowych/',
  LoanWithoutBik: '/artykuly/pozyczka-bez-BIK-u-szybkie-pozyczki-online-bez-baz/',
  LoanForInDebt:
    '/artykuly/pozyczki-online-dla-zadluzonych-czy-mozna-ubiegac-sie-o-kredyt/',
  LoansForProof: '/artykuly/pozyczki-na-dowod-przez-internet-chwilowki-na-dowod/',
  OnlineNonbankLoans:
    '/artykuly/pozyczki-pozabankowe-online-kredyty-pozabankowe-prezez-internet/',
  LoanInstallment: '/artykuly/pozyczki-online-na-raty-pozyczki-chwilowki-na-raty/',
  LoansWithoutCertificates:
    '/artykuly/pozyczki-bez-zaswiadczen-online-chwilowki-bez-formalnosci/',
  FreeLoan: '/artykuly/pierwsza-pozyczka-za-darmo-chwilowki-za-0-zl/',
  SocialLoans: '/artykuly/pozyczki-spolecznosciowe-czym-jest-kredyt-spolecznosciowy/',
  LoansToHome: '/artykuly/pozyczki-do-domu-nowosc-pozyczki-udzielane-w-domu-klienta/',
  LoansToUnemployed:
    '/artykuly/pozyczki-dla-bezrobotnych-online-kredyt-dla-niepracujacych/',
  ContinueFillingView: '/application/draft/:uuid/',
  LoanForTwoMonths: '/artykuly/pozyczka-na-61-dni/',
  LoansToAccount: '/artykuly/pozyczki-przez-internet-na-konto-bankowe/',
  PrivateLoans: '/artykuly/pozyczki-prywatne-szybka-pozyczka-prywatna/',
  CreditWorthiness:
    '/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/',
  WhatIsCredit: '/artykuly/kredyt-a-pozyczka-sprawdz-jakie-sa-roznice/',
  TenThousandLoan: '/artykuly/pozyczka-10000-zl-pozyczka-na-dowolny-cel/',
  LoanForThreeMonths: '/artykuly/pozyczka-na-90-dni-chwilowka-na-3-miesiace/',
  LoanForHolidays: '/artykuly/pozyczka-na-swieta-szybka-pozyczka-swiateczna/',
  InstantInternetLoans: '/artykuly/pozyczka-od-reki-chwilowki-od-reki-przez-internet/',
  LoansInFifteenMinutes: '/artykuly/pozyczka-online-w-15-minut-ekspresowe-pozyczki/',
  LoansForCompanies:
    '/artykuly/pozyczki-dla-firm-pozyczki-pozabankowe-dla-przedsiebiorcow/',
  OnlineLongtermLoans:
    '/artykuly/pozyczki-dlugoterminowe-online-pozyczaj-na-24-36-48-60-a-nawet-120-miesiecy/',
  LoanForYoungPeople: '/artykuly/chwilowki-od-18-lat-pozyczki-online-dla-mlodych/',
  NewNonbankLoans: '/artykuly/nowe-pozyczki-pozabankowe-najnowsze-chwilowki-online/',
  LoanWithoutEmploymentContract:
    '/artykuly/pozyczka-bez-umowy-o-prace-jakie-sa-warunki-jej-otrzymania/',
  ShortTermLoans:
    '/artykuly/pozyczki-krotkoterminowe-szybkie-pozyczki-na-61-90-i-120-dni/',
  BestPlaceToTakeLoan: '/artykuly/gdzie-najlepiej-wziac-pozyczke-online/',
  LoanForYear: '/artykuly/pozyczka-na-12-miesiecy-pozyczki-przez-internet-na-rok/',
  LoanFor1200: '/artykuly/pozyczka-1200-zl-atrakcyjne-oferty-pozyczek-na-1200-zl/',
  LoanFor20YearOlds: '/artykuly/chwilowki-od-20-lat-pozyczki-online-od-20-lat/',
  LoanForWedding:
    '/artykuly/kredyt-lub-pozyczka-na-wesele-sposob-na-sfinansowanie-przyjecia/',
  LoanForPensioners:
    '/artykuly/chwilowki-dla-emerytow-pozyczki-online-dla-osob-na-emeryturze/',
  LoanOnDeclaration: '/artykuly/pozyczka-na-oswiadczenie-na-czym-polega/',
  ResponsibleBorrowing:
    '/artykuly/odpowiedzialne-pozyczanie-jak-pozyczac-odpowiedzialnie-i-bezpiecznie/',
  DebtSpiral:
    '/artykuly/spirala-zadluzenia-co-to-jest-jak-nie-wpasc-i-jak-wyjsc-z-dlugow/',
  SafeOnlineBorrowing:
    '/artykuly/bezpieczne-pozyczanie-pieniedzy-przez-internet-na-co-zwracac-uwage/',
  WhatIsRRSO: '/artykuly/co-to-jest-RRSO-jak-obliczyc-i-jak-wplywa-na-wysokosc-kredytu/',
  WhatAreTheseAbbreviations:
    '/artykuly/bik-big-krd-erif-rdn-co-znacza-te-skroty-jak-sprawdzic-rejestry-dluznikow/',
  LoanCalculator: '/artykuly/kalkulator-kredytowy-online-co-to-jest/',
  LoanFor20000:
    '/artykuly/kredyt-20-tys-zlotych-pozyczki-pozabankowe-do-20-000-zl-przez-internet/',
  LoanFor100000:
    '/artykuly/kredyt-100-tys-zlotych-najlepsze-oferty-pozyczek-gotowkowych/',
  LoanFor200000:
    '/artykuly/kredyt-200-tys-zlotych-najlepsze-oferty-pozyczek-gotowkowych/',
  LoanForEntrepreneurs:
    '/artykuly/kredyt-gotowkowy-dla-jednoosobowej-dzialalnosci-gospodarczej/',
  ConsumerCredit: '/artykuly/kredyt-konsumencki-vs-kredyt-konsumpcyjny-jakie-sa-roznice/',
  VacationLoan: '/artykuly/kredyt-na-wakacje/',
  Parabanks: '/artykuly/parabanki-czym-sa-jak-dzialaja-czym-roznia-sie-od-bankow/',
  LoanFor50000: '/artykuly/kredyt-50-tys-online-jakie-warunki-rata/',
  LoanFor30000:
    '/artykuly/kredyt-30-tys-online-jak-dostac-kredyt-30-000-zl-przez-internet/',
  LoanFor10000:
    '/artykuly/kredyt-10-tys-online-jakie-warunki-jak-dostac-kredyt-10-000-zl-przez-internet/',
  LoanFor150000:
    '/artykuly/kredyt-150-tys-online-warunki-i-raty-jak-dostac-kredyt-150-000-zl/',
  LoanFor15000:
    '/artykuly/kredyt-15-tys-online-jakie-sa-warunki-i-raty-kredytu-15-000-zl/',
  LoanFor40000:
    '/artykuly/kredyt-40-tys-online-jakie-warunki-jak-dostac-kredyt-40-000-zl/',
  LoanFor5000:
    '/artykuly/kredyt-5-tys-online-jak-dostac-kredyt-na-5-000-zl-przez-internet/',
  LoanFor60000:
    '/artykuly/kredyt-60-tys-online-jakie-warunki-jak-dostac-kredyt-60-000-zl/',
  LoanFor25000:
    '/artykuly/kredyt-25-tys-online-warunki-rata-jak-dostac-kredyt-25-000-zl-przez-internet/',
  LoanFor80000:
    '/artykuly/kredyt-80-tys-online-warunki-zarobki-jak-dostac-kredyt-80-000-zl/',
  LoanFor70000:
    '/artykuly/kredyt-70-tys-online-warunki-raty-zarobki-jak-dostac-kredyt-70-000-zl/',
  LoanFor65000:
    '/artykuly/kredyt-65-tys-online-skorzystaj-z-kalkulatora-i-wez-kredyt-na-65-000-zl/',
  LoanFor35000:
    '/artykuly/kredyt-35-tys-online-najlepsze-oferty-kredytu-35-000-zl-przez-internet/',
  LoanFor90000:
    '/artykuly/kredyt-90-tys-online-jakie-warunki-jak-dostac-kredyt-90-000-zl/',
  LoanFor20Thousand:
    '/artykuly/pozyczka-20-000-zl-atrakcyjne-oferty-pozyczek-pozabankowych-do-20-000-zl/',
  LoanForEveryone: '/artykuly/pozyczka-dla-kazdego-szybkie-chwilowki-online-w-24h/',
  Loan3000: '/artykuly/pozyczka-3000-zl-chiwilowki-3000-zl-przez-internet-na-raty/',
  LoanFor30Thousand:
    '/artykuly/szybka-pozyczka-30-000-zl-pozyczki-online-do-30-000-zl-na-raty/',
  UrgentLoanToday: '/artykuly/pilna-pozyczka-na-dzisiaj-ekspresowe-chwilowki-na-dzisiaj/',
  LoanFor6Months: '/artykuly/pozyczka-na-6-miesiecy-pozyczka-online-na-pol-roku/',
  AQuickLoanOnMandate: '/artykuly/szybka-pozyczka-na-umowe-zlecenie-lub-umowe-o-dzielo/',
  GoodLoan: '/artykuly/dobra-pozyczka-najlepsze-oferty-chwilowek-z-saverium/',
  LoanFor50Thousand: '/artykuly/pozyczka-50-000-zl-pozyczki-online-do-50-000-zl/',
  CarLoan: '/kredyt-samochodowy/',
  ConsolidationLoan: '/kredyt-konsolidacyjny/',
  BusinessLoan: '/kredyt-dla-firm/',
  RevolvingLoan: '/kredyt-odnawialny/',
  RefinancingLoan: '/kredyt-refinansowowy/',
  LoanForFinishingHouse: '/kredyt-na-wykonczenie-domu/',
  LoanFor45Days: '/artykuly/pozyczka-na-45-dni-chwilowki-online-na-45-dni/',
  InstallmentLoanNoVerification: '/artykuly/pozyczka-na-raty-bez-weryfikacji-jak-zdobyc/',
  CreditForStatement: '/artykuly/kredyt-na-oswiadczenie-zobacz-jak-do-dostac/',
  ApartmentRenovationLoan: '/artykuly/kredyt-na-remont-mieszkania-jak-pozyskac/',
  LoanForFinishingApartment:
    '/artykuly/kredyt-na-wykonczenie-mieszkania-jakie-sa-warunki/',
  GiroCheckLoan: '/artykuly/pozyczka-na-czek-giro-co-to-jest/',
  AQuickLoanForNow: '/artykuly/szybka-pozyczka-na-juz-zobacz-jak-uzyskac/',
  GrantableLoan: '/artykuly/pozyczka-100-przyznawalnosci-jak-sie-ubiegac/',
  OnlineNonbankCreditCard: '/artykuly/pozabankowa-karta-kredytowa-online-co-to-jest/',
  CreditCardWhatIsIt: '/artykuly/co-to-jest-karta-kredytowa-jak-dziala/',
  ALoanImmediately: '/artykuly/pozyczka-od-zaraz-jak-sie-ubiegac/',
  WhereToBorrowMoney: '/artykuly/skad-pozyczyc-pieniadze-jakie-sa-mozliwosci/',
  LoansWithoutCreditWorthiness:
    '/artykuly/pozyczki-bez-zdolnosci-kredytowej-czy-to-mozliwe/',
  WhereToGetMoney: '/artykuly/skad-wziac-pieniadze-na-juz/',
  FastOnlineCheckout: '/artykuly/szybka-kasa-przez-internet-jak-sie-ubiegac/',
  PayByCardOverInternet: '/artykuly/jak-szybko-i-bezpieczne-placic-karta-przez-internet/',
  InstallmentLoanNoBik:
    '/artykuly/pozyczka-na-raty-bez-bik-pozyczki-ratalne-bez-weryfikacji-BIK/',
  PaydayLoansNoDatabases:
    '/artykuly/chwilowki-bez-baz-pozyczki-online-bez-sprawdzania-baz/',
  AccountBalance: '/artykuly/saldo-konta-co-to-jest-jak-sprawdzic/',
  LoanWithoutPhoneVerification: '/artykuly/pozyczka-bez-weryfikacji-telefonicznej/',
  Loans24Hours: '/artykuly/pozyczki-24h-przez-internet-chwilowki/24-7/',
  LoanWithNoIncome: '/artykuly/pozyczka-bez-udokumentowanych/dochodow/',
  BiggestCashLoan: '/artykuly/najwiekszy-kredyt-gotowkowy/',
  CashLoanAndFixedContract: '/artykuly/kredyt-a-umowa-na-czas-okreslony/',
  LoanAfterOneMonth: '/artykuly/kredyt-po-1-miesiacu-pracy/',
  CashLoanForAnyPurpose: '/artykuly/kredyt-gotowkowy-na-dowolny-cel/',
  WhoCanGetALoan: '/artykuly/kto-moze-otrzymac-kredyt/',
  CashLoanForOnlineStatement: '/artykuly/kredyt-gotowkowy-na-oswiadczenie-online/',
  UsedCarCashLoan: '/artykuly/kredyt-gotowkowy-na-samochod-uzywany/',
  CashLoanWithoutCreditWorthiness: '/artykuly/kredyt-gotowkowy-bez-zdolnosci-kredytowej/',
  CashLoanProofNoEmployment: '/artykuly/kredyt-gotowkowy-na-dowod-bez-umowy-o-prace/',
  CashLoanWithoutOnlineCertificates: '/artykuly/kredyt-gotowkowy-bez-zaswiadczen-online/',
  LoanOverInternet: '/artykuly/kredyt-przez-internet/',
  OnlineInstallmentLoan: '/artykuly/kredyt-ratalny/',
  CashLoanForHomeRenovation: '/artykuly/kredyt-gotowkowy-na-remont-domu/',
  CashLoanWithDatabaseEntries: '/artykuly/kredyt-gotowkowy-z-wpisami-w-bazach/',
  RevolvingLoanWhatIsIt: '/artykuly/kredyt-rewolwingowy/',
  CashLoanWithMortgage: '/artykuly/kredyt-gotowkowy-majac-kredyt-hipoteczny/',
  CanYouLoanWithMortgage: '/artykuly/czy-mozna-dostac-kredyt-majac-kredyt-hipoteczny/',
  CashLoanStepByStep: '/artykuly/jak-wziac-kredyt-gotowkowy-krok-po-kroku/',
  CashLoanFromAbroad: '/artykuly/kredyt-gotowkowy-na-dochody-z-zagranicy/',
  JointCashLoan: '/artykuly/wspolny-kredyt-gotowkowy/',
  CarOrCashLoan: '/artykuly/kredyt-samochodowy-czy-gotowkowy/',
  LoanWithoutPartnerConsent: '/artykuly/kredyt-bez-zgody-wspolmalzonka/',
  CashLoanForMortgage: '/artykuly/kredyt-gotowkowy-na-mieszkanie/',
  CashLoanFixedInterest: '/artykuly/kredyt-gotowkowy-ze-stalym-oprocentowaniem/',
  ContractLoan: '/artykuly/kredyt-na-umowe-zlecenie/',
  EarnHowMuchForLoan: '/artykuly/ile-trzeba-zarabiac-aby-dostac-kredyt-gotowkowy/',
  HowMuchWaitingCashLoan: '/artykuly/ile-sie-czeka-na-kredyt-gotowkowy/',
  IsItWorthTakingCashLoanNow: '/artykuly/czy-warto-teraz-brac-kredyt-gotowkowy/',
  DoInterestRatesAffectLoan: '/artykuly/stopy-procentowe-a-kredyt-gotowkowy/',
  WhatDocumentsForCashLoan: '/artykuly/dokumenty-do-kredytu-gotowkowego/',
}

/**
 * This object defains what site spesific routes you have and translations for those.
 * All routes must also have corresponding translation
 */
const langRoutes = [
  {
    name: 'CancelDraftView',
    component: CancelDraftView,
  },
  {
    name: 'MainView',
    component: MainView,
    meta: {
      isPublic: true,
      title: 'Pożyczki online - szybkie pożyczki przez internet | Saverium',
      description:
        'Pożyczki online ✅ Szybkie pożyczki przez internet z Saverium ✅ Uzyskaj szybką pożyczke online w prosty sposób ✅ Wypełnij wniosek i otrzymaj oferty!',
    },
  },
  {
    name: 'ContinueFillingView',
    component: form,
    meta: {
      title: 'Zaaplikuj o pożyczkę bez zabezpieczeń i poręczycieli - Saverium.pl',
    },
  },
  {
    name: 'FaqView',
    component: FaqView,
    meta: {
      title: 'Najczęściej zadawane pytania | Saverium.pl',
      description:
        'Sprawdź najczęściej zadawane pytania ✅ Znajdziesz tutaj odpowiedzi na najbardziej nurtujące pytania ✅ Wejdź na stronę Saverium.pl',
      isPublic: true,
    },
  },
  /* {
    name: 'LoanView',
    component: form,
    meta: {
      title: 'Zaaplikuj o pożyczkę bez zabezpieczeń i poręczycieli - Saverium.pl',
    },
  }, */

  /* {
    name: 'MyAccountView',
    component: MyAccountView,
    meta: {
      title: 'Moje konto - Saverium.pl',
      requireLogin: true,
    },
  }, */
  {
    name: 'MyAccountTokenLoginView',
    component: TokenLoginView,
    meta: {
      title: 'Moje konto - Saverium.pl',
      isPublic: true,
    },
  },
  {
    name: 'LogoutView',
    component: LogoutView,
    meta: {
      title: 'Saverium.pl',
      isPublic: true,
    },
  },
  {
    name: 'LoginView',
    component: LoginView,
    meta: {
      title: 'Saverium.pl',
      isPublic: true,
    },
  },
  {
    name: 'ResetPasswordView',
    component: ResetPasswordView,
    meta: {
      title: 'Saverium.pl',
      isPublic: true,
    },
  },
  {
    name: 'SetPasswordView',
    component: SetPasswordView,
    meta: {
      title: 'Saverium.pl',
      isPublic: true,
    },
  },
  {
    name: 'OurPartnersView',
    component: OurPartnersView,
    meta: {
      title: 'Nasi partnerzy pożyczkowi i kredytowi | Saverium',
      isPublic: true,
      description:
        'Współpracujemy z renomowanymi firmamy pożyczkowymi ✅ Sprawdź z kim współpracujemy ✅ Wypełnij wniosek pożyczkowy online',
    },
  },
  {
    name: 'SmsVerificationView',
    component: SmsVerificationView,
    meta: {
      title: 'Saverium.pl',
      isPublic: true,
    },
  },
  {
    name: 'ArticleView',
    component: ArticleView,
    meta: {
      title: 'Artykuły pożyczkowe i kredytowe | Saverium.pl',
      description:
        'Eksperckie artykuły dotyczące pożyczek i kredytów ✅ Rzetelne informacje na wyciągnięcie ręki ✅ Wejdź na stronę i sprawdź nasze artykuły',
      isPublic: true,
    },
  },
  {
    name: 'OnlinePaydayLoans',
    component: OnlinePaydayLoans,
    meta: {
      title: 'Chwilówki online - szybkie pożyczki chwilówki przez internet',
      description:
        'Chwilówki online ✅ Pożyczki chwilówki przez internet z Saverium ✅ Bezpiecznie i z niskim oprocentowaniem ✅ Wypełnij wniosek online',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/payday-loan-couple.jpg',
    },
    props: {
      headline: 'Pożyczki chwilówki online – kredyty chwilówki przez internet',
      datePublished: '2022-04-25',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/payday-loan-couple.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CashLoan',
    component: CashLoan,
    meta: {
      title: 'Kredyt gotówkowy - szybka pożyczka gotówkowa online',
      description:
        'Czym jest kredyt gotówkowy i czym różni się od pożyczki? ✅ Z Saverium weźmiesz pozyczkę gotówkową nawet do 200 000 zł  ✅ Sprawdź',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/polish-money-hand.jpg',
    },
    props: {
      headline: 'Kredyt gotówkowy - szybka pożyczka gotówkowa online',
      datePublished: '2020-12-07',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/polish-money-hand.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanWithoutBik',
    component: LoanWithoutBik,
    meta: {
      title: 'Pożyczka bez bik - chwilówki online bez biku | Saverium',
      description:
        'Pożyczka bez bik, inaczej nazywane chwilówkami bez bik ✅ Zobacz czy możliwe jest wzięcie pożyczki bez weryfikacji w bazach dłużników',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/bik-loan.jpg',
    },
    props: {
      headline: 'Pożyczka bez bik - chwilówki online bez biku | Saverium',
      datePublished: '2020-11-25',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/bik-loan.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanForInDebt',
    component: LoanForInDebt,
    meta: {
      title: 'Pożyczki dla zadłużonych online | Saverium',
      description:
        'Pożyczki dla zadłużonych online ✅ Sprawdź czy można ubiegać się o pożyczkę lub kredyt, będąc osobą zadłużoną ✅ Odwiedź Saverium',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/thinking-man.jpg',
    },
    props: {
      headline: 'Pożyczki dla zadłużonych online | Saverium',
      datePublished: '2020-12-02',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/thinking-man.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoansForProof',
    component: LoansForProof,
    meta: {
      title: 'Pożyczki na dowód przez Internet – chwilówki na dowód',
      description:
        'Pożyczki na dowód przez internet ✅ Chwilówki na dowód online ✅ Bezpiecznie, szybko i na korzystnych warunkach ✅ Sprawdź nas',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/wallet-id.jpg',
    },
    props: {
      headline: 'Pożyczki na dowód przez Internet – chwilówki na dowód',
      datePublished: '2022-04-25',
      type: 'Article',
      image: 'static/img/saverium_pl/article-images/wallet-id.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'OnlineNonbankLoans',
    component: OnlineNonbankLoans,
    meta: {
      title: 'Pożyczki pozabankowe online - przez internet | Saverium',
      description:
        'Pożyczki pozabankowe online ✅ Szybko i bezpiecznie pożyczysz potrzebną sumę pieniędzy ✅ Korzystne warunki i niskie oprocentowanie ✅ Sprawdź',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/online-nonbank.jpg',
    },
    props: {
      headline:
        'Pożyczki pozabankowe online – oferta kredytów pozabankowych przez internet',
      datePublished: '2022-04-25',
      type: 'Article',
      image: 'static/img/saverium_pl/article-images/online-nonbank.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanInstallment',
    component: LoanInstallment,
    meta: {
      title: 'Pożyczki online na raty - chwilówki na raty | Saverium',
      description:
        'Pożyczki online na raty ✅ Sprawdź ofertę, szybkich pożyczek ratalnych ✅ Uzyskaj szybką i bezpieczną pożyczkę na korzystnych warunkach',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-installment.jpg',
    },
    props: {
      headline: 'Pożyczki online na raty - chwilówki na raty',
      datePublished: '2020-11-19',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-installment.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoansWithoutCertificates',
    component: LoansWithoutCertificates,
    meta: {
      title: 'Pożyczki bez zaświadczeń o dochodach online | Saverium',
      description:
        'Pożyczki bez zaświadczeń przez internet ✅ Oferta szybkich pożyczek chwilówek bez formalności ✅ Sprawdź, jak uzyskać pożyczkę',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loans-without-certificate.jpg',
    },
    props: {
      headline: 'Pożyczki bez zaświadczeń o dochodach online',
      datePublished: '2022-04-25',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loans-without-certificate.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'FreeLoan',
    component: FreeLoan,
    meta: {
      title: 'Pierwsza pożyczka za darmo - chwilówki za 0 zł | Saverium',
      description:
        'Pierwsza pożyczka za darmo ✅ Sprawdź czym jest pierwsza darmowa pożyczka online ✅ Odwiedź Saverium i dowiedz się więcej na temat pożyczek',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/free-loan.jpg',
    },
    props: {
      headline: 'Pierwsza pożyczka za darmo - chwilówki za 0 zł | Saverium',
      datePublished: '2020-11-23',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/free-loan.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'SocialLoans',
    component: SocialLoans,
    meta: {
      title: 'Pożyczki społecznościowe - czym są, jak działają? | Saverium',
      description:
        'Pożyczki społecznościowe ✅ Dowiedz się czym są i jak działają pożyczki społecznościowe ✅ Sprawdź jak otrzymać pożyczkę społecznościwą',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/social-loan.jpg',
    },
    props: {
      headline: 'Pożyczki społecznościowe - czym są, jak działają? | Saverium',
      datePublished: '2020-12-01',
      type: 'Article',
      image: 'static/img/saverium_pl/article-images/social-loan.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoansToHome',
    component: LoansToHome,
    meta: {
      title: 'Pożyczki do domu - pożyczki udzielane w domu',
      description:
        'Pożyczki do domu ✅ Pożyczki udzielane w domu to nowość na rynku finansowym ✅ Sprawdź jak wygląda proces udzielania pożyczek w domu',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loans-to-home.jpg',
    },
    props: {
      headline: 'Pożyczki do domu - pożyczki udzielane w domu',
      datePublished: '2020-11-15',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loans-to-home.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoansToUnemployed',
    component: LoansToUnemployed,
    meta: {
      title: 'Pożyczki dla bezrobotnych online - pożyczki dla niepracujących',
      description:
        'Pożyczki dla bezrobotnych online ✅ Jakie są warunki pożyczki dla niepracujących? ✅ Ile można pożyczyć osoba bezrobotna? ✅ Sprawdź ',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-to-unemployed.jpg',
    },
    props: {
      headline: 'Pożyczki dla bezrobotnych online - pożyczki dla niepracujących',
      datePublished: '2020-12-04',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-to-unemployed.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanForTwoMonths',
    component: LoanForTwoMonths,
    meta: {
      title: 'Pożyczka na 61 dni – chwilówka na 2 miesiące',
      description:
        'Pożyczka na 61 dni ✅ Sprawdź warunki otrzymania pożyczki chwilówki na 2 miesiące ✅ Korzystne warunki pożyczki z Saverium ✅ Wypelnij wniosek online',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-for-two-months.jpg',
    },
    props: {
      headline: 'Pożyczka na 61 dni – chwilówka na 2 miesiące',
      datePublished: '2020-12-14',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-for-two-months.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoansToAccount',
    component: LoansToAccount,
    meta: {
      title: 'Pożyczki przez Internet na konto bankowe - całkowicie online',
      description:
        'Pożyczka przez internet na konto bankowe ✅ Z Saverium w prosty i bezpieczny sposób pożyczysz, potrzebną sumę online ✅ Sprawdź!',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-to-account.jpg',
    },
    props: {
      headline: 'Pożyczki przez Internet na konto bankowe - całkowicie online',
      datePublished: '2022-04-25',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-to-account.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'PrivateLoans',
    component: PrivateLoans,
    meta: {
      title: 'Pożyczki prywatne – szybka pożyczka prywatna',
      description:
        'Pożyczki prywatne ✅ Sprawdź czym są i na czym polegają pożyczki prywatne ✅ Dowiedz się o wadach i zaletach ✅ Sprawdź Saverium.pl',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/private-loans.jpg',
    },
    props: {
      headline: 'Pożyczki prywatne – szybka pożyczka prywatna',
      datePublished: '2020-12-17',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/private-loans.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CreditWorthiness',
    component: CreditWorthiness,
    meta: {
      title:
        'Zdolność kredytowa – czym jest? Jak sprawdzić i poprawić zdolność kredytową?',
      description:
        'Sprawdź czym jest zdolność kredytowa ✅ Dowiedz się jak sprawdzić i poprawić swoją zdolność kreedytową ✅ Odwiedź poradnik Saverium.pl',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/private-loans.jpg',
    },
    props: {
      headline:
        'Zdolność kredytowa – czym jest? Jak sprawdzić i poprawić zdolność kredytową?',
      datePublished: '2020-12-12',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/private-loans.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'WhatIsCredit',
    component: WhatIsCredit,
    meta: {
      title: 'Kredyt a pożyczka – sprawdź, jakie są różnice',
      description:
        'Kredyt a pożyczka ✅ Sprawdź jakie są różnice pomiędzy kredytem a pożyczką ✅ Dowiedz się jak uzyskać pożyczkę pozabankową ✅ Sprawdź Saverium.pl',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/what-is-credit.jpg',
    },
    props: {
      headline: 'Kredyt a pożyczka – sprawdź, jakie są różnice',
      datePublished: '2020-12-18',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/what-is-credit.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'TenThousandLoan',
    component: TenThousandLoan,
    meta: {
      title: 'Pożyczka 10 000 zł – pożyczka na dowolny cel',
      description:
        'Pożyczka 10 000 zł ✅ Pożyczka online na dowolny cel ✅ Sprawdź jakie są warunki uzyskania pożyczki i wypełnij wniosek online ✅ Decyzja w 15 minut',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/10000-loan.jpg',
    },
    props: {
      headline: 'Pożyczka 10 000 zł – pożyczka na dowolny cel',
      datePublished: '2020-12-18',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/10000-loan.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanForThreeMonths',
    component: LoanForThreeMonths,
    meta: {
      title: 'Pożyczka na 90 dni – chwilówka na 3 miesiące',
      description:
        'Pożyczka na 90 dni ✅ Chwilówka online z okresem spłaty na 3 miesiące ✅ Sprawdź jak uzyskać pożyczkę na 90 dni ✅ Odwiedź Saverium.pl',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-for-90-days.jpg',
    },
    props: {
      headline: 'Pożyczka na 90 dni – chwilówka na 3 miesiące',
      datePublished: '2020-12-18',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-for-90-days.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanForHolidays',
    component: LoanForHolidays,
    meta: {
      title: 'Pożyczka na święta - szybka pożyczka świąteczna',
      description:
        'Pożyczka na święta 🎄 Szybka pożyczka świąteczna 🎅 Weź pożyczkę online na korzystnych warunkach ✅ Wypełnij wniosek i natychmiast otrzymaj oferty!',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-for-christmas.jpg',
    },
    props: {
      headline: 'Pożyczka na święta - szybka pożyczka świąteczna',
      datePublished: '2020-12-18',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-for-christmas.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'InstantInternetLoans',
    component: InstantInternetLoans,
    meta: {
      title: 'Pożyczka od ręki – szybkie chwilówki od ręki przez internet',
      description:
        'Pożyczka od ręki ✅ Sprawdź, jak uzyskać szybką chwilówkę od ręki online ✅ Wypełnij wniosek i uzyskaj pożyczkę przez internet',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/instant-internet-loans.jpg',
    },
    props: {
      headline: 'Pożyczka od ręki – chwilówki od ręki przez internet',
      datePublished: '2022-04-25',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/instant-internet-loans.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoansInFifteenMinutes',
    component: LoansInFifteenMinutes,
    meta: {
      title: 'Pożyczka online w 15 minut – ekspresowe pożyczki | Saverium',
      description:
        'Pożyczka online w 15 minut ✅ Ekspresowe pożyczki online z Saverium ✅ Uzyskaj szybką chwilówkę na korzystnych warunkach',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loans-in-15-minutes.jpg',
    },
    props: {
      headline: 'Pożyczka online w 15 minut – ekspresowe pożyczki',
      datePublished: '22020-12-01',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loans-in-15-minutes.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoansForCompanies',
    component: LoansForCompanies,
    meta: {
      title: 'Pożyczki dla firm – pożyczki pozabankowe dla przedsiębiorców',
      description:
        'Pożyczki dla firm ✅ Dowiedz sięna czym polegają pożyczki pozabankowe dla przedsiębiorców ✅ Jakie sa warunki i ile można pożyczyć ✅ Sprawdź',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loans-for-companies.jpg',
    },
    props: {
      headline: 'Pożyczki dla firm – pożyczki pozabankowe dla przedsiębiorców',
      datePublished: '22020-12-01',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loans-for-companies.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'OnlineLongtermLoans',
    component: OnlineLongtermLoans,
    meta: {
      title: 'Pożyczki długoterminowe online – na raty | Saverium',
      description:
        'Pożyczki długoterminowe online ⭐ Uzyskaj pożyczkę na 12, 24, 36, 48 miesięcy lub dłużej ⭐ Wypełnij wniosek ☛ Sprawdź Saverium.pl',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/online-longterm-loans.jpg',
    },
    props: {
      headline: 'Pożyczki długoterminowe online – do kilku lat | Saverium',
      datePublished: '2022-04-25',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/online-longterm-loans.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanForYoungPeople',
    component: LoanForYoungPeople,
    meta: {
      title: 'Chwilówki od 18 lat – pożyczki online dla młodych',
      description:
        'Chwilówki od 18 lat ✅ Szybkie pożyczki online dla młodych osób ✅ Sprawdź naszą ofertę  i wypełnij tylko jeden prosty wniosek online',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loans-for-18-year-olds.jpg',
    },
    props: {
      headline: 'Chwilówki od 18 lat – pożyczki online dla młodych',
      datePublished: '2021-01-11',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loans-for-18-year-olds.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'NewNonbankLoans',
    component: NewNonbankLoans,
    meta: {
      title: 'Nowe pożyczki pozabankowe – najnowsze chwilówki online',
      description:
        'Nowe pożyczki pozabankowe ✅ Sprawdź czym są najnowsze chwilówki i zapoznaj się z naszą ofertą ✅ Wypełnij tylko jeden prosty wniosek',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/new-nonbank-loan.jpg',
    },
    props: {
      headline: 'Nowe pożyczki pozabankowe – najnowsze chwilówki online',
      datePublished: '2021-01-11',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/new-nonbank-loan.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanWithoutEmploymentContract',
    component: LoanWithoutEmploymentContract,
    meta: {
      title: 'Pożyczka bez umowy o pracę – jakie są warunki jej otrzymania?',
      description:
        'Pożyczka bez umowy o pracę ✅ Jakie są warunki otrzymania pożyczki, nie mając umowy o pracę? ✅ Sprawdź nasz artykuł i wypełnij prosty wniosek',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-without-employment-contract.jpg',
    },
    props: {
      headline: 'Pożyczka bez umowy o pracę – jakie są warunki jej otrzymania?',
      datePublished: '2021-01-11',
      type: 'Article',
      image:
        '/static/img/saverium_pl/article-images/loan-without-employment-contract.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'ShortTermLoans',
    component: ShortTermLoans,
    meta: {
      title: 'Pożyczki krótkoterminowe online | Saverium.pl',
      description:
        'Pożyczki krótkoterminowe online ☛ pożyczaj pieniądze na 61, 90 i 120dni ☛ Sprawdź warunki uzyskania i złóż wniosek na Saverium.pl',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/online-longterm-loans.jpg',
    },
    props: {
      headline: 'Pożyczki krótkoterminowe online | Saverium.pl',
      datePublished: '2021-01-12',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/online-longterm-loans.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'BestPlaceToTakeLoan',
    component: BestPlaceToTakeLoan,
    meta: {
      title: 'Gdzie najlepiej wziąć pożyczkę? Najlepsze pożyczki 2021',
      description:
        'Zastanawiasz się, gdzie najlepiej wziąć pożyczkę? ✅ Odpowiemy Ci na to pytanie ✅ Wypełnij tylko jeden wniosek ✅ Dowiedz się więcej na naszej stronie',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/best-place-for-loan.jpg',
    },
    props: {
      headline: 'Gdzie najlepiej wziąć pożyczkę? Najlepsze pożyczki 2021',
      datePublished: '2021-01-22',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/best-place-for-loan.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanForYear',
    component: LoanForYear,
    meta: {
      title: 'Pożyczka na 12 miesięcy – pożyczka online na rok | Saverium',
      description:
        'Poszukujesz atrakcyjnej pożyczki na 12 miesięcy? ✅ Skorzystaj z platformy Saverium ✅ Wypełnij jeden wniosek, aby otrzymać oferty pożyczek online',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/online-longterm-loans.jpg',
    },
    props: {
      headline: 'Pożyczka na 12 miesięcy – pożyczka online na rok | Saverium',
      datePublished: '2021-01-22',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/online-longterm-loans.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor1200',
    component: LoanFor1200,
    meta: {
      title: 'Pożyczka 1200 zł – atrakcyjne chwilówki do 1200 zł | Saverium',
      description:
        'Pożyczka do 1200 zł z Saverium ✅ Złóż tylko jeden wniosek i otrzymaj atrakcyjne oferty ✅ Skorzystaj z naszej platformy pożyczkowej',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-for-1200.jpg',
    },
    props: {
      headline: 'Pożyczka 1200 zł – atrakcyjne chwilówki do 1200 zł | Saverium',
      datePublished: '2021-02-05',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-for-1200.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor20YearOlds',
    component: LoanFor20YearOlds,
    meta: {
      title: 'Chwilówki od 20 lat – pożyczki online od 20 lat | Saverium',
      description:
        'Skorzystaj z oferty szybkich pożyczek od 20 lat ✅ Wypełnij tylko jeden wniosek, aby otrzymać atrakcyjne oferty ✅ Sprawdź platformę Saverium',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-for-20-year-olds.jpg',
    },
    props: {
      headline: 'Chwilówki od 20 lat – pożyczki online od 20 lat | Saverium',
      datePublished: '2021-02-05',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-for-20-year-olds.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanForWedding',
    component: LoanForWedding,
    meta: {
      title: 'Pożyczka na wesele 2022 – kredyt na wesele | Saverium',
      description:
        'Planujesz wesele i potrzebujesz środków finansowych? ✅ Skorzystaj z oferty Saverium ✅ Wypełnij tylko jeden prosty wniosek, aby otrzymać oferty!',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-for-wedding.jpg',
    },
    props: {
      headline: 'Pożyczka na wesele 2022 – kredyt na wesele | Saverium',
      datePublished: '2022-05-30',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-for-wedding.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanForPensioners',
    component: LoanForPensioners,
    meta: {
      title: 'Pożyczki chwilówki dla emerytów – sprawdź ofertę Saverium',
      description:
        'Pożyczki chwilówki dla osób na emeryturze ✅ Wypełnij jeden prosty wniosek o pożyczkę online ✅ Sprawdź ofertę platformy pożyczkowej Saverium',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-for-pensioners.jpg',
    },
    props: {
      headline: 'Pożyczki chwilówki dla emerytów – sprawdź ofertę Saverium',
      datePublished: '2021-02-05',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-for-pensioners.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanOnDeclaration',
    component: LoanOnDeclaration,
    meta: {
      title: 'Pożyczka na oświadczenie online - sprawdź warunki kredytu',
      description:
        'Pożyczka na oświadczenie przez internet ✅ Sprawdź jakie są warunki otrzymania kredytu na oświadczenie ✅ Sprawdź możliwości pożyczkowe!',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-on-declaration.jpg',
    },
    props: {
      headline: 'Pożyczka na oświadczenie online - sprawdź warunki kredytu',
      datePublished: '2021-02-05',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-on-declaration.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'ResponsibleBorrowing',
    component: ResponsibleBorrowing,
    meta: {
      title: 'Odpowiedzialne pożyczanie – co to oznacza? | Saverium',
      description:
        'Co oznacza odpowiedzialne pożyczanie? ✅ Sprawdź, w jaki sposób odpowiedzialnie i bezpiecznie pożyczać pieniądze online ✅ Odwiedź Saverium.pl',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/responsible-borrowing.jpg',
    },
    props: {
      headline: '',
      datePublished: '2021-02-23',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'DebtSpiral',
    component: DebtSpiral,
    meta: {
      title: 'Spirala zadłużenia – co to jest? Jak wyjść ze spirali długów?',
      description:
        'Sprawdź, czym jest spirala zadłużenia. Dowiedz się, jak nie wpaść w spiralę zadłużenia. Przeczytaj praktyczne porady, jak wyjść ze spirali długów.',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/debt-spiral.jpg',
    },
    props: {
      headline: 'Spirala zadłużenia – co to jest? Jak wyjść ze spirali długów?',
      datePublished: '2021-02-23',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/debt-spiral.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'SafeOnlineBorrowing',
    component: SafeOnlineBorrowing,
    meta: {
      title: 'Bezpieczne pożyczanie pieniędzy przez Internet | Saverium',
      description:
        'Czy wiesz, jak bezpiecznie pożyczać pieniądze przez inernet? Sprawdź jakich zasad przestrzegać. Odwiedź Saverium.pl',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/safe-online-borrowing.jpg',
    },
    props: {
      headline: 'Bezpieczne pożyczanie pieniędzy przez Internet | Saverium',
      datePublished: '2021-02-23',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/safe-online-borrowing.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'WhatIsRRSO',
    component: WhatIsRRSO,
    meta: {
      title: 'RRSO - co to jest, jak obliczyć? | Saverium',
      description:
        'Sprawdź, co oznacza termin RRSO i jak wpływa na kredyt lub pożyczkę. Dowiedz się, jak obliczyć RRSO. Odwiedź Saverium.pl',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/what-is-rrso.jpg',
    },
    props: {
      headline: 'RRSO - co to jest, jak obliczyć? | Saverium',
      datePublished: '2021-02-23',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/what-is-rrso.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'WhatAreTheseAbbreviations',
    component: WhatAreTheseAbbreviations,
    meta: {
      title: 'BIK, BIG, KRD – informacje o bazach dłużników | Saverium',
      description:
        'Do baz dłużników mogą trafić wszystkie osoby, które nie spłacają terminowo swoich zobowiązań. Dowiedz się, czym są BIK, BIG, KRD i inne instytucje.',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/what-are-these.jpeg',
    },
    props: {
      headline: 'BIK, BIG, KRD – informacje o bazach dłużników | Saverium',
      datePublished: '2021-03-19',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/what-are-these.jpeg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanCalculator',
    component: LoanCalculator,
    meta: {
      title: 'Kalkulator kredytowy –  kredytu gotówkowego i pożyczek online',
      description:
        'Kalkulatory kredytowy ➡️ Wprowadź kwotę pożyczki oraz okres spłaty i poznaj miesięczną ratę ➡️ Skorzystaj z naszego kalkulatora kredytowego',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-calculator.jpeg',
    },
    props: {
      headline: 'Kalkulator kredytowy –  kredytu gotówkowego i pożyczek online',
      datePublished: '22.3.2021',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-calculator.jpeg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor20000',
    component: LoanFor20000,
    meta: {
      title: 'Kredyt 20 tyś złotych na dowolny cel – najlepsze oferty',
      description:
        'Interesuje Cię kredyt na 20 000 zł na dowolny cel? Wybierz Saverium. Wypełnij 1 wniosek, a decyzję i pieniądze na koncie otrzymasz błyskawicznie!',
      isPublic: true,
      img: '',
    },
    props: {
      headline: 'Kredyt 20 tyś złotych na dowolny cel – najlepsze oferty',
      datePublished: '2021-04-07',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor100000',
    component: LoanFor100000,
    meta: {
      title: 'Kredyt 100 tyś złotych na dowolny cel – najlepsze oferty',
      description:
        'Chcesz wziąć kredyt do 100 000 zł na dowolny cel? Wejdź na stronę Saverium.pl, wybierz wysokość pożyczki i okres spłaty oraz wypełnij prosty wniose',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-for-100000.jpeg',
    },
    props: {
      headline: 'Kredyt 100 tyś złotych na dowolny cel – najlepsze oferty',
      datePublished: '2021-04-07',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-for-100000.jpeg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor200000',
    component: LoanFor200000,
    meta: {
      title: 'Kredyt 200 tys. złotych na dowolny cel - najlepsze oferty',
      description:
        'Sprawdź oferty wzięcia kredytu do 200 000 zł na dowolny cel. Skorzystaj z kalkulatora Saverium, wybierz długość kredytu i poznaj miesięczną ratę.',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-for-200000.jpeg',
    },
    props: {
      headline: 'Kredyt 200 tys. złotych na dowolny cel - najlepsze oferty',
      datePublished: '2021-04-07',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-for-200000.jpeg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanForEntrepreneurs',
    component: LoanForEntrepreneurs,
    meta: {
      title: 'Kredyt dla jednoosobowej działalności gospodarczej | Saverium',
      description:
        'Zastanawiasz się nad wzięciem kredytu dla jednoosobowej działalności gospodarczej? Poznaj szczegóły zobowiązania na Saverium.pl',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/loan-for-entrepreneurs.jpeg',
    },
    props: {
      headline: 'Kredyt dla jednoosobowej działalności gospodarczej | Saverium',
      datePublished: '2021-04-07',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-for-entrepreneurs.jpeg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'ConsumerCredit',
    component: ConsumerCredit,
    meta: {
      title: 'Kredyt konsumencki vs kredyt konsumpcyjny – jaka różnica?',
      description:
        'Kredyt konsumencki nie jest tym samym co kredyt konsumpcyjny ➡️ Poznaj różnice pomiędzy tym kredytami ➡️ Dowiedz się więcej na Saverium.pl',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/consumer-credit.jpeg',
    },
    props: {
      headline: 'Kredyt konsumencki vs kredyt konsumpcyjny – jaka różnica?',
      datePublished: '2021-04-07',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/consumer-credit.jpeg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'VacationLoan',
    component: VacationLoan,
    meta: {
      title: 'Kredyt na wakacje 2022 – pożyczka wakacyjna | Saverium',
      description:
        'Pragniesz odpocząć i wyjechać na wymarzone wakacje? Sprawdź co dla Ciebie przygotowaliśmy. Wypełnij 1 prosty wniosek o pożyczkę online na raty.',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/vacation-loan.jpeg',
    },
    props: {
      headline: 'Kredyt na wakacje 2022 – pożyczka wakacyjna | Saverium',
      datePublished: '2022-05-30',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/vacation-loan.jpeg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'Parabanks',
    component: Parabanks,
    meta: {
      title: 'Parabanki – czym są, jak działają, różnice względem banku',
      description:
        'Zastanawiasz się czym są parabanki? Dowiedz się czym są i jak działają parabanki oraz czym różnią się względem banków. Odwiedź Saverium.pl',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/parabanks.jpeg',
    },
    props: {
      headline: 'Parabanki – czym są, jak działają, różnice względem banku',
      datePublished: '2021-04-07',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/parabanks.jpeg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor50000',
    component: LoanFor50000,
    meta: {
      title: 'Kredyt 50 tys online - sprawdź warunki i weź kredyt 50 000 zł',
      description:
        'Kredyt 50 tys. zł przez internet ➤ Sprawdź warunki oraz wysokość raty ➤ Dowiedz się, jak wziąć kredyt do 50 000 zł przez internet. Odwiedź Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt 50 tys online - sprawdź warunki i weź kredyt 50 000 zł',
      datePublished: '2021-05-21',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor30000',
    component: LoanFor30000,
    meta: {
      title: 'Kredyt 30 tys online - sprawdź warunki i weź kredyt 30 000 zł',
      description:
        'Kredyt 30 tys. zł przez internet ➤ Sprawdź warunki oraz wysokość raty ➤ Dowiedz się, jak wziąć kredyt do 30 000 zł online. Odwiedź Saverium.pl',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt 30 tys online - sprawdź warunki i weź kredyt 30 000 zł',
      datePublished: '2021-09-09',
      type: 'Article',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor10000',
    component: LoanFor10000,
    meta: {
      title: 'Kredyt 10 tys online - sprawdź warunki i weź kredyt 10 000 zł',
      description:
        'Kredyt 10 tys. zł przez internet ➤ Sprawdź warunki oraz wysokość raty ➤ Dowiedz się, jak wziąć kredyt do 10 000 zł przez internet w Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt 10 tys online - sprawdź warunki i weź kredyt 10 000 zł',
      datePublished: '2021-05-29',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor150000',
    component: LoanFor150000,
    meta: {
      title: 'Kredyt 150 tys online - sprawdź warunki i weź kredyt 150 000 zł',
      description:
        'Kredyt 150 tys. zł przez internet ➤ Sprawdź warunki oraz wysokość raty ➤ Dowiedz się, jak wziąć kredyt do 150 000 zł online w Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt 150 tys online - sprawdź warunki i weź kredyt 150 000 zł',
      datePublished: '2021-05-29',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor15000',
    component: LoanFor15000,
    meta: {
      title: 'Kredyt 15 tys online - sprawdź warunki i weź kredyt 15 000 zł',
      description:
        'Kredyt 15 tys. zł przez internet ➤ Sprawdź warunki oraz wysokość raty ➤ Dowiedz się, jak wziąć kredyt do 15 000 zł online w Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt 15 tys online - sprawdź warunki i weź kredyt 15 000 zł',
      datePublished: '2021-05-29',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor40000',
    component: LoanFor40000,
    meta: {
      title: 'Kredyt 40 tys online - sprawdź warunki i weź kredyt 40 000 zł',
      description:
        'Kredyt 40 tys. zł przez internet ➤ Sprawdź warunki oraz wysokość raty ➤ Dowiedz się, jak wziąć kredyt do 40 000 zł online w Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt 40 tys online - sprawdź warunki i weź kredyt 40 000 zł',
      datePublished: '2021-05-29',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor5000',
    component: LoanFor5000,
    meta: {
      title: 'Kredyt 5 tys online - sprawdź warunki i weź kredyt 5 000 zł',
      description:
        'Kredyt 5 tys. zł przez internet ➤ Sprawdź warunki oraz wysokość raty ➤ Dowiedz się, jak wziąć kredyt do 5 000 zł online w Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt 5 tys online - sprawdź warunki i weź kredyt 5 000 zł',
      datePublished: '2021-05-29',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor60000',
    component: LoanFor60000,
    meta: {
      title: 'Kredyt 60 tys online - sprawdź warunki i weź kredyt 60 000 zł',
      description:
        'Kredyt 60 tys. zł przez internet ➤ Sprawdź warunki oraz wysokość raty ➤ Dowiedz się, jak wziąć kredyt do 60 000 zł online w Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt 60 tys online - sprawdź warunki i weź kredyt 60 000 zł',
      datePublished: '2021-05-29',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor25000',
    component: LoanFor25000,
    meta: {
      title: 'Kredyt 25 tys online - sprawdź warunki i weź kredyt 25 000 zł',
      description:
        'Kredyt 25 tys. zł przez internet ➤ Sprawdź warunki oraz wysokość raty ➤ Dowiedz się, jak wziąć kredyt do 25 000 zł online w Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt 25 tys online - sprawdź warunki i weź kredyt 25 000 zł',
      datePublished: '2021-05-29',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor80000',
    component: LoanFor80000,
    meta: {
      title: 'Kredyt 80 tys online - sprawdź warunki i weź kredyt 80 000 zł',
      description:
        'Kredyt 80 tys. zł przez internet ➤ Sprawdź warunki oraz wysokość raty ➤ Dowiedz się, jak wziąć kredyt do 80 000 zł online w Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt 80 tys online - sprawdź warunki i weź kredyt 80 000 zł',
      datePublished: '2021-05-29',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor70000',
    component: LoanFor70000,
    meta: {
      title: 'Kredyt 70 tys online - sprawdź warunki i weź kredyt 70 000 zł',
      description:
        'Kredyt 70 tys. zł przez internet ➤ Sprawdź warunki oraz wysokość raty ➤ Dowiedz się, jak wziąć kredyt do 70 000 zł online w Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt 70 tys online - sprawdź warunki i weź kredyt 70 000 zł',
      datePublished: '2021-05-29',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor65000',
    component: LoanFor65000,
    meta: {
      title: 'Kredyt 65 tys online - sprawdź warunki i weź kredyt 65 000 zł',
      description:
        'Kredyt 65 tys. zł przez internet ➤ Sprawdź warunki oraz wysokość raty ➤ Dowiedz się, jak wziąć kredyt do 65 000 zł online w Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt 65 tys online - sprawdź warunki i weź kredyt 65 000 zł',
      datePublished: '2021-05-29',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor35000',
    component: LoanFor35000,
    meta: {
      title: 'Kredyt 35 tys online - sprawdź warunki i weź kredyt 35 000 zł',
      description:
        'Kredyt 35 tys. zł przez internet ➤ Sprawdź warunki oraz wysokość raty ➤ Dowiedz się, jak wziąć kredyt do 35 000 zł online w Saverium',
      isPublic: true,
      img: '/static/img/saverium_pl/article-images/35k-loan.jpeg',
    },
    props: {
      headline: 'Kredyt 35 tys online - sprawdź warunki i weź kredyt 35 000 zł',
      datePublished: '2021-05-29',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/35k-loan.jpeg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor90000',
    component: LoanFor90000,
    meta: {
      title: 'Kredyt 90 tys online - sprawdź warunki i weź kredyt 90 000 zł',
      description:
        'Kredyt 90 tys. zł przez internet ➤ Sprawdź warunki oraz wysokość raty ➤ Dowiedz się, jak wziąć kredyt do 90 000 zł online w Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt 90 tys online - sprawdź warunki i weź kredyt 90 000 zł',
      datePublished: '2021-05-29',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/35k-loan.jpeg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor20Thousand',
    component: LoanFor20Thousand,
    meta: {
      title: 'Pożyczka pozabankowa do 20 000 zł | Saverium',
      description:
        'Meta Description: Sprawdź, atrakcyjne oferty pożyczek pozabankowych do 20 000 zł. Weź pożyczkę na dowolny cel za pośrednictwem platformy Saverium',
      isPublic: true,
    },
    props: {
      headline: '',
      datePublished: '2021-09-07',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanForEveryone',
    component: LoanForEveryone,
    meta: {
      title: 'Pożyczka dla każdego - chwilówka online w 24h | Saverium',
      description:
        'Pożyczki dla każdego ✓ Szybkie chwilówki online w 24h ✓ Sprawdź warunki otrzymania i weź pożyczkę za pośrednictwem Saverium',
      isPublic: true,
    },
    props: {
      headline: '',
      datePublished: '2021-09-07',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'Loan3000',
    component: Loan3000,
    meta: {
      title: 'Pożyczka 3000 zł - chwilówki online do 3000 zł',
      description:
        'Pożyczki przez internet do 3000 zł ✓ Chwilówki online do 3000 zł nawet na raty ✓ Sprawdź warunki otrzymania i weź pożyczkę w Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Pożyczka 3000 zł - chwilówki online do 3000 zł',
      datePublished: '2021-09-09',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor30Thousand',
    component: LoanFor30Thousand,
    meta: {
      title: 'Szybka pożyczka 30 000 zł – pożyczka do 30 tyś | Saverium',
      description:
        'Szybka pożyczka 30 000 zł przez internet ✅ Skorzystaj z oferty pożyczki pozabankowej za pośrednictwem Saverium ✅ Wypełnij tylko 1 prosty wniosek!',
      isPublic: true,
    },
    props: {
      headline: '',
      datePublished: '2021-09-07',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'UrgentLoanToday',
    component: UrgentLoanToday,
    meta: {
      title: 'Pilna pożyczka na dzisiaj – decyzja w kilka minut | Saverium',
      description:
        'Potrzebujesz szybkiej pożyczki z decyzją na dzisiaj? ✓ Skorzystaj z oferty przygotowanej przez Saverium ✓ Wypełnij tylko 1 prosty wniosek!',
      isPublic: true,
    },
    props: {
      headline: '',
      datePublished: '2021-09-07',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor6Months',
    component: LoanFor6Months,
    meta: {
      title: 'Pożyczka na 6 miesięcy online | Saverim',
      description:
        'Pożyczka przez internet na 6 miesięcy ✓ Wybierz półroczny okres kredytowania ✓ Wypełnij tylko 1 prosty wniosek na platformie Saverium',
      isPublic: true,
    },
    props: {
      headline: '',
      datePublished: '2021-09-07',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'AQuickLoanOnMandate',
    component: AQuickLoanOnMandate,
    meta: {
      title: 'Szybka pożyczka na umowę zlecenie lub dzieło | Saverium',
      description:
        'Skorzystaj z oferty pożyczki online na umowę zlecenie lub o dzieło ✓ Z Saverium weźmiesz pożyczkę szybko i bezpiecznie ✓ Sprawdź!',
      isPublic: true,
    },
    props: {
      headline: '',
      datePublished: '2021-09-07',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'GoodLoan',
    component: GoodLoan,
    meta: {
      title: 'Dobra pożyczka – najlepsze oferty chwilówek | Saverium',
      description:
        'korzystaj z najlepszych ofert szybkich pożyczek online ✓ Weź pożyczkę na korzystnych warunkach ✓ Najlepsze oferty chwilówek na Saverium.pl',
      isPublic: true,
    },
    props: {
      headline: '',
      datePublished: '2021-09-07',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor50Thousand',
    component: LoanFor50Thousand,
    meta: {
      title: 'Pożyczka pozabankowa 50 000 zł - sprawdź możliwości',
      description:
        'Potrzebujesz pożyczki do 50 000 zł? ✓ Skorzystaj z oferty przygotowanej przez Saverium ✓ Sprawdź warunki otrzymania korzystnej pożyczki online',
      isPublic: true,
    },
    props: {
      headline: '',
      datePublished: '2021-09-07',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CarLoan',
    component: CarLoan,
    meta: {
      title: 'Kredyt samochodowy – pożyczka na samochód 2022',
      description:
        'Kredyt samochodowy pozwala na zakup samochodu ✨ Sprawdź rodzaje kredytów samochodowych ⭐ i kto może z nich skorzystać',
      isPublic: true,
    },
    props: {
      headline: '',
      datePublished: '2022-05-30',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'ConsolidationLoan',
    component: ConsolidationLoan,
    meta: {
      title: 'Kredyt konsolidacyjny – konsolidacja kredytu | Saverium',
      description:
        'Sprawdź najważniejsze informacje na temat kredytu konsolidacyjnego ⭐ Dowiedz się na czym polega konsolidacja kredytów ⭐ Odwiedź nas',
      isPublic: true,
    },
    props: {
      headline: '',
      datePublished: '2021-09-08',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'BusinessLoan',
    component: BusinessLoan,
    meta: {
      title: 'Kredyt dla firm online – pożyczki dla firm | Saverium',
      description:
        'Kredyty i pożyczki online dla firm na dowolny cel ✓ Sprawdź najważniejsze informacje ✓ Zobacz jakie warunki musisz spełnić',
      isPublic: true,
    },
    props: {
      headline: '',
      datePublished: '2021-09-08',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'RevolvingLoan',
    component: RevolvingLoan,
    meta: {
      title: 'Kredyt odnawialny – jak działa? Najważniejsze informacje',
      description:
        'Kredyt odnawialny to dodatkowa kwota, które powiększa Twoje saldo ✓ Sprawdź jak działa kredyt odnawialny ✓ Dowiedz się więcej',
      isPublic: true,
    },
    props: {
      headline: '',
      datePublished: '2021-09-08',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'RefinancingLoan',
    component: RefinancingLoan,
    meta: {
      title: 'Kredyt refinansowy – co to jest refinansowanie kredytu?',
      description:
        'Zebraliśmy najważniejsze informacje o kredycie refinansowym ✓ Zobacz, czy możesz się o niego ubiegać ✓ Sprawdź zasady przyznawania',
      isPublic: true,
    },
    props: {
      headline: '',
      datePublished: '2021-09-08',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanForFinishingHouse',
    component: LoanForFinishingHouse,
    meta: {
      title: 'Kredyt na wykończenie domu lub mieszkania | Saverium',
      description:
        'Kredyt na wykończenie domu ⭐ zebraliśmy najważniejsze informacje ⭐ Sprawdź zalety kredytu na wykończenie domu ⭐ Odwiedź Saverium.pl',
      isPublic: true,
    },
    props: {
      headline: '',
      datePublished: '2021-09-08',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanFor45Days',
    component: LoanFor45Days,
    meta: {
      title: 'Pożyczka na 45 dni - chwilówki online na 45 dni | Saverium',
      description:
        'Pożyczki online na 45 dni ✓ Chwilówki przez internet ze spłatą w ciągu 45 dni ✓ Sprawdź warunki otrzymania i weź pożyczkę w Saverium',
      isPublic: true,
    },
    props: {
      headline: '',
      datePublished: '2021-09-08',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'InstallmentLoanNoVerification',
    component: InstallmentLoanNoVerification,
    meta: {
      title: 'Pożyczka na raty bez weryfikacji. Jak zdobyć?',
      description:
        'Masz niekorzystną historię kredytową i trudno ci uzyskać kredyt bankowy? Rozwiązaniem jest pożyczka na raty bez weryfikacji. Sprawdź, jak dostać pożyczkę na raty bez BIK!',
      isPublic: true,
    },
    props: {
      headline: 'Pożyczka na raty bez weryfikacji. Jak zdobyć?',
      datePublished: '2022-03-21',
      type: 'Article',
      image:
        '/static/img/saverium_pl/article-images/installment-loan-no-verification.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CreditForStatement',
    component: CreditForStatement,
    meta: {
      title: 'Kredyt na oświadczenie. Zobacz, jak do dostać!',
      description:
        'Czym jest kredyt na oświadczenie i czym różni się od typowego kredytu? Przekonaj się, co zrobić, by otrzymać kredyt na oświadczenie!',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt na oświadczenie. Zobacz, jak do dostać!',
      datePublished: '2022-03-21',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/credit-for-statement.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'ApartmentRenovationLoan',
    component: ApartmentRenovationLoan,
    meta: {
      title: 'Kredyt na remont mieszkania. Jak pozyskać? | Saverium',
      description:
        'Zobacz, jak skutecznie ubiegać się o kredyt na remont mieszkania! Sprawdź, co dokładnie możesz dzięki niemu sfinansować!',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt na remont mieszkania. Jak pozyskać? | Saverium',
      datePublished: '2022-03-21',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/apartment-renovation-loan.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanForFinishingApartment',
    component: LoanForFinishingApartment,
    meta: {
      title: 'Kredyt na wykończenie mieszkania. Jak dostać?',
      description:
        'W jaki sposób otrzymać kredyt na wykończenie mieszkania? Jakie warunki trzeba spełnić? Przekonaj się i uzyskaj pożyczkę!',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt na wykończenie mieszkania. Jak dostać?',
      datePublished: '2022-03-21',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-for-finishing-apartment.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'GiroCheckLoan',
    component: GiroCheckLoan,
    meta: {
      title: 'Pożyczka na czek GIRO. Dowiedz się, czym jest!',
      description:
        'Czym właściwie jest pożyczka na czek GIRO? Dla kogo stanowi dobre rozwiązanie pożyczkowe? Dowiedz się, jak możesz skorzystać z zalet tego instrumentu!',
      isPublic: true,
    },
    props: {
      headline: 'Pożyczka na czek GIRO. Dowiedz się, czym jest!',
      datePublished: '2022-03-21',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/giro-check-loan.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'AQuickLoanForNow',
    component: AQuickLoanForNow,
    meta: {
      title: 'Szybka pożyczka na już! Zobacz, jak uzyskać!',
      description:
        'akie warunki musisz spełnić, by otrzymać szybką pożyczkę na już? Gdzie jej szukać? Poznaj kluczowe informacje i szybko zdobądź potrzebne środki!',
      isPublic: true,
    },
    props: {
      headline: 'Szybka pożyczka na już. Jakie są warunki jej uzyskania?',
      datePublished: '2022-03-21',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/quick-loan-for-now.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'GrantableLoan',
    component: GrantableLoan,
    meta: {
      title: 'Pożyczka 100% przyznawalności. Sprawdź! | Saverium',
      description:
        'Czy pożyczka 100% przyznawalności istnieje? Gdzie można ją odnaleźć? Przekonaj się, jak zdobyć finansowanie w pewny sposób!',
      isPublic: true,
    },
    props: {
      headline: 'Pożyczka 100% przyznawalności. Sprawdź! | Saverium',
      datePublished: '2022-03-21',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/grantable-loan.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'OnlineNonbankCreditCard',
    component: OnlineNonbankCreditCard,
    meta: {
      title: 'Pozabankowa karta kredytowa online | Saverium',
      description:
        'Sprawdź, czym jest pozabankowa karta kredytowa. Zobacz jak wygląda spłata karty kredytowej i jak otrzymać pozabankową kartę kredytową online',
      isPublic: true,
    },
    props: {
      headline: 'Pozabankowa karta kredytowa online | Saverium',
      datePublished: '2022-03-21',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/online-nonbank-credit-card.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CreditCardWhatIsIt',
    component: CreditCardWhatIsIt,
    meta: {
      title: 'Karta kredytowa - co to jest, w jaki sposób działa?',
      description:
        'Zobacz, czym jest karta kredytowa. Dowiedz się, jak działa oraz kto może ubiegać się o uzyskanie karty kredytowej. Sprawdź, kiedy sięgnąć po kartę kredytową.',
      isPublic: true,
    },
    props: {
      headline: 'Karta kredytowa - co to jest, w jaki sposób działa?',
      datePublished: '2022-03-21',
      type: 'Article',
      image: '/static/img/saverium_dk/article-imgs/creditworthiness-tiny.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'ALoanImmediately',
    component: ALoanImmediately,
    meta: {
      title: 'Pożyczka od zaraz - sprawdź jak uzyskać | Saverium',
      description:
        'Pożyczka od zaraz ⭐ Zobacz, kto może ubiegać się o pożyczkę oraz jakie są warunki jej uzyskania ⭐ Wypełnij jeden prosty wniosek, aby móc uzyskać pożyczkę online',
      isPublic: true,
    },
    props: {
      headline: 'Pożyczka od zaraz - sprawdź jak uzyskać | Saverium',
      datePublished: '2022-03-21',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-immediately.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'WhereToBorrowMoney',
    component: WhereToBorrowMoney,
    meta: {
      title: 'Skąd pożyczyć pieniądze - co można zrobić? | Saverium',
      description:
        'Skąd pożyczyć pieniądze? ⭐ Sprawdź, jakie są możliwości otrzymania dodatkowych środków pieniężnych ⭐ Zobacz, skąd pożyczyć pieniądze online',
      isPublic: true,
    },
    props: {
      headline: 'Skąd pożyczyć pieniądze - co można zrobić? | Saverium',
      datePublished: '2022-03-21',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/where-to-borrow-money.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoansWithoutCreditWorthiness',
    component: LoansWithoutCreditWorthiness,
    meta: {
      title: 'Pożyczki bez zdolności kredytowej | Saverium',
      description:
        'Pożyczki bez zdolności kredytowej ⭐ Sprawdź, jakie wymagania należy spełnić, aby ubiegać się o pożyczkę ⭐ Wypełnij jeden prosty wniosek, aby móc uzyskać pożyczkę',
      isPublic: true,
    },
    props: {
      headline: 'Pożyczki bez zdolności kredytowej | Saverium',
      datePublished: '2022-03-21',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loans-without-creditworthiness.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'WhereToGetMoney',
    component: WhereToGetMoney,
    meta: {
      title: 'Skąd wziąć pieniądze na już? | Saverium',
      description:
        'Zobacz, skąd wziąć pieniądze na już ⭐ Sprawdź, jakie są możliwości na pożyczenie pieniędzy ⭐ Dowiedz się więcej na stronie i dowiedz się, jak uzyskać dodatkowe środki',
      isPublic: true,
    },
    props: {
      headline: 'Skąd wziąć pieniądze na już? | Saverium',
      datePublished: '2022-03-21',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/where-to-get-money.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'FastOnlineCheckout',
    component: FastOnlineCheckout,
    meta: {
      title: 'Szybka kasa przez internet - sprawdź! | Saverium',
      description:
        'Szybka kasa przez internet ⭐ Sprawdź, w jaki sposób ubiegać się o dodatkowe środki finansowe ⭐ Zobacz, kto może się ubiegać o szybką pożyczkę online',
      isPublic: true,
    },
    props: {
      headline: 'Szybka kasa przez internet - sprawdź! | Saverium',
      datePublished: '2022-03-21',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/fast-online-checkout.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'PayByCardOverInternet',
    component: PayByCardOverInternet,
    meta: {
      title: 'Jak szybko i bezpieczne płacić kartą przez internet?',
      description:
        'Jak szybko i bezpiecznie płacić kartą przez internet ⭐ Dowiedz się wszystkiego na temat bezpiecznych i szybkich płatności internetowych ⭐ Sprawdź i zobacz jak to działa',
      isPublic: true,
    },
    props: {
      headline: 'Jak szybko i bezpieczne płacić kartą przez internet?',
      datePublished: '2022-03-21',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/pay-by-card-over-internet.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'InstallmentLoanNoBik',
    component: InstallmentLoanNoBik,
    meta: {
      title: 'Pożyczka na raty bez bik - sprawdź jak otrzymać | Saverium',
      description:
        'Pożyczka na raty bez BIK ⭐ Sprawdź, kto może ubiegać się o taki produkt finansowy ⭐ Zobacz, jakie są warunki otrzymania pożyczki ',
      isPublic: true,
    },
    props: {
      headline: 'Pożyczka na raty bez bik - sprawdź jak otrzymać',
      datePublished: '2022-05-06',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/installment-no-bik.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'PaydayLoansNoDatabases',
    component: PaydayLoansNoDatabases,
    meta: {
      title: 'Chwilówki bez baz - pożyczki bez sprawdzania baz | Saverium',
      description:
        'Chwilówki bez baz ⭐ Pożyczki online bez sprawdzania baz kredytowych ⭐ Dowiedz się, dla kogo skierowany jest taki produkt ',
      isPublic: true,
    },
    props: {
      headline: 'Chwilówki bez baz - pożyczki bez sprawdzania baz',
      datePublished: '2022-05-06',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/payday-loans-no-databases.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'AccountBalance',
    component: AccountBalance,
    meta: {
      title: 'Saldo konta - co to jest, jak sprawdzić? | Saverium',
      description:
        'Saldo konta ⭐ Zobacz, co to jest saldo konta oraz jak je sprawdzić ⭐ Dowiedz się, co oznacza saldo na minusie. Odwiedź Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Saldo konta - co to jest, jak sprawdzić? Co oznacza saldo na minusie?',
      datePublished: '2022-05-06',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/account-balance.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanWithoutPhoneVerification',
    component: LoanWithoutPhoneVerification,
    meta: {
      title: 'Pożyczka bez weryfikacji telefonicznej - Sprawdź | Saverium',
      description:
        'Pożyczka bez weryfikacji telefonicznej ⭐ Sprawdź, na czym polega ten produkt finansowy ⭐ Zobacz, jak ubiegać się o pożyczkę',
      isPublic: true,
    },
    props: {
      headline: 'Pożyczka bez weryfikacji telefonicznej',
      datePublished: '2022-05-06',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/account-balance.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'Loans24Hours',
    component: Loans24Hours,
    meta: {
      title: 'Pożyczki 24h przez Internet - chwilówki 24/7 | Saverium',
      description:
        'Pożyczki 24h przez Internet ⭐ Chwilówki 24h 7 dni w tygodniu ⭐ Sprawdź, jak wnioskować i uzyskać dodatkowe finansowanie',
      isPublic: true,
    },
    props: {
      headline: 'Pożyczki 24h przez internet - chwilówki 24/7',
      datePublished: '2022-05-06',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-24-hours.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanWithNoIncome',
    component: LoanWithNoIncome,
    meta: {
      title: 'Pożyczka bez udokumentowanych dochodów | Saverium',
      description:
        'Pożyczka bez udokumentowanych dochodów ⭐ Sprawdź, kto może się ubiegać oraz jakie są warunki uzyskania ⭐ Odwiedź Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Pożyczka bez udokumentowanych dochodów',
      datePublished: '2022-05-06',
      type: 'Article',
      image: '/static/img/saverium_pl/article-images/loan-with-no-income.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'https://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'BiggestCashLoan',
    component: BiggestCashLoan,
    meta: {
      title: 'Największy kredyt gotówkowy - na jaką kwotę wziąć? | Saverium',
      description:
        'Największy kredyt gotówkowy ⭐ Dowiedz się, na jaką kwotę możesz wziąć pożyczkę gotówkową ⭐ Zobacz i wypełnij wniosek na Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Największy kredyt gotówkowy - jaki można wziąć?',
      datePublished: '2022-06-17',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CashLoanAndFixedContract',
    component: CashLoanAndFixedContract,
    meta: {
      title: 'Kredyt gotówkowy a umowa na czas określony | Saverium',
      description:
        'Kredyt gotówkowy a umowa na czas określony ⭐ Sprawdź, czy otrzymasz kredyt gotówkowy mając umowę terminową ⭐ Dowiedz się więcej',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt a umowa na czas określony',
      datePublished: '2022-06-17',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanAfterOneMonth',
    component: LoanAfterOneMonth,
    meta: {
      title: 'Kredyt po 1 miesiącu pracy - czy otrzymasz kredyt? | Saverium',
      description:
        'Kredyt po 1 miesiącu pracy ⭐ Sprawdź, jaki jest minimalny okres zatrudnienia do kredytu ⭐ Zobacz, czy otrzymasz kredyt po 1 miesiącu pracy',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt po 1 miesiącu pracy - minimalny okres zatrudnienia do kredytu',
      datePublished: '2022-06-17',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CashLoanForAnyPurpose',
    component: CashLoanForAnyPurpose,
    meta: {
      title: 'Kredyt gotówkowy na dowolny cel | Saverium',
      description:
        'Kredyt gotówkowy na dowolny cel ⭐ Sprawdź, jak uzyskać kredyt gotówkowy w Saverium ⭐ Zobacz, czego będziesz potrzebować do kredytu',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt gotówkowy na dowolny cel',
      datePublished: '2022-06-17',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'WhoCanGetALoan',
    component: WhoCanGetALoan,
    meta: {
      title: 'Kto może otrzymać kredyt gotówkowy? Warunki i wymagania | Saverium',
      description:
        'Kto może otrzymać kredyt gotówkowy? ⭐ Przeczytaj, jakie warunki należy spełnić, aby otrzymać kredyt gotówkowy ⭐ Zobacz więcej na stronie Saverium',
      isPublic: true,
    },
    props: {
      headline:
        'Kto może otrzymać kredyt - jakie trzeba spełniać warunki żeby dostać kredyt?',
      datePublished: '2022-06-17',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CashLoanForOnlineStatement',
    component: CashLoanForOnlineStatement,
    meta: {
      title: 'Kredyt gotówkowy na oświadczenie online | Saverium',
      description:
        'Kredyt gotówkowy na oświadczenie online ⭐ Zobacz, czy otrzymasz kredyt na oświadczenie przez internet ⭐ Odwiedź stronę i wypełnij wniosek online',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt gotówkowy na oświadczenie online',
      datePublished: '2022-06-17',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'UsedCarCashLoan',
    component: UsedCarCashLoan,
    meta: {
      title: 'Kredyt gotówkowy na samochód używany',
      description:
        'Kredyt gotówkowy na samochód używany ⭐ Sprawdź, jakie są warunki otrzymania kredytu gotówkowego ⭐ Odwiedź naszą stronę',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt gotówkowy na oświadczenie online',
      datePublished: '2022-06-17',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CashLoanWithoutCreditWorthiness',
    component: CashLoanWithoutCreditWorthiness,
    meta: {
      title: 'Kredyt gotówkowy bez zdolności kredytowej | Saverium',
      description:
        'Kredyt gotówkowy bez zdolności kredytowej ⭐ Sprawdź, czy jest szansa na uzyskanie kredytu gotówkowego nie mając zdolności ⭐ Odwiedź nas',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt gotówkowy bez zdolności kredytowej',
      datePublished: '2022-06-17',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CashLoanProofNoEmployment',
    component: CashLoanProofNoEmployment,
    meta: {
      title: 'Kredyt gotówkowy na dowód bez umowy o pracę',
      description:
        'Kredyt gotówkowy na dowód bez umowy o pracę ⭐ Sprawdź, czy jest szansa otrzymać kredyt gotówkowy nie mając umowy o prace ⭐ Odwiedź nas',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt gotówkowy na dowód bez umowy o pracę',
      datePublished: '2022-06-17',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CashLoanWithoutOnlineCertificates',
    component: CashLoanWithoutOnlineCertificates,
    meta: {
      title: 'Kredyt gotówkowy bez zaświadczeń online | Saverium',
      description:
        'Kredyt gotówkowy bez zaświadczeń online ⭐ Sprawdź, czy otrzymasz kredyt bez zaświadczeń przez internet ⭐ Wypełnij wniosek online',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt gotówkowy bez zaświadczeń online',
      datePublished: '2022-06-17',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanOverInternet',
    component: LoanOverInternet,
    meta: {
      title: 'Kredyt przez Internet - szybki kredyt online | Saverium',
      description:
        'Kredyt przez Internet ⭐ Szybki kredyt online w Saverium ⭐ Wypełnij jeden prosty wniosek, aby ubiegać się o kredyt do 200 000 zł online',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt przez Internet - szybki kredyt online',
      datePublished: '2022-06-17',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'OnlineInstallmentLoan',
    component: OnlineInstallmentLoan,
    meta: {
      title: 'Kredyt ratalny - kredyt na raty online | Saverium',
      description:
        'Kredyt ratalny ⭐ Sprawdź ofertę kredytów na raty online ⭐ Odwiedź Saverium i wypełnij jeden prosty wniosek przez internet',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt ratalny - kredyt na raty online',
      datePublished: '2022-06-17',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CashLoanForHomeRenovation',
    component: CashLoanForHomeRenovation,
    meta: {
      title: 'Kredyt gotówkowy na remont domu | Saverium',
      description:
        'Kredyt gotówkowy na remont domu ⭐ Sprawdź, jakie są warunki uzyskania kredytu gotówkowego ⭐ Wypełnij wniosek z Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt gotówkowy na remont domu',
      datePublished: '2022-06-17',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CashLoanWithDatabaseEntries',
    component: CashLoanWithDatabaseEntries,
    meta: {
      title: 'Kredyt gotówkowy z wpisami w bazach',
      description:
        'Kredyt gotówkowy z wpisami w bazach ⭐ Dowiedz się, czy otrzymasz kredyt gotówkami z negatywnymi wpisami ⭐ Odwiedź nas',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt gotówkowy z wpisami w bazach',
      datePublished: '2022-06-17',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'RevolvingLoanWhatIsIt',
    component: RevolvingLoanWhatIsIt,
    meta: {
      title: 'Kredyt rewolwingowy - co to jest? | Saverium',
      description:
        'Kredyt rewolwingowy ⭐ Sprawdź, czym jest kredyt rewolwingowy i w jaki sposób go otrzymać ⭐ Dowiedz się więcej na stronie Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt rewolwingowy - co to jest?',
      datePublished: '2022-06-17',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CashLoanWithMortgage',
    component: CashLoanWithMortgage,
    meta: {
      title: 'Kredyt gotówkowy mając kredyt hipoteczny | Saverium',
      description:
        'Czy możliwe jest uzyskanie kredytu gotówkowego mając kredyt hipoteczny? Sprawdź praktyczne informacje i dowiedz się więcej na stronie Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt rewolwingowy - co to jest?',
      datePublished: '2022-07-11',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CanYouLoanWithMortgage',
    component: CanYouLoanWithMortgage,
    meta: {
      title: 'Czy można dostać kredyt mając kredyt hipoteczny? | Saverium',
      description:
        'Sprawdź, czy mając kredyt hipoteczny, możesz ubiegać się o uzyskanie kredytu gotówkowego. Zobacz warunki uzyskanie i dowiedz się więcej z artykułu',
      isPublic: true,
    },
    props: {
      headline: 'Czy można dostać kredyt mając kredyt hipoteczny?',
      datePublished: '2022-07-11',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CashLoanStepByStep',
    component: CashLoanStepByStep,
    meta: {
      title: 'Jak wziąć kredyt gotówkowy krok po kroku? | Saverium',
      description:
        'Jak wziąć kredyt gotówkowy krok po kroku? Sprawdź praktyczny poradnik na stronie Saverium Dowiedz się, jak bezpiecznie zaciągnąć kredyt online',
      isPublic: true,
    },
    props: {
      headline: 'Jak wziąć kredyt gotówkowy krok po kroku?',
      datePublished: '2022-07-11',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CashLoanFromAbroad',
    component: CashLoanFromAbroad,
    meta: {
      title: 'Kredyt gotówkowy na dochody z zagranicy | Saverium',
      description:
        'Kredyt gotówkowy na dochody z zagranicy. Sprawdź, czy jesteś w stanie otrzymać kredyt na dochody uzyskiwanie za granicą. Przeczytaj artykuł i dowiedz się więcej',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt gotówkowy na dochody z zagranicy',
      datePublished: '2022-07-11',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'JointCashLoan',
    component: JointCashLoan,
    meta: {
      title: 'Wspólny kredyt gotówkowy - co należy wiedzieć? | Saverium',
      description:
        'Wspólny kredyt gotówkowy. Zobacz, co należy wiedzieć decydując się na wspólny kredyt. Sprawdź, czy charakteryzuje się taki kredyt i jak oceniana jest zdolność kredytowa.',
      isPublic: true,
    },
    props: {
      headline: 'Wspólny kredyt gotówkowy - co należy wiedzieć?',
      datePublished: '2022-07-11',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CarOrCashLoan',
    component: CarOrCashLoan,
    meta: {
      title: 'Kredyt samochodowy czy gotówkowy - jaki wybrać? | Saverium',
      description:
        'Kredyt samochodowy czy gotówkowy. Jaki rodzaj kredytu będzie odpowiedni na zakup auta? Dowiedz się wszystkiego z naszego kompendium wiedzy',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt samochodowy czy gotówkowy - jaki wybrać?',
      datePublished: '2022-07-11',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'LoanWithoutPartnerConsent',
    component: LoanWithoutPartnerConsent,
    meta: {
      title: 'Kredyt bez zgody współmałżonka - co należy wiedzieć?',
      description:
        'Czy można otrzymać kredyt gotówkowy bez zgody współmałżonka? Są pewne warunki, jakie trzeba spełnić, aby uzyskać taki kredyt. Dowiedz się więcej z artykułu',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt bez zgody współmałżonka - co należy wiedzieć?',
      datePublished: '2022-07-11',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CashLoanForMortgage',
    component: CashLoanForMortgage,
    meta: {
      title: 'Kredyt gotówkowy na mieszkanie zamiast hipotecznego | Saverium',
      description:
        'Kredyt gotówkowy na mieszkanie zamiast hipotecznego. Kiedy to rozwiązanie będzie najlepsze? Dowiedz się, kiedy warto zaciągnąć kredyt gotówkowy na mieszkanie',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt gotówkowy na mieszkanie zamiast hipotecznego',
      datePublished: '2022-07-11',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'CashLoanFixedInterest',
    component: CashLoanFixedInterest,
    meta: {
      title: 'Kredyt gotówkowy ze stałym oprocentowaniem | Saverium',
      description:
        'Kredyt gotówkowy ze stałym oprocentowaniem. Sprawdź ofertę Saverium dotyczącą kredytów ze stałym oprocentowaniem. Wypełnij jeden prosty formularz',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt gotówkowy ze stałym oprocentowaniem',
      datePublished: '2022-07-11',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'ContractLoan',
    component: ContractLoan,
    meta: {
      title: 'Kredyt na umowę zlecenie | Saverium',
      description:
        'Kredyt na umowę zlecenie. Sprawdź, kiedy możliwe jest uzyskanie tego rodzaju kredytu. Zobacz, jak przebiega ocena zdolności kredytowej osoby zatrudnione na UZ',
      isPublic: true,
    },
    props: {
      headline: 'Kredyt na umowę zlecenie - kiedy jest możliwe uzyskanie?',
      datePublished: '2022-07-11',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'EarnHowMuchForLoan',
    component: EarnHowMuchForLoan,
    meta: {
      title: 'ile trzeba zarabiać, aby dostać kredyt gotówkowy w 2022 roku?',
      description:
        'Ile trzeba zarabiać, aby dostać kredyt gotówkowy w 2022 roku? ✓ To pytanie zadaje sobie wiele osób ✓ Sprawdź praktyczne informacje',
      isPublic: true,
    },
    props: {
      headline: 'Ile trzeba zarabiać, żeby dostać kredyt gotówkowy w 2022 roku?',
      datePublished: '2022-08-24',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'HowMuchWaitingCashLoan',
    component: HowMuchWaitingCashLoan,
    meta: {
      title: 'Ile się czeka na kredyt gotówkowy? | Saverium',
      description:
        'Ile się czeka na kredyt gotówkowy z banku? ✓ Sprawdź praktyczne informacje przygotowane przez naszych specjalistów ✓ Odwiedź Saverium',
      isPublic: true,
    },
    props: {
      headline: 'Ile się czeka na kredyt gotówkowy?',
      datePublished: '2022-08-24',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'IsItWorthTakingCashLoanNow',
    component: IsItWorthTakingCashLoanNow,
    meta: {
      title: 'Czy warto brać kredyt gotówkowy 2022? | Saverium',
      description:
        'Czy warto decydować się na kredyt gotówkowy w 2022 roku? ✓ Sprawdź opinię naszych ekspertów ✓ Dowiedz się więcej na temat kredytów',
      isPublic: true,
    },
    props: {
      headline: 'Czy warto teraz brać kredyt gotówkowy?',
      datePublished: '2022-08-24',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'DoInterestRatesAffectLoan',
    component: DoInterestRatesAffectLoan,
    meta: {
      title: 'Stopy procentowe a kredyt gotówkowy | Saverium',
      description:
        'Czy stopy procentowe mają wpływ na kredyt gotówkowy? ✓ Wyjaśniamy zależność pomiędzy stopami procentowymi a wysokością kredytu ✓ Sprawdź',
      isPublic: true,
    },
    props: {
      headline: 'Czy stopy procentowe mają wpływ na kredyt gotówkowy?',
      datePublished: '2022-08-24',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
  {
    name: 'WhatDocumentsForCashLoan',
    component: WhatDocumentsForCashLoan,
    meta: {
      title: 'Potrzebne dokumenty do kredytu gotówkowego | Saverium',
      description:
        'Jakie dokumenty są potrzebne do kredytu gotówkowego? ✓ Sprawdź niezbędne potrzebne dokumenty ✓ Przygotowaliśmy listę niezbędnych elementów',
      isPublic: true,
    },
    props: {
      headline: 'Jakie dokumenty są potrzebne do wnioskowania o kredyt gotówkowy?',
      datePublished: '2022-08-24',
      type: 'Article',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.pl',
        url: 'http://saverium.pl',
        logo: '/static/img/saverium_pl/saverium_pl_logo.png',
      },
    },
  },
]

const rawRoutes = routeUtils.createRawRoutes(langRoutes, localizedPaths)

export default rawRoutes
