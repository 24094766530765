<template>
  <div class="row article">
    <h1 class="center-title">
      Parabanki - czym są, jak działają? Czym różnią się od banków?
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >Instytucja parabanku w ostatnich latach stała się bardzo popularna w naszym
              kraju. Oferta tego typu firm często bywa postrzegana jako alternatywa dla
              banków, jednak warto pamiętać o tym, że te dwie instytucje bardzo mocno się
              od siebie różnią. Podpowiadamy, czym właściwie są parabanki i co tak
              naprawdę odróżnia je od banków.</i
            >
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/parabanks-tiny.jpeg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/parabanks.jpeg"
            alt="Parabanki - czym są, jak działają? Czym różnią się od banków?"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
          <h2 id="1">
            {{ contents[0].title }}
          </h2>
          <p>
            Instytucje parabankowe to termin, który w naszym kraju nie ma umocowania w
            żadnych regulacjach prawnych. W praktyce oznacza to, że w żadnej ustawie
            termin parabank nie został zdefiniowany i opisany. Tego typu instytucje,
            podobnie jak banki oferują różnego rodzaju produkty finansowe. Są to
            najczęściej
            <b-link :to="$root.rn.ShortTermLoans">pożyczki krótkoterminowe</b-link>, takie
            jak
            <b-link :to="$root.rn.InstantInternetLoans"
              >chwilówka od ręki przez Internet</b-link
            >. Parabanki oferują jednak także
            <b-link :to="$root.rn.LoanInstallment">pożyczki ratalne</b-link> i inne
            rodzaje <b-link :to="$root.rn.CashLoan">kredytów gotówkowych</b-link>.
          </p>
          <p>
            Tego typu instytucje różnicuje od banków kilka istotnych kwestii, przede
            wszystkim parabanki głównie skupiają się na udzielaniu pożyczek na krótki
            okres spłaty, podczas gdy banki swoje produkty mają raczej nakierowane na
            spłatę w dłuższym czasie. W przypadku parabanków, wniosek pożyczkowy jest
            zdecydowanie mniej skomplikowany niż w przypadku banków, a wnioskujący może
            otrzymać
            <b-link :to="$root.rn.InstantInternetLoans">pożyczkę od ręki</b-link>, często
            w tym samym dniu co złożony wniosek.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Zarówno banki, jak i parabanki udzielają klientom różnego rodzaju zobowiązań
            finansowych. Nie oznacza to jednak, że są to te same instytucje, działające na
            równych zasadach. Co je od siebie różni? Działalność banków zabezpieczona jest
            przez Bankowy Fundusz Gwarancyjny. Banki, bardzo szczegółowo analizują
            wszelkie wnioski pożyczkowe, podczas gdy parabanki podchodzą do tych kwestii w
            sposób bardziej liberalny. Parabanki, są często szybszą, a także bardzo często
            jedyną alternatywą dla osób, którym bank odmówił udzielenia kredytu. Wymogi
            jakie stawiają parabanki przed swoimi klientami są zdecydowanie mniejsze niż w
            przypadku kredytów bankowych, ale także kwoty pożyczek są zdecydowanie
            mniejsze. W przypadku parabanków, mówimy zazwyczaj o pożyczkach na 30 dni,
            <b-link :to="$root.rn.LoanForTwoMonths">pożyczkach na 60 dni</b-link> lub
            <b-link :to="$root.rn.LoanForThreeMonths">pożyczkach na 90 dni</b-link>. Tylko
            niektóre instytucje parabankowe oferują
            <b-link :to="$root.rn.OnlineLongtermLoans">pożyczki długoterminowe</b-link>.
            Kolejną różnicą jest to, że niektóre z parabanków, w ramach akcji promocyjnych
            i marketingowych, oferują dla swoich klientów
            <b-link :to="$root.rn.FreeLoan">pierwszą pożyczkę darmową</b-link>. To ciekawa
            propozycja dla wszelkich osób, które po raz pierwszy chcą zaciągnąć
            zobowiązanie finansowe.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Parabanki funkcjonują w naszym kraju przede wszystkim w formie instytucji
            online. Niektóre firmy prowadzą działalność stacjonarną, jednak odpowiedzią na
            potrzeby rynku coraz częściej jest działalność internetowa. Co to dokładnie
            oznacza?
          </p>
          <p>
            Zobowiązania takie jak
            <b-link :to="$root.rn.LoansToAccount"
              >pożyczka przez Internet na konto bankowe</b-link
            >, czy popularne
            <b-link :to="$root.rn.LoansInFifteenMinutes"
              >pożyczki online w 15 minut</b-link
            >
            udzielane są poprzez wypełnienie odpowiedniego wniosku przez Internet. Ogromna
            część obsługi klienta odbywa się właśnie za pośrednictwem sieci, a pieniądze
            po przejściu procesu weryfikacji klienta, trafiają na jego konto bankowe.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Działalność parabanków nie jest w Polsce monitorowana przez Komisję Nadzoru
            Finansowego. Nie zmienia to jednak faktu, że system parabanków funkcjonuje w
            naszym kraju już od wielu lat. Codziennie wiele osób korzysta z ich usług.
            Warto jednak mieć świadomość tego, że tego typu instytucje nie są bankami.
            Różnią się od nich znacznie i jeżeli z pełną świadomością zaakceptujemy ten
            fakt, a korzystanie z ich usług będzie przemyślane, współpraca rozegra się
            bardzo dobrze. Niezależnie czy decydujemy się na pożyczkę bankową czy w
            instytucji parabankowej, musimy to robić odpowiedzialnie i tylko wtedy, gdy
            mamy całkowitą pewność, że uda nam się spłacić zobowiązanie w terminie, wraz z
            wszelkimi odsetkami i prowizjami. Poznaj
            <b-link :to="$root.rn.ResponsibleBorrowing"
              >zasady odpowiedzialnego pożyczania pieniędzy</b-link
            >
            przygotowane przez ekspertów Saverium.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            Większość parabanków w Polsce działa niemal całkowicie online. Oznacza to, że
            wzięcie pożyczki lub kredytu w parabanku oparte jest przede wszystkim na
            wypełnieniu odpowiedniego formularza i potwierdzeniu swoich danych
            (najczęściej za pomocą symbolicznego przelewu bankowego).
          </p>
          <p>
            Jeszcze do niedawna panowało przekonanie, że parabanki są bardzo liberalne i
            „rozdają" zobowiązania finansowego każdemu, kto o nie prosi. Być może w
            przeszłości faktycznie miało to miejsce, jednak warto wiedzieć, że czas bardzo
            mocno zweryfikował te kwestie. Obecnie większość parabanków sprawdza swoich
            klientów w
            <b-link :to="$root.rn.WhatAreTheseAbbreviations"
              >Biurach Informacji Gospodarczej</b-link
            >. Zła historia kredytowa może więc sprawić, że
            <b-link :to="$root.rn.OnlineNonbankLoans"
              >pożyczka pozabankowa przez Internet</b-link
            >
            nie zostanie nam udzielona.
          </p>
          <p>
            Mimo wszystko pożyczki z parabanków mają wiele zalet. Środki wypłacane są na
            nasze konto nawet w kilkanaście minut. Wiele firm oferuje możliwości
            refinansowania pożyczki, czyli przedłużania okresu jej spłaty. W końcu warto
            zaznaczyć, że oferta parabanków to obecnie nie tylko chwilówki, ale także
            pożyczki online na raty, a co raz częściej pożyczki długoterminowe. Za
            pośrednictwem Saverium, możesz ubiegać się o
            <b-link :to="$root.rn.LoansForProof">pożyczkę na dowód osobisty</b-link> w
            przedziale od 100 do nawet 200 000 zł, z okresem spłaty od 1 miesiąca do nawet
            120 miesięcy.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            Instytucje parabankowe nie są bankami. To podstawowa różnicą, którą należy
            znać przed podjęciem decyzji o skorzystaniu z oferty tego typu firmy.
            Instytucje parabankowe udzielają w głównej mierze pożyczek krótkoterminowych,
            maksymalnie do kilkudziesięciu tysięcy złotych. Banki zazwyczaj swoje produkty
            kredytowe udzielają długoterminowo. Kwoty kredytów bankowych są zazwyczaj
            większe i niżej oprocentowane niż w przypadku
            <b-link :to="$root.rn.OnlinePaydayLoans">pożyczek chwilówek</b-link>.
          </p>
          <p>
            Jeżeli interesuje Cię kredyt gotówkowy, skorzystaj z
            <b-link :to="$root.rn.LoanCalculator">kalkulatora kredytowego</b-link>
            Saverium. To prosta aplikacja, dzięki której po wprowadzeniu kwoty pożyczki i
            okresu spłaty, poznasz miesięczną ratę kredytu, kwotę odsetek oraz prowizji, a
            także wypełnisz darmowy wniosek kredytowy.
          </p>
          <p>
            Za pośrednictwem Saverium, możesz ubiegać się o kredyt bankowy oraz pożyczki w
            instytucjach parabankowych. Współpracujemy tylko z renomowanymi firmami o
            ugruntowanej pozycji na rynku finansowym. Kwota pożyczek może wynosić od 100
            zł do 200 000 zł.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'Czym są instytucje parabankowe?',
          link: '#1',
        },
        {
          title: 'Czym różnią się parabanki od banków?',
          link: '#2',
        },
        {
          title: 'Zasady funkcjonowania parabanków',
          link: '#3',
        },
        {
          title: 'System parabankowy w Polsce',
          link: '#4',
        },
        {
          title: 'Wzięcie pożyczki w parabanku',
          link: '#5',
        },
        {
          title: 'Instytucje parabankowe - podsumowanie',
          link: '#6',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped></style>
