<template>
  <div class="row article">
    <h1 class="center-title">Co to jest karta kredytowa, jak działa?</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            Pożyczka, której udziela wyspecjalizowana instytucja bądź kredyt bankowy to
            produkty finansowe, dzięki którym możemy pozyskać pewną sumę pieniędzy
            jednorazowo. Tymczasem osoby, które co jakiś czas potrzebują dopływu gotówki,
            mogą skorzystać z karty kredytowej. Co to jest karta kredytowa i jak działa?
          </p>
          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_dk/article-imgs/creditworthiness-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_dk/article-imgs/creditworthiness.jpg"
            alt="Pozabankowa karta kredytowa online - co to jest?"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul class="table-of-contents">
              <li>
                <a href="#karta-kredytowa-na-czym-polega">
                  Karta kredytowa - na czym polega?
                </a>
              </li>
              <li>
                <a href="#jak-otrzymac-karte-kredytowa">
                  Jak otrzymać kartę kredytową?
                </a>
              </li>
              <li>
                <a href="#splata-zadluzenia-z-karty-kredytowej">
                  Spłata zadłużenia z karty kredytowej
                </a>
              </li>
              <li>
                <a href="#kiedy-warto-siegnac-po-karte-kredytowa">
                  Kiedy warto sięgnąć po kartę kredytową?
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container mt-5">
          <h2 id="karta-kredytowa-na-czym-polega">Karta kredytowa - na czym polega?</h2>
          <p>
            Karta kredytowa funkcjonuje na nieco innych zasadach, niż
            <a
              href="https://www.saverium.pl/artykuly/kalkulator-kredytowy-online-co-to-jest/"
              rel="noopener noreferrer"
              target="_blank"
              >kredyt gotówkowy</a
            >
            czy
            <a
              href="https://www.saverium.pl/artykuly/pozyczki-pozabankowe-online-kredyty-pozabankowe-prezez-internet/"
              rel="noopener noreferrer"
              target="_blank"
              >pożyczka pozabankowa</a
            >. Decydując się na ich zaciągnięcie, otrzymujemy na konto określoną ilość
            pieniędzy, którą możemy dysponować dowolnie bądź przeznaczyć ją na określony
            cel. Pieniądze, wraz z odsetkami, należy zwrócić w określonym w umowie
            terminie.
          </p>
          <p>
            A na czym polega karta kredytowa i jaki jest schemat jej działania? Otóż w tym
            przypadku klient nie otrzymuje do dyspozycji pieniędzy (nie są one
            przekazywane na jego konto), a kartę, na której znajduje się zakodowany
            pieniądz elektroniczny. Innymi słowy, posiadacz karty kredytowej może
            dokonywać płatności w ramach przyznanego limitu. Obecnie karty kredytowe
            honorowane są w sklepach oraz w punktach usługowych na takich samych zasadach,
            jak karty debetowe.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="jak-otrzymac-karte-kredytowa">Jak otrzymać kartę kredytową?</h2>
          <p>
            Wiadomo już, jak korzystać z karty kredytowej, zatem warto wyjaśnić, w jaki
            sposób można ją otrzymać. Otóż należy zacząć od tego, że karty kredytowe
            wydawane są zarówno przez banki, jak i wyspecjalizowane firmy pożyczkowe. W
            pierwszym przypadku procedura przyznawania nie różni się zasadniczo od tej,
            która obowiązuje w przypadku zwykłych kredytów. Klient musi bowiem przedstawić
            dokumenty, na podstawie których oceniana jest jego zdolność kredytowa.
          </p>
          <p>
            Sama ocena
            <a
              href="https://www.saverium.pl/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/"
              rel="noopener noreferrer"
              target="_blank"
              >zdolności kredytowej</a
            >
            ma niebagatelny wpływ na wysokość przyznanego limitu. Nieco inaczej jest w
            przypadku firm pożyczkowych, które prowadząca bardziej liberalną politykę. Aby
            otrzymać pozabankową kartę kredytową, należy wypełnić stosowny wniosek, który
            dostępny jest na stronie internetowej firmy pożyczkowej. Następnie klient
            przechodzi procedurę weryfikacyjną, która ma na celu potwierdzenie jego
            tożsamości. Po pozytywnym rozpatrzeniu wniosku otrzymuje się kartę, za pomocą
            której można dokonywać płatności zarówno w sklepach stacjonarnych, jak i
            internetowych. Tutaj również obowiązuje limit, w ramach którego można płacić
            za towary czy usługi.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="splata-zadluzenia-z-karty-kredytowej">
            Spłata zadłużenia z karty kredytowej
          </h2>
          <p>
            Posiadacz karty kredytowej może w danym okresie (zwykle jest to miesiąc)
            wykorzystać cały, przyznany mu limit bądź tylko pewną jego część. Po okresie
            kredytowania zobowiązany jest spłacić powstałe zadłużenie, jednak wyłącznie na
            wykorzystaną kwotę. Spłata zadłużenia z karty kredytowej wygląda więc zupełnie
            inaczej, niż w przypadku kredytu czy pożyczki, gdzie należy oddać całą,
            pożyczoną sumę, bez względu na to, ile rzeczywiście zostało wykorzystane.
            Posiadacz karty kredytowej zazwyczaj spłaca zadłużenie jednorazowo, choć można
            rozłożyć płatność na kilka rat, w zależności od potrzeb. W niektórych
            przypadkach możliwe jest dokonanie spłaty części zadłużenia, aby móc ponownie
            korzystać z przyznanego limitu. Istotną kwestią jest oprocentowanie karty
            kredytowej, bowiem kredytobiorca musi zwrócić wykorzystaną kwotę limitu wraz z
            odsetkami.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kiedy-warto-siegnac-po-karte-kredytowa">
            Kiedy warto sięgnąć po kartę kredytową?
          </h2>
          <p>
            Kwestia tego, jak używać karty kredytowej, jest niezwykle prosta, jednak warto
            zastanowić się, komu szczególnie ten produkt finansowy może być pomocny? Otóż
            najchętniej korzystają z niego osoby, które co jakiś czas potrzebują
            dodatkowej gotówki, na przykład pod koniec miesiąca, w oczekiwaniu na wypłatę
            kolejnego wynagrodzenia. Karta kredytowa to doskonałe rozwiązanie dla tych,
            którzy nie chcą okresowo sięgać po pożyczki bankowe bądź pozabankowe
            odpowiedniki w formie
            <a href="https://www.saverium.pl/" rel="noopener noreferrer" target="_blank"
              >pożyczek online</a
            >.
          </p>
          <p>
            Warto przy tym pamiętać, że dokonywanie płatności kartą kredytową jest
            niezwykle proste, a co więcej, obecnie zdecydowana większość punktów
            usługowych i handlowych posiada terminal, dzięki któremu możemy wygodnie
            płacić za zakupy. Posiadacz karty kredytowej nie musi określać, na co
            przeznaczy środki w ramach przyznanego limitu - można wydać je na zakup
            podstawowych artykułów spożywczych, a także opłacić nimi pobyt w hotelu czy
            rachunek za wykonane usługi.
          </p>
        </div>
      </div>
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
export default {
  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
