import _ from 'lodash'
import settings from '@/settings/settings'
import lang from '@/lang'
import enumActions from '@/store/actions/enumActions'

// Initial state
const state = {
  maritalStatuses: {},
  dwellingOptions: {},
  dwellingTypes: {},
  educationOptions: {},
  occupationOptions: {},
  loanPurposeOptions: {},
  bankOptions: {},
  regionOptions: {},
  accountTypeOptions: {},
  creditCards: {},
}

const actions = enumActions

const mutations = {
  setEnums(state, enums) {
    state['maritalStatuses'] = buildFormArray(
      filterEnums(enums.maritalStatuses, 'maritalStatuses')
    )
    state['dwellingOptions'] = buildFormArray(
      filterEnums(enums.dwellingOptions, 'dwellingOptions')
    )
    state['dwellingTypes'] = buildFormArray(
      filterEnums(enums.dwellingTypes, 'dwellingTypes')
    )
    state['educationOptions'] = buildFormArray(
      filterEnums(enums.educationOptions, 'educationOptions')
    )
    state['occupationOptions'] = buildFormArray(
      filterEnums(enums.occupationOptions, 'occupationOptions')
    )
    state['loanPurposeOptions'] = buildFormArray(
      filterEnums(enums.loanPurposeOptions, 'loanPurposeOptions')
    )
    state['accountTypeOptions'] = buildFormArray(
      filterEnums(enums.accountTypeOptions, 'accountTypeOptions')
    )
    state['bankOptions'] = enums.bankOptions[settings.LOCALE]
    state['regionOptions'] = enums.regionOptions[settings.LOCALE]

    state['creditCards'] = buildFormArray(
      filterEnums(enums.cardIssuerOptions, 'cardIssuerOptions')
    )

    function buildFormArray(responseObject) {
      const formArray = []
      let i = 0
      for (const key in responseObject) {
        formArray[i] = { value: key, text: lang.t(responseObject[key]) }
        i++
      }
      return formArray
    }

    function filterEnums(responseObject, type) {
      let allowed = []
      if (type === 'loanPurposeOptions') {
        allowed = [51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61]
      } else if (type === 'maritalStatuses') {
        allowed = [1, 2, 3, 4, 5, 6]
      } else if (type === 'dwellingOptions') {
        allowed = [1, 2, 3, 4, 6, 8, 9, 10, 11, 12, 13, 14, 15]
      } else if (type === 'dwellingTypes') {
        allowed = [1, 3, 5, 6, 4, 7]
      } else if (type === 'educationOptions') {
        allowed = [2, 3, 4, 5, 6, 7, 8, 9, 10]
      } else if (type === 'occupationOptions') {
        allowed = [2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15]
      } else if (type === 'accountTypeOptions') {
        allowed = [1, 2, 3]
      } else if (type === 'cardIssuerOptions') {
        allowed = [1, 2, 3, 4, 5]
      }
      const filtered = _.pick(responseObject, allowed)
      return filtered
    }
  },
}

export default {
  state,
  mutations,
  actions,
}
