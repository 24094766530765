<template>
  <b-container class="text-center mt-5">
    <h1>404</h1>
    <p>{{ $t('page_not_found') }}</p>
    <b-link
      :to="$root.rn.MainView"
      class="btn btn-primary btn-saverium next-btn btn btn-front mt-5"
      id="apply"
    >
      {{ $t('back_to_main_page') }}
    </b-link>
  </b-container>
</template>

<script>
export default {}
</script>

<style></style>
