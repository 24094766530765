<template>
  <div>
    <div class="article">
      <h1 class="center-title">Minilån</h1>
      <div class="top-text text-container">
        <p>
          Hvis du har behov for et minilån eller blot et lille lån, så er du landet det
          rigtige sted. Hvis du har mod på at udfylde vores 2-minutters ansøgningsskema,
          kan vi indhente tilbud på minilån til dig fra en lang række banker og långivere.
          På den måde får du et overblik over udbuddet og kan dermed vælge de bedste og
          billigste minilån på markedet.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#find-det-rette-minilaan-igennem-os"
                    >Find det rette minilån igennem os</a
                  >
                </li>
                <li><a href="#et-lille-laan">Et lille lån</a></li>
                <li><a href="#minilaan-uden-sikkerhed">Minilån uden sikkerhed</a></li>
                <li><a href="#kreditvurdering">Kreditvurdering</a></li>
                <li>
                  <a href="#hvad-kan-man-bruge-et-lille-laan-til"
                    >Hvad kan man bruge et lille lån til</a
                  >
                </li>
                <li><a href="#et-gratis-minilaan">Et gratis minilån</a></li>
                <li>
                  <a href="#bedste-og-billigste-minilaan">Bedste og billigste minilån</a>
                </li>
                <li>
                  <a href="#vi-hjaelper-dig-uanset-dit-behov"
                    >Vi hjælper dig uanset dit behov</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="find-det-rette-minilaan-igennem-os">
            Find det rette minilån igennem os
          </h2>
          <p>
            Har du behov for et minilån, så kan vi hjælpe dig med at finde både det bedste
            og billigste minilån hurtigt og nemt. Hvis du har brug for et lille lån på
            5.000 kr. eller 10.000 kr., eller behov for at låne et andet lille beløb, så
            kan vi finde et bredt og godt udvalg af minilån til dig.
          </p>
          <p>
            Det eneste det kræver er, at du bruger to minutter på at udfylde vores online
            ansøgning her på siden. Herefter indhenter vi tilbud på minilån, som du ikke
            behøver at stille sikkerhed for.
          </p>
          <p>
            Ansøgningen er gratis, tilbuddene er uforpligtende og vi indhenter tilbud fra
            en lang række udbydere af minilån. Ansøg nu og få pengene på kontoen i dag.
          </p>
        </div>

        <div class="text-container">
          <h2 id="et-lille-laan">Et lille lån</h2>
          <p>
            Et lille lån bliver typisk brugt til akutte behov eller super skarpe tilbud,
            hvor det er altafgørende at slå til nu. Når du låner et lille beløb, kan du
            nemlig dække dit behov med relativt få omkostninger og en begrænset
            kreditvurderingsprocess uden at behøver at optage et større
            <a
              href="https://saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >.
          </p>
          <p>
            Et lille lån forbinder mange med <strong>mikrolån</strong>, samt
            <a
              href="https://saverium.dk/laan-penge/kvilaan"
              rel="noopener noreferrer"
              target="_blank"
              >kviklån</a
            ><strong> </strong>og<strong> </strong
            ><a
              href="https://saverium.dk/laan-penge/privatlaan/sms-laan"
              rel="noopener noreferrer"
              target="_blank"
              >sms lån</a
            >
            hvorfra betegnelser såsom “mini sms lån” og “mini kviklån” også udspringer.
            Det er med god grund, da disse to lånetyper i stor stil er synonym med et
            lille lån og minilån. Når du optager et lille lån, får du typisk også de samme
            fordele fra disse lånetyper, såsom hurtig udbetaling, og at du kun skal
            igennem den basale form for kreditvurdering.
          </p>
          <p>
            Hvis har behov for at låne et lille beløb, vil du som regl ikke overstige et
            minilån på 10.000 kr., hvilket er i den høje ende af skalaen for minilån.
            Typisk kan minilån er på 5.000 kr. eller derunder dække diverse pludseligt
            opståede uheldige økonomiske situationer, så som at du har fået stjålet en
            ejendel. Du har måske en forsikring, som dækker dette tyveri, men da
            genstanden har tabt værdi, og du også har en selvrisiko, kan genanskaffelsen
            let løbe op i 5.000 kr., hvilket et minilån kan dække.
          </p>
          <p>
            Hvis du har en uundværlig genstand, der får din hverdag til at hænge sammen,
            som er gået i stykker, så kan det også typisk dækkes med et lille lån. Hvis
            det fx er din
            <a
              href="https://saverium.dk/laan-penge/billaan"
              rel="noopener noreferrer"
              target="_blank"
              >bil</a
            >, så kan det ofte betale sig at reparere den, og det kan mange gange klares
            med et minilån på 10.000 kr. eller derunder. Hvis det derimod er en anden
            genstand, som er gået i stykker, så vil et lille lån ofte, uanset om det så
            kræver et nyt køb, kunne dække behovet.
          </p>
          <p>
            Hvis du har behov for mere end et minilån på 10.000 kr., vil det som regel
            ikke blive karakteriseret som et minilån. Grænsen for hvad der betegnes som et
            minilån, et lille lån eller et større lån, er dog flydende. Det vil fx ikke
            være de 5.000 kr. ekstra, som du evt. kunne få behov for at låne, som var
            altafgørende for din mulighed for at få dækket dit behov via et minilån. En
            låneudbyder vil højest sandsynligt blot omtale det som “at
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >”, frem for et decideret minilån.
          </p>
          <p>
            Uanset om det så er et minilån på 3.000 kr., 5.000 kr., 10.000 kr. eller lidt
            mere, så vil kreditvurderingsprocessen være den samme, og du vil ikke blive
            bedt om at stille sikkerhed for lånet. Dette kaldes et minilån uden sikkerhed,
            som ikke skal forveksles med et minilån uden kreditvurdering, hvilket vi vil
            komme nærmere ind på nedenfor.
          </p>
        </div>

        <div class="text-container">
          <h2 id="minilaan-uden-sikkerhed">Minilån uden sikkerhed</h2>
          <p>
            Et minilån uden sikkerhed er helt normalt. Hvis du ansøger om et minilån uden
            sikkerhed, betyder det blot, at du ikke skal stille sikkerhed for dit lån. At
            stille sikkerhed for dit minilån betyder, at du giver långiveren ret til at
            tage én eller flere, af dine på forhånd aftalte genstande, hvis du
            misligeholder dine terminer.
          </p>
          <p>
            Når du ansøger om et minilån uden sikkerhed, risikerer du derfor ikke, at du
            kommer i den situation, hvor långiveren kan sælge nogle af dine ejendele.
            Dette er en stor sikkerhed for alle låntagere, som ikke på forhånd er 100%
            sikre på at de får betalingsproblemer i løbet af lånets løbetid.
          </p>
          <p>
            Der vil altid foregå en kreditvurdering af dig, før du bliver godkendt til et
            minilån. Denne kreditvurdering er dog baseret på historisk data, og da ingen
            kan forudsige fremtiden, kan du havne i en situation, hvor du alligevel støder
            ind i økonomiske problemer.
          </p>
          <p>
            Lad os antage at du først fik én genstand stjålet. Denne genstand kunne du nu
            dække med et et minilån, som du fik bevilliget efter alle kunstens regler
            indenfor kreditvurdering. Kort tid efter går nu en anden ting i stykker, som
            du bliver nødt til at fikse. Det kan fx være din <strong>cykel</strong>, som
            er gået i stykker, hvilket er en uundværlig genstand for dig, da du ellers
            skal til at betale for at pendle til og fra dit arbejde.
          </p>
          <p>
            I denne situation vil du, uanset hvordan du vender og drejer det, have behov
            for flere penge, end hvad du og banken anslog, da de foretog en
            kreditvurdering af dig i forbindelse med dit minilån. I dette tilfælde kan du
            derfor løbe ind i betalingsproblemer i forhold til dit minilån, og her er du
            meget bedre stillet, hvis dit lån er et minilån er uden sikkerhed.
          </p>
          <p>
            Dette lyder måske som et usandsynligt eller meget uheldigt scenarie, men der
            findes mange hundrede sammenfald og årsager, som betyder, at man kan komme i
            en situation hvor man ikke kan opretholde de aftalte månedlige betalinger på
            lånet.
          </p>
          <p>
            Når vi indhenter tilbud på minilån til dig, vil det altid være minilån uden
            sikkerhed. Derfor behøver du slet ikke tænke over det, hvis du benytter vores
            service.
          </p>
        </div>

        <div class="text-container">
          <h2 id="kreditvurdering">Kreditvurdering</h2>
          <p>
            Når du ansøger om et minilån, eller et hvilket som helst andet lån, er det et
            lovkrav, at långiveren foretager en kreditvurdering af dig. Hvor omfattende en
            kreditvurdering du blive mødt af, afhænger af hvor risikabel en kunde
            banken/långiveren vurderer, at du er. I Danmark har vi gennem lovgivning
            fastsat et minimum af kreditvurdering, som er relativ omfattende, hvilket
            gælder uanset om du ønsker et minilån på 5.000 kr. eller et beløb, som er
            mange gange højere.
          </p>
          <p>
            Der kan dog være forskel på, hvordan og hvor grundigt du bliver
            kreditvurderet, når du ansøger om et lille lån, hvilket vi vil gennemgå
            herunder.
          </p>
          <h3>Minilån uden kreditvurdering</h3>
          <p>
            Lad os slå det fast til at starte med. Det er ikke muligt nogle steder at få
            et minilån uden kreditvurdering. Vi ved at mange danskere efterspørger minilån
            uden kreditvurdering, hvilket aldrig har været muligt.
          </p>
          <p>
            Du kan godt finde et minilån med en begrænset kreditvurderingsprocess, og før
            <a
              href="https://www.finanstilsynet.dk/Nyheder-og-Presse/Pressemeddelelser/2021/Vejledning_080421"
              rel="noopener noreferrer"
              target="_blank"
              >lovgivningen vedr. rådighedsbeløb</a
            >, var det også muligt at finde minilån hvor kreditvurderingen var mindre, end
            det minimum, som du oplever i dag.
          </p>
          <p>
            Hvis du er en af de personer, som havde et brændende ønske om at finde et
            minilån uden kreditvurdering, så må vi desværre skuffe dig. Minilån uden
            kreditvurdering er ikke muligt via os, vores konkurrenter, eller hvis du går
            direkte til en låneudbyder.
          </p>
          <p>
            Du kan helt sikkert finde steder på nettet, som omtaler minilån uden
            kreditvurdering og fremstiller det som om, at det er muligt hos dem. Dette er
            udelukkende en måde at få dig ind i butikken, for at sælge dig et andet lån,
            hvilket typisk vil være et minilån med kreditvurdering.
          </p>
          <p>
            Hvis du finder et minilån uden kreditvurdering, så kan du være 100% sikker på,
            at du låner et sted, som ikke følger lovgivningen. Et minilån uden
            kreditvurdering er ikke på grænsen af lovgivningen eller en del af grå-zonen,
            som mange virksomheder bevæger sig i. Det er decideret ulovligt og strafbart,
            uanset hvor lille et lån du ansøger om.
          </p>
          <p>
            Vores anbefaling er klart, at man skal holde sig langt væk fra alle, som
            tilbyder, eller foregiver at tilbyde minilån uden kreditvurdering. Du ønsker
            på ingen måde, at blive fanget af en lånehaj, som insisterer på at bryde
            loven.
          </p>
          <h3>Minilån trods RKI</h3>
          <p>
            Hvis du søger et minilån trods RKI, så har du ikke oddsne på din side. Det kan
            nemlig være rigtigt svært at blive godkendt til et minilån trods RKI, og det
            er der flere årsager til.
          </p>
          <p>
            For det første, og måske den mest åbentlyse, så sender det et dårligt signal
            til långiveren om din betalingsmuligheder, hvis du er registreret i RKI eller
            Debitor Registret. Det sidste som en långiver ønsker for sin forretning, er at
            overføre penge til en person, som ikke kan betale dem tilbage. Ikke nok med at
            renter og gebyrer ikke bliver betalt, hvilket skulle have været kreditors
            overskud, men hvis personen også har brugt pengene, så kan det meget let blive
            en stor negativ forretning for långiveren.
          </p>
          <p>
            For det andet, følger der sjældent god omtale med, når man udsteder et minilån
            til en person på trods af RKI. Som långiver kan man let blive udsillet som en
            uansvarlig långiver, der udlåner penge til folk, som med stor sandsynlighed
            allerede har problemer med at få økonomien til at hænge sammen.
          </p>
          <p>
            Dermed ikke sagt at det ikke er muligt at få et minilån med RKI. Hvis kreditor
            kan se, at grunden til at du står i RKI er en parkeringsbøde eller en bøde på
            biblioteket, som måske blot er en forseelse, eller fordi at du er uenig i
            bøden, så er det muligt, at dette ikke bliver tolket som
            betalingsvanskeligheder - især hvis det kun er et lille beløb, som du ønsker
            at låne trods RKI.
          </p>
          <p>
            Det vil nok altid kræve en uddybning til långiveren, hvis du skal have
            bevilliget et minilån trods RKI, men hvis forklaringen lyder rimelig, og din
            økonomi ellers også ser ud til at kunne bære renter og afdrag på et minilån,
            så er det muligt at få et minilån trods RKI.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-kan-man-bruge-et-lille-laan-til">
            Hvad kan man bruge et lille lån til
          </h2>
          <p>
            Et lille lån bruges ofte til at få hverdagen til at hænge sammen i form af
            reparationer, generhvervelse og uforudsete udgifter, samt gaver til diverse
            fødselsdage og højtider. Med mindre dine store drømme er billige, så vil et
            lille lån nok ikke være relevant.
          </p>
          <p>
            Hvis vi starter med de helt <strong>små lån</strong> på 500 kr og 1.000 kr.,
            så kan disse dække gaver til børn, børnebørn, <strong>konfirmationer</strong>,
            samt andre ting i småtingsafdelingen. Her er er behovet nemlig ofte kun et
            meget lille lån.
          </p>
          <p>
            Tager vi næste skridt på trappen, vil minilån på 3.000 kr. og 2.000 kr. ofte
            kunne dække mindre reparationer på transportmidler, samt diverse
            kvartalsregninger, som har sneget sig ind i postkassen. I nogle tilfælde vil
            et mindre lån også kunne dække behovet, men med et minilån på 3.000 kr., vil
            de fleste uforudsete udgifter af denne type kunne dækkes.
          </p>
          <p>
            Hvis vi kommer op i størrelsesordnen 4.000 kr. eller et minilån på 5.000 kr.,
            kan dette, for de flestes vedkommende dække købet af en
            <strong>cykel</strong>, <strong>vaskemaskine</strong>,
            <strong>opvaskemaskine </strong>eller dække en
            <strong>tandlægeregning</strong>. Disse genstande fås selvfølgeligt også
            dyrere, og nogle gange dyrere end et minilån kan dække.
          </p>
          <p>
            Hvis vi går helt op på et minilån på 10.000 kr., hvilket ofte vil være det
            største
            <a
              href="https://saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              >lånebeløb</a
            >, som man vil karakterisere som et minilån, kan dette bruges som lån til en
            <strong>ny seng</strong>, <strong>nye møbler</strong>, et
            <strong>lån til en ny mobil</strong>, eller andet <strong>elektronik</strong>.
          </p>
          <p>
            Der vil selvfølgeligt være overlap mellem beløbsgrupperne ovenfor. Dette er
            blot for at nævne nogle af de produkter, områder og kategorier, som vi ved, at
            minilåne ofte bliver brugt til. Det står dig helt frit for, hvordan du ønsker
            at bruge pengene, når du optager et lille lån.
          </p>
        </div>

        <div class="text-container">
          <h2 id="et-gratis-minilaan">Et gratis minilån</h2>
          <p>
            Som markedet er i dag, er det ikke længere muligt at finde gratis minilån. Du
            kan stadigvæk finde billige minilån, men et gratis minilån finder du desværre
            ikke. De gratis minilån, som du tidligere kunne finde, og måske har hørt om,
            havde dog også en begrænset løbetid på 30 dage.
          </p>
          <p>
            I slutningen af 2020érne lavede regeringen store indgreb på markedet for
            minilån. Det betød at udbyderne af minilån fik svært ved at lave et overskud,
            på trods af, at det tidligere nærmest var umuligt ikke at lave en rigtig god
            forretning. Et af de helt store indgreb på markedet var bla. ÅOP-loftet, som i
            praksis betød at udbydere af bla. minilån blev nødt til at sænke deres rente
            til omkring 1/10 af, hvad den tidligere lå på .
          </p>
          <p>
            Man skulle tro at det faldt godt i tråd med gratis minilån, men det gjorde det
            ikke. Med en rente på en tidendedel af, hvad den tidligere havde ligget på,
            forsvandt en stor del af indtægterne til minilånsfirmaerne, men omkostninger
            til systemer, kontor og personale var uændret. Derfor blev minilånene nødt til
            at skære de 30 gratis dage væk, i håbet om, at de kunne få en lidt højere
            indtjening.
          </p>
          <p>
            Siden indgreb ovenfor, samt flere andre, har der ikke eksisteret gratis
            minilån på det danske marked. Du vil dog stadigvæk kunne finde hjemmesider,
            som fortæller, at de kan tilbyde dig, eller hjælpe dig med at finde gratis
            minilån. På samme måde som minilån uden kreditvurdering, er dette enten
            forældet marteriale, som kræver en gevaldig genopfriskning, eller blot et
            marketingkneb til at lokke dig ind på deres hjemmeside, så de kan sælge
            minilån, som hverken er gratis eller uden renter.
          </p>
          <p>
            Leder du efter et gratis minilån, må vi desværre skuffe dig. Det næstbedste du
            kan få, er et billigt minilån, og det kan vi til gengæld hjælpe dig med at
            finde.
          </p>
        </div>

        <div class="text-container">
          <h2 id="bedste-og-billigste-minilaan">Bedste og billigste minilån</h2>
          <p>
            Hvis du vil finde de bedste og billigste minilån, så er du havnet det helt
            rigtige sted. Vi kan nemlig, med blot én ansøgning fra dig, indhente tilbud på
            gode og billige minilån fra mange spillere på det danske minilånsmarked.
          </p>
          <p>
            For at du kan få det bedste minilån, indhenter vi ikke kun tilbud fra de
            gængse udbydere, men også andre aktører, som er et alternativ til et minilån,
            eller blot udbyder små lån. Hvis du ønsker at finde de billige minilån, så kan
            vi anbefale at benytte vores ansøgningsformular, da vi afsøger en stor del af
            markedet for dig, og vender tilbage med mange tilbud på billige minilån og små
            lån med det samme.
          </p>
          <p>
            Vores systemer er koblet sammen med mange udbydere på markedet. Derved kan vi
            indhente tilbud på minilån, hvilket ikke er mulig for alle
            sammenligningstjenester.
          </p>
          <p>
            Vi kan sagtens hjælpe dig med at finde det billigste minilån, men når vi taler
            minilån, har de fleste også et behov for at det går hurtigt, hvilket vi kommer
            ind på nedenfor.
          </p>
          <h3>Hastighed</h3>
          <p>
            Står du med et akut økonomisk problem, så hjælper det ikke noget, at du får
            dine penge om lang tid. Heldigvis er langt de fleste minilån hurtige. Når du
            ansøger gennem os, fortæller vi dig, hvilke tilbud der er de hurtige minilån,
            og hvilke der er de langsomme.
          </p>
          <p>
            Et hurtigt minilån vil blive udbetalt på dagen, hvor du ansøger. Ofte vil
            minilånet blive udbetalt direkte efter at du har ansøgt, da flere låneudbydere
            overfører med en straksoverførsel. Det betyder, at du modtager pengene på din
            konto med det samme, uanset om du færdiggør din ansøgning efter arbejdstid, i
            weekenden eller på en helligdag.
          </p>
          <p>
            Denne form for minilån har flere betegnelser, bla. et ekspres minilån, et
            hurtigt lille lån eller blot et hurtigt minilån.
          </p>
          <h3>Undgå de langsomme</h3>
          <p>
            Hvis du vælger et af de langsomme minilån, så vil det heldigvis ikke tage
            flere uger at overføre beløbet. Ansøgningsprocessen kan være lidt mere
            omstændig og tidskrævende, og hvis ikke udbyderen af minilånet har
            digitaliseret hele processen, kræver det måske et opkald eller en mail, for at
            lånet bliver godkendt. Det betyder også, at hvis du ikke vælger et af de
            hurtige minilån, så kan du risikere, at du ikke kan gennemføre din ansøgning
            uden for almindelig arbejdstid.
          </p>
          <p>
            Hvis du ikke vælger et hurtigt minilån, kan du også komme ud for, at dine
            penge ikke kan udbetales med det samme, som du bliver godkendt. I realteten
            kan lånet godt udbetales, men da det tager én bankdag at overføre beløbet fra
            låneudbyderens konto til dig, tager det også én dag før du står med lånet på
            kontoen.
          </p>
          <p>
            Lægger vi effekterne oven i hinanden, og er du uheldig at ramme ind i en række
            bankhelligdage i træk, kan der godt gå en lille uge fra du ansøger, til at du
            har pengene på kontoen. Hvis det ikke er noget problem for dig, at dit minilån
            ikke er hurtigt, så bør du vælge ud fra prisen. Hvis du derimod vægter højt,
            at det er et hurtigt minilån, så vær opmærksom på, ovenstående områder.
          </p>
        </div>

        <div class="text-container">
          <h2 id="vi-hjaelper-dig-uanset-dit-behov">Vi hjælper dig uanset dit behov</h2>
          <p>
            Hvis du leder efter et minilån, så lad os hjælpe dig. Vi søger efter det
            bedste, billigste og nyeste minilån, blandt et hav af låneudbydere, så vi
            bedst muligt kan hjælpe dig med at finde et godt og billigt minilån, som
            passer til dit behov. Det behøver ikke være svært at finde et lille lån, hvis
            du blot bruger to minutter på at
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne lån</a
            >
            gennem os.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },

  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
