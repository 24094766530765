<template>
  <div class="wrapper">
    <div class="popup">
      <h4 class="title">{{ $t('we_use_cookies') }}</h4>
      <p class="consent-check">
        {{ $t('by_using_our_site_you_accept_cookies') }}
        <b-link target="_blank" style="color: #05083c" :to="consentUrl">{{
          $t('here')
        }}</b-link>
      </p>
      <button class="btn btn-consent mandatory" id="revoke" @click="acceptCookies(false)">
        {{ $t('use_only_required') }}
      </button>
      <button class="btn btn-consent" id="accept" @click="acceptCookies(true)">
        {{ $t('i_accept_all_cookies') }}</button
      ><br />
      <b-link
        target="_blank"
        href="/persondatapolitik#cookies"
        class="consent-check small"
      >
        {{ $t('control_cookies') }}
      </b-link>
    </div>
  </div>
</template>

<script>
export default {
  props: { consentUrl: String },
  methods: {
    acceptCookies(value) {
      this.$store.dispatch('acceptCookies', value)
      if (value === true) {
        window.location.reload()
        fbq('consent', 'grant')
      } else {
        fbq('consent', 'revoke')
      }
    },
  },
}
</script>
<style scoped lang="scss">
.btn-consent {
  background: #0da300;
  color: white;
  border: none;
  transition: all 0.2s;
  border-radius: 5px;
  height: 2.2rem;
  margin: 0.8rem;
  margin-top: 0.1rem;
  &.mandatory {
    background: gray;
  }
  &:hover {
    transform: scale(1.04);
    color: white;
    background: rgb(0, 97, 0);
  }
  &:focus {
    background: rgb(0, 97, 0);
  }
  &:not(:disabled):not(.disabled):active {
    background: rgb(0, 97, 0);
  }
}

.small {
  font-size: 12px;
  text-decoration: underline;
}
a {
  text-decoration: underline;
}
</style>
