<template>
  <div>
    <section id="information" class="flex-fill" v-if="showFullFooter">
      <div class="content">
        <h2>
          {{ $t('we_are_competing_over_30') }}
        </h2>
        <div class="container container-slim">
          <p>{{ $t('saverium_service_info') }}</p>
        </div>
      </div>

      <div class="container container-slim mt-4">
        <div class="title title-logo-wrapper">
          <h3 class="title logos">
            {{ $t('we_are_a_member_of') }}
          </h3>
        </div>
        <div class="row d-flex flex-row partner-wrapper col-12">
          <div class="col-12 col-md-6">
            <b-col cols="12" class="footer-logo">
              <b-link href="https://zpf.pl/">
                <b-img
                  fluid
                  class="logo-big p-2"
                  src="/static/img/saverium_pl/ZPF-logo.png"
                  alt="ZPF"
                />
              </b-link>
            </b-col>
          </div>
          <div class="col-12 col-md-6">
            <div class="col-12 row footer-imgs">
              <b-img
                fluid
                class="logo-big col-6 p-2 zpflogo"
                src="/static/img/saverium_pl/zpf-zdp-cert-img.png"
                alt="Certyfikat audytu etycznego ZPF"
              />
              <b-img
                fluid
                class="logo-big col-6 p-2 zpfcert"
                src="/static/img/saverium_pl/small-certificate.jpg"
                alt="Certyfikat audytu etycznego ZPF mały"
              />
            </div>
            <div class="col-12 row partner-row">
              <p class="col-12 text-center">
                <b-link :to="$root.rn.ZPFZDP" target="_blank">
                  Nasz certyfikat audytu etycznego ZPF
                </b-link>
              </p>
              <p class="col-12 text-center">
                {{ $t('check') }}
                <b-link :to="$root.rn.ZPFPDF" target="_blank">
                  Zasady Dobrych Praktyk
                </b-link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-text-container pt-4 pb-4">
        <div class="container container-slim">
          <h2>{{ $t('online_loan_quickly_with_saverium') }}</h2>
          <p>{{ $t('do_you_want_to_access_offers') }}</p>
          <p>{{ $t('use_the_help_of_experienced_specialist') }}</p>
          <h3>{{ $t('how_does_a_quick_loan_work') }}</h3>
          <p>{{ $t('the_principle_of_saverium_is_simple') }}</p>
          <p>{{ $t('the_only_thing_you_should_do') }}</p>
          <ul>
            <li>{{ $t('the_amount_of_loan') }}</li>
            <li>{{ $t('repayment_period_in_months') }}</li>
            <li>{{ $t('loan_purpose_selection_from_dropdown') }}</li>
            <li>{{ $t('e-mail_address') }}</li>
            <li>{{ $t('phone') }}</li>
            <li>{{ $t('me_and_my_name') }}</li>
          </ul>
          <p>
            {{ $t('you_will_immediatelly_see_installment') }}
            <b-link :to="$root.rn.OnlineNonbankLoans">{{
              $t('installment_link')
            }}</b-link>
            {{ $t('after_submitting_loan_you_recieve_email') }}
          </p>
          <h3>{{ $t('why_a_quick_loan_from_saverium') }}</h3>
          <p>{{ $t('saverium_is_reliable') }}</p>
          <p>{{ $t('the_online_loans_are_tailored') }}</p>
          <h3>{{ $t('choose_saverium_get_loan') }}</h3>
          <p>{{ $t('each_client_who_want_borrow_money') }}</p>
          <p>
            <b-link :to="$root.rn.CashLoan">{{ $t('cash_loans') }}</b-link>
            {{ $t('and') }}
            <b-link :to="$root.rn.OnlinePaydayLoans">{{ $t('payday_loans') }}</b-link>
            {{ $t('you_can_download_online') }}
          </p>
        </div>
      </div>
      <div class="row front-page-container col-12">
        <div class="col-12 col-lg-6">
          <div class="blog-article-img"></div>
        </div>
        <div class="blog-links-container col-12 col-lg-6">
          <div class="container container-slim mt-4">
            <h4>{{ $t('blog') }}</h4>
            <ul class="mt-4">
              <li>
                <b-link :to="$root.rn.LoanForFinishingHouse">
                  Kredyt na wykończenie domu – najważniejsze informacje
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.CashLoan">
                  Kredyt gotówkowy – kalkulator i oferty pożyczek gotówkowych
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.ConsolidationLoan">
                  Kredyt konsolidacyjny – najważniejsze informacje
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.RevolvingLoan">
                  Kredyt odnawialny – najważniejsze informacje
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.RefinancingLoan">
                  Kredyt refinansowy – najważniejsze informacje
                </b-link>
              </li>

              <li>
                <b-link :to="$root.rn.CarLoan">
                  Kredyt samochodowy – najważniejsze informacje
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.BusinessLoan">
                  Kredyt dla firm – najważniejsze informacje
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.LoanInstallment"
                  >Pożyczki online na raty – pożyczki chwilówki na raty</b-link
                >
              </li>
              <li>
                <b-link :to="$root.rn.OnlinePaydayLoans"
                  >Pożyczki chwilówki online – kredyty chwilówki przez internet</b-link
                >
              </li>
              <li>
                <b-link :to="$root.rn.OnlineNonbankLoans"
                  >Pożyczki pozabankowe online – kredyty pozabankowe przez
                  internet</b-link
                >
              </li>
              <li>
                <b-link :to="$root.rn.LoansToHome"
                  >Pożyczki do domu – nowość, pożyczki udzielane w domu klienta</b-link
                >
              </li>
              <li>
                <b-link :to="$root.rn.LoansToAccount"
                  >Pożyczki przez internet na konto bankowe
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.LoanForTwoMonths"
                  >Pożyczka na 61 dni – chwilówka na 2 miesiące
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.LoanWithoutBik">
                  Pożyczka bez BIK-u – szybkie pożyczki online bez baz
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.LoansForProof">
                  Pożyczki na dowód przez Internet – chwilówki na dowód
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.LoansWithoutCertificates">
                  Pożyczki bez zaświadczeń przez internet – bez formalności
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.InstantInternetLoans">
                  Pożyczka od ręki – chwilówki od ręki przez internet
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.LoansInFifteenMinutes">
                  Pożyczka online w 15 minut – ekspresowe pożyczki
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.LoanOnDeclaration">
                  Pożyczka na oświadczenie – na czym polega?
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.OnlineLongtermLoans">
                  Pożyczki długoterminowe online – pożyczaj na 24, 36, 48, 60, a nawet 120
                  miesięcy</b-link
                >
              </li>
              <li>
                <b-link :to="$root.rn.Loans24Hours">
                  Pożyczki 24h przez internet - chwilówki 24/7
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.LoanWithNoIncome">
                  Pożyczka bez udokumentowanych dochodów
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.LoansWithoutCreditWorthiness">
                  Pożyczki bez zdolności kredytowej - czy to możliwe?
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.UrgentLoanToday">
                  Pilna pożyczka na dzisiaj – ekspresowe chwilówki na dzisiaj
                </b-link>
              </li>
              <li>
                <b-link :to="$root.rn.PaydayLoansNoDatabases">
                  Chwilówki bez baz - pożyczki online bez sprawdzania baz
                </b-link>
              </li>
            </ul>
          </div>
          <b-link
            :to="$root.rn.LoanView"
            class="btn btn-primary btn-saverium btn-wide next-btn btn btn-front mb-4"
          >
            {{ $t('get_a_loan') }}
          </b-link>
        </div>
      </div>
    </section>
    <footer class="d-flex flex-column" id="footer">
      <div class="footer d-flex">
        <div class="container-fluid no-gutters d-flex justify-content-center">
          <div class="row w-100 text-center text-md-left">
            <div
              class="col order-last col-12 text-center col-xxl order-xxl-first text-xxl-left"
            >
              <b-link :to="$root.rn.MainView" aria-label="Saverium">
                <img
                  src="/static/img/saverium_logo.png"
                  alt="Saverium logo"
                  class="logo"
                />
              </b-link>
            </div>
            <div class="col col-12 col-md-6 col-lg-3 col-xxl-auto">
              <span class="title">LVS Brokers Sp. z o.o.</span>
              <span>
                KRS 0000759418
                <br />NIP 9721296774 <br />KNF
                <a
                  href="https://www.knf.gov.pl/podmioty/wyszukiwarka_podmiotow#561140"
                  rel="noopener"
                  target="_blank"
                  class="gold-link"
                  >RPK028139</a
                >
              </span>
            </div>
            <div class="col col-12 col-md-6 col-lg-3 col-xxl-auto">
              <span class="title">{{ $t('address') }}</span>
              <span>
                NOBEL PRO
                <br />Pańska 96, <br />00-837 Warszawa, <br />7 piętro, lokal 83
              </span>
            </div>

            <div class="col col-12 col-md-6 col-lg-3 col-xxl-auto">
              <span class="title">{{ $t('customer_service') }}</span>
              <span>Pon - Pt</span>
              <br />9:00 - 17:00 <br />22 307 07 74 <br />kontakt@saverium.pl
            </div>
            <div class="col-12 col-xl-4 col-lg-3 row footer-link-list">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <b-link :to="$root.rn.LoanView">{{ $t('get_a_loan') }}</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.TosPDF" target="_blank">{{
                    $t('terms_of_usage')
                  }}</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.MarketingConsentsPDF" target="_blank">{{
                    $t('privacy_policy')
                  }}</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.rnLoanFor200000">Kredyt 200 tys złotych</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.LoanFor150000">Kredyt 150 tys złotych</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.rnLoanFor100000">Kredyt 100 tys złotych</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.LoanFor90000">Kredyt 90 tys złotych</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.LoanFor80000">Kredyt 80 tys złotych</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.LoanFor70000">Kredyt 70 tys złotych</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.LoanFor60000">Kredyt 60 tys złotych</b-link>
                </li>
              </ul>
              <ul class="nav flex-column flex-xxl-column">
                <li class="nav-item">
                  <b-link :to="$root.rn.LoanFor50000">Kredyt 50 tys złotych</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.LoanFor40000">Kredyt 40 tys złotych</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.LoanFor35000">Kredyt 35 tys złotych</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.LoanFor30000">Kredyt 30 tys złotych</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.LoanFor25000">Kredyt 25 tys złotych</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.LoanFor20000">Kredyt 20 tys złotych</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.LoanFor15000">Kredyt 15 tys złotych</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.LoanFor10000">Kredyt 10 tys złotych</b-link>
                </li>
                <li class="nav-item">
                  <b-link :to="$root.rn.LoanFor5000">Kredyt 5 tys złotych</b-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  computed: {
    showFullFooter: function () {
      const path = this.$route.path
      return path === this.$root.rn.MainView
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
