<template>
  <div class="row article">
    <div class="col-xl-8 col-12">
      <h1 class="center-title">
        Billigere lån online? Digitale banker er også kommet til Danmark
      </h1>
      <div class="text-container">
        <p>
          <i
            >Rimelige renter og fleksible lånevilkår er i stigende grad årsagen til, at en
            dansker vælger et lån, der tilbydes af en digital bank. Efterhånden som
            konkurrencen stiger på lånemarkedet, bliver sammenligningen af banker og
            lånetilbud vigtigere.</i
          >
        </p>
        <picture>
          <source
            srcset="/static/img/saverium_dk/article-imgs/hand-money.webp"
            type="image/webp"
          />
          <source
            srcset="/static/img/saverium_dk/article-imgs/hand-money.jpg"
            type="image/jpg"
          />
          <img
            width="700"
            height="466"
            class="article-img"
            src="/static/img/saverium_dk/article-imgs/hand-money.jpg"
            alt="Billigere lån online? Digitale banker er også kommet til Danmark"
          />
        </picture>
        <p>
          Da forbrugeradfærden ændrer sig fra fysiske interaktioner til den digitale
          verden, er det i løbet af de sidste par år blevet mere og mere populært for
          danskere at søge lån online i stedet for at besøge deres bankkontor. Disse
          moderne digitale banker, der kun opererer online, har hurtigt hævet deres
          popularitet selv til niveauet for traditionelle danske banker. Typiske
          tjenester, de tilbyder i Danmark, inkluderer f.eks. forbrugerlån,
          <b-link :to="$root.rn.ConsolidationLoan">samlelån</b-link>
          og kreditkort.
        </p>
        <p>
          Danskerne foretrækker disse moderne digitale banker, især som långivere, da de
          ofte er i stand til at tilbyde et meget konkurrencedygtigt prisniveau og mere
          fleksible service- og lånevilkår end traditionelle operatører. F.eks. kan
          åbnings- og håndteringsomkostningerne forbundet med et lån være betydeligt
          lavere. Du kan ansøge om et lån direkte fra bankerne på deres hjemmeside, men
          den nemmeste måde at nå bankerne på er med en enkelt ansøgning ved hjælp af den
          nye, sikre og fortrolige lånesammenligningstjeneste, Saverium.dk.
        </p>
      </div>
      <div class="text-container">
        <h2>Kundeservice på dansk og overkommeligt prisniveau</h2>
        <p>
          At disse banker kun tilbyder deres tjenester online betyder, at de muligvis ikke
          har nogen fysiske steder i Danmark. Kommunikation og online-interaktion er dog
          hurtig og finder sted på dansk, hvilket gør disse bankers tjenester til en
          levedygtig mulighed for internet kyndige danskere (danskerne er forresten en af
          de mest avancerede nationer inden for internet- og mobilbrug!)
        </p>
        <p>
          At sende en låneansøgning og modtage et tilbud online er meget hurtig, og om
          nødvendigt er kundeservice tilgængelig på dansk både via e-mail og telefon.
          Online bankernes popularitet er blevet styrket af de gode oplevelser hos danske
          kunder med lave renter, hurtige svartider og pålidelighed.
        </p>
      </div>
      <div class="text-container">
        <h2>
          Bankernes konkurrence vokser - Du bør altid sammenligne tilbuddene fra din
          hovedbank og fra de andre banker
        </h2>
        <p>
          På vores sammenligningstjeneste kan du søge et lån hurtigt og nemt - og helt
          gratis. Vi sammenligner lån op til 500,000 kr. mellem flere forskellige banker
          og finansielle institutioner. Blandt banker og finansielle institutioner er
          flere traditionelle såvel som alle de digitale banker, der er kommet ind på det
          danske marked. Du kan nemt sammenligne lånetilbud fra flere banker ved kun at
          indsende en låneansøgning.
        </p>
        <p>
          Brug af Saverium.dk til at ansøge om et lån sparer dig tid og penge. Baseret på
          din ansøgning beregner banker og finansielle institutioner, der arbejder sammen
          med os, dig et personlig lånetilbud, og vi samler alle de tilbud, du modtager
          klart, på en side i et let sammenligneligt format. Ved at sammenligne tilbuddene
          kan du sikre dig, at du ikke ender med for dyre lånekontrakter og kan finde et
          tilbud, der passer til dine behov. At få lånetilbud er ikke bindende for at tage
          et lån: Du kan sammenligne tilbud, og hvis du finder et passende tilbud, kan du
          nemt acceptere det direkte online.
        </p>
      </div>
      <div class="text-container">
        <h2>Sådan fungerer sammenligningsservice i praksis</h2>
        <p>
          <b-link :to="$root.rn.MainView"
            >Du vælger det ønskede lånebeløb og låneperioden på Saverium.dk</b-link
          >
          startside eller i låneberegneren til højre på denne side. Du vil også straks se
          et overblik over den månedlige rater for lånet. Dette giver dig mulighed for at
          evaluere, hvordan tilbagebetaling af lånet passer ind i dit budget. Når du går
          videre til låneansøgningen, beder vi om oplysninger såsom din ansættelsesstatus,
          indkomst og eksisterende gæld, der giver banker og långivere mulighed for at
          beregne et personligt lånetilbud til dig.
        </p>
        <p>
          Når låneansøgningen er afsluttet, videresender vi den til de banker og
          finansielle institutioner, vi samarbejder med. Du får låne afgørelsen med det
          samme, og de første tilbud kommer inden for få minutter. Vi opretter en konto
          til Saverium-konto, hvor det er nemt at gennemgå og sammenligne modtagne
          lånetilbud. Når du har fundet det rigtige lånetilbud til dig, kan du acceptere
          det direkte fra dit Saverium-konto, hvorefter du bliver sendt til långiverens
          side for at underskrive låneaftalen med dit NemID. På dette tidspunkt kan nogle
          långivere også bede dig om mere information til støtte for din lånebeslutning,
          såsom eSkat-samtykke eller en lønseddel.
        </p>
        <p>
          Udfyldning af en låneansøgning binder dig ikke til at tage et lån, og det er
          helt gratis at lave en lånesammenligning, så du skal altid lave en
          lånesammenligning, når du søger at tage et lån.
        </p>
        <p>2020-09-14 / Søren</p>
      </div>
    </div>
    <div class="col-xl-4 col-12">
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
  </div>
</template>
<script>
import LoanWidget from '@/components/saverium_dk/seo_components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'

export default {
  components: {
    LoanWidget,
  },
  data() {
    return {
      visible: false,
    }
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss" scoped></style>
