<template>
  <div class="row article">
    <h1 class="center-title">
      Kredyt konsumencki vs kredyt konsumpcyjny - jakie są różnice?
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >Określenia kredyt konsumencki i kredyt konsumpcyjny bardzo często stosowane
              są zamiennie. Tymczasem te dwa produkty finansowe znacząco się od siebie
              różnią. Podpowiadamy, jakie dokładnie są między nimi różnice i na co
              powinieneś zwracać uwagę jeszcze przed podpisaniem umowy kredytowej.</i
            >
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/consumer-credit-tiny.jpeg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/consumer-credit.jpeg"
            alt="Kredyt konsumencki vs kredyt konsumpcyjny - jakie są różnice?"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
          <h2 id="1">
            {{ contents[0].title }}
          </h2>
          <p>
            Kredytem konsumenckim nazywamy produkt finansowy, który udzielany jest
            klientom przez bank lub instytucję pozabankową. Jest on dedykowany wyłącznie
            osobom fizycznym, które mogą przeznaczyć otrzymane pieniądze na dowolny cel,
            który jednak nie może być przeznaczony na działalność gospodarczą. Jak sama
            nazwa wskazuje, są to kredyty skierowane do konsumenta.
          </p>
          <p><b>Kredytami konsumenckimi są między innymi:</b></p>
          <ul>
            <li>kredyty samochodowe</li>
            <li><b-link :to="$root.rn.CashLoan">kredyty gotówkowe</b-link></li>
            <li>kredyty ratalne</li>
            <li>kredyty konsolidacyjne</li>
            <li><b-link :to="$root.rn.OnlinePaydayLoans">chwilówki</b-link></li>
            <li>
              <b-link :to="$root.rn.LoanInstallment">pożyczki online na raty</b-link>
            </li>
          </ul>
          <p>
            <b
              >W umowie kredytu konsumenckiego powinno znaleźć się kilka konkretnych
              zapisów. Należy wymienić między innymi:</b
            >
          </p>
          <ul>
            <li>dokładne dane kredytobiorcy</li>
            <li>adres kredytobiorcy</li>
            <li>nazwa kredytodawcy i miejsce jego siedziby</li>
            <li>organ, który zarejestrował działalność kredytodawcy</li>
            <li>termin spłaty zobowiązania</li>
            <li>warunki spłaty zobowiązania</li>
            <li>wszystkie informacje o warunkach odstąpienia od umowy</li>
            <li>roczną stopę oprocentowania oraz warunki jej zmiany</li>
            <li>wysokość rocznej stopy oprocentowania przeterminowanych należności</li>
          </ul>
          <p>
            Uważne czytanie umowy to najlepszy sposób na bezpieczne pożyczanie pieniędzy.
            Zawsze powinny znaleźć się w niej wszystkie powyższe kwestie.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            W przypadku produktu, jakim jest kredyt konsumpcyjny ustawa nie określa
            żadnych konkretnych definicji ani ograniczeń. Co to oznacza w praktyce? Tego
            typu kredyt regulowany jest przez Prawo bankowe, jednak w żadnej ustawie nie
            ma wzmianki o jego maksymalnej kwocie.
          </p>
          <p>
            Kredyt konsumpcyjny jest więc niczym innym, jak tylko kredytem gotówkowym,
            który można przeznaczyć na dowolny cel – od remontu salonu po potrzeby
            działalności gospodarczej. Może to być zarówno kredyt udzielany przez banki
            oraz instytucje pozabankowe – na przykład w formie takiej, jak
            <b-link :to="$root.rn.LoansToAccount">pożyczka przez Internet na konto</b-link
            >.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Kredyt konsumencki a kredyt konsumpcyjny to dwie różne kwestie. Aby dobrze
            zrozumieć, czym właściwie się od siebie różnią, najlepiej przyjrzeć się
            różnicom pomiędzy tymi dwoma produktami.
          </p>
          <p>
            Umowa o kredyt konsumencki może być podpisana wyłącznie z osobą fizyczną. W
            praktyce każda osoba, która nie prowadzi działalności gospodarczej może wziąć
            kredyt konsumencki. Do jakiej kwoty? Według Ustawy maksymalna wysokość takiego
            zobowiązania wynosi 255 550 złotych (lub równowartość tego w innej walucie).
            Tymczasem z kredytu konsumpcyjnego skorzystać może każdy a jego wysokość nie
            została ograniczona żadnym przepisem ani ustawą.
          </p>
          <p>
            Zasady udzielania kredytu konsumenckiego są dokładnie określone przez przepisy
            polskiego prawa. Dodatkowo kredytu konsumenckiego udziela się na cel wskazany
            przez klienta we wniosku. Tymczasem kredyt konsumpcyjny może być wypłacony na
            dowolny cel – kredytodawca nie ingeruje w żaden sposób w to, na co klient
            przeznaczy otrzymane pieniądze.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Wiele osób zastanawia się, czy kredyt hipoteczny jest kredytem konsumpcyjnym.
            Pod pojęciem kredytu hipotecznego kryje się zobowiązanie, którego bank udziela
            nam w celu umożliwienia zakupu domu lub mieszkania. Zabezpieczeniem jest wpis
            hipoteki do księgi wieczystej nieruchomości na rzecz banku.
          </p>
          <p>
            Z tego względu często uważa się, że kredyt hipoteczny jest tożsamy z pojęciem
            kredytu konsumpcyjnego. Tymczasem nic bardziej mylnego. Taki stan rzeczy
            faktycznie obowiązywał do roku 2011, czyli do momentu wejścia w życie
            odpowiedniej Ustawy. Obecnie kredyt hipoteczny nie jest kredytem konsumenckim,
            jednak warto wiedzieć, że niektóre zapisy, które go dotyczą mają odniesienie
            także do kredytów mieszkaniowych. Co na przykład?
          </p>
          <p>
            Przede wszystkim mowa o konieczności informowania klienta przez kredytodawcę o
            wszelkich zmianach w umowie i jej warunkach.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            Każdy, kto zastanawia się nad wzięciem kredytu konsumenckiego musi
            odpowiedzieć sobie na pytanie jaką kwotę pożyczyć. Nie ma przepisów, które
            regulują dolną granicę kredytu konsumpcyjnego. Banki zazwyczaj oferują kredyty
            od 500 złotych w górę.
            <b-link :to="$root.rn.InstantInternetLoans">Pożyczka od ręki</b-link> w
            niektórych przypadkach może być nawet mniejsza – niektóre firmy oferują
            pożyczki od stu, czy dwustu złotych.
          </p>
          <p>
            Co w przypadku górnej kwoty? Niezależnie od tego, czy jest to kredyt
            gotówkowy, czy kredy ratalny, kwota nie może przekraczać 255 550 złotych lub
            równowartości tego w innej walucie. Łatwo zauważyć, że jest to stosunkowo duża
            ilość pieniędzy. W praktyce stosunkowo rzadko zdarza się, aby bank lub firma
            pożyczkowa udzielił komuś takiego kredytu. Wymaga to doskonałej historii
            kredytowej i wysokich zarobków.
          </p>
          <p>
            Każda instytucja finansowa określa ramy kredytów, których udziela. Zazwyczaj
            nie jest to więcej niż sto pięćdziesiąt tysięcy złotych. Tego typu produkt
            finansów powinien być bowiem dostosowany do możliwości kredytobiorcy. Spłata
            bardzo dużej kwoty w stosunkowo krótkim czasie może być problematyczna. W
            Saverium masz możliwość wnioskowania o kredyt lub pożyczkę w wysokości od 100
            do nawet 200 000 zł z okresem spłaty od miesiąca do nawet 120 miesięcy.
            Wypełnij jeden prosty wniosek, aby otrzymać atrakcyjne oferty pożyczek lub
            kredytu.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            Pożyczanie pieniędzy powinno być przede wszystkim bezpieczne. Właśnie dlatego
            w poszukiwaniu dodatkowej gotówki warto kierować się przede wszystkim
            reputacją instytucji finansowej, do której się zwracamy.
          </p>
          <p>
            Banki z pewnością są instytucjami godnymi zaufania. Zawsze przed wzięciem
            kredytu konsumpcyjnego warto jednak dokładnie przeczytać umowę i w razie
            wątpliwości zgłosić się do doradcy finansowego lub prawnika.
          </p>
          <p>
            W ostatnich latach coraz popularniejsze stały się instytucje pozabankowe,
            które udzielają różnego rodzaju
            <b-link :to="$root.rn.MainView">pożyczek online</b-link> i kredytów. Chociaż
            przez lata tak zwane chwilówki cieszyły się złą sławą, obecnie są one objęte
            przepisami dotyczącymi kredytów konsumenckich i mogą być uznane za całkowicie
            bezpieczne. Mimo wszystko warto sprawdzać wszystkie oferty i czytać opinie
            dotyczące firmy, z którą chcemy podpisać umowę.
          </p>
          <p>
            Obecnie chwilówki i
            <b-link :to="$root.rn.LoanInstallment">pożyczki ratalne</b-link> dostępne są
            od ręki nawet przez Internet. Aby sprawdzić, czy firma jest godna zaufania
            można skorzystać z popularnych porównywarek instytucji finansowych i
            pozabankowych. Możesz także wziąć pożyczkę za pośrednictwem naszej platformy.
            W gronie naszych partnerów są tylko renomowane firmy pożyczkowe o ugruntowanej
            pozycji na rynku finansowym.
          </p>
          <p>
            Pojęcie kredytu konsumenckiego i kredytu konsumpcyjnego bywa ze sobą wzajemnie
            mylone. Przed skorzystaniem z usług banku lub firmy pożyczkowej warto jednak
            pamiętać o tym, że są to zupełnie inne oferty, które różnią się od siebie
            regulacjami prawnymi. Maksymalna wysokość kredytu konsumenckiego jest
            regulowana przez odpowiednią, przytoczoną we wcześniejszych akapitach artykułu
            ustawę.
          </p>
          <p>
            Przed skorzystaniem z oferty instytucji finansowej pamiętaj, aby dobrze
            zapoznać się z warunkami umowy. Niezależnie od tego, czy jest to kredyt
            gotówkowy, czy ratalny ważne jest przede wszystkim bezpieczeństwo
            kredytobiorcy. Najlepsza pożyczka to taka, która jest możliwa do spłacenia w
            terminie. Nigdy nie powinniśmy brać kredytu lub pożyczki, jeżeli nie mamy
            pewności, że uda nam się ją spłacić w terminie. Warto pamiętać, że
            nieodpowiedzialne pożyczanie pieniędzy może w krótkim czasie doprowadzić do
            <b-link :to="$root.rn.DebtSpiral">spirali zadłużenia</b-link>. Myślisz o
            wzięciu pożyczki lub kredytu? Sprawdź również podstawowe zasady
            <b-link :to="$root.rn.ResponsibleBorrowing"
              >odpowiedzialnego pożyczania pieniędzy</b-link
            >.
          </p>
          <p>
            Skorzystaj także z
            <b-link :to="$root.rn.LoanCalculator">kalkulatora kredytu online</b-link>,
            dostępnego na naszej stronie.
          </p>
        </div>
      </div>
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  data() {
    return {
      contents: [
        {
          title: 'Co to jest kredyt konsumencki?',
          link: '#1',
        },
        {
          title: 'Co to jest kredyt konsumpcyjny?',
          link: '#2',
        },
        {
          title: 'Kredyt konsumencki a kredyt konsumpcyjny',
          link: '#3',
        },
        {
          title: 'Kredyt konsumencki a kredyt hipoteczny',
          link: '#4',
        },
        {
          title: 'Kredyt konsumencki - na jaką kwotę?',
          link: '#5',
        },
        {
          title: 'Jak bezpieczne wziąć kredyt konsumencki?',
          link: '#6',
        },
      ],
    }
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>

<style lang="scss" scoped></style>
