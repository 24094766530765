<template>
  <div class="article-wrapper" data-type="1" data-language="pl">
    <div class="article row col-12">
      <h1 class="center-title">
        Kredyt gotówkowy mając kredyt hipoteczny - jakie są warunki
      </h1>
      <div class="row col-12">
        <div class="article-container col-12 col-lg-8">
          <div class="text-container">
            <p>
              Kredyt hipoteczny to wieloletnie zobowiązanie finansowe, które najczęściej
              służy do zakupu własnego mieszkania bądź domu. Kredytobiorcy często
              zainteresowani są dobraniem
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-kalkulator-i-oferty-pozyczek-gotowkowych/"
                rel="noopener noreferrer"
                target="_blank"
                >kredytu gotówkowego</a
              >, który ma za zadanie pokryć na przykład koszty związane z remontem bądź
              innego typu wydatki. Czy mając kredyt hipoteczny można wziąć kredyt
              gotówkowy?
            </p>
            <b-img-lazy
              width="700"
              height="466"
              blank-src="/static/img/saverium_pl/article-images/cash-loan-with-mortgage-tiny.webp"
              blank-height="700px"
              blank-width="466px"
              class="article-img"
              src="/static/img/saverium_pl/article-images/cash-loan-with-mortgage.webp"
              alt="Kredyt gotówkowy mając kredyt hipoteczny - jakie są warunki"
            />
            <div class="toc container">
              <h3>{{ $t('table_of_contents') }}</h3>
              <ul class="table-of-contents">
                <li>
                  <a href="#dlaczego-przy-kredycie-hipotecznym-trudniej-o-gotowkowy"
                    >Dlaczego przy kredycie hipotecznym trudniej o gotówkowy?</a
                  >
                </li>
                <li>
                  <a href="#jak-ocenia-sie-zdolnosc-kredytowa-osob-posiadajacych-hipoteke"
                    >Jak ocenia się zdolność kredytową osób, posiadających hipotekę?</a
                  >
                </li>
                <li>
                  <a href="#kredyt-gotowkowy-przy-hipotece-na-jaka-kwote"
                    >Kredyt gotówkowy przy hipotece - na jaką kwotę?</a
                  >
                </li>
                <li>
                  <a href="#kredyt-hipoteczny-i-gotowkowy-jakie-problemy-moga-sie-pojawic"
                    >Kredyt hipoteczny i gotówkowy - jakie problemy mogą się pojawić?</a
                  >
                </li>
                <li>
                  <a href="#kto-nie-otrzyma-kredytu-gotowkowego-majac-hipoteke"
                    >Kto nie otrzyma kredytu gotówkowego, mając hipotekę?</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="text-container">
            <h2 id="dlaczego-przy-kredycie-hipotecznym-trudniej-o-gotowkowy">
              Dlaczego przy kredycie hipotecznym trudniej o gotówkowy?
            </h2>
            <p>
              Bank, udzielając finansowania, musi mieć pewność, że klient będzie w stanie
              terminowo spłacić swoje zobowiązanie wraz z należnymi odsetkami. W tym celu
              ocenia
              <a
                href="https://www.saverium.pl/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/"
                rel="noopener noreferrer"
                target="_blank"
                >zdolność kredytową</a
              >
              potencjalnego kredytobiorcy, biorąc pod uwagę między innymi pozostałe
              kredyty czy pożyczki, które ten musi spłacać. Posiadając kredyt hipoteczny,
              trudniej jest o gotówkowy, gdyż ten pierwszy znacznie zaniża zdolność
              kredytową. Klient będzie musiał spłacać dwa zobowiązania, które w sumie
              opiewają na wysoką kwotę, zamiast jednego. Dlatego też osoby, które ubiegają
              się o kredyt gotówkowy mając hipotekę, muszą wykazać się znacznie wyższą
              zdolnością kredytową, niż gdyby zobowiązania hipotecznego nie posiadały.
            </p>
          </div>

          <div class="text-container">
            <h2 id="jak-ocenia-sie-zdolnosc-kredytowa-osob-posiadajacych-hipoteke">
              Jak ocenia się zdolność kredytową osób, posiadających hipotekę?
            </h2>
            <p>
              Procedura oceny zdolności kredytowej zasadniczo nie różni się w przypadku,
              gdy klient posiada kredyt hipoteczny i w sytuacji, gdy nie ma on żadnych
              innych zobowiązań finansowych. Bank zawsze bierze pod uwagę:
            </p>
            <ul>
              <li>wysokość miesięcznych dochodów,</li>
              <li>źródło dochodów,</li>
              <li>ilość osób, będących na utrzymaniu kredytobiorcy,</li>
              <li>średnie miesięczne wydatki gospodarstwa domowego.</li>
            </ul>
            <p>
              Dla banku ważne są również pozostałe zobowiązania, jakie posiada
              kredytobiorca - w tym przypadku będzie to kredyt hipoteczny. Fakt, iż klient
              musi co miesiąc spłacać ratę kredytu, powoduje, że jego zdolność kredytowa
              ulega automatycznie obniżeniu. W rzeczywistości osoba, która chciałaby
              zaciągnąć kredyt gotówkowy do hipotecznego, musi wykazać stosunkowo wysokie
              miesięczne dochody.
            </p>
          </div>

          <div class="text-container">
            <h2 id="kredyt-gotowkowy-przy-hipotece-na-jaka-kwote">
              Kredyt gotówkowy przy hipotece - na jaką kwotę?
            </h2>
            <p>
              Klienci najczęściej zadają sobie pytanie, czy można dostać kredyt gotówkowy,
              mając kredyt hipoteczny, jednak warto zwrócić uwagę przede wszystkim na
              możliwą do uzyskania kwotę kredytu. Obecnie kredyty gotówkowe udzielane są
              nawet na kilkaset tysięcy złotych, lecz zwykle taka oferta znajduje się poza
              zasięgiem kredytobiorcy. Bank, analizując sytuację finansową klienta,
              sprawdza, ile maksymalnie mogłaby wynosić miesięczna rata kredytu
              gotówkowego. Na tej podstawie szacuje maksymalną kwotę kredytu, jaką klient
              może uzyskać. Warto mieć na uwadze, że oferta każdego banku jest nieco inna,
              dlatego warto je między sobą porównać, zanim złożymy wniosek w konkretnym
              oddziale.
            </p>
          </div>

          <div class="text-container">
            <h2 id="kredyt-hipoteczny-i-gotowkowy-jakie-problemy-moga-sie-pojawic">
              Kredyt hipoteczny i gotówkowy - jakie problemy mogą się pojawić?
            </h2>
            <p>
              Posiadanie dwóch kredytów jednocześnie stanowi spore wyzwanie logistyczne,
              bowiem każde zobowiązanie trzeba spłacać w terminie. Należy więc ustawić
              spłatę kredytu jako zlecenie stałe, tak aby nie zapomnieć o miesięcznej
              racie. Pamiętajmy, że ratę kredytu hipotecznego i gotówkowego spłacamy
              osobno - zobowiązań tych nie łączy się, nawet jeśli zostały one zaciągnięte
              w jednym banku. Kolejny problem ma charakter stricte finansowy, gdyż z
              biegiem lat mogą się pojawić problemy w spłacie kredytów, jeśli nasza
              sytuacja materialna ulegnie pogorszeniu. W takich przypadkach należy podjąć
              negocjacje z bankiem i postarać się na przykład o wakacje kredytowe.
            </p>
          </div>

          <div class="text-container">
            <h2 id="kto-nie-otrzyma-kredytu-gotowkowego-majac-hipoteke">
              Kto nie otrzyma kredytu gotówkowego, mając hipotekę?
            </h2>
            <p>
              Banki są w stanie przyznać dodatkowy
              <a
                href="https://www.saverium.pl/artykuly/kredyt-ratalny/"
                rel="noopener noreferrer"
                target="_blank"
                >kredyt ratalny</a
              >, jeśli hipoteka jest jedynym zobowiązaniem, jakie w danym momencie posiada
              klient. W przypadku, gdy zaciągnął on już jeden
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-na-dowolny-cel/"
                rel="noopener noreferrer"
                target="_blank"
                >kredyt gotówkowy na dowolny cel</a
              >, szanse na pozyskanie kolejnego są bardzo niskie. Ponadto, o kredyt nie
              mogą ubiegać się osoby, które nie spłacają sumiennie zobowiązania
              hipotecznego. Nie jest on dostępny dla kredytobiorców, którzy mają zbyt
              niską zdolność kredytową, aby jednocześnie spłacać dwa zobowiązania w tym
              samym czasie. Pamiętajmy, że bank każdego klienta ocenia indywidualnie,
              zatem chcąc uzyskać kredyt gotówkowy, należy złożyć wniosek w wybranym banku
              i oczekiwać na odpowiedź.
            </p>
            <p>
              Możesz także wypełnić jeden prosty wniosek za pośrednictwem Saverium, aby
              ubiegać się o pożyczkę online lub kredyt gotówkowy w wysokości do 200 000
              zł, maksymalnie na 120 miesięcy.
            </p>
            <p>
              Sprawdź,
              <a
                href="https://www.saverium.pl/artykuly/kto-moze-otrzymac-kredyt/"
                rel="noopener noreferrer"
                target="_blank"
                >kto może otrzymać kredyt i jakie są warunki uzyskania</a
              >
              kredytu i wypełnij wniosek online!
            </p>
          </div>
        </div>
        <div class="container container-widget col-12 col-lg-4">
          <h4>{{ $t('get_a_loan_easily') }}</h4>
          <LoanWidget class="small-range mt-4" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div style="margin: auto">
        <RelatedArticlesComponent />
      </div>
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
export default {
  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
