<template>
  <div class="mt-5" style="text-align: center">
    <b-card-group
      class="list-unstyled article-list"
      v-for="(article, index) in latestArticles.slice(
        Math.max(latestArticles.length - 3, 0)
      )"
      :key="index"
    >
      <b-card no-body class="overflow-hidden">
        <b-row no-gutters>
          <b-col md="6">
            <b-card-img
              :src="article.src"
              blank-color="#abc"
              :alt="article.title"
              class="rounded-0"
              max-width="100px"
              height="100%"
              width="100%"
              style="margin: auto"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body>
              <b-card-title class="small">
                <b-link :to="article.url">
                  {{ article.title }}
                </b-link>
              </b-card-title>
              <b-card-text>
                <p class="small">
                  <i>{{ article.date }}</i>
                </p>
                <p class="small mb-1 text-truncate">
                  {{ article.description }}
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-card-group>
  </div>
</template>
<script>
export default {
  computed: {
    latestArticles() {
      return this.$store.state.articles.articles.filter(article => article.src)
    },
  },
}
</script>
<style lang="scss"></style>
