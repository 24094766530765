<template>
  <div class="article-wrapper" data-type="1" data-language="pl">
    <div class="article row">
      <h1 class="center-title">Kredyt a umowa na czas określony</h1>
      <div class="row col-12">
        <div class="article-container col-12 col-lg-8">
          <div class="text-container">
            <p>
              Wiele mówi się o tym, że banki o wiele łaskawiej patrzą na klientów, którzy
              ubiegając się o kredyt, posiadają umowę o pracę na czas nieokreślony.
              Tymczasem kredytobiorcy, którzy zatrudnieni są w ramach umowy o pracę na
              czas określony, również mają szansę na
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-kalkulator-i-oferty-pozyczek-gotowkowych/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>uzyskanie kredytu gotówkowego</strong></a
              >. Umowa na czas określony, a kredyt - jak wygląda to od strony formalnej?
            </p>
            <b-img-lazy
              width="700"
              height="466"
              blank-src="/static/img/saverium_pl/article-images/cash-loan-and-fixed-contract-tiny.webp"
              blank-height="700px"
              blank-width="466px"
              class="article-img"
              src="/static/img/saverium_pl/article-images/cash-loan-and-fixed-contract.webp"
              alt="Kredyt a umowa na czas określony"
            />
            <div class="toc container">
              <h3>{{ $t('table_of_contents') }}</h3>
              <ul class="table-of-contents">
                <li>
                  <a
                    href="#czy-mozna-dostac-kredyt-gotowkowy-przy-umowie-na-czas-okreslony"
                    >Czy można dostać kredyt gotówkowy przy umowie na czas określony?</a
                  >
                </li>
                <li>
                  <a href="#warunki-kredytowania-czy-umowie-na-czas-okreslony"
                    >Warunki kredytowania czy umowie na czas określony</a
                  >
                </li>
                <li>
                  <a href="#po-jakim-czasie-pracy-mozna-dostac-kredyt"
                    >Po jakim czasie pracy można dostać kredyt?</a
                  >
                </li>
                <li>
                  <a href="#kredyt-przy-umowie-na-czas-okreslony-czy-warto"
                    >Kredyt przy umowie na czas określony - czy warto?</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="text-container">
            <h2 id="czy-mozna-dostac-kredyt-gotowkowy-przy-umowie-na-czas-okreslony">
              Czy można dostać kredyt gotówkowy przy umowie na czas określony?
            </h2>
            <p>
              W głowach potencjalnych kredytobiorców często rodzi się pytanie, czy żeby
              dostać kredyt trzeba mieć umowę na stałe, to znaczy na czas nieokreślony?
              Odpowiedź brzmi nie, choć często osobom zatrudnionym na czas określony
              trudniej jest pozyskać finansowanie. Umowa na czas określony wygasa
              automatycznie po okresie, na który została zawarta. Pracodawca nie ma
              obowiązku jej przedłużać, nie musi również podawać powodu, dla którego nie
              chce tego uczynić. Dlatego też umowa na czas określony nie stanowi dobrego
              zabezpieczenia ani dla banku, ani dla samego kredytobiorcy. Problem polega
              jednak na tym, że zgodnie z polskim prawem, umowa na czas określony może być
              zawarta aż na 33 miesiące, czyli prawie na 3 lata! W tym czasie pracownik
              może ubiegać się o finansowanie, lecz niekoniecznie na duże sumy - na
              przykład o kredyt na 10 tys. zł.
            </p>
          </div>

          <div class="text-container">
            <h2 id="warunki-kredytowania-czy-umowie-na-czas-okreslony">
              Warunki kredytowania czy umowie na czas określony
            </h2>
            <p>
              Wiadomo już, czy do kredytu musi być umowa na czas nieokreślony, jednak
              warto zastanowić się, jakie warunki kredytowania przygotowano dla osób,
              które nie posiadają stałego kontraktu? Otóż sam fakt posiadania umowy o
              pracę nie sprawia jeszcze, że wnioskujący o
              <a
                href="https://www.saverium.pl/artykuly/kredyt-konsumencki-vs-kredyt-konsumpcyjny-jakie-sa-roznice/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>kredyt konsumpcyjny</strong></a
              >
              może go otrzymać. Należy spełnić dodatkowe warunki, które dotyczą na
              przykład:
            </p>
            <ul>
              <li>wysokości dochodów miesięcznych,</li>
              <li>
                ilości osób, pozostających na utrzymaniu kredytobiorcy (przy danych
                dochodach),
              </li>
              <li>innych zobowiązań, pozostających do spłacenia.</li>
            </ul>
            <p>
              Ponadto, jeśli klient posiada niespłacone długi, nie może liczyć na
              uzyskanie kredytu, bez względu na to, jaka jest podstawa nawiązania stosunku
              pracy. Warto mieć na uwadze, że kredyt gotówkowy dla osób z umową na czas
              określony zwykle opiewa na niskie kwoty.
              <a
                href="https://www.saverium.pl/artykuly/kredyt-20-tys-zlotych-pozyczki-pozabankowe-do-20-000-zl-przez-internet/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>Kredyt 20 tys. zł</strong></a
              >
              można otrzymać, jeśli kredytobiorca będzie w stanie spłacić go, zanim umowa
              o pracę wygaśnie. W praktyce oznacza to, że rata kredytu będzie naprawdę
              wysoka. Jeszcze trudniej jest uzyskać
              <a
                href="https://www.saverium.pl/artykuly/kredyt-30-tys-online-jak-dostac-kredyt-30-000-zl-przez-internet/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>kredyt 30 tys</strong></a
              >. i na wyższe sumy, gdyż najczęściej kredytobiorca charakteryzuje się zbyt
              niską
              <a
                href="https://www.saverium.pl/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>zdolnością kredytową</strong></a
              >.
            </p>
          </div>

          <div class="text-container">
            <h2 id="po-jakim-czasie-pracy-mozna-dostac-kredyt">
              Po jakim czasie pracy można dostać kredyt?
            </h2>
            <p>
              Innym aspektem, jaki często zastanawia kredytobiorców, jest długość okresu
              zatrudnienia, przy której można ubiegać się o kredyt. Ile czasu umowa o
              pracę do kredytu? Banki zwykle wymagają, aby klient był zatrudniony u danego
              pracodawcy przez co najmniej miesiąc, choć w niektórych przypadkach o
              finansowanie można ubiegać się dopiero po 3-6 miesiącach pracy. Warto mieć
              na uwadze, że banki biorą pod uwagę konkretną umowę, nie jest zatem ważna
              ciągłość zatrudnienia - chyba, że wcześniej podpisywaliśmy umowę z dokładnie
              tym samym pracodawcą. W tym miejscu trzeba zaznaczyć, że umowa na czas
              określony może być zawarta trzykrotnie, zanim pracodawca wystawi umowę o
              pracę na czas nieokreślony.
            </p>
          </div>

          <div class="text-container">
            <h2 id="kredyt-przy-umowie-na-czas-okreslony-czy-warto">
              Kredyt przy umowie na czas określony - czy warto?
            </h2>
            <p>
              Kredyt gotówkowy może zostać udzielony osobie, która posiada umowę o pracę
              na czas określony, jednak sam okres kredytowania ulega skróceniu do momentu
              wygaśnięcia kontraktu. Możemy więc poczekać do czasu uzyskania umowy na czas
              nieokreślony, jeśli zależy nam na większych sumach. Jest to opłacalne
              szczególnie w momencie, gdy pracodawca wystawił nam promesę dalszego
              zatrudnienia, a kolejna umowa będzie zawarta na czas nieokreślony. Wtedy też
              możemy wnioskować o kredyt na znacznie wyższe kwoty, nawet kilkudziesięciu
              tysięcy złotych. Warto mieć na uwadze, że rodzaj umowy nie jest jedynym
              czynnikiem, jaki bank bierze pod uwagę przy ocenie zdolności kredytowej.
              Jeżeli kredytodawca odmówi udzielenia finansowania, można ubiegać się o
              <a
                href="https://www.saverium.pl/artykuly/pozyczki-pozabankowe-online-kredyty-pozabankowe-prezez-internet/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>pożyczkę pozabankową</strong></a
              >.
              <a
                href="https://www.saverium.pl/artykuly/kredyt-a-pozyczka-sprawdz-jakie-sa-roznice/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>Pożyczka a kredyt</strong></a
              >
              to różne instrumenty finansowe - pożyczka pozabankowa udzielana jest na
              uproszczonych warunkach, także osobom z niską zdolnością kredytową.
            </p>
          </div>
        </div>
        <div class="container container-widget col-12 col-lg-4">
          <h4>{{ $t('get_a_loan_easily') }}</h4>
          <LoanWidget class="small-range mt-4" />
        </div>
      </div>
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
export default {
  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
