<template>
  <div>
    <div class="article">
      <h1 class="center-title">Kassekredit</h1>

      <div class="top-text text-container">
        <p>
          Leder du efter en billig kassekredit eller et lån? Vi hjælper dig med at finde
          de bedste og billigste kassekreditter på markedet. Hvis du udfylder vores korte
          ansøgningsskema indhenter vi, med det samme, tilbud på kassekreditter til dig,
          så du hurtigt og nemt kan vælge den kassekredit, som passer bedst til dig og dit
          behov.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#ansoeg-om-kassekredit-igennem-os"
                    >Ansøg om kassekredit igennem os</a
                  >
                </li>
                <li><a href="#faa-en-billig-kassekredit">Få en billig kassekredit</a></li>
                <li><a href="#hvad-er-en-kassekredit">Hvad er en kassekredit</a></li>
                <li>
                  <a href="#kassekredit-online-eller-i-banken"
                    >Kassekredit online eller i banken</a
                  >
                </li>
                <li>
                  <a href="#nye-kassekreditter-og-laan">Nye kassekreditter og lån</a>
                </li>
                <li>
                  <a href="#kassekredit-til-virksomheder">Kassekredit til virksomheder</a>
                </li>
                <li>
                  <a href="#kassekredit-til-studerende">Kassekredit til studerende</a>
                </li>
                <li>
                  <a href="#kan-jeg-faa-en-kassekredit">Kan jeg få en kassekredit?</a>
                </li>
                <li><a href="#skal-vi-hjaelpe-dig">Skal vi hjælpe dig?</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="ansoeg-om-kassekredit-igennem-os">Ansøg om kassekredit igennem os</h2>
          <p>
            Hos os kan du ansøge om kassekredit og lån. Vi afsøger markedet blandt vores
            lånepartnere, for at finde frem til gode og billige kassekreditter til dig.
            Det eneste det kræver fra dig, er at du udfylder vores korte online
            ansøgingsformular herunder.
          </p>
          <p>
            Herefter finder vi tilbud til dig, som du ikke behøver at stille sikkerhed
            for, hvilket også kaldes “kassekredit uden sikkerhed”. Afslutningsvis lister
            vi alle de tilbud, som vi har indhentet, så du hurtigt og nemt kan vælge den
            bedste, hurtigste eller billigste kassekredit. Opret din ansøgning nu, og få
            svar med det samme.
          </p>
        </div>

        <div class="text-container">
          <h2 id="faa-en-billig-kassekredit">Få en billig kassekredit</h2>
          <p>
            Hvis du leder efter en kassekredit, er der flere parametre, som er vigtige. En
            af de helt afgørende parametre er, om du kan få en billig kassekredit. Vi vil
            gerne hjælpe dig med at finde ud af, hvor billig en kassekredit du kan få. Den
            bedste måde at finde ud af hvor billig en kassekredit du kan få, er ved at
            afsøge markedet, hvilket er lige præcis det, som vi kan hjælpe med.
          </p>
          <p>
            At ansøge om en kassekredit gennem os er helt gratis og desuden helt
            uforpligtende. Samtidig er det en nem måde at få et overblik over dine
            muligheder for at finde en billig kassekredit. Netop det er også årsagen til,
            at et stigende antal danskere benytter os år efter år.
          </p>
          <h3>Hvad koster en kassekredit</h3>
          <p>
            Kassekreditter varierer meget i pris, og netop derfor kan det være svært at
            gøre sig klog på prisen på en kassekredit. Nogle kassekreditter har lave
            renter, men høje gebyrer. Andre har høje renter, men lave gebyrer.
          </p>
          <p>
            Renter på kassekreditter er dog ikke altid den mest relevante
            sammenligningsparameter, hvis du leder efter en billig kassekredit. Ofte vil
            ÅOPén være den bedste parameter, hvis du ønsker at
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne</a
            >
            financielle produkter.
          </p>
          <p>
            ÅOP, som står for årlige omkostninger i procent, tager højde for alle
            omkostninger, som påløber din dyre / billige kassekredit. Hvis vi fx antager,
            at du skal benytte den over en periode på 5 år, vil ÅOPén fortælle dig,
            hvilken en af de to kassekreditter, som er billigst og dyrest for den givne
            periode. Af denne årsag anbefaler vi sjældent at du udelukkende leder efter en
            kassekredit med lav rente, da du kan blive pålagt omkostninger på anden vis.
          </p>
          <p>
            Hvis løbetiden er ukendt, kan det dog være en fordel at lede efter en
            kassekredit med en lav rente. Det glæder især, hvis du tænker at den skal
            benyttes over en lang periode, da engangsomkostninger så som et opstartsgebyr,
            kan blive forsvindende små sammenhold med renteomkostnignerne, med mindre du
            vælger en kassekredit med en lav rente.
          </p>
          <p>
            Hvis du er heldig, kan du finde en gratis kassekredit med en ÅOP på 0%.
            Tidligere var det ikke unormalt as finde dem med 30 dages rentefrihed. Det
            finder du dog ikke i samme udstrækning i dag grundet lovgining på området, som
            har begrænset mange af de udbydere, som tidligere tilbød gratis kassekredit.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-er-en-kassekredit">Hvad er en kassekredit</h2>
          <p>
            Hvad er en kassekredit egentligt? Det er en slags backup for ens bankkonto,
            som bliver brugt af privatpersoner og virksomheder, hvis man løber ind i
            omkostninger mens ens konto er tom. Her vil overtrækket blive dække, og du vil
            ikke modtage et opkald fra banken og en streg i deres sorte bog.
            Kassekredit-lånet, som det også betegnes, står til rådighed i den periode, som
            du aftaler med dit pengeinstitut. For privatpersoner, vil det typisk være en
            ubegrænset periode.
          </p>
          <p>
            En kassekredit fungerer i stor stil på samme måde, som hvis du skulle optage
            et
            <a
              href="https://saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtig lån</a
            >. Du kan finde kassekreditter og lån, som tilbyder samme
            <a
              href="https://saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              >lånebeløb</a
            >, og de kan derfor også ofte erstatte hinanden. Den primære forskel er, at du
            vil opleve mere fleksibilitet med førstnævnte, sammenlignet med et lån, både i
            forhold til at hæve yderligere og i forhold til afbetaling. Lånet vil dog
            typisk være en anelse billigere end kreditten af samme årsag. Hvor meget du
            ville kunne hæve kassekreditten og lånet, kommer dog an på, hvilken aftale
            banken/långiveren tilbyder.
          </p>
          <p>
            Nogle gange kan du opleve at du skal skifte bank for at oprette en
            kassekredit. Hvis du ansøger om en online kassekredit gennem os, viser vi dog
            udelukkende muligheder, hvor du ikke behøver skifte bank for at oprette din
            kassekredit. Du kan beholde din nuværende bank og oprette kassekreditten hos
            en anden udbyder, hvis det er det, du ønsker.
          </p>
          <p>
            En kassekredit har en masse fordele, men der er også nogle ulemper, som man
            skal huske at tænker over inden man opretter en.
          </p>
          <h3>Fordele</h3>
          <p>
            Hvad koster en ny kassekredit? Det ses ofte, at det er gratis at oprette en
            kassekredit. Hvis man er førstegangskunde hos en udbyder af online
            kassekredit, er det ikke sikkert, at du skal betale hverken gebyrer eller
            renter på kassekreditten til at starte med, hvilket ligeledes gør sig gældende
            for lån.
          </p>
          <p>
            At udlåne penge fra bankens side er dog ikke gratis, da banken både skal have
            dækket deres omkostninger, tab og samtidig skabe en profit. Derfor forbliver
            en kassekredit aldrig gratis, men du kan ofte finde en billig kassekredit, som
            også forbliver billig på længere sigt.
          </p>
          <p>
            Det kan sagtens være en stor omkostning at få et <strong>kreditlån</strong>.
            Det kan sagtens koste over 1.000 kr., og af den årsag er vigtig, at du holder
            øje med hvad stiftelsesgebyret ligger på og sammenligner dét med de andre
            omkostninger. Det er ofte gratis at have en ny kassekredit, hvis du ikke
            anvender den. Først når du begynder at trækker på den, starter du med at
            betale gebyrer, provision og renter.
          </p>
          <p>
            En anden fordel ved at låne på denne måde er, at man også selv vælger hvor
            meget man ønsker at afdrage. Overtrækker du med fx 10.000 kr., kan du selv
            bestemme om du vil betale det af med det samme, eller om du vil splitte det op
            og betale over længere tid. Du skal dog være opmærksom på, at jo længere du
            venter med at betale beløbet tilbage, jo dyrere bliver det også i form af
            renter på kassekreditten.
          </p>
          <p>
            Set med de positive briller, har du mulighed for at tilbagebetale beløbet i
            dit eget tempo, så du kan få det til at passe med din økonomi. Hvis din
            indkomst er variablel måned for måned, kan der være måneder, hvor du hurtigt
            kan barbere gælden ned, mens der andre måneder kan trækkes, hvis din
            indkomster lav. Husk derfor også at se på, hvor meget du betaler for renter på
            kassekreditten.
          </p>
          <p>
            Løber du ind i en stor uforudset udgift, behøver du ikke at skulle ud og tegne
            et
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >nyt lån</a
            >, så længe du har mulighed for at trække på kassekreditten. Der er rigtig
            mange dansker som i dag anvender deres kassekredit/lån som en buffer i
            økonomien til de pludseligt opståede økonomisk udfordringer, hvor man skal
            bruge penge, som man ikke lige har på lommen.
          </p>
          <h3>Ulemper</h3>
          <p>
            Efter de ca. 30 dage skal du være opmærksom på at der begynder at komme
            provision, gebyrer samt renter på din kassekredit.
          </p>
          <p>
            En af de største ulemper, i forhold til et almindeligt
            <a
              href="https://saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >
            er, at renten på kassekreditten i de fleste tilfælde er noget højere end ved
            at optage et lån. Dog betaler du kun renter, når du anvender din kassekredit.
            På grund af den højere rente, kan det være, at du skal overveje, om det er en
            fornuftig ide, hvis du står og mangler et stort beløb over en længere periode.
            Måske kommer du til at betale højere renter på kassekreditten, end du ville
            skulle på et tilsvarende lån.
          </p>
          <p>
            En anden ulempe er, at du med et almindeligt lån automatisk afdrager det over
            fx 5 til 15 år. Det gør du ikke automatisk på en kassekredit. og derved kan
            du, hvis du ikke er opmærksom på det, stå med en lige så stor gæld om 15 år,
            som du har betalt renter og gebyrer for i hele perioden. Vi håber du fik svar
            på hvad en kassekredit er.
          </p>
        </div>

        <div class="text-container">
          <h2 id="kassekredit-online-eller-i-banken">
            Kassekredit online eller i banken
          </h2>
          <p>
            Det er ikke kun muligt at optage en hurtig kassekredit online. Det er også en
            mulighed at oprette den i en fysisk bank. Herunder vil vi gennemgå nogle af de
            fordele og ulemper, som vi ser ved den gammeldags metode i banken, samt
            fordelene du får ved en online kassekredit.
          </p>
          <h3>Online kassekredit</h3>
          <p>
            Vælger du at optage et nyt kassekredit “lån” online, vil du opleve, at kravene
            til dig som ansøger generelt er lavere, end hvis du havde ansøgt i en
            bankfilial. Som regel er der størst chance for at kunne få en kassekredit,
            hvis du er over 25 år.
          </p>
          <p>
            Kendetegnet ved en online kassekredit er, at den er hurtigt, og at det nemt at
            ansøge. Faktisk skal du blot udfylde en ansøgningsformular på nettet, hvilket
            du kan gøre her på vores side. En online kassekredit har nogle af de samme
            fordele, som du finder ved et
            <a
              href="https://saverium.dk/laan-penge/kvilaan"
              rel="noopener noreferrer"
              target="_blank"
              >kviklån</a
            >, men ikke altid de samme ulemper. Derfor betegnes en online kassekredit også
            “kviklån kassekredit” eller “en hurtig kassekredit”.
          </p>
          <p>
            Ansøgning bruger du også som en beregner til kassekreditten, da den vil
            fortælle dig udgiften i forbindelse med denne. Når du har udfyldt ansøgningen,
            vil du efter kort tid vil blive informeret om, hvorvidt du er godkendt eller
            afvist hos en lang række udbydere af online kassekredit og lån.
          </p>
          <p>
            På samme måde som at det ikke er muligt at få et lån uden en kreditvurdering,
            er det heller ikke mulig at åbne en online kassekredit, uden at der først
            laves en kreditvurdering af dig. Det er dog muligt at få en kassekredit uden
            at stille sikkerhed.
          </p>
          <h4>Hurtig kassekredit</h4>
          <p>
            En online kassekredit er også en hurtig kassekredit. Da det hele foregår
            online og processerne i stor stil er automatiseret, vil du opleve at din
            ansøgning på en online kassekredit hurtigt ekspederes.
          </p>
          <p>
            Ansøgningen og underskriften foregår med din digitale signatur og få minutter
            efter at du har påbegyndt ansøgningen, kan du stå med en godkendt og åben
            online kassekredit. Selv for os, som arbejder med online og hurtige
            kassekreditter hver dag, er det svært for os at forestille os en hurtigere
            kassekredit, end det vi ser på markedet i dag.
          </p>
          <p>
            Lige så snart du har skrevet under på din aftale, vil udbetalingen gå i gang.
            Hvis du ansøger og bliver godkendt på en hverdag, kan pengene allerede være
            til rådighed for dig samme dag. Hvis du mangler en hurtig kassekredit, så er
            en online-versionen et oplagt valg.
          </p>
          <h3>Få den hos banken</h3>
          <p>
            Forskellen på en ny kassekredit online og hos din bank er faktisk ikke så
            stor. Den største forskel mellem de to er at behandlingstiden på din
            ansøgning. Hvis du går til din bank, vil ansøgningen typisk tage længere tid.
            Faktisk kan du knap nok nå at finde telefonnummeret til din bank og komme
            igennem telefonkøen, før du har udfyld vores online ansøgning og fået
            godkendelsen.
          </p>
          <p>
            Dog har banken nogle andre fordele, der gør at kassekredit i banken kan være
            relevant. Hvis du ansøger om kassekredit i din bank, kan du muligvis få
            økonomisk rådgivning i samme omgang. Det kan være en fordel, hvis du ikke har
            overblik over din økonomi. Samtidig kan det også være intimiderende at skulle
            have din bankrådgiver til at se din økonomi igennem.
          </p>
          <p>
            En anden fordelene ved at oprette en kassekredit i banken er, at den ofte er
            en billig kassekredit sammenlignet med en online kassekredit. Det er ikke kun
            renten, som ofte er billigere i banken, men også oprettelsesomkostninger mv.
            Dette er dog ikke altid tilfældet, hvorfor vi anbefaler, at du som minimum
            også afsøger markedet for online kassekreditter.
          </p>
          <p>
            Der er både fordele og ulemper ved kassekredit online og i banken. Derfor kan
            det være relevant at afsøge begge muligheder, hvis du vil være helt sikker på,
            at du har fundet det bedste tilbud. Hvis du har brug for penge lige nu, så er
            kassekredit online nok den hurtigste løsning. Vi vil ikke gøre os til herre
            over, hvilket valg der er bedst for dig, men hvis du ønsker at sammenligne
            kassekreditter eller lån, er du velkommen til at benytte vores tjeneste.
          </p>
        </div>

        <div class="text-container">
          <h2 id="nye-kassekreditter-og-laan">Nye kassekreditter og lån</h2>
          <p>
            Hos os bestræber vi os hele tiden på at finde helt nye kassekreditter og lån,
            så du får det bedste overblik over lånemarkedet. Ved at holde vores database
            om udbydere på markedet opdateret, kan vi give dig de bedste forudsætninger,
            da vi både kan sende din ansøgning ud til de gamle udbydere af kassekreditter,
            samt udbydere af de helt nye kassekreditter og lån.
          </p>
          <p>
            De nye udbydere på markedet har som regl baseret deres systemer, regler og
            arbejdsgange på en moderne teknologi og nutidens behov. Derfor har de helt nye
            kassekreditter også ofte nogle fordele, som du ikke finder hos de lidt ældre
            versioner.
          </p>
          <p>
            Dermed ikke sagt at de ældre ikke har fordele. Et eksempel på en fordel kan fx
            være, at de har mere data om hvilke typer af kunder, som kan overholde deres
            låneaftale. Hvis du som udgangspunkt har en fin økonomi, men blot mangler en
            kassekredit til at dække småbehov, er det måske muligt at få en billigere
            kassekredit hos de gamle udbydere, frem for de helt nye udbydere af
            kassekreditter.
          </p>
        </div>

        <div class="text-container">
          <h2 id="kassekredit-til-virksomheder">Kassekredit til virksomheder</h2>
          <p>
            Hvis din virksomhed mangler en kassekredit eller ønsker at
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >
            via et lån, har vi to muligheder at tilbyde. Du kan enten ansøge om en
            almindelig kassekredit til din virksomhed på denne side, eller du kan følge
            dette link, for at ansøge om et decideret
            <a
              href="https://saverium.dk/laan-penge/erhvervslaan"
              rel="noopener noreferrer"
              target="_blank"
              >erhvervslån</a
            >. Ansøgningsprocessen for erhverv og private er forskellige, hvilket også gør
            sig gældende for de låneprodukter, som du bliver tilbudt. Hvis du er
            erhvervsdrivende og har brug for kapital til dit firma, vil vi anbefale
            erhvervslånet.
          </p>
        </div>

        <div class="text-container">
          <h2 id="kassekredit-til-studerende">Kassekredit til studerende</h2>
          <p>
            Som studerende har man ikke mange penge at gøre godt med, og kontoen kan ofte
            gå i minus. Derfor kan en kassekredit som studerende være en oplagt løsning.
            Vi tilbyder ikke kun at indhente tilbud på kassekreditter til studerende, men
            i bund og grund at indhente tilbud til alle, som måtte ønske sig dette, eller
            blot et almindeligt lån. Det gælder uanset hvilken situation eller periode i
            deres liv, man befinder sig i.
          </p>
        </div>

        <div class="text-container">
          <h2 id="kan-jeg-faa-en-kassekredit">Kan jeg få en kassekredit?</h2>
          <p>
            Hvis du ønsker at vide om du kan få en kassekredit, så er den mest oplagte
            mulighed at ansøge her på siden. Det tager få minutter at ansøge om en
            kassekredit gennem os, og vi skaffer med det samme tilbud og/eller afvisninger
            fra en lang rækker banker og långivere.
          </p>
          <p>
            Hvis du ikke kan få en kassekredit gennem vores sammenligningstjeneste, er det
            nok begrænset, hvad du har af muligheder. Nedenfor vil vi gennemgå nogle af de
            krav, som ofte bliver stillet i forbindelse med at få en kassekredit.
          </p>
          <h3>Hvilke krav bliver der stillet når jeg ansøger?</h3>
          <p>
            Før du kan blive godkendt til at oprette en kassekredit, skal du leve op til
            nogle krav. Det fungerer mere eller mindre på samme vis, som hvis du skal
            ansøge om et lån. Dog skal det lige nævnes at der kan være en stor forskel på
            de krav, som du kan få stillet ved bankerne i forhold til hvis, du ansøger om
            en kassekredit online.
          </p>
          <p>
            Det er som regel noget nemmere at blive accepteret til en kassekredit online
            end det vil være i banken. Hvis det drejer sig om et mindre beløb, er det
            sjældent at du skal vedhæfte lønsedler, budgetter med mere, når du ansøger om
            en kassekredit. Nedenfor kan du se hvilke krav som altid/ofte bliver stillet.
          </p>
          <ul>
            <li>
              <strong>Du må ikke være registreret i RKI: </strong>RKI står for Ribers
              kredit information, det er et register for folk der ikke betaler sine
              regninger. De fleste udbydere anvender RKI til at undgå de værste betalere.
              Det sker at nogle enkelte udbyder, som har kampagner, hvor det er muligt at
              låne selvom du står registreret i RKI. Det er dog som oftest en fejl, men
              grundet den måde, hvorpå marketing fungerer, kan der i praksis opstå disse
              fejl. Hvis du er registeret i RKI og er blevet afvist, anbefaler vi, at du
              afbetaler din gæld ud før ansøger om en hurtig kassekredit.
            </li>
            <li>
              <strong>Dansk statsborgerskab: </strong
              ><a
                href="https://saverium.dk/laan-penge/privatlaan/laan-med-mitid"
                rel="noopener noreferrer"
                target="_blank"
                >Nem-ID / Mit-ID</a
              >
              samt dansk statsborgerskab er påkrævet i de fleste tilfælde. Når du skriver
              under på din aftale, sker det nemlig online med din digitale signatur.
              Derudover skal du også have bopæl i Danmark.
            </li>
            <li>
              <strong>Du skal minimum være 18 år: </strong>Hvis du skal godkendes til en
              kassekredit online, skal du være fyldt 18 år. Nogle udbyder kræver, at du
              skal være fyldt 20 år. Grunden til at ens alder spiller en rolle, er fordi
              at
              <a
                href="https://saverium.dk/laan-penge/laaneudbydere"
                rel="noopener noreferrer"
                target="_blank"
                >låneudbyderen</a
              >
              vil være sikker på, at du kan betale beløbet tilbage. Det er sjældent at en
              person under 18 år har en særlig stor indkomst, og dermed vil chancen være
              lille, for at han/hun er i stand til at betale renterne og diverse
              omkostninger.
            </li>
          </ul>
        </div>

        <div class="text-container">
          <h2 id="skal-vi-hjaelpe-dig">Skal vi hjælpe dig?</h2>
          <p>
            Ønsker du vores hjælp til at finde en god, hurtig og billig kassekredit, skal
            du blot ansøge via vores formular her på siden. Det tager kun 2 minuter. Vi
            indhenter tilbud til dig helt automatisk, så du i ro og mag kan sammenligne
            kassekreditterne og lånene og vælge den løsning, som passer bedst til dig, dit
            behov og din økonomi. Husk at vores service er gratis og uforpligtende.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  data() {
    return {
      contents: [
        {
          title: 'Hvad er en billig kassekredit?',
          link: '#1',
        },
        {
          title: 'Hvordan virker en billig kassekredit? – Kassekredit fordele og ulemper',
          link: '#2',
        },
        {
          title: 'Hvilke krav skal man leve op til for at få en ny kassekredit?',
          link: '#3',
        },
        {
          title: 'Få lav kassekredit rente online eller fysisk?',
          link: '#4',
        },
        {
          title: 'Kassekredit i banken eller kassekredit online?',
          link: '#5',
        },
      ],
    }
  },

  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
