<template lang="html">
  <div v-if="application.status === 6 && this.$root.brand === 'saverium_dk'">
    <div class="col-md-12 offer-card p-4">
      <p>
        <b>{{ $t('all_your_applications_have_been_cancelled') }}</b>
      </p>
      <p>{{ $t('we_will_contact_you_if_you_happen_to_get_offer') }}</p>
    </div>
  </div>
  <div v-else-if="application.offers.length">
    <b-alert :show="show" fade dismissible @dismissed="hideAlert" id="new-offer-alert">{{
      $t('new_loan_offer_has_been_approved')
    }}</b-alert>
    <div :key="componentKey">
      <offer
        v-for="offer in sortOffers(application)"
        :key="offer.uuid"
        :offer="offer"
        :showall="showall"
        :currency="currency"
        v-on:sortOffers="sortOffers"
      >
      </offer>
    </div>
  </div>
  <div v-else-if="application.status != 4" class="mb-5 spinner-wrapper text-center">
    <lottie-animation
      path="./static/animations/loading-lottie.json"
      :width="256"
      :height="110"
    />
    <p>
      {{ $t('your_application_is_being_processed') }}
    </p>
  </div>
</template>

<script>
import Offer from '@/components/account_view_components/OfferComponent.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import _ from 'lodash'
export default {
  props: ['application', 'componentKey'],

  data() {
    return {
      show: false,
      sortedOffersPL: [],
    }
  },
  components: {
    Offer,
    'lottie-animation': LottieAnimation,
  },
  computed: {
    showall() {
      return this.$store.state.offers.showall
    },
    currency() {
      if (this.$root.brand === 'saverium_pl') {
        return 'zł'
      } else if (this.$root.brand === 'saverium_dk') {
        return 'kr.'
      } else if (this.$root.brand === 'saverium_es') {
        return '€'
      } else {
        return ''
      }
    },
  },

  watch: {
    sortedOffersPL(newOffers, oldOffers) {
      if (this.$root.brand === 'saverium_pl') {
        if (newOffers !== oldOffers && oldOffers.length > 0) {
          if (newOffers[0].product_name !== oldOffers[0].product_name) {
            this.showAlert()
            window.scrollTo({ top: 120, behavior: 'smooth' })
          }
        }
      }
    },
  },

  methods: {
    hideAlert() {
      this.show = false
    },
    showAlert() {
      this.show = true
    },
    sortOffers(app) {
      if (!app) {
        return []
      }
      const offers = _.uniqBy(app.offers, 'uuid')
      const PENDING = 1
      const ACCEPTED = 2
      const REJECTED = 3
      const CANCELLED = 4
      const SELECTED = 5
      const PAIDOUT = 6
      const WITHDRAWN = 7

      const PRIORITY_MAPPING = [
        PAIDOUT,
        SELECTED,
        ACCEPTED,
        PENDING,
        REJECTED,
        WITHDRAWN,
        CANCELLED,
      ]

      // Currently priority values don't go too high, but if it happens, these
      // very scientific not-pulled-out-of-thin-air should be changed to bigger
      // I think.
      // Now there's at least some leeway
      const PRIORITY_MAPPING_VALUES = [
        { key: PAIDOUT, value: 20 },
        { key: SELECTED, value: 15 },
        { key: ACCEPTED, value: 10 },
        { key: PENDING, value: 1 },
        { key: REJECTED, value: 0 },
        { key: WITHDRAWN, value: 0 },
        { key: CANCELLED, value: 0 },
      ]
      const mappedOffers = app.offers.map(offer => {
        const index = PRIORITY_MAPPING.indexOf(offer.status)
        const weight = PRIORITY_MAPPING_VALUES[index].value * 100
        const approvedAmount = offer.options[0]
          ? _.toNumber(offer.options[0].approved_amount)
          : 0
        if (this.$root.brand === 'saverium_dk') {
          offer.priorityStatus = weight * approvedAmount + offer.priority + weight
        } else {
          offer.priorityStatus = offer.priority + weight
        }
        return offer
      })

      const sortedOffers = _.orderBy(mappedOffers, offer => offer.priorityStatus, [
        'desc',
      ])
      if (this.$root.brand === 'saverium_pl') {
        this.sortedOffersPL = sortedOffers
      }
      if (this.showall === true) {
        return sortedOffers
      }
      if (offers.some(offer => offer.status === 6)) {
        return offers.filter(offer => offer.status === 6)
      }
      if (this.$root.brand === 'saverium_pl') {
        return offers.filter(offer => offer.status !== 3)
      }

      return sortedOffers
    },
  },
}
</script>

<style lang="scss" scoped>
.spinner-wrapper {
  font-size: 18px;
}

.btn-danger {
  color: #fff !important;
}

.card-img-top {
  line-height: 7.1rem;
}

.shadow {
  box-shadow: 0 0 !important;
  @media screen and (min-width: 768px) {
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.15) !important;
  }
}

.btn {
  padding: 0.375rem 1rem;
  border-radius: 3rem;
  font-weight: 700;
}

.btn-enhanced:hover {
  color: white;
}

.card-body p:last-child {
  margin-bottom: 0;
}

.border-green {
  border: 1px solid #28a745;
}
</style>
