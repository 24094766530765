<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til studerende</h1>
      <div class="top-text text-container">
        <p>
          Har du brug for et lån som studerende, så kan vi hjælpe dig. Vi finder de
          billigste forbrugslån til studerende blandt mange banker og låneudbydere. Det
          eneste det kræver, er at du bruger 2 minutter på at udfylde vores ansøgning her
          på siden.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#billige-laan-til-studerende">Billige lån til studerende</a>
                </li>
                <li><a href="#laan-til-unge-studerende">Lån til unge studerende</a></li>
                <li><a href="#hvor-meget-kan-man-laane">Hvor meget kan man låne?</a></li>
                <li><a href="#laan-til-studiebolig">Lån til studiebolig</a></li>
                <li><a href="#andre-laaneformaal">Andre låneformål</a></li>
                <li>
                  <a href="#har-du-brug-for-vores-hjaelp">Har du brug for vores hjælp?</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="billige-laan-til-studerende">Billige lån til studerende</h2>
          <p>
            Noget af det sværeste at finde som studerende er et
            <a
              href="https://saverium.dk/laan-penge/"
              rel="noopener noreferrer"
              target="_blank"
              >billigt lån</a
            >
            for netop studerende. Hvis du, som studerende, ansøger om et lån hos en
            tilfældig
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbyder</a
            >, vil du med stor sandsynlighed få et afslag eller en høj rente.
          </p>
          <p>
            Med en spinkel økonomi, som de fleste studerende har, er en høj rente på et
            lån sjældent attraktivt, og hvis behovet for lånet ikke er stort, kan du
            selvfølgeligt altid vælge at fortsætte uden et lån. Hvis du derimod står med
            et økonomisk problem, som ikke kan vente, så har du måske ikke andre
            muligheder end at takke ja til et dyrt tilbud.
          </p>
          <p>
            Det er i hvert fald hvad mange tænker, men heldigvis kan vi hjælpe dig med at
            finde de bedste og billigste lån til dig som studerende. Det eneste det
            kræver, er få minutter af din tid, hvor du afgiver dine oplysninger, så vi kan
            indhente tilbud, heriblandt billige tilbud, på lån til dig som studerende.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-unge-studerende">Lån til unge studerende</h2>
          <p>
            Mange unge studerende har en presset økonomi. Måske er du lige flyttet
            hjemmefra og skal derfor til at betale flere udgifter, end du plejer. Mor og
            far har måske hjulpet med diverse udgifter indtil nu, men nu skal din
            <strong>SU</strong> både række til <strong>husjele</strong>, bøger, transport,
            <strong>mad</strong>, tøj osv., som måske helt eller delvist har været dækket
            af dine forældres økonomi.
          </p>
          <p>
            De fleste studerende tager derfor et studiejob før de låner. Her kan du tage
            et helt almindeligt ufaglært job i en butik, eller med fordel finde dig et
            studierelevant job, som oftest er bedre betalt, men også giver point i form af
            erhvervserfaring.
          </p>
          <p>
            Denne erfaring vil være en fordel, når du dimiterer, og skal ud og have et
            fuldtidsjob. Hvis du har optaget et lån som studerende, vil du med stor
            sandsynlighed også hurtigere kunne afbetale dit lån, hvis du har relevant
            erhvervserfaring fra din studietid. Dette skyldes at dine jobmuligheder, og
            din forhandlingsstyrke i jobsamtalen til dit fremtidige fuldtidsjob forbedres
            betydeligt. Du vil dermed hurtigere kunne lande et job, som er bedre betalt,
            end hvad du ville kunne opnå uden et studiejob.
          </p>
          <p>
            Hvis du er ung studerende og har svært ved at finde et studierelevant job,
            eller blot har midlertidige økonomiske problemer, kan et lån være en god
            mulighed. Her hjælper vi dig med at finde de bedste og billigste lån til
            studerende.
          </p>
          <h3>18 og 19 år</h3>
          <p>
            Der er især mange 18 og-19 årige studerende, som ønsker at låne. Det hænger
            sammen med, at man som 18 eller 19-årig typisk er blevet færdig med
            ungdomsuddannelsen og vælger at flytte til den by, hvor ens uddannelse ligger.
          </p>
          <p>
            Når du flytter hjemmefra, og muligvis langt væk fra dine forældre, beholder du
            med stor sandsynlighed ikke dit job i den lokale butik, hvilket giver en
            økonomisk udfordring oven i alle de nye omkostninger. Hvis du flytter i
            forbindelse med studiestart, kan du derfor også få behov for at låne til
            studiebøgerne, som hurtigt løber op i 2-3.000 kr. pr. semester.
          </p>
          <p>
            Udfordringen for dig som 18 eller 19 årig studerende er, at det kan være svært
            at finde et nogen, som ønsker at bevillige dig et lån. Du kan derfor spare
            meget tid på at ansøge gennem os, så vi kan gøre alt det hårde arbejde, ved at
            indsamle
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >lånetilbud</a
            >
            (og afslag) til dig. Mange af de tilbagemeldinger du får, vil nok desværre
            være afslag, da du endnu ikke har rundet 20 år. Hvis du selv skulle ud at
            indhente tilbud, ville du muligvis ende ud i en situation, hvor du har ansøgt
            tre steder, fået afslag alle gange, og derefter opgivet håbet om at få et lån
            som studerende.
          </p>
          <p>
            Hvis du derimod ansøger igennem os, vil vi indhente tilbud fra en lang række
            banker og långivere på samme tid, så du langt hurtigere kan få et langt bedre
            overblik over dine muligheder for at låne som studerende.
          </p>
          <p>
            Ved at ansøge gennem os, kan du spare dig selv for at finde, ansøge og prøve
            at sammenligne de mange forskelligt opstillede tilbud, som du vil modtager,
            hvilket sagtens kan løbe op i flere dages arbejde. Ansøger du gennem os, vil
            du i modsætning modtage alle dine tilbud inden for få minutter, som er lette
            at sammenligne, så du kan vælge det billigste lån til dig som studerende.
          </p>
          <p>
            Uanset hvor gammel du er, så længe du er fyldt 18 år, kan vi hjælpe dig med at
            finde det rette lån. Vores samarbejdspartnere tilbyder
            <a
              href="https://saverium.dk/laan-penge/laan-til-alle/"
              rel="noopener noreferrer"
              target="_blank"
              >lån til alle</a
            >, som står i en situation, hvor der er økonomisk forsvarlig at låne. Det er
            heller ikke påkrævet at du er studerende.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvor-meget-kan-man-laane">Hvor meget kan man låne?</h2>
          <p>
            Som studerende kan du i princippet låne lige så meget du ønsker, men der er
            dog nogle begrænsninger, som de fleste studerende støder på, hvilket sætter et
            helt naturligt loft over hvor meget man som studerende kan låne.
          </p>
          <p>
            Den første barriere som i næsten alle tilfælde vil føre til et afslag på lån,
            uanset om du er studerende eller ej, er hvis du står i RKI eller Debitor
            Registret. Det er egentligt ikke ulovligt at låne ud til folk der er
            registreret i hverken det ene eller andet af disse to registre over dårlige
            betalere, men det giver med stor sandsynlighed dårlig omtale, og set med
            bankens statistiske briller på, vil der vil være stor risiko for, at disse
            personer ikke kan betale deres lån tilbage.
          </p>
          <p>
            De to sidste paramatre, som ofte er en forhindring er gældsfaktoren (hvor
            meget du tjener holdt op imod hvad du låner) og dit rådighedsbeløb efter
            renter, gebyrer og afdrag er betalt. Dette kaldes også left to live. Hvis dit
            rådighedsbeløb er for lavt, må banken ikke låne dig penge, selv ikke hvis du
            er studerende, og med stor sandsynlighed kan forvente en højere indkomst så
            snart du er færdiguddannet.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-studiebolig">Lån til studiebolig</h2>
          <p>
            En af de andre helst store udgifter for studerende, som kan kræve et lån, er
            din bolig. Herunder vil vi gennemgå nogle af de mest almindelige former for
            til lån til studerende, som der ansøges om i forbindelse med boligen.
          </p>
          <h3>Andelsbolig</h3>
          <p>
            De færreste studerende har økonomi til at købe en lejlighed. Hvis man ønsker
            sit eget sted, leder de fleste studerende derfor efter leje, eller at låne til
            en andelslejlighed. Vi kan desværre ikke hjælpe med at lån til andelslejlighed
            til studerende, men vi kan hjælpe dig med et lån, som kan dække nogle af de
            udgifter, som du måtte have i forbindelse med indflytningen. Det kan fx være
            et indskud til din studiebolig, <strong>møbler</strong>, maling eller noget
            helt fjerde, som kan dækkes med et
            <a
              href="https://saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >
            til studerende.
          </p>
          <p>
            Som studerende kan du, på samme vis som alle andre, søge om både store og små
            <a
              href="https://saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              >lånebeløb</a
            >. Der er ikke nogen grund til at holde dig tilbage med en ansøgning, hvis du
            har behov for at
            <a
              href="https://saverium.dk/laan-penge/"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >
            som studerende.
          </p>
        </div>

        <div class="text-container">
          <h2 id="andre-laaneformaal">Andre låneformål</h2>
          <p>
            Der findes også andre formål hvor et billigt lån til studerende kan være
            relevant. Har du fx behov for et
            <a
              href="https://saverium.dk/laan-penge/billaan"
              rel="noopener noreferrer"
              target="_blank"
              >billån</a
            >
            som studerende, kan du med fordel benytte vores service til at få et overblik
            over dine
            <a
              href="https://saverium.dk/laan-penge/bolig/laanemuligheder"
              rel="noopener noreferrer"
              target="_blank"
              >lånemuligheder</a
            >.
          </p>
          <p>
            Hvis du gennem tiden har optaget flere mindre
            <a
              href="https://saverium.dk/laan-penge/kviklaan"
              rel="noopener noreferrer"
              target="_blank"
              >kviklån</a
            >, kan et
            <a
              href="https://saverium.dk/laan-penge/samlelaan"
              rel="noopener noreferrer"
              target="_blank"
              >samlelån</a
            >
            for studerende også være en oplagt mulighed, hvis du ønsker at spare penge og
            få lidt mere luft i økonomien.
          </p>
        </div>

        <div class="text-container">
          <h2 id="har-du-brug-for-vores-hjaelp">Har du brug for vores hjælp?</h2>
          <p>
            Hvis du på nuværende tidspunkt tænker, at du har behov til at finde de bedste
            og billigste forbrugslån til studerende, så hjælper vi gerne. Som nævnt
            tidligere er 2 minutter af din tid det eneste, som vi har behov for fra dig.
          </p>
          <p>
            De to minutter benytter du på at udfylde vores ansøgningsskema, så vi kan
            indhente tilbud på lån, som er lavet specifikt til dig som studerende, og med
            den økonomi og tidshorizont, som du som har.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
