import i18n from '@/lang'

const t = key => i18n.tc(key)

export const loanSumOptions = [
  { value: '1000', text: '1 000 €' },
  { value: '2000', text: '2 000 €' },
  { value: '3000', text: '3 000 €' },
  { value: '4000', text: '4 000 €' },
  { value: '5000', text: '5 000 €' },
  { value: '10000', text: '10 000 €' },
  { value: '20000', text: '20 000 €' },
  { value: '30000', text: '30 000 €' },
  { value: '40000', text: '40 000 €' },
  { value: '50000', text: '50 000 €' },
  { value: '60000', text: '60 000 €' },
  { value: '70000', text: '70 000 €' },
  { value: '80000', text: '80 000 €' },
  { value: '90000', text: '90 000 €' },
  { value: '100000', text: '100 000 €' },
  { value: '110000', text: '110 000 €' },
  { value: '120000', text: '120 000 €' },
  { value: '130000', text: '130 000 €' },
  { value: '140000', text: '140 000 €' },
  { value: '150000', text: '150 000 €' },
  { value: '160000', text: '160 000 €' },
  { value: '170000', text: '170 000 €' },
  { value: '180000', text: '180 000 €' },
  { value: '190000', text: '190 000 €' },
  { value: '200000', text: '200 000 €' },
  { value: '210000', text: '210 000 €' },
  { value: '220000', text: '220 000 €' },
  { value: '230000', text: '230 000 €' },
  { value: '240000', text: '240 000 €' },
  { value: '250000', text: '250 000 €' },
  { value: '300000', text: '300 000 €' },
  { value: '400000', text: '400 000 €' },
  { value: '500000', text: '500 000 €' },
  { value: '1000000', text: '1 000 000 €' },
  { value: '2000000', text: '2 000 000 €' },
  { value: '3000000', text: '3 000 000 €' },
  { value: '4000000', text: '4 000 000 €' },
  { value: '5000000', text: '5 000 000 €' },
  { value: '6000000', text: '6 000 000 €' },
  { value: '7000000', text: '7 000 000 €' },
  { value: '8000000', text: '8 000 000 €' },
]
export const limitedLoanTimeOptions = [
  { value: '2', text: '3 kk' },
  { value: '6', text: '6 kk' },
  { value: '9', text: '9 kk' },
  { value: '12', text: '12 kk' },
  { value: '18', text: '18 kk' },
  { value: '24', text: '24 kk' },
]
export const loanTimeOptions = [
  { value: '2', text: '3 kk' },
  { value: '6', text: '6 kk' },
  { value: '9', text: '9 kk' },
  { value: '12', text: '12 kk' },
  { value: '18', text: '18 kk' },
  { value: '24', text: '24 kk' },
  { value: '36', text: '3 vuotta' },
  { value: '48', text: '4 vuotta' },
  { value: '60', text: '5 vuotta' },
  { value: '72', text: '6 vuotta' },
  { value: '84', text: '7 vuotta' },
  { value: '96', text: '8 vuotta' },
]

export const positionOptions = [
  { value: null, text: t('choose_position') },
  { value: '1', text: t('board_member') },
  { value: '2', text: t('ceo') },
  { value: '3', text: t('associate_board_member') },
  { value: '4', text: t('cfo') },
  { value: '5', text: t('other_executive') },
  { value: '6', text: t('other_employee') },
]

export const professionStatusOptions = [
  { value: null, text: t('choose_profession_status') },
  { value: '1', text: t('other') },
  { value: '2', text: t('employee') },
  { value: '3', text: t('entrepreneur') },
  { value: '4', text: t('student') },
  { value: '5', text: t('retired') },
]

export const educationOptions = [
  { value: null, text: t('choose_education') },
  { value: '1', text: t('lower_lo_ed') },
  { value: '2', text: t('higher_lo_ed') },
  { value: '3', text: t('middle_ed') },
  { value: '4', text: t('lower_hi_ed') },
  { value: '5', text: t('lower_uni_ed') },
  { value: '6', text: t('higher_uni_ed') },
  { value: '7', text: t('research_ed') },
]

export const dwellingOptions = [
  { value: null, text: t('choose_dwelling') },
  { value: '1', text: t('rental_apartment') },
  { value: '2', text: t('own_apartment') },
  { value: '3', text: t('other') },
]

export const dwellingTypeOptions = [
  { value: null, text: t('choose_dwelling_type') },
  { value: '1', text: t('detached_apartment') },
  { value: '2', text: t('row_apartment') },
  { value: '3', text: t('high_rise_apartment') },
]

export const loanTypeOptions = [
  { value: null, text: t('choose') },
  { value: '1', text: t('limit') },
  { value: '2', text: t('monthly') },
  { value: '3', text: t('once') },
  { value: '4', text: t('other') },
]

export const signatureOptions = [
  { value: 'digital', text: t('digital') },
  { value: 'paper', text: t('paper') },
]

export const vehiclesOwned = [
  { value: '1', text: t('vehicle_none') },
  { value: '2', text: t('vehicle_car') },
  { value: '3', text: t('vehicle_motorbike') },
  { value: '4', text: t('vehicle_both') },
]

const getTurnoverDates = () => {
  let turnoverDates = [
    {
      value: null,
      text: t('choose'),
    },
  ]
  let currentDate = new Date()
  currentDate.setDate(1)
  for (var i = 0; i < 6; i++) {
    currentDate.setMonth(currentDate.getMonth() - 1)
    turnoverDates.push({
      text: `${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`,
      value: currentDate.toISOString().split('T')[0],
    })
  }
  return turnoverDates
}

export const turnoverDates = getTurnoverDates()
