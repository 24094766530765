<template>
  <div class="main-container">
    <div class="main">
      <main-form-view />
    </div>
    <div class="main">
      <b-container class="main-container">
        <paragraph-header :value="$t('faq')" />
        <p>{{ $t('faq_info') }}</p>
        <div class="secondary-title">{{ $t('applying_for_loan') }}</div>
        <p class="bold">{{ $t('applying_for_loan_q') }}</p>
        <p>{{ $t('applying_for_loan_a') }}</p>
        <div class="secondary-title">{{ $t('loan_decisions') }}</div>
        <p class="bold">{{ $t('loan_decisions_q_1') }}</p>
        <p>{{ $t('loan_decisions_a_1') }}</p>
        <p class="bold">{{ $t('loan_decisions_q_2') }}</p>
        <p>{{ $t('loan_decisions_a_2') }}</p>
      </b-container>
    </div>
  </div>
</template>

<script>
import ParagraphHeader from '@/components/lainaayritykselle_fi/ParagraphHeader'
import MainFormView from '@/components/lainaayritykselle_fi/newForm/MainFormView'

export default {
  components: {
    ParagraphHeader,
    MainFormView
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  width: 100%;
  position: relative;
}
.image {
  width: 100%;
  margin-top: -10vw;
}
@media screen and (min-width: 1800px) {
  .image {
    margin-top: -20vw;
  }
}
@media screen and (min-width: 2100px) {
  .image {
    margin-top: -30vw;
  }
}
.secondary-title {
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 10px;
  font-weight: 500;
}

.bold {
  font-weight: 500;
}
</style>
