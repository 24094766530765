import { render, staticRenderFns } from "./ApplicationComponent.vue?vue&type=template&id=055e1a3e&scoped=true&lang=html&"
import script from "./ApplicationComponent.vue?vue&type=script&lang=js&"
export * from "./ApplicationComponent.vue?vue&type=script&lang=js&"
import style0 from "./ApplicationComponent.vue?vue&type=style&index=0&id=055e1a3e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "055e1a3e",
  null
  
)

export default component.exports