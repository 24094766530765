<template lang="html">
  <div class="main">
    <div class="container col-lg-8">
      <h5 class="mt-5 text-center px-2">
        {{ $t('complete_the_loan_application_absolutely_for_free') }}
      </h5>
      <h6 class="mb-5 text-center px-2">
        {{ $t('you_will_reveice_an_immediate_loan_decision') }}
      </h6>
      <div class="form-progress">
        <form-progress :errors="stepErrors" :steps="steps.length" />
      </div>
      <div class="alert alert-danger" ref="hasErrors" role="alert" v-if="hasErrors">
        {{ $t('check_the_fields_marked_in_red') }}<br />
        <i>{{ $t('customer_service_phone_text') }}</i>
      </div>
      <component :is="steps[current]" class="app-container" />
      <div class="col-12 mb-5 btn-row">
        <button
          class="back-btn btn btn-back"
          type="button"
          @click="back"
          :key="'back_' + current"
          v-if="current > 1"
        >
          <i class="fal fa-angle-double-left"></i>
          {{ $t('back') }}
        </button>
        <b-link
          class="back-btn btn btn-back"
          :to="$root.rn.MainView"
          type="button"
          :key="'back_' + current"
          v-if="current === 1"
        >
          <i class="fal fa-angle-double-left"></i>
          {{ $t('back') }}
        </b-link>
        <button
          class="next-btn btn btn-front"
          type="button"
          @click="next"
          :key="'next_' + current"
          v-if="current < steps.length - 1"
        >
          {{ $t('next') }}
          <i class="fal fa-angle-double-right"></i>
        </button>
        <div class="submit-btn d-sm-block" v-else-if="current === steps.length - 1">
          <div class="spinner-wrapper" v-if="loading">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <button
            v-else
            class="next-btn btn btn-front"
            name="submitLoan"
            id="submitLoan"
            @click="submit()"
          >
            {{ $t('apply_for_a_loan_arrow') }}
          </button>
        </div>
      </div>
      <p class="customer-service-text">
        <i>{{ $t('customer_service_phone_text') }}</i>
      </p>
    </div>
  </div>
</template>

<script>
import StepOne from '@/components/saverium_dk/steps/StepOne'
import StepTwo from '@/components/saverium_dk/steps/StepTwo'
import StepThree from '@/components/saverium_dk/steps/StepThree'
import StepFour from '@/components/saverium_dk/steps/StepFour'
import settings from '@/settings/settings'
import FormProgress from '@/components/form/FormProgressDk'
import StepFive from '@/components/saverium_dk/steps/StepFive'
import HeaderComponent from '@/components/saverium_dk/HeaderComponent'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      stepErrors: [],
      steps: ['StepOne', 'StepTwo', 'StepThree', 'StepFour'],
      stepHeaders: [
        ['email', 'phone', 'firstName', 'lastName', 'loanPurpose', 'tos'],

        [
          'dwelling',
          'dwellingDate',
          'civilstatus',
          'dependants',
          'carTypes',

          'businessId',
          'occupation',
          'employedSince',
        ],

        [
          'costOfLiving',
          'leasingExpense',
          'income_gross',
          'income_net',
          'funds_available',
          'other_income',
          'householdConsumption',
          'householdHeatingExpenses',
          'householdUtilitiesExpenses',
          'householdDigitalMediaExpenses',
          'householdInsuranceExpenses',
        ],

        [
          'bankCode',
          'hasStudentloan',
          'hasMortgage',
          'hasCarloan',
          'bank_account',
          'rki',
          'bankconsents',
          'personalDataPolicy',
          'marketing',
          'isDanish',
          'countryResidentSince',
          'foreignerNumber',
          'typeOfResidencePermit',
          'ssn',
        ],
        [
          'coapplicant.email',
          'coapplicant.occupation',
          'coapplicant.firstName',
          'coapplicant.lastName',
          'coapplicant.phone',
          'coapplicant.ssn',
        ],
      ],
    }
  },
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    FormProgress,
    HeaderComponent,
  },
  computed: {
    ...mapState({
      errors: state => state.application.errors,
      current: state => state.application.currentStep,
      requiredFields: state => state.application.requiredFields,
      canProceed: state => state.application.formCanProceed,
      loading: state => state.application.sendingApplication,
      coApplicant: state => state.application.coApplicant,
    }),
    hasErrors() {
      return Object.keys(this.errors).length > 0
    },
    hasErrorsInCurrentStep() {
      const keys = Object.keys(this.errors)

      return this.stepHeaders[this.current].some(v => keys.includes(v))
    },
  },
  watch: {
    hasErrors(newV, oldV) {
      this.checkErrors()
    },
    coApplicant(newStatus, oldStatus) {
      if (newStatus === false) {
        this.steps.splice(4, 1)
      } else if (newStatus === true) {
        this.setSteps()
      }
    },
  },
  created() {
    if (this.hasErrors) {
      this.checkErrors()
    }
    if (this.coApplicant === true) {
      this.setSteps()
    }
  },
  methods: {
    setSteps() {
      if (this.steps.length === 4) {
        this.steps.push('StepFive')
      }
    },
    checkErrors() {
      this.max = this.steps.length - 1
      const keys = Object.keys(this.errors)
      this.stepErrors = this.stepHeaders.reduce((acc, cur, index) => {
        const find = cur.some(v => keys.includes(v))
        if (find && !acc.includes(index)) {
          acc.push(index)
        }
        return acc
      }, [])
    },
    back() {
      this.$store.commit('setStepProgress', this.current - 1)
    },
    next() {
      this.$store.dispatch('checkErrors', this.current)
      this.checkErrors()
      if (this.current <= 1 && this.coApplicant === false) {
        this.steps.splice(4, 1)
      }

      if (
        this.current === 1 &&
        this.$store.state.application.cars.length > 0 &&
        this.$store.state.application.carTypesNull === true
      ) {
        this.$store.commit('setCarError', true)
        return
      }
      if (
        this.current === 1 &&
        this.$store.state.application.dependants > 0 &&
        this.$store.state.application.kidsAgesNull === true
      ) {
        this.$store.commit('setKidError', true)
        return
      }
      if (this.current === 3 && this.$store.state.application.rki === '') {
        return
      }
      if (this.hasErrorsInCurrentStep) {
        const items = document.getElementsByClassName('is-invalid')
        const visible = [...items].filter(el => {
          return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length)
        })

        if (visible.length > 0) {
          window.scrollTo({
            top: items[0].offsetTop,
          })
        }
        return
      }

      this.$store.commit('setStepProgress', this.current + 1)
      window.scrollTo(0, 120)
    },
    submit() {
      if (this.current === 3 && this.coApplicant === true) {
        this.setSteps()
        this.next()
        return
      }
      this.$store.dispatch('checkErrors', this.current)
      this.checkErrors()

      if (settings.GOOGLE_ANALYTICS !== '') {
        try {
          if (typeof ga !== 'undefined') {
            ga('send', 'pageview', 'ansoegning/sent/')
          }
        } catch (e) {
          return e
        }
      }
      this.$store.dispatch('sendApplication')
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-row {
  height: 40px;
}

.btn,
.submit-btn {
  width: 40%;
}

.next-btn,
.submit-btn {
  float: right;
}

.submit-btn {
  height: 40px;
  .btn {
    width: 100%;
  }
}

.form-progress {
  width: 80%;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}
.spinner-wrapper {
  font-size: 60px;
  text-align: center;
}
.app-container {
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
