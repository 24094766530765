import axios from 'axios'
import * as Cookies from 'tiny-cookie'
import { UserManager, User } from 'oidc-client'

const ERROR_FIELD_MAPPING = {
  tos_accepted: 'tos',
  loan_purpose: 'loanPurpose',
  email: 'email',
  phone: 'phone',
  occupation: 'occupation',
  education: 'education',
}

const APPLICANT_ERRORS = {
  first_name: 'firstName',
  last_name: 'lastName',
  email: 'email',
  phone: 'phone',
  dwelling_expenses: 'costOfLiving',
  education: 'education',
  id_number: 'idnumber',
  ssn: 'ssn',
  marital_status: 'civilstatus',
  dependants: 'dependants',
  dwelling: 'dwelling',
  dwelling_area: 'dwellingArea',
  dwelling_date: 'dwellingDate',
  co_living: 'numberOfPeople',
  household_heating_expenses: 'householdHeatingExpenses',
  household_utilities_expenses: 'householdUtilitiesExpenses',
  household_digital_media_expenses: 'householdDigitalMediaExpenses',
  household_insurance_expenses: 'householdInsuranceExpenses',
  existing_loans: 'existingLoans',
}

const ADDRESS_ERRORS = {
  zip_code: 'zipCode',
  city: 'city',
  street: ['street', 'houseNumber', 'apartmentNumber'],
}

const EMPLOYMENT_ERRORS = {
  employer: 'employer',
  start: 'employedSince',
  end: 'employedUntil',
  employment_type: 'occupation',
  job_title: 'profession',
}

const BANK_ACCOUNT_ERRORS = {
  account_type: 'accountType',
  iban: 'bankAccount',
  account_holder: 'accountHolder',
}

class SaveriumAPI {
  AUTH_TOKEN_OBJ(auth) {
    return { withCredentials: true, headers: { Authorization: `Bearer ${auth}` } }
  }

  constructor(config, oidcConfig) {
    if (!(this instanceof SaveriumAPI)) return new SaveriumAPI(config, oidcConfig)

    if (config == null) {
      throw new Error('Config not given')
    }

    if (oidcConfig == null) {
      throw new Error('Oidc config not given')
    }

    if (!config['api_baseUrl'] && !config['API']) {
      throw new Error('api_baseUrl needs to be defined in config.')
    } else if (!config['api_baseUrl']) {
      config['api_baseUrl'] = config['API']
    }

    if (!config['brand_slug'] && !config['BRAND_SLUG']) {
      throw new Error('brand_slug needs to be defined in config.')
    } else if (!config['brand_slug']) {
      config['brand_slug'] = config['BRAND_SLUG']
    }

    this.config = config
    this.oidcConfig = oidcConfig
    this.oidcUserManager = new UserManager(oidcConfig)

    this.axios = axios.create({
      baseURL: config.api_baseUrl,
      headers: { 'Accept-Language': config.locale },
    })

    this.routes = {
      visit_url: `${config.api_baseUrl}/user/visit/`,
      login_url: `${config.api_baseUrl}/token/`,
      application_draft_url: `${config.api_baseUrl}/application/draft/`,
      existing_application_draft_url: uuid =>
        `${config.api_baseUrl}/application/draft/${uuid}/`,
      user_application_drafts_url: `${config.api_baseUrl}/user/application/drafts/`,
      application_url: `${config.api_baseUrl}/loan_application/`,
      offers_url: uuid => `${config.api_baseUrl}/application/${uuid}/offer_list/`,
      user_applications_url: `${config.api_baseUrl}/user/applications/`,
      accept_offer: uuid => `${config.api_baseUrl}/user/application/option/${uuid}/`,
      enums_url: `${config.api_baseUrl}/enums/`,
      cancel_draft_url: uuid => `${config.api_baseUrl}/application/draft/${uuid}/cancel`,
      change_nip_url: `${config.api_baseUrl}/application/companyinfo/`,
      verify_sms: `${config.api_baseUrl}/verify_sms/`,
      update_phone: uuid => `${config.api_baseUrl}/application/${uuid}/update/`,
      eskat_success: uuid =>
        `${config.api_baseUrl}/user/application/option/${uuid}/basisbank-eskat/success/`,
      eskat_failure: uuid =>
        `${config.api_baseUrl}/user/application/option/${uuid}/basisbank-eskat/failure/`,
      axa_get_offers: `${config.api_baseUrl}/insurance/user/axa-ppi-offers/`,
      axa_send_application: `${config.api_baseUrl}/insurance/user/axa-ppi-submit/`,
      chubb_get_child_quote: `${config.api_baseUrl}/insurance/chubb/child/quote/`,
      veritas_send_application: `${config.api_baseUrl}/insurance/veritas/submit/`,
      chubb_start_payment: `${config.api_baseUrl}/insurance/chubb/child/payment/`,
      chubb_create_policy: `${config.api_baseUrl}/insurance/chubb/child/policy/`,
      get_lead_info: uuid => `${config.api_baseUrl}/application/draft/${uuid}/`,
      cancel_application: uuid => `${config.api_baseUrl}/application/${uuid}/cancel`,
      magic_link_entry: uuid => `${config.api_baseUrl}/magic-link/${uuid}/`,
      extra_info_url: url => `${config.api_baseUrl}/application/${url}`,
      sme_application_url: `${config.api_baseUrl}/application/`,
    }
    this.authRoutes = {
      recover_password_url: `${config.AUTH_API}/recover_password/`,
      forgot_password_url: `${config.AUTH_API}/forgot_password/`,
    }
  }

  async refreshLogin() {
    let refreshToken
    await this.oidcUserManager.getUser().then(user => {
      if (user) {
        refreshToken = user.refresh_token
      }
    })
    const data = {
      refresh_token: refreshToken,
      client_id: this.oidcConfig.client_id,
      grant_type: 'refresh_token',
    }
    try {
      const response = await axios({
        method: 'post',
        data: data,
        url: this.oidcConfig.authority + 'token/',
      })
      if (response) {
        this.oidcUserManager.getUser().then(user => {
          if (user) {
            user.access_token = response.data.access_token
            user.refresh_token = response.data.refresh_token
            this.oidcUserManager.storeUser(user)
          }
        })
        return response
      }
    } catch (e) {
      return e
    }
  }

  async login(data) {
    data.append('scope', this.oidcConfig.scope)
    data.append('client_id', this.oidcConfig.client_id)

    const response = await axios({
      method: 'post',
      data: data,
      url: this.oidcConfig.authority + 'token/',
    })

    const user = new User({
      id_token: response.data.id_token,
      access_token: response.data.access_token,
      refresh_token: response.data.refresh_token,
      user: { profile: '' },
    })

    this.oidcUserManager.storeUser(user)
    return user
  }

  async login_from_magic_link(query) {
    if (!query.id_token || !query.access_token) {
      return
    }

    const user = new User({
      id_token: query.id_token,
      access_token: query.access_token,
      refresh_token: query.data.refresh_token,
      user: { profile: '' },
    })

    this.oidcUserManager.storeUser(user)
    return user
  }

  async logout() {
    try {
      await this.oidcUserManager.signoutRedirect()
      return true
    } catch (e) {
      // Error signing out...user missing?
      return false
    }
  }

  saveUser(auth_token, jwt_token) {
    const user = new User({
      id_token: jwt_token,
      access_token: auth_token,
      user: { profile: '' },
    })

    this.oidcUserManager.storeUser(user)
  }

  authTokenObj(auth) {
    return { withCredentials: true, headers: { Authorization: `Bearer ${auth}` } }
  }

  async getOffers(auth, uuid) {
    try {
      const response = await this.axios.get(
        this.routes.offers_url(uuid),
        this.authTokenObj(auth)
      )

      return response.data
    } catch (e) {
      if (e.response.status === 401) {
        Cookies.remove('auth_token')
      }
      return e
    }
  }

  async getUserApplications(auth) {
    try {
      const response = await this.axios.get(
        this.routes.user_applications_url,
        this.authTokenObj(auth)
      )
      return response.data
    } catch (e) {
      if (e.response.status === 401) {
        Cookies.remove('auth_token')
      }
      return e
    }
  }

  async getSessionKey(options) {
    try {
      const response = await this.axios.post(this.routes.visit_url, {
        brand_slug: this.config.brand_slug,
        options,
      })

      return response.data
    } catch (e) {
      return e
    }
  }

  async sendApplicationDraft(draft) {
    try {
      const response = await this.axios.post(this.routes.application_draft_url, draft)

      return response.data
    } catch (e) {
      return e
    }
  }

  async getApplicationDraft(uuid) {
    try {
      const response = await this.axios.get(
        this.routes.existing_application_draft_url(uuid)
      )
      return response.data
    } catch (e) {
      return e
    }
  }

  async updateApplicationDraft(uuid, draft) {
    try {
      const response = await this.axios.put(
        this.routes.existing_application_draft_url(uuid),
        draft
      )
      return response.data
    } catch (e) {
      return e
    }
  }

  // Return error message
  getErrorMessage(error) {
    if (error.response) {
      const errorObj = error.response.data
      let errors = {}

      // Compose key:value pairs for each error
      if (errorObj['applicants'] && errorObj['applicants'][0]) {
        const mainApplicant = errorObj['applicants'][0]

        errors = this.mapErrors(errors, APPLICANT_ERRORS, mainApplicant)
        if (mainApplicant['bank_account']) {
          errors = this.mapErrors(
            errors,
            BANK_ACCOUNT_ERRORS,
            mainApplicant['bank_account']
          )
        }
        if (mainApplicant['address']) {
          errors = this.mapErrors(errors, ADDRESS_ERRORS, mainApplicant['address'])
        }
        if (mainApplicant['employment']) {
          errors = this.mapErrors(errors, EMPLOYMENT_ERRORS, mainApplicant['employment'])
        }
      }

      errors = this.mapErrors(errors, ERROR_FIELD_MAPPING, errorObj)
      return { error: true, errors }
    }
    // Something else went wrong
    return { error: true, key: '500' }
  }

  mapErrors(errors, errorMap, errorObj) {
    Object.keys(errorObj).forEach(key => {
      if (errorMap[key]) {
        if (Array.isArray(errorMap[key])) {
          errorMap[key].forEach(function (element) {
            errors[element] = errorObj[key]
          })
        } else {
          errors[errorMap[key]] = errorObj[key]
        }
      }
    })
    return errors
  }

  async sendApplication(application) {
    try {
      const response = await this.axios.post(this.routes.application_url, application)
      return response.data
    } catch (e) {
      const error = this.getErrorMessage(e)

      return { error, rawError: e.response }
    }
  }

  async getEnums() {
    try {
      const response = await axios.get(this.routes.enums_url)
      return response.data
    } catch (e) {
      return e
    }
  }

  getAccessToken() {
    // let oidcUserManager = new UserManager(this.oidcConfig)
    return this.oidcUserManager.getUser().then(user => {
      if (user) {
        return user.access_token
      }
      return null
    })
  }

  async acceptOfferOption(auth, uuid, data) {
    try {
      const response = await axios.post(
        this.routes.accept_offer(uuid),
        data,
        this.AUTH_TOKEN_OBJ(auth)
      )
      return response.data
    } catch (e) {
      const error = this.getErrorMessage(e)

      return { error, rawError: e.response }
    }
  }

  logoutUser() {
    Cookies.remove('auth_token')
    Cookies.remove('application_uuid')
  }

  getFbClickValue(options) {
    // Look for Facebook click id from cookies
    const fbId = Cookies.get('_fbc')
    if (fbId) {
      options.query.subid5 = fbId
    } else {
      // If not in cookies, id can be found in the url
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const fbId = urlParams.get('fbclid')
      if (fbId) {
        options.query.subid5 = fbId
      }
    }
    return null
  }

  setVersionId(id) {
    const currId = Cookies.get('version_id')
    if (!currId) {
      Cookies.set('version_id', id)
    }
  }

  getVersionId() {
    const id = Cookies.get('version_id')
    return id
  }

  async getSessionKeyValue(options) {
    // Try to fetch session key from cookies
    const key = Cookies.get('session_key')

    // Key found
    if (key) return key

    if (!options) {
      options = {}
    }
    // Key not found, get session key from API
    const response = await this.getSessionKey(options)
    const sessionKey = response.session_key

    if (sessionKey) {
      // Set session key to cookies with expiration time of 2 hours
      Cookies.set('session_key', sessionKey, { expires: '2h' })
      return sessionKey
    }

    return null
  }

  // Set application uuid
  setApplicationUUID(uuid) {
    if (!uuid) {
      Cookies.remove('application_uuid')
    } else {
      Cookies.set('application_uuid', uuid, { expires: '2D' })
    }
  }

  deleteApplicationUUID() {
    Cookies.remove('application_uuid')
  }

  getApplicationUUID() {
    // Try to fetch uuid from cookies
    const key = Cookies.get('application_uuid')

    // Key found
    if (key) return key
    return null
  }

  // Check if draft uuid exists
  getDraftUUID() {
    // Try to fetch uuid from cookies
    const key = Cookies.get('draft_uuid')

    // Key found
    if (key) return key
    return null
  }

  deleteDraftUUID() {
    Cookies.remove('draft_uuid')
  }

  // Set application draft uuid
  setDraftUUID(uuid) {
    Cookies.set('draft_uuid', uuid, { expires: '5h' })
  }

  checkCookieConsent() {
    const consent = Cookies.get('accept_cookies')
    if (consent) {
      return true
    }
    return false
  }

  acceptCookies(value) {
    const consent = Cookies.get('accept_cookies')
    if (consent) {
      return consent
    }
    return Cookies.set('accept_cookies', value, { expires: '1Y' })
  }

  saveSalusgroupClick(value) {
    return Cookies.set('salusgroup_click', value, { expires: '30D' })
  }

  saveSalusSessionId(value) {
    return Cookies.set('salusgroup_sessionid', value, { expires: '30D' })
  }

  setSalusCookie(payload) {
    for (const [key, value] of Object.entries(payload)) {
      Cookies.set(key, value, { expires: '30D' })
    }
  }

  async getSalusCookies() {
    const keys = ['pubid', 'pub1', 'pub2', 'pub3', 'pub4', 'pub5', 'pubid_reference']
    const existingValues = keys.map(key => {
      const existingValuesArray = { key, value: Cookies.get(key) }
      return existingValuesArray
    })
    return existingValues
  }

  async setPassword(formData) {
    await axios.post(this.authRoutes.recover_password_url, formData)
  }

  async resetPassword(formData) {
    await axios.post(this.authRoutes.forgot_password_url, formData)
  }

  async cancelDraft(draftUUID) {
    await axios({
      method: 'put',
      data: { action: 'cancel' },
      url: this.routes.cancel_draft_url(draftUUID),
    })
  }

  async changeNIP(nip) {
    try {
      const response = await axios({
        method: 'post',
        data: { nip: nip },
        url: this.routes.change_nip_url,
        timeout: 1000,
      })
      return response.data
    } catch (e) {
      const error = this.getErrorMessage(e)
      return { error, rawError: e.response }
    }
  }

  async verifyPhoneNumber(verificationNumber, uuid) {
    try {
      const response = await axios({
        method: 'post',
        data: {
          brand_slug: this.config.brand_slug,
          application_uuid: uuid,
          key: verificationNumber,
        },
        url: this.routes.verify_sms,
      })
      return response.data
    } catch (e) {
      const error = this.getErrorMessage(e)

      return { error, rawError: e.response }
    }
  }

  async updatePhoneNumber(phone, uuid, token) {
    try {
      const response = await axios({
        method: 'put',
        data: {
          phone: phone,
          resend_sms: true,
        },
        url: this.routes.update_phone(uuid),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      return response.data
    } catch (e) {
      const error = this.getErrorMessage(e)
      return { error, rawError: e.response }
    }
  }

  async sendEskatSuccess(uuid, access_token) {
    try {
      const response = await axios({
        method: 'put',
        url: this.routes.eskat_success(uuid),
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      return response.data
    } catch (e) {
      const error = this.getErrorMessage(e)
      return { error, rawError: e.response }
    }
  }

  async sendEskatFailure(uuid, access_token) {
    try {
      const response = await axios({
        method: 'put',
        url: this.routes.eskat_failure(uuid),
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      return response.data
    } catch (e) {
      const error = this.getErrorMessage(e)
      return { error, rawError: e.response }
    }
  }

  async axaGetOffers(access_token) {
    try {
      const response = await axios({
        method: 'get',
        url: this.routes.axa_get_offers,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      return response
    } catch (e) {
      const error = this.getErrorMessage(e)
      return { error, rawError: e.response }
    }
  }

  async axaSendApplication(access_token, application) {
    try {
      const response = await axios({
        method: 'post',
        url: this.routes.axa_send_application,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        data: application,
      })
      return response
    } catch (e) {
      const error = this.getErrorMessage(e)
      return { error, rawError: e.response }
    }
  }

  async chubbGetChildQuote(data) {
    try {
      const response = await axios({
        method: 'post',
        url: this.routes.chubb_get_child_quote,
        data: data,
      })
      return response
    } catch (e) {
      const error = this.getErrorMessage(e)
      return { error, rawError: e.response }
    }
  }

  async chubbChildPayment(data) {
    try {
      const response = await axios({
        method: 'post',
        url: this.routes.chubb_start_payment,
        data: data,
      })
      return response.data
    } catch (e) {
      const error = this.getErrorMessage(e)
      return { error, rawError: e.response }
    }
  }

  async chubbCreatePolicy(data) {
    try {
      const response = await axios({
        method: 'post',
        url: this.routes.chubb_create_policy,
        data: data,
      })
      return response
    } catch (e) {
      const error = this.getErrorMessage(e)
      return { error, rawError: e.response }
    }
  }

  async veritasSendApplication(data) {
    try {
      const response = await axios({
        method: 'post',
        url: this.veritas_send_application,
        data: data,
      })
      return response
    } catch (e) {
      const error = this.getErrorMessage(e)
      return { error, rawError: e.response }
    }
  }

  async getLeadInfo(uuid) {
    try {
      const response = await axios({
        method: 'get',
        url: this.routes.get_lead_info(uuid),
      })
      return response
    } catch (e) {
      const error = this.getErrorMessage(e)
      return { error, rawError: e.response }
    }
  }

  async cancelApplication(uuid) {
    try {
      const response = await axios({
        method: 'put',
        url: this.routes.cancel_application(uuid),
      })
      return response
    } catch (e) {
      const error = this.getErrorMessage(e)
      return { error, rawError: e.response }
    }
  }

  async getMagicLink(uuid) {
    try {
      const response = await axios({
        method: 'get',
        url: this.routes.magic_link_entry(uuid),
      })

      return response
    } catch (e) {
      const error = this.getErrorMessage(e)
      return { error, rawError: e.response }
    }
  }

  async sendExtraInfo(url, data, auth) {
    try {
      const response = await axios({
        method: 'post',
        url: this.routes.extra_info_url(url),
        data,
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
      return response.data
    } catch (e) {
      const error = this.getErrorMessage(e)
      return { error, rawError: e.response }
    }
  }

  async sendSmeApplication(data) {
    try {
      const response = await axios({
        method: 'post',
        url: this.routes.sme_application_url,
        data,
      })
      return response.data
    } catch (e) {
      const error = this.getErrorMessage(e)
      return { error, rawError: e.response, status: e.response.status }
    }
  }
}

function install(Vue, options) {
  const { config, oidcConfig } = options

  const API = new SaveriumAPI(config, oidcConfig)
  Vue.saverium = API
  Vue.prototype.saverium = API
}

SaveriumAPI.install = install

export default SaveriumAPI
