<template lang="html">
  <input
    class="form-control"
    :class="invalid ? 'is-invalid' : '' || error ? 'is-invalid' : ''"
    type="text"
    :value="value"
    @change="input"
    :placeholder="placeholder"
  />
</template>

<script>
export default {
  props: ['value', 'placeholder', 'error'],
  data() {
    return {
      invalid: false,
    }
  },
  methods: {
    input(event) {
      const value = event.target.value.replace(/\s/gi, '').toUpperCase()
      if (value !== this.value) {
        this.$emit('input', value, this.validateIdnumber(value))
      }
    },

    validateIdnumber(value) {
      const id = value
      // Check if the value has correct form, three letters and six numbers
      if (!/^[A-Z]{3}\d{6}$/.exec(id)) {
        this.invalid = true
        return false
      }
      // Each number has a weight assigned to it, numbers go from 1 to 9
      const weights = [7, 3, 1, 0, 7, 3, 1, 7, 3]
      const blown0 = id.split('') // Split the value
      // Assign the weight of the letter. Weights are (ASCII value - 55) from A to Z
      blown0[0] = id.charCodeAt(0) - 55
      blown0[1] = id.charCodeAt(1) - 55
      blown0[2] = id.charCodeAt(2) - 55
      // Map the whole thing and calculate the division and compare it to the check digit
      const blown = blown0.map(d => parseInt(d, 10))
      const sum = blown.reduce((a, c, i) => a + c * weights[i], 0)
      if (sum % 10 === blown[3]) {
        this.invalid = false
      } else {
        this.invalid = true
      }
    },
  },
}
</script>
