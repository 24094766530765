<template>
  <div class="row article">
    <h1 class="center-title">Kredyt refinansowy – najważniejsze informacje</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            Wraz z upływem czasu często zmieniają się warunki kredytowania na rynku
            bankowym. Może okazać się więc, że dotychczasowy kredyt jest drogi w
            porównaniu z obecnymi ofertami. Klient nie musi godzić się na to, by spłacać
            drogie zobowiązanie. Opcja zaciągnięcia refinansowanego kredytu jest dla niego
            wyjściem, które powinien rozważyć w przypadku wieloletniej spłaty zobowiązania
            hipotecznego. Co to jest refinansowanie kredytu? Na czym to polega?
          </p>
          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/refinancing-loans-tiny.webp"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/refinancing-loans.webp"
            alt="Kredyt refinansowy – najważniejsze informacje"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul class="table-of-contents">
              <li>
                <a href="#co-to-jest-kredyt-refinansowy-definicja">
                  Co to jest kredyt refinansowy – definicja
                </a>
              </li>
              <li>
                <a href="#kredyt-refinansowy-dla-kogo-przeznaczony">
                  Kredyt refinansowy – dla kogo przeznaczony?
                </a>
              </li>
              <li>
                <a href="#kredyt-refinansowy-kiedy-sie-zdecydowac">
                  Kredyt refinansowy – kiedy się zdecydować?
                </a>
              </li>
              <li>
                <a href="#kredyt-refinansowy-jakie-sa-rodzaje">
                  Kredyt refinansowy – jakie są rodzaje?
                </a>
              </li>
              <li>
                <a href="#kredyt-refinansowy-na-jakich-zasadach-jest-udzielany">
                  Kredyt refinansowy – na jakich zasadach jest udzielany?
                </a>
              </li>
              <li>
                <a href="#kredyt-refinansowy-jakie-dokumenty-sa-potrzebne">
                  Kredyt refinansowy – jakie dokumenty są potrzebne?
                </a>
              </li>
              <li>
                <a href="#kredyt-refinansowy-jakie-jest-oprocentowanie">
                  Kredyt refinansowy – jakie jest oprocentowanie?
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container mt-5">
          <h2 id="co-to-jest-kredyt-refinansowy-definicja">
            Co to jest kredyt refinansowy – definicja
          </h2>
          <p>
            Wielu kredytobiorców hipotecznych może zastanawiać się, czy kredyt refinansowy
            jest opłacalnym rozwiązaniem i kiedy warto się na niego zdecydować. Jednak na
            początku należy dowiedzieć się,
            <strong>co to jest kredyt refinansowy</strong>? Definicja przyjmowana
            powszechnie stanowi, że jest to zobowiązanie zaciągane w innym banku dla
            natychmiastowej spłaty kredytu. Najczęściej refinansowanie odbywa się w ramach
            transakcji bezgotówkowej, międzybankowej.
          </p>
          <p>
            Polega to na tym, że bank, w którym zaciągany jest kredyt refinansowy,
            dokonuje spłaty dotychczasowego, jednego zobowiązania udzielonego wcześniej.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-refinansowy-dla-kogo-przeznaczony">
            Kredyt refinansowy – dla kogo przeznaczony?
          </h2>
          <p>
            Refinansowanie przeznaczone jest dla klienta, który ma już zaciągnięty kredyt
            hipoteczny i
            <strong>chciałby całkowicie zmienić warunki kredytowania</strong>, zaś jego
            dotychczasowy bank nie pozwala na renegocjowanie umowy. Teoretycznie można
            refinansować
            <a
              href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-kalkulator-i-oferty-pozyczek-gotowkowych/"
              rel="noopener noreferrer"
              target="_blank"
              >kredyty gotówkowe</a
            >, samochodowe i tym podobne. W praktyce jednak banki najczęściej udzielają
            kredytów refinansowych dotyczących spłaty zobowiązań długoterminowych
            zaciąganych zarówno przez konsumentów, jak i przez przedsiębiorców, w ramach
            prowadzonej przez nich działalności gospodarczej.
          </p>
          <p><strong>Do przyznania kredytu refinansowego wymagane są:</strong></p>
          <ul>
            <li>Posiadanie już zaciągniętego kredytu w innym banku.</li>
            <li>
              Odpowiednio wysoka
              <a
                href="https://www.saverium.pl/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/"
                rel="noopener noreferrer"
                target="_blank"
                >zdolność kredytowa</a
              >
              i dobra historia kredytowania.
            </li>
            <li>
              Uzyskanie zgody na transakcję bezgotówkową dokonywaną pomiędzy instytucjami,
              czyli bank musi zgodzić się na spłatę zobowiązania przez nową instytucję
              kredytującą.
            </li>
          </ul>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-refinansowy-kiedy-sie-zdecydowac">
            Kredyt refinansowy – kiedy się zdecydować?
          </h2>
          <p>
            Nie zawsze podjęcie decyzji o zaciągnięciu kredytu refinansowego będzie dobrym
            wyborem. Warto się nim zainteresować, gdy warunki kredytowania dotychczasowego
            zobowiązania, zwłaszcza hipotecznego, wieloletniego są zdecydowanie gorsze od
            tych, jakie oferowane są przez banki aktualnie. Kredyt refinansowy opłaca się
            tylko wówczas, gdy koszty nowego produktu, na obecnych warunkach,
            <strong>są znacznie niższe od spłacanego zobowiązania</strong>. Warto wyliczyć
            sobie całkowite oszczędności związane z refinansowaniem, czyli od kwoty
            całkowitego kosztu starego zobowiązania odjąć koszty operacyjne.
          </p>
          <p>
            Całkowity koszt starego kredytu obejmuje kwotę, jaką klient musi spłacić w
            ramach dotychczasowego kredytu. Z kolei koszty operacyjne dotyczą prowizji,
            jaka może być naliczona w banku za dokonanie całkowitej, wcześniejszej spłaty
            zobowiązania. Doliczyć trzeba do tej puli prowizję za udzielenie kredytu
            refinansowego, koszty wyceny nieruchomości, która ma stanowić zabezpieczenie
            spłaty kredytu hipotecznego oraz koszty dokonania wpisów w księdze wieczystej
            nieruchomości.
          </p>
          <p>
            <strong
              >Jeśli oszczędności są znaczne, warto skorzystać z kredytu
              refinansowego</strong
            >. Jeszcze więcej klient zaoszczędzi, jeśli nowy bank nie pobierze prowizji za
            przyznanie zobowiązania.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-refinansowy-jakie-sa-rodzaje">
            Kredyt refinansowy – jakie są rodzaje?
          </h2>
          <p>
            Refinansowanie kredytów może obejmować właściwie dowolne zobowiązania. Można
            wyróżnić kilka rodzajów takiego produktu:
          </p>
          <ul>
            <li>kredyt refinansowy hipoteczny,</li>
            <li>kredyt refinansowy inwestycyjny,</li>
            <li>kredyt refinansowy gotówkowy,</li>
            <li>
              pożyczka refinansowa, w tym
              <a
                href="https://www.saverium.pl/artykuly/pozyczki-pozabankowe-online-kredyty-pozabankowe-prezez-internet/"
                rel="noopener noreferrer"
                target="_blank"
                >pożyczka pozabankowa</a
              >.
            </li>
          </ul>
          <p>
            Najbardziej popularne jest refinansowanie kredytu hipotecznego, które polega
            na
            <strong
              >przeniesieniu do innego banku zobowiązania z zabezpieczeniem
              hipotecznym</strong
            >, zaciągniętego na potrzeby nabycia nieruchomości mieszkalnej czy budowy domu
            jednorodzinnego. Wynika to przede wszystkim z tego, że kredyty hipoteczne z
            reguły mają długi okres spłaty. Jeżeli w trakcie kredytowania warunki
            proponowane przez banki diametralnie się zmienią,
            <strong
              >wówczas można znaleźć tańsze oferty produktów o podobnym
              charakterze</strong
            >
            i skorzystać z atrakcyjnego kredytu refinansowego hipotecznego. Wymaga on
            ustanowienia hipoteki na nieruchomości na rzecz nowego banku.
          </p>
          <p>
            Przedsiębiorcom z kolei proponuje się nieco inną odmianę takiego produktu.
            Jeśli firma zaciągnęła kredyt inwestycyjny na niezbyt korzystnych warunkach,
            może zdecydować się na kredyt refinansowy inwestycyjny. Z uwagi na wysokie
            ryzyko ponoszone przez bank, sytuacja finansowa firmy musi być dobra, by
            otworzyła ona sobie drogę do tańszego kredytowania.
          </p>
          <p>
            Rodzaje kredytów refinansowych obejmują ponadto kredyt gotówkowy refinansowy i
            pożyczkę refinansową. Przy zaciągnięciu kredytu lub pożyczki bez
            zabezpieczenia na bardzo niekorzystnych warunkach, np. z wysokim poziomem
            oprocentowania, można z czasem pomyśleć o refinansowaniu. Pozwala ono również
            na zerwanie współpracy z dotychczasowym bankiem, jeśli nie układa się ona
            dobrze.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-refinansowy-na-jakich-zasadach-jest-udzielany">
            Kredyt refinansowy – na jakich zasadach jest udzielany?
          </h2>
          <p>
            Zasadniczo kredyt refinansowy przydzielany jest na podobnych warunkach jak
            produkt pierwotny, który zostaje spłacony przy okazji operacji refinansowania.
            Charakteryzuje go ta sama:
          </p>
          <ul>
            <li>Kwota zobowiązania, z wyłączeniem odsetek, prowizji i innych opłat.</li>
            <li>
              Forma zabezpieczenia spłaty kredytu – jeśli jest to refinansowanie kredytu
              hipotecznego, to zabezpieczenie w formie hipoteki na nieruchomości
              mieszkalnej jest przenoszone na nowy bank.
            </li>
            <li>Termin ostatecznej spłaty zobowiązania.</li>
          </ul>
          <p>
            <strong>Zmianie ulegają za to wysokość oprocentowania kredytu</strong>, formy
            jego ubezpieczenia czy wysokość spłacanych rat kapitałowo-odsetkowych. Raty
            mogą być regulowane w formule rat malejących lub stałych w okresie
            kredytowania.
          </p>
          <p>
            Właściwie
            <strong
              >zawsze kredyt refinansowy jest udzielany klientom przez inny bank</strong
            >
            niż ten, który pierwotnie przyznał zobowiązanie. Jeśli jednak jest to ta sama
            instytucja bankowa, wówczas mowa jest właściwie nie o refinansowaniu, tylko o
            renegocjacji warunków zobowiązania.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-refinansowy-jakie-dokumenty-sa-potrzebne">
            Kredyt refinansowy – jakie dokumenty są potrzebne?
          </h2>
          <p>
            Do uzyskania kredytu refinansowego na pewno wymagany będzie wniosek wypełniony
            przez klienta na formularzu bankowym. Oprócz wniosku kredytowego banki mogą
            oczekiwać od innych dokumentów,
            <strong>w tym zaświadczenia o wysokości i źródle uzyskiwanych dochodów</strong
            >.
          </p>
          <p>
            By skutecznie refinansować kredyt, konieczne jest też przygotowanie dokumentu,
            na którym będzie widniała pozostała do spłaty kwota dotychczasowego
            zadłużenia. Do swojego obecnego banku należy więc wystosować wniosek o wydanie
            zaświadczenia o zobowiązaniu. Bank będzie musiał zbadać, czy klienta stać na
            refinansowanie kredytu mieszkaniowego czy gotówkowego lub innego zobowiązania,
            by nie ponosić dodatkowych kosztów i ryzyka związanego z finansowaniem.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-refinansowy-jakie-jest-oprocentowanie">
            Kredyt refinansowy – jakie jest oprocentowanie?
          </h2>
          <p>
            Nie można w sposób jednoznaczny określić, ile wynosi oprocentowanie kredytu
            refinansowego. Zależy to od aktualnej, często indywidualnie składanej
            klientowi, oferty kredytowej. Refinansowanie zasadniczo powinno pozwalać na
            zmniejszenie oprocentowania, a co za tym idzie zmniejszenie kosztów
            całkowitych produktu.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
