<template>
  <div class="article-wrapper" data-type="1" data-language="pl">
    <div class="article row col-12">
      <h1 class="center-title">Kredyt bez zgody współmałżonka - co należy wiedzieć?</h1>
      <div class="row col-12">
        <div class="article-container col-12 col-lg-8">
          <div class="text-container">
            <p>
              Decyzja odnośnie zaciągnięcia kredytu nie zawsze zapada wśród małżonków
              jednogłośnie. W przypadku braku zgody jednego z nich, powstaje pytanie, czy
              wciąż można ubiegać się o kredyt? Jeśli tak, to na jakich warunkach jest on
              przyznawany oraz czy należy poinformować drugą stronę o jego otrzymaniu?
            </p>

            <b-img-lazy
              width="700"
              height="466"
              blank-src="/static/img/saverium_pl/article-images/loan-without-partner-consent-tiny.webp"
              blank-height="700px"
              blank-width="466px"
              class="article-img"
              src="/static/img/saverium_pl/article-images/loan-without-partner-consent.webp"
              alt="Kredyt bez zgody współmałżonka - co należy wiedzieć?"
            />
            <div class="toc container">
              <h3>{{ $t('table_of_contents') }}</h3>
              <ul class="table-of-contents">
                <li>
                  <a href="#czy-mozna-wziac-kredyt-bez-zgody-wspolmalzonka"
                    >Czy można wziąć kredyt bez zgody współmałżonka?</a
                  >
                </li>
                <li>
                  <a href="#kredyt-bez-zgody-malzonka-warunki"
                    >Kredyt bez zgody małżonka - warunki</a
                  >
                </li>
                <li>
                  <a href="#konsekwencje-zaciagniecia-kredytu-bez-zgody-malzonka"
                    >Konsekwencje zaciągnięcia kredytu bez zgody małżonka</a
                  >
                </li>
                <li>
                  <a href="#kredyt-bez-zgody-malzonka-czy-warto"
                    >Kredyt bez zgody małżonka - czy warto?</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="text-container">
            <h2 id="czy-mozna-wziac-kredyt-bez-zgody-wspolmalzonka">
              Czy można wziąć kredyt bez zgody współmałżonka?
            </h2>
            <p>
              Odpowiedź na pytanie, czy można wziąć kredyt bez zgody współmałżonka, nie
              jest jednoznaczna. Wiele zależy tutaj od rodzaju kredytu oraz kwoty, na jaką
              ma zostać udzielony. Jeśli chodzi o kredyt hipoteczny, to niemal zawsze
              istnieje obowiązek uzyskania na niego zgody drugiego małżonka. Nieco inaczej
              sprawa wygląda przy
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-kalkulator-i-oferty-pozyczek-gotowkowych/"
                rel="noopener noreferrer"
                target="_blank"
                >kredycie gotówkowym</a
              >. Do 2005 roku banki prawie zawsze wymagały udzielenia zgody obu małżonków
              na zaciągnięcie kredytu. Na mocy nowych przepisów o
              <a
                href="https://www.saverium.pl/artykuly/kredyt-ratalny/"
                rel="noopener noreferrer"
                target="_blank"
                >kredyt gotówkowy na raty</a
              >
              może starać się tylko jeden ze współmałżonków. Problemu z zaciągnięciem
              kredytu bez zgody małżonka nie mają pary, które zawarły umowę małżeńską, a
              więc posiadają rozdzielność majątkową. Prawo w tym przypadku działa też w
              drugą stronę, pozwalając im zaciągnąć kredyt wspólnie.
            </p>
          </div>

          <div class="text-container">
            <h2 id="kredyt-bez-zgody-malzonka-warunki">
              Kredyt bez zgody małżonka - warunki
            </h2>
            <p>
              Warto wiedzieć, jakie są warunki kredytu bez zgody małżonka, a więc czego
              dokładnie wymagają od swoich klientów banki.
              <a
                href="https://www.saverium.pl/artykuly/kto-moze-otrzymac-kredyt/"
                rel="noopener noreferrer"
                target="_blank"
                >Kto może otrzymać kredyt</a
              >? Bardzo istotna jest tutaj procedura oceny
              <a
                href="https://www.saverium.pl/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/"
                rel="noopener noreferrer"
                target="_blank"
                >zdolności kredytowej</a
              >. Bank ocenia, czy klient będzie w stanie spłacić zobowiązanie, biorąc pod
              uwagę jego miesięczne dochody oraz ich źródło. Istotna jest także ilość
              osób, pozostających na utrzymaniu kredytobiorcy oraz średnie koszty życia w
              danej miejscowości. Kredyt nie zostanie udzielony, jeśli klient figuruje w
              bazach dłużników. Ważne jest również, do jakiej kwoty kredyt bez zgody
              małżonka może zostać udzielony. Co do zasady, banki udzielają zobowiązań na
              kwotę około 20-50 tysięcy złotych, jeśli brakuje zgody drugiego małżonka na
              zaciągnięcie kredytu. Nie są to więc wysokie sumy, jednak w wielu
              przypadkach mogą okazać się wystarczające.
            </p>
          </div>

          <div class="text-container">
            <h2 id="konsekwencje-zaciagniecia-kredytu-bez-zgody-malzonka">
              Konsekwencje zaciągnięcia kredytu bez zgody małżonka
            </h2>
            <p>
              <a
                href="https://www.saverium.pl/artykuly/kredyt-przez-internet/"
                rel="noopener noreferrer"
                target="_blank"
                >Szybki kredyt przez internet</a
              >, jaki oferują niektóre banki, wydaje się doskonałym sposobem na
              sfinansowanie drobnych wydatków. Jak się okazuje, nawet jeśli małżonek nie
              zgadza się na jego zaciągnięcie, bank w dalszym ciągu może udzielić kredytu.
              Niemniej jednak osoba, która zdecydowała się na taki krok, musi liczyć się z
              pewnymi konsekwencjami. Otóż w przypadku, gdy pojawią się jakiekolwiek
              problemy ze spłatą zobowiązania, komornik może ściągać potrzebne kwoty
              wyłącznie z majątku osobistego kredytobiorcy. Nie jest to trudne szczególnie
              w przypadku, gdy małżonkowie mają oddzielne konta bankowe.
            </p>
            <p>
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-bez-zaswiadczen-online/"
                rel="noopener noreferrer"
                target="_blank"
                >Kredyt gotówkowy bez zaświadczeń online,</a
              >
              na który nie wyraził zgody współmałżonek, może również stanowić akt braku
              lojalności, zwłaszcza jeśli druga strona w ogóle nie wie o fakcie jego
              zaciągnięcia. W praktyce może kredyt gotówkowy na oświadczenie online bez
              zgody małżonka może być mocnym argumentem na przykład podczas rozprawy
              rozwodowej, choć wiele zależy od charakteru zobowiązania oraz okoliczności
              wnioskowania o nie. Trudno więc mówić jednoznacznie o konsekwencjach
              zaciągania kredytu bez zgody małżonka.
            </p>
          </div>

          <div class="text-container">
            <h2 id="kredyt-bez-zgody-malzonka-czy-warto">
              Kredyt bez zgody małżonka - czy warto?
            </h2>
            <p>
              Zaciągnięcie kredytu bez zgody małżonka nie zawsze ma charakter negatywny i
              świadczy o niezgodzie w małżeństwie. Łatwość wnioskowania o kredyt może być
              bardzo pomocna, jeśli jeden z małżonków mieszka w innym miejscu i trudno
              byłoby mu podpisać niezbędną dokumentację. Ponadto, niektóre pary prowadzą
              co prawda wspólne gospodarstwo domowe, nie mając podpisanej intercyzy, lecz
              nie kontrolują wzajemnych wydatków.
            </p>
            <p>
              Należy jednak pamiętać, że kredyt bez zgody małżonka zwykle nie opiewa na
              wysokie sumy, nie może mieć również charakteru hipotecznego. Warto też wziąć
              pod uwagę to, czy posiadamy na tyle dużą zdolność kredytową, aby wnioskować
              o finansowanie. W wielu przypadkach większe szanse na pozyskanie kredytu ma
              para, a nie pojedyncza osoba, bowiem biorąc pod uwagę zarobki drugiego
              małżonka oraz źródło dochodów, zdolność kredytowa można znacznie wzrosnąć.
            </p>
          </div>
        </div>
        <div class="container container-widget col-12 col-lg-4">
          <h4>{{ $t('get_a_loan_easily') }}</h4>
          <LoanWidget class="small-range mt-4" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div style="margin: auto">
        <RelatedArticlesComponent />
      </div>
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
export default {
  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
