<template lang="html">
  <input
    class="form-control"
    :class="invalid ? 'is-invalid' : '' || error ? 'is-invalid' : ''"
    type="text"
    :value="value"
    @change="input"
    :placeholder="placeholder"
    :identifier="identifier"
    inputmode="tel"
  />
</template>

<script>
import { DateTime } from 'luxon'
import { validateCvr, validateCpr } from '@/utils/utils'

export default {
  props: ['value', 'placeholder', 'error', 'identifier'],
  data() {
    return {
      invalid: false,
    }
  },
  methods: {
    input(event) {
      if (this.identifier === 'cpr') {
        let value = event.target.value.replace(/\s/gi, '').toUpperCase()
        if (!value.includes('-')) {
          const transform = value => {
            return `${value.substring(0, 6)}${
              value.length > 6 ? '-' : ''
            }${value.substring(6)}`
          }
          value = transform(value)
        }
        if (value !== this.value) {
          this.$emit('input', value, this.validateCpr(value))
        }
      } else if (this.identifier === 'cvr') {
        const value = event.target.value
        if (value !== this.value) {
          this.$emit('input', value, this.validateCvr(value))
        }
      } else if (this.identifier === 'pesel') {
        const value = event.target.value
        if (value !== this.value) {
          this.$emit('input', value, this.validatePesel(value))
        }
      } else if (this.identifier === 'nip') {
        const value = event.target.value
        if (value !== this.value) {
          this.$emit('input', value, this.validateNip(value))
        }
      }
    },
    validateCvr(value) {
      this.invalid = !validateCvr(value)
    },
    validateCpr(value) {
      this.invalid = validateCpr(value)
    },
    validatePesel(value) {
      // For PESEL the form is YYMMDDZZZXQ where YYMMDD is birthday, century being coded in the month
      // People born 1900-1999 the months are written as natural months whereas people born in the 21st century
      // months are written as 21, 22, 23 .... 32
      if (value.length === 11) {
        const numberArray = value.split('')
        let month = value.substring(2, 4)
        const day = value.substring(0, 2)
        const year = value.substring(4, 6)

        if (month > 12) {
          // Valid until year 2099 so I think we're good
          month = (month - 20).toLocaleString(undefined, { minimumIntegerDigits: 2 })
          const fullBirthday = day + '-' + month + '-' + year
          const formatted = DateTime.fromFormat(fullBirthday, 'yy-MM-dd')
          const today = DateTime.now()

          const difference = today.diff(formatted, 'years').toObject().years
          if (difference < 18) {
            this.invalid = true
            return
          }
        }

        let sum = 0
        for (let i = 0; i < numberArray.length - 1; i++) {
          sum += +numberArray[i] * this.getMultiplier(i + 1)
        }
        const modulo = sum % 10
        const lastDigit = Number(value.substring(value.length - 1))
        this.invalid = !((modulo === 0 && lastDigit === 0) || lastDigit === 10 - modulo)
      } else {
        this.invalid = true
      }
    },

    getMultiplier(index) {
      switch (index % 4) {
        case 1:
          return 1
        case 2:
          return 3
        case 3:
          return 7
        case 0:
          return 9
      }
      throw new Error('Error')
    },

    validateNip(value) {
      const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7]
      value = value.replace(/[\s-]/g, '')

      if (value.length === 10 && parseInt(value, 10) > 0) {
        let sum = 0
        for (let i = 0; i < 9; i++) {
          sum += value[i] * weights[i]
        }
        this.invalid = !(sum % 11 === Number(value[9]))
        return
      }
      this.invalid = true
    },
  },
}
</script>

<style lang="sass" scoped></style>
