import fi from './fi'
import es from './es'
import pl from './pl'
import dk from './dk'
import settings from '@/settings/settings'

let dates = {}

if (settings.LOCALE === 'fi') {
  dates = fi
} else if (settings.LOCALE === 'pl') {
  dates = pl
} else if (settings.LOCALE === 'es') {
  dates = es
} else if (settings.LOCALE === 'dk') {
  dates = dk
}

export default dates
