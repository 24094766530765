<template lang="html">
  <div ref="header">
    <h2 v-if="fullView" class="mb-3 text-center">
      {{ step }}.
      {{ $t('coapplicant_info') }}
    </h2>
    <h1 v-else class="mb-3 text-center">
      {{ $t('coapplicant_info') }}
    </h1>
    <div class="row">
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="text"
          storekey="coapplicant.firstName"
          id="firstName"
          class="mainInput"
          name="firstname"
        >
          {{ $t('first_name') }}
        </form-input>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="text"
          name="lastname"
          storekey="coapplicant.lastName"
          id="lastName"
          class="mainInput"
        >
          {{ $t('last_name') }}
        </form-input>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="identity"
          identifier="cpr"
          storekey="coapplicant.ssn"
          id="ssn"
          name="ssn"
          class="mainInput"
          inputmode="numeric"
        >
          {{ $t('ssn') }}
        </form-input>
        <div v-if="ssnError" class="invalid-feedback" style="display: block">
          {{ $t('coapplicant_ssn_needs_to_be_different') }}
        </div>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="email"
          storekey="coapplicant.email"
          id="email"
          name="email"
          autocomplete="email"
        >
          {{ $t('email') }}
        </form-input>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="phone"
          storekey="coapplicant.phone"
          id="phone"
          inputmode="numeric"
          name="phone"
          autocomplete="tel"
          prepend="+45"
        >
          {{ $t('phone_number') }}
        </form-input>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="numeric"
          currency="kr."
          separator="."
          storekey="coapplicant.monthlyGrossIncome"
          id="monthlyGrossIncome"
          class="mainInput"
          name="monthlyGrossIncome"
        >
          {{ $t('monthly_income_gross') }}
        </form-input>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="numeric"
          currency="kr."
          separator="."
          name="monthlyNetIncome"
          storekey="coapplicant.monthlyNetIncome"
          id="monthlyNetIncome"
          class="mainInput"
        >
          {{ $t('monthly_income_net') }}
        </form-input>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="numeric"
          currency="kr."
          separator="."
          name="otherIncome"
          storekey="coapplicant.otherIncome"
          id="otherIncome"
          class="mainInput"
        >
          {{ $t('other_monthly_income') }}
        </form-input>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="numeric"
          currency="kr."
          separator="."
          storekey="coapplicant.newDebtThisYear"
          id="newDebtThisYear"
          class="mainInput"
          name="newDebtThisYear"
        >
          {{ $t('new_debt_this_year') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input
          :items="occupationOptions"
          storekey="coapplicant.occupation"
          type="dropdown"
          id="occupation"
        >
          {{ $t('occupation') }}
        </form-input>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-6" v-if="this.occupation === '4'">
        <form-input
          storekey="coapplicant.businessId"
          type="identity"
          identifier="cvr"
          inputmode="numeric"
          id="business_id"
        >
          {{ $t('business_id') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input
          type="month"
          storekey="coapplicant.employedSince"
          id="employedSince"
          placeholder="fx. 11.09.2008"
        >
          {{ $t(dynamicOccupationTitle) }}
        </form-input>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-6">
        <label for="unemploymentFund">
          {{ $t('coapplicant_is_member_of_unemployment_fund') }}
        </label>
        <div
          class="radio-toolbar"
          name="coapplicant.unemploymentFund"
          id="unemploymentFund"
          @change="updateInput"
        >
          <input
            type="radio"
            id="fund1"
            name="coapplicant.unemploymentFund"
            :value="true"
            :checked="this.unemploymentFund === true"
          />
          <label class="radiolabel" for="fund1">{{ $t('yes') }}</label>

          <input
            type="radio"
            id="fund2"
            name="coapplicant.unemploymentFund"
            :value="false"
            :checked="this.unemploymentFund === false"
          />
          <label class="radiolabel" for="fund2">{{ $t('no') }}</label>
        </div>
      </div>
      <div class="form-group col-sm-6">
        <form-input
          type="month"
          storekey="coapplicant.memberOfUnemploymentFundSince"
          id="memberOfUnemploymentFundSince"
          inputmode="numeric"
          :class="{ hidden: this.unemploymentFund === false }"
        >
          {{ $t('member_since') }}
        </form-input>
      </div>
    </div>
    <div class="row" style="text-align: left">
      <b-form-checkbox
        id="coapplicant.isDanish"
        v-model="isDanish"
        storekey="coapplicant.isDanish"
        name="isDanish"
        :value="true"
        :unchecked-value="false"
        class="consent-checkbox"
        :class="danishErrors ? 'is-invalid' : ''"
      >
        <div>
          {{ $t('coapplicant_is_danish') }}
        </div>
      </b-form-checkbox>
    </div>

    <div v-if="this.isDanish === false" class="row">
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="month"
          storekey="coapplicant.countryResidentSince"
          id="denmarkdate"
        >
          {{ $t('lived_in_denmark_since') }}
        </form-input>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="dropdown"
          storekey="coapplicant.typeOfResidencePermit"
          id="coapplicant.residencePermit"
          :items="residenceTypes"
        >
          {{ $t('type_of_residence_permit') }}
        </form-input>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="text"
          storekey="coapplicant.foreignerNumber"
          id="coapplicant.foreignerNumber"
          inputmode="numeric"
        >
          {{ $t('foreigners_number_or_id') }}
          <i
            v-b-tooltip.hover.v-saverium-blue-input
            title="Hvis du ikke har udlændingenummer, skal du abre skrive 12345
"
            class="fas fa-question-circle"
          ></i>
        </form-input>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="dropdown"
          storekey="coapplicant.subType"
          id="coapplicant.residenceType"
          :items="residenceTypeSubTypes"
          :class="{ hidden: this.subtype === false }"
        >
          {{ $t('residence_type') }}
        </form-input>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="text"
          storekey="coapplicant.permitNumber"
          id="coapplicant.permitNumber"
          inputmode="numeric"
          :class="{ hidden: this.subtype === false }"
        >
          {{ $t('serial_number_on_residence_permit') }}
          <i
            v-b-tooltip.hover.v-saverium-blue-input
            title="Hvis du ikke har løbenummer på din opholdstilladelse, bare skriv 12345
"
            class="fas fa-question-circle"
          ></i>
        </form-input>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="month"
          storekey="coapplicant.expiryDate"
          id="expiryDate"
          :class="{ hidden: this.subtype === false || this.perm === true }"
        >
          {{ $t('expiry_date_of_residence_permit') }}
        </form-input>
      </div>
    </div>
    <div class="container">
      <div class="consent-checkbox custom-control custom-checkbox col-sm-12">
        <input
          type="checkbox"
          id="coapplicantTos"
          @change="updateInput"
          name="coapplicantTos"
          storekey="coapplicantTos"
          :value="true"
          v-model="coapplicantTos"
          :unchecked-value="false"
          class="custom-control-input"
          :class="tosErrors ? 'is-invalid' : ''"
        />
        <label for="coapplicantTos" class="custom-control-label">
          {{ $t('i_accept_coapplicant') }}
          <a target="_blank" href="/tjenestevilkar"> {{ $t('terms') }}</a>
          {{ $t('and') }}
          <a target="_blank" href="/persondatapolitik">{{ $t('privacy_policy') }}</a>
        </label>

        <div v-if="tosErrors" class="invalid-feedback" style="display: block">
          {{ $t('field_is_required') }}
        </div>
      </div>
      <div class="row mb-5">
        <div class="consent-checkbox custom-control custom-checkbox col-sm-12">
          <input
            type="checkbox"
            id="coapplicantBankconsents"
            @change="updateInput"
            name="coapplicantBankconsents"
            storekey="coapplicantBankconsents"
            class="custom-control-input"
            :class="dataPolicyError ? 'is-invalid' : ''"
            :value="true"
            v-model="coapplicantBankconsents"
            :unchecked-value="false"
          />
          <label for="coapplicantBankconsents" class="custom-control-label">
            {{ $t('consent_to_kredit_status_1_coapplicant') }}
            <span :key="bank.value" v-for="bank in banks">{{ bank.value }}, </span>
            <span v-html="$t('consent_to_kredit_status_2')"></span>

            <span class="link" @click=";(visible = !visible), stop($event)">her</span>.
          </label>
          <div v-if="dataPolicyError" class="invalid-feedback" style="display: block">
            {{ $t('field_is_required') }}
          </div>
        </div>
        <b-collapse v-model="visible">
          <ul>
            <li :key="bank.value" v-for="bank in banks">
              Læs {{ bank.value }} fulde privativspolitik
              <a :href="bank.link" target="_blank">her</a>
            </li>
          </ul>
        </b-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import FormInput from '@/components/form/FormInput'
import { mapState } from 'vuex'
import { banks } from '@/utils/saverium_dk/const'
export default {
  props: ['fullView', 'step', 'value'],
  components: {
    FormInput,
  },
  data() {
    return {
      danishErrors: false,
      subtype: '',
      perm: '',
      visible: false,
      ssnError: false,
      residenceTypeSubTypes: [],
    }
  },
  computed: {
    ...mapState({
      occupationOptions: state => state.enums.occupationOptions,
      unemploymentFund: state => state.application.coapplicant.unemploymentFund,
      applicantSsn: state => state.application.ssn,
      coapplicantSsn: state => state.application.coapplicant.ssn,
      occupation: state => state.application.coapplicant.occupation,
      residenceTypes: state => state.enums.residencePermitTypes,
      residenceTypeSubTypesTemporary: state =>
        state.enums.coApplicantResidencePermitSubTypesTemporary,
      residenceTypeSubTypesPermanent: state =>
        state.enums.coApplicantResidencePermitSubTypesPermanent,
    }),

    isDanish: {
      get() {
        return this.$store.state.application.coapplicant.isDanish
      },
      set(isDanish) {
        this.$store.state.application.coapplicant.isDanish = isDanish
      },
    },
    residenceType: {
      get() {
        return this.$store.state.application.coapplicant.typeOfResidencePermit
      },
      set(typeOfResidencePermit) {
        this.$store.state.application.coapplicant.typeOfResidencePermit =
          typeOfResidencePermit
      },
    },

    banks: {
      get() {
        return banks
      },
      set(banks) {
        this.$store.state.application.banks = banks
      },
    },
    coapplicantTos: {
      get() {
        return this.$store.state.application.coapplicantTos
      },
      set(coapplicantTos) {
        this.$store.state.application.coapplicantTos = coapplicantTos
      },
    },
    coapplicantBankconsents: {
      get() {
        return this.$store.state.application.coapplicantBankconsents
      },
      set(coapplicantBankconsents) {
        this.$store.state.application.coapplicantBankconsents = coapplicantBankconsents
      },
    },
    tosErrors: {
      get() {
        return this.$store.state.application.errors.coapplicantTos
      },
      set(tosErrors) {
        this.$store.state.application.errors.coapplicantTos = tosErrors
      },
    },
    dataPolicyError: {
      get() {
        return this.$store.state.application.errors.coapplicantBankconsents
      },
      set(dataPolicyError) {
        this.$store.state.application.errors.coapplicantBankconsents = dataPolicyError
      },
    },
    dynamicOccupationTitle() {
      const titleMapping = {
        5: 'pensioner_since',
        6: 'student_since',
        7: 'unemployed_since',
      }
      return titleMapping[this.occupation]
        ? titleMapping[this.occupation]
        : 'employed_since'
    },
  },
  watch: {
    residenceType(value) {
      if (value === '1') {
        this.subtype = true
        this.residenceTypeSubTypes = this.residenceTypeSubTypesTemporary
        this.perm = false
      } else if (value === '2') {
        this.subtype = true
        this.perm = true
        this.residenceTypeSubTypes = this.residenceTypeSubTypesPermanent
      } else {
        this.subtype = false
        this.perm = false
      }
    },
    isDanish(value) {
      this.$store.commit('updateApplication', {
        key: 'coapplicant.isDanish',
        value: value,
      })
      if (this.isDanish === true) {
        this.$store.dispatch('removeError', 'isDanish')
        this.$store.dispatch('removeError', 'residenceId')
        this.$store.dispatch('removeError', 'countryResidentSince')
        this.danishErrors = false
      }
    },
    coapplicantSsn(coapplicantSsn) {
      if (this.applicantSsn === coapplicantSsn) {
        this.ssnError = true
      } else {
        this.ssnError = false
      }
    },
  },
  methods: {
    updateInput(event) {
      const {
        target: { name, value: _val },
      } = event
      let value = _val
      if (value === 'true') {
        value = true
      }
      if (value === 'false') {
        value = false
      }

      if (name === 'coapplicantTos') {
        this.tosErrors = false
        this.$store.dispatch('removeError', name)
        this.$store.commit('updateApplication', {
          key: name,
          value: this.coapplicantTos,
        })
        return
      }
      if (name === 'coapplicantBankconsents') {
        this.dataPolicyError = false
        this.$store.dispatch('removeError', name)
        this.$store.commit('updateApplication', {
          key: name,
          value: this.coapplicantBankconsents,
        })
        return
      }

      this.$store.commit('updateApplication', {
        key: name,
        value: value,
      })
      this.$store.dispatch('updateOrCreateDraft')
    },
    stop(event) {
      event.preventDefault()
    },
  },
}
</script>
<style lang="scss" scoped>
.active {
  background-color: #05083c;
  color: white;
}
.hidden {
  display: none;
}
.radiolabel {
  width: auto;
}
.toggle-collapse {
  cursor: pointer;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.close-collapse {
  cursor: pointer;
  transform: rotate(-90deg);
}

.collapse-wrapper {
  text-align: right;
  cursor: pointer;
}
.consent-text {
  font-size: 13px;
}
.consents {
  overflow: auto;
  max-height: 100px;
}
.custom-control {
  margin-bottom: 10px;
}
@media all and (max-width: 489px) {
  .consents {
    max-width: 300px;
  }
  .custom-control {
    margin-left: 15px;
  }
}
.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
