<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til møbler</h1>

      <div class="top-text text-container">
        <p>
          Leder du efter et lån til nye møbler? Vi hjælper dig med at finde et møbellån og
          evt. afsøger dine muligheder for at kunne købe møbler på afbetaling på trods af
          RKI i form af et lån. Benyt vores ansøgningsformular, som tager 2 minutter at
          udfylde. Herefter indhenter vi straks tilbud fra en lang række banker og andre
          pengeinstitutter, så du kan danne dig et overblik over dine muligheder for at få
          et lån.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li><a href="#laan-til-moebler">Lån til møbler</a></li>
                <li>
                  <a href="#hvordan-laaner-du-til-nye-moebler-igennem-os"
                    >Hvordan låner du til nye møbler igennem os?</a
                  >
                </li>
                <li><a href="#finansiering-af-sofa">Finansiering af sofa</a></li>
                <li><a href="#laan-til-designermoebler">Lån til designermøbler</a></li>
                <li>
                  <a href="#koeb-moebler-paa-afbetaling-trods-rki"
                    >Køb møbler på afbetaling trods RKI</a
                  >
                </li>
                <li><a href="#rentefri-moebellaan">Rentefri møbellån</a></li>
                <li>
                  <a href="#vi-hjaelper-med-finansiering-af-moebler"
                    >Vi hjælper med finansiering af møbler</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="hvordan-laaner-du-til-nye-moebler-igennem-os">
            Hvordan låner du til nye møbler igennem os?
          </h2>
          <p>
            Først og fremmest, skal du udfylde vores
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låneansøgning</a
            >
            her på siden, hvilket som nævnt blot tager 2 minutter. Herefter fremsender vi
            din ansøgning til de mange banker og pengeinstitutter, som vi har koplet på
            vores platform. Fælles for dem alle er, at de kan tilbyde dig lån til nye
            møbler i form af et
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >.
          </p>
          <p>
            Når ansøgningen afsendt, indhenter vi tilbud på møbellån til dig fra alle
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbyderene</a
            >, så du hurtigt og nemt kan danne dig et overblik over, hvem der kan tilbyde
            dig et møbellån, og hvor du kan få det bedste lån til møbler henne.
          </p>
          <p>
            Herefter vælger du blot, hvilken udbyder du ønsker skal finansiere dine
            møbler, ved at klikke på denne udbyder. Vi viderestiller dig herefter til
            udbyderens hjemmeside, som verificerer den data du har oplyst, så de på lovlig
            vis kan udbetale lånet til dine nye møbler.
          </p>
          <p>
            Hele processen tager typisk 5 minutter fra du starter din ansøgning, til at
            pengene står på din konto, så du med det samme kan købe den reol, det bord, de
            stole, eller hvilket som helst andet møbel, som du ønsker dig
          </p>
        </div>

        <div class="text-container">
          <h2 id="finansiering-af-sofa">Finansiering af sofa</h2>
          <p>
            Hvis du specifikt har brug for finansiering af en sofa, hvilket vi ved at
            mange af vores brugere har, så kan vi også hjælpe.
          </p>
          <p>
            En sofa er ofte en større udgift, og dermed et af de dyrere møbler, som kan
            kræve et lån. Uanset om du har udset dig en normal
            <a
              href="https://jysk.dk/stue/sofaer"
              rel="noopener noreferrer"
              target="_blank"
              >sofa fra Jysk</a
            >
            og har brug for
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/5000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >et lån på 5.000 kr.</a
            >, eller en dyr
            <a
              href="https://paustian.com/produkter/187-sofaer-/"
              rel="noopener noreferrer"
              target="_blank"
              >designersofa fra Paustian</a
            >
            til
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/20000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >20.000 kr.</a
            >, så kan vi hjælpe dig. Hvis du bygger din sofa selv, kan omkostningerne også
            hurtigt løbe op, hvis det fx skal være en 5-personers sofa eller en hjørnesofa
            med chaiselong.
          </p>
          <p>
            Der findes flere møbelforretninger, som selv udbyder lån til møbler, inkl.
            rentefri lån til møbler, men som vi vil gennemgå længere nede på siden, så
            skal du være meget opmærksom på alle de skjulte omkostninger, som de kan finde
            på at pålægge dig.
          </p>
          <p>
            Hvis du har brug for finansiering til en sofa eller et andet møbel, anbefaler
            vi derfor som minimum, at du ansøger igennem os, for at have et
            sammenligningsgrundlag, så du ender ud med det
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >bedste lån</a
            >
            og ikke blot det, som ser bedst ud på overfladen.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-designermoebler">Lån til designermøbler</h2>
          <p>
            Designermøbler er nogle af de dyreste møbler og lån til møbler er derfor også
            ofte relevante i denne sammenhæng.
          </p>
          <h3>Designerbord</h3>
          <p>
            Et godt designerbord som fx et Børge Mogensen Shakerbord eller et Piet Hein
            Super-Ellipsebord, kan sagtens koste hhv.
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/50000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >50.000 kr.</a
            >
            og
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/25000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >25.000 kr.</a
            >
          </p>
          <p>
            Hvis du er på udkig efter et designerbord af denne type, så har du måske
            allerede lagt penge til side. Uanset om du har behov for at låne hele beløbet
            eller blot mangler et
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/10000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >lån på 10.000 kr</a
            >. til at dække den sidste del af udgiften til møblet, så hjælper vi dig
            gerne.
          </p>
          <h3>Designerlamper</h3>
          <p>
            Lamper, hvis det skal være en designerlampe, eller en serie af lamper, som du
            ønsker dig, så kan det også blive en dyr fornøjelse. En PH Artichoke LED
            Pendel Kobber, Ø720 kan nogle gange fås til
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/75000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >75.000 kr.</a
            >, men koster som udgangspunkt
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/100000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >100.000 kr.</a
            >
          </p>
          <p>
            Ud over Koglen findes der også mange andre designerlamper, som ofte kan kræve
            finansiering, men sjældent i samme udstrækning. Har du fx sat dig for at købe
            en lampe fra
            <a href="https://www.leklint.dk/" rel="noopener noreferrer" target="_blank"
              >Le Klint</a
            >, kræver dette sjældent et møbellån i samme størelsesorden.
          </p>
          <h3>Designerstole</h3>
          <p>
            Sidst men ikke mindst kan vi nævne designerstolene, som også ofte er
            omdrejningspunktet for et lån til møbler. Stolene i sig selv koster sjældent
            over 10.000 kr. og ofte i nærheden af 5.000 kr., men bliver ofte købt i 4, 6
            eller 8 stk., som betyder at et lån til møbler lige pludselig skal overvejes.
          </p>
          <p>
            Hvis stolene i samme ombæring skal ombetrækkes eller restaureres, hvis de fx
            købes i en vintagebutik, løber regningen hurtigt op.
          </p>
        </div>

        <div class="text-container">
          <h2 id="koeb-moebler-paa-afbetaling-trods-rki">
            Køb møbler på afbetaling trods RKI
          </h2>
          <p>
            At købe møbler på afbetaling trods RKI er sjældent anbefalelsesværdigt eller i
            det hele taget muligt, men hvis du har overvejet din situation og er fast
            besluttet på at at låne, så lad os hjælpe dig med at undersøge, hvilke
            muligheder du har.
          </p>
          <p>
            Langt de fleste långivere på markedet ønsker ikke at udlåne penge til
            personer, som er registreret som en dårlig betaler i RKI eller Debitor
            Registret. I det fleste tilfælde vil det være en afvisningsgrund, da det både
            giver dårlig omtale at låne ud til folk med gældsproblemer, men også ofte vil
            være en dårlig forretning, da sandsynligheden for at lånet tilbagebetales er
            lav.
          </p>
          <p>
            Der findes dog være tilfælde, hvor et lån til fx møbler trods RKI kan opnås,
            hvis en långiver fx kan se, at registreringen i RKI eller Debitor Registret
            skyldes en simpel forglemmelse af fx en parkeringsbøde, eller en verserende
            diskussion om, hvorvidt <strong>gælden</strong> bør betales eller blot
            afskrives.
          </p>
          <p>
            I mange tilfælde tror mange personer fejlagtigt også, at de står registreret i
            RKI, og dermed ikke kan købe deres deres møbler eller andet på afbetaling.
            Ofte er disse personer aldrig registreret i RKI, eller også er deres
            registrering blevet fjernet.
          </p>
          <p>
            Hvis du har besluttet dig for at låne, uanset om det er til møbler eller ej,
            og du tror at du står i RKI, så anbefaler vi, at du ansøger igennem os, da det
            hurtigt ville kunne give dig et overblik over dine
            <a
              href="https://www.saverium.dk/laan-penge/bolig/laanemuligheder"
              rel="noopener noreferrer"
              target="_blank"
              >lånemuligheder</a
            >.
          </p>
        </div>

        <div class="text-container">
          <h2 id="rentefri-moebellaan">Rentefri møbellån</h2>
          <p>
            Hvis du leder efter rentefri lån til møbler, så kan det godt findes, men intet
            lån kommer uden en pris, og det bør du være opmærksom på. Hvordan du i sidste
            ende kommer til at betale for dit rentefri møbellån afhænger af udbyderens
            vilkår.
          </p>
          <p>
            Nedenunder gennemgår vi nogle af de faldgruber, som vi ser at folk typisk
            falder i, når de blindt går efter et rentefrit møbellån, eller
            <strong>rentefri lån</strong> helt generelt.
          </p>
          <h3>Din data (spam marketing)</h3>
          <p>
            Den første faldgruber ved at låne til møbler rentefrit er, at du betaler med
            din data i form af fremtidig markedsføring. Dette kan være på email, sms, med
            post, eller ved at du bliver forfulgt med reklamer alle steder på nettet.
          </p>
          <h3>Oprettelsesgebyr</h3>
          <p>
            Oprettelsesgebyr er en af de mest anvendte måder at skjule omkostningerne ved
            lån på bla. møbler. Ofte er lånets størrelse blot lidt større end det, som dit
            bord, stole eller seng koster. På den måde overser du let udgiften.
          </p>
          <h3>Gebyr for ikke at have tilbagebetalt efter kort tid</h3>
          <p>
            En anden anvendt metode er at lånet løber over meget kort tid - fx 14 dage.
            Her får du så lov at betale et stort gebyr, hvis ikke du kan tilbagebetale dit
            møbellån inden for 14 dage, hvilket disse udbydere godt ved er umuligt for
            mange, som vælger denne mulighed for at låne til deres møbler. Hvis du ved at
            der går løn ind, som kan dække møbellånet inden for 14 dage, kan dette dog
            være en god mulighed.
          </p>
          <h3>Diverse gebyrer</h3>
          <p>
            Sidste med ikke mindst, findes der et hav af forskellige gebyrer, som alle
            sammen har til formål at skjule omkostningen ved dit møbellån. Det kan fx
            være, at det koster penge at forhøje dit lån yderligere, gebyrer for
            påmindelser om betalingsfrist eller andre gebyrer.
          </p>
          <p>
            Hvis du absolut vil have et rentefrit lån til møbler, så husk at der er mange
            faldgruber, og låneudbyderen med sikkerhed ikke har interesse i at fortælle
            dig, hvordan de har skjult deres omkostninger.
          </p>
        </div>

        <div class="text-container">
          <h2 id="vi-hjaelper-med-finansiering-af-moebler">
            Vi hjælper med finansiering af møbler
          </h2>
          <p>
            Står du med et behov for at finansiere dine møbler, uanset om det så er en
            komode, tøjskab eller noget tredje, så hjælper vi dig gerne. Hvis stuen,
            soveværelset, udestuen eller terrassen mangler et opløft, så kan nye møbler
            ofte være svaret.
          </p>
          <p>
            Hvis du har brug for hjælp til dit møbellån, så er vores kundeservice klar til
            at hjælpe dig på mail og telefon. Eftersom vores ansøgning er meget kort og
            brugervenlig, er den nemmeste vej til et møbellån ofte at udfylde ansøgningen,
            frem for at kontakte vores kundeservice.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
