<template lang="html">
  <div class="">
    <label v-if="!hideLabel && type !== 'checkbox'" :name="name" :for="id">
      <slot></slot>
      <span v-if="error" :key="error" class="invalid-feedback" style="display: block">
        {{ $t(error) }}
      </span>
    </label>
    <div>
      <numeric-input
        :error="error"
        :value="value"
        :name="name"
        :id="id"
        @input="updateValue"
        :currency="currency"
        :separator="separator"
        v-if="type === 'numeric' || type === 'integer'"
        :placeholder="placeholder"
        :customEvent="customEvent"
      />

      <text-input
        :error="error"
        :name="name"
        :value="value"
        @input="updateValue"
        :placeholder="placeholder"
        :id="id"
        :maxlength="maxlength"
        v-else-if="type === 'text' || type === 'string'"
      />
      <dropdown-input
        :error="error"
        :value="value"
        @input="updateValue"
        :items="items"
        :id="id"
        :suffix="suffix"
        v-else-if="type === 'dropdown'"
      />
      <email-input
        :error="error"
        :value="value"
        @input="updateValue"
        :name="name"
        :id="id"
        v-else-if="type === 'email'"
      />
      <phone-input
        :error="error"
        :value="value"
        @input="updateValue"
        :placeholder="placeholder"
        :name="name"
        :id="id"
        :prepend="prepend"
        v-else-if="type === 'phone'"
      />
      <phone-input-es
        :error="error"
        :value="value"
        @input="updateValue"
        :id="id"
        v-else-if="type === 'phoneEs'"
      />
      <month-input
        :error="error"
        :value="value"
        @input="updateValue"
        :placeholder="placeholder"
        :id="id"
        v-else-if="type === 'month'"
      />
      <date-input
        :error="error"
        :value="value"
        :placeholder="placeholder"
        @input="updateValue"
        :id="id"
        v-else-if="type === 'date'"
      />
      <regex-input
        :error="error"
        :value="value"
        @input="updateValue"
        :regex="regex"
        :name="name"
        :id="id"
        :placeholder="placeholder"
        v-else-if="type === 'regex'"
      />
      <prepend-regex-input
        :error="error"
        :value="value"
        @input="updateValue"
        :regex="regex"
        :prepend="prepend"
        v-else-if="type === 'prependregex'"
      />
      <checkbox-input
        :error="error"
        :value="value"
        @input="updateValue"
        :id="id"
        v-else-if="type === 'checkbox'"
      >
        <slot></slot>
        <span v-if="error" :key="error" class="invalid-feedback" style="display: block">
          {{ $t(error) }}
        </span>
      </checkbox-input>
      <postal-code
        :error="error"
        :name="name"
        :value="value"
        @input="updateValue"
        :placeholder="placeholder"
        :id="id"
        :maxlength="maxlength"
        v-else-if="type === 'postal-code'"
      />
      <identity-input
        :error="error"
        :value="value"
        @input="updateValue"
        :name="name"
        :placeholder="placeholder"
        :id="id"
        :identifier="identifier"
        v-else-if="type === 'identity'"
      />
      <polish-id-input
        :error="error"
        :value="value"
        @input="updateValue"
        :name="name"
        :placeholder="placeholder"
        :id="id"
        v-else-if="type === 'polish-id'"
      />
      <boolean-input
        :error="error"
        :value="value"
        :checked="checked"
        :name="name"
        @input="updateValue"
        :id="id"
        v-else-if="type === 'boolean' || type === 'bool'"
      />
    </div>
  </div>
</template>

<script>
import NumericInput from './inputs/Numeric'
import TextInput from './inputs/Text'
import DropdownInput from './inputs/Dropdown'
import EmailInput from './inputs/Email'
import PhoneInput from './inputs/Phone'
import PhoneInputEs from './inputs/PhoneEs'
import MonthInput from './inputs/Month'
import CheckboxInput from './inputs/Checkbox'
import DateInput from './inputs/Date'
import RegexInput from './inputs/Regex'
import PrependRegexInput from './inputs/PrependRegex'
import PostalCode from './inputs/PostalCode'
import IdentityInput from './inputs/Identity'
import BooleanInput from './inputs/Boolean'
import PolishIdInput from './inputs/PolishId'
import _ from 'lodash'

export default {
  props: [
    'type',
    'storekey',
    'items',
    'hideLabel',
    'placeholder',
    'prepend',
    'regex',
    'validation',
    'name',
    'maxlength',
    'id',
    'identifier',
    'separator',
    'currency',
    'customEvent',
    'suffix',
    'checked',
  ],
  components: {
    NumericInput,
    TextInput,
    DropdownInput,
    EmailInput,
    PhoneInput,
    MonthInput,
    CheckboxInput,
    DateInput,
    RegexInput,
    PrependRegexInput,
    PhoneInputEs,
    PostalCode,
    IdentityInput,
    BooleanInput,
    PolishIdInput,
  },
  computed: {
    value() {
      if (this.storekey) {
        return _.get(this.$store.state.application, this.storekey)
      }
      return undefined
    },
    error() {
      return (
        this.$store.state.application.errors[this.storekey] &&
        this.$store.state.application.errors[this.storekey][0]
      ) // TODO: See how it works with nested objects.
    },
    needEmployerInfo() {
      return this.$store.state.application.needEmployerInfo
    },
  },
  methods: {
    updateValue(value, validated) {
      this.$store.commit('updateApplication', { key: this.storekey, value: value })
      this.$store.dispatch('updateOrCreateDraft')

      if (validated === null || (this.validation && !this.validation(value))) {
        // Validation failed, store the error.
        this.$store.commit('fieldError', this.storekey)
      }
      if (this.error) {
        if (this.error === 'Field is required') {
          this.$store.commit('fieldError', this.storekey)
        }

        if (
          (validated || validated === undefined) &&
          (!this.validation || this.validation(value))
        ) {
          // Validated or validation doesn't exist.
          this.$store.commit('removeError', this.storekey)
        }
      }
      if (this.storekey === 'hasRegisteredAddress') {
        if (value === false) {
          this.$store.commit('removeError', 'registeredZipCode')
          this.$store.commit('removeError', 'registeredCity')
          this.$store.commit('removeError', 'registeredStreet')
          this.$store.commit('removeError', 'registeredHouseNumber')
          this.$store.commit('removeError', 'registeredRegion')
        }
      }

      if (this.needEmployerInfo === false && this.storekey === 'occupation') {
        this.$store.commit('removeError', 'workPhone')
        this.$store.commit('removeError', 'employer')
        this.$store.commit('removeError', 'businessId')
      }
    },
  },
}
</script>

<style lang="sass" scoped></style>
