<template>
    <div class="main-container">
    <div class="main">
      <main-form-view />
    </div>
    <div class="main">
      <b-container class="main-container">
        <paragraph-header :value="$t('company_loan')" />
        <p>{{ $t('company_loan_text_1') }}</p>
        <p>{{ $t('company_loan_text_2') }}</p>
        <div class="secondary-title">{{ $t('company_loan_guarantees') }}</div>
        <p>{{ $t('company_loan_guarantees_text_1') }}</p>
        <p>{{ $t('company_loan_guarantees_text_2') }}</p>
        <p>{{ $t('company_loan_guarantees_text_3') }}</p>
        <p>{{ $t('company_loan_guarantees_text_4') }}</p>
        <div class="secondary-title">{{ $t('company_loan_usage') }}</div>
        <p>{{ $t('company_loan_usage_text_1') }}</p>
        <p>{{ $t('company_loan_usage_text_2') }}</p>
        <p>{{ $t('company_loan_usage_text_3') }}</p>
        <p>{{ $t('company_loan_usage_text_4') }}</p>
        <div class="secondary-title">{{ $t('company_loan_benefits') }}</div>
        <p>{{ $t('company_loan_benefits_1') }}</p>
        <p>{{ $t('company_loan_benefits_2') }}</p>
        <p>{{ $t('company_loan_benefits_3') }}</p>
      </b-container>
    </div>
  </div>
</template>

<script>
import ParagraphHeader from '@/components/lainaayritykselle_fi/ParagraphHeader'
import MainFormView from '@/components/lainaayritykselle_fi/newForm/MainFormView'

export default {
  metaInfo: {
    meta: [
      { name: 'description', content: 'Palvelussamme voit kilpailuttaa yritysluotot helposti ja nopeasti. Mukana vertailussa yli 10 yritysluottoa.' },
      { name: 'keywords', content: 'yrityslaina, kilpailuttaminen' }
    ]
  },
  components: {
    ParagraphHeader,
    MainFormView
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .main {
    width: 100%;
    position: relative;
  }

  .secondary-title {
    text-transform: uppercase;
    margin-top: 50px;
    margin-bottom: 10px;
    font-weight: 500;
  }
</style>
