import settings from '@/settings/settings'
import { DateTime } from 'luxon'

const loanAmounts = require('@/utils/' + settings.BRAND + '/const').loanAmounts
const loanAmountsSme = require('@/utils/' + settings.BRAND + '/const').smeLoanAmounts
function pmt(rate, number_of_periods, present_value, future_value = 0, type = 0) {
  const q = Math.pow(1 + rate, number_of_periods)
  return (rate * (future_value + q * present_value)) / ((-1 + q) * (1 + rate * type))
}

export const getApproximateLoanValues = (amount, time) => {
  // set base repaymenttime value if both loanamount and
  // repaymenttime values are below certain thresholds
  if (amount < 2000 && time > 120) {
    time = 120
  }
  let annualRate
  if (settings.BRAND === 'saverium_pl') {
    annualRate = 0.0819 / 12
  } else if (settings.BRAND === 'saverium_es') {
    // Same for now
    annualRate = 0.0819 / 12
  } else if (settings.BRAND === 'saverium_dk') {
    // Better than Lendo
    time = time * 12
    annualRate = 0.0719 / 12
  }
  const monthlyPayment = pmt(annualRate, time, amount)
  const costs = monthlyPayment * time - amount
  return {
    loanAmountStatus: amount,
    repaymentTimeStatus: time,
    costMonthly: monthlyPayment,
    cost: costs,
  }
}

export const getApproximateSmeLoanValues = (amount, time) => {
  time = time * 12
  const annualRate = 0.0719 / 12
  const monthlyPayment = pmt(annualRate, time, amount)
  const costs = monthlyPayment * time - amount
  return {
    loanAmountStatus: amount,
    repaymentTimeStatus: time,
    costMonthly: monthlyPayment,
    cost: costs,
  }
}

export const getMonthlyCosts = (amount, maxLoanTime) => {
  const allCosts = []
  const annualRate = 0.0719 / 12
  let year
  for (year = 1; year <= maxLoanTime; year++) {
    allCosts.push({
      text: `${Math.round(pmt(annualRate, year * 12, amount))} kr. (${year} år)`,
      value: year,
    })
  }
  return allCosts
}

/**
 * Finds next valid loan amount based on given amount
 * @param {*} elem
 */
export const findMatchAmount = amount => {
  let val = amount
  if (typeof amount === 'string') {
    val = parseInt(amount.replace(/\D/g, ''))
  }
  let found = false
  if (val < loanAmounts[0]) {
    val = loanAmounts[0]
  }
  // find next valid amount
  for (let i = 0; i < loanAmounts.length; i++) {
    const v = loanAmounts[i]
    if (v == val) {
      val = v
      found = true
      break
    } else if (v > val) {
      val = loanAmounts[i]
      found = true
      break
    }
  }
  if (!found) {
    val = loanAmounts[loanAmounts.length - 1]
  }
  return val
}
export const findMatchAmountSme = amount => {
  let val = amount
  if (typeof amount === 'string') {
    val = parseInt(amount.replace(/\D/g, ''))
  }
  let found = false
  if (val < loanAmountsSme[0]) {
    val = loanAmountsSme[0]
  }
  // find next valid amount
  for (let i = 0; i < loanAmountsSme.length; i++) {
    const v = loanAmountsSme[i]
    if (v == val) {
      val = v
      found = true
      break
    } else if (v > val) {
      val = loanAmountsSme[i]
      found = true
      break
    }
  }
  if (!found) {
    val = loanAmounts[loanAmountsSme.length - 1]
  }
  return val
}

export const findAmountByIndex = ind => {
  return loanAmounts[ind]
}
export const findAmountByIndexSme = ind => {
  return loanAmountsSme[ind]
}

export const calculateInputRangeStep = amount => {
  const v = findMatchAmount(amount)
  let step = 100
  if (v >= 5000 && v < 10000) {
    step = 500
  } else if (v >= 10000) {
    step = 1000
  }
  return step
}
export const calculateInputRangeStepSme = amount => {
  const step = 100000
  return step
}

export const validAmountToText = amount => {
  let val = amount
  if (typeof val === 'string') {
    val = parseInt(amount.replace(/\D/g, ''))
  }
  const zl = String.fromCharCode(122) + String.fromCharCode(322)
  val = findMatchAmount(val)
  if (settings.BRAND === 'saverium_pl') {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + zl
  } else {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ' + '€'
  }
}

export const generateAmountSteps = (end, start, step) => {
  const options = []
  const loanAmounts = Array.from(
    { length: (end - start) / step },
    (v, k) => start + step * k
  )
  loanAmounts.forEach(function (v, i) {
    options.push({ value: v, text: validAmountToText(v) })
  })
  return options
}

export const generateStructuredArticleData = (
  headline,
  publisher,
  datePublished,
  type,
  image,
  author
) => {
  const jsonld = {
    '@context': 'https://schema.org',
    '@type': type,
    headline: headline,
    datePublished: datePublished,
    image: image,
    author: author,
    publisher: [
      {
        '@type': publisher.type,
        name: publisher.name,
        url: publisher.url,
        logo: publisher.logo,
      },
    ],
  }
  return jsonld
}

function checkIfMobile() {
  let hasTouchScreen = false
  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0
  } else if ('msMaxTouchPoints' in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0
  } else {
    const mQ = window.matchMedia && matchMedia('(pointer:coarse)')
    if (mQ && mQ.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches
    } else if ('orientation' in window) {
      hasTouchScreen = true // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing sniff sniff
      const UA = navigator.userAgent
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
    }
  }
  if (hasTouchScreen) {
    return true
  } else {
    return false
  }
}
export const isMobile = checkIfMobile()

export const checkIfInViewport = element => {
  const rect = element.getBoundingClientRect()
  return (
    rect.bottom >= 0 &&
    rect.right >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const setSliderColor = ({
  elementId,
  min,
  max,
  value,
  firstColor,
  secondColor,
}) => {
  const slider = document.getElementById(elementId)
  if (slider) {
    slider.style.background = `linear-gradient(to right, ${firstColor} 0%, ${secondColor} ${
      ((value - min) / (max - min)) * 100
    }%, #DEE2E6 ${((value - min) / (max - min)) * 100}%, #DEE2E6 100%)`

    slider.style.height = '0.7rem'
  }
}

export const customFormatter = date => {
  if (settings.BRAND === 'saverium_pl') {
    const polishDate = DateTime.fromISO(date, { zone: 'Europe/Warsaw' }).toFormat(
      'dd.MM.yyyy tt'
    )
    return polishDate
  } else if (settings.BRAND === 'saverium_es') {
    // Luxon format: - F short localized date and time with seconds
    const spanishDate = DateTime.fromISO(date, { zone: 'Europe/Madrid' }).toFormat('F')
    return spanishDate
  } else if (settings.BRAND === 'saverium_dk') {
    // Luxon format: dd - numeric day, MM - numeric month, numeric - yyyy year
    const danishDate = DateTime.fromISO(date, { zone: 'Europe/Helsinki' }).toFormat(
      'dd.MM.yyyy'
    )
    return danishDate
  }
}

export const validateCvr = value => {
  const weights = [2, 7, 6, 5, 4, 3, 2]
  const id = value
    .toString()
    .split('')
    .map(d => parseInt(d, 10))
  if (id.length !== 8) {
    return false
  }
  const serial = id.slice(0, 7)
  const expectedCheck = id.pop()
  const sum = serial.reduce((a, c, i) => a + c * weights[i], 0)
  const mod = sum % 11
  const apparentCheck = 11 - mod
  return expectedCheck === apparentCheck
}

export const createStructuredData = structuredData => {
  const script = document.createElement('script')
  script.type = 'application/ld+json'
  script.innerHTML = JSON.stringify(structuredData)
  script.id = 'structuredData'
  document.head.appendChild(script)
}

export const validateCpr = value => {
  // CPR, only checks if length is correct because Danish system ran out of sequence numbers ¯\_(ツ)_/¯
  // Now it also checks that the applicant is over 18
  const regex = /[0-9]{6}[0-9]{4}/
  const day = value.substring(0, 2)
  const month = value.substring(2, 4)
  const year = value.substring(4, 6)
  const fullBirthday = day + '-' + month + '-' + year
  const formatted = DateTime.fromFormat(fullBirthday, 'dd-MM-yy')
  const today = DateTime.now()
  const difference = today.diff(formatted, 'years')
  // Year with combined century (digit 7) will tell the century
  value = value.replace(/[^\d]/g, '')
  const century = parseInt(value[6], 10)
  let invalid = false
  const diff = difference.toObject().years
  // If digit 7 is 0-3 person is born 1900-1999, no age check required
  // if digit 7 is 4 years 37-99 require no age check
  // if digit 7 is 5-8 years 58-99 require no age check
  // if digit 7 is 9 years 37-99 require no age check
  // TL;DR on those cases person is born on the 20th century.
  if (regex.test(value) && day <= 31 && month <= 12) {
    if (century >= 0 && century <= 3) {
      invalid = false
      return invalid
    }
    if (century === 4 || century === 9) {
      if (year >= 37 || diff > 18) {
        invalid = false
      } else {
        invalid = true
      }
    } else if (century >= 5 && century <= 8) {
      if (year >= 58 || diff > 18) {
        invalid = false
      } else {
        invalid = true
      }
    }
  } else {
    invalid = true
  }
  return invalid
}
