<template>
  <div>
    <div class="article">
      <h1 class="center-title">Privatlån</h1>
      <div class="top-text text-container">
        <p>
          Leder du efter et billigt tilbud på privatlån? Det kan vi hjælpe med. Hvis du
          bruger 2 minutter på at udfylde vores ansøgningsformular, indhenter vi gratis og
          uforpligtende tilbud på privatlån til dig, så du nemt kan vælge det billigste og
          bedste privatlån.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#faa-hjaelp-til-privatlaan-gennem-os"
                    >Få hjælp til privatlån gennem os</a
                  >
                </li>
                <li>
                  <a href="#ansoeg-om-privatlaan-og-faa-flere-tilbud-paa-faa-minutter"
                    >Ansøg om privatlån og få flere tilbud på få minutter</a
                  >
                </li>
                <li><a href="#hvad-er-et-privatlaan">Hvad er et privatlån?</a></li>
                <li>
                  <a href="#hvad-koster-et-privatlaan">Hvad koster et privatlån?</a>
                </li>
                <li>
                  <a href="#hvad-kan-jeg-bruge-et-privatlaan-til"
                    >Hvad kan jeg bruge et privatlån til?</a
                  >
                </li>
                <li>
                  <a href="#hvor-stort-kan-laanet-blive">Hvor stort kan lånet blive?</a>
                </li>
                <li><a href="#privatlaan-uden-sikkerhed">Privatlån uden sikkerhed</a></li>
                <li><a href="#laan-af-privatpersoner">Lån af privatpersoner</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="faa-hjaelp-til-privatlaan-gennem-os">
            Få hjælp til privatlån gennem os
          </h2>
          <p>
            Vi har en sammenligningstjeneste for privatlån, som du kan benytte helt
            gratis. Hvis du ønsker at låne penge privat, så er du havnet det rigtige sted.
          </p>
          <p>
            Hvis du har mod på at udfylde vores korte online ansøgningsformular, som du
            finder herunder, vil vi afsøge markedet blandt vores lånepartnere for tilbud
            på privatlån, som er skræddersyet til dig, dit behov og din økonomi.
          </p>
          <p>
            Ansøgningen til lånet tager 2-5 minutter, hvilket hurtigt kan tage flere dage,
            hvis du selv skal ud og finde de samme udbydere af private låneaftaler, samt
            udfylde deres ansøgningsformularer én efter én.
          </p>
          <p>
            Ansøg nu, så finder vi en lang række personlige privatlånstilbud til dig med
            det samme.
          </p>
        </div>

        <div class="text-container">
          <h2 id="ansoeg-om-privatlaan-og-faa-flere-tilbud-paa-faa-minutter">
            Ansøg om privatlån og få flere tilbud på få minutter
          </h2>
          <p>
            Det kan virke besværligt at finde et billigt privat lån med lav rente. Det er
            i særdeleshed gældende, hvis man er helt grøn på området. Heldigvis kan vi
            hjælpe dig, da du her på siden kan indhente tilbud på fra flere långivere med
            en enkelt ansøgning og vælge det billigste privatlån, frem for det første du
            støder på.
          </p>
          <p>
            Når du har udfyldt vores ansøgningsformular, modtager du tilbud fra vores
            samarbejdspartnere på få minutter, hvilket omfatter en lang række banker og
            långivere, som gør alt hvad de kan, for at skræddersy det billigt privatlån
            til dig.
          </p>
          <p>
            Alle modtagne tilbud er naturligvis uforpligtende, hvorfor du ikke er
            forpligtet til at acceptere nogle af dem, hvis ikke du finder dem tiltalende.
            Ønsker du blot at undersøge markedet for privatlån, kan du sagtens oprette en
            uforpligtende ansøgning.
          </p>
          <p>
            Ønsker du at sikre, at du optager et billig privatlån, bør du sammenligne
            privatlån fra flere låneudbydere. Det er netop den process, som vi har gjort
            nemt, da alle lånetilbud indhentes via én enkelt ansøgning og præsenteres på
            samme side.
          </p>
          <p>
            Fælles for de mange udbydere af billige privatlån, som vi samarbejder med, er
            at du ikke er påkrævet at stille sikkerhed for lånet. Vær opmærksom på, at vi
            ikke selv er en udbyder af privatlån - vi formidler blot kontakten og
            ansøgningen mellem dig og långiveren.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-er-et-privatlaan">Hvad er et privatlån?</h2>
          <p>
            Et privatlån kan i stor stil sammenlignes med et
            <a
              href="https://saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >. Derfor er et privat lån ikke en specifik type lån med særlige lånevilkår.
            Når du låner penge privat, fungerer det på samme vis som ovenstående låntype.
            Det er ikke nogle begrænsninger på privatlånet alt efter hvad du vælger at
            benytte lånet til. Fordelen ved denne type lån er desuden, at det er et
            <a
              href="https://saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtigt lån</a
            >, hvor pengene i løbet af kort tid, er disponible på din bankkonto.
          </p>
          <h3>Hvad er et Privatlån Plus?</h3>
          <p>
            Flere låneudbydere tilbyder ikke blot almindelige privatlån, men også et
            Privatlån Plus. Teknisk set er der ikke nogen forskel på de to lånetyper,
            andet end lånebeløbets størrelse.
          </p>
          <p>
            Som regel er et regulært privatlån et lån beløb på op til 50.000 kr., mens et
            Privatlån Plus betegner et lån med et beløb på over 50.000 kr. Foruden dette,
            er der ingen forskelle på de to typer lån.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-koster-et-privatlaan">Hvad koster et privatlån?</h2>
          <p>
            Der kan ikke gives et konkret svar på, hvad et privatlån koster at optage.
            Prisen for at optage et privatlån afhænger af flere faktorer, ligesom
            låneudbydernes priser også varierer. Derfor er derfor stor forskel på hvad et
            lånet koster. Af samme årsag er det også vigtigt at afsøge markedet, for at
            finde det billigste privatlån.
          </p>
          <h3>Billigste privatlån</h3>
          <p>
            Hvis du ønsker et billigt privatlån, findes der mange knapper at skrue på. For
            det første kan du sænke
            <a
              href="https://saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              >låbebeløbet</a
            >, så du ikke låner mere end højst nødvendigt. Dernæst kan du sænke løbetiden,
            så du slipper med så lav en rente på privatlånet som muligt, målt i kroner og
            ører over over hele lånets løbetid. Sidst men ikke mindst, får man de
            billigste privatlån, når ens økonomi er sund og stabil.
          </p>
          <p>
            Det er selvfølgeligt ikke altid, at man selv er herre over sin økonomiske
            situation, og det er ofte ikke noget, som man kan ændre på fra dag til dag. I
            sidste ende er det op til hver enkelt långiver at vurdere, hvilken rente de
            vil tilbyde dig. Af denne årsag, er det også vigtigt at
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne lån</a
            >, før du træffer dit valg.
          </p>
          <p>
            Et privatlåns omkostninger kan beregnes via vores
            <a
              href="https://saverium.dk/laan-penge/privatlaan/laaneberegner"
              rel="noopener noreferrer"
              target="_blank"
              >låneberegner</a
            >, med udgangspunkt i din kreditvurdering. Kreditvurderingen har til formål at
            fortælle den pågældende långiver, hvor stor en risiko, der er, for at du ikke
            har råd til tilbagebetalingen. Det inkluder også en betragtning af, hvor meget
            du med rimelighed kan betale i renter, gebyrer og afdrag sammenlagt hver
            måned. Hvis din kreditværdighed kan betragtes som værende god, vil du derfor
            modtage tilbud om privatlån med lavere omkostninger.
          </p>
          <p>
            Vi har specialiseret os i at finde de bedste privatlån for dig og resten af
            Danmark. Det tager ikke mange minutter at udfylde vores ansøgning, så du kan
            danne dig et overblik over, hvem der kan tilbyde dig et billigt privatlån, og
            hvem der kan tilbyde et dyrt.
          </p>
          <p>
            På grund af långivernes forskellige regnemetoder, som ligger til grund for
            deres prisberegninger, vil én person kunne få et billigt privatlån hos den ene
            udbyder, hvor den anden vil kunne finde et billigere privatlån hos en anden
            udbyder.
          </p>
          <h3>Sådan sammenligner du prisen på privatlån</h3>
          <p>
            Når du skal finde det bedste privatlån, er det selvfølgelig det billigste
            privatlån, som de fleste leder efter. Det er dog ikke tilstrækkeligt, at du
            blot finder et lån med lav rente, da der også er andre omkostninger forbundet
            med et lån.
          </p>
          <p>
            Når du skal sammenligne forskellige lånetilbud, er det ikke blot renten, der
            skal inddrages i sammenligningen. Derimod bør du
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne lån</a
            >
            med afsæt i ÅOP (Årlige Omkostninger i Procent). Det er et tal, der fortæller
            dig lånets samlede omkostninger i procent ift. lånebeløbet.
          </p>
          <p>
            Vær dog opmærksom på, at ÅOP udelukkende kan bruges til sammenligning af
            privatlån, der har samme lånebeløb og samme løbetid. Det er et lovkrav, at der
            oplyses en ÅOP på alle lån nu til dags. Derfor er det altid nemt at finde et
            låns ÅOP.
          </p>
          <p>
            Foruden privatlånets ÅOP, er det også klogt at holde øje med den samlede
            tilbagebetaling. Når du holder øje med den samlede tilbagebetaling, er det
            muligt at se, hvordan løbetiden påvirker de samlede låneomkostninger. Jo
            længere løbetid, jo længere skal du betale renter på dit lån.
          </p>
          <p>
            Vær opmærksom på, hvis du skulle støde på det, at den maksimale ÅOP, som en
            långiver må tilbyde er 35%. I tilfælde af at långiveren markedsfører sine
            låneprodukter, vil dette tal være 25%. Alle långivere, som vi samarbejder med,
            tilbyder maksimalt en ÅOP på 25%, da vores service karakteriseres som
            markedsføring for långiverne. Derfør behøver du ikke bekymre dig om dette, når
            du benytter vores tjeneste til at beregne og finde de bedste privatlån
          </p>
          <h3>Hvad dækker ÅOP over?</h3>
          <p>
            ÅOP er en forkortelse for Årlige Omkostninger i Procent. Det betyder, at alle
            omkostninger på dit privatlån er indregnet i ÅOP'en, hvorfor tallet giver et
            komplet overblik over lånets omkostninger. Husk dog, at det er på årlig basis.
          </p>
          <p>Følgende omkostninger er blandt andet inkluderer i ÅOP'en:</p>
          <ul>
            <li>Stiftelsesomkostninger</li>
            <li>Renter</li>
            <li>Administrationsgebyrer</li>
            <li>Kontogebyrer</li>
            <li>Påkrævede PBS-gebyrer</li>
          </ul>
          <p>
            Vi nævner lige igen, at det er vigtigt at du kun benytter ÅOP til at
            sammenligne lån, som har samme løbetid og lånebeløb.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-kan-jeg-bruge-et-privatlaan-til">
            Hvad kan jeg bruge et privatlån til?
          </h2>
          <p>
            I og med, at et privatlån er et usikret lån, kan de lånte penge spenderes, som
            du ønsker. Du kan reelt set bruge denne type lån til at købe et hus, om end
            det skal være et billigt ét af slagsen.
          </p>
          <p>
            De fleste låner penge privat for at finansiering af alt fra
            <strong>rejser</strong> og shoppingture til uforudsete udgifter. Når du
            optager et lån i denne kategori, er det primære formål at købe en genstand,
            service eller oplevelse, som du løbenden kan <strong>afdrage</strong> på.
            Låneformål for et privatlån kunne være:
          </p>
          <ul>
            <li><strong>Indskud til bolig</strong></li>
            <li><strong>Lån til rejser</strong></li>
            <li><strong>Nye møbler</strong></li>
            <li><strong>Renovering af bolig</strong></li>
            <li>Betaling af regninger</li>
            <li><strong>Bryllup</strong></li>
            <li><strong>Konfirmation</strong></li>
          </ul>
          <p>
            Det kan også være, at du blot står og mangler lidt penge sidst på måneden.
            Uanset hvad årsagen er, kan et privatlån være en ideel løsning. Det er
            vigtigt, at du husker, at der altid er omkostninger forbundet med at lån.
            Derfor bør du også altid lede efter et billigt privatlån.
          </p>
          <p>
            Har du besluttet dig for, at du skal optage et lån, kan det altid betale sig
            at undersøge flere lånetilbud. På denne måde finder du nemlig lettest frem til
            de billigste privatlån med lav rente og lav ÅOP.
          </p>
          <h3>Fordele og ulemper ved privatlån</h3>
          <p>
            Forsøger du at finde et billig privatlån, er det naturligvis essentielt, at du
            er opmærksom på, at der er fordele og ulemper forbundet med låntypen.
            Heldigvis er der lovgivning som kan hjælpe dig i forbindelse med privatlån,
            hvilket du kan læse mere om på
            <a
              href="https://em.dk/nyhedsarkiv/2020/juni/nu-er-regler-om-opgoer-med-kviklaan-paa-plads/"
              rel="noopener noreferrer"
              target="_blank"
              >erhvervsministeriets hjemmeside</a
            >.
          </p>
          <p>
            Herunder fremhæves en række af de mest fremtrædende fordele og ulemper
            forbundet med netop denne låntype:
          </p>
          <p><strong>Fordele:</strong></p>
          <ul>
            <li>Det er muligt at tilpasse lånebeløbet</li>
            <li>Meget kort udbetalingstid</li>
            <li>
              Høj sikkerhed og digital validering da du
              <a
                href="https://saverium.dk/laan-penge/privatlaan/laan-med-mitid"
                rel="noopener noreferrer"
                target="_blank"
                >låner med NemID/MitID</a
              >
            </li>
            <li>Kan tilpasses til alle individuelle behov</li>
            <li>Der stilles få spørgsmål</li>
          </ul>
          <p><strong>Ulemper:</strong></p>
          <ul>
            <li>Du skal betale afdrag hver måned</li>
            <li>Der er risiko for høje gebyrer, hvis du misligholder dine afdrag</li>
          </ul>
          <p>
            På trods af, at du har fundet et billig privatlån, er det alligevel vigtigt at
            overveje nøje, hvorvidt det er den rette beslutning at optage et lån.
          </p>
          <p>
            Det er nemt at finde et lån, hvorfor man også nemt risikerer at lade sig lokke
            af
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >lånetilbuddet</a
            >, før man har skabt et tilstrækkeligt overblik over privatøkonomien. Det er
            yderst vigtigt, at du ikke gør dette, men derimod overvejer din beslutning
            meget nøje.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvor-stort-kan-laanet-blive">Hvor stort kan lånet blive?</h2>
          <p>
            De fleste kan blive godkendt til et privatlån med en rente på et retfærdigt
            niveau. Der er dog forskel på, hvor høje beløb man kan blive godkendt til.
          </p>
          <p>
            Langt de fleste ville kunne blive godkendt til fx et
            <a
              href="https://saverium.dk/laan-penge/kvilaan"
              rel="noopener noreferrer"
              target="_blank"
              >kviklån</a
            >
            (tidligere kendt som <strong>SMS lån </strong>og
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/mobillaan/"
              rel="noopener noreferrer"
              target="_blank"
              >mobillån</a
            >), eller et
            <a
              href="https://saverium.dk/laan-penge/minilaan"
              rel="noopener noreferrer"
              target="_blank"
              >minilån</a
            >. Det vil typisk være beløb på 3.000 - 10.000 kr.
          </p>
          <p>
            Ønsker du et højere
            <a
              href="https://saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              >lånebeløb</a
            >, vil der også blive stillet større krav til din kreditværdighed. Ofte vil du
            blive bedt om at give flere oplysninger om dig selv og din økonomi, når du
            ansøger om højere beløb. Det handler dog ikke om beløbet i sig selv, men
            derimod om, at långiverne ønsker at sikre, at du som låntager har råd til
            tilbagebetalingen.
          </p>
          <p>
            Risikoen for at du ikke kan tilbagebetale, kan du delvist imødekomme ved at
            justere på løbetiden på dit privatlån. En længere løbetid, vil give dig en
            lavere månedlig ydelse. Med en lavere månedlige ydelse på dit privatlån,
            sikrer ikke kun långiveren, at du har større sandsynlighed for at overholde
            dine terminer, men hjælper også på dit månedlige rådighedsbeløb (også kaldet
            “left to live”).
          </p>
          <h3>Lån aldrig mere, end du har råd til</h3>
          <p>
            “Left to live” er fastlagt af regeringen, som i bund og grund er til for at
            sikre, at du ikke kommer til at sidde med så dyrt et lån, at du ikke kan købe
            mad, betale din husleje eller købe tøj til dig selv og dine børn. Alt hvad der
            kan karakteriseres som luksus, kan godt barberes væk fra din hverdag, men
            basale fornødenheder kan ikke. Dette har især været et problem i de første år
            efter årtusindeskiftet for <strong>blankolån</strong> og
            <strong>kontantlån</strong>.
          </p>
          <p>
            I jagten på det billigste privatlån, er det let at komme til at overse de
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >bedste lånetilbud</a
            >. I dag kan et lån optages i løbet af få minutter, hvorfor mange desværre
            ender med at optage et lån uden at have afdækket markedet for bedre tilbud på
            privatlån.
          </p>
          <p>
            Før du giver dig i kast, er det en god ide at overveje din økonomi. Du behøver
            dog ikke at lave et udførligt budget, da långiveren har et ansvar for at
            sikre, at du kan betale terminerne.
          </p>
          <p>Hurtig udbetaling</p>
          <p>
            Vil du være garanteret en kort udbetalingstid, så er et online privatlån det
            rette valg, da adskillige låneudbydere har en udbetalingstid på få timer, alt
            afhængigt af dit ønskede lånebeløb.
          </p>
          <p>
            Har du brug for penge nu, er det derfor ikke din bank, du skal overveje.
            Derimod skal du blot udfylde vores ansøgningsformular, hvor du øjeblikkeligt
            vil modtage uforpligtende lånetilbud på privatlån fra vores
            samarbejdspartnere. Fælles for dem alle er, at de har en kort udbetalingstid.
          </p>
          <p>
            Er du eksempelvis stødt på et godt tilbud, eller har du modtaget en uforudset
            regning, der skal betales øjeblikkeligt, er det selvfølgelig relevant, at dit
            privatlån udbetales hurtigt. Derfor er dette også af høj prioritet hos de
            fleste
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >långivere</a
            >.
          </p>
          <p>
            Vær dog opmærksom på, at der ved større privatlån nogle gang kan følge en
            længere udbetalingstid. Dette hænger sammen med, at kreditvurderingen typisk
            er mere omfattende og ikke at udbetalingen i sig selv er langsommere. På de
            små privatlån på fx 10.000 eller 50.000 kr., vil der dog altid være en kort
            udbetalingstid. Alle låneudbydere understøtter dog
            <a
              href="https://saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >lån med MitID og NemID</a
            >, som får låneprocessen til at forløbe langt hurtigere end tidligere.
          </p>
        </div>

        <div class="text-container">
          <h2 id="privatlaan-uden-sikkerhed">Privatlån uden sikkerhed</h2>
          <p>
            Låner du penge på nettet i form af et privatlån, er du ikke påkrævet at stille
            sikkerhed for dit lån. Derfor er det ikke nødvendigt at have ejendele af stor
            værdi, der kan stilles som sikkerhed, hvilket typisk er tilfældet i
            forbindelse med et banklån.
          </p>
          <p>
            At du ikke skal stille sikkerhed for dit lån, er en fordel i sig selv, da du
            dermed ikke risikerer at må sælge dine værdier, der er stillet som sikkerhed.
          </p>
          <p>
            Der er også en anden årsag til, at privatlån uden sikkerhed er en fordel. Når
            du
            <a
              href="https://saverium.dk/laan-penge/privatlaan/uden-sikkerhed"
              rel="noopener noreferrer"
              target="_blank"
              >låner uden sikkerhed</a
            >
            skal du jo ikke stille sikkerhed for lånet, og derfor skal der ikke allokeres
            tid til at vurdere værdien af den eller de ejendele, som du er villig til at
            lade långiveren tage pant i.
          </p>
          <p>
            Det faktum, at du ikke er påkrævet at stille sikkerhed for dit privatlån, er
            derfor et afgørende aspekt af, at både behandlings- og udbetalingstiden er
            kort, hvilket, som tidligere angivet, også er en betydelig fordel ved
            <a
              href="https://saverium.dk/laan-penge/online-laan"
              rel="noopener noreferrer"
              target="_blank"
              >online lån</a
            >.
          </p>
          <p>
            Vær dog opmærksom på, at et lån uden sikkerhed ikke er ensbetydende med et
            <a
              href="https://saverium.dk/laan-penge/privatlaan/uden-sikkerhed"
              rel="noopener noreferrer"
              target="_blank"
              >lån uden kreditvurdering</a
            >. Der vil til hver en tid blive udført en kreditvurdering af dig som
            låntager, når du optager et lån. Dette gør sig gældende uanset om du optager
            et privatlån eller et andet lån.
          </p>
          <p>
            Det er lovpligtigt at kreditvurdere låntager, og derfor vil det ikke være
            muligt at omgå denne del af låneprocessen. Såfremt du eksempelvis ikke har en
            fast indkomst eller lignende, kan dette give et afslag. Du skal dog blot være
            taknemmelig for at du bliver kreditvurderet, da det sikrer dig mod, at du
            optager et privatlån, som du ikke har råd til.
          </p>
          <h3>Privatlån trods RKI</h3>
          <p>
            Ønsker du at finde et privatlån på trods af RKI-status, så skal du nok
            desværre lede længe. At låne penge privat uden at der foretages en
            kreditvurdering er faktisk ikke lovligt, og efter som et positivt opslag i RKI
            tæller meget ned i kreditvurderingen, vil det være meget svært at finde nogle
            låneudbydere, som vil gå med til at give et privatlån på trods af RKI. Hvis du
            står i RKI, vil vi hellere anbefale, at du får fat i en gældsrådgiver, som kan
            hjælpe dig med at få rettet op på din økonomi.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-af-privatpersoner">Lån af privatpersoner</h2>
          <p>
            Der er fordele og ulemper ved at lån af privatpersoner. Det er fx ikke
            unormalt, at forældre udlåner penge til deres børn. Her vil der typisk ikke
            være foretaget en omfattende kreditvurdering, og kravene for tilbagebetaling,
            uanset om du overholder dine terminer eller ej, vil nok også være noget mere
            lempelig, end hvad du vil opleve, hvis du optager et privatlån i en bank.
          </p>
          <p>
            Der er dog også ulemper at finde ved lån imellem privatpersoner. Hvis du låner
            af en fremmed person, vil du ikke være sikret på samme måde, som ved et
            almindeligt privatlån. Du vil ikke blive kreditvurderet, og hvis din kreditor
            er kynisk, kan du ende ud i en situation, hvor kreditoren kræver, at du
            betaler urimelige afdrag og renter på lånet fra privatpersonen.
          </p>
          <h3>Lån af privatpersoner trods RKI</h3>
          <p>
            Måske har du lige læst vores afsnit om privatlån trods RKI, men tænker, at det
            måske i stedet for er muligt at låne af privatpersoner trods RKI. Der findes
            mange metoder at låne penge på trods RKI, som enten ikke er lovlige, ligger i
            en gråzone eller er helt legitime. Fælles for dem alle er dog, at det meget
            sjældent er anbefalelsesværdigt. Står du i RKI og har du et familiemedlem, som
            kan hjælpe med <strong>finansiering</strong>, er dette både lovligt og muligt.
            Igen vil vi dog understrege, at det sjældent er en god løsning at
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >
            af private hvis man er i RKI.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
