export default {
  days: ['ma', 'ti', 'ke', 'to', 'pe', 'la', 'su'],
  months: [
    'Tammi',
    'Helmi',
    'Maalis',
    'Huhti',
    'Touko',
    'Kesä',
    'Heinä',
    'Elo',
    'Syys',
    'Loka',
    'Marras',
    'Joulu',
  ],
  pickers: [
    'seuraavat 7 päivää',
    'seuraavat 30 päivää',
    'edelliset 7 päivää',
    'edelliset 30 päivää',
  ],
  placeholder: {
    date: 'Valitse päivämäärä',
    dateRange: 'Valitse alue',
  },
}
