<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån fra Resurs Bank</h1>
      <div class="top-text text-container">
        <ul>
          <li>Lån mellem 10.000 – 300.000 kr.</li>
          <li>Låneafgørelse indenfor 24 timer.</li>
          <li>Lån uden sikkerhed.</li>
          <li>
            Underskriv hurtigt og enkelt dit
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >lån med NemID/MitID</a
            >.
          </li>
        </ul>
        <p>
          Resurs Bank blev grundlagt i 1977 i Sverige og kom til Danmark i 2014. De kan
          findes i både Norge, Sverige, Finland og Danmark med mere end 5.7 millioner
          kunder i Norden. Resurs Bank tilbyder
          <a
            href="https://www.saverium.dk/laan-penge/forbrugslaan"
            rel="noopener noreferrer"
            target="_blank"
            >billige forbrugslån</a
          >
          og
          <a
            href="https://www.saverium.dk/laan-penge/samlelaan"
            rel="noopener noreferrer"
            target="_blank"
            >samlelån med lav rente</a
          >
          fra 10.000 til 300.000 kroner til privatkunder.
        </p>
        <p>
          Igennem vores gratis online sammenligningstjeneste, kan du modtage et
          <a
            href="https://www.saverium.dk/laan-penge/beloeb"
            rel="noopener noreferrer"
            target="_blank"
            >lånetilbud</a
          >
          fra Resurs Bank, som du kan sammenligne med tilbud fra mere end 10 andre banker
          og velkendte
          <a
            href="https://www.saverium.dk/laan-penge/laaneudbydere"
            rel="noopener noreferrer"
            target="_blank"
            >låneudbydere</a
          >, hvilket kan skabe dig et overblik over det nuværende lånemarked. På den måde
          kan du være sikker på, du ikke optager et dyrt lån med høje renter, men i stedet
          vælge imellem flere
          <a
            href="https://www.saverium.dk/laan-penge"
            rel="noopener noreferrer"
            target="_blank"
            >billige lån</a
          >, der passer til din økonomi.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#rente-og-laanevilkaar-hos-resurs-bank"
                    >Rente og lånevilkår hos Resurs Bank</a
                  >
                </li>
                <li>
                  <a href="#saadan-ansoeger-du-om-et-laan-hos-resurs-bank"
                    >Sådan ansøger du om et lån hos Resurs Bank</a
                  >
                </li>
                <li><a href="#resurs-bank">Resurs Bank</a></li>
                <li>
                  <a href="#vilkaar-for-laan-hos-resurs-bank"
                    >Vilkår for lån hos Resurs Bank</a
                  >
                </li>
                <li>
                  <a href="#eksempel-paa-et-laan-hos-resurs-bank"
                    >Eksempel på et lån hos Resurs Bank</a
                  >
                </li>
                <li>
                  <a href="#resurs-banks-kundeservice">Resurs Banks kundeservice</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="rente-og-laanevilkaar-hos-resurs-bank">
            Rente og lånevilkår hos Resurs Bank
          </h2>
          <p><strong>Lånebeløb: </strong>10.000 - 300 000 kr.</p>
          <p><strong>Låneperiode: </strong>2 til 12 år</p>
          <p><strong>Variabel debitorrente: </strong>4,99% - 15,54%</p>
          <p>
            <strong>Andre udgifter: </strong>Mdl. Administrationsgebyr 0-49 kr.
            Etableringsgebyr 0-10%
          </p>
          <p>
            <strong>Behandlingstid: </strong>Svar indenfor 24 timer og pengene på din
            konto indenfor 1-3 hverdage
          </p>
          <p><strong>Medansøger: </strong>Ja</p>
          <p></p>
        </div>

        <div class="text-container">
          <h2 id="saadan-ansoeger-du-om-et-laan-hos-resurs-bank">
            Sådan ansøger du om et lån hos Resurs Bank
          </h2>
          <p>
            Udfyld din <strong>låneansøgning </strong>online på få minutter og
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenlign de lånetilbud</a
            >, som du modtager på din brugerkonto.
          </p>
          <ul>
            <li>
              <strong>Udfyld ansøgningen med det ønskede beløb og løbetid.</strong> Vælg
              beløbet du ønsker, og den låneperiode du vil have for dit
              <a
                href="https://www.saverium.dk/laan-penge"
                rel="noopener noreferrer"
                target="_blank"
                >nye lån</a
              >.
            </li>
            <li>
              <strong>Angiv dine andre oplysninger.</strong> Oplys i din ansøgning dine
              andre nødvendige oplysninger.
            </li>
            <li>
              <strong>Send ansøgning online</strong>. Send din udfyldte ansøgning. Vi
              indhenter straks de forskellige skræddersyet tilbud til dig, så du hurtig
              kan få svar på om du er godkendt.
            </li>
            <li>
              <strong>Accepter det ønskede tilbud</strong>. Sammenlign dine tilbud online
              på din brugerkonto, og accepter det tilbud der passer bedst til dig.
            </li>
            <li>
              <strong>Lånet vil blive udbetalt til din konto</strong>. Hvis du vælger et
              lån fra Resurs Bank, vil dit ønskede lån blive udbetalt til din bankkonto
              indenfor 1-3 hverdage.
            </li>
          </ul>
        </div>

        <div class="text-container">
          <h2 id="resurs-bank">Resurs Bank</h2>
          <p>
            Resurs Bank havde tilbage i 1977 fokus på finansiering af forbrugsgoder så som
            musikanlæg, <strong>fjernsyn</strong> og andre form for
            <strong>elektronik</strong>. I dag er det anderledes, siden Resurs Bank
            sidenhen har vokset sig en hel del større, og ændret deres filosofi til at
            skabe balance i menneskers hverdagsøkonomi. I dag tilbyder Resurs Bank
            låneprodukter i mange forskellige formater indenfor
            <strong>finansiering</strong> og lån, og der kommer deres mange års erfaring
            deres kunder gode.
          </p>
          <p>
            Du kan være sikker på at få tilbudt en god rente, når du vælger et tilbud hos
            Resurs Bank igennem vores online tjeneste. Det er lige meget om det er et
            <a
              href="https://www.saverium.dk/laan-penge/billaan"
              rel="noopener noreferrer"
              target="_blank"
              >billån uden sikkerhed</a
            >, som du har brug for til en ny bil, om du skal have renoveret
            <strong>køkken </strong>og <strong>badeværelse,</strong> eller om du vil bruge
            lånet til <strong>indskud til en lejlighed</strong>. Uanset hvad lånet skal
            bruges til, skal du ikke stille sikkerhed for lånet. Det vil sige, at Resurs
            Bank ikke behøver, at du skal stille nogen form for værdi til rådighed for at
            kunne få et lån hos dem
          </p>
          <p>
            Når du udfylder en ansøgning igennem vores online tjeneste, kan du få overblik
            over dine <strong>lånemuligheder</strong> ved at få tilbud fra mere end 10
            banker og andre udbydere af lån udover Resurs Bank. Det koster ingenting, og
            du forpligter dig ikke til at acceptere nogle af de forskellige tilbud, som du
            skulle modtage. Du kan indsende en gratis og uforpligtende ansøgning på få
            minutter online inde på vores hjemmeside, og du får straks svar, så du nemt
            kan sammenligne de forskellige tilbud.
          </p>
        </div>

        <div class="text-container">
          <h2 id="vilkaar-for-laan-hos-resurs-bank">Vilkår for lån hos Resurs Bank</h2>
          <p>
            For at kunne blive godkendt til et lån hos Resurs Bank, skal du leve op til
            disse 5 vilkår:
          </p>
          <ul>
            <li>Du skal have fast bopæl i Danmark.</li>
            <li>Du skal være fyldt 18 år.</li>
            <li>Du skal have et NemID/MitID.</li>
            <li>Du må du ikke være registreret i Debitor Registret eller RKI.</li>
            <li>Du skal have fast indkomst.</li>
          </ul>
          <p>
            Udover ovenstående, vil der blive foretaget en kreditvurdering af din
            privatøkonomi ved at du giver samtykke til eSKAT og kontooplyningstjenesten
            Monthio.
          </p>
          <p>
            Rentesatsen bliver herefter bestemt ud fra din indkomst, rådighedsbeløb og din
            samlede økonomi.
          </p>
        </div>

        <div class="text-container">
          <h2 id="eksempel-paa-et-laan-hos-resurs-bank">
            Eksempel på et lån hos Resurs Bank
          </h2>
          <p>
            <strong>Låneeksempel</strong>: Ved lån på 150.000 kr. over 12 år er den
            månedlige ydelse 1.916 kr. Variabel debitorrente er 10,25 % og ÅOP 11,89 %.
            Lånet er inklusivt et oprettelsegebyr på 5.250 kr. og et administrationgebyr
            på 29 kr. pr. md. De samlede kreditomkostninger er 125.945 kr. og der skal
            samlet tilbagebetales 275.945 kr.
          </p>
        </div>

        <div class="text-container">
          <h2 id="resurs-banks-kundeservice">Resurs Banks kundeservice</h2>
          <p>
            Resurs Bank kan kontaktes over telefon via deres kundeservice alle hverdage
            fra kl. 9 – 17:00. Ring på telefon: 39 13 16 00. Du kan også skrive til dem på
            deres e-mail adresse:
            <a href="mailto:mail@resurs.dk" rel="noopener noreferrer" target="_blank"
              >mail@resurs.dk</a
            >
          </p>
          <p>
            Skulle du have nogle spørgsmål vedrørende din ansøgning kan du kontakte os på
            hverdage mellem 09:00 - 15:00 på telefon: 93 75 99 59. Du kan os kontakte os
            på e-mail:
            <a
              href="mailto:kundeservice@saverium.dk"
              rel="noopener noreferrer"
              target="_blank"
              >kundeservice@saverium.dk</a
            >.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
