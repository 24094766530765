<template>
  <div class="card-body text-left">
    <CardInformation :offer="offer" :action="action" :currency="currency" />
    <div class="mb-3">
      <b-img
        class="raiffeisen-offer-img"
        src="/static/img/saverium_pl/raiffeisen-offer-img.webp"
      />
      <template v-if="offer.description">
        <CardFooter :offer="offer" />
      </template>
    </div>
  </div>
</template>
<script>
import CardInformation from '@/components/account_view_components/CardInformation.vue'
import CardFooter from '@/components/account_view_components/CardFooter.vue'
export default {
  props: ['offer', 'action', 'currency'],
  data() {
    return { toggle: false }
  },
  components: {
    CardInformation,
    CardFooter,
  },
}
</script>
<style lang="scss" scoped>
.btn-block {
  @media (max-width: 995px) {
    width: 100%;
    margin: auto;
  }
}

.btn-row {
  display: flex;
  height: 65px;
  justify-content: flex-end;
}
.raiffeisen-offer-img {
  position: absolute;
  right: 5vw;
  top: 5rem;
  height: 100%;
  max-height: 200px;
  @media (max-width: 1500px) {
    max-height: 150px;
  }
  @media (max-width: 770px) {
    position: relative;
    top: 0;
    max-height: 120px;
    right: 0;
    left: 0;
    display: block;
    margin: 0 auto;
  }
}
</style>
