<template>
  <div>
    <script type="application/javascript" id="salus-preform"></script>
    <script
      async
      type="application/javascript"
      src="https://salus.group/forms/salusgroup_forms_min.js?token=dk_1547006_frontpage_shortform"
    ></script>
  </div>
</template>

<script>
import { loanTimeOptions, loanAmounts } from '@/utils/saverium_dk/const'
import { mapState } from 'vuex'
import preform from '@/utils/saverium_dk/salus-preform.js'

export default {
  props: { variant: { type: String }, versionId: { type: String } },
  components: {},
  data() {
    return {
      current: 0,
    }
  },
  mounted() {
    this.setApplicationType('consumerloan')
    const referrer = Document.referrer
    // removed cookie handling for now, saved values will be set in footer
    if (referrer) {
      const url = new URL(referrer)
      this.pubid = url.searchParams.get('pubid')
      this.pub1 = url.searchParams.get('pubidsub1')
      this.pub2 = url.searchParams.get('pubidsub2')
      this.pub3 = url.searchParams.get('pubidsub3')
      this.pub4 = url.searchParams.get('pubidsub4')
      this.pub5 = url.searchParams.get('pubidsub5')
      this.pubid_reference = url.searchParams.get('pubid_reference')
      this.$store.commit('updateTrackers', {
        pubid: this.pubid,
        pub1: this.pub1,
        pub2: this.pub2,
        pub3: this.pub3,
        pub4: this.pub4,
        pub5: this.pub5,
        pubid_reference: this.pubid_reference,
      })
    }

    this.setFormData()
  },
  computed: {
    ...mapState({
      firstName: state => state.application.firstName,
      lastName: state => state.application.lastName,
      email: state => state.application.email,
      loanPurpose: state => state.application.loanPurpose,
      tos: state => state.application.tos,
    }),
    loanTimeOptions() {
      return loanTimeOptions
    },
    loanAmounts() {
      return loanAmounts
    },
    pubid: {
      get() {
        return this.$store.state.salusTrackers.pubid
      },
      set(pubid) {
        this.$store.state.salusTrackers.pubid = pubid
      },
    },
    pub1: {
      get() {
        return this.$store.state.salusTrackers.pub1
      },
      set(pub1) {
        this.$store.state.salusTrackers.pub1 = pub1
      },
    },
    pub2: {
      get() {
        return this.$store.state.salusTrackers.pub2
      },
      set(pub2) {
        this.$store.state.salusTrackers.pub2 = pub2
      },
    },
    pub3: {
      get() {
        return this.$store.state.salusTrackers.pub3
      },
      set(pub3) {
        this.$store.state.salusTrackers.pub3 = pub3
      },
    },
    pub4: {
      get() {
        return this.$store.state.salusTrackers.pub4
      },
      set(pub4) {
        this.$store.state.salusTrackers.pub4 = pub4
      },
    },
    pub5: {
      get() {
        return this.$store.state.salusTrackers.pub5
      },
      set(pub5) {
        this.$store.state.salusTrackers.pub5 = pub5
      },
    },
    pubid_reference: {
      get() {
        return this.$store.state.salusTrackers.pubid_reference
      },
      set(pubid_reference) {
        this.$store.state.salusTrackers.pubid_reference = pubid_reference
      },
    },
  },
  methods: {
    next() {
      this.$store.dispatch('checkErrors', this.current)
      this.$store.commit('setApplicationType', 'consumerloan')

      const fields = [this.firstName, this.lastName, this.email, this.loanPurpose]
      if (!fields.some(field => field === '') && this.tos !== 0) {
        this.$store.commit('setStepProgress', this.current + 1)
        this.$router.push(this.$root.rn.LoanViewSalus)
      }
    },
    setApplicationType(value) {
      this.$store.commit('setApplicationType', value)
    },
    setFormData() {
      const script = document.getElementById('salus-preform')
      const form = preform(
        this.pubid,
        this.pub1,
        this.pub2,
        this.pub3,
        this.pub4,
        this.pub5,
        this.pubid_reference
      )
      script.innerHTML = form
    },
  },
}
</script>
