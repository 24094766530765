<template>
  <div class="article-wrapper" data-type="1" data-language="pl">
    <div class="article row col-12">
      <h1 class="center-title">Czy można dostać kredyt mając kredyt hipoteczny?</h1>
      <div class="row col-12">
        <div class="article-container col-12 col-lg-8">
          <div class="text-container">
            <p>
              Kredyt hipoteczny często stanowi jedyny sposób na sfinansowanie zakupu
              mieszkania czy domu. Spłaca się go przez wiele lat, lecz w międzyczasie może
              pojawić się potrzeba zaciągnięcia kolejnego zobowiązania, z którego środki
              pieniężne przeznaczymy na dowolny cel. Czy można dostać
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-kalkulator-i-oferty-pozyczek-gotowkowych/"
                rel="noopener noreferrer"
                target="_blank"
                >kredyt gotówkowy</a
              >, spłacając kredyt hipoteczny?
            </p>
            <b-img-lazy
              width="700"
              height="466"
              blank-src="/static/img/saverium_pl/article-images/can-you-loan-with-mortgage-tiny.webp"
              blank-height="700px"
              blank-width="466px"
              class="article-img"
              src="/static/img/saverium_pl/article-images/can-you-loan-with-mortgage.webp"
              alt="Kredyt gotówkowy mając kredyt hipoteczny - jakie są warunki"
            />
            <div class="toc container">
              <h3>{{ $t('table_of_contents') }}</h3>
              <ul class="table-of-contents">
                <li>
                  <a
                    href="#kredyt-gotowkowy-przy-kredycie-hipotecznym-czy-w-ogole-jest-mozliwy"
                    >Kredyt gotówkowy przy kredycie hipotecznym - czy w ogóle jest
                    możliwy?</a
                  >
                </li>
                <li>
                  <a href="#jak-otrzymac-kredyt-gotowkowy-przy-kredycie-hipotecznym"
                    >Jak otrzymać kredyt gotówkowy przy kredycie hipotecznym?</a
                  >
                </li>
                <li>
                  <a href="#dobranie-kredytu-rozwiazanie-dla-wszystkich"
                    >Dobranie kredytu - rozwiązanie dla wszystkich?</a
                  >
                </li>
                <li>
                  <a href="#kiedy-nie-mozna-otrzymac-kredytu-gotowkowego"
                    >Kiedy nie można otrzymać kredytu gotówkowego?</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="text-container">
            <h2 id="kredyt-gotowkowy-przy-kredycie-hipotecznym-czy-w-ogole-jest-mozliwy">
              Kredyt gotówkowy przy kredycie hipotecznym - czy w ogóle jest możliwy?
            </h2>
            <p>
              Kredyt gotówkowy, w przeciwieństwie do kredytu hipotecznego, spłacony
              zostaje w krótszym okresie czasu, a pieniądze z niego pochodzące można
              przeznaczyć na dowolny cel. Zwykle osoby, które posiadają stosunkowo wysokie
              dochody, nie mają problemu z uzyskaniem
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-na-dowolny-cel/"
                rel="noopener noreferrer"
                target="_blank"
                >kredytu gotówkowego na dowolny cel</a
              >, jednak problem może pojawić się, jeśli wcześniej zaciągnęły już kredyt
              hipoteczny. Posiadanie jednego zobowiązania obniża
              <a
                href="https://www.saverium.pl/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/"
                rel="noopener noreferrer"
                target="_blank"
                >zdolność kredytową,</a
              >
              przez co uzyskanie kolejnego kredytu może być o wiele trudniejsze. Nie
              oznacza to jednak, że obecni kredytobiorcy w ogóle nie mają szans na kolejny
              kredyt. Wręcz przeciwnie - banki zachęcają ich do zaciągania kolejnych
              zobowiązań, często przygotowują odpowiednie oferty. Niemniej jednak należy
              we własnym zakresie zastanowić się, czy będziemy w stanie spłacić następne
              zobowiązanie, jednocześnie uszczuplając swój domowy budżet.
            </p>
          </div>

          <div class="text-container">
            <h2 id="jak-otrzymac-kredyt-gotowkowy-przy-kredycie-hipotecznym">
              Jak otrzymać kredyt gotówkowy przy kredycie hipotecznym?
            </h2>
            <p>
              Procedura kredytowania przy kredycie hipotecznym jest podobna jak w
              przypadku osób, które nie posiadają wobec banku żadnych zobowiązań. Należy
              bowiem wykazać się odpowiednią zdolnością kredytową, przy której ocenie bank
              bierze pod uwagę:
            </p>
            <ul>
              <li>wysokość dochodów,</li>
              <li>źródło dochodów,</li>
              <li>ilość osób pozostających na utrzymaniu kredytobiorcy,</li>
              <li>średnie, miesięczne wydatki gospodarstwa domowego.</li>
            </ul>
            <p>
              Bank koryguje ogólną zdolność kredytową klienta o kwotę zobowiązania, które
              obecnie spłaca - mowa tutaj oczywiście o kredycie hipotecznym. Dlatego też w
              przypadku kredytu gotówkowego, który dobierany jest do kredytu hipotecznego,
              wymagania są nieco bardziej surowe. Klient musi bowiem udowodnić, że będzie
              w stanie jednocześnie spłacać nie jedno, a dwa zobowiązania.
            </p>
          </div>

          <div class="text-container">
            <h2 id="dobranie-kredytu-rozwiazanie-dla-wszystkich">
              Dobranie kredytu - rozwiązanie dla wszystkich?
            </h2>
            <p>
              Dobranie kredytu gotówkowego do hipotecznego wydaje się dobrym rozwiązaniem,
              jeśli potrzebujemy środków pieniężnych na różnego typu wydatki, na przykład
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-na-remont-domu/"
                rel="noopener noreferrer"
                target="_blank"
                >kredyt gotówkowy na remont domu</a
              >. Z drugiej strony, nie jest to rozwiązanie dla każdego. Kwestia tego, czy
              mogą dobrać do kredytu hipotecznego kredyt gotówkowy, jest bardzo
              indywidualna. Banki surowo podchodzą do klientów, którzy mają już jakieś
              zobowiązanie finansowe. Ponadto, musimy być świadomi, że decydując się na
              kredyt gotówkowy, nasz miesięczny budżet ulegnie znaczącej redukcji.
              Oszacowanie kwot, jakie będzie trzeba przekazać na rzecz banku, jest
              stosunkowo trudne, jeśli mamy kredyt ze zmiennym oprocentowaniem. Wysokość
              raty zależy bowiem bezpośrednio od wysokości bazowych stóp procentowych,
              zatem w niestabilnych okresach nasze miesięczne zobowiązanie wobec banku
              może drastycznie wzrosnąć ( w przypadku podwyżki stóp procentowych). Dotyczy
              to zarówno kredytów hipotecznych, jak i gotówkowych.
            </p>
          </div>

          <div class="text-container">
            <h2 id="kiedy-nie-mozna-otrzymac-kredytu-gotowkowego">
              Kiedy nie można otrzymać kredytu gotówkowego?
            </h2>
            <p>
              Kredyt gotówkowy nie zostanie przyznany każdemu, kto złoży odpowiedni
              wniosek. Bardzo istotny jest bowiem fakt, czy poza zobowiązaniem o
              charakterze hipotecznym, klient nie posiada jeszcze jednego kredytu
              gotówkowego. O ile bowiem otrzymanie dodatkowego kredytu do kredytu
              hipotecznego jest możliwe, o tyle szansa na pozyskanie każdego kolejnego
              zobowiązania jest już niewielka. Rozwiązaniem może być zwiększenie kwoty
              kredytu gotówkowego, choć i to nie w każdym przypadku będzie możliwe. Każdy
              klient traktowany jest przez bank indywidualnie, dlatego trudno
              jednoznacznie odpowiedzieć na pytanie, kto i w jakich przypadkach otrzymany
              kredyt gotówkowy do kredytu hipotecznego. Nie jest prawdą, jakoby łatwiej
              było dostać kolejny kredyt w banku, w którym zaciągnęliśmy kredyt
              hipoteczny, choć sama procedura oceny zdolności kredytowej może wtedy
              przebiegać nieco szybciej, zwłaszcza jeśli w tej samej instytucji posiadamy
              konto osobiste.
            </p>
            <p><strong>Przeczytaj także inne nasze artykuły z tej tematyki:</strong></p>
            <ul>
              <li>
                <a
                  href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-z-wpisami-w-bazach/"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Kredyt gotówkowy z wpisami w bazach</a
                >
              </li>
              <li>
                <a
                  href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-bez-zdolnosci-kredytowej/"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Kredyt gotówkowy bez zdolności kredytowej</a
                >
              </li>
              <li>
                <a
                  href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-bez-zaswiadczen-online/"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Kredyt gotówkowy bez zaświadczeń online</a
                >
              </li>
              <li>
                <a
                  href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-na-oswiadczenie-online/"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Kredyt gotówkowy na oświadczenie online</a
                >
              </li>
              <li>
                <a
                  href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-na-dowod-bez-umowy-o-prace/"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Kredyt gotówkowy na dowód bez umowy o pracę</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="container container-widget col-12 col-lg-4">
          <h4>{{ $t('get_a_loan_easily') }}</h4>
          <LoanWidget class="small-range mt-4" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div style="margin: auto">
        <RelatedArticlesComponent />
      </div>
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
export default {
  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
