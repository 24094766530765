import { render, staticRenderFns } from "./FaqWidget.vue?vue&type=template&id=5fa5a1c1&scoped=true&"
var script = {}
import style0 from "./FaqWidget.vue?vue&type=style&index=0&id=5fa5a1c1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fa5a1c1",
  null
  
)

export default component.exports