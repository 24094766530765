// Field mappings
const FIELD_MAPPING = {
  amount: 'amount',
  first_name: 'firstName',
  last_name: 'lastName',
  ssn: 'ssn',
  address: 'address',
  city: 'city',
  zip_code: 'postcode',
  telephone_number: 'phone',
  email_address: 'email',
  product: 'product',
  iban: 'iban',
}

const composeChildQuote = application => {
  let body = {
    number_of_children: application.children.length,
  }
  return body
}

const composeEntrepreneurInsurance = application => {
  let body = {
    name: application.name,
    email_address: application.email,
    year: application.year,
    income: application.income,
    tos: application.income,
    tyel: application.workers,
    company: application.company,
    phone: application.phone,
    monthly_payment: application.payment,
  }
  return body
}

// Compose loan application body for API request
const composeApplicationBody = application => {
  let body = {
    brand_name: 'Saverium.fi',
    ssn: application.ssn,
    first_name: application.firstName,
    last_name: application.lastName,
    email_address: application.email,
    city: application.city,
    postcode: application.postcode,
    address: application.address,
    iban: application.iban,
    phone: application.phone,
    working_accepted: application.working,
    health_accepted: application.health,
    submit: application.submit,
    product: application.product,
  }
  return body
}
// Compose insurance quote application body

const composeQuoteBody = application => {
  let body = {
    main_insured_details: {
      first_name: application.firstName,
      last_name: application.lastName,
      identity_number: application.ssn,
    },

    dependants_details: application.children.map(c => ({
      first_name: c.firstName,
      last_name: c.lastName,
      identity_number: c.ssn,
    })),
    address_line_1: application.address,
    post_code: application.postcode,
    mobile_phone: application.phone,
    city: application.city,
    email_address: application.email,
    brand_slug: 'saverium.fi',
    quoted_amount: application.price,
    billing_frequency: application.billingFrequency,
    accepted_marketing_email: !!application.marketingEmail,
    accepted_marketing_sms: !!application.marketingSms,
  }

  return body
}

// Compose application data that will be stored from draft
const composeStoreApplication = application => {
  if (!application) {
    return {}
  }

  const storeApplication = {}
  // Compose key:value pairs for each field
  Object.keys(application).forEach(key => {
    if (Object.keys(FIELD_MAPPING).includes(key)) {
      storeApplication[FIELD_MAPPING[key]] = application[key]
    }
  })
  return storeApplication
}

export default {
  composeApplicationBody,
  composeStoreApplication,
  composeQuoteBody,
  composeChildQuote,
  composeEntrepreneurInsurance,
}
