<template>
  <div>
    <h4>{{ $t('loan_applications') }}</h4>
    <div class="list-group">
      <table class="offer-table">
        <thead>
          <tr>
            <th>{{ $t('application_id') }}</th>
            <th>{{ $t('loan_amount') }}</th>
            <th>{{ $t('application_date') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="list-group-item"
            @click="activateApp(app)"
            :class="getBtnColor(app.status, app)"
            :key="i"
            v-for="(app, i) in sortedApplications"
          >
            <td>#{{ app.id }}</td>
            <td>{{ app.loan_amount }} {{ currency }}</td>
            <td>{{ customForm(app.date) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { customFormatter } from '@/utils/utils.js'

export default {
  props: ['app', 'activeApp', 'currency', 'sortedApplications'],

  methods: {
    activateApp(app) {
      this.$emit('activate', app)
    },
    getBtnColor(status, app) {
      if (app.uuid === this.activeApp.uuid) {
        return 'active-dk'
      }
      if (status === 4) {
        return 'list-group-item-secondary'
      } else if (status === 5) {
        return 'list-group-item-info'
      } else {
        return ''
      }
    },
    customForm(date) {
      return customFormatter(date)
    },
  },
}
</script>
<style lang="scss" scoped>
.offer-table {
  display: table;
  width: 100%;
  border-collapse: collapse;

  th {
    font-weight: 400;
  }
  tr {
    display: table-row;
    border-radius: 5px;
    &:first-child {
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-bottom-right-radius: 5px;
    }
  }
  th,
  td {
    padding-right: 10px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.3rem;
  }
}
.list-group-item {
  cursor: pointer;
  background: none;
  padding-left: 0.75rem;
}
.list-group {
  @media all and (max-width: 991px) {
    max-height: 185px;
    overflow-y: auto;
    border: #05083c38;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;

    background: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0)),
      linear-gradient(to top, #fff, rgba(255, 255, 255, 0)),
      linear-gradient(to bottom, darken(#c0c0c0, 20%), rgba(255, 255, 255, 0)),
      linear-gradient(to top, darken(#d6d6d6, 20%), rgba(255, 255, 255, 0));

    background-position: 0 0, 0 100%, 0 20%, 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    @media (max-width: 380px) {
      background-size: 100% 5em, 100% 5em, 100% 3em, 100% 1em;
    }
    background-size: 100% 5em, 100% 5em, 100% 1.5em, 100% 1em;
    background-attachment: local, local, scroll, scroll;

    th {
      position: sticky;
      top: 0;
      background: white;
      z-index: 2;
    }
  }
}
.active-dk {
  background: #05083c;
  color: white;
}
</style>
