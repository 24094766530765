<template lang="html">
  <vue-numeric
    class="form-control"
    :class="error ? 'is-invalid' : ''"
    :currency="currency"
    :separator="separator"
    :value="value"
    @input="input"
    currency-symbol-position="suffix"
    :customEvent="customEvent"
    :placeholder="placeholder"
    @blur="commitValue"
    inputmode="numeric"
  ></vue-numeric>
</template>

<script>
import VueNumeric from 'vue-numeric'

export default {
  components: {
    'vue-numeric': VueNumeric,
  },
  props: ['value', 'error', 'currency', 'separator', 'placeholder', 'customEvent'],
  methods: {
    input(value) {
      if (value !== this.value) {
        this.$emit('input', value)
      }
    },
    commitValue() {
      if (this.value === 0 && this.customEvent === true) {
        this.$store.commit('tweakRequirements', { key: 'costOfLiving', value: 0 })
      }
    },
  },
}
</script>

<style lang="sass" scoped></style>
