<template>
  <div class="row article">
    <h1 class="center-title">Pożyczka na święta - szybka pożyczka świąteczna</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i>
              Święta Bożego Narodzenia są okazją do spotkań rodzinnych i z przyjaciółmi.
              Wspólnie zasiadamy przy stole wigilijnym, a później biesiadujemy tradycyjnie
              w czasie pierwszego i drugiego dnia świąt. To czas radości, związany z
              dawaniem innym prezentów. Na to wszystko potrzeba jednak pieniędzy. Możesz
              je pozyskać z zewnątrz, w ramach
              <b-link :to="$root.rn.MainView">chwilówek online</b-link>, czyli
              <b-link :to="$root.rn.OnlineNonbankLoans">pożyczek pozabankowych</b-link>
              lub kredytów gotówkowych zaciąganych w bankach.</i
            >
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/loan-for-christmas-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/loan-for-christmas.jpg"
            alt="Pożyczka na święta - szybka pożyczka świąteczna"
          />

          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            W roku 2020 Boże Narodzenie będzie miało niestety inny charakter niż
            dotychczas, a wszystko za sprawą pandemii. Wprowadzone przez rządzących
            obostrzenia sanitarne powodują, że wiele osób będzie zmuszonych pozostać w
            ścisłym, rodzinnym gronie na Wigilii. Nie będą podejmować gości z różnych
            stron Polski, a bliscy z zagranicy także nie przyjadą na uroczystą kolację 24
            grudnia.
          </p>
          <p>
            Miesiące pandemii spowodowały, że wielu Polaków deklaruje, iż przeznaczą
            mniejszą kwotę na organizację świąt i kupno prezentów dla bliskich, niż w
            ubiegłych latach. Wskazuje na to między innymi badanie firmy Deloitte z 2020
            roku. Respondenci pytani o to, ile chcą wydać na święta Bożego Narodzenia w
            2020 roku odpowiedzieli, że średnio będzie to 1318 zł, czyli o 30 proc. mniej
            niż w 2019 roku.
          </p>
          <p>
            Jeszcze większe różnice w wysokości planowanych wydatków widoczne są w
            przypadku Barometru Providenta związanego ze świętami. Ankieta przeprowadzona
            na niecałe 2 miesiące przed Bożym Narodzeniem pokazała, że Polacy planują
            przeznaczyć na zorganizowanie świąt dla siebie i bliskich średnio 744,39 zł, a
            w ubiegłym roku była to kwota o niemal 1000 zł wyższa. Co 10. przebadana osoba
            powiedziała też, że jeszcze nie wie, w jaki sposób sfinansuje wydatki
            świąteczne. Niewykluczone, że wielu potrzebna będzie pożyczka na święta 2020.
            Pamiętaj jednak, by dokładnie sprawdzić szczegóły danej oferty, by
            rzeczywiście skorzystać z promocyjnej propozycji.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Szybkie pożyczki na święta to nic innego jak oferty pozabankowe, które
            zaciągniesz w licznych firmach pożyczkowych. Firmy pozabankowe, tak jak banki,
            na kilka tygodni przed świętami promują dedykowane oferty, które pozwalają na
            pozyskanie pożyczki świątecznej czy też
            <b-link :to="$root.rn.CashLoan">kredytu gotówkowego</b-link> z podobnym
            przeznaczeniem. Nie oznacza to jednak, że są to zobowiązania celowe. Nie
            musisz wykazać pożyczkodawcy, że za środki pozyskane z chwilówki świątecznej
            kupiłeś produkty do przygotowania potraw wigilijnych czy prezenty dla
            najbliższych.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Pożyczka świąteczna jest klasyczną ofertą pozabankową, tak zwaną
            <b-link :to="$root.rn.OnlinePaydayLoans">chwilówką</b-link>, którą możesz
            zaciągnąć w stosunkowo krótkim czasie, nawet w ciągu 15 minut od chwili
            złożenia wniosku. Pożyczka tego rodzaju jest udzielana z zachowaniem
            uproszczonych wymogów formalnych. Nie musisz poddawać się skrupulatnej ocenie
            zdolności i historii kredytowania w BIK-u. Szybko złożysz wymagany wniosek, a
            po jego rozpatrzeniu i zweryfikowaniu twojej tożsamości otrzymasz pożyczki
            świąteczne na dowów online, bez konieczności nawiązywania bezpośredniego
            kontaktu z firmą.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Grupą klientów, do których w szczególności kierowana jest szybka chwilówka na
            święta są osoby chcące pozyskać pieniądze z wypłatą na konto bankowe w
            stosunkowo krótkim czasie, bez konieczności dopełniania wielu formalności, z
            jakimi zwykle ma się do czynienia przy kredytowaniu bankowym. Jeśli z różnych
            względów nie możesz zaciągnąć kredytu w banku, możesz postarać się o pożyczkę
            pozabankową – chwilówkę na święta. Zrobisz to nawet online, co sprzyja
            wygodzie pozyskiwania zewnętrznego finansowania wydatków świątecznych.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            Platforma pożyczkowa Saverium uprości Ci wybór świątecznej oferty. W jaki
            sposób zaciągana jest w Saverium pożyczka na święta? Wystarczy, że wypełnisz
            jeden wniosek, wpisując wysokość pożądanej kwoty oraz okres kredytowania wraz
            z danymi osobowymi, by rozpoczął się proces pozyskiwania różnych ofert na
            szybki kredyt na święta czy pożyczki świąteczne. Saverium współpracuje z
            różnymi firmami, dlatego po weryfikacji twojego wniosku może przedstawić ci
            kilka propozycji zaciągnięcia zobowiązania. Do niczego Cię one nie obligują –
            jeśli stwierdzisz, że jednak nie potrzebujesz pożyczki, nic nie będziesz
            musiał robić ani za nic płacić.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            Interesuje cię pożyczka świąteczna 2020? Przy przeglądaniu ofert,
            pozwalających ci na sfinansowanie zakupów świątecznych i nie tylko na to,
            przeczytaj, jak oprocentowana będzie takie zobowiązanie. Ważne są całkowite
            koszty kredytowania oraz wysokość RRSO – rocznej rzeczywistej stopy
            oprocentowania. Zdarza się, że szybka pożyczka na święta dla nowego klienta
            danej firmy może być udzielona bez dodatkowych kosztów, jako
            <b-link :to="$root.rn.FreeLoan">darmowa chwilówka</b-link>. To najbardziej
            atrakcyjna propozycja otrzymania takich środków, z której będziesz mógł
            skorzystać, ale tylko w ściśle określonych przypadkach.
          </p>
          <p>
            Jeśli wiesz, że nie będziesz w stanie, np. za miesiąc czy za dwa spłacić
            pełnej kwoty pożyczki, zastanów się nad możliwością zaciągnięcia
            <b-link :to="$root.rn.LoanInstallment">pożyczki online na raty</b-link> w
            firmie pozabankowej. Ułatwi ci to wywiązanie się ze zobowiązania finansowego w
            przyszłości. Sprawdź też, jaką prowizję pobiera firma pożyczkowa za taką
            pożyczkę.
          </p>
        </div>
        <div class="text-container">
          <h2 id="7">{{ contents[6].title }}</h2>
          <p>
            Możesz martwić się, czy pożyczka świąteczna udzielana za pośrednictwem
            platformy Saverium będzie dla ciebie całkowicie bezpieczna. W tym przypadku
            nie ma mowy o przesyłaniu danych wrażliwych, a tylko o weryfikacji tożsamości
            klienta. Jak już podkreślaliśmy, złożenie wniosku o szybką pożyczkę na święta
            w Saverium do niczego Cię nie zobowiązuje. Możesz po prostu przejrzeć oferty,
            a następnie skorzystać z jednej z nich lub pozostawić je bez odzewu.
          </p>
        </div>
        <div class="text-container">
          <h2 id="8">{{ contents[7].title }}</h2>
          <p>
            Pożyczki świąteczne na dowód są sposobem na błyskawiczne podreperowanie
            budżetu przeznaczonego na święta. Jeśli okaże się, że brakuje Ci pieniędzy na
            zakup przepysznych produktów do potraw bożonarodzeniowych czy na wymarzone
            prezenty dla dzieci albo dla innych osób bliskich, które chcesz obdarować na
            gwiazdkę, możesz zdecydować się na szybką pożyczkę na święta i uzyskać środki
            na dowolne cele. Dobrą ofertę na pożyczki świąteczne na dowód możesz otrzymać
            w krótkim czasie za pośrednictwem Saverium.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'Pożyczka na święta 2020',
          link: '#1',
        },
        {
          title: 'Szybka chwilówka na święta na dowolny cel',
          link: '#2',
        },
        {
          title: 'Czym jest pożyczka świąteczna?',
          link: '#3',
        },
        {
          title: 'Dla kogo są pożyczki na święta?',
          link: '#4',
        },
        {
          title: 'Jak wziąć szybką pożyczkę na święta z Saverium?',
          link: '#5',
        },
        {
          title: 'Pożyczka świąteczna 2020 - na co zwrócić uwagę?',
          link: '#6',
        },
        {
          title: 'Czy pożyczka świąteczna z Saverium jest bezpieczna?',
          link: '#7',
        },
        {
          title: 'Szybkie pożyczki na święta - podsumowanie',
          link: '#8',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped></style>
