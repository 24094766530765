<template>
  <div>
    <b-container class="d-block justify-content-center mt-5 mb-5">
      <h1>
        {{ $t('our_partners') }}
      </h1>

      <h5>
        {{ $t('you_can_get_more_information_about_partner') }}
      </h5>
      <div class="container logos-wrapper" id="logo-container">
        <b-row class="col-12 align-items-center logo-row">
          <div v-for="(partner, index) in ourPartners" :key="index">
            <OurPartnerComponent
              :imgSrc="partner.imgSrc"
              :title="partner.title"
              :mainText="partner.mainText"
              :modalNumber="partner.modalNumber"
              :number="partner.number"
            />
          </div>
        </b-row>
      </div>

      <h2>
        {{ $t('we_are_a_member_of') }}
      </h2>
      <div class="container logos-wrapper">
        <b-row class="col-12 align-items-center" style="padding: 20px">
          <b-col cols="12" sm="12" md="12" lg="6">
            <b-link href="https://zpf.pl/">
              <b-img
                class="logo-big"
                src="/static/img/saverium_pl/ZPF-logo.png"
                alt="ZPF"
              />
            </b-link>
          </b-col>
        </b-row>
        <b-row class="col-12 align-items-center" style="padding: 20px">
          <b-col cols="12" sm="12" md="12" lg="6">
            <b-img
              class="logo-big"
              src="/static/img/saverium_pl/zpf-zdp-cert-img.png"
              alt="Certyfikat audytu etycznego ZPF"
            />

            <p>
              <b-link :to="$root.rn.ZPFZDP" target="_blank">
                Nasz certyfikat audytu etycznego ZPF
              </b-link>
            </p>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import OurPartnerComponent from '@/components/OurPartnerComponent.vue'
import { ourPartners } from '@/utils/saverium_pl/partners.js'

export default {
  components: {
    OurPartnerComponent,
  },
  computed: {
    ourPartners() {
      return ourPartners
    },
  },
}
</script>

<style>
h5 {
  align-self: center;
}
.highlight {
  padding: 1rem;
  max-width: 210px;
  align-self: baseline;
}
.highlight:hover {
  background-color: #05083c1e;
  max-height: 210px;
  cursor: pointer;
}
.logo-modal-header {
  margin-right: 0;
  max-width: 150px;
  margin-top: 10px;
  max-height: 100px;
  margin-left: auto;
  display: block;
}

@media (max-width: 425px) {
  .logo-row {
    justify-content: center;
  }
}
h1,
h2 {
  font-size: 2rem;
}
</style>
