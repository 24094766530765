<template>
  <div class="main">
    <div class="row col-12">
      <div class="container col-12 col-md-6 col-lg-6 col-xl-8 mt-5">
        <h1>
          {{ $t('articles') }}
        </h1>

        <ArticleCardComponent />
      </div>
      <div class="container col-12 col-md-6 col-lg-6 col-xl-4 mt-5">
        <h2>{{ $t('newest_articles') }}</h2>
        <ArticlePreviewComponent />
        <div class="mt-5">
          <h2>{{ $t('all_articles') }}</h2>
          <ArticleListComponent />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArticleCardComponent from '@/components/ArticleCardComponent'
import ArticlePreviewComponent from '@/components/ArticlePreviewComponent'
import ArticleListComponent from '@/components/ArticleListComponent'
export default {
  components: {
    ArticlePreviewComponent,
    ArticleCardComponent,
    ArticleListComponent,
  },
}
</script>
<style lang="scss" scoped></style>
