<template>
  <div class="main">
    <div class="container">
      <div>
        <ul class="nav nav-pills justify-content-end mt-2">
          <li class="nav-item">
            <b-link class="logout" :to="$root.rn.LogoutView" v-if="isAuthenticated">
              {{ $t('logout') }}
            </b-link>
          </li>
        </ul>
      </div>

      <h3 class="main-title">{{ $t('all_completed_loan_applications') }}</h3>

      <p class="offer-info-text">
        {{ $t('open_your_loan_application_to_get_more_loan_offers') }}
      </p>
      <template v-if="this.$root.brand === 'saverium_dk'">
        <p>
          <i>{{ $t('customer_service_phone_text') }}</i>
        </p>
      </template>
      <template v-if="loading">
        <lottie-animation
          path="./static/animations/loading-lottie.json"
          :width="256"
          :height="110"
          class="loading-animation"
        />
      </template>
      <div class="row">
        <div class="col-12 col-lg-3 mb-3" v-if="showOlder" id="loanlist">
          <ApplicationListComponent
            :currency="currency"
            :sortedApplications="sortedApplications"
            v-on:activate="activateApp"
            :activeApp="activeApp"
          />
        </div>

        <div class="col-12 mb-2" v-if="!showOlder && newestApplication">
          <h5 v-if="hasPaidout || newestStatus === 5">
            {{ $t('the_loan_offer_you_selected_has_been_paid') }}
          </h5>
          <h5 v-else-if="newestStatus === 2 || newestStatus === 3 || newestStatus === 1">
            {{ $t('choose_one_of_the_pre_approved_offers') }}
          </h5>

          <div v-else-if="newestStatus === 4" class="alert alert-warning">
            <span>{{ $t('application_has_been_cancelled') }}</span>
            <p>
              {{ $t('your_application_has_been_cancelled') }}
            </p>
          </div>
          <small>{{ customForm(newestApplication.date) }}</small>
        </div>
        <div class="col-lg-9 col-12 offer-container" v-if="newestApplication">
          <div v-if="showOlder">
            <h4 class="offer-title">
              {{ $t('offers_for_application') }} #{{ activeApp.id }}
            </h4>
            <div v-if="activeApp.status === 4" class="alert alert-warning">
              <span>{{ $t('application_has_been_cancelled') }}</span>
              <p>
                {{ $t('your_application_has_been_cancelled') }}
              </p>
            </div>
          </div>

          <application
            :application="activeApp || newestApplication"
            :paidout="showall"
            ref="applicationComponent"
            :componentKey="componentKey"
          />

          <div
            v-if="hasPaidout && !showall"
            class="btn btn-block underline"
            @click="setShowAll(true)"
          >
            {{ $t('show_all_offers') }}
          </div>
        </div>

        <div
          class="col-lg-9 col-12 offer-container my-5"
          v-if="!showOlder && sortedApplications.length > 1"
        >
          <button
            class="btn btn-block btn-primary account-view-btn show-older"
            @click="btnShowOlder"
          >
            {{ $t('show_older_applications') }}
          </button>
        </div>
      </div>
      <div class="col-12 cancel-wrapper" v-if="cancellableLoan">
        <h5>{{ $t('cancelling_loan_application') }}</h5>

        <p>{{ $t('if_you_decide_to_cancel_application') }}</p>
        <div class="col-lg-9 col-12 offer-container my-5">
          <button
            class="btn btn-block btn-primary account-view-btn cancel-btn"
            @click="cancelApplication"
          >
            {{ $t('cancel_application') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Application from '@/components/ApplicationComponent.vue'
import ApplicationListComponent from '@/components/account_view_components/ApplicationListComponent.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import { customFormatter } from '@/utils/utils.js'
export default {
  components: {
    Application,
    ApplicationListComponent,
    'lottie-animation': LottieAnimation,
  },
  props: ['eskatSuccess', 'eskatFailure'],
  created() {
    const pathArray = window.location.pathname.split('/')
    this.eskatUuid = pathArray[2]
    if (this.eskatSuccess === true) {
      this.sendEskatSuccess()
    }
    if (this.eskatFailure === true) {
      this.sendEskatFailure()
    }
    // This does authentication in itself, as when the request fails the auth token is expired or not valid.
    this.fetchOffers()
    this.checkBasis()
    this.$store.commit('setShowall', false)
    this.hasPaidout = false
    if (this.isAuthenticated) {
      this.pollOffers()
      this.checkPaidout()
    }
  },
  watch: {
    isAuthenticated(newState, oldState) {
      this.checkBasis()
      // So we don't start polling right away in the event when the user is not signed in.
      if (newState && !this._interval) {
        this.pollOffers()
      }
      this.checkPaidout()
    },
  },
  data() {
    return {
      activeApp: this.newestApplication,
      showOlder: false,
      value: 5,
      animate: true,
      location: window.location.pathname.href,
      hasPaidout: false,
      filteredApplications: '',
      date: '',
      componentKey: 0,
    }
  },
  computed: {
    cancellableLoan() {
      if (this.activeApp?.status !== 4 && this.activeApp?.status !== 6) {
        return true
      } else if (!this.activeApp && this.newestStatus !== 4 && this.newestStatus !== 6) {
        return true
      } else {
        return false
      }
    },
    newestStatus() {
      return this.newestApplication.status
    },
    newestApplication() {
      if (this.sortedApplications) {
        return this.sortedApplications[0]
      }
      return this.$store.commit('setLoading', true)
    },
    sortedApplications() {
      return this.sortApplications(this.applicationList)
    },
    ...mapState({
      isAuthenticated: state =>
        state.oidcStore.oidcIsAuthenticated || state.user.loggedIn,
      loading: state => state.application.loading,
    }),
    applicationList: {
      get() {
        return this.$store.state.application.applicationList
      },
      set(applicationList) {
        this.$store.state.application.applicationList = applicationList
      },
    },
    offers: {
      get() {
        return this.$store.state.offers.offers
      },
      set(offers) {
        this.$store.state.offers.offers = offers
      },
    },
    showall: {
      get() {
        return this.$store.state.offers.showall
      },
      set(showall) {
        this.$store.state.offers.showall = showall
      },
    },
    newApplication: {
      get() {
        return this.$store.state.application.new_application
      },
      set(newApplication) {
        this.$store.state.application.new_application = newApplication
      },
    },
    isBasis: {
      get() {
        return this.$store.state.application.isBasis
      },
      set(isBasis) {
        this.$store.state.application.isBasis = isBasis
      },
    },
    eskatUrl: {
      get() {
        return this.$store.state.application.eskatUrl
      },
      set(eskatUrl) {
        this.$store.state.application.eskatUrl = eskatUrl
      },
    },
    eskatUuid: {
      get() {
        return this.$store.state.application.eskatUuid
      },
      set(eskatUuid) {
        this.$store.state.application.eskatUuid = eskatUuid
      },
    },
    currency() {
      if (this.$root.brand === 'saverium_pl') {
        return 'zł'
      } else if (this.$root.brand === 'saverium_dk') {
        return 'kr.'
      } else if (this.$root.brand === 'saverium_es') {
        return '€'
      } else {
        return ''
      }
    },
    destroyed() {
      return this.stopPolling()
    },
  },
  methods: {
    customForm(date) {
      return customFormatter(date)
    },

    activateApp(app) {
      this.checkBasis()
      const element = document.getElementById('loanlist')
      const offset = 120
      const bodyRect = document.body.getBoundingClientRect().top
      const elementRect = element.getBoundingClientRect().top
      const elementPosition = elementRect - bodyRect
      const offsetPosition = elementPosition - offset
      window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
      this.activeApp = app
      this.checkStatuses()
    },
    btnShowOlder() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.checkStatuses()
      this.showOlder = true
      this.checkPaidout()
    },
    checkStatuses() {
      this.hasPaidout = false
      this.$store.commit('setShowall', false)
      this.checkPaidout()
    },
    fetchOffers() {
      this.$store.dispatch('fetchApplicationList')
      this.applicationKey += 1
      const currentApp = this.activeApp || this.newestApplication
      if (currentApp) {
        const id = currentApp.id
        this.activeApp = this.applicationList?.find(app => {
          return app.id === id
        })
      }
    },
    pollOffers() {
      this._interval = setInterval(this.fetchOffers, 10 * 1000)
    },
    stopPolling() {
      clearInterval(this._interval)
      this._interval = undefined
    },
    sendEskatSuccess() {
      this.$store
        .dispatch('sendEskatSuccess', { eskatUuid: this.eskatUuid })
        .then(this.fetchOffers)
    },
    sendEskatFailure() {
      this.$store.dispatch('sendEskatFailure', { eskatUuid: this.eskatUuid })
    },

    checkBasis() {
      if (this.activeApp) {
        this.activeApp.offers.forEach(offer => {
          if (offer.action_required) {
            this.isBasis = true
            this.eskatUrl = offer.action_required
            this.eskatUuid = offer.uuid
          } else {
            this.isBasis = false
          }
        })
      } else if (this.newestApplication) {
        this.newestApplication.offers.forEach(offer => {
          if (offer.action_required) {
            this.isBasis = true
            this.eskatUrl = offer.action_required
            this.eskatUuid = offer.uuid
          } else {
            this.isBasis = false
          }
        })
      }
    },
    checkPaidout() {
      if (!this.activeApp) {
        this.activeApp = this.newestApplication
      }
      this.hasPaidout = this.activeApp?.offers?.some(offer => offer.status === 6)
    },

    setShowAll(value) {
      this.$store.commit('setShowall', value)
      this.$refs.applicationComponent.sortOffers(this.activeApp || this.newestApplication)
    },
    async cancelApplication() {
      if (!this.activeApp) {
        this.activeApp = this.newestApplication
      }
      await this.$store
        .dispatch('cancelApplication', { uuid: this.activeApp.uuid })
        .then(status => {
          if (status === 'cancelled') {
            status = 4
          }
          this.activeApp.status = status
        })
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    sortApplications(value) {
      try {
        return value.sort((a, b) => new Date(b.date) - new Date(a.date))
      } catch (e) {}
    },
  },
}
</script>

<style lang="scss" scoped>
.container > h3,
.container > p {
  @media screen and (max-width: 768px) {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.card-body p:last-child {
  margin-bottom: 0;
}

.loading-text {
  text-align: center;
}

.offer-container {
  @media (min-width: 992px) {
    padding-left: 10%;
  }
}

.main-title {
  margin-top: 0;
}
.offer-title {
  @media all and (max-width: 525px) {
    margin-top: 2rem;
  }
}
.offer-info-text {
  @media (max-width: 525px) {
    font-size: 12px;
  }
}
.underline {
  font-family: 'Montserrat';
  &:hover {
    text-decoration: underline;
  }
}
.logout {
  color: #05083c;
  @media all and (max-width: 735px) {
    display: none;
  }
}
</style>
