<template>
  <div class="row article">
    <h1 class="center-title">Pożyczka na oświadczenie – na czym polega?</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >Procedury kredytowe w instytucjach bankowych powodują, że nawet przez kilka
              czy kilkanaście dni niejednokrotnie możesz czekać na wydanie decyzji na
              złożony wniosek. Do tego wraz z tym wnioskiem musisz przekazać do banku
              zaświadczenie o wysokości uzyskiwanych dochodów. Jeśli nie chcesz tyle
              czekać, zainteresuj się ofertą kredytową na oświadczenie czy na
              <b-link :to="$root.rn.OnlineNonbankLoans">pożyczkę pozabankową</b-link>,
              którą w takim trybie bez trudu otrzymasz.
            </i>
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/loan-on-declaration-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/loan-on-declaration.jpg"
            alt="Pożyczka na oświadczenie – na czym polega?
"
          />

          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            Pożyczka na oświadczenie jest produktem finansowym, udzielanym głównie w
            firmach pozabankowych. Może mieć postać
            <b-link :to="$root.rn.MainView">chwilówki online</b-link>, przyznawanej
            bezpośrednio na konto bankowe. W przypadku pożyczki na oświadczenie nie musisz
            składać do wniosku zaświadczeń o wysokości swojego wynagrodzenia czy innych
            dokumentów dochodowych. Wystarczy, że wyrazisz oświadczenie, ile naprawdę
            zarabiasz. Na tej podstawie pożyczkodawca wyliczy Twoją
            <b-link :to="$root.rn.CreditWorthiness">zdolność kredytową</b-link> i rozważy
            Twój wniosek pożyczkowy.
          </p>
          <p>
            Oświadczenie ma postać deklaracji wysokości dochodów miesięcznych, które
            pozyskiwać możesz tak naprawdę z wielu różnych źródeł. Jako klient firmy
            pozabankowej udzielającej
            <b-link :to="$root.rn.OnlinePaydayLoans">chwilówki przez internet</b-link> bez
            zaświadczeń o zarobkach i dochodach, nie musisz przedstawiać pliku dokumentów,
            które będą potwierdzeniem wiarygodności Twoich zapewnień w tym zakresie.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Podobnym produktem do pożyczki na oświadczenie są kredyty na oświadczenie o
            dochodach, udzielane przez instytucje bankowe – banki i spółdzielcze kasy
            oszczędnościowo-kredytowe (SKOK). To
            <b-link :to="$root.rn.CashLoan">kredyty gotówkowe</b-link>, w przypadku
            których przy minimum formalności możesz otrzymać kredyt na oświadczenie, także
            online, czyli bez wychodzenia z domu.
          </p>
          <p>
            Nie zawsze kredyt online na oświadczenie rzeczywiście będzie Ci przyznany
            przez bank, bez zweryfikowania źródła uzyskiwanych dochodów. Bank, może na
            przykład zastrzec sobie prawo do weryfikacji deklaracji klienta, co wiąże się
            między innymi z potwierdzeniem zarobków u pracodawcy.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            W zależności od tego, czy chcesz zaciągnąć zobowiązanie finansowe w banku czy
            w firmie pożyczkowej, możesz skorzystać odpowiednio z kredytu lub pożyczki na
            oświadczenie o dochodach. Pożyczkę otrzymasz jednak szybciej, nawet w formie
            chwilówki przez internet, bez konieczności dopełniania wielu formalności.
            Natomiast bank ma najczęściej wyższe wymagania odnośnie swoich przyszłych
            klientów, ale i umożliwia zaciągnięcie kredytu na oświadczenie w wyższej
            kwocie.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            W pierwszej kolejności pożyczki na oświadczenie o dochodach adresowane są do
            klientów, którym zależy na czasie uzyskania pieniędzy z pożyczki, z
            bezpośrednią wypłatą pieniędzy na konto bankowe. Ten rodzaj pożyczki sprawdzi
            się także w przypadku osób, które pracują, ale nie na umowę o pracę tylko np.
            na umowę zlecenie lub o dzieło.
          </p>
          <h3>By móc zaciągnąć pożyczkę na oświadczenie, powinieneś:</h3>
          <ul>
            <li>
              Wypełnić i złożyć wniosek o pożyczkę wraz z oświadczeniem o wysokości
              dochodów miesięcznych.
            </li>
            <li>Mieć co najmniej 18 lat.</li>
            <li>Mieć pełną zdolność do czynności prawnych.</li>
            <li>
              Mieć konto bankowe założone na własne dane, te same, które wskazałeś we
              wniosku o chwilówkę.
            </li>
            <li>
              Mieć odpowiednio wysoką zdolność kredytową – szacowaną na podstawie twojego
              oświadczenia.
            </li>
            <li>
              Nie mieć negatywnych wpisów w Biurze Informacji Kredytowej czy w bazach
              innych biur informacji gospodarczych (BIG) działających w Polsce.
            </li>
          </ul>
          <p>
            Spełnienie takich warunków powoduje, że właściwie masz pewność, że zostanie Ci
            udzielona pożyczka na oświadczenie.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            Zastanawiasz się, w jaki sposób funkcjonują pożyczki gotówkowe na oświadczenie
            o dochodach? Są to zobowiązania finansowe w formie
            <b-link :to="$root.rn.LoansToAccount"
              >pożyczek przez internet na konto bankowe</b-link
            >. Przepustką do takiej pożyczki jest wypełnienie wniosku elektronicznego,
            wraz z deklaracją o wysokości Twoich łącznych dochodów miesięcznych. To
            podstawa, by firma, z którą chcesz nawiązać współpracę, była w stanie
            oszacować, czy rzeczywiście stać Cię na pożyczkę na oświadczenie. Jak się
            oblicza sumę, o którą możesz się ubiegać? Od kwoty wykazanych dochodów
            odliczane są koszty utrzymania jednej osoby w gospodarstwie domowym czy inne
            stałe, deklarowane przez Ciebie wydatki.
          </p>
          <p>
            Często też przeprowadzana jest weryfikacja wnioskodawcy w dostępnych dla firmy
            pożyczkowej bazach danych, jak BIG InfoMonitor czy KRD i ERIF. Pozwala to
            firmom na zminimalizowanie ryzyka ponoszonego w związku z udzieleniem Ci
            szybkiej pożyczki na oświadczenie.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            Jeśli zależy Ci na tym, by pożyczkę otrzymać jak najszybciej i nie masz czasu
            ani ochoty gromadzić dokumentów dochodowych, zwykle wymaganych przez banki,
            sięgnij po pożyczkę na oświadczenie. Możesz ją uzyskać nawet w ciągu
            kilkunastu minut od chwili złożenia wniosku. Zweryfikuj swoją tożsamość w
            wybranej firmie pozabankowej i ciesz się wypłatą pożyczki bezpośrednio na
            konto bankowe.
          </p>
        </div>
        <div class="text-container">
          <h2 id="7">{{ contents[6].title }}</h2>
          <p>
            Możesz zaoszczędzić sporo swojego cennego czasu i pozyskać wiele
            zindywidualizowanych ofert, dopasowanych do Twoich potrzeb, jeśli skorzystasz
            z platformy pożyczkowej Saverium. Wystarczy, że podasz wysokość pożądanej
            kwoty chwilówki pozabankowej, okres kredytowania, cel pożyczki i podstawowe
            dane, aby Saverium skierowało Twoje zapytanie do swoich partnerów –
            sprawdzonych firm – oraz wysłało do Ciebie zestawienie najlepszych ofert
            chwilówek na oświadczenie, z jakich możesz, choć nie musisz skorzystać. Za
            pomocą jednego wniosku zapoznasz się z aktualnymi ofertami kilku czy
            kilkunastu pożyczkodawców, co ułatwi Ci wybór tej propozycji, która odpowiada
            Twoim oczekiwaniom. Skorzystaj z naszej platformy, dzięki której jesteś w
            stanie wziąć
            <b-link :to="$root.rn.LoansInFifteenMinutes"
              >pożyczkę online w 15 minut</b-link
            >, w prosty sposób, bez wychodzenia z domu.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'Co to jest pożyczka na oświadczenie?',
          link: '#1',
        },
        {
          title: 'Co to jest kredyt na oświadczenie?',
          link: '#2',
        },
        {
          title: 'Pożyczka czy kredyt na oświadczenie?',
          link: '#3',
        },
        {
          title: 'Do kogo kierowane są pożyczki na oświadczenie?',
          link: '#4',
        },
        {
          title: 'Jak działają pożyczki na oświadczenie o dochodach?',
          link: '#5',
        },
        {
          title: 'Czy warto wziąć pożyczkę na oświadczenie?',
          link: '#6',
        },
        {
          title: 'Weź pożyczkę za pośrednictwem Saverium',
          link: '#7',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped></style>
