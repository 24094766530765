import settings from '@/settings/settings'

// This is used when importing router from somewhere else than main.js

// After init 'settings.BRAND' should be static so this should be fine
let router = require('./' + settings.BRAND).default
let routes = require('./' + settings.BRAND + '/routes')
router.routes = routes.routes[settings.LOCALE]

export default router
