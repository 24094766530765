<template>
  <div class="row article non-landing">
    <div class="mb-5 col-xl-8 col-12">
      <h1 class="center-title">
        Kreditværdighed - Disse 5 ting påvirker, om du får et lån
      </h1>
      <div class="text-container">
        <p>
          <i
            >Påvirker din kreditværdighed din låneansøgning? Læs her 5 grunde til, at du
            muligvis ikke får et lån fra en bank og hvordan du kan ordne det.</i
          >
        </p>

        <b-img-lazy
          width="700"
          height="466"
          blank-src="/static/img/saverium_dk/article-imgs/creditworthiness-tiny.jpg"
          blank-height="700px"
          blank-width="466px"
          class="article-img"
          src="/static/img/saverium_dk/article-imgs/creditworthiness.jpg"
          alt="Zdolność kredytowa – czym jest? Jak sprawdzić i poprawić zdolność kredytową?"
        />
        <p>
          Er din kreditvurdering god nok? Har du forsøgt at ansøge om et lån fra en bank,
          men din ansøgning blev ikke godkendt? Banker er altid forpligtet til at finde ud
          af om ansøgerens kreditværdighed, inden de tilbyder lånet. Hvis
          kreditværdigheden ikke er god nok, kan banken ikke yde lånet til ansøgeren.
          Dette er et forsøg på at forhindre folk i at blive stå inde med alt for meget
          gæld og fremme en bedre økonomi.
        </p>
        <p>
          Hvordan vurderes kreditværdigheden så? Vurderingen består af mange forskellige
          faktorer, hvoraf den vigtigste er ansøgerens forhold mellem indkomst og udgift.
          Banker kontrollerer altid ansøgerens kreditværdighed, når de modtager en
          låneansøgning. Der er flere forskellige måder at kontrollere kreditværdighed på,
          men alle disse bestemmes af
          <a href="https://eba.europa.eu/" target="_blank" rel="noopener"
            >Den Europæiske Banktilsynsmyndighed (EBA)</a
          >
        </p>
        <p>De vigtigste faktorer til bestemmelse af kreditværdighed er</p>
        <ul>
          <li>
            <b>Indtægtsopgørelse.</b> Har ansøgeren en tilstrækkelig stor og regelmæssig
            indkomst.
          </li>
          <li>
            <b>Nøjagtighed af oplysninger.</b> Er ansøgerens oplysninger korrekte. Dette
            kan f.eks. Kontrolleres ved hjælp af bilag.
          </li>
          <li><b>Evne til at tilbagebetale.</b> Kan ansøgeren tilbagebetale lånet?</li>
        </ul>
      </div>
      <div class="text-container">
        <h2>Disse 5 ting påvirker din kreditværdighed</h2>
        <p>
          Kreditværdighed påvirkes af flere forskellige ting. Disse 5 ting påvirker ofte,
          om du får et lån, og om din kreditværdighed er god nok.
        </p>
        <ol>
          <li>
            <b>Omsætning - udgiftsforhold.</b> Hvis udgifterne er højere end indkomsten,
            vil dette påvirke kreditværdigheden negativt. Dette er en af de vigtigste
            ting, som banker kontrollerer.
          </li>
          <li>
            <b>Kreditoplysninger.</b> forkerte kreditoplysninger forringer
            kreditværdigheden og gør det vanskeligere at få et lån.
          </li>
          <li>
            <b>Fast ansættelse.</b> I bankens øjne er et mere permanent ansættelsesforhold
            altid bedre end et tidsbegrænset forhold.
          </li>
          <li>
            <b>Forbeholdt gæld.</b> Hvis du allerede har flere forskellige højrentelån
            eller flere kreditkort, der er brugt indtil nålen, er din kreditværdighed ikke
            så god.
          </li>
          <li>
            <b>Du betaler dine regninger sent.</b> Sent betalte fakturaer forringer også
            kreditværdigheden.
          </li>
        </ol>
      </div>
      <div class="text-container">
        <h2>Hvordan får jeg et lån? - Sådan kan du påvirke din kreditværdighed</h2>
        <p>
          Det vigtigste er at tage sig af din egen økonomi, selvom du har et stramt job.
          Udgifterne bør aldrig overstige indtægtsbeløbet. Hvis din indkomst pludselig
          falder på grund af kan du muligvis også skære udgifter, medmindre du har en
          anden indtægtskilde.
        </p>
        <p>
          En af de nemmeste måder at tage sig af sin økonomi er at kombinere alle dine små
          og højrentelån til et lån med lavere renter. Tag ikke flere små lån, men
          kombiner dine tidligere lån sammen, og spar både månedlige tilbagebetalinger af
          lån og omkostninger. Sørg også for at betale dine regninger til tiden, så dette
          ikke påvirker din kreditværdighed negativt. Hvis du ikke kan betale fakturaen
          inden forfaldsdatoen, skal du kontakte betalingsmodtageren.
        </p>
      </div>
      <div class="text-container">
        <h2>Saverium hjælper dig med at spare i hverdagen</h2>
        <p>
          Målet med Saverium er at hjælpe folk med at træffe bedre økonomiske
          beslutninger. Vi
          <b-link :to="$root.rn.LoanView"
            >sammenligner lånetilbud fra flere banker og långivere med kun en
            ansøgning</b-link
          >. Du kan ansøge om et lån på 3000 - 500.000 kroner efter dine egne behov med en
          låneperiode på 1-15 år.
        </p>
        <p>2020-12-22/Søren</p>
      </div>
    </div>
    <div class="col-xl-4 col-12">
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
  </div>
</template>
<script>
import LoanWidget from '@/components/saverium_dk/seo_components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'

export default {
  components: {
    LoanWidget,
  },
  data() {
    return {
      visible: false,
    }
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss" scoped></style>
