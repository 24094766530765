<template lang="html">
  <svg viewBox="0 0 100 9">
    <g
      v-for="step in _steps"
      :transform="`translate(${(100 / _steps) * step - 100 / _steps / 2} 0) scale(1)`"
      @click="circleClick(step)"
      class="circle"
      :class="step <= max ? 'pointer' : ''"
    >
      <circle
        cy="50%"
        r="3"
        :class="{ larger: hasError(step) }"
        :fill="circleFill(step)"
      />
      <text y="5.85" class="text" text-anchor="middle">{{ step }}</text>
      <text y="0.8" class="title" text-anchor="middle">
        {{ $t(titles[step - 1]) }}
      </text>
    </g>
    <g
      v-for="step in _steps - 1"
      :transform="`translate(${(100 / _steps) * step - 100 / _steps / 2} 0)`"
    >
      <rect
        :x="100 / _steps / 2 - 100 / _steps / 4"
        y="4.2"
        :width="100 / _steps / 2"
        height="0.2"
        fill="grey"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: ['steps', 'errors'],
  data() {
    return {
      blue: '#007bff',
      red: '#dc3545',
      green: '#28a745',
      grey: '#6c757d',
      titles: [
        'loan_details',
        'personal_information',
        'address_and_contact_information',
        'employment',
        'credit_obligations_and_other_debts',
        'bank_details',
        'consents',
      ],
    }
  },
  computed: {
    _steps() {
      return parseInt(this.steps, 10)
    },
    current() {
      return this.$store.state.application.currentStep + 1
    },
    max() {
      return this.$store.state.application.maxStep + 1
    },
  },
  methods: {
    hasError(step) {
      return this.errors.includes(step - 1)
    },
    circleFill(step) {
      if (step > this.max) {
        return this.grey
      } else if (step === this.current) {
        return this.blue
      } else if (this.hasError(step)) {
        return this.red
      } else if (step <= this.max) {
        return this.green
      } else {
        return this.grey
      }
    },
    circleClick(step) {
      // if (step !== this.current) {
      if (step <= this.max && step !== this.current) {
        this.$store.commit('setStepProgress', step - 1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.text {
  font: bold 4px 'Montserrat';
  fill: white;
}
.title {
  font: bold 1px 'Montserrat';
  fill: black;
  display: none;
}

.larger {
  animation: bounce 1s linear 6;
  animation-direction: alternate;
}

@keyframes bounce {
  from {
    r: 3;
  }
  to {
    r: 3.5;
  }
}

.pointer {
  cursor: pointer;
}
.circle:hover > .title {
  @media screen and (min-width: 768px) {
    display: inline;
  }
}
</style>
