const fi = []

/**
 * Insert txt inside a heading tag of spesified number
 * @param {Number} size
 * @param {String} text
 */
const h = (size, text) => {
  return `<h${size}>${text}</h${size}>`
}

/**
 * Put string inside the paragraph
 * @param {String} text
 */
const p = (text) => {
  return '<p>' + text + '</p>'
}

/**
 * Function for adding a strong tag around text
 * @param {String} text
 */
const bold = (text) => {
  return `<strong>${text}</strong>`
}

/**
 * Function for adding a linebreak at the end
 * @param {String} text
 */
const nl = (text) => {
  return text + '<br />'
}

fi[0] = h(2, 'LainaaYritykselle.fi Käyttöehdot')
fi[1] = h(3, '1 § Yleistä')
fi[2] = p('Nämä käyttöehdot ohjaavat LVS Brokers Oy:n (“LVS Brokers” tai “Me”) ylläpitämää yritysluottojen lainanvälityspalvelua palvelua sekä LainaaYritykselle.fi-verkkosivua (sekä verkkosivu että itse palvelu LainaaYritykselle.fi).')
fi[3] = p('Vierailemalla sivustolla ja käyttämällä palvelua LainaaYritykselle.fi sinä (”sinä” tai “käyttäjä” tai “lainanhakija”) hyväksyt nämä käyttöehdot sekä henkilötietojesi käsittelyn <a href="https://www.omalaina.fi/rekisteriseloste/" target="_blank">Tietosuojaselosteemme</a> mukaisesti.')
fi[4] = p('LVS Brokers on itsenäinen lainanvälittäjä, eikä sitä ole valtuutettu edustamaan tai hyväksymään lainasopimuksia luotonantajien puolesta. Huomioi, että LVS Brokers voi toimia ainoastaan välikätenä yrityksen ja luotonantajan välillä, eikä se itse myönnä lainaa tai tee päätöksiä lainoihin tai lainahakemusten hyväksymiseen liittyen.')
fi[5] = p('Huomaa, että nämä käyttöehdot koskevat vain LainaaYritykselle.fi-sivustoa, eivätkä niitä luotonantajia, jotka myöntävät lainaa.')
fi[6] = h(3, '2 § LainaaYritykselle.fin käyttö')
fi[7] = p('Käyttämällä LainaaYritykselle.fi:n palveluja lainanhaussa annat LVS Brokersille luvan pyytää yrityksesi ja sinun puolestasi yhtä tai useampaa lainatarjousta pankeilta ja luotonantajilta, jotka tekevät yhteistyötä LVS Brokersin kanssa. Useimmat luotonantajat ovat valtuutettuja ja valvottuja Suomen Finanssivalvonnan tai Aluehallintoviraston toimesta. Katso sivustot www.avi.fi ja www.finanssivalvonta.fi saadaksesi lisää tietoa.')
fi[8] = p('LainaaYritykselle.fi:n käyttäminen on täysin maksutonta lainanhakijoille.')
fi[9] = p('Lainanhakijan on pidettävä huoli, että hänen käyttäjänimensä, salasanansa ja muu annettu tieto LainaaYritykselle.fin sivuille rekisteröinnin yhteydessä pidetään salassa ja säilötään turvallisesti ja ilman, että kolmasosapuoli pääsee niihin käsiksi.')
fi[10] = p('Jos herää epäilys, että kolmas osapuoli on päässyt luvatta käsiksi käyttäjänimeen ja/tai salasanaan, lainanhakijan on heti informoitava LVS Brokersia ja vaihdettava käyttäjänimensä ja/tai salasanansa. Lainanhakija on vastuussa omien tunnustensa käytöstä LainaaYritykselle.fi:n sivulla ja palvelussa sekä hänen käyttäjänimestään ja/tai salasanasta, jotka hänelle on annettu.')
fi[11] = h(3, '3 § Lainahakemukset')
fi[12] = p('Lainanhakijan on pidettävä huoli siitä, että kaikki LVS Brokersin lainahakemuksessa annetut tiedot ovat oikein ja pitävät paikkansa.')
fi[13] = p('Lainanhakijan vastuulla on informoida meitä kaikista muutoksista, joita hänen ja yrityksensä tietoihinsa mahdollisesti tulee.')
fi[14] = p('Lainanhakijan on ymmärrettävä, että hänen henkilökohtaiset tietonsa, kuten yhteystiedot, taloudelliset tiedot ja tiedot aikaisemmista lainoista ja luotoista voidaan kysyä kolmannen osapuolen rekistereistä (Esim Bisnode Oy), jotka ovat saatavilla luotonantajille ja LVS Brokers Oy:lle, jotta voidaan arvioida ja vahvistaa lainahakemukset, jotka palvelun kautta lähetetään.')
fi[15] = p('LVS Brokers vastaanottaa ja vahvistaa sinun lainahakemuksesi ja välittää sen eteenpäin yhdelle tai usealle luotonantajalle, jolloin luotonantaja voi tarjota yrityksellesi lainatarjousta. Annat suostumuksesi ja ymmärryksesi siitä, että luotonantajat käsittelevät hakemuksessa olevat tiedot, jotta he voivat arvioida tekemäsi hakemuksen.')
fi[16] = p('Lainaehdot (mukaan lukien lainamäärä, korko, kulut ja lainanlyhennys) määrittelee yksinomaan luotonantaja. Lainanhakija hyväksyy luotonantajan lainaehdot samalla, kun hän hyväksyy lainatarjouksen luotonantajalta. LVS Brokers Oy ei ole osapuoli lainasopimuksissa, eikä näin pysty vaikuttamaan lainasopimusten sisältöön.')
fi[17] = p('Käyttämällä LainaaYritykselle.fi:n palveluja, annat suostumuksen meille saada tietoa lainahakemusta koskevista päätöksistä ja lainatarjouksista, jos lainasopimus solmitaan lainanhakijan ja luotonantajan välillä. Lainanhakija hyväksyy, että luotonantajan päätös voidaan lähettää verkossa suoraan siihen sähköpostiin, jonka lainanhakija on lainahakemuksessaan antanut.')
fi[18] = p('Huomaa, että joko me tai luotonantajat voivat oman harkintansa perusteella hylätä hakemuksesi. Luotonantaja voi myös tarjota yrityksellesi lainaa, joka eroaa siitä lainasta, jota olet lainahakemuksessa hakenut. Kumpikaan me tai luotonantaja eivät voi taata sitä, että sinulle myönnetään laina. Voit vapaasti hyväksyä tai hylätä lainatarjoukset, joita saat luotonantajilta.')
fi[19] = p('Kun haet lainaa, muista vertailla ja arvioida yrityksesi nykyiset tulot ja menot, jotta pystyt laskemaan yrityksellesi realistisen kuukausittaisen lainanlyhennyksen. Henkilökohtaisten asumiskulujesi arvioimiseen voit käyttää ohjeita, jotka on julkaissut Kilpailu- ja kuluttajavirasto, osoitteessa: www.kkv.fi.')
fi[20] = h(3, '4 § LainaaYritykselle.fin toiminta & luvallisuus')
fi[21] = p('Huomaa, että LainaaYritykselle.fi:n toiminnassa voi esiintyä katkoksia, esimerkiksi tietokatkon, internetyhteyden katkeamisen tai kolmannen osapuolen palvelukatkojen seurauksena.')
fi[22] = p('LainaaYritykselle.fi:n palveluja ei ole luvallista käyttää laittomaan toimintaan.')
fi[23] = p('LVS Brokers voi tarpeen vaatiessa estää lainanhakijan pääsyn LainaaYritykselle.fi sivulle ilman erillistä ilmoitusta, jos LVS Brokers epäilee lainanhakijan käyttävän LainaaYritykselle.fi:n palveluja käyttöehtojen vastaisesti.')
fi[24] = h(3, '5 § Aineettomat oikeudet')
fi[25] = p('Kaikki aineettomat oikeudet, jotka kuuluvat LainaaYritykselle.fi:hin ja tähän liittyvät dokumentit ja näiden eri osat ja kopiot ovat kiistattomasti ainoastaan LVS Brokersin ja tämän alihankkijoiden ja lisenssinhaltioiden omaisuutta. Aineettomilla oikeuksilla tarkoitetaan tekijänoikeussuojaa ja tähän liittyviä oikeuksia (mukaan lukien tietokanta ja katalogioikeudet, ja valokuvien oikeudet), patentit, hyötyohjelmat, tavaramerkit, designoikeudet, tuotemerkit, tuotenimet, liikesalaisuudet, tieto-taito sekä kaikki muut muodot rekisteröidyistä ja rekisteröimättömistä aineettomista oikeuksista.')
fi[26] = p('Nämä käyttöehdot eivät anna sinulle (lainanhakija) tai yrityksellesi aineettomia oikeuksia LainaaYritykselle.fi:n omaisuuteen ja kaikki oikeudet, joita tässä ei erikseen hakijalle myönnetä, ovat tiukasti LVS Brokersin ja sen alihankkijoiden sekä lisenssinhaltioiden omaisuutta.')
fi[27] = h(3, '6 § Vastuuvapaus')
fi[28] = p('LainaaYritykselle.fi:n palvelut ovat sinulle täysin ilmaisia. LVS Brokers voi milloin tahansa oman harkintansa mukaan lakkauttaa tai keskeyttää LainaaYritykselle.fi:n toiminnan tai se voi vapaasti muokata ja päivittää palvelua. Emme ole vastuussa mahdollisista haitoista, jotka syntyvät tällaisten katkojen tai lakkautusten takia.')
fi[29] = p('LVS Brokers toimii välikätenä sinun ja luotonantajien välillä. Me emme ole vastuussa mahdollisista haitoista tai vahingoista, jotka johtuvat luotonantajien toiminnasta ja päätöksistä tai heidän myöntämistään lainoistaan.')
fi[30] = h(3, '7 § Irtisanominen')
fi[31] = p('Lainanhakija voi milloin tahansa irtisanoa tämän sopimuksen lähettämällä tästä ilmoituksen LVS Brokers Oy:n asiakaspalveluun (yhteystiedot alhaalla).')
fi[32] = p('Selkeyden vuoksi kerrottakoon, että tämä osio koskee vain käyttöehtojen irtisanomista LVS Brokersin ja lainanhakijan välillä. Kaikki lainat tai lainasopimukset hakijan ja luotonantajan välillä voidaan irtisanoa vain näiden omien lainasopimusten ehtojen perusteella.')
fi[33] = p('Ota yhteyttä luotonantajaan, jos haluat perua lainasopimuksen. Huomaa, että luotonantajan on lain mukaan annettava sinulle tietoa lainasopimuksen irtisanomisesta niin kuin myös tällaisen irtisanomisen seurauksista ja maksuista.')
fi[34] = h(3, '9 § Muut ehdot')
fi[35] = h(4, 'Muutokset')
fi[36] = p('LVS Brokers on oikeutettu muuttamaan ja/tai korjaamaan näitä käyttöehtoja antamalla käyttäjille tästä ilmoituksen vähintään 30 päivää ennen joko LainaaYritykselle.fi verkkosivulla tai sähköpostin (jos saatavilla) kautta. Palvelun käytön jatkaminen tämän ilmoituksen jälkeen nähdään käyttäjän myöntymisenä uusiin ehtoihin.')
fi[37] = h(4, 'Toimeksianto')
fi[38] = p('LVS Brokers on oikeutettu siirtämään kaikki tai osa sen käyttöoikeuksista tai velvoitteista osittain tai kokonaan ilman käyttäjien aikaisempaa suostumusta tytäryhtiölle tai seuraajalle, tai mahdolliselle liiketoiminnan ostajalle LainaaYritykselle.fi liittyen.')
fi[39] = h(4, 'Ylivoimaiset esteet')
fi[40] = p('Kumpikaan osapuoli, me tai lainanhakija, ei ole vastuussa mahdollisista haitoista näihin käyttöehtoihin liittyen, jotka johtuvat syistä, joihin kumpikaan osapuoli itse ei pysty vaikuttamaan mukaan lukien muun muassa sodan, kansannousun, kapinan tai muun kansallisen tottelemattomuuden, karanteenin, ulkopuolisen organisaation työriidan, toimitusten viivästymisen tai epäonnistumisen, onnettomuuden, tulvan, maanjäristyksen, tulipalon, myrskyn, hallituksen toiminnan tai lakisääteisten seikkojen vaikutukset.')
fi[41] = h(4, 'Soveltuva laki ja erimielisyydet')
fi[42] = p('Nämä käyttöehdot on laadittu noudattaen Suomen valtion lakeja. Tarjoamme LainaaYritykselle.fi:n palveluja vain suomalaisille yrityksille ja vakituisesti Suomessa asuville henkilöille. Erimielisyydet, jotka näistä käyttöehdoista mahdollisesti aiheutuvat käsitellään Helsingin käräjäoikeudessa.')

const footer = []
footer[0] = nl(bold('LVS Brokers Oy '))
footer[1] = nl('PL 644, 00101 Helsinki Finland')
footer[2] = nl('www.LainaaYritykselle.fi ')
footer[3] = nl('Asiakaspalvelu: asiakaspalvelu@LainaaYritykselle.fi')
footer[4] = nl('Toiminnan kenttä: Yritysten lainanvälitys')
footer[5] = nl('Yrityksen Y-tunnus: 2521965-8')

fi[43] = p(footer.join(''))

export default fi.join('')
