<template>
    <div>
        <b-container>
            <b-row>
                <b-col v-html="$t('tos')"></b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
