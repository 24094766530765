<template lang="html">
  <div>
    <date-picker
      :input-class="'form-control ' + (error ? 'is-invalid' : '')"
      :lang="datepickerLocale"
      type="month"
      v-model="model"
      width="100%"
      @input="input"
      :value="value"
      format="MM.YYYY"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import datepickerLocale from '@/lang/dates'
import { DateTime } from 'luxon'
export default {
  props: ['value', 'error', 'placeholder'],
  computed: {
    datepickerLocale() {
      return datepickerLocale
    },
  },
  data() {
    return {
      model: '',
    }
  },
  components: {
    DatePicker,
  },
  methods: {
    input(date) {
      let formattedDate
      if (this.touchscreen) {
        formattedDate = date
      } else {
        formattedDate =
          date !== null
            ? DateTime.fromISO(date.toISOString()).toFormat('yyyy-MM-dd')
            : date
      }

      if (formattedDate !== this.value) {
        this.$emit('input', formattedDate)
      }
    },
  },
}
</script>

<style lang="sass" scoped></style>
