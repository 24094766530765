<template>
  <div>
    {{ $t('redirecting') }}
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions(['redirectMagicLink']),
  },
  mounted() {
    const pathArray = window.location.pathname.split('/')
    const uuid = pathArray[2]
    this.redirectMagicLink({ uuid: uuid })
  },
}
</script>
