<template>
  <div class="card-body text-left">
    <CardInformation
      :offer="offer"
      :action="action"
      :currency="currency"
      :status="status"
      :productName="productName"
    />
    <template v-if="offer.description">
      <CardFooter :offer="offer" />
    </template>
  </div>
</template>
<script>
import CardInformation from '@/components/account_view_components/CardInformation.vue'
import CardFooter from './CardFooter.vue'
export default {
  props: ['offer', 'action', 'currency', 'productName', 'status'],
  components: {
    CardInformation,
    CardFooter,
  },
}
</script>
<style lang="scss" scoped></style>
