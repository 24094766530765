import settings from '@/settings/settings'
const BRAND_SLUG = settings.BRAND_SLUG
const BRAND_NAME = settings.BRAND_NAME
// Compose loan application body for API request
const composeApplicationBody = (application, sessionKey, versionId) => {
  const body = {
    brand_slug: BRAND_SLUG,
    brand_name: BRAND_NAME,
    version_id: versionId,
    session_key: sessionKey,
    amount: application.amount,
    time_term: application.repayment_time * 12,
    loan_purpose: application.loanPurpose,
    marketing_consents: application.marketing,
    tos_accepted: application.tos,
    personal_data_policy: application.personalDataPolicy,
    coapplicant_tos_accepted:
      application.coApplicant === true ? application.coapplicantTos : true,
    coapplicant_bank_consents:
      application.coApplicant === true ? application.coapplicantBankconsents : true,
    applicants: [
      {
        dk_citizen: application.isDanish,
        residence_permit:
          application.isDanish === false
            ? {
                type: application.typeOfResidencePermit,
                subtype: application.subType,
                permit_number: application.permitNumber,
                foreigner_number: application.foreignerNumber,
                expiry_date:
                  application.expiryDate !== '' ? application.expiryDate : null,
                lived_in_denmark_since: application.countryResidentSince,
              }
            : null,
        first_name: application.firstName,
        last_name: application.lastName,
        ssn: application.ssn,
        email: application.email,
        phone: application.phone,
        education: application.education,
        address: {
          country_code: 'DK',
          street: application.street,
          city: application.city,
          zip_code: application.postalCode,
        },
        unemployment_fund_member: application.unemploymentFund,
        unemployment_fund_member_since: application.memberOfUnemploymentFundSince,
        monthly_gross_income: application.income_gross,
        monthly_net_income: application.income_net,
        monthly_cohabitant_net_income: application.spouseIncome,
        employments: [
          {
            employment_type: application.occupation,
            start: application.employedSince,
            business_id: application.businessId,
          },
        ],
        bank_account: {
          bank_code: application.bankCode,
          account_number: application.accountNumber,
          opened_date: application.openedDate,
          credit_cards: [],
          account_type: 1,
        },

        vehicles: application.cars.map(c => ({
          ownership: {
            Leaset: 2,
            Ejet: 3,
            Firmabil: 4,
          }[c.type],

          leasing_expense:
            c.type === 'Leaset'
              ? Math.ceil(application.leasingExpense / application.numberOfLeasedCars)
              : 0,

          car_expense: Math.ceil(application.costOfCars / application.cars.length),
        })),
        household_heating_expenses: application.householdHeatingExpenses,
        household_utilities_expenses: application.householdUtilitiesExpenses,
        household_digital_media_expenses: application.householdDigitalMediaExpenses,
        household_insurance_expenses: application.householdInsuranceExpenses,
        household_transportation_expenses: application.householdTransportExpenses,
        existing_loans: [], // empty list to be populated later
        household_regular_expenses: application.householdConsumption,
        primary_habitant_cost_share: application.shareOfCosts / 100,
        last_year_total_income: application.lastYearTotal,
        new_debt_this_tax_year: application.newDebtThisYear,
        monthly_other: application.other_income,
        monthly_other_source: application.otherSource,

        dwelling: application.dwelling,
        dwelling_date: application.dwellingDate,
        dependants: application.dependants,
        dependant_ages: application.dependantAges,
        dwelling_expenses: application.costOfLiving,
        marital_status: application.civilstatus,

        household_discretionary_income: application.funds_available,
        debtor_registry: application.rki,
      },
    ],
  }
  if (application.coApplicant === true) {
    body['applicants'].push({
      main_applicant: false,
      first_name: application.coapplicant.firstName,
      last_name: application.coapplicant.lastName,
      ssn: application.coapplicant.ssn,
      dk_citizen: application.coapplicant.isDanish,
      phone: application.coapplicant.phone,
      email: application.coapplicant.email,
      residence_permit:
        application.coapplicant.isDanish === false
          ? {
              type: application.coapplicant.typeOfResidencePermit,
              subtype: application.coapplicant.subType,
              permit_number: application.coapplicant.permitNumber,
              foreigner_number: application.coapplicant.foreignerNumber,
              expiry_date:
                application.coapplicant.expiryDate !== ''
                  ? application.coapplicant.expiryDate
                  : null,
              lived_in_denmark_since: application.coapplicant.countryResidentSince,
            }
          : null,
      monthly_gross_income: application.coapplicant.monthlyGrossIncome,
      monthly_net_income: application.coapplicant.monthlyNetIncome,
      new_debt_this_tax_year: application.coapplicant.newDebtThisYear,
      monthly_other: application.coapplicant.otherIncome,
      employments: [
        {
          employment_type: application.coapplicant.occupation,
          start: application.coapplicant.employedSince,
          business_id: application.coapplicant.businessId,
        },
      ],
      unemployment_fund_member: application.coapplicant.unemploymentFund,
      unemployment_fund_member_since:
        application.coapplicant.memberOfUnemploymentFundSince,
    })
  }
  Object.entries(application.existingLoans).forEach(([key, item]) => {
    if (item.toBePaid) {
      body['applicants'][0]['existing_loans'].push({
        type: item.type,
        amount: item.toBePaid,
        payment: item.payment,
        consider_consolidation: item.consider_consolidation,
        lender: item.lender,
      })
    }
  })
  Object.entries(application.creditCards).forEach(([key, value]) => {
    if (value > 0) {
      body['applicants'][0]['bank_account']['credit_cards'].push({
        issuer: value,
      })
    }
  })
  return body
}
const composeSmeApplicationBody = (application, sessionKey) => {
  const body = {
    application_type: 2,
    brand_slug: BRAND_SLUG,
    brand_name: BRAND_NAME,
    session_key: sessionKey,
    amount: application.smeloan.amount,
    phone: `+45${application.smeloan.phone}`,
    time_term: application.smeloan.repayment_time,
    business_name: application.smeloan.businessName,
    business_id: application.smeloan.businessId,
    year_founded: application.smeloan.yearFounded,
    street: application.smeloan.streetAddress,
    zip_code: application.smeloan.postalCode,
    yearly_turnover: application.smeloan.turnover,
    first_name: application.smeloan.firstName,
    last_name: application.smeloan.lastName,
    ssn: application.smeloan.ssn,
    action: '',
    email: application.smeloan.contactEmail,
    tos_accepted: application.smeloan.tos,
    selling_bill: false,
    iban: application.smeloan.bankAccount,
  }
  return body
}

export default {
  composeApplicationBody,
  composeSmeApplicationBody,
}
