import settings from '@/settings/settings'
import transforms from '@/saverium/transforms'
import router from '@/router'
import Vue from 'vue'
import _ from 'lodash'
// Common application actions for saverium-brands. Imported from @/store/<brand>/modules/application.js.
const composeApplicationBody = transforms[settings.BRAND].composeApplicationBody
const RN = router.routes
const composeExtraDataBody = transforms[settings.BRAND].composeExtraDataBody
const composeSmeApplicationBody = transforms[settings.BRAND].composeSmeApplicationBody
const mappings = {
  tos_accepted: 'tos',
  iban: 'bankAccount',
  id_number: 'idnumber',
  business_id: 'businessId',
  start: 'employedSince',
  end: 'employedUntil',
  employment_type: 'occupation',
  coapplicant_tos_accepted: 'coapplicantTos',
  coapplicant_bank_consents: 'coapplicantBankconsents',
  co_living: 'numberOfPeople',
}

const nipMappings = {
  name: 'employer',
  phone: 'workPhone',
  street_address: 'workStreetAddress',
  postal_code: 'workZipCode',
  city: 'workCity',
}

const extraDataMapping = {
  'Dziecko 1 - data urodzenia': 'extraInfo.Dziecko1',
  'Dziecko 2 - data urodzenia': 'extraInfo.Dziecko2',
  'Dziecko 3 - data urodzenia': 'extraInfo.Dziecko3',
  'Dziecko 4 - data urodzenia': 'extraInfo.Dziecko4',
  'Dziecko 5 - data urodzenia': 'extraInfo.Dziecko5',
  'Dziecko 6 - data urodzenia': 'extraInfo.Dziecko6',
  'Dziecko 7 - data urodzenia': 'extraInfo.Dziecko7',
  'Dziecko 8 - data urodzenia': 'extraInfo.Dziecko8',
}
const versionMapping = ['original', 'input']
export default {
  async finishApplication({ commit, state, dispatch }) {
    commit('setErrors', [])
    commit('setStepErrors', [])
  },

  async sendApplication({ commit, state, rootState, dispatch }) {
    // clear errors
    commit('setErrors', [])
    commit('setStepErrors', [])
    commit('sendApplication', state)
    commit('sendNewApplication', true)
    const sessionKey = await Vue.saverium.getSessionKeyValue()
    const versionId = await Vue.saverium.getVersionId()
    const version = versionMapping[versionId]
    const body = composeApplicationBody(state, sessionKey, version)

    const response = await Vue.saverium.sendApplication(body)
    commit('sendApplicationFinished')
    if (response.error) {
      dispatch('getErrorsFromServer', response.rawError.data)
    } else {
      state.applicationUUID = response.application_uuid
      state.token = response.auth_token
      state.jwt_token = response.id_token
      if (settings.GOOGLE_ANALYTICS !== '') {
        if (settings.BRAND === 'saverium_pl') {
          try {
            if (typeof ga !== 'undefined') {
              ga('send', 'pageview', 'wniosek/sent/OK')
            }
          } catch (error) {}
        }
        if (settings.BRAND === 'saverium_dk') {
          try {
            if (typeof ga !== 'undefined') {
              ga('send', 'pageview', 'ansoegning/sent/OK')
            }
          } catch (error) {}
        }
        fbq('track', 'SubmitApplication')
      }
      if (response.status === 'SMS_VERIFICATION_REQUIRED') {
        router.push({ name: 'SmsVerificationView' })
      } else {
        if (!response.auth_token) {
          Vue.saverium.setApplicationUUID(state.applicationUUID)
          router.push({ name: 'LoginView' })
        }
        if (state.applicationUUID && state.token && state.jwt_token) {
          // save auth_token and jwt_token to oidcClient storage
          Vue.saverium.saveUser(state.token, state.jwt_token)
          commit('setLoading', true)
          router.push({ name: 'MyAccountView' })
        }
      }
    }
  },
  async sendSmeApplication({ commit, state, dispatch }) {
    commit('setValue', { key: 'serverError', value: false })
    commit('setErrors', [])
    commit('setLoading', true)
    const sessionKey = await Vue.saverium.getSessionKeyValue()
    const body = composeSmeApplicationBody(state, sessionKey)
    const response = await Vue.saverium.sendSmeApplication(body)
    if (response.status === 500) {
      commit('setLoading', false)
      commit('setValue', { key: 'serverError', value: true })
    } else if (response.error) {
      dispatch('getSmeErrorsFromServer', response.rawError.data)
      commit('setLoading', false)
    } else {
      commit('setLoading', false)
      const applicationUUID = response.application_uuid
      const token = response.auth_token?.access_token
      const jwt_token = response.auth_token?.id_token

      if (applicationUUID && token && jwt_token) {
        // save auth_token and jwt_token to oidcClient storage
        Vue.saverium.saveUser(token, jwt_token)
        router.push({ name: 'MyAccountView' })
      } else {
        Vue.saverium.setApplicationUUID(applicationUUID)
        router.push({ name: 'LoginView' })
      }
    }
  },

  async sendExtraInfo({ commit, dispatch, state }, { url }) {
    const sessionKey = await Vue.saverium.getSessionKeyValue()
    const accessToken = await Vue.saverium.getAccessToken()
    const body = composeExtraDataBody(state, sessionKey)
    const response = await Vue.saverium.sendExtraInfo(url, body, accessToken)
    if (response.status === 'OK') {
      commit('setModalStatus', 'success')
    } else if (response.rawError) {
      dispatch('getExtraErrorsFromServer', response.rawError.data)
    }
  },
  async verifyPhoneNumber({ commit, state }, { verificationNumber }) {
    const uuid = state.applicationUUID
    const token = state.token
    const jwt_token = state.jwt_token
    const response = await Vue.saverium.verifyPhoneNumber(verificationNumber, uuid)
    if (response.status === 'success') {
      if (!token) {
        Vue.saverium.setApplicationUUID(uuid)
        commit('sendNewApplication', true)
        router.push({ name: 'LoginView' })
      }
      if (uuid && token && jwt_token) {
        Vue.saverium.saveUser(token, jwt_token)
        router.push({ name: 'MyAccountView' })
      }
    }

    if (response.message === 'Invalid key') {
      commit('setValue', { key: 'disableButton', value: false })
      commit('showError', state)
    }
  },
  async updatePhoneNumber({ commit, state }, { phone }) {
    const uuid = state.applicationUUID
    const token = state.token
    const response = await Vue.saverium.updatePhoneNumber(phone, uuid, token)
    if (response.status === 'ok') {
      commit('numberUpdated', state)
    } else if (response.rawError.status === 429) {
      state.waitingTime = response.rawError.headers['retry-after']
      commit('showThrottling', state)
    } else if (response.rawError.status === 400) {
      commit('numberUpdatedFailed', state)
    }
  },

  async sendEskatSuccess({ commit, state }, { eskatUuid }) {
    const accessToken = await Vue.saverium.getAccessToken()
    const response = await Vue.saverium.sendEskatSuccess(eskatUuid, accessToken)
    if (response.status === 'SUCCESS') {
      router.push({ name: 'MyAccountView' })
    }
  },

  async sendEskatFailure({ commit, state }, { eskatUuid }) {
    const accessToken = await Vue.saverium.getAccessToken()
    const response = await Vue.saverium.sendEskatFailure(eskatUuid, accessToken)
    if (response.status === 'SUCCESS') {
      router.push({ name: 'MyAccountView' })
    }
  },

  async updateOrCreateDraft({ commit, dispatch, state }) {
    if (!state.draftInProcess) {
      commit('setDraftProcess', true)
      const versionId = await Vue.saverium.getVersionId()
      const version = versionMapping[versionId]
      const sessionKey = await dispatch('setSessionKey')
      const body = composeApplicationBody(state, sessionKey, version)
      const uuid = Vue.saverium.getDraftUUID()
      if (uuid && uuid !== 'undefined') {
        body['uuid'] = uuid
      } else {
        delete body['uuid']
      }
      const response = await Vue.saverium.sendApplicationDraft(body)
      if (response) {
        commit('setDraftProcess', false)
      }
      Vue.saverium.setDraftUUID(response.uuid)
    }
  },

  async getLeadInfo({ state, commit }, { uuid }) {
    const response = await Vue.saverium.getLeadInfo(uuid)
    if (response.data) {
      commit('setApplication', response.data.form_data)
      commit('setSessionKey', response.data.form_data.session_key)
    } else {
      router.push({ name: 'MainView' })
      commit('draftFound', false)
    }
  },

  async redirectMagicLink({ state }, { uuid }) {
    const response = await Vue.saverium.getMagicLink(uuid)
    if (response.data) {
      const returnLink = response.data.return_link
      window.location = `${returnLink}`
    } else {
      router.push({ name: 'MainView' })
    }
  },

  async cancelApplication({ commit, state }, { uuid }) {
    const response = await Vue.saverium.cancelApplication(uuid)
    return response.data.status
  },
  async fetchApplicationList({ commit, state }) {
    const accessToken = await Vue.saverium.getAccessToken()
    if (accessToken) {
      commit('setLoading', true)
      const applicationList = await Vue.saverium.getUserApplications(accessToken)
      if (applicationList.response && [401].includes(applicationList.response?.status)) {
        const response = await Vue.saverium.refreshLogin()

        if (response.status === 200) {
          commit('setLoggedIn', true)
          commit('setLoading', false)
        } else {
          commit('setLoggedIn', false)
          router.replace(RN.paths.LoginView).catch(() => {})
        }
      } else if (
        applicationList.response &&
        [404, 400].includes(applicationList.response.status)
      ) {
        commit('setLoggedIn', false)
        router.replace(RN.paths.LoginView).catch(() => {})
      } else {
        commit('setLoading', false)
        commit('setApplicationList', applicationList)
        commit('setLoggedIn', true)
      }
    } else {
      router.replace(RN.paths.LoginView).catch(() => {})
    }
  },
  setStepErrors({ commit, state }) {
    const keys = Object.keys(state.errors)
    const stepErrors = state.requiredFields.reduce((acc, cur, index) => {
      const find = cur.some(v => keys.includes(v))
      if (find && !acc.includes(index + 1)) {
        acc.push(index + 1)
      }
      return acc
    }, [])

    commit('setStepErrors', stepErrors)
  },

  setSmeErrors({ commit, state }, errors) {
    commit('setSmeErrors', { ...errors })
  },

  getExtraErrorsFromServer({ commit, dispatch }, errors) {
    errors = _.mapKeys(errors, (val, err) => {
      if (extraDataMapping[err]) {
        return extraDataMapping[err]
      }
      return 'extraInfo.' + err
    })
    commit('setErrors', errors)
  },

  getErrorsFromServer({ commit, state, dispatch }, errors) {
    if (errors.applicants) {
      if (settings.BRAND === 'saverium_dk') {
        const applicantErrors = errors.applicants[0]
        let coapplicantErrors = errors.applicants[1]
        if (coapplicantErrors) {
          if (coapplicantErrors.first_name) {
            coapplicantErrors['coapplicant.firstName'] = coapplicantErrors.first_name
            delete coapplicantErrors.first_name
          }
          if (coapplicantErrors.last_name) {
            coapplicantErrors['coapplicant.lastName'] = coapplicantErrors.last_name
            delete coapplicantErrors.last_name
          }
          if (coapplicantErrors.ssn) {
            coapplicantErrors['coapplicant.ssn'] = coapplicantErrors.ssn
            delete coapplicantErrors.ssn
          }
          if (coapplicantErrors.occupation) {
            coapplicantErrors['coapplicant.occupation'] = coapplicantErrors.occupation
            delete coapplicantErrors.occupation
          }
          if (coapplicantErrors.email) {
            coapplicantErrors['coapplicant.email'] = coapplicantErrors.email
            delete coapplicantErrors.email
          }
          if (coapplicantErrors.phone) {
            coapplicantErrors['coapplicant.phone'] = coapplicantErrors.phone
            delete coapplicantErrors.phone
          }
          if (coapplicantErrors.employments) {
            coapplicantErrors['coapplicant.businessId'] =
              coapplicantErrors.employments[0].businessId
            coapplicantErrors['coapplicant.occupation'] =
              coapplicantErrors.employments[0].employment_type
            coapplicantErrors['coapplicant.employedSince'] =
              coapplicantErrors.employments[0].start

            delete coapplicantErrors.employments
          }
          if (coapplicantErrors.residence_permit) {
            coapplicantErrors = { ...coapplicantErrors.residence_permit }

            if (coapplicantErrors.foreigner_number) {
              coapplicantErrors['coapplicant.foreignerNumber'] =
                coapplicantErrors.foreigner_number
              delete coapplicantErrors.foreigner_number
            }
            if (coapplicantErrors.permit_number) {
              coapplicantErrors['coapplicant.permitNumber'] =
                coapplicantErrors.permit_number
              delete coapplicantErrors.permit_number
            }
            if (coapplicantErrors.subtype) {
              coapplicantErrors['coapplicant.subType'] = coapplicantErrors.subtype
              delete coapplicantErrors.subtype
            }
            if (coapplicantErrors.type) {
              coapplicantErrors['coapplicant.typeOfResidencePermit'] =
                coapplicantErrors.type
              delete coapplicantErrors.type
            }

            if (coapplicantErrors.country_resident_since) {
              coapplicantErrors['coapplicant.countryResidentSince'] =
                coapplicantErrors.country_resident_since
              delete coapplicantErrors.country_resident_since
            }
          }
        }
        errors = { ...applicantErrors, ...coapplicantErrors }
      } else {
        errors = { ...errors.applicants[0] }
      }
    }

    if (errors.tos_accepted) {
      this.state.application.errorsInCheckbox = true
      this.state.application.consentError = true
    }
    if (errors.personal_data_policy) {
      this.state.application.dataPolicyError = true
    }
    if (errors.employments) {
      const employmentErrors = errors.employments[0]
      if (employmentErrors.phone) {
        employmentErrors['workPhone'] = employmentErrors.phone
        delete employmentErrors.phone
      }
      if (employmentErrors.businessId) {
        employmentErrors['businessId'] = employmentErrors.business_id
        delete employmentErrors.businessId
      }
      errors = { ...errors, ...employmentErrors }
      delete errors.employments
    }
    if (errors.registered_address) {
      const addressErrors = errors.registered_address
      if (addressErrors.city) {
        addressErrors['registeredCity'] = addressErrors.city
        delete addressErrors.city
      }
      if (addressErrors.zip_code) {
        addressErrors['registeredZipCode'] = addressErrors.zip_code
        delete addressErrors.zip_code
      }
      if (addressErrors.street) {
        addressErrors['registeredStreet'] = addressErrors.street
        delete addressErrors.street
      }
      if (addressErrors.region) {
        addressErrors['registeredRegion'] = addressErrors.region
        delete addressErrors.region
      }
      errors = { ...errors, ...addressErrors }
      delete errors.registered_address
    }

    let i = 0
    // FIXME: this is horrible to read => use proper variable names
    while (i < 10 && !_.every(errors, e => _.isArray(e))) {
      i += 1
      errors = _.reduce(
        errors,
        (acc, cur, key) => {
          if (_.isArray(cur)) {
            acc[key] = cur
          } else {
            acc = { ...acc, ...cur }
          }
          return acc
        },
        {}
      )
    }

    errors = _.mapKeys(errors, (val, err) => {
      if (mappings[err]) {
        return mappings[err]
      } else {
        let split = err.split('_')
        split = split.map((v, i) => (i > 0 ? _.capitalize(v) : v))
        return split.join('')
      }
    })

    commit('setErrors', errors)
    dispatch('setStepErrors')
  },
  getSmeErrorsFromServer({ commit, state, dispatch }, errors) {
    errors = _.mapKeys(errors, (value, error) => {
      let split = error.split('_')
      split = split.map((v, i) => (i > 0 ? _.capitalize(v) : v))
      return split.join('')
    })
    commit('setSmeErrors', errors)
  },
  removeError({ commit }, key) {
    commit('removeError', key)
  },
  checkErrors({ commit, state, dispatch }, step) {
    let fields
    if (step !== undefined) {
      fields = state.requiredFields[step]
    } else {
      fields = _.flatten(state.requiredFields)
    }
    const errorObject = {}
    for (const field of fields) {
      if (!state[field] || state[field].length === 0) {
        errorObject[field] = ['Field is required']
      }

      if (
        state.errors[field] &&
        state.errors[field][0] === 'Field is required' &&
        state[field]
      ) {
        delete state.errors[field]
      }
    }

    const errors = Object.assign({}, state.errors, errorObject)
    commit('setErrors', errors)

    if (step === undefined) {
      dispatch('setStepErrors')
    }
  },
  async changeNIP({ state, commit }, nip) {
    commit('setLoading', true)
    const response = await Vue.saverium.changeNIP(nip)
    if (response.details) {
      commit('setLoading', false)
      Object.entries(response.details).forEach(([key, value]) => {
        commit('updateApplication', { key: nipMappings[key], value: value })
      })
      return response.details
    } else {
      commit('setLoading', false)
      return response
    }
  },
}
