<template>
  <div class="row article">
    <h1 class="center-title">Kredyt odnawialny – najważniejsze informacje</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            Banki udzielają kredytów różnego rodzaju – celowych i pozwalających na
            realizację dowolnych przedsięwzięć wymagających zewnętrznego finansowania.
            Jednym z zobowiązań, w przypadku których cel nie jest określany przez klienta,
            jest kredyt odnawialny. Jest to specyficzny rodzaj produktu bankowego, który
            jest wykorzystywany wielokrotnie przez klienta w ramach jednego limitu.
          </p>
          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/revolving-loan-tiny.webp"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/revolving-loan.webp"
            alt="Kredyt odnawialny – najważniejsze informacje"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul class="table-of-contents">
              <li>
                <a href="#co-to-jest-kredyt-odnawialny">
                  Co to jest kredyt odnawialny?
                </a>
              </li>
              <li>
                <a href="#jak-dziala-kredyt-odnawialny">
                  Jak działa kredyt odnawialny?
                </a>
              </li>
              <li>
                <a href="#kredyt-odnawialny-vs-debet-roznice">
                  Kredyt odnawialny vs debet – różnice
                </a>
              </li>
              <li>
                <a href="#kredyt-odnawialny-vs-kredyt-gotowkowy">
                  Kredyt odnawialny vs kredyt gotówkowy
                </a>
              </li>
              <li>
                <a href="#na-jakich-warunkach-jest-udzielany-kredyt-odnawialny">
                  Na jakich warunkach jest udzielany kredyt odnawialny?
                </a>
              </li>
              <li>
                <a href="#dla-kogo-przeznaczony-jest-kredyt-odnawialny">
                  Dla kogo przeznaczony jest kredyt odnawialny?
                </a>
              </li>
              <li>
                <a href="#jakie-jest-oprocentowanie-kredytu-odnawialnego">
                  Jakie jest oprocentowanie kredytu odnawialnego?
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container mt-5">
          <h2 id="co-to-jest-kredyt-odnawialny">Co to jest kredyt odnawialny?</h2>
          <p>
            Specyficzna forma kredytu odnawialnego czyni z niego
            <strong>produkt wyjątkowy w ofercie banków</strong>. Czym się charakteryzuje?
            Co to jest i kiedy można z niego skorzystać? Kredyt odnawialny jest produktem,
            który polega na tym, że bank udziela zobowiązania w rachunku
            oszczędnościowo-rozliczeniowym klienta. Wysokość kredytu odnawialnego
            uzależniona jest od
            <a
              href="https://www.saverium.pl/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/"
              rel="noopener noreferrer"
              target="_blank"
              >zdolności kredytowej</a
            >
            klienta, oferty bankowej oraz od historii kredytowania danego wnioskującego.
          </p>
          <p>
            Kredyt lub limit odnawialny – czym jest już wiadomo, ale jak go otrzymać i jak
            korzystać z pieniędzy banku?
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="jak-dziala-kredyt-odnawialny">Jak działa kredyt odnawialny?</h2>
          <p>
            Po przeprowadzeniu procesu kredytowego i zweryfikowaniu zdolności, klient
            otrzymuje właściwy dla niego limit do dowolnej dyspozycji, który jest
            zarezerwowany na jego koncie. Dlatego konieczne jest posiadanie przez
            kredytobiorcę rachunku w banku, który udziela kredytu odnawialnego. Czasem
            wystarczające okazuje się, że klient zadeklaruje, że takie konto otworzy z
            dniem przyznania mu limitu.
          </p>
          <p>
            Co istotne i specyficzne dla kredytu odnawialnego, to fakt, że jego
            <strong
              >wartość powiększa wysokość środków finansowych, jakie klient posiada na
              swoim koncie</strong
            >
            oszczędnościowo-rozliczeniowym. Przez cały czas trwania umowy z bankiem, która
            z reguły zawierana jest na 12 miesięcy z możliwością odnowienia limitu po tym
            czasie, kredytobiorca może korzystać z pieniędzy z kredytu odnawialnego.
            Jednak sięga po nie, jak wypłaci lub wyda w formie bezgotówkowej wszystkie
            swoje pieniądze, jakie miał na koncie.
            <strong>Od tego momentu korzysta z limitu odnawialnego</strong>, ale każda
            kwota wpływająca na rachunek automatycznie powoduje, że limit jest spłacany.
            Nie ma tu więc żadnego harmonogramu spłaty, jakiego musiałby przestrzegać
            klient. Dlatego może on wielokrotnie wykorzystywać limit odnawialny i
            regulować go kolejnymi wpłatami na konto, bez względu na źródło ich
            pochodzenia.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-odnawialny-vs-debet-roznice">
            Kredyt odnawialny vs debet – różnice
          </h2>
          <p>
            Zarówno w przypadku limitu odnawialnego, jak i debetu na koncie osobistym,
            masz do czynienia z pieniędzmi banku, z których możesz skorzystać w
            podbramkowych sytuacjach, kiedy skończą ci się twoje pieniądze na rachunku.
            <strong
              >Jednak pamiętaj, że kredyt odnawialny i debet na koncie nie są takimi
              samymi produktami</strong
            >, choć mają wiele cech wspólnych. Warto mieć na uwadze, że przy zaciąganiu
            kredytu odnawialnego i uzyskaniu debetu na koncie osobistym, korzystasz
            dowolnie z pieniędzy przyznanych ci przez bank.
          </p>
          <p>
            Wiedz jednak, że debet na koncie jest produktem przeznaczonym wyłącznie dla
            posiadaczy ROR-ów, czyli rachunków oszczędnościowo-rozliczeniowych w tym samym
            banku.
            <strong
              >Kredyt odnawialny, nazywany też linią kredytową lub limitem
              odnawialnym</strong
            >
            i wymaga podpisania umowy z bankiem, choć można to zrobić przy zachowaniu
            minimum formalności. Przyznawany jest często w znacznie większym limicie niż
            debet na koncie.
          </p>
          <p>
            Te dwa produkty różni też okres spłaty i koszty ponoszone przez klientów.
            Kredyt odnawialny wiąże się z koniecznością uiszczenia prowizji za jego
            przyznanie, ale za to można go spłacać nawet przez cały rok – tak jak ci
            wygodnie. Natomiast <strong>debet przyznawany może być za darmo</strong>, choć
            konieczne jest uregulowanie zobowiązania wobec banku nawet w ciągu jednego
            miesiąca.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-odnawialny-vs-kredyt-gotowkowy">
            Kredyt odnawialny vs kredyt gotówkowy
          </h2>
          <p>
            Na czym polega różnica pomiędzy wspomnianymi produktami? Otóż
            <a
              href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-kalkulator-i-oferty-pozyczek-gotowkowych/"
              rel="noopener noreferrer"
              target="_blank"
              >kredyt gotówkowy</a
            >
            nie jest przypisany do konta bankowego, a limit odnawialny już tak. Wcale nie
            musisz mieć rachunku w instytucji, w której uzyskałeś kredyt gotówkowy, ale
            formalności związane z jego udostępnianiem będą bardziej zaawansowane niż przy
            otwieraniu limitu odnawialnego w istniejącym już od pewnego czasu koncie
            oszczędnościowo-rozliczeniowym.
          </p>
          <p>
            Kredyt odnawialny i gotówkowy spożytkujesz na dowolne cele, a przy tym mogą
            one być udzielane w podobnej wysokości i z podobnym oprocentowaniem.
            Najwyraźniejsza różnica pomiędzy nimi tkwi w tym, że
            <strong
              >kredyt gotówkowy spłacasz według z góry określonego harmonogramu</strong
            >, a odnawialny regulowany jest automatycznie przy każdorazowej wpłacie na
            konto bankowe. Z limitu odnawialnego na rachunku możesz korzystać w ten sposób
            wielokrotnie, czego nie można powiedzieć o kredycie gotówkowym.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="na-jakich-warunkach-jest-udzielany-kredyt-odnawialny">
            Na jakich warunkach jest udzielany kredyt odnawialny?
          </h2>
          <p>
            Podstawowym wymogiem przyznawania kredytów bankowych, w tym kredytu
            odnawialnego, jest posiadanie odpowiednio wysokiej zdolności i dobrej historii
            w
            <a
              href="https://www.saverium.pl/artykuly/bik-big-krd-erif-rdn-co-znacza-te-skroty-jak-sprawdzic-rejestry-dluznikow/"
              rel="noopener noreferrer"
              target="_blank"
              >Biurze Informacji Kredytowej</a
            >. Poza tym konieczne jest konto w tym samym banku lub złożenie deklaracji, że
            klient je otworzy. Dla tych, którzy mają już rachunek w danej instytucji i
            zapewniają stałe wpływy,
            <strong>procedura przyznania kredytu odnawialnego będzie uproszczona</strong>.
          </p>
          <p>
            Nowy klient banku przy ubieganiu się o kredyt odnawialny wraz z wnioskiem o
            takie zobowiązanie będzie musiał dostarczyć do instytucji dokumenty
            potwierdzające jego sytuację finansową, zwłaszcza te określające wysokość
            dochodów miesięcznych. Bank może poprosić o przyniesienie:
          </p>
          <ul>
            <li>
              zaświadczenia z zakładu pracy o wysokości uzyskiwanych dochodów i rodzaju
              umowy o pracę,
            </li>
            <li>deklaracji PIT za ostatni rok podatkowy,</li>
            <li>
              wyciągu z konta bankowego, na które przelewane jest wynagrodzenie, emerytura
              lub renta,
            </li>
            <li>decyzji o przyznaniu świadczenia, takiego jak emerytura czy renta,</li>
            <li>
              książki przychodów i rozchodów czy ewidencji przychodów – w przypadku
              starania się o kredyt odnawialny przez osoby prowadzące działalność
              gospodarczą.
            </li>
          </ul>
        </div>
        <div class="text-container mt-5">
          <h2 id="dla-kogo-przeznaczony-jest-kredyt-odnawialny">
            Dla kogo przeznaczony jest kredyt odnawialny?
          </h2>
          <p>
            Szansę na uzyskanie kredytu odnawialnego mają osoby pełnoletnie, z polskim
            obywatelstwem, które mają stałe miejsce zamieszkania w Polsce oraz dobrą
            historię kredytowania w BIK-u. Po uzyskaniu wniosku o kredyt odnawialny bank
            zbada, czy klienta stać na taki produkt, dlatego wyliczy wysokość zdolności
            kredytowej na podstawie dokumentów dochodowych.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="jakie-jest-oprocentowanie-kredytu-odnawialnego">
            Jakie jest oprocentowanie kredytu odnawialnego?
          </h2>
          <p>
            Ciekawą cechą, jaką przypisuje się do tego rodzaju kredytów, jest ich
            oprocentowanie. W jaki sposób naliczane są odsetki od takiego zobowiązania?
            Wyłącznie od wykorzystanej części limitu odnawialnego.
          </p>
          <p><strong>Kredyt odnawialny przez internet – online</strong></p>
          <p>
            Istnieje możliwość, aby w niektórych bankach zaciągnąć kredyt odnawialny przez
            internet, bez konieczności składania papierowego wniosku. O taki kredyt
            odnawialny można starać się poprzez elektroniczny formularz wniosku. W
            niektórych przypadkach udzielany jest na podstawie podpisanej własnoręcznie
            umowy w placówce bankowej lub za pośrednictwem kuriera.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
