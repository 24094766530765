<template>
  <div class="row article non-landing">
    <div class="mb-5 col-xl-8 col-12">
      <h1 class="center-title">
        Bedre økonomiske beslutninger - Foretag ikke disse fejl igen
      </h1>
      <div class="text-container">
        <p>
          <i>
            Hvordan får man familiens økonomi på benene igen? Bedre økonomiske
            beslutninger ligger kun bag et par små ændringer. Disse tip hjælper dig
            lettere med at slippe af med høje renter og dårlige beslutninger.
          </i>
        </p>
        <picture>
          <source
            srcset="/static/img/saverium_dk/article-imgs/better-financial-desicions.webp"
            type="image/webp"
          />
          <source
            srcset="/static/img/saverium_dk/article-imgs/better-financial-desicions.jpg"
            type="image/jpg"
          />
          <img
            width="700"
            height="466"
            class="article-img"
            src="/static/img/saverium_dk/article-imgs/better-financial-desicions.jpg"
            alt="Med de rigtige økonomiske beslutninger og et par tip kan du stadig vende din økonomi til det bedre. "
          />
        </picture>

        <p>
          Hvordan træffer man bedre økonomiske beslutninger, når hverdagen er stram, og
          lønnen næppe er nok til de nødvendige udgifter? Hvad hvis gælden er akkumuleret
          for meget, og de høje renter på lånene gør det vanskeligt at tilbagebetale?
        </p>
        <p>
          Med de rigtige økonomiske beslutninger og et par tip kan du stadig vende din
          økonomi til det bedre.
        </p>
      </div>
      <div class="text-container">
        <h2>Forbedring af familiens økonomi med bedre beslutninger</h2>
        <p>
          Enhver økonomi kan blive negativ på et eller andet tidspunkt. Daglige udgifter
          øges ofte med for eksempel pludselige sygdomme, værkstedomkostninger på bil og
          reparationer og udgifter til renovering af hjemmet. I familier med børn kommer
          overraskende udgifter igen fra forskellige hobbyer og skolerelateret gebyrer.
        </p>
        <p>
          Først skal du beregne, hvor meget obligatorisk udgift du har hver måned.
          Sammenlign derefter hvor meget indkomst du får i forhold til måneden for at se
          om du har penge tilbage efter de obligatoriske udgifter. Hvis der ikke er penge
          tilbage, kan de heller ikke spares i tilfælde af en dårlig dag.
        </p>
      </div>
      <div class="text-container">
        <h2>Sådan får du din økonomi i orden - Undgå fejl</h2>
        <ul>
          <li>
            <b>Vær realistisk – Skær ned.</b> Brug ikke penge på ting, du ikke
            nødvendigvis har brug for. For eksempel skære ned på streamingtjenester og
            spise ude.
          </li>
          <li>
            <b>Tag ikke et nyt lån, men samle de gamle.</b> Hvis du allerede har et dyrt
            lån, skal du ikke tegne et nyt lån ved siden af dette. I stedet kan du
            refinansiere eller konsolidere dine gamle lån til et billigere lån med lav
            rente.
          </li>
          <li>
            <b>Skær også ned på forsikring og elektricitet.</b> Sørg også for at skære ned
            på andre tjenester, der tager mange penge hver måned. Disse inkluderer f.eks.
            Forsikring, el aftaler og telefonabonnementer. Led efter andre udbyder.
          </li>
          <li>
            <b>Vær forsigtig med kreditkort.</b> Kreditkortregninger kan hurtigt samle
            sig, hvis kortet bruges hensynsløst, og kreditkortrenten kan være overraskende
            høj.
          </li>
        </ul>
      </div>
      <div class="text-container">
        <h2>Bedre beslutninger ved sammenligning - Du mister ikke ved at konkurrere</h2>
        <p>
          En af de største udgiftsposter i de fleste menneskers økonomier er
          tilbagebetaling af lån. Lån skal tilbagebetales hver måned, og ofte medfører at
          deres renter bliver til en yderligere betaling til selve lånebeløbet.
        </p>
        <p>
          Det er værd at huske, at afdrag og lån normalt medfører andre omkostninger end
          blot den månedlige rate og renter. Flere gæld medfører også et antal forskellige
          faktureringsgebyrer og andre udgifter, der kan blive rigtig dyre over måneder og
          år.
        </p>
        <p>
          Hvis du er i økonomiske vanskeligheder, må du ikke optage et nyt lån til en høj
          rente. Du kan få refinansieret et gammelt lån eller et kombinationslån
          garanterer næsten altid en lavere rente og bedre lånevilkår for at erstatte det
          gamle lån.
        </p>
        <h2>Saverium hjælper dig med at forbedre din økonomi</h2>
        <p>
          Målet med Saverium er at hjælpe folk med at træffe bedre økonomiske
          beslutninger. Vi sammenligner lånetilbud fra flere banker og långivere med kun
          en <b-link :to="$root.rn.LoanView">ansøgning</b-link>. Du kan ansøge om et lån
          på 3000 - 500.000 kroner efter dine egne behov med en låneperiode på 1-15 år.
        </p>
        <p>2020-11-19/Søren</p>
      </div>
    </div>
    <div class="col-xl-4 col-12">
      <div class="container container-widget">
        <h4>{{ $t('apply_for_consolidation') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
  </div>
</template>
<script>
import LoanWidget from '@/components/saverium_dk/seo_components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    LoanWidget,
  },
}
</script>
<style lang="scss" scoped></style>
