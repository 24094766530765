<template>
  <div>
    <div class="article">
      <h1 class="center-title">Samlelån</h1>
      <div class="top-text text-container">
        <p>
          Leder du efter et billigt og hurtigt samlelån? Det kan vi godt hjælpe dig med at
          finde. Vi hjælper dagligt vores brugere med at samle lån et sted, så de kan
          spare penge, og hvis det blot er refinansiering du har brug for, kan vi også
          sagtens hjælpe dig. Hvis du udfylder vores ansøgningsformular, som tager 2
          minutter, vil vi med det samme have dine tilbud på samlelån klar.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#saadan-hjaelper-vi-dig-med-et-samlelaan"
                    >Sådan hjælper vi dig med et samlelån</a
                  >
                </li>
                <li><a href="#hvad-er-et-samlelaan">Hvad er et samlelån</a></li>
                <li><a href="#samlelaan-med-lav-rente">Samlelån med lav rente</a></li>
                <li><a href="#billige-samlelaan">Billige samlelån</a></li>
                <li>
                  <a href="#hurtig-udbetaling-og-ekspedering"
                    >Hurtig udbetaling og ekspedering</a
                  >
                </li>
                <li>
                  <a href="#hvad-udgoer-det-bedste-samlelaan"
                    >Hvad udgør det bedste samlelån</a
                  >
                </li>
                <li><a href="#refinansiering">Refinansiering</a></li>
                <li>
                  <a href="#vigtigt-at-vide-om-at-samle-laan"
                    >Vigtigt at vide om at samle lån</a
                  >
                </li>
                <li><a href="#saadan-optager-du-laanet">Sådan optager du lånet</a></li>
                <li>
                  <a href="#ofte-stillede-spoergsmaal-vedroerende-samlelaan"
                    >Ofte stillede spørgsmål vedrørende samlelån</a
                  >
                </li>
                <li>
                  <a href="#samlelaan-hos-udvalgte-udbydere"
                    >Samlelån hos udvalgte udbydere</a
                  >
                </li>
                <li>
                  <a href="#skal-vi-hjaelpe-dig-med-at-samle-dine-laan"
                    >Skal vi hjælpe dig med at samle dine lån?</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="saadan-hjaelper-vi-dig-med-et-samlelaan">
            Sådan hjælper vi dig med et samlelån
          </h2>
          <p>
            Måden det foregår på, er at du udfylder vores ansøgningsformular. Herefter
            fremsender vi ansøgningen til en lang række banker og långivere, som vender
            tilbage med tilbud på et samlelån til dig. Uanset om du søger et billig
            samlelån med lav rente eller et hurtigt samlelån, så finder vi en lang række
            muligheder, som du frit kan vælge imellem.
          </p>
          <p>
            Ansøgningen tager få minutter, og du får svar tilbage med det samme. Herefter
            præsenterer vi tilbudene på overskuelig vis, så du kan vælge det sted at samle
            dine lån, som passer bedst til dig og dine behov. Ansøgningen er helt
            uforpligtende.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-er-et-samlelaan">Hvad er et samlelån</h2>
          <p>
            Et samlelån handler i bund og grund om at samle dine lån eller
            <strong>gæld</strong> hos én enkelt långiver. Når du vælger at samle lån et
            sted får du en række fordele, som blandt andet dækker over lavere rente, færre
            gebyrer og mindre besvær med at holde styr på diverse kreditorer. Hvis man på
            en anden måde skal sige hvad et samlelån er, er det en måde at samle sine lån
            ét sted til lavere omkostninger, samtidig med at hverdagen gøres en del
            nemmere. At samle sine lån er udelukkende en fordel for dig.
          </p>
          <p>
            Samlelån kommer i mange størrelser, men de fleste vælger billige samlelån på
            400.000 kr. og opefter, da der som regl vil være flere små og mellemstore lån,
            som skal samles i ét lån.
          </p>
        </div>

        <div class="text-container">
          <h2 id="samlelaan-med-lav-rente">Samlelån med lav rente</h2>
          <p>
            Langt de fleste, som leder efter et samlelån, går som udgangspunkt efter at
            finde et samlelån med lav rente. Det er også et rigtigt godt udgangspunkt for
            et billigt samlelån, da formålet er at spare penge. En lav rente på et
            samlelån er dog ikke altid en betydende med, at du har fundet det billigste
            samlelån eller det bedste samlelån, da der er flere parametre, som du bør når
            du samler dine lån, end blot en lav rente.
          </p>
          <p>
            Hvis du ikke er opmærksom på, hvad du ellers bør tænke over, før du i blinde
            vælger et samlelån med lav rente, så læs med nedenfor.
          </p>
        </div>

        <div class="text-container">
          <h2 id="billige-samlelaan">Billige samlelån</h2>
          <p>
            Du har nu taget skridtet fra at kigge på samlelån med lav rente, til at kigge
            på de reelle omkostninger ved at samle dine lån ét sted. Renten er som nævnt
            vigtig at huske på, men hvis du skal finde det billigste samlelån, så skal du
            også huske at kigge på alle de andre omkostninger, som er forbundet med lånet.
          </p>
          <p>
            Et billigt samlelån har ikke for høje administrationsomkostninger,
            oprettelsesgebyrer, udbetalingsgebyrer eller andre former for omkostninger.
            Det billigste samlelån kan sagtens være høj på den ene omkostning, men lav på
            den anden. Derfor er det vigtigt, at du ser på det samlede billede af udgifter
            forbundet med samlelånet, for at du kan finde frem til det billigste samlelån.
          </p>
          <p>
            Der er flere af disse oplysninger, som vi ren lovpligtigt skal oplyse, selv om
            det kan forvirre mere end gavner, når dit formål er at finde det billigste
            samlelån. Derfor gør vi her en stor dyd ud af at uddybe, hvordan du vælger det
            billigste samlelån.
          </p>
          <p>
            Hvis du samler alle omkostnigerne under én term, finder du ÅOP. ÅOP står for
            omkostninger i procent, som er en af de vigtigste parametre at kigge på, hvis
            du gerne, vil have at dit samlelån bliver så billigt som muligt. Har du fx
            behov for et samlelån på 400.000 kr. og har lånet en ÅOP på 5%, så betaler du
            en årlig omkostning på 5% af lånet hvert år, indtil du har afbetalt hele dit
            samlelån.
          </p>
          <p>
            Vær opmærksom på, at ÅOP kun giver mening at sammenligne på baggrund af, hvis
            du har lån med identisk lånebeløb og løbetid. Hvis ikke dette er tilfældet, må
            du benytte nogle af de andre sammenligningsparametre, for at finde det
            billigste samlelån.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hurtig-udbetaling-og-ekspedering">Hurtig udbetaling og ekspedering</h2>
          <p>
            En af de andre vigtige faktorer, når du vælger at samle dine lån, er om det er
            et hurtigt samlelån. Det er måske ikke alle, som har brug for et hurtigt
            samlelån, men i de fleste tilfælde, ønsker man at få afsluttet processen så
            hurtigt som muligt, så man kan komme videre til nogle mere interessante
            områder af livet.
          </p>
          <p>
            På trods af at vi dagligt beskæftiger os med lån og samlelån, så ved vi også,
            at lån helt generelt er lavinteresse. Af denne grund er et hurtigt samlelån en
            fordel, da du ikke behøver at bruge mange dage på en langsom ansøgningsprocess
            eller evt. udbetaling, hvis dit samlelån også indebærer, at du trækker lidt
            flere penge ud til dig selv.
          </p>
          <p>
            Hvis dine eksisterende lån, som skal samles under et billigere samlelån, ikke
            generer dig synderligt, så er et hurtigt samlelån måske ikke højeste
            prioritet. Alt andet lige, vil det dog være en fordel at finde et hurtigt
            samlelån, hvis det er lige så billigt som dine andre muligheder for at samle
            dine lån.
          </p>
          <p>
            I tilfælde af en meget langsommelig process, kan det også komme på tale, at du
            kan spare penge ved at finde et hurtigt samlelån. Formålet med samlelånet er
            jo netop at finde et samlelån, som er billigere end dine andre lån. Hvis
            processen trækker ud, kan du derfor komme til at betale renter og gebyrer på
            de lån, som du ønsker at samle et sted, i længere tid end højest nødvendigt.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-udgoer-det-bedste-samlelaan">Hvad udgør det bedste samlelån</h2>
          <p>
            Nu har vi afdækket de billigste samlelån, og hvad fordelen ved et hurtigt
            samlelån er. Nu er vi ved at nå frem til de sidste ting, som skal på plads,
            for at du kan finde det bedste samlelån.
          </p>
          <p>
            Når du leder efter det bedste samlelån, så skal du selvfølgelig have hastighed
            og prisen med i mente, men der er også andre paramatre, som du skal overvej,
            når du vil finde det bedste samlelån.
          </p>
          <p>
            Den måske vigtigste pointe her, er at det bedste samlelån for dig, er et lån,
            som får din økonomi til at hænge bedre sammen. Her er det vigtigt at kigge på
            den månedlige ydelse. Hvis den månedlige ydelse er for høj, vil dit
            rådighedsbeløb blive for lav, og dermed har du ikke fundet et godt samlelån.
            Det er måske stadigvæk det bedste samlelån, som du har mulighed for at få, og
            i det tilfælde må du overveje, om det stadigvæk er en god løsning,
            sammenlignet med at have flere små lån at holde styr på.
          </p>
          <p>
            Sidst, men ikke nødvendigvis mindst, skal du være opmærksom på, hvilke
            muligheder du har i forbindelse med dit nye samlelån. Kan du fx forhøje lånet,
            hvis det bliver behov for at
            <a
              href="https://saverium.dk/laan-penge/"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >
            igen? Har du mulighed for at afdrage hurtigere på lånet, så du hurtigere kan
            blive gældfri? Er der nogle gebyrer forbundet med disse to nævnte muligheder?
            Er det påkrævet/ønsket at kunne sætte betalingen op automatisk?
          </p>
          <p>
            Måske er der flere parametre, som du tænker er relevante for at finde det
            bedste samlelån til din situation. Hvis du har fundet et billigt samlelån, som
            oven i købet er hurtigt og fleksibelt, så har du med stor sandsynlighed fundet
            det bedste samlelån for dig.
          </p>
        </div>

        <div class="text-container">
          <h2 id="refinansiering">Refinansiering</h2>
          <p>
            Vi skelner mellem to former for refinansiering af lån. Der er én type, som
            dækker over refinansiering af
            <a
              href="https://saverium.dk/laan-penge/bolig"
              rel="noopener noreferrer"
              target="_blank"
              >boliglån</a
            >, som vi ikke beskæftiger os med, og en anden form, nemlig refinansiering af
            <a
              href="https://saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >, som vi i stor stil beskæftiger os med.
          </p>
          <p>
            Hvis vi kigger nærmere på sidstnævnte form for refinanciering af lån, er der
            et stort sammenfald med billige samlelån. Den største forskel er, at der i
            mange tilfælde af refinansiering ikke skal samles mange små lån til et større
            og billigere samlelån, men derimod skal ét enkelt lån blot refinansieres,
            eller omlægges.
          </p>
          <p>
            Det kan være at du har haft en afdragsfri ordning på dit lån, som nu udløber,
            og derfør ønsker at refinanciere lånet for at få en lavere månedlig ydelse.
            Har du fx haft et lån, som løber over 10 år, hvoraf du har mulighed for 2
            afdragsfrie år, hvilket du har brugt på nuværende tidspunkt, skal lånet
            afdrages over 8 år, uden nogen form for afdragsfrihed.
          </p>
          <p>
            I det tilfælde, kan det være fordelagtigt at refinansiere lånet, da du med
            stor sandsynlighed kan få lov at afbetale lånet over 10 nye år.
          </p>
          <p>
            En anden mulighed, som kan opstå, hvis du undersøger mulighederne for
            refinansiering af lån er, at du finder et bedre tilbud, da du kan du få en
            lavere rente / bedre pris pga. lånemarkedets udvikling.
          </p>
        </div>

        <div class="text-container">
          <h2 id="vigtigt-at-vide-om-at-samle-laan">Vigtigt at vide om at samle lån</h2>
          <ul>
            <li>
              Ved at ansøge om et samlelån vil du kunne kombinere de små lån du allerede
              har fra forskellige kreditorer til et samlet lån. Dette giver dig bedre
              mulighed for at holde styr på din økonomi og sparer dig penge hver måned på
              låneomkostninger.
            </li>
            <li>
              At søge om billige samlelån er blevet mere og mere populært over de seneste
              år. Ved at samle dine lån et bestemt sted har du kun én fast udgift. Som
              regel er prisen på samlelånet også lavere, end hvis du har individuelle lån
              ved flere kreditor.
            </li>
            <li>
              Der findes mange banker og platforme som tilbyder at samle dine lån. For at
              få et overblik over hvor du finder det bedste sted at sale lån, anbefaler vi
              at du går gennem en service som vores.
            </li>
            <li>
              Det er meget vigtigt at man finder et billigt samlelån, som passer perfekt
              til ens personlige økonomi, så lånet og processen bliver overskuelig.
              Pointen med at samle lån et sted er nemlig at det skal gøre det hele nemmere
              og billigere.
            </li>
            <li>
              Ved at anvende en platform som vores kan du nemt, gratis, uforpligtende og
              overskueligt sammenligne samlelån hos de forskellige låneudbyder, ved hjælp
              af en enkelt ansøgning.
            </li>
          </ul>
        </div>

        <div class="text-container">
          <h2 id="saadan-optager-du-laanet">Sådan optager du lånet</h2>
          <ol>
            <li>
              Skriv alle dine nuværende lån ned: Hvad er dit nuværende beløb du mangler at
              tilbagebetale, samt hvor meget du betaler om måneden til disse lån. Se for
              eksempel vores udregningseksempel nedenfor.
            </li>
            <li>
              Indtast dit lånebehov i formularen her på siden. Du skal her vælge et
              lånebeløb, som svarer til din restgæld på dine nuværende lån, som du ønsker
              at samle og vælg ”Samlelån” som formål. Du kan også inkludere et evt. ekstra
              <a
                href="https://saverium.dk/laan-penge/beloeb"
                rel="noopener noreferrer"
                target="_blank"
                >lånebeløb</a
              >
              i beregningen, hvis du har behov for at låne yderligere.
            </li>
            <li>
              Efter du har valgt beløbet, kan du justere den månedlige ydelse, som vil
              påvirke din tilbagebetalingstid. Den månedlige ydelse skal passe ind i dit
              budget, så du har mulighed for at opretholde din låneaftale og samtidig ikke
              leve af pasta med ketchup hver dag.
            </li>
            <li>
              Når lånebeløbet, tilbagebetalingstiden og den månedlig ydelse er afklaret,
              indtaster du dine oplysninger i låneansøgningen. Herefter vil vi spørge ind
              til dine kontaktoplysninger, bopæl, jobstatus, nuværende lån og andre
              månedlige udgifter. Vi behøver disse oplysninger så banker og långiverne har
              mulighed for at udregne et personligt og velpasset
              <a
                href="https://saverium.dk/laan-penge/sammenligning"
                rel="noopener noreferrer"
                target="_blank"
                >lånetilbud</a
              >. Det er meget vigtigt at udfylde disse informationer så præcist som
              muligt, for at du kan få de bedst mulige tilbud om billige samlelån med lav
              rente. Husk på, at disse oplysninger vil blive verificeret af bankerne og
              det derfor ikke hjælper at pynte på tallene til at starte med. Når du har
              udfyldt alle de relevante felter, kan du sende ansøgningen afsted.
            </li>
            <li>
              Med det samme vil du modtage dine første tilbud om samlelån! Vi vil samle
              dem alle på din personlige konto, for at gøre det nemmere for dig at
              sammenligne de forskellige samlelånstilbud. Du kan efterfølgende i ro og mag
              sætte dig ind i de forskellige tilbud om at samle dine lån og vælge et
              billigt samlelån, der netop passer dig bedst.
            </li>
            <li>
              Når du har sammenlignet dine tilbud og foretaget dit valg, skal du acceptere
              tilbuddet. Herefter vil du blive ført over til den valgte låneudbyderes
              hjemmeside, hvor du skal gennemføre Identifikation med dit
              <a
                href="https://saverium.dk/laan-penge/privatlaan/laan-med-mitid"
                rel="noopener noreferrer"
                target="_blank"
                >NemID/MitID</a
              >
              og bekræfte lånetilbuddet. Identifikation er nødvendigt af sikkerhedsmæssige
              årsager så låneudbyderen kan være sikker på det er dig, der ansøger om at
              samle lån.
            </li>
            <li>
              Til sidst sørger banken for at afvikle dine eksisterende lån, som du valgte
              at samle til et lån, samt udbetale et evt. ekstra lån. Nu kan du nyde en
              nemmere og mere overskuelig økonomi, da du nu kun har et lån, som du skal
              afdrage og betale renter på hver måned, samt nyde en økonomisk situation med
              mere luft.
            </li>
          </ol>
          <h3>Udregningseksempel</h3>
          <p>
            Nedenfor finder du et realistisk eksempel på, hvor meget du kan spare ved at
            samle dine lån ét sted frem for at have mange små lån.
          </p>
          <ul>
            <li>
              <strong>Total uden at samle lån:</strong> Månedlig ydelse i alt uden
              samlelån på 4.785 kr. og total tilbagebetaling på 316.530 kr.
            </li>
            <li class="ql-indent-1">
              30.000 kr. ubetalt kreditkort gæld med 15% ÅOP Månedlig betaling: 820 kr.
            </li>
            <li class="ql-indent-1">
              100.000 kr. lån for renovering af hus: løbetid 8 år og 30,15% ÅOP Månedlig
              betaling: 2.527 kr.
            </li>
            <li class="ql-indent-1">
              20.000 kr. kviklån over 2 år, 78% ÅOP Månedlig betaling: 1.438 kr.
            </li>
            <li>
              <strong>Total hvor du samler lån: </strong>Månedlig ydelse med brug af
              samlelån 2.060 kr. og total tilbagebetaling på 120.467 kr.
            </li>
            <li>
              <strong>Forskel med samlelån:</strong> besparrelse på 120.467 kr., samt en
              månedlig ydelse, som er 2.725 kr. lavere (baseret på 8 års løbetid og 7,19%
              ÅOP)
            </li>
            <li><br /></li>
          </ul>
        </div>

        <div class="text-container">
          <h2 id="ofte-stillede-spoergsmaal-vedroerende-samlelaan">
            Ofte stillede spørgsmål vedrørende samlelån
          </h2>
          <p>
            Herunder har vi samlet nogle af de mest almindelige spørgsmål, som vi bliver
            spurgt om, når vores brugere overvejer at samle deres lån.
          </p>
          <h3>Hvilke fordele får jeg ved at samle mine lån?</h3>
          <p>
            Der findes to primære fordele ved et samlelån. For det første kan du få et
            samlelån som er billigere end dine eksisterende lån tilsammen. For det andet,
            skal du kun holde styr på ét lån, frem for flere, hvis du samler dine lån hos
            en udbyder.
          </p>
          <h3>Kan jeg samle mine lån?</h3>
          <p>
            Der findes forskellige forhold, som skal være opfyldt for at du kan blive
            godkendt til et samlelån. Du skal fx være fyldt 18 år, have dansk bopæl, ofte
            ikke være registreret i RKI eller lignende.
          </p>
          <h3>Kan man optage et samlelån trods RKI?</h3>
          <p>
            Som udgangspunkt kan du ikke optage lån, herunder samlelån, hvis du er
            registreret i RKI eller Debitor Registret. I nogle få tilfælde kan det dog
            være muligt, fx hvis du allerede har et lån hos en enkelt udbyder, som du
            afdrager på, hvis det også er denne udbyder, som du indhenter tilbud om
            samlelån fra.
          </p>
          <p>
            At samle lån er typisk også billigere end dine andre lån lagt sammen. Dermed
            er det muligt, at låneydbyden af samlelånet vurderer, at du godt kan overholde
            en aftale om samlelån, på trods af at du har misligeholdt en eller flere af
            dine andre låneaftaler.
          </p>
          <p>
            Det er gratis, hurtigt og uforpligtende at ansøge om at samle dine lån igennem
            os. Derfor vil vi også anbefale dig at ansøge om et samlelån på trods af en
            registrering i RKI. Dermed ikke sagt at du kan få et billigt samlelån uden
            kreditvurdering, da banken altid vil foretage dette af dig, før et evt.
            samlelån godkendes.
          </p>
        </div>

        <div class="text-container">
          <h2 id="samlelaan-hos-udvalgte-udbydere">Samlelån hos udvalgte udbydere</h2>
          <p>
            Herunder har vi udvalgt nogle eksempler på banker, som vi samarbejder med
            inden for samlelån. Dette er blot eksempler, som gælder i skrivende stund,
            hvilket betyder, at du muligvis vil modtage tilbud fra flere eller andre
            udbydere af samlelån og på andre lånevillår.
          </p>
          <p>
            Markedet for at samle lån til en lav rente er i rivende udvikling, og
            udbyderne nedenfor kan derfor have justeret deres tilbud, samt at nogle
            udbydere kan have lukket deres forretning. Samtidig har vi med stor
            sandsynlighed koblet nye udbydere på vores platform, når du læser denne
            gennemgang gennemgang af samlelån.
          </p>
          <h3>Eksempel 1</h3>
          <p>
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere/express-bank"
              rel="noopener noreferrer"
              target="_blank"
              >Express Bank</a
            >
            vil sørge for at indfri din “klatgæld”, så du undgår besværet ved at kontakte
            dine nuværende
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere</a
            >. På denne måde har du et større beløb at gøre godt med i hverdagen.
          </p>
          <p>
            Hos Express Bank kan du låne alt fra 75.000 - 500.000 kr. For at kunne ansøge
            om et samlelån til en lav rente hos Express Bank, skal du indsende din seneste
            lønseddel, årsopgørelse samt have en årlig husstandsindkomst på mindst 250.000
            kr. Derudover foretager Express Bank en kreditvurdering af dig.
          </p>
          <p>
            Du har mulighed for at vælge en tilbagebetalingsperiode på samlelån på 2-12
            år. For at kunne ansøge om samlelån skal du minimum være fyldt 18 minimum.
          </p>
          <p>Debitorrenten går fra 4,95 til 19,95%.</p>
          <h3>Eksempel 2</h3>
          <p>
            Hos
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere/basisbank"
              rel="noopener noreferrer"
              target="_blank"
              >Basisbank</a
            >
            kan du også samle dine små lån helt fra 30.000 kr. og op til 500.000 kr. Her
            har du også en valgfri tilbagebetalingstid fra 12 måneder og op til 15 år.
          </p>
          <p>
            For at kunne ansøge om et billigt samlelån igennem Basisbank skal du være
            fyldt 18 år, have en fast indtægt, ikke være registreret i RKI eller Debitor
            Registret, samt have bopæl i Danmark. For at kunne acceptere din ansøgning vil
            Basisbank også lave en kreditvurdering af dig.
          </p>
          <h3>Eksempel 3</h3>
          <p>
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere/resurs-bank"
              rel="noopener noreferrer"
              target="_blank"
              >Resurs Bank</a
            >
            kan også hjælpe dig med et billigt samlelån ved samle dine mindre lån for dig.
            Resurs Banks samlelån forbedrer ikke kun din økonomi, men de gør det os nemt
            for dig, da du ikke skal fremsende nogle dokumenter.
          </p>
          <p>
            Igennem Resurs Bank kan du låne alt fra 10.000 kr. til 300.000 kr. med en
            løbetid fra 2 til 12 år. Her kan du få en variabel debitorrente mellem 4.99 og
            15.54 %. Før du kan blive bevilliget et samlelån, skal du være fyldt 18 år og
            være i arbejde eller pensioneret. Har du været langtidssygemeldt eller
            registeret dom dårlig betaler, kan du ikke blive godkendt.
          </p>
          <p>
            Bliver du godkendt og lever op til kravene, vil Resurs Bank vurdere din
            økonomi ved hjælp af
            <a
              href="https://skat.dk/skat.aspx?oid=2271052"
              rel="noopener noreferrer"
              target="_blank"
              >eSKAT</a
            >, som du giver samtykke til. Ved at give samtykke til eSKAT, behøver du ikke
            at fremsende dokumenter såsom årsopgørelse og lønsedler.
          </p>
        </div>

        <div class="text-container">
          <h2 id="skal-vi-hjaelpe-dig-med-at-samle-dine-laan">
            Skal vi hjælpe dig med at samle dine lån?
          </h2>
          <p>
            Vi håber du er blevet klogere på hvad det vil sige at samle dine lån og kan se
            fordelene i et billigt samlelån. Hvis du tidligere har prøvet at ansøge hos
            individuelle banker eller långivere og tænker “hvorfor kan jeg ikke få et
            samlelån?”, så er det en oplagt mulighed at prøve vores tjeneste, da vi
            indhenter tilbud fra en lang række banker og långivere.
          </p>
          <p>
            Grundet vores mange forskellige udbydere, har vi høj godkendelse på vores
            samlelån, sammenlignet med individuelle pengeinstitutter. Selv hvis du slet
            ikke kunne få ét tilbud på samlelån et andet sted, er det meget muligt at du
            kan få et billigt samlelån her.
          </p>
          <p>
            Hvis du ønsker at ansøge om samlelån hos os, kan du benytte vores beregner til
            samlelån. Du udfylder blot vores ansøgning her på siden og afslutter med at
            vælge “samlelån” i nederste felt. På den måde vil du blive guidet igennem
            processen, hvor du kan indtaste dine eksisterende lån, som skal afvikles, i
            forbindelse med at du optager et samlelån. Beregneren til samlelån kan desuden
            benyttes til at give dig et overslag på ÅOP, rente og samlet tilbagebetaling
            for dit samlelån, hvilket du kan holde op imod dine nuværende låneaftaler.
          </p>
          <p>
            Vi håber at du ønsker at prøve vores uforpligtende service, og modtage
            samlelånstilbud inden for få minutter. Uanset hvad vil vi takke dig for at
            have læst vores information om samlelån.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },

  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
