<template>
  <div>
    <div class="container container-faq col-lg-8 mt-5 mb-5">
      <h1>Ofte stillede spørgsmål</h1>
      <p>
        Læs de oftest stillede spørgsmål, før du kontakter os. Du kan finde de svar, du
        leder efter, hurtigere.
      </p>
      <h2 class="mt-4 mb-2">Krav til ansøgningen</h2>
      <div class="accordion" role="tablist">
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-1
              >Hvem kan ansøge om et lån?<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Alle over 18 år kan ansøge om et lån, du skal dog også have bopæl i
                Danmark, NemID, dansk telefon nummer samt dansk CPR-nummer.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-9
              >Kan jeg optage et lån hvis jeg bor udenfor Danmarks grænser?<span
                class="when-opened"
              >
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-9" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Det er desværre ikke muligt at optage et lån hvis du bor udenfor Danmark,
                det gælder også for folk der bor på Grønland og Færøerne</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-6
              >Hvad er aldersgrænsen hos Saverium.dk?<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-6" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Alle, der er mindst 18 år, kan ansøge om et lån. Nogle banker kan have
                forskellige aldersgrænser og kan beslutte at yde et lån baseret på
                disse.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-14
              >Kan jeg optage et lån trods jeg er registreret i Debitor
              Registret/RKI?<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-14" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Det er desværre ikke muligt at optage et lån hvis du er registreret i RKI
                og Debitor Registret.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <h2 class="mt-4 mb-2">{{ $t('questions_about_saverium') }}</h2>
      <div class="accordion" role="tablist">
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-7
              >Hvem er Saverium?<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-7" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Saverium.dk er en nem og overskuelig sammenlignings platform indenfor lån
                til private. Vi har over 500.000 tilfredse kunder i Norden. Vi hjælper dig
                med at få de bedste lånetilbud sendt til dig som passer dine
                behov.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-8
              >Skal jeg betale for at anvende Saverium.dk?<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-8" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Nej, det er helt gratis at oprette og sende en låneansøgning igennem os.
                Udover det binder du dig ikke fast til at skulle optage et lån. Så du kan
                sagtens gennemgå dine lån og bestemmer om du vil optage et lån eller ej
                uden binding.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <h2 class="mt-4 mb-2">Låneansøgning</h2>
      <div class="accordion" role="tablist">
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-11
              >Hvor meget kan jeg låne?<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-11" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>Du kan låne alt fra 3.000 kr. til 500.000 kr.</b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-2
              >Kan en pensionist ansøge om et lån?<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Vælg pensionist som indkomstkilde, og udfyld din
                pensionsdato.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-3
              >Jeg er arbejdsløs. Kan jeg ansøge om et lån?<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Hvis du er arbejdsløs, kan du stadig ansøge om et lån. Imidlertid kræver
                mange banker et vist niveau af stabil indkomst for at tilbagebetale
                lånet.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-12
              >Hvor lang en løbetid kan jeg få?<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-12" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Du kan vælge alt fra 1 – 15 år at betale dit lån tilbage i.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-13
              >Hvornår udbetales lånet?<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-13" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Når du har valgt det tilbud som matcher dine behov, sender vi dig videre
                til den låneudbydere som du har valgt for at gennemføre det sidste i
                forhold til din ansøgning. Derefter bliver dit lån så udbetalt af dem,
                hvornår det sker afhænger af hvilken låneudbydere du vælger. Du vil som
                udgangspunkt få lånet udbetalt til din bank konto samme dag, der kan dog
                være nogle banker hvor det tager nogle få dage før du kan se dem på din
                konto.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <h2 class="mt-4 mb-2">Kreditvurdering</h2>
      <div class="accordion" role="tablist">
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-4
              >Hvorfor blev min låneansøgning afvist?<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Saverium.dk opretter ansøgninger om kredittilbud og sender dem til mange
                banker. Hver bank træffer sin egen beslutning om lånet, og vi kan ikke
                påvirke disse beslutninger. Nogle ansøgninger afvises af grunde, der er
                ukendte for os, ligesom vi heller ikke kender grundene til, at visse
                accepteres. En almindelig grund til at afvise en låneansøgning er fordi
                ansøgeren ikke opfylder indkomst- eller gældskriterierne.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-5
              >Kan jeg få et lån med en lav kreditvurdering?<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-5" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Du kan ansøge om et lån, selvom din kreditværdighed er lav. Imidlertid
                tilbyder banker generelt ikke lån til denne type ansøgere.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="faq-card">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="faq-header" block v-b-toggle.accordion-10
              >Hvem bestemmer om jeg kan optage et lån?<span class="when-opened">
                <i
                  class="fal fa-chevron-circle-down collapser close-collapse"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="when-closed">
                <i
                  class="fal fa-chevron-circle-down collapser"
                  aria-hidden="true"
                  data-fa-transform="rotate-90"
                ></i> </span
            ></b-button>
          </b-card-header>
          <b-collapse id="accordion-10" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Efter du har sendt din ansøgning afsted, vil vores partner ud fra dine
                oplysninger vurdere om de vil tilbyde dig et lån. Derefter vil de
                fremsende dig et tilbud du så kan acceptere hvis tilbuddet er noget du
                ønsker.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jsonld: {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          {
            '@type': 'Question',
            name: 'Hvem kan ansøge om et lån?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Alle over 18 år kan ansøge om et lån, du skal dog også have bopæl i Danmark, NemID, dansk telefon nummer samt dansk CPR-nummer.',
            },
          },
          {
            '@type': 'Question',
            name: 'Kan jeg optage et lån trods jeg er registreret i Debitor Registret/RKI?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Det er desværre ikke muligt at optage et lån hvis du bor udenfor Danmark, det gælder også for folk der bor på Grønland og Færøerne',
            },
          },
          {
            '@type': 'Question',
            name: 'Hvad er aldersgrænsen hos Saverium.dk?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Alle, der er mindst 18 år, kan ansøge om et lån. Nogle banker kan have forskellige aldersgrænser og kan beslutte at yde et lån baseret på disse.',
            },
          },
          {
            '@type': 'Question',
            name: 'Kan jeg optage et lån trods jeg er registreret i Debitor Registret/RKI?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Det er desværre ikke muligt at optage et lån hvis du er registreret i RKI og Debitor Registret.',
            },
          },
          {
            '@type': 'Question',
            name: 'Hvem er Saverium?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Saverium.dk er en nem og overskuelig sammenlignings platform indenfor lån til private. Vi har over 500.000 tilfredse kunder i Norden. Vi hjælper dig med at få de bedste lånetilbud sendt til dig som passer dine behov.',
            },
          },
          {
            '@type': 'Question',
            name: 'Skal jeg betale for at anvende Saverium.dk?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Nej, det er helt gratis at oprette og sende en låneansøgning igennem os. Udover det binder du dig ikke fast til at skulle optage et lån. Så du kan sagtens gennemgå dine lån og bestemmer om du vil optage et lån eller ej uden binding.',
            },
          },
          {
            '@type': 'Question',
            name: 'Hvor meget kan jeg låne?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Du kan låne alt fra 3.000 kr. til 500.000 kr.',
            },
          },
          {
            '@type': 'Question',
            name: 'Kan en pensionist ansøge om et lån?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Vælg pensionist som indkomstkilde, og udfyld din pensionsdato.',
            },
          },
          {
            '@type': 'Question',
            name: 'Jeg er arbejdsløs. Kan jeg ansøge om et lån?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Hvis du er arbejdsløs, kan du stadig ansøge om et lån. Imidlertid kræver mange banker et vist niveau af stabil indkomst for at tilbagebetale lånet.',
            },
          },
          {
            '@type': 'Question',
            name: 'Hvor lang en løbetid kan jeg få?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Du kan vælge alt fra 1 – 15 år at betale dit lån tilbage i.',
            },
          },
          {
            '@type': 'Question',
            name: 'Hvornår udbetales lånet?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Når du har valgt det tilbud som matcher dine behov, sender vi dig videre til den låneudbydere som du har valgt for at gennemføre det sidste i forhold til din ansøgning. Derefter bliver dit lån så udbetalt af dem, hvornår det sker afhænger af hvilken låneudbydere du vælger. Du vil som udgangspunkt få lånet udbetalt til din bank konto samme dag, der kan dog være nogle banker hvor det tager nogle få dage før du kan se dem på din konto.',
            },
          },
          {
            '@type': 'Question',
            name: 'Hvorfor blev min låneansøgning afvist?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Saverium.dk opretter ansøgninger om kredittilbud og sender dem til mange banker. Hver bank træffer sin egen beslutning om lånet, og vi kan ikke påvirke disse beslutninger. Nogle ansøgninger afvises af grunde, der er ukendte for os, ligesom vi heller ikke kender grundene til, at visse accepteres. En almindelig grund til at afvise en låneansøgning er fordi ansøgeren ikke opfylder indkomst- eller gældskriterierne.',
            },
          },
          {
            '@type': 'Question',
            name: 'Kan jeg få et lån med en lav kreditvurdering?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Du kan ansøge om et lån, selvom din kreditværdighed er lav. Imidlertid tilbyder banker generelt ikke lån til denne type ansøgere.',
            },
          },
          {
            '@type': 'Question',
            name: 'Hvem bestemmer om jeg kan optage et lån?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Efter du har sendt din ansøgning afsted, vil vores partner ud fra dine oplysninger vurdere om de vil tilbyde dig et lån. Derefter vil de fremsende dig et tilbud du så kan acceptere hvis tilbuddet er noget du ønsker.',
            },
          },
        ],
      },
    }
  },
  created() {
    const scriptExists = document.getElementById('structuredData')
    if (!scriptExists) {
      const script = document.createElement('script')
      script.type = 'application/ld+json'
      script.innerHTML = JSON.stringify(this.jsonld)
      script.id = 'structuredData'
      document.head.appendChild(script)
    }
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
}
</script>

<style lang="scss"></style>
