<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån penge til flytning</h1>
      <div class="top-text text-container">
        <p>
          Skal du flytte, og har du brug for et flyttelån til at dække udgifterne? Vi kan
          hjælpe dig, hvis du har brug for at låne penge til flytningen. Ansøg via vores
          formular på 2 minutter, så indhenter vi tilbud til dig med det samme, så du nemt
          kan vælge det bedste og billigste flyttelån.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li><a href="#hvad-er-et-flyttelaan">Hvad er et flyttelån?</a></li>
                <li>
                  <a href="#hvike-flytninger-kraever-et-laan"
                    >Hvike flytninger kræver et lån?</a
                  >
                </li>
                <li>
                  <a href="#vi-hjaelper-gerne-med-at-finde-laan-til-flytning"
                    >Vi hjælper gerne med at finde lån til flytning</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="hvad-er-et-flyttelaan">Hvad er et flyttelån?</h2>
          <p>
            Et flyttelån er at låne penge, som kun er påtænkt din flytning. Et flyttelån
            bruges oftest, hvis der skal transporteres store mængder af inventar, da der
            sjældent er behov for at låne penge til flytningen, hvis det meste kan være i
            en bil eller en trailer.
          </p>
          <p>
            Hvis økonomien ikke rækker til flyttekasser, dobbelt husleje/boligafgift, en
            flyttevogn eller et flyttefirma, kan det komme på tale at låne penge til
            flytningen. Et flyttelån er desuden ikke et specifikt lån, men blot en måde at
            benytte et
            <a
              href="https://saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >
            på.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvike-flytninger-kraever-et-laan">Hvike flytninger kræver et lån?</h2>
          <p>
            Hvis vi prøver at opdele flytningerne, som mange gennemgår på et liv, så skal
            der flyttes fra værelset i dine forældres hus/lejlighed, over til din egen
            lejlighed. Derudover kommer der måske et tidspunkt, hvor du ønsker at flytte
            fra lejlighed til hus, og igen når du flytter fra hus til plejehjem eller
            tilbage i en lejlighed, når børnene er fløjet fra reden. Nedenfor vil vi
            gennemgå, hvornår du højest sandsynligt får behov for at låne penge til en
            flytning.
          </p>
          <h3>Fra forældre til lejlighed</h3>
          <p>
            Når du flytter fra dine forældre og ind i din egen lejlighed eller på et
            kollegieværelse, har du som oftest ikke behov for et flyttelån. De fleste der
            flytter væk fra deres forældre, har sjældent mange møbler ud over en seng, et
            bord, et skab og et tv. Dette kan som oftes fyldes ind i en trailer, sammen
            med tøj og alle andre småting.
          </p>
          <h3>Fra lejlighed til hus</h3>
          <p>
            Hvis du flytter i hus fra en lejlighed, kan et flyttelån måske komme på tale.
            Det er dog ikke altid at der er behov for at
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >
            i forbindelse med denne flytning, da det primært afhænger af tre faktorer.
          </p>
          <p>
            Den første faktor er størrelsen på din lejlighed. Hvis du har haft en stor
            lejlighed, har du med stor sandsynlighed også en god økonomi, så du har haft
            råd til at fylde den op. Dernæst er det helt naturligt, at fylde den plads ud
            man har, da der ellers bliver meget tomt. Har du haft en stor lejlighed, er
            sandsynligheden for at du får behov for at låne penge til flytning og en
            flyttemand større.
          </p>
          <p>
            Den anden faktor er om du har børn og kæreste/mand/kone. Jo flere personer der
            skal flytte fra lejligheden, jo større er mængden af ting, som skal
            transporteres. Her får du måske brug for et flyttefirma og et flyttelån.
          </p>
          <p>
            Sidst men ikke mindst, afhænger behovet for et flyttelån også af, hvilken
            etage du bor på og om der er elevator eller ej.
          </p>
          <h3>Fra hus til nyt hus</h3>
          <p>
            Bor du it et hus, og skal rykke over i et nyt hus, har du med stor
            sandsynlighed mange ting, som skal flyttes. Ligeledes har du måske genstande,
            som er så store og tunge, at du ikke selv kan eller ønsker at flytte dem. I
            dette tilfælde vil flytningen ofte være større end du eller din familie selv
            kan overcome og dermed kommer flyttefirma og flyttelån på tale.
          </p>
          <h3>Fra hus til plejehjem eller lejlighed</h3>
          <p>
            Når du flytter fra hus til et mindre sted, vil der sjældent være behov for at
            låne penge til flytningen. Da din nye bolig er mindre end din nuværende, og du
            måske også har voksne børn, som kan hjælpe dig, vil der nok i højere grad
            skulle smides ting på lossepladsen eller stilles ting til storskrald. Dermed
            er det begrænset hvad der reelt skal flyttes, hvilket ofte kan klares med en
            trailer eller varevogn.
          </p>
        </div>

        <div class="text-container">
          <h2 id="vi-hjaelper-gerne-med-at-finde-laan-til-flytning">
            Vi hjælper gerne med at finde lån til flytning
          </h2>
          <p>
            Vi har specialiseret os i at finde lån til mange behov, inkl. når der er behov
            for at låne penge til flytning. Vi samarbejder med en lang række banker og
            långivere, som du kan ansøge hos på én gang, ved blot at indsende én ansøgning
            via vores hjemmeside. Det giver dig et godt overblik over dine
            <a
              href="https://saverium.dk/laan-penge/bolig/laanemuligheder"
              rel="noopener noreferrer"
              target="_blank"
              >lånemuligheder</a
            >, så du nemt og hurtigt kan finde det rette lån til din situation
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
