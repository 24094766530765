<template>
  <div>
    <div class="title">{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: 'ParagraphHeader',
  props: ['value', 'hideForm'],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  text-transform: uppercase;
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: 60px;
}
</style>
