import settings from '@/settings/settings'

// Field mappings
const FIELD_MAPPING = {
  amount: 'amount',
  time_term: 'time',
  applicant_business_application_name: 'businessName',
  applicant_business_application_business_id: 'businessId',
  applicant_business_application_year_founded: 'yearFounded',
  applicant_business_application_address_street: 'businessAddress',
  applicant_business_application_address_city: 'businessCity',
  applicant_business_application_address_zip_code: 'businessZipCode',
  applicant_business_application_address_phone: 'businessPhone',
  applicant_first_name: 'firstName',
  applicant_last_name: 'lastName',
  applicant_ssn: 'ssn',
  applicant_address_street: 'address',
  applicant_address_city: 'city',
  applicant_address_zip_code: 'zipCode',
  applicant_phone: 'phone',
  applicant_email: 'email',
  applicant_work_phone: 'workPhone',
  applicant_position: 'position',
  applicant_owner: 'isOwner',
  applicant_main_job: 'mainJob',
  applicant_started_working: 'startedWorking',
  applicant_monthly_net_income: 'netIncome',
  applicant_profession_status: 'professionStatus',
  applicant_education: 'education',
  applicant_dwelling: 'dwelling',
  applicant_dwelling_expenses: 'dwellingExpenses',
  applicant_dwelling_type: 'dwellingType',
  applicant_dependants: 'dependants',
  applicant_vehicle_owned: 'vehicles',
  applicant_business_application_existing_loan: 'existingLoans',
  applicant_business_application_company_turnover: 'turnover',
  additional_info: 'additionalInfo',
  campaign_code: 'campaignCode',
  signature_method: 'signatureMethod',
  marketing_permission: 'marketingPermission',
  tos_accepted: 'tos',
  iban: 'iban',
}

const BRAND_SLUG = settings.BRAND_SLUG
const BRAND_NAME = settings.BRAND_NAME
// Compose loan application body for API request
const composeApplicationBody = (application, sessionKey, auth, skip) => {
  const body = {
    brand_slug: BRAND_SLUG,
    brand_name: BRAND_NAME,
    session_key: sessionKey,
    amount: application.amount,
    time_term: application.time,
    business_name: application.businessName,
    business_id: application.businessId,
    year_founded: `${application.yearFounded || '2019'}-01-01`,
    street: application.street,
    zip_code: application.zipCode,
    phone: application.phone,
    yearly_turnover: application.netIncome,
    first_name: application.firstName,
    last_name: application.lastName,
    ssn: application.ssn,
    action: skip ? 'skip' : '',
    email: application.email,
    tos_accepted: application.tos,
    selling_bill: application.sellingBill,
    iban: application.iban,
  }

  // Add auth token to request if user has authenticated
  if (auth) {
    body.auth_token = auth
  }

  return body
}

// Compose loan application draft body for API request
const composeApplicationDraftBody = (application, sessionKey) => {
  const body = {
    brand_slug: BRAND_SLUG,
    session_key: sessionKey,
    amount: application.amount,
    time_term: application.time,
    applicant_business_application_name: application.businessName,
    applicant_business_application_business_id: application.businessId,
    applicant_first_name: application.firstName,
    applicant_last_name: application.lastName,
    applicant_ssn: application.ssn,
    applicant_phone: application.phone,
    applicant_email: application.email,
    applicant_work_phone: application.workPhone,
  }

  return body
}

// Compose application data that will be stored from draft
const composeStoreApplication = application => {
  if (!application) {
    return {}
  }

  const storeApplication = {}
  // Compose key:value pairs for each field
  Object.keys(application).forEach(key => {
    if (Object.keys(FIELD_MAPPING).includes(key)) {
      storeApplication[FIELD_MAPPING[key]] = application[key]
    }
  })
  return storeApplication
}

export default {
  composeApplicationBody,
  composeApplicationDraftBody,
  composeStoreApplication,
}
