<template>
  <div class="main salus-form">
    <script type="application/javascript" id="salus-form"></script>
    <script
      type="application/javascript"
      async
      src="https://salus.group/forms/salusgroup_forms_min.js?token=dk_1547006_full_saverium_full"
    ></script>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import salusForm from '@/utils/saverium_dk/salus-form.js'
// Moved both Salus scripts to their own files as formatting always broke them (remember the semicolons if editing!)
export default {
  data() {
    return {}
  },

  mounted() {
    this.setFormData()
  },
  computed: {
    ...mapState({
      loanAmount: state => state.application.amount,
      loanTime: state => state.application.repayment_time,
      email: state => state.application.email,
      phone: state => state.application.phone,
    }),
    pubid: {
      get() {
        return this.$store.state.salusTrackers.pubid
      },
      set(pubid) {
        this.$store.state.salusTrackers.pubid = pubid
      },
    },
    pub1: {
      get() {
        return this.$store.state.salusTrackers.pub1
      },
      set(pub1) {
        this.$store.state.salusTrackers.pub1 = pub1
      },
    },
    pub2: {
      get() {
        return this.$store.state.salusTrackers.pub2
      },
      set(pub2) {
        this.$store.state.salusTrackers.pub2 = pub2
      },
    },
    pub3: {
      get() {
        return this.$store.state.salusTrackers.pub3
      },
      set(pub3) {
        this.$store.state.salusTrackers.pub3 = pub3
      },
    },
    pub4: {
      get() {
        return this.$store.state.salusTrackers.pub4
      },
      set(pub4) {
        this.$store.state.salusTrackers.pub4 = pub4
      },
    },
    pub5: {
      get() {
        return this.$store.state.salusTrackers.pub5
      },
      set(pub5) {
        this.$store.state.salusTrackers.pub5 = pub5
      },
    },
    pubid_reference: {
      get() {
        return this.$store.state.salusTrackers.pubid_reference
      },
      set(pubid_reference) {
        this.$store.state.salusTrackers.pubid_reference = pubid_reference
      },
    },
  },
  methods: {
    setFormData() {
      const script = document.getElementById('salus-form')
      const form = salusForm(
        this.pubid,
        this.pub1,
        this.pub2,
        this.pub3,
        this.pub4,
        this.pub5,
        this.pubid_reference,
        this.loanAmount,
        this.loanTime,
        this.email,
        this.phone
      )
      script.innerHTML = form
    },
  },
}
</script>
