<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån penge til julen</h1>

      <div class="top-text text-container">
        <p>
          Er du på udkig efter de bedste og billigste forbrugslån på markedet? Eller blot
          et forbrugslån uden sikkerhed? Det hjælper vi dig med at finde. Hvis du udfylder
          vores online ansøgningsskema, kan vi med det samme indhente tilbud på
          forbrugslån til dig fra en lang række banker, så du kan sammenligne renter og
          tilbud på forbrugslån i Danmark.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li><a href="#julelaan">Julelån</a></li>
                <li><a href="#laan-penge-til-julegaver">lån penge til julegaver</a></li>
                <li>
                  <a href="#andre-udgifter-i-forbindelse-med-julen"
                    >Andre udgifter i forbindelse med julen</a
                  >
                </li>
                <li>
                  <a href="#husk-at-der-findes-julehjaelp"
                    >Husk at der findes julehjælp</a
                  >
                </li>
                <li>
                  <a href="#julen-er-mere-end-bare-penge-og-gaver"
                    >Julen er mere end bare penge og gaver</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="julelaan">Julelån</h2>
          <p>
            Julen er en dyr tid og derfor kan et julelån være altafgørende. Ikke nok med
            at du måske lige har brugt penge på <strong>Black Friday</strong>, men lige om
            lidt kommer også et nytår, som måske også koster penge. Julelånet kan hjælpe
            dig med julegaverne, men også mere generelt i forhold til julen. Hvis du skal
            holde fest for familie eller venner, og der både skal købes juletræ, julepynt,
            gås, and, flæskesteg og vin, kan der hurtigt blive brug for et julelån til at
            dække regningen.
          </p>
          <h3>Billige julelån</h3>
          <p>
            Hvis du tænker, at du har behov for et julelån, så er det, lige som med alle
            andre tidspunkter når du
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låner penge</a
            >, en god ide at
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne lånetilbud</a
            >, så du får et billigt julelån - og det kan du med vores produkt. Vi
            sammenligner mange former for
            <a
              href="https://saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >, herunder julelån, så du ikke behøver at betale mere end højest nødvendigt
            for dine lån.
          </p>
          <p>
            I mange tilfælde er det ikke nok, at man finder et billigt julelån, da også
            hastigheden er vigtig. Derfor går vi også meget op i, at dit lån bliver
            udbetalt hurtigt, så du kan få dækket dine udgifter med det samme, hvis du
            står og mangler penge til juleindkøbet kort før juleaften. Du kan som
            udgangspunkt forvente, at dit julelån udbetales samme dag, som du ansøger,
            eller næste hverdag.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-penge-til-julegaver">lån penge til julegaver</h2>
          <p>
            En af de største udfordringer for budgettet op til julen er julegaverne.
            Uanset om det er dig der afholder juleaften eller ej, så vil der være en
            udgift at dække i form af julegaver. Her kan det blive nødvendigt at låne
            penge til julegaverne, da vi desværre ikke regne med, at julemanden kommer med
            en stor pose penge til os.
          </p>
          <p>
            Mange danskere har behov for at låne penge til julegaver, så de kan give både
            forældre, børn, børnebørn, fætre, kusiner og gode venner en gave. Nogle gange
            er det muligt at finde den helt rigtige gave til småpenge og andre gange, vil
            det være oplagt med en gave, som er gratis eller man selv kan bygge. Desværre
            gælder det ikke for alle, og i de tilfælde kan det komme på tale at låne penge
            til julegaver.
          </p>
        </div>

        <div class="text-container">
          <h2 id="andre-udgifter-i-forbindelse-med-julen">
            Andre udgifter i forbindelse med julen
          </h2>
          <p>
            Nu har vi talt om at låne penge til julegaver og til dig, som afholder
            juleaften, men julen byder også på andre udgifter, som et lån til julen kan
            dække. Skal du fx have transporteret familien fra Sjælland til Jylland eller
            omvendt, så løber udgifterne til benzin, bro/færge, snacks m.v. hurtigt op i
            1.500 kr. tur-retur. Andre vælger at tage på en juleferie i udlandet, da de
            enten er alene eller skal se familie i udlandet, hvilket hurtigt kan blive
            meget dyrere end en tur rundt i Danmark.
          </p>
          <p>
            Det er ofte ikke den enkelte udgift til én julegave eller vinen på julebordet
            der kræver at lån, men derimod alle de udgifter, der falder sammen oven i
            julen. Samtidig er budgettet måske allerede stramt efter Black Friday og Cyber
            monday.
          </p>
          <p>
            Har du oven i købet en nytårs aften, som kræver torsk, østers, kransekage,
            champagne og en ny kjole/skjorte nytårs aften, så er julelånet til at dække
            julegaverne og julen i virkeligheden det lån, der får dig igennem hele sidste
            kvartal af året.
          </p>
        </div>

        <div class="text-container">
          <h2 id="husk-at-der-findes-julehjaelp">Husk at der findes julehjælp</h2>
          <p>
            Hvis du ikke ønsker at låne til julegaverne, kan julehjælp også være en
            mulighed. Der er fordele og ulemper ved både lån til julegaver og julehjælp.
            Julehjælpen er en gratis økonomisk hjælp, hvor det derimod koster penge at
            låne til julegaver. Det er dog langt fra alle, der kan få julehjælp, og ikke
            alle der ønsker at bede om andres penge.
          </p>
          <p>
            Vi vil lade det være helt op til dig, hvad du vælger. Hvis du vælger at ansøge
            om julehjælp, har vi fundet nogle sider med information om julehjælp, hvordan
            det foregår, hvem der kan få julehjælp, hvis du ønsker at være frivillig og
            hvordan du ansøger.
          </p>
          <ul>
            <li>
              <a
                href="https://www.folkekirken.dk/aarets-hoejtider/advent-og-jul/julehjaelp"
                rel="noopener noreferrer"
                target="_blank"
                >Folkekirkens julehjælp</a
              >
            </li>
            <li>
              <a
                href="https://www.rodekors.dk/jul/julehjaelp"
                rel="noopener noreferrer"
                target="_blank"
                >Rødekors julehjælp</a
              >
            </li>
            <li>
              <a
                href="https://www.blaakors.dk/jul"
                rel="noopener noreferrer"
                target="_blank"
                >Blåkors julehjælp</a
              >
            </li>
            <li>
              <a
                href="https://www.frelsenshaer.dk/hjaelp/julehjaelp/"
                rel="noopener noreferrer"
                target="_blank"
                >Frelsens hær julehjælp</a
              >
            </li>
            <li>
              <a
                href="https://moedrehjaelpen.dk/forside/det-goer-vi/radgivning/julehjaelp/"
                rel="noopener noreferrer"
                target="_blank"
                >Mødrehjælpen</a
              >
            </li>
            <li>
              <a
                href="https://julehjaelpen.dk/sog-julehjaelp-hos-julehjaelpendk"
                rel="noopener noreferrer"
                target="_blank"
                >Julehjælpen.dk</a
              >
            </li>
          </ul>
        </div>

        <div class="text-container">
          <h2 id="julen-er-mere-end-bare-penge-og-gaver">
            Julen er mere end bare penge og gaver
          </h2>
          <p>
            Uanset om du låner penge til julegaver eller vælger at få julehjælp, så håber
            vi at du får en glædelig jul. Husk på at dit samvær og tilstedeværelse betyder
            mere end gaverne eller en stor og flot julemiddag. Derfor bør du ikke
            fortvivle, hvis du har brug for at slippe igennem julen billigt.
          </p>
          <p>
            En skovtur/kælketur og en dag med kagebagning kan have lige så stor, hvis ikke
            større betydning, for både dine forældre, børn og børnebørn end en julegave.
            Det er tanken der tæller, og selv hvis dine børn eller børnebørn ikke helt har
            forstået det endnu, så er det guld værd at lære det til senere i livet.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },

  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
