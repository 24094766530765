<template>
  <div class="row article">
    <h1 class="center-title">
      Saldo konta - co to jest, jak sprawdzić? Co oznacza saldo na minusie?
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            Saldo konta to pojęcie, z którym spotyka się każdy, posiadający rachunek
            bankowy. Choć wiele osób nie zdaje sobie z tego sprawy, saldo na swoim koncie
            sprawdzamy na co dzień, logując się do bankowości elektronicznej. Czym
            dokładnie jest saldo konta bankowego, jak je sprawdzić i co oznacza saldo na
            minusie?
          </p>
          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/account-balance-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/account-balance.jpg"
            alt="Saldo konta - co to jest, jak sprawdzić? Co oznacza saldo na minusie?"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul class="table-of-contents">
              <li>
                <a href="#co-to-jest-saldo-konta"> Co to jest saldo konta? </a>
              </li>
              <li>
                <a href="#saldo-ksiegowe-a-saldo-dostepne">
                  Saldo księgowe, a saldo dostępne
                </a>
              </li>
              <li>
                <a href="#co-oznacza-saldo-na-minusie"> Co oznacza saldo na minusie? </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container mt-5">
          <h2 id="co-to-jest-saldo-konta">Co to jest saldo konta?</h2>
          <p>
            Na początek warto odpowiedzieć na pytanie, co to jest saldo konta i kiedy mamy
            z nim do czynienia? Otóż saldo to w rzeczywistości kwota pieniędzy, która
            zapisana jest na naszym rachunku bankowym. Wyraża ona pieniądze, które realnie
            pozostają do naszej dyspozycji. Saldo rachunku dotyczy nie tylko osób, które
            korzystają z bankowości internetowej, choć w rzeczywistości najłatwiej
            sprawdzić jego wartość, logując się do niej. Z drugiej strony istnieje
            możliwość sprawdzenia salda rachunku bezpośrednio w oddziale banku, a nawet
            telefoniczne, za pośrednictwem dedykowanej infolinii. W bankowości rozróżnia
            się pojęcie salda księgowego oraz dostępnego - choć na co dzień nie wszyscy
            spotykamy się z tego typu pojęciami, gdyż zostały one zastąpiony ogólnym
            sformułowaniem "saldo konta", dobrze wiedzieć, co dokładnie znaczą.
          </p>
          <p>
            <strong>Sprawdź: </strong
            ><a
              href="https://www.saverium.pl/artykuly/pozyczki-pozabankowe-online-kredyty-pozabankowe-prezez-internet/"
              rel="noopener noreferrer"
              target="_blank"
              >Pożyczki pozabankowe online – oferta kredytów pozabankowych przez
              internet</a
            >
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="saldo-ksiegowe-a-saldo-dostepne">Saldo księgowe, a saldo dostępne</h2>
          <p>
            Chcąc odpowiedzieć na pytanie, co to jest saldo księgowe, należy w pierwszej
            kolejności zapoznać się ze schematem księgowania przelewów bankowych. Otóż w
            niektórych sytuacjach przelewy czy transakcje płatnicze nie są księgowane w
            czasie rzeczywistym. Dzieje się tak na przykład w momencie, gdy dokonujemy
            płatności za towary lub usługi - najczęściej kwota "schodzi" z naszego konta
            dopiero po kilku godzinach, jak nie następnego dnia. Z drugiej strony
            pieniędzmi, które wydaliśmy, nie możemy już swobodnie dysponować. Dlatego też
            bank niejako blokuje środki na naszym koncie, co wyraża saldo dostępne. Saldo
            księgowe nie uwzględnia natomiast transakcji, które zostały przeprowadzone,
            lecz jeszcze nie są zaksięgowane. Warto zobrazować to prostym przykładem -
            jeśli mamy na koncie 2000 zł i dokonujemy płatności na kwotę 500 zł, to saldo
            księgowe cały czas wynosi 2000 zł, aż do momentu zaksięgowania przelewu przez
            bank. Tymczasem saldo dostępne wynosi 1500 zł - tyle realnie możemy jeszcze
            wydatkować, gdyż bank zablokował część środków, za pomocą których już
            dokonaliśmy płatności. Saldo a dostępne środki nie są więc pojęciami
            tożsamymi, przynajmniej z punktu widzenia terminologii bankowej.
          </p>
          <p>
            <strong>Może Cię zainteresować: </strong
            ><a
              href="https://www.saverium.pl/artykuly/pozyczki-online-na-raty-pozyczki-chwilowki-na-raty/"
              rel="noopener noreferrer"
              target="_blank"
              >Pożyczki online na raty – pożyczki chwilówki na raty</a
            >
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="co-oznacza-saldo-na-minusie">Co oznacza saldo na minusie?</h2>
          <p>
            Wiadomo już, co to jest saldo rachunku, jak je sprawdzić oraz czym różni się
            saldo dostępne od salda księgowego. Niemniej jednak warto mieć świadomość, że
            istnieje także coś takiego, jak saldo ujemne, które może powstać w dwóch
            przypadkach. Co oznacza saldo na minusie? W rzeczywistości obrazuje ono, że na
            koncie nie posiadamy środków finansowych, lecz niespłacone zobowiązania. Saldo
            ujemne powstaje, gdy:
          </p>
          <ul>
            <li>
              bank umożliwił klientowi korzystanie z linii debetowej (choć nie mamy
              środków własnych, nadal możemy dokonywać płatności do pewnego limitu),
            </li>
            <li>
              bank naliczył dodatkowe opłaty, a my nie posiadamy obecnie środków, aby je
              uregulować.
            </li>
          </ul>
          <p>
            Można więc powiedzieć, że saldo na minusie nie oznacza, że posiadamy środki do
            dyspozycji, a wręcz przeciwnie - związane jest z kosztami, które przyjdzie nam
            pokryć. Saldo ujemne może kojarzyć się z kartą kredytową, choć występuje ono w
            rachunku bieżącym, a nie kredytowym. Sprawdzić możemy je w dokładnie taki sam
            sposób, jak saldo dodatnie - wystarczy zalogować się do swojej bankowości
            internetowej, udać do oddziału banku bądź zadzwonić na infolinię. Saldo na
            minusie wymaga od nas stałej kontroli, bowiem często nie wiemy, jaki
            rzeczywiście dług będziemy musieli spłacić w najbliższej przyszłości. Ponadto,
            niektórzy nie zdają sobie sprawy z faktu, iż nie korzystają z własnych
            środków, a z dostępnej linii debetowej. Warto więc od czasu do czasu
            (przynajmniej raz w tygodniu) sprawdzać stan konta, czyli jego saldo. Zwróćmy
            uwagę przede wszystkim na saldo dostępne, a nie na saldo księgowe, bowiem
            lepiej obrazuje ono, ile pieniędzy pozostało nam do dyspozycji.
          </p>
          <p>
            <strong>Zobacz także: </strong
            ><a
              href="https://www.saverium.pl/artykuly/pozyczki-na-dowod-przez-internet-chwilowki-na-dowod/"
              rel="noopener noreferrer"
              target="_blank"
              >Pożyczki na dowód osobisty przez Internet – chwilówki na dowód</a
            >
          </p>
        </div>
      </div>
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'

export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
