<template>
  <div class="row article non-landing">
    <div class="mb-5 col-xl-8 col-12">
      <div class="article-container">
        <h1 class="center-title">
          Ny lov begrænser renten på lån - Samle dine gamle lån
        </h1>
        <div class="text-container">
          <p>
            <i
              >En ny lov, der begrænser renten på lån trådte i kraft i Danmark her til
              sommer. Den nye lovgivning gælder ikke automatisk på gamle lån, så hvis du
              har optaget et lån inden 1. Juli 2020, skal du tage skridtet imod at
              erstatte dine gamle dyre lån med et lån, der falder ind under den nye
              lovgivning. Ved at samle dine gamle dyre lån til et lån med en lavere rente
              kan du spare flere tusindvis af kroner hver måned.</i
            >
          </p>
          <picture>
            <source
              srcset="/static/img/saverium_dk/article-imgs/money-finger.webp"
              type="image/webp"
            />
            <source
              srcset="/static/img/saverium_dk/article-imgs/money-finger.jpg"
              type="image/jpg"
            />
            <img
              width="700"
              height="466"
              class="article-img"
              src="/static/img/saverium_dk/article-imgs/money-finger.jpg"
              alt="Ny lov begrænser renten på lån - Samle dine gamle lån"
            />
          </picture>

          <h2>
            Dit næste skridt er nødvendigt: Ændring i loven sætter en maksimal rente for
            lån, men anvendes ikke automatisk
          </h2>
          <p>
            Endelig kan vi sige farvel til tårnhøje renter! I begyndelsen af juli i år
            trådte en ny lov om renteomkostninger, der fastsætter den maksimale rente på
            forbrugslån, der er tildelt i Danmark. I dag må eventuelle nye lån ikke
            overstige en årlig procentsats på 35%. Mange banker og udbydere af lån har
            imidlertid yderligere reduceret deres maksimale satser på grund af et
            markedsføringsforbud, som gælder for alle lån der bliver tilbudt med mere end
            en procentsats på 25% årlig.
          </p>
          <p>
            Dette er en stor fornyelse, da der ikke tidligere har været noget loft for
            rentesatsen for lån i Danmark. Derfor kan lån, der er taget før d. 01.07.2020
            endda have flere hundreder af procentvis årlige renter. Den nye lov gælder for
            al forbrugerkredit, uanset om det f.eks. er forbrugslån, kviklån eller ubetalt
            kreditkort.
          </p>
          <p>
            Hvad enhver forbruger skal vide om den nye lovgivning er, at den ikke anvendes
            automatisk til de gamle lån. Derfor skal du tage skridtet i mod at erstatte de
            gamle dyre lån med et nyt lån, der følger den nye regulering for at opnå en
            betydelige lavere rente.
            <b-link :to="$root.rn.ConsolidationLoan"
              >Hvis du har flere små og højrentelån, kan du overvejer at kombinere dem med
              et samlelån nu</b-link
            >.
          </p>
        </div>
        <div class="text-container">
          <h2>
            Lavere låneomkostninger og bedre overblik over din økonomi - Fordele ved et
            samlelån forklaret
          </h2>
          <p>
            Et samlelån er ét større lån, der bruges til at tilbagebetale alle tidligere
            forbrugslån og andre højrentelån for at afslutte de dyre låneaftaler man har
            tidligere har optaget. Dette efterlader kun ét samlet lån, der skal
            tilbagebetales på et månedligt afdrag, hvor renten er mest sandsynligt lavere
            end de tidligere lån. Nedenfor har vi listet alle fordelene ved ét samlelån.
          </p>
          <ul>
            <li>
              <b>Renten på lånet falder.</b>
              Med et samlelån har du kun et lån at betale i stedet for flere små
              individuelle lån. Omkostningerne vil være lavere end at have flere
              forskellige forbrugslån.
            </li>
            <li>
              <b>Kun en forfaldsdato pr. måned.</b>
              At slippe af med flere små lån giver dig kun en forfaldsdato, hvilket giver
              dig mere overblik over din økonomi. Så du behøver ikke betale månedlige
              faktureringsgebyrer og andre administrationsomkostninger til flere
              låneudbydere.
            </li>
            <li>
              <b>Reducere eller forlæng lånetiden.</b>
              Du kan tage et samlelån med en kortere eller længere låneperiode - så det
              passer perfekt til din økonomiske situation. Via Saverium.dk kan du
              sammenligne samlelån med en låneperiode på 1-15 år.
            </li>
            <li>
              <b>Lavere tilbagebetaling pr. måned.</b>
              Ved at samle dine lån vil du reducere den månedlig tilbagebetaling. Dette
              medføre store besparelser i hverdagen for dig.
            </li>
          </ul>
          <p>
            Fordelene ved et samlelån er nu bedre end nogensinde, da med den nye rentesats
            vil den årlige rente på dit lån aldrig stige med mere end 35%, og i de fleste
            tilfælde den begrænset til 25% på grund af markedsførings restriktionerne.
            Igennem Saverium.dk, kan du
            <b-link :to="$root.rn.MainView"
              >sammenligne samlelån der starter fra så lavt som 2,95% i rente </b-link
            >.
          </p>
        </div>
        <div class="text-container">
          <h2>
            Konkurrencen mellem banker bliver hårdere - sammenligning af lånetilbud bliver
            vigtigere
          </h2>
          <p>
            Med et renteloft er det sikrere og lettere at ansøge om et lån, da
            omkostninger ikke kan stige for højt grundet den nye lovgivning. Da
            renteloftet gælder for alle forbrugslån og kreditter, er alle banker og
            forsikringsselskaber på samme linje. Du kan nu låne med den samme rente fra
            din egen bank som du kan ved et mindre forsikringsselskab. Dette gør det endnu
            vigtigere at sammenligne lån.
          </p>
          <p>
            Saverium.dk er en dansk lånesammenligningstjeneste, hvor du kan sammenligne
            lån helt gratis uden forpligtelser. Efterhånden som renteniveauet skærpes, vil
            konkurrencen mellem banker kun intensiveres, hvilket kan gøre det vanskeligt
            at finde det mest optimale lån, der passer netop til din økonomi. Ved at kun
            udfylde en låneansøgning når Saverium.dk ud til flere banker for at finde de
            tilbud der matcher dine søgekriterier. Vi indsamler lånetilbuddene nemt og
            tydeligt på din Saverium-konto, hvor du nemt kan gennemgå og sammenligne de
            tilbud du har fået tilbudt.
          </p>
          <p>
            <b-link :to="$root.rn.LoanView"
              >Ansøg om et samlelån her for at få bedre lånevilkår og spar penge hver
              måned.</b-link
            >
          </p>
          <p>2020-09-14 / Søren</p>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-12">
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
  </div>
</template>
<script>
import LoanWidget from '@/components/saverium_dk/seo_components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'

export default {
  components: {
    LoanWidget,
  },
  data() {
    return {
      visible: false,
    }
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss" scoped></style>
