<template>
  <div class="row article">
    <h1 class="center-title">Kredyt samochodowy – najważniejsze informacje</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            Do udzielania kredytów uprawnione są jedynie instytucje bankowe – głównie
            banki lub spółdzielcze kasy oszczędnościowo-kredytowe. W ofercie mają one
            zobowiązania dowolnego przeznaczenia, jak i celowe. Do tej drugiej grupy
            zalicza się kredyt samochodowy. Dowiedz się więcej, czym on jest i jakie
            inwestycje możesz zrealizować za jego pośrednictwem.
          </p>
          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/car-loan-tiny.webp"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/car-loan.webp"
            alt="Kredyt samochodowy – najważniejsze informacje"
          />

          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul class="table-of-contents">
              <li>
                <a href="#co-to-jest-kredyt-samochodowy">
                  Co to jest kredyt samochodowy?
                </a>
              </li>
              <li>
                <a href="#rodzaje-kredytow-samochodowych">
                  Rodzaje kredytów samochodowych
                </a>
              </li>
              <li>
                <a href="#co-nalezy-wiedziec-o-kredycie-samochodowym">
                  Co należy wiedzieć o kredycie samochodowym?
                </a>
              </li>
              <li>
                <a href="#na-jakich-warunkach-przyznawany-jest-kredyt-samochodowy">
                  Na jakich warunkach przyznawany jest kredyt samochodowy?
                </a>
              </li>
              <li>
                <a href="#kto-moze-skorzystac-z-kredytu-samochodowego">
                  Kto może skorzystać z kredytu samochodowego?
                </a>
              </li>
              <li>
                <a href="#kredyt-samochodowy-a-kredyt-gotowkowy">
                  Kredyt samochodowy a kredyt gotówkowy
                </a>
              </li>
              <li>
                <a href="#kredyt-samochodowy-jakie-dokumenty-sa-potrzebne">
                  Kredyt samochodowy – jakie dokumenty są potrzebne?
                </a>
              </li>
              <li>
                <a href="#kredyt-na-zakup-samochodu-uzywanego">
                  Kredyt na zakup samochodu używanego
                </a>
              </li>
              <li>
                <a href="#kredyt-samochodowy-jakie-raty">
                  Kredyt samochodowy – jakie raty?
                </a>
              </li>
              <li>
                <a href="#kredyt-samochodowy-przez-internet-online">
                  Kredyt samochodowy przez internet (online)
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container mt-5">
          <h2 id="co-to-jest-kredyt-samochodowy">Co to jest kredyt samochodowy?</h2>
          <p>
            Kredyt samochodowy jest produktem bankowym, przeznaczonym na zakup pojazdu
            mechanicznego, nie tylko samochodu. Pieniądze pochodzące z niego muszą być
            wydatkowane przez klienta w zadeklarowany we wniosku sposób, na przykład na
            zakup nowego samochodu od konkretnego zbywcy. Banki mogą oferować zarówno
            <strong
              >kredyt na samochód, jak i pożyczkę samochodową na uproszczonych
              warunkach</strong
            >. Czasami można zaciągnąć takie zobowiązanie wraz z dodatkowym
            <a
              href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-kalkulator-i-oferty-pozyczek-gotowkowych/"
              rel="noopener noreferrer"
              target="_blank"
              >kredytem gotówkowym</a
            >, do dowolnego wykorzystania.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="rodzaje-kredytow-samochodowych">Rodzaje kredytów samochodowych</h2>
          <p>
            Istnieją trzy podstawowe rodzaje kredytów samochodowych, które wyróżnione
            zostały na podstawie tego, w jaki sposób są one spłacane. Może to być kredyt
            na zakup samochodu:
          </p>
          <ul>
            <li>standardowy,</li>
            <li>balonowy,</li>
            <li>jednoratalny (jednoratowy).</li>
          </ul>
          <p>
            <strong>Standardowy kredyt na auto spłacany jest co miesiąc</strong>, w ramach
            ustalonego harmonogramu, a na konto banku musi trafić odpowiednia kwota raty
            kapitałowo-odsetkowej, w wysokości ustalonej w umowie. Taki produkt właściwie
            nie wymaga posiadania wkładu własnego.
          </p>
          <p>
            Inaczej wygląda to w przypadku kredytu jednoratowego, który przeznaczony jest
            dla klientów wpłacających sprzedającemu pewną część wartości pojazdu ze
            środków własnych. Może to być na przykład połowa wartości pojazdu. Wówczas
            mówi się o kredycie „50 na 50”. Połowę pokrywa kupujący – kredytobiorca, a
            połowa jest kredytowana.
            <strong
              >Kredyt na samochód w tej formie spłacany jest w ramach jednej raty, na
              koniec okresu umowy</strong
            >.
          </p>
          <p>
            Ciekawym rodzajem zobowiązania jest kredyt balonowy, który polega na tym, że
            klient regularnie spłaca niskie raty kapitałowo-odsetkowe, które obejmują
            przykładowo 60–80 proc. wartości pojazdu, a ostatnia balonowa rata jest
            spłacana na koniec jednorazowo. <strong>To tak zwana rata z wykupem</strong>.
            Zaletą takiego kredytu na zakup samochodu jest to, że przez cały okres spłaty,
            trwający zwykle od 2 do 5 lat, klient może swobodnie korzystać z pojazdu i
            płacić niskie raty. Niekiedy w ogóle nie płaci on raty balonowej, tylko
            decyduje się na odsprzedanie pojazdu bankowi i zakup nowego, w wybranej formie
            finansowania.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="co-nalezy-wiedziec-o-kredycie-samochodowym">
            Co należy wiedzieć o kredycie samochodowym?
          </h2>
          <p>
            Zanim klient zaciągnie kredyt na auto, powinien mieć świadomość, na jakie
            najważniejsze kwestie z nim związane warto zwrócić szczególną uwagę.
            <strong>Czy chcesz wziąć kredyt na samochód używany czy nowy?</strong> Banki
            są skłonne udzielić zobowiązania na auto z drugiej ręki, choć musi ono mieć
            nie więcej niż na przykład 6–8 lat.
          </p>
          <p>
            Co istotne, przez cały okres spłaty kredytu samochodowego w banku klient jest
            właścicielem pojazdu, choć niekoniecznie może nim dowolnie rozporządzać,
            ponieważ auto jest zabezpieczeniem spłaty zobowiązania. Jeśli kredytobiorca
            nie spłaci zobowiązania samochodowego, wówczas instytucja, która finansowała
            zakup ma prawo dochodzić swoich wierzytelności i
            <strong>przejąć w związku z nimi pojazd, w celu jego sprzedaży</strong>.
          </p>
          <p>
            Przed podpisaniem umowy trzeba sprawdzić, jak ustanawiane są zabezpieczenia
            spłaty. Czy stanowi je tylko samochód, czy na przykład również cesja praw z
            umowy ubezpieczenia AC albo depozyt karty pojazdu lub zastaw rejestrowy.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="na-jakich-warunkach-przyznawany-jest-kredyt-samochodowy">
            Na jakich warunkach przyznawany jest kredyt samochodowy?
          </h2>
          <p>
            Podobnie jak każdy inny kredyt, także kredyt na samochód udzielany jest na
            określonych warunkach wskazanych przez bank. Trzeba podkreślić, że nie tylko
            auto może być przedmiotem finansowania i niekoniecznie musi być to nowy
            pojazd. Przedmiotem kredytowania mogą być również:
          </p>
          <ul>
            <li>motocykl,</li>
            <li>quad,</li>
            <li>skuter,</li>
            <li>łódź,</li>
            <li>helikopter,</li>
            <li>przyczepa.</li>
          </ul>
          <p>
            Warto sprawdzić w banku, który udzielić ma kredytu na auto,
            <strong
              >jakie pojazdy mogą być kredytowane w ramach takiego zobowiązania</strong
            >. Jeśli bank zdecyduje się na sfinansowanie nabycia samochodu używanego,
            najprawdopodobniej wskaże maksymalny wiek auta w momencie spłaty ostatniej
            raty. Może być to na przykład 6–8 lat lub 8-10 lat.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kto-moze-skorzystac-z-kredytu-samochodowego">
            Kto może skorzystać z kredytu samochodowego?
          </h2>
          <p>
            Szanse na kredyt na zakup samochodu mają tylko ci klienci, którzy posiadają
            odpowiednio wysoką
            <a
              href="https://www.saverium.pl/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/"
              rel="noopener noreferrer"
              target="_blank"
              >zdolność kredytową</a
            >
            oraz mogą pochwalić się dobrą historią w
            <a
              href="https://www.saverium.pl/artykuly/bik-big-krd-erif-rdn-co-znacza-te-skroty-jak-sprawdzic-rejestry-dluznikow/"
              rel="noopener noreferrer"
              target="_blank"
              >Biurze Informacji Kredytowej</a
            >. Niska zdolność może być niwelowana poprzez dodatkowe zabezpieczenia spłaty
            zobowiązania.
          </p>
          <p>
            <strong
              >Banki dokładnie weryfikują zdolność kredytową potencjalnych
              klientów</strong
            >. Starając się o zobowiązania na samochód, trzeba złożyć wymagane dokumenty.
            Mają one stanowić potwierdzenie, że stać cię na zadłużenie tego rodzaju i
            prawdopodobnie będziesz spłacać regularnie raty kapitałowo-odsetkowe.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-samochodowy-a-kredyt-gotowkowy">
            Kredyt samochodowy a kredyt gotówkowy
          </h2>
          <p>
            Symulacja kredytu samochodowego i kredytu gotówkowego pokaże, które
            rozwiązanie pozwalające na finansowanie zewnętrzne nabycia pojazdu
            mechanicznego będzie korzystniejsze dla klienta. Kredyt samochodowy może być
            tańszy od kredytu gotówkowego, ponieważ jest zobowiązaniem celowym, z
            zabezpieczeniem w postaci kupowanego pojazdu. Jeśli celem nabywcy jest zakup
            wieloletniego auta, a ponadto potrzebuje on pieniędzy na niezbędne naprawy,
            kredyt gotówkowy może być jedynym wyjściem z sytuacji. W tym przypadku trzeba
            jednak liczyć się z koniecznością poniesienia nieco wyższych kosztów.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-samochodowy-jakie-dokumenty-sa-potrzebne">
            Kredyt samochodowy – jakie dokumenty są potrzebne?
          </h2>
          <p>
            Ubieganie się o kredyt samochodowy wymaga złożenia wniosku na druku bankowym,
            do którego trzeba dołączyć określone dokumenty. Na liście załączników znaleźć
            powinny się:
          </p>
          <ul>
            <li>kopia dokumentu tożsamości kredytobiorcy,</li>
            <li>
              zaświadczenie o wysokości uzyskiwanych dochodów, na przykład z zakładu
              pracy,
            </li>
            <li>
              dokumenty związane z samochodem, na przykład faktura zakupu lub umowa kupna
              i sprzedaży pojazdu, kserokopia dowodu rejestracyjnego i karty pojazdu czy
              zaświadczenie z sądu o braku wpisu pojazdu do rejestru zastawów.
            </li>
          </ul>
          <p>
            Kredyt na auto wypłacony zostanie po ustanowieniu zabezpieczeń i potwierdzeniu
            faktu, że pojazd został ubezpieczony.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-na-zakup-samochodu-uzywanego">
            Kredyt na zakup samochodu używanego
          </h2>
          <p>
            Pojazd używany również może być przedmiotem kredytu samochodowego. Kredyt na
            takie auto zostanie udzielony, jeśli nie jest ono zbyt stare. Niektóre banki
            udzielają zobowiązań na pojazdy mające nie więcej niż 6–10 lat albo i mniej.
            Większość takich produktów finansowych ma niestety wyższe oprocentowanie od
            innych kredytów na samochody. Najtańszy kredyt na auto można uzyskać jednak na
            pojazd nowy. Przy zakupie używanego samochodu trzeba liczyć się z możliwością
            ograniczonego okresu spłaty.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-samochodowy-jakie-raty">Kredyt samochodowy – jakie raty?</h2>
          <p>
            Kredytobiorcy najczęściej mogą zadecydować, jaka będzie rata kredytu
            samochodowego: malejąca czy rosnąca. Taki wybór jest niemożliwy w przypadku
            kredytów samochodowych jednoratalnych i balonowych.
            <strong
              >Raty malejące polegają na tym, że początkowo kredytobiorcy spłacają raty w
              wyższej wysokości</strong
            >, a kwoty maleją wraz z uregulowaniem większej części kapitału. Dzięki temu
            kredyt samochodowy może być jeszcze tańszy. Natomiast przy zobowiązaniu w
            ratach równych ich wysokość jest z góry znana. Początkowo jednak klient spłaca
            w większej części odsetki, a kapitał nadal pozostaje do uiszczenia, dlatego w
            ogólnym rozrachunku taki kredyt na zakup samochodu staje się droższy.
          </p>
          <p>
            <strong
              >Wybór malejącej raty kredytu samochodowego zarezerwowany jest tylko dla
              klientów, którzy mają wysoką zdolność</strong
            >
            <strong>kredytową</strong>. Wymaga on bowiem spłaty wyższych rat na początku
            okresu kredytowania.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-samochodowy-przez-internet-online">
            Kredyt samochodowy przez internet (online)
          </h2>
          <p>
            Gdzie szukać kredytu samochodowego? Obecnie część banków daje możliwość
            wnioskowania o taki produkt w trybie online. Całkowicie przez internet można
            zaciągnąć kredyt na auto nowe lub kredyt na samochód używany. Trzeba tylko
            dopełnić wszystkich formalności i złożyć wniosek wraz z wymaganymi
            załącznikami.
          </p>
        </div>
      </div>
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
