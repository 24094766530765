import { render, staticRenderFns } from "./LoanForYear.vue?vue&type=template&id=cf90934a&scoped=true&"
import script from "./LoanForYear.vue?vue&type=script&lang=js&"
export * from "./LoanForYear.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf90934a",
  null
  
)

export default component.exports