<template>
  <div>
    <div class="article">
      <h1 class="center-title">Billige lån</h1>
      <div class="top-text text-container">
        <p>
          Internettet byder på et utal af måder, hvorpå du kan låne penge. Har du et ønske
          om at optage et billige lån, hvad end formålet med dette måtte være, er det dog
          vigtigt at være opmærksom på, at ikke alle lån er lige billige.
        </p>
        <p>
          Derfor er det vigtigt, at du går tålmodigt og struktureres til værks, hvis du
          skal låne penge billigt. Selvom et lån ved første øjekast kan virke billigt, kan
          sandheden være en helt anden. Derfor er det vitalt, at du nærlæser dine
          lånetilbud fra ende til anden.
        </p>
        <p>
          Hos Saverium.dk har vi gjort det nemt at indhente tilbud på billige lån fra
          vores partnere, hvilket omfatter velrenommerede udbydere som Lån Let, Basisbank,
          Resurs Bank, Tando, Express Bank m.fl.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul v-for="(text, i) in contents" :key="i">
                <li>
                  <a :href="text.link">
                    {{ text.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">Lån billigt i samarbejde med Saverium.dk</h2>
          <p>
            Hos Saverium.dk har vi gjort det nemt at optage et lån billigt. Når du
            udfylder vores ansøgningsformular, modtager du lånetilbud fra vores partnere,
            som præsenteres på overskuelig vis på én side. Derved har du let ved at vælge
            det
            <b-link :to="$root.rn.ConsumerLoans">forbrugslån</b-link>, der appellerer mest
            til dig.
          </p>
          <p>
            Når du har udfyldt ansøgningen, får du øjeblikkeligt svar. Du vil derfor ikke
            skulle vente utålmodigt på et svar om, hvorvidt du er blevet godkendt.
            Ligeledes kan du forvente, at samtlige lånetilbud er kalkuleret med
            udgangspunkt i den udfyldte ansøgning og udarbejdet specifikt til dig.
          </p>
          <p>
            At indhente tilbud via Saverium.dk er desuden helt og aldeles uforpligtende.
            Skulle der – mod forventning – ikke være et tilbud, der tiltaler dig, kan du
            blot afvise dem alle. Der er derfor ingen risiko forbundet med at indhente
            lånetilbud her på siden.
          </p>
          <p>
            Falder du for et af de billige kviklån, du bliver præsenteret for, kan du
            glæde dig over, at der hverken stilles krav til sikkerhedsstillelse eller
            dokumentation. Ligeledes foregår hele processen på nettet, hvorfor du kan
            optage et billigt lån med
            <b-link :to="$root.rn.LoansWithLowRate">lav rente</b-link> på ganske få
            minutter.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">Sådan finder du de billigste lån på nettet</h2>
          <p>
            Du finder rigtig mange billige lånemuligheder på nettet. Blandt disse ønsker
            du sandsynligvis at garantere, at du finder det billigste lån af dem alle.
            Grunden den
            <a
              href="https://em.dk/nyhedsarkiv/2020/juni/nu-er-regler-om-opgoer-med-kviklaan-paa-plads/"
              target="_blank"
              rel="noopener"
              >nye lovgivning</a
            >
            som er blevet fastslået omkring forbrugslån, er det nu billigere end
            nogensinde før.
          </p>
          <p>
            Før du giver dig i kast med at lede efter et billigt lån
            <b-link :to="$root.rn.UnsecuredLoans">uden sikkerhed</b-link>, er der dog en
            række faktorer, der skal være styr på forinden. Det er i særdeleshed med
            hensyn til din privatøkonomi.
          </p>
          <p>
            Først og fremmest skal du beslutte dig for, hvor du har brug for at låne.
            Herudover er det også vigtigt, at du kender dit månedlige rådighedsbeløb
            forholdsvis præcist. Har du ikke et indgående kendskab til dette, ved du reelt
            ikke, hvad du kan afbetale på dit billige lån med NemID hver måned.
          </p>
          <p>
            Når du skal optage et billigt
            <b-link :to="$root.rn.ConsumerLoans">forbrugslån</b-link> på nettet, er det
            hovedsageligt nedenstående parametre, der skal være styr på.
          </p>
          <h3>1. Hvor meget vil du låne?</h3>
          <p>
            Før du overhovedet kan gå i gang med at lede efter billige lån uden sikkerhed,
            er du nødt til at tage stilling til, hvor mange penge du ønsker at låne.
          </p>
          <p>
            Det kan lyde indlysende, men det er essentielt, at du låner den rette sum
            penge. Selvom du falder over et billig lån
            <b-link :to="$root.rn.UnsecuredLoans">uden sikkerhed</b-link>, bør du fortsat
            ansøge om det beløb, du reelt har brug for – hverken mere eller mindre.
          </p>
          <p>
            Låner du for lidt, kan du være nødsaget til at optage yderligere et quicklån
            senere, hvilket kan blive dyrere end nødvendigt. Låner du for meget, bliver
            det også dyrere, da de løbende renteomkostninger dermed beregnes ud fra et
            højere beløb.
          </p>
          <p>
            Når du skal vurdere, hvad du har råd til at låne, bør du følge nedenstående
            fremgangsmåde:
          </p>
          <ol>
            <li>
              Lav et detaljeret budget: Start med at lægge et budget. Med et detaljeret
              budget, får du et overblik over dine præcise udgifter samt dit månedlige
              rådighedsbeløb.
            </li>
            <li>
              Sæt et realistisk beløb: Når du skal vælge den rette månedlige ydelse, skal
              du vælge en ydelse, der hverken er for høj eller lav. Vælger du en for høj
              ydelse, risikere du, at du ikke kan overholde låneaftalen. Vælger du derimod
              en for lav ydelse, bliver det blot unødigt dyrt at betale
              <b-link :to="$root.rn.PrivateLoans">privatlånet</b-link> tilbage.
            </li>
            <li>
              Find rette løbetid: Lånets løbetid er afgørende for, hvor dyrt det bliver.
              Jo hurtigere du betaler lånet af, desto færre renter vil der blive pålagt i
              perioden. Hvad end du vil sikre, at du får det billigste kviklån, billigste
              boliglån eller
              <b-link :to="$root.rn.CarLoan">billigste billån</b-link>, er en kort løbetid
              en nødvendighed i denne sammenhæng.
            </li>
          </ol>
          <h3>2. Lang eller kort løbetid?</h3>
          <p>
            Før du kaster dig ud i at lede efter et billig lån, skal du have en idé om,
            hvor hurtigt du er i stand til at tilbagebetale det beløb, du skal låne.
          </p>
          <p>
            Hvis du har fastlagt det beløb, du ønsker at låne, og ligeledes har kendskab
            til dit månedlige rådighedsbeløb. Dermed kan du også vurdere, hvilket beløb,
            du har mulighed for at afdrage med.
          </p>
          <p>
            Det er nærliggende at tro, at det
            <b-link :to="$root.rn.ConsumerLoans">billigste forbrugslån</b-link> er det,
            der har den laveste månedlige ydelse. Vælger du et lån med længere løbetid,
            vil de samlede omkostninger og den samlede tilbagebetaling ligeledes være
            tilsvarende større.
          </p>
          <p>
            Vil du være sikker på, at du får et billig lån med
            <b-link :to="$root.rn.LoansWithLowRate">lav rente</b-link>, er det vigtigt at
            vælge et lån med kort løbetid. Det garanterer nemlig, at låneomkostningerne
            ikke løber på i en lige så lang periode.
          </p>
          <h3>3. Fast eller variabel rente?</h3>
          <p>
            Overordnet set er der to renteformer at vælge mellem, når du skal
            <b-link :to="$root.rn.LoanCalculator">optage et lån</b-link>
            fast eller variabel rente.
          </p>
          <p>
            Når du kigger på
            <b-link :to="$root.rn.LoansWithLowRate">billige lån med lav rente</b-link>,
            vil du opleve, at det som udgangspunkt er en variabel rente, der er lavest.
            Det er ikke ensbetydende med, at du ikke kan få et billigt lån med fast rente,
            hvorfor der intet er i vejen for at jagte det billigste lån med fast rente.
          </p>
          <p>
            Årsagen til, at en variabel rente som regel er den billigste løsning er, at
            den svinger i takt med markedsrenten. Det vil sige, at renten kan falde,
            hvilket vil medføre, at du får et billigere lån. Omvendt kan den også stige,
            hvorfor du kan risikere at få et dyrere lån.
          </p>
          <p>
            En fast rente er derimod, hvad navnet antyder; fast. Det vil sige, at den
            forbliver den samme under hele låneperioden. Når du skal vælge renteform, skal
            du gøre op med dig selv, om du vil satse eller gå den sikre vej.
          </p>
          <p>
            I dag fås de fleste quick lån udelukkende med fast rente. Ønsker du
            eksempelvis at <b-link :to="$root.rn.LoanOffers">søge et lån</b-link> på 5000
            kr. eller et lån på 15000 kr., er det sjældent muligt at vælge en variabel
            rente.
          </p>
          <p>
            Er der tale om et større lån, som eksempelvis et billigt billån eller et
            billig forbrugslån med stort lånebeløb, kan du hos visse udbydere selv vælge
            renteform. I et sådant tilfælde skal du derfor gøre op med dig selv, hvorvidt
            du foretrækker en fast eller variabel rente.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">
            Dit billige lån med lav rente er ikke det eneste afgørende parameter
          </h2>
          <p>
            Vil du gerne låne penge nemt, hurtigt og billigt, så lån penge på nettet. Hvad
            enten du leder efter billige forbrugslån, billige billån eller en helt tredje
            låntype, er det vigtigt at være opmærksom på, at renten ikke er det eneste
            afgørende parameter.
          </p>
          <p>
            Til trods for, at hovedparten af de billige lån har
            <b-link :to="$root.rn.LoansWithLowRate">lav rente</b-link>, er der også andre
            omkostninger forbundet med at
            <b-link :to="$root.rn.LoanOptions">søge et lån</b-link>.
          </p>
          <p>
            Når du vil sammenligne forskellige lånemuligheder, er der derfor flere
            faktorer at tage højde for. Blandt andet er der også visse etablerings- og
            gebyromkostninger, der skal betales – og disse er naturligvis ikke indregnet i
            lånets rente.
          </p>
          <p>
            Derfor rådes du til at sammenligne forskellige lånetilbud med afsæt i ÅOP. Det
            er en forkortelse for Årlige Omkostninger i Procent, og er et tal, der
            omfatter lånets rente samt de forskellige gebyrer, der pålægges
            <b-link :to="$root.rn.PrivateLoans">privatlånet</b-link>
            (etableringsomkostninger, administrationsgebyrer og fakturering).
          </p>
          <p>
            Vær dog opmærksom på, at ÅOP udelukkende kan bruges som direkte
            sammenligneligt parameter, hvis lånetilbuddenes løbetid og lånebeløb er
            identiske. Er det ikke tilfældet, er der andre muligheder for at sammenligne
            tilbuddene.
          </p>
          <h3>Hold øje med lånet samlede tilbagebetaling</h3>
          <p>
            Hvad koster det at låne et vist beløb? Det er sandsynligvis noget, de fleste
            har interesse i at vide, før de optager et lån.
          </p>
          <p>
            Når du skal vurdere,
            <b-link :to="$root.rn.LoanCalculator">hvad et givent lån vil koste</b-link>,
            kan ÅOP'en give en nogenlunde idé om det. Vil du gerne vide, hvad det koster i
            kroner og øre, er det derimod den samlede tilbagebetaling, du skal holde øje
            med.
          </p>
          <p>
            I og med, at det er den samlede tilbagebetaling, der kigges på, er det også
            muligt at bruge dette tal til at sammenligne forskellige privatlån. Det vil
            sige, at du sagtens vil kunne sammenligne et hurtig lån med en løbetid på 24
            måneder med et lån på tilsvarende beløb med løbetid på seks måneder.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">Lån penge billigt – er det muligt for alle?</h2>
          <p>
            Du har sandsynligvis hørt, at ønsker man undgå bankernes mange og strenge
            krav, så lån penge billigt online. Men er det ensbetydende med, at de
            onlinebaserede lånevirksomheder ingen krav stiller til deres låntagere og er
            det muligt for alle?
          </p>
          <p>
            Nej, ikke helt. Dog er kravene både færre og mildere, hvorfor de fleste kan få
            bevilliget et online lån. Dermed ikke sagt, at alle kan få et billigt lån med
            lav rente på nettet.
          </p>
          <p>
            Derfor bør du undersøge krav og betingelser hos de gældende låneudbydere, før
            du indsender en låneansøgning. Hos samtlige udbydere vil du naturligvis blive
            bedt om at angive en række oplysninger om din økonomiske situation.
          </p>
          <p>
            Ud fra de angivne økonomiske oplysninger, foretages en kreditvurdering. Her
            vurderer långiver, hvorvidt din økonomi kan bære det ønskede lån. Ligeledes er
            det også med udgangspunkt i denne, at långiveren fastsætter lånets rente.
          </p>
          <p>
            Da det er en risikovurdering fra långivers side, medfører en bedre
            kreditværdighed selvfølgelig en bedre låneaftale. Derfor vil det afspejle sig
            direkte i lånets rente og vilkår, hvis det vurderes, at du har en god
            kreditværdighed.
          </p>
          <p>
            Mange låneudbydere har også alderskrav. Selvfølgelig er de alle forpligtet til
            at sikre, at du er fyldt 18 år og dermed er myndig. Langt fra alle aktører er
            dog villige til at yde lån til 18-årige, hvorfor de i stedet har fastsat en
            minimumsalder på 21, 23 eller 25 år.
          </p>
          <h3>Billigt og hurtigt lån trods RKI – kan det lade sig gøre?</h3>
          <p>
            Vil du gøre dig forhåbninger om at låne penge, må du ikke være registreret i
            RKI. Det er ikke blot gældende, hvis du vil optage et hurtigt lån hos en
            onlinebaseret udbyder – det udelukker dig nemlig også fra enhver lånemulighed
            i banken.
          </p>
          <p>
            Derfor er det ikke muligt at optage et lån trods RKI, hvis du er stemplet som
            dårlig betaler i Ribers Kredit Information. Før du kan gøre dig forhåbninger
            om et lån, er du derfor nødt til at få slettet din registrering.
          </p>
          <p>Registreringen i RKI kan fjernes på to måder:</p>
          <ul>
            <li>Du kan betale den gæld, der er skyld i din registrering</li>
            <li>
              Du kan vente fem år, hvorefter din registrering som dårlig betaler
              automatisk fjernes
            </li>
          </ul>
          <p>
            Sidstnævnte løsning lyder sandsynligvis appellerende, men vær dog opmærksom
            på, at den slettede registrering ikke er ensbetydende med, at din gæld er væk.
            Din gæld vil fortsat eksistere – det er blot din registrering i RKI, der
            fjernes.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">Få et billigere lån med en medansøger</h2>
          <p>
            Ikke alle har muligheden, men såfremt det er muligt, er det fornuftigt at
            overveje at ansøge om et lån med en medansøger med chancen om at få et
            billigere lån. Der er stor sandsynlighed for, at det vil resultere i et bedre
            tilbud, hvis du ansøger med en anden person, end hvis du ansøger alene.
          </p>
          <p>
            Årsagen til dette er, at låneudbyderen vurderer, at jeres fælles
            kreditværdighed er bedre, da I er to om at dele låneforpligtelserne. Det
            minimerer selvfølgelig långivers risiko, hvorfor de trygt kan sænke
            renteniveauet.
          </p>
          <h3>Ikke et krav, at medansøger er din partner</h3>
          <p>
            Mange tror fejlagtigt, at det er et krav, at det er en samleverske, der skal
            være medansøger. Sådan forholder det sig dog slet ikke. Derimod kan det være
            en hvilken som helst person, der er din medansøger. Derfor kan det sagtens
            være en ven, et familiemedlem e.l.
          </p>
          <p>
            Endvidere er dette ensbetydende med, at din medansøger ikke nødvendigvis
            behøver at være en person, som du deler adresse eller økonomi med.
          </p>
          <p>
            I denne sammenhæng er det vigtigt at være opmærksom på, at I begge hæfter for
            lånet. Hvis den ene part misligholder lånet, er den anden part forpligtet til
            at betale. Af denne årsag er det vigtigt at vælge en medansøger, som du stoler
            på.
          </p>
        </div>
      </div>
      <AboutUsWidget />

      <CTAScrollerComponent elementId="preform-container" />
    </div>
  </div>
</template>
<script>
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  data() {
    return {
      contents: [
        {
          title: 'Lån billigt i samarbejde med Saverium.dk',
          link: '#1',
        },
        {
          title: 'Sådan finder du de billigste lån på nettet',
          link: '#2',
        },
        {
          title: 'Dit billige lån med lav rente er ikke det eneste afgørende parameter',
          link: '#3',
        },
        {
          title: 'Lån penge billigt – er det muligt for alle?',
          link: '#4',
        },
        {
          title: 'Få et billigere lån med en medansøger',
          link: '#5',
        },
      ],
    }
  },
  methods: {
    setLoan(value) {
      this.loanamount = value
      const key = 'amount'
      this.$store.commit('updateApplication', { key, value })
      this.$router
        .push({
          name: 'DynamicLoanPage',
          params: { loanamount: this.loanamount },
        })
        .catch(() => {})
    },
  },
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },

  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
