<template>
  <div class="article-wrapper" data-type="1" data-language="pl">
    <div class="article row col-12">
      <h1 class="center-title">Kredyt rewolwingowy - co to jest?</h1>
      <div class="row col-12">
        <div class="article-container col-12 col-lg-8">
          <div class="text-container">
            <p>
              Kredyt na ogół kojarzy się z pewną sumą pieniędzy, którą bank udostępnia
              klientowi. Transfer środków ma charakter jednorazowy - po spłacie kredytu
              umowa pomiędzy stronami wygasa. Niemniej jednak istnieje inny rodzaj
              kredytu, zwany rewolwingowym. Cieszy się on szczególną popularnością wśród
              przedsiębiorców, choć mogą też z niego korzystać także rolnicy. Co to jest
              kredyt rewolwingowy i kiedy warto takowy zaciągnąć?
            </p>
            <b-img-lazy
              width="700"
              height="466"
              blank-src="/static/img/saverium_pl/article-images/fast-online-checkout-tiny.jpg"
              blank-height="700px"
              blank-width="466px"
              class="article-img"
              src="/static/img/saverium_pl/article-images/fast-online-checkout.jpg"
              alt="Szybka kasa przez internet - jak się ubiegać?"
            />
            <div class="toc container">
              <h3>{{ $t('table_of_contents') }}</h3>
              <ul class="table-of-contents">
                <li>
                  <a href="#kredyt-rewolwingowy-charakterystyka-produktu"
                    >Kredyt rewolwingowy - charakterystyka produktu</a
                  >
                </li>
                <li>
                  <a href="#kredyt-rewolwingowy-a-kredyt-w-rachunku-biezacym"
                    >Kredyt rewolwingowy, a kredyt w rachunku bieżącym</a
                  >
                </li>
                <li>
                  <a href="#kredyt-rewolwingowy-a-obrotowy"
                    >Kredyt rewolwingowy, a obrotowy</a
                  >
                </li>
                <li>
                  <a
                    href="#jakie-wymagania-trzeba-spelnic-aby-otrzymac-kredyt-rewolwingowy"
                    >Jakie wymagania trzeba spełnić, aby otrzymać kredyt rewolwingowy?</a
                  >
                </li>
                <li>
                  <a href="#czy-warto-zaciagnac-kredyt-rewolwingowy"
                    >Czy warto zaciągnąć kredyt rewolwingowy?</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="text-container">
            <h2 id="kredyt-rewolwingowy-charakterystyka-produktu">
              Kredyt rewolwingowy - charakterystyka produktu
            </h2>
            <p>
              Kredyt rewolwingowy ma charakter
              <a
                href="https://www.saverium.pl/kredyt-odnawialny/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>kredytu odnawialnego</strong></a
              >, a swą konstrukcją przypomina kartę kredytową bądź kredyt w rachunku
              bieżącym. Klient podpisuje z bankiem umowę, na mocy której może korzystać z
              odnawialnego limitu. Po dokonaniu spłaty zadłużenia bądź jedynie odsetek,
              limit ponownie jest dostępny dla klienta. Kredyt rewolwingowy charakteryzuje
              się tym, że:
            </p>
            <ul>
              <li>
                przyznawany jest na krótki okres czasu - umowa zwykle zawarta jest na 12
                miesięcy, choć można też znaleźć oferty długoterminowe,
              </li>
              <li>środki można przeznaczyć na dowolny cel,</li>
              <li>środki można wykorzystać do wysokości przyznanego limitu.</li>
            </ul>
            <p>
              Kredyt rewolwingowy może mieć różną konstrukcję - czasami bank wymaga, aby
              spłacić kapitał wraz z odsetkami, zanim limit ulegnie odnowieniu.
              Najczęściej jednak klient w czasie trwania umowy spłaca jedynie odsetki,
              natomiast kapitał może zostać spłacony w dowolnym momencie, także po
              zakończeniu okresu obowiązywania umowy.
            </p>
          </div>

          <div class="text-container">
            <h2 id="kredyt-rewolwingowy-a-kredyt-w-rachunku-biezacym">
              Kredyt rewolwingowy, a kredyt w rachunku bieżącym
            </h2>
            <p>
              Wiedząc, na czym polega kredyt rewolwingowy, nietrudno zauważyć, że
              przypomina on nieco kredyt w rachunku bieżącym bądź kredyt, który udzielany
              jest w ramach karty kredytowej. Niemniej jednak pomiędzy poszczególnymi
              produktami istnieje kilka różnic. Kredyt rewolwingowy, w przeciwieństwie do
              kredytu w rachunku bieżącym, nie wymaga natychmiastowej spłaty. W drugim
              przypadku, jeśli chcemy odnowić limit, musimy oddać pożyczony kapitał wraz z
              odsetkami. Ponadto, zaciągnięcie kredytu w rachunku bieżącym wymaga
              posiadania konta osobistego, na które na bieżąco wpływają środki finansowe.
              Zazwyczaj tego typu kredyt przyznawany jest stałym klientom, którzy osiągają
              odpowiednio wysokie wpływy na rachunek.
            </p>
            <p>
              Tymczasem kredyt rewolwingowy umożliwia dokonanie jednorazowej spłaty
              kapitału bądź spłacanie go w transzach. Klient zobowiązany jest jedynie do
              bieżącego regulowania odsetek, wynikających z umowy. Co więcej, po kredyt w
              rachunku bieżącym najczęściej sięgają osoby fizyczne, nieprowadzące
              działalności gospodarczej. Jest on dobrym sposobem na podreperowanie
              domowego budżetu. Kredyt rewolwingowy zwykle jest zaciągany przez firmy,
              które potrzebują stałego dopływu gotówki, aby móc rozwijać prowadzoną
              działalność.
            </p>
          </div>

          <div class="text-container">
            <h2 id="kredyt-rewolwingowy-a-obrotowy">Kredyt rewolwingowy, a obrotowy</h2>
            <p>
              Kredyt rewolwingowy inaczej nazywany jest kredytem obrotowym, bowiem z
              angielskiego słowo "revolving" oznacza właśnie "obrotowy". Umożliwia on
              pozyskanie środków finansowych w dowolnym momencie, na przykład w przypadku
              przejściowych problemów z płynnością. Jeśli przedsiębiorca potrzebuje
              pieniędzy na dokonanie płatności za fakturę czy zrealizowanie większych
              zakupów, może sięgnąć po gotówkę pochodzącą z kredytu obrotowego. Pieniądze
              można przeznaczyć na dowolny cel, jednak musi być on związany z prowadzoną
              działalnością gospodarczą. Kredyt obrotowy dedykowany jest zarówno
              początkującym przedsiębiorcom, jak i tym, którzy na rynku działają już od
              dłuższego czasu. Zazwyczaj wymaga się, aby działalność była prowadzona przez
              co najmniej pół roku, zanim bank podpisze z klientem umowę kredytową.
            </p>
          </div>

          <div class="text-container">
            <h2 id="jakie-wymagania-trzeba-spelnic-aby-otrzymac-kredyt-rewolwingowy">
              Jakie wymagania trzeba spełnić, aby otrzymać kredyt rewolwingowy?
            </h2>
            <p>
              Kredyt obrotowy posiada w swojej ofercie każdy większy bank. Każda
              instytucja określa warunki kredytowania indywidualnie, jednak podstawowym
              wymogiem jest posiadanie zdolności kredytowej. Do jej oceny wykorzystuje się
              dokumentację finansową jednostki. Do banku należy więc dostarczyć takie
              dokumenty, jak:
            </p>
            <ul>
              <li>sprawozdanie finansowe za rok ubiegły,</li>
              <li>zeznanie podatkowe z poprzedniego roku,</li>
              <li>zaświadczenie o niezaleganiu w płatnościach składek ZUS i podatków,</li>
              <li>
                dokumentację z KRS bądź CEiDG, w zależności od tego, gdzie zarejestrowana
                jest firma.
              </li>
            </ul>
            <p>
              Banki wymagają od swoich klientów, aby prowadzili oni działalność
              gospodarczą przez co najmniej pół roku, zanim złożą wniosek o kredyt.
              Niektóre instytucje zaostrzają ten wymóg - kredyt może otrzymać jedynie
              przedsiębiorca, który prowadzi firmę co najmniej przez 12 miesięcy. Ponadto,
              szansę na pozyskanie finansowania mają jedynie ci przedsiębiorcy, którzy
              osiągają zysk z prowadzonej działalności gospodarczej. Ponoszone straty
              przekreśla szansę na uzyskanie kredytu. Banki weryfikują także historię
              kredytową firmy, a więc sprawdzają, czy nie figuruje ona w bazach dłużników.
              Warto pamiętać, że niektóre bazy gromadzą informacje nie tylko o
              niespłaconych kredytach, lecz również o fakturach, rachunkach czy innych
              zobowiązaniach, za które nie dokonano płatności w terminie. W niektórych
              przypadkach banki oceniają
              <a
                href="https://www.saverium.pl/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>zdolność kredytową</strong></a
              >
              samej firmy, a także jej właścicieli, zwłaszcza w przypadku działalności
              jednoosobowych.
            </p>
          </div>

          <div class="text-container">
            <h2 id="czy-warto-zaciagnac-kredyt-rewolwingowy">
              Czy warto zaciągnąć kredyt rewolwingowy?
            </h2>
            <p>
              Kredyt obrotowy stanowi dla przedsiębiorców kolejne źródło finansowania, po
              które mogą sięgnąć w dowolnym momencie, przez cały okres obowiązywania
              umowy. Jego podstawową zaletą jest fakt, iż środki pieniężne można
              przeznaczyć na dowolny cel, w tym również na spłatę zobowiązań. Ponadto,
              przedsiębiorca nie musi regulować rat w konkretnych terminach - na bieżąco
              spłaca się głównie odsetki, natomiast kapitał można oddawać okresowo, co
              sprawia, że kredyt staje się bardziej elastyczny.
            </p>
            <p>
              Przedsiębiorcy, którzy potrzebują dodatkowych środków pieniężnych, nie muszą
              składać wniosków o kredyt i oczekiwać na ich rozpatrzenie - pieniądze
              dostępne są niemalże od ręki przez cały okres obowiązywania umowy,
              oczywiście do wysokości przyznanego limitu. Kredyt rewolwingowy stanowi więc
              pomocne narzędzie finansowania, jednak należy pamiętać, że zobowiązanie to
              będzie trzeba, prędzej czy później, spłacić. Stąd też dobrze zawczasu
              zabezpieczyć środki, zwłaszcza jeśli spłata zadłużenia nastąpi pod koniec
              okresu kredytowania.
            </p>
          </div>
        </div>
        <div class="container container-widget col-12 col-lg-4">
          <h4>{{ $t('get_a_loan_easily') }}</h4>
          <LoanWidget class="small-range mt-4" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div style="margin: auto">
        <RelatedArticlesComponent />
      </div>
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
export default {
  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
