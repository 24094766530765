import Vue from 'vue'
import VueI18n from 'vue-i18n'
import pl from './pl.json'
import en from './en.json'
import es from './es.json'
import fi from './fi.json'
import fiTos from './tos_fi'
import settings from '@/settings/settings'
import dk from './dk.json'

Vue.use(VueI18n)

const locale = settings.LOCALE

const numberFormats = {
  pl: {
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
    },
    currency: {
      style: 'decimal',
      minimumFractionDigits: 0,
    },
  },
  es: {
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
  },
  dk: {
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
    },
    currency: {
      style: 'decimal',
      minimumFractionDigits: 0,
    },
  },
  fi: {
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
    },
  },
}

if (locale === 'fi') {
  fi.tos = fiTos
} else {
  fi.tos = ''
}

const messages = {
  pl,
  en,
  es,
  fi,
  dk,
}

// Now doesn't spam "translation not found", change silentTranslationWarn to false if needed to debug.
const i18n = new VueI18n({
  locale,
  messages,
  numberFormats,
  silentTranslationWarn: true,
})

export default i18n
