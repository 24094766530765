import Vue from 'vue'
import router from '@/router'

// Common offer actions for saverium-brands. Imported from @/store/<brand>/modules/offers.js.

const RN = router.routes

export default {
  async fetchOffers({ commit, rootState }) {
    const uuid = Vue.saverium.getApplicationUUID()
    const token = await Vue.saverium.getAccessToken()
    if (uuid && token) {
      // const offers = testOffer;
      const offers = await Vue.saverium.getOffers(token, uuid)
      if (offers.response && offers.response.status === 404) {
        router.replace(RN.paths.MainView)
      } else {
        commit('setOffers', offers)
      }
    } else {
      router.replace(RN.paths.MainView)
    }
  },

  async fetchOffersByUUID({ commit }, { applicationUUID }) {
    const uuid = Vue.saverium.getApplicationUUID()
    const accessToken = await Vue.saverium.getAccessToken()
    // Check that uuid length is 36 characters
    const validUUID = applicationUUID.length === 36
    if (!validUUID) {
      return router.replace(RN.paths.MainView)
    }
    if (accessToken && uuid && uuid === applicationUUID) {
      // Same as existing application uuid
      const offers = await Vue.saverium.getOffers(accessToken, uuid)
      if (offers.response && [404, 401, 400].includes(offers.response.status)) {
        return router.push(RN.paths.LogoutView)
      }
      Vue.saverium.setApplicationUUID(applicationUUID)
      commit('setOffers', offers)
      router.replace(RN.paths.LoanOfferView)
    } else if (accessToken) {
      // Token found but application uuid is different
      Vue.saverium.setApplicationUUID(applicationUUID)
      router.replace(RN.paths.LoanOfferView)
      const offers = await Vue.saverium.getOffers(accessToken, applicationUUID)
      commit('setOffers', offers)
    } else {
      // Token expired, show login form
      commit('setShowLoginForm', true)
    }
  },

  async acceptOffer({ commit, state }, { uuid }) {
    const token = await Vue.saverium.getAccessToken()
    if (token && uuid) {
      const response = await Vue.saverium.acceptOfferOption(token, uuid, {
        action: 'accept',
      })
      if (response.status === 'ok') {
        commit('addAcceptOffer', uuid)
      }
      if (response.sms_verification === true) {
        commit('verifySMS', state, state.sms_verification)
      }
      return response
    }
  },

  async sendVerificationCode({ commit, state }, { uuid, verificationCode }) {
    const token = await Vue.saverium.getAccessToken()
    if (token && uuid && verificationCode) {
      const response = await Vue.saverium.acceptOfferOption(token, uuid, {
        action: 'verify_sms',
        verification_code: verificationCode,
      })
      if (response.status === 'ok') {
        commit('addAcceptOffer', uuid)
        commit('showSuccess', state)
      }
      if (response.error) {
        commit('showError', state)
      } else if (response.redirect) {
        window.location.href = response.redirect
      }
    }
  },
}
