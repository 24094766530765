<template>
  <b-container class="container">
    <b-row>
      <b-col cols="12" sm="6" class="resetPassword">
        <h1 class="page-header">{{ $t('password_recovery') }}</h1>
        <div class="well">
          <div class="alert alert-danger alert-dismissable" v-if="this.error">
            <button class="close" type="button" v-on:click="error = false">×</button>
            {{ $t('somethin_went_wrong_with_password_recovery') }}
          </div>
          <div class="alert alert-info alert-dismissible" v-if="this.success">
            {{ $t('email_has_been_sent_to') }}
            {{ email }}.
            {{ $t('check_email_and_not_mistyped') }}
          </div>
          <form method="post" @submit.prevent="submitEmail()" v-if="!this.success">
            <label for="email">{{ $t('email') }}</label>
            <b-form-input
              type="email"
              class="w-100"
              name="email"
              id="password"
              v-model="email"
            ></b-form-input>
            <hr />
            <button type="submit" class="btn btn-primary btn-lg btn-block">
              <i class="fa fa-fw fa-sign-in"></i>
              {{ $t('send') }}
            </button>
          </form>
        </div>
        <hr />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Input from '@/components/form/Input'
import settings from '@/settings/settings'

export default {
  data() {
    return {
      success: false,
      error: false,
      email: '',
    }
  },
  components: {
    'form-input': Input,
  },
  computed: {},
  methods: {
    async submitEmail() {
      let formData = new FormData(document.querySelector('form'))
      formData.append('language', settings.LOCALE)
      let response = await this.$store.dispatch('resetPassword', formData)
      if (response) {
        this.success = true
      } else {
        this.error = true
      }
    },
  },
}
</script>

<style scoped>
@media (min-width: 572px) {
  .resetPassword {
    margin-left: 25%;
  }
}
</style>
