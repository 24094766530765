<template>
  <div class="row article">
    <h1 class="center-title">
      Kredyt gotówkowy – kalkulator i oferty pożyczek gotówkowych
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >Kredyt gotówkowy pod wieloma względami jest podobny do pożyczki, a niekiedy
              nawet do chwilówki online. Sprawdźmy, co odróżnia takie produkty i z jakimi
              kosztami dla klienta wiąże się zaciągnięcie kredytu gotówkowego?</i
            >
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/polish-money-hand-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/polish-money-hand.jpg"
            alt="Pożyczki chwilówki online – kredyty chwilówki przez internet"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
          <h2 id="1">
            {{ contents[0].title }}
          </h2>
          <p>
            Podstawowym produktem kredytowym oferowanym przez banki jest kredyt gotówkowy.
            Takie zobowiązanie udzielane jest w formie pożyczki gotówkowej czy kredytu
            konsumenckiego. Pozwala on na realizowanie dowolnych celów finansowych przez
            klientów, ponieważ banki nie wymagają, by podawać we wniosku, na co zostanie
            on wydatkowany.
          </p>
          <p>
            Podstawą prawną dla kredytów gotówkowych są przepisy Prawa bankowego. Według
            nich takie zobowiązanie udzielane jest na podstawie pisemnej umowy, o ile
            chodzi o kwotę co najmniej 1000 zł.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>Kredyty gotówkowe mogą przybrać formę:</p>
          <ul>
            <li>pożyczki ratalnej,</li>
            <li>kredytu udzielanego w ramach karty kredytowej,</li>
            <li>limitu na koncie osobistym,</li>
            <li>kredytu gotówkowego online.</li>
          </ul>
          <p>
            Zdecydowana większość kredytów gotówkowych określana jest także mianem
            kredytów konsumenckich. Art. 3. Ustawy z dnia 12 maja 2011 roku o kredycie
            konsumenckim wskazuje, że takim zobowiązaniem jest umowa dotycząca kredytu nie
            większego niż 255 550 zł albo równowartości tej kwoty w walucie innej niż
            polska. Kredyt w banku udzielany jest klientowi, będącemu konsumentem w
            rozumieniu przepisów Kodeksu cywilnego. W dalszych przepisach tej samej ustawy
            można przeczytać, że umową o kredyt konsumencki będzie w szczególności umowa
            kredytu bankowego.
          </p>
          <p>
            Jeśli przyjmiemy, że kredyt gotówkowy to kredyt konsumencki, to ma on kilka
            cech charakterystycznych, takich jak:
          </p>
          <ul>
            <li>dowolny cel wydatkowania środków,</li>
            <li>uproszczona procedura udzielania zobowiązania,</li>
            <li>elastyczny okres kredytowania, od kilku miesięcy do nawet 10 lat,</li>
            <li>
              stosunkowo duża kwota zobowiązania, mieszczącą się w przedziale od kilku do
              kilkuset tysięcy złotych.
            </li>
          </ul>
          <p>
            Warto zwrócić uwagę na to, że tylko banki i spółdzielcze kasy
            oszczędnościowo-kredytowe mogą udzielać kredytów osobom prywatnym i
            przedsiębiorcom.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Obecnie coraz więcej banków ma w swojej ofercie kredyty gotówkowe online. W
            którym banku można zaciągnąć takie zobowiązanie całkowicie przez internet? Z
            pewnością w wielu, ponieważ takie instytucje w wyniku pandemii COVID-19
            musiały zmienić swoje podejście do kwestii przeprowadzania procesu
            kredytowania. W efekcie nawet klienci, którzy wcześniej zobowiązani byli
            pofatygować się do banku celem zaciągnięcia kredytu gotówkowego, teraz
            wszystkie sprawy formalne z tym związane mogą załatwić przez internet.
          </p>
          <p>
            Pożyczki bankowe i kredyty gotówkowe w bankach w pierwszej kolejności
            udostępniane są online klientom, którzy mają dostęp do bankowości
            elektronicznej w tej samej instytucji finansowej. Jest to spowodowane tym, że
            łatwiej zweryfikować ich tożsamość czy wiarygodność na podstawie historii
            rachunku, do którego bank ma dostęp.
          </p>
          <p>
            Jeśli zaś chodzi o oferty kredytowe kierowane do nowych klientów banków, to
            najczęściej należy najpierw założyć konto osobiste w danej instytucji, by
            później móc zweryfikować swoją tożsamość, np. przelewem jednorazowym,
            wnioskując przez internet o pożądany produkt. Obowiązkiem klienta chcącego
            ubiegać się o takie zobowiązanie jest złożenie elektronicznego wniosku wraz z
            wymaganymi załącznikami do banku. Umowa kredytowa może być zawarta na
            odległość za pomocą bankowości elektronicznej, kuriera lub klient może być
            zaproszony do najbliższej placówki bankowej celem złożenia na niej swojego
            podpisu.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Uprawnionym do zaciągnięcia kredytu gotówkowego może być osoba fizyczna lub
            prawna, która ma odpowiednio wysoką zdolność kredytową, czyli zdolność do
            spłaty zobowiązania wraz z odsetkami i pozostałymi naliczonymi opłatami. Wśród
            innych ważnych warunków do spełnienia przez potencjalnych klientów, którym
            zależy na otrzymaniu pożyczki gotówkowej czy kredytu gotówkowego, są:
          </p>
          <ul>
            <li>ukończenie 18. roku życia,</li>
            <li>uzyskanie pełnej zdolności do czynności prawnych,</li>
            <li>
              posiadanie dobrej historii kredytowania w Biurze Informacji Kredytowej
              (BIK),
            </li>
            <li>
              złożenie formalnego wniosku kredytowego, wraz z wymaganymi załącznikami.
            </li>
          </ul>
          <p>
            Udzielenie kredytu gotówkowego jest uznaniowe, co oznacza, że banki nie mają
            obowiązku przyznania go każdemu, kto złoży wniosek. Jedna instytucja finansowa
            odmówi wypłaty środków, a inna udzieli zobowiązania finansowego.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            Dzięki uzyskaniu kredytu gotówkowego klient może zrealizować dowolne cele, a
            bank nie może mu ograniczać możliwości spożytkowania uzyskanych pieniędzy.
            Otrzymaną kwotę można przeznaczyć nawet na spłatę rat innych zobowiązań, choć
            nie jest to najlepsza decyzja ze strony kredytobiorcy, ponieważ łatwo może on
            przez to wpaść w tzw. spiralę zadłużenia.
          </p>
          <p>
            Dzięki promocyjnym ofertom na tanie kredyty gotówkowe online bez wychodzenia z
            domu można uzyskać pieniądze z banku z wypłatą na wskazane konto i zapłacić za
            takie zobowiązanie możliwie jak najniższe odsetki.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            By móc zaciągnąć korzystny kredyt, z niskim oprocentowaniem, należy wyszukać
            odpowiednią propozycję. Zobowiązania z niskim oprocentowaniem często oferowane
            są nowym klientom, celem przyciągnięcia ich uwagi. Z drugiej strony,
            atrakcyjny kredyt może być zaproponowany także dotychczasowemu klientowi, z
            którym współpraca z bankiem układa się bardzo dobrze.
          </p>
          <p>
            Dokumentem, na podstawie którego możemy się ubiegać o kredyty gotówkowe jest
            wniosek składany na formularzu bankowym. By móc zaciągnąć zobowiązanie, należy
            jeszcze złożyć zaświadczenie o wysokości uzyskiwanych dochodów, choć niektóre
            instytucje mogą zażądać np. wyciągu z konta z kilku ostatnich miesięcy dla
            potwierdzenia odpowiednio wysokiej zdolności kredytowej zainteresowanego
            ofertą.
          </p>
        </div>
        <div class="text-container">
          <h2 id="7">{{ contents[6].title }}</h2>
          <p>
            Pod względem formalnym istnieje wiele cech odróżniających pożyczki gotówkowe w
            bankach od ofert pozabankowych. Instytucje bankowe udzielają zobowiązań na
            podstawie przepisów Prawa bankowego czy ustawy o kredycie konsumenckim. Do
            takiego działania uprawnione są wyłącznie banki, których działalność
            regulowana jest przez Komisję Nadzoru Finansowego.
          </p>
          <p>
            Z kolei chwilówki online, czyli pożyczki gotówkowe pozabankowe, oferowane są
            przez firmy działające poza sektorem bankowym, na podstawie przepisów Kodeksu
            cywilnego. Przyznawane są one nawet w trybie online, przy uproszczonym
            procesie kredytowym.
          </p>
          <p>
            O ile wszystkie banki przy udzielaniu kredytów gotówkowych sprawdzają
            wiarygodność klienta w BIK-u, o tyle firmy pozabankowe robią to rzadko, a
            jeśli się na to decydują, to nawet przy negatywnych wpisach w BIK-u klient ma
            szansę na uzyskanie pożyczki.
          </p>
        </div>
        <div class="text-container">
          <h2 id="8">{{ contents[7].title }}</h2>
          <p>
            Niektóre banki udzielą korzystnego kredytu gotówkowego, nawet przy
            wnioskowaniu online, w ciągu kilkunastu minut od chwili złożenia wniosku. Na
            decyzję nie trzeba więc obecnie długo czekać, co pozwala zrównać ze sobą
            szybkość udzielania kredytów i pożyczek – w banku i w firmach pozabankowych.
          </p>
        </div>
        <div class="text-container">
          <h2 id="9">{{ contents[8].title }}</h2>
          <p>
            Zgodnie z prawem umowa kredytu gotówkowego powinna być zawarta na piśmie,
            jeśli udzielany jest on na kwotę 1000 zł lub wyższą. W ramach takiej umowy
            bank zobowiązuje się oddać do dyspozycji klienta, na oznaczony czas, kwotę
            środków pieniężnych z przeznaczeniem na ustalony cel (np. konsumpcję). W tej
            samej umowie kredytobiorca zobowiązuje się do korzystania z zobowiązania na
            warunkach określonych w jej treści. W art. 69. Prawa bankowego dodano, że
            zwrotu kwoty wykorzystanego kredytu wraz z odsetkami klient musi dokonać w
            umówionym uprzednio terminie.
          </p>
        </div>
        <div class="text-container">
          <h2 id="10">{{ contents[9].title }}</h2>
          <p>
            Za sprawą obniżanych drastycznie w 2020 roku podstawowych stóp procentowych w
            Polsce przez Radę Polityki Pieniężnej (RPP) działającą przy Narodowym Banku
            Polskim(NBP), można dziś zaciągać w polskich bankach kredyty z niskim
            oprocentowaniem.
          </p>
          <p>
            Kodeks cywilny przewiduje maksymalną wysokość oprocentowania kredytów i
            pożyczek – wartość ta może osiągnąć dwukrotność odsetek ustawowych. Odsetki te
            wynoszą tyle, ile suma stopy referencyjnej i 3,5 punktu procentowego, czyli
            obecnie jest to 3,6 proc. W rezultacie najwyższe aktualnie (październik 2020
            roku) oprocentowanie kredytu gotówkowego, jak i pożyczki pozabankowej to 7,2
            proc. w skali roku.
          </p>
        </div>
        <div class="text-container">
          <h2 id="11">{{ contents[10].title }}</h2>
          <p>
            Kredyt gotówkowy zaciągnięty w banku musi być spłacany zgodnie z
            harmonogramem, który jest załącznikiem do zawieranej z bankiem umowy. Taki
            harmonogram uwzględnia terminy comiesięcznej płatności raty
            kapitałowo-odsetkowej wraz np. ze składką na ubezpieczenie spłaty
            zobowiązania. Klient ma jednak możliwość spłacenia całości lub części kredytu
            przed terminem wskazanym w umowie.
          </p>
        </div>
        <div class="text-container">
          <h2 id="12">{{ contents[11].title }}</h2>
          <p>
            Możliwość przedterminowej spłaty całości lub części zobowiązania
            zagwarantowana jest przepisami ustawy o kredycie konsumenckim (art. 48). W
            umowie z bankiem powinny być wskazane warunki wcześniejszej całościowej lub
            częściowej spłaty. Niewykluczone, że bank pobierze z tego tytułu pewne
            dodatkowe opłaty. W przypadku kredytu konsumenckiego obowiązkiem kredytodawcy
            jest proporcjonalne obniżenie i dokonanie zwrotu wszystkich kosztów
            kredytowania.
          </p>
        </div>
        <div class="text-container">
          <h2 id="13">{{ contents[12].title }}</h2>
          <p>
            Chcesz zaciągnąć korzystną pożyczkę z niskim oprocentowaniem? Złóż wniosek
            <b-link :to="$root.rn.LoanView">tutaj</b-link>. Podaj interesującą Cię kwotę,
            okres spłaty, dane adresowe, osobowe, informacje o zatrudnieniu i pozostałe,
            których potrzebujemy. Wnioskiem zajmiemy się natychmiast po jego otrzymaniu.
            Pieniądze mogą trafić na Twoje konto nawet w ciągu kilku minut!
          </p>
        </div>
      </div>
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'

import { generateStructuredArticleData } from '@/utils/utils'
export default {
  data() {
    return {
      contents: [
        {
          title: 'Co to jest kredyt gotówkowy?',
          link: '#1',
        },
        {
          title: 'Kredyt gotówkowy – podstawowe informacje',
          link: '#2',
        },
        {
          title: 'Jak uzyskać kredyt gotówkowy online?',
          link: '#3',
        },
        {
          title: 'Kto może wziąć kredyt gotówkowy?',
          link: '#4',
        },
        {
          title: 'Jakie są korzyści kredytu gotówkowego?',
          link: '#5',
        },
        {
          title: 'Czego potrzebuję, aby wziąć kredyt gotówkowy?',
          link: '#6',
        },
        {
          title: 'Kredyt gotówkowy a pożyczka pozabankowa',
          link: '#7',
        },
        {
          title: 'Jak długo trzeba czekać na decyzję kredytową?',
          link: '#8',
        },
        {
          title: 'Umowa kredytu gotówkowego',
          link: '#9',
        },
        {
          title: 'Oprocentowanie kredytu gotówkowego',
          link: '#10',
        },
        {
          title: 'Spłata kredytu gotówkowego',
          link: '#11',
        },
        {
          title: 'Wcześniejsza spłata kredytu gotówkowego',
          link: '#12',
        },
        {
          title: 'Złóż wniosek o pożyczkę gotówkową',
          link: '#13',
        },
      ],
    }
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
<style lang="scss" scoped></style>
