<template>
  <div class="row article non-landing">
    <div class="col-xl-8 col-12">
      <h1 class="center-title">
        Hvor meget kan jeg få udbetalt ved at optage et lån? – Og er det en god ide?
      </h1>
      <div class="text-container">
        <p>
          <i>
            Hvor mange penge du kan låne når du ansøger om det online, afhænger meget af
            hvor du ansøger om lånet, til hvilket formål og hvilket beløb du selv har
            tænkt på forhånd. Lånets størrelse bestemmes af de banker og
            finansieringsselskaber, der tilbyder lånene. Forskellige banker og
            pengeinstitutter tilbyder lån i forskellige størrelser, nogle mindre og andre
            større.
          </i>
        </p>
        <picture>
          <source
            srcset="/static/img/saverium_dk/article-imgs/sad-couple.webp"
            type="image/webp"
          />
          <source
            srcset="/static/img/saverium_dk/article-imgs/sad-couple.jpg"
            type="image/jpg"
          />
          <img
            width="700"
            height="466"
            class="article-img"
            src="/static/img/saverium_dk/article-imgs/sad-couple.jpg"
            alt="Hvor meget kan jeg få udbetalt ved at optage et lån? – Og er det en god ide?"
          />
        </picture>

        <p>
          Næsten alle finansieringsselskaber er i stand til at tilbyde små lån på op til
          et par tusinde kroner, og disse er de mest almindelige lånebeløb at optage
          online. Imidlertid vokser flere banker og finansieringsselskaber, og der kan fås
          lån fra flere steder for så lidt som 1.000 – 500.000 kr. På grund af det store
          udbud er det værd at være endnu mere præcis i søgningen efter det lån som passer
          netop til din situation, da renten på sådanne kortfristede lån ofte kan være
          højere end forventet.
        </p>
      </div>
      <div class="text-container">
        <h2>Har du brug for et lån?</h2>
        <p>
          Det er aldrig en god idé at optage et lån ud over dine behov, så du ikke skal
          betale renter på de ekstra penge forgæves. Inden du optager et lån, skal du
          planlægge dit lånebehov ordentligt og tænke over, hvad der ville være et
          passende lånebeløb, som du virkelig har brug for og også vil være i stand til at
          kunne tilbagebetale. Så lån ikke for meget forgæves, men tænk nøje over, hvor
          meget du har brug for. Ved hjælp af den
          <b-link :to="$root.rn.MainView">vejledende</b-link> låneberegner der findes på
          vores hjemmeside, kan du se størrelsen på den månedlige rate med forskellige
          lånebeløb og tilbagebetalingsperioder.
        </p>
        <p>
          Det formål, som et lån bruges til, kan også påvirke, hvor meget banker og
          finansieringsselskaber er villige til at låne dig. Hvis du planlægger at købe en
          lejlighed, får du sandsynligvis et lån meget lettere, end hvis du bad mig om at
          optage et lån, så du kan gå i byen en weekend. Når du ansøger om et lån, skal du
          være ærlig om, hvor du ansøger om lånet, især hvis det er et større lånebeløb.
          Selvfølgelig kan der tages små forbrugslån til ethvert formål, du ønsker, og
          långiveren stiller ikke spørgsmålstegn ved eller nødvendigvis endda spørgsmålet
          om formålet med lånet.
        </p>
      </div>
      <div class="text-container">
        <h2>Hvad er din livssituation?</h2>
        <p>
          At få et lån og lånebeløbet påvirkes til en vis grad også af din livssituation.
          Banker kan kræve en lønseddel for at give dem større tillid til, at du vil være
          i stand til at tilbagebetale dit lån. Dette kan også påvirke lånebeløbet. For
          eksempel, hvis du har ansøgt om et lån på 100.000 kroner men kun får lånetilbud
          tilbage på 50.000 kroner, kan dette skyldes et manglende arbejdscertifikat.
        </p>
        <p>
          Hvis du allerede har mange lån, kan dette påvirke det lånebeløb, banken yder. Jo
          flere lån du har, jo mere skal du tilbagebetale. Lånets størrelse kan også
          påvirkes af din kundehistorie hos den bank, hvorfra du ansøger om et lånetilbud.
          Hvis du tidligere har fået et lån fra dem og har tilbagebetalt dit lån, kan det
          være lettere for banken at give dig det lånebeløb, du ønsker.
        </p>
      </div>
      <div class="text-container">
        <h2>Leder du efter et lån alene eller sammen med nogen?</h2>
        <p>
          Hvis du ansøger om et lån sammen med en anden, såsom en ægtefælle eller ven, er
          dine chancer for at få et større lån bedre. Hvis to personer tager et lån, vil
          banken have bedre sikkerhed for, at lånet bliver tilbagebetalt. Ansøgning om et
          lån sammen kan være en god mulighed, især når man ansøger om større lån, såsom
          en lånekombination. Selvfølgelig kan du også ansøge om et lån alene. I dette
          tilfælde er det op til dig at få et lån, så banken behøver kun at fokusere på
          din betalingsevne.
        </p>
      </div>
      <div class="text-container">
        <h2>Ser mod den generelle økonomisituation</h2>
        <p>
          Selvom alt er fint på din side, kan banken stadig beslutte, at den ikke vil låne
          dig nøjagtigt det samme beløb, som du gerne vil. Dette kan påvirkes af mange
          ting, såsom bankens egen økonomiske situation og den generelle økonomiske
          situation. Banker og finansieringsselskaber er meget opmærksomme på verdens
          monetære situation. Måske forudsiger de en nedgang, der snart kommer, ellers ved
          de noget, der kan påvirke folks evne til at tilbagebetale. Banker og
          finansieringsselskaber beslutter selv, hvor mange penge de er villige til at
          låne ud til deres kunder, og kunden kan selv bestemme, om de vil acceptere et
          sådant tilbud.
        </p>
        <p>2020-10-06 / Søren</p>
      </div>
    </div>
    <div class="col-xl-4 col-12">
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
  </div>
</template>
<script>
import LoanWidget from '@/components/saverium_dk/seo_components/LoanWidget.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
export default {
  data() {
    return {}
  },
  components: {
    LoanWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss" scoped></style>
