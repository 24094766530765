<template lang="html">
  <div class="mb-5" ref="header">
    <h2 v-if="fullView" class="mb-3 text-center">
      {{ step }}.
      {{ $t('income') }}
    </h2>
    <h1 v-else class="mb-3 text-center">
      {{ $t('income') }}
    </h1>

    <div class="row align-items-end">
      <span class="sub-title mt-2  mb-2">{{ $t('household_income') }}</span>
      <p class="household-info-text">
        <i>{{ $t('household_income_info_text') }}</i>
      </p>
      <div class="row col-12 p-0">
        <div class="form-group col-sm-6" @change="updateIncome">
          <form-input
            currency="kr."
            separator="."
            type="numeric"
            storekey="income_gross"
            id="income_gross"
            inputmode="numeric"
            :model="grossIncome"
          >
            {{ $t('monthly_income_gross') }}
          </form-input>
          <span class="col-12 col-md-6 small">{{ $t('monthly_income_text') }}</span>
        </div>
        <div class="form-group col-sm-6" @change="updateIncome">
          <form-input
            currency="kr."
            separator="."
            type="numeric"
            storekey="income_net"
            id="income_net"
            inputmode="numeric"
            :model="netIncome"
          >
            {{ $t('monthly_income_net') }}
          </form-input>
          <div v-if="grossError" class="invalid-feedback" style="display: block">
            {{ $t('monthly_gross_needs_to_be_bigger') }}
          </div>
        </div>
      </div>
      <div class="form-group col-sm-6" @change="updateIncome">
        <form-input
          currency="kr."
          separator="."
          type="numeric"
          storekey="other_income"
          id="other_income"
          inputmode="numeric"
          :model="otherIncome"
        >
          {{ $t('other_monthly_income') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6" @change="updateIncome">
        <form-input
          v-if="this.civilstatus === '2' || this.civilstatus === '6'"
          currency="kr."
          separator="."
          type="numeric"
          storekey="spouseIncome"
          id="spouse_income"
          inputmode="numeric"
          :model="spouseIncome"
        >
          {{ $t('spouse_income') }}
        </form-input>
      </div>
      <div class="container">
        <p>{{ $t('total_income') }} {{ totalIncome }} kr.</p>
      </div>

      <span class="sub-title mt-2 mb-2">{{ $t('household_expenses') }}</span>
      <p class="household-info-text">
        <i>{{ $t('household_expense_text') }}</i>
      </p>
      <div class="row align-items-end">
        <div class="form-group col-sm-6" @change="updateIncome">
          <form-input
            currency="kr."
            separator="."
            type="numeric"
            storekey="householdHeatingExpenses"
            id="householdHeatingExpenses"
            inputmode="numeric"
            :model="householdHeatingExpenses"
          >
            {{ $t('household_heating_expenses') }}
          </form-input>
        </div>
        <div class="form-group col-sm-6" @change="updateIncome">
          <form-input
            currency="kr."
            separator="."
            type="numeric"
            storekey="householdUtilitiesExpenses"
            id="householdUtilitiesExpenses"
            inputmode="numeric"
            :model="householdUtilitiesExpenses"
          >
            {{ $t('household_utilities_expenses') }}
          </form-input>
        </div>
        <div class="form-group col-sm-6" @change="updateIncome">
          <form-input
            currency="kr."
            separator="."
            type="numeric"
            storekey="householdDigitalMediaExpenses"
            id="householdDigitalMediaExpenses"
            inputmode="numeric"
            :model="householdDigitalMediaExpenses"
          >
            {{ $t('household_digital_media_expenses') }}
          </form-input>
        </div>
        <div class="form-group col-sm-6" @change="updateIncome">
          <form-input
            currency="kr."
            separator="."
            type="numeric"
            storekey="householdInsuranceExpenses"
            id="householdInsuranceExpenses"
            inputmode="numeric"
            :model="householdInsuranceExpenses"
          >
            {{ $t('household_insurance_expenses') }}
          </form-input>
        </div>
        <div class="row col-12 align-items-end p-0">
          <div class="form-group col-sm-6 mb-5" @change="updateIncome">
            <form-input
              currency="kr."
              separator="."
              type="numeric"
              storekey="householdTransportExpenses"
              id="householdTransportExpenses"
              inputmode="numeric"
              :model="householdTransportExpenses"
            >
              {{ $t('transportation_expenses') }}
            </form-input>
          </div>
          <div class="form-group col-sm-6 mb-4" @change="updateIncome" v-if="this.hasCar">
            <form-input
              currency="kr."
              separator="."
              type="numeric"
              storekey="costOfCars"
              id="costOfCars"
              inputmode="numeric"
              :model="costOfCars"
            >
              {{ $t('monthly_costs_of_car') }}
            </form-input>
            <span class="col-12 col-md-6 small">{{
              $t('do_not_include_insurance_etc')
            }}</span>
          </div>
        </div>
        <div
          class="form-group col-sm-6"
          @change="updateIncome"
          v-if="this.leasingRequired"
        >
          <form-input
            currency="kr."
            separator="."
            type="numeric"
            storekey="leasingExpense"
            id="leasingExpense"
            inputmode="numeric"
            :model="leasingExpense"
          >
            {{ $t('monthly_leasing_cost') }}
          </form-input>
        </div>

        <div class="form-group col-sm-6" @change="updateIncome">
          <form-input
            currency="kr."
            separator="."
            type="numeric"
            storekey="costOfLiving"
            id="costOfLiving"
            inputmode="numeric"
            :model="costOfLiving"
          >
            <span v-if="this.hasOwnHouse === true">
              {{ $t('monthly_living_cost_for_house_owners') }}
            </span>
            <span v-else-if="this.monthlyPropertyTax === true">
              {{ $t('monthly_property_tax') }}</span
            >
            <span v-else-if="this.housingBenefit === true">{{
              $t('monthly_housing_benefit')
            }}</span>
            <span v-else>{{ $t('monthly_living_expenses') }}</span>
          </form-input>
        </div>

        <div
          class="form-group col-sm-6"
          v-if="this.civilstatus === '2' || this.civilstatus === '6'"
        >
          <form-input
            type="dropdown"
            storekey="shareOfCosts"
            id="shareOfCosts"
            :items="shareOfCosts"
            suffix=" %"
            >{{ $t('your_share_of_costs') }}
          </form-input>
        </div>
        <div class="container">
          <p>{{ $t('joint_household_expenses') }} {{ jointHouseholdExpenses }} kr.</p>
        </div>
      </div>
      <div class="row align-items-end">
        <b-tooltip
          :show="showTooltip"
          target="loan-container"
          id="loan-tooltip"
          variant="saverium-blue-input"
          triggers="manual"
          custom-class="info-tooltip"
        >
          <span @click="closeTooltip"
            >{{ $t('you_sure_you_dont_have_loans') }}<i class="close-x far fa-times"></i
          ></span>
        </b-tooltip>
        <span class="sub-title mt-2">{{ $t('your_existing_loans') }}</span>
        <div class="container" id="loan-container">
          <div class="row">
            <div class="form-group col-sm-6">
              <label for="creditCards.visa"> {{ $t('i_have_visa_or_dankort') }}</label>
              <div
                class="radio-toolbar"
                name="creditCards.visa"
                id="creditCards.visa"
                @change="updateInput"
                :value="value"
                storekey="creditCards.visa"
              >
                <input
                  type="radio"
                  id="card1"
                  name="creditCards.visa"
                  :value="6"
                  :checked="this.visa == 6"
                />
                <label class="radiolabel" for="card1">{{ $t('yes') }}</label>

                <input
                  type="radio"
                  id="card2"
                  name="creditCards.visa"
                  :value="0"
                  :checked="this.visa == 0"
                />
                <label class="radiolabel" for="card2">{{ $t('no') }}</label>
              </div>
            </div>
            <div class="form-group col-sm-6">
              <label for="creditCards.mastercard">
                {{ $t('i_have_other_credit_card') }}</label
              >
              <div
                class="radio-toolbar"
                name="creditCards.masterCard"
                @change="updateInput"
                :value="value"
                role="group"
                storekey="creditCards.masterCard"
              >
                <input
                  type="radio"
                  id="mastercard1"
                  name="creditCards.masterCard"
                  :value="1"
                  :checked="this.masterCard == 1"
                />
                <label class="radiolabel" for="mastercard1">{{ $t('yes') }}</label>

                <input
                  type="radio"
                  id="mastercard2"
                  name="creditCards.masterCard"
                  :value="0"
                  :checked="this.masterCard == 0"
                />
                <label class="radiolabel" for="mastercard2">{{ $t('no') }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-6" @change="updateIncome">
              <label for="mortgage"> {{ $t('i_have_mortgage') }}</label>
              <div
                class="radio-toolbar"
                name="mortgage"
                role="group"
                @change="updateInput"
                :value="value"
              >
                <input
                  type="radio"
                  id="mortgage_yes"
                  name="hasMortgage"
                  :value="true"
                  :checked="this.hasMortgage === true"
                />
                <label class="radiolabel" for="mortgage_yes">{{ $t('yes') }}</label>

                <input
                  type="radio"
                  id="mortgage_no"
                  name="hasMortgage"
                  :value="false"
                  :checked="this.hasMortgage === false"
                />
                <label class="radiolabel" for="mortgage_no">{{ $t('no') }}</label>
              </div>
              <form-input
                type="numeric"
                currency="kr."
                separator="."
                storekey="existingLoans.mortgage.toBePaid"
                id="mortgage"
                inputmode="numeric"
                :class="{ hidden: this.hasMortgage === false }"
              >
                {{ $t('loan_total_amount') }}
              </form-input>
              <form-input
                type="numeric"
                currency="kr."
                separator="."
                storekey="existingLoans.mortgage.payment"
                id="mortgage_monthly"
                inputmode="numeric"
                :class="{ hidden: this.hasMortgage === false }"
              >
                {{ $t('monthly_payment') }}
              </form-input>
            </div>
            <div class="form-group col-sm-6" @change="updateIncome">
              <label for="carloan"> {{ $t('i_have_car_loan') }}</label>
              <div
                class="radio-toolbar"
                name="hasCarloan"
                storekey="hasCarloan"
                id="hasCarloan"
                @change="updateInput"
                :value="value"
              >
                <input
                  type="radio"
                  tabindex="0"
                  id="carloan_yes"
                  name="hasCarloan"
                  :value="true"
                  :checked="this.hasCarloan == true"
                />
                <label class="radiolabel" for="carloan_yes">{{ $t('yes') }}</label>

                <input
                  type="radio"
                  id="carloan_no"
                  name="hasCarloan"
                  :value="false"
                  :checked="this.hasCarloan == false"
                />
                <label class="radiolabel" for="carloan_no">{{ $t('no') }}</label>
              </div>
              <form-input
                type="numeric"
                currency="kr."
                separator="."
                storekey="existingLoans.carloan.toBePaid"
                id="carloan"
                inputmode="numeric"
                :class="{ hidden: this.hasCarloan === false }"
              >
                {{ $t('loan_total_amount') }}
              </form-input>
              <form-input
                type="numeric"
                currency="kr."
                separator="."
                storekey="existingLoans.carloan.payment"
                id="carloan_monthly"
                inputmode="numeric"
                :class="{ hidden: this.hasCarloan === false }"
              >
                {{ $t('monthly_payment') }}
              </form-input>
              <div
                v-if="this.hasCarloan === true"
                class="consent-checkbox custom-control custom-checkbox col-sm-12"
              >
                <input
                  id="consider-con-1"
                  type="checkbox"
                  class="custom-control-input"
                  storekey="existingLoans.carloan.consider_consolidation"
                  :value="1"
                  :unchecked-value="0"
                  v-model="carLoanConsolidation"
                />
                <label class="custom-control-label" for="consider-con-1">{{
                  $t('consider_consolidation')
                }}</label>
              </div>
              <form-input
                v-if="carLoanConsolidation === true"
                type="text"
                storekey="existingLoans.carloan.lender"
                maxlength="60"
              >
                {{ $t('lender') }}
              </form-input>
            </div>
            <div class="form-group col-sm-6" @change="updateIncome">
              <label for="studentloan"> {{ $t('i_have_student_loan') }}</label>
              <div
                class="radio-toolbar"
                name="hasStudentloan"
                storekey="hasStudentloan"
                id="hasStudentloan"
                @change="updateInput"
                :value="value"
              >
                <input
                  type="radio"
                  id="card3"
                  name="hasStudentloan"
                  :value="true"
                  :checked="this.hasStudentloan == true"
                />
                <label class="radiolabel" for="card3">{{ $t('yes') }}</label>

                <input
                  type="radio"
                  id="card4"
                  name="hasStudentloan"
                  :value="false"
                  :checked="this.hasStudentloan === false"
                />
                <label class="radiolabel" for="card4">{{ $t('no') }}</label>
              </div>
              <form-input
                type="numeric"
                currency="kr."
                separator="."
                storekey="existingLoans.studentloan.toBePaid"
                id="studentloan"
                inputmode="numeric"
                :class="{ hidden: this.hasStudentloan === false }"
              >
                {{ $t('loan_total_amount') }}
              </form-input>
              <form-input
                type="numeric"
                currency="kr."
                separator="."
                storekey="existingLoans.studentloan.payment"
                id="studentloan_monthly"
                inputmode="numeric"
                :class="{ hidden: this.hasStudentloan === false }"
              >
                {{ $t('monthly_payment') }}
              </form-input>

              <div
                v-if="this.hasStudentloan === true"
                class="consent-checkbox custom-control custom-checkbox col-sm-12"
              >
                <input
                  id="consider-con-2"
                  type="checkbox"
                  class="custom-control-input"
                  storekey="existingLoans.studentloan.consider_consolidation"
                  @change="updateInput"
                  :value="1"
                  :unchecked-value="2"
                  v-model="studentLoanConsolidation"
                />
                <label class="custom-control-label" for="consider-con-2">{{
                  $t('consider_consolidation')
                }}</label>
              </div>
              <form-input
                v-if="studentLoanConsolidation === true"
                type="text"
                storekey="existingLoans.studentloan.lender"
                maxlength="60"
              >
                {{ $t('lender') }}
              </form-input>
            </div>

            <div class="form-group col-sm-6" @change="updateIncome">
              <label for="hasOtherloan"> {{ $t('i_have_other_loan') }}</label>
              <div class="radio-toolbar" @change="updateInput" :value="value">
                <input
                  type="radio"
                  id="otherloan_yes"
                  name="hasOtherloan"
                  :value="true"
                  :checked="this.hasOtherloan === true"
                />
                <label class="radiolabel" for="otherloan_yes">{{ $t('yes') }}</label>

                <input
                  type="radio"
                  id="otherloan_no"
                  name="hasOtherloan"
                  :value="false"
                  :checked="this.hasOtherloan === false"
                />
                <label class="radiolabel" for="otherloan_no">{{ $t('no') }}</label>
              </div>
              <form-input
                type="numeric"
                currency="kr."
                separator="."
                storekey="existingLoans.otherloan.toBePaid"
                id="otherloan"
                inputmode="numeric"
                :class="{ hidden: this.hasOtherloan === false }"
              >
                {{ $t('loan_total_amount') }}
              </form-input>
              <form-input
                type="numeric"
                currency="kr."
                separator="."
                storekey="existingLoans.otherloan.payment"
                id="otherloan_monthly"
                inputmode="numeric"
                :class="{ hidden: this.hasOtherloan === false }"
              >
                {{ $t('monthly_payment') }}
              </form-input>

              <div
                v-if="this.hasOtherloan === true"
                class="consent-checkbox custom-control custom-checkbox col-sm-12"
              >
                <input
                  id="consider-con-3"
                  type="checkbox"
                  class="custom-control-input"
                  storekey="existingLoans.otherloan.consider_consolidation"
                  @change="updateInput"
                  :value="1"
                  :unchecked-value="0"
                  v-model="otherloanConsolidation"
                />
                <label class="custom-control-label" for="consider-con-3">{{
                  $t('consider_consolidation')
                }}</label>
              </div>
              <form-input
                v-if="otherloanConsolidation === true"
                type="text"
                storekey="existingLoans.otherloan.lender"
                maxlength="60"
              >
                {{ $t('lender') }}
              </form-input>
            </div>
            <div
              class="form-group col-sm-6"
              v-if="
                this.hasOtherloan ||
                  this.hasStudentloan ||
                  this.hasMortgage ||
                  this.hasCarloan
              "
            >
              <label for="new-debt">{{ $t('have_you_new_debt') }}</label>
              <div
                class="radio-toolbar"
                name="newDebt"
                role="group"
                @change="updateInput"
                :value="value"
              >
                <input
                  type="radio"
                  id="newDebt_yes"
                  name="hasnewDebt"
                  :value="true"
                  :checked="this.hasnewDebt === true"
                />
                <label class="radiolabel" for="newDebt_yes">{{ $t('yes') }}</label>

                <input
                  type="radio"
                  id="newDebt_no"
                  name="hasnewDebt"
                  :value="false"
                  :checked="this.hasnewDebt === false"
                />
                <label class="radiolabel" for="newDebt_no">{{ $t('no') }}</label>
              </div>
              <form-input
                type="numeric"
                currency="kr."
                separator="."
                storekey="newDebtThisYear"
                id="newDebtThisYear"
                inputmode="numeric"
                :class="{ hidden: hasnewDebt === false }"
                :model="hasnewDebt ? newDebtThisYear : (newDebtThisYear = 0)"
              >
                {{ $t('total_payment') }}
              </form-input>
            </div>
          </div>
        </div>
        <span class="sub-title mt-2">{{ $t('available_amount_and_consumption') }}</span>
        <div class="row">
          <div class="form-group col-sm-6" @change="updateFundsAvailable">
            <div v-if="fundsError" class="invalid-feedback" style="display: block">
              {{ $t('available_funds_cant_be_bigger_than_income') }}
            </div>
            <form-input
              currency="kr."
              separator="."
              type="numeric"
              storekey="funds_available"
              id="funds_available"
              inputmode="numeric"
              :model="fundsAvailable"
            >
              {{ $t('monthly_funds_available') }}
            </form-input>
            <span class="col-12 col-md-6 small">{{
              $t('monthly_income_estimation')
            }}</span>
          </div>
          <div class="form-group col-sm-6" @input="checkLoans" @change="scrollToElement">
            <div v-if="fundsError" class="invalid-feedback" style="display: block">
              {{ $t('available_funds_cant_be_bigger_than_income') }}
            </div>
            <form-input
              currency="kr."
              separator="."
              type="numeric"
              storekey="householdConsumption"
              id="householdConsumption"
              inputmode="numeric"
              :model="householdConsumption"
            >
              {{ $t('monthly_household_consumption') }}
            </form-input>
            <span class="col-12 col-md-6 small">{{
              $t('household_consumption_estimation')
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from '@/components/form/FormInput'
import { mapState } from 'vuex'
import { shareOfCosts } from '@/utils/saverium_dk/const'
export default {
  props: ['fullView', 'step', 'value', 'error'],
  components: {
    FormInput,
  },

  data() {
    return {
      requiredFields: [
        'income_gross',
        'income_net',
        'funds_available',
        'other_income',
        'lastYearTotal',
        'costOfLiving',
        'householdConsumption',
      ],
      checked: false,
      grossError: false,
      fundsError: false,
      showTooltip: false,
    }
  },
  computed: {
    ...mapState({
      stepErrors: state => state.application.stepErrors,
      hasCarloan: state => state.application.hasCarloan,
      hasMortgage: state => state.application.hasMortgage,
      otherCreditCard: state => state.application.other_credit_card,
      cardIssuerOptions: state => state.enums.cardIssuerOptions,
      netIncome: state => state.application.income_net,
      grossIncome: state => state.application.income_gross,
      otherIncome: state => state.application.other_income,
      costOfLiving: state => state.application.costOfLiving,
      householdHeatingExpenses: state => state.application.householdHeatingExpenses,
      householdInsuranceExpenses: state => state.application.householdInsuranceExpenses,
      householdUtilitiesExpenses: state => state.application.householdUtilitiesExpenses,
      householdDigitalMediaExpenses: state =>
        state.application.householdDigitalMediaExpenses,
      householdTransportExpenses: state => state.application.householdTransportExpenses,
      errors: state => state.application.errors,
      leasingExpense: state => state.application.leasingExpense,
      civilstatus: state => state.application.civilstatus,
      hasOwnHouse: state => state.application.hasOwnHouse,
      housingBenefit: state => state.application.housingBenefit,
      monthlyPropertyTax: state => state.application.monthlyPropertyTax,
      cars: state => state.application.cars,
      hasnewDebt: state => state.application.hasnewDebt,
      hasStudentloan: state => state.application.hasStudentloan,
      spouseIncome: state => state.application.spouseIncome,
      hasOtherloan: state => state.application.hasOtherloan,
      mortgage: state => state.application.existingLoans.mortgage.payment,
      studentloan: state => state.application.existingLoans.studentloan.payment,
      carloan: state => state.application.existingLoans.carloan.payment,
      otherloan: state => state.application.existingLoans.otherloan.payment,
    }),

    jointHouseholdExpenses() {
      return (
        this.householdHeatingExpenses +
        this.householdInsuranceExpenses +
        this.householdUtilitiesExpenses +
        this.householdDigitalMediaExpenses +
        this.householdTransportExpenses +
        this.costOfLiving +
        this.leasingExpense +
        this.costOfCars
      )
    },
    loans() {
      return [this.mortgage, this.studentloan, this.carloan, this.otherloan]
    },
    hasLoans() {
      return [this.hasOtherloan, this.hasCarloan, this.hasMortgage, this.hasStudentloan]
    },
    newDebtThisYear: {
      get() {
        return this.$store.state.application.newDebtThisYear
      },
      set(newDebtThisYear) {
        this.$store.state.application.newDebtThisYear = newDebtThisYear
      },
    },

    costOfCars: {
      get() {
        return this.$store.state.application.costOfCars
      },
      set(costOfCars) {
        this.$store.state.application.costOfCars = costOfCars
      },
    },

    studentLoanConsolidation: {
      get() {
        return this.$store.state.application.existingLoans.studentloan
          .consider_consolidation
      },
      set(considerConsolidationStudent) {
        this.$store.state.application.existingLoans.studentloan.consider_consolidation = considerConsolidationStudent
      },
    },
    otherloanConsolidation: {
      get() {
        return this.$store.state.application.existingLoans.otherloan
          .consider_consolidation
      },
      set(considerConsolidationOther) {
        this.$store.state.application.existingLoans.otherloan.consider_consolidation = considerConsolidationOther
      },
    },
    carLoanConsolidation: {
      get() {
        return this.$store.state.application.existingLoans.carloan.consider_consolidation
      },
      set(considerConsolidationCar) {
        this.$store.state.application.existingLoans.carloan.consider_consolidation = considerConsolidationCar
      },
    },
    totalIncome() {
      return this.netIncome + this.otherIncome + this.spouseIncome
    },
    fundsAvailable: {
      get() {
        return this.$store.state.application.funds_available
      },
      set(fundsAvailable) {
        this.$store.state.application.funds_available = fundsAvailable
      },
    },
    householdConsumption: {
      get() {
        return this.$store.state.application.householdConsumption
      },
      set(householdConsumption) {
        this.$store.state.application.householdConsumption = householdConsumption
      },
    },
    leasingRequired() {
      if (this.$store.state.application.requiredFields[2].includes('leasingExpense')) {
        return true
      } else {
        return false
      }
    },
    hasCar() {
      if (this.$store.state.application.cars.length > 0) {
        return true
      } else {
        return false
      }
    },
    visa: {
      get() {
        return this.$store.state.application.creditCards.visa
      },
      set(visa) {
        this.$store.state.application.creditCards.visa = visa
      },
    },
    masterCard: {
      get() {
        return this.$store.state.application.creditCards.masterCard
      },
      set(masterCard) {
        this.$store.state.application.creditCards.masterCard = masterCard
      },
    },
    shareOfCosts() {
      return shareOfCosts
    },
  },
  watch: {
    stepErrors(newV) {
      if (this.fullView && newV[0] === parseInt(this.step, 10)) {
        this.$refs.header.scrollIntoView()
      }
    },
    netIncome(value) {
      if (this.grossIncome < value) {
        this.grossError = true
      } else {
        this.grossError = false
      }
    },
    grossIncome(value) {
      if (this.netIncome > value) {
        this.grossError = true
      } else {
        this.grossError = false
      }
    },
    fundsAvailable(value) {
      if (this.totalIncome < this.fundsAvailable) {
        this.fundsError = true
      } else {
        this.fundsError = false
      }
    },
  },
  methods: {
    isValid(id) {
      return !Object.keys(this.errors).includes(id)
    },

    updateIncome() {
      this.fundsAvailable =
        this.netIncome +
        this.spouseIncome +
        this.otherIncome -
        this.householdHeatingExpenses -
        this.householdUtilitiesExpenses -
        this.householdDigitalMediaExpenses -
        this.householdInsuranceExpenses -
        this.householdTransportExpenses -
        this.leasingExpense -
        this.costOfLiving -
        this.mortgage -
        this.studentloan -
        this.carloan -
        this.otherloan -
        this.costOfCars
    },
    updateFundsAvailable(event) {
      const {
        target: { value: _val },
      } = event
      const value = _val
      this.fundsAvailable = value
    },
    async checkLoans() {
      this.showTooltip = this.hasLoans.every(loan => loan === false)
      if (this.showTooltip) {
        this.$root.$emit('bv::show::tooltip', 'loan-tooltip')
      } else {
        this.$root.$emit('bv::hide::tooltip', 'loan-tooltip')
      }
    },
    scrollToElement() {
      const tooltip = document.getElementById('loan-tooltip')
      const rect = tooltip?.getBoundingClientRect()
      if (rect?.top < 0) {
        tooltip.scrollIntoView()
      }
    },
    closeTooltip() {
      this.$root.$emit('bv::hide::tooltip', 'loan-tooltip')
    },
    updateInput(event) {
      const {
        target: { name, value: _val },
      } = event
      let value = _val
      if (value === 'true') {
        value = true
      }
      if (value === 'false') {
        value = false
      }

      this.$store.dispatch('removeError', name)

      this.$store.commit('updateApplication', {
        key: name,
        value: value,
      })
      this.$store.dispatch('updateOrCreateDraft')
      this.checkLoans()
    },
  },
}
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}
.info-panel {
  cursor: pointer;
}
.small {
  padding-left: 0;
}
.sub-title {
  font-size: 1.6rem;
  margin-bottom: 2rem;
}
</style>
