<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til regninger</h1>
      <div class="top-text text-container">
        <p>
          Står du med en regning der skal betales, og har du brug for et lån til
          regningen? Så kan vi hjælpe dig med at finde det helt rette lån til dig og din
          situation på 2 minutter.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#laan-til-alle-former-for-regninger"
                    >Lån til alle former for regninger</a
                  >
                </li>
                <li>
                  <a href="#boer-jeg-laane-til-at-betale-regninger"
                    >Bør jeg låne til at betale regninger?</a
                  >
                </li>
                <li>
                  <a href="#laan-til-andet-end-regninger">Lån til andet end regninger</a>
                </li>
                <li>
                  <a href="#vi-er-klar-til-at-hjaelpe-dig"
                    >Vi er klar til at hjælpe dig</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="laan-til-alle-former-for-regninger">
            Lån til alle former for regninger
          </h2>
          <p>
            Regninger kommer nogle gange uventet, og nogle gange er de større end hvad vi
            forventer. I disse situationer, kan det være en god mulighed at optage et lån
            til at dække regningen.
          </p>
          <p>
            Vi, eller mere præcist de
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere</a
            >
            som tilbyder lån via vores platform, skelner ikke imellem hvilke regninger du
            låner til at betale.
          </p>
          <p>
            I princippet er det underordnet om du har en lille elregning, som er dumpet
            ind af brevsprækken, eller har du fået en stor
            <strong>regning var værkstedet</strong> eller en
            <strong>tandlægeregning</strong>. Hvis du ansøger igennem os, vil det nemlig
            være et
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >, som du optager, for at dække dit udestående, og dermed gælder de gængse
            rammer for denne lånetype.
          </p>
          <h3>Hvilke krav stilles der?</h3>
          <p>
            I bund og grund skal vores lånepartner i første omgang sikre sig, at de på
            lovlig vis må udlåne penge til dig. Her bliver det fx set på din gældsfaktor
            (hvor meget du har lånt i forhold til hvor meget du tjener), samt hvor højt
            dit rådighedsbeløb er, når du har betalt den månedge ydelse på lånet.
          </p>
          <p>
            Herefter foretager banken deres egen <strong>kreditvurdering</strong>, som
            skal sikre sig, at de ikke kommer til at tabe penge på at låne ud til dig.
            Grundet de mange stramninger på låneområdet, som er vedtaget i slutningen af
            10érne og starten af 20érne, er det dog sjældent, at banken ikke ønsker at
            <a
              href="https://www.saverium.dk/laan-penge/"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >
            til dig, hvis rent lovgivningsmæssigt gerne må.
          </p>
          <p>
            Alt dette behøver du dog ikke bekymre dig om, da det klares automatisk, lige
            så snart du har udfyldt ansøgningsformularen her på siden. Alle vores
            bankpartnere giver dig svar på din
            <a
              href="https://www.saverium.dk/laan-penge/"
              rel="noopener noreferrer"
              target="_blank"
              >låneansøgning</a
            >
            med det samme, så du også kan få betalt din regning med det samme.
          </p>
        </div>

        <div class="text-container">
          <h2 id="boer-jeg-laane-til-at-betale-regninger">
            Bør jeg låne til at betale regninger?
          </h2>
          <p>
            Ved at låne penge til at dække regningen, undgår du at blive pålagt
            ekstregebyrer, hvis dit alternativ er at betale regningen for sent. Hvis
            regningen er stor, og der går længe, inden at du har mulighed for at betale
            den med din nuværende økonomi, undgår du også risikoen for at blive
            registreret i RKI eller Debitor Registret.
          </p>
          <p>
            Det er også værd at nævne, at ubetalte regninger er ubehagelige at have
            hængende over hovedet. Én ting er, at man skal huske at betale den, men lige
            så ubehageligt kan det være, når kreditoren gang på gang ringer til dig, og
            spørger efter deres betaling, eller begynder at true dig med en registrering i
            RKI.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-andet-end-regninger">Lån til andet end regninger</h2>
          <p>
            Du kan som nævnt låne til dine regninger igennem os, men vi kan også hjælpe
            dig med mange andre lånebehov. Har du en større drøm, som skal udleves, kræver
            det også ofte et lån. Der følger som oftest en regning med, som skal betales,
            og det er ikke altid at man har haft mulighed for at spare op. Måske er din
            drøm først lige blevet klar for dig, og hvis du ønsker at udleve den nu, så
            kan et lån til at dække regningen lige pludselig blive meget aktuel.
          </p>
        </div>

        <div class="text-container">
          <h2 id="vi-er-klar-til-at-hjaelpe-dig">Vi er klar til at hjælpe dig</h2>
          <p>
            Uanset hvad dit behov er, og hvilken regning der skal lånes til, så er vi klar
            til at hjælpe dig. Vores service er tilgængelig hele døgnet, og hvis du skule
            løbe ind i problemer, så har du også mulighed for at kontakte vores
            kundservice på mail eller telefon.
          </p>
          <p>
            Uanset hvor længe du venter, og hvor meget du ønsker det, så forsvinder
            regningerne ikke af sig selv. Hvis du ikke har råd til at betale dem her og
            nu, så vil vi anbefale dig at undersøge mulighederne for et lån, så du ved
            hvad dit alternativ er til dårlig nattesøvn, dårligt forhold til din kreditor
            og risikoen for opkrævningsgebyrer og at havne i RKI.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
