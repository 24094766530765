<template>
  <div>
    <h2>Logging you in...</h2>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'OidcCallback',
  methods: {
    ...mapActions(['oidcSignInCallback']),
  },
  mounted() {
    this.oidcSignInCallback()
      .then(redirectPath => {
        this.$router.push(redirectPath)
      })
      .catch(err => {
        console.error(err)
        this.$router.push('/oidc-callback-error') // Handle errors any way you want
      })
  },
}
</script>
