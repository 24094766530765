<template lang="html">
  <div :class="location !== '/' ? 'mb-5' : ''" ref="header">
    <div v-if="location !== '/'">
      <h2 v-if="fullView" class="mb-3 text-center">
        {{ step }}.
        {{ $t('personal_information') }}
      </h2>
      <h1 v-else class="mb-3 text-center">
        {{ $t('personal_information') }}
      </h1>
    </div>
    <div class="row align-items-end">
      <div class="form-group col-sm-6">
        <form-input type="email" storekey="email" id="email">
          {{ $t('email') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input
          type="phone"
          storekey="phone"
          inputmode="numeric"
          name="phone"
          autocomplete="tel"
          prepend="+48"
          id="phone"
          placeholder="np. 123456789"
        >
          {{ $t('phone_number') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input type="text" storekey="firstName" id="firstName">
          {{ $t('first_name') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input type="text" storekey="lastName" id="lastName">
          {{ $t('last_name') }}
        </form-input>
      </div>
    </div>
    <div v-if="location !== '/'">
      <div class="row align-items-end">
        <div class="form-group col-sm-6">
          <form-input
            type="polish-id"
            storekey="idnumber"
            placeholder="np. ABA300000"
            id="idnumber"
          >
            {{ $t('identity_card_serial_number') }}
          </form-input>
        </div>

        <div class="form-group col-sm-6">
          <form-input
            type="identity"
            identifier="pesel"
            storekey="ssn"
            placeholder="np. 79022720957"
            id="ssn"
          >
            {{ $t('pesel') }}
          </form-input>
        </div>
      </div>

      <div class="row align-items-end">
        <div class="form-group col-sm-6">
          <form-input
            type="dropdown"
            storekey="education"
            :items="educationOptions"
            id="education"
          >
            {{ $t('education') }}
          </form-input>
        </div>
        <div class="form-group col-sm-6">
          <form-input
            type="dropdown"
            storekey="civilstatus"
            :items="civilstatusOptions"
            id="civilstatus"
          >
            {{ $t('marital_status') }}
          </form-input>
        </div>

        <div class="form-group col-sm-6">
          <form-input
            type="dropdown"
            storekey="dependants"
            :items="dependentsOptions"
            id="dependants"
          >
            {{ $t('dependents') }}
          </form-input>
        </div>
        <div class="form-group col-sm-6" v-if="location === '/'">
          <form-input
            storekey="loanPurpose"
            type="dropdown"
            :items="loanPurposeOptions"
            id="loanpurpose"
          >
            {{ $t('loan_purpose') }}
          </form-input>
        </div>
      </div>
      <div class="row align-items-end">
        <div class="form-group col-sm-6">
          <form-input type="checkbox" storekey="in_political_position">
            {{
              $t('i_declare_that_i_am_a_person_occupying_a_prominent_political_position')
            }}
            <b-link :to="$root.rn.PEPPDF" target="_blank">(PEP)</b-link>,
            {{ $t('pep_member') }}
          </form-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dependentsOptions } from '@/utils/saverium_pl/const.js'
import FormInput from '@/components/form/FormInput'

export default {
  props: ['fullView', 'step'],
  data() {
    return {
      requiredFields: [
        'email',
        'phone',
        'firstName',
        'lastName',
        'idnumber',
        'ssn',
        'civilstatus',
        'education',
        'dependants',
      ],
      idnumber: '',
      pesel: '',
      regexPesel: /[0-9]{4}[0-3]{1}[0-9]{1}[0-9]{5}/,
      location: window.location.pathname,
    }
  },
  components: {
    FormInput,
  },
  computed: {
    loanPurposeOptions() {
      return this.$store.state.enums.loanPurposeOptions
    },
    civilstatusOptions() {
      return this.$store.state.enums.maritalStatuses
    },
    dependentsOptions() {
      return dependentsOptions
    },
    stepErrors() {
      return this.$store.state.application.stepErrors
    },
    educationOptions() {
      return this.$store.state.enums.educationOptions
    },
  },
  watch: {
    stepErrors(newV) {
      if (this.fullView && newV[0] === parseInt(this.step, 10)) {
        this.$refs.header.scrollIntoView()
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
