<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til traktor</h1>
      <div class="top-text text-container">
        <p>
          Søger du efter et nyt lån til en traktor, så kan du hurtigt gøre det her, online
          og uden at stille sikkerhed. Du kan selv bestemme, om du ønsker at bruge lånet
          til en ny eller brugt traktor. For at finde det bedste lån til traktor i din
          situation, kan du optage et forbrugslån, som kan bruges til at købe traktoren,
          som du står og mangler. Du udfylder vores gratis online låneansøgning på få
          minutter, og få hurtigt svar på hvor du kan finde et billigt traktorlån.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li><a href="#hvorfor-ansoege">Hvorfor ansøge?</a></li>
                <li>
                  <a href="#kan-man-faa-et-laan-til-traktor-med-fast-lav-rente"
                    >Kan man få et lån til traktor med fast lav rente?</a
                  >
                </li>
                <li>
                  <a href="#hvordan-finder-jeg-det-bedste-laan-til-en-ny-traktor"
                    >Hvordan finder jeg det bedste lån til en ny traktor?</a
                  >
                </li>
                <li>
                  <a href="#hvad-kan-jeg-bruge-laanet-til"
                    >Hvad kan jeg bruge lånet til?</a
                  >
                </li>
                <li>
                  <a href="#find-de-bedste-tilbud-ved-at-sammenligne"
                    >Find de bedste tilbud ved at sammenligne</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="hvorfor-ansoege">Hvorfor ansøge?</h2>
          <p>
            For at ansøge om lån til traktor online, skal du blot have adgang til internet
            på en mobiltelefon, tablet eller computer. Det er meget nemt og hurtigt at
            ansøge om et lån til traktor online, da ansøgningen blot tager få minutter.
            Herefter vil du straks modtage svar på din låneansøgning.
          </p>
          <p>
            Hvis du søger finansiering til en traktor i en gammeldags bank, skal du til at
            stille sikkerhed for lånet. Det slipper du heldigvis for ved at søge om et
            usikret
            <a
              href="https://www.saverium.dk/laan-penge/online-laan"
              rel="noopener noreferrer"
              target="_blank"
              >lån online</a
            >, da online låneudbydere sjældent bekymrer sig over, hvad du bruger dit lån
            til. Du kan dermed sagtens bruge dit lån til at købe en ny sprøjtemaskine
            eller robotskraber til køerne.
          </p>
        </div>

        <div class="text-container">
          <h2 id="kan-man-faa-et-laan-til-traktor-med-fast-lav-rente">
            Kan man få et lån til traktor med fast lav rente?
          </h2>
          <p>
            Det er en god ide at anvende en lånesammenligningstjenseste som vores, da du
            derved har større sandsynlighed for at finde et lån til en traktor med en fast
            lav rente.
          </p>
          <p>
            Via vores platform kan du skabe dig et overblik over de forskellige
            lånetilbud, som du kan få til en traktor eller andre køretøjer til dit
            landbrug. På den måde kan du vælge det finansieringstilbud til din traktor,
            som passer til dig og din økonomi. De tilbud vi indhenter, er nemlig
            skræddersyet
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >
            med fast lav rente til traktor, baseret på dine behov og din økonomiske
            situation.
          </p>
          <p>
            På den måde sikre du dig og din økonomi bedst, når du leder efter et billigt
            lån til traktor, i modsætning til at vælge det første og bedste lånetilbud,
            som du støder på.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvordan-finder-jeg-det-bedste-laan-til-en-ny-traktor">
            Hvordan finder jeg det bedste lån til en ny traktor?
          </h2>
          <p>
            Den nemmeste måde at finde det bedste lån til traktor, er ved at ansøge
            igennem en online sammenligningstjeneste som vores. På den måde sparer du tid,
            og du når hurtigere ud til mange forskellige låneudbydere og banker. Hvis du
            selv skulle afdække markedet på samme måde som os, ville det tage uanede
            mængeder af tid, da det kræver en ansøgning til hver enkelt låneudbyder, som
            du først skal finde frem til.
          </p>
          <p>
            Når du har afsendt din ansøgning kan du slappe af og vente, mens vi gør
            arbejdet for dig ved at indhente skræddersyet lånetilbud. Det tager dog som
            regl mindre end ét minut, så pausen er kort. Herefter skal du blot sammenligne
            de forskellige
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >lånetilbud</a
            >
            til traktor, som du har modtaget fra vores samarbejdspartnere.
          </p>
          <p>
            Når du har valgt dit ønskede lånetilbud vil du blive omdirigeret til selve
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbyderen</a
            >, hvor du skal give samtykke til, at de må lave en endelig kreditvurdering af
            dig. Efter det er sket, vil du blive kontaktet af udbyderen, som du valgte.
            Herefter vil lånet vil enten blive udbetalt til din konto, eller du vil blive
            afvist, alt efter hvordan din kreditvurdering faldt ud.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-kan-jeg-bruge-laanet-til">Hvad kan jeg bruge lånet til?</h2>
          <p>
            Ansøger du om et lån til traktor online, kan du faktisk bruge lånet til netop
            det du går og mangler i din situation. Om det er en gammel traktor, ny traktor
            eller en finsnitter, så kan du bruge dit
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >nye lån</a
            >
            til traktor, til hvad du mangler på din gård.
          </p>
          <p>
            Det kan være alt fra en gammel mejetærsker til en ny foderrobot til dine grise
            eller køer. Det kan også være, at du har brug for at låne til en ny såmaskine
            eller stalden til dyrene skal <strong>renoveres</strong>. Når du optager et
            nyt lån online, skal du ikke dokumenterek hvad lånet skal bruges til. Det
            vigtigste for
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbyderne</a
            >
            er, at du som låntager kan overholde terminerne.
          </p>
        </div>

        <div class="text-container">
          <h2 id="find-de-bedste-tilbud-ved-at-sammenligne">
            Find de bedste tilbud ved at sammenligne
          </h2>
          <p>
            En af de bedste måder at sikre sig, at man finder et
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >billigt lån</a
            >
            til en ny traktor, er at at
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne lån</a
            >
            til traktorer først. Det kan du nemt, gratis og uforpligtende gøre her på
            vores hjemmeside. Det tager kun et få par minutter at udfylde vores online
            låneansøgning, som du får en hurtig tilbagemelding på.
          </p>
          <p>
            Efter at du har udfyldt alle de nødvendige felter og har trykket “Send
            låneansøgning”, klarer vi arbejdet for dig, ved at fremsende din ansøgning til
            alle vores samarbejdspartnere. Efter få minutter vil vi sende dig en email med
            de forskellige lånetilbud til traktor, som du har modtaget, så du nemt og
            overskueligt kan sammenligne og vælge det lånetilbud, som passer dig bedst.
          </p>
          <p>
            Når du har valgt et af dine modtagende lånetilbud, vil vi forbinde dig til
            selve låneudbyderen, ved at sende dig over på deres hjemmeside, hvor du skal
            give samtykke med
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >NemID/MitID</a
            >, for de kan lave en kreditvurdering af dig. Efter kreditvurdering er
            overstået vil du få svar på, om du er godkendt eller afvist til finansiering
            af din traktor. Skulle det blive godkendt vil både vi og låneudbyderen sende
            dig en bekræftelse pr. email, og lånet vil blive udbetalt til din bankkonto så
            hurtigt som muligt, alt afhængig af hvilken låneudbydere du vælger til dit nye
            traktorlån.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },

  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
