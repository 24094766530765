<template lang="html">
  <b-form-checkbox
    :checked="value"
    :unchecked-value="false"
    :value="true"
    @change="input"
    class="form-input"
    :state="error ? false : null"
  >
    <slot></slot>
  </b-form-checkbox>
</template>

<script>
export default {
  props: ['value', 'error'],
  methods: {
    input(value) {
      if (value !== this.value) {
        this.$emit('input', value)
      }
    },
  },
}
</script>

<style lang="sass" scoped></style>
