<template>
  <div>
    <div class="article">
      <h1 class="center-title">Låneudbydere</h1>
      <div class="top-text text-container">
        <p>
          Hvis du skal låne penge, så har du brug for en låneudbyder, et lånefirma eller
          en udlåner - kært barn har mange navne. Du kan selvfølgelig vælge at tage den
          første du finder, men hvis du lige som alle andre går op i, at betale så lav en
          rente som muligt, og du gerne vil have udbetalt lånet så hurtigt som muligt, så
          kan det være en god ide at sammenligne låneudbyderenes tilbud, så du får det
          bedste lån. Det er netop dét, som vi kan hjælpe med - hurtigt, gratis og
          uforpligtende.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#hvilke-typer-laaneudbydere-findes-der"
                    >Hvilke typer låneudbydere findes der?</a
                  >
                </li>
                <li><a href="#alle-laanefirmaer">Alle lånefirmaer</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="hvilke-typer-laaneudbydere-findes-der">
            Hvilke typer låneudbydere findes der?
          </h2>
          <p>
            Der findes mange forskellige lånefirmaer i og uden for Danmark, som udlåner
            penge til privatpersoner, og der kan være stor forskel på hvilke lånetyper,
            lånebeløb og formål, som disse låneudbydere tilbyder. I dag tilbyder langt de
            fleste låneudbydere online ansøgninger, så du ikke behøver at møde op i deres
            filial eller ansøge telefonisk, hvilket har gjort ansøgningsprocessen langt
            nemmere, da en stor del af låneprocessen samtidig er blevet automatiseret.
          </p>
          <h3>Private udlånere</h3>
          <p>
            Private låneudbydere kan både betegne privatpersoner, som udlåner deres egne
            penge til andre private, men det kan også betyde udlånere, som udelukkende
            udlåner til private personer - i modsætning til fx
            <a
              href="https://saverium.dk/laan-penge/erhvervslaan"
              rel="noopener noreferrer"
              target="_blank"
              >erhvervslån</a
            >.
          </p>
          <p>
            Hos os tilbyder vi både sammenligning af udlån til private og udlån til
            erhverv. Udvalget af udlånere, som udlåner til privatpersoner er dog generelt
            langt større end lån til virksomheder, både når du ansøger om lån via os, men
            også mere generelt på hele lånemarkedet.
          </p>
          <h3>Online selskaber</h3>
          <p>
            Online låneudbydere har haft sit fremmarch, i forbindelse med at internettet
            er blevet mere udbredt og data omkring ansøgeren kunne trækkes automatisk fra
            diverse offentlige og private databaser.
          </p>
          <p>
            Online låneselskaber tilbyder, som du nok havde gættet,
            <a
              href="https://saverium.dk/laan-penge/online-laan"
              rel="noopener noreferrer"
              target="_blank"
              >online lån</a
            >, hvor hele processen foregår online fra start til slut. Det gælder for såvel
            ansøgningen, behandlingen såvel som udbetaling af lånet. Hvis du ønsker at
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >
            og få en hurtig afklaring på en <strong>låneansøgning</strong>, så er det
            blandt de online låneselskaber, du skal lede.
          </p>
          <p>
            I dag har tilbyder de fleste (hvis ikke alle) lånefirmaer, at du kan foretage
            hele processen online, hvilket også gør sig gældende for de firmaer, som vi
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligner lån</a
            >
            for dig blandt.
          </p>
          <p>
            De online låneselskaber har deres rødder tilbage i sms-lån, som senere blev
            erstattet af
            <a
              href="https://saverium.dk/laan-penge/kvilaan"
              rel="noopener noreferrer"
              target="_blank"
              >kviklån</a
            >, som alle dage har haft deres fokus på mindre lånebeløb, nem
            ansøgningsprocess og hurtig udbetaling. I dag findes disse lån ikke mere på
            samme måde, men termerne bliver ofte brugt for at betegne
            <a
              href="https://saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >, hvor både lånets størrelse, samt hastigheden for udbetaling og ansøgningen.
          </p>
          <h3>Alternative lånefirmaer</h3>
          <p>
            Hvis du leder efter de nye eller små lånefirmaer, som knap nok er til at finde
            på nettet, har vi også en række af dem, som vi benytter, når søger efter de
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >bedste lånetilbud</a
            >
            til dig. Ligeledes har vi mange udenlandske låneudbydere, som ofte kan tilbyde
            bedre vilkår end de kendte danske låneudbydere. Sidst men ikke mindst findes
            der også mange ukendte låneudbydere, som du kun tilfældigvis vil støde på,
            hvis du selv skulle ud og afsøge lånemarkedet. Disse lånefirmaer har vi
            heldigvis også en række af. Det skal også nævnes at der selvfølgelig er et
            stort overlap blandt både de nye, små, ukendte og udenlandske låneudbydere.
          </p>
        </div>

        <div class="text-container">
          <h2 id="alle-laanefirmaer">Alle lånefirmaer</h2>
          <p>
            Herunder har vi lavet en liste med alle de lånefirmaer i Danmark, som vi
            kender til, men det er muligt at der findes nogle få nye lån, som vi ikke har
            fået nævnt her. Listen indeholder alle lånefirmaer, som stadigvæk udlåner
            eller er lukket ned for nyligt. Grunden til at vi fastholder de nyligt
            nedlukkede lånefirmaer på listen ér, at der er en sandsynlighed for at de
            bliver genåbnet eller opkøbt og derved igen bliver relevante.
          </p>
          <p>
            Da vi hver dag arbejder på, at give dig så godt et udvalg af lån at vælge
            imellem, har vi også brugt mange kræfter på at afsøge hele markedet for
            lånetilbud. Derfor er der meget lille sandsynlighed for, at du finder et
            lånefirma, som ikke fremgår af denne liste, som kan tilbyde dig bedre
            lånevilkår. Hvis du alligevel falder over et nyt lån, som vi ikke har på
            listen, så skriv gerne til os, da vi ønsker at samle alle lånefirmaer på denne
            liste, så du og fremtidige besøgende på vores hjemmeside kan få et overblik
            over alle låneudbydere, som tilbyder lån til danskerne.
          </p>
          <div class="row">
            <ul>
              <li>Lendon</li>
              <li>Spar-nord</li>
              <li>Pantsat</li>
              <li>Vestjyskbank</li>
              <li>Acceptcard</li>
              <li>ferratum</li>
              <li>Saxo</li>
              <li>Nordcredit</li>
              <li>Qred</li>
              <li>Creamfinance</li>
              <li>Nordea</li>
              <li>
                <a
                  href="https://saverium.dk/laan-penge/laaneudbydere/modus-finans"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Modus finans</a
                >
              </li>
              <li>Lendomatic</li>
              <li>Capitalbox</li>
              <li>Middelfart sparekasse</li>
              <li>Seb</li>
              <li>Nordax</li>
              <li>Danske bank</li>
              <li>Viabill</li>
              <li>Mozipo</li>
              <li>
                <a
                  href="https://saverium.dk/laan-penge/laaneudbydere/fellow-bank"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Fellow Bank</a
                >
              </li>

              <li>Skandia</li>
              <li>Realkredit Danmark</li>
              <li>Udenom banken</li>
              <li>Bank-nordik</li>
              <li>Clickfinans</li>
              <li>
                <a
                  href="https://saverium.dk/laan-penge/laaneudbydere/resurs-bank"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Resurs bank</a
                >
              </li>

              <li>Folkelaanet</li>
              <li>Sydbank</li>
              <li>Sparekassen Sjælland-Fyn</li>
              <li>Al finans</li>
              <li>Lendino</li>
            </ul>
            <ul>
              <li>Fokus-laan</li>

              <li>Jyske bank</li>
              <li>Swedbank</li>
              <li>moen-bank</li>
              <li>Northmill</li>

              <li>Cashper</li>
              <li>Kronelån</li>

              <li>Coop bank</li>
              <li>Paymark</li>

              <li>Sparekassen Kronjylland</li>
              <li>Auroralån</li>
              <li>Cash-buddy</li>
              <li>Leasy</li>
              <li>Sparekassen Djursland</li>
              <li>Turbolaan</li>
              <li>Sparekassen Vendsyssel</li>
              <li>Spargo finans</li>
              <li>Vivus</li>
              <li>wandoo</li>
              <li>Lån og spar</li>
              <li>Zmarta</li>
              <li>Novum</li>

              <li>Bank norwegian</li>
              <li>
                <a
                  href="https://saverium.dk/laan-penge/laaneudbydere/basisbank"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Basisbank</a
                >
              </li>
              <li>Klarna</li>
              <li>Zetfinans</li>
              <li>Foeniks</li>
              <li>Selene</li>
              <li>Trustbuddy</li>
              <li>Bilka</li>
              <li>Bluestep</li>
              <li>Handelsbanken</li>
            </ul>
            <ul>
              <li>Sparekassen Sjælland-fyn</li>

              <li>Dan Aktiv</li>
              <li>Remember</li>
              <li>Capa</li>
              <li>Anna Finans</li>
              <li>Simbo</li>
              <li>Ellos bank</li>
              <li>Bedre Kredit</li>
              <li>Tando</li>

              <li>Creditstar</li>
              <li>4finance</li>
              <li>Hotlaan</li>
              <li>Scandi Bank</li>
              <li>Credistar</li>

              <li>
                <a
                  href="https://saverium.dk/laan-penge/laaneudbydere/express-bank"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Ekspress bank</a
                >
              </li>

              <li>Capitolia</li>
              <li>Facit bank</li>
              <li>Diba</li>
              <li>Pengeautomaten</li>
              <li>Lån fra IKEA</li>
              <li>Sparxpres</li>
              <li>Nordic lån</li>
              <li>Jutlander</li>
              <li>Blue Finans</li>
              <li>Komplett bank</li>
              <li>Folkia</li>
              <li>Alm. brand bank</li>
              <li>Nordisk lån</li>
              <li>Sparekassen Vendsyssel</li>
              <li>Arbejdernes Landsbank</li>
              <li>Pensam</li>
              <li>Zaplo</li>
              <li>Santander</li>
              <li>Nykredit</li>
              <li>Djursland bank</li>
              <li>Ikano</li>
              <li>Finans-247</li>
              <li>
                <a
                  href="https://saverium.dk/laan-penge/laaneudbydere/unilaan"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Unilaan</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },

  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
