<template>
  <div class="row article">
    <h1 class="center-title">
      Co to jest RRSO, jak obliczyć i jak wpływa na wysokość kredytu?
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >W ofertach banków i firm pożyczkowych obowiązkowo powinny znaleźć się
              wszystkie informacje dotyczące kosztów udzielanego zobowiązania, wraz z
              reprezentatywnym przykładem. W materiałach tych powinieneś znaleźć wysokość
              RRSO – parametr ten wyrażony jest jako wartość procentowa. Co to jest RRSO?
              Jakie znaczenie ma dla rzeczywistych kosztów kredytowania?</i
            >
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/what-is-rrso-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/what-is-rrso.jpg"
            alt="Co to jest RRSO, jak obliczyć i jak wpływa na wysokość kredytu?"
          />

          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            RRSO jest skrótem od rzeczywistej rocznej stopy oprocentowania, która została
            ona zdefiniowana na gruncie przepisów Ustawy z dnia 23 marca 2017 roku o
            kredycie hipotecznym oraz o nadzorze nad pośrednikami kredytu hipotecznego i
            agentami. Art. 4. pkt 17. wskazuje, że rzeczywista roczna stopa oprocentowania
            to całkowity koszt kredytu hipotecznego ponoszony przez konsumenta, wyrażony
            jako wartość procentowa całkowitej kwoty zobowiązania i podawany niezmiennie w
            stosunku rocznym.
          </p>
          <p>
            Nie tylko w zakresie kredytów z zabezpieczeniem hipotecznym można mówić o
            RRSO. Wskaźnik ten można określić dla wszystkich zobowiązań finansowych, a
            więc i kredytów gotówkowych, samochodowych czy też pożyczek, niekoniecznie
            udzielanych przez banki czy spółdzielcze kasy oszczędnościowo-kredytowe, na co
            wskazuje zdefiniowanie tego parametru w Ustawie z dnia 12 maja 2011 roku o
            kredycie konsumenckim. Art. 5. pkt 12., podobnie do wspominanego artykułu
            ustawy o kredycie hipotecznym, stanowi, że rzeczywista roczna stopa
            oprocentowania to całkowity koszt zobowiązania, jaki ponosi konsument,
            wyrażony jako wartość procentowa kwoty kredytu w stosunku rocznym. 8
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Błędem popełnianym przez wielu klientów porównujących ze sobą różne oferty
            kredytowe jest branie pod uwagę wyłącznie oprocentowania rzeczywistego. Stopa
            oprocentowania wyrażona w postaci stałej lub zmiennej w stosunku rocznym ma
            wpływ, często decydujący na koszt zobowiązania, ale nie jest to jedyny
            wydatek, jaki ponosi klient.
          </p>
          <p>
            Dlatego lepszym rozwiązaniem jest kontrolowanie w poszczególnych propozycjach
            zewnętrznego finansowania wysokości RRSO. Koszt całkowity kredytu podawany w
            formie procentowej pozwoli na wytypowanie najbardziej atrakcyjnej oferty. Na
            współczynnik RRSO składają się takie elementy jak:
          </p>
          <ul>
            <li>
              oprocentowanie kredytu naliczane według ustalonej w umowie stopy
              oprocentowania (stałej lub zmiennej), składające się zwykle z dwóch
              czynników: stawki referencyjnej, np. WIBOR(R) oraz marży banku, podlegającej
              negocjacjom,
            </li>
            <li>prowizja za przystąpienie do kredytu czy też za jego uruchomienie,</li>
            <li>składka ubezpieczeniowa związana z kredytem,</li>
            <li>opłata za rozpatrzenie wniosku,</li>
            <li>
              pozostałe opłaty i prowizje składające się na całkowity koszt kredytowania.
            </li>
          </ul>
          <p>
            RRSO ujmuje wszystkie wydatki związane z zobowiązaniem, ale bierze pod uwagę
            także zmianę wartości pieniądza w czasie. Jeśli wartość ta w wyniku czynników
            inflacyjnych ulegnie takiej zmianie w trakcie spłaty kredytu, inny też będzie
            poziom RRSO.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Wzór na RRSO jest skomplikowany i wynika on z wytycznych ustawowych. W ustawie
            o kredycie konsumenckim wprost określono, jak liczyć ten parametr kosztu
            kredytowego. Wzór na RRSO przedstawia się następująco:
          </p>
          <b-img
            class="rrso-function mb-4"
            src="/static/img/saverium_pl/article-images/function-rrso.jpg"
          />
          <p>
            By móc obliczyć RRSO, należy pozyskać wszystkie dane dotyczące kosztu
            kredytowania, okresu spłaty czy wysokości zobowiązania. Potrzebne są wartości
            ujęte w powyższym wzorze, tj.:
          </p>
          <ul>
            <li>X – rzeczywista roczna stopa oprocentowania,</li>
            <li>m – numer kolejny ostatniej wypłaty kredytu,</li>
            <li>k – numer kolejny wypłaty, zatem 1 ≤ k≤ m,</li>
            <li>Ck – kwota wypłaty k,</li>
            <li>
              tk – okres wyrażany w latach lub ułamkach lat, liczony pomiędzy dniem
              pierwszej wypłaty a dniem każdej kolejnej wypłaty, dlatego t1=0,
            </li>
            <li>m` – numer kolejny ostatniej spłaty lub wnoszonych opłat,</li>
            <li>1 – numer kolejny spłaty lub wnoszonych opłat,</li>
            <li>D1 – kwota spłaty lub wnoszonych opłat,</li>
            <li>
              S1 – okres wyrażony w latach lub ułamkach lat, między dniem pierwszej
              wypłaty a dniem każdej spłaty lub wniesienia opłat.
            </li>
          </ul>
          <p>
            Samodzielne wyliczanie wysokości RRSO bywa kłopotliwe, dlatego warto posłużyć
            się dostępnym w internecie kalkulatorem. Uwzględnia on takie parametry
            kredytowania jak:
          </p>
          <ul>
            <li>Kwota zobowiązania,</li>
            <li>okres spłaty,</li>
            <li>wysokość oprocentowania wyrażona procentowo,</li>
            <li>prowizja – wyrażona procentowo,</li>
            <li>inne koszty kredytowania,</li>
            <li>typ raty – równe lub malejące.</li>
          </ul>
          <p>
            W celu wyliczenia tego parametru według powyższego wzoru na RRSO trzeba
            przyjąć, że:
          </p>
          <ul>
            <li>
              Umowa kredytowa będzie obowiązywała przez cały okres, na jaki została ona
              zawarta, a strony wywiążą się ze swoich zobowiązań w określonych terminach.
            </li>
            <li>
              Kwoty wypłacone i spłacone przez kredytodawcę i kredytobiorcę niekoniecznie
              muszą być równe i nie muszą być płacone w równych odstępach czasu.
            </li>
            <li>
              Data początkowa będzie równocześnie datą wniesienia do banku pierwszej raty
              kapitałowo-odsetkowej.
            </li>
            <li>
              Odstępy pomiędzy datami używanymi w obliczeniach wyrażone będą w latach lub
              ułamkach lat.
            </li>
          </ul>
          <p>
            Wynik RRSO podawany jest z dokładnością do co najmniej jednego miejsca po
            przecinku. Można samodzielnie sporządzić formułę obliczeń RRSO. Jak obliczyć
            RRSO w Excelu? W tym programie nie ma gotowej formuły zwracającej wartość
            nominalnej rocznej stopy oprocentowania, ale można ją bez trudu wstawić.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Dla klienta najważniejsze jest, by RRSO wynosiło możliwie jak najmniej. Im
            miara określająca procentowy koszt całkowity kredytu będzie niższa, tym mniej
            kosztów poniesie się z tytułu spłaty długu do banku lub firmy pożyczkowej.
            Jeśli bank informuje, że udziela zobowiązania bez oprocentowania,
            niekoniecznie jego RRSO będzie wynosiło również 0 proc. w skali roku. Czasem
            jednak można znaleźć i takie oferty, najczęściej będą to promocyjne pierwsze
            pożyczki chwilówki dla nowych klientów w firmach pozabankowych lub kredyty
            ratalne w bankach.
          </p>
          <p>
            RRSO na poziomie 0 proc. będzie najkorzystniejsze dla klienta, ponieważ
            oznacza to, że zwróci on do banku taką samą kwotę pieniędzy, jaką otrzymał w
            transzach lub przy podpisywaniu umowy. Trzeba jednak uważać na propozycje
            kredytów z RRSO równym 0 proc., ponieważ często są one determinowane
            skorzystaniem z innych produktów i usług banku, w ramach oferty cross-selling,
            czyli sprzedaży wiązanej czy też krzyżowej.
          </p>
          <p>
            Porównywanie ze sobą ofert kredytów lub pożyczek o takich samych parametrach
            dotyczących wysokości kwoty zobowiązania czy okresu spłaty powinno odbywać się
            z uwzględnieniem parametru RRSO. To ułatwi obiektywne wybranie takiego
            zobowiązania, które będzie wiązało się z poniesieniem możliwie jak najniższych
            kosztów przez klienta.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import LoanWidget from '@/components/LoanWidget.vue'
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'RRSO – definicja ustawowa pojęcia',
          link: '#1',
        },
        {
          title: 'Co ujmuje swoim zasięgiem RRSO?',
          link: '#2',
        },
        {
          title: 'Jak obliczyć RRSO?',
          link: '#3',
        },
        {
          title: 'Jakie RRSO jest korzystne?',
          link: '#4',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped></style>
