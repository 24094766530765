<template>
  <div>
    <div class="article">
      <h1 class="center-title">Mobillån</h1>
      <div class="top-text text-container">
        <p>
          Hvis du har behov for et mobillån, så kan vi hjælpe dig med at finde de bedste
          og billigste mobillån uden sikkerhed på markedet. Hvis du udfylder vores
          formular, som tager 2 minutter, finder vi de bedste mobillån til dig.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#introduktion-til-mobillaan">Introduktion til mobillån</a>
                </li>
                <li>
                  <a href="#beloebsstoerrelser-for-mobillaan"
                    >Beløbsstørrelser for mobillån</a
                  >
                </li>
                <li>
                  <a href="#mobillaan-trods-rki-og-uden-sikkerhed"
                    >Mobillån trods RKI og uden sikkerhed</a
                  >
                </li>
                <li>
                  <a href="#mobillaan-med-nemid-og-mitid">Mobillån med NemID og MitID</a>
                </li>
                <li><a href="#aldersgraense">Aldersgrænse</a></li>
                <li>
                  <a href="#hvordan-finder-jeg-det-rette-laan"
                    >Hvordan finder jeg det rette lån?</a
                  >
                </li>
                <li>
                  <a href="#har-du-brug-for-vores-hjaelp">Har du brug for vores hjælp?</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="introduktion-til-mobillaan">Introduktion til mobillån</h2>
          <p>
            Et mobillån er et mindre lån, som du typsik optages via mobiltelefonen. Sådan
            var det i hvert fald tidligere, men i dag er mobillån i lige så høj grad
            noget, som du kan optage via din computer eller tablet, da det ikke er
            begrænset til en mobil.
          </p>
          <p>
            Mobillån startede tilbage i 10érne som de såkaldt <strong>SMS-lån</strong>,
            hvorfra synonymet mobillån opstod. Senere hen blev det i praksis umuligt at
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >
            over en simpel tekstbesked grundet lovgivning og de tekniske muligheder over
            nettet, som gjorde at mobillån blev den nye betegnelse. Mobillån dækker over
            <strong>små lån</strong>, på samme vis som
            <a
              href="https://www.saverium.dk/laan-penge/kviklaan"
              rel="noopener noreferrer"
              target="_blank"
              >kviklån</a
            >,
            <a
              href="https://www.saverium.dk/laan-penge/minilaan"
              rel="noopener noreferrer"
              target="_blank"
              >minilån</a
            >,
            <a
              href="https://www.saverium.dk/laan-penge/billaan/motorcykel%20https://www.saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtiglån</a
            >
            og delvist
            <a
              href="https://www.saverium.dk/laan-penge/online-laan"
              rel="noopener noreferrer"
              target="_blank"
              >online lån</a
            >. For alle disse låntyper gælder, at de falder ind under kategorien for små
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >.
          </p>
        </div>

        <div class="text-container">
          <h2 id="beloebsstoerrelser-for-mobillaan">Beløbsstørrelser for mobillån</h2>
          <p>
            Mobillån kommer i forskellige størrelser, men langt de fleste ligger på ca.
            5.000 kr. Kommer du over 10.000 kr., vil det typisk ikke blive betegnet som et
            mobillån længere, og går du under 3.000 kr., er udbuddet meget begrænset.
          </p>
          <p>
            Nedenfor vil vi gennemgå nogle af de mest almindelige ting, som vi ved at
            mobillån bliver brugt til.
          </p>
          <h3>Mobillån 10.000 kr.</h3>
          <p>
            Et mobillån på 10.000 kr. vil ofte være det største beløb, som falder ind
            under betegnelsen mobillån. Dette beløb kan dække langt de fleste af dine
            udskrivninger i din dagligdagen. Selv hvis du påtænker at
            <strong>låne til en ferie</strong> for dig og kæresten, så vil 10.000 kr. som
            oftest være tilstrækkeligt. Det vil nok ikke være nok til at kunne købe en
            brugt
            <a
              href="https://www.saverium.dk/laan-penge/billaan"
              rel="noopener noreferrer"
              target="_blank"
              >bil</a
            >
            eller
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan/baad"
              rel="noopener noreferrer"
              target="_blank"
              >båd</a
            >, uanset hvilken stand den så måtte være i, men hvis du blot behøver et
            tilskud, så kan et mobillån på 10.000 kr. måske alligevel være nok.
          </p>
          <h3>Mobillån 5.000 kr.</h3>
          <p>
            Et mobillån på 5.000 kr., vil være tilstrækkeligt for langt de fleste, som
            leder efter denne type lån. Hvis en af dine
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan/haarde-hvidevarer"
              rel="noopener noreferrer"
              target="_blank"
              >hårde hvidevarer</a
            >
            er gået i stykker og skal erstattes eller repareres, så vil et mobillån på
            5.000 kr. ofte være tilstrækkeligt, med mindre at du ønsker at købe det
            største og nyeste køleskab til at erstatte dit eksisterende.
          </p>
          <p>
            Er din cykel blevet stjålet, er situationen den samme for langt de fleste. En
            sprit ny racercykel koster ofte mere end 5.000 kr., men hvis du blot skal have
            en reparation eller ønsker at købe en god citybike, så vil et mobillån på
            5.000 kr. fint kunne dække dit behov.
          </p>
          <p>
            Det samme gør sig gældende for mange af de ting du finder i hjemmet, herunder
            tv, højtalere, spisebord og seng, hvorfor mobillån på 5.000 kr. er et meget
            almindeligt
            <a
              href="https://www.saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              >lånebeløb</a
            >. Vi kunne nævne mange produkter, som kan dækkes af mobillån på 5.000 kr.,
            men for at listen ikke bliver for lang, vil vi stoppe her. Skal du have det
            nyeste nye eller noget der er større end gennemsnittet, så er det dog ikke
            altid, at et mobillån på 5.000 kr. er kan dække dit behov.
          </p>
          <h3>Mobillån under 5.000 kr.</h3>
          <p>
            Et mobillån på under 5.000 kr. kan i stor udstrækning benyttes på alle de
            samme ting, som et mobillån på 5.000 kr. Kvaliteten vil alt andet lige være
            lavere, hvis du betaler mindre for din genstand, men produktkategorierne, som
            du kan købe, vil i stor udstrækning være de samme.
          </p>
          <p>
            Hvis du skal have dækket en
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan/dyrlaage"
              rel="noopener noreferrer"
              target="_blank"
              >dyrlægeregning</a
            >, kan et mobillån på 2.000 kr. ofte være nok, og hvis du skal låne til en
            <strong>tandlægeregning</strong>, så er 4.000 kr. også ofte nok, selv ved
            halvstore indgreb.
          </p>
          <p>
            Hvis u blot er løbet tør for penge sidst på måneden, men får en regning på el,
            vand eller varme, eller er det blot det sidste indkøb på måneden der skal
            klares, så kan 500 eller 1.000 kr. som regl også være nok.
          </p>
        </div>

        <div class="text-container">
          <h2 id="mobillaan-trods-rki-og-uden-sikkerhed">
            Mobillån trods RKI og uden sikkerhed
          </h2>
          <p>
            Teoretisk set er det muligt at optage et mobillån trods RKI uden at stille
            sikkerhed, men det er dog sjældent en realitet. Hvis du ønsker et lån, uanset
            hvilken type lån du søger, så må långiveren gerne udstede lån til dig, på
            trods af at du står registreret i RKI eller Debitor Registret. En anmærkning
            som dårlig betaler er blot en stærk indikator for långiveren om, at der er
            risiko for, at pengene ikke bliver tilbagebetalt.
          </p>
          <h3>Lovgivning</h3>
          <p>
            Hvis man kigger på lovgivningen på området, er der to tilfælde, som oftes er
            udslagsgivende for at en långiver ikke må udstede lån. For det første må din
            gældsfaktor ikke være for høj, hvilket betyder, i praksis betyder, at jo mere
            du tjener, jo mere må du skylder væk. Hvis din gældsfaktor bliver for høj, så
            må långiveren ikke udstede lån til dig.
          </p>
          <p>
            Den anden faktor er rådighedsbeløbet, hvilket betyder at du skal have et
            bestemt rådighedsbeløb tilbage at leve for. Dette afhænger af din
            livssituation og andre omkostninger.
          </p>
          <h3>Långiverens kreditvurdering</h3>
          <p>
            I forhold til mobillån, så er det sjældent disse to faktorer, som er afgørende
            for, om du må låne. Det er derimod
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbyderens</a
            >
            egen kreditvurdering af dig, som ligger til grund for, om de ønsker at låne
            dig penge. Hvis du ønsker et mobillån uden sikkerhed og du samtidig står i
            RKI, løber långiveren dermed en stor risiko. Dermed ikke sagt, at det er 100%
            umuligt.
          </p>
          <p>
            Du kan finde mange hjemmesider, der lokker med mobillån og “mobile lån” trods
            RKI, som i virkeligheden ikke kan tilbyde det, uanset om du så stiller
            sikkerhed eller ej. Vi vil også for en god ordens skyld nævne, at dine chancer
            hos os er små. Dog er vi nok det bedste sted at søge, da du dermed kommer til
            at ansøge hos en lang række af de långivere, som potentielt set kunne tilbyde
            mobillån trods RKI.
          </p>
          <h3>Kauktionist</h3>
          <p>
            Hvis du står i RKI, men har mulighed for at finde en kautionist, som kan være
            garant for dit mobillån, så er situationen lige pludselig helt anderledes.
            Dermed er det denne person, som bliver kreditvurderet af långiveren, hvilket
            kan være en fordel for dig, hvis du ønsker et mobillån trods RKI og du ikke
            ønsker at stille sikkerhed.
          </p>
        </div>

        <div class="text-container">
          <h2 id="mobillaan-med-nemid-og-mitid">Mobillån med NemID og MitID</h2>
          <p>
            Hvis du ønsker at låne penge over mobilen, så er det i dag svært at komme uden
            om NemID/MitID. Os bekendt underskrives alle
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >lån med NemID eller MitID</a
            >, uanset hvilken form for
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan"
              rel="noopener noreferrer"
              target="_blank"
              >privatlån</a
            >
            du ønsker at optage
          </p>
          <p>
            Tidligere, dengang da digital underskrift ikke fantes, smartphones var mindre
            udbredt og bankernes processer var knap så digitale og automatiserede, kunne
            du finde lån, som kunne signeres med en fysisk underskrift.
          </p>
          <p>
            Af sikkerhedsmæssige årsager, samt besparelserne for banken ved at få en
            digital underskrift med NemID eller MitID på dit mobillån, er alle udbydere i
            dag gået over til denne form for underskrift.
          </p>
        </div>

        <div class="text-container">
          <h2 id="aldersgraense">Aldersgrænse</h2>
          <p>
            Aldersgrænsen for mobillån er 18 år, på lige fod med andre former for lån. Vi
            samarbejder med en lang række
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >udbydere af lån</a
            >, som hver især har deres individuelle krav til låntagere. For nogle er det
            et krav at du skal være fyldt 18 år for at få et mobillån. For andre er
            grænsen for mobillån 20 år eller 21 år.
          </p>
          <p>
            Hvis du leder efter et mobillån og er i tvivl om, hvorvidt du er inden for
            eller uden for aldersgrænsen for at kunne optage lånet, så foreslår vi at du
            ansøger på vores side. Det er hurtigere og nemmere at ansøge, sammenlignet med
            at undersøge betingelserne hos hver enkelt udbyder. Samtidig indhenter vi på
            den måde tilbud til dig fra alle udbydere, så du ikke behøver at ansøge
            bagefter.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvordan-finder-jeg-det-rette-laan">
            Hvordan finder jeg det rette lån?
          </h2>
          <p>
            Først og fremmest skal du have et sammenligninggrundlag. Den nemmeste måde at
            få et sammenligningsgrundlag er at udfylde vores formular, da vi på den måde
            indhenter tilbud fra en lang række banker, så du nemt kan
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne lånene</a
            >. Denne del af processen tager blot to minutter, da vores ansøgningsformular
            er kort, og vi indhenter tilbudene helt automatisk og med det samme.
          </p>
          <p>
            Når du har fået alle dine tilbud, skal du gøre op med dig selv, hvad der er
            vigtigst for dig i et mobillån. De fleste vægter et hurtigt mobillån højt, men
            lige så vigtigt er det at se på ÅOPén, der fortæller dig hvilket lån, der er
            billigst - såfremt løbetiderne og beløbene er ens.
          </p>
          <p>
            Hvis løbetid og lånebeløbet ikke er ens, kan du evt. sammenligne på baggrund
            af den totale tilbagebetaling og den månedlige ydelse, hvilket vil være vores
            forslag.
          </p>
        </div>

        <div class="text-container">
          <h2 id="har-du-brug-for-vores-hjaelp">Har du brug for vores hjælp?</h2>
          <p>
            Hvis du er i tvivl om noget omkring vores process i forhold til at finde og
            sammenligne mobillån, så kontakt os gerne på email eller telefon. Vi er klar
            til at hjælpe dig, uanset om du ønsker at optage et nyt mobillån eller
            <a
              href="https://www.saverium.dk/laan-penge/samlelaan"
              rel="noopener noreferrer"
              target="_blank"
              >samle eksisterende lån</a
            >.
          </p>
        </div>
        <div class="text-container">
          <h2 id="laan-penge-til-en-ny-mobil">Lån penge til en ny mobil</h2>
          <p>
            Hvis dit behov for et mobillån i virkeligheden handler om at låne til en
            mobil, så kan vi også hjælpe. Har du fx et brændende ønske om at låne til den
            nyeste iPhone, så send en ansøgning.
          </p>
          <p>
            Vær opmærksom på, at det er svært at låne til en ny mobil hvis du står i RKI,
            uanset om det er en mobil med eller uden abonnement.
          </p>
          <h3>Lån mindst mulig</h3>
          <p>
            Hvis du ønsker at begrænse dit lån til din nye iPhone / mobil, så har vi nogle
            forslag, som du kan overveje.
          </p>
          <ul>
            <li>
              Overvej om du behøver at låne til den nyeste iPhone, eller om du kan nøjes
              med en ældre version, som du kan købe brugt. Ofte kan du spare 50% ved at
              købe modellen fra sidste år brugt.
            </li>
            <li>
              Hvis du i stedet for at låne til en iPhone, vælger at købe en Android-mobil,
              kan du ofte spare mange penge. Langt de fleste apps fungerer på Android på
              samme måde som på en iPhone.
            </li>
            <li>
              Lån til en mobil med abonnement. Hvis du vælger at købe din mobil sammen med
              et abonnement, er der også ofte mange penge at spare. Det gælder dog ikke i
              lige så stor stil hvis du køber / låner til en iPhone, som en Android
              telefon. Uanset hvad får du brug for et abonnement, og derfor kan det også
              ofte også betale sig at binde sig til et abonnement i forbindelse med købet
            </li>
          </ul>
          <h3>Andre omkostninger til din mobil</h3>
          <p>
            Ud over at låne til selve mobilen, så er der muligvis også andre omkostninger,
            som du skal medregne. Har du fx brug for et nyt headset? Så skal det også med
            i lånet til mobilen.
          </p>
          <p>
            Mange nye mobiltelefoner/smartphones har heller ikke en oplader med som
            udgangspunkt. Hvis du ønsker en hurtig oplader, som de fleste nye smartphones
            kan udnytte, er du derfor nødt til at købe den separat.
          </p>
          <p>
            I nogle tilfælde kan du også komme til at skulle betale for apps, hvis du
            skifter fra fx Android til iPhone, og hvis du også skal tilkøbe et cover, så
            skal det også oven i prisen.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
