<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til bryllup</h1>

      <div class="top-text text-container">
        <p>
          Har I brug for et at låne penge til jeres bryllup? Vi kan hjælpe jer med at
          finde det rette bryllupslån, så I slipper så billigt som muligt. Hvis I udfylder
          vores to-minutters ansøgningsskema her på siden, indhenter vi med det samme
          tilbud på lån til jeres bryllup fra en lang række banker og andre
          pengeinstitutter.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#saadan-ansoeger-i-om-at-laane-penge-til-bryllupet"
                    >Sådan ansøger I om at låne penge til bryllupet</a
                  >
                </li>
                <li>
                  <a href="#hvorfor-ansoege-om-et-bryllupslaan"
                    >Hvorfor ansøge om et bryllupslån</a
                  >
                </li>
                <li>
                  <a
                    href="#har-i-brug-for-vores-hjaelp-til-at-finde-et-laan-til-bryllupet"
                    >Har I brug for vores hjælp til at finde et lån til bryllupet?</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="saadan-ansoeger-i-om-at-laane-penge-til-bryllupet">
            Sådan ansøger I om at låne penge til bryllupet
          </h2>
          <p>
            Hvis I ønsker at vi skal indhente tilbud på lån til jeres bryllup, så skal I
            som nævnt i første omgang udfylde vores ansøgning. Herefter fremsender vi de
            oplysninger, som I har opgivet, til bankerne, som derefter vender tilbage med
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >lånetilbud</a
            >
            på jeres brullupslån. Hele processen med at fremsende informationerne og
            indhente tilbud foregår automatisk og med det samme, så I, uden at vente, kan
            få svar på, hvem der kan låne jer hvilke beløb og på hvilke vilkår.
          </p>
          <p>
            Herefter er det op til jer at vælge, hvilket bryllupslån I ønsker. Vi
            opstiller alle tilbud på overskuelig vis, så de er nemme at sammenligne, så I
            hurtigt kan vælge
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >det bedste lån</a
            >
            til jeres bryllup.
          </p>
          <p>
            Når I har fundet det helt rigtige tilbud viderestiller vi jer til
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbyderens</a
            >
            hjemmeside, som vil foretage den endelige verificering af jeres data inden
            lånet udbetales.
          </p>
          <p>
            Hele processen tager typisk 5 minutter fra I starter jeres ansøgning til at
            pengene står på jeres konto.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvorfor-ansoege-om-et-bryllupslaan">
            Hvorfor ansøge om et bryllupslån
          </h2>
          <p>
            Et bryllup er ofte en meget stor udgift, da der er mange større udgifter, som
            alle sammen skal betales på én og samme tid. Hvis I ønsker et stort
            kirkebryllup, med en stor fest for hele familien og alle vennerne, så skal I
            ikke blive overrasket, hvis omkostningerne løber op i
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/50000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >50.000 kr.</a
            >,
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/75000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >75.000 kr.</a
            >
            eller helt op i
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/100000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >100.000 kr.</a
            >
            Derfor vælger mange også at optage et et
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >
            for at dække omkostningerne i første omgang.
          </p>
          <h3>Bryllupsfesten</h3>
          <p>
            En af de helt store udgifter i forbindelse med bryllupet er festen. Her skal
            der nemlig både betales for tjenere, band/musik, festlokaler, oppyntning og en
            kuvertpris for hver enkelt gæst.
          </p>
          <p>
            Hvis I ønsker at holde et billigt bryllup, hvor I ikke behøver at
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >, men I samtidig ikke har sparet op i lang tid, så kan I fx slippe langt
            billigere ved at fravælge livemusik, vælge et billigere cateringfirma eller
            lave en del af madden og oppyntingen selv, hvis I ikke ønsker at skære i
            invitationslisten.
          </p>
          <h3>Bryllupskagen</h3>
          <p>
            Bryllupskagen er endnu en udgift i forbindelse med bryllupet, som i sig selv
            heller ikke kræver et bryllupslån, men er en udgift som også skal tages med i
            budgettet. Vælger I fx en
            <a
              href="https://laglace.dk/sortiment/bryllupskager/#:~:text=Priserne%20p%C3%A5%20en%20bryllupskage%20ligger,Deres%20%C3%B8nsker%20og%20vores%20muligheder."
              rel="noopener noreferrer"
              target="_blank"
              >bryllupskage fra La Glace</a
            >, skal I regne med en kuvertpris på 125-165 kr. Vælger I derimod en
            <a
              href="https://www.bakingsins.dk/priser"
              rel="noopener noreferrer"
              target="_blank"
              >billig bryllupskage i ét lag fra fx Baking Sins By Lea</a
            >, kan i nøjes med 55 kr./kuvert.
          </p>
          <p>
            Hvis der er gået en konditor tabt i en af jer, eller en af jeres bekendte, så
            kan I selvfølgelig også minimere risikoen for at skulle låne penge til
            bryllupet, eller blot minimere jeres bryllupslån, hvis I, eller jeres
            bekendte, kan forberede kagen.
          </p>
          <h3>Brudekjole, hår og makeup</h3>
          <p>
            Hvis du ønsker at føle dig som en prinsesse for en dag (her taler vi
            selvfølgelig til bruden), så kommer du ikke uden om brudekjole, opsætning af
            hår og professionel makeup.
          </p>
          <p>
            Den største udgift kommer ofte i forbindelse med brudekjolen, som typisk
            koster mellem
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/10000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >10.000 kr.</a
            >
            og
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/15000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >15.000 kr.</a
            >
            Her har du også mulighed for at minimere jeres lån til brylluppet, ved at
            vælge en langt billigere model fra nogle af de almindelige tøjmærker, som også
            kan bruges som festkjole fremover.
          </p>
          <p>
            Hvis du har en veninde, som kan lægge din makeup og sætte dit hår, så kan du
            også slippe uden om den sidste del af bryllupslånet. Er det ikke en mulighed,
            skal du forvente at det koster
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/3000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >3000 kr.</a
            >
            til
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/5000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >5000 kr.</a
            >
            for hår og makeup hver især.
          </p>
          <h3>Ringe</h3>
          <p>
            Vielsesringe og forlovelsesring er ofte en betydelig omkostning ved bryllupet.
          </p>
          <p>
            En forlovelsesring og en vielsesring koster ofte en månedsløn hver især, evt.
            i form af et bryllupslån i første omgang. Hvis man følger de gamle
            traditioner, vil det være gommen, der betaler for ringen, men hvis I er et
            mindre traditionelt par eller blot har fællesøkonomi, så er det måske ikke
            tilfældet for jer.
          </p>
          <p>
            Da en månedsløn kan være meget forskellig fra person til person, kommer både
            forlovelses- og vielsesringe også i mange prisklasser alt efter hvilket metal
            der skal bruges, og om der skal være nogen form diamant eller anden ædelsten i
            ringen.
          </p>
          <p>
            Når det er sagt, så er der også meget stor forskel på, hvor meget man som par
            og kommende mand og kone, ønsker at bruge på ringe. Mange par vælger også en
            mindre materialistisk tilgang og bruger i stedet for pengene på festen og
            bryllupsrejsen.
          </p>
          <h3>Bryllupsrejsen</h3>
          <p>
            En bryllupsrejse kan også nemt blive en dyr fornøjelse selv uden børn. Mange
            vælger at tage på en længere rejse lige efter bryllupsdagen, men lige som de
            andre elementer af bryllupet, så er der også stor forskel på bryllupsrejser.
          </p>
          <p>
            Vil I en kort tur til et sted i Europa, eller vil I på en lang rejse længere
            væk? Hvis rejsen er kort, er der ofte ikke brug for at låne penge til
            bryllupet til lige netop denne post, men hvis I ønsker en længere rejse, så
            kan et
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan/ferie"
              rel="noopener noreferrer"
              target="_blank"
              >lån til at finansiere rejsen</a
            >
            være værd at overveje.
          </p>
          <h3>Bryllupsfotograf</h3>
          <p>
            En bryllupsfotograf varierer også meget i pris, alt efter hvem hvad I ønsker.
            Er det mange eller få billeder, er det hele dagen eller blot et par timer. Alt
            fra et par tusinde kroner op til
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/20000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >20.000 kr.</a
            >
            kan siges at være inden for normalen.
          </p>
          <p>
            Vælger I den dyre udgave, er I også mere sikre på at fotografen har god
            forståelse for komposition, er god til at fange autentiske billeder, god til
            at redigere billederne og har alt det tekniske udstyr i form af kamera, lys,
            kameralinser m.v., som skal til for at fange de helt perfekte
            bryllupsbilleder.
          </p>
          <h3>Diverse udgifter til bryllupet</h3>
          <p>
            Ud over ovennævnte udgifter, kan der også være omkostninger til fx
            brudebukett, danseundervisning, hvis I ikke har helt styr på brudevalsen,
            takkekort til alle deltagere til bryllupet, rengøring i lokalerne efter
            festen, overnatning efter festen og drikkevarer.
          </p>
          <p>
            Vielsen i sig selv, uanset om den så foregår i kirken eller på rådhuset er det
            helt gratis, men hvis I ønsker jer en uforglemmelig oplevelse, så kan det ofte
            blive dyrt, og dermed kan der blive behov for at låne penge til jeres bryllup.
            Hvis I derimod ikke ønsker at låne til bryllupet, kan Ioverveje, om I kan
            nøjes med lidt mindre - fx ved at spare på nogle af de områder, som vi har
            gennemgået ovenfor. Hvis I er heldige, kan I måske helt undgå bryllupslånet.
          </p>
        </div>

        <div class="text-container">
          <h2 id="har-i-brug-for-vores-hjaelp-til-at-finde-et-laan-til-bryllupet">
            Har I brug for vores hjælp til at finde et lån til bryllupet?
          </h2>
          <p>
            Hvis I kan se, at der er behov for at låne penge til jeres bryllup, så hjælper
            vi jer gerne med at finde det rette lån. Ved at udfylde vores korte
            ansøgningsformular her på siden, kan vi indhente tilbud på bryllupslån til jer
            på få minutter.
          </p>
          <p>
            Uanset om I ønsker at benytte tilbudene eller ej, så kan det være en god ide
            at udfylde ansøgningen, da I derved får en ide om, hvor meget I kan låne til
            jeres bryllup og hvad prisen evt. vil være. I og med at vi indhenter mange
            forskellige tilbud, får I hurtigt en ide om, hvad et bryllupslån i jeres
            tilfælde koster. På den måde kommer i ikke til at vælge et dyrt lån, som I
            evt. får tilbudt hos en enkelt udbyder af bryllupslån.
          </p>
          <p>
            Husk at jeres muligheder for at få et lån er bedst, og prisen på lånet vil
            være lavere, hvis I ansøger om lånet til jeres bryllup sammen.
          </p>
          <p>
            Hvis I har spørgsmål, så husk at I altid kan kontakte os på telefon og email.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
