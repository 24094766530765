<template>
  <b-container fluid class="offer-container">
    <div class="loan-header">{{ $t('offers_for_application') }} #{{ applicationID }}</div>
    <template v-if="offers.length > 0">
      <div v-for="offer in offers" :key="offer.uuid">
        <template v-if="offer.options.length && offers.length >= 1">
          <offer-component :offer="offer" />
        </template>
      </div>
    </template>
    <template v-if="!hasOffers(offers)">
      <b-card class="mt-2">{{ $t('waiting_for_offers') }}</b-card>
    </template>
  </b-container>
</template>

<script>
import OfferComponent from '@/components/account_view_components/OfferComponent'
export default {
  components: {
    OfferComponent,
  },
  computed: {
    offers() {
      return this.$store.getters.getCurrentApplication.offers
    },
    applicationID() {
      return this.$store.state.offers.currentApplicationId
    },
  },
  methods: {
    hasOffers(offers) {
      if (offers.length === 0) {
        return false
      }
      let twoLevelOptions = offers.map(offer => offer.options)
      var allOptions = []
      for (let i = 0; i < twoLevelOptions.length; i++) {
        allOptions = allOptions.concat(twoLevelOptions[i])
      }
      if (allOptions.length === 0) {
        return false
      }
      return true
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.company-banner {
  max-width: 100%;
  max-height: 80px;
}
</style>
