<template>
  <div class="row article non-landing">
    <h1 class="center-title">Hvad skal dit rådighedsbeløb være i 2022?</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            Hvis man ønsker at skabe et overblik over ens privatøkonomi, er ens
            rådighedsbeløb et fornuftigt sted at starte. Det vil kunne fortælle dig hvor
            du kan forbedre din privatøkonomi ved at lave nogle få udregninger.
          </p>
          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_dk/article-imgs/available-amount-in-2022-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_dk/article-imgs/available-amount-in-2022.jpg"
            alt="Hvad skal dit rådighedsbeløb være i 2022?"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul>
              <li>
                <a href="#hvad-er-ens-raadighedsbeloeb"> Hvad er ens rådighedsbeløb? </a>
              </li>
              <li>
                <a href="#hvad-dit-raadighedsbeloeb-boer-vaere">
                  Hvad dit rådighedsbeløb bør være
                </a>
              </li>
              <li>
                <a href="#saverium-hjaelper-dig-paa-laanemarked">
                  Saverium hjælper dig på lånemarked
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container">
          <h2 id="hvad-er-ens-raadighedsbeloeb">Hvad er ens rådighedsbeløb?</h2>
          <p>
            Ens rådighedsbeløb er det beløb du har månedligt tilbage af din løn, efter du
            har betalt alle dine faste udgifter. Dine faste udgifter dækker over de
            udgifter som du betaler fast hvert kvartal eller hver måned. Det kan være alt
            fra forsikringer, husleje og afbetaling af gæld osv.
          </p>
          <p>
            Dermed er det <strong>meget vigtigt</strong> at have styr på ens
            rådighedsbeløb, siden det er det beløb du lever af hver måned, og det viser
            hvordan din privatøkonomi ser ud.
          </p>
          <p>
            <strong>Gratis tip:</strong> Det vil være en god ide at undersøge om du måske
            skulle have penge til gode fra skat. Ikke alle dansker får deres fradrag
            noteret, som kan gå hen og betyde at de går glip af deres egne penge.
          </p>
          <p>
            <a
              href="https://taxhelper.dk/?referrer=saverium"
              rel="noopener noreferrer"
              target="_blank"
              >Du kan klikke her, og se om du skulle have penge til gode.</a
            >
          </p>
          <h3><strong>Hvad dækker over dit rådighedsbeløb</strong>?</h3>
          <p>
            Som nævnt tidligere dækker rådighedsbeløbet over dit forbrug. Det drejer sig
            normalt om:
          </p>
          <ul>
            <li>Husholdningen (mad, vaskemiddel osv.)</li>
            <li>Tøj og sko</li>
            <li>Personlig pleje (medicin, tandpasta osv.)</li>
            <li>Andet forbrug (transport, Netflix osv.)</li>
            <li>Opsparing</li>
            <li>Ferie</li>
            <li>Specielle anledninger og gaver (fødselsdage, jul osv.)</li>
            <li>Uforudsete udgifter</li>
          </ul>
          <p>
            Derfor er det vigtigt, at du husker at din opsparing og dine uforudsete
            udgifter også skal inkluderes i beløbet. Derfor er et godt og gratis råd, at
            du bestemmer dig for, hvor stor en del af din løn der skal gå til de to
            poster.
          </p>
          <h3><strong>Anbefalet rådighedsbeløb</strong></h3>
          <p>
            Det rådighedsbeløb som anbefales, varierer efter nogle kriterier, blandt andet
            hvor gammel du er, hvor mange personer der bor i husstanden, og hvor du bor.
          </p>
          <p>
            Som et eksempel er der flere låneudbydere, der anbefaler, at du har et større
            rådighedsbeløb, hvis du bor i en af de større byer i Danmark. Dette skyldes,
            at prisniveauet og dine udgifter til bolig bare generelt er højere end i andre
            dele af landet.
          </p>
          <p>
            Det vigtigste er din egen vurdering af, hvor mange penge du kan leve for hver
            måned. Derfor bør du sætte dig et realistisk beløb, så din økonomi ikke bliver
            for presset.
          </p>
          <p>
            Der findes nogle anbefalede rådighedsbeløb, som du kan bruge som en guide til
            at skabe et bedre overblik over din økonomi. Det er dog vigtigt at nævne, at
            det er anbefalinger, og kravene kan derfor være forskellige for eksempel fra
            bankerne, hvis du ønsker at optage et hurtigt lån.
          </p>
        </div>
        <div class="text-container">
          <h2 id="hvad-dit-raadighedsbeloeb-boer-vaere">
            Hvad dit rådighedsbeløb bør være
          </h2>
          <p>
            Der findes forskellige anbefalinger som føres af
            <a
              href="https://www.finanstilsynet.dk/"
              rel="noopener noreferrer"
              target="_blank"
              >Finanstilsynet</a
            >, om hvad dit rådighedsbeløb pr. måned bør være, alt efter om du har en
            samboende, med eller uden børn, eller om du bor alene.
          </p>
          <h3><strong>Rådighedsbeløb for en person</strong></h3>
          <p>
            Hvis du bor for alene, anbefaler Finanstilsynet, at dit rådighedsbeløb er
            omkring:
          </p>
          <ul>
            <li>5.000-6.000 kr. om måneden</li>
          </ul>
          <p>
            Når du bor alene, er du kun dig selv til at betale de faste udgifter som
            husleje, forsikringer, varme og elregninger, gæld osv. Udover det er det ofte
            os dyrere at købe ind til mad pr. person, da du normalt måske ikke køber ind i
            store mængder og derfor ikke får samme rabatter.
          </p>
          <p>
            Hvis du er studerende, kan du være undtaget af denne vejledning, da der er
            mange studerende, der har under 5.000 kr. til sig selv, efter at de har betalt
            alle deres faste udgifter.
          </p>
          <h3><strong>Rådighedsbeløb for to voksne</strong></h3>
          <p>Finanstilsynet anbefalede rådighedsbeløb for to samboende uden børn er:</p>
          <ul>
            <li>8.500-10.000 kr. om måneden</li>
          </ul>
          <p>
            Når man er to personer, deler man typisk de fleste af udgifterne på den ene
            eller anden måde, og derfor vurderer Finanstilsynet, at to samlevende kan leve
            for et mindre beløb pr. person om måneden, end en person som bor alene. Så
            bliver nogle af de andre udgifter også billigere pr. person som for eksempel
            mad.
          </p>
          <h3><strong>Rådighedsbeløb for en familie</strong></h3>
          <p>
            Ifølge
            <a
              href="https://taenk.dk/forbrugerliv/familieliv"
              rel="noopener noreferrer"
              target="_blank"
              >Forbrugerrådet</a
            >
            koster ét barn samlet ca. 1.3000.000 kr. fra fødslen til deres 18-års
            fødselsdag. Finanstilsynet anbefaler derfor følgende beløb pr. barn:
          </p>
          <ul>
            <li>2.500 kr. pr. barn om måneden oveni de 8.500-10.000 for to samlevende</li>
          </ul>
          <p>
            Rådighedsbeløbet for børnefamilier dækker udgifter til tøj, mad samt personlig
            pleje og en lang række andre områder. Derudover er der udgifter som eventuel
            børneopsparing, men de bør inkluderes i de faste udgifter, og dermed skal
            rådighedsbeløbet ikke bruges i den her situation.
          </p>
          <h3><strong>Rådighedsbeløb som pensionist</strong></h3>
          <p>
            Hvis du er pensionist, anbefaler Finanstilsynets de samme, hvilket er afhængig
            af om du er enlig eller har en samboende. En enlig pensionist anbefales at
            have et rådighedsbeløb på 5.000-6.000 kr., og samlevende pensionister
            anbefales at have 8.500-10.000 kr. i rådighedsbeløb.
          </p>
          <p>
            Kravene fra de forskellige låneudbydere kan dog være anderledes, hvis du
            ønsker at låne penge. Det skyldes, at låneudbyderne vurderer dig ud fra en
            række kriterier end dit rådighedsbeløb, hvor de tager stilling til det ønskede
            lånebeløb samt løbetiden.
          </p>
        </div>
        <div class="text-container">
          <h2 id="saverium-hjaelper-dig-paa-laanemarked">
            Saverium hjælper dig på lånemarked
          </h2>
          <p>
            Saverium.dk er her for at hjælpe dig med at sammenligne lånemarked for at give
            alle en fair chance så du kan finde det bedste lånetilbud nemt. Vi
            sammenligner lånetilbud fra flere velkendte banker og låneudbydere med kun én
            enkelt
            <a
              href="https://www.saverium.dk/ansoegning"
              rel="noopener noreferrer"
              target="_blank"
              >ansøgning</a
            >. Du kan ansøge om et lån fra 3000 - 500.000 kroner alt efter dine egne behov
            med en låneperiode på 1-15 år.
          </p>
          <p></p>
          <p>2022-02-02/Søren</p>
        </div>
      </div>
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
  </div>
</template>
<script>
import LoanWidget from '@/components/saverium_dk/seo_components/LoanWidget.vue'
export default {
  components: {
    LoanWidget,
  },
}
</script>
