<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til plastikkirurgi</h1>

      <div class="top-text text-container">
        <p>
          Ønsker du at låne til plastikkirurgi? Hvis du ansøger igennem os, finder vi
          hurtigt flere forskellige udbydere, som kan hjælpe dig med at finansiere din
          kosmetiske operation. Uanset om du har behov for et lån til en fedmeoperation,
          et lån til en brystforstørrelse eller noget helt tredje, så hjælper vi dig
          gerne. Udfyld vores ansøgning på 2 minutter og se, hvilke muligheder du har for
          at låne.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#saadan-laaner-du-til-plastikkirurgi-med-os"
                    >Sådan låner du til plastikkirurgi med os</a
                  >
                </li>
                <li>
                  <a href="#laan-til-forskellige-operationer"
                    >Lån til forskellige operationer</a
                  >
                </li>
                <li>
                  <a href="#skal-vi-hjaelpe-dig-med-at-finansiere-din-operation"
                    >Skal vi hjælpe dig med at finansiere din operation?</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="saadan-laaner-du-til-plastikkirurgi-med-os">
            Sådan låner du til plastikkirurgi med os
          </h2>
          <p>
            Når du ansøger om lån og finansiering til plastikkirurgi igennem os, er det
            første som vi har behov for, at du udfylder vores 2-minutters online
            ansøgningsskema. Her oplyser du diverse informationer, som vi efterfølgende
            kan videreformidle til den lange række af banker og alternative
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere</a
            >, som vi samarbejder med i forhold til finansiering og lån til
            plastikkirurgi.
          </p>
          <p>
            Disse banker / udbydere vurderer herefter, automatisk og med det samme, om de
            kan tilbyde dig et lån til plastikkirurgien og evt. til hvilken rente, løbetid
            og på hvilke vilkår. Her er det ikke afgørende, om du låner til
            brystoperationen, fedmeoperationen eller en anden operation. Du vil blot blive
            vurderet på din økonomi, da långiveren skal sikre sig, at du kan tilbagebetale
            lånet.
          </p>
          <p>
            Så snart vi får svar tilbage fra långiverne, vil du kunne se, hvad hver enkelt
            udbyder kan tilbyde dig af lån i forhold til din operation. Vi opstiller alle
            operationslån på en overskuelig og sammenlignelig liste, så du hurtigt og nemt
            kan vælge det
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >, som du ønsker skal dække din kosmetiske operation.
          </p>
          <p>
            Når du har valgt hvilket firma, som skal finansiere dit plastikkirurgiske
            indgreb, vil vi sende dig over på dette firmas hjemmeside, hvor du via
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >MitID</a
            >, kan verificere dine oplysninger, så lånet kan udbetales. Herefter udbetales
            lånet, så du kan bestille din kosmetiske operation.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-forskellige-operationer">Lån til forskellige operationer</h2>
          <p>
            I forbindelse med mange forskellige plastikkirurgiske operationer, kan det
            være relevant at
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >. Dette skyldes især, at kosmetiske operationer oftest ikke er billige.
            Nedenfor har vi samlet nogle af de mest almindelige plastikkirurgiske indgreb,
            samt hvad de koster, så du hurtigt kan danne dig et overblik over, om du har
            brug for at låne til din plastikkirurgi, eller om du har penge nok på kontoen
            i forvejen.
          </p>
          <h3>Brystforstørrelse og brystløft</h3>
          <p>
            Den hyppigste kosmetiske operation er en brystforstørrende operation (BFO).
            Denne kan enten blive foretaget ved at sprøjte (injecere) dit eget fedt fra en
            fedtsugning (liposuction), ind i brysterne, eller via silicone implantater,
            som de fleste nok kender til.
          </p>
          <p>
            Fordelen ved ved at bruge eget fedt, er at dette væv ikke kan afstødes af din
            krop, da det er dine egne celler, som blot omfordeles på kroppen. Hvis du
            synes, at du har for meget fylde på sidebenene, så kan du både få større
            bryster og en fladere mave i samme operation. Ulempen er derimod, at operation
            er mere omfattende, og at du ikke kan være sikker på, hvor stor en del af det
            transplanterede fedt, som sætter sig fast i brysterne, og hvor meget kroppen
            selv skyller ud, som døde celler.
          </p>
          <p>
            Hvis du ønsker en brystforstørrende operation med eget fedt eller med
            silikoneimplantater, kan du forvente en pris på ca.
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/40000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >40.000 kr.</a
            >
            inkl. fedtsugning.
          </p>
          <p>
            I forhold til siliconenimplantaterne har du mulighed for at vælge forskellig
            kvalitet og forsikring, hvilket både kan give en højere og lavere pris. Dette
            har du ikke i samme udstrækning mulighed / behov for, hvis du vælger at
            omrokere dit eget fedtvæv.
          </p>
          <h3>Fedtsugning</h3>
          <p>
            En slankekur eller livsstilsændring hjælper ofte ikke mod de sidste
            genstridige fedtdepoter. Hvis du er genetisk disponeret til at have lidt flere
            kilo end du ønsker, eller tidligere har været meget overvægtig, så vil din
            krop helt automatisk kæmpe imod, at du taber de sidste kilo. Her vil en
            fedtsugning ofte være den eneste mulighed, for at få bug med det sidste.
          </p>
          <p>
            Hvis du leder efter et lån til fedtsugning, så vil prisen også variere meget,
            alt efter om du skal have fedtsuget armene, lårene, maven (abdominalplastik)
            eller hele kroppen. Du kan forvente at skulle betale
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/15000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >15.000 kr.</a
            >
            til
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/30000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >30.000 kr.</a
            >
            pr. område, som du ønsker fedtsuget. Dermed kan prisen hurtigt løbe op, og et
            lån til denne plastikkirurigske operation, kan derfor godt lande på
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/100000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >100.000 kr.</a
            >
            i alt.
          </p>
          <h3>Fedmeoperation</h3>
          <p>
            Hvis du ønsker en decideret fedmeoperation med gastric bypass, gastric
            balloon, gastric sleeve eller gastric plication, skal du forvente en pris på
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/20000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >20.000 kr.</a
            >
            til 40.000 kr.
          </p>
          <p>
            Hvis du er svært overvægtig og ønsker en fedmeoperation, kan du i nogle
            tilfælde få tilbudt en
            <a
              href="https://www.sundhed.dk/borger/patienthaandbogen/hormoner-og-stofskifte/sygdomme/overvaegt-og-kost/fedmekirurgi/"
              rel="noopener noreferrer"
              target="_blank"
              >gratis fedmeoperation</a
            >
            af det offentlige, da det kan kategoriseres som en sundhedsfremmende
            operation. Dermed kan du muligvis helt undgå at låne til denne operation, da
            den ikke vil falde i kategorien af plastikkirurgiske operationer.
          </p>
          <h3>Mave</h3>
          <p>
            Et af de mest almindelige problemer ved større vægttab, uanset om det er efter
            en fedmeoperation eller en fedtsugning, er løst og overskydende maveskind.
            Dette kan også forekomme på andre dele af kroppen, men er ofte mest udtalt
            omkring maven. Hvis du ønsker at låne til hele denne plastikkirurgiske
            operation, skal du ansøge om et lån på ca.
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/30000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >30.000 kr.</a
            >
          </p>
          <h3>Øjenlåg</h3>
          <p>
            Har du tunge øjenlåg, som du ønsker at få opereret for at se yngre og friskere
            ud, så hjælper vi dig også med et lån til denne operation.
          </p>
          <p>
            Hvis du kan klare operationen i lokalbedøvelse, har du brug for ca.
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/10000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >10.000 kr.</a
            >
            Hvis du synes at det er for nervepirrende at skulle gennemgå operationen uden
            fuld bedøvelse, så skal du derimod forvente
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/25000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >25.000 kr.</a
            >
          </p>
          <p>
            På samme måde som med fedmeoperationen, kan du helt undgå at låne penge til
            denne kosmetiske operation, hvis du kan overbevise din læge om, at dine
            øjenlåg hænger så meget, at de skærmer for dit syn.
          </p>
          <h3>Brystreduktion og brystløft</h3>
          <p>
            En brystreduktion er en typisk plastikkirurgisk operation for mænd. Dette vil
            som regl enten være hormonelt betinget eller en følgevirkning til et stort
            vægttab.
          </p>
          <p>
            Hos kvinder vil det sjældent være en hormonel ubalance, som skaber
            problemerne, men det kan derimod være en størrelsesforskel på brysterne, som
            du ønsker reduceret, eller hængebryster, som du ønsker at løfte.
          </p>
          <p>
            Prisen for denne operation er 20.000 kr. til
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/50000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >50.000 kr.</a
            >
            Hvis du har brug for et udvidet brystløft, hvor du både får fjernet hud
            omkring brystvorten, lodret ned fra brystvorten og under brystet, vil beløbet
            ligge i den høje ende, hvorfor det også ofte er denne operation, hvor det
            bliver aktuelt at låne til en brystoperation.
          </p>
          <h3>Næsekorrektion</h3>
          <p>
            Har du brug for et lån til plastikkirurgi i forbindelse med en skæv næse eller
            et knæk på næsen, skal du forvente at skulle låne 25.000 kr. eller
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/35000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >35.000 kr.</a
            >
            alt efter, som du kan klare operationen i lokalbedøvelse, eller ønsker fuld
            anæstesi. Hvis du har en en skillevæg som skal fikses, behøver du sjældent et
            lån til plastikkirurgi, da din læge kan henvise dig på det offentliges
            regning.
          </p>
          <h3>Karsprængninger</h3>
          <p>
            Karsprængninger (blodkarsprængninger) koster ofte
            <strong>1.000 kr.</strong> til
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/5000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >5.000 kr.</a
            >
            at få behandlet, alt efter hvor stort et område der skal behandles. Grundet
            beløbsstørrelsen skulle man ikke forvente, at der var mange, som ansøgte om
            lån til denne type plastikkirurgi / kosmetiske indgreb. Alligevel ser vi mange
            brugere, som ansøger om at låne til netop dette plastikkirurgiske indgreb, da
            karsprængninger både er relativt nemme, komplikationsfri og billige at fjerne,
            sammenlignet med andre kosmetiske indreb.
          </p>
          <p>
            Prisen afhænger i høj grad af, om du har behov for IPL, ND:yag eller
            mikrosklerosering, for at få bug med dine karsprængninger.
          </p>
          <h3>Ansigtsløft</h3>
          <p>
            Et ansigtsløft er en af de dyre plastikkirurgiske operationer, hvor lånet også
            ofte kommer på tale, med mindre at der er sparet op i lang tid forinden. Det
            er ikke unormalt at et ansigtsløft koster <strong>75.000 kr.</strong>, og der
            udover dette kan være tillægsydelser i forbindelse med læbeløft, fedtsugning
            af hals og opstramning af halsmusklen, alt efter hvad du har brug for. Vores
            øvre grænse på hvad vi kan hjælpe, med i forhold til lån til plastikkirurgi
            ligger dog langt over dette beløb. Uanset hvor stort et lån til
            plastikkirurgi, som du har behov for, behøver du ikke at tøve med at ansøge.
          </p>
        </div>

        <div class="text-container">
          <h2 id="skal-vi-hjaelpe-dig-med-at-finansiere-din-operation">
            Skal vi hjælpe dig med at finansiere din operation?
          </h2>
          <p>
            Hvis du er klar til at gå under kniven, men blot behøver at låne de sidste
            penge til din kosmetiske operation, så hjælper vi dig gerne. Det kan altid
            betale sig at
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne lån</a
            >
            - især inden for plastikkirurgi, hvor udgifterne kan være meget store, og der
            dermed kan være mange penge at spare, ved at vælge det
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >billigste lån</a
            >.
          </p>
          <p>
            Indsend din ansøgning nu, og se hvor du kan få det billigste lån til
            plastikkirurgi.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
