const state = {
  articleNumber: 0,
  articles: [
    {
      title: 'Pożyczki chwilówki online – kredyty chwilówki przez internet',
      description:
        'Nie każdy dysponuje oszczędnościami, które może przeznaczyć na dowolne cele inwestycyjne, m.in. na zakup nowego samochodu czy sprzętu RTV/AGD. ',
      src: '/static/img/saverium_pl/article-images/payday-loan-couple.webp',
      url: '/artykuly/pozyczki-chwilowki-online-redyty-chwilowki-przez-interwebp',
      tiny: '/static/img/saverium_pl/article-images/payday-loan-couple-tiny.jpg',
      date: '30.11.2020',
    },
    {
      title: 'Kredyt gotówkowy – kalkulator i oferty pożyczek gotówkowych',
      description:
        'Kredyt gotówkowy pod wieloma względami jest podobny do pożyczki, a niekiedy nawet do chwilówki online. Sprawdźmy, co odróżnia takie produkty i z jakimi kosztami dla klienta wiąże się zaciągnięcie kredytu gotówkowego?',
      src: '/static/img/saverium_pl/article-images/polish-money-hand.jpg',
      tiny: '/static/img/saverium_pl/article-images/polish-money-hand-tiny.jpg',
      url: '/artykuly/kredyt-gotowkowy-kalkulator-i-oferty-pozyczek-gotowkowych/',
      date: '7.12.2020',
    },
    {
      title: 'Pożyczka bez BIK-u – szybkie pożyczki online bez baz',
      description:
        'Firmy pożyczkowe w materiałach reklamowych często informują potencjalnych klientów, że są w stanie udzielić pożyczkę bez weryfikacji w BIK-u. Co to oznacza?',
      src: '/static/img/saverium_pl/article-images/bik-loan.jpg',
      tiny: '/static/img/saverium_pl/article-images/bik-loan-tiny.jpg',
      url: '/artykuly/pozyczka-bez-BIK-u-szybkie-pozyczki-online-bez-baz/',
      date: '25.11.2020',
    },
    {
      title: 'Pożyczki online dla zadłużonych – czy można ubiegać się o kredyt?',
      description:
        'Portfele Polaków mocno odczuły koronawirusowy kryzys. Lockdown z wiosny 2020 roku i kolejne ograniczenia sanitarne wprowadzone jesienią bieżącego roku spowodowały, że nasze zadłużenie wzrosło.',
      src: '/static/img/saverium_pl/article-images/thinking-man.jpg',
      tiny: '/static/img/saverium_pl/article-images/thinking-man-tiny.jpg',
      url: '/artykuly/pozyczki-online-dla-zadluzonych-czy-mozna-ubiegac-sie-o-kredyt/',
      date: '2.12.2020',
    },
    {
      title: 'Pożyczki na dowód przez Internet – chwilówki na dowód',
      description:
        'Podstawowym dokumentem każdego obywatela w Polsce jest dowód osobisty, który pozwala na szybkie sprawdzenie tożsamości danej osoby. Dowód okazujemy przy kontroli policyjnej na ulicy czy też przy załatwianiu wielu spraw w urzędach, np. urzędzie miasta czy pracy.',
      src: '/static/img/saverium_pl/article-images/wallet-id.jpg',
      tiny: '/static/img/saverium_pl/article-images/wallet-id-tiny.jpg',
      url: '/artykuly/pozyczki-na-dowod-przez-internet-chwilowki-na-dowod/',
      date: '25.11.2020',
    },
    {
      title: 'Pożyczki pozabankowe online – kredyty pozabankowe przez internet',
      description:
        'Na rynku finansowym można wyróżnić dwa rodzaje zobowiązań – pożyczki i kredyty. Drugie z wymienionych są produktami stricte bankowymi, a jeśli firma pozabankowa deklaruje, że ma je w ofercie, w istocie są to pożyczki pozabankowe.',
      src: '/static/img/saverium_pl/article-images/online-nonbank.jpg',
      tiny: '/static/img/saverium_pl/article-images/online-nonbank-tiny.jpg',
      url: '/artykuly/pozyczki-pozabankowe-online-kredyty-pozabankowe-prezez-internet/',
      date: '3.11.2020',
    },
    {
      title: 'Pożyczki online na raty – pożyczki chwilówki na raty',
      description:
        'Kiedy brakuje nam wolnych środków finansowych na zakup pralki, lodówki albo samochodu, wielu konsumentów decyduje się na rozłożenie płatności na raty. Takie zakupy niezmiennie cieszą się dużą popularnością w Polsce.',
      src: '/static/img/saverium_pl/article-images/loan-installment.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-installment-tiny.jpg',
      url: '/artykuly/pozyczki-online-na-raty-pozyczki-chwilowki-na-raty/',
      date: '19.11.2020',
    },
    {
      title: 'Pożyczki bez zaświadczeń online – chwilówki bez formalności',
      description:
        'Przy zaciąganiu kredytów bankowych klient musi dostarczyć do wniosku wiele różnych dokumentów, m.in. potwierdzających wysokość dochodów czy zatrudnienie w danej firmie. Bardzo rzadko takie zobowiązanie udzielane jest bez zaświadczeń o dochodach.',
      src: '/static/img/saverium_pl/article-images/loans-without-certificate.jpg',
      tiny: '/static/img/saverium_pl/article-images/loans-without-certificate-tiny.jpg',
      url: '/artykuly/pozyczki-bez-zaswiadczen-online-chwilowki-bez-formalnosci/',
      date: '25.04.2022',
    },
    {
      title: 'Pierwsza pożyczka za darmo – chwilówki za 0 zł',
      description:
        'Czy można pożyczać pieniądze całkowicie za darmo, bez konieczności płacenia jakichkolwiek prowizji czy opłat z tego tytułu, a zwłaszcza odsetek? Okazuje się, że pożyczki za darmo są popularnym produktem promocyjnym w firmach pozabankowych – takie darmowe chwilówki przez internet udziela wielu pożyczkodawców. Kto może na nie liczyć?',
      src: '/static/img/saverium_pl/article-images/free-loan.jpg',
      tiny: '/static/img/saverium_pl/article-images/free-loan-tiny.jpg',
      url: '/artykuly/pierwsza-pozyczka-za-darmo-chwilowki-za-0-zl/',
      date: '23.11.2020',
    },
    {
      title: 'Pożyczki społecznościowe – czym jest kredyt społecznościowy?',
      description:
        'Kredyt jest produktem stricte bankowym, udzielanym przede wszystkim na podstawie przepisów Prawa bankowego. Nieco innym rozwiązaniem są pożyczki, oferowane przez różnego rodzaju instytucje finansowe, a nawet osoby prywatne. ',
      src: '/static/img/saverium_pl/article-images/social-loan.jpg',
      tiny: '/static/img/saverium_pl/article-images/social-loan-tiny.jpg',
      url: '/artykuly/pozyczki-spolecznosciowe-czym-jest-kredyt-spolecznosciowy/',
      date: '1.12.2020',
    },
    {
      title: 'Pożyczki do domu – nowość, pożyczki udzielane w domu klienta',
      description:
        'Przyzwyczailiśmy się do tego, że bez wychodzenia z domu można już zaciągnąć pożyczkę online na raty czy z jednorazową spłatą na koniec okresu kredytowania.',
      src: '/static/img/saverium_pl/article-images/loans-to-home.jpg',
      tiny: '/static/img/saverium_pl/article-images/loans-to-home-tiny.jpg',
      url: '/artykuly/pozyczki-do-domu-nowosc-pozyczki-udzielane-w-domu-klienta/',
      date: '15.11.2020',
    },
    {
      title: 'Pożyczki dla bezrobotnych online – kredyt dla niepracujących',
      description:
        'Europejski urząd statystyczny, czyli Eurostat informuje, że w Polsce w sierpniu 2020 roku stopa bezrobocia wyniosła 3,1 proc. Tymczasem Główny Urząd Statystyczny podaje, że osiągnęła ona 6,1 proc. i na tym samym poziomie utrzymywała się we wrześniu',
      src: '/static/img/saverium_pl/article-images/loan-to-unemployed.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-to-unemployed-tiny.jpg',
      url: '/artykuly/pozyczki-dla-bezrobotnych-online-kredyt-dla-niepracujacych/',
      date: '4.12.2020',
    },
    {
      title: 'Pożyczka na 61 dni – chwilówki ze spłatą 61 dni',
      description:
        'Pożyczki na 61 dni ☛ chwilówki ze spłatą w ciągu 61 dni ☛ Sprawdźwarunki uzyskania pożyczki online za pośrednictwem Saverium',
      src: '/static/img/saverium_pl/article-images/loan-for-two-months.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-for-two-months-tiny.jpg',
      url: '/artykuly/pozyczka-na-61-dni/',
      date: '14.12.2020',
    },

    {
      title: 'Pożyczki prywatne – szybka pożyczka prywatna',
      description:
        'Pożyczka według Kodeksu cywilnego może mieć formę finansową, ale nie musi. Opiera się na umowie, jaką zawierają ze sobą dwie strony. Wcale nie jest powiedziane, że pożyczkodawcą musi być firma trudniąca się zawodowo udzielaniem kredytów gotówkowych czy pożyczek online na raty.',
      src: '/static/img/saverium_pl/article-images/private-loans.jpg',
      tiny: '/static/img/saverium_pl/article-images/private-loans-tiny.jpg',
      url: '/artykuly/pozyczki-prywatne-szybka-pozyczka-prywatna/',
      date: '19.12.2020',
    },
    {
      title: 'Pożyczki przez internet na konto bankowe',
      description:
        'Pożyczanie pieniędzy aktualnie jest znacznie prostsze niż jeszcze 20–30 lat temu, z uwagi na powszechny dostęp do internetu w Polsce. Nie trzeba już chodzić do firm pożyczkowych czy banków po to, by uzyskać środki. ',
      src: '/static/img/saverium_pl/article-images/loan-to-account.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-to-account-tiny.jpg',
      url: '/artykuly/pozyczki-przez-internet-na-konto-bankowe/',
      date: '17.12.2020',
    },
    {
      title:
        'Zdolność kredytowa – czym jest? Jak sprawdzić i poprawić zdolność kredytową?',
      description:
        'Podstawowym warunkiem, jaki stawiają swoim klientom banki i firmy pożyczkowe przy udzielaniu zobowiązań jest posiadanie odpowiednio wysokiej zdolności kredytowej. Co też należy rozumieć przez pojęcia zdolność kredytowa lub zdolność pożyczkowa?',
      src: '/static/img/saverium_pl/article-images/credit-worthiness.jpg',
      tiny: '/static/img/saverium_pl/article-images/credit-worthiness-tiny.jpg',
      url: '/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/',
      date: '12.12.2020',
    },
    {
      title: 'Pożyczka 10 000 zł – pożyczka na dowolny cel',
      description:
        'Potrzeby finansowe bywają różne. Kiedy zabraknie Ci np. kwoty 10 000 zł na remont kuchni czy dokończenie budowy domu, nie musisz załamywać rąk. Skorzystaj z oferty na kredyt gotówkowy w banku, a jeśli zależy ci wyjątkowo na czasie, możesz też wnioskować o taką kwotę w ramach pożyczki pozabankowej do 10 000 zł. Gdzie uzyskać taką kwotę? Sprawdź specyfikę pożyczek na 10 000 zł.',
      src: '/static/img/saverium_pl/article-images/10000-loan.jpg',
      tiny: '/static/img/saverium_pl/article-images/10000-loan-tiny.jpg',
      url: '/artykuly/pozyczka-10000-zl-pozyczka-na-dowolny-cel/',
      date: '18.12.2020',
    },
    {
      title: 'Kredyt a pożyczka – sprawdź, jakie są różnice',
      description:
        'Pojęcia kredyt i pożyczka są do siebie podobne i wielu z nas wykorzystuje je zamiennie. Tymczasem istnieje wiele cech różnicujących takie produkty finansowe. Dowiedz się, czym właściwie cechuje się pożyczka, a kiedy zobowiązanie finansowe może być uznane za kredyt.',
      src: '/static/img/saverium_pl/article-images/what-is-credit.jpg',
      tiny: '/static/img/saverium_pl/article-images/what-is-credit-tiny.jpg',
      url: '/artykuly/kredyt-a-pozyczka-sprawdz-jakie-sa-roznice/',
      date: '18.12.2020',
    },
    {
      title: 'Pożyczka na 90 dni – chwilówka na 3 miesiące',
      description:
        'Trzymiesięczny okres kredytowania może okazać się dla Ciebie optymalnym czasem, w którym będziesz mógł dowolnie wykorzystać środki pozyskane z banku czy firmy pożyczkowej. Nie jest tak, że oferty na chwilówki online, czyli głównie propozycje pozabankowe, obejmują wyłącznie 30–60 dni na zwrot środków.',
      src: '/static/img/saverium_pl/article-images/loan-for-90-days.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-for-90-days-tiny.jpg',
      url: '/artykuly/pozyczka-na-90-dni-chwilowka-na-3-miesiace/',
      date: '18.12.2020',
    },
    {
      title: 'Pożyczka na święta - szybka pożyczka świąteczna',
      description:
        'Święta Bożego Narodzenia są okazją do spotkań rodzinnych i z przyjaciółmi. Wspólnie zasiadamy przy stole wigilijnym, a później biesiadujemy tradycyjnie w czasie pierwszego i drugiego dnia świąt. To czas radości, związany z dawaniem innym prezentów. Na to wszystko potrzeba jednak pieniędzy.',
      src: '/static/img/saverium_pl/article-images/loan-for-christmas.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-for-christmas-tiny.jpg',
      url: '/artykuly/pozyczka-na-swieta-szybka-pozyczka-swiateczna/',
      date: '18.12.2020',
    },
    {
      title: 'Pożyczka od ręki – chwilówki od ręki przez internet',
      description:
        'W życiu zdarzają się różne sytuacje, w których trzeba pożyczyć pieniądze w możliwie jak najkrótszym czasie. Jeśli nie możesz wówczas sięgnąć po swoje oszczędności czy uzyskać wsparcia od rodziny i znajomych, pozostają ci instytucje kredytowe i pożyczkowe.',
      src: '/static/img/saverium_pl/article-images/instant-internet-loans.jpg',
      tiny: '/static/img/saverium_pl/article-images/instant-internet-loans-tiny.jpg',
      url: '/artykuly/pozyczka-od-reki-chwilowki-od-reki-przez-internet/',
      date: '18.12.2020',
    },
    {
      title: 'Pożyczka online w 15 minut – ekspresowe pożyczki',
      description:
        'Realizacja procesu prowadzącego do zaciągnięcia kredytu gotówkowego w banku może trwać nawet kilka dni, a w skrajnych sytuacjach nawet tygodni. Inaczej jest w przypadku firm pozabankowych, które nie stosują tak restrykcyjnych zasad kontrolowania wiarygodności swoich klientów.',
      src: '/static/img/saverium_pl/article-images/loans-in-15-minutes.jpg',
      tiny: '/static/img/saverium_pl/article-images/loans-in-15-minutes-tiny.jpg',
      url: '/artykuly/pozyczka-online-w-15-minut-ekspresowe-pozyczki/',
      date: '21.12.2020',
    },
    {
      title: 'Pożyczki dla firm – pożyczki pozabankowe dla przedsiębiorców',
      description:
        'Brak spływu na czas należności może spowodować, że firma będzie zmuszona do poszukiwania zewnętrznych źródeł finansowania. Jesteś przedsiębiorcą i spotkałeś się już z perspektywą utraty płynności? Jeśli tak, to dowiedz się, gdzie szukać pomocy?',
      src: '/static/img/saverium_pl/article-images/loans-for-companies.jpg',
      tiny: '/static/img/saverium_pl/article-images/loans-for-companies-tiny.jpg',
      url: '/artykuly/pozyczki-dla-firm-pozyczki-pozabankowe-dla-przedsiebiorcow/',
      date: '21.12.2020',
    },
    {
      title:
        'Pożyczki długoterminowe online – pożyczaj na 24, 36, 48, 60, a nawet 120 miesięcy',
      description:
        'Pożyczki chwilówki online, czyli po prostu oferty pozabankowe, całkiem słusznie kojarzą się z krótkim okresem kredytowania. Stąd też bierze się ich nazwa – są udzielane „na chwilę”.',
      src: '/static/img/saverium_pl/article-images/online-longterm-loans.jpg',
      tiny: '/static/img/saverium_pl/article-images/online-longterm-loans-tiny.jpg',
      url: '/artykuly/pozyczki-dlugoterminowe-online-pozyczaj-na-24-36-48-60-a-nawet-120-miesiecy/',
      date: '21.12.2020',
    },
    {
      title: 'Chwilówki od 18 lat – pożyczki online dla młodych',
      description:
        'W polskim prawie cywilnym uwzględniono przepisy wskazujące, kiedy obywatel czy obywatelka stają się osobami pełnoletnimi. Granicą wiekową, która została wytyczona jest 18 lat. Od momentu ukończenia takiego wieku dana osoba staje się pełnoletnia i ma pełną zdolność do czynności prawnych, o ile z innych względów nie została ona ograniczona.',
      src: '/static/img/saverium_pl/article-images/loans-for-18-year-olds.jpg',
      tiny: '/static/img/saverium_pl/article-images/loans-for-18-year-olds-tiny.jpg',
      url: '/artykuly/chwilowki-od-18-lat-pozyczki-online-dla-mlodych/',
      date: '11.1.2021',
    },
    {
      title: 'Nowe pożyczki pozabankowe – najnowsze chwilówki online',
      description:
        'Na rynku pożyczek chwilówek online i pożyczek na dowód przez internet co kilka tygodni czy miesięcy pojawiają się nowe firmy skłonne do udzielenia swoim klientom takich zobowiązań finansowych.',
      src: '/static/img/saverium_pl/article-images/new-nonbank-loans.jpg',
      tiny: '/static/img/saverium_pl/article-images/new-nonbank-loans-tiny.jpg',
      url: '/artykuly/nowe-pozyczki-pozabankowe-najnowsze-chwilowki-online/',
      date: '11.1.2021',
    },
    {
      title: 'Pożyczka bez umowy o pracę – jakie są warunki jej otrzymania?',
      description:
        'Pilna potrzeba finansowa powoduje, że szukasz oferty, która pozwoli Ci zaciągnąć możliwie jak najszybciej pożyczki bez zaświadczeń. Jeśli nie masz umowy o pracę, nie oznacza to jednocześnie, że nie otrzymasz chwilówki w firmie pozabankowej. Na jakich warunkach możesz ją uzyskać?',
      src: '/static/img/saverium_pl/article-images/loan-without-employment-contract.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-without-employment-contract-tiny.jpg',
      url: '/artykuly/pozyczka-bez-umowy-o-prace-jakie-sa-warunki-jej-otrzymania/',
      date: '11.1.2021',
    },
    {
      title: 'Pożyczki krótkoterminowe - szybkie pożyczki na 61, 90 i 120 dni',
      description:
        'Gdy potrzebujesz szybko pieniędzy, z przeznaczeniem na dowolne cele, sięgnij po pożyczki krótkoterminowe, które uzupełnią z miejsca Twój domowy budżet. Możesz je otrzymać nawet w ciągu kilkunastu minut od chwili złożenia wniosku.',
      src: '/static/img/saverium_pl/article-images/shortterm-loans.jpg',
      tiny: '/static/img/saverium_pl/article-images/shortterm-loans-tiny.jpg',
      url: '/artykuly/pozyczki-krotkoterminowe-szybkie-pozyczki-na-61-90-i-120-dni/',
      date: '12.1.2021',
    },
    {
      title: 'Gdzie najlepiej wziąć pożyczkę online?',
      description:
        'Klient potrzebujący zaciągnąć pożyczkę od ręki, nawet w ciągu 15 minut od chwili złożenia wniosku, powinien zainteresować się ofertą firm parabankowych działających w kanałach internetowych i mobilnych. ',
      src: '/static/img/saverium_pl/article-images/best-place-for-loan.jpg',
      tiny: '/static/img/saverium_pl/article-images/best-place-for-loan-tiny.jpg',
      url: '/artykuly/gdzie-najlepiej-wziac-pozyczke-online/',
      date: '22.1.2021',
    },
    {
      title: 'Pożyczka na 12 miesięcy – pożyczki przez internet na rok',
      description:
        'Pożyczki chwilówki online najczęściej kojarzone są z krótkoterminowymi zobowiązaniami, które doraźnie pomogą uzupełnić twój budżet domowy. Możesz je wydać na dowolne cele.',
      src: '/static/img/saverium_pl/article-images/loan-for-year.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-for-year-tiny.jpg',
      url: '/artykuly/pozyczka-na-12-miesiecy-pozyczki-przez-internet-na-rok/',
      date: '22.1.2021',
    },
    {
      title: 'Pożyczka 1200 zł – atrakcyjne oferty pożyczek na 1200 zł',
      description:
        'Sytuacji, w których nagle możesz potrzebować dodatkowych środków finansowych, może być wiele. Nagła awaria samochodu i związana z nią konieczność kosztownej naprawy w warsztacie albo zepsuta pralka i wymiana jej na nowe urządzenie to tylko jedne z wielu powodów, które uzasadniają Twoją potrzebę zaciągnięcia szybkiej pożyczki do 1200 zł.',
      src: '/static/img/saverium_pl/article-images/loan-for-1200.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-for-1200-tiny.jpg',
      url: '/artykuly/pozyczka-1200-zl-atrakcyjne-oferty-pozyczek-na-1200-zl/',
      date: '5.2.2021',
    },
    {
      title: 'Chwilówki od 20 lat – pożyczki online od 20 lat',
      description:
        'Zasadniczo kredyty i pożyczki dla młodych ludzi dostępne są od 18. roku życia. Granica 18 lat jest w Polsce kluczowa, ponieważ od momentu ukończenia tego wieku uzyskujesz na mocy przepisów Kodeksu cywilnego pełną zdolność do czynności prawnych.',
      src: '/static/img/saverium_pl/article-images/loan-for-20-year-olds.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-for-20-year-olds-tiny.jpg',
      url: '/artykuly/chwilowki-od-20-lat-pozyczki-online-od-20-lat/',
      date: '5.2.2021',
    },
    {
      title: 'Kredyt lub pożyczka na wesele – sposób na sfinansowanie przyjęcia',
      description:
        'Serwis Weeding.pl w 2019 roku przeprowadził badania mające na celu oszacowanie średnich kosztów sfinansowania przyjęcia weselnego. Okazało się, że na zorganizowanie ślubu i wesela zdecydowana większość Polaków wydaje od 41 do 60 tys.',
      src: '/static/img/saverium_pl/article-images/loan-for-wedding.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-for-wedding-tiny.jpg',
      url: '/artykuly/kredyt-lub-pozyczka-na-wesele-sposob-na-sfinansowanie-przyjecia/',
      date: '5.2.2021',
    },
    {
      title: 'Chwilówki dla emerytów – pożyczki online dla osób na emeryturze',
      description:
        'Świadczenia emerytalne są stałym źródłem pozyskiwania dochodów, choć zwykle nie mają one zbyt pokaźnej wysokości. Emeryci są grupą, która ma ograniczone możliwości zaciągania kredytów i pożyczek w bankach, z uwagi na wymogi odnośnie do wieku klienta.',
      src: '/static/img/saverium_pl/article-images/loan-for-pensioners.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-for-pensioners-tiny.jpg',
      url: '/artykuly/chwilowki-dla-emerytow-pozyczki-online-dla-osob-na-emeryturze/',
      date: '5.2.2021',
    },
    {
      title: 'Pożyczka na oświadczenie – na czym polega?',
      description:
        'Procedury kredytowe w instytucjach bankowych powodują, że nawet przez kilka czy kilkanaście dni niejednokrotnie możesz czekać na wydanie decyzji na złożony wniosek. Do tego wraz z tym wnioskiem musisz przekazać do banku zaświadczenie o wysokości uzyskiwanych dochodów.',
      src: '/static/img/saverium_pl/article-images/loan-on-declaration.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-on-declaration-tiny.jpg',
      url: '/artykuly/pozyczka-na-oswiadczenie-na-czym-polega/',
      date: '5.2.2021',
    },
    {
      title: 'Odpowiedzialne pożyczanie – jak pożyczać odpowiedzialnie i bezpiecznie?',
      description:
        'Spontaniczność i kierowanie się nagłym impulsem być może urozmaica nasze życie i dodaje mu kolorytu, ale jednocześnie nie jest dobrym kierunkiem w przypadku sfery finansowej.',
      src: '/static/img/saverium_pl/article-images/responsible-borrowing.jpg',
      tiny: '/static/img/saverium_pl/article-images/responsible-borrowing-tiny.jpg',
      url: '/artykuly/odpowiedzialne-pozyczanie-jak-pozyczac-odpowiedzialnie-i-bezpiecznie/',
      date: '23.2.2021',
    },
    {
      title: 'Spirala zadłużenia – co to jest? Jak nie wpaść i jak wyjść z długów?',
      description:
        'Potrzeby każdego z nas są różne i w odmienny sposób je realizujemy. Mieszkanie możemy kupić na kredyt hipoteczny z banku, a remont przeprowadzić ze środków uzyskanych z kredytu gotówkowego.',
      src: '/static/img/saverium_pl/article-images/debt-spiral.jpg',
      tiny: '/static/img/saverium_pl/article-images/debt-spiral-tiny.jpg',
      url: '/artykuly/spirala-zadluzenia-co-to-jest-jak-nie-wpasc-i-jak-wyjsc-z-dlugow/',
      date: '23.2.2021',
    },
    {
      title: 'Bezpieczne pożyczanie pieniędzy przez Internet – na co zwracać uwagę?',
      description:
        'W sieci prężnie rozwija się sfera finansowa, co pozwala konsumentom na korzystanie z pożyczek i kredytów przez internet.',
      src: '/static/img/saverium_pl/article-images/safe-online-borrowing.jpg',
      tiny: '/static/img/saverium_pl/article-images/safe-online-borrowing-tiny.jpg',
      url: '/artykuly/bezpieczne-pozyczanie-pieniedzy-przez-internet-na-co-zwracac-uwage/',
      date: '23.2.2021',
    },
    {
      title: 'Co to jest RRSO, jak obliczyć i jak wpływa na wysokość kredytu?',
      description:
        'W ofertach banków i firm pożyczkowych obowiązkowo powinny znaleźć się wszystkie informacje dotyczące kosztów udzielanego zobowiązania, wraz z reprezentatywnym przykładem.',
      src: '/static/img/saverium_pl/article-images/what-is-rrso.jpg',
      tiny: '/static/img/saverium_pl/article-images/what-is-rrso-tiny.jpg',
      url: '/artykuly/co-to-jest-RRSO-jak-obliczyc-i-jak-wplywa-na-wysokosc-kredytu/',
      date: '23.2.2021',
    },
    {
      title:
        'BIK, BIG, KRD, ERIF, RDN - co znaczą te skróty? Jak sprawdzić rejestry dłużników?',
      description:
        'Wbrew temu, że z czasem sytuacja materialna Polaków polepsza się, coraz więcej naszych rodaków ma problemy ze spłatą swoich zobowiązań finansowych. Problemy z regulowaniem długów to pierwszy krok do trafiania do bazy dłużników.',
      src: '/static/img/saverium_pl/article-images/what-are-these.jpeg',
      tiny: '/static/img/saverium_pl/article-images/what-are-these-tiny.jpeg',
      url: '/artykuly/bik-big-krd-erif-rdn-co-znacza-te-skroty-jak-sprawdzic-rejestry-dluznikow/',
      date: '19.3.2021',
    },
    {
      title: 'Kalkulator kredytowy online - co to jest?',
      description:
        'Kalkulator kredytowy online to bardzo proste i intuicyjne narzędzie, które dostępne jest dla każdego, kto posiada połączenie z internetem i zastanawia się jak obliczyć ratę kredytu, który ma w planach.',
      src: '/static/img/saverium_pl/article-images/loan-calculator.jpeg',
      tiny: '/static/img/saverium_pl/article-images/loan-calculator-tiny.jpeg',
      url: '/artykuly/kalkulator-kredytowy-online-co-to-jest/',
      date: '22.3.2021',
    },
    {
      title: 'Kredyt 20 tys. złotych - pożyczki pozabankowe do 20 000 zł przez Internet',
      description:
        'Pożyczka 20 tyś to bardzo popularne zobowiązanie finansowe, którego udzielają zarówno banki, jak i instytucje pozabankowe. Rozważając tego typu pożyczkę przez Internet zapewne zastanawiasz się ile będzie wynosiła rata oraz jakie warunki trzeba spełnić, aby ją otrzymać.',
      src: '/static/img/saverium_pl/article-images/loan-for-20000.jpeg',
      tiny: '/static/img/saverium_pl/article-images/loan-for-20000-tiny.jpeg',
      url: '/artykuly/kredyt-20-tys-zlotych-pozyczki-pozabankowe-do-20-000-zl-przez-internet/',
      date: '7.4.2021',
    },
    {
      title: 'Kredyt 100 tys. złotych - najlepsze oferty pożyczek gotówkowych',
      description:
        'Kredyt na 100 tysięcy złotych to spory zastrzyk gotówki, który możemy otrzymać od banku. Aby go otrzymać, należy spełniać określone warunki związane z historią kredytową.',
      src: '/static/img/saverium_pl/article-images/loan-for-100000.jpeg',
      tiny: '/static/img/saverium_pl/article-images/loan-for-100000-tiny.jpeg',
      url: '/artykuly/kredyt-100-tys-zlotych-najlepsze-oferty-pozyczek-gotowkowych/',
      date: '7.4.2021',
    },
    {
      title: 'Kredyt 200 tys. złotych - najlepsze oferty pożyczek gotówkowych',
      description:
        'Zobowiązania kredytowe, które wynoszą 200 tysięcy złotych zazwyczaj kojarzone są z kredytami hipotecznymi, zaciąganymi na zakup nieruchomości. Tymczasem niejednokrotnie w życiu czekają na nas duże inwestycje, które niekoniecznie związane są z szukaniem lokum.',
      src: '/static/img/saverium_pl/article-images/loan-for-200000.jpeg',
      tiny: '/static/img/saverium_pl/article-images/loan-for-200000-tiny.jpeg',
      url: '/artykuly/kredyt-200-tys-zlotych-najlepsze-oferty-pozyczek-gotowkowych/',
      date: '7.4.2021',
    },
    {
      title: 'Kredyt gotówkowy dla jednoosobowej działalności gospodarczej',
      description:
        'Wiele wskazuje na to, że mali przedsiębiorcy wolą zaciągać prywatne zobowiązania niż kredyty firmowe. Czy naprawdę kredyt dla jednoosobowej działalności gospodarczej jest tak trudny do dostania?',
      src: '/static/img/saverium_pl/article-images/loan-for-entrepreneurs.jpeg',
      tiny: '/static/img/saverium_pl/article-images/loan-for-entrepreneurs-tiny.jpeg',
      url: '/artykuly/kredyt-gotowkowy-dla-jednoosobowej-dzialalnosci-gospodarczej/',
      date: '7.4.2021',
    },
    {
      title: 'Kredyt konsumencki vs kredyt konsumpcyjny - jakie są różnice?',
      description:
        'Określenia kredyt konsumencki i kredyt konsumpcyjny bardzo często stosowane są zamiennie. Tymczasem te dwa produkty finansowe znacząco się od siebie różnią.',
      src: '/static/img/saverium_pl/article-images/consumer-credit.jpeg',
      tiny: '/static/img/saverium_pl/article-images/consumer-credit-tiny.jpeg',
      url: '/artykuly/kredyt-konsumencki-vs-kredyt-konsumpcyjny-jakie-sa-roznice/',
      date: '7.4.2021',
    },
    {
      title: 'Kredyt na wakacje 2021 - sfinansuj wakacje na raty',
      description:
        'Niezależnie od tego czy preferujesz kierunek polski czy zagraniczny, morze, mazury, góry lub egzotyczne plaże z krystalicznie czystą wodą – wakacje są kosztownym, jednorazowym przedsięwzięciem.',
      src: '/static/img/saverium_pl/article-images/vacation-loan.jpeg',
      tiny: '/static/img/saverium_pl/article-images/vacation-loan-tiny.jpeg',
      url: '/artykuly/kredyt-na-wakacje-2021-sfinansuj-wakacje-na-raty/',
      date: '7.4.2021',
    },
    {
      title: 'Parabanki - czym są, jak działają? Czym różnią się od banków?',
      description:
        'Instytucja parabanku w ostatnich latach stała się bardzo popularna w naszym kraju. Oferta tego typu firm często bywa postrzegana jako alternatywa dla banków, jednak warto pamiętać o tym, że te dwie instytucje bardzo mocno się od siebie różnią.',
      src: '/static/img/saverium_pl/article-images/parabanks.jpeg',
      tiny: '/static/img/saverium_pl/article-images/parabanks-tiny.jpeg',
      url: '/artykuly/parabanki-czym-sa-jak-dzialaja-czym-roznia-sie-od-bankow/',
      date: '7.4.2021',
    },
    {
      title: 'Kredyt 15 tys online - jakie są warunki i raty kredytu 15 000 zł?',
      description:
        'Kredyt gotówkowy 15 tysięcy złotych to zobowiązanie finansowe, na które może obecnie zdecydować się bardzo wiele osób. Niezależnie od tego, czy chcemy wyjechać na luksusowe wakacje, czy zrobić remont, takie pieniądze w wielu przypadkach rozwiązują wiele problemów.',
      src: '/static/img/saverium_pl/article-images/15k-loan.jpeg',
      tiny: '/static/img/saverium_pl/article-images/15k-loan-tiny.jpeg',
      url: '/artykuly/kredyt-15-tys-online-jakie-sa-warunki-i-raty-kredytu-15-000-zl/',
      date: '21.5.2021',
    },
    {
      title:
        'Kredyt 25 tys online - warunki, rata, jak dostać kredyt 25 000 zł przez internet?',
      description:
        'Kredyt konsumencki na 25 tysięcy złotych to bardzo popularne zobowiązanie finansowe, które chętnie wybierane jest przez wiele osób.',
      src: '/static/img/saverium_pl/article-images/25k-loan.jpeg',
      tiny: '/static/img/saverium_pl/article-images/25k-loan-tiny.jpeg',
      url: '/artykuly/kredyt-25-tys-online-warunki-rata-jak-dostac-kredyt-25-000-zl-przez-internet/',
      date: '21.5.2021',
    },
    {
      title: 'Kredyt 30 tys online - jak dostać kredyt 30 000 zł przez internet?',
      description:
        'Kredyt gotówkowy do 30 tysięcy to zobowiązanie finansowe, które może zaspokoić wiele różnorodnych potrzeb – od remontu aż po zmianę samochodu na nowszy model.',
      src: '/static/img/saverium_pl/article-images/30k-loan.jpeg',
      tiny: '/static/img/saverium_pl/article-images/30k-loan-tiny.jpeg',
      url: '/artykuly/kredyt-30-tys-online-jak-dostac-kredyt-30-000-zl-przez-internet/',
      date: '21.5.2021',
    },
    {
      title: 'Kredyt 35 tyś online - najlepsze oferty kredytu 35 000 zł przez internet',
      description:
        'Kredyt na 35 tysięcy złotych to spory zastrzyk gotówki, która może być potrzebna w wielu różnych przypadkach. Taka kwota wystarczy na większy remont, czy zmianę samochodu.',
      src: '/static/img/saverium_pl/article-images/35k-loan.jpeg',
      tiny: '/static/img/saverium_pl/article-images/35k-loan-tiny.jpeg',
      url: '/artykuly/kredyt-35-tys-online-najlepsze-oferty-kredytu-35-000-zl-przez-internet/',
      date: '21.5.2021',
    },
    {
      title: 'Kredyt 40 tys online - jakie warunki, jak dostać kredyt 40 000 zł?',
      description:
        'Zaciągnięcie kredytu gotówkowego na 40 tysięcy złotych to dla wielu klientów przepustka do spełnienia wielu marzeń. Za taką kwotę można kupić nowy samochód, zrobić większy remont, czy wykończyć swoje własne cztery kąty.',
      src: '/static/img/saverium_pl/article-images/40k-loan.jpeg',
      tiny: '/static/img/saverium_pl/article-images/40k-loan-tiny.jpeg',
      url: '/artykuly/kredyt-40-tys-online-jakie-warunki-jak-dostac-kredyt-40-000-zl/',
      date: '21.5.2021',
    },
    {
      title: 'Kredyt 50 tys online - jakie warunki, rata? Jak dostać kredyt 50 000 zł?',
      description:
        'Niezależnie od tego, czy potrzebujesz wyremontować mieszkanie, czy stoi przed Tobą wyzwanie zupełnie innego typu – czasami potrzeba pożyczenia większej ilości gotówki jest konieczna.',
      src: '/static/img/saverium_pl/article-images/50k-loan.jpeg',
      tiny: '/static/img/saverium_pl/article-images/50k-loan-tiny.jpeg',
      url: '/artykuly/kredyt-50-tys-online-jakie-warunki-rata/',
      date: '21.5.2021',
    },
    {
      title: 'Kredyt 60 tys online - jakie warunki, jak dostać kredyt 60 000 zł?',
      description:
        ' W momencie, kiedy potrzebujemy pieniędzy na dowolny cel, pożyczka lub kredyt na 60 tys złotych może być najlepszym rozwiązaniem. Wiele osób jednak zastanawia się, jakie warunki trzeba spełnić, aby otrzymać tego typu zobowiązanie finansowe.',
      src: '/static/img/saverium_pl/article-images/60k-loan.jpeg',
      tiny: '/static/img/saverium_pl/article-images/60k-loan-tiny.jpeg',
      url: '/artykuly/kredyt-60-tys-online-jakie-warunki-jak-dostac-kredyt-60-000-zl/',
      date: '21.5.2021',
    },
    {
      title: 'Kredyt 65 tyś online - skorzystaj z kalkulatora i weź kredyt na 65 000 zł',
      description:
        'Jeżeli zastanawiasz się nad wzięciem kredytu na 65 000 złotych masz dzisiaj możliwość skorzystania z bardzo wielu różnorodnych ofert bankowych.',
      src: '/static/img/saverium_pl/article-images/65k-loan.jpeg',
      tiny: '/static/img/saverium_pl/article-images/65k-loan-tiny.jpeg',
      url: '/artykuly/kredyt-65-tys-online-skorzystaj-z-kalkulatora-i-wez-kredyt-na-65-000-zl/',
      date: '21.5.2021',
    },
    {
      title:
        'Kredyt 70 tyś online - warunki, raty, zarobki, jak dostać kredyt 70 000 zł?',
      description:
        'Czy kredyt na 70 tysięcy jest ofertą, którą można uzyskać tylko w banku? To pytanie spędza sen z powiek wielu potencjalnym kredytobiorcom. Taka kwota nie należy na najczęściej wybieranych, jednak w wielu sytuacjach życiowych może okazać się niezwykle pomocna.',
      src: '/static/img/saverium_pl/article-images/70k-loan.jpeg',
      tiny: '/static/img/saverium_pl/article-images/70k-loan-tiny.jpeg',
      url: '/artykuly/kredyt-70-tys-online-warunki-raty-zarobki-jak-dostac-kredyt-70-000-zl/',
      date: '21.5.2021',
    },
    {
      title: 'Kredyt 80 tys online - warunki, zarobki, jak dostać kredyt 80 000 zł?',
      description:
        'Nie da się ukryć, że kwota w wysokości 80 000 złotych może znacznie podreperować domowy budżet. Nic więc dziwnego, że coraz więcej osób decyduje się na zaciągnięcie pożyczki lub kredytu w tej wysokości.',
      src: '/static/img/saverium_pl/article-images/80k-loan.jpeg',
      tiny: '/static/img/saverium_pl/article-images/80k-loan-tiny.jpeg',
      url: '/artykuly/kredyt-80-tys-online-warunki-zarobki-jak-dostac-kredyt-80-000-zl/',
      date: '21.5.2021',
    },
    {
      title: 'Kredyt 90 tyś online - jakie warunki, jak dostac kredyt 90 000 zł?',
      description:
        'Kredyt konsumencki na 90 tysięcy złotych to spory zastrzyk gotówki, którą można przeznaczyć na całkowicie dowolny cel.',
      src: '/static/img/saverium_pl/article-images/90k-loan.jpeg',
      tiny: '/static/img/saverium_pl/article-images/90k-loan-tiny.jpeg',
      url: '/artykuly/kredyt-90-tys-online-jakie-warunki-jak-dostac-kredyt-90-000-zl/',
      date: '21.5.2021',
    },
    {
      title: 'Pożyczka 20 000 zł – atrakcyjne oferty pożyczek pozabankowych do 20 000 zł',
      description:
        'Potrzebujesz pożyczyć większą kwotę pieniędzy, ale nie masz z różnych powodów szans na zaciągnięcie kredytu gotówkowego w banku?',
      url: '/artykuly/pozyczki-chwilowki-online-redyty-chwilowki-przez-internet/',
      date: '7.9.2021',
    },
    {
      title: 'Pożyczka dla każdego – szybkie chwilówki online w 24h',
      description:
        'Potrzebujesz szybkiej chwilówki na 61 dni, którą błyskawicznie otrzymasz na konto bankowe?',
      url: '/artykuly/pozyczka-na-61-dni/',
      date: '7.9.2021',
    },
    {
      title: 'Pożyczka 3000 zł – chwilówki 3000 zł przez internet na raty',
      description:
        'Zazwyczaj zobowiązania w kwocie kilku tysięcy złotych, do których zalicza się też tytułowa pożyczka 3000 zł, spłacane są w trybie jednorazowym.',
      url: '/artykuly/pozyczka-3000-zl-chiwilowki-3000-zl-przez-internet-na-raty/',
      date: '7.9.2021',
    },
    {
      title: 'Szybka pożyczka 30 000 zł – pożyczki online do 30 000 zł na raty',
      description:
        'Brakuje Ci pieniędzy na dokończenie remontu mieszkania? Chcesz pożyczyć środki na nowy samochód?',
      url: '/artykuly/szybka-pozyczka-30-000-zl-pozyczki-online-do-30-000-zl-na-raty/',
      date: '7.9.2021',
    },
    {
      title: 'Pilna pożyczka na dzisiaj – ekspresowe chwilówki na dzisiaj',
      description:
        'Nagły wyjazd, znakomita oferta zakupu samochodu albo konieczność naprawy zepsutej sprzętu RTV/AGD to przykładowe sytuacje, które wymagają sięgnięcia do kieszeni.',
      url: '/artykuly/pilna-pozyczka-na-dzisiaj-ekspresowe-chwilowki-na-dzisiaj/',
      date: '7.9.2021',
    },
    {
      title: 'Pożyczka na 6 miesięcy – pożyczka online na pół roku',
      description:
        'Półroczny okres kredytowania jest wystarczająco długi, by móc rozłożyć w czasie spłatę zobowiązania finansowego.',
      url: '/artykuly/pozyczka-na-6-miesiecy-pozyczka-online-na-pol-roku/',
      date: '7.9.2021',
    },
    {
      title: 'Szybka pożyczka na umowę zlecenie lub umowę o dzieło',
      description:
        'W bankach klienci ubiegający się o kredyt gotówkowy  czy zobowiązanie finansowe innego rodzaju muszą wykazać, że posiadają odpowiednio wysoką zdolność kredytową.',
      url: '/artykuly/szybka-pozyczka-na-umowe-zlecenie-lub-umowe-o-dzielo/',
      date: '7.9.2021',
    },
    {
      title: 'Dobra pożyczka – najlepsze oferty chwilówek z Saverium',
      description:
        'zym jest dobra pożyczka? Z Twojej perspektywy to taka oferta, która pozwoli Ci szybko uzyskać pieniądze, z wypłatą bezpośrednio na Twoje konto bankowe, a jednocześnie odsetki od zobowiązania będą możliwie jak najniższe.',
      url: '/artykuly/dobra-pozyczka-najlepsze-oferty-chwilowek-z-saverium/',
      date: '7.9.2021',
    },
    {
      title: 'Pożyczka 50 000 zł – pożyczki online do 50 000 zł',
      description:
        'Potrzebujesz większej kwoty pieniędzy i to w możliwie jak najkrótszym czasie? Nie możesz czekać, aż bank rozpatrzy Twój wniosek o kredyt gotówkowy?',
      url: '/artykuly/pozyczka-50-000-zl-pozyczki-online-do-50-000-zl/',
      date: '7.9.2021',
    },
    {
      title: 'Kredyt samochodowy – najważniejsze informacje',
      description:
        'Do udzielania kredytów uprawnione są jedynie instytucje bankowe – głównie banki lub spółdzielcze kasy oszczędnościowo-kredytowe. W ofercie mają one zobowiązania dowolnego przeznaczenia, jak i celowe.',
      url: '/kredyt-samochodowy/',
      date: '8.9.2021',
    },
    {
      title: 'Kredyt konsolidacyjny – najważniejsze informacje',
      description:
        ' Wśród wielu różnych ofert, jakie dostępne są w instytucjach bankowych, można znaleźć między innymi propozycje udzielenia kredytu konsolidacyjnego.',
      url: '/kredyt-konsolidacyjny/',
      date: '8.9.2021',
    },
    {
      title: 'Kredyt dla firm – najważniejsze informacje',
      description: 'Instytucje bankowe oferują kredyty oraz pożyczki online',
      url: '/kredyt-dla-firm/',
      date: '8.9.2021',
    },
    {
      title: 'Kredyt odnawialny – najważniejsze informacje',
      description:
        'Banki udzielają kredytów różnego rodzaju – celowych i pozwalających na realizację dowolnych przedsięwzięć wymagających zewnętrznego finansowania.',
      url: '/kredyt-odnawialny/',
      date: '8.9.2021',
    },
    {
      title: 'Kredyt refinansowy – najważniejsze informacje',
      description:
        'Wraz z upływem czasu często zmieniają się warunki kredytowania na rynku bankowym. Może okazać się więc, że dotychczasowy kredyt jest drogi w porównaniu z obecnymi ofertami.',
      url: '/kredyt-refinansowowy/',
      date: '8.9.2021',
    },
    {
      title: 'Kredyt na wykończenie domu – najważniejsze informacje',
      description:
        'Banki udzielają klientom kredytów celowych, jak np. kredyt hipoteczny czy samochodowy, jak i takich o dowolnym przeznaczeniu, czego przykładem jest kredyt gotówkowy.',
      url: '/kredyt-na-wykonczenie-domu/',
      date: '8.9.2021',
    },

    {
      title: 'Pożyczka na raty bez weryfikacji. Jak zdobyć?',
      description:
        'Masz niekorzystną historię kredytową i trudno ci uzyskać kredyt bankowy? Rozwiązaniem jest pożyczka na raty bez weryfikacji. Sprawdź, jak dostać pożyczkę na raty bez BIK!',
      src: '/static/img/saverium_pl/article-images/installment-loan-no-verification.jpg',
      tiny: '/static/img/saverium_pl/article-images/installment-loan-no-verification-tiny.jpg',
      url: '/artykuly/pozyczka-na-raty-bez-weryfikacji-jak-zdobyc/',
      date: '21.3.2022',
    },
    {
      title: 'Kredyt na oświadczenie. Zobacz, jak do dostać!',
      description:
        'Czym jest kredyt na oświadczenie i czym różni się od typowego kredytu? Przekonaj się, co zrobić, by otrzymać kredyt na oświadczenie!',
      src: '/static/img/saverium_pl/article-images/credit-for-statement.jpg',
      tiny: '/static/img/saverium_pl/article-images/credit-for-statement-tiny.jpg',
      url: '/artykuly/kredyt-na-oswiadczenie-zobacz-jak-do-dostac/',
      date: '21.3.2022',
    },
    {
      title: 'Kredyt na remont mieszkania – jak się ubiegać?',
      description:
        'Co zrobić, aby otrzymać kredyt na remont mieszkania? Które rozwiązanie jest najlepsze? Zobacz, jak wybrać najlepszy kredyt na mieszkanie i remont!',
      src: '/static/img/saverium_pl/article-images/apartment-renovation-loan.jpg',
      tiny: '/static/img/saverium_pl/article-images/apartment-renovation-loan-tiny.jpg',
      url: '/artykuly/kredyt-na-remont-mieszkania-jak-pozyskac/',
      date: '21.3.2022',
    },
    {
      title: 'Kredyt na wykończenie mieszkania. Jak dostać?',
      description:
        'W jaki sposób otrzymać kredyt na wykończenie mieszkania? Jakie warunki trzeba spełnić? Przekonaj się i uzyskaj pożyczkę!',
      src: '/static/img/saverium_pl/article-images/loan-for-finishing-apartment.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-for-finishing-apartment-tiny.jpg',
      url: '/artykuly/kredyt-na-wykonczenie-mieszkania-jakie-sa-warunki/',
      date: '21.3.2022',
    },
    {
      title: 'Pożyczka na czek GIRO – co to jest?',
      description:
        'Czym jest pożyczka na czek GIRO i jak może ci pomóc? Poznaj najważniejsze informacje dotyczące tego rozwiązania. Przekonaj się, w jaki sposób możesz dzięki niemu sprawnie pozyskać potrzebne środki.',
      src: '/static/img/saverium_pl/article-images/giro-check-loan.jpg',
      tiny: '/static/img/saverium_pl/article-images/giro-check-loan-tiny.jpg',
      url: '/artykuly/pozyczka-na-czek-giro-co-to-jest/',
      date: '21.3.2022',
    },
    {
      title: 'Szybka pożyczka na już. Jakie są warunki jej uzyskania?',
      description:
        'Nieprzewidziane wydatki i skomplikowane sytuacje życiowe? Odpowiedzią może okazać się szybka pożyczka na już. Zobacz, jak funkcjonują szybkie pożyczki online.',
      src: '/static/img/saverium_pl/article-images/quick-loan-for-now.jpg',
      tiny: '/static/img/saverium_pl/article-images/quick-loan-for-now-tiny.jpg',
      url: '/artykuly/szybka-pozyczka-na-juz-zobacz-jak-uzyskac/',
      date: '21.3.2022',
    },
    {
      title: 'Pożyczka 100% przyznawalności – jak się ubiegać?',
      description:
        'Czy w ogóle istnieje coś takiego jak pożyczka 100% przyznawalności? A może to jedynie pobożne życzenia kredytobiorców?',
      src: '/static/img/saverium_pl/article-images/grantable-loan.jpg',
      tiny: '/static/img/saverium_pl/article-images/grantable-loan-tiny.jpg',
      url: '/artykuly/pozyczka-100-przyznawalnosci-jak-sie-ubiegac/',
      date: '21.3.2022',
    },
    {
      title: 'Pozabankowa karta kredytowa online - co to jest?',
      description:
        'Karta kredytowa umożliwia dokonywanie płatności za towary bądź usługi nawet w sytuacji, gdy jej posiadacz nie dysponuje wolnymi środkami finansowymi.',
      src: '/static/img/saverium_pl/article-images/online-nonbank-credit-card.jpg',
      tiny: '/static/img/saverium_pl/article-images/online-nonbank-credit-card-tiny.jpg',
      url: '/artykuly/pozabankowa-karta-kredytowa-online-co-to-jest/',
      date: '21.3.2022',
    },
    {
      title: 'Co to jest karta kredytowa, jak działa?',
      description:
        'Pożyczka, której udziela wyspecjalizowana instytucja bądź kredyt bankowy to produkty finansowe, dzięki którym możemy pozyskać pewną sumę pieniędzy jednorazowo.',
      src: '/static/img/saverium_dk/article-imgs/creditworthiness.jpg',
      tiny: '/static/img/saverium_dk/article-imgs/creditworthiness-tiny.jpg',
      url: '/artykuly/co-to-jest-karta-kredytowa-jak-dziala/',
      date: '21.3.2022',
    },
    {
      title: 'Pożyczka od zaraz - jak się ubiegać?',
      description:
        'Środki finansowe, pochodzące z szybkiej pożyczki, mogą pomóc pokryć większe bądź mniejsze wydatki, gdyż sama pożyczka udzielana jest na różne kwoty.',
      src: '/static/img/saverium_pl/article-images/loan-immediately.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-immediately-tiny.jpg',
      url: '/artykuly/pozyczka-od-zaraz-jak-sie-ubiegac/',
      date: '21.3.2022',
    },
    {
      title: 'Skąd pożyczyć pieniądze, jakie są możliwości?',
      description:
        'Gdy pojawia się potrzeba pozyskania dodatkowych środków finansowych, dobrze jest rozważyć wszelkie możliwości i wybrać najkorzystniejszą dla siebie.',
      url: '/artykuly/skad-pozyczyc-pieniadze-jakie-sa-mozliwosci/',
      date: '21.3.2022',
      src: '/static/img/saverium_pl/article-images/where-to-borrow-money.jpg',
      tiny: '/static/img/saverium_pl/article-images/where-to-borrow-money-tiny.jpg',
    },
    {
      title: 'Pożyczki bez zdolności kredytowej - czy to możliwe?',
      description:
        'Banki skrupulatnie sprawdzają zdolność kredytową klientów, którzy ubiegają się o dodatkowe finansowanie.',
      src: '/static/img/saverium_pl/article-images/loans-without-creditworthiness.jpg',
      tiny: '/static/img/saverium_pl/article-images/loans-without-creditworthiness-tiny.jpg',
      url: '/artykuly/pozyczki-bez-zdolnosci-kredytowej-czy-to-mozliwe/',
      date: '21.3.2022',
    },
    {
      title: 'Skąd wziąć pieniądze na już?',
      description:
        'Konieczność poniesienia nieprzewidzianych wydatków zwykle występuje w najmniej spodziewanym i odpowiednim momencie - gdy szef spóźnia się z wypłatą wynagrodzenia, kiedy trzeba było pokryć innego rodzaju, większe wydatki bądź gdy chwilowo zostaliśmy bez żadnych oszczędności.',
      src: '/static/img/saverium_pl/article-images/where-to-get-money.jpg',
      tiny: '/static/img/saverium_pl/article-images/where-to-get-money-tiny.jpg',
      url: '/artykuly/skad-wziac-pieniadze-na-juz/',
      date: '21.3.2022',
    },
    {
      title: 'Szybka kasa przez internet - jak się ubiegać?',
      description:
        'Pozyskanie dodatkowej gotówki w bardzo krótkim czasie nie musi być trudne ani wiązać się z nadzwyczajnie wysokim oprocentowaniem.',
      url: '/artykuly/szybka-kasa-przez-internet-jak-sie-ubiegac/',
      date: '21.3.2022',
      src: '/static/img/saverium_pl/article-images/fast-online-checkout.jpg',
      tiny: '/static/img/saverium_pl/article-images/fast-online-checkout-tiny.jpg',
    },
    {
      title: 'Jak szybko i bezpieczne płacić kartą przez internet?',
      description:
        'Płatność kartą stała się częścią naszej codzienności tak jak i zakupu internetowe.',
      url: '/artykuly/jak-szybko-i-bezpieczne-placic-karta-przez-internet/',
      src: '/static/img/saverium_pl/article-images/pay-by-card-over-internet.jpg',
      tiny: '/static/img/saverium_pl/article-images/pay-by-card-over-internet-tiny.jpg',
      date: '21.3.2022',
    },
    {
      title: 'Pożyczka na raty bez bik - pożyczki ratalne bez weryfikacji BIK',
      description:
        'O pożyczkę, czy to w banku, czy w wyspecjalizowanej firmie pożyczkowej, mogą starać się osoby z dobrą historią kredytową.',
      url: '/artykuly/pozyczka-na-raty-bez-bik-pozyczki-ratalne-bez-weryfikacji-BIK/',
      src: '/static/img/saverium_pl/article-images/installment-no-bik.jpg',
      tiny: '/static/img/saverium_pl/article-images/installment-no-bik-tiny.jpg',
      date: '06.5.2022',
    },
    {
      title: 'Chwilówki bez baz - pożyczki online bez sprawdzania baz',
      description:
        'Niespłacone zobowiązania finansowe uniemożliwiają uzyskanie pożyczki pozabankowej czy kredytu bankowego.',
      url: '/artykuly/chwilowki-bez-baz-pozyczki-online-bez-sprawdzania-baz/',
      src: '/static/img/saverium_pl/article-images/payday-loans-no-databases.jpg',
      tiny: '/static/img/saverium_pl/article-images/payday-loans-no-databases-tiny.jpg',
      date: '06.5.2022',
    },
    {
      title: 'Saldo konta - co to jest, jak sprawdzić? Co oznacza saldo na minusie?',
      description:
        'Saldo konta to pojęcie, z którym spotyka się każdy, posiadający rachunek bankowy.',
      url: '/artykuly/saldo-konta-co-to-jest-jak-sprawdzic/',
      src: '/static/img/saverium_pl/article-images/account-balance.jpg',
      tiny: '/static/img/saverium_pl/article-images/account-balance-tiny.jpg',
      date: '06.5.2022',
    },
    {
      title: 'Pożyczka bez weryfikacji telefonicznej',
      description:
        'Obecnie większość formalności, związana z zaciąganiem pożyczki pozabankowej, może być załatwiona online, bez wychodzenia z domu i bez konieczności kontaktowania się z konsultantem.',
      url: '/artykuly/pozyczka-bez-weryfikacji-telefonicznej/',
      src: '/static/img/saverium_pl/article-images/loan-without-phone-verification.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-without-phone-verification-tiny.jpg',
      date: '06.5.2022',
    },
    {
      title: 'Pożyczki 24h przez internet - chwilówki 24/7',
      description:
        'Globalizacja i powszechny dostęp do internetu dają nam obecnie bardzo szerokie możliwości.',
      url: '/artykuly/pozyczki-24h-przez-internet-chwilowki/24-7/',
      src: '/static/img/saverium_pl/article-images/loan-24-hours.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-24-hours-tiny.jpg',
      date: '06.5.2022',
    },
    {
      title: 'Pożyczka bez udokumentowanych dochodów',
      description:
        'Zaciągnięcie pożyczki bankowej związane jest z koniecznością udokumentowania swoich dochodów, na podstawie których bank ocenia zdolność kredytową klienta',
      url: '/artykuly/pozyczka-bez-udokumentowanych/dochodow/',
      src: '/static/img/saverium_pl/article-images/loan-with-no-income.jpg',
      tiny: '/static/img/saverium_pl/article-images/loan-with-no-income-tiny.jpg',
      date: '06.5.2022',
    },
    {
      title: 'Największy kredyt gotówkowy - jaki można wziąć?',
      description:
        'Kredyt gotówkowy zwykle kojarzy się ze zobowiązaniem, opiewającym na stosunkowo niską kwotę.',
      src: '/static/img/saverium_pl/article-images/biggest-cash-loan.webp',
      tiny: '/static/img/saverium_pl/article-images/biggest-cash-loan-tiny.webp',
      url: '/artykuly/najwiekszy-kredyt-gotowkowy/',
      date: '17.6.2022',
    },
    {
      title: 'Kredyt a umowa na czas określony',
      description:
        'Wiele mówi się o tym, że banki o wiele łaskawiej patrzą na klientów, którzy ubiegając się o kredyt, posiadają umowę o pracę na czas nieokreślony.',
      src: '/static/img/saverium_pl/article-images/cash-loan-and-fixed-contract.webp',
      tiny: '/static/img/saverium_pl/article-images/cash-loan-and-fixed-contract-tiny.webp',
      url: '/artykuly/kredyt-a-umowa-na-czas-okreslony/',
      date: '17.6.2022',
    },
    {
      title: 'Kredyt po 1 miesiącu pracy - minimalny okres zatrudnienia do kredytu',
      description:
        'Każdy bank wymaga, aby potencjalny kredytobiorca posiadał źródło dochodu.',
      src: '/static/img/saverium_pl/article-images/loan-after-one-month.webp',
      tiny: '/static/img/saverium_pl/article-images/loan-after-one-month-tiny.webp',
      url: '/artykuly/kredyt-po-1-miesiacu-pracy/',
      date: '17.6.2022',
    },
    {
      title: 'Kredyt gotówkowy na dowolny cel',
      description:
        'Popularność kredytów gotówkowych rośnie z roku na rok, głównie ze względu na uproszczoną procedurę ich przyznawania, na przykład w stosunku do kredytów hipotecznych.',
      src: '/static/img/saverium_pl/article-images/cash-loan-for-any-purpose.webp',
      tiny: '/static/img/saverium_pl/article-images/cash-loan-for-any-purpose-tiny.webp',
      url: '/artykuly/kredyt-gotowkowy-na-dowolny-cel/',
      date: '17.6.2022',
    },
    {
      title:
        'Kto może otrzymać kredyt - jakie trzeba spełniać warunki żeby dostać kredyt?',
      description:
        'Jakie trzeba spełnić warunki, aby dostać kredyt? Czy mam zdolność kredytową? Kto nie może dostać kredytu?',
      src: '/static/img/saverium_pl/article-images/who-can-get-a-loan.webp',
      tiny: '/static/img/saverium_pl/article-images/who-can-get-a-loan-tiny.webp',
      url: '/artykuly/kto-moze-otrzymac-kredyt/',
      date: '17.6.2022',
    },
    {
      title: 'Kredyt gotówkowy na oświadczenie online',
      description:
        'Banki, zanim podejmą decyzję o przyznaniu kredytu, badają zdolność kredytową swoich potencjalnych klientów.',
      src: '/static/img/saverium_pl/article-images/cash-loan-for-online-statement.webp',
      tiny: '/static/img/saverium_pl/article-images/cash-loan-for-online-statement-tiny.webp',
      date: '17.06.2022',
      url: '/artykuly/kredyt-gotowkowy-na-oswiadczenie-online/',
    },
    {
      title: 'Kredyt gotówkowy na samochód używany',
      description:
        'Kredyt na samochód to jeden z najpopularniejszych rodzajów kredytu gotówkowego.',
      src: '/static/img/saverium_pl/article-images/used-car-cash-loan.webp',
      tiny: '/static/img/saverium_pl/article-images/used-car-cash-loan-tiny.webp',
      date: '17.06.2022',
      url: '/artykuly/kredyt-gotowkowy-na-samochod-uzywany/',
    },
    {
      title: 'Kredyt gotówkowy bez zdolności kredytowej',
      description:
        'Banki udzielają kredytów klientom, którzy w ich ocenie są w stanie spłacić zobowiązanie w określonym terminie.',
      src: '/static/img/saverium_pl/article-images/cash-loan-without-credit-worthiness.webp',
      tiny: '/static/img/saverium_pl/article-images/cash-loan-without-credit-worthiness-tiny.webp',
      date: '17.06.2022',
      url: '/artykuly/kredyt-gotowkowy-bez-zdolnosci-kredytowej/',
    },
    {
      title: 'Kredyt gotówkowy na dowód bez umowy o pracę',
      description:
        'Umowa o pracę stanowi dobre zabezpieczenie spłaty kredyt, zwłaszcza jeśli zawarta jest na czas nieokreślony.',
      src: '/static/img/saverium_pl/article-images/cash-loan-proof-no-employment.webp',
      tiny: '/static/img/saverium_pl/article-images/cash-loan-proof-no-employment-tiny.webp',
      date: '17.06.2022',
      url: '/artykuly/kredyt-gotowkowy-na-dowod-bez-umowy-o-prace/',
    },
    {
      title: 'Kredyt gotówkowy bez zaświadczeń online zdolność kredytową',
      description:
        'Banki udzielają kredytów osobom, które posiadają, co oznacza, że są w stanie spłacić zobowiązanie w określonym umową terminie.',
      src: '/static/img/saverium_pl/article-images/cash-loan-without-online-certificates.webp',
      tiny: '/static/img/saverium_pl/article-images/cash-loan-without-online-certificates-tiny.webp',
      date: '17.06.2022',
      url: '/artykuly/kredyt-gotowkowy-bez-zaswiadczen-online/',
    },
    {
      title: 'Kredyt przez Internet - szybki kredyt online',
      description:
        'Jeszcze do niedawna podpisanie umowy kredytowej wymagało osobistego stawienia się w oddziale banku.',
      src: '/static/img/saverium_pl/article-images/loan-over-internet.webp',
      tiny: '/static/img/saverium_pl/article-images/loan-over-internet-tiny.webp',
      date: '17.06.2022',
      url: '/artykuly/kredyt-przez-internet/',
    },
    {
      title: 'Kredyt ratalny - kredyt na raty online',
      description:
        'Zakup sprzętu AGD, roweru czy usługi turystycznej to tylko przykłady wydatków, których nie ponosimy zbyt często, lecz wymagają one posiadania sporych oszczędności.',
      src: '/static/img/saverium_pl/article-images/online-installment-loan.webp',
      tiny: '/static/img/saverium_pl/article-images/online-installment-loan-tiny.webp',
      date: '17.06.2022',
      url: '/artykuly/kredyt-ratalny/',
    },
    {
      title: 'Kredyt gotówkowy na remont domu',
      description:
        'Remont domu wolno stojącego bądź mieszkania związany jest z koniecznością poniesienia sporych wydatków.',
      src: '/static/img/saverium_pl/article-images/cash-loan-for-home-renovation.webp',
      tiny: '/static/img/saverium_pl/article-images/cash-loan-for-home-renovation-tiny.webp',
      date: '17.06.2022',
      url: '/artykuly/kredyt-gotowkowy-na-remont-domu/',
    },
    {
      title: 'Kredyt gotówkowy z wpisami w bazach',
      description:
        'Kredyt gotówkowy mogą otrzymać osoby, które posiadają zdolność kredytową . Przy jej ocenie bierze się pod uwagę różne czynniki, w tym między innymi wysokość dochodów oraz ich źródło.',
      src: '/static/img/saverium_pl/article-images/cash-loan-with-database-entries.webp',
      tiny: '/static/img/saverium_pl/article-images/cash-loan-with-database-entries-tiny.webp',
      date: '17.06.2022',
      url: '/artykuly/kredyt-gotowkowy-z-wpisami-w-bazach/',
    },
    {
      title: 'Kredyt rewolwingowy - co to jest?',
      description:
        'Kredyt na ogół kojarzy się z pewną sumą pieniędzy, którą bank udostępnia klientowi.',
      src: '/static/img/saverium_pl/article-images/fast-online-checkout.jpg',
      tiny: '/static/img/saverium_pl/article-images/fast-online-checkout-tiny.jpg',
      date: '17.06.2022',
      url: '/artykuly/kredyt-rewolwingowy/',
    },
    {
      title: 'Kredyt gotówkowy mając kredyt hipoteczny - jakie są warunki',
      description:
        'Kredyt hipoteczny to wieloletnie zobowiązanie finansowe, które najczęściej służy do zakupu własnego mieszkania bądź domu.',
      src: '/static/img/saverium_pl/article-images/cash-loan-with-mortgage.webp',
      tiny: '/static/img/saverium_pl/article-images/cash-loan-with-mortgage-tiny.webp',
      date: '11.07.2022',
      url: '/artykuly/kredyt-gotowkowy-majac-kredyt-hipoteczny/',
    },
    {
      title: 'Czy można dostać kredyt mając kredyt hipoteczny?',
      description:
        'Kredyt hipoteczny często stanowi jedyny sposób na sfinansowanie zakupu mieszkania czy domu.',
      src: '/static/img/saverium_pl/article-images/can-you-loan-with-mortgage.webp',
      tiny: '/static/img/saverium_pl/article-images/can-you-loan-with-mortgage-tiny.webp',
      date: '11.07.2022',
      url: '/artykuly/czy-mozna-dostac-kredyt-majac-kredyt-hipoteczny/',
    },
    {
      title: 'Jak wziąć kredyt gotówkowy krok po kroku?',
      description: 'Kredyt gotówkowy może posłużyć na zakup dowolnych towarów lub usług.',
      src: '/static/img/saverium_pl/article-images/cash-loan-step-by-step.webp',
      tiny: '/static/img/saverium_pl/article-images/cash-loan-step-by-step-tiny.webp',
      date: '11.07.2022',
      url: '/artykuly/jak-wziac-kredyt-gotowkowy-krok-po-kroku/',
    },
    {
      title: 'Kredyt gotówkowy na dochody z zagranicy',
      description:
        'Zabezpieczeniem spłaty kredytu są regularne dochody w odpowiedniej wysokości. Można je uzyskiwać z różnych źródeł, na przykład z pracy zarobkowej.',
      src: '/static/img/saverium_pl/article-images/cash-loan-from-abroad.webp',
      tiny: '/static/img/saverium_pl/article-images/cash-loan-from-abroad-tiny.webp',
      date: '11.07.2022',
      url: '/artykuly/kredyt-gotowkowy-na-dochody-z-zagranicy/',
    },
    {
      title: 'Wspólny kredyt gotówkowy - co należy wiedzieć?',
      description:
        'Wspólny kredyt gotówkowy w małżeństwie nie jest niczym niespotykanym, zwłaszcza jeśli para nie zawarła intercyzy.',
      src: '/static/img/saverium_pl/article-images/joint-cash-loan.webp',
      tiny: '/static/img/saverium_pl/article-images/joint-cash-loan-tiny.webp',
      date: '11.07.2022',
      url: '/artykuly/wspolny-kredyt-gotowkowy/',
    },
    {
      title: 'Kredyt samochodowy czy gotówkowy - jaki wybrać?',
      description:
        'Samochody zarówno nowe, jak i używany kosztują całkiem sporo, zatem nie każdy jest w stanie sfinansować ich zakup z własnych oszczędności.',
      src: '/static/img/saverium_pl/article-images/car-or-cash-loan.webp',
      tiny: '/static/img/saverium_pl/article-images/car-or-cash-loan-tiny.webp',
      date: '11.07.2022',
      url: '/artykuly/kredyt-samochodowy-czy-gotowkowy/',
    },
    {
      title: 'Kredyt bez zgody współmałżonka - co należy wiedzieć?',
      description:
        'Decyzja odnośnie zaciągnięcia kredytu nie zawsze zapada wśród małżonków jednogłośnie. W przypadku braku zgody jednego z nich, powstaje pytanie, czy wciąż można ubiegać się o kredyt?',
      src: '/static/img/saverium_pl/article-images/loan-without-partner-consent.webp',
      tiny: '/static/img/saverium_pl/article-images/loan-without-partner-consent-tiny.webp',
      date: '11.07.2022',
      url: '/artykuly/kredyt-bez-zgody-wspolmalzonka/',
    },
    {
      title: 'Kredyt gotówkowy na mieszkanie zamiast hipotecznego',
      description:
        'Kredyt hipoteczny jest na ogół tańszy od gotówkowego, jednak jego uzyskanie wymaga dopełnienia większej liczby formalności.',
      src: '/static/img/saverium_pl/article-images/cash-loan-for-mortgage.webp',
      tiny: '/static/img/saverium_pl/article-images/cash-loan-for-mortgage-tiny.webp',
      date: '11.07.2022',
      url: '/artykuly/kredyt-gotowkowy-na-mieszkanie/',
    },
    {
      title: 'Kredyt gotówkowy ze stałym oprocentowaniem',
      description:
        'Przy niepewnej sytuacji na rynkach finansowych oraz podwyżkach stóp procentowych, kredyt gotówkowy ze stałym oprocentowaniem staje się bardzo pożądanym produktem.',
      src: '/static/img/saverium_pl/article-images/cash-loan-fixed-interest.webp',
      tiny: '/static/img/saverium_pl/article-images/cash-loan-fixed-interest-tiny.webp',
      date: '11.07.2022',
      url: '/artykuly/kredyt-gotowkowy-ze-stalym-oprocentowaniem/',
    },
    {
      title: 'Kredyt na umowę zlecenie - kiedy jest możliwe uzyskanie?',
      description:
        'Przyjęło się, że o kredyt mogą starać się wyłącznie osoby zatrudnione w ramach umowy o pracę.',
      src: '/static/img/saverium_pl/article-images/contract-loan.webp',
      tiny: '/static/img/saverium_pl/article-images/contract-loan-tiny.webp',
      date: '11.07.2022',
      url: '/artykuly/kredyt-na-umowe-zlecenie/',
    },
    {
      title: 'Ile trzeba zarabiać, żeby dostać kredyt gotówkowy w 2022 roku?',
      description:
        'Nie da się ukryć, że w obecnych czasach kredyt gotówkowy dla większości z nas stał się praktycznie standardem. Często zaciągamy bowiem kredyty celem poprawy własnej płynności finansowej lub po prostu zrealizowania większej inwestycji.',
      src: '/static/img/saverium_pl/article-images/earn-how-much-for-loan.webp',
      tiny: '/static/img/saverium_pl/article-images/earn-how-much-for-loan-tiny.webp',
      date: '24.08.2022',
      url: '/artykuly/ile-trzeba-zarabiac-aby-dostac-kredyt-gotowkowy/',
    },
    {
      title: 'Ile się czeka na kredyt gotówkowy?',
      description:
        'Do niedawna zaciągnięcie kredytu większości z nas kojarzyło się przede wszystkim z maksimum formalności i wyjątkowo długim czasem oczekiwania.',
      src: '/static/img/saverium_pl/article-images/wait-how-much-for-cash-loan.webp',
      tiny: '/static/img/saverium_pl/article-images/wait-how-much-for-cash-loan-tiny.webp',
      date: '24.08.2022',
      url: '/artykuly/ile-sie-czeka-na-kredyt-gotowkowy/',
    },
    {
      title: 'Czy warto teraz brać kredyt gotówkowy?',
      description:
        'Kredyt gotówkowy dla większości z nas był rozwiązaniem dość standardowym. Dzięki dodatkowej gotówce, mogliśmy szybko sfinansować większe inwestycje, a także uzupełnić ewentualną dziurę w budżecie domowym.',
      src: '/static/img/saverium_pl/article-images/is-it-worth-taking-cash-loan.webp',
      tiny: '/static/img/saverium_pl/article-images/is-it-worth-taking-cash-loan-tiny.webp',
      date: '24.08.2022',
      url: '/artykuly/czy-warto-teraz-brac-kredyt-gotowkowy/',
    },
    {
      title: 'Czy stopy procentowe mają wpływ na kredyt gotówkowy?',
      description:
        'Każdy z nas doskonale zdaje sobie sprawę z tego, że kredyty gotówkowe są szybkim i obecnie dość prostym sposobem na pozyskanie konkretnej ilości gotówki.',
      src: '/static/img/saverium_pl/article-images/do-interest-rates-affect-loan.webp',
      tiny: '/static/img/saverium_pl/article-images/do-interest-rates-affect-loan-tiny.webp',
      date: '24.08.2022',
      url: '/artykuly/stopy-procentowe-a-kredyt-gotowkowy/',
    },
    {
      title: 'Jakie dokumenty są potrzebne do wnioskowania o kredyt gotówkowy?',
      description:
        'Każdy kredytobiorca doskonale zdaje sobie sprawę z tego, że wnioskowanie o kredyt gotówkowy nigdy nie było i prawdopodobnie nie będzie należało do najłatwiejszych zadań.',
      src: '/static/img/saverium_pl/article-images/what-documents-for-cash-loan.webp',
      tiny: '/static/img/saverium_pl/article-images/what-documents-for-cash-loan-tiny.webp',
      date: '24.08.2022',
      url: '/artykuly/dokumenty-do-kredytu-gotowkowego/',
    },
  ],
}
const getters = {}

const mutations = {}
export default {
  state,
  getters,
  mutations,
}
