<template>
  <div id="fp">
    <info-row />
  </div>
</template>

<script>
import InfoRow from '@/components/saverium_pl/InfoRowComponent'

export default {
  components: {
    InfoRow,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
