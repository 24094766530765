import saverium_pl from './saverium_pl'
import saverium_es from './saverium_es'
import lainaayritykselle_fi from './lainaayritykselle_fi'
import saverium_dk from './saverium_dk'
import saverium_fi from './saverium_fi'

export default {
  saverium_pl,
  saverium_es,
  lainaayritykselle_fi,
  saverium_dk,
  saverium_fi,
}
