<template>
  <div class="card-body text-left">
    <CardInformation :offer="offer" :action="action" :currency="currency" />
    <div class="mb-3">
      <div class="text-cRenter btn-row my-2 col-12">
        <button class="btn btn-block mb-4 btn-primary accept-offer" v-b-modal.extraInfo>
          {{ $t('provide_more_info_plz') }} »
        </button>
      </div>
      <div>
        <b-modal id="extraInfo" :title="$t('provide_more_info_plz')">
          <div class="alert alert-danger" role="alert" v-if="hasErrors">
            <template> {{ $t('check_the_fields_marked_in_red') }} </template>
          </div>
          <template v-if="modalstatus === 'normal' || !modalstatus">
            <div
              class="form-group col-12"
              v-for="(fieldContent, title) in missingFields"
              :key="title"
            >
              <template
                v-if="
                  title === 'id_type' ||
                  title === 'house_type' ||
                  title === 'house_status' ||
                  title === 'employment_sector' ||
                  title === 'profession_code'
                "
              >
                <form-input
                  type="dropdown"
                  :storekey="`extraInfo.${title}`"
                  :items="fieldContent"
                >
                  {{ $t(title) }}
                </form-input>
              </template>
              <template v-else-if="title.includes('Dziecko')">
                <form-input
                  :type="fieldContent"
                  :name="title"
                  :storekey="`extraInfo.Dziecko${title.match(/\d+/)[0]}`"
                  :id="title"
                >
                  {{ $t(title) }}
                </form-input>
              </template>
              <template v-else>
                <form-input
                  :type="fieldContent"
                  :name="title"
                  :storekey="`extraInfo.${title}`"
                  :id="title"
                >
                  {{ $t(title) }}
                </form-input>
              </template>
            </div>
            <div slot="modal-footer" class="card-footer">
              <b-button
                variant="primary"
                size="sm"
                class="send-info float-right"
                @click="sendInfo()"
                >Ok</b-button
              >
            </div>
          </template>
          <template v-else-if="modalstatus === 'success'">
            <p>{{ $t('thank_you') }}{{ $t('your_information_has_been_sent') }}</p>
            <p>{{ $t('you_may_close_the_modal') }}</p>
            <div slot="modal-footer" class="card-footer">
              <b-button
                variant="primary"
                size="sm"
                class="send-info float-right"
                @click="$bvModal.hide('extraInfo'), reloadOffers()"
              >
                Ok</b-button
              >
            </div>
          </template>
        </b-modal>
      </div>
      <template v-if="offer.description">
        <CardFooter :offer="offer" />
      </template>
    </div>
  </div>
</template>
<script>
import FormInput from '@/components/form/FormInput'
import CardInformation from '@/components/account_view_components/CardInformation.vue'
import CardFooter from './CardFooter.vue'

export default {
  props: [
    'offer',
    'action',
    'currency',
    'missingFields',
    'callbackUrl',
    'active_non_bank_obligations',
  ],
  data() {
    return {
      toggle: false,
    }
  },
  components: {
    FormInput,
    CardInformation,
    CardFooter,
  },
  computed: {
    modalstatus() {
      return this.$store.state.application.modalstatus
    },
    errors() {
      return this.$store.state.application.errors
    },
    hasErrors() {
      return Object.keys(this.errors).length > 0
    },
  },
  methods: {
    sendInfo() {
      const trimmedUrl = this.callbackUrl.replace('/api/application/', '')
      this.$store.dispatch('sendExtraInfo', { url: trimmedUrl })
    },
    reloadOffers() {
      this.$store.dispatch('fetchApplicationList')
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
}
</script>
<style lang="scss" scoped>
.card-footer {
  width: 100%;
}
.send-info {
  width: 100%;
  height: 3rem;
  @media (max-width: 500px) {
    max-width: 300px;
  }
}
.btn-block {
  @media (max-width: 995px) {
    width: 100%;
    margin: auto;
  }
}

.btn-row {
  display: flex;
  height: 65px;
  justify-content: flex-end;
}
</style>
