<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til efterskoleophold</h1>
      <div class="top-text text-container">
        <p>
          Skal du (eller dit barn) på et efterskoleophold, men leder du blot efter lånet
          til at dække udgiften? Vi hjælper dig med at finde de bedste lån til
          efterskolen. Udfyld vores ansøgning, så indhenter vi lånetilbud til dig med det
          samme, fra en lang række kendte banker og långivere.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#saadan-finder-vi-det-rette-laan-til-efterskoleopholdet"
                    >Sådan finder vi det rette lån til efterskoleopholdet</a
                  >
                </li>
                <li>
                  <a href="#laan-til-selve-efterskoleopholdet"
                    >Lån til selve efterskoleopholdet</a
                  >
                </li>
                <li><a href="#udgift-til-udstyr">Udgift til udstyr</a></li>
                <li><a href="#laan-til-udflugterne">Lån til udflugterne</a></li>
                <li>
                  <a href="#andre-omkostninger-forbundet-med-efterskoleophold"
                    >Andre omkostninger forbundet med efterskoleophold</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="saadan-finder-vi-det-rette-laan-til-efterskoleopholdet">
            Sådan finder vi det rette lån til efterskoleopholdet
          </h2>
          <p>
            Først og fremmest skal du udfylde vores ansøgningsformular her på siden.
            Herefter sender vi dine oplysninger ud til en lang række banker, som alle
            sammen vurderer, hvilket lån de kan tilbyde dig. Hvis du søger for dit barn,
            så kan vi anbefale, at du søger sammen med barnets anden forælder, da det vil
            give jer flere og bedre tilbud.
          </p>
          <p>
            Når ansøgningen er sendt afsted, indhenter vi med det samme
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >lånetilbud</a
            >
            til dig/jer. Dette er tilbud på helt almindelige
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >, da det er den type af lån, som vil være relevant for et efterskoleophold.
          </p>
          <p>
            Herefter kan du/I vælge det lån, som I syntes passer bedst, alt efter om I
            prioriterer prisen, hastigheden, kundetilfredsheden, en specifik bank, eller
            noget helt femte.
          </p>
          <p>
            Fordelen ved at benytte vores service er, at det er meget hurtigere end selv
            at skulle indhente tilbud og også langt nemmere at overskue og sammenlign dem.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-selve-efterskoleopholdet">Lån til selve efterskoleopholdet</h2>
          <p>
            Efterskoleopholdet koster typisk 50.000 til 100.000 kr. for et helt år i følge
            <a
              href="https://www.efterskolerne.dk/da/Pris/Hvad_koster_det"
              rel="noopener noreferrer"
              target="_blank"
              >efterskolerne.dk</a
            >. Hvis du skal på en af de populære efterskoler såsom Oure, ligger prisen i
            den høje ende, hvis du derimod vælger Baunehøj, ligger prisen i den lave ende,
            på trods af at denne også er populær.
          </p>
          <p>
            Ud over disse to efterskoler, findes der også en lang række andre populære
            efterskoler, herunder Femmøller, SINE Sportsefterskole og Ollerup, som vi kan
            anbefale at undersøge, hvis du ikke er helt sikker på, hvilken efterskole du
            ønsker at gå på.
          </p>
          <p>
            Hvis din familie har en presset økonomi, men stadigvæk ønsker et
            efterskoleophold, så er der to muligheder. Den ene er at søge tilskud til
            efterskoleopholdet via selve skolen, hvilket kaldes “<a
              href="https://moedrehjaelpen.dk/holdepunkt/viden-og-raad/efterskole-stoette-raadgivning/"
              rel="noopener noreferrer"
              target="_blank"
              >Individuel supplerende elevstøtte</a
            >”. Du har også mulighed for at søge om lån til efterskoleopholdet igennem os,
            hvor vi finder de
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >bedste lån</a
            >
            til at dække efterskoleophold.
          </p>
        </div>

        <div class="text-container">
          <h2 id="udgift-til-udstyr">Udgift til udstyr</h2>
          <p>
            Mange, som beslutter sig for at tage et efterskoleophold, vælger deres
            efterskole alt efter hvilken interesse de har. Hvis interessen er
            omkostningstung, så kan et lån til udstyret på efterskolen komme på tale.
          </p>
          <p>
            Hvis du tager på en efterskole med fokus på musik, fodbold, håndbold eller
            basketball, så vil udstyret i sig selv sjældent kræve et lån. Derimod kan
            udstyret på efterskolen godt komme til at kræve et lån, hvis du fx vælger et
            ophold med fokus på en dyr hobby så som ridning, ski/snowboard eller golf.
          </p>
          <p>
            Ofte vil du have udstyret med på forhånd eller kunne låne det basale udstyr af
            efterskolen, men hvis du pågebynder en ny sport, indtil nu har lånt dit
            udstyr, eller blot ikke ønsker at gå ned på udstyret, så kan det blive en
            nødvendig tin at låne til på efterskolen.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-udflugterne">Lån til udflugterne</h2>
          <p>
            Mange ophold inkluderer også en eller flere rejser rundt i verdenen, som ikke
            altid er inkluderet i prisen på opholdet, hvilket er endnu en udgift, som kan
            kræve et lån. Vælger du fx en efterskole med fokus på basketball, kan der fx
            være en tur til USA, som du selv skal betale for. Hvis du har valgt et ophold
            med fokus på ski/snowboard, så går turen, eller turene, ofte til Alperne.
          </p>
          <p>
            Vi kunne fortsætte med at nævne udflugter og lande, alt efter hvilken linje,
            som du ønsker at gå på. Pointen er, at du muligvis ikke havde medregnet disse
            udflugter i dit budget, og her kan lån til efterskoleopholdet igen komme på
            tale.
          </p>
        </div>

        <div class="text-container">
          <h2 id="andre-omkostninger-forbundet-med-efterskoleophold">
            Andre omkostninger forbundet med efterskoleophold
          </h2>
          <p>
            Ud over ovenstående omkostninger, som i større eller mindre grad er umuligt at
            komme uden om, når man skal på efterskole, så findes der andre mindre
            omkostninger, som også bør tages med i betragtningen.
          </p>
          <p>
            Den første er lommepenge. Mad er inkluderet i opholdet på efterskolen, men
            hvis du ønsker at have råd til at købe en Cola, pizza, slik eller andet, så må
            du frem med lommepengene.
          </p>
          <p>
            Herudover kan du komme ud for at du selv skal betale for at vaske af dit tøj,
            med mindre du tager alt tøjet med hjem til mor og far. Da mange efterskoler
            ligger langt væk fra byerne, er der dog ofte også langt hjem, hvilket betyder,
            at du måske skal medregne transportomkostninger til og fra efterskolen.
          </p>
          <p>
            Alt dette skal også tages med i betragtningerne, når du overvejer, om du har
            behov for at låne til efterskoleopholdet.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
