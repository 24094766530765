<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til husleje</h1>

      <div class="top-text text-container">
        <p>
          Har du svært ved at få økonomien til at hænge sammen i denne måned? Vi hjælper
          dig gerne med at finde et lån til at dække huslejen. Det eneste det kræver er,
          at du udfylder vores ansøgning her på siden, som tager 2 minutter, så vi kan
          finde det helt rette lån til din situation.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#hvordan-du-faar-det-bedste-laan-til-at-daekke-huslejen"
                    >Hvordan du får det bedste lån til at dække huslejen</a
                  >
                </li>
                <li>
                  <a href="#risiko-ved-ikke-at-betale-huslejen"
                    >Risiko ved ikke at betale huslejen</a
                  >
                </li>
                <li>
                  <a href="#lav-en-aftale-med-din-udlejer"
                    >Lav en aftale med din udlejer</a
                  >
                </li>
                <li>
                  <a href="#hvorfor-har-du-brug-for-at-laane-penge-til-huslejen"
                    >Hvorfor har du brug for at låne penge til huslejen?</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="hvordan-du-faar-det-bedste-laan-til-at-daekke-huslejen">
            Hvordan du får det bedste lån til at dække huslejen
          </h2>
          <p>
            Hvis du leder efter det bedste lån til huslejen, så er det vigtigt at
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne lån</a
            >. Uanset hvordan du
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låner penge</a
            >, så finder du altid de <strong>bedste lån</strong> på denne måde.
          </p>
          <p>
            Hvis du vælger os, til at hjælpe dig med at låne penge til huslejen, er det
            eneste vi har brug for, at du udfylder vores ansøgningsskema, hvilket som
            nævnt, tager 2 minutter.
          </p>
          <p>
            Herefter fremsender vi din ansøgning om et lån til husleje til en lang række
            banker og
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >lånefirmer</a
            >, som med det samme vender tilbage med
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >tilbud på forbrugslån</a
            >
            til dig, som kan dække din husleje.
          </p>
          <p>
            Herefter skal du blot vælge det tilbud, som du ønsker. Uanset om du har brug
            for et at
            <a
              href="https://www.saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge hurtigt</a
            ><strong> </strong>eller det billigste lån til huslejen, så finder vi et godt
            tilbud til dig.
          </p>
          <h3>Lån penge af kommunen til husleje</h3>
          <p>
            I nogle tilfælde kan man låne penge af kommunen til huslejen. Her vil der dog
            tale om specielle tilfælde, og kun hvis din boligsituation kan betegnes som et
            minimum af levestandard.
          </p>
          <p>
            Hvis kommunen vurderer, at du sagtens kan bo i noget mindre, som er i
            dårligere stand og som ligger længere væk fra byen, så er der meget stor
            sandsynlighed for, at du ikke kan låne penge af kommunen til huslejen til din
            nuværende bolig.
          </p>
          <p>
            Hvis du ønsker at låne penge af kommunen til din husleje, så skal du have fat
            i
            <a
              href="https://www.kk.dk/borger/borgerservice"
              rel="noopener noreferrer"
              target="_blank"
              >kommunens borgerservice</a
            >.
          </p>
        </div>

        <div class="text-container">
          <h2 id="risiko-ved-ikke-at-betale-huslejen">
            Risiko ved ikke at betale huslejen
          </h2>
          <p>
            Hvis du ikke betaler din husleje, er der flere uønskede ting, som kan ske.
            Derfor anbefaler vi, at du som minimum undersøger dine muligheder for at få et
            lån til huslejen.
          </p>
          <ul>
            <li>
              For det første kan der blive lukket for el, vand og varme, hvilket ingen af
              os ønsker.
            </li>
            <li>
              For det risikere du at blive smidt ud af lejligheden. Dette kan enten ske på
              fredelig vis, eller ved at politiet ankommer sammen med en låsesmed og
              omkoder din lås på hoveddøren.
            </li>
            <li>
              For det tredje risikerer du at blive registreret i RKI, hvilket gør livet
              besværligit, da alle dine udgifter fremover skal forudbetales, fordi ingen
              tør at give dig kredit.
            </li>
          </ul>
          <p>
            Hvis du ikke ønsker nogle af de tre ovenstående scenarier, kan det være
            relevant at låne til huslejen som en midlertidig løsning.
          </p>
        </div>

        <div class="text-container">
          <h2 id="lav-en-aftale-med-din-udlejer">Lav en aftale med din udlejer</h2>
          <p>
            Hvis det er muligt, og du ved at du snart får penge på kontoen, så kan du evt.
            overveje at lave en aftale med din udlejer om at udskyde din husleje til næste
            måned. På denne måde undgår at låne penge til at betale husleje.
          </p>
          <p>
            Det vil med stor sandsynlighed være en udskydelse af problemet, og behovet for
            at låne til huslejen er derfor også blot udskudt til måneden efter, hvor du
            vil stå i samme situation, med mindre at der er en stor pose penge i vente.
          </p>
          <p>
            Uanset om du ønsker, og har mulighed for, at låne til huslejen, vil det være
            en god ide at tage fat i din udlejer og forklare ham/hende problemet. Hvis du
            forklarer din situation, vil din udlejer, alt andet lige, være mere tilbøjelig
            til at hjælpe dig, da du pænt spørger om hjælp frem for at skjule problemet.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvorfor-har-du-brug-for-at-laane-penge-til-huslejen">
            Hvorfor har du brug for at låne penge til huslejen?
          </h2>
          <p>
            Det kan være en god ide at spørge dig selv, hvorfor du har brug for at låne
            penge til huslejen. Det kan selvfølgelig være helt banalt, så som at du er
            blevet <strong>arbejdsløs</strong>, og endnu ikke har fundet et nyt job.
          </p>
          <p>
            I nogle tilfælde kan der ligge andre problemer til grund, så som manglenede
            overblik over hvad man har af udgifter og indtægter, impulsive køb eller
            steder i økonomien, hvor der evt. kan spares mange penge.
          </p>
          <p>
            Dette hjælper selvfølgelig ikke situationen nu, hvor der måske er behov for at
            låne penge til næste husleje. I fremtiden kan det dog blive en stor hjælp at
            se, om der er nogle bagvedliggende problemer, så du undgår igen at havne i
            situationen, hvor du har behov for at låne til huslejen.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
