<template>
  <div>
    <div class="flex-fill front-loan-select" style="width: 100%">
      <label class="monthly-rate" for="repayment_time">
        {{ $t('repayment_period') }}
      </label>
      <div class="loan-range row" style="flex-direction: row; justify-content: center">
        <input
          class="w-100 loanamount m-0"
          :update="update"
          @change="changeLoanPeriod"
          :read-only="true"
          inputmode="numeric"
          :value="loanTimeOptions[data.repayment_time - 1].text"
        />
      </div>

      <div class="time-range-dk">
        <div>
          <slider-input
            id="repayment_time"
            type="range"
            min="1"
            :value="data.repayment_time"
            :update="update"
            @click="update"
            aria-hidden="true"
            :max="loanTimeOptions.length"
            showLabel="false"
          ></slider-input>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <span class="min">61 {{ $t('days') }}</span>
        <span class="max">{{ loanTimeOptions.length + 1 }} {{ $t('months') }}</span>
      </div>
      <div class="row">
        <div class="form-group col-12 col-sm-6">
          <p>
            {{ $t('monthly_installment') }}
            <small>*</small>
            <br />
          </p>

          <div class="cost-field">
            <span class="small-cost" id="costMonthly">{{
              computedLoans.costMonthly.toFixed(0)
            }}</span>
            {{ $t('zl') }}
          </div>
        </div>
        <div class="form-group col-12 col-sm-6">
          <p>
            {{ $t('interest_and_commissions') }}
            <small>*</small>
            <br />
          </p>

          <div class="cost-field">
            <span class="small-cost" id="cost">{{ computedLoans.cost.toFixed(0) }}</span>
            {{ $t('zl') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gsap } from 'gsap'
import {
  getApproximateLoanValues,
  findMatchAmount,
  calculateInputRangeStep,
  setSliderColor,
} from '@/utils/utils'
import { loanTimeOptions, loanAmounts } from '@/utils/saverium_pl/const'
import Input from '@/components/form/Input'
export default {
  mounted() {
    this.createSlider()
  },
  beforeDestroy() {
    this.createSlider()
  },
  data() {
    return {
      costMonthly: 0,
      cost: 0,
      step: 100,
      current: 0,
      props: ['StepOne'],
      visible: false,
      amount: 0,
      amount_index: 0,
    }
  },
  components: {
    'slider-input': Input,
  },
  computed: {
    data() {
      const data = this.$store.state.application
      const valid_amount = findMatchAmount(this.$store.state.application.amount)
      this.setValues(valid_amount)
      return Object.assign(data, this.$data)
    },
    loanAmounts() {
      return loanAmounts
    },
    loanTimeOptions() {
      return loanTimeOptions
    },
    loanPurposeOptions() {
      return this.$store.state.enums.loanPurposeOptions
    },
    showFullHeader: function () {
      const path = this.$route.path
      return path === this.$root.rn.MainView
    },
    stepErrors() {
      return this.$store.state.application.stepErrors
    },
    computedLoans() {
      const delay = 0.2
      const computed = getApproximateLoanValues(
        this.$store.state.application.amount,
        this.$store.state.application.repayment_time
      )

      gsap.to(this.$data, { costMonthly: computed.costMonthly, duration: delay })
      gsap.to(this.$data, { cost: computed.cost, duration: delay })
      return this.data
    },
    tos() {
      return this.$store.state.application.tos
    },
  },

  methods: {
    setValues(valid_amount) {
      this.step = calculateInputRangeStep(valid_amount)
      this.amount = valid_amount
      this.amount_index = this.loanAmounts.indexOf(valid_amount)
    },
    update(key, value) {
      if (key === 'loanrange') {
        key = 'amount_index'
      }
      this.createSlider(value)
      this.$store.commit('updateApplication', { key, value })
    },
    changeLoanPeriod(event) {
      let value = event.target.value
      const key = 'repayment_time'
      if (value <= 120 && value >= 3) {
        this.$store.commit('updateApplication', { key, value })
      } else if (value > 120) {
        value = 120
        this.$store.commit('updateApplication', { key, value })
      } else if (value < 1) {
        value = 1
        this.$store.commit('updateApplication', { key, value })
      }
    },
    createSlider(value) {
      let val
      if (value) {
        val = value
      } else {
        val = this.data.repayment_time
      }

      setSliderColor({
        elementId: 'repayment_time',
        min: 1,
        max: this.loanTimeOptions.length,
        value: val,
        firstColor: 'rgb(5, 8, 60)',
        secondColor: 'rgb(90 93 144)',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.row {
  font-weight: 500;
}
.monthly-rate {
  font-size: 18px;
}
.loan-range input {
  border: none;
  line-height: 1;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  color: #05083c;
  text-align: center;
  background-repeat: repeat-x;
  border: 0;
  margin: 15px 0;
}
.loanamount {
  font-size: 46px;
  @media (max-width: 500px) {
    font-size: 36px;
  }
}
.small-cost {
  font-size: 1.8rem;
}
.time-range-dk {
  padding: 0.9rem;
}
.form-group {
  p {
    color: black;
    margin-bottom: 0;
  }
}
</style>
