<template>
  <div class="row article">
    <h1 class="center-title">
      Zdolność kredytowa – czym jest? Jak sprawdzić i poprawić zdolność kredytową?
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >Podstawowym warunkiem, jaki stawiają swoim klientom banki i firmy
              pożyczkowe przy udzielaniu zobowiązań jest posiadanie odpowiednio wysokiej
              zdolności kredytowej. Co też należy rozumieć przez pojęcia zdolność
              kredytowa lub zdolność pożyczkowa? Jak ją kształtować i czy są skuteczne
              sposoby na jej zwiększenie?
            </i>
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/credit-worthiness-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/credit-worthiness.jpg"
            alt="Zdolność kredytowa – czym jest? Jak sprawdzić i poprawić zdolność kredytową?"
          />

          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container mt-5">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            Definicję tego, co to jest zdolność kredytowa, znaleźć można w przepisach
            ustawy Prawo bankowe. W art. 70 tego aktu prawnego ustawodawca wskazuje, że
            bank uzależnia przyznanie zobowiązania od zdolności kredytowej wnioskującego.
            Kredytobiorca ma obowiązek na żądanie banku, w którym wnioskuje o kredyt,
            przedłożyć dokumenty i informacje niezbędne do dokonania oceny wspominanej
            zdolności.
          </p>
          <p>
            Bez wątpienia zdolność kredytowa może być z powodzeniem traktowana jako
            podstawowy parametr, który decyduje o szansach na uzyskanie wnioskowanego
            zobowiązania. Można ją również rozumieć jako możliwość spłaty potencjalnej
            raty kapitałowo-odsetkowej z dochodów, jakie kredytobiorca uzyskuje co miesiąc
            np. z tytułu pracy zawodowej. Jeśli klient ma zbyt niską zdolność kredytową
            albo nie ma jej wcale, nie może liczyć na wydanie pozytywnej decyzji. Z
            drugiej strony wysoka zdolność daje bankowi większą pewność, że zobowiązanie
            zaciągnięte przez kredytobiorcę zostanie spłacone w ustalonym terminie.
          </p>
          <p>
            Można traktować zdolność kredytową jako możliwości kredytowe, które wskazują
            klientowi, o jaką maksymalną kwotę zobowiązania może on wnioskować w danym
            banku.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Warto wiedzieć, jak banki czy firmy pożyczkowe podchodzą do kwestii badania
            zdolności kredytowej. Każda instytucja może mieć nieco inne zasady jej
            wyliczania. Algorytmy i programy mogą brać pod uwagę wiele różnych kryteriów.
            Dlatego nie jest powiedziane, że jeśli jeden bank odmówi udzielenia
            zobowiązania z uwagi na zbyt niską zdolność kredytową, to inny, przy takiej
            samej zdolności finansowej wnioskującego, nie zdecyduje się na jego
            przyznanie. Banki nie ujawniają swojej metodologii i nie mówią klientom, jak
            liczona jest owa zdolność.
          </p>
          <p>
            W uproszczeniu można powiedzieć, że zdolność kredytową wyznacza różnica
            pomiędzy miesięcznymi wpływami, jakie notuje dana osoba czy przedsiębiorstwo a
            miesięcznymi kosztami. Wynik tego działania oznacza, ile klient może
            maksymalnie przeznaczyć na ratę kapitałowo-odsetkową nowego kredytu, choć nie
            wszystkie dochody do jego dyspozycji mogą zostać spożytkowane. Musi pozostać
            kwota do dowolnego wykorzystania, pomniejszająca zdolność kredytową.
          </p>
          <p>
            Prócz dochodów i wydatków miesięcznych banki i firmy pożyczkowe przy
            szacowaniu zdolności kredytowej biorą też pod uwagę wybrany przez klienta
            okres spłaty kredytu, wysokość oprocentowania, typ rat czy nawet stan cywilny,
            wykonywany zawód i fakt posiadania samochodu. Banki i część firm pożyczkowych
            oceny zdolności kredytowej dokonują w korespondencji do informacji pobranych z
            Biura Informacji Kredytowej dotyczących sytuacji kredytowej – aktualnej i
            przeszłej klienta.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Można spróbować samodzielnie zweryfikować zdolność kredytową, przyglądając się
            swoim dochodom i wydatkom. Warto posłużyć się także kalkulatorem zdolności
            kredytowej, jaki można znaleźć w internecie. Uwzględnia on zwykle takie
            parametry jak:
          </p>
          <ul>
            <li>łączny dochód netto,</li>
            <li>raty innych kredytów,</li>
            <li>wysokość limitu na kartach kredytowych,</li>
            <li>koszty życia,</li>
            <li>liczba osób w gospodarstwie domowym.</li>
          </ul>
          <p>
            Na tej podstawie szybko można wyliczyć zdolność kredytową osób fizycznych.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Istnieje wiele czynników wpływających na ostateczną wysokość zdolności
            kredytowej. Wśród nich wymienia się:
          </p>
          <ul>
            <li>
              Wysokości dochodów uzyskiwanych przez klienta – liczą się dochody stałe,
              wpływające co miesiąc.
            </li>
            <li>Pula posiadanych oszczędności.</li>
            <li>
              Rodzaj źródła dochodów – preferowana jest umowa o pracę zawarta na czas
              nieokreślony – jest ona lepiej oceniana niż umowa cywilnoprawna czy
              prowadzona przez wnioskodawcę działalność gospodarcza, pomimo możliwości
              osiągania wyższych dochodów z tego tytułu.
            </li>
            <li>
              Historia kredytowa – pozyskana w formie raportu i scoringu BIK (Biura
              Informacji Kredytowej).
            </li>
            <li>
              Wielkość gospodarstwa domowego – dla banków znaczenie ma stan cywilny
              klienta oraz liczba osób, które są na jego utrzymaniu.
            </li>
            <li>
              Status zawodowy – jeśli wnioskodawca o kredyt lub pożyczkę pozabankową jest
              bezrobotny, ma rzecz jasna mniejsze szanse na zobowiązanie finansowe. Banki
              zwracają uwagę na zdobyte wykształcenie i zajmowaną pozycję zawodową, jak i
              na fakt wykonywania tzw. zawodu zaufania publicznego.
            </li>
            <li>
              Parametry związane z kredytem – wysokość kredytu, okres kredytowania,
              zabezpieczenia spłaty, typ rat (stałe lub malejące), wysokość
              oprocentowania.
            </li>
          </ul>
          <p>
            Poszczególne czynniki w różnym stopniu mogą wpływać na wyliczenia związane z
            indywidualną zdolnością kredytową klienta.
          </p>
        </div>
        <div class="text-container mt-5">
          <p>
            Potencjalni kredytobiorcy jeszcze przed złożeniem wniosku o
            <b-link :to="$root.rn.CashLoan">kredyt gotówkowy</b-link>, kredyt hipoteczny,
            a nawet o <b-link :to="$root.rn.CashLoan">pożyczkę online</b-link> mogą zadbać
            o zwiększenie swojej szansy na pozytywną odpowiedź od banku Wystarczy, że
            zadbają o wysokość swojej zdolności kredytowej. Na jej zwiększenie wpływają:
          </p>
          <ul>
            <li>
              brak innych zobowiązań, zwłaszcza w dużej kwocie czy przeterminowanych;
            </li>
            <li>redukcja stałych wydatków;</li>
            <li>
              zatrudnienie na podstawie umowy o pracę zawartej na czas nieokreślony;
            </li>
            <li>
              zamknięcie ewentualnych limitów debetowych i kredytowych na koncie czy
              rezygnacja z karty kredytowej.
            </li>
          </ul>
          <p>
            W ramach przygotowywania się do zaciągnięcia kredytu w banku czy
            <b-link :to="$root.rn.OnlineNonbankLoans">pożyczki pozabankowej</b-link>
            warto przeanalizować swoje finanse. Należy podliczyć dochody i zobowiązania,
            aby wskazać największe stałe obciążenia i spróbować je zredukować. Korzystnie
            na zdolność kredytową wpłynie zwiększenie zarobków, można np. poprosić szefa o
            podwyżkę. Nie jest najlepszym pomysłem, by tuż przed wnioskowaniem o kredyt
            zmieniać pracę, nawet na lepiej płatną, ponieważ bank przy wyliczaniu
            zdolności weźmie pod uwagę zarobki z co najmniej trzech ostatnich miesięcy.
            Jeśli już klient chce znaleźć inne miejsce zatrudnienia, to powinno mu ono
            przynieść wyższe wynagrodzenie i stałe źródło dochodów. Później wystarczy
            odczekać kilka miesięcy i złożyć wniosek kredytowy.
          </p>
          <p>
            Banki przy szacowaniu zdolności biorą też pod uwagę historię kredytowania w
            BIK-u. Dobrze by było, aby znalazły się tam pozytywne wpisy, świadczące o tym,
            że bank ma do czynienia z klientem rzetelnie wywiązującym się ze swoich
            zobowiązań. Każda niespłacona wierzytelność wpłynie ujemnie na zdolność.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            Indywidualna, samodzielna ocena zdolności kredytowej pozwoli znaleźć sposób na
            jej podwyższenie, a więc również na zwiększenie swojego potencjału
            kredytowego. Dzięki takiej analizie można wskazać słabe miejsca, np. brak
            wpisów w BIK-u czy wysokie wydatki miesięczne i nieregularne dochody. Później
            nie pozostaje nic innego, jak zredukować kwestie ujemnie wpływające na
            wysokość zdolności kredytowej.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            Warto samodzielnie zweryfikować swoją zdolność kredytową przed złożeniem
            wniosku o zobowiązanie, a to dlatego, że przyniesie to odpowiedź na pytanie,
            na jak wysoki kredyt czy pożyczkę można liczyć. Złożenie wniosku o kredyt w
            kwocie dalece przekraczającej indywidualną zdolność najprawdopodobniej
            spowoduje, że zostanie on z miejsca odrzucony.
          </p>
          <p>
            Z reguły zdolność kredytową mogą określić doradcy na podstawie algorytmów,
            jakimi posługują się takie firmy i ich pracownicy.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="7">{{ contents[6].title }}</h2>
          <p>
            Banki muszą dbać o jakość swojego portfela, dlatego przed udzieleniem
            zobowiązania każda instytucja bankowa sprawdza zdolność kredytową klienta.
            Muszą to robić ze względu na Prawo bankowe i Rekomendację T wydaną przez
            Komisję Nadzoru Finansowego. Zgodnie z jej ustaleniami, banki mają swobodę w
            określaniu, jak wysoki może być wskaźnik obciążeń kredytowych do dochodów
            netto klienta, choć przed nowelizacją owej rekomendacji było to zaledwie 50–65
            proc. Banki muszą sprawdzać zdolność ubiegających się o kredyty, ale mogą to
            robić w ramach tzw. uproszczonej procedury oceny.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="8">{{ contents[7].title }}</h2>
          <p>
            Jeśli klient uzyskuje dochody miesięczne z tytułu prowadzonej przez siebie
            działalności gospodarczej, również ma szansę na otrzymanie pozytywnej decyzji.
            Aby wyliczyć zdolność przedsiębiorcy, bank przeanalizuje kondycję finansową, w
            jakiej w ostatnich miesiącach znajdowała się jego firma. Do banku trzeba
            będzie dostarczyć wszystkie pożądane dokumenty obrazujące przepływ finansowy
            między firmą a podmiotami zewnętrznymi. Ważna jest stabilna zdolność
            kredytowa, a działalność gospodarcza nie jest przeszkodą dla uzyskania
            zobowiązania.
          </p>
        </div>
      </div>

      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  data() {
    return {
      contents: [
        {
          title: 'Co to jest zdolność kredytowa?',
          link: '#1',
        },
        {
          title: 'Jak liczona jest zdolność kredytowa?',
          link: '#2',
        },
        {
          title: 'Jak sprawdzić zdolność kredytową?',
          link: '#3',
        },
        {
          title: 'Od czego zależy zdolność kredytowa?',
          link: '#4',
        },
        {
          title: 'Ocena zdolności kredytowej',
          link: '#5',
        },
        {
          title: 'Sprawdzenie zdolności kredytowej',
          link: '#6',
        },
        {
          title: 'Dlaczego zdolność kredytowa ma takie znaczenie?',
          link: '#7',
        },
        {
          title: 'Zdolność kredytowa a działalność gospodarcza',
          link: '#8',
        },
      ],
    }
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
<style lang="scss" scoped></style>
