<template lang="html">
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">{{ prepend }}</span>
    </div>
    <input
      class="form-control"
      :class="error ? 'is-invalid' : ''"
      type="text"
      :value="_value"
      @change="input"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
export default {
  props: ['value', 'error', 'regex', 'placeholder', 'prepend'],
  computed: {
    _value() {
      if (this.value.startsWith(this.prepend)) {
        return this.value.slice(this.prepend.length)
      }
      return this.value
    },
  },
  methods: {
    input(event) {
      let value = event.target.value

      if (value.startsWith(this.prepend)) {
        value = value.slice(this.prepend.length)
      }

      if (this.prepend + value !== this.value) {
        this.$emit('input', this.prepend + value, this.validate(value))
      }
    },
    validate(value) {
      return value.match(new RegExp(this.regex))
    },
  },
}
</script>

<style lang="sass" scoped></style>
