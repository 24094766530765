<template>
  <div class="row col-12" style="flex-flow:column">
    <h3>{{ $t('related_articles') }}</h3>
    <b-card-group deck class=" col-12">
      <div
        class="col-12 col-sm-6 col-md-4 col-xl-3"
        v-for="(article, index) in articles"
        :key="index"
      >
        <b-card no-body class="overflow-hidden  related-cards">
          <b-card-img
            :src="article.src"
            blank-color="#abc"
            :alt="article.title"
            img-top
          ></b-card-img>
          <b-card-body>
            <b-card-title class="small">
              <b-link :to="article.url">
                {{ article.title }}
              </b-link>
            </b-card-title>
            <p class="small">
              <i>{{ article.date }}</i>
            </p>
            <b-card-text class="truncate">
              <p class="small mb-1">
                {{ article.description }}
              </p>
            </b-card-text>
          </b-card-body>
          <template class="footer" #footer>
            <b-link :to="article.url">
              <em
                >{{ $t('continue_reading') }}
                <i class="fal fa-chevron-double-right" style="font-size: 0.8rem"></i
              ></em>
            </b-link>
          </template>
        </b-card>
      </div>
    </b-card-group>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  computed: {
    articles() {
      return _.sampleSize(
        this.$store.state.articles.articles.filter(article => article.src),
        4
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.small {
  a {
    text-decoration: none !important;
    color: #05083c;
    background-size: 0 100%;

    &:hover {
      background-image: linear-gradient(transparent calc(100% - 1px), #05083c 1px);
      background-size: 100% 100%;
      background-position: 100%;
      position: relative;
      background-repeat: no-repeat;
      transition: all 0.2s;
    }
  }
}
</style>
