<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån {{ loanamount }} kr.</h1>
      <div class="top-text text-container">
        <p>
          Uanset lånets tiltænkte formål, er der adskillige fordele forbundet med at
          optage lånet på nettet. Fælles for de mange onlinebaserede lånemuligheder er, at
          det er nemt og bekvemt at optage lånet.
        </p>
        <p>
          Efterspørgslen efter online lån har været stigende i de seneste år. Dette har
          resultere i et større udbud af onlinebaserede låneprodukter, der adskiller sig
          på flere punkter. Eksempelvis har flere långivere introduceret muligheden for
          afdragsfrihed, rentefrie lån, lang løbetid m.m. Ønsker du at optage et lån på
          {{ loanamount }} kr., har du derfor rig mulighed for dette.
        </p>
        <p>
          Dette er selvfølgelige ensbetydende med, at dine muligheder ikke er begrænset
          til et banklån. Ønsker du en nem og hurtig låneprocessen, så undgå banken, og
          lån
          {{ loanamount }} kr. på nettet hos én af de utallige lånevirksomheder.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul v-for="(text, i) in contents" :key="i">
                <li>
                  <a :href="text.link">
                    {{ text.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <table class="loan-table">
            <thead>
              <td>
                <b>{{ $t('loan') }}</b>
              </td>
              <td>
                <b>{{ $t('repayment_period') }}</b>
              </td>
              <td>
                <b>{{ $t('per_month') }}</b>
              </td>
              <td>
                <b>{{ $t('apply_for_a_loan') }}</b>
              </td>
            </thead>
            <tbody>
              <tr v-for="(time, i) of loantime" :key="i">
                <td>{{ loanamount }} kr.</td>
                <td>{{ time }} {{ $t('year') }}</td>
                <td>{{ computedLoans[i] }} kr.</td>
                <td>
                  <b-link :to="$root.rn.LoanView" @click="setLoan(loanamount, time)"
                    >{{ $t('get_a_loan') }} »</b-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-container">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            At låne {{ loanamount }} kr. kan virke som en besværlig proces, da der er
            mange lånevirksomheder at vælge mellem. Af denne grund er det ikke nemt at
            finde ud af, hvilken af de mange muligheder, der er den rette. Hos Saverium.dk
            har vi heldigvis gjort processen lettere, hurtigere og mere gennemskuelig, så
            du kan låne {{ loanamount }} kr. nemt. Vi tilbyder, at du kan indhente flere
            lånetilbud baseret på en enkelt låneansøgning kombineret med vores låne
            beregner. Baseret på din ansøgning udarbejder hver lånevirksomhed et
            personligt og individuelt lånetilbud. Alle lånetilbud bliver præsenteret på én
            side, hvilket simplificerer sammenligningen af de modtagne lånetilbud.
          </p>
          <p>
            Hele processen foregår derfor online, ligesom du modtager svar på din
            ansøgning med det samme. Kort efter din ansøgning er indsendt, kan du derfor
            sætte din endelige underskrift på et af de modtagne tilbud. Her kan du altså
            finde et hurtig lån, og stadig låne penge med lav rente – og også låne
            {{ loanamount }} kr. med lav rente. Finder du ingen af de modtagne tilbud
            tiltalende, er du ikke forpligtet til at acceptere ét af dem. Derfor er der
            ingen risiko forbundet med at indhente tilbud på et lån på
            {{ loanamount }} kr. på Saverium.dk. Hvis du har et ønske om at låne
            {{ loanamount }} kr. billigt, bør du bruge Saverium.dk til at indhente dine
            tilbud – det er ikke blot nemt og effektivt, men også gratis. Her kan du låne
            penge og få dem med det samme.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Der er et utal af fordele forbundet med at optage et lån på nettet. En af de
            markante fordele er, at der ikke er krav om sikkerhedsstillelse. Ønsker du at
            låne penge i et traditionelt pengeinstitut, slipper du sjældent for at stille
            en sikkerhed for det respektive lån. Bankerne kræver som regel, at du stiller
            bil, bolig eller andre værdier som sikkerhed for lånet. Er du ikke i stand til
            at betale de månedlige afdrag, kan banken kræve, at den eller de pantsatte
            værdier sælges, med henblik på at indfri dit kredit lån nu og her.
          </p>
          <p>
            Ønsker du at minimere risikoen forbundet med at optage et lån, er det derfor
            fordelsagtigt at optage dit lån på {{ loanamount }} kr. uden sikkerhed på
            nettet. Derved er der ingen fare for, at du skal sælge eller aflevere dine
            værdier, såfremt lånet misligholdes. Under alle omstændigheder er det
            naturligvis essentielt at vurdere din privatøkonomiske situation, før et lån
            optages. Du bør nemlig så vidt muligt garantere, at du har råd til lånet. Læg
            derfor et grundigt budget, der viser dit månedlige rådighedsbeløb, og vurder
            dine lånemuligheder ud fra det.
          </p>
          <h3>Du skal kompensere for långivernes øgede risiko</h3>
          <p>
            Da långiverne påtager sig en større risiko, ved ikke at kræve, at du skal
            stille en sikkerhed for dit lån, skal de kompenseres for øgede risiko på anden
            vis. Det sker som regel ved øgede rente- og gebyromkostninger, hvilket man som
            låntager skal være indstillet på. Flere onlinebaserede lånevirksomheder er dog
            i skarp konkurrence med de traditionelle banker i forhold til renteniveauet.
            Tiden hvor det var markant dyrere at låne penge på nettet, er derfor ovre.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Ønsker du at låne {{ loanamount }} kr., ønsker du sandsynligvis at låne
            pengene billigt. Derfor ønsker de fleste at have et kendskab til, hvad koster
            det at låne pengene, før de beslutter sig. Til trods for, at vi gerne vil give
            et konkret svar på dette, er det desværre ikke muligt. Når du optager et
            online lån, bliver dine lånetilbud udarbejdet specifikt til dig. Derfor
            beregnes såvel rente- som gebyrsatser individuelt fra tilbud til tilbud. Husk,
            at du kan beregne lån med Saverium.dk´s låneberegner.
          </p>
          <p>
            Tilbuddene udregnes og udarbejdes med udgangspunkt i dine personlige
            informationer. Her tages der højde for din job- og indkomstmæssige situation,
            ønsket lånebeløb samt ønsket løbetid. Netop som følge af de mange variabler,
            der har indflydelse på lånetilbuddene, er det ikke muligt at give et konkret
            svar på, hvad det koster at låne {{ loanamount }} kr. Ønsker du at vide, hvad
            det vil koste at optage et hurtigt lån på nettet, er du derfor nødt til at
            indhente personlige lånetilbud.
          </p>
          <h3>Renteomkostninger er ikke de eneste omkostninger</h3>
          <p>
            Det er nærliggende at tro, at du blot skal undersøge lånets rentesats, hvis du
            skal vurdere lånets pris. Realiteten er dog en anden. Renteomkostninger er
            ikke de eneste omkostninger, men blot én blandt flere omkostninger, der er
            forbundet med at optage et privat lån. Fokuserer man udelukkende på renten,
            vil lånemarkedet pludselig indeholde mange billige lån. Desværre forholder det
            sig ikke helt således. Netop derfor skal du i stedet holde øje med lånets ÅOP
            (Årlige Omkostninger i Procent), når du skal vurdere omkostningsniveauet. Vær
            dog opmærksom på, at ÅOP'en viser omkostninger på årlig basis. Særligt ved lån
            med kort løbetid kan det derfor virke skræmmende højt. Dette er dog ikke
            problematisk, da lånet netop skal afvikles på eksempelvis få måneder.
          </p>
          <h3>Længere løbetid resulterer i flere omkostninger</h3>
          <p>
            Et lån med længere løbetid kan virke appellerende, da den månedlige ydelse
            mindskes. Det forårsages naturligvis af, at den samlede tilbagebetaling
            fordeles over en længere periode. Ganske vist er den månedlige ydelse mindre,
            men der er blot flere af dem. I og med, at der er flere rater, der skal
            betales, er der også flere rater, hvorpå der påløber renter og gebyrer. Det
            medfører, at lånets samlede omkostninger forøges ved længere løbetid. Tillader
            din økonomi det, anbefales det derfor altid at afvikle din gæld hurtigst
            muligt. Derved bliver det billigere at optage et kvik lån.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Går du til din bank for et lån nu til dags, vil det som regel indebære en
            række krav og betingelser:
          </p>
          <ul>
            <li>
              Personligt møde: Som regel er det påkrævet, at du aftaler et personligt møde
              med din bankrådgiver. Ved dette møde skal du typisk forklare årsagen til din
              låne forespørgsel, men også have medbringe dokumentation.
            </li>
            <li>
              Krav om sikkerhed: Et udbredt krav er, at du vil blive bedt om at stille
              værdier som sikkerhed for dit lån. Derved sikrer banken sig, at lånet bliver
              tilbagebetalt.
            </li>
            <li>
              Dokumentation: I de fleste tilfælde kræver banken, at der indsendes
              yderligere dokumentation. Ofte er det dokumentation i form af årsopgørelse,
              budget og lønsedler.
            </li>
          </ul>
          <p>
            Beslutter du dig for at optage et lån i banken, skal du derfor forvente en
            længere og mere besværlig låneproces. Uanset lånebeløb og -formål tager det
            som regel flere dage, før du modtager dit lånetilbud. Ønsker du derimod at
            låne
            {{ loanamount }} kr. uden dokumentation let og bekvemt, er du bedre stillet
            ved at låne penge online hos en lånevirksomhed. Her er der du nemlig
            garanteret en hurtig og smertefri låneproces. Det er nemlig hverken en del af
            den gængse kutyme, at der skal aftales møde med en rådgiver, angives
            begrundelse for lånet samt indsendes dokumentation, som det er tilfældet i
            banken.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            En af de mest markante fordele ved at låne penge på nettet er, at hele
            processen foregår på internettet. Derfor er processen ikke blot simpel, men
            også hurtigt overstået så du kan nemt låne penge nu og her. Det er nemlig kun
            nedenstående trin, du skal igennem:
          </p>
          <ol>
            <li>
              Første skridt er at udfylde en online låneansøgning på långiverens
              hjemmeside
            </li>
            <li>Herefter underskriver du din ansøgning med dit NemID</li>
            <li>
              Har du modtaget et lånetilbud, du ønsker at acceptere, skal du blot
              underskrive låneaftale med dit NemID
            </li>
          </ol>
          <p>
            Det vidner om, at låneprocessen næppe bliver meget nemmere. Du er i hvert fald
            garanteret, at et banklån er betydeligt mere omstændeligt.
          </p>
          <h3>Hvorfor bruges NemID til ansøgning af lån?</h3>
          <p>
            Når du ansøger om et lån på nettet, er det et krav, at du underskriver med dit
            NemID, men hvorfor bruges NemID til ansøgning af et lån? Der er flere årsager
            til, at det i dag er et krav for hovedparten af de lånevirksomheder, der er
            aktive på det danske marked.
          </p>
          <p>
            Først og fremmest skyldes det, at långiverne derved kan sikre, at du er, hvem
            du udgiver dig for at være. Ligeledes får låneudbyderen mulighed for
            automatisk at indhente de relevante kreditoplysninger fra SKAT. Kun i få
            tilfælde er det ikke muligt at gennemføre hele processen på nettet. Enkelte
            långivere eller låneprodukter kræver en udvidet ansøgningsformular, men det er
            yderst sjældent.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            Ganske vist er en onlinebaseret låneproces forholdsvis simpel at gennemføre,
            men dette er ikke ensbetydende med, at du blot skal vælge et vilkårligt lån.
            For at garantere, at du opnår en billig låneaftale, er det essentielt, at du
            tager dig tid til at sammenligne forskellige lånemuligheder. Netop derfor er
            det også vigtigt at sammenligne lånemuligheder, før du skriver under fra flere
            forskellige lånevirksomheder.
          </p>
          <p>
            At sammenligne forskellige lånetilbud er den eneste måde, hvorpå du kan
            garantere, at du sætter din underskrift på et fornuftigt lånetilbud. Har du
            ikke flere lånetilbud at sammenligne, har du ikke noget at tage udgangspunkt
            i, når du skal vurdere, hvorvidt et givent lånetilbud er fornuftigt.
          </p>
          <h3>Ikke tilstrækkeligt at sammenligne ud fra rentesats</h3>
          <p>
            Mange uerfarne låntagere er ikke bekendt med, at der er mange omkostninger
            forbundet med at optage et lån op til {{ loanamount }} kr. Det vil sige, at
            det ikke tilstrækkeligt at sammenligne ud fra rentesatsen, der har relevans,
            når du skal sammenligne flere forskellige lånetilbud for at finde frem til det
            billigste lån. Heldigvis er alle digitale långivere forpligtet til at skilte
            tydeligt med lejeaftalens ÅOP. Derfor har du altid adgang til et tal, der
            medfører, at sammenligningen af de modtagne lånetilbud er nemt
          </p>
          <p>
            Når du skal sammenligne flere lånetilbud med hinanden, og du har i sinde at
            bruge ÅOP til dette, er det yderst vigtigt, at løbetid og lånebeløb er ens for
            alle tilbud. Er de ikke det, er de nemlig ikke direkte sammenlignelige. I og
            med, at det ikke blot er den mest overskuelige, men også mest effektive måde,
            hvorpå du kan sammenligne forskellige lånetilbud, anbefales det naturligvis at
            bruge denne løsning.
          </p>
          <p>
            Dette er ensbetydende med, at du skal sikre, at samtlige lånetilbud er ens i
            forhold til lånebeløb og løbetid. Indhenter du dine lånetilbud via
            Saverium.dk, er det selvfølgelig nemt at sikre, da dette blot specificeres i
            forbindelse med ansøgningsprocessen.
          </p>
        </div>
        <div class="text-container">
          <h2 id="7">{{ contents[6].title }}</h2>
          <p>
            I dag er det ikke muligt at låne {{ loanamount }} kr., såfremt du er
            registreret som dårlig betaler i RKI. Hverken banker eller onlinebaserede
            låneudbydere bevilliger almindelige lån eller quick lån til dårlige betalere.
            Derfor trods RKI er det ikke muligt at lån {{ loanamount }} kr.
          </p>
          <p>
            Vil du gøre dig forhåbninger om at optage et lån på {{ loanamount }} kr.
            kroner, er du derfor nødt til at få din RKI-registrering slettet. I denne
            forbindelse har du to muligheder:
          </p>
          <ol>
            <li>
              Du kan afbetale den gæld, der er skyld i, at du er blevet registreret.
            </li>
            <li>Du kan vente fem år, hvorefter din registrering automatisk slettes.</li>
          </ol>
          <p>
            Der er naturligvis intet, der forhindrer dig i at forsøge at ansøge, til trods
            for en registrering i RKI. Du skal dog ikke sætte forventningerne for højt, da
            de mange långivere ikke ønsker at løbe risikoen, der er forbundet med at
            bevillige et lån til en RKI-registreret person.
          </p>
        </div>
        <div class="text-container">
          <h2 id="8">{{ contents[7].title }}</h2>
          <p>
            Fordelene forbundet med at optage et {{ loanamount }} kr. lån på nettet er
            mange. Dette bør der ingen tvivl herske om, og faktisk kan du nemt finde et
            billig lån på {{ loanamount }} kr. Foruden långivernes manglende krav om
            sikkerhedsstillelse og dokumentation, er der ligeledes ingen krav om, at du
            redegør for, hvad lånet skal bruges til. Forsøger du at optage et
            {{ loanamount }} kr. lån i en traditionel bank, vil du opleve, at det er
            påkrævet, at du dokumenterer, hvad lånets tiltænkte formål er.
          </p>
          <p>
            Det vil sige, at du ikke er begrænset i forhold til lånets formål. Hvad enten
            du ønsker at spendere pengene på en luksuriøs ferie eller nye møbler til
            stuen, kan du frit gøre dette. Ønsker du at undgå, at din bankrådgiver skal
            snage i, hvad du ønsker at låne penge til, så lån {{ loanamount }} kr. billigt
            nu på nettet til lige, hvad du drømmer om. Ingen af de onlinebaserede
            lånevirksomheder kræver, at du i forbindelse med låneansøgningen vedlægger en
            redegørelse for dets tiltænkte formål.
          </p>
          <p>
            Når pengene er indbetalt på din bankkonto, er du derfor i din gode ret til at
            spendere pengene, som du vil. Lån penge hurtigt på nettet – det er nemt og
            foregår uden problemer.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  props: ['loanamount'],
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    loantime() {
      let i = 0
      const loantime = []
      for (i = 1; i <= 20; i++) {
        loantime.push(i)
      }
      return loantime
    },
    computedLoans() {
      const computedLoans = []
      let monthlyPayment
      let i = 0
      for (i = 1; i <= 20; i++) {
        let time = i
        const amount = parseInt(this.loanamount, 10)
        time = time * 12
        const annualRate = 0.0719 / 12
        monthlyPayment = this.pmt(annualRate, time, amount).toFixed(0)
        computedLoans.push(monthlyPayment)
      }
      return computedLoans
    },
    contents() {
      return [
        {
          title: `Lån ${this.loanamount} kr. nemt og bekvemt med Saverium.dk`,
          link: '#1',
        },
        {
          title: `Lån ${this.loanamount} kr. uden sikkerhed på nettet`,
          link: '#2',
        },
        {
          title: `Hvad koster et lån på ${this.loanamount} kr.?`,
          link: '#3',
        },
        {
          title: `Lån ${this.loanamount} kr. uden dokumentation – lån let og bekvemt`,
          link: '#4',
        },
        {
          title: 'Lån penge nu – Så nemt er det at låne penge med NemID',
          link: '#5',
        },
        {
          title: 'Vigtigt at sammenligne lånemuligheder, før du skriver under',
          link: '#6',
        },
        {
          title: `Lån ${this.loanamount} kr. trods RKI – kan det lade sig gøre?`,
          link: '#7',
        },
        {
          title: `Lån ${this.loanamount} kr. billigt til lige, hvad du drømmer om`,
          link: '#8',
        },
      ]
    },
  },
  methods: {
    pmt(rate, number_of_periods, present_value, future_value = 0, type = 0) {
      var q = Math.pow(1 + rate, number_of_periods)
      return (rate * (future_value + q * present_value)) / ((-1 + q) * (1 + rate * type))
    },
    setLoan(value, year) {
      const key = 'amount'
      this.$store.commit('updateApplication', { key, value })
      this.$store.commit('updateApplication', { key: 'repayment_time', value: year })
    },
  },
}
</script>
