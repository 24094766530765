<template lang="html">
  <div class="mb-5" ref="header">
    <h2 v-if="fullView" class="mb-3 text-center">
      {{ step }}.
      {{ $t('credit_obligations_and_other_debts') }}
    </h2>
    <h1 v-else class="mb-3 text-center">
      {{ $t('credit_obligations_and_other_debts') }}
    </h1>

    <div class="d-md-none mb-3">
      <div class="row mt-4">
        <div class="col-12">
          <h4>{{ $t('mortgage') }}</h4>
        </div>
        <div class="col-12">
          <form-input
            type="numeric"
            placeholder="0"
            storekey="existingLoans.mortgage.toBePaid"
            currency="zł"
            separator="space"
          >
            {{ $t('amount_to_be_repaid') }}
          </form-input>
        </div>
        <div class="col-12 mt-4">
          <form-input
            type="numeric"
            placeholder="0"
            storekey="existingLoans.mortgage.monthlyPayment"
            currency="zł"
            separator="space"
          >
            <p style="{word-wrap: ellipsis; overflow: hidden;}">
              {{ $t('debt_reduction_per_month') }}
            </p>
          </form-input>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <h4>{{ $t('consumer_loans') }}</h4>
        </div>
        <div class="col-12">
          <form-input
            type="numeric"
            placeholder="0"
            storekey="existingLoans.mortgage.toBePaid"
            currency="zł"
            separator="space"
          >
            {{ $t('amount_to_be_repaid') }}
          </form-input>
        </div>
        <div class="col-12 mt-4">
          <form-input
            type="numeric"
            placeholder="0"
            storekey="existingLoans.mortgage.monthlyPayment"
            currency="zł"
            separator="space"
          >
            {{ $t('debt_reduction_per_month') }}
          </form-input>
        </div>
        <div class="col-12 mt-4">
          <form-input
            type="checkbox"
            storekey="existingLoans.consumerLoan.considerConsolidation"
          >
            {{ $t('i_want_to_apply_for_this_loan') }}
          </form-input>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <h4>{{ $t('other_debts') }}</h4>
        </div>
        <div class="col-12">
          <form-input
            type="numeric"
            placeholder="0"
            storekey="existingLoans.mortgage.toBePaid"
            currency="zł"
            separator="space"
          >
            {{ $t('amount_to_be_repaid') }}
          </form-input>
        </div>
        <div class="col-12 mt-4">
          <form-input
            type="numeric"
            placeholder="0"
            storekey="existingLoans.mortgage.monthlyPayment"
            currency="zł"
            separator="space"
          >
            {{ $t('debt_reduction_per_month') }}
          </form-input>
        </div>
        <div class="col-12 mt-4">
          <form-input
            type="checkbox"
            storekey="existingLoans.consumerLoan.considerConsolidation"
          >
            {{ $t('i_want_to_apply_for_this_loan') }}
          </form-input>
        </div>
      </div>
    </div>

    <table class="d-none d-md-table table">
      <thead class="">
        <tr>
          <th>{{ $t('debt_details') }}</th>
          <th>{{ $t('amount_to_be_repaid') }}</th>
          <th>{{ $t('debt_reduction_per_month') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="align-middle">{{ $t('mortgage') }}</td>
          <td class="align-middle">
            <form-input
              type="numeric"
              hideLabel="true"
              placeholder="0"
              storekey="existingLoans.mortgage.toBePaid"
              id="mortgageToBePaid"
              currency="zł"
              separator="space"
            />
          </td>
          <td class="align-middle">
            <form-input
              type="numeric"
              placeholder="0"
              hideLabel="true"
              storekey="existingLoans.mortgage.monthlyPayment"
              id="mortgageMonthlyPayment"
              currency="zł"
              separator="space"
            />
          </td>
          <td class="align-middle"></td>
        </tr>
        <tr>
          <td class="align-middle">{{ $t('consumer_loans') }}</td>
          <td class="align-middle">
            <form-input
              type="numeric"
              hideLabel="true"
              storekey="existingLoans.consumerLoan.toBePaid"
              id="consumerLoanToBePaid"
              currency="zł"
              separator="space"
            />
          </td>
          <td class="align-middle">
            <form-input
              type="numeric"
              hideLabel="true"
              storekey="existingLoans.consumerLoan.monthlyPayment"
              id="consumerLoanMonthlyPayment"
              currency="zł"
              separator="space"
            />
          </td>
        </tr>
        <tr>
          <td class="align-middle">{{ $t('other_debts') }}</td>
          <td class="align-middle">
            <form-input
              type="numeric"
              hideLabel="true"
              storekey="existingLoans.otherDebts.toBePaid"
              id="otherDebtsToBePaid"
              currency="zł"
              separator="space"
            />
          </td>
          <td class="align-middle">
            <form-input
              type="numeric"
              hideLabel="true"
              storekey="existingLoans.otherDebts.monthlyPayment"
              id="otherDebtsMonthlyPayment"
              currency="zł"
              separator="space"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <b class="pl-2">{{ $t('which_of_these_credit_cards_you_have') }}</b>
    <div class=" credit-card-row mb-5 mt-2">
      <div class="col consent-checkbox custom-control custom-checkbox">
        <div class="form-check" v-for="(card, i) in creditCards" :key="i">
          <input
            class="custom-control-input"
            type="checkbox"
            :id="`creditCards.${card.text}`"
            :storekey="`creditCards.${card.text}`"
            :name="`creditCards.${card.text}`"
            :value="card.value"
            @change="updateInput"
          />
          <label :for="`creditCards.${card.text}`" class="custom-control-label">{{
            card.text
          }}</label>
        </div>
      </div>
    </div>

    <div class="row align-items-end mb-5">
      <div class="form-group col-sm-6">
        <form-input
          storekey="costOfLiving"
          type="numeric"
          separator="space"
          currency="zł"
          :customEvent="true"
          id="costOfLiving"
        >
          <label for="costOfLiving">{{ $t('monthly_expenses') }}</label>
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input
          storekey="otherMonthlyExpenses"
          type="numeric"
          id="otherMonthlyExpenses"
          currency="zł"
          separator="space"
        >
          <label for="otherMonthlyExpenses">{{ $t('other_costs') }}</label>
        </form-input>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from '@/components/form/FormInput'

export default {
  props: ['fullView', 'step'],
  components: {
    FormInput,
  },
  data() {
    return {
      requiredFields: ['costOfLiving'],
    }
  },
  computed: {
    stepErrors() {
      return this.$store.state.application.stepErrors
    },
    creditCards() {
      return this.$store.state.enums.creditCards
    },
  },
  watch: {
    stepErrors(newV) {
      if (this.fullView && newV[0] === parseInt(this.step, 10)) {
        this.$refs.header.scrollIntoView()
      }
    },
  },
  methods: {
    updateInput(event) {
      const {
        target: { name, value: _val },
      } = event
      let value = _val
      this.$store.commit('updateApplication', {
        key: name,
        value: value,
      })
      this.$store.dispatch('updateOrCreateDraft')
    },
  },
}
</script>

<style lang="scss" scoped>
.consent-checkbox {
  flex-direction: row;
  display: flex;
  @media (max-width: 770px) {
    display: inline;
  }
}

.form-check {
  padding-left: 1rem;
  padding-right: 3rem;

  @media screen and (max-width: 1200px) {
    padding-left: 2.5rem;
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
