<template>
  <div>
    <div class="row sliders col-12">
      <LoanAmountSlider
        currency="kr."
        separator="."
        :loanAmounts="smeLoanAmounts"
        :variant="variant"
        versionId="0"
      />
      <MonthlyAmount
        :loanTimeOptions="loanTimeOptions"
        :loanAmounts="smeLoanAmounts"
        :variant="variant"
        suffix="måneder"
        versionId="0"
      />
    </div>
    <div class="first-part row align-items-end">
      <b-alert
        class="alert alert-danger"
        role="alert"
        :show="submitStatus === 'error'"
        id="errorSending"
      >
        {{ $t('check_the_fields_marked_in_red') }}<br />
        <i>{{ $t('customer_service_phone_text') }}</i>
      </b-alert>
      <b-alert
        class="alert alert-danger"
        role="alert"
        :show="serverError"
        dismissible
        id="generalError"
      >
        <p>{{ $t('something_went_wrong_try_again_later') }}</p>
        <i>{{ $t('customer_service_phone_text') }}</i>
      </b-alert>
      <b-form-group
        class="col-12 col-md-6"
        id="business-name-group"
        :label="$t('company_name')"
        label-for="business-name"
      >
        <b-form-input
          id="business-name"
          v-model="$v.form.businessName.$model"
          :state="validateState('businessName')"
          :key="'businessName'"
          aria-describedby="business-name-live-feedback"
          @change="updateValue('businessName')"
        >
        </b-form-input>
        <b-form-invalid-feedback id="business-name-live-feedback"
          >{{ $t('required') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="col-12 col-md-6"
        id="business-id-group"
        :label="$t('business_id')"
        label-for="business-id"
      >
        <b-form-input
          id="business-id"
          v-model="$v.form.businessId.$model"
          :state="validateState('businessId')"
          aria-describedby="business-id-live-feedback"
          @change="updateValue('businessId')"
          @input="delayTouch($v.form.businessId)"
        >
        </b-form-input>

        <b-form-invalid-feedback id="business-id-live-feedback"
          >{{ $t('Invalid') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="col-12 col-md-6"
        id="street-address-group"
        :label="$t('contact_address')"
        label-for="street-address"
      >
        <b-form-input
          id="street-address"
          v-model="$v.form.streetAddress.$model"
          :state="validateState('streetAddress')"
          aria-describedby="street-address-live-feedback"
          @change="updateValue('streetAddress')"
        >
        </b-form-input>
        <b-form-invalid-feedback id="street-address-live-feedback"
          >{{ $t('field_is_required') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="col-12 col-md-6"
        id="postal-code-group"
        :label="$t('postal_code')"
        label-for="postal-code"
      >
        <b-form-input
          id="postal-code"
          v-model="$v.form.postalCode.$model"
          :state="validateState('postalCode')"
          aria-describedby="postal-code-live-feedback"
          type="number"
          @change="updateValue('postalCode')"
          @input="delayTouch($v.form.postalCode)"
        >
        </b-form-input>
        <b-form-invalid-feedback id="postal-code-live-feedback"
          >{{ $t('Invalid') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="col-12 col-md-6"
        id="city-group"
        :label="$t('city')"
        label-for="city"
      >
        <b-form-input
          id="city"
          v-model="$v.form.city.$model"
          :state="validateState('city')"
          aria-describedby="city-live-feedback"
          @change="updateValue('city')"
        >
        </b-form-input>
        <b-form-invalid-feedback id="city-live-feedback"
          >{{ $t('field_is_required') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="col-12 col-md-6"
        id="bank-account-group"
        :label="$t('contact_bank_account')"
        label-for="bank-account"
      >
        <b-form-input
          id="bank-account"
          v-model="$v.form.bankAccount.$model"
          :state="validateState('bankAccount')"
          aria-describedby="bank-account-live-feedback"
          @change="updateValue('bankAccount')"
          placeholder="fx. DK5000400440116243"
          @input="delayTouch($v.form.bankAccount)"
        >
        </b-form-input>
        <b-form-invalid-feedback id="bank-account-live-feedback"
          >{{ $t('Invalid') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="col-12 col-md-6"
        id="year-founded-group"
        :label="$t('year_founded')"
        label-for="year-founded"
      >
        <b-form-input
          id="year-founded"
          v-model="$v.form.yearFounded.$model"
          :state="validateState('yearFounded')"
          aria-describedby="year-founded-live-feedback"
          type="number"
          @change="updateValue('yearFounded')"
          @input="delayTouch($v.form.yearFounded)"
        >
        </b-form-input>
        <b-form-invalid-feedback id="year-founded-live-feedback"
          >{{ $t('Invalid') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        class="col-12"
        id="yearly-turnover-group"
        :label="$t('turnover')"
        label-for="yearly-turnover"
      >
        <VueNumeric
          id="yearly-turnover"
          :class="`form-control ${this.incomeClass}`"
          v-model="$v.form.turnover.$model"
          :state="validateState('turnover')"
          aria-describedby="yearly-turnover-live-feedback"
          type="tel"
          @change="updateValue('turnover')"
          currency-symbol-position="suffix"
          placeholder="0 Kr."
          currency="Kr."
        >
        </VueNumeric>
        <b-form-invalid-feedback id="yearly-turnover-live-feedback"
          >{{ $t('field_is_required') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="col-12">
        <h3 class="contact-person-title">{{ $t('contact_person') }}</h3>
      </div>
      <b-form-group
        class="col-12 col-md-6"
        id="first-name-group"
        :label="$t('first_name')"
        label-for="first-name"
      >
        <b-form-input
          id="first-name"
          v-model="$v.form.firstName.$model"
          :state="validateState('firstName')"
          aria-describedby="first-name-live-feedback"
          @change="updateValue('firstName')"
        >
        </b-form-input>
        <b-form-invalid-feedback id="first-name-live-feedback"
          >{{ $t('field_is_required') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="col-12 col-md-6"
        id="last-name-group"
        :label="$t('last_name')"
        label-for="last-name"
      >
        <b-form-input
          id="last-name"
          v-model="$v.form.lastName.$model"
          :state="validateState('lastName')"
          aria-describedby="last-name-live-feedback"
          @change="updateValue('lastName')"
        >
        </b-form-input>
        <b-form-invalid-feedback id="last-name-live-feedback"
          >{{ $t('field_is_required') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="col-12 col-md-6"
        id="ssn-group"
        :label="$t('ssn')"
        label-for="ssn"
      >
        <b-form-input
          id="ssn"
          v-model="$v.form.ssn.$model"
          :state="validateState('ssn')"
          aria-describedby="ssn-live-feedback"
          @change="updateValue('ssn')"
          @input="delayTouch($v.form.ssn)"
        >
        </b-form-input>
        <b-form-invalid-feedback id="ssn-live-feedback"
          >{{ $t('Invalid') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="col-12 col-md-6"
        id="contact-email-group"
        :label="$t('email')"
        label-for="contact-email"
      >
        <b-form-input
          id="contact-email"
          v-model="$v.form.contactEmail.$model"
          :state="validateState('contactEmail')"
          aria-describedby="contact-email-live-feedback"
          type="email"
          @change="updateValue('contactEmail')"
          @input="delayTouch($v.form.contactEmail)"
        >
        </b-form-input>
        <b-form-invalid-feedback id="contact-email-live-feedback"
          >{{ $t('Invalid') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="col-12 col-md-6"
        id="phone-group"
        :label="$t('phone_number')"
        label-for="phone"
      >
        <b-input-group prepend="+45">
          <b-form-input
            id="phone"
            v-model="$v.form.phone.$model"
            :state="validateState('phone')"
            aria-describedby="phone-live-feedback"
            input-type="tel"
            @change="updateValue('phone')"
            @input="delayTouch($v.form.phone)"
          >
          </b-form-input>
          <b-form-invalid-feedback id="phone-live-feedback"
            >{{ $t('Invalid') }}
          </b-form-invalid-feedback>
        </b-input-group>
      </b-form-group>
      <div class="row">
        <b-form-group>
          <b-form-checkbox
            id="tos"
            name="tos"
            v-model="$v.form.tos.$model"
            :state="validateState('tos')"
            @change="updateValue('tos')"
            aria-describedby="tos-live-feedback"
            class="sme-tos-checkbox"
            ><label for="tos"
              >{{ $t('i_accept') }}
              <b-link :to="$root.rn.SmeTosView" class="lowercase" target="_blank">{{
                $t('terms_conditions')
              }}</b-link>
              {{ $t('and') }}
              <b-link :to="$root.rn.PrivacyPolicyView">
                {{ $t('privacy_conditions') }}</b-link
              ></label
            >
          </b-form-checkbox>
          <b-form-invalid-feedback
            id="tos-live-feedback"
            :class="$v.form.tos.$error ? 'is-invalid-checkbox' : ''"
            >{{ $t('field_is_required') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div class="col-md-12 apply-button mt-4" style="text-align: right">
        <b-link
          v-if="!loading"
          @click="sendApplication"
          class="btn btn-primary btn-saverium btn-wide next-btn btn btn-front"
          id="apply"
        >
          {{ $t('get_a_loan') }}
        </b-link>
        <div class="spinner-wrapper" v-else>
          <div class="text-center">
            <b-spinner class="loading-spinner" label="Loading"></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, helpers, maxLength, email, sameAs } from 'vuelidate/lib/validators'
import { smeLoanAmounts, smeLoanTimeOptions } from '@/utils/saverium_dk/const'
import LoanAmountSlider from '@/components/LoanAmountSlider'
import MonthlyAmount from '@/components/saverium_dk/loan_amount_components/MonthlyAmount.vue'
import { validateCvr, validateCpr } from '@/utils/utils'
import { DateTime } from 'luxon'
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import VueNumeric from 'vue-numeric'

const isValidCPR = value => {
  return !validateCpr(value)
}
const isValidPostalCode = helpers.regex(
  'isValidPostalCode',
  /^(?:[1-24-9]\d{3}|3[0-8]\d{2})$/
)
const isIban = helpers.regex('isIban', /^DK[0-9]{16}$/)
const isValidCVR = value => {
  return validateCvr(value)
}
const yearIsInPast = value => {
  if (DateTime.fromFormat(value, 'yyyy').diffNow('years') <= 0) {
    return true
  } else {
    return false
  }
}

const isValidPhone = value => {
  if (value) {
    try {
      const phoneNumber = parsePhoneNumber(value, 'DK').number
      return isValidPhoneNumber(phoneNumber, 'DK')
    } catch (e) {}
  }
  return false
}

const touchMap = new WeakMap()

export default {
  props: { variant: String, versionId: String },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        ssn: '',
        yearFounded: '',
        streetAddress: '',
        postalCode: '',
        city: '',
        phone: '',
        turnover: '',
        businessName: '',
        contactEmail: '',
        bankAccount: '',
        businessId: '',
        tos: '',
      },
      submitStatus: null,
      errorList: [],
      incomeClass: '',
    }
  },
  components: {
    LoanAmountSlider,
    MonthlyAmount,
    VueNumeric,
  },
  computed: {
    smeLoanAmounts() {
      return smeLoanAmounts
    },
    loanTimeOptions() {
      return smeLoanTimeOptions
    },
    loading() {
      return this.$store.state.application.loading
    },
    smeErrors() {
      return this.$store.state.application.smeErrors
    },
    serverError() {
      return this.$store.state.application.serverError
    },
  },
  watch: {
    'form.firstName': function () {
      this.cleanError('firstName')
    },
    'form.lastName': function () {
      this.cleanError('lastName')
    },
    'form.ssn': function () {
      this.cleanError('ssn')
    },
    'form.yearFounded': function () {
      this.cleanError('yearFounded')
    },
    'form.streetAddress': function () {
      this.cleanError('streetAddress')
    },
    'form.postalCode': function () {
      this.cleanError('postalCode')
    },
    'form.phone': function () {
      this.cleanError('phone')
    },
    'form.city': function () {
      this.cleanError('city')
    },
    'form.turnover': function () {
      this.cleanError('turnover')
    },
    'form.businessName': function () {
      this.cleanError('businessName')
    },
    'form.contactEmail': function () {
      this.cleanError('contactEmail')
    },
    'form.bankAccount': function () {
      this.cleanError('bankAccount')
    },
    'form.businessId': function () {
      this.cleanError('businessId')
    },
    'form.tos': function () {
      this.cleanError('tos')
    },
  },
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      ssn: {
        required,
        isValidCPR,
        maxLength: maxLength(11),
      },
      yearFounded: {
        required,
        yearIsInPast,
      },
      streetAddress: {
        required,
      },
      postalCode: {
        required,
        isValidPostalCode,
      },
      city: {
        required,
      },
      phone: {
        required,
        isValidPhone,
      },
      turnover: {
        required,
      },
      businessName: {
        required,
      },
      contactEmail: {
        required,
        email,
      },
      bankAccount: {
        required,
        isIban,
      },
      businessId: {
        required,
        isValidCVR,
      },
      tos: {
        required,
        sameAs: sameAs(() => true),
      },
    },
  },

  methods: {
    sendApplication() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'error'
      } else {
        this.submitStatus = 'sending'
        this.$store.dispatch('sendSmeApplication')
        Object.keys(this.smeErrors).forEach(key => {
          if (this.errorList.includes(key)) {
            this.errorList[key] = this[key]
          }
        })
      }
    },

    updateValue(key) {
      this.submitStatus = ''

      const value = this.$v.form[`${key}`].$model
      if (key === 'ssn') {
        this.formatSsn(value, this.$v)
      }
      if (key === 'bankAccount') {
        this.removeWhitespace(value, this.$v)
      }
      this.$store.commit('updateApplication', {
        key: `smeloan.${key}`,
        value: value,
      })
    },

    validateState(value) {
      /*
       * Validation method.
       */
      const { $dirty, $error } = this.$v.form[`${value}`]
      if (value === 'turnover' && $dirty) {
        if (this.$v.form[`${value}`].$error) {
          this.incomeClass = 'is-invalid'
        } else {
          this.incomeClass = 'is-valid'
        }
      }

      // Backend errors.
      if (this.errorList.includes(value)) {
        return false
      }

      // Vuelidate validation.
      return $dirty ? !$error : null
    },
    cleanError(value) {
      /*
       * Clean error from errorList.
       */
      this.errorList = this.errorList.filter(val => {
        return val !== value
      })
    },
    delayTouch($v) {
      // Calms down inline validation with some fields that have stronger validators
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 500))
    },
    formatSsn(oldValue, $v) {
      let value = oldValue.replace(/\s/gi, '')
      if (!value.includes('-')) {
        const transform = value => {
          return `${value.substring(0, 6)}${value.length > 6 ? '-' : ''}${value.substring(
            6
          )}`
        }
        value = transform(value)
        $v.form.ssn.$model = value
      }
    },

    removeWhitespace(oldV, $v) {
      const value = oldV.replace(/\s/gi, '').toUpperCase()
      $v.form.bankAccount.$model = value
    },
  },
}
</script>
<style lang="scss" scoped>
.text-top {
  margin-top: 2rem;
  .fal {
    font-size: 2rem;
  }
}
.sme-tos-checkbox {
  padding-left: 2.5rem;
}

.is-invalid-checkbox {
  display: block;
  text-align: left;
  margin-left: 2.5rem;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.lowercase {
  text-transform: lowercase;
}
.first-part {
  margin-top: 2rem;
}
.contact-person-title {
  font-size: 1.2rem;
  margin-top: 2rem;
}
</style>
