<template lang="html">
  <div ref="header">
    <div v-if="location.indexOf('ansoegning') != -1">
      <h2 v-if="fullView" class="mb-3 text-center">
        {{ step }}.
        {{ $t('personal_info') }}
      </h2>
      <h1 v-else class="mb-3 text-center">
        {{ $t('personal_info') }}
      </h1>
      <div class="row">
        <div class="form-group col-sm-12 col-md-6">
          <form-input
            type="dropdown"
            storekey="amount"
            id="amount"
            :items="loanAmounts"
            suffix=" kr."
          >
            {{ $t('preferred_loan_amount') }}
          </form-input>
        </div>
        <div class="form-group col-sm-12 col-md-6">
          <form-input
            type="dropdown"
            :items="monthlyCostOptions"
            storekey="repayment_time"
            id="repayment_time_dropdown"
            suffix=" kr./md."
          >
            {{ $t('repayment_period') }}
          </form-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="email"
          storekey="email"
          id="email"
          name="email"
          autocomplete="email"
        >
          {{ $t('email') }}
        </form-input>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="phone"
          storekey="phone"
          id="phone"
          inputmode="numeric"
          name="phone"
          autocomplete="tel"
          prepend="+45"
        >
          {{ $t('phone_number') }}
        </form-input>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="text"
          storekey="firstName"
          id="firstName"
          class="mainInput"
          name="firstname"
        >
          {{ $t('first_name') }}
        </form-input>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          type="text"
          name="lastname"
          storekey="lastName"
          id="lastName"
          class="mainInput"
        >
          {{ $t('last_name') }}
        </form-input>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-md-6">
        <form-input
          :items="loanPurposeOptions"
          storekey="loanPurpose"
          type="dropdown"
          id="loanPurpose"
        >
          {{ $t('reason_for_loan') }}
        </form-input>
      </div>
    </div>
    <div class="row">
      <div
        class="consent-checkbox custom-checkbox col-sm-12 disclaimer"
        style="text-align: left"
      >
        <form-input type="checkbox" storekey="tos" id="tos">
          {{ $t('i_accept') }}
          <a target="_blank" href="/tjenestevilkar"> {{ $t('terms') }}</a>
          {{ $t('and') }}
          <a target="_blank" href="/persondatapolitik">{{ $t('privacy_policy') }}</a>
        </form-input>
      </div>
      <div class="col-md-12 col-sm-12" style="text-align: left">
        <div class="consent-checkbox custom-control custom-checkbox col-sm-12">
          <input
            type="checkbox"
            id="marketing"
            name="marketing"
            storekey="marketing"
            :value="true"
            :unchecked-value="false"
            class="custom-control-input"
            v-model="marketing"
          />
          <label
            for="marketing"
            id="marketingLabel"
            class="custom-control-label disclaimer"
          >
            {{ $t('i_accept_marketing') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loanTimeOptions, loanAmounts } from '@/utils/saverium_dk/const'
import { getMonthlyCosts } from '@/utils/utils'
import FormInput from '@/components/form/FormInput'
export default {
  created() {
    const uuid = this.$route.params.uuid
    if (uuid) {
      this.$store.dispatch('getLeadInfo', {
        uuid,
      })
    }
  },
  props: ['fullView', 'step', 'value'],
  components: {
    FormInput,
  },
  data() {
    return {
      requiredFields: ['firstName', 'lastName', 'phone', 'email', 'tos', 'loanPurpose'],
      error: false,
      permanent: '',
      type: '',

      location: window.location.pathname,
    }
  },
  computed: {
    loanAmounts() {
      return loanAmounts
    },
    loanTimeOptions() {
      return loanTimeOptions
    },
    loanPurposeOptions() {
      return this.$store.state.enums.loanPurposeOptions
    },
    stepErrors() {
      return this.$store.state.application.stepErrors
    },
    monthlyCostOptions() {
      return getMonthlyCosts(this.loanAmount, this.loanTimeOptions.length)
    },
    errors() {
      return this.$store.state.application.errors
    },

    loanAmount() {
      return this.$store.state.application.amount
    },
    marketing: {
      get() {
        return this.$store.state.application.marketing
      },
      set(marketing) {
        this.$store.state.application.marketing = marketing
      },
    },

    tos: {
      get() {
        return this.$store.state.application.tos
      },
      set(tos) {
        this.$store.state.application.tos = tos
      },
    },
  },
  watch: {
    stepErrors(newV) {
      if (this.fullView && newV[0] === parseInt(this.step, 10)) {
        this.$refs.header.scrollIntoView()
      }
      if (Object.keys(this.errors).some(e => this.requiredFields.includes(e))) {
        this.error = true
      }
    },
  },
  methods: {
    updateInput(event) {
      const {
        target: { name, value: _val },
      } = event
      let value = _val
      if (value === 'true') {
        value = true
      }
      if (value === 'false') {
        value = false
      }

      if (name === 'tos') {
        this.$store.commit('updateApplication', {
          key: name,
          value: this.tos,
        })
        return
      }
      this.$store.commit('updateApplication', {
        key: name,
        value: value,
      })
      this.$store.dispatch('updateOrCreateDraft')
    },
  },
}
</script>

<style lang="scss" scoped>
.active {
  background-color: #05083c;
  color: white;
}
.is-invalid {
  color: #dc3545;
}
.consent-checkbox {
  &:last-child {
    padding-bottom: 1rem;
  }
}
.hidden {
  display: none;
}
</style>
