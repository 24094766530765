<template lang="html">
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">{{ prepend }}</span>
    </div>
    <input
      class="form-control"
      :class="invalid ? 'is-invalid' : '' || error ? 'is-invalid' : ''"
      type="tel"
      :value="_value"
      @change="input"
      :name="name"
      :id="id"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
export default {
  props: ['value', 'placeholder', 'error', 'name', 'id', 'prepend'],
  data() {
    return {
      invalid: false,
      country: '',
    }
  },
  computed: {
    _value() {
      this.value.replace(/[^0-9]+/gi, '')
      if (this.value.startsWith(this.prepend)) {
        return this.value.slice(this.prepend.length)
      }
      return this.value
    },

    _class() {
      if (this.invalid) {
        return 'is-invalid'
      } else {
        return ''
      }
    },
  },
  methods: {
    input(event) {
      let value = event.target.value
      if (value.startsWith(this.prepend)) {
        value = value.slice(this.prepend.length)
      }
      value = value.replace(/[^0-9]+/gi, '')

      if (this.prepend + value !== this.value) {
        this.$emit('input', this.prepend + value)
      }
      if (this.$root.brand === 'saverium_dk') {
        this.country = 'DK'
      } else if (this.$root.brand === 'saverium_pl') {
        this.country = 'PL'
      }
      if (value) {
        const phoneNumber = parsePhoneNumber(value, this.country).number
        this.invalid = !isValidPhoneNumber(phoneNumber, this.country)
      } else {
        this.invalid = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
