<template lang="html">
  <div>
    <template v-if="this.touchscreen">
      <b-input
        type="date"
        :lang="datepickerLocale"
        :input-class="'form-control' + (error ? ' is-invalid' : '')"
        width="100%"
        @input="input"
        :value="value"
        v-model="model"
        :placeholder="placeholder"
      >
      </b-input>
    </template>
    <template v-else>
      <datepicker
        :lang="datepickerLocale"
        :input-class="'form-control' + (error ? ' is-invalid' : '')"
        width="100%"
        @input="input"
        :value="value"
        v-model="model"
        :placeholder="placeholder"
        :format="brand === 'saverium_es' ? 'DD/MM/YYYY' : 'DD.MM.YYYY'"
    /></template>
  </div>
</template>

<script>
import Datepicker from 'vue2-datepicker'
import datepickerLocale from '@/lang/dates'
import settings from '@/settings/settings'
import { isMobile } from '@/utils/utils.js'
import { DateTime } from 'luxon'

export default {
  props: ['value', 'error', 'placeholder'],
  computed: {
    datepickerLocale() {
      return datepickerLocale
    },
    brand() {
      return settings.BRAND
    },
  },
  data() {
    return {
      touchscreen: false,
      model: '',
    }
  },
  components: {
    Datepicker,
  },
  created() {
    this.touchscreen = isMobile
  },
  methods: {
    input(date) {
      let formattedDate
      if (this.touchscreen) {
        formattedDate = date
      } else {
        formattedDate =
          date !== null
            ? DateTime.fromISO(date.toISOString()).toFormat('yyyy-MM-dd')
            : date
      }

      if (formattedDate !== this.value) {
        this.$emit('input', formattedDate)
      }
    },
  },
}
</script>

<style lang="scss">
.mx-datepicker {
  width: 100% !important;
}
div.mx-input-wrapper {
  input {
    background-color: white !important;
  }
}
@media (max-width: 400px) {
  .mx-datepicker-popup {
    width: auto;
    .mx-calendar {
      width: 100%;
    }
    .mx-calendar-content {
      width: 100%;
    }
  }
}
</style>
