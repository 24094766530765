<template>
  <main>
    <div class="row">
      <section id="ingress" class="faq-box col-xl-6 col-lg-8 col-12">
        <div class="container">
          <div class="content" style="margin: auto">
            <span class="title" style="font-size: 30px;">{{ $t('faq_long') }}</span>

            <div>
              <div role="tablist">
                <div v-for="(question, index) in content" :key="index">
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button
                        class="faq-button"
                        block
                        href="#"
                        @click="question.visible = !question.visible"
                        :class="question.visible ? null : 'collapsed'"
                        :aria-expanded="question.visible ? 'true' : 'false'"
                      >
                        {{ $t(`${question.question}`) }}
                        <span class="when-opened">
                          <i
                            class="fal fa-chevron-circle-down collapser close-collapse"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span class="when-closed">
                          <i
                            class="fal fa-chevron-circle-down collapser"
                            aria-hidden="true"
                            data-fa-transform="rotate-90"
                          ></i>
                        </span>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="content.id"
                      accordion="my-accordion"
                      role="tabpanel"
                      data-fa-transform="rotate-90"
                      v-model="question.visible"
                    >
                      <b-card-body>
                        <b-card-text> {{ $t(`${question.answer}`) }}</b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="container customer-service col-12 col-lg-4 col-xl-6">
        <p class="info-slogan">Obsługa klienta</p>
        <p class="info-slogan strong">kontakt@saverium.pl</p>
      </section>
    </div>
  </main>
</template>
<script>
export default {
  data() {
    return {
      content: [
        {
          question: 'who_can_apply_for_a_loan',
          answer: 'anyone_who_is_older_than_18',
          id: 1,
          visible: false,
        },
        {
          question: 'can_a_retired_person_apply_for_a_loan',
          answer: 'yes_you_can',
          id: 2,
          visible: false,
        },
        {
          question: 'can_a_unemployed_person_get_a_loan',
          answer: 'some_banks_give_out_loans_to_unemployed',
          id: 3,
          visible: false,
        },
        {
          question: 'how_do_i_get_loan_online',
          answer: 'you_get_a_loan_by',
          id: 4,
          visible: false,
        },
        {
          question: 'how_to_get_loan_online',
          answer: 'how_to_get_loan_online',
          id: 5,
          visible: false,
        },
        {
          question: 'can_i_have_more_than_one_loan',
          answer: 'you_can_have_more_than_one_loan',
          id: 6,
          visible: false,
        },
        {
          question: 'how_should_i_repay_the_loan',
          answer: 'you_should_repay_the_loan',
          id: 7,
          visible: false,
        },
        {
          question: 'why_was_i_refused_online_loan',
          answer: 'you_were_refused_because',
          id: 8,
          visible: false,
        },
        {
          question: 'how_to_change_my_bank_account_number',
          answer: 'change_bank_account_number_by',
          id: 9,
          visible: false,
        },
      ],
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.card-text {
  font-size: 0.9rem;
}
.content {
  background: none;
  text-align: center;
  color: #05083c;
  h2 {
    color: #bdab71;
  }
  padding: 2rem;
}
</style>
