<template>
  <div></div>
</template>

<script>
import Vue from 'vue'
export default {
  created() {
    if (this.$route.params.token) {
      Vue.saverium.saveUser(this.$route.params.token)
      this.$router.push(this.$root.rn.MyAccountView)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
