<template>
  <div class="wrapper">
    <div class="popup">
      <h4 class="title">{{ $t('we_use_cookies') }}</h4>
      <p class="consent-check">
        {{ $t('by_using_our_site_you_accept_cookies') }}
        <b-link target="_blank" :to="consentUrl">{{ $t('here') }}</b-link>
      </p>

      <button class="btn btn-consent" id="accept" @click="acceptCookies(true)">
        {{ $t('ok') }}</button
      ><br />
    </div>
  </div>
</template>

<script>
export default {
  props: { consentUrl: String },
  methods: {
    acceptCookies(value) {
      this.$store.dispatch('acceptCookies', value)
    },
  },
}
</script>
<style scoped lang="scss">
.consent-check {
  font-size: 0.7rem;
}
.btn-consent {
  background: #0da300;
  text-transform: uppercase;
  color: white;
  border: none;
  transition: all 0.2s;
  border-radius: 5px;
  height: auto;
  font-size: 1rem;
  &:hover {
    transform: scale(1.04);
    color: white;
    background: rgb(0, 97, 0);
  }
  &:not(:disabled):not(.disabled):active,
  &:focus {
    background: rgb(0, 97, 0);
  }
}
</style>
