<template>
  <div class="card-body text-left">
    <CardInformation :offer="offer" :action="action" :currency="currency" />
    <div class="mb-3">
      <p class="small mb-2">
        {{ $t('aion_disclaimer') }}
      </p>
      <template v-if="offer.description">
        <CardFooter :offer="offer" />
      </template>
    </div>
  </div>
</template>
<script>
import CardInformation from '@/components/account_view_components/CardInformation.vue'
import CardFooter from '@/components/account_view_components/CardFooter.vue'

export default {
  props: ['offer', 'action', 'currency'],
  data() {
    return { toggle: false }
  },
  components: {
    CardInformation,
    CardFooter,
  },
}
</script>
<style lang="scss" scoped>
.btn-block {
  @media (max-width: 995px) {
    width: 100%;
    margin: auto;
  }
}

.btn-row {
  display: flex;
  height: 65px;
  justify-content: flex-end;
}
.small {
  text-align: right;
  max-width: 100%;
  @media (max-width: 480px) {
    text-align: left;
  }
}
</style>
