import Vue from 'vue'
import Vuex from 'vuex'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import articles from './modules/articles'
import application from './modules/application'
import offers from './modules/offers'
import enums from './modules/enums'
import applicationType from './modules/applicationType'
import user from '@/store/modules/user'

const oidcSettings = require('@/settings/settings').oidcSettings
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    application,
    offers,
    enums,
    user,
    articles,
    applicationType,
    oidcStore: vuexOidcCreateStoreModule(oidcSettings),
  },
})
