<template>
  <b-container class="title">
    <b-row>
      <b-col class="main-title" cols="12">{{ $t('our_best_offers') }}</b-col>
      <b-col cols="6">
        <span class="red-value">{{ amount }} € </span>
        <span class="title-small">{{ $t('for_loan_amount') }}</span>
      </b-col>
      <b-col cols="6">
        <span class="red-value">{{ time }} </span>
        <span class="title-small">{{ $t('for_loan_term') }}</span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  computed: {
    amount() {
      return this.$store.getters.getCurrentApplication.loan_amount
    },
    time() {
      return this.$store.getters.getCurrentApplication.time_term
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  font-size: 20px;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  word-wrap: break-word;
}

.main-title {
  font-size: 17px;
  line-height: 34px;
  margin-bottom: 10px;
}

.red-value {
  color: #2a76bc;
  font-size: 27px;
}

.title-small {
  font-size: 15px;
  color: #555;
}

.black-value {
  font-size: 27px;
}

.link {
  font-size: 16px;
  color: #2a76bc;
  cursor: pointer;
}
</style>
