<template>
  <div>
    <div class="article">
      <h1 class="center-title">Modus Finans lån</h1>

      <div class="top-text text-container">
        <ul>
          <li>Lån mellem 10.000 – 150.000 kr.</li>
          <li>Hurtigt svar på din ansøgning.</li>
          <li>
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/uden-sikkerhed"
              rel="noopener noreferrer"
              target="_blank"
              >Lån uden sikkerhed</a
            >.
          </li>
          <li>Indfri lånet før tid gratis.</li>
        </ul>

        <p>
          Modus Finans (L´EASY A/S) er en online
          <a
            href="https://www.saverium.dk/laan-penge/laaneudbydere"
            rel="noopener noreferrer"
            target="_blank"
            >låneudbyder</a
          >
          i den mellemstore kategori, som blandt andet tilbyder
          <a
            href="https://www.saverium.dk/laan-penge/forbrugslaan"
            rel="noopener noreferrer"
            target="_blank"
            >forbrugslån uden sikkerhed</a
          >
          på 10.000 til 150.000 kroner til en lav variable rente. De er en del af en
          større og velkendt koncern, så du kan være sikker på at få god service samt gode
          vilkår, når det kommer til finansielle løsninger.
        </p>
        <p>
          Anvender du vores online platform til at undersøge dine
          <a
            href="https://www.saverium.dk/laan-penge/sammenligning"
            rel="noopener noreferrer"
            target="_blank"
            >lånemuligheder</a
          >, vil vi kunne tilbyde dig at gøre alt arbejdet, hvis blot du udfylder vores
          gratis ansøgningskema. Vi indhenter forskellige
          <a
            href="https://saverium.dk/laan-penge/sammenligning"
            rel="noopener noreferrer"
            target="_blank"
            >lånetilbud</a
          >
          for dig fra de mange låneudbydere, som vi arbejder sammen med, så du kun skal
          fokusere på at sammenligne dine tilbud og vælge dét tilbud, der netop passer
          bedst til dig.
        </p>
        <p>
          Dermed spare vi dig tid og kræfter, ved at du undgår at skulle kontakte og
          udfylde flere ansøgninger end bare den éne ansøgning, som du har udfyldt igennem
          os.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#rente-og-laanevilkaar-hos-modus-finans"
                    >Rente og lånevilkår hos Modus Finans</a
                  >
                </li>
                <li>
                  <a href="#saadan-ansoeger-du-om-et-laan-hos-modus-finans"
                    >Sådan ansøger du om et lån hos Modus Finans</a
                  >
                </li>
                <li><a href="#modus-finans">Modus Finans</a></li>
                <li>
                  <a href="#eksempel-paa-et-laan-hos-modus-finans"
                    >Eksempel på et lån hos Modus Finans</a
                  >
                </li>
                <li><a href="#kontakt-kundeservice">Kontakt kundeservice</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="rente-og-laanevilkaar-hos-modus-finans">
            Rente og lånevilkår hos Modus Finans
          </h2>
          <p><strong>Lånebeløb: </strong>10.000 - 150 000 kr.</p>
          <p><strong>Låneperiode: </strong>2 til 10 år</p>
          <p><strong>Variabel debitorrente: </strong>9,90% - 19,65%</p>
          <p><strong>Andre udgifter: </strong>Oprettelsesgebyr på 8%</p>
          <p>
            <strong>Behandlingstid: </strong>Hurtig svar tid og pengene på din konto
            indenfor 1-2 hverdage
          </p>
          <p><strong>Medansøger: </strong>Nej</p>
          <p></p>
        </div>

        <div class="text-container">
          <h2 id="saadan-ansoeger-du-om-et-laan-hos-modus-finans">
            Sådan ansøger du om et lån hos Modus Finans
          </h2>
          <p>
            Udfyld den online <strong>låneansøgning </strong>formular hurtigt<strong
            > </strong>på få minutter, og sammenlign dine tilbud, som du modtager på din
            brugerkonto online.
          </p>
          <ul>
            <li>
              <strong>Udfyld din ansøgning med det ønskede beløb og løbetid.</strong> Vælg
              låneperiode og det beløb du ønsker for dit lån.
            </li>
            <li>
              <strong>Oplys dine andre nødvendige oplysninger.</strong> Udfyld din
              ansøgning med de resterende nødvendige oplysninger.
            </li>
            <li>
              <strong>Send din ansøgning afsted</strong>. Send din udfyldte ansøgning
              online. Vi indhenter herefter flere forskellige skræddersyet tilbud til dig,
              baseret på dine oplyste oplysninger.
            </li>
            <li>
              <strong>Accepter dit ønskede tilbud</strong>. Sammenlign og vælg det ønskede
              tilbud, der passer til dig.
            </li>
            <li>
              <strong>Dit lån vil blive udbetalt til din konto</strong>. Det ønskede lån
              du har valgt, vil blive udbetalt til din konto indenfor 1-2 hverdage, hvis
              du vælger at acceptere et lån hos Modus Finans.
            </li>
          </ul>
        </div>

        <div class="text-container">
          <h2 id="modus-finans">Modus Finans</h2>
          <p>
            Modus Finans, tidligere kendt som L´EASY A/S er kreditformidler for Facit Bank
            A/S. Modus Finans kan blandt andet tilbyde dig et
            <a
              href="https://www.saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtiglån uden sikkerhed</a
            >, med en løbetid fra 24 måneder og helt op til 120 måneder. En af Modus
            Finans services er, at de tilbyder, at du altid kan betale dit lån tilbage før
            tid uden gebyr, hvis denne mulighed opstår i din økonomi.
          </p>
          <p>
            Når du laver en ansøgning via vores gratis online tjeneste, kan du modtage
            flere forskellige tilbud fra mere end 10 velkendte banker og andre udbydere på
            lånemarkedet udover Modus Finans. Det koster dig ingenting, og du forpligter
            dig heller ikke til at acceptere nogle af de modtagende tilbud. Du kan
            indsende en uforpligtende og gratis ansøgning online på få minutter via vores
            hjemmeside.
          </p>
          <p>
            Om det er et
            <a
              href="https://www.saverium.dk/laan-penge/samlelaan"
              rel="noopener noreferrer"
              target="_blank"
              >samlelån</a
            >
            eller et
            <a
              href="https://www.saverium.dk/laan-penge/kviklaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtigt kviklån</a
            >
            du søger, vil vi hjælpe dig med at finde de rette tilbud til dig.
          </p>
          <p>Vilkår for et lån hos Modus Finans</p>
          <p>
            For at kunne blive godkendt til et lån hos Modus Finans, skal du leve op til
            deres 3 krav som minimum:
          </p>
          <ul>
            <li>Du skal have en fast indtægt, som du kan dokumentere.</li>
            <li>Du skal være fyldt 21 år.</li>
            <li>
              Du må du ikke være registreret i hverken
              <strong>Debitor Registret eller RKI</strong>.
            </li>
          </ul>
          <p>
            Udover ovenstående, vil der blive foretaget en kreditvurdering af din økonomi
            ved at du giver samtykke til eSKAT og kontooplyningstjenesten Monthio. Dette
            skal du gøre ved brug af dit
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >MitID eller NemID</a
            >.
          </p>
          <p>
            Renten for dit
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >nye lån</a
            >
            vil blive sat efter din kreditvurdering er blevet lavet.
          </p>
        </div>

        <div class="text-container">
          <h2 id="eksempel-paa-et-laan-hos-modus-finans">
            Eksempel på et lån hos Modus Finans
          </h2>
          <p>
            <strong>Låneeksempel</strong>: Lånebeløb: 50.000 kr. - Løbetid: 60 mdr. Den
            månedlige ydelse er baseret på variabel debitorrente 9,90% - 19,56%. ÅOP
            13,63% - 23,95%. Samlet beløb, der skal betales tilbage er kr. 68.007 - 82.247
            kr. Samlede kreditomkostninger kr. 18.007 - 32.247 kr. Alle omkostninger og
            gebyrer er medregnett i beregningen, forudsat betaling via HomeBanking. Du har
            fortrydelsesret i 14 dage for lånet.
          </p>
        </div>

        <div class="text-container">
          <h2 id="kontakt-kundeservice">Kontakt kundeservice</h2>
          <p>
            Du kan ringe til Modus Finans kundeservice alle hverdage fra kl. 08:00 – 18:00
            og i weekenderne kan de kontaktes mellem 10:00 – 18:00. Ring på tlf.: 99 99 91
            11. Du kan os kontakte Modus Finans via deres mail:
            <a
              href="mailto:modusfinans@modusfinans.dk"
              rel="noopener noreferrer"
              target="_blank"
              >modusfinans@modusfinans.dk</a
            >.
          </p>
          <p>
            Skulle du have nogle spørgsmål vedrørende hvordan du skulle udfylde din
            ansøgning eller noget helt andet, kan du kontakte os i hverdage mellem 09:00 -
            15:00 på telefon: 93 75 99 59. Du er os velkommen til at kontakte os direkte
            på e-mail:
            <a
              href="mailto:kundeservice@saverium.dk"
              rel="noopener noreferrer"
              target="_blank"
              >kundeservice@saverium.dk</a
            >.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
