<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til tandlæge</h1>

      <div class="top-text text-container">
        <p>
          Har du brug for at låne penge til tandlægeregningen? Vi hjælper dig med at finde
          de billigste lån til at dække din tandlægeregning, så du kan få fikset dine
          tænder hurtigt og få et flot smil. Ansøg om et tandlån på 2 minutter hos os, ved
          at udfylde vores ansøgningsformular her på siden, så vi kan finde de bedste
          tilbud på lån til tandlægen til dig fra en lang række banker.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#find-det-rette-laan-til-tandlaegen-med-os"
                    >Find det rette lån til tandlægen med os</a
                  >
                </li>
                <li>
                  <a href="#et-billigt-laan-til-tandlaegen"
                    >Et billigt lån til tandlægen</a
                  >
                </li>
                <li>
                  <a href="#laan-til-tandlaege-trods-rki">Lån til tandlæge trods RKI</a>
                </li>
                <li>
                  <a href="#laan-til-forskellige-typer-af-tandbehandlinger"
                    >Lån til forskellige typer af tandbehandlinger</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="find-det-rette-laan-til-tandlaegen-med-os">
            Find det rette lån til tandlægen med os
          </h2>
          <p>
            Hvis ønsker at finde det bedste eller billigste lån til tandlægen, så hjælper
            vi dig gerne. Måden det foregår på er, at du som nævnt ovenfor, udfylder en
            ansøgning her på siden, hvilket tager 2 minutter.
          </p>
          <p>
            Herefter fremsender vi din forespørgsel på at låne penge til tandlægen til en
            lang række banker og andre
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere</a
            >, som automatisk og med det samme, vender tilbage til os, med tilbud på
            tandlån til dig.
          </p>
          <p>
            Herefter er det blot op til dig at vælge, hvem der skal stå for at finansiere
            dine tænder, ud fra den liste af
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >lånetilbud</a
            >, som du måtte få.
          </p>
          <p>
            Vi hjælper dig med at finde det rette tandlån, uanset om du ønsker at låne til
            en fremtidig tandbehandling eller til en eksisterende tandlægeregning, og
            uanset hvilken størrelse dit tandlægelån så måtte være.
          </p>
        </div>

        <div class="text-container">
          <h2 id="et-billigt-laan-til-tandlaegen">Et billigt lån til tandlægen</h2>
          <p>
            Som udgangspunkt ønsker de fleste et billigt lån til tandlægen, hvilket er et
            godt sted at starte. Den billigste måde at låne penge til tandlægen, er ved at
            indhente tilbud på tandlån fra en lang række udbydere, og derefter sammenligne
            disse udbydere, for at se, hvem som kan tilbyde den laveste ÅOP (årlig
            omkostning i procent).
          </p>
          <p>
            Dette er dog kun tilfældet hvis både løbetid og lånets størrelse er identisk.
            Hvis ikke dette er tilfældet, kan du komme til at betale mere for det tandlån,
            som har en lavere ÅOP.
          </p>
          <p>
            Hvis ikke lånebeløbet og løbetiden er identisk, bør du i stedet se på de
            totale kreditomkostninger ved tandlægelånet. Samtidig bør du her også skelne
            til den måendlige ydelse, for at være sikker på, at din uoverskuelige
            tandlægeregning ikke blot bliver erstattet med en uoverskuelig månedlig ydelse
            på tandlægelånet.
          </p>
          <h3>Rentefri tandlægelån</h3>
          <p>
            Kan man få et rentefrit landlægelån? Vi ved, at der er mange steder som
            reklamerer med rentefri tandlægelån, og det lyder måske godt, men der er altid
            en pris at betale, uanset om du skal have finansieret besøget hos din tandlæge
            eller en anden udgift.
          </p>
          <p>
            Et rentefrit tandlægelån er ikke lig med omkostningsfrit eller gratis
            tandlægelån. I stedet for at betale renter, betaler du måske et opstartsgebyr
            eller har kun kort tid til at tilbagebetale dit lån, inden at der kommer et
            rykkergebyr ind af postkassen. Der er mange måder hvorpå du kan blive snydt af
            tilbud om rentefri tandlægelån. Derfor anbefaler vi, at du er ekstra
            omhyggelig, hvis du leder efter rentefri finansiering af din tandlægeregning.
          </p>
          <h3>Hvad er det bedste tandlån?</h3>
          <p>
            Det bedste lån til tandlægen, ud fra vores erfaring, er hvis du både ser på
            udbetalingshastigheden og prisen på lånet. Når du
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låner penge</a
            >, især i en nødsituation, som det kan være med dine tænder, kan du ikke vente
            uger eller måneder på en udbetaling eller låneafklaring. Derfor er det også
            ofte relevant, at se på hvor hurtigt du modtager dit lån. Husk derfor at se
            på, hvor
            <a
              href="https://www.saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtigt lånet</a
            >
            udbetales.
          </p>
          <p>
            Vores bankpartnere udbetaler som udgangspunkt alle deres
            <a
              href="https://www.saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >lån hurtigt</a
            >. Hvis ikke andet er opgivet på vores side, så kan du forvente, at dit lån
            senest bliver udbetalt næste bankdag, hvilket er tidsnok for de fleste med en
            tandpine eller en knækket tand. I alle tilfælde, vil du selvfølgelig modtage
            en godkendelse på at du får lånet udbetalt, hvilket du evt. ville kunne
            fremvise til tandlægen, hvis du ikke kan vente til næstkommende bankdag.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-tandlaege-trods-rki">Lån til tandlæge trods RKI</h2>
          <p>
            Hvis ønsker at låne til tandlægen trods en registrering i RKI, så er chancerne
            små. Lidt på samme måde som med rentefri tandlån, så er der mange, som lokker
            med at du kan låne til tandlægen trods RKI, hvilket meget sjældent er
            tilfældet.
          </p>
          <p>
            Der findes dog nogle få tilfælde, hvor det er muligt at få finansiering til
            tandlægen på trods af en registrering som dårlig betaler.
          </p>
          <p>
            Den første mulighed er, at dit udestående er et
            <a
              href="https://www.saverium.dk/laan-penge/minilaan"
              rel="noopener noreferrer"
              target="_blank"
              >lille beløb</a
            >, som du kan forklare blot er en uoverensstemmelse imellem dig og kreditoren.
            Dermed kan du muligvis forklare långiveren, at du ikke har problemer med at
            betale dine regninger, men blot er uenig i lige præcis dén regning, bøde eller
            afgift.
          </p>
          <p>
            Den anden mulighed er at
            <a
              href="https://www.tandlaegeforeningen.dk/til-patienter/priser-og-tilskudsmuligheder/tilskud-til-tandpleje/voksne/kontanthjaelpsmodtagere-mfl/"
              rel="noopener noreferrer"
              target="_blank"
              >søge tilskud til tandlægeregningen hos kommunen</a
            >. Dette er dog kun en mulighed i specielle tilfælde.
          </p>
          <p>
            Den sidste mulighed er at søge om et tandlån hos fx os, da mange af vores
            ansøgere, som tror at de er registreret i RKI, faktisk ikke er, og derfor godt
            kan få et tandlægelån, i form af et
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >, på helt normal vis.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-forskellige-typer-af-tandbehandlinger">
            Lån til forskellige typer af tandbehandlinger
          </h2>
          <p>
            I dette afsnit, vil vi gennemgå nogle af de mest almindelige procedurer, som
            vi ved, at vores brugere ansøger om tandlån til. Uanset om du kan finde din
            tandbehandling nedenfor eller ej, er du stadigvæk meget velkommen til at
            ansøge om et lån til tandlægen igennem os.
          </p>
          <p>
            Vær opmærksom på, at hvis du er medlem af
            <a
              href="https://www.sygeforsikring.dk/tilskud-til-tandlaege"
              rel="noopener noreferrer"
              target="_blank"
              >Sygesikring Danmark</a
            >, vil du delvist kunne få dækket nogle af nedenstående omkostninger.
          </p>
          <h3>Tandeftersyn</h3>
          <p>
            Et tandeftersyn er en af de mest almindelige procedurer der findes. Dette
            koster omkring <strong>500 kr.</strong>, hvilket er i den billige ende af, alt
            hvad du ellers ville kunne få foretaget hos tandlægen. Man skulle derfor tro,
            at der ikke var mange ansøgere om tandlægelån til denne kategori, men da
            tandeftersynet er aktuelt for alle, vil der også være mange patienter, som kan
            få problemer med at få økonomien til at hænge sammen, hvis de skal betale for
            et check af tænderne.
          </p>
          <h3>Fyldning</h3>
          <p>
            En fyldning er også en af de mest udbredte behandlinger hos tandlægen. Ligesom
            eftersynet, koster dette også ofte i omegnen af 500 kr. Hvis du skal have
            lavet flere huller på samme tid, kan det dog godt løbe op i et højere beløb,
            hvor et mindre lån til tandlægen kan blive relevant.
          </p>
          <h3>Visdomstand</h3>
          <p>
            Hvis du er så uheldig at naturen har udstyret dig med en eller flere
            visdomstænder, og du skal have revet dem ud, da de enten skubber til dine
            andre tænder eller har fået et hul, så må du også til tandlægen. Her skal du
            regne med at låne omkring <strong>1.000 kr.</strong> pr. visdomstand, som skal
            rives ud, hvis ikke du har en buffer på kontoen.
          </p>
          <h3>Pardentose</h3>
          <p>
            <a
              href="https://netdoktor.dk/sygdomme/fakta/parodontose.htm"
              rel="noopener noreferrer"
              target="_blank"
              >Parodontose (paradentose)</a
            >, er en betændelsestilstand i støttevævet omkring tænderne. Her slipper du
            desværre ikke med én enkelt behandling, da dette kan være svært at slippe af
            med og derfor ofte bliver en kronisk sygdom.
          </p>
          <p>
            Ofte koster pardentose mellem <strong>2.000 kr. </strong>og
            <strong>6.000 kr.</strong> at behandle, men er sygdommen så fremskreden, at du
            har behov for en tandkødsoperation, er det ikke ualmindeligt, at der er behov
            for et tandlægelån på helt op til
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/3000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >20.000 kr.</a
            >
          </p>
          <p>
            Herefter vil du have behov for årlig check og opfølgende behandling, som
            typisk løber op i <strong>1.000 kr.</strong> til
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/3000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >3.000 kr.</a
            >
          </p>
          <h3>Tandkrone</h3>
          <p>
            Hvis din tand er knækket eller flækket, så har du med stor sandsynlighed brug
            for en krone på tanden. En tandkrone er en keramisk “hætte”, som sættes oven
            på den beskadiget tand. Før kronen kan sættes på, slibes tanden ned til en
            stump, så der er plads til at sætte kronen på.
          </p>
          <p>
            En krone koster ofte
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/5000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >5.000 kr.</a
            >
            at få lavet. Hvis du ikke har pengene på din bankkonto, hjælper vi
            selvfølgeligt også gerne dig med at låne penge til tandlægen, så du kan få et
            flot smil igen.
          </p>
          <h3>Tandbøjle</h3>
          <p>
            En almindelig tandbøjle med fast bøjle i både under- og overkæbe koster som
            regel
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/40000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >40.000 kr.</a
            >
            Hvis du, som de fleste, ønsker en usynlig bøjle, så som
            <a
              href="https://www.invisalign.com/"
              rel="noopener noreferrer"
              target="_blank"
              >Invisalign</a
            >, koster det ca.
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/50000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >50.000 kr.</a
            >
          </p>
          <p>
            Er du under 18 år, kan du få en gratis tandbøjle hos
            <a
              href="https://www.kk.dk/borger/sundhed-og-forebyggelse/tandpleje/tandpleje-for-boern-og-unge"
              rel="noopener noreferrer"
              target="_blank"
              >skoletandlægen</a
            >, men er du ude af skoletandplejeordningen, må du selv betale. Her vil de
            fleste ofte have brug for et tandlægelån.
          </p>
          <h3>Rodbehandling</h3>
          <p>
            Er du så uheldig, at have haft hul / caries i en tand så længe, at hullet i
            tanden er gået igennem tandbenet og ned i nerven og har givet betændelse i
            nerven, så er der ingen vej uden om en rodbehandling.
          </p>
          <p>
            Alt efter hvor mange rodkaneler der er påvirket, og om du også skal have en
            rodskylning, varierer prisen fra ca. 1.000 kr. og op til 3.000 kr. pr. tand.
          </p>
          <p>
            En rodbehandling kommer ofte uventet, og vi ser derfor også mange, som får
            brug får at låne til denne tandbehandling.
          </p>
          <h3>Tandimplantat</h3>
          <p>
            Skal du have lavet et enkelttandsimplantat, er der stor forskel på prisen. Alt
            fra
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/10000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >10.000 kr.</a
            >
            og op til
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/25000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >25.000 kr.</a
            >
            er normalt. På trods af at denne tandbehanling sjældent kommer uventet, kan et
            lån til at dække denne tandlægeregning ofte blive en nødvendighed pga. prisen.
          </p>
          <p>
            Dette er prisen for et enkelt implantat. Hvis du har behov for flere
            implantater, vil prisen selvfølgeligt også være højere.
          </p>
          <h3>Røntgen og bedeøvelse</h3>
          <p>
            Lokalbedøvelse (lokalanalgesi), koster ofte 250 kr. Herudover koster
            røntgenbilleder til at stille den rigtige diagnose alt fra 150 kr og op til
            1.000 kr., hvis der er behov for et røntgenbillede i panorama.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
