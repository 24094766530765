<template lang="html">
  <div class="mb-5" ref="header">
    <h2 v-if="fullView" class="mb-3 text-center">
      {{ step }}.
      {{ $t('existing_loans_and_bank_account_info') }}
    </h2>
    <h1 v-else class="mb-3 text-center long-title">
      {{ $t('existing_loans_and_bank_account_info') }}
    </h1>

    <div class="row align-items-end">
      <div class="form-group col-sm-6">
        <form-input
          type="regex"
          placeholder="np. 3454 345"
          regex="^[0-9]{7,10}$"
          storekey="accountNumber"
          id="accountNumber"
          inputmode="numeric"
        >
          {{ $t('bank_account') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6">
        <form-input
          type="regex"
          storekey="bankCode"
          id="bankCode"
          inputmode="numeric"
          regex="^[0-9]{4}$"
        >
          {{ $t('registeringnumber') }}
          {{ $t('four_digits') }}
        </form-input>
      </div>
      <span class="col-12 small">{{ $t('bank_number_text') }}</span>
    </div>
    <div class="form-group col-sm-6">
      <form-input type="month" storekey="openedDate" id="openedDate">
        {{ $t('customer_of_bank_since') }}
      </form-input>
    </div>
    <div class="row">
      <div class="form-group col-sm-6" storekey="rki">
        <label for="rki"> {{ $t('are_you_registered_in_rki') }}</label>
        <span v-if="errors.rki" class="invalid-feedback" style="display: inline-block">
          {{ $t('required') }}
        </span>
        <div class="radio-toolbar" name="rki" id="rki" @change="updateInput">
          <input
            type="radio"
            id="rki1"
            name="rki"
            :value="true"
            :checked="this.rki === true"
          />
          <label class="radiolabel" for="rki1">{{ $t('yes') }}</label>

          <input
            type="radio"
            id="rki2"
            name="rki"
            :value="false"
            :checked="this.rki === false"
          />
          <label class="radiolabel" for="rki2">{{ $t('no') }}</label>
        </div>
        <span class="small">{{ $t('this_info_will_be_checked_later') }}</span>
      </div>
      <div class="form-group col-sm-6">
        <form-input
          type="identity"
          storekey="ssn"
          id="ssn"
          name="ssn"
          class="mainInput"
          inputmode="numeric"
          identifier="cpr"
        >
          {{ $t('ssn') }}
          <i
            v-b-tooltip.hover.v-saverium-blue-input
            title="Vi har brug for dit CPR-nummer, så bankerne kan udregne dine lånetilbud."
            class="fas fa-question-circle"
          ></i>
        </form-input>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 mb-5 mt-5" style="text-align: left">
        <b-form-checkbox
          storekey="coApplicant"
          v-model="coApplicant"
          name="check-button"
          id="coApplicant"
          switch
          @change="changeCoApplicantStatus"
        >
          {{ $t('i_have_a_coapplicant') }}
        </b-form-checkbox>
      </div>
    </div>
    <div class="row col-md-12 col-sm-12">
      <div>
        <b-form-checkbox
          id="isDanish"
          v-model="isDanish"
          storekey="isDanish"
          name="isDanish"
          :value="true"
          :unchecked-value="false"
          class="consent-checkbox"
          :class="danishErrors ? 'is-invalid' : ''"
        >
          <div>
            {{ $t('i_am_danish') }}
          </div>
        </b-form-checkbox>
        <div v-if="danishErrors" class="invalid-feedback" style="display: block">
          {{ $t('field_is_required') }}
        </div>
      </div>
      <div v-if="this.isDanish === false" class="row">
        <div class="form-group col-sm-12 col-md-6">
          <form-input type="month" storekey="countryResidentSince" id="denmarkdate">
            {{ $t('lived_in_denmark_since') }}
          </form-input>
        </div>
        <div class="form-group col-sm-12 col-md-6">
          <form-input
            type="dropdown"
            storekey="typeOfResidencePermit"
            id="residencePermit"
            :items="residenceTypes"
          >
            {{ $t('type_of_residence_permit') }}
          </form-input>
        </div>
        <div class="form-group col-sm-12 col-md-6">
          <form-input
            type="text"
            storekey="foreignerNumber"
            id="foreignerNumber"
            inputmode="numeric"
          >
            {{ $t('foreigners_number_or_id') }}
            <i
              v-b-tooltip.hover.v-saverium-blue-input
              title="Hvis du ikke har udlændingenummer, skal du abre skrive 12345
"
              class="fas fa-question-circle"
            ></i>
          </form-input>
        </div>
        <div class="form-group col-sm-12 col-md-6">
          <form-input
            type="dropdown"
            storekey="subType"
            id="residenceType"
            :items="residenceTypeSubTypes"
            :class="{ hidden: this.subtype === false }"
          >
            {{ $t('residence_type') }}
          </form-input>
        </div>
        <div class="form-group col-sm-12 col-md-6">
          <form-input
            type="text"
            storekey="permitNumber"
            id="permitNumber"
            inputmode="numeric"
            :class="{ hidden: this.subtype === false }"
          >
            {{ $t('serial_number_on_residence_permit') }}
            <i
              v-b-tooltip.hover.v-saverium-blue-input
              title="Hvis du ikke har løbenummer på din opholdstilladelse, bare skriv 12345
"
              class="fas fa-question-circle"
            ></i>
          </form-input>
        </div>
        <div class="form-group col-sm-12 col-md-6">
          <form-input
            type="month"
            storekey="expiryDate"
            id="expiryDate"
            :class="{ hidden: this.subtype === false || this.perm === true }"
          >
            {{ $t('expiry_date_of_residence_permit') }}
          </form-input>
        </div>
      </div>

      <div class="consent-checkbox custom-control custom-checkbox col-sm-12">
        <input
          type="checkbox"
          id="personalDataPolicy"
          @change="updateInput"
          name="personalDataPolicy"
          storekey="personalDataPolicy"
          class="custom-control-input"
          :class="errors.personalDataPolicy ? 'is-invalid' : ''"
          :value="true"
          v-model="personalDataPolicy"
          :unchecked-value="false"
        />
        <label for="personalDataPolicy" class="custom-control-label">
          {{ $t('consent_to_kredit_status_1') }}
          <span :key="bank.value" v-for="bank in banks">{{ bank.value }}, </span>
          <span v-html="$t('consent_to_kredit_status_2')"></span>

          <span class="link" @click=";(visible = !visible), stop($event)">her</span>.
        </label>
        <div
          v-if="errors.personalDataPolicy"
          class="invalid-feedback"
          style="display: block"
        >
          {{ $t('field_is_required') }}
        </div>
      </div>
      <b-collapse v-model="visible">
        <ul>
          <li :key="bank.value" v-for="bank in banks">
            Læs {{ bank.value }} fulde privativspolitik
            <a :href="bank.link" target="_blank">her</a>
          </li>
        </ul>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import FormInput from '@/components/form/FormInput'
import { banks } from '@/utils/saverium_dk/const'
import { mapState } from 'vuex'
export default {
  props: ['fullView', 'step', 'value'],
  components: {
    FormInput,
  },
  data() {
    return {
      requiredFields: [
        'accountNumber',
        'bankCode',
        'tos',
        'personalDataPolicy',
        'rki',
        'ssn',
      ],
      subtype: '',
      active: '',
      isHidden: true,
      visible: false,
      allErrors: false,
      danishErrors: false,
      perm: '',
      residenceTypeSubTypes: [],
    }
  },
  computed: {
    ...mapState({
      stepErrors: state => state.application.stepErrors,
      consents: state => state.application.consents,
      errors: state => state.application.errors,
      rki: state => state.application.rki,
      residenceTypes: state => state.enums.residencePermitTypes,
      residenceTypeSubTypesTemporary: state =>
        state.enums.residencePermitSubTypesTemporary,
      residenceTypeSubTypesPermanent: state =>
        state.enums.residencePermitSubTypesPermanent,
      typeOfResidencePermit: state => state.application.typeOfResidencePermit,
    }),

    personalDataPolicy: {
      get() {
        return this.$store.state.application.personalDataPolicy
      },
      set(personalDataPolicy) {
        this.$store.state.application.personalDataPolicy = personalDataPolicy
      },
    },

    dataPolicyError: {
      get() {
        return this.$store.state.application.dataPolicyError
      },
      set(dataPolicyError) {
        this.$store.state.application.dataPolicyError = dataPolicyError
      },
    },

    banks: {
      get() {
        return banks
      },
      set(banks) {
        this.$store.state.application.banks = banks
      },
    },
    isDanish: {
      get() {
        return this.$store.state.application.isDanish
      },
      set(isDanish) {
        this.$store.state.application.isDanish = isDanish
      },
    },
    coApplicant: {
      get() {
        return this.$store.state.application.coApplicant
      },
      set(coApplicant) {
        this.$store.state.application.coApplicant = coApplicant
      },
    },
  },
  watch: {
    typeOfResidencePermit(value) {
      if (value === '1') {
        this.subtype = true
        this.residenceTypeSubTypes = this.residenceTypeSubTypesTemporary
        this.perm = false
      } else if (value === '2') {
        this.subtype = true
        this.perm = true
        this.residenceTypeSubTypes = this.residenceTypeSubTypesPermanent
      } else {
        this.subtype = false
        this.perm = false
      }
    },
    stepErrors(newV) {
      if (this.fullView && newV[0] === parseInt(this.step, 10)) {
        this.$refs.header.scrollIntoView()
      }
    },
    isDanish(value) {
      this.$store.commit('updateApplication', { key: 'isDanish', value: value })
      if (this.isDanish === true) {
        const keys = [
          'countryResidentSince',
          'isDanish',
          'residenceId',
          'foreignerNumber',
          'permitNumber',
          'subType',
          'typeOfResidencePermit',
        ]
        keys.forEach(key => {
          this.$store.dispatch('removeError', key)
        })

        this.danishErrors = false
      }
    },
  },

  methods: {
    isValid(id) {
      return !Object.keys(this.errors).includes(id)
    },
    stop(event) {
      event.preventDefault()
    },
    updateInput(event) {
      const {
        target: { name, value: _val },
      } = event
      let value = _val
      if (value === 'true') {
        value = true
      }
      if (value === 'false') {
        value = false
      }
      if (name === 'rki') {
        this.$store.dispatch('removeError', 'rki')
      }
      if (name === 'personalDataPolicy') {
        this.$store.dispatch('removeError', name)
        this.$store.commit('updateApplication', {
          key: name,
          value: event.target.checked,
        })
        this.$store.dispatch('updateOrCreateDraft')
        return
      }
      this.$store.dispatch('removeError', name)

      this.$store.commit('updateApplication', {
        key: name,
        value: value,
      })
      this.$store.dispatch('updateOrCreateDraft')
    },
    changeCoApplicantStatus(value) {
      this.$store.commit('updateApplication', {
        key: 'coApplicant',
        value: value,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.small {
  text-align: left;
  margin: 0;
  margin-bottom: 2rem;
}
.active {
  background-color: #05083c;
  color: white;
}
.hidden {
  display: none;
}
.radiolabel {
  width: auto;
}
.toggle-collapse {
  cursor: pointer;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.close-collapse {
  cursor: pointer;
  transform: rotate(-90deg);
}

.collapse-wrapper {
  text-align: right;
  cursor: pointer;
}
.consent-text {
  font-size: 13px;
}
.consents {
  overflow: auto;
  max-height: 100px;
}
.custom-control {
  margin-bottom: 10px;
}
@media all and (max-width: 489px) {
  .long-title {
    font-size: 2rem;
  }
  .consents {
    max-width: 300px;
  }
  .custom-control {
    margin-left: 15px;
  }
}
.link {
  text-decoration: underline;
  cursor: pointer;
}
.custom-control-label {
  a {
    color: black;
    background-image: linear-gradient(transparent calc(100% - 1.5px), rgb(5, 8, 60) 1px);
    background-size: 100% 100%;
    background-position: 100%;
    position: relative;
    background-repeat: no-repeat;
    transition: all 0.2s;

    &:hover {
      background-size: 0 100%;
      text-decoration: none;
    }
  }
}
</style>
