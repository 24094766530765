<template>
  <div>
    <div class="article">
      <h1 class="center-title">MC Lån</h1>
      <div class="top-text text-container">
        <p>
          De færreste har likviderne til at betale motorcyklens fulde pris, når de
          anskaffer sig en motorcykel. I de fleste tilfælde bliver købet finansieret med
          et MC lån.
        </p>
        <p>
          Hvis du selv leder efter et billigt MC lån, der kan finansiere af den
          motorcykel, du har udset dig, er der mange
          <b-link :to="$root.rn.LoanOptions">lånemuligheder</b-link> at vælge mellem.
          Disse lånemuligheder adskiller sig naturligvis på baggrund af adskillige
          parametre.
        </p>
        <p>
          Netop af denne grund, er det essentielt, at dit lån til motorcykel optages med
          stor omhu. Heldigvis er der hjælp at hentet her på siden igennem Saverium.dk.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul v-for="(text, i) in contents" :key="i">
                <li>
                  <a :href="text.link">
                    {{ text.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">Lån penge til MC igennem Saverium.dk</h2>
          <p>
            Hvis du vil
            <b-link :to="$root.rn.LoanImmediately">hurtig lån penge</b-link> til MC uden
            sikkerhed, der egner sig til finansiering af en motorcykel, er det vitalt, at
            dit lån afspejler, at det bruges til at købe en motorcykel. Du kan eventuelt
            læse om de forskellige regler der er for de forskellige motorcykler kørsel på
            <a
              href="https://www.mctc.dk/godt-at-vide/love-og-regler/regler-for-koerekort-til-mc/"
              target="_blank"
              rel="noopener"
              >mctc.dk</a
            >.
          </p>
          <p>
            Hos Saverium.dk kan du indhente lånetilbud fra forskellige lånevirksomheder
            ved blot at udfylde én ansøgningsformular. Alle lånetilbud præsenteres tilmed
            på samme side, hvorfor du nemt får et godt overblik, og kan udføre en
            lånesammenligning.
          </p>
          <p>
            Samtlige lånetilbud er selvfølgelig uforpligtende, hvorfor du ikke er
            forpligtet til at acceptere et af de modtagne tilbud. Er der ikke et af de
            billige
            <b-link :to="$root.rn.LoansWithLowRate">lån med lav rente</b-link>, der
            appellerer til dig, kan du blot afvise dem alle.
          </p>
          <p>
            Når du modtager lånetilbud via Saverium.dk, er de selvfølgelig udarbejdet
            personligt til dig. De konkrete priser er derfor beregnet med udgangspunkt i
            din privatøkonomi, ønske om løbetid mv.
          </p>
          <p>
            Endvidere er det også vigtigt at understrege, at ingen af låneudbyderne har
            krav om sikkerhedsstillelse. Ønsker du at undgå dette, finder du sandsynligvis
            et
            <b-link :to="$root.rn.CheapLoan">billigt lån</b-link> til MC fra en af vores
            samarbejdspartnere, som tiltaler dig.
          </p>
          <p>
            Efter at have udfyldt din ansøgning, vil du modtage tilbud fra vores partnere,
            der omfatter Basisbank, Express Bank, Fellow Finance, Lån Let, Resurs Bank
            m.fl.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">Sikret eller usikret MC-lån – hvad bør jeg vælge?</h2>
          <p>
            Har du besluttet at optage et lån til køb af motorcykel, er der flere
            elementer, der skal overvejes nøje. For at finde det bedste lån for dit
            vedkommende, skal du først og fremmest tage stilling til, hvorvidt du ønsker
            at optage et sikret eller usikkert lån MC-lån.
          </p>
          <p>
            Et sikret lån er et lån, hvor långiver har pant i din motorcykel. Det vil
            sige, at långiver kan kræve, at du sælger motorcyklen, såfremt du kommer bagud
            med tilbagebetalingen af dit lån.
          </p>
          <p>
            Dermed er risikoen minimeret for långiver, da lånet er bundet op på et
            værdifuldt aktiv, der kan sælges, hvis betalingen misligholdes. Det betyder
            ofte, at du får tilbudt en mere fordelagtig rente, men kreditvurderingen er
            ofte også mere omfattende.
          </p>
          <p>
            Derfor minder det på flere punkter om det bedste boliglån eller
            <b-link :to="$root.rn.CarLoan">billån</b-link>. Det er nemlig også låntyper,
            der er tiltænkt finansieringen af det specifikke aktiv.
          </p>
          <p>
            Lånet bliver dog ikke bevilliget, hvis ikke du kan stille med 20 % i
            egenbetaling. Kan du det, vil långiver finansiere de resterende 80 % af
            motorcyklens købssum. Da du selv har betalt 20 % af motorcyklens værdi, og har
            stillet pant i aktivet, har långiver derfor en større sikkerhed.
          </p>
          <h3>Hvad er et usikret MC-lån?</h3>
          <p>
            Beslutter du derimod at optage et usikret lån til finansiering af dit
            motorcykel køb, vil du ikke skulle stille sikkerhed for dit lån.
          </p>
          <p>
            Til trods for, at dette lyder som det bedste MC lån, da det minimerer risikoen
            for at måtte sælge sin motorcykel, hvis man kommer bagud med betalingen, er
            der også ulemper.
          </p>
          <p>
            I og med, at långiver ikke har samme grad af sikkerhed, da der ikke er stillet
            pant i et værdifuldt aktiv, skal de naturligvis kompenseres for den øgede
            risiko. Det sker i form af højere renteomkostninger, hvorfor den bedste rente
            ikke fås med et usikret lån.
          </p>
          <p>
            I takt med den stigende konkurrence, er det dog blevet muligt at finde
            <b-link :to="$root.rn.UnsecuredLoans">usikrede lån</b-link> med lav rente. Det
            kan du blandt andet indhente tilbud på her på siden.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">Omkostninger ved et lån til motorcykel</h2>
          <p>
            Har du besluttet at låne penge til at købe en motorcykel, er der en række
            faktorer, der er vigtige at være opmærksom på, inden du kaster dig ud i dit
            låne eventyr. De har nemlig afgørende indflydelse på dit lån. Der er nemlig
            omkostninger ved et lån til motorcykel.
          </p>
          <ol>
            <li>ÅOP (Årlige Omkostninger i Procent)</li>
            <li>Lånets løbetid (Den periode, hvor du betaler af på lånet)</li>
            <li>Rente (Fast eller variabel rente)</li>
            <li>
              Udbetaling (Hvor stor en andel af motorcyklens købesum, du skal betale selv)
            </li>
          </ol>
          <h3>1. Årlige omkostninger</h3>
          <p>
            ÅOP er en forkortelse for Årlige Omkostninger i Procent. Som navnet rigtigt
            antyder, er det et tal, der viser de samlede årlige omkostninger i form af et
            procenttal. Inkluderet i dette tal er både engangsomkostninger og løbende
            omkostninger.
          </p>
          <p>
            På mange punkter er ÅOP med til at gøre det lettere for forbrugerne at
            sammenligne lån fra forskellige lånevirksomheder. Det skyldes, at samtlige
            omkostninger forbundet med lånet er inkluderet i ÅOP’en.
          </p>
          <p>
            Når du sammenligner
            <b-link :to="$root.rn.LoanOffers">forskellige lånetilbud</b-link> med henblik
            på at finde det billigste MC lån, er ÅOP oplagt at bruge. Vær dog opmærksom
            på, at det forudsætter, at lånene er identiske i forhold til låntype, løbetid,
            indfrielsesvilkår og skattemæssig behandling.
          </p>
          <h3>2. Lånets løbetid</h3>
          <p>
            Lånets løbetid er den periode, hvor du afdrager på dit lån. Det er
            selvfølgelig et parameter, der har afgørende betydning for den samlede pris på
            dit lån. Som udgangspunkt kan du forvente, at jo længere en løbetid lånet har,
            desto dyrere er det.
          </p>
          <p>
            Hvorvidt du skal vælge et lån med kort eller lang tilbagebetalingsperiode,
            afhænger af flere forskellige parametre. Ønsker du at sikre, at du får et
            billig MC lån, er det hensigtsmæssigt at vælge et lån med kort løbetid. Det
            minimerer nemlig renteomkostningerne betydeligt.
          </p>
          <p>
            Ønsker du derimod en lav månedlig ydelse, skal du i stedet binde dig til en
            længere løbetid. En længere løbetid vil nemlig resultere i et større økonomisk
            råderum, da du hver måned afdrager med et mindre beløb, end hvis du havde
            valgt en kortere løbetid.
          </p>
          <p>
            Før du beslutter dig for lånets løbetid, er det essentielt, at du har med i
            dine overvejelser, at en motorcykel mister værdi relativt hurtigt. Selv i
            tilfælde af, at du har købt en brugt motorcykel, er det derfor
            hensigtsmæssigt, at din gæld er indfriet hurtigst muligt.
          </p>
          <p>
            En oplagt mulighed er at bruge vores løbetider
            <b-link :to="$root.rn.LoanCalculator">beregner</b-link>. Hermed kan du se,
            hvordan forskellige løbetider påvirker lånets samlede omkostninger. Derfor:
            vil du have indblik i lånets totale omkostninger, alt afhængigt af længde på
            tilbagebetalingsperioden, så beregn løbetider.
          </p>
          <h3>3. Renter</h3>
          <p>
            Når man skal optage et MC lån, uanset hvilken type lån, der er tale om, er det
            vigtigt at tage stilling til, hvorvidt man ønsker fast eller variabel rente.
          </p>
          <p>
            Til trods for, at de fleste er af den opfattelse, at et
            <b-link :to="$root.rn.LoansWithLowRate">lån med fast lav rente</b-link> er den
            mest fordelagtige løsning, kan der også være fordele forbundet med en variabel
            rente. Derfor skal du overveje nøje, hvad der egner sig bedst til din
            situation.
          </p>
          <ul>
            <li>
              Fast rente: Vælger du et lån med en fast rente, er du garanteret, at din
              rente forbliver den samme i løbet af lånets løbetid. Derfor kender du også
              til lånets faktiske omkostninger på forhånd.
            </li>
            <li>
              Variabel rente: En variabel rente medfører derimod, at renten stiger og
              falder i løbet af lånets løbetid. Du tager derfor en chance, da det kan
              resultere i, at dit lån bliver enten dyrere eller billigere, alt afhængigt
              af om renten stiger eller falder.
            </li>
          </ul>
          <p>
            Når du skal vurdere, hvorvidt du skal vælge det
            <b-link :to="$root.rn.CheapLoan">billigste lån</b-link> med fast rente eller
            du skal satse på en variabel rente, handler det hovedsageligt om din
            risikovillighed.
          </p>
          <p>
            Beslutter du at acceptere et MC lån med en fast rente, får du en større
            sikkerhed, da renten er konstant under hele lånets løbetid. Dog er
            renteniveauet typisk højere, hvorfor du betaler for den øgede grad af
            sikkerhed, der følger med dette valg.
          </p>
          <p>
            Vælger du en variabel rente, kan du som regel få et billigt lån med lav rente.
            Du tager dog en chance, hvis du vælger denne løsning, for en stigning i den
            variable rente kan resultere i, at lånet bliver dyrere end med en fast rente.
          </p>
          <p>
            Falder den variable rente derimod, og vedholder et lavere niveau, er den
            økonomisk mere fordelagtig end en fast rente.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">Kræver et lån til MC altid en udbetaling?</h2>
          <p>
            Et MC-lån minder på mange punkter om et billån. Blandt andet er der mange
            långivere, der kræver, at du selv kan lægge en udbetaling. For långiver
            handler dette grundlæggende om sikkerhed, hvorfor mange banker og långivere
            kræver en udbetaling på 20 % af motorcyklens købssum.
          </p>
          <p>
            Det varierer dog mellem de mange udbydere, hvorvidt du skal lægge en
            udbetaling. Derudover er det også forskelligt fra aktør til aktør, hvor stor
            en udbetaling, der er påkrævet. Leder du efter et billig lån til MC med lav
            rente, er det selvfølgelig værd at holde øje med kravene til udbetalingen.
          </p>
          <p>
            Fordelen ved en større udbetaling er, at du får dit lån til MC billigt, da
            lånets samlede størrelse bliver mindre. I og med, at du mindsker det beløb, du
            skal låne, får du også et mindre beløb at afdrage på. Ulempen er naturligvis,
            at ikke alle er i besiddelse af den sum, der skal betales.
          </p>
          <p>
            Ovenstående er dog ikke ensbetydende med, at du er forpligtet til at betale en
            udbetaling. Der findes flere udbyder, der ikke stiller krav om dette, hvad
            enten du køber en dyr eller billig MC.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">Hvem kan bevilliges et lån til køb af motorcykel?</h2>
          <p>
            Når du skal <b-link :to="$root.rn.MainView">låne penge</b-link>, undgår du
            ikke, at der af långiver stilles en række krav til dig som låntager. Der er
            ganske vist forskel på mængden og omfanget af disse krav, alt afhængigt dit
            valg af lånevirksomheder.
          </p>
          <p>
            Har du intentioner om at optage et MC lån til finansiering af en motorcykel,
            kan du forvente, at der stilles krav til nedenstående:
          </p>
          <ul>
            <li>
              Krav til alder: Ønsker du at optage et lån til køb af motorcykel, er det et
              krav, at du er fyldt 18 år, da du ellers ikke er myndig. Det er dog ikke
              ensbetydende med, at alle aktører bevilliger MC-lån til 18-årige. Flere
              steder kræver de, at du er fyldt enten 21, 23 eller 25 år.
            </li>
            <li>
              Indkomst: Din indkomst kan have afgørende betydning for dine lånemuligheder.
              Der er flere långivere, der stiller krav til, at din årlige indkomst lever
              op til deres minimumskrav. Ligeledes har din indkomst også betydning for,
              hvor stort et lån, du kan få.
            </li>
            <li>
              Billedlegitimation: De fleste låneudbydere kræver, at både låneansøgning og
              låneaftale underskrives med NemID. På denne måde kan de både indhente
              relevante informationer om din økonomi fra SKAT, men også bekræfte, at du
              er, hvem du giver dig ud for at være.
            </li>
            <li>
              RKI: Er du registreret som dårlig betaler i RKI, er et lån til køb af
              motorcykel, hverken brugt eller ny motorcykel muligt. I et sådant tilfælde,
              er det dog ikke blot et lån til MC, der er umuligt – derimod er det umuligt
              at indgå en hvilken som helst låne- og kreditaftale.
            </li>
          </ul>
          <h3>Din kreditvurdering er afgørende for dine lånemuligheder</h3>
          <p>
            Når du ansøger om et MC lån, foretager långiveren en kreditvurdering af dig.
            Det er gældende for alle lån i Danmark, og ikke blot i forbindelse med MC-lån.
            Denne vurdering er selvfølgelig afgørende for dine lånemuligheder, da den
            definerer den tilbudte rente, potentielt lånebeløb mv.
          </p>
          <p>
            Hvis du skal låne penge, er der derfor ikke nogen vej udenom denne vurdering.
            Dog er der forskel på, hvor grundigt den udføres hos de forskellige udbydere.
            Er der tale om et stort lån, vil det også medføre en mere omfattende
            kreditvurdering.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">Kan man låne penge til alle motorcykler?</h2>
          <p>
            Som udgangspunkt er det muligt at låne penge til enhver motorcykel. Har du
            fundet en billig motorcykel, du gerne vil købe, kan du sagtens få et lån med
            lav rente, der kan hjælpe dig med at realisere drømmen om den pågældende
            motorcykel.
          </p>
          <p>
            Det samme er desuden gældende, hvad enten det er en dyr motorcykel, en brugt
            MC mv. De fleste motorcykel lån bliver udstedt til køb af brugte motorcykler,
            men du kan selvfølgelig også sagtens få et lån på 100.000 kroner e.l. til køb
            af en ny motorcykel.
          </p>
          <p>
            Omvendt kan det også være, at den billige MC du har kigget på, er til salg til
            30.000 kr. I så fald er det ikke utænkeligt, at du kan få et lån på 30000
            kroner, hvorved du undgår at lægge en udbetaling – såfremt det er noget, du
            ønsker at undgå, da du naturligvis selv bestemmer.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  data() {
    return {
      contents: [
        {
          title: 'Lån penge til MC igennem Saverium.dk',
          link: '#1',
        },
        {
          title: 'Sikret eller usikret MC-lån – hvad bør jeg vælge?',
          link: '#2',
        },
        {
          title: 'Omkostninger ved et lån til motorcykel',
          link: '#3',
        },
        {
          title: 'Kræver et lån til MC altid en udbetaling?',
          link: '#4',
        },
        {
          title: 'Hvem kan bevilliges et lån til køb af motorcykel?',
          link: '#5',
        },
        {
          title: 'Kan man låne penge til alle motorcykler?',
          link: '#6',
        },
      ],
    }
  },

  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
