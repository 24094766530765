export const loanAmounts = []
let i = 0
while (i < 5000) {
  for (i = 100; i <= 5000; i += 100) {
    loanAmounts.push(i)
  }
}

while (i >= 5000 && i <= 200000) {
  for (i = 5000; i <= 200000; i += 500) {
    loanAmounts.push(i)
  }
}

export const loanTimeOptions = [
  { value: '2', text: '2 miesiące' },
  { value: '3', text: '3 miesiące' },
  { value: '4', text: '4 miesiące' },
  { value: '5', text: '5 miesięcy' },
  { value: '6', text: '6 miesięcy' },
  { value: '7', text: '7 miesięcy' },
  { value: '8', text: '8 miesięcy' },
  { value: '9', text: '9 miesięcy' },
  { value: '10', text: '10 miesięcy' },
  { value: '11', text: '11 miesięcy' },
  { value: '12', text: '12 miesiące' },
  { value: '13', text: '13 miesiące' },
  { value: '14', text: '14 miesiące' },
  { value: '15', text: '15 miesięcy' },
  { value: '16', text: '16 miesięcy' },
  { value: '17', text: '17 miesięcy' },
  { value: '18', text: '18 miesięcy' },
  { value: '19', text: '19 miesięcy' },
  { value: '20', text: '20 miesięcy' },
  { value: '21', text: '21 miesięcy' },
  { value: '22', text: '22 miesiące' },
  { value: '23', text: '23 miesiące' },
  { value: '24', text: '24 miesiące' },
  { value: '25', text: '25 miesięcy' },
  { value: '26', text: '26 miesięcy' },
  { value: '27', text: '27 miesięcy' },
  { value: '28', text: '28 miesięcy' },
  { value: '29', text: '29 miesięcy' },
  { value: '30', text: '30 miesięcy' },
  { value: '31', text: '31 miesięcy' },
  { value: '32', text: '32 miesiące' },
  { value: '33', text: '33 miesiące' },
  { value: '34', text: '34 miesiące' },
  { value: '35', text: '35 miesięcy' },
  { value: '36', text: '36 miesięcy' },
  { value: '37', text: '37 miesięcy' },
  { value: '38', text: '38 miesięcy' },
  { value: '39', text: '39 miesięcy' },
  { value: '40', text: '40 miesięcy' },
  { value: '41', text: '41 miesięcy' },
  { value: '42', text: '42 miesiące' },
  { value: '43', text: '43 miesiące' },
  { value: '44', text: '44 miesiące' },
  { value: '45', text: '45 miesięcy' },
  { value: '46', text: '46 miesięcy' },
  { value: '47', text: '47 miesięcy' },
  { value: '48', text: '48 miesięcy' },
  { value: '49', text: '49 miesięcy' },
  { value: '50', text: '50 miesięcy' },
  { value: '51', text: '51 miesięcy' },
  { value: '52', text: '52 miesiące' },
  { value: '53', text: '53 miesiące' },
  { value: '54', text: '54 miesiące' },
  { value: '55', text: '55 miesięcy' },
  { value: '56', text: '56 miesięcy' },
  { value: '57', text: '57 miesięcy' },
  { value: '58', text: '58 miesięcy' },
  { value: '59', text: '59 miesięcy' },
  { value: '60', text: '60 miesięcy' },
  { value: '61', text: '61 miesięcy' },
  { value: '62', text: '62 miesiące' },
  { value: '63', text: '63 miesiące' },
  { value: '64', text: '64 miesiące' },
  { value: '65', text: '65 miesięcy' },
  { value: '66', text: '66 miesięcy' },
  { value: '67', text: '67 miesięcy' },
  { value: '68', text: '68 miesięcy' },
  { value: '69', text: '69 miesięcy' },
  { value: '70', text: '70 miesięcy' },
  { value: '71', text: '71 miesięcy' },
  { value: '72', text: '72 miesiące' },
  { value: '73', text: '73 miesiące' },
  { value: '74', text: '74 miesiące' },
  { value: '75', text: '75 miesięcy' },
  { value: '76', text: '76 miesięcy' },
  { value: '77', text: '77 miesięcy' },
  { value: '78', text: '78 miesięcy' },
  { value: '79', text: '79 miesięcy' },
  { value: '80', text: '80 miesięcy' },
  { value: '81', text: '81 miesięcy' },
  { value: '82', text: '82 miesiące' },
  { value: '83', text: '83 miesiące' },
  { value: '84', text: '84 miesiące' },
  { value: '85', text: '85 miesięcy' },
  { value: '86', text: '86 miesięcy' },
  { value: '87', text: '87 miesięcy' },
  { value: '88', text: '88 miesięcy' },
  { value: '89', text: '89 miesięcy' },
  { value: '90', text: '90 miesięcy' },
  { value: '91', text: '91 miesięcy' },
  { value: '92', text: '92 miesiące' },
  { value: '93', text: '93 miesiące' },
  { value: '94', text: '94 miesiące' },
  { value: '95', text: '95 miesięcy' },
  { value: '96', text: '96 miesięcy' },
  { value: '97', text: '97 miesięcy' },
  { value: '98', text: '98 miesięcy' },
  { value: '99', text: '99 miesięcy' },
  { value: '100', text: '100 miesięcy' },
  { value: '101', text: '101 miesięcy' },
  { value: '102', text: '102 miesiące' },
  { value: '103', text: '103 miesiące' },
  { value: '104', text: '104 miesiące' },
  { value: '105', text: '105 miesięcy' },
  { value: '106', text: '106 miesięcy' },
  { value: '107', text: '107 miesięcy' },
  { value: '108', text: '108 miesięcy' },
  { value: '109', text: '109 miesięcy' },
  { value: '110', text: '110 miesięcy' },
  { value: '111', text: '111 miesięcy' },
  { value: '112', text: '112 miesiące' },
  { value: '113', text: '113 miesiące' },
  { value: '114', text: '114 miesiące' },
  { value: '115', text: '115 miesięcy' },
  { value: '116', text: '116 miesięcy' },
  { value: '117', text: '117 miesięcy' },
  { value: '118', text: '118 miesięcy' },
  { value: '119', text: '119 miesięcy' },
  { value: '120', text: '120 miesięcy' },
]

export const dependentsOptions = [
  { value: '0', text: '0' },
  { value: '1', text: '1' },
  { value: '2', text: '2' },
  { value: '3', text: '3' },
  { value: '4', text: '4' },
  { value: '5', text: '5' },
  { value: '6', text: '6' },
  { value: '7', text: '7' },
  { value: '8', text: '8' },
]
