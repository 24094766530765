<template>
    <b-container class="text-center">
        {{ $t('thankyou_page') }}
        <br />
        <div class="btn link">
          <a @click="logout">
            Kirjaudu ulos
          </a>
        </div>
    </b-container>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style scoped>
  .link {
    font-size: 16px;
    color: #2a76bc;
    cursor: pointer;
  }
</style>
