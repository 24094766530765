<template>
  <div class="row article">
    <h1 class="center-title">Pożyczka na 12 miesięcy – pożyczki przez internet na rok</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i>
              Pożyczki chwilówki online najczęściej kojarzone są z krótkoterminowymi
              zobowiązaniami, które doraźnie pomogą uzupełnić twój budżet domowy. Możesz
              je wydać na dowolne cele. Warto jednak również wiedzieć, że istnieją
              <b-link :to="$root.rn.LoansForProof"
                >pożyczki na dowód przez internet</b-link
              >
              z dłuższym terminem spłaty, na przykład pożyczki na 12 miesięcy.
            </i>
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/loan-for-year-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/loan-for-year.jpg"
            alt="Pożyczka na 12 miesięcy – pożyczki przez internet na rok"
          />

          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            Długoterminowe
            <b-link :to="$root.rn.LoanWithoutBik">pożyczki bez BIK-u</b-link> na 12
            miesięcy są oferowane przez wiele firm pozabankowych w Polsce. Są one
            stosunkowo łatwo dostępne, zwłaszcza w porównaniu z bankowymi
            <b-link :to="$root.rn.CashLoan">kredytami gotówkowymi.</b-link> Możesz
            zaciągnąć <b-link :to="$root.rn.LoanInstallment">pożyczkę ratalną</b-link> na
            12 miesięcy, którą będziesz stopniowo spłacać, co odciąży Twój budżet domowy.
          </p>

          <p>
            Wypłatę pożyczki na 12 miesięcy online dostaniesz w formie bezgotówkowej, na
            konto bankowe wskazane we wniosku.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Chcesz zaciągnąć pożyczkę na dowód na 12 miesięcy? Komu w szczególności są one
            polecane? To dobre rozwiązanie kłopotów finansowych, kiedy nie masz
            oszczędności, by móc pokryć bieżące wydatki. Pożyczka na rok online jest
            oferowana zwykle przez firmy pozabankowe bez szczegółowej weryfikacji klienta.
            Wystarczy, że wypełnisz prosty wniosek i spełnisz niewielkie wymagania.
            Musisz:
          </p>
          <ul>
            <li>
              Mieć ukończone co najmniej 18 lat i posiadać pełną zdolnością do czynności
              prawnych.
            </li>
            <li>Mieć stały adres w Polsce.</li>
            <li>Mieć konto bankowe założone na swoje dane.</li>
            <li>Mieć odpowiednio wysoką zdolność kredytową.</li>
            <li>Podać numer twojego telefonu komórkowego.</li>
            <li>
              Być osobą, która nie funkcjonuje jako nierzetelny dłużnik Biura Informacji
              Kredytowej czy baz biur informacji gospodarczych.
            </li>
          </ul>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Czy pożyczka na 12 miesięcy to dobry pomysł dla Ciebie? Tak, możesz ją
            zaciągnąć szybko, sprawnie, bez konieczności osobistej wizyty w stacjonarnej
            siedzibie firmy pozabankowej. Większość marek z tej branży udzieli Ci pożyczki
            chwilówki na 12 miesięcy w bardzo krótkim czasie, nawet w kilkanaście minut od
            chwili złożenia wniosku. Poza tym, unikniesz kontroli swojej historii
            kredytowania w BIK-u, choć uważaj – czasem firmy udzielające chwilówki na 12
            miesięcy sięgają do tej bazy danych na zasadach wzajemnej wymiany informacji,
            choć traktują wynik scoringu BIK-u w sposób bardziej liberalny niż instytucje
            bankowe.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Chcesz ułatwić sobie sprawę i szybko znaleźć pożyczkę na 12 miesięcy? Nie
            chcesz tracić czasu na składanie kilku czy kilkunastu wniosków? Odpowiedzią na
            Twoje oczekiwania jest oferta Saverium. Nasza platforma pozwoli Ci zgromadzić
            co najmniej kilka ofert dopasowanych indywidualnie do Twoich potrzeb. Jedyne,
            co musisz zrobić, to wpisać kwotę pożądanej
            <b-link :to="$root.rn.OnlineLongtermLoans"
              >pożyczki długoterminowej online</b-link
            >, a także okres kredytowania i cel, jaki chcesz sfinansować za jej
            pośrednictwem. Po podaniu swoich podstawowych danych, Saverium wyśle zapytania
            do swoich partnerów biznesowych – czołowych firm pozabankowych. Szybko
            uzyskasz wykaz konkurencyjnych propozycji finansowania zewnętrznego z
            wykorzystaniem pożyczki na 12 miesięcy przez internet. W istocie Saverium nie
            jest pożyczkodawcą, ale pośrednikiem ułatwiającym znalezienie Ci
            najkorzystniejszej w danej chwili oferty.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            Jeśli zależy Ci na bezpieczeństwie finansowym przy zaciąganiu pożyczki
            chwilówki na 12 miesięcy, skorzystaj z oferty firm pozabankowych, które na
            pewno prowadzą legalnie działalność na polskim rynku finansowym. Jak to
            zweryfikować? Nie musisz robić tego samodzielnie. Jeżeli zdecydujesz się na
            propozycję jednego z partnerów platformy Saverium, to masz pewność, że został
            on wcześniej sprawdzony pod tym kątem. Firmy pożyczkowe mają obecnie obowiązek
            rejestrowania się w rejestrze instytucji pożyczkowych prowadzonym przez
            Komisję Nadzoru Finansowego. Saverium jest tylko pośrednikiem i ostatecznie
            podpisujesz umowę na pożyczkę pozabankową na 12 miesięcy z wybraną instytucją
            pożyczkową. Wybrana instytucja powinna zweryfikować Twoją tożsamość, na
            przykład za pomocą prostego przelewu na symboliczną kwotę 1 zł lub 1 gr. Ma to
            na celu upewnienie się, czy osoba wpisana we wniosku jest rzeczywiście tym, za
            kogo się podaje. Kontrolowana jest zgodność danych z przelewu weryfikacyjnego
            i tych umieszczonych przez Ciebie we wniosku o
            <b-link :to="$root.rn.LoansToAccount"
              >chwilówkę z wypłatą na konto bankowe</b-link
            >. By taka weryfikacja była możliwa i przebiegła poprawnie, rachunek musi być
            założony na Twoje dane.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            Jakie są najważniejsze kwestie związane z szybką pożyczką przez internet na 12
            miesięcy? Szukając oferty, sprawdź, ile zapłacisz za takie zobowiązanie.
            Chodzi tu nie tylko o wysokość oprocentowania w skali roku, ale i o całkowite
            koszty kredytowania czy poziom RRSO – Rocznej Rzeczywistej Stopy
            Oprocentowania. To te dwa parametry, które oznaczysz w ofertach pożyczek
            gotówkowych na 12 miesięcy, powinny być w centrum Twojego zainteresowania, bo
            informują Cię o atrakcyjności zobowiązania i o tym, ile za nie zapłacisz – w
            wymiarze kwotowym lub procentowym.
          </p>
          <p>
            Najprawdopodobniej żadna szybka pożyczka przez internet na 12 miesięcy nie
            będzie bezpłatna, ale możesz wybrać taką, w przypadku której zapłacisz
            możliwie jak najmniej odsetek. Pamiętaj, by ustalić dogodny dla Ciebie termin
            spłaty rat, o ile masz do czynienia z ofertami na raty.
          </p>
        </div>
        <div class="text-container">
          <h2 id="7">{{ contents[6].title }}</h2>
          <p>
            Zależy Ci na tym, by zaciągnąć zobowiązanie długoterminowe? Nie musisz
            rezygnować z sektora
            <b-link :to="$root.rn.OnlinePaydayLoans">pożyczek chwilówek online</b-link>.
            Również i firmy działające w tej branży są w stanie zaoferować Ci propozycję
            pozabankową na 12 miesięcy, z prostym procesem weryfikacji Twojej tożsamości i
            <b-link :to="$root.rn.CreditWorthiness">zdolności kredytowej</b-link>. Wypłatę
            pożyczki online na 12 miesięcy otrzymasz w możliwie jak najkrótszym czasie,
            nawet w ciągu kilkunastu minut od chwili złożenia wniosku, bezpośrednio na
            wskazane konto bankowe. Wnioskowanie o
            <b-link :to="$root.rn.InstantInternetLoans">pożyczkę od ręki</b-link> za
            pośrednictwem Saverium jest proste i bezpieczne. Wypełniasz tylko jeden
            wniosek, a my przedstawiamy Ci co najmniej kilka propozycji interesującej Cię
            pożyczki. Z naszą pomocą, w sposób bezpieczy pożyczysz potrzebną kwotę na
            okres 12 miesięcy.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'Pożyczka na 12 miesięcy przez internet',
          link: '#1',
        },
        {
          title: 'Dla kogo pożyczka na 12 miesięcy?',
          link: '#2',
        },
        {
          title: 'Jakie są zalety pożyczek pozabankowych na 12 miesięcy?',
          link: '#3',
        },
        {
          title: 'Jak wziąć pożyczkę na 12 miesięcy z Saverium?',
          link: '#4',
        },
        {
          title: 'Pożyczka na 12 miesięcy – bezpieczeństwo',
          link: '#5',
        },
        {
          title: 'Pożyczka na 12 miesięcy – co trzeba wiedzieć?',
          link: '#6',
        },
        {
          title: 'Pożyczka na 12 miesięcy – podsumowanie',
          link: '#7',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped></style>
