<template lang="html">
  <div class="main">
    <div class="container col-lg-8">
      <h5 class="mt-5 text-center px-2">
        {{ $t('complete_the_loan_application_absolutely_for_free') }}
      </h5>
      <h6 class="mb-5  text-center px-2">
        {{ '(' + $t('you_will_reveice_an_immediate_loan_decision') + ')' }}
      </h6>
      <div class="alert alert-danger" ref="hasErrors" role="alert" v-if="hasErrors">
        {{ $t('check_the_fields_marked_in_red') }}
      </div>
      <h2 class="mb-3 text-center">
        {{ $t('loan_details') }}
      </h2>
      <div class="row">
        <div class="form-group col-sm-6">
          <form-input
            storekey="amount"
            :items="loanSumOptions"
            type="dropdown"
            id="loanamount"
          >
            {{ $t('loan_amount') }}
          </form-input>
        </div>
        <div class="form-group col-sm-6">
          <form-input
            storekey="repayment_time"
            :items="loanTimeOptions"
            type="dropdown"
            id="loanperiod"
          >
            {{ $t('repayment_period') }}
          </form-input>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <span
            >{{ $t('monthly_fee') }} * <br /><vue-numeric
              separator="."
              currency="€"
              :read-only="true"
              :value="computedLoans.costMonthly.toFixed(0)"
            />
          </span>
        </div>
        <div class="form-group col-sm-6">
          <span
            >{{ $t('interest_and_commissions') }} * <br /><vue-numeric
              separator="."
              currency="€"
              :read-only="true"
              :value="computedLoans.cost.toFixed(0)"
            />
          </span>
        </div>
      </div>
      <h2 class="mb-3 text-center">
        {{ $t('personal_information') }}
      </h2>
      <div class="row">
        <div class="form-group col-sm-6">
          <form-input storekey="email" type="email" id="email">
            {{ $t('email_address') }}
          </form-input>
        </div>
        <div class="form-group col-sm-6">
          <form-input storekey="phone" type="phoneEs" id="phone">
            {{ $t('phone_number') }}
          </form-input>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <form-input storekey="firstName" type="text" id="firstName">
            {{ $t('first_name') }}
          </form-input>
        </div>
        <div class="form-group col-sm-6">
          <form-input storekey="lastName" type="text" id="lastName">
            {{ $t('last_name') }}
          </form-input>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <form-input storekey="dateOfBirth" type="date" id="birthdate">
            {{ $t('birth_date') }}
          </form-input>
        </div>
        <div class="form-group col-sm-6">
          <form-input storekey="ssn" type="text" id="ssn">
            {{ $t('dni_nie') }}
          </form-input>
        </div>
      </div>
      <div class="form-check col-12 mb-3 pl-5">
        <form-input type="checkbox" storekey="tosAccepted" id="tosaccepted">
          {{ $t('i_confirm') + ' ' }}
          <b-link :to="$root.rn.TosPDF" target="_blank">{{
            $t('general_conditions')
          }}</b-link>
          {{ $t('and') + ' ' }}
          <b-link :to="$root.rn.PrivacyPolicyPDF" target="_blank">{{
            $t('privacy_policy') + '.'
          }}</b-link>
        </form-input>
      </div>
      <div class="form-check col-12 mb-3 pl-5">
        <form-input type="checkbox" storekey="marketing">
          {{ $t('i_confirm_marketing') }}
        </form-input>
      </div>
      <div class="col-12 mb-5 btn-row">
        <div class="submit-btn d-sm-block">
          <div class="spinner-wrapper" v-if="loading">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <button
            v-else
            class="btn btn-success"
            name="submitLoan"
            id="submitLoan"
            @click="submit()"
          >
            {{ $t('apply_for_a_loan_arrow') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loanTimeOptions } from '@/utils/saverium_es/const'
import { generateAmountSteps, getApproximateLoanValues } from '@/utils/utils'
import { gsap } from 'gsap'
import FormInput from '@/components/form/FormInput'
import { mapState } from 'vuex'
import VueNumeric from 'vue-numeric'

export default {
  data() {
    return {
      cost: 0,
      costMonthly: 0,
    }
  },
  components: {
    FormInput,
    VueNumeric,
  },
  computed: {
    ...mapState({
      errors: state => state.application.errors,
      requiredFields: state => state.application.requiredFields,
      canProceed: state => state.application.formCanProceed,
      loading: state => state.application.sendingApplication,
    }),
    hasErrors() {
      return Object.keys(this.errors).length > 0
    },
    loanTimeOptions() {
      return loanTimeOptions
    },
    loanSumOptions() {
      let options = generateAmountSteps(5000, 100, 100)
      options = options.concat(generateAmountSteps(10000, 5000, 500))
      options = options.concat(generateAmountSteps(60500, 10000, 500))
      return options
    },
    computedLoans() {
      let delay = 0.5
      let computed = getApproximateLoanValues(
        this.$store.state.application.amount,
        this.$store.state.application.repayment_time
      )
      gsap.to(this.$data, { costMonthly: computed.costMonthly, duration: delay })
      gsap.to(this.$data, { cost: computed.cost, duration: delay })
      return this.$data
    },
  },
  watch: {
    hasErrors(newV, oldV) {
      this.checkErrors()
    },
  },
  created() {
    if (this.hasErrors) {
      this.checkErrors()
    }
  },
  methods: {
    checkErrors() {
      return this.errors
    },
    submit() {
      this.$store.dispatch('checkErrors', this.current)
      this.checkErrors()
      if (this.hasErrors) {
        return
      }

      if (typeof ga !== 'undefined') {
        ga('send', 'pageview')
      }
      this.$store.dispatch('sendApplication')
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-row {
  height: 40px;
}

.btn,
.submit-btn {
  width: 40%;
}

.next-btn,
.submit-btn {
  float: right;
}

.submit-btn {
  height: 40px;
  .btn {
    width: 100%;
  }
}

.form-progress {
  width: 80%;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}
.spinner-wrapper {
  font-size: 60px;
  text-align: center;
}
</style>
