<template>
  <div class="main-container">
    <div class="main">
      <b-container class="main-container">
        <p>{{ $t('you_have_logged_out') }}</p>
        <div class="btn link">
          <b-link :to="$root.rn.LoginView" class="link">
            {{ $t('login') }}
          </b-link>
        </div>
      </b-container>
    </div>
    <div class="main">
      <main-form-view />
    </div>
  </div>
</template>

<script>
import MainFormView from '@/components/lainaayritykselle_fi/newForm/MainFormView'

export default {
  components: {
    MainFormView,
  },
}
</script>

<style scoped>
.main {
  width: 100%;
  position: relative;
}
.main-container .container {
  margin-bottom: 5%;
  text-align: center;
  background-color: #e5eaef;
  padding-top: 5px;
}
.image {
  width: 100%;
  margin-top: -10vw;
}
@media screen and (min-width: 1800px) {
  .image {
    margin-top: -20vw;
  }
}
@media screen and (min-width: 2100px) {
  .image {
    margin-top: -30vw;
  }
}
.secondary-title {
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 10px;
  font-weight: 500;
}

.bold {
  font-weight: 500;
}
</style>
