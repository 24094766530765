<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til scooter</h1>
      <div class="top-text text-container">
        <p>
          Leder du efter et nyt lån til en scooter, så kan du nemt gøre det her, online og
          uden at stille sikkerhed. Du kan selv bestemme, om du ønsker at bruge lånet til
          en brugt scooter eller en gammel knallert. For at finde det bedste lån til en ny
          scooter online, kan du ansøge om et normalt forbrugslån, som kan bruges til
          finansiering af din nye scooter, som du står og skal købe.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li><a href="#hvordan-ansoeger-jeg">Hvordan ansøger jeg?</a></li>
                <li><a href="#hvorfor-boer-jeg-ansoege">Hvorfor bør jeg ansøge?</a></li>
                <li>
                  <a href="#hvordan-ansoeger-jeg-om-det-bedste-laan-til-scooter"
                    >Hvordan ansøger jeg om det bedste lån til scooter?</a
                  >
                </li>
                <li>
                  <a href="#hvor-billigt-kan-det-blive">Hvor billigt kan det blive?</a>
                </li>
                <li>
                  <a href="#hvad-kan-jeg-bruge-et-scooter-laan-til"
                    >Hvad kan jeg bruge et scooter lån til?</a
                  >
                </li>
                <li><a href="#sammenlign-scooterlaan">Sammenlign scooterlån</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="hvordan-ansoeger-jeg">Hvordan ansøger jeg?</h2>
          <p>
            Det eneste du skal gøre, er at udfylde vores gratis og uforpligtende online
            låneansøgning herunder. Herefter fremsender vi ansøgningen til de forskellige
            låneudbydere, som vi arbejder sammen med. Derefter vil du med det samme få
            svar på din ansøgning, hvor du så kan sammenligne dine forskellige lånetilbud
            til scooter fra vores samarbejdspartnere.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvorfor-boer-jeg-ansoege">Hvorfor bør jeg ansøge?</h2>
          <p>
            Hvis du vil lave en ansøgning om et lån til scooter, skal du blot have adgang
            til internet på en tablet, mobil eller computer. Det er meget enkelt og
            hurtigt at udfylde ansøgning om et lånet til din scooter, da det blot tager få
            minutter. Herefter vil du modtage svar på din låneansøgning med det samme.
          </p>
          <p>
            Hvis du søger finansiering til en scooter i en fysisk bank, skal du til at
            stille sikkerhed for dit lån. Det slipper du heldigvis for ved at søge om et
            <a
              href="https://www.saverium.dk/laan-penge/online-laan"
              rel="noopener noreferrer"
              target="_blank"
              >lån online</a
            >, da online
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere</a
            >
            sjældent blander sig i, hvad du skal bruge dit
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >nye lån</a
            >
            til. Du kan derfor sagtens bruge dit lån til at købe en ny Puch Maxi eller en
            el-scooter, hvis det er det, som du behøver.
          </p>
          <p>
            Hvis du mangler pengene til en ny scooter, vil det være en god ide at ansøge
            online, da du kan optage et nyt
            <a
              href="https://www.saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtigt lån</a
            >
            som kan udbetales straks.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvordan-ansoeger-jeg-om-det-bedste-laan-til-scooter">
            Hvordan ansøger jeg om det bedste lån til scooter?
          </h2>
          <p>
            Den nemmeste måde at finde det bedste lån til scooter, er ved at ansøge
            igennem en online sammenligningstjeneste som vores. På den måde sparer du tid,
            og du når hurtigere ud til flere forskellige velkendte låneudbydere og banker,
            end hvis du selv skulle til og lave en ansøgning til hver enkelt låneudbyder.
          </p>
          <p>
            Når du har afsendt din ansøgning, gør vi arbejdet for dig ved at indhente
            skræddersyet lånetilbud til scooter inden for ét minut. Derefter skal du blot
            sammenligne de forskellige
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >lånetilbud</a
            >
            til scooter, som du har modtaget fra vores samarbejdspartnere.
          </p>
          <p>
            Når du har valgt dit ønskede lånetilbud vil du blive omdirigeret til selve
            låneudbyderen, hvor du skal give samtykke til, at de må lave en
            kreditvurdering af dig. Efter det er sket, vil du blive kontaktet af
            udbyderen, som du valgte, der nu udbetaler lånet til din konto, med mindre at
            du blev afvist.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvor-billigt-kan-det-blive">Hvor billigt kan det blive?</h2>
          <p>
            Det er en rigtig god ide at anvende en lånesammenligningstjenseste som vores,
            da du derved har større sandsynlighed for at finde et billigt lån til en ny
            scooter med en fast lav rente. Via vores platform kan du skabe dig et overblik
            over de forskellige lånetilbud fra vores udvalg af låneudbydere, som du kan få
            til at købe en brugt knallert eller dele til din scooter. På den måde kan du
            vælge det bedste finansieringstilbud til en scooter, som passer til dig og din
            økonomi, ved at få et skræddersyet
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >et billigt forbrugslån</a
            >
            til en scooter, som lever op til dine krav.
          </p>
          <p>
            Ved at følge ovenstående eksempel sikrer du dig at finde det bedste tilbud,
            når du leder efter et billigt lån til scooter, i modsætning til at vælge den
            første og bedste mulighed, som du skulle falde over.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-kan-jeg-bruge-et-scooter-laan-til">
            Hvad kan jeg bruge et scooter lån til?
          </h2>
          <p>
            Ansøger du om et scooterlån online, kan du faktisk bruge lånet, til netop det
            du går og skal bruge pengene til. Om det er en gammel Puch Maxi, PGO, ny
            udstødning, sidespejle eller reservedele til din scooter, så kan du bruge dit
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >nye lån</a
            >
            til scooter, til hvad du mangler.
          </p>
          <p>
            Det kan være alt fra en ny scooter ved en forhandler eller en brugt knallert
            du vil købe på internettet eller igennem en ven. Det kan være du har brug for
            at låne til at skifte nogle dele på din Aprilia Sonic eller mangler
            finansiering til købet af en ny PGO. Når du optager et lån, skal du ikke
            dokumentere hvad lånet skal bruges til. Det vigtigste for
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbyderne</a
            >
            er, at du som låntager kan tilbagebetale lånet indenfor den aftalte løbetid.
          </p>
        </div>

        <div class="text-container">
          <h2 id="sammenlign-scooterlaan">Sammenlign scooterlån</h2>
          <p>
            En af de bedste måder at sikre sig, at man finder et
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >billigt lån</a
            >
            til en ny scooter, er at
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne lån online</a
            >. Det kan man gøre gratis og uforpligtende her på vores hjemmeside. Det tager
            kun få minutter at udfylde vores gratis online låneansøgning, som man får en
            hurtig tilbagemelding på.
          </p>
          <p>
            Efter at du har udfyldt alle de nødvendige felter og har trykket “Send
            låneansøgning”, klarer vi arbejdet for dig, ved at fremsende din ansøgning til
            alle vores samarbejdspartnere. Efter få minutter vil vi sende dig en email med
            de forskellige lånetilbud til scooter, som du har modtaget. Herefter kan du gå
            ind og sammenligne dine lånetilbud nemt og overskueligt.
          </p>
          <p>
            Når du har valgt et af dine modtagende lånetilbud, vil vi forbinde dig til
            selve låneudbyderen, ved at omdirigere dig over på deres hjemmeside hvor du
            skal give samtykke med
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >NemID/MitID</a
            >, så de kan lave en kreditvurdering af dig. Efter kreditvurdering er fortaget
            vil du få svar på, om du er godkendt eller afvist til finansiering af din
            scooter. Skulle du blive godkendt vil vi, samt låneudbyderen, sende dig en
            bekræftelse på alt er i orden, og lånet vil blive udbetalt til din bankkonto
            så hurtigt som muligt, alt afhængig af hvilken låneudbydere du vælger til dit
            nye scooterlån.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },

  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
