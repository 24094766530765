import { render, staticRenderFns } from "./LoanFor35000.vue?vue&type=template&id=652f76d6&scoped=true&"
import script from "./LoanFor35000.vue?vue&type=script&lang=js&"
export * from "./LoanFor35000.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "652f76d6",
  null
  
)

export default component.exports