import { findMatchAmount, findAmountByIndex, validAmountToText } from '@/utils/utils'
import _ from 'lodash'
import applicationActions from '@/store/actions/applicationActions'
const mappings = {
  first_name: 'firstName',
  last_name: 'lastName',
  loan_purpose: 'loanPurpose',
  dwelling_date: 'dwellingDate',
  marital_status: 'civilstatus',
  co_living: 'numberOfPeople',
  monthly_gross_income: 'monthlySalary',
  monthly_net_income: 'monthlyNetIncome',
  monthly_other: 'otherIncome',
  monthly_other_source: 'otherIncomeReason',
  household_discretionary_income: 'funds_available',
  dwelling_expenses: 'costOfLiving',
  dwelling_type: 'dwellingType',
  account_number: 'bankAccount',
  existing_loans: 'existingLoans',
  time_term: 'repayment_time',
  has_summer_house: 'hasSummerHouse',
  has_registered_address: 'hasRegisteredAddress,',
  zip_code: 'zipCode',
  bank_name: 'bankName',
  account_holder: 'accountHolder',
  iban: 'bankAccount',
  account_type: 'accountType',
}
const employmentMappings = {
  city: 'workCity',
  phone: 'workPhone',
  zip_code: 'workZipCode',
  business_id: 'businessId',
  employment_type: 'occupation',
  opened_date: 'openedDate',
  street: 'workStreetAddress',
}
const state = {
  currentStep: 0,
  maxStep: 0,
  requiredFields: [
    [
      // Step 1
      'loanPurpose',
      'tos',
    ],
    [
      // Step 2
      'email',
      'phone',
      'firstName',
      'lastName',
      'idnumber',
      'ssn',
      'education',
      'civilstatus',
      'dependants',
    ],
    [
      // Step 3
      'region',
      'street',
      'houseNumber',
      'zipCode',
      'city',
      'dwelling',
      'dwellingDate',
      'numberOfPeople',
    ],
    [
      // Step 4
      'occupation',
      'monthlyNetIncome',
    ],
    [
      // Step 5
      'costOfLiving',
    ],
    [
      // Step 6
      'bankAccount',
      'bankName',
      'accountType',

      'personalDataPolicy',

      'marketingConsents',
      'acceptAll',
    ],
  ],

  amount: '5000',
  amount_label: '5 000 zł',
  amount_index: 49,
  repayment_time: '24',
  firstName: '',
  lastName: '',
  email: '',
  idnumber: '',
  ssn: '',
  phone: '',
  // address
  street: '',
  houseNumber: '',
  apartmentNumber: '',
  zipCode: '',
  city: '',
  region: '',
  workStreetAddress: '',
  workCity: '',
  workZipCode: '',
  workPhone: '',
  dwellingArea: '',
  hasRegisteredAddress: false,
  registeredStreet: '',
  registeredHouseNumber: '',
  registeredZipCode: '',
  registeredApartmentNumber: '',
  registeredCity: '',
  registeredRegion: '',
  dwelling: 1,
  dwellingType: 1,
  dwellingDate: '',
  numberOfPeople: '',
  hasSummerHouse: 0,
  costOfLiving: '',
  otherMonthlyExpenses: 0,

  civilstatus: '',
  dependants: '',

  education: '',
  occupation: '',
  employer: '',
  businessId: '',
  profession: '',
  employedSince: null,
  employedUntil: null,
  monthlySalary: 0,
  monthlyNetIncome: 0,
  otherIncome: 0,
  otherIncomeReason: '',
  lastYearIncome: 0,

  bankName: '',
  bankAccount: '',
  accountHolder: '',
  accountType: '',
  loanPurpose: '',
  in_political_position: 0,
  tos: 0,
  personalDataPolicy: 0,
  consents: 0,
  marketingConsents: 0,
  acceptAll: false,

  creditCards: {
    Visa: 0,
    MasterCard: 0,
    Amex: 0,
    DinersClub: 0,
    otherCreditCard: 0,
  },

  existingLoans: {
    mortgage: {
      toBePaid: 0,
      monthlyPayment: 0,
      considerConsolidation: 0,
      type: 2,
    },
    consumerLoan: {
      toBePaid: 0,
      monthlyPayment: 0,
      considerConsolidation: 0,
      type: 3,
    },
    carLoan: {
      toBePaid: 0,
      monthlyPayment: 0,
      considerConsolidation: 0,
      type: 4,
    },
    creditCard: {
      toBePaid: 0,
      monthlyPayment: 0,
      considerConsolidation: 0,
      type: 5,
    },
    fastLoan: {
      toBePaid: 0,
      monthlyPayment: 0,
      considerConsolidation: 0,
      type: 6,
    },
    collateralLoan: {
      toBePaid: 0,
      monthlyPayment: 0,
      considerConsolidation: 0,
      type: 9,
    },
    alimony: {
      toBePaid: 0,
      monthlyPayment: 0,
      considerConsolidation: 0,
      type: 7,
    },
    installment: {
      toBePaid: 0,
      monthlyPayment: 0,
      considerConsolidation: 0,
      type: 8,
    },
    otherDebts: {
      toBePaid: 0,
      monthlyPayment: 0,
      considerConsolidation: 0,
      type: 1,
    },
  },
  applicationList: [],
  formValidationError: false,
  errors: {},
  stepErrors: [],
  sendingApplication: false,
  new_application: false,
  new_phone_number: '',
  verificationNumber: '',
  code_verified: '',
  needEmployerInfo: false,
  needEndDate: false,
  applicationUUID: '',
  token: '',
  jwt_token: '',
  numberUpdated: '',
  throttlingEnabled: false,
  waitingTime: 0,
  allAccepted: false,
  consentError: false,
  draftFound: true,
  loading: false,
  modalstatus: 'normal',
  extraInfo: {
    active_non_bank_obligations: false,
    document_expiration_date: '',
    id_type: '',
    mother_maiden_name: '',
    total_500_plus_amount: 0,
    house_status: '',
    house_type: '',
    employment_sector: '',
    profession_code: '',
    Dziecko1: '',
    Dziecko2: '',
    Dziecko3: '',
    Dziecko4: '',
    Dziecko5: '',
    Dziecko6: '',
    Dziecko7: '',
    Dziecko8: '',
  },
  draftInProcess: false,
  disableButton: false,
}

const getters = {
  checkErrors(state) {
    return Object.keys(state.errors).length
  },
}

const actions = applicationActions

const mutations = {
  setLoading(state, value) {
    state['loading'] = value
  },
  sendApplication(state) {
    state['sendingApplication'] = true
  },
  sendNewApplication(state) {
    state['new_application'] = true
  },
  sendApplicationFinished(state) {
    state['sendingApplication'] = false
  },
  setApplicationList(state, applicationList) {
    state['applicationList'] = applicationList
  },
  updateApplication(state, { key, value }) {
    // update label from amount
    if (key === 'amount') {
      state['amount_label'] = validAmountToText(value)
    }
    // update amount if label changed
    if (key === 'amount_label') {
      state['amount'] = findMatchAmount(value)
    }

    if (key === 'amount_index') {
      state['amount'] = findAmountByIndex(value)
      state['amount_label'] = validAmountToText(state['amount'])
    }

    if (key === 'occupation') {
      // Set required fields to its original state
      state.requiredFields[3] = ['occupation', 'monthlyNetIncome']

      const phoneIndex = state.requiredFields[3].indexOf('workPhone')
      const employedUntilIndex = state.requiredFields[3].indexOf('employedUntil')
      const employedSinceIndex = state.requiredFields[3].indexOf('employedSince')
      const employerIndex = state.requiredFields[3].indexOf('employer')
      const nipIndex = state.requiredFields[3].indexOf('businessId')

      if (
        ['2', '3', '4', '9', '10', '12', '15'].indexOf(value) !== -1 &&
        employerIndex === -1 &&
        phoneIndex === -1 &&
        nipIndex === -1
      ) {
        state['needEmployerInfo'] = true
        state.requiredFields[3].push('businessId', 'workPhone', 'employer')
      } else {
        state['needEmployerInfo'] = false

        state.requiredFields[3].splice(employerIndex, 1)
        state.requiredFields[3].splice(phoneIndex, 1)
        state.requiredFields[3].splice(nipIndex, 1)
      }

      if (['2', '11', '10', '15'].indexOf(value) !== -1 && employedUntilIndex === -1) {
        state['needEndDate'] = true
        state.requiredFields[3].push('employedUntil')
      } else {
        state['needEndDate'] = false
        state.requiredFields[3].splice(employedUntilIndex, 1)
      }
      if (
        ['2', '3', '9', '11', '10', '15', '4'].indexOf(value) !== -1 &&
        employedSinceIndex === -1
      ) {
        state['needStartDate'] = true
        state.requiredFields[3].push('employedSince')
      } else {
        state['needStartDate'] = false
        state.requiredFields[3].splice(employedSinceIndex, 1)
      }

      state['occupation'] = value
    }

    if (key === 'hasRegisteredAddress') {
      const cityIndex = state.requiredFields[2].indexOf('registeredCity')
      const zipcodeIndex = state.requiredFields[2].indexOf('registeredZipCode')
      const streetIndex = state.requiredFields[2].indexOf('registeredStreet')
      const houseIndex = state.requiredFields[2].indexOf('registeredHouseNumber')
      const regionIndex = state.requiredFields[2].indexOf('registeredRegion')
      if (cityIndex === -1 && value === true) {
        state.requiredFields[2].push('registeredCity')
      }
      if (cityIndex !== -1 && value !== true) {
        state.requiredFields[2].pop()
      }
      if (zipcodeIndex === -1 && value === true) {
        state.requiredFields[2].push('registeredZipCode')
      }
      if (zipcodeIndex !== -1 && value !== true) {
        state.requiredFields[2].pop()
      }
      if (streetIndex === -1 && value === true) {
        state.requiredFields[2].push('registeredStreet')
      }
      if (streetIndex !== -1 && value !== true) {
        state.requiredFields[2].pop()
      }
      if (houseIndex === -1 && value === true) {
        state.requiredFields[2].push('registeredHouseNumber')
      }
      if (houseIndex !== -1 && value !== true) {
        state.requiredFields[2].pop()
      }
      if (regionIndex === -1 && value === true) {
        state.requiredFields[2].push('registeredRegion')
      }
      if (regionIndex !== -1 && value !== true) {
        state.requiredFields[2].pop()
      }

      state['hasRegisteredAddress'] = value
    }

    _.set(state, key, value)
  },

  tweakRequirements(state, { key, value }) {
    if (key === 'costOfLiving') {
      const livingIndex = state.requiredFields[4].indexOf('costOfLiving')
      if (livingIndex !== -1 && value === 0) {
        state.requiredFields[4].pop()
        state['costOfLiving'] = 0
      }
    }
  },

  updateCreditCards(state, { key, value }) {
    state.creditCards[key] = value
  },
  updateExistingLoans(state, { key, value, loan_type }) {
    state.existingLoans[loan_type][key] = value
  },
  setErrors(state, errors) {
    state.errors = errors
    state.allAccepted = false
  },
  setStepErrors(state, errors) {
    state.stepErrors = errors
  },

  setStepProgress(state, value) {
    state.currentStep = value
    state.maxStep = Math.max(value, state.maxStep)
  },

  fieldError(state, key) {
    state.errors = Object.assign({}, state.errors, { [key]: ['Invalid'] })
  },
  removeError(state, key) {
    const errors = Object.assign({}, state.errors)
    delete errors[key]
    state.errors = errors
  },
  showError(state) {
    state.code_verified = false
  },
  numberUpdatedFailed(state) {
    state.numberUpdated = false
  },
  numberUpdated(state) {
    state.numberUpdated = true
  },
  showThrottling(state) {
    state.numberUpdated = false
    state.throttlingEnabled = true
  },
  setApplication(state, data, key) {
    const address = data.applicants[0].address.street.split(', ')
    const residenceInfo = {
      street: address[0],
      houseNumber: address[1],
      apartmentNumber: address[2],
    }
    const applicantData = {
      ...data,
      ...data.applicants[0],
      ...data.applicants[0].address,
      ...data.applicants[0].bank_account,
      ...residenceInfo,
    }
    const employmentData = {
      ...data.applicants[0].employments[0],
    }

    Object.keys(applicantData).forEach(key => {
      if (Object.keys(state).includes(key)) {
        state[key] = applicantData[key]
      } else if (Object.keys(mappings).includes(key)) {
        state[mappings[key]] = applicantData[key]
      }
    })
    Object.keys(employmentData).forEach(key => {
      if (Object.keys(employmentMappings).includes(key)) {
        state[employmentMappings[key]] = employmentData[key]
        state['needEmployerInfo'] = true
        state['needEndDate'] = true
      } else if (Object.keys(state).includes(key)) {
        state[key] = employmentData[key]
      }
    })
  },
  draftFound(state, value) {
    state['draftFound'] = value
  },
  setModalStatus(state, value) {
    state['modalstatus'] = value
  },
  setDraftProcess(state, value) {
    state['draftInProcess'] = value
  },
  setValue(state, { key, value }) {
    state[key] = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
