<template lang="html">
  <div class="card mb-3">
    <div class="row no-gutters">
      <div class="col-md-12 offer-card" :class="status">
        <div class="card-header">
          <div class="row header-row">
            <img
              v-if="offer.image"
              :src="offer.image"
              alt="Company Banner"
              class="company-banner"
            />

            <h6 class="name" v-if="!offer.image">{{ offer.company.name }}</h6>
            <h6 class="name">{{ offer.product_name }}</h6>
          </div>
        </div>
        <div class="spinner-wrapper text-center">
          <div class="badge-wrapper">
            <b-badge
              v-for="(badge, b) in badges"
              :key="b"
              pill
              class="paid-out-badge offer-badge"
              :style="{
                color: `${badge.color_foreground}`,
                background: `${badge.color_background}`,
              }"
              v-b-tooltip.hover.v-saverium-blue-input
              :title="badge.info_text"
              >{{ badge.label }}
            </b-badge>
          </div>
          <div v-if="status === 'wait'">
            <div class="card-body">
              <p>
                {{ $t('your_application_is_being_processed') }}
              </p>
            </div>
          </div>
          <div v-else-if="context === 'rejected'">
            <div class="card-body">
              <p>{{ $t('offer_has_been_rejected') }}</p>
            </div>
          </div>

          <div v-else-if="product === 'modus'">
            <ModusCard
              :offer="offer"
              :action="action"
              :context="context"
              :currency="currency"
            />
          </div>
          <div v-else-if="productName === 'AION'">
            <AionCard
              :offer="offer"
              :action="action"
              :context="context"
              :currency="currency"
            />
          </div>
          <div v-else-if="productName === 'Raiffeisen'">
            <RaiffeisenCard
              :offer="offer"
              :action="action"
              :context="context"
              :currency="currency"
            />
          </div>
          <div v-else-if="offerType === 'company_loan' && brand === 'saverium_dk'">
            <CompanyLoanCard
              :offer="offer"
              :currency="currency"
              :businessDescription="businessDescription"
            />
          </div>
          <div v-else-if="status === 'paidout'">
            <PaidOutOffer :offer="offer" :context="context" :currency="currency" />
          </div>
          <div v-else-if="status === 'collect_extra_data'">
            <div class="card-body">
              <ExtraDataCard
                :offer="offer"
                :action="action"
                :context="context"
                :currency="currency"
                :missingFields="missingFields"
                :callbackUrl="callbackUrl"
              />
            </div>
          </div>
          <div v-else-if="status === 'email_and_phone_contact'">
            <LinkExpiredCard
              :offer="offer"
              :action="action"
              :context="context"
              :currency="currency"
              :productName="productName"
              :status="status"
            />
          </div>
          <div v-else>
            <OfferCard
              :offer="offer"
              :action="action"
              :context="context"
              :currency="currency"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OfferCard from '@/components/account_view_components/OfferCard'
import ModusCard from '@/components/account_view_components/product_cards/ModusCard'
import AionCard from '@/components/account_view_components/product_cards/AionCard'
import RaiffeisenCard from '@/components/account_view_components/product_cards/RaiffeisenCard'
import PaidOutOffer from '@/components/account_view_components/PaidOutOffer'
import ExtraDataCard from '@/components/account_view_components/ExtraDataCard'
import LinkExpiredCard from './LinkExpiredCard'
import CompanyLoanCard from '@/components/account_view_components/CompanyLoanCard'
export default {
  props: ['offer', 'currency'],
  components: {
    OfferCard,
    PaidOutOffer,
    ModusCard,
    ExtraDataCard,
    AionCard,
    LinkExpiredCard,
    CompanyLoanCard,
    RaiffeisenCard,
  },
  computed: {
    action() {
      return this.offer.render_spec.next_action.action
    },
    brand() {
      return this.$root.brand
    },
    context() {
      const ctx = this.offer.render_spec.context
      // Demangle enum, until backend has been updated to do the same
      return ctx.replace(/ /, '_').toLowerCase()
    },
    badges() {
      return this.offer.badges
    },
    product() {
      return this.offer.product_slug
    },
    productName() {
      return this.offer.product_name
    },
    missingFields() {
      return this.offer.render_spec.next_action.missing_fields
    },
    callbackUrl() {
      return this.offer.render_spec.next_action.callback_url
    },
    actionContext() {
      /* Combines action and context, if latter is found:
       * 'wait', '' -> 'wait'
       * 'wait', 'wait_contact' -> 'wait_wait_contact'
       */
      return this.context ? `${this.action}_${this.context}` : this.action
    },
    businessDescription() {
      return this.offer.always_visible_offer_text
    },
    status() {
      const actionContext = this.actionContext
      // Note: Stored as variable first, because jslint bawks at it otherwise.
      /* Possible action -> context combinations, as of 2021-09-14:
       * 'wait', ''
       * 'wait', 'wait_contact'
       * 'accept', ''
       * 'eskat', ''
       * 'none', 'cancelled'
       * 'none', 'paid_out'
       * 'none', 'rejected'
       * 'none', ''
       * ... and maybe: ??
       * 'collect_extra_data', ''
       */
      const mapping = {
        wait_wait_contact: 'email_and_phone_contact',
        none_cancelled: 'cancelled',
        none_paid_out: 'paidout',
        none_rejected: 'rejected',
        wait_none: 'wait',
        accept_none: 'accept',
        eskat_none: 'eskat',
        collect_extra_data_none: 'collect_extra_data',
        none: '',
      }
      return mapping[actionContext] || actionContext
    },
    offerType() {
      const offerType = this.offer.offer_type
      const typeMapping = {
        1: 'consumer_loan',
        2: 'company_loan',
      }
      return typeMapping[offerType]
    },
  },
}
</script>

<style lang="scss" scoped>
.spinner-wrapper {
  font-size: 18px;
}

.card-header {
  border: none;
  background-color: white;
}
.name {
  float: right;
}
.header-row {
  align-items: center;
  justify-content: space-between;
}

.offer-badge {
  margin: 1rem;
  margin-right: 0.6rem;
  background: #0da300;
  margin-bottom: 0.7rem;
  font-size: 1rem;
  white-space: normal;
}

.accept,
.eskat {
  border-left: 15px solid #0b8600;
}
.cancelled {
  border-left: 15px solid gray;
}
.email_and_phone_contact,
.collect_extra_data,
.wait {
  border-left: 15px solid #042452;
}
.rejected {
  border-left: 15px solid #7d0000;
}

.paidout {
  border-left: 15px solid #085e00;
}
</style>
