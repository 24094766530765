<template lang="html">
  <div class="mb-5" ref="header">
    <h2 v-if="fullView" class="mb-3 text-center">
      {{ step }}.
      {{ $t('employment') }}
    </h2>
    <h1 v-else class="mb-3 text-center">
      {{ $t('employment') }}
    </h1>
    <div class="spinner-wrapper" v-if="loading">
      <div class="text-center">
        <b-spinner class="loading-spinner" label="Loading"></b-spinner>
      </div>
    </div>
    <div class="row">
      <div
        class="form-group col-sm-6"
        v-bind:value="value"
        v-on:input="value = $event.target.value"
        @change="changeOccupation"
      >
        <form-input
          type="dropdown"
          storekey="occupation"
          :items="occupationOptions"
          id="sourceOfIncome"
        >
          {{ $t('source_of_income') }}
        </form-input>
      </div>

      <div
        class="form-group col-sm-6"
        @change="changeNIP"
        v-if="this.needEmployerInfo"
        v-bind:value="NIP"
        v-on:input="NIP = $event.target.value"
      >
        <form-input
          type="identity"
          identifier="nip"
          storekey="businessId"
          placeholder="np. 0000000000"
          id="NIP"
        >
          {{ $t('employers_nip') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6" v-if="this.needEmployerInfo">
        <form-input type="text" storekey="employer" id="employer">
          {{ $t('employers_name') }}
        </form-input>
      </div>

      <div id="workCity" class="form-group col-sm-6" v-if="this.needEmployerInfo">
        <form-input type="text" storekey="workCity">
          {{ $t('city') }}
        </form-input>
      </div>

      <div
        id="workPhone"
        class="form-group col-sm-6 is_invalid"
        v-if="this.needEmployerInfo"
        v-bind:value="workPhone"
        v-on:input="workPhone = $event.target.value"
      >
        <form-input type="phone" storekey="workPhone" prepend="+48">
          {{ $t('employers_phone_number') }}
        </form-input>
      </div>

      <div id="zipcode" class="form-group col-sm-6" v-if="this.needEmployerInfo">
        <form-input type="text" storekey="workZipCode">
          {{ $t('zip_code') }}
        </form-input>
      </div>

      <div id="streetAddress" class="form-group col-sm-6" v-if="this.needEmployerInfo">
        <form-input type="text" storekey="workStreetAddress">
          {{ $t('street') }}
        </form-input>
      </div>

      <div id="professionCon" class="form-group col-sm-6" v-if="this.needEmployerInfo">
        <form-input type="text" storekey="profession">
          {{ $t('position') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6">
        <form-input
          type="numeric"
          storekey="monthlyNetIncome"
          id="monthlyNetIncome"
          currency="zł"
          separator="space"
        >
          {{ $t('monthly_net_income') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6">
        <form-input
          type="numeric"
          storekey="otherIncome"
          id="otherIncome"
          currency="zł"
          separator="space"
        >
          {{ $t('other_monthly_income') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6">
        <form-input type="text" storekey="otherIncomeReason" id="otherIncomeReason">
          {{ $t('source_of_other_income') }}
        </form-input>
      </div>

      <div class="form-group col-sm-6" v-if="this.occupation !== '7'">
        <form-input type="month" storekey="employedSince" id="employedSince">
          {{ $t('employment_started') }}
        </form-input>
      </div>
      <div class="form-group col-sm-6" v-if="this.needEndDate" id="employedUntil">
        <form-input type="month" storekey="employedUntil">
          {{ $t('employment_ends') }}
        </form-input>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from '@/components/form/FormInput'
export default {
  props: ['fullView', 'step'],
  components: {
    FormInput,
  },
  data: function() {
    return {
      requiredFields: ['occupation'],
      value: '',
      NIP: '',
      name: '',
      streetAddress: '',
      city: '',
      zipcode: '',
      endDate: true,
    }
  },
  computed: {
    occupationOptions() {
      return this.$store.state.enums.occupationOptions
    },
    stepErrors() {
      return this.$store.state.application.stepErrors
    },
    needEmployerInfo() {
      return this.$store.state.application.needEmployerInfo
    },
    occupation() {
      return this.$store.state.application.occupation
    },
    needEndDate() {
      return this.$store.state.application.needEndDate
    },
    needStartDate() {
      return this.$store.state.application.needStartDate
    },
    loading() {
      return this.$store.state.application.loading
    },
    employer: {
      get() {
        return this.$store.state.application.employer
      },
      set(employer) {
        this.$store.state.application.employer = employer
      },
    },
    workCity: {
      get() {
        return this.$store.state.application.workCity
      },
      set(workCity) {
        this.$store.state.application.workCity = workCity
      },
    },
    workPhone: {
      get() {
        return this.$store.state.application.workPhone
      },
      set(workPhone) {
        this.$store.state.application.workPhone = workPhone
      },
    },
    workZipCode: {
      get() {
        return this.$store.state.application.workZipCode
      },
      set(workZipCode) {
        this.$store.state.application.workZipCode = workZipCode
      },
    },
    workStreetAddress: {
      get() {
        return this.$store.state.application.workStreetAddress
      },
      set(workStreetAddress) {
        this.$store.state.application.workStreetAddress = workStreetAddress
      },
    },
    profession: {
      get() {
        return this.$store.state.application.profession
      },
      set(profession) {
        this.$store.state.application.profession = profession
      },
    },
    businessId: {
      get() {
        return this.$store.state.application.businessId
      },
      set(businessId) {
        this.$store.state.application.businessId = businessId
      },
    },
  },
  watch: {
    stepErrors(newV) {
      if (this.fullView && newV[0] === parseInt(this.step, 10)) {
        this.$refs.header.scrollIntoView()
      }
    },
  },
  methods: {
    isValid(id) {
      return !Object.keys(this.$store.state.application.errors).includes(id)
    },
    changeOccupation() {
      if (this.needEndDate === false) {
        this.$store.commit('removeError', 'employedUntil')
        this.$store.commit('removeError', { key: 'employedUntil', value: '' })
      }
      if (this.needStartDate === false) {
        this.$store.commit('removeError', 'employedSince')
        this.$store.commit('updateApplication', { key: 'employedSince', value: '' })
      }
      if (this.needEmployerInfo === false) {
        let keys = [
          'businessId',
          'workPhone',
          'employer',
          'workCity',
          'workZipCode',
          'workStreet',
        ]
        keys.forEach(key => {
          this.$store.commit('removeError', key)
          this.$store.commit('updateApplication', { key: key, value: '' })
        })
      }
    },
    async changeNIP() {
      await this.$store.dispatch('changeNIP', this.NIP)
    },
  },
}
</script>

<style lang="scss" scoped></style>
