<template>
  <div class="row article">
    <h1 class="center-title">Jak szybko i bezpieczne płacić kartą przez internet?</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            Płatność kartą stała się częścią naszej codzienności tak jak i zakupu
            internetowe. Coraz chętniej kupujemy w sieci nie tylko ubrania czy kosmetyki,
            ale również meble, artykuły dekoracyjne czy nawet żywność. Pozostaje pytanie,
            jak płacić kartą, a jednocześnie zabezpieczyć swoje finanse?
          </p>
          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/pay-by-card-over-internet-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/pay-by-card-over-internet.jpg"
            alt="Spirala zadłużenia – co to jest? Jak nie wpaść i jak wyjść z długów?"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul class="table-of-contents">
              <li>
                <a
                  href="#platnosc-karta-w-internecie-duze-ulatwienie-czy-przykra-koniecznosc"
                >
                  Płatność kartą w internecie - duże ułatwienie czy przykra konieczność?
                </a>
              </li>
              <li>
                <a href="#schemat-dokonywania-platnosci-karta">
                  Schemat dokonywania płatności kartą
                </a>
              </li>
              <li>
                <a href="#jak-zabezpieczyc-platnosci-karta-online">
                  Jak zabezpieczyć płatności kartą online?
                </a>
              </li>
              <li>
                <a href="#bezpieczne-platnosci-w-sieci"> Bezpieczne płatności w sieci </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container mt-5">
          <h2 id="platnosc-karta-w-internecie-duze-ulatwienie-czy-przykra-koniecznosc">
            Płatność kartą w internecie - duże ułatwienie czy przykra konieczność?
          </h2>
          <p>
            Robiąc zakupy online, w większości przypadków będziemy musieli zapłacić za nie
            kartą. Niektóre firmy oferują co prawda możliwość płatności za pobraniem,
            jednak usługa ta jest droższa, a co więcej, coraz rzadziej spotykana. Wszystko
            dlatego, że płatności kartą upowszechniły się już w dzisiejszym świecie. Z
            drugiej jednak strony wiele słyszy się o wyłudzeniach, które mają miejsce
            właśnie w internecie. Okazuje się bowiem, że płatności kartą online są
            bezpieczne, jednak należy zachować również dużą ostrożność, aby nie paść
            ofiarą internetowego oszustwa.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="schemat-dokonywania-platnosci-karta">
            Schemat dokonywania płatności kartą
          </h2>
          <p>
            Klient, który decyduje się na płatność online kartą płatniczą, zobowiązany
            jest podać:
          </p>
          <ul>
            <li>swoje dane osobowe,</li>
            <li>numer karty,</li>
            <li>datę ważności karty,</li>
            <li>numer CVV2.</li>
          </ul>
          <p>
            Wielkość danych, które trzeba udostępnić, rodzić może pytanie o bezpieczeństwo
            transakcji. Tymczasem są one przesyłane do agenta rozliczeniowego, który
            uzyskuje z banku informację odnośnie dostępności środków pieniężnych na
            koncie. Płatność kartą visa powoduje, że dane trafiają do sieci Visanet, zaś
            płatność kartą mastercard - do sieci Banknet.
          </p>
          <p>
            Sklep, w którym dokonujemy zakupów, otrzymuje informację zwrotną od agenta o
            pozytywnym wyniku autoryzacji. Dane przesyłane są automatycznie, a cała
            procedura trwa zaledwie kilkanaście sekund. Należy mieć przy tym świadomość,
            że sprzedawca nie pozyskuje naszych danych - służą one jedynie w procesie
            weryfikacji i autoryzacji.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="jak-zabezpieczyc-platnosci-karta-online">
            Jak zabezpieczyć płatności kartą online?
          </h2>
          <p>
            Zakupy kartą przez internet będą jeszcze bezpieczniejsze, gdy ustalimy sobie
            na karcie pewne limity płatności. Mają one zastosowanie szczególnie w
            przypadku kart debetowych, za pomocą których możemy dysponować środkami, które
            mamy na koncie osobistym bądź oszczędnościowym. Płatność kartą sprawia, że
            wydajemy pieniądze stosunkowo lekką ręką, dlatego też owe zabezpieczenia
            okazują się być szczególnie pomocne dla osób lubiących zakupy w sieci.
            Przykładowo, możemy ustalić, że nie wydamy więcej, niż 500 zł dziennie. Jeśli
            będziemy chcieli wykonać transakcję przekraczającą tę kwotę, bank nie wyda na
            nią zezwolenia. Oczywiście, blokadę można w każdym momencie ściągnąć -
            osobiście w oddziale bądź dzwoniąc na infolinię czy nawet logując się do
            bankowości internetowej.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="bezpieczne-platnosci-w-sieci">Bezpieczne płatności w sieci</h2>
          <p>
            Aby w sposób bezpieczny dokonywać zakupów w internecie, należy korzystać ze
            sprawdzonych autoryzowanych serwisów. Sprawdźmy, jakimi opiniami cieszy się
            dany sklep oraz czy został on zarejestrowany w bazie firm. Strona, na której
            podajemy dane z karty, powinna mieć odpowiednie zabezpieczenie - jej adres
            powinien zaczynać się od "HTTPS". Dokonując płatności, połączmy się z własnym
            internetem.
          </p>
          <p>
            Najlepiej nie spisywać danych z karty w miejscu publicznym, na przykład w
            galerii handlowej czy na przystanku komunikacji miejskiej. W tego typu
            miejscach mogą być bowiem zainstalowane kamery, za pomocą których będzie
            możliwe odczytanie danych z karty przez osoby do tego nieuprawnione. Karty
            płatnicze, czy to kredytowe, czy debetowe, powinny być przechowywane w
            bezpiecznych miejscach.
          </p>
          <p>
            Nie nośmy przy sobie wszelkich kart, jakimi dysponujemy, a już z pewnością nie
            trzymajmy ich w tym samym portfelu czy w tej samej szafce w domu. Pamiętajmy,
            że bezpieczeństwo dokonywania płatności w sieci zależy przede wszystkim od
            naszej czujności - nie traćmy jej nawet wtedy, gdy w krótkim czasie chcemy
            zapłacić za zakupy. Jeśli zgubimy kartę debetową bądź kredytową, niezwłocznie
            poinformujemy o tym organ, który ją wydał. Będzie on w stanie zablokować
            dokonywanie płatności za pomocą karty, przez co jej posiadacz nie utraci
            środków, nie zostanie również wykorzystany przyznany limit w przypadku kart
            kredytowych.
          </p>
        </div>
      </div>
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
export default {
  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
