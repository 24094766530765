<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til computer</h1>

      <div class="top-text text-container">
        <p>
          Mangler du et lån til en ny computer eller Macbook? Vi hjælper dig med at finde
          den bedste finansiering til din computer blandt mange banker og
          pengeinstitutter. Det eneste det kræver, er at du udfylder vores online
          ansøgning på 2 minutter. Herefter vi indhenter, med det samme, skræddersyede
          tilbud på lån til din nye computer.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#hvordan-vi-indhenter-tilbud-paa-laan-til-computer"
                    >Hvordan vi indhenter tilbud på lån til computer</a
                  >
                </li>
                <li><a href="#laan-til-pc">Lån til pc</a></li>
                <li><a href="#laan-til-macbook-og-mac">Lån til Macbook og Mac</a></li>
                <li><a href="#laan-til-chromebook">Lån til Chromebook</a></li>
                <li>
                  <a href="#husk-udgiften-til-software">Husk udgiften til software</a>
                </li>
                <li><a href="#computermoebler">Computermøbler</a></li>
                <li>
                  <a href="#lad-os-finde-dit-computerlaan-for-dig"
                    >Lad os finde dit computerlån for dig</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="hvordan-vi-indhenter-tilbud-paa-laan-til-computer">
            Hvordan vi indhenter tilbud på lån til computer
          </h2>
          <p>
            Når du ansøger om et lån til en computer igennem os, starter du med at udfylde
            vores ansøgningsformular. Herefter fremsender vi dine informationer til de
            mange banker og pengeinstitutter, som er koblet på vores platform.
          </p>
          <p>
            Hver enkelt
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbyder</a
            >
            vender med det samme tilbage med
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >tilbud på forbrugslån</a
            >
            til dig, som du frit kan benytte, som et lån til den pc, Macbook eller
            Chromebook, som du ønsker dig.
          </p>
          <p>
            Vi opstiller alle de computerlån, som du modtager på en overskuelig og
            sammenlignelig måde, så du nemt kan vælge det bedste og
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >billigste lån</a
            >, til netop dit behov.
          </p>
          <p>
            Når du har fundet det rette lån til at dække dit behov for en ny computer,
            vælger du blot den udbyder du ønsker, hvorefter vi videresender dig til
            udbyderens hjemmeside, hvor dine oplysninger verificeres. Herefter udbetales
            lånet til din nye computer, så du kan købe alt det hardware og software, som
            du har brug for.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-pc">Lån til pc</h2>
          <p>
            En pc kommer i mange udgaver og prisklasser. Alt efter hvilken model og type
            pc du ønsker dig, kan et lån være mere eller mindre relevant. Herunder vil vi
            gennemgå nogle af de mest almindelige lån til pc, som vores brugere ansøger
            om.
          </p>
          <h3>Gaming pc</h3>
          <p>
            En gaming pc kan hurtigt blive en dyr fornøjelse. Hvis du gerne vil spille de
            nyeste AAA spil, i den højeste opløsning og med den hurtigste framerate, så
            har du brug for en kraftig gaming-pc med et godt grafikkort.
          </p>
          <p>
            En gamer-pc kan snildt koste
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/20000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >20.000 kr.</a
            >, men vælger du en stationær, som du selv bygger, frem for en bærbar, kan du
            ofte få lige så god hardware og performance for halvdelen eller ⅔ af
            budgettet. Uanset om du har brug for hele beløbet, eller blot en mindre del,
            hjælper vi dig gerne med lånet til din nye computer.
          </p>
          <p><strong>Peferials</strong></p>
          <p>
            Sammen med en gamercomputer, vil der også ofte være omkostninger forbundet med
            ekstraudstyr. Du har fx brug for en skærm med høj refresh rate, en mus med en
            høj DPI og måske højtalere, headset, keyboard mv., som alt sammen skal lægges
            oven i omkostningen til din pc.
          </p>
          <p>
            Med et lån til computeren, får du mulighed for at købe det helt perfekte
            setup. Derved skaber du de optimale forudsætninger for at undgå lag, dårlig
            opløsning og throttling i dine spil.
          </p>
          <h3>Arbejdscomputer</h3>
          <p>
            En arbejdscomputer betaler du sjældent selv for, og du vil derfor oftest ikke
            have brug for at låne penge til en computer, hvis den blot skal bruges i
            arbejdsøjemed - med mindre du er freelancer eller selvstændig.
          </p>
          <p>
            I de fleste tilfælde vil det være din arbejdsgiver, som skal ansøge om et
            <a
              href="https://www.saverium.dk/laan-penge/erhvervslaan"
              rel="noopener noreferrer"
              target="_blank"
              >erhvervslån</a
            >, hvis der er behov for at låne til din pc.
          </p>
          <p>
            Hvis du har behov for en hel arbejdsstation med kontorstol, hæve-sænke-bord,
            m.v., bør prisen for dette tilføjes til lånet til computeren.
          </p>
          <h3>Privat</h3>
          <p>
            Hvis du blot har brug for at låne til en privat pc, som skal kunne surfe på
            nettet, skrive en mail,
          </p>
          <p>
            se
            <a href="https://www.netflix.com/" rel="noopener noreferrer" target="_blank"
              >Netflix</a
            >, og evt. kunne redigere et dokument, så har du sjældent brug for en kraftig
            computer.
          </p>
          <p>
            Her kan du ofte slippe afsted med et
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/5000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >lån på 5.000 kr.</a
            >, selv hvis du ikke allerede har lagt nogle penge til side.
          </p>
          <p>
            Når du køber, eller låner til en privat computer, vil du sjældent behøve at
            stille store krav til hardwaren, ud over at du har brug for en fornuftig skærm
            og en god batterilevetid, hvis det er en laptop. Dermed løber udgiften
            sjældent særligt højt op, og derfor vil der oftest ikke være behov for at låne
            penge til denne computer.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-macbook-og-mac">Lån til Macbook og Mac</h2>
          <p>
            Hvis du ønsker dig en lækker
            <a
              href="https://www.apple.com/dk/mac/"
              rel="noopener noreferrer"
              target="_blank"
              >Macbook Pro, Macbook Air, Mac Studio eller Mac Pro</a
            >, løber omkostningerne hurtigt op - især hvis det skal være med gode
            specifikationer.
          </p>
          <p>
            Hvis vi starter med det dyreste Apple-produkt, så koster en Mac Pro i omegnen
            af
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/50000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >50.000 kr</a
            >., og her vil de fleste nok få behov for en eller anden form for finansiering
            eller lån til computeren.
          </p>
          <p>
            Herefter finder vi Mac Studio, som også er tiltænkt professionelle, som du
            skal regne med koster
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/20000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >20.000 kr.</a
            >
            eller
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/40000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >40.000 kr.</a
            >
            alt efter hvilken model og specs du ønsker.
          </p>
          <p>
            De mest almindelige Macs, herunder Macbook Air/Pro og iMacs, fås for mellem
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/10000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >10.000 kr.</a
            >
            og 20.000 kr. Da det ofte er disse Macs der købes til privatbrug, kan lånet
            til computeren også ofte komme på tale i disse tilfælde. Hvis du, lige som
            mange andre, også ønsker dig en
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan/mobil"
              rel="noopener noreferrer"
              target="_blank"
              >iPhone</a
            >
            oven i handlen, så kan budgettet komme op i omegnen af
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/30000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >30.000 kr.</a
            >
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-chromebook">Lån til Chromebook</h2>
          <p>
            Hvis du hverken ønsker Apples styresystem eller Windows, eller blot ønsker dig
            en simpel billig computer, så kan du overveje at
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >
            til en
            <a
              href="https://www.google.com/chromebook/"
              rel="noopener noreferrer"
              target="_blank"
              >Chromebook</a
            >.
          </p>
          <p>
            Fordelen ved en Chromebook er bla. at du ikke behøver at overveje
            softwareopdateringer eller drivers, samtidig med at du får en billig bærbar
            computer, som er bygget til Googles produkter, herunder Chrome Cast og Google
            Drive.
          </p>
          <p>
            En Chromebook er ofte den billigste løsning, da den ofte kan fås for
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/3000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >3.000 kr.</a
            >
            eller deromkring. Derudover er det en mere driftsikker computer en en Windows
            computer, da den ikke kræver opdateringer, automatisk tager backups og ikke
            bliver inficeret med virus.
          </p>
          <p>
            Hvis du ikke har behov for nogle af de programmer/apps, som kun fungerer på
            Windows eller Apples OS, så kan du ofte slippe afsted med en bedre handel og
            mindre lån til din computer ved at vælge en Chromebook. Har du oven i købet en
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan/mobil"
              rel="noopener noreferrer"
              target="_blank"
              >Android telefon</a
            >, så er Chromebooken bygget til at fungere ekstra godt sammen med denne.
          </p>
        </div>

        <div class="text-container">
          <h2 id="husk-udgiften-til-software">Husk udgiften til software</h2>
          <p>
            Når man låner til en ny computer, ser vi ofte, at der også skal købes ny
            software. Grunden til at mange folk skifter deres computer ud er nemlig, at
            den nye software de ønsker sig, ikke kan køre på deres gamle setup. Dette
            gælder især for gamere eller professionelle, som redigerer musik eller film.
          </p>
          <p>
            For en professionnel grafisk designer, er det ofte Adobe i form af
            Illustrator, Photoshop og Lightroom der er behov for. Her vælger mange at købe
            hele
            <a
              href="https://www.adobe.com/dk/creativecloud.html"
              rel="noopener noreferrer"
              target="_blank"
              >Creative Cloud</a
            >
            til ca. 500 kr/md.
          </p>
          <p>
            Hvis du redigerer musik, så vil det ofte være på en Mac, med en dertilhørende
            Logic Pro installation. Logic pro koster i sig selv små
            <strong>2.000 kr.</strong>
          </p>
          <p>
            Hvis du redigerer film, er det enten Adobe Premiere Pro til lidt over eller
            Final Cut Pro, som også koster omkring 2.000 kr. hver.
          </p>
          <p>
            Hvis du derimod blot ønsker at brug din computer til gaming, så husk at de
            fleste AAA-spil koster 400-500 kr.
          </p>
          <p>
            Hvis du overvejer at benytte Microsoft Office til email, Excel og Word, så kan
            du med fordel overveje at spare de små <strong>1.000 kr</strong>., som en
            Office Family pakke koster, og i stedet for benytte Googles gratis alternativ,
            <a
              href="https://www.google.com/drive/"
              rel="noopener noreferrer"
              target="_blank"
              >Google Drive</a
            >, så du ikke låner mere til din nye computer end højest nødvendigt.
          </p>
        </div>

        <div class="text-container">
          <h2 id="computermoebler">Computermøbler</h2>
          <p>
            Computermøbler er den sidste udgift, som du bør tage med i budgettet, når du
            låner penge til en computer. Dette er i sig selv ikke et lån til en computer,
            men en udgift, som nogle gange også skal medregnes. En god gamerstol koster fx
            nemt <strong>1.000 kr.</strong> eller <strong>1.500 kr.</strong> ekstra, og et
            skrivebord, kan også sagtens koste det samme eller det dobbelte.
          </p>
        </div>

        <div class="text-container">
          <h2 id="lad-os-finde-dit-computerlaan-for-dig">
            Lad os finde dit computerlån for dig
          </h2>
          <p>
            Hvis du nå nuværende tidspunkt ønsker at låne penge til din næste computer, så
            vil vi anbefale dig at benytte vores tjeneste. I og med at vi indhenter tilbud
            fra mange forskellige banker og pengeinstitutter, undgår du at tage det
            første, og måske dårligste tilbud, som du får. Hvis du benyttervores tjeneste,
            skaber du de bedste forudsætninger for at få et billigt lån til en computer,
            da du nemt og hurtigt kan få et overblik over dine
            <a
              href="https://www.saverium.dk/laan-penge/bolig/laanemuligheder"
              rel="noopener noreferrer"
              target="_blank"
              >lånemuligheder</a
            >.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
