<template>
  <div class="row article">
    <h1 class="center-title">Kredyt a pożyczka – sprawdź, jakie są różnice</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >Pojęcia kredyt i pożyczka są do siebie podobne i wielu z nas wykorzystuje
              je zamiennie. Tymczasem istnieje wiele cech różnicujących takie produkty
              finansowe. Dowiedz się, czym właściwie cechuje się pożyczka, a kiedy
              zobowiązanie finansowe może być uznane za kredyt.
            </i>
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/what-is-credit-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/what-is-credit.jpg"
            alt="Kredyt a pożyczka – sprawdź, jakie są różnice"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
          <h2 id="1">
            {{ contents[0].title }}
          </h2>
          <p>
            Co oznacza ten termin w świetle prawa cywilnego? Pożyczka według art. 720
            Kodeksu cywilnego jest umową, na podstawie której dający ją zobowiązuje się
            przenieść na własność biorącego określoną ilość pieniędzy albo rzeczy
            oznaczonych tylko co do gatunku. Biorący pożyczkę zobowiązuje się zwrócić tę
            samą ilość pieniędzy albo tę samą ilość rzeczy tego samego gatunku i tej samej
            jakości. Umowa, w przypadku której jej przedmiotem jest kwota ponad 1000 zł,
            wymaga zachowania, z mocy prawa cywilnego, formy dokumentowej, czyli musi być
            zawarta na piśmie.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Art. 69 Prawa bankowego wskazuje, czym jest umowa kredytu i tym samym, co to
            jest kredyt. Na podstawie podpisywanej pomiędzy kredytobiorcą i kredytodawcą
            umowy bank zobowiązuje się oddać do dyspozycji swojego klienta na czas
            oznaczony środki pieniężne z przeznaczeniem na ustalony cel, choć są
            odstępstwa od tej reguły. Przykładem niech będzie
            <b-link :to="$root.rn.CashLoan">kredyt gotówkowy</b-link>, w przypadku którego
            klient nie musi podawać, na co spożytkuje otrzymane pieniądze. Jednocześnie ta
            sama umowa kredytowa zobowiązuje kredytobiorcę do korzystania z środków
            bankowych ściśle zgodnie z ustalonymi warunkami. Musi on zwrócić pożyczoną
            kwotę wraz z odsetkami, w oznaczonych terminach spłaty oraz uiścić prowizję od
            udzielonego zobowiązania. Wymogiem jest, by umowa kredytowa miała zachowaną
            formę dokumentową – pisemną.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Już sam fakt, że podstawa prawna jest inna w przypadku pożyczki i kredytu
            powoduje, że mamy do czynienia z nieco innymi produktami. Między kredytem a
            <b-link :to="$root.rn.OnlinePaydayLoans">pożyczką chwilówką</b-link> czy po
            prostu pożyczką gotówkową są podobieństwa i różnice.
          </p>
          <h5>Pożyczka a kredyt – podobieństwa</h5>
          <p>
            Nie bez powodu wielu z nas mówi, że zaciąga kredyt lub pożyczkę i ma na myśli
            ten sam produkt. W obu bowiem przypadkach jest to zobowiązanie finansowe,
            które trzeba zwrócić w umówionym terminie.
          </p>
          <p>
            Co ciekawe, zdarza się, że pożyczka i kredyt konsumencki są jednym i tym
            samym. W takiej sytuacji ani błędem, ani nadużyciem nie będzie uznanie
            pożyczki za kredyt, choć z dopiskiem, że jest to kredyt konsumencki. Art. 3
            ustawy o kredycie konsumenckim wskazuje, że przez umowę takiego zobowiązania
            rozumie się umowę, na mocy której kredytobiorcy udostępniana jest kwota nie
            większa niż 255 550 zł lub równowartość tej sumy w walucie innej niż waluta
            polska. Kredytu konsumenckiego odpłatnie udziela kredytodawca w zakresie
            swojej działalności gospodarczej lub daje przyrzeczenie jego udzielenia
            konsumentowi.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Kiedy zobowiązanie udzielane przez jeden podmiot innemu będzie pożyczką, a
            kiedy kredytem? Różnice wynikają tutaj między innymi z tego, że podstawą
            udzielenia pożyczki jest prawo cywilne, a kredytu – ustawa Prawo bankowe.
          </p>
          <p>
            Odmienność między tymi dwoma formami pożyczania pieniędzy tkwi również w tym,
            kto może stać się kredytobiorcą lub pożyczkobiorcą. Kredytu banki udzielają
            tylko osobom, które nie mają negatywnych wpisów w historii, którą sprawdzają w
            Biurze Informacji Kredytowej. Natomiast firma udzielająca pożyczek może nawet
            nie zweryfikować BIK-u albo udzielić zobowiązania osobie, która jest już
            dłużnikiem. Jej cel nigdy nie jest określany w umowie, a przy kredycie się to
            zdarza, czego przykładem są celowe zobowiązania bankowe, jak kredyt
            samochodowy czy mieszkaniowy.
          </p>
          <p>
            Formalności związane z kredytem gotówkowym czy hipotecznym są bardziej
            zaawansowane i jest ich więcej niż w przypadku pożyczki, którą możesz otrzymać
            nawet w ciągu kilkunastu minut od chwili złożenia wniosku. Kwota pożyczki z
            reguły jest niższa niż kredytu, choć pod tym względem czasem różnice mogą być
            minimalne.
          </p>
          <p>
            W niektórych firmach pożyczkowych możesz uzyskać pieniądze w bez dodatkowych
            kosztów, tj. skorzystać z darmowej oferty, podczas gdy banki zawsze pobierają
            pewne opłaty za udzielenie kredytu.
          </p>
          <p>
            Zwykle pożyczki są spłacane jednorazowo, a kredyty ratalnie, ale i pod tym
            względem mogą nastąpić pewne odstępstwa i możesz zaciągnąć
            <b-link :to="$root.rn.LoanInstallment">pożyczkę na raty</b-link>.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            Jak już zostało powiedziane, jedną z różnic pomiędzy pożyczką a kredytem jest
            to, kto może ich udzielić. Pożyczkę możesz otrzymać od firmy, banku, a nawet
            osoby prywatnej, podczas gdy kredyt jest stricte produktem bankowym i tylko
            bank czy instytucja mająca taki status, jak spółdzielcza kasa
            oszczędnościowo-kredytowa (SKOK), może w istocie podpisać z tobą umowę.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            Kredyt jest produktem bankowym i tylko instytucje mające status banków mogą go
            udzielić. Klient chcący skorzystać z takiej oferty, musi mieć dobrą historię w
            BIK-u oraz uzyskać pozytywną ocenę swojej zdolności kredytowej. W przypadku
            wielu kredytów klient musi określić cel, na jaki zaciąga zobowiązanie.
          </p>
          <p>
            W przypadku pożyczek wskazanie, na co zostaną przeznaczone środki, nie jest
            wymagane. Ponadto mogą one być udostępnione klientowi przez dowolne podmioty,
            na dowolną kwotę, także w formie darmowego zobowiązania.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'Czym jest pożyczka?',
          link: '#1',
        },
        {
          title: 'Czym jest kredyt?',
          link: '#2',
        },
        {
          title: 'Czym różni się kredyt od pożyczki?',
          link: '#3',
        },
        {
          title: 'Pożyczka a kredyt – różnice',
          link: '#4',
        },
        {
          title: 'Kto może udzielić kredytu, a kto pożyczki?',
          link: '#5',
        },
        {
          title: 'Kredyt a pożyczka – podsumowanie',
          link: '#6',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped></style>
