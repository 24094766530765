<template>
  <div class="main" v-if="loggedIn">
    <div class="offers-main" v-if="$store.state.offers.currentApplicationId != null">
      <offers-header />

      <b-container>
        <b-row>
          <b-col xl="8">
            <offer-list />
          </b-col>
          <b-col xl="4" v-if="$store.state.offers.applications.length > 0">
            <application-list />
          </b-col>
        </b-row>
      </b-container>

      <offers-bottom />
    </div>
    <b-spinner label="Spinning" class="m-auto" v-else></b-spinner>
  </div>
  <div v-else><LoginView /></div>
</template>

<script>
import OffersHeader from '@/components/lainaayritykselle_fi/offers/OffersHeader'
import OfferList from '@/components/lainaayritykselle_fi/offers/OfferList'
import ApplicationList from '@/components/lainaayritykselle_fi/offers/ApplicationList'
import OffersBottom from '@/components/lainaayritykselle_fi/offers/OffersBottom'
import LoginView from '@/views/lainaayritykselle_fi/LoginView.vue'
export default {
  computed: {
    loggedIn() {
      return this.$store.state.user.loggedIn
    },
  },
  async created() {
    this.$store.dispatch('isLoggedIn')
    await this.$store.dispatch('fetchApplications')
    this.pollOffers()
  },
  methods: {
    async fetchApplications() {
      const path = this.$route.path
      if (path === this.$root.rn.OffersView) {
        await this.$store.dispatch('fetchApplications')
      } else {
        clearInterval(this.interval)
      }
    },
    pollOffers() {
      this.interval = setInterval(this.fetchApplications, 1000 * 10)
    },
    async replyToOffer(offer, option) {
      if (option.esign) {
        window.open(option.esign, '_blank')
      } else {
        // lets do some POST magic
        const response = await this.$store.dispatch('acceptOffer', option.uuid)

        if (response.status === 'ok') {
          this.$router.push(this.$root.rn.ThankYouView)
        }
      }
    },
  },
  components: {
    OffersHeader,
    OfferList,
    ApplicationList,
    OffersBottom,
    LoginView,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  color: #333;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 40px;
}
</style>
