let routeUtils = {
  // This creates final route object from localized versions
  createRawRoutes(langRoutes, localizedPaths) {
    let rawRoutes = {
      routes: [],
      paths: localizedPaths,
    }

    langRoutes.map((route, i) => {
      route.path = localizedPaths[route.name]
      rawRoutes.routes.push(route)
    })

    // Every defined route must have a path
    rawRoutes.routes.map(route => {
      if (!route.path) {
        throw new Error(`${route.name} is missing a path!`)
      }
    })
    return rawRoutes
  },
}

export default routeUtils
