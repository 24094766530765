import Vue from 'vue'
import Router from 'vue-router'
import NotFound from '@/views/lainaayritykselle_fi/NotFoundView'
import OidcCallback from '@/views/lainaayritykselle_fi/OidcCallbackView'
import Meta from 'vue-meta'
import settings from '@/settings/settings'
import RedirectView from '@/views/RedirectView'
// Route translations
import { routes } from './routes'

const RN = routes[settings.LOCALE]
Vue.use(Meta)
Vue.use(Router)
RN.routes.push({
  path: '/redirect/:uuid/',
  name: 'redirectView',
  component: RedirectView,
})

RN.routes.push({
  path: '/oidc-callback', // Needs to match redirect_uri in you oidcSettings
  name: 'oidcCallback',
  component: OidcCallback,
  meta: {
    isPublic: true,
    isOidcCallback: true,
  },
})

// Common 404 route, has to be added last
RN.routes.push({
  path: '*',
  name: 'NotFound',
  component: NotFound,
  meta: {
    title: 'Lainaayritykselle.fi',
  },
})
/*
 * Replacing default router.push() function because it throws an error below when using next({ ... })
 * https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  mode: 'history',
  routes: RN.routes,
  linkActiveClass: 'ddd',
  linkExactActiveClass: 'exact-acti1e',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Middleware for manipulating different scenaris inside app
router.beforeEach((to, from, next) => {
  // set page title from meta
  document.title = to.meta.title
  // Check auth if needed
  Vue.saverium.getSessionKeyValue({ query: to.query })
  if (to.meta.requireLogin) {
    Vue.saverium.oidcUserManager.getUser().then(user => {
      if (user) {
        next()
      } else {
        // This is raising an error with original push() function (that is why it is replaced above)
        next({
          path: RN.paths.LoginView,
          query: { next: to.fullPath },
          replace: true,
        })
      }
    })
  } else {
    next()
  }
})

export default router
