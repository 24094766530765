import Vue from 'vue'
import Router from 'vue-router'

import NotFoundView from '@/views/NotFoundView'
import OidcCallbackView from '@/views/OidcCallbackView'
import RedirectView from '@/views/RedirectView'
import settings from '@/settings/settings'
import { routes } from './routes'

const RouteObj = routes[settings.LOCALE]

Vue.use(Router)
RouteObj.routes.push({
  path: '/redirect/:uuid/',
  name: 'redirectView',
  component: RedirectView,
})
// Common 404 route, has to be added last
RouteObj.routes.push({
  path: '*',
  name: 'NotFoundView',
  component: NotFoundView,
  meta: {
    title: 'Saverium.es',
  },
})

RouteObj.routes.push({
  path: '/oidc-callback', // Needs to match redirect_uri in you oidcSettings
  name: 'oidcCallbackView',
  component: OidcCallbackView,
  meta: {
    isPublic: true,
    isOidcCallback: true,
  },
})

const router = new Router({
  mode: 'history',
  routes: RouteObj.routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Middleware for manipulating different scenaris inside app
router.beforeEach((to, from, next) => {
  // set page title from meta
  document.title = to.meta.title
  next()
})

export default router
