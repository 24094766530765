import settings from '@/settings/settings'

const BRAND_SLUG = settings.BRAND_SLUG
const BRAND_NAME = settings.BRAND_NAME
// Compose loan application body for API request
const composeApplicationBody = (application, sessionKey) => {
  let body = {
    brand_slug: BRAND_SLUG,
    brand_name: BRAND_NAME,
    session_key: sessionKey,
    amount: application.amount,
    time_term: application.repayment_time,
    applicants: [
      {
        first_name: application.firstName,
        last_name: application.lastName,
        ssn: application.ssn,
        email: application.email,
        phone: application.phone,
        birth_date: application.dateOfBirth,
      },
    ],
    tos_accepted: application.tosAccepted,
    personal_data_policy: application.tosAccepted,
    marketing_consents: application.marketing,
  }
  return body
}

export default {
  composeApplicationBody,
}
