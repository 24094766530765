<template>
  <div class="row article">
    <h1 class="center-title">Pozabankowa karta kredytowa online - co to jest?</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            Karta kredytowa umożliwia dokonywanie płatności za towary bądź usługi nawet w
            sytuacji, gdy jej posiadacz nie dysponuje wolnymi środkami finansowymi. Karta
            może zostać wydana przez bank lub przez firmę pożyczkową. Czym są karty
            kredytowe pozabankowe i na jakich zasadach funkcjonują?
          </p>
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul class="table-of-contents">
              <li>
                <a href="#karta-kredytowa-od-reki-charakterystyka-produktu">
                  Karta kredytowa od ręki - charakterystyka produktu
                </a>
              </li>
              <li>
                <a href="#jak-otrzymac-karte-kredytowa-online">
                  Jak otrzymać kartę kredytową online?
                </a>
              </li>
              <li>
                <a href="#splata-zadluzenia-z-karty-kredytowej">
                  Spłata zadłużenia z karty kredytowej
                </a>
              </li>
              <li>
                <a href="#karta-kredytowa-przez-internet-dlaczego-warto">
                  Karta kredytowa przez internet - dlaczego warto?
                </a>
              </li>
            </ul>
          </div>
        </div>
        <b-img-lazy
          width="700"
          height="466"
          blank-src="/static/img/saverium_pl/article-images/online-nonbank-credit-card-tiny.jpg"
          blank-height="700px"
          blank-width="466px"
          class="article-img"
          src="/static/img/saverium_pl/article-images/online-nonbank-credit-card.jpg"
          alt="Pozabankowa karta kredytowa online - co to jest?"
        />
        <div class="text-container mt-5">
          <h2 id="karta-kredytowa-od-reki-charakterystyka-produktu">
            Karta kredytowa od ręki - charakterystyka produktu
          </h2>
          <p>
            Karta kredytowa często bywa mylona z kartą debetową, dlatego też na początek
            warto wyjaśnić, jaka jest między nimi różnica. Otóż kartą debetową możemy
            dokonywać płatności wyłącznie w momencie, gdy posiadamy na koncie wolne środki
            pieniężne. Oznacza to, że karta debetowa wydawana jest do konta osobistego.
          </p>
          <p>
            Tymczasem karta kredytowa umożliwia dokonywanie płatności na takich samych
            zasadach, jak karta debetowa, jednak różnica polega na tym, że aby jej używać,
            nie trzeba dysponować wolnymi środkami finansowymi. Na karcie zakodowany jest
            pewien limit, do którego klient może dokonywać płatności - zazwyczaj wynosi on
            od kilkuset do nawet kilku tysięcy złotych. Karta kredytowa może zostać wydana
            przez bank bądź przez firmę pożyczkową. Ta druga instytucja wydaje karty
            kredytowe bez zaświadczeń, co oznacza, że procedura ich przyznania jest
            znacznie uproszczona.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="jak-otrzymac-karte-kredytowa-online">
            Jak otrzymać kartę kredytową online?
          </h2>
          <p>
            Karta kredytowa online może zostać przyznana klientowi, który złoży wniosek -
            znaleźć go można na stronie internetowej firmy pożyczkowej. We wniosku należy
            podać dane osobowe, jednak klient nie ma obowiązku przesyłania dodatkowej
            dokumentacji, na przykład zaświadczenia o zatrudnieniu, zarobkach, wyciągu z
            konta bankowego. Wręcz przeciwnie, wystarczy tylko wypełnić wniosek o
            pozabankową kartę kredytową, a następnie przejść procedurę weryfikacyjną.
          </p>
          <p>
            Ma ona na celu ustalenie tożsamości klienta, a polega na wykonaniu przelewu z
            własnego konta bankowego na rachunek firmy pożyczkowej. Karty kredytowe
            pozabankowe charakteryzuje wysoki stopień przyznawania, głównie ze względu na
            fakt, iż w całej procedurze nie jest badana
            <a
              href="https://www.saverium.pl/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/"
              rel="noopener noreferrer"
              target="_blank"
              >zdolność kredytowa</a
            >
            klienta. Po zatwierdzeniu wniosku karta kredytowa zostaje przesłana klientowi,
            ten zaś po jej aktywowaniu może dokonywać płatności w sklepach stacjonarnych
            oraz internetowych.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="splata-zadluzenia-z-karty-kredytowej">
            Spłata zadłużenia z karty kredytowej
          </h2>
          <p>
            Dokonując płatności kartą kredytową, klient zaciąga w firmie pożyczkowej swego
            rodzaju kredyt. Dysponuje on bowiem pieniędzmi w ramach przyznanego limitu,
            jednak po okresie kredytowania wydatkowane środki finansowe należy zwrócić
            wraz z należnymi odsetkami. Te zaś wynikają bezpośrednio z zawartej umowy.
            Dodatkowo klient może zostać zobowiązany do pokrycia pozostałych kosztów, na
            przykład z tytułu wydania karty. Sama spłata powstałego zadłużenia może mieć
            charakter jednorazowy bądź też ratalny, w zależności od potrzeb oraz
            możliwości klienta. Limit na karcie ma charakter odnawialny, bowiem po
            spłaceniu powstałego długu klient ponownie może dokonywać płatności w
            wyznaczonym umową okresie. Warto pamiętać, że klient oddaje firmie pożyczkowe
            jedynie kwotę, którą rzeczywiście wydatkował, a nie cały przyznany limit.
          </p>
          <p>
            Karta kredytowa online przeznaczona jest dla osób, które posiadają jakieś
            źródło dochodów, a także nie są zadłużone. Obecnie nie ma możliwości uzyskania
            karty kredytowej bez bik, czyli dla klientów z niespłaconymi długami.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="karta-kredytowa-przez-internet-dlaczego-warto">
            Karta kredytowa przez internet - dlaczego warto?
          </h2>
          <p>
            Pozabankową kartę kredytową można uzyskać w bardzo prosty sposób. Sam wniosek
            o kartę kredytową online wypełnia się w kilka minut, natomiast produkt ten
            charakteryzuje się wysokim stopniem przyznawania. Klient za pomocą karty
            kredytowej może poprawić swoją płynność finansową, dokonując płatności za
            wybrane przez siebie towary czy usługi.
          </p>
          <p>
            Pozabankowa karta kredytowa to doskonałe rozwiązanie dla osób, które nie chcą
            wiązać się umową z bankiem, a zatem i zakładać w nim konta, bowiem jest to
            częsty wymóg tego typu instytucji, aby karta mogła zostać przyznana.
            Uproszczona procedura wydawania karty pozabankowej okazuje się być nieoceniona
            dla tych, którzy w krótkim czasie chcą pozyskać dodatkową gotówkę. Odnawialny
            limit pozwala natomiast korzystać z pieniędzy przez dłuższy okres czasu.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
export default {
  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
