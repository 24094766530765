<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til investering</h1>

      <div class="top-text text-container">
        <p>
          Ønsker du at låne penge til at investere i ejendomme, aktier eller noget helt
          tredje? Vi hjælper dig med hurtigt og nemt at få et overblik over lånemarkedet,
          så du kan vurdere, om din gearing bliver en rentabel forretning. Udfyld vores
          ansøgningsskema på 2 minutter, så finder vi en lang række lån, som du kan
          benytte som investeringslån.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#saadan-finder-vi-de-bedste-investeringslaan"
                    >Sådan finder vi de bedste investeringslån</a
                  >
                </li>
                <li>
                  <a href="#vigtig-information-om-investeringslaan"
                    >Vigtig information om investeringslån</a
                  >
                </li>
                <li>
                  <a href="#laan-til-alle-typer-investeringer"
                    >Lån til alle typer investeringer</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="saadan-finder-vi-de-bedste-investeringslaan">
            Sådan finder vi de bedste investeringslån
          </h2>
          <p>
            Hvis du ønsker at finde det bedste lån investeringer og have det højeste
            return on investment, er det altafgørende, om du kan få et
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >billigt lån</a
            >.
          </p>
          <p>
            Hvis du samtidig ønsker vores hjælp til at finde lånet til investeringen, så
            har vi kun brug for, at du udfylder vores
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låneansøgning</a
            >, hvilket som nævnt blot tager 2 minutter.
          </p>
          <p>
            Når du udfylder en ansøgning om at låne til en investering, fremsender de
            oplysninger, som du har afgivet i ansøgningen, til en lang række banker og
            alternative långivere. Disse långivere foretager derefter en fuldautomatisk
            kreditvurdering af dig, hvorefter de vender tilbage med et tilbud på et
            investeringslån til dig.
          </p>
          <p>
            Vi opstiller alle de godkendte tilbud om investeringslån, som du modtager, på
            en overskuelig måde, så du nemt kan se, hvilken udbyder der kan tilbyde den
            laveste ÅOP, som ofte er den afgørende faktor ved gearing / lån til
            investering.
          </p>
          <p>
            Nu er det blot op til dig, at vælge din foretrukne udbyder, hvorefter du skal
            verificere dine oplysninger på udbyderens hjemmeside via
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >NemID / MitID</a
            >, før investeringslånet udbetales til din konto, så du kan foretage en
            ejendomsinvestering, eller hvad du ellers ønsker at investere i.
          </p>
          <p>
            Efter som processen er 100% automatiseret, vil du kunne modtage tilbud og
            optage investeringslån, i form af et usikret
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >, hele døgnet og på alle årets 365 dage.
          </p>
          <p>
            Hvis du ønsker at låne til en investering i en erhvervsmæssig sammenhæng, skal
            du i stedet for ansøge om et
            <a
              href="https://www.saverium.dk/laan-penge/erhvervslaan"
              rel="noopener noreferrer"
              target="_blank"
              >erhvervslån</a
            >, hvilket fx ofte vil være tilfældet for lån til ejendomsinvesteringer.
          </p>
        </div>

        <div class="text-container">
          <h2 id="vigtig-information-om-investeringslaan">
            Vigtig information om investeringslån
          </h2>
          <p>
            Når du låner til investering, er det vigtigt for os at nævne, at vi ikke er
            investeringsrådgivere, og derfor hverken kan anbefale eller fraråde dig at
            investere. Har du spørgsmål om, hvorvidt du skal investere i ejendomme,
            aktier, om du skal shorte, investere i FTE’er eller noget helt andet, så må vi
            henvise til professionelle rådgivere, eller du må bruge din egen viden på
            området.
          </p>
          <p>
            Hvis du overvejer at geare din investering, regner vi med at du allerede er
            relativt godt inde i aktiemarkedet og kender til den risiko, volatilitet,
            afkast m.v. som aktiemarkedet og andre investeringsområder indebærer. Hvis vi
            blot ser på området for lån til investeringer, er det vigtigt for os at nævne,
            at du selvfølgelig skal se på ÅOP'en for de investeringslån, som du bliver
            tilbudt, og holde dem op imod det afkast, som du kan få på din investering.
          </p>
          <p>
            I mange tilfælde, vil du kunne få et højere forventet afkast på din
            investering end omkostningerne ved at optage et investeringslån igennem de
            banker, som vi
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligner lån</a
            >
            blandt, men hvor det er 100% sikkert, at du skal tilbagebetale lånet, er der
            derimod større usikkerhed forbundet med dit afkast på din investering.
          </p>
          <p>
            Hvis du har et forventet årligt afkast på 5% og et tilbud om at låne penge til
            investeringen med en ÅOP på 4%, står du stadigvæk med en risiko, som
            professionelle investorer normalt ikke ville acceptere, selv ikke hvis du
            investerer kedeligt og “sikkert” i fx S&amp;P 500, Down Jones Industrial
            Average, MSCI South Africa Index eller lignende.
          </p>
          <p>
            Når du låner til investeringer, eller blot investerer helt generelt, har du
            desuden indirekte “omkostninger” forbundet med inflationen, samt at du har
            bundet din kapital i investeringen, som derfor ikke kan bruges frit på samme
            vis som penge på din konto.
          </p>
          <p>
            Ovennævnte omkostninger bør du derfor også huske som en del af prisen ved at
            investere. Når du låner til investeringer, bør disse faktorer også indgå, når
            du overvejer hvor stor difference mellem renten på investeringslånet og det
            forventede afkast på din investering skal være.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-alle-typer-investeringer">Lån til alle typer investeringer</h2>
          <p>
            Uanset hvilken type af investering du ønsker at låne til, så kan vi hjælpe
            dig. Der findes et hav af forskellige investeringsmuligheder, så herunder vil
            vi gennemgå nogle af de mest udbredte investeringsområder, som vi ser har
            interessen fra vores brugere.
          </p>
          <h3>Investering i uddannelse</h3>
          <p>
            En lidt alternativ investeringsmulighed, hvis man kan kalde det dét, er at
            låne penge til at investere i en uddannelse til sig selv. I bund og grund er
            dette en investering lige som så mange andre former, da du tager en omkostning
            her og nu, i form af at låne penge og bruge din tid, for senere hen at få et
            afkast i form af en højere løn. Du kan få mere af vide om lige præcis denne
            form for investering ved at læse mere på vores side, som er dedikeret til at
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan/uddannelse"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge til uddannelse</a
            >.
          </p>
          <h3>Investering i ejendele</h3>
          <p>
            Originale designermøbler, dyre ure og klassiske biler, som der er et begrænset
            udbud af, og som ikke bliver produceret mere, kan være en god investering. Her
            vælger mange at købe en ejendel, som de også selv får glæde af at bruge,
            hvilket fx kunne være et ur fra 50érne eller 60érne fra Patek Philippe, Omega
            eller Rolex, som både er et lækkert ur at gå med, men også kan give et godt
            afkast.
          </p>
          <p>
            Ud over det begrænsede udbud, som alt andet lige vil blive mindre og mindre i
            takt med at disse genstande bliver totalskadet eller på anden måde forsvinder,
            betaler mange købere også for patina. Jo mere du bruger genstanden uden at den
            desideret går i stykker, jo højere bliver salgsprisen også.
          </p>
          <p>
            Husk altid at få alle parirer, kvitteringer, certificater, æsker m.v. med når
            du investerer i fx et ur, da disse ting har en stor betydning for din
            gensalgsværdi.
          </p>
          <h3>Investering i statsobligationer</h3>
          <p>
            Hvis du låner til at investere i statsobligationer, foretager du den måske
            mindst resikable investering, som findes. For en rigtig spekulant er det måske
            ikke super interessant med et forventet afkast på 2-5% om året, men risikoen
            er også så tæt på 0, som du kommer med investeringer. Hvis du blot mangler et
            sted at opbevare dine penge, som du ved ikke skal benyttes inden for
            obligationens løbetid, så kan det stadigvæk være en bedre forretning at
            placere pengene i statsobligationer frem for banken.
          </p>
          <h3>Investering i din egen bolig</h3>
          <p>
            I en helt anden boldgade finder vi lån til investering i egen bolig. Dette vil
            som regl være et <strong>lån til renovering</strong>, herunder
            <strong>varmepumpe</strong>, <strong>solceller</strong> eller andre
            <strong>energiforbedringer</strong>. Når du låner til denne investering, vil
            du, modsat de fleste andre investeringer, få et afkast fra første dag i form
            af mindre strøm- og varmeregninger.
          </p>
          <p>
            Når du efterfølgende skal sælge din bolig, vil du desuden kunne sætte en
            højere salgspris for din bolig. Stigning i salgsprisen vil dog aldrig nå op på
            det niveau, som du har investeret for, da dine installationer bliver slidt og
            teknisk outdated med tiden. Samlet set kan disse former for investeringer i
            boligen oftest betale sig, hvis du planlægger at blive boende i boligen nogle
            få år ud i fremtiden. I meget sjældne tilfælde, kan der være en prisstigning,
            som overstiger din investering, hvis du har foretaget præcis de investeringer,
            som køberen ellers selv ville have foretaget, da køberen ikke selv får
            besværet med at indhente tilbud, have håndværker gående o.s.v.
          </p>
          <p>
            Mange vælger også disse former for investeringer grundet den grønne
            investeringsprofil. Hvis du ikke ønsker at investere penge i kul, olie, våben
            eller andet, som kan være skadelige for naturen og verden, kan et lån til
            investeringen i din egen bolig være en oplagt mulighed.
          </p>
          <h3>Investering i valuta</h3>
          <p>
            Valutainvesteringer er meget simple. Vælger du at låne til at investere i
            valuta, bruger du dit lån på at opkøbe en bestemt valuta, fx USD, EUR, GBP
            eller en helt fjerde valuta. Herefter følger du blot kursudviklingen og
            sælger, når der er en stor nok fortjeneste. Grundet fastkurspolitiken, giver
            det selvfølgelig ikke mening at lave en valutainvestering i EUR, hvis du
            bruger DKK til at investere.
          </p>
          <p>
            Du kan også vælge at sætte dine penge i kryptovaluta, fx Bitcoins og Ethereum,
            på helt samme måde som traditionel valuta. Her har volitaliteten dog historisk
            set været langt større, og investering i krypto kan derfor på nogle måder
            sammenlignes med en gearet investering i traditionel valuta.
          </p>
          <p>
            Hvis du ønsker at låne penge til investering i valuta, så vær opmærksom på
            lånets løbetid, og om du evt. kan afbetale lånet når som helst, uden at skulle
            betale for den tid, hvor du ikke har lånt penge. Dette er ekstra vigtigt med
            en kortsigtet investering, som en valutainvestering typisk er.
          </p>
          <h3>Lån til ejendomsinvestering</h3>
          <p>
            Et sidste stort område, som vi vil gennemgå, er investering i ejendomme -
            altså ejendomsinvestering. Her kan vi, lige som inden for de andre områder,
            hjælpe med lånet til ejendomsinvesteringen, men ikke selve rådgivningen.
          </p>
          <p>
            Skal du ud at købe et hus, og i den forbindelse skal have et lån, så er det i
            princippet en ejendomsinvestering, som du låner penge til. Dette er langt den
            hyppigste form for ejendomsinvestering, som sjovt nok sjældent omtales som
            dette.
          </p>
          <p>
            De fleste som søger om låner til en ejendomsinvestering, vil dog typisk være
            folk, der ønsker et lån til en større ejendom, som kan udlejes til privat
            eller erhvervsmæssige formål.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
