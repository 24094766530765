<template>
  <div
    class="container logos-wrapper flex-content-center justify-content-center align-items-center"
  >
    <h2 class="coop-title">{{ $t('our_partners') }}</h2>
    <b-row class="align-items-center white-background">
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/business-credit.png"
          alt="Business Credit"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/certum.png"
          alt="Certum"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/fellow_finance.svg"
          alt="Fellow Finance"
          height="100%"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/capital-box.png"
          alt="Capital Box"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/gf.png"
          alt="GF Money"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/kasvurahoitus.png"
          alt="Kasvurahoitus"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/qred.png"
          alt="Qred"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/rahoittaja.png"
          alt="Rahoittaja"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/bf-logo.png"
          alt="Bluefinance"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/siltaraha.png"
          alt="Siltaraha"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/yritysluotto.png"
          alt="Yritysluotto"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/koin-capital.png"
          alt="Yritysluotto"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/credinord.png"
          alt="Yritysluotto"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/yrityslaina247.png"
          alt="Yrityslaina247"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/fundu.png"
          alt="Fundu"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/vauraus.png"
          alt="Vauraus"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/amex.png"
          alt="Amex Oy"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/opr-flex.png"
          alt="OPR flex"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/svea.png"
          alt="Svea"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/estateguru.png"
          alt="Estateguru"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/resursbank.png"
          alt="resursbank"
        />
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="4" class="text-center">
        <b-img
          class="logo"
          src="/static/img/lainaayritykselle_fi/partner-logos-sm/sme-finance.svg"
          alt="SME Finance"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ParagraphHeader from '../ParagraphHeader'
export default {
  components: {
    ParagraphHeader,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-container {
  margin-top: 50px;
  border-bottom: 1px solid rgb(230, 230, 230);
  min-height: 170px;
  margin-bottom: 0;
  max-width: 100%;
}

.column {
  margin-top: 20px;
  margin-bottom: 20px;
}

.icon {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
}

.title {
  color: #2a76bc;
  text-transform: uppercase;
  font-weight: 600;
}
.logo {
  margin: 20px;
}
@media only screen and (min-width: 1200px) {
  .last-logo {
    margin-left: 100px;
  }
}
.coop-title {
  text-transform: uppercase;
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: 60px;
  text-align: center;
}
</style>
