<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån penge med MitID eller NemID</h1>
      <div class="top-text text-container">
        <p>
          Vi kan hjælpe dig med at låne penge med MitID og NemID. Hvis du udfylder vores
          2-minutters ansøgningsformular, indhenter vi med det samme tilbud fra en lang
          række banker og långivere til dig, så du kan vælge det bedste og billigste lån
          med MitID eller NemID.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#laan-med-nem-og-mitid-gennem-os"
                    >Lån med Nem- og MitID gennem os</a
                  >
                </li>
                <li>
                  <a href="#hvorfor-skal-man-bruge-mitid-naar-man-laaner"
                    >Hvorfor skal man bruge MitID når man låner?</a
                  >
                </li>
                <li>
                  <a href="#kan-man-stadigvaek-laane-penge-med-nemid"
                    >Kan man stadigvæk låne penge med NemID?</a
                  >
                </li>
                <li><a href="#hvad-kan-man-laane-til">Hvad kan man låne til?</a></li>
                <li><a href="#hvad-er-nemid-mitid">Hvad er NemID / MitID</a></li>
                <li>
                  <a href="#sammenlign-laan-med-mitid-nemid"
                    >Sammenlign lån med MitID / NemID</a
                  >
                </li>
                <li>
                  <a href="#skal-man-bruge-mitid-naar-man-benytter-en-laanebroker"
                    >Skal man bruge MitID når man benytter en lånebroker?</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="laan-med-nem-og-mitid-gennem-os">Lån med Nem- og MitID gennem os</h2>
          <p>
            Hvis du ønsker at låne penge med MitID eller NemID, er du havnet det rigtige
            sted. Hvis du ønsker at låne penge uden MitID eller NemID, er det desværre
            ikke længere muligt i Danmark.
          </p>
          <p>
            Det første du skal gøre for at låne med MitID / NemID er at udfylde formularen
            her på siden. Herefter indhenter vi uforpligtende lånetilbud fra en lang række
            banker og långivere, så du kan sammenligne og vælge det bedste lån. Når du har
            valgt dit ønskede lånetilbud, vil du blive sendt videre til låneudbyderens
            hjemmeside, hvor du kan underskrive din låneaftale digitalt og få udbetalt
            lånet samme dag. Alle tilbud, som vi indhenter er gratis og uforpligtende.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvorfor-skal-man-bruge-mitid-naar-man-laaner">
            Hvorfor skal man bruge MitID når man låner?
          </h2>
          <p>
            Det er hurtigt at låne med MitID og samtidig er både sikkerheden og
            bekvemmeligheden høj for dig og långiveren. I modsætning til en gammeldags
            fysisk underskrift, er det blevet meget sværere at forfalske overskrifter og
            dermed langt sværere at optage lån med MitID i andres navne, hvilket både er
            en fordel for dig og långiveren.
          </p>
          <p>
            Dernæst har du også fordelen ved ikke at skulle scanne eller indsende en
            underskrift fysisk, for at banken kan se, at du har underskrevet låneaftalen,
            hvilket både er besværligt og tidskrævende sammenlignet med processen når du
            låner med MitID. Ligeledes har banken en stor fordel ved at du låner dine
            penge med MitID, da de modtager en digital underskrift, som nemt kan håndteres
            og behandles automatisk i deres systemer. Når du låner med MitID lettes
            arbejdsbyrden for banken og lånet udbetales hurtigere.
          </p>
          <p>
            Samlet set er lån generelt, men især online lån med MitID, langt hurtigere med
            den nemme godkendelse end det var for blot få år siden. Dernæst skulle man
            også forvente, hvilket dog hverken er muligt at be- eller afkræfte, at lån med
            MitID er billigere end tidligere, grundet den mere automatiserede process for
            bankerne.
          </p>
          <p>
            Der er næsten udelukkende fordele at se i forhold til processen, der krævede
            fysisk underskrift i starten af 2000, når du i
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligner lån</a
            >
            med NemID og lån med MitID. Det er især blevet hurtigere at låne af at
            långiverne har indført NemID, som nu er blevet erstattet af MitID. Den eneste
            ulempe, som vi kan komme på, er hvis du har mistet din mobil eller telefonen
            er løbet tør for strøm.
          </p>
          <p>
            Hvis du ikke allerede har MitID-appen, kan du her hente
            <a
              href="https://play.google.com/store/apps/details?id=dk.mitid.app.android"
              rel="noopener noreferrer"
              target="_blank"
              >MitID til Android</a
            >
            og
            <a
              href="https://apps.apple.com/us/app/mitid-app/id1555231176"
              rel="noopener noreferrer"
              target="_blank"
              >MitID til iOS</a
            >.
          </p>
          <h3>Lån uden NemID / MitID</h3>
          <p>
            I dag er det praktisk talt umuligt at komme uden om MitID når du
            <a
              href="https://saverium.dk/laan-penge/privatlaan"
              rel="noopener noreferrer"
              target="_blank"
              >låner penge privat</a
            >, hvis du ønsker at holde dig inden for det regulerende lånemarked, hvor du
            som forbruger er beskyttet. Det eneste sted, hvor det er muligt at optage lån
            uden MitID, som muligvis kunne være en god løsning, er hos din familie. Her
            kan der dog opstå mange andre problemer, og det er de færreste, som ønsker at
            have penge i klemme blandt familiemedlemmer. Derfor anbefaler vi altid at du
            undersøger dine lånemuligheder blandt de etablerede banker og långivere, før
            du overvejer lån fra familiemedlemmer.
          </p>
        </div>

        <div class="text-container">
          <h2 id="kan-man-stadigvaek-laane-penge-med-nemid">
            Kan man stadigvæk låne penge med NemID?
          </h2>
          <p>
            NemID blev udfaset i løbet af 2022, og du kan derfor ikke længere låne penge
            med NemID. Du vil derfor heller ikke opleve, at der kommer nye lån med NemID
            på markedet. Hvis du støder på tilfælde, der tilbyder lån med NemID, vil det
            dog være særtilfælde, da både NemID-appen og NemID-papkortet bør være
            forældede på nuværende tidspunkt.
          </p>
          <p>
            Forskellen på NemID og
            <a href="https://www.mitid.dk/" rel="noopener noreferrer" target="_blank"
              >MitID</a
            >
            er dog meget små fra brugerens synspunkt. Hvis du ansøger om et
            <a
              href="https://saverium.dk/laan-penge/online-laan"
              rel="noopener noreferrer"
              target="_blank"
              >lån online</a
            >
            og ønsker at underskrive låneaftalen digitalt, logger du blot ind på din MitID
            app i stedet for din NemID app med din kode / fingerscanner og swiper til
            højre, som danskerne har gjort siden NemID-appen blev lanceret d. 18 maj 2018.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-kan-man-laane-til">Hvad kan man låne til?</h2>
          <p>
            Hvis du benytter vores tjeneste, har vi en lang række ting, som du kan låne
            til. De hurtige lån med MitID hænger i de fleste tilfælde sammen med
            <a
              href="https://saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >, men det ses også ofte at lånet benyttes til opgradering af
            <a
              href="https://saverium.dk/laan-penge/bolig"
              rel="noopener noreferrer"
              target="_blank"
              >bolig</a
            >. Uanset om du ønsker et <strong>lån til ferie</strong>,
            <a
              href="https://saverium.dk/laan-penge/forbrugslaan/baad"
              rel="noopener noreferrer"
              target="_blank"
              >lån til en båd</a
            >, betale regninger eller noget fjerde, så er det muligt at låne, såfremt du
            har dit MitID på dig.
          </p>
          <p>
            Som nævnt tidligere forløber processen ganske hurtigt, da underskriften er
            digital og bankernes processer i stor udstrækning er digitale og automatiske.
            Står du med et tilbud her og nu, eller en regning der skal betales i dag,
            giver de nye lån med MitID dig en stor fordel.
          </p>
          <h3>Alternativ mulighed når du låner med din digitale signatur</h3>
          <p>
            En anden mulighed, som typisk ikke forbindes med de nye lån med MitID, er lån,
            som optages med henblik på at samle / refinansiering <strong>gæld</strong>.
            Her låner du, for at
            <a
              href="https://saverium.dk/laan-penge/samlelaan"
              rel="noopener noreferrer"
              target="_blank"
              >samle dine eksisterende lån</a
            >
            hos én långiver, hvilket giver en lavere rente og ofte en lavere månedlig
            ydelse. Derudover vil der kun være ét lån, som kan kan generere gebyrer, og
            dermed falder dine omkostninger til lånet yderligere. Dernæst er der også værd
            at nævne, at du kun skal holde styr på ét lån frem for eksempelvis fem mindre
            lån, hvilket også har en stor værdi - ikke i kroner og øre, men i
            livskvalitet.
          </p>
          <p>
            På samme vis, som alle andre lån, skal du også benytte dit MitID i dette
            tilfælde, da du optager et
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >nyt lån</a
            >
            med dit MitID, for at afvikle dine eksisterende lån.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-er-nemid-mitid">Hvad er NemID / MitID</h2>
          <p>
            NemID og MitID er begge ejet af
            <a href="https://digst.dk/" rel="noopener noreferrer" target="_blank"
              >Digitaliseringsstyrelsen</a
            >, som har til formål at digitalisere offentlige instanser i Danmark. I
            låneøjemed har det dog også den fordel, at det er hurtigt at låne med NemID og
            MitID.
          </p>
          <h3>Hvad kan man bruge det til?</h3>
          <p>
            En række offentlige services benytter NemID og MitID når du logger ind. Det
            gælder bla.
            <a href="https://skat.dk/" rel="noopener noreferrer" target="_blank"
              >Skat.dk</a
            >,
            <a href="https://www.e-boks.com/" rel="noopener noreferrer" target="_blank"
              >e-boks</a
            >
            og
            <a href="https://www.borger.dk/" rel="noopener noreferrer" target="_blank"
              >borger</a
            >. Ud over de offentlige services, som benytter digital signatur, er NemID og
            MitID nu også udbredt til private formål, så du fx kan underskrive nye lån med
            MitID eller NemID eller underskrive aftaler med forsikringsselskaber og
            pensionsaftaler digitalt.
          </p>
          <h3>Hvordan får jeg det??</h3>
          <p>
            Hvis du er fyldt 15 år og har et CPR-nummer, som er godkendt til NemID/MitID,
            har du mulighed for at få MitID. Det er ikke længere være muligt at få NemID,
            da det nye MitID har erstattet NemID. Du skal dog være opmærksom på, at det
            ikke er nok blot at være i besiddelses af MitID, hvis du påtænker at låne med
            MitID.
          </p>
          <p>
            For at låne penge med MitID, skal du, hos langt de fleste udbydere af lån være
            fyldt 18 år. Desuden er lån med MitID / NemID trods RKI ikke muligt, da ingen
            låneudbydere i Danmark tilbyder lån, hvis du står registreret i RKI.
          </p>
          <p>
            Hvis dit NemID eller MitID er blevet spærret, og du har behov for at
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >, skal du tage kontakt til din bank. Banken kan hjælpe dig med at genåbne din
            digitale signatur, eller fortælle dig hvem du evt. skal kontakte, så du igen
            kan låne penge med MitID.
          </p>
        </div>

        <div class="text-container">
          <h2 id="sammenlign-laan-med-mitid-nemid">Sammenlign lån med MitID / NemID</h2>
          <p>
            Når du benytter en sammenliningstjeneste, som vores, hvilket også kaldes en
            lånebroker, vil det være forskelligt fra broker til broker, om du skal benytte
            MitID.
          </p>
          <p>
            De fleste låneudbydere kræver at du giver adgang til eSkat, dine transaktioner
            (posteringer i netbank) og verificerer dit CPR-nummer. Den eneste mulighed for
            at gøre det, er ved at give adgang med MitID/NemID før du kan blive godkendt
            til lånet. I nogle tilfælde skal du logge ind med MitID/NemID inden bankerne
            sender
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >lånetilbudene</a
            >. I andre tilfælde vil du først blive bedt om login/verificering efter at du
            har valgt tilbuddet, men inden at lånet udbetales. I sidste ende kommer du dog
            til at benytte dit NemID/MitID for at låne penge og ofte gange flere gange i
            løbet af låneprocessen.
          </p>
          <p>
            Andre typer af sammenligningstjenester indhenter ikke lånetilbud til dig, men
            viser derimod generelle informationer om lånene fra forskellige banker og
            låneudbydere. Hos disse tjenester vil du ikke blive bedt om NemID eller MitID
            login - dog kan du heller ikke låne via disse tjenester, da de blot vil sende
            dig videre til bankens/låneudbyderens hjemmeside eller til en decideret
            lånebroker, som os.
          </p>
          <p>
            Din sidste mulighed er at gå ind på alle bankers hjemmesider og ansøge om et
            nyt lån med MitID / NemID én efter én. Denne process er af flere årsager
            langsommelig, kedelig og tidskrævende. I første omgang skal du finde frem til
            alle de låneudbydere der findes. Dernæst skal du finde frem til det sted på
            udbyderens hjemmeside, hvor du kan ansøge om et
            <a
              href="https://saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtigt lån</a
            >
            med MitID / NemID. For det tredje skal du udfylde ansøgningsskemaer for hver
            enkelt bank. Sidst men ikke mindst, har du ikke en samlet og overskuelig liste
            lånetilbud, hvilket du får, når du ansøger hos os.
          </p>
          <p>
            Når du låner penge i dag, uanset hvilken type forbrug vi taler om, så er det
            svært at komme uden om brokers, og vi håber du kan se fordelen ved at bruge
            vores tjeneste.
          </p>
        </div>

        <div class="text-container">
          <h2 id="skal-man-bruge-mitid-naar-man-benytter-en-laanebroker">
            Skal man bruge MitID når man benytter en lånebroker?
          </h2>
          <p>Det korte svar er “måske”, så det kan du ikke bruge til så meget.</p>
          <p>
            Det lidt længere svar er, at det varierer fra broker til broker, da nogle
            brokers vælger at indhente alle eller dele af de informationer, som du du skal
            oplyse, når du ansøger om lån via dem, gennem diverse NemID / MitID-opslag.
          </p>
          <p>
            Fremtidsscenariet er nok, at den data, som lånebrokers indhenter via et NemID
            / MitID-opslag, vil blive accepteret af finanstilsynet, som værende lige så
            valid, som den information bankerne og långiverne selv indhenter via
            MitID-opslag i forbindelse med kreditvurdering til lån. I princippet er det
            præcis den samme data begge parter indhenter, men da det ikke i skrivende
            stund helt sikkert, hvilke oplysninger bankerne selv skal validere, og hvilke
            oplysninger de må stole på fra tredjepart, vil du komme til at støde på mange
            NemID / MitID-opslag for at optage et nyt lån, når du benytter en broker der
            indhenter data via NemID eller MitID.
          </p>
          <p>
            Det har dog også nogle fordele, at indhente data via disse digitale opslag,
            bla. at der vil være en del information vedr. din økonomi, som du ikke selv
            behøver at overveje, da informationen kan hentes via din netbank og
            <a href="https://skat.dk/" rel="noopener noreferrer" target="_blank">Skat</a>.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },

  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
