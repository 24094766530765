<template>
  <div class="row article">
    <h1 class="center-title">
      Pożyczki społecznościowe – czym jest kredyt społecznościowy?
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >Kredyt jest produktem stricte bankowym, udzielanym przede wszystkim na
              podstawie przepisów Prawa bankowego. Nieco innym rozwiązaniem są pożyczki,
              oferowane przez różnego rodzaju instytucje finansowe, a nawet osoby
              prywatne. Regulacje dotyczące umów pożyczkowych znalazły się w przepisach
              Kodeksu cywilnego. Natomiast co należy rozumieć przez pojęcie pożyczka
              społecznościowa lub kredyt społecznościowy?</i
            >
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/social-loan-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/social-loan.jpg"
            alt="Pożyczki chwilówki online – kredyty chwilówki przez internet"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container mt-5">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            Od wielu lat istnieje szerokie grono podmiotów udzielających pożyczek
            społecznościowych w Polsce. Na czym to jednak polega? Można to traktować jako
            alternatywę dla pożyczek pozabankowych i kredytów udzielanych w bankach. Nie
            są one ani produktem bankowym, ani chwilówką, a pożyczką społeczną, udzielaną
            w formie social lendingu. Umowa w takim przypadku polega na uzgodnieniu przez
            dwie osoby fizyczne tego, że jedna strona pożyczy pieniądze na określony
            procent, a druga zwróci je w ustalonym terminie wraz z odsetkami.
          </p>
          <p>
            Podstawą dla szybkich pożyczek społecznościowych są umowy cywilnoprawne.
            Strony umawiają się co do pożyczenia określonej kwoty środków finansowych,
            ustalają wysokość oprocentowania i termin zwrotu pieniędzy.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Oferty pożyczek społecznościowych kierowane są do osób, które z różnych
            powodów nie mają szansy na uzyskanie kredytu gotówkowego w banku. Może być to
            spowodowane zbyt niską zdolnością czy złą historią w Biurze Informacji
            Kredytowej (BIK). Jednocześnie te same osoby mogą mieć problemy z uzyskaniem
            pozytywnej decyzji w firmie pozabankowej, udzielającej pożyczek online na raty
            lub z jednorazową spłatą na koniec okresu kredytowania.
          </p>
          <p>
            Pozostaje im więc do dyspozycji alternatywa w postaci pożyczek
            społecznościowych przez internet, które są stosunkowo łatwiejsze do uzyskania.
            Warunkiem ich otrzymania jest znalezienie w społecznościowym portalu
            pożyczkowym oferenta, który zdecyduje się udzielić zobowiązania na pożądanych
            przez nas zasadach.
          </p>
          <p>
            Zwykle dodatkowym wymogiem będzie zarejestrowanie się pożyczkobiorcy na takim
            portalu i poddanie się procedurze weryfikacji tożsamości.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Serwisy typu social lending nie są instytucjami, które biorą odpowiedzialność
            za transakcje zawierane za ich pośrednictwem. Pożyczki społecznościowe
            działają w ten sposób, że to jedna osoba fizyczna umawia się z drugą na
            pożyczenie pieniędzy. Platforma social lendingowa pełni funkcję pośrednika,
            który umożliwia spotkanie, zwykle w trybie online, dwóch stron umowy
            cywilno-prawnej, i weryfikuje ich tożsamość.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Jeśli chcemy zaciągnąć pożyczkę społecznościową online, musimy zawrzeć umowę
            cywilnoprawną z osobą prywatną. Nie ma tu mowy o pośrednictwie banków czy firm
            pozabankowych. Klient musi zgłosić na platformie social lendingowej chęć
            zaciągnięcia zobowiązania w konkretnej kwocie, na odpowiedni okres, i wskazać,
            ile maksymalnie może za nie zapłacić. Natomiast potencjalni pożyczkodawcy
            zgłaszają swoje propozycje, spośród których zainteresowany może wybrać taką,
            która będzie dla niego najkorzystniejsza. Sfinalizowanie transakcji polega na
            zawarciu odpowiedniej umowy pomiędzy dwiema stronami.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            Pożyczki społecznościowe nie podlegają nadzorowi ze strony żadnych
            zewnętrznych instytucji, np. Komisji Nadzoru Finansowego.
          </p>
          <p>
            Osoby chcące zaciągnąć pożyczkę społecznościową online powinny znaleźć
            platformę, która pozwala na udostępnianie takich zobowiązań i kojarzenie ze
            sobą pożyczkodawców i pożyczkobiorców. Klient powinien uważać na to, z kim
            zawiera umowę cywilnoprawną o udzielenie zobowiązania. Warto korzystać z
            platform mających już doświadczenie w funkcjonowaniu na rynku pożyczkowym.
            Jeśli z różnych powodów nie jest to możliwe, być może lepiej będzie rozważyć
            zaciągnięcie chwilówki online przez internet, w formie oferty pozabankowej, w
            jednej z marek od lat działających na rynku finansowym w Polsce.
          </p>
          <p>
            Osoby, które udostępniają pożyczki społecznościowe zainteresowanym, nigdy nie
            mogą mieć pewności, że otrzymają ich zwrot w odpowiednim terminie. Ryzyko to
            jest kompensowane wyższym poziomem oprocentowania należnego im z tytułu
            udzielania takich zobowiązań.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            Oprocentowanie pożyczek społecznościowych podlega indywidualnym negocjacjom,
            które prowadzą ze sobą strony umowy. Warto przy tym podkreślić, że przy tego
            rodzaju pożyczkach pojawia się obowiązek opłacenia podatku od pożyczki przez
            korzystającego ze środków oraz podatku od zysków kapitałowych przez
            udostępniającego środki.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="7">{{ contents[6].title }}</h2>
          <p>
            Podobnie ja w przypadku oprocentowania społecznościowej pożyczki, tak i przy
            ustalaniu jej górnej kwoty obowiązują zasady określone przez strony umowy. O
            ile chwilówki online pozabankowe udzielane są zwykle na niską kwotę kilku
            tysięcy złotych, to w tym przypadku kwoty zobowiązania może wynosić nawet
            kilkadziesiąt tysięcy złotych i więcej.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="8">{{ contents[7].title }}</h2>
          <p>
            Oferta na pożyczki społecznościowe jest atrakcyjna dla niektórych klientów i
            trudno się temu dziwić, ponieważ ta forma zobowiązania finansowego ma wiele
            zalet. Wśród nich należy wyróżnić:
          </p>
          <ul>
            <li>możliwość indywidualnego dopasowania oferty do swoich potrzeb,</li>
            <li>
              negocjowanie warunków – kwoty pożyczki, okresu kredytowego i wysokości
              oprocentowania,
            </li>
            <li>
              możliwość przeznaczenia pozyskanych środków finansowych na cele dowolne,
            </li>
            <li>
              ograniczenie formalności do minimum – zwykle do zaciągnięcia zobowiązania
              wystarczy dowód osobisty,
            </li>
            <li>niskie bariery przy pozyskania środków,</li>
            <li>wysoka dostępność oferty,</li>
            <li>możliwość zaciągnięcia pożyczki społecznościowej online,</li>
            <li>dostępne wysokie kwoty zobowiązania,</li>
            <li>szybki czas uzyskania decyzji.</li>
          </ul>
          <p>
            Niestety, ale oferta na pożyczki social lending ma też swoje wady. Przede
            wszystkim nie są one regulowane prawnie, a bynajmniej nie w taki sposób, jak
            kredyty bankowe. Mogą być obarczone wyższym oprocentowaniem i koniecznością
            dokonania opłaty serwisowej za korzystanie z portalu kontaktującego ze sobą
            strony umowy. Liczyć się trzeba z tym, że czas oczekiwania na uruchomienie
            środków z pożyczki społecznościowej jest dłuższy w porównaniu do ofert
            pozabankowych – szybkich pożyczek online.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="9">{{ contents[8].title }}</h2>
          <p>
            Platformy pożyczek społecznościowych weryfikują tożsamość nowych,
            potencjalnych pożyczkobiorców za pomocą przelewu weryfikacyjnego lub usług i
            aplikacji, takich jak Kontomatik, uzyskujących chwilowy dostęp do konta
            bankowego klienta. Możliwa jest też weryfikacja dobrowolna, która zwiększa
            wiarygodność klienta i polega ona na:
          </p>
          <ul>
            <li>potwierdzeniu adresu za pomocą skanu rachunku za media,</li>
            <li>
              dostarczeniu cyfrowo zaświadczenia o wysokości wynagrodzenia i zatrudnieniu,
            </li>
            <li>
              wyrażeniu zgody na sprawdzenie danych klienta w bazach biur informacji
              gospodarczej.
            </li>
          </ul>
        </div>
        <div class="text-container mt-5">
          <h2 id="10">{{ contents[9].title }}</h2>
          <p>
            Podstawą do udzielania chwilówek i pożyczek społecznościowych są te same
            przepisy Kodeksu cywilnego. Mowa tu o dziale XIX tej ustawy, a dokładnie o
            artykułach od 720 do 724. Wskazano w nich istotę umowy pożyczki.
          </p>
          <p>
            Pożyczki społecznościowe mogą być udzielane poza działalnością gospodarczą, a
            w przypadku chwilówek w roli udzielającego występuje firma. Zwykle szybciej
            możemy uzyskać pożyczkę gotówkową niż społecznościową, ale klient musi zgodzić
            się na warunki zaproponowane przez udzielającego zobowiązania.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="11">{{ contents[10].title }}</h2>
          <p>
            Osoby zadłużone, które nie mogą przedstawić zaświadczenia o wysokości
            uzyskiwanych dochodów i mają negatywne wpisy w BIK lub w BIG-ach raczej nie
            będą miały możliwości uzyskania kredytu bankowego, a mogą też mieć problem ze
            skorzystaniem z oferty pozabankowej. Warto jednak, by te same osoby spróbowały
            zaciągnąć pożyczkę społecznościową, która może być dla nich jedyną alternatywą
            dla pozyskania zewnętrznego finansowania.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'Co to jest pożyczka społecznościowa?',
          link: '#1',
        },
        {
          title: 'Do kogo kierowane są pożyczki społecznościowe?',
          link: '#2',
        },
        {
          title: 'Jak działają pożyczki społecznościowe?',
          link: '#3',
        },
        {
          title: 'Jak otrzymać pożyczkę społecznościową?',
          link: '#4',
        },
        {
          title: 'Czy pożyczki społecznościowe są bezpieczne?',
          link: '#5',
        },
        {
          title: 'Jak oprocentowane są pożyczki społecznościowe?',
          link: '#6',
        },
        {
          title: 'Pożyczka społecznościowa – jaką kwotę mogę pożyczyć?',
          link: '#7',
        },
        {
          title: 'Pożyczki społecznościowe – wady i zalety',
          link: '#8',
        },
        {
          title: 'Pożyczki społcznościowe – weryfikacja',
          link: '#9',
        },
        {
          title: 'Pożyczka społecznościowa vs pożyczka chwilówka',
          link: '#10',
        },
        {
          title: 'Pożyczka społecznościowa – podsumowanie',
          link: '#11',
        },
      ],
    }
  },
}
</script>
