<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til cykel</h1>

      <div class="top-text text-container">
        <p>
          Har du brug for et lån eller finansiering til en ny cykel, eller leder du efter
          rentefri cykellån? Vi hjælper dig med at finde et billigt cykellån blandt mange
          banker. Udfyld vores formular, så indhenter vi en lang række tilbud til dig med
          det samme, så du kan købe din cykel nu.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li><a href="#rentefri-cykellaan">Rentefri cykellån</a></li>
                <li><a href="#forskellige-salgs-cykler">Forskellige salgs cykler</a></li>
                <li><a href="#cykellaan-med-rki">Cykellån med RKI</a></li>
                <li><a href="#cykeludstyr">Cykeludstyr</a></li>
                <li>
                  <a href="#vi-hjaelper-dig-med-at-finde-det-bedste-cykellaan"
                    >Vi hjælper dig med at finde det bedste cykellån</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="rentefri-cykellaan">Rentefri cykellån</h2>
          <p>
            Mange folk, som leder efter et lån til en cykel, leder efter et rentefrit
            cykellån. Et rentefrit lån til cykler er dog ikke ensbetydende med at lånet er
            omkostningsfrit, og i sidste ende er det nok dét, som disse personer ønsker
            sig. Flere af de rentefri cykellån, som vi er stødt på, har diverse
            begrænsninger eller gebyrer, som du måske ikke lige havde gennemskuet, da du
            blev fanget af overskriften omkring et rentefrit lån til cyklen.
          </p>
          <p>
            Uanset hvad du låner til, så har lånet en pris, som kan være renter, gebyrer,
            din data eller noget fjerde. Derfor er vores råd altid at
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne lån</a
            >, så du på et oplyst grundlag kan vælge det rette lån for dig og din
            situation - i dette tilfælde et lån til en cykel.
          </p>
          <p>
            Hvis du ønsker dig et lån til en cykel, så anbefaler vi, at du ligesom i alle
            andre tilfælde, hvor du leder efter
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >billige forbrugslån</a
            >, sammenligner lånene på baggrund af ÅOP, hvilket står for årlige
            omkostninger i procent. Når du sammenligner cykellån på baggrund af ÅOP, vil
            du finde det lån, som er billigst, såfremt lånets størrelse og løbetiden er
            identisk.
          </p>
          <p>
            Vi kan hjælpe dig med at finde billige cykellån blandt en lang række banker og
            långivere. Det eneste, som vi har behov for er 2 minutter af din tid, hvor du
            angiver de nødvendige oplysninger, for at vi kan indhente skræddersyede tilbud
            på dit lån til din nye cykel.
          </p>
        </div>

        <div class="text-container">
          <h2 id="forskellige-salgs-cykler">Forskellige salgs cykler</h2>
          <p>
            Uanset hvilken slags cykel du ønsker et lån til, så er vi klar til at hjælpe
            dig.
          </p>
          <p>
            Typisk er det de mere omkostningstunge cykler, såsom ladcykler, bulletcykler,
            Christianiacykler, el-cykler og racercykler, som vores brugere ønsker at
            financiere. Vi hjælper dig dog gerne, uanset om det er et lån til en dyr
            cykel, eller blot en trehjulet cykel til et barn.
          </p>
          <h3>Lån til ladcykel / Christianiacykel</h3>
          <p>
            Et lån til en ladcykel eller Christianiacykel vil være relevant for mange, som
            ikke har lagt penge til side til at financiere deres cykel, især hvis man
            ønsker sig en af de karakteristiske Christianiacykler.
          </p>
          <p>
            En Christianiacykel kan sagtens koste 10-30.000 kr., alt efter hvilken model
            man ønsker sig. Uanset om man ønsker sig en af de dyre eller billige
            ladcykler, så kan et lån eller financiering, evt. et rentefrit cykellån, komme
            på tale.
          </p>
          <p>
            De fleste som køber en ladcykel har en familie, og her fungerer ladcyklen som
            en erstatning for en bil nummer to. Dermed er kan en ladcykel blive
            uundværlig, og hvis du ikke har råd til at købe cyklen kontant, er det nok
            svært at komme uden om at
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >.
          </p>
          <h3>Lån til racercykel</h3>
          <p>
            Hvis du dyrker cykelsport på landevejen, så kommer du ikke udenom at skulle
            anskaffe dig en racercykel/landevejscykel.
          </p>
          <p>
            Det er selvfølgelig muligt at købe en billig brugt racercykel, men hvis du,
            som de fleste der er rigtigt interesseret i cykelsport, ønsker dig en
            ordentlig racercykel, så kan det være svært at klare finansieringen af cyklen
            uden et lån.
          </p>
          <p>
            En racercykel kan koste alt fra
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/5000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >5.000 kr.</a
            >
            og opad. Hvis du ønsker dig en BMC Teammachine SLR TWO med elektronisk
            gearskifte, lige som tidligere Tour De France vindere, så bliver det svært at
            finde den fra ny til mindre end
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/40000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >40.000 kr.</a
            >
            Dermed kan et lån til cyklen lige pludselig blive meget nødvendigt.
          </p>
          <p>
            Hvis drømmen om er en S-Works McLaren Tarmac-cykel, lige som den Jakob
            Fuglsang kørte på under turen i 2014, så skal der +<a
              href="https://www.saverium.dk/laan-penge/beloeb/100000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >100.000 kr.</a
            >
            op af lommen. For langt de fleste vil dette beløb være uoverkommeligt med
            deres nuværende økonomi, så her vil et lån til køb af racercyklen også komme
            på tale.
          </p>
          <h3>Lån til elcykel</h3>
          <p>
            En elcykel kan lige som en racercykel blive en dyr omgang, og derfor kommer et
            lån til en elcykel også ofte på tale.
          </p>
          <p>
            Elcykler koster sjældent under 10.000 kr., og de fleste modeller koster oftest
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/20000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >20.000 kr.</a
            >
            eller mere. Hvis du ønsker nogle at de bedste og dyreste modeller, som fx.
            Kalkhof, Specialized, Scott eller Cannondale kan tilbyde, er priser omkring
            40.000 kr. heller ikke unormale.
          </p>
          <p>
            Uanset hvilken prisklasse du kigger i, kan det blive relevant at overveje et
            lån til en elcykel. Måske havde du i første omgang afsat 10.000 kr. til din
            nye elcykel, men hvis du i processen er blevet opmærksom på nogle af fordelene
            ved de dyrere cykler, så som lavere vægt, større effekt på batteriet,
            automatgear, eller flere KWH, så kan det blive nødvendigt at låne det sidste,
            så du kan få netop den elcykel, der passer til dit behov.
          </p>
          <h3>Lån til citybike</h3>
          <p>
            En citybike er sjældent lige så dyr som en racer, ladcykel eller elcykel, og
            derfor hænder det også knap så ofte, at et lån til en cykel er nødvendigt ved
            køb af denne type cykel. Hvis du er på udkig efter en citybike, er du nok også
            opmærksom på dette, og har derfor med stor sandsynlighed, også sat et mindre
            beløb af til at købe cykel for.
          </p>
          <p>
            Hvis du allerede har afsat et budget af til en ny citybike, så er
            sandsynligheden lav for at du får brug for at låne mere end
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/5000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >5.000 kr.</a
            >, da de fleste citybikes kan købes inden for et budget på 10.000 kr. Selv med
            Reelight lygter, støtteben, skærme, ringeklokke, forsikringsgodkendt lås og
            evt. en ekstra wire- eller kædelås, kommer du sjældent over 10.000 kr.
          </p>
        </div>

        <div class="text-container">
          <h2 id="cykellaan-med-rki">Cykellån med RKI</h2>
          <p>
            Hvis du søger en cykel på afbetaling trods RKI, så er dine chancer for at
            blive godkendt betydeligt lavere, end hvis du ikke stod i RKI. Dermed ikke
            sagt, at det er umuligt at finde en cykelforhandler eller långiver, som kan
            tilbyde dig et cykellån trods RKI.
          </p>
          <p>
            Hvis du køber en cykel på afbetaling mens du står i RKI, vil du med stor
            sandsynlighed skulle stille cyklen, eller en anden genstand, som pant. Dermed
            har långiveren mulighed for at indkræve din cykel, hvis ikke du kan betale
            ydelserne på dit cykellån.
          </p>
          <p>
            I modsætning til en oplevelse, som er væk så snart den er brugt, eller en
            genstand, som taber sin værdi hurtigt, så beholder cyklen ofte sin værdi over
            lang tid. Dermed kan et cykellån med pant i cyklen være en mulighed, hvilket
            ikke altid er tilfældet, hvis du ønsker at låne til en anden genstand, service
            eller oplevelse.
          </p>
          <p>
            Alt andet lige, er det dog i din interesse, at du ikke skal stille pant for
            dit lån til din nye cykel. Hvis du udfylder vores ansøgningsskema, vil du
            hurtigt få svar fra en lang række banker og alternative långivere på, om du
            kan få et cykellån på afbetaling trods RKI, uden at du behøver at stille nogen
            form for sikkerhed.
          </p>
        </div>

        <div class="text-container">
          <h2 id="cykeludstyr">Cykeludstyr</h2>
          <p>
            I forbindelse med køb af en ny cykel, har du også ofte brug for ekstraudstyr,
            hvilket i nogle tilfælde også kan være en medvirkende faktor til, at du får
            behov for et lån til cyklen (eller udstyret, alt efter hvordan man ser på det
            det). Det er dog sjældent tilfældet for Christianiacyklen, citybiken eller
            cyklen til dine børn, men hvis du køber en elcykel, kan der være store
            omkostninger forbundet med at opgradere de elektroniske dele.
          </p>
          <p>
            Hvis du ønsker at benytte din cykel på eliteplan eller blot i forbindelse med
            en seriøs sportsgren, så kan ekstraudstyr også løbe op i mange penge. Dette
            gælder især inden for mountainbike og racercykler.
          </p>
          <p>
            Hvis vi tager udgangspunkt i et eksempel for en racercykel, så kan en
            Bontrager Pro Carbon flaskeholder koste 500 kr., Abus GameChanger TT Aero
            cykelhjelm 2.500 kr. og Shimano S-Phyre RC902 cykelsko 3.000 kr. Allerede her
            er du oppe i 6.000 kr., og vi har endnu ikke lagt handsker, trøje, bukser,
            cykelbriller eller værktøj og vedligeholdelsesprodukter oven i.
          </p>
          <p>
            Vælger du en mountainbike, får du brug for mange af de samme kategorier af
            udstyr, som til en racercykel. Derudover vælger mange også at købe en
            cykelholder til bilen, da mange af de bedste mountain bike trails i Danmark
            kræver en køretur i bilen.
          </p>
        </div>

        <div class="text-container">
          <h2 id="vi-hjaelper-dig-med-at-finde-det-bedste-cykellaan">
            Vi hjælper dig med at finde det bedste cykellån
          </h2>
          <p>
            Uanset om du ønsker fuld finansiering af din cykel, eller blot et tilskud i
            form af et mindre lån til din cykel, så hjælper vi dig gerne. Vores
            kundeservice er klar til at hjælpe dig på telefon og mail, hvis du har
            spørgsmål til hvordan vi kan hjælpe dig med at finde det rette lån til din nye
            cykel inkl. udstyr.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
