<template>
  <div>
    <div class="article">
      <h1 class="center-title">Bådlån samt finansiering af bådkøb</h1>
      <div class="top-text text-container">
        <p>
          At vi i dejlige Danmark har kilometer lange kyststrækninger og havne i snart
          hver by er en drøm for de fleste bilentusiaster. Det er forståeligt, at mange af
          os dansker går rundt med en drøm om at eje sin egen båd. Det giver lidt en helt
          anden form for frihed at kunne sejle rundt i sin egen båd og opleve de danske og
          udenlandske farvande.
        </p>
        <p>
          Ligesom som så mange andre drømme, så koster drømmen om at kunne kalde en båd
          for ”Min båd” også en stor sum penge. Det er ikke en stor del af os, der hverken
          har lønnen eller opsparingen der kan smide pengene for prisen der står på købet
          af, ny motorbåd eller køb af en ny sejlbåd for eksempel. Dog skal du ikke opgive
          drømmen, for ligesom folk får en bil finansiering/optager et
          <b-link :to="$root.rn.CarLoan">billån</b-link>, er det skam også muligt at købe
          båden du drømmer om eller en brugt båd, ved hjælp af at tage et lån til båd, så
          du netop kan købe den båd som du går og drømmer dig om.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul v-for="(text, i) in contents" :key="i">
                <li>
                  <a :href="text.link">
                    {{ text.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">Hvilke slags både kan et lån til båd bruges til?</h2>
          <p>
            Optager du et bådlån igennem Saverium.dk bliver der ikke stillet
            spørgsmålstegn eller krav ved hvilken slags båd du har tænkt dig at bruge
            lånet på. Så hvilken slags båd, lånet skal bruges til, er op til dig, om det
            er en ny motorbåd, gammel sejlbåd eller hvilket mærke din ønske båd er blander
            vi os ikke i. Efter du har fået dit lån til båd igennem Saverium.dk og pengene
            står på din egen konto, har du fuld ansvar over hvad beløbet du har lånt skal
            bruges til. På den måde sikre du dig at du opnår fleksibilitet og frihed til
            at kunne finde netop den båd du har i tankerne.
          </p>
          <p>
            Vi hos Saverium anbefaler at du grundigt undersøger båd markedet og finder en
            båd i høj kvalitet, for at få så meget som muligt ud dit lån til båd. Så inden
            du køber den første og bedste du har fået øje på, endelig tjek hjemmesider som
            eventuelt DBA. En anden super ide er at opsøge en bådforhandler for at også få
            en god snak og der er som regel altid også garanti på den ved køb af en båd
            hos en forhandler. Hvilke udgifter du skal tage højde for forklare
            <a
              href="https://dansksejlunion.dk/viden-og-vedligeholdelse/ny-baadejer/hvad-koster-det-at-have-baad"
              target="_blank"
              rel="noopener"
              >Dansk Sejlunion</a
            >.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">Hvor er det muligt at søge om et lån til din båd?</h2>
          <p>
            Siden det er meget populært at købe båd i Danmark, er det nemmere end
            nogensinde at finde det korrekte lånetilbud om så det er til båd kørekort
            eller måske endda en billig husbåd. Det har bankerne samt online låneudbyder
            fundet ud af og er klar til at hjælpe dig med at skrue det helt perfekte
            lånetilbud sammen til køb af båd som kunne være alt fra et lån til båd på
            250.000 kr. til et lån på 500.000 kr.
          </p>
          <p>
            De fleste henvender sig som regel altid først til sin egen bank, og det er
            også tit det er den billigste løsning samt du får personlig rådgivning. Det er
            dog ikke altid den mulighed er der for alle så af den grund findes der på
            internettet nye låneudbyder som gør livet mere nemmere for dig så du kan få et
            lån til båd, så du kan købe din motorbåd eller hvilken anden som helst båd.
          </p>
          <h3>Lån hos banken</h3>
          <p>
            Hvis du ønsker at optage et bådlån i banken, kræver det ofte at du har sparet
            penge op så du har kredit. Det er muligt at få et billigt banklån til båd, men
            det kræver du har kredit, så du kan stille sikkerhed for de penge du låner.
            Det er nemlig ikke altid muligt at kunne låne hele beløbet om det så er til
            køb af en billig båd, bil eller hus.
          </p>
          <p>
            Hvis du har en bil eller et hus kan du faktisk bruge en af disse ting som en
            sikkerhed og optjene hurtig kredit på den måde, som gør du kan i mange
            tilfælde forhandle dig en endnu lavere rente. Dermed vil bådlåns renten være
            billigere og du spare yderligere og kan få det billigste banklån på denne her
            måde.
          </p>
          <h3>Online låneudbydere</h3>
          <p>
            Der er i nogle tilfælde at det ikke er muligt den forhandler eller bank du
            ønsker at optage lånet igennem vil hjælpe dig med at finansiere din drøm om at
            eje en båd. Derfor findes der heldigvis et nemt og alternativt sted at finde
            de penge du skal bruge til købet af eventuelt en brugt motorbåd.
          </p>
          <p>
            Har du næsen efter at optage et personligt lån eller dit bådlån på nettet er
            det ofte du kan
            <b-link :to="$root.rn.UnsecuredLoans">låne uden at sikkerhed</b-link> eller
            have en opsparing som du skal bruge til udbetaling klar.
          </p>
          <p>
            Dog skal du være opmærksom på at optage et lån på nettet i nogle tilfælde kan
            være dyrere end banklånet. Der følgere ligesom ved et banklån gebyr og renter
            som der skal betales så det er vigtig man kan beregne løbetid og
            <b-link :to="$root.rn.LoanCalculator">beregn lånet</b-link>. Det er super
            hurtigt og nemt at gøre det på denne her måde.
          </p>
          <p>
            Saverium.dk der samarbejder med nogle af de bedste banker i Danmark samt
            pengeinstitutter. På den måde er det muligt at have en bådlån beregner lige
            ved hånden og ved en enkelt ansøgning kan du få den sendt ud til låneselskaber
            som Fellow Finance, Resurs Bank, Basisbank samt flere og du vil modtage svar
            indenfor kort tid.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">Hvilke krav stilles der for at få et bådlån?</h2>
          <p>
            Før du kan blive godkendt til at kunne optage et bådlån, skal du opfylde en
            række krav. Det fungerer mere eller mindre ligesom hvis du skal optage et
            normal
            <b-link :to="$root.rn.PrivateLoans">privatlån på nettet</b-link>. Ansøger du
            på nettet er en af de positive ting er du meget sjældent skal fremvise
            lønsedler, budgetter osv. Hvilket gør det meget mere enkelt at skulle optage
            lånet igennem en online udbyder som Saverium.dk.
          </p>
          <p>
            Tager du i en fysisk bank er chancen stor for at du skal fremvise disse ting
            da du skal forklare hvad pengene skal bruges til og banken være sikker på du
            er i stand til at kunne betale lånet tilbage til dem.
          </p>
          <ul>
            <li class="heavy-list">Du må ikke være registreret i RKI</li>
          </ul>
          <p>
            RKI står for Ribers kredit information, det er et register for folk der ikke
            betaler sine regninger. De udbyder som tilbyder bådlån,
            <b-link :to="$root.rn.ConsumerLoans">forbrugslån</b-link> samt andre slags lån
            anvender RKI til at undgå de værste betaler. Det sker at nogle enkelte udbyder
            af bådlån til har nogle kampagner, hvor det er muligt at låne selvom man står
            registreret i RKI. Hvis du er registeret i RKI og blevet afvist, så skal du
            betale din gæld ud før det er muligt at kunne blive godkendt til at optage et
            nyt lån. Det er vigtigt at opnå en bedre kredit, for at kunne optage et lån
            til en båd, da de kan koste mange penge.
          </p>
          <ul>
            <li class="heavy-list">Dansk statsborgerskab</li>
          </ul>
          <p>
            Ønsker du dig at skulle have en chance for at optage et bådlån, skal du have
            et NEM-ID samt være dansk statsborger. Når du skriver under på en aftale i
            forhold til dit lån, sker det nemlig online med dit NEM-ID. Udover det skal du
            også have bopæl i Danmark.
          </p>
          <ul>
            <li class="heavy-list">Du skal minimum være 18 år</li>
          </ul>
          <p>
            Hvis du skal blive godkendt til at optage et bådlån online, skal du være fyldt
            18 år. Der findes nogle udbyder som kræver du skal være over 20 år. Grunden
            til at ens alder spiller en rolle er fordi, låneudbyder vil være sikker på man
            kan betale tilbage, så de sikrer sig imod dårlige betalere. Det er meget
            sjældent at en person under 18 år har en særlig stor indkomst, og dermed vil
            chancen for at man er i stand til at kunne betale renterne og diverse
            omkostninger tilbage er lille. Dermed vil låneudbyder samt banker afvise
            personen med det samme.
          </p>
          <p>
            <b>OPS</b> Uanset om du vælger at optage bådlånet i banken eller hos en online
            udbyder vil du altid blive kreditvurderet. Der indgår nogle faktorer i forhold
            til indkomst som ejerforhold altså bil, hus eller lejlighed osv. En
            kreditvurdering vil sikre dig at du som låneoptager får muligheden for at få
            den lavest omkostning og renter på dit lån til båd.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">Skal du stille sikkerhed for at optage et bådlån?</h2>
          <p>
            Ønsker du at låne i banken, vil de som regel altid have pant i båden du køber
            i forhold til sikkerhed så de kan få deres penge tilbage, hvis du skulle ende
            i den situation at du ikke kan betale pengene du har lånt tilbage. Så der er
            chance for du skal stille sikkerhed for at optage et bådlån.
          </p>
          <p>
            Det kan være en meget smart ide hvis banken er enig i aftalen. Hvis du stiller
            sikkerhed for det beløb du låner, vil det også betyde samtidig at du kan få en
            meget lavere rente end når du låner penge uden sikkerhed.
          </p>
          <p>
            Foretager du lånet på nettet igennem en online udbyder som Saverium.dk behøver
            du sjældent at skulle stille sikkerhed for beløbet du ønsker at låne. Der
            bliver ikke stillet spørgsmål i forhold til hvad du skal bruge pengene til, om
            du kigger på billige både der til salg eller de dyre af slags. Det kan i nogle
            tilfælde betyde bådlånet bliver dyrere siden du ikke har noget sikkerhed og
            byde med.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">Skal du lægge en udbetaling for at blive godkendt til et bådlån?</h2>
          <p>
            Vi var lidt inde på det tidligere med at de fleste banker gerne vil have du
            selv er i stand til at kunne lægge en udbetaling, hvis man ønsker at gøre sig
            gældende for at kunne få et bådlån.
          </p>
          <p>
            Det som regel kun en procentdel af prisen på båden og satsen kan variere fra
            hvilken bank du henvender dig til.
          </p>
          <p>
            Kigger du dig om på nettet er chancen for du skal lægge ud for udbetalingen
            selv yderst sjældent hvilket betyder du uden problem kan låne hele beløbet du
            står og mangler til din båd.
          </p>
          <p>
            I en del tilfælde kan du kombinere de to former for lån, så du tager lånet til
            udbetalingen online og derefter optager resten af lånet i banken. På den måde
            får du samlet de fordele der er ved
            <b-link :to="$root.rn.LoanOptions">online lån</b-link> samt et fysisk banklån.
          </p>
          <p>
            Altid husk at vælge den korteste løbetid som muligt som din økonomi kan holde
            til for dit forbrugslån, så du hurtigst muligt kan komme af med udgifterne.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">Hvordan beregner du prisen på et bådlån?</h2>
          <p>
            Du kan enten ringe rundt eller sende en e-mail til de banker eller
            låneudbydere du vil optage et bådlån hos. Derefter vil de vende tilbage med et
            <b-link :to="$root.rn.LoanOffers">lånetilbud</b-link> i forhold til hvor meget
            de kan tilbyde dig og til hvilke låneomkostninger Det er de meste normale måde
            man vil <b-link :to="$root.rn.LoanCalculator">beregne prisen</b-link> på et
            bådlån, hvis du ikke anvender en platform som Saverium.dk.
          </p>
          <p>
            Du kan forene flere forskellige lån til et
            <b-link :to="$root.rn.ConsolidationLoan">samlelån</b-link> også derefter regne
            ud, hvor meget lånet ender med at komme til at koste dig, samt om båden er
            prisen værd.
          </p>
        </div>
        <div class="text-container">
          <h2 id="7">Er det muligt at sammenligne priser på lån til en båd?</h2>
          <p>
            Saverium.dk har gjort dig den tjeneste at sammenligne de tilbud du får
            tilsendt bare med en enkelt ansøgning. Så i stedet for du skal selv til at
            beregne masse banklån fra forskellige steder har Saverium.dk gjort det for
            dig. Når du sidder med de tilbud vi har hentet ind som vores partner har
            præsenteret og skræddersyet til dig, kan du vælge hvilken en der netop passer
            allerbedst til det du ønsker dig samt ikke får din økonomi til at falde
            sammen.
          </p>
          <p>
            Når du sammenligner de forskellige priser på dit bådlån, er det ÅOP du skal
            holde øje med. Det tal viser nemlig dig de Årlige Omkostninger i Procent som
            viser de udgifter du skal betale til svarer til når det kommer til
            lånebeløbet.
          </p>
          <p>
            Der findes en masse låneudbydere som alle vil låne dig penge, så hvorfor ikke
            gør det nemt for dem samt dig selv ved at kun lave en ansøgning og nå ud til
            dem alle sammen? Det eneste du skal gøre, er at udfylde en låneansøgning på
            Saverium.dk. Imens vi sender din ansøgning ud til vores partner, kan du kigge
            dig om efter den båd der netop vil være det perfekte match for dig. Du
            udfylder bare hvilken løbetid samt beløb du ønsker dig, så sørger vi for de
            tilbud du får overholder dine krav som du stiller.
          </p>
          <p>
            Skulle du have nogle spørgsmål til vores service, så tøv ikke med at kontakte
            os på mail igennem kundeservice@saverium.dk. Vi besvarer hellere end gerne din
            henvendelse så vi kan få gjort din drøm om en båd i opfyldelse så du kan nyde
            de mange kyststrækninger og friheden.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  data() {
    return {
      contents: [
        {
          title: 'Hvilke slags både kan et lån til båd bruges til?',
          link: '#1',
        },
        {
          title: 'Hvor er det muligt at søge om et lån til din båd?',
          link: '#2',
        },
        {
          title: 'Hvilke krav stilles der for at få et bådlån?',
          link: '#3',
        },
        {
          title: 'Skal du stille sikkerhed for at optage et bådlån?',
          link: '#4',
        },
        {
          title: 'Skal du lægge en udbetaling for at blive godkendt til et bådlån?',
          link: '#5',
        },
        {
          title: 'Hvordan beregner du prisen på et bådlån?',
          link: '#6',
        },
        {
          title: 'Er det muligt at sammenligne priser på lån til en båd?',
          link: '#7',
        },
      ],
    }
  },

  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },

  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
