<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lånemuligheder</h1>
      <div class="top-text text-container">
        <p>
          Internettet indeholder et bredt spektrum af forskellige lånemuligheder. Det vil
          sige, at ønsker du at låne penge hos en onlinebaseret lånevirksomhed kan du
          benytte en platform som Saverium.dk, der indhenter flere lånetilbud til dig fra
          forskellige låneudbydere, er der rig mulighed for dette.
        </p>
        <p>
          Mængden af lånemuligheder i Danmark har en størrelse, der medfører, at det kan
          virke uoverskueligt at skabe et overblik over alle lånemuligheder.
        </p>
        <p>
          Det er særligt gældende, da der med tiden er opstået mange navne til de
          forskellige lånemuligheder. Ligeledes er der over de seneste år blevet
          introduceret nye lånemuligheder, der blot giver dig endnu flere muligheder.
        </p>
        <p>
          Ønsker du at
          <b-link :to="$root.rn.PrivateLoans">optage et privatlån på nettet</b-link>, bør
          du derfor sætte dig ind i, hvad de enkelte låneprodukter tilbyder og kræver. Kun
          på denne måde kan du garantere, at du vælger en ideel løsning for dit
          vedkommende.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul v-for="(text, i) in contents" :key="i">
                <li>
                  <a :href="text.link">
                    {{ text.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">
            Sådan indhenter du nemt og bekvemt lånetilbud som styrker dine lånemuligheder
          </h2>
          <p>
            Da der findes mange gode lånemuligheder fra et bredt udvalg af
            lånevirksomheder, kan det virke vanskeligt at vurdere, hvorfra du bør indhente
            dine lånetilbud.
          </p>
          <p>
            I stedet for at bekymre dig om dette, bør du indhente tilbud på Saverium.dk.
            Her kan du indhente flere lånetilbud med en enkelt ansøgning, hvilket
            resulterer i en mindre omfattende og tidskrævende låneproces.
          </p>
          <p>
            Ligeledes garanterer det, at du vil blive præsenteret for en række gode
            lånemuligheder på overskuelig vis, da alle modtagne lånetilbud vil være
            beregnet med udgangspunkt i den udfyldte ansøgning. Derfor får du
            <b-link :to="$root.rn.LoanOffers">personlige lånetilbud</b-link>, der tager
            højde for dine ønsker til blandt andet lånebeløb og løbetid.
          </p>
          <p>
            Hele processen foregår på nettet, hvorfor er ingen krav er om fysiske møder
            mv. Endvidere tilbyder samtlige partnere på Saverium.dk, at du kan låne penge
            uden sikkerhed. Blandt samarbejdspartnere finder du blandt andet Basisbank,
            Resurs Bank, Express Bank og LånLet.
          </p>
          <p>
            Det er selvfølgelig både gratis og uforpligtende at benytte Saverium.dk til at
            indhente lånetilbud. Du er derfor ikke forpligtet til at acceptere ét af de
            modtagne lånetilbud, hvis ikke du mener, at de er gode nok.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">Hvad påvirker dine lånemuligheder?</h2>
          <p>
            Hvad påvirker dine lånemuligheder af? Det gør de af en lang række faktorer,
            hvilket blandt andet omfatter dine personlige og økonomiske forhold.
          </p>
          <p>
            Gør du dig forhåbninger om at blive præsenteret for en række billige
            lånemuligheder, er det naturligvis essentielt, at du er opmærksom på, at
            lånevirksomhederne stiller en række krav til dig.
          </p>
          <p>
            Af denne årsag er det ikke alle, der bliver præsenteret for de bedste
            lånemuligheder, da låneudbyderne eksempelvis vurderer, at de økonomiske
            forhold ikke retfærdiggør det.
          </p>
          <p>
            Det kan derfor sagtens lade sig gøre at finde billige
            <b-link :to="$root.rn.UnsecuredLoans">lån uden sikkerhed</b-link>, men ikke
            alle vil blive tilbudt lånetilbud med lige
            <b-link :to="$root.rn.LoansWithLowRate">lavt rente- og gebyrniveau</b-link>.
            Når man skal optage et lån i Danmark, er det særligt nedenstående faktorer,
            der påvirker dine lånemuligheder.
          </p>
          <h3>1. Indkomst</h3>
          <p>
            Din indkomst er en af de mest afgørende faktor, når dine lånemuligheder
            vurderes. Det varierer blandt de forskellige lånevirksomheder, hvilke krav de
            stiller til dit indkomstniveau. Fælles for de fleste aktører er dog, at de
            kræver, at du har en fast indkomst.
          </p>
          <p>
            Jo højere din indkomst er, desto bedre er dine lånemuligheder. Ønsker du at
            <b-link :to="$root.rn.CheapLoan">låne penge billigt</b-link>, forudsætter det
            derfor, at du har et indkomstniveau, der medfører, at låneudbyderen ikke
            mener, at der er en risiko for, at du ikke kan betale tilbage.
          </p>
          <p>
            De fleste låneudbydere beregner gerne ud fra husstandens årlige indkomst. Har
            du en samlever, der kan skrives på ansøgning som medansøger, forbedrer dette
            muligheder for et
            <b-link :to="$root.rn.CheapLoan">billigt lån</b-link> kraftigt.
          </p>
          <p>
            Til trods for, at I reelt har to separate indkomster, bliver det betragtet som
            én hos långiverne. Derfor opnår man lettere en fair pris på et lån, hvis man
            har en medansøger.
          </p>
          <h3>2. Alder</h3>
          <p>
            Der findes adskillige lånemuligheder for studerende, og nærmest lige så mange
            lånemuligheder til pensionister. Det vil sige, at de fleste kan optage et lån
            på nettet, hvis de ønsker.
          </p>
          <p>
            Ikke desto mindre har din alder dog indflydelse på dine lånemuligheder. Dette
            skyldes, at långiverne bruger din alder som pejlemærke for din indkomst og
            økonomiske bevidsthed. Er du eksempelvis 18 år gammel, har du sandsynligvis
            ikke en synderligt stor indkomst, hvorfor antallet af lånemuligheder vil være
            begrænset for dit vedkommende
          </p>
          <p>
            Til trods for, at der er mange
            <b-link :to="$root.rn.NewLoan">nye lån</b-link> på nettet og mange nye
            låneudbydere på markedet, er det forholdsvis status quo i forhold til
            alderskrav. Hovedparten af de udbyderne, der er aktive på det danske marked,
            kræver nemlig, at du er fyldt enten 21 eller 23 år.
          </p>
          <p>
            Ovenstående er ikke ensbetydende med, at du ikke finder lån online til
            personer under 21 år. Det kan sagtens lade sig gøre. Men du vil ikke opleve,
            at det er de
            <b-link :to="$root.rn.QuickLoan">billigste kviklån</b-link>, der tilbydes,
            hvis ikke du er fyldt 21 eller 23 år.
          </p>
          <p>
            Endvidere er det som regel mikrolån og kvicklån, der udbydes til studerende og
            yngre personer. Det vil sige, at du som ung er begrænset i forhold til
            lånebeløbet, hvilket igen hænger sammen med, at långiverne vurderer, at din
            betalingsevne er lav.
          </p>
          <h3>3. RKI</h3>
          <p>
            Én af de faktorer, der i særdeleshed kan slukke for dine lånedrømme, er en
            registrering i RKI (Ribers Kredit Information). Er du registreret som dårlig
            betaler i dette register, er det umuligt at låne penge hos en online bank i
            Danmark.
          </p>
          <p>
            Det er ikke muligt at finde alternative lånemuligheder, hvor du som
            RKI-registreret vil blive accepteret. Er du registreret i RKI eller Debitor
            Registret, er lån lukket land – ikke blot på nettet, men også i din egen bank.
            Du kan læse mere på
            <a
              href="https://finansdanmark.dk/gode-raad/gaeldsraadgivning/"
              target="_blank"
              rel="noopener"
              >Finansdanmark.dk</a
            >
            omkring gældsrådgivning.
          </p>
          <p>
            Skal du gøre dig forhåbninger om at nyde godt af de mange nye lånemuligheder i
            2020, er det derfor påkrævet, at du først kommer ud af RKI. Det kan du gøre på
            to måder:
          </p>
          <ol>
            <li>
              Betal din gæld: Den første (og bedste løsning) er at betale den gæld, der
              har fået dig registreret. Når gælden er betalt, kan du anmode om at blive
              slettet fra registeret.
            </li>
            <li>
              Vent fem år: Den anden mulighed er at vente i fem år. Når du har været
              registreret i fem år, bliver du automatisk slettet fra registeret. Husk dog
              på, at det ikke betyder, at gælden forsvinder – det er kun din registrering
              i RKI, der gør det.
            </li>
          </ol>
          <h3>4. Bopæl og bankkonto</h3>
          <p>
            Lån penge og få dem med det samme – det er én af de store salgsparametre for
            onlinebaserede lånevirksomheder. For at kunne nyde godt af dette, kræver det
            som regel, at du har dansk folkeregisteradresse og dansk bankkonto.
          </p>
          <p>
            Der findes enkelt udbydere, der er aktive på det danske marked, hvor lån af
            penge online er muligt, uden dansk folkeregisteradresse og bankkonto i en
            dansk bank. Det er dog et fåtal, der tilbyder dette, hvorfor dine
            lånemuligheder er begrænsede.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">Hvad kan jeg låne på nettet samt få de bedste lånemuligheder?</h2>
          <p>
            Blandt de personer, der ønsker at optage lån på nettet, er der særligt ét
            spørgsmål, der trænger sig på: "Hvad kan jeg låne for?". Det er desværre ikke
            muligt at give et konkret svar på dette, da det – som du kan læse herover –
            afhænger af flere personlige forhold, hvilket påvirker dine lånemuligheder.
          </p>
          <p>
            Ønsker du at optage et helt nyt lån, vil du sandsynligvis lede efter en
            <b-link :to="$root.rn.QuickLoan">kviklån</b-link>
            oversigt. Det er nemlig en god måde, hvorpå du kan danne dig et fornuftigt
            overblik over lånemarkedets mange muligheder.
          </p>
          <p>
            I denne sammenhæng er det dog vigtigt at huske, at der også findes adskillige
            andre låneformer. Eksempelvis findes der alt fra store
            <b-link :to="$root.rn.ConsumerLoans">forbrugslån</b-link> og MC lån til
            diverse lånemuligheder til hus.
          </p>
          <p>
            Det onlinebaserede lånemarked har nemlig undergået en markant udvikling i
            løbet af de seneste år. Blandt andet derfor er det muligt at finde
            lånemuligheder til bolig- eller bilkøb. Derfor er der også betydelig forskel
            på, hvilke lånebeløb, der udbydes i forbindelse med de forskellige låntyper.
          </p>
          <p>
            De traditionelle kviklån lyder typisk på mellem 1.000 kr. og
            <b-link @click="setLoan(20000)">20.000 kr.</b-link> Forbrugslån kan strække
            sig fra 20.000 kr. til <b-link @click="setLoan(500000)">500.000 kr</b-link>.
            Leder du efter lån til køb af bil eller bolig, kan lånebeløbet endda overstige
            dette beløb.
          </p>
          <p>
            Endvidere er der også låneudbydere, der udbyder lånemuligheder for
            virksomheder, hvor både lånebeløb og kriterier for optagelse af lånet
            naturligvis er noget helt andet.
          </p>
          <h3>Mulighederne er mange</h3>
          <p>
            Lånemulighederne er tæt på uendelige i dag. Vil du være garanteret, at du
            optager et lån, der er skræddersyet efter dine specifikke ønske og behov, så
            <b-link :to="$root.rn.LoanImmediately">lån penge hurtigt og nemt</b-link> på
            nettet.
          </p>
          <p>
            Hvis ikke du har haft held i banken, så
            <b-link :to="$root.rn.UnsecuredLoans">lån penge uden krav</b-link> på nettet.
            Du kan – med garanti – finde en glimrende løsning, der harmonerer med din
            privatøkonomiske situation blandt de utallige lånemuligheder på nettet. Som
            regel har du nemlig friheden til selv at fastsætte løbetid og lånebeløb.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">Hvordan finder jeg de bedste lånemuligheder for lån på nettet?</h2>
          <p>
            Skal du låne penge, så lån penge billigt, men hvordan finder du de bedste
            lånemuligheder for det billigste lån? Der er nemlig ingen grund til at betale
            højere låneomkostninger, end højst nødvendigt.
          </p>
          <p>
            Det er dog lettere sagt end gjort, da lønomkostningerne som regel er skjult i
            form af diverse gebyrer. Det er ikke tilstrækkeligt at finde
            <b-link :to="$root.rn.LoansWithLowRate">lån med en lav rente</b-link>. Mange
            tror fejlagtigt, at de billigste lån med fast rente, er den økonomisk bedst
            beslutning. Sådan forholder det sig ikke.
          </p>
          <p>
            Disse lån virker sandsynligvis billige, fordi renten er lav, men som regel er
            omkostningerne pålagt i form af diverse gebyrer. Netop derfor er det
            essentielt at være opmærksom på, at der er flere andre omkostninger forbundet
            med at optage et lån.
          </p>
          <h3>Sammenlign og beregn lånemuligheder med udgangspunkt i ÅOP</h3>
          <p>
            Lån af penge i dag er både nemt og hurtigt. Faktisk foregår det for mange så
            hurtigt, at de ikke får undersøgt lånets totale omkostninger forud for
            indgåelse af en låneaftale. Desværre medfører det for mange, at de sætter sig
            for dyrt.
          </p>
          <p>
            For at gøre op med dette, er der indført et dansk lovkrav om, at alle lån- og
            kreditgivere skal skilte synligt med aftalens ÅOP (Årlige Omkostninger i
            Procent). Dette krav er naturligvis også gældende for ethvert nyt lånefirma i
            Danmark, og ikke blot for de etablerede aktører.
          </p>
          <p>
            Når du skal optage et lån, er det derfor i særdeleshed ÅOP’en, der skal være i
            fokus. Ud fra dette tal kan du nemlig forholdsvis let vurdere, hvorvidt et
            givent lån er dyrt eller ej.
          </p>
          <p>
            Vær dog opmærksom på, at tallet er et udtryk for de årlige omkostninger.
            Opdager du et mindre lån med en løbetid på eksempelvis 30 dage, vil ÅOP'en
            virke utrolig høj. Det skal du derfor ikke lade dig skræmme af.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">Mange fordele ved online lånemuligheder</h2>
          <p>
            Ønsker du at sikre, at din låneproces er nem og bekvem? I så fald så lån
            hurtige penge på nettet, hvor du ikke blot møder mildere krav og en hurtigere
            låneproces, men også adskillige andre fordele. Der findes nemlig mangle
            fordele ved at låne at låne online, som kan øge dine online lånemuligheder.
          </p>
          <p>
            Er du i tvivl om, hvorvidt dit lån af penge i dag bør optages online eller i
            egen bank, bør du naturligvis være opmærksom på, at der er fordele og ulemper
            forbundet med begge dele. Så vær sikker på at tjekke dine lånemuligheder.
          </p>
          <h3>Slip for at stille sikkerhed</h3>
          <p>
            En af de markante fordele ved at låne penge på nettet er, at du undgår at
            stille sikkerhed for lånet. Når du låner penge i banken, kræver det typisk, at
            du stiller sikkerhed for lånebeløbet.
          </p>
          <p>
            Dette kan gøres på flere måder, men som regel sker det ved, at banken tager
            pant i bil, bolig e.l. Ejer du ikke noget af værdi, der kan dække det fulde
            lånebeløb, vil banken sjældent bevillige et lån.
          </p>
          <p>
            På nettet forholder det sig anderledes. Fælles for de mange onlinebaserede
            lånevirksomheder er, at lån af penge nemt kan ske, uden at stille sikkerhed
            for lånet. Det er derfor ikke påkrævet, at du ejer en bil, bolig e.l., som
            långiver kan tage pant i.
          </p>
          <h3>Penge inden for kort tid</h3>
          <p>
            Har du brug for et
            <b-link :to="$root.rn.LoanImmediately">lån af penge akut</b-link>, er det ikke
            banken, du skal ansøge om lån hos. Ansøgnings- og behandlingsprocessen er som
            regel yderst langtrukken, da den omfatter en dybdegående kreditvurdering,
            fysiske møder mv.
          </p>
          <p>
            Derfor varer det som regel lang tid, før du har pengene på din konto. Låner du
            i stedet pengene hos en online lånevirksomhed, er processen hurtig og bekvem.
          </p>
          <p>
            Alt afhængigt af lånets størrelse, vil være pengene være udbetalt til din
            bankkonto inden for 24 til 48 timer. Har du brug for et lån af hurtige penge,
            er det derfor blandt de utallige billige lån med NemID, du skal lede.
          </p>
          <p>
            Lån af penge på nettet er derfor forbundet med flere fordele, som ses herover.
            Låneprocessen er nem, kravene er milde, du skal ikke stille sikkerhed, og
            pengene udbetales hurtigt. Dog vil du ofte opleve, at lånets omkostninger er
            højere, end i banken. Derfor skal du holde fordele og ulemper op mod hinanden,
            for at vurdere, hvad du vægter højest.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  data() {
    return {
      contents: [
        {
          title:
            'Sådan indhenter du nemt og bekvemt lånetilbud som styrker dine lånemuligheder',
          link: '#1',
        },
        {
          title: 'Hvad påvirker dine lånemuligheder?',
          link: '#2',
        },
        {
          title: 'Hvad kan jeg låne på nettet samt få de bedste lånemuligheder?',
          link: '#3',
        },
        {
          title: 'Hvordan finder jeg de bedste lånemuligheder for lån på nettet?',
          link: '#4',
        },
        {
          title: 'Mange fordele ved online lånemuligheder',
          link: '#5',
        },
      ],
    }
  },
  methods: {
    setLoan(value) {
      this.loanamount = value
      const key = 'amount'
      this.$store.commit('updateApplication', { key, value })
      this.$router
        .push({
          name: 'DynamicLoanPage',
          params: { loanamount: this.loanamount },
        })
        .catch(() => {})
    },
  },
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
