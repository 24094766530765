<template>
  <main>
    <div class="container">
      <template v-if="type === 'consumerloan'">
        <section id="ingress">
          <FaqWidget />
        </section>
      </template>
      <section id="ingress" class="col-sm-12 mb-2 sme-text" v-else>
        <div class="col-12" style="margin: auto">
          <h3 class="title">Sammenligning af erhvervslån – hurtig og pålidelig</h3>
          <div class="container mt-4">
            <p>
              At kunne have mulighed for at lave en sammenligning af diverse erhvervslån
              online gør det lettere at finde et billigt lån til netop din virksomheds
              økonomisk situation. Det er hurtigt og pålideligt at anvende Saverium.dk.
              Når du overvejer at starte en virksomhed, er det altid en god ide at have
              nogle penge til rådighed selv inden du begynder, da det gør det lettere at
              starte en virksomhed op. Det er dog muligt at stifte et selskab uden
              opsparing med et virksomhedslån.
            </p>
            <p>
              Iværksætteri kræver fantasi, mod samt finansiering. En god forretningsidé er
              intet uden en anstændig forretningsplan, og den vil aldrig realisere sig,
              hvos der ikke kan findes nogen finansiering til virksomheden. Hvis din
              forrentningsidé er god, skal du kæmpe for at få din virksomhed ud i verden
              og gøre den tilgængelig for kunder. Et erhvervslån online er en god mulighed
              når du hurtig har brug for penge for at starte din virksomhed op. Så længe
              at dit erhvervslån kommer til at blive udelukkende brugt på din virksomhed
              kan du bruge pengene til alt fra inventar, løn, udvidelse af lokaler eller
              hvis du har et underskud i en periode.
            </p>
            <p>
              At få et firmalån fra en bank kan være besværligt, og det tager altid lang
              tid da der er en masse papirarbejde som skal ordnes. Når du ansøger om et
              erhvervslån online kan du bruge pengene hurtig og lige så pålideligt som når
              du ansøger em et lån igennem en bank.
            </p>
            <h3 class="title">
              Hvor kan man få den bedste finansiering til ens virksomhedsbehov?
            </h3>
            <div class="container mt-4">
              <p>
                Når du leder efter et erhvervslån til et nyt selskab, er det især vigtig
                at finde det nøjagtige finansieringsbeløb, og hvor kan man få den bedste
                finansiering til ens virksomhed. Den meste normale er at tage et
                forrentningslån i banken. Dette kræver dog du stiller sikkerhed til
                rådighed og en meget velforberedt forretningsplan du skal præsentere. At
                opsøge en bank kan gøre det lidt mere besværligt at skulle optage et
                virksomhedslån hos, da du skal kunne sælge banken din forretningside, og
                det kan være ikke lige alle synes at din ide lyder vellykket i deres ører.
                Banken vil også altid typisk have højere renter på deres lån.
              </p>
              <p>
                Du kan også få et erhvervslån uden sikkerhed, og disse tilbydes af flere
                banker såvel finansieringsselskaber online Disse lån kan også kombineres,
                og sammen med et banklån kan du optage et virksomhedslån uden sikkerhed,
                for at fungere som sikkerhed eller som yderligere finansiering. Et
                erhvervslån uden sikkerhed hjælper dig med at få finansiering til din
                virksomhed hurtigt og ubesværet.
              </p>
              <p>
                Når du overvejer at finansiere din virksomhed, er det værd at huske at
                overveje de forskellige lån og deres betingelser for at få dig det
                billigste mulige lån til dit firma. Det er let at sammenligne lån via
                Saverium.dk lånesammenligningstjeneste, som giver dig mulighed for at
                sammenligne forskellige lånetilbud mellem flere forskellige banker og
                pengeinstitutter.
              </p>
              <h3 class="title">
                Hvad gør jeg, hvis jeg vil starte en virksomhed? – Lån for at starte en
                virksomhed
              </h3>
              <div class="container mt-4">
                <p>
                  ”Hvad gør jeg hvis jeg vil starte en virksomhed”, “Skal jeg optage et
                  lån for at starte en virksomhed?”, tænker man i starten. Det vigtigste
                  ved at starte en forretning er en god forretningsplan. Det giver
                  virksomheden mulighed for at komme langt med at realisere sin vision
                  samt holde sine planer og budget under kontrol. Forretningsplanen skal
                  altid være så realistisk som muligt, så store planer ikke mislykkes lige
                  efter start af en virksomhed. Så det er ret godt at vide, hvad en
                  virksomhed potentielt kan opnå i starten, hvad dens målgruppe er,
                  hvordan virksomhedens forretning vil blive ført, og hvor meget hele
                  virksomhedens drift vil koste.
                </p>
                <p>
                  At starte en virksomhed og skaffe finansiering til din egen idé kan være
                  en stor tærskel for mange nystartede iværksættere, da det ud over høje
                  omkostninger kræver meget mod. Men med en god forretningsplan er det
                  lettere at træde ind i det ukendte og give din forretningsidé en chance.
                  Når du stifter en virksomhed, anbefales det også at spare nogle penge,
                  da det altid kræver nogle gebyrer at starte en virksomhed. I mangel af
                  finansiering er et erhvervslån altid en mulighed, som man kan stole på,
                  når man starter en virksomhed.
                </p>
                <h3 class="title">Hvad kan man bruge et erhvervslån til?</h3>
                <div class="container mt-4">
                  <p>
                    Så snart du har fået udbetalt lånet til din konto, er du herre over
                    hvad lånet bruges til. Så hvad kan man bruge et erhvervslån til er
                    helt op til dig selv så længe det bruges på din virksomhed. Det er
                    vigtigt at hvis du optager et erhvervslån at det udelukkende bruges på
                    ens firma, om det så er til løn, kontorartikler, nye maskiner eller
                    endda et underskud der er kommet. Hvis du optager et lån som skal
                    bruges på et underskud, er det vigtigt at vurdere om at pengene kan
                    betales tilbage og nedgangen for din virksomhed ikke fortsætter, så du
                    har mulighed for at kunne tilbage lånet tilbage. Bruger du lånet på
                    dit personale kan det bruges til a hyre en ny til virksomheden eller
                    aflønne de nuværende medarbejder. Det kan os være at dit firma har
                    brug for ny inventar som kan eventuelt være kontorstole, computer
                    eller andre IT relaterede ting som kan forbedre dine medarbejders
                    arbejdsforhold, så de kan opnå det maximale for virksomheden. Du kan
                    også anvende dit erhvervslån til at reklamere og markedsføre din
                    virksomhed og dermed øge kendskabet til din virksomhed, hvilket kan
                    resultere i større salg som giver en større omsætning.
                  </p>
                  <p>
                    Er din virksomhed en produktionsvirksomhed, er det en god ide at
                    investere pengene i nye maskiner, så du kan øge din omsætning. Dette
                    er blot nogle eksempler som meget gerne skulle kunne få din virksomhed
                    i fremdrift så du kan genere en større omsætning, så du i sidste ende
                    har mulighed for at kunne betale dit lån tilbage.
                  </p>
                </div>
                <h3 class="title">Hvor mange penge kan jeg låne?</h3>
                <div class="container mt-4">
                  <p>
                    Det vil variere meget fra hvilken låneudbyder, hvilket beløb det er
                    muligt for dig og din virksomhed at optage. Så hvor mange penge kan
                    jeg låne til min virksomhed? Da der er tale om et virksomhedslån kan
                    man ikke forvente at låne et lige så stort beløb som eventuelt
                    privatkunder der ønsker sig at låne penge til finansieringen af køb
                    til bolig. Din egen og virksomhedens økonomi spiller nemlig ind i
                    forhold til hvor meget hver enkelt låneudbydere er villig til at
                    tilbyde dig. For låneudbyderne handler det jo om at de skal være
                    sikker på at få sine penge tilbage igen, så bedre økonomi du og din
                    virksomhed har, jo større chance er det for dig at kunne låne et
                    større beløb da de er sikker på at kunne få pengene tilbage igen.
                    Igennem Saverium.dk kan man låne fra 10.000 – 1.000.000 kr. til dit
                    firma.
                  </p>
                </div>
                <h3 class="title">Løbetid på erhvervslån</h3>
                <div class="container mt-4">
                  <p>
                    Løbetiden også kaldt lånetiden er det tidsrum du har til at kunne
                    tilbagebetale dit lån. Løbetiden på erhvervslån varierer i høj grad af
                    hvor stort et beløb du ønsker at optage. Hos Saverium.dk har du
                    mulighed for at låne alt i mellem 10.000 – 1.000.000 kr. til netop
                    hvad din virksomhed har brug for.
                  </p>
                  <p>
                    Som regel når der er snakke om et erhvervslån, så skal løbetiden
                    reflektere investeringens levetid. Så hvis du har eventuelt lånt
                    pengene til en firmabil, ny maskine eller ligne og det forventes at
                    den først skal udskiftes efter fem år, så vil dit erhvervslån skulle
                    betales inden for fem år.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
export default {
  components: {
    FaqWidget,
  },
  computed: {
    type() {
      return this.$store.state.applicationType.type
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#ingress {
  text-align: center;
  .title {
    text-align: left;
    font-size: 1.75rem;
    @media (max-width: 1600px) {
      text-align: center;
    }
  }
  .card-body {
    padding-top: 0;
  }
  .card-text {
    text-align: left;
    margin-left: 3.5rem;
    @media (max-width: 800px) {
      margin-left: auto;
    }
  }
}
</style>
