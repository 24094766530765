<template>
  <div>
    <section id="loan" class="flex-fill" style="margin-top: 0">
      <div class="container d-flex main-header-container">
        <div class="left-column flex-fill"></div>
        <div class="loan-boxes flex-fill">
          <div>
            <b-card class="main-card" no-body id="first-page">
              <h1 class="main-headline consumer">
                Find hurtigt og gratis det billigste
                <span class="split">lån blandt mange banker</span>
              </h1>
              <div class="check-list">
                <ul>
                  <li>{{ $t('loan_from_3k_to_500k') }}</li>
                  <li>{{ $t('get_offer_immediately') }}</li>
                  <li>{{ $t('no_need_for_guarantee') }}</li>
                </ul>
              </div>

              <div class="form-box">
                <div id="application-form-mainpage">
                  <div class="inner d-flex flex-column">
                    <b-alert
                      :show="!draftFound"
                      dismissible
                      fade
                      variant="warning"
                      class="alert"
                    >
                      {{ $t('no_draft_found') }}
                    </b-alert>

                    <PreformContainer />
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </section>
    <section id="header-footer" class="mid-section">
      <CTAScrollerComponent elementId="first-page" />
      <PartnerContainer v-if="type === 'consumerloan'" />
      <div v-if="type === 'consumerloan'">
        <HowItWorksWidget />
      </div>
      <div v-else>
        <SmeContent />
      </div>
    </section>
    <div :id="type === 'consumerloan' ? 'fp' : ''" class="faq-container">
      <div :class="type === 'consumerloan' ? 'faq-padding' : ''">
        <info-row />
      </div>
    </div>
  </div>
</template>
<script>
import InfoRow from '@/components/saverium_dk/InfoRowComponent.vue'
import SmeContent from '@/components/saverium_dk/main_page_components/SmeContent'
import SmeMainForm from '@/components/saverium_dk/sme_components/SmeMainForm'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer.vue'
import * as Cookies from 'tiny-cookie'
import { mapState } from 'vuex'
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
export default {
  created() {
    window.addEventListener('resize', this.checkVariant)
  },
  mounted() {
    const consent = Cookies.get('accept_cookies')
    this.width = window.innerWidth
    if (consent) {
      fbq('track', 'PageView')
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkVariant)
  },
  data() {
    return {
      step: 100,
      current: 0,
      showNextSmeStep: false,
      showAlert: false,
      showContainerScroller: false,
      debouncedWidth: 0,
      widthTimeout: null,
    }
  },
  components: {
    PartnerContainer,
    SmeMainForm,
    SmeContent,
    InfoRow,
    PreformContainer,
    CTAScrollerComponent,
    HowItWorksWidget,
  },

  computed: {
    ...mapState({
      coApplicant: state => state.application.coApplicant,
      stepErrors: state => state.application.stepErrors,
      draftFound: state => state.application.draftFound,
    }),

    isAuthenticated() {
      return (
        this.$store.state.oidcStore.oidcIsAuthenticated || this.$store.state.user.loggedIn
      )
    },
    type: {
      get() {
        return this.$store.state.applicationType.type
      },
      set(type) {
        this.$store.state.applicationType.type = type
      },
    },
    variant() {
      return this.checkVariant()
    },
    width: {
      get() {
        return this.debouncedWidth
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.widthTimeout = setTimeout(() => {
          this.debouncedWidth = val
        }, 500)
      },
    },
    versionId() {
      return this.$store.state.user.versionId
    },
  },

  methods: {
    setApplicationType(value) {
      this.$store.commit('setApplicationType', value)
    },
    checkVariant() {
      this.width = window.innerWidth
      if (this.width > 765) {
        return 'dark'
      } else {
        return 'light'
      }
    },
    nextSmeStep() {
      this.showNextSmeStep = true
    },
  },
}
</script>
<style lang="scss" scoped>
.loan-boxes {
  text-align: center;
}

.apply-button {
  text-align: right;
}

.alert {
  font-size: 1.22rem;
}
.main-card {
  background: #fff;
  padding: 1rem;
  margin-top: 2rem;
}
</style>
