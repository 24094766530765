<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til ferier og rejser</h1>

      <div class="top-text text-container">
        <p>
          Leder du efter det bedste og billigste steder at låne penge til ferier og
          rejser? Vi hjælper dagligt vores brugere med at finde de rette ferielån og
          rejselån. Udfyld vores ansøgning på to minutter, så indhenter vi tilbud fra en
          lang række banker til dig med det samme, så du kan komme ud at rejse billigst
          muligt.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#saadan-finder-vi-finder-de-billigste-ferielaan-til-dig"
                    >Sådan finder vi finder de billigste ferielån til dig</a
                  >
                </li>
                <li>
                  <a href="#laan-til-alle-typer-af-ferier-og-rejser"
                    >Lån til alle typer af ferier og rejser</a
                  >
                </li>
                <li>
                  <a href="#rejselaan-til-laengere-rejser">Rejselån til længere rejser</a>
                </li>
                <li>
                  <a href="#faa-hjaelp-til-dit-rejselaan-eller-ferielaan"
                    >Få hjælp til dit rejselån eller ferielån</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="saadan-finder-vi-finder-de-billigste-ferielaan-til-dig">
            Sådan finder vi finder de billigste ferielån til dig
          </h2>
          <p>
            Hvis du har brug for et lån til ferien, så er det vigtigste, at du finder et
            billigt ferielån - men hvad er egentligt et
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >billigt lån</a
            >? Hvordan forholder du dig til renter, gebyrer, totale låneomkostninger mv.
            på ferielån, vil vi gennemgå herunder, så du ikke famler i blinde, når du
            leder efter netop dét rejselån/<a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >, som skal hjælpe din økonomi igennem rejsen.
          </p>
          <p>
            Hvis du ønsker at ansøge om at låne penge til din rejse eller ferie, og gerne
            så billigt som muligt, så er det vigtigt at
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne lånetilbud</a
            >
            fra flere udbydere af ferielån. Hvis du ikke foretager en sammenligning, ved
            du ikke om du har fundet er dyrt eller billigt ferielån.
          </p>
          <p>
            Hvis du ansøger igennem os, indhenter vi tilbud på lån til ferie fra en lang
            række banker og alternative
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere</a
            >, så du hurtigt kan danne dig et overblik over, hvilke ferielån som er dyre,
            og hvilke som er billige.
          </p>
          <p>
            Alle de banker som godkender dig et at låne penge til ferien/rejsen, viser vi
            dig på en overskuelig og sammenligning liste, su du nemt kan vælge dét
            ferielån, som passer bedst til din situation.
          </p>
          <p>
            Det eneste som vi har brug for fra dig, for at vi kan indhente tilbud på
            ferielån, er at du udfylder vores ansøgningsformular, som tager 2 minutter.
          </p>
          <h3>Sådan sammenligner du ferielån</h3>
          <p>
            Når du har indsendt din ansøgning om et lån til ferien til os, indhenter vi
            automatisk og med det samme tilbud fra alle vores bankpartnere. Dermed får du
            hurtigt et overblik over, hvem der kan tilbyde at låne dig penge til ferien.
          </p>
          <p>
            Hvis du ønsker at finde det billigste ferielån, så kan du nemt gøre dette ved
            at tage det rejselån/ferielån, som har den laveste ÅOP. Dette gælder dog kun i
            tilfælde af at beløbene og løbetiden er ens.
          </p>
          <p>
            Hvis dette ikke er tilfældet, bør du derimod se på de totale låneomkostninger,
            hvilket også altid viser dig hvilket ferielån der er billigst. Her du bør dog
            også samtidig sammenligne den månedlige ydelse på feirelånene, da du kan
            risikere, at omkostningerne for to ferielån er identiske, men det ene lån skal
            tilbagebetales over meget kort tid, og dermed har en uforholdsmæssig høj
            ydelse, som din økonomi måske ikke kan holde til.
          </p>
          <p>
            Sidst men ikke mindst bør du også overveje, hvor hurtigt du har brug for at
            lånet til din ferie skal stå på din konto. Hvis du planlægger en ferie langt
            ude i fremtiden, kan du måske godt vente lidt med at få lånet udbetalt, men
            hvis du står og skal låne penge til en afbudsrejse, som du lige har set, så er
            det også altafgørende, om ferielånet også er et
            <a
              href="https://www.saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtigt lån</a
            >.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-alle-typer-af-ferier-og-rejser">
            Lån til alle typer af ferier og rejser
          </h2>
          <p>
            Uanset hvilken rejse eller feriedestination du har sat dig i udsigt, så koster
            det penge. Er det en weekendtur til London uden shopping eller
            restaurantbesøg, så er omkostningerne også begrænsede. Er det derimod
            drømmerejsen der forløber over flere måneder med hele familien, så kan det
            hurtigt løbe op i
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/100000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >100.000 kr.</a
            >
            eller mere. I sidstnævnte tilfælde kommer det ofte på tale at lån penge til
            rejsen.
          </p>
          <p>
            Nedenfor vil vi gennemgå nogle af de mest almindelige ferie og rejseformer,
            som vi hjælper vores brugere med at finde ferielån og rejselån til.
          </p>
          <h3>Shopping- og storbyferie</h3>
          <p>
            Hvis turen går til London, Paris, New York eller en fjerde storby, hvor
            shopping er det primære formål, så kan det være svært at vide præcis hvilket
            budget, du har behov for. Flyturen tur-retur, samt kost og logi kan du med god
            sandsynlighed godt estimere, men hele shoppingdelen, kan være svær at vurdere.
          </p>
          <p>
            I dette tilfælde kan det være en god ide at undersøge, om du med dit
            potentielle ferielån har mulighed for at låne penge på ferien imens du er på
            den i form af et tillægslån.
          </p>
          <p>
            Det er ikke usandsynligt shoppingferien kan blive en dyr fornøjelse - og måske
            dyrere end forventet, hvis du kommer forbi nogle af de kendte shoppingstreets
            så som Champs-Élysées, Oxford Street eller Fifth Avenue.
          </p>
          <h3>Sommerferie</h3>
          <p>
            At låne penge til sommerferien, er nok det mest udbredte ferielån, som vi
            hjælper med. Sommerferien er ofte en ferie, som især børnefamilier ikke ønsker
            at undvære. Her ser vi også store spring i budgetterne alt efter om I er en
            familie på +4 personer, som skal til Thailand i flere uger, eller i er en
            familie på 3, som skal på campingferie i Danmark en weekend.
          </p>
          <p>
            Vi ser sjældent at der er behov for at låne penge til sommerferien, hvis vi
            taler en telttur på en campingplads i Danmark. Hvis familien er lidt større og
            turen går til Frankrig, Spanien, Italien eller en af de græske øer, så løber
            omkostninger til fly, og/eller leje af en autocamper/campingvogn og benzin
            også ofte op, og dermed kan et lån til ferien igen komme på tale.
          </p>
          <p>
            Hertil skal der muligvis også købes nyt badetøj, solcreme, m.v., som i sig
            selv ikke kræver et ferielån, men også skal med i feriebudgettet.
          </p>
          <h3>Skiferie</h3>
          <p>Skiferie kommer ofte i tre forskellige budgetklasser.</p>
          <p>
            Den billigste skiferie, som dog ikke er super billig mere, er en tur til de
            Sverige eller Norge. Dette er ofte et godt begyndersted. Her ser vi sjældent,
            at der er behov for et lån til at dække feriens udgifter, men det er ikke
            usandsynligt, da det også ofte er familier med småbørn, som har ofte har de
            mest pressede økonomier, der vælger disse destinationer.
          </p>
          <p>
            Hvis du derimod vælger at tage sydpå til Aplerne, vil turen i sig selv være
            dyrere, men leveomkostningerne lavere. Hvis du tager afsted som ung med
            vennerne, eller i forbindelse med en større tur på dit studie, hvor dine
            forældre ikke betaler, så kan et lån til ferien blive relevant.
          </p>
          <p>
            Den dyreste destination er Canada. Turen til Canada i sig selv er en del
            dyrere end turen til Aplerne, hvilket i sig selv kan være en grund til at låne
            penge til ferien.
          </p>
          <p>
            Ud over selve transporten og opholdet, løber der også andre omkostninger på en
            skiferie, som et ferielån evt. også kan dække. Du får med stor sandsynlighed
            brug for et liftkort, men også leje af ski/snowboard, støvler, hjelm,
            rygskjold, hvis du ikke ejer dette i forvejen. Er du rigtig enthusiast, kan
            det også være, at du ønsker at blive droppet af med helikopter på toppen af et
            bjerg, og tage off-pisten ned hele vejen, hvilket i sig selv er en dyr
            fornøjelse.
          </p>
        </div>

        <div class="text-container">
          <h2 id="rejselaan-til-laengere-rejser">Rejselån til længere rejser</h2>
          <p>
            De længere rejser er ofte også de dyreste. For det første er rejsen i sig selv
            længere og dermed dyrere, men hvis du tager orlov fra arbejdet, har du ingen
            indkomst i denne periode, hvor du normalt ellers ville få løn, mens du var på
            ferie. Selv hvis du udlejer din bolig mens du er væk, kan et rejselån blive
            aktuelt, da omkostningerne hurtigt løber op.
          </p>
          <h3>Backpacking</h3>
          <p>
            Hvis du lige er blevet færdig med din ungdomsuddannelse, og gerne vil
            backpacke i Østen, vælger de fleste at arbejde et år eller et halvt år, for at
            spare op til rejsen. Hvis du derimod ikke kan finde et arbejde eller blot
            ønsker at rejse først, så må du låne penge fx i form af et rejselån.
          </p>
          <p>
            Denne form for
            <a
              href="https://webackpack.dk/hvad-koster-det-at-backpacke/"
              rel="noopener noreferrer"
              target="_blank"
              >rejse koster</a
            >
            typisk alt fra 300 til 700 kr. / dag, alt efter hvilket sted du ønsker at
            opleve, men langt de fleste steder ligger på ca 500 kr. / dag. Ønsker du at
            være væk tre måneder, skal du derfor regne med små 50.000 kr. Vi hjælper gerne
            med et
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/50000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >lån på 50.000 kr.</a
            >, men hvis dit behov er et større eller mindre rejselån, kan vi også sagtens
            hjælpe.
          </p>
          <p>
            Dette budget inkluderer de normale udflugter, som du kan forvente. Hvis du har
            støre planer end den normale backpacker, skal du nok lægge lidt ekstra oven i
            budgettet. Det er sjældent at man kommer hjem med ubrugt budget som
            backpacker, da der altid er en ekstra udflugt der kan opleves, et nyt bjerg
            der kan bestiges eller et sted, som man ikke har prøvet at bungee-jumpe,
            hvilket alt sammen koster penge, og måske skal dækkes af et rejselån.
          </p>
          <h3>Interrail</h3>
          <p>
            Ligesom alle andre rejser, så kan Interrail både blive en dyr og en relativ
            overkommelig udgift på rejsebudgettet. I følge
            <a
              href="https://roadtalk.dk/interrail-budget/#:~:text=rejsendes%20Interrail%20budget.-,Pris%20for%20Interrail%20rejse%3A,Sev%C3%A6rdigheder%3A%201%2C000%20kr."
              rel="noopener noreferrer"
              target="_blank"
              >Roadtalk</a
            >, kan du slippe afsted med for 7.500 kr. hvis du vælger de helt skrabede
            hostels og den billigste mad. Her vil der sjældent være behov for et lån til
            rejsen. Vælger du derimod at din Interrail skal være i den luksuriøse ende af
            skalaen, så må du slippe omkring
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/25000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >25.000 kr.</a
            >
            Uanset hvor stort eller lille dit behov er for et rejselån til at dække dine
            udgifter til Interrail, så hjælper vi dig gerne.
          </p>
          <h3>Bryllupsrejse og rejser med familien</h3>
          <p>
            En lang bryllupsrejse eller en længere rejse med familien er ofte en dyr
            fornøjelse. I dette tilfælde vil du ofte have en bolig, som du kan
            <a href="https://www.airbnb.com/" rel="noopener noreferrer" target="_blank"
              >leje ud på fx Airbnb</a
            >
            i perioden, hvor du er ude at rejse, og på den måde “neutralisere” en del af
            rejseudgifterne, men det vil langt fra være nok til at dække hele udgiften.
          </p>
          <p>
            Som tidligere nævnt kan der også være omkostninger i form af manglende
            indtægt. Hvis du under din rejse ikke har nogen form for indtægt, hvis du fx
            har opsagt dit job eller er på orlov, kan et lån til rejsen blive ekstra
            nødvendigt. Dette gælder nok sjældent bryllupsrejser, men det er ofte en
            vigtig faktor at tage med i overvejelserne, hvis du tager på en længere rejse
            med familien.
          </p>
          <p>
            Det er ikke ualmindeligt at en længere rejse med familien netto kan koste
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/75000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >75.000 kr.</a
            >
            Hvis du ikke har sparet nok op, så er et lån til rejsen nok din bedste
            mulighed for at komme afsted.
          </p>
        </div>

        <div class="text-container">
          <h2 id="faa-hjaelp-til-dit-rejselaan-eller-ferielaan">
            Få hjælp til dit rejselån eller ferielån
          </h2>
          <p>
            Hvis du på nuværende tidspunkt tænker at din bedste mulighed er at låne penge
            til rejsen eller ferien, så tøv ikke med at udfylde en ansøgning hos os.
            Ansøgningen om ferielån og rejselån er helt uforpligtende og tager kun to
            minutter at udfylde. På den måde ved du, hvilke muligheder du har for at låne
            til ferien eller rejsen, uanset om regningen skal betales nu, eller du blot er
            ved at lægge et budget.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
