import Vue from 'vue'

// Common enum actions for saverium-brands. Imported from @/store/<brand>/modules/enums.js.

export default {
  async getEnums({ commit }) {
    let enums = await Vue.saverium.getEnums()
    commit('setEnums', enums)
  },
}
