<template>
  <header
    class="header-pl d-flex flex-column"
    :class="{ 'only-header-pl': !showFullHeader }"
  >
    <!-- This fake div hidden preloads our web font! -->
    <div class="div-fake-hidden">
      <i class="far fa-square fa-3x"></i><i class="fal fa-square fa-3x"></i
      ><i class="fad fa-square fa-3x"></i>
    </div>
    <div class="blend-wrap">
      <div class="blend-content">
        <div class="blend-shield">
          <nav
            class="navbar navbar-expand-lg flex-shrink-0"
            style="background-color: #05083c"
          >
            <div class="container">
              <b-link :to="$root.rn.MainView" aria-label="Saverium" class="navbar-brand">
                <img
                  height="113"
                  width="360"
                  src="/static/img/saverium_pl/saverium_pl_logo.png"
                  alt="Saverium.pl Logo"
                />
              </b-link>

              <b-btn
                class="navbar-toggler"
                type="button"
                v-b-toggle.navbarText
                @click="visible = !visible"
                :aria-expanded="visible ? 'true' : 'false'"
                :aria-controls="'#navbarText'"
                variant="sav"
                aria-label="toggle-dropdown"
              >
                <span class="navbar-toggler-icon">
                  <i class="far fa-bars"></i>
                </span>
              </b-btn>

              <b-collapse class="collapse navbar-collapse" :id="'navbarText'">
                <ul class="navbar-nav ml-auto">
                  <li class="">
                    <b-dropdown class="header-dropdown" right offset="20">
                      <template v-slot:button-content class="nav-link">
                        {{ $t('loans') }}
                      </template>
                      <b-dropdown-item
                        class="dropdown-link"
                        :to="$root.rn.OnlineLongtermLoans"
                      >
                        {{ $t('long_term_loans') }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        class="dropdown-link"
                        :to="$root.rn.ShortTermLoans"
                      >
                        {{ $t('short_term_loans') }}
                      </b-dropdown-item>

                      <b-dropdown-item
                        class="dropdown-link"
                        :to="$root.rn.OnlineNonbankLoans"
                      >
                        {{ $t('nonbank_loan') }}
                      </b-dropdown-item>

                      <b-dropdown-item
                        class="dropdown-link"
                        :to="$root.rn.OnlinePaydayLoans"
                      >
                        {{ $t('payday_loan') }}
                      </b-dropdown-item>

                      <b-dropdown-item class="dropdown-link" :to="$root.rn.LoansForProof">
                        {{ $t('loan_for_proof') }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        class="dropdown-link"
                        :to="$root.rn.InstantInternetLoans"
                      >
                        {{ $t('instant_loan') }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        class="dropdown-link"
                        :to="$root.rn.LoanInstallment"
                      >
                        {{ $t('loan_installment') }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        class="dropdown-link"
                        :to="$root.rn.LoansInFifteenMinutes"
                      >
                        {{ $t('loans_in_fifteen_minutes') }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </li>
                  <li>
                    <b-dropdown class="header-dropdown" right offset="20">
                      <template v-slot:button-content class="nav-link">
                        {{ $t('credits') }}
                      </template>
                      <b-dropdown-item
                        class="dropdown-link"
                        :to="$root.rn.LoanCalculator"
                      >
                        {{ $t('loan_calculator') }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        class="dropdown-link"
                        :to="$root.rn.LoanForFinishingHouse"
                      >
                        {{ $t('loan_for_finishing_house') }}
                      </b-dropdown-item>
                      <b-dropdown-item class="dropdown-link" :to="$root.rn.CashLoan">
                        {{ $t('cash_loan') }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        class="dropdown-link"
                        :to="$root.rn.ConsolidationLoan"
                      >
                        {{ $t('consolidation_loan') }}
                      </b-dropdown-item>
                      <b-dropdown-item class="dropdown-link" :to="$root.rn.RevolvingLoan">
                        {{ $t('revolving_loan') }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        class="dropdown-link"
                        :to="$root.rn.RefinancingLoan"
                      >
                        {{ $t('refinancing_loan') }}
                      </b-dropdown-item>
                      <b-dropdown-item class="dropdown-link" :to="$root.rn.CarLoan">
                        {{ $t('car_loan') }}
                      </b-dropdown-item>
                      <b-dropdown-item class="dropdown-link" :to="$root.rn.BusinessLoan">
                        {{ $t('business_loan') }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </li>
                  <li>
                    <b-link :to="$root.rn.ArticleView" class="nav-link">
                      {{ $t('articles') }}
                    </b-link>
                  </li>
                  <li>
                    <b-link :to="$root.rn.OurPartnersView" class="nav-link">
                      {{ $t('our_partners') }}
                    </b-link>
                  </li>
                  <!--       <li>
                    <b-link :to="$root.rn.LoanView" class="nav-link">
                      {{ $t('loan_application') }}
                    </b-link>
                  </li> -->
                  <li>
                    <b-link :to="$root.rn.FaqView" class="nav-link">
                      {{ $t('faq') }}
                    </b-link>
                  </li>
                  <!--   <li>
                    <b-link :to="$root.rn.MyAccountView" class="nav-link">
                      {{ $t('my_account') }}
                    </b-link>
                  </li> -->
                </ul>
              </b-collapse>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <section
      id="loan"
      class="flex-fill"
      v-if="showFullHeader"
      style="background-color: #ffffff; margin-top: 0; margin-bottom: 0px"
    >
      <div class="container d-flex">
        <div class="left-column flex-fill">
          <div class="header-slogan flex-fill">
            {{ $t('reliable_and_fast') }}
          </div>
        </div>
        <b-overlay show opacity="0.85">
          <div class="loan-boxes flex-fill">
            <div class="form-box">
              <h1>{{ $t('get_a_loan_easily') }}</h1>
              <span>
                {{ $t('get_multiple_loan_offers_through_one_loan_application') }}
              </span>
              <div class="inner d-flex flex-column">
                <b-alert :show="!draftFound" dismissible fade variant="warning">
                  {{ $t('no_draft_found') }}
                </b-alert>
                <LoanAmountSlider
                  currency="zł"
                  separator="space"
                  :loanAmounts="this.loanAmounts"
                  :variant="variant"
                />
                <MonthlyAmount />
                <StepTwo />
                <StepOne />

                <small class="small">
                  Administratorem danych osobowych jest LVS Brokers Sp. z o. o. z siedzibą
                  w Poznaniu. Informacje na temat przetwarzania danych osobowych znajdują
                  się w
                  <b-link :to="$root.rn.MarketingConsentsPDF" target="_blank"
                    >Polityce Prywatności</b-link
                  >. Dane osobowe będę przez nas przetwarzane w celu umożliwienia
                  skorzystania z Saverium. Wprzypadku niedokończenia lub nieprawidłowego
                  wypełnienia wniosku wprowadzone do formularza dane osobowe mogą zostać
                  przez nas użyte do kontaktu mającego na celu umożliwienie dokończenia
                  wniosku albo pomoc w jegoprawidłowym wypełnieniu. W razie jakichkolwiek
                  pytań prosimy o kontakt pod kontakt@Saverium.pl
                </small>
                <!--   <div class="col-md-12 apply-button" style="text-align: right">
                <b-link
                  @click="next"
                  class="btn btn-primary btn-saverium btn-wide next-btn btn btn-front"
                  id="apply"
                >
                  {{ $t('get_a_loan') }}
                </b-link>
              </div> -->
              </div>
              <span class="disclaimer">
                {{ $t('the_apr_is') }}
              </span>
            </div>
          </div>
          <template #overlay>
            <p class="notice-text">Usługa jest obecnie niedostępna</p>
          </template>
        </b-overlay>
      </div>
    </section>

    <section
      id="header-footer"
      v-if="showFullHeader"
      class="five-points"
      style="text-align: center; background: #05083c"
    >
      <div
        class="flex-content-center justify-content-center align-items-center info-box pt-3"
      >
        <h2 class="title white gold">
          {{ $t('get_a_loan_easily') }}
        </h2>
        <div class="table">
          <ul class="nav" style="display: inline-flex">
            <li>
              <b-link class="white-link" :to="$root.rn.LoanView" @click="setLoan(5000)"
                >5 000 &#122;&#322;</b-link
              >
            </li>
            <li>
              <b-link class="white-link" :to="$root.rn.LoanView" @click="setLoan(10000)"
                >10 000 &#122;&#322;</b-link
              >
            </li>
            <li>
              <b-link class="white-link" :to="$root.rn.LoanView" @click="setLoan(20000)"
                >20 000 &#122;&#322;</b-link
              >
            </li>
            <li>
              <b-link class="white-link" :to="$root.rn.LoanView" @click="setLoan(50000)"
                >50 000 &#122;&#322;</b-link
              >
            </li>
          </ul>
        </div>
      </div>
      <section class="logo-section col-12 pt-2">
        <div class="title title-logo-wrapper">
          <h3 class="title logos">
            {{ $t('our_partners') }}
          </h3>
        </div>
        <div class="container logos-wrapper">
          <b-row class="justify-content-center logo-row">
            <div
              v-for="(partner, index) in arrangedPartners"
              :key="index"
              class="logo-box"
            >
              <b-col cols="12">
                <b-img class="logo" :src="partner.src" :alt="partner.alt" />
              </b-col>
            </div>
          </b-row>
        </div>
      </section>
      <div
        class="flex-content-center justify-content-center align-items-center info-box mt-2"
      >
        <span class="title-gold" style="font-size: 30px">
          {{ $t('this_is_how_it_works') }}
        </span>
        <br />
        <div class="row info">
          <div class="col-lg-2 col-12">
            <i class="fad fa-user-shield"></i>
            <h3 class="title-gold">{{ $t('secure') }}</h3>
            <p class="small-gold">
              {{ $t('more_than_500000_satisfied_customers') }}
            </p>
          </div>
          <div class="col-lg-2 col-12">
            <i class="fad fa-tachometer-alt-fastest"></i>
            <h3 class="title-gold">{{ $t('fast') }}</h3>
            <p class="small-gold">
              {{ $t('get_money_to_your_bank_account_in_a_same_day') }}
            </p>
          </div>
          <div class="col-lg-2 col-12">
            <i class="fad fa-handshake"></i>
            <h3 class="title-gold">{{ $t('no_insurances') }}</h3>
            <p class="small-gold">
              {{ $t('no_need_for_insurances_or_guarantees') }}
            </p>
          </div>
          <div class="col-lg-2 col-12">
            <i class="fal fa-percentage"></i>
            <h3 class="title-gold">
              {{ $t('safe_from_the_interest_rates') }}
            </h3>
            <p class="small-gold">
              {{ $t('we_find_you_the_best_loan_offer') }}
            </p>
          </div>
          <div class="col-lg-2 col-12">
            <i class="fad fa-coins"></i>
            <h3 class="title-gold">{{ $t('free') }}</h3>
            <p class="small-gold">
              {{ $t('apply_for_a_loan_is_free_of_charge') }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </header>
</template>

<script>
import StepOne from '@/components/saverium_pl/steps/StepOne'
import StepTwo from '@/components/saverium_pl/steps/StepTwo'
import MonthlyAmount from '@/components/saverium_pl/loan_amount_components/MonthlyAmount'
import { loanAmounts } from '@/utils/saverium_pl/const'
import LoanAmountSlider from '@/components/LoanAmountSlider'
import { arrangedPartners } from '@/utils/saverium_pl/partners'
export default {
  created() {
    const scriptExists = document.getElementById('financialServiceData')
    if (!scriptExists) {
      const script = document.createElement('script')
      script.type = 'application/ld+json'
      script.innerHTML = JSON.stringify(this.jsonld)
      script.id = 'financialServiceData'
      document.head.appendChild(script)
    }
  },
  beforeDestroy() {
    const script = document.getElementById('financialServiceData')
    document.head.removeChild(script)
  },
  data() {
    return {
      current: 0,
      props: ['StepOne', 'StepTwo'],
      visible: false,
      jsonld: {
        '@context': 'https://schema.org',
        '@type': 'FinancialService',
        name: 'Saverium.pl',
        legalName: 'LVS Brokers Sp. z o.o.',
        image: 'https://www.saverium.pl/static/img/saverium_pl/saverium_pl_logo.png',
        priceRange: '100-200000',
        brand: {
          '@type': 'Thing',
          name: 'saverium.pl',
        },
        address: {
          '@type': 'PostalAddress',
          streetAddress: 'NOBEL PRO Pańska 96,',
          postalCode: '00-837',
          addressLocality: 'Warszawa',
          telephone: '223070774',
        },
      },
      variant: 'dark',
    }
  },
  components: {
    LoanAmountSlider,
    MonthlyAmount,
    StepOne,
    StepTwo,
  },
  computed: {
    arrangedPartners() {
      return arrangedPartners
    },
    showFullHeader: function () {
      const path = this.$route.path
      return path === this.$root.rn.MainView
    },

    draftFound() {
      return this.$store.state.application.draftFound
    },
    loanAmounts() {
      return loanAmounts
    },
  },

  methods: {
    setLoan(value) {
      const key = 'amount'
      this.$store.commit('updateApplication', { key, value })
    },
    next() {
      this.$store.commit('setStepProgress', this.current + 1)
      this.$router.push(this.$root.rn.LoanView)
    },
  },
}
</script>
<style lang="scss" scoped>
@media (max-width: 750px) {
  .collapse.show {
    text-align: center;
  }
}
.loan-boxes {
  text-align: center;
}
.apply-button {
  text-align: right;
}
.info-box {
  text-align: center;
}
.title-logo-wrapper {
  background: white;
  .title {
    margin-top: 0 !important;
    font-family: 'Montserrat', sans-serif !important;
  }
}
.five-points {
  padding-bottom: 0 !important;
}
.logo-section {
  margin: auto;
  padding-bottom: 2rem;
  background: white;
}
.gold {
  color: #bdab71 !important;
}

.small {
  font-size: 0.7rem;
  margin-bottom: 2rem;
  a {
    font-weight: 400;
    background-image: linear-gradient(transparent calc(100% - 1px), rgb(5, 8, 60) 1px);
    background-size: 100% 100%;
    background-position: 100%;
    position: relative;
    background-repeat: no-repeat;
    transition: all 0.2s;
    &:hover {
      transition: all 0.2s;
      text-decoration: none;
      background-size: 0 100%;
    }
  }
}
.logos-wrapper {
  max-width: 1580px;
  .logo-box {
    align-self: baseline;
    margin-bottom: 2rem;
  }
}

.notice-text {
  font-size: 1.2rem;
}
</style>
