<template>
  <div class="row article">
    <h1 class="center-title">Nowe pożyczki pozabankowe – najnowsze chwilówki online</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >Na rynku
              <b-link :to="$root.rn.OnlinePaydayLoans">pożyczek chwilówek online</b-link>
              i
              <b-link :to="$root.rn.LoansForProof"
                >pożyczek na dowód przez internet</b-link
              >
              co kilka tygodni czy miesięcy pojawiają się nowe firmy skłonne do udzielenia
              swoim klientom takich zobowiązań finansowych. Również i istniejący
              pożyczkodawcy zmieniają okresowo swoje oferty, co daje szersze możliwości
              wyboru takiej propozycji, która najbardziej przypadnie nam do gustu spośród
              nowych pożyczek pozabankowych.
            </i>
          </p>
          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/new-nonbank-loans-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/new-nonbank-loans.jpg"
            alt="Nowe pożyczki pozabankowe – najnowsze chwilówki online"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container mt-5">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            Określenie nowe chwilówki przez internet oznacza wszystkie te oferty na
            chwilówki gotówkowe pozabankowe, które w danym czasie pojawiają się na rynku.
            Nowe firmy wprowadzają swoją ofertę, a ty możesz wówczas skorzystać z
            najnowszych chwilówek, zgodnie z warunkami stawianymi Ci przez pożyczkodawców.
          </p>
          <p>
            Mianem najnowszych chwilówek na rynku można określić
            <b-link :to="$root.rn.MainView">pożyczki online</b-link>, które spełniają
            jeden z poniższych warunków:
          </p>
          <ul>
            <li>
              Są oferowane przez nowe firmy pożyczkowe na rynku pozabankowym w Polsce –
              działające około 2 lata.
            </li>
            <li>Są promocyjnymi ofertami adresowanymi do wąskiej grupy klientów.</li>
            <li>
              Wykorzystują najnowsze technologie, np. udzielają nowych chwilówek online
              lub przez aplikację mobilną.
            </li>
          </ul>
          <p>
            Jeśli pożyczki proponowane są przez firmę, która dopiero buduje świadomość
            marki wśród klientów i ma ich jeszcze niewielu, to także będą to nowe
            chwilówki pozabankowe.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Zastanawiasz się, dlaczego i czy w ogóle warto jest zainteresować się nowymi
            chwilówkami na rynku? Zapoznaj się z ich największymi zaletami, co może pomóc
            Ci w racjonalnym podjęciu decyzji w zakresie wyboru formy zewnętrznego
            finansowania. Do atutów nowych chwilówek online zaliczamy:
          </p>
          <ul>
            <li>
              Świetne promocje na nowe oferty, dla nowych klientów – łącznie z możliwością
              zaciągnięcia <b-link :to="$root.rn.FreeLoan">pożyczki bezpłatnie</b-link>,
              bez ponoszenia kosztów dodatkowych opłat czy odsetek.
            </li>
            <li>
              Deklarowanie udzielenia finansowania bez sprawdzania baz danych BIK-u czy
              biur informacji gospodarczej, jak BIG InfoMonitor czy Krajowy Rejestr
              Dłużników.
            </li>
            <li>
              Brak baz danych klientów, w których pożyczkodawca mógłby sam szybko
              sprawdzić, czy nie masz długów.
            </li>
          </ul>
          <p>
            Jeśli zależy Ci na szybkiej pożyczce chwilówce na 30 dni czy
            <b-link :to="$root.rn.LoanForTwoMonths">pożyczce na 60 dni</b-link>, z niskimi
            kosztami, zainteresuj się możliwością skorzystania z nowej oferty na rynku od
            mało znanej firmy pozabankowej. Możesz także skorzystać z naszej platformy -
            składając tylko jeden wniosek, uzyskasz od nas kilka propozycji szybkich
            pożyczek online i sam zdecydujesz, która jest dla Ciebie najlepsza. Ubieganie
            się o pożyczkę za pośrednictwem platformy Saverium jest całkowicie bezpłatne.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Oferta na mało znane
            <b-link :to="$root.rn.LoanInstallment">pożyczki pozabankowe</b-link>
            dedykowana jest osobom, którym zależy na możliwości szybkiego uzyskania
            wypłaty środków bezpośrednio na konto bankowe podane na etapie wnioskowania o
            takie zobowiązanie.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Podobnie jak w przypadku już istniejących pożyczek pozabankowych na rynku
            chwilówek, z nowej oferty na podobne produkty finansowe skorzystasz, jeśli
            spełniasz kilka podstawowych wymogów:
          </p>
          <ul>
            <li>
              Masz co najmniej 18 lat, czyli jesteś osobą pełnoletnią, z pełną zdolnością
              do czynności prawnych.
            </li>
            <li>Masz dowód osobisty i polskie obywatelstwo.</li>
            <li>Masz numer konta bankowego i rachunek założony na własne dane.</li>
            <li>
              Masz wystarczająco wysoką
              <b-link :to="$root.rn.CreditWorthiness">zdolność kredytową</b-link>.
            </li>
            <li>Nie masz długów w bazach danych dłużników.</li>
          </ul>
        </div>
        <div class="text-container">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            Nowe pożyczki pozabankowe mogą być tak samo bezpieczne jak i te, które od lat
            są dostępne na rynku. Zazwyczaj nowi pożyczkodawcy także wymagają od klienta
            zweryfikowania tożsamości poprzez jednorazowy przelew weryfikacyjny na
            symboliczną kwotę 1 gr lub 1 zł. Jeśli dane z przelewu będą takie same jak te
            wskazane we wniosku o pożyczkę, weryfikacja przebiegnie poprawnie.
          </p>
          <p>
            Jeśli chcesz mieć pewność, że nowa pożyczka, którą chcesz zaciągnąć, nie wiąże
            się z żadnym ryzykiem, sprawdź, czy firma, która ją oferuje jest wpisana do
            Rejestru Instytucji Pożyczkowych prowadzonego przez Komisję Nadzoru
            Finansowego.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            Platforma Saverium pozwoli Ci w sposób łatwy i szybki ubiegać się o nowe
            pożyczki pozabankowe przez internet. Wystarczy, że złożysz jeden wniosek. Po
            weryfikacji przekażemy go do kilku firm. Dzięki temu w krótkim czasie możesz
            uzyskać wiele ofert i wybrać taką, która będzie dla Ciebie najlepsza. Nie
            jesteś zobowiązany do tego, by zdecydować się na z jakąkolwiek z
            przedstawionych Ci propozycji.
          </p>
        </div>
        <div class="text-container">
          <h2 id="7">{{ contents[6].title }}</h2>
          <p>
            Nowe pożyczki pozabankowe mogą być udzielane na promocyjnych warunkach,
            dlatego warto się nimi zainteresować i rozważyć możliwość skorzystania z mało
            znanych chwilówek przez internet. Czasami możesz uzyskać taką pożyczkę nawet
            bezpłatnie, o ile zwrócisz ją w terminie wskazanym w umowie. Wypełnij tylko
            jeden wnisoek za pośrednictwem naszej platformy, aby uzyskać atrakcyjne
            propozycje
            <b-link :to="$root.rn.LoansInFifteenMinutes"
              >szybkich pożyczek z wypłatą nawet w 15 minut</b-link
            >.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'Czym są nowe pożyczki pozabankowe?',
          link: '#1',
        },
        {
          title: 'Jakie są zalety nowych pożyczek pozabankowych?',
          link: '#2',
        },
        {
          title: 'Do kogo skierowana jest oferta nowych pożyczek?',
          link: '#3',
        },
        {
          title: 'Jakie są warunki otrzymania nowej pożyczki pozabankowej?',
          link: '#4',
        },
        {
          title: 'Czy nowe pożyczki pozabankowe są bezpieczne?',
          link: '#5',
        },
        {
          title: 'Skorzystaj z Saverium i weź pożyczkę online',
          link: '#6',
        },
        {
          title: 'Nowe pożyczki pozabankowe – podsumowanie',
          link: '#7',
        },
      ],
    }
  },
}
</script>
