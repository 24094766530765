<template>
  <div>
    <div class="article">
      <h1 class="center-title">Hurtiglån - Lån penge hurtigt her</h1>
      <div class="top-text text-container">
        <p>
          Mangler du penge her og nu? Ansøg om et hurtiglån på to minutter. Ofte har du
          pengene inden for en hverdag, fra du bliver godkendt til et hurtigt lån. Start
          din ansøgning her.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#introduktion-til-hurtiglaan">Introduktion til hurtiglån</a>
                </li>
                <li>
                  <a href="#laan-penge-hurtigt-og-billigt"
                    >Lån penge hurtigt og billigt</a
                  >
                </li>
                <li>
                  <a href="#situationer-der-kraever-et-hurtigt-laan"
                    >Situationer der kræver et hurtigt lån</a
                  >
                </li>
                <li>
                  <a href="#faa-et-hurtiglaan-gennem-os">Få et hurtiglån gennem os</a>
                </li>
                <li>
                  <a href="#saadan-finder-du-det-billigste-hurtiglaan"
                    >Sådan finder du det billigste hurtiglån</a
                  >
                </li>
                <li><a href="#ofte-stillede-spoergsmaal">Ofte stillede spørgsmål</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="introduktion-til-hurtiglaan">Introduktion til hurtiglån</h2>
          <p>
            Et hurtiglån er skabt til behov, hvor mange har brug for pengene inden for
            kort tid. Her er både ansøgningen og udbetalingen tilpasset på en måde, så du
            kan have pengene hurtigst muligt på din konto.
          </p>
          <p>
            Selvom lånet skal gå hurtigt, er det vigtigt, at man får afsøgt mulighederne
            på markedet, og det er her, vi kommer ind i billedet. Vi hjælper dig med at
            ansøge om et hurtigt lån hos en lang række banker og låneudbydere, så du uden
            at skulle vente eller spilde tid kan sammenligne og vælge det lån, som passer
            bedst til dine behov.
          </p>
          <p>
            Vi giver dig et komplet overblik over de forskellige lån, så du både kan se,
            hvor du kan få lånet billigst og hvor du hurtigst muligt kan have pengene på
            din konto.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-penge-hurtigt-og-billigt">Lån penge hurtigt og billigt</h2>
          <p>
            Hvis der er to ting, vi hjælper vores brugere med, så er det at
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >
            på en måde, der både gør det hurtigt og billigt. Vi er en uvildig
            sammenligning af mange tilbud, så vi er kun interesseret i, at du finder det
            hurtiglån med den absolut laveste udgift.
          </p>
          <p>
            Kigger vi en række år tilbage, kunne dette ikke lade sig gøre, da
            digitaliseringen ikke var kommet til et punkt, hvor et komplet kreditvurdering
            kunne gøres så hurtigt (og online). Tiden har dog ændret sig, og vi kan derfor
            tilbyde dig at finde frem til det billigste hurtige lån på blot få minutter.
          </p>
          <p>
            Vores service er naturligvis gratis og uforpligtende, og det står dig frit for
            at benytte vores service til at undersøge, hvorvidt du har mulighed for at
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >
            til lige præcis det, du går og drømmer om.
          </p>
        </div>

        <div class="text-container">
          <h2 id="situationer-der-kraever-et-hurtigt-laan">
            Situationer der kræver et hurtigt lån
          </h2>
          <p>
            Når man bliver økonomisk ansvarlig, forsøger man naturligvis at planlægge sin
            økonomi, så der ikke kommer en masse uventede poster - og det er naturligvis
            også sådan, vi anbefaler dig at tilrettelægge din økonomi. Det er aldrig rart
            at have en stresset situation, der kræver, at man handler virkelig hurtigt.
            Dog ved vi også, at de fleste kommer ud i situationer, hvor økonomien lige
            pludselig bliver presset, og derfor hjælper vi hvert år mange danskere med at
            finde det bedste hurtiglån.
          </p>
          <p>Herunder kan du se nogle situationer, som ofte kræver et hurtigt lån.</p>
          <h3>En pludselig regning</h3>
          <p>
            Hvis der lige pludselig kommer en regning, som I ikke havde regnet med, så kan
            et hurtigt lån være en løsning, der giver ro i maven igen. Det kan eksempelvis
            en tur til tandlægen, hvor du regnede med at skulle betale for et tjek og en
            tandrensning, men hvor det pludselig blev til flere huller og en
            paradontosebehandling. I en situation som denne, har man typisk ikke den
            længste kredit, og derfor er det rart at kunne låne penge hurtigt, så man ikke
            skal gå og frygte rykkere.
          </p>
          <h3>Et godt tilbud</h3>
          <p>
            Her snakker vi ikke om et tilbud, som trigger din lyst til at købe nye ting
            eller udskifte et velfungerende apperat i huset. Vi taler om de indkøb, du
            allerede havde planlagt at gøre. Forestil dig, at vaskemaskinen er gået i
            stykker, og I har planlagt at købe en ny, når næste lønning går ind. Midt på
            måneden ser I dog, at lige præcis den model, I har set jer lun på, er på 40%
            tilbud. I har stadig ikke pengene på kontoen og tilbuddet udløber, når I får
            løn. Her kan et hurtigt lån være en god løsning, som gør, at I kan gøre brug
            af det gode tilbud.
          </p>
          <h3>Skader på boligen</h3>
          <p>
            Hvis man ejer et hus eller en lejlighed, har man eller hele eller dele af
            ansvaret for at tingene virker. Hvis en storm, et skybrud eller lign. skader
            boligen, kan det blive en stor udskrivning. Gør din situation, at denne
            betaling havner hos dig, så kan du have brug for at låne penge hurtigt, så du
            kan få udbedret skaden inden den forværres. Hvis du har en opsparing eller
            noget opsparet friværdi, så bør du overveje, om det giver bedst mening at
            bruge heraf. Er det dog ikke tilfældet, så kan et hurtigt lån gennem en af
            vores samarbejdspartnere være løsningen.
          </p>
          <h3>Særlige anledninger</h3>
          <p>
            Nogle gange kommer alle fester og fejringer som perler på en snor. Pludselig
            er alle weekender i to til tre måneder fyldt med polterabend, fødselsdag,
            bryllup, housewarming, reception og lign. Selvom det er hyggeligt og skønt at
            mødes til disse fejringer, så er det også en dyr omgang. Derfor kan et hurtigt
            lån være attraktivt, så man har mulighed for at køber gaver, transport,
            drikkevarer og lignende til alle arrangementerne - og så betale tilbage i de
            efterfølgende måneder, hvor der er færre ting på programmet.
          </p>
          <h3>Ferier</h3>
          <p>
            Er man på ferie og løber tør for penge, kan det være rigtig ærgerligt at
            skulle gå på kompromis, når man “endelig” er kommet afsted på en skøn ferie.
            Her kan der være en oplagt muligt til at optage et hurtigt lån på et lille
            beløb, som så kan bruges på resten af ferien. Til lige pærics dette formål, er
            det særlig vigtigt, at man får pengene hurtigt udbetalt, så man når at have
            dem, mens man er på ferie. Mange af vores udbydere tilbyder, at du har pengene
            samme dag, som du ansøger.
          </p>
        </div>

        <div class="text-container">
          <h2 id="faa-et-hurtiglaan-gennem-os">Få et hurtiglån gennem os</h2>
          <p>
            Hurtiglån er en bred beskrivelse, som er blevet brugt til at beskrive mange
            forskellige typer af lån. Herunder kan du se de tre ting, som vi mener
            kendetegner et hurtiglån.
          </p>
          <h3>1) Hurtigt overblik</h3>
          <p>
            Det er denne del, vi har specialiseret os i. Du kan på få minutter få et
            overblik over dine muligheder for at optage et hurtigt lån. Det er naturligvis
            ganske gratis og uforpligtende, så få indblik i dit udvalg af lån uden
            nødvendigvis at skulle tage et af dem. Vi lader dig ganske enkelt foretage en
            ansøgning, som vi så sender videre til et kæmpe udvalg af banker og
            låneudbydere. Dem som godkender dig til lånet, samlet vi så tilbud fra, så du
            nemt kan sammenligne ÅOP, kreditomkostninger, udbetaling (herunder
            <strong>lån med straks udbetaling</strong>) og lign.
          </p>
          <h3>2) Hurtig ansøgning</h3>
          <p>
            Denne hænger lidt sammen med ovenstående, for vi laver ikke bare en hurtig
            ansøgning for dig. Vi laver mange ansøgninger for dig på få minutter. Det
            betyder, at når du ser overblikket, har vi allerede lavet ansøgningerne og
            fået svarene til dig.
          </p>
          <h3>3) Hurtig udbetaling</h3>
          <p>
            En lang række af vores samarbejdspartnere er valgt baseret på deres evne til
            at kunne udbetale lånet hurtigt. Vi ved nemlig, at det kan være essentielt for
            dig, at du ikke skal gå og vente flere dage, hvis du har et akut behov for at
            låne penge. Mange af de udbydere, vi samarbejder med, tilbyder udbetaling på
            inden for en hverdag (nogle gange få minutter efter, du har accepteret deres
            tilbud).
          </p>
        </div>

        <div class="text-container">
          <h2 id="saadan-finder-du-det-billigste-hurtiglaan">
            Sådan finder du det billigste hurtiglån
          </h2>
          <p>
            Pris er helt klart en af de vigtigste parametre, når man optager et lån.
            Typisk er det samlede kreditomkostninger, som er det mest interessante og
            håndgribelige tal. Her kan vi nemlig se, hvor meget der skal betales tilbage
            udover det beløb, som man låner. Herunder kan du se nogle af vores råd til,
            hvordan du får det billigste hurtiglån.
          </p>
          <h3>Sammelign</h3>
          <p>
            Vores første punkt handler naturligvis om at
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne lån</a
            >, da der kan være stor forskel på udgifterne hos forskellige udbydere af
            hurtiglån. Det er nok ikke så overraskende, at dette er vores først punkt, da
            det er lige præcis dette, vi hjælper mange danskere med hver eneste måned.
            Egentlig gør vi præcis det samme for dig, som Pricerunner gør for en masse
            fysiske produkter. Vi giver dig en liste over udbydere og deres pris for at
            låne dig det ønskede beløb.
          </p>
          <h3>Kort løbetid</h3>
          <p>
            Desto kortere løbetiden er, desto lavere vil de samlede kreditomkostninger
            være. Du skal nødvendigvis ikke sætte sig så stramt økonomisk, at du ender med
            ikke at kunne betale de månedlige afdrag, så derfor bør du lægge et realistisk
            budget, og så sætte løbetiden (og dermed de månedlige afdrag) baseret på
            dette.
          </p>
          <h3>Lavt lånebeløb</h3>
          <p>
            Denne giver formentlig sig selv. Hvis du låner færre penge, får du også færre
            omkostninger. Vi vælger dog at nævne den alligevel, da mange glemmer, at også
            små forskelle kan have en mærkbar betydning på udgifterne. Gennem vores
            service kan du sætte et specifikt lånebeløb på lige præcis det antal tusinde
            kroner, du har brug for. Fremfor at tage et “pænt rundt tal”, så overvej, om
            der kan skæres nogle tusinde kroner af.
          </p>
          <h3>Kombiner flere muligheder</h3>
          <p>
            Der findes mange forskellige måder at få et hurtigt lån på, og det i nogle
            tilfælde være en fordel at kombinere lidt. Det kan eksempelvis være, at du har
            noget friværdi, som dog ikke er nok til hele dit behov. Her kan du tage så
            meget lån i friværdien som muligt og ansøge via vores tjeneste til at låne
            resten.
          </p>
        </div>

        <div class="text-container">
          <h2 id="ofte-stillede-spoergsmaal">Ofte stillede spørgsmål</h2>
          <p>
            Vi har hjulpet tusindevis af danskere med at finde det rette lån, og vi har
            gennem tiden modtaget mange spørgsmål ift. vores tjeneste og mulighed for at
            sammenligne hurtige lån. Herunder kan du se nogle af de spørgsmål, som vi ofte
            har fået fra kunder.
          </p>
          <h3>“Hvor hurtigt har jeg pengene?”</h3>
          <p>
            Det kommer lidt an på udbyderen, men ofte oplever vi, at pengene kan være på
            din konto inden for en hverdag (og nogle gange samme dag). Vi kan dog ikke
            garantere, at du får et tilbud fra lige præcis de udbydere, som tilbyder
            dette.
          </p>
          <h3>“Hvad skal jeg bruge for at ansøge?”</h3>
          <p>
            Vi har udnyttet de digitale muligheder, så det er så let for dig at ansøge om
            et hurtigt lån som muligt. Du skal blot vælge dit ønskede lån, indtaste nogle
            få oplysninger og bekræfte med NemID eller MitID. Du kan foretage ansøgningen
            fra mobiltelefon, tablet eller computer, så længe enheden har adgang til
            internettet.
          </p>
          <h3>“Skal jeg stille sikkerhed?”</h3>
          <p>
            Nej, vores lån kræver ikke, at du stiller sikkerhed for lånet. Alle vores
            samarbejdspartnere laver naturligvis en kreditvurdering, og de tilbyder kun
            hurtiglån til dem, hvor de mener lånebehov og økonomi stemmer overens.
          </p>
          <h3>“Kan jeg tage hurtiglån som 18-årig?”</h3>
          <p>
            Ja, det kan du sagtens. Aldersgrænsen for at benytte vores service er 18 år.
            Der kan dog være enkelte låneudbydere, som har en anden aldersgrænse for deres
            hurtiglån. Du kan dog benytte vores service uanset din alder, og så skal vi
            nok finde frem til de udbydere, som tilbyder hurtiglån til 18-årige.
          </p>
          <h3>“Kan jeg ansøge som arbejdsløs?”</h3>
          <p>
            Du er velkommen til at ansøge hos os, uanset din jobsituation. Dog kræver en
            del banker og låneudbydere et niveau af stabil indkomst, før de
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låner penge</a
            >
            ud. Som arbejdsløs skal du derfor forvente at blive godkendt af færre
            udbydere, men hvis du har et realistisk lånebeløb ift. dit månedlige
            rådighedsbeløb, kan der sagtens være muligheder for at du kan blive godkendt
            til et hurtiglån.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
