<template>
<b-container class="text-center">
    {{ $t('page_not_found') }}
</b-container>
</template>

<script>
export default {

}
</script>

<style>

</style>
