<template>
  <div>
    <b-row class="header-row">
      <b-col class="header-primary" md="12" cols="12">
        <div :class="{ white: white }">{{ $t('main_view_header_1') }}</div>
        <div class="red" :class="{ brighter: white }">{{ $t('main_view_header_2') }}</div>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>

<script>
export default {
  props: [
    'white'
  ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .header-primary {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 20px;
  }

  .red {
    color: #2a76bc;
    /* color: rgb(235, 0, 18); */
  }

  .brighter {
    color: #41a4ff;
  }

  .white {
    color: #ffffff;
  }

  .header-secondary {
    margin-bottom: 20px;
  }

  .header-row {
    padding-top: 0;
    margin-top: 20px;
  }
</style>
