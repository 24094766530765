<template>
  <div>
    <div class="article">
      <h1 class="center-title">Sammenlign lånetilbud</h1>
      <div class="top-text text-container">
        <p>
          Vi hjælper dig med at sammenligne lån, så du kan få det bedste og billigste
          lånetilbud. Hvis du udfylder vores korte ansøgningsformular, kan vi indhente
          personlige lånetilbud til dig fra mange banker og långivere på samme tid. Vi
          indhenter dine lånetilbud med det samme, så du hurtigt kan få overblik og vælge
          det rigtige tilbud.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#saadan-hjaelper-vi-dig-med-at-sammenligne-laan"
                    >Sådan hjælper vi dig med at sammenligne lån</a
                  >
                </li>
                <li>
                  <a href="#hvorfor-skal-man-sammenligne-laan"
                    >Hvorfor skal man sammenligne lån?</a
                  >
                </li>
                <li>
                  <a
                    href="#saadan-indhentes-laanetilbud-fra-flere-banker-med-en-ansoegning"
                    >Sådan indhentes lånetilbud fra flere banker med én ansøgning</a
                  >
                </li>
                <li>
                  <a href="#hvilke-laanetyper-kan-man-sammenligne"
                    >Hvilke lånetyper kan man sammenligne?</a
                  >
                </li>
                <li>
                  <a href="#hvordan-defineres-det-bedste-laanetilbud"
                    >Hvordan defineres det bedste lånetilbud?</a
                  >
                </li>
                <li>
                  <a href="#faa-mange-gratis-laanetilbud">Få mange gratis lånetilbud</a>
                </li>
                <li>
                  <a href="#information-du-skal-oplyse-for-at-kunne-indhente-laanetilbud"
                    >Information du skal oplyse for at kunne indhente lånetilbud</a
                  >
                </li>
                <li>
                  <a href="#hvor-kan-man-ellers-sammenligne-laan"
                    >Hvor kan man ellers sammenligne lån</a
                  >
                </li>
                <li>
                  <a href="#flere-faktorer-har-har-indflydelse-paa-dine-laanetilbud"
                    >Flere faktorer har har indflydelse på dine lånetilbud</a
                  >
                </li>
                <li>
                  <a href="#saadan-foroeger-du-dine-chancer-for-at-blive-godkendt"
                    >Sådan forøger du dine chancer for at blive godkendt</a
                  >
                </li>
                <li>
                  <a href="#skal-vi-hjaelpe-dig-med-at-indhente-laanetilbud"
                    >Skal vi hjælpe dig med at indhente lånetilbud?</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="saadan-hjaelper-vi-dig-med-at-sammenligne-laan">
            Sådan hjælper vi dig med at sammenligne lån
          </h2>
          <p>
            Vi har specialiseret os i at sammenligne lån, så du meget nemmere kan finde de
            bedste og billigste lånetilbud. Måden det foregår på er, at du først vælger,
            hvor stort et lån du ønsker. Herefter indtaster du en række oplysninger, som
            vi sender ud til en lang række banker og andre långivere, så de kan give dig
            personlige lånetilbud. På den måde kan vi indhente lånetilbud til dig fra
            mange banker på samme tid og med blot én uforpligtende ansøgning.
            Efterfølgende vælger du blot dét lånetilbud, som passer bedst til dig.
            Sammenligning af priser lån har aldrig været nemmere.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvorfor-skal-man-sammenligne-laan">
            Hvorfor skal man sammenligne lån?
          </h2>
          <p>
            Herunder finder du de fire vigtigste årsager til, at vi mener, at det er
            vigtigt at sammenligne lån og benytte en lånebroker-tjeneste som vores.
          </p>
          <h3>Långiveren er som udgangspunkt ikke på din side</h3>
          <p>
            Der findes et hav af banker og långivere på det danske marked, som alle sammen
            har til formål at tjene penge på at
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >
            ud, hvilket er den første grund til at sammenligne lån, da banken ikke har
            interesse i at du får et
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >billigt lån</a
            >.
          </p>
          <p>
            Banken har ansat rådgivere, men bankrådgivere bliver også ofte betegnet som
            banksælgere, da de er ansat af banken, for at sælge dig bankens produkter.
            Bankverden har brandet deres sælgere på smart måde, så de ud fra betegnelsen
            fremstår uvildige eller evt. på din side. Lidt på samme måde, som at
            ejendomsmægleren ikke er uvildig.
          </p>
          <h3>Lån og lånesammenligning er lavinteresse</h3>
          <p>
            For det andet er lån helt generelt et lavinteresse område, hvilket bankerne
            også godt ved. Det betyder, at det er de færreste, som helt af sig selv sætter
            sig ned en fredag aften og hygger sig med at finde og sammenligne lån af ren
            og skær interesse. Lån og lånesammenligning er, sagt på en anden måde, ikke en
            hobby eller sport, men derimod blot et middel, til at komme i mål med nogle af
            sine interesser og drømme. Det skal ses på samme måde som internet,
            mobilabonnement og forsikring, som de færreste nok også glæder sig til at købe
            eller sammenligne.
          </p>
          <h3>Lån er en kompliceret at forstå</h3>
          <p>
            For det tredje er lån en kompleks størrrelse, hvor du umuligt kan føle dig
            frem til den rette løsning. Der er mange fagtermer, som kan være svære at
            forstå og forholde sig til. Her kan vi fx nævne ÅOP, bidragssats, renters
            rente og låneomlægning, men vi kunne også have opremset halvtreds andre
            termer, som kan være svære at forstå. Desværre bliver de ofte nødvendige at
            forstå, hvis du skal sammenligne lånetilbud, som du modtager enkeltstående.
          </p>
          <p>
            Selv hvis du forstår alle begreberne hver for sig, kan det stadigvæk være
            svært at holde tungen lige i munden, når du sidder med 20 lånetilbud og prøver
            at sammenligne prisen på lånene, hvis de er opstillet på hver deres måde, som
            de helt naturligt vil være.
          </p>
          <h3>Lån er ofte en stor økonomisk beslutning</h3>
          <p>
            Kunne du nogensinde finde på at vælge den første <strong>rejse </strong>du
            blev tilbudt, eller det første <strong>huslån</strong> du så på nettet? Nej
            vel. Det er en alt for stor beslutning at træffe, uden at have et
            sammenligningsgrundlag. Af samme årsag bør du ikke optage et stort og evt.
            langsigtet lån uden at sammenligne først.
          </p>
          <p>
            Desværre er forskellen på de tre ovenstående beslutninger, at
            lånesammenligning, som nævnt er lavinteresse, mens de to andre beslutninger er
            drevet af lyst, interesse og drømme, så derfor vil du helt naturligt sætte
            gang i sammenligningen.
          </p>
          <p>
            Jo større lånet er, og jo længere tid du forventer at du skal bruge på at
            tilbagebetale lånet, desto flere penge er der potentiale for at spare, hvis du
            foretager en god lånesammenligning før du beslutter dig.
          </p>
          <p>
            Vi kan nok aldrig gøre lån og lånesammenligning til højinteresse, men vi kan
            bestemt simplificere både processen med at indhente og sammenligne lånetilbud
            bedre for dig, og i modsætning til banken, har vi langt større interesse i, at
            du vælger det bedste lånetilbud.
          </p>
        </div>

        <div class="text-container">
          <h2 id="saadan-indhentes-laanetilbud-fra-flere-banker-med-en-ansoegning">
            Sådan indhentes lånetilbud fra flere banker med én ansøgning
          </h2>
          <p>
            Når du ansøger om lån via os, laver du i praksis en låneansøgning baseret på
            en række spørgsmål, som vores bank- og lånepartnere har brug for at få afvide,
            for at kunne kreditvurdere dig. Dine svar, på disse spørgsmål, sender vi over
            til vores lånepartnere, så snart du har besvaret det sidste spørgsmål og
            trykket på “Afsend”.
          </p>
          <p>
            Herfra foretager bankerne en automatisk kreditvurdering af dig, for at kunne
            give dig et lånetilbud, hvilket sker inden for få sekunder. På den måde vil du
            modtage lånetilbud fra flere banker på samme tid, som alle bliver langt ind på
            din oversigt over tilgængelige lånetilbud, så du nemt kan sammenligne
            låntilbudene.
          </p>
          <p>
            Vi præsenterer lånetilbuddene på en overskuelig og sammenligning måde, med
            alle de lovpligtige oplysninger, så du uden problemer kan se, hvilket
            lånetilbud, der passer bedst til dig.
          </p>
          <p>
            Når du har valgt dit ønskede lånetilbud, vil du evt. blive ført over til
            bankens hjemmeside, da banken muligvis selv ønsker at validere dine
            oplysninger. Dette kan fx være RKI-status, din indkomst og CPR-nummer.
            Sidstnævnte skal blandt andet bruges, hvis pengene skal overføres til din
            Nem-kontoen. Hvis banken har brug yderligere oplysninger, vil de enten tage
            fat i dig telefonisk eller pr. mail, hvilket dog sker yderst sjældent.
          </p>
          <p>
            Det lyder måske simpelt at indhente lånetilbud fra flere banker, og det er det
            også for dig. Bag ved den simple overflade, ligger komplicerede IT-systemer,
            som på millisekunder skal tale sammen, for at vi kan indhente lånetilbud fra
            flere banker til dig.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvilke-laanetyper-kan-man-sammenligne">
            Hvilke lånetyper kan man sammenligne?
          </h2>
          <p>
            En af de største lånetyper, som ligger til grund for mange lånesammenligner,
            er
            <a
              href="https://saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >, herunder
            <a
              href="https://saverium.dk/laan-penge/kassekredit"
              rel="noopener noreferrer"
              target="_blank"
              >kassekredit</a
            >,
            <a
              href="https://saverium.dk/laan-penge/samlelaan"
              rel="noopener noreferrer"
              target="_blank"
              >samlelån</a
            >
            og
            <a
              href="https://saverium.dk/laan-penge/kvilaan"
              rel="noopener noreferrer"
              target="_blank"
              >kviklån</a
            >. Både udbud og efterspørgsel er stor på disse typer af lån, som i bund og
            grund er meget sammenlignelige. Derfor giver det også god mening, at der er
            opstået hjemmesider, som ikke specialiserer sig i andet end at indhente
            lånetilbud fra flere banker, og hjælpe dig med at sammenligne lånetilbudene.
          </p>
          <p>
            Hvis du bevæger dig op i
            <a
              href="https://saverium.dk/laan-penge/bolig"
              rel="noopener noreferrer"
              target="_blank"
              >boliglån</a
            >
            eller
            <a
              href="https://saverium.dk/laan-penge/billaan"
              rel="noopener noreferrer"
              target="_blank"
              >billån</a
            >, vil beløbene også typisk være større. Især inden for sidstnævnte, findes
            der tjenester, så som vores, som hjælper dig med at finde det bedste
            lånetilbud.
          </p>
          <p>
            I en helt anden kategori for lånesammenligning, findes sammenligning af
            indlånsrenter hos diverse banker. Dette er ikke ikke vores speciale, men hvis
            du ønsker at placere en stor mængde penge i banken, kan det være en fordel at
            sammenligne indlånstilbud, før du vælger bank.
          </p>
          <p>
            Hvad der gør sig gældende for alle lånetyper, som man kan sammenligne, er at
            der kan være mange penge at spare. Hvis ikke det var muligt at opnå en
            betydelig besparelse, er det nok tvivlsomt, om lånesammenligning overhovedet
            ville eksistere som begreb.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvordan-defineres-det-bedste-laanetilbud">
            Hvordan defineres det bedste lånetilbud?
          </h2>
          <p>
            Hvad er egentligt det bedste lånetilbud? Det har vi tænkt os at give vores bud
            på, hvad vi mener det bedste lånetilbud er. Hvis vi skal definere det bedste
            lånetilbud, vil vi typisk tage udgangspunkt i pris, hastighed og forholdet til
            banken.
          </p>
          <h3>Bedste lånetilbud ud fra pris</h3>
          <p>
            De fleste vil som udgangspunkt sige, at man bør sammenligne priser på lån, og
            tage det billigste lånetilbud. Det er efter vores mening ikke et dårligt
            udgangspunkt, men der kan også være andre faktorer, som er værd at tage med i
            overvejelserne, når vi skal definere det bedste lånetilbud.
          </p>
          <p>
            Når du skal sammenligne lånetilbud ud fra prisen, bør sammenlign altid ske med
            afsæt i ÅOP. Mange begår den fejltagelse, at de tror, at renten er lånets
            eneste omkostning. Sådan forholder det sig dog ikke. Der er adskillige andre
            låneomkostninger, der også skal inddrages når du sammenligner lån. Heldigvis
            behøver man dog ikke sammenligne hver enkelt omkostning enkeltvis. I stedet
            skal sammenligningen laves med afsæt i ÅOP, som er en forkortelse for årlige
            omkostninger i procent. Det er ét tal, der samler lånets totale omkostninger
            på årlig basis og fremviser det i form af et procenttal.
          </p>
          <p>
            Når du skal sammenligne priser på lån med afsæt i ÅOP, er det essentielt, at
            de lånetilbud der sammenlignes, har ens lånebeløb og løbetid. Er det ikke
            tilfældet, er det ikke muligt at sammenligne dem på baggrund af ÅOP.
          </p>
          <p>
            Årsagen til dette er, at begge parametre har direkte indvirkning på ÅOP'en.
            Hvis løbetiden forlænges, deles omkostningerne også ud over en længere
            periode. Det vil medføre, at lånets ÅOP kan blive lavere, selvom de totale
            omkostninger er højere.
          </p>
          <h3>Bedste lånetilbud ud fra hastighed</h3>
          <p>
            Det bedste lånetilbud kan i nogle tilfælde også være det hurtigste lånetilbud.
            Dermed ikke sagt at du ikke skal sammenlign priser på lån, men hvis du står
            med et
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >akut behov for at låne penge</a
            >, du kun har behov for at låne i en kort periode, og
            <a
              href="https://saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              >lånebeløbet</a
            >
            er begrænset, så spiller tiden eller hastigheden hvormed lånet udbetales, ofte
            en større rolle, end om du kan spare 100 kr. på lånet.
          </p>
          <p>
            I nogle tilfælde, vil der være flere penge at spare, og forskellen i
            udbetalingshastighed vil være mindre. Her kan det være svært at sige, hvad der
            er det bedste lånetilbud. Under dette punkt, kan vi også nævne bekvemmelighed,
            da det også ofte hænger sammen med hastighed.
          </p>
          <p>
            Vælger du et lån, som kun kræver en online ansøgning, hvor der ikke skal
            indsendes dokumenter og kan klares
            <a
              href="https://saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >NemID eller MitID</a
            >, vil ansøgningsprocessen også forløbe meget hurtigere og nemmere.
          </p>
          <h3>Bedste lånetilbud ud fra dit forhold til banken</h3>
          <p>
            Et tredje punkt, som kan være med til at bestemme, hvad der er det bedste
            lånetilbud for dig, er hvilket forhold du har til den enkelte
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbyder</a
            >. Et dårligt forhold tæller ned og et godt forhold tæller op. Hvis du som
            udgangspunkt ikke har noget forhold, føler du dig måske alligevel tryggere ved
            at benytte den ene udbyder frem for den anden.
          </p>
          <p>
            For at opsummere, så er det altid vigtigt at sammenlign priser på lån, men det
            bedste lånetilbud, kan også være det hurtigste lånetilbud, eller lånetilbuddet
            fra den udbyder, som du stoler mest på. I de fleste tilfælde, vil det bedste
            lånetilbud, for de fleste, være et mix af disse parametre. Måske har du flere
            forhold, som du tænker har indflydelse på, hvad der er det bedste lånetilbud
            for netop dig.
          </p>
          <h3>Andre parametre for et godt lånetilbud</h3>
          <p>
            Ud over ovenstående tre parametre, kan vi også nævne følgende andre faktorer,
            som kan være relevante at tage i mente, når du skal sammenligne lån:
          </p>
          <ul>
            <li>Mulighed for pause eller afdragsfrie måneder</li>
            <li>Mulighed for at forhøje det oprindelige lån</li>
            <li>Mulighed for tidlig indfrielse uden yderligere omkostninger</li>
            <li>Mulighed for låneforsikring</li>
            <li>Mulighed for at tilføje en medansøger</li>
          </ul>
        </div>

        <div class="text-container">
          <h2 id="faa-mange-gratis-laanetilbud">Få mange gratis lånetilbud</h2>
          <p>
            Vores tjeneste er betalt af bankerne, som giver os en lille betaling, hver
            gang vi henviser en ny kunder til dem via vores lånesammenligning. Vores krav
            til bankerne er derimod, at de ikke må sætte priserne op, for kunder som
            henvender sig gennem os. På den måde kan vi indhente gratis lånetilbud til
            dig, uden at det på nogen måde koster dig noget.
          </p>
          <p>
            De fleste steder er det gratis at indhente lånetilbud, uanset om du benytter
            en broker som os til at sammenligne lån, eller du går direkte til din bank. Da
            du kan få gratis lånetilbud hos os, og oven i købet indhente lånetilbud fra
            mange banker på sammen tid, er der ikke den store gevinst ved at gå direkte
            til banken.
          </p>
          <p>
            Når man tænker på gratis lånetilbud, vil der også være nogle, som forbinder
            det med at <strong>låne penge gratis</strong>. Det var engang mulig, da
            <a
              href="https://saverium.dk/laan-penge/kvilaan"
              rel="noopener noreferrer"
              target="_blank"
              >kviklånene</a
            >
            havde kronede dage, men på grund af regulering, findes de ikke mere. Der har
            også været situationer på boligmarkedet, hvor man næsten kunne få et gratis
            lånetilbud, hvilket dog sker yderst sjældent.
          </p>
          <p>
            Hvis du står med et lånebehov og gerne vil sammenligne lån i dag, så kan vi
            indhente lånetilbud til dig helt gratis.
          </p>
        </div>

        <div class="text-container">
          <h2 id="information-du-skal-oplyse-for-at-kunne-indhente-laanetilbud">
            Information du skal oplyse for at kunne indhente lånetilbud
          </h2>
          <p>
            Hvis vi skal indhente lånetilbud fra flere banker til dig, så du har mulighed
            for at sammenligne lånetilbud, har vi brug for nogle oplysninger om dig. Disse
            oplysninger benytter vi udelukkende til at bankerne kan kreditvurdere dig og
            evt. din medansøger, hvis I er to personer, som ønsker at indhente et
            lånetilbud sammen.
          </p>
          <p>
            Informationerne falder ind under de fire sider i vores ansøgningsformular;
            lånebehov og kontaktinformation, bolig og beskæftigelse, indkomst og udgifter,
            samt person- og bankoplysninger. Derudover vil der være et femte punkt, som
            kun er aktuelt, hvis du har en medansøger.
          </p>
          <h3>Lånebehov og kontaktinformation</h3>
          <p>
            Først og fremmest, skal vi vide, hvor meget du har behov for at låne, for at
            vi kan indhente relevant lånetilbud fra flere banker, samt hvor høj en
            månedlig ydelse, du ønsker at betale. Herefter udfylder du dine
            kontaktinformationer, herunder navn, email og telefonnummer, som første skridt
            på vejen mod at sammenligne lån.
          </p>
          <h3>Bolig og beskæftigelse</h3>
          <p>
            På siden for bolig og beskæftigelse, skal du oplyse din adresse, hvor længe du
            har boet på adressen, samt hvilken type bolig du bor i, om du er gift eller
            single, samt hvad din uddannelse og beskæftigelse er. Herefter spørger vi ind
            til antallet af børn i husstanden, samt deres alder. Afslutningsvis, har vi
            brug for nogle oplysninger om antallet af biler, ejerforholdet og om du/I er
            medlem af en A-kasse. Alle disse oplysninger har bankerne og långiverne behov
            for at vide, da de er afgørende for hvilke lånetilbud bankerne kan tilbyde.
          </p>
          <h3>Indkomst og udgifter</h3>
          <p>
            Under Indkomst og udgifter skal din og evt. din samlevers indkomst før og
            efter skat oplyses, samt udgifter til el, vand, varme, telefoni, internet, tv,
            samt transport, ejerudgifter og forsikring.
          </p>
          <p>
            Afslutninvis har vi behov for at kende dine eksisterende lån, hvilket ikke kun
            er relevant for at indhente lånetilbud, men også hvis du har behov for et
            <a
              href="https://saverium.dk/laan-penge/samlelaan"
              rel="noopener noreferrer"
              target="_blank"
              >samlelån</a
            >.
          </p>
          <h3>Person- og bankoplysninger</h3>
          <p>
            Til sidst har vi behov for at kende dit CPR-nummer, dine bankinformationer,
            samt om du er registreret i RKI eller Debitor Registret. Det sidste punkt er
            et punkt, hvor vi ved at mange ansøgere ikke oplyser korrekt, da de godt ved,
            at det kan være svært at få et lånetilbud, hvis man har denne form for
            betalingsanmærkninger. Information vil dog altid blive valideret
            efterfølgende, og efter som du ønsker at sammenligne lån, som ikke blot er
            fiktive, gavner det dig derfor ikke at afgive forkerte oplyse.
          </p>
          <p>
            Hvis du opgiver forkerte informationer, som banken kan validere efterfølgende,
            vil de lånetilbud, som vi indhenter til dig, ikke være gyldige, og dermed
            kommer du til at sidde og sammenligne lån, som du ikke kan få udbetalt i
            sidste ende. Derfor anbefaler vi altid, at du opgiver så korrekte
            informationer som muligt, da du ellers kommer til at spilde din tid på fiktive
            lånetilbud og en fiktiv lånesammenligning.
          </p>
          <p>
            Det kan virke som meget information, som du skal udfylde, for at vi kan
            indhente lånetilbud, som kan danne grundlag for at du kan sammenligne lån. I
            de fleste tilfælde, tager det dog ikke mere end 2-5 minutter at udfylde denne
            information. Størstedelen af den informationen vi spørger om, er nemlig
            oplysninger, som du allerede kender, og ikke behøver at finde frem.
          </p>
          <p>
            Hvis du alternativt selv skal ud og indhente lånetilbud fra flere banker eller
            långivere, kunne denne process nemt tage en hel dag. Her ville du nemlig ikke
            blot skulle udfylde alle låneansøgningerne, men også finde frem til alle
            bankerne, finde ud af om de overhovedet tilbyder lån til personer, som ikke er
            kunder i banken allerede, samt sammenligne lånetilbuddene, som ikke vil være
            opstillet på samme måde. Set fra vores synspunkt, er det svært at se, at det
            kan betale sig, at indhente lånetilbud fra flere banker selv.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvor-kan-man-ellers-sammenligne-laan">
            Hvor kan man ellers sammenligne lån
          </h2>
          <p>
            Hvis du efter at have prøvet vores tjeneste, gerne vil finde andre steder,
            hvor du kan indhente lånetilbud fra flere banker, eller blot få en oversigt
            over, hvilke lån der findes på markedet, har vi herunder samlet en liste for
            dig. Nogle af disse lånesammenligningsfirmaer er vores konkurrenter, hvor
            andre er vores samarbejdspartnere. Listen indeholder både:
          </p>
          <ol>
            <li>Lånebrokers som indhenter lånetilbud fra flere banker til dig</li>
            <li>
              Affiliate hjemmesider som giver en general lånesammenligning i form af en
              oversigt over generelle lånevilkår hos de enkelte banker
            </li>
            <li>
              En blanding af hjemmesider som sammenligner
              <a
                href="https://saverium.dk/laan-penge/erhvervslaan"
                rel="noopener noreferrer"
                target="_blank"
                >erhvervslån</a
              >
              og
              <a
                href="https://saverium.dk/laan-penge/privatlaan"
                rel="noopener noreferrer"
                target="_blank"
                >privatlån</a
              >
            </li>
          </ol>
          <p>
            Affiliates er ikke integreret med bankerne. Gennem disse kan du derfor ikke
            indhente tilbud fra flere banker. I princippet er det muligt for affiliates at
            have flere
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere</a
            >
            på deres liste, sammenlignet med brokers, da ikke alle banker har systemer,
            som kan opsamle de informationer, som du indtaster på brokerens hjemmeside.
            Grundet regulering, efterspørgsel og markedets udvikling, er udvalget hos
            brokers dog i dag størst.
          </p>
          <p>
            På listen over firmaer, som hjælper dig med at sammenligne priser på lån
            finder du; <strong>Bankly</strong>, <strong>Lendo</strong>,
            <strong>Lånio</strong>, <strong>MyBanker</strong>, <strong>Yorker</strong>,
            <strong>LetFinans</strong>, <strong>Lendme</strong>,
            <strong>Digifinans</strong>, <strong>Mikonomi</strong>,
            <strong>Arcadia</strong>, <strong>UnoFinans</strong>,
            <strong>City Finans</strong>, <strong>GoLoan</strong>,
            <strong>Axo, Sambla </strong>samt <strong>FairLån</strong>. Det er dog værd at
            bemærke, at det meget sjældent gør en forskel hvor du sammenligner lån, hvis
            du vælger at benytte en af de store lånebrokers, så som os.
          </p>
          <p>
            Hvis du allerede har benyttet en af vores konkurrenter, som er store på
            lånesammenligning, er det derfor også begrænset, hvad du vil opnå, ved at
            sammenligne lån via vores service. I skrivende stund, er vi dog sikre på, at
            vi har nogle banker og långivere koblet på vores service, som andre store
            spillere indenfor lånesammenligning ikke kan tilbyde at sammenligne på deres
            service.
          </p>
        </div>

        <div class="text-container">
          <h2 id="flere-faktorer-har-har-indflydelse-paa-dine-laanetilbud">
            Flere faktorer har har indflydelse på dine lånetilbud
          </h2>
          <p>
            Der er mange faktorer, der har indflydelse på dine lånetilbud. Netop derfor
            har lånevirksomhederne ikke faste listepriser, men beregner derimod altid
            personlige og individuelle lånetilbud ud fra en kreditvurdering - og bør du
            altid sammenligne lån.
          </p>
          <p>
            Det er også ensbetydende med, at du ikke undgår en kreditvurdering, når vi
            skal indhente lånetilbud for dig. Havde du gjort dig forhåbninger om dette,
            bliver du skuffet. Alt fra traditionelle pengeinstitutter til moderne
            lånevirksomheder foretager nemlig kreditvurdering af deres låntagere.
          </p>
          <p>
            Når en långiver foretager en kreditvurdering, er der flere faktorer, der
            bliver inddraget. Det er ganske vist forskelligt fra udbyder til udbyder, men
            nedenstående faktorer er altid inkluderet i kreditvurderingen.
          </p>
          <h3>Din Indkomst</h3>
          <p>
            Din indkomst er en af de mest afgørende aspekter af din kreditvurdering og
            dermed hvilke lånetilbud du modtager. Hvis ikke din indkomst er tilstrækkeligt
            høj, kan långiveren blive i tvivl om, om du har råd til de månedlige terminer.
            Dette kan have indflydelse på, om du modtager, og har mulighed for at
            sammenligne flere lånetilbud, eller blot et afslag på ansøgning.
          </p>
          <p>
            Derfor afhænger det maksimale
            <a
              href="https://saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              >lånebeløb</a
            >, som du kan låne, direkte af din indkomst. Har du en høj indkomst, kan du
            naturligvis låne et højere beløb. Det er dog ikke udelukkende din indkomst,
            der definerer dit maksimale beløb – uanset hvilken lånetype, der er tale om.
          </p>
          <p>
            Vær dog opmærksom på, at det ikke er ensbetydende med, at du skal have et job.
            Din indkomst kan derfor sagtens være i form af <strong>SU</strong>,
            <strong>kontanthjælp</strong>, <strong>dagpenge </strong>mv. Enkelte långivere
            kræver dog et fast arbejde, om end det ofte er i forbindelse med større lån.
          </p>
          <h3>Din status i RKI og Debitor Registret</h3>
          <p>
            Er du registreret som dårlig betaler i RKI, er der meget lille sandsynlighed
            for at optage et lån – heller ikke i tilfælde af, at det er et
            <a
              href="https://saverium.dk/laan-penge/minilaan"
              rel="noopener noreferrer"
              target="_blank"
              >minilån</a
            >
            på 1.000 kr. En registrering i RKI (Ribers Kredit Information) er en direkte
            afvisning hos langt de fleste långivere og dermed er der ikke grundlag for at
            indhente lånetilbud eller sammenligne lån.
          </p>
          <p>
            Skal du gøre dig forhåbninger om et lån, er du derfor nødt til at få slettet
            din registrering, hvilket kun kan lade sig gøre på to måder:
          </p>
          <ol>
            <li>Du kan betale den gæld, der har resulteret i din registrering</li>
            <li>Du kan vente i fem år, hvorefter din registrering automatisk slettes</li>
          </ol>
          <p>
            I denne sammenhæng er det dog vigtigt, at du er opmærksom på, at det ikke er
            ensbetydende med, at din gæld slettes, hvis du vælger løsning nr. to. Derimod
            betyder det blot, at din registrering i RKI fjernes. Derfor rådes du
            selvfølgelig også til at vælge løsning nr. et. Det medfører ikke blot, at din
            registrering fjernes, men også, at din gæld er ude af verden.
          </p>
          <h3>Dit rådighedsbeløb</h3>
          <p>
            Til trods for en høj indkomst, er du ikke garanteret at kunne få bevilliget et
            stort lån. Dit rådighedsbeløb er nemlig også afgørende. Det er nemlig det, som
            danner grundlag for, hvorvidt du har råd til tilbagebetalingen af dit lån.
          </p>
          <p>
            I rådighedsbeløbet tages der også højde for din eksisterende gæld. Derfor er
            dét ofte også noget, du vil blive bedt om at oplyse, når du ansøger om om at
            få et lånetilbud. At der tages højde for dette, er dog udelukkende en fordel.
            Det sikrer dig mod, at du optager et lån, som du ikke har råd til. Angiv
            derfor altid korrekt tal, når du skal oplyse om nuværende gæld, rådighedsbeløb
            mv. Det er nemlig lige så høj grad værd at oplyse for din egen skyld.
          </p>
        </div>

        <div class="text-container">
          <h2 id="saadan-foroeger-du-dine-chancer-for-at-blive-godkendt">
            Sådan forøger du dine chancer for at blive godkendt
          </h2>
          <p>
            Når du skal sammenligne lån eller indhente lånetilbud, handler det ikke blot
            om at finde den bedste bank i Danmark. Det handler også om at give sig selv de
            bedst mulige forudsætninger for at få bevilliget et lån. Du kan forøge dine
            chancer for at blive godkendt til et lån på følgende tre måder.
          </p>
          <h3>Få en medansøger på dit lån</h3>
          <p>
            Er I to om at ansøge om lånet, kan långiverne være mere villige til at
            godkende <strong>låneansøgningen</strong>. Ligeledes vil de ofte også sænke
            renterne på lånet, da I er to om at dele betalingen. Det er en fordel for
            långiver, da det minimerer risikoen for, at pengene ikke tilbagebetales.
          </p>
          <p>
            Sandsynligheden for at du/I bliver godkendt, når I ansøger sammen er større.
            Dermed er sandsynligheden for, at I overhovedet kan sammenligne lån også
            størst, hvis I ansøger sammen, da risikoen for at modtage ét eller nul
            lånetilbud er meget mindre, og der dermed ikke er noget
            sammenligningsgrundlag.
          </p>
          <h3>Forlæng lånets løbetid</h3>
          <p>
            At forlænge lånets løbetid kan ligeledes have indflydelse på, hvorvidt du
            bliver godkendt eller ej. Det vil resultere i, at den månedlig ydelse bliver
            mindre, hvorfor tilbagebetalingen bliver mere overskuelig. Dog vil det også
            betyde, at de totale omkostninger stiger.
          </p>
          <h3>Afdrag på lånet via Betalingsservice</h3>
          <p>
            Der er flere fordele ved at tilmelde din betaling til Betalingsservice. Du
            undgår nemlig ikke blot omkostninger til girokort og administration, men øger
            også chancerne for, at långiver godkender din ansøgning, hvis du accepterer at
            betaling sker via Betalingsservice, da du på den måde ikke hver måned skal
            tage stilling til, om du skal betale din månedlige ydelse.
          </p>
        </div>

        <div class="text-container">
          <h2 id="skal-vi-hjaelpe-dig-med-at-indhente-laanetilbud">
            Skal vi hjælpe dig med at indhente lånetilbud?
          </h2>
          <p>
            Hvis du har læst al den information vi har samlet om at indhente lånetilbud
            fra flere banker og sammenligne priser på lån, bør du være godt rustet, til at
            finde og vælge det bedste lånetilbud. Vi håber at informationen var til at
            forstå og at du har lyst til at prøve vores lånesammenligningstjeneste, så vi
            kan indhente lånetilbud til dig, og du dermed kan finde det bedste lånetilbud
            til netop dig.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },

  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
