import MainView from '@/views/saverium_dk/MainView'
import LoanView from '@/views/saverium_dk/LoanView'
import OurPartnersView from '@/views/saverium_dk/OurPartnersView'
import FaqView from '@/views/saverium_dk/FaqView'
import LoginView from '@/views/LoginView'
import ResetPasswordView from '@/views/ResetPasswordView'
import SetPasswordView from '@/views/SetPasswordView'
import MyAccountView from '@/views/MyAccountView'
import LogoutView from '@/views/LogoutView'
import TosView from '@/views/saverium_dk/TosView'
import PrivacyPolicyView from '@/views/saverium_dk/PrivacyPolicyView'
import routeUtils from '@/router/routeUtils'
import TokenLoginView from '@/views/TokenLoginView'
import ArticleMenuView from '@/views/ArticleMenuView'
import StoryArticle from '@/views/saverium_dk/articles/StoryArticle'
import NewInterestRate from '@/views/saverium_dk/articles/NewInterestRate'
import ConsolidationLoan from '@/views/saverium_dk/articles/LoanConsolidation'
import CheaperLoan from '@/views/saverium_dk/articles/CheaperLoan'
import NeedALoan from '@/views/saverium_dk/articles/NeedALoan'
import CancelDraftView from '@/views/CancelDraftView'
import BetterFinancials from '@/views/saverium_dk/articles/BetterFinancials'
import Creditworthiness from '@/views/saverium_dk/articles/Creditworthiness'
import BoatLoan from '@/views/saverium_dk/articles/BoatLoan'
import CarLoan from '@/views/saverium_dk/articles/CarLoan'
import CheapLoan from '@/views/saverium_dk/articles/CheapLoan'
import ConsumerLoans from '@/views/saverium_dk/articles/ConsumerLoans'
import DynamicLoanPage from '@/views/saverium_dk/articles/DynamicLoanPage'
import Overdraft from '@/views/saverium_dk/articles/Overdraft'
import QuickLoan from '@/views/saverium_dk/articles/QuickLoan'
import Loan25000Kronor from '@/views/saverium_dk/articles/Loan25000Kronor'
import Loan50000Kronor from '@/views/saverium_dk/articles/Loan50000Kronor'
import Loan100000Kronor from '@/views/saverium_dk/articles/Loan100000Kronor'
import LoansWithLowRate from '@/views/saverium_dk/articles/LoansWithLowRate'
import LoanImmediately from '@/views/saverium_dk/articles/LoanImmediately'
import UnsecuredLoans from '@/views/saverium_dk/articles/UnsecuredLoans'
import LoanCalculator from '@/views/saverium_dk/articles/LoanCalculator'
import LoanOptions from '@/views/saverium_dk/articles/LoanOptions'
import LoanOffers from '@/views/saverium_dk/articles/LoanOffers'
import MCLoan from '@/views/saverium_dk/articles/MCLoan'
import NewLoan from '@/views/saverium_dk/articles/NewLoan'
import PrivateLoans from '@/views/saverium_dk/articles/PrivateLoans'
import SmeLandingPage from '@/views/saverium_dk/SmeLandingPageView'
import HowCanICombineLoan from '@/views/saverium_dk/articles/HowCanICombineLoan'
import ResursBankLanding from '@/views/saverium_dk/articles/ResursBankLanding'
import ExpressBankLanding from '@/views/saverium_dk/articles/ExpressBankLanding'
import BasisBankLanding from '@/views/saverium_dk/articles/BasisBankLanding'
import ComparingHelps from '@/views/saverium_dk/articles/ComparingHelps'
import GetYourChristmasFinances from '@/views/saverium_dk/articles/GetYourChristmasFinances'
import FerratumLanding from '@/views/saverium_dk/articles/FerratumLanding'
import UnilanLanding from '@/views/saverium_dk/articles/UnilanLanding'
import RenovatingUnderPandemic from '@/views/saverium_dk/articles/RenovatingUnderPandemic'
import FromNemIDToMitID from '@/views/saverium_dk/articles/FromNemIDToMitID'
import SmeTosView from '@/views/saverium_dk/SmeTosView.vue'
import ModusLanding from '@/views/saverium_dk/articles/ModusLanding'
import HowEasyIsTakingOutLoan from '@/views/saverium_dk/articles/HowEasyIsTakingOutLoan'
import HowMuchMoneyIn2022 from '@/views/saverium_dk/articles/HowMuchMoneyIn2022'
import FellowBankLanding from '@/views/saverium_dk/articles/FellowBankLanding'
import LoanForAllSizes from '@/views/saverium_dk/articles/LoanForAllSizes'
import BusinessLoan from '@/views/saverium_dk/articles/BusinessLoan'
import MiniLoan from '@/views/saverium_dk/articles/MiniLoan'
import LoanForScooter from '@/views/saverium_dk/articles/LoanForScooter'
import LoanForTractor from '@/views/saverium_dk/articles/LoanForTractor'
import HomeLoanLoans from '@/views/saverium_dk/articles/HomeLoanLoans'
import LoansForEveryone from '@/views/saverium_dk/articles/LoansForEveryone'
import Mortgage from '@/views/saverium_dk/articles/Mortgage'
import LoanProviders from '@/views/saverium_dk/articles/LoanProviders'
import CompareLoanOffers from '@/views/saverium_dk/articles/CompareLoanOffers'
import StudentLoan from '@/views/saverium_dk/articles/StudentLoan'
import LoanViewSalus from '@/views/saverium_dk/LoanViewSalus'
import LoanForMoving from '@/views/saverium_dk/articles/LoanForMoving'
import BorrowMoney from '@/views/saverium_dk/articles/BorrowMoney'
import OnlineLoan from '@/views/saverium_dk/articles/OnlineLoan'
import QuickerLoan from '@/views/saverium_dk/articles/QuickerLoan'
import MobileLoans from '@/views/saverium_dk/articles/MobileLoans'
import LoansForVetBills from '@/views/saverium_dk/articles/LoansForVetBills'
import LoansForAfterSchool from '@/views/saverium_dk/articles/LoansForAfterSchool'
import LoanForHorse from '@/views/saverium_dk/articles/LoanForHorse'
import LoansForBills from '@/views/saverium_dk/articles/LoansForBills'
import LoanForTv from '@/views/saverium_dk/articles/LoanForTv'
import LoanForBicycle from '@/views/saverium_dk/articles/LoanForBicycle'
import LoanForTravel from '@/views/saverium_dk/articles/LoanForTravel'
import LoanForGarden from '@/views/saverium_dk/articles/LoanForGarden'
import LoanForFurniture from '@/views/saverium_dk/articles/LoanForFurniture'
import LoanForComputer from '@/views/saverium_dk/articles/LoanForComputer'
import LoanForWedding from '@/views/saverium_dk/articles/LoanForWedding'
import LoanForDriversLicence from '@/views/saverium_dk/articles/LoanForDriversLicence'
import LoanForDentist from '@/views/saverium_dk/articles/LoanForDentist'
import LoanForGroceries from '@/views/saverium_dk/articles/LoanForGroceries'
import LoanForRent from '@/views/saverium_dk/articles/LoanForRent'
import WorkshopInvoiceInstallments from '@/views/saverium_dk/articles/WorkshopInvoiceInstallments'
import LoanForPlasticSurgery from '@/views/saverium_dk/articles/LoanForPlasticSurgery'
import LoanForEducation from '@/views/saverium_dk/articles/LoanForEducation'
import LoanForInvesting from '@/views/saverium_dk/articles/LoanForInvesting'
// Translations for paths, will be used as link urls

const localizedPaths = {
  MainView: '/',
  LoanView: '/ansoegning/',
  OurPartnersView: '/vores-partnere/',
  FaqView: '/ofte-stillede-spoergsmaal/',
  LoginView: '/login/',
  ResetPasswordView: '/reset_password/',
  SetPasswordView: '/set_password/:pk/:key/:ts/',
  MyAccountView: '/konto/',
  MyAccountTokenLoginView: '/account/:token/',
  LogoutView: '/logout/',
  TosView: '/tjenestevilkar/',
  PrivacyPolicyView: '/persondatapolitik/',
  SmeTosView: '/tjenestevilkar-erhverslan/',
  ArticleMenuView: '/artikler/',
  ConsolidationLoan: '/laan-penge/samlelaan/',
  StoryArticle: '/artikler/tage-kontrol-over-din-okonomi/',
  NewInterestRate: '/artikler/ny-lov-begraenser-renten-pa-lan-samle-dine-gamle-lan/',
  CheaperLoan: '/artikler/billigere-lan-online/',
  NeedALoan: '/artikler/har-du-brug-for-et-lan/',
  CancelDraftView: '/cancel/:draft_uuid',
  BetterFinancials:
    '/artikler/bedre-okonomiske-beslutninger-foretag-ikke-disse-fejl-igen/',
  ContinueFillingView: '/ansoegning/:uuid',
  Creditworthiness: '/artikler/kreditvaerdighed-disse-5-ting-pavirker-om-du-far-et-lan/',
  BoatLoan: '/laan-penge/forbrugslaan/baad/',
  CarLoan: '/laan-penge/billaan/',
  CheapLoan: '/billige-lan/',
  ConsumerLoans: '/laan-penge/forbrugslaan/',
  DynamicLoanPage: '/laan-penge/beloeb/:loanamount-kr//',
  Overdraft: '/laan-penge/kassekredit/',
  QuickLoan: '/laan-penge/kviklaan/',
  Loan25000Kronor: '/laan-penge/beloeb/25000-kr/',
  Loan50000Kronor: '/laan-penge/beloeb/50000-kr/',
  Loan100000Kronor: '/laan-penge/beloeb/100000-kr/',
  LoansWithLowRate: '/lan-med-fast-lav-rente/',
  LoanImmediately: '/lan-penge-akut/',
  UnsecuredLoans: '/laan-penge/privatlaan/uden-sikkerhed/',
  LoanCalculator: '/laan-penge/privatlaan/laaneberegner/',
  LoanOptions: '/laan-penge/bolig/laanemuligheder/',
  LoanOffers: '/lanetilbud/',
  MCLoan: '/laan-penge/billaan/motorcykel/',
  NewLoan: '/nye-lan/',
  PrivateLoans: '/laan-penge/privatlaan/',
  SmeLandingPage: '/erhvervslan/',
  HowCanICombineLoan:
    '/artikler/kan-jeg-kombinere-nogle-af-mine-lan-hvorfor-delvis-betaling-af-gaeld-er-det-hele-vaerd/',
  ResursBankLanding: '/laan-penge/laaneudbydere/resurs-bank/',
  ExpressBankLanding: '/laan-penge/laaneudbydere/express-bank/',
  BasisBankLanding: '/laan-penge/laaneudbydere/basisbank/',
  ComparingHelps: '/artikler/at-sammenligne-hjaelper-os-med-at-traeffe-det-rette-valg/',
  GetYourChristmasFinances: '/laan-penge/forbrugslaan/jul/',
  FerratumLanding: '/laan-penge/laaneudbydere/ferratum/',
  UnilanLanding: '/laan-penge/laaneudbydere/unilaan/',
  RenovatingUnderPandemic: '/artikler/renovering-af-hus-og-hjem-under-pandemien/',
  FromNemIDToMitID: '/laan-penge/privatlaan/laan-med-mitid/',
  ModusLanding: '/laan-penge/laaneudbydere/modus-finans/',
  HowEasyIsTakingOutLoan: '/artikler/hvor-nemt-er-det-at-optage-et-lan/',
  HowMuchMoneyIn2022: '/artikler/hvad-skal-dit-radighedsbelob-vaere-i-2022/',
  FellowBankLanding: '/laan-penge/laaneudbydere/fellow-bank/',
  LoanForAllSizes: '/laan-penge/beloeb/',
  BusinessLoan: '/laan-penge/erhvervslaan/',
  MiniLoan: '/laan-penge/minilaan/',
  LoanForScooter: '/laan-penge/forbrugslaan/scooter/',
  LoanForTractor: '/laan-penge/forbrugslaan/traktor/',
  HomeLoanLoans: '/laan-penge/forbrugslaan/haarde-hvidevarer/',
  LoansForEveryone: '/laan-penge/laan-til-alle/',
  Mortgage: '/laan-penge/bolig/',
  LoanProviders: '/laan-penge/laaneudbydere/',
  CompareLoanOffers: '/laan-penge/sammenligning/',
  StudentLoan: '/laan-penge/laan-til-alle/studerende/',
  LoanViewSalus: '/ansoegning2/',
  LoanForMoving: '/laan-penge/forbrugslaan/flytning/',
  BorrowMoney: '/laan-penge/',
  OnlineLoan: '/laan-penge/online-laan/',
  QuickerLoan: '/laan-penge/hurtiglaan/',
  MobileLoans: '/laan-penge/privatlaan/mobillaan/',
  LoansForVetBills: '/laan-penge/forbrugslaan/dyrlaage/',
  LoansForAfterSchool: '/laan-penge/forbrugslaan/efterskole/',
  LoanForHorse: '/laan-penge/forbrugslaan/hest/',
  LoansForBills: '/laan-penge/forbrugslaan/regninger/',
  LoanForTv: '/laan-penge/forbrugslaan/tv/',
  LoanForBicycle: '/laan-penge/forbrugslaan/cykel/',
  LoanForTravel: '/laan-penge/forbrugslaan/ferie/',
  LoanForGarden: '/laan-penge/forbrugslaan/have/',
  LoanForFurniture: '/laan-penge/forbrugslaan/moebler/',
  LoanForComputer: '/laan-penge/forbrugslaan/computer/',
  LoanForWedding: '/laan-penge/forbrugslaan/bryllup/',
  LoanForDriversLicence: '/laan-penge/forbrugslaan/koerekort/',
  LoanForDentist: '/laan-penge/forbrugslaan/tandlaage/',
  LoanForGroceries: '/laan-penge/forbrugslaan/mad/',
  LoanForRent: '/laan-penge/forbrugslaan/husleje',
  WorkshopInvoiceInstallments: '/laan-penge/forbrugslaan/vaarksted/',
  LoanForPlasticSurgery: '/laan-penge/forbrugslaan/plastikkirurgi/',
  LoanForEducation: '/laan-penge/forbrugslaan/uddannelse/',
  LoanForInvesting: '/laan-penge/forbrugslaan/investering/',
}

/**
 * This object defines the site specific routes. Meta object contains page meta info, and all needed props
 * (usually information needed for rich results, can be used for other props as well).
 * All other info is in string format except component, which is the component imported above and isPublic which is boolean.
 * @example
 *  {
    name: '',
    component: ,
    meta: {
      title: '',
      description: '',
      isPublic: true
    },
    props: {
      headline: '',
      datePublished: '',
      type: '',
      author: '',
      image: '',
      publisher: {
        type: '',
        name: '',
        url: '',
        logo: ''
      }
    }
  }
 */
const langRoutes = [
  {
    name: 'CancelDraftView',
    component: CancelDraftView,
    title: 'Saverium.dk | Enkel ansøgning om lån på 3.000-500.000 kr. online',
  },
  {
    name: 'ContinueFillingView',
    component: LoanViewSalus,
    alias: '/application/draft/:uuid/',
    meta: {
      description:
        'Udfyld en låneansøgning gratis, og du får en afgørelse med det samme. Sammenlign 3.000 - 500.000 kr. lånetilbud fra flere banker og pengeinstitutter ved hjælp af Saverium.dk.',
      title: 'Saverium.dk | Enkel ansøgning om lån på 3.000-500.000 kr. online',
    },
  },
  {
    name: 'MainView',
    component: MainView,
    meta: {
      isPublic: true,
      title: 'Saverium.dk | Enkel ansøgning om lån på 3.000-500.000 kr. online',
      description:
        'Saverium.dk giver dig mulighed for at ansøge om et lån fra flere banker i én ansøgning. Din ansøgning behandles med det samme, og du modtager pengene hurtigt på din konto.',
    },
  },
  {
    name: 'LoanViewSalus',
    component: LoanViewSalus,
    meta: {
      isPublic: true,
      title: 'Ansøg om et lån og få afgørelsen med det samme | Saverium.dk',
      description:
        'Udfyld en låneansøgning gratis, og du får en afgørelse med det samme. Sammenlign 3.000 - 500.000 kr. lånetilbud fra flere banker og pengeinstitutter ved hjælp af Saverium.dk.',
    },
  },
  {
    name: 'LoanView',
    component: LoanView,
    redirect: to => {
      return {
        path: '/',
      }
    },
    meta: {
      isPublic: true,
      title: 'Ansøg om et lån og få afgørelsen med det samme | Saverium.dk',
      description:
        'Udfyld en låneansøgning gratis, og du får en afgørelse med det samme. Sammenlign 3.000 - 500.000 kr. lånetilbud fra flere banker og pengeinstitutter ved hjælp af Saverium.dk.',
    },
  },
  {
    name: 'OurPartnersView',
    component: OurPartnersView,
    meta: {
      title: 'Vores partnere | Saverium.dk',
      isPublic: true,
      description:
        'Vi arbejder i samarbejde med troværdige banker og pengeinstitutter. Saverium.dk hjælper dig med at sammenligne lån fra flere banker og långivere op til 500.000 kr.',
    },
  },
  {
    name: 'FaqView',
    component: FaqView,
    meta: {
      title: 'Ofte stillede spørgsmål | Saverium.dk',
      isPublic: true,
      description:
        'Find svar på ofte stillede spørgsmål omkring din låneansøgning, sammenligning af lån på Saverium.dk. Vi hos Saverium.dk hjælper dig med at få lånetilbud fra flere banker med kun en ansøgning.',
    },
  },
  {
    name: 'LoginView',
    component: LoginView,
    meta: {
      title: 'Log ind - Saverium.dk',
      isPublic: true,
    },
  },
  {
    name: 'ResetPasswordView',
    component: ResetPasswordView,
    meta: {
      title: 'Nulstil adgangskode - Saverium.dk',
      isPublic: true,
    },
  },
  {
    name: 'SetPasswordView',
    component: SetPasswordView,
    meta: {
      title: 'Indstil adgangskode - Saverium.dk',
      isPublic: true,
    },
  },
  {
    name: 'MyAccountView',
    component: MyAccountView,
    meta: {
      title: 'Min konto - Saverium.dk',
      requireLogin: true,
      description:
        'Saverium.dk giver dig mulighed for at ansøge om et lån fra flere banker i én ansøgning. Din ansøgning behandles med det samme, og du modtager pengene hurtigt på din konto.',
    },
  },
  {
    name: 'LogoutView',
    component: LogoutView,
    meta: {
      title: 'Saverium.dk',
      isPublic: true,
    },
  },
  {
    name: 'TosView',
    component: TosView,
    meta: {
      title: 'Tjenestevilkår | Saverium.dk',
      isPublic: true,
      description:
        'Disse tjenestevilkår (“Tjenestevilkårene”) er gældende for den online formidling af forbrugslån, der tilbydes af LVS Brokers Oy, et selskab med hjemsted i Finland, PL 644, FI- 00101 Helsinki, Finland (“LVS Brokers”), gennem LVS Brokers’ hjemmeside, https://www.saverium.dk (hjemmesiden og relaterede tjenester herefter under ét benævnt “Saverium.dk”).',
    },
  },
  {
    name: 'SmeTosView',
    component: SmeTosView,
    meta: {
      title: 'Tjenestevilkår (Erhvervslån) | Saverium.dk',
      isPublic: true,
      description:
        'Disse tjenestevilkår (“Tjenestevilkårene”) er gældende for den online formidling af forbrugslån, der tilbydes af LVS Brokers Oy, et selskab med hjemsted i Finland, PL 644, FI- 00101 Helsinki, Finland (“LVS Brokers”), gennem LVS Brokers’ hjemmeside, https://www.saverium.dk (hjemmesiden og relaterede tjenester herefter under ét benævnt “Saverium.dk”).',
    },
  },
  {
    name: 'PrivacyPolicyView',
    component: PrivacyPolicyView,
    meta: {
      title: 'Persondatapolitik | Saverium.dk',
      isPublic: true,
      description:
        'Denne Persondatapolitik regulerer, hvordan LVS Brokers Oy ("LVS Brokers" eller "vi") behandler personoplysninger, der indsamles fra brugerne ("Bruger" eller "du") af de tjenester, som LVS Brokers stiller til rådighed på hjemmesiden Saverium.dk, til brug for sammenligning af forbrugslån, samt fra de øvrige brugere af hjemmesiden Saverium.dk (hjemmeside og tjenester samlet benævnt "Saverium.dk").',
    },
  },

  {
    name: 'ArticleMenuView',
    component: ArticleMenuView,
    meta: {
      title: 'Artikler | Saverium.dk',
      isPublic: true,
      description:
        'I artikelsektionen kan du finde nyttige oplysninger om lån og praktiske tip til styring af din personlige økonomi. Ved hjælp af Saverium.dk kan du sammenligne lån op til 500.000 kr.',
    },
  },
  {
    name: 'ConsolidationLoan',
    component: ConsolidationLoan,
    meta: {
      title: 'Få et billigt samlelån her - spar penge ved at samle dine lån',

      isPublic: true,
      description:
        'Indhent tilbud på samlelån fra mange banker ✓ Ansøg online ✓ få svar hurtigt  ✓ find de bedste samlelån med lav rente ✓ hurtigt og høj godkendelse ✓ ',
    },
    props: {
      headline: 'Få et billigt samlelån her - spar penge ved at samle dine lån',
      datePublished: '2020-09-14',
      dateModified: '2022-06-28',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'StoryArticle',
    component: StoryArticle,
    meta: {
      title:
        '“At havne i en farlig cirkel af gæld kan ske for enhver” - Læs Anne-Katrins historie om at tage kontrol over sin økonomi',

      isPublic: true,
      description:
        'Hvis du betaler store beløb på forskellige lån månedligt, kan du overveje at kombinere lånene. Opsummerer al udestående gæld på afdrag og kreditkortregninger, gå til Saverium.dk hjemmesiden og indtast det lånebeløb, der svarer til din gæld. Ved at ændre låneperioden kan du justere, hvilken månedlig rate, der passer til din personlige økonomi.',
      img: '/static/img/saverium_dk/article-imgs/anne-katrin.jpg',
    },
    props: {
      headline:
        '“At havne i en farlig cirkel af gæld kan ske for enhver” - Læs Anne-Katrins historie om at tage kontrol over sin økonomi',
      datePublished: '2020-09-14',
      type: 'Article',
      author: 'Søren',
      image: '/static/img/saverium_dk/article-imgs/anne-katrin.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'NewInterestRate',
    component: NewInterestRate,
    meta: {
      title: 'Ny lov begrænser renten på lån - Samle dine gamle lån',
      isPublic: true,
      description:
        'En ny lov, der begrænser renten på lån trådte i kraft i Danmark her til sommer. Den nye lovgivning gælder ikke automatisk på gamle lån, så hvis du har optaget et lån inden 1. Juli 2020, skal du tage skridtet imod at erstatte dine gamle dyre lån med et lån, der falder ind under den nye lovgivning. Ved at samle dine gamle dyre lån til et lån med en lavere rente kan du spare flere tusindvis af kroner hver måned.',
      img: '/static/img/saverium_dk/article-imgs/money-finger.jpg',
    },
    props: {
      headline: 'Ny lov begrænser renten på lån - Samle dine gamle lån',
      datePublished: '2020-09-14',
      type: 'Article',
      author: 'Søren',
      image: '/static/img/saverium_dk/article-imgs/money-finger.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'CheaperLoan',
    component: CheaperLoan,
    meta: {
      title: 'Billigere lån online? Digitale banker er også kommet til Danmark',
      isPublic: true,
      description:
        'Da forbrugeradfærden ændrer sig fra fysiske interaktioner til den digitale verden, er det i løbet af de sidste par år blevet mere og mere populært for danskere at søge lån online i stedet for at besøge deres bankkontor. Disse moderne digitale banker, der kun opererer online, har hurtigt hævet deres popularitet selv til niveauet for traditionelle danske banker. Typiske tjenester, de tilbyder i Danmark, inkluderer f.eks. forbrugerlån, samlelån og kreditkort.',
      img: '/static/img/saverium_dk/article-imgs/hand-money.jpg',
    },
    props: {
      headline: 'Ny lov begrænser renten på lån - Samle dine gamle lån',
      datePublished: '2020-09-14',
      type: 'Article',
      author: 'Søren',
      image: '/static/img/saverium_dk/article-imgs/hand-money.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'MyAccountTokenLoginView',
    component: TokenLoginView,
    meta: {
      title: 'Min konto - Saverium.dk',
      isPublic: true,
    },
  },
  {
    name: 'NeedALoan',
    component: NeedALoan,
    meta: {
      title:
        'Hvor meget kan jeg få udbetalt ved at optage et lån? – Og er det en god ide?',
      isPublic: true,
      description:
        'Hvor mange penge du kan låne når du ansøger om det online, afhænger meget af hvor du ansøger om lånet, til hvilket formål og hvilket beløb du selv har tænkt på forhånd. Lånets størrelse bestemmes af de banker og finansieringsselskaber, der tilbyder lånene. Forskellige banker og pengeinstitutter tilbyder lån i forskellige størrelser, nogle mindre og andre større.',
      img: '/static/img/saverium_dk/article-imgs/sad-couple.jpg',
    },
    props: {
      headline:
        'Hvor meget kan jeg få udbetalt ved at optage et lån? – Og er det en god ide?',
      datePublished: '2020-10-06',
      type: 'Article',
      author: 'Søren',
      image: '/static/img/saverium_dk/article-imgs/sad-couple.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'BetterFinancials',
    component: BetterFinancials,
    meta: {
      title: 'Bedre økonomiske beslutninger - Foretag ikke disse fejl igen',
      isPublic: true,
      description:
        'Bedre økonomiske beslutninger ligger kun bag et par små ændringer. Disse tip hjælper dig lettere med at slippe af med høje renter og dårlige beslutninger.',
      img: '/static/img/saverium_dk/article-imgs/better-financial-desicions.jpg',
    },
    props: {
      headline: 'Bedre økonomiske beslutninger - Foretag ikke disse fejl igen',
      datePublished: '2020-11-19',
      type: 'Article',
      author: 'Søren',
      image: '/static/img/saverium_dk/article-imgs/better-financial-desicions.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'Creditworthiness',
    component: Creditworthiness,
    meta: {
      title: 'Kreditværdighed - Disse 5 ting påvirker, om du får et lån',
      isPublic: true,
      description:
        'Påvirker din kreditværdighed din låneansøgning? Læs her 5 grunde til, at du muligvis ikke får et lån fra en bank og hvordan du kan ordne det.',
      img: '/static/img/saverium_dk/article-imgs/creditworthiness.jpg',
    },
    props: {
      headline: 'Kreditværdighed - Disse 5 ting påvirker, om du får et lån',
      datePublished: '2020-12-22',
      type: 'Article',
      author: 'Søren',
      image: '/static/img/saverium_dk/article-imgs/creditworthiness.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'BoatLoan',
    component: BoatLoan,
    meta: {
      title:
        'Bådlån og finansiering - Bådlån og finansiering - Hvad koster det at køb 1 båd? | Saverium',
      isPublic: true,
      description:
        'Sammenligning af bådlån og hvilke krav stilles der, så du kan få penge til din drømme båd. Beregn prisen på lån til båd hos Saverium.dk',
    },
    props: {
      headline:
        'Bådlån og finansiering - Bådlån og finansiering - Hvad koster det at køb 1 båd? | Saverium',
      type: 'Article',
      datePublished: '2021-02-10',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'CarLoan',
    component: CarLoan,
    meta: {
      title: 'Find Det Billigste Billån - Ansøg Online Om Lån Til Bil',
      isPublic: true,
      description:
        'Sammenlign billån fra flere udbydere ✓ Ansøg og få svar på kort tid ✓ 100% online ansøgning og helt uforpligtende ✓ Lån til bil uden besvær → Ansøg Her',
    },
    props: {
      headline: 'Find Det Billigste Billån - Ansøg Online Om Lån Til Bil',
      datePublished: '2021-02-10',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'CheapLoan',
    component: CheapLoan,
    meta: {
      title: 'Billige lån - Find hurtigt det billigste lån på nettet | Saverium',
      isPublic: true,
      description:
        'Lån penge hurtig til fast lav rente uden sikkerhed på nettet. Sammenlign billige lånetilbud så du hurtig kan få penge på din konto igennem Saverium',
    },
    props: {
      headline: 'Billån - Lån penge til køb af ny eller brugt bil | Saverium',
      datePublished: '2021-02-10',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'ConsumerLoans',
    component: ConsumerLoans,
    meta: {
      title: 'Sammenlign forbrugslån her - find de bedste og billigste',
      isPublic: true,
      description:
        'Sammenlign forbrugslån uden sikkerhed ✓ Ansøg online ✓ få svar hurtigt  ✓ Find de billigste og bedste tilbud med lav rente blandt mange banker ✓',
    },
    props: {
      headline: 'Sammenlign forbrugslån her - find de bedste og billigste',
      datePublished: '2022-06-28',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'DynamicLoanPage',
    component: DynamicLoanPage,
    meta: {
      title: 'Saverium.dk | Enkel ansøgning om lån på 3.000-500.000 kr. online',
      isPublic: true,
      description:
        'Lån nemt og hurtig online uden sikkerhed eller garanti. Sammenlign tilbud igennem Saverium.dk for at få det bedste tilbud.',
    },
    props: true,
  },
  {
    name: 'Overdraft',
    component: Overdraft,
    meta: {
      title: 'Find billig kassekredit online, hurtigt og nemt - sammenlign her',
      isPublic: true,
      description:
        'Sammenlign kassekreditter fra flere udbydere ✓ Ansøg online og få svar hurtigt ✓ 100% uforpligtende ✓ Find de laveste renter og bedste tilbud ✓',
    },
    props: {
      headline: 'Find billig kassekredit online, hurtigt og nemt - sammenlign her',
      datePublished: '2021-02-10',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'QuickLoan',
    component: QuickLoan,
    meta: {
      title: 'Kviklån - sammenlign de bedste og billigste kviklån her',
      isPublic: true,
      description:
        'Sammenlign kviklån fra mange udbydere ✓ Hurtigt online ansøgning ✓ Svar med det samme ✓ Lav rente uden at stille sikkerhed ✓ Høj godkendelse ✓',
    },
    props: {
      headline: 'Kviklån - sammenlign de bedste og billigste kviklån her',
      datePublished: '2021-02-10',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'Loan25000Kronor',
    component: Loan25000Kronor,
    meta: {
      title: 'Lån hurtig 25000 kr. Forkæl dig selv eller familien | Saverium',
      isPublic: true,
      description:
        'Få hurtig 2500 kr på din konto. Gratis låneansøgning. Sammenlign lån så du får det bedste tilbud igennem Saverium.dk',
    },
    props: {
      headline: 'Lån hurtig 25000 kr. Forkæl dig selv eller familien | Saverium',
      datePublished: '2021-02-10',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'Loan50000Kronor',
    component: Loan50000Kronor,
    meta: {
      title: 'Lån hurtig 50000 kr. Forkæl dig selv eller familien | Saverium',
      isPublic: true,
      description:
        'Få hurtig 50.000 kr. på din konto. Gratis låneansøgning. Sammenlign lån så du får det bedste tilbud igennem Saverium.dk.',
    },
    props: {
      headline: 'Lån hurtig 50000 kr. Forkæl dig selv eller familien | Saverium',
      datePublished: '2021-02-10',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'Loan100000Kronor',
    component: Loan100000Kronor,
    meta: {
      title: 'Lån hurtig 100000 kr. Køb ny bil eller renovere boligen | Saverium',
      isPublic: true,
      description:
        'Få hurtig 100.000 kr. på din konto. Gratis låneansøgning. Sammenlign lån så du får det bedste tilbud igennem Saverium.dk.',
    },
    props: {
      headline: 'Lån hurtig 100000 kr. Køb ny bil eller renovere boligen | Saverium',
      datePublished: '2021-02-10',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoansWithLowRate',
    component: LoansWithLowRate,
    meta: {
      title: 'Lån med lav rente - Spar på dit lån med fast lav rente | Saverium',
      isPublic: true,
      description:
        'Få et uforpligtende lånetilbud med fast lav rente på dit forbrugslån/ så du spare flere penge til i din hverdag. Lån alt fra 3000 kr. - 500.000 kr.',
    },
    props: {
      headline: 'Lån med lav rente - Spar på dit lån med fast lav rente | Saverium',
      datePublished: '2021-02-10',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanImmediately',
    component: LoanImmediately,
    meta: {
      title: 'Lån hurtig penge - Få udbetalt lånet indenfor 24 timer | Saverium',
      isPublic: true,
      description:
        'Lån penge online billig med fast lav rente. Få pengene på din konto hurtig uden sikkerhed på internettet igennem Saverium.dk',
    },
    props: {
      headline: 'Lån hurtig penge - Få udbetalt lånet indenfor 24 timer | Saverium',
      datePublished: '2021-02-10',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'UnsecuredLoans',
    component: UnsecuredLoans,
    meta: {
      title: 'Få lån uden sikkerhed - Pengene udbetalt på 24 timer | Saverium',
      isPublic: true,
      description:
        'Lån nemt og hurtig online uden sikkerhed eller garanti. Sammenlign tilbud igennem Saverium.dk for at få det bedste tilbud.',
    },
    props: {
      headline: 'Få lån uden sikkerhed - Pengene udbetalt på 24 timer | Saverium',
      datePublished: '2021-02-10',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanCalculator',
    component: LoanCalculator,
    meta: {
      title: 'Låneberegner - Hvad koster dit lån? | Saverium',
      isPublic: true,
      description:
        'Få overblik over låneomkostningerne, så du får det bedste lånetilbud som passer til din økonomi. Nemt og hurtigt igennem Saverium.dk',
    },
    props: {
      headline: 'Låneberegner - Hvad koster dit lån? | Saverium',
      datePublished: '2021-02-10',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanOptions',
    component: LoanOptions,
    meta: {
      title: 'Lånemuligheder - Hvor kan jeg få nemt et lån? | Saverium',
      isPublic: true,
      description:
        'Få hurtig og nemt et lån på internettet og se de forskellige muligheder for få det bedste tilbud til dig. Sammenlign dine muligheder igennem Saverium.dk',
    },
    props: {
      headline: 'Lånemuligheder - Hvor kan jeg få nemt et lån? | Saverium',
      datePublished: '2021-02-10',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanOffers',
    component: LoanOffers,
    meta: {
      title: 'Lånetilbud - Få flere tilbud med en ansøgning | Saverium',
      isPublic: true,
      description:
        'Få nemt et hav af lånetilbud sendt til dig og sammenlign dem så de passer til din økonomi. Igennem Saverium.dk er det hurtigt og overskueligt.',
    },
    props: {
      headline: 'Lånetilbud - Få flere tilbud med en ansøgning | Saverium',
      datePublished: '2021-02-10',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'MCLoan',
    component: MCLoan,
    meta: {
      title: 'Hurtig MC lån - Spar penge på dit motorcykel lån | Saverium',
      isPublic: true,
      description:
        'Lån penge til ny eller gammel motorcykel igennem Saverium.dk og få pengene indenfor 24 timer. Sammenlign de forskellige tilbud på et sted.',
    },
    props: {
      headline: 'Hurtig MC lån - Spar penge på dit motorcykel lån | Saverium',
      datePublished: '2021-02-10',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'NewLoan',
    component: NewLoan,
    meta: {
      title: 'Nyt lån - Få hurtig penge på nettet | Saverium',
      isPublic: true,
      description:
        'Få flere lånetilbud samlet på et sted igennem Saverium.dk med en enkel låneansøgning. Du får pengene på kontoen indenfor 24 timer.',
    },
    props: {
      headline: 'Nyt lån - Få hurtig penge på nettet | Saverium',
      datePublished: '2021-02-10',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'PrivateLoans',
    component: PrivateLoans,
    meta: {
      title: 'Sammenlign privatlån uden sikkerhed - Lån penge privat her',
      isPublic: true,
      description:
        'Sammenlign privatlån hvor du ikke skal stille sikkerhed ✓ Ansøg online og få svar hurtigt ✓ 100% uforpligtende ✓ Find de bedste tilbud blandt mange banker ✓',
    },
    props: {
      headline: 'Sammenlign privatlån uden sikkerhed - Lån penge privat her',
      datePublished: '2022-06-28',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'SmeLandingPage',
    component: SmeLandingPage,
    meta: {
      title: 'Saverium.dk | Nem ansøgning til erhvervslån op til 1.000.000 kr.',
      isPublic: true,
      description:
        'Saverium.dk hjælper dig med at finde det lån, der passer bedst til dig og din virksomhed. Vi indhenter lånetilbud fra flere låneudbydere, så du kan sammenligne dine lånetilbud samme sted. Din ansøgning bliver behandlet straks så du modtager pengene hurtigst muligt.',
    },
  },
  {
    name: 'HowCanICombineLoan',
    component: HowCanICombineLoan,
    meta: {
      title:
        'Kan jeg kombinere nogle af mine lån? - Hvorfor delvis betaling af gæld er det hele værd',
      description:
        'Fik du ikke det lånetilbud, du ønskede? Det er også værd at udnytte et mindre lånetilbud til at kombinere gamle lån. Læs her for tip til delvis lånekonsolidering',
      isPublic: true,
    },
    props: {
      headline:
        'Kan jeg kombinere nogle af mine lån? - Hvorfor delvis betaling af gæld er det hele værd',
      datePublished: '2021-06-09',
      type: 'Article',
      author: 'Søren',
      image: '/static/img/saverium_dk/article-imgs/how-can-i-combine-loan.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'ResursBankLanding',
    component: ResursBankLanding,
    meta: {
      title: 'Skal du have et lån hos Resurs Bank?',
      description:
        'Sammenlign lån fra Resurs Bank samt andre udbydere ✓ Nem online låneansøgning ✓ Ansøg gratis og uforpligtende ✓',
      isPublic: true,
    },
    props: {
      headline: 'Skal du have et lån hos Resurs Bank?',
      datePublished: '2021-09-30',
      dateModified: '2022-06-29',
      type: 'Article',
      author: '',
      image: '/static/img/saverium_dk/resurs-logo.svg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'ExpressBankLanding',
    component: ExpressBankLanding,
    meta: {
      title: 'Skal du have et lån fra Express Bank?',
      description:
        'Sammenlign lån til private fra Express Bank samt andre låneudbydere ✓ Uforpligtende online låneansøgning ✓ Ansøg gratis her ✓ ',
      isPublic: true,
    },
    props: {
      headline: 'Skal du have et lån fra Express Bank?',
      datePublished: '2021-10-18',
      type: 'Article',
      author: '',
      image: '/static/img/saverium_dk/expressbank-logo.webp',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'BasisBankLanding',
    component: BasisBankLanding,
    meta: {
      title: 'Basisbank lån 30.000 – 500.000 kr.',
      description:
        'Basisbank lån uden sikkerhed fra 30.000 - 500.000 kr. Udbetaling næste hverdag. Hurtig og gratis låneansøgning via Saverium.',
      isPublic: true,
    },
    props: {
      headline: 'Basisbank lån 30.000 – 500.000 kr.',
      datePublished: '2021-10-25',
      type: 'Article',
      author: 'Søren',
      image: '/static/img/saverium_dk/basisbank-logo-big.webp',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'ComparingHelps',
    component: ComparingHelps,
    meta: {
      title: 'At sammenligne hjælper os med at træffe det rette valg',
      description:
        'I dag sammenligner vi stort set alt der har en pris. Om det så er mælk nede ved den lokale købmand eller om det er et billigt billån man leder efter.',
      isPublic: true,
    },
    props: {
      headline: 'At sammenligne hjælper os med at træffe det rette valg',
      datePublished: '2021-11-29',
      type: 'Article',
      author: 'Søren',
      image: '/static/img/saverium_dk/compare-everything.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'GetYourChristmasFinances',
    component: GetYourChristmasFinances,
    meta: {
      title: 'Lån til julen og julegaverne - find det bedste julelån her',
      description:
        'Find det bedste julelån blandt mange banker ✓ Udfyld ansøgningen på få minutter ✓ undgå at betale mere for dit lån end højest nødvendigt ✓ ',
      isPublic: true,
    },
    props: {
      headline: 'Lån til julen og julegaverne - find det bedste julelån her',
      datePublished: '2022-06-28',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'FerratumLanding',
    component: FerratumLanding,
    meta: {
      title: 'Har du brug for et lån fra Ferratum Bank?',
      description:
        'Sammenlign lånetilbud fra Ferratum og flere udbydere ✓ Hurtig online låneansøgning ✓ Ansøg gratis online ✓ ',
      isPublic: true,
    },
    props: {
      headline: 'Har du brug for et lån fra Ferratum Bank?',
      datePublished: '2021-12-14',
      dateModified: '2022-06-29',
      type: 'Article',
      author: 'Søren',
      image: '/static/img/saverium_dk/ferratum-logo.svg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'UnilanLanding',
    component: UnilanLanding,
    meta: {
      title: 'Skal dit næste lån være hos Unilån?',
      description:
        'Sammenlign lånetilbud fra Unilån samt mange andre låneudbydere ✓ Uforpligtende online låneansøgning ✓ Ansøg her ✓ ',
      isPublic: true,
    },
    props: {
      headline: 'Skal dit næste lån være hos Unilån?',
      datePublished: '2022-01-14',
      dateModified: '2022-06-29',
      type: 'Article',
      author: '',
      image: '/static/img/saverium_dk/unilan-logo-small.webp',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'RenovatingUnderPandemic',
    component: RenovatingUnderPandemic,
    meta: {
      title: 'Renovering af hus og hjem under pandemien',
      description:
        'Unilån lån mellem 20.000 - 50.000 kr. Låneafgørelse med det samme via Saverium.',
      isPublic: true,
    },
    props: {
      headline: 'Renovering af hus og hjem under pandemien',
      datePublished: '2022-01-14',
      type: 'Article',
      author: 'Søren',
      image: '/static/img/saverium_dk/renovating-under-pandemic.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'FromNemIDToMitID',
    component: FromNemIDToMitID,
    meta: {
      title: 'Lån med MitID og NemID - Udbetaling i dag',
      description:
        'Sammenlign og ansøg om lån og underskriv digitalt med MitID eller NemID ✓ Ansøg på få minutter ✓ 100% online ansøgning ✓ sammenlign lån blandt mange banker ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån med MitID og NemID - Udbetaling i dag',
      datePublished: '2022-06-28',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'ModusLanding',
    component: ModusLanding,
    meta: {
      title: 'Modus Finans lån 10.000 – 150.000 kr.',
      description:
        'Modus Finans lån mellem 10.000 – 150.000 kr. Hurtig låneafgørelse via Saverium.',
      isPublic: true,
    },
    props: {
      headline: 'Modus Finans lån 10.000 – 150.000 kr.',
      datePublished: '2022-01-31',
      type: 'Article',
      author: 'Søren',
      image: '/static/img/saverium_dk/modus_finans_logo.svg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'HowEasyIsTakingOutLoan',
    component: HowEasyIsTakingOutLoan,
    meta: {
      title: 'Hvor nemt er det at optage et lån?',
      description:
        'Det er nemmere end nogensinde før at finde det billigste lån online, så du har ingen grund til at vælge det første og måske bedste tilbud du ser, bare fordi det skal være nemt og hurtigt.',
      isPublic: true,
    },
    props: {
      headline: 'Hvor nemt er det at optage et lån?',
      datePublished: '2022-02-02',
      type: 'Article',
      author: 'Søren',
      image: '/static/img/saverium_dk/article-imgs/how-easy-taking-out-loan.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'HowMuchMoneyIn2022',
    component: HowMuchMoneyIn2022,
    meta: {
      title: 'Hvad skal dit rådighedsbeløb være i 2022?',
      description:
        'Hvis man ønsker at skabe et overblik over ens privatøkonomi, er ens rådighedsbeløb et fornuftigt sted at starte. Det vil kunne fortælle dig hvor du kan forbedre din privatøkonomi ved at lave nogle få udregninger.',
      isPublic: true,
    },
    props: {
      headline: 'Hvor nemt er det at optage et lån?',
      datePublished: '2022-02-02',
      type: 'Article',
      author: 'Søren',
      image: '/static/img/saverium_dk/article-imgs/available-amount-in-2022.jpg',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'FellowBankLanding',
    component: FellowBankLanding,
    meta: {
      title: 'Ønsker du et lån fra Fellow Bank?',
      description:
        'Sammenlign forbrugslån fra Fellow Bank samt andre låneudbydere ✓ Gratis online låneansøgning ✓ Ansøg online her ✓',
      isPublic: true,
    },
    props: {
      headline: 'Ønsker du et lån fra Fellow Bank?',
      datePublished: '2022-02-12',
      dateModified: '2022-06-29',
      type: 'Article',
      author: '',
      image: '/static/img/saverium_dk/partner-logos/fellow-bank-small.png',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForAllSizes',
    component: LoanForAllSizes,
    meta: {
      title: 'Find lånebeløb i alle størrelser og til alle formål',
      description:
        'Lånebeløb fra 3.000 kr til 500.000 kr. ✓ Sammenlign tilbud fra mange banker ✓ Ansøg på 2 min. ✓ Få det bedste lån i dag ✓ 100% uforpligtende og gratis ✓',
      isPublic: true,
    },
    props: {
      headline: 'Find lånebeløb i alle størrelser og til alle formål',
      datePublished: '2022-06-28',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'BusinessLoan',
    component: BusinessLoan,
    meta: {
      title: 'Ansøg om erhvervslån - Vi finder det bedste erhvervslån til dig',
      description:
        'Sammenlign erhvervslån fra flere udbydere ✓ Ansøg og få svar på kort tid ✓ 100% online ansøgning og helt uforpligtende ✓ Lån til erhverv uden al besværet ✓',
      isPublic: true,
    },
    props: {
      headline: 'Ansøg om erhvervslån - Vi finder det bedste erhvervslån til dig',
      datePublished: '2022-06-28',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'MiniLoan',
    component: MiniLoan,
    meta: {
      title: 'Sammenlign minilån og få et lille lån med hurtig kreditvurdering',
      description:
        'Find de bedste minilån blandt mange udbydere ✓ Ansøg hurtigt og nemt online ✓ Få svar med det samme ✓ Uden at stille sikkerhed ✓ Høj godkendelse ✓',
      isPublic: true,
    },
    props: {
      headline: 'Sammenlign minilån og få et lille lån med hurtig kreditvurdering',
      datePublished: '2022-06-28',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForScooter',
    component: LoanForScooter,
    meta: {
      title: 'Sammenlign scooterlån - Hurtig udbetaling 24/7',
      description:
        'Ansøg og sammenlign lån til scooter og knallert ✓ 100% gratis låneansøgning ✓ Sammenlign lån blandt anerkendte banker ✓',
      isPublic: true,
    },
    props: {
      headline: 'Sammenlign scooterlån - Hurtig udbetaling 24/7',
      datePublished: '2022-06-28',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForTractor',
    component: LoanForTractor,
    meta: {
      title: 'Sammenlign traktorlån - Straks udbetaling i dag',
      description:
        'Ansøg og sammenlign lån til traktor ✓ Ansøg på få minutter ✓ 100% gratis låneansøgning ✓ Sammenlign lån blandt velkendte banker ✓',
      isPublic: true,
    },
    props: {
      headline: 'Sammenlign traktorlån - Straks udbetaling i dag',
      datePublished: '2022-06-28',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'HomeLoanLoans',
    component: HomeLoanLoans,
    meta: {
      title: 'Lån til hårde hvidevare - Hurtig udbetaling og sammenligning 24/7',
      description:
        'Ansøg gratis og sammenlign lån til hårde hvidevare ✓ Uforpligtende låneansøgning ✓ Sammenlign lånetilbud fra flere velkendte banker ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån til hårde hvidevare - Hurtig udbetaling og sammenligning 24/7',
      datePublished: '2022-06-28',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoansForEveryone',
    component: LoansForEveryone,
    meta: {
      title: 'Lån til alle uanset køn, uddannelse og alder - Sammenlign her',
      description:
        'Hos os kan du sammenlign lån blandt mange banker ✓ Alle kan søge om lån ✓ Ansøg på få minutter ✓ 100% uforpligtende og online ansøgning ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån til alle uanset køn, uddannelse og alder - Sammenlign her',
      datePublished: '2022-06-28',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'Mortgage',
    component: Mortgage,
    meta: {
      title: 'Boliglån - Vi hjælper dig med det sidste til boligen',
      description:
        'Lån op til 500.000 kr. via et usikret forbrugslån, som du kan bruge på at færdiggøre dine boligdrømme. Vi sammenligner lån for dig blandt mange udbydere',
      isPublic: true,
    },
    props: {
      headline: 'Boliglån - Vi hjælper dig med det sidste til boligen',
      datePublished: '2022-06-28',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanProviders',
    component: LoanProviders,
    meta: {
      title: 'Find den rette låneudbyder for dig på få minutter',
      description:
        'Sammenlign lånetilbud fra en lang række låneudbydere ✓ Lån 3.000-500.000 kr. ✓  Ansøg på 2 min. ✓ Sammenlign tilbud fra flere banker ✓ 100% gratis ✓ ',
      isPublic: true,
    },
    props: {
      headline: 'Find den rette låneudbyder for dig på få minutter',
      datePublished: '2022-06-28',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'CompareLoanOffers',
    component: CompareLoanOffers,
    meta: {
      title: 'Sammenlign lån her -  Få gratis lånetilbud fra flere banker',
      description:
        'Her kan du sammenligne lån fra mange banker og långivere ✓ Ansøg og få svar på få minutter ✓ 100% online ansøgning ✓ Helt uforpligtende ansøgning ✓',
      isPublic: true,
    },
    props: {
      headline: 'Sammenlign lån her -  Få gratis lånetilbud fra flere banker',
      datePublished: '2022-06-28',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'StudentLoan',
    component: StudentLoan,
    meta: {
      title: 'Lån til studerende - Find de beste tilbud her',
      description:
        'Find de billigste lån som studerende ✓ Forbrugslån til studerende ✓ Ansøg på 2 minutter ✓ Pengene på din konto i dag ✓ Gratis og uforpligtende ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån til studerende - Find de beste tilbud her',
      datePublished: '2022-07-06',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForMoving',
    component: LoanForMoving,
    meta: {
      title: 'Lån penge til flytning - find det billigste flyttelån her',
      description:
        'Vi finder de bedste flyttelån til dig ✓ Ansøg online og få svar med det samme ✓ Ansøgningen er helt uforpligtende ✓ Udfyld ansøgningsskemaet på 2 min. ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån penge til flytning - find det billigste flyttelån her',
      datePublished: '2022-07-08',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'BorrowMoney',
    component: BorrowMoney,
    meta: {
      title: 'Lån Penge | Find De Bedste og Billigste Lån På 2 Minutter Her!',
      description:
        'Lån penge billigt. Sammenlign renter blandt mange banker ✓ Ansøg på 2 minutter ✓ Få pengene på din konto i dag ✓ Gratis og uforpligtende ✓ Læs mere her.',
      isPublic: true,
    },
    props: {
      headline: 'Lån Penge | Find De Bedste og Billigste Lån På 2 Minutter Her!',
      datePublished: '2022-07-08',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'OnlineLoan',
    component: OnlineLoan,
    meta: {
      title: 'Onlinelån På 2 Min - Lån Penge Online - Start Ansøgning Her',
      description:
        'Sammenlign onlinelån fra flere udbydere ✓ Ansøg fra computer, mobil eller tablet ✓ 100% online ansøgning og helt uforpligtende → Ansøg Her',
      isPublic: true,
    },
    props: {
      headline: 'Onlinelån På 2 Min - Lån Penge Online - Start Ansøgning Her',
      datePublished: '2022-07-08',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'QuickerLoan',
    component: QuickerLoan,
    meta: {
      title: 'Hurtiglån op til 500.00 kr. - Hurtig Online Ansøgning - Start Her',
      description:
        'Sammenlign hurtiglån fra flere udbydere ✓ Ansøgning tager blot 2 min. ✓ 100% online ansøgning og helt uforpligtende → Ansøg Om Hurtigt Lån Her',
      isPublic: true,
    },
    props: {
      headline: 'Hurtiglån op til 500.00 kr. - Hurtig Online Ansøgning - Start Her',
      datePublished: '2022-07-08',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'MobileLoans',
    component: MobileLoans,
    meta: {
      title: 'Sammenlign mobillån her - find billige mobillån her',
      description:
        'Ansøg om mobillån blandt mange banker med blot én ansøgning ✓ få svar på ansøgningen med det samme ✓ 100% online ansøgning og helt uforpligtende ✓',
      isPublic: true,
    },
    props: {
      headline: 'Sammenlign mobillån her - find billige mobillån her',
      datePublished: '2022-07-08',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoansForVetBills',
    component: LoansForVetBills,
    meta: {
      title: 'Lån til dyrlægeregningen - Find lån til at betale dyrlægen',
      description:
        'Sammenligne lån bandt mange banker ✓ find det billigste lån til at betale dine regninger ✓ Ansøg på 2 min. og få pengene i dag ✓ Gratis og uforpligtende ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån til dyrlægeregningen - Find lån til at betale dyrlægen',
      datePublished: '2022-07-08',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoansForAfterSchool',
    component: LoansForAfterSchool,
    meta: {
      title: 'Lån til efterskoleophold - Find det bedste lån til efterskole',
      description:
        'Sammenlign og find de bedste lån blandt mange banker til at betale for dit efterskoleophold ✓ Gratis og uforpligtende ✓ Ansøg på 2 min. ✓ Få pengene i dag ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån til efterskoleophold - Find det bedste lån til efterskole',
      datePublished: '2022-07-08',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForHorse',
    component: LoanForHorse,
    meta: {
      title: 'Lån penge til en hest - Straks udbetaling af dit lån',
      description:
        'Gratis låneansøgning og sammenlign lånetilbud til heste ✓ Gratis låneansøgning ✓ Sammenlign tilbud fra flere velkendte banker ✓ 100% gratis ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån penge til en hest - Straks udbetaling af dit lån',
      datePublished: '2022-07-08',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoansForBills',
    component: LoansForBills,
    meta: {
      title: 'Lån til regninger - Find det rette lån til at betale dine regninger',
      description:
        'Sammenligne lån bandt mange banker og find det billigste lån til at betale dine regninger ✓ Ansøg på 2 min. og få pengene i dag ✓ Gratis og uforpligtende ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån til regninger - Find det rette lån til at betale dine regninger',
      datePublished: '2022-07-08',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForTv',
    component: LoanForTv,
    meta: {
      title: 'Lån nemt til et TV - Hurtig udbetaling af dit lån',
      description:
        'Ansøg gratis og sammenlign lånetilbud til TV ✓ Gratis låneansøgning ✓ Sammenlign tilbud fra flere anerkendte banker ✓ 100% uforpligtende ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån nemt til et TV - Hurtig udbetaling af dit lån',
      datePublished: '2022-07-08',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForBicycle',
    component: LoanForBicycle,
    meta: {
      title: 'Sammenlign cykellån her - lån til alle former for cykler',
      description:
        'Sammenlign lån til cykler blandt mange banker ✓ Ansøg online og få svar med det samme ✓ helt uforpligtende ✓ Ansøg på 2 minutter hele døgnet rundt ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån til cykel',
      datePublished: '2022-07-19',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForTravel',
    component: LoanForTravel,
    meta: {
      title: 'Lån til ferie og rejser - Find de bedste ferielån og rejselån her',
      description:
        'Sammenlign lån til dine rejser og ferier ✓ Ansøg på 2 min. og få svar med det samme ✓ 100% uforpligtende ✓ Modtag tilbud fra en lang række banker ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån til ferier og rejser',
      datePublished: '2022-07-19',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForGarden',
    component: LoanForGarden,
    meta: {
      title: 'Lån penge til din have - Straksoverførsel af lånet',
      description:
        'Online låneansøgning og sammenlign lånetilbud til din have ✓ Gratis låneansøgning ✓ Sammenlign tilbud fra flere anerkendte banker ✓ 100% gratis ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån til din have',
      datePublished: '2022-08-24',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForFurniture',
    component: LoanForFurniture,
    meta: {
      title: 'Lån til møbler her - Ansøg om møbellån på 2 minutter',
      description:
        'Find de bedste møbellån fra en lang række udbydere ✓ Ansøg nu og få svar med det samme ✓ 100% online ✓ 100% uforpligtende → Ansøg Her',
      isPublic: true,
    },
    props: {
      headline: 'Lån til møbler',
      datePublished: '2022-08-24',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForComputer',
    component: LoanForComputer,
    meta: {
      title: 'Lån til computer | Få finansieret din computer, pc eller Macbook her',
      description:
        'Lån til alle former for computer igennem os. Sammenlign lån blandt mange banker ✓ Ansøg på 2 minutter ✓ Få pengene i dag ✓ Gratis og uforpligtende ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån til computer',
      datePublished: '2022-08-24',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForWedding',
    component: LoanForWedding,
    meta: {
      title: 'Lån til bryllupet - Find det billigste bryllupslån her',
      description:
        'Sammenlign bryllupslån fra mange banker ✓ Undersøg jeres muligheder og find det billigste lån ✓ 100% online ansøgning ✓ 100% uforpligtende → Ansøg her',
      isPublic: true,
    },
    props: {
      headline: 'Lån til bryllupet - Find det billigste bryllupslån her',
      datePublished: '2022-08-24',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForDriversLicence',
    component: LoanForDriversLicence,
    meta: {
      title: 'Lån til kørekort billigt og nemt - Få finansieret dit kørekort her',
      description:
        'Sammenlign og ansøg om lån til kørekort blandt mange banker ✓ Ansøgning tager kun 2 minutter ✓ 100% online og uforpligtende ansøgning ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån til kørekort billigt og nemt - Få finansieret dit kørekort her',
      datePublished: '2022-08-24',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForDentist',
    component: LoanForDentist,
    meta: {
      title: 'Lån til tandlægen her - Find det billigste tandlån her',
      description:
        'Sammenlign tandlægelån fra mange udbydere ✓ Ansøg her og få svar med det samme ✓ 100% uforpligtende ✓ Få dit tandlån udbetalt med det samme ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån til tandlægen her - Find det billigste tandlån her',
      datePublished: '2022-08-24',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForGroceries',
    component: LoanForGroceries,
    meta: {
      title: 'Lån penge til madindkøb - Hurtig overførsel af lånet',
      description:
        'Online låneansøgning ✓ Sammenlign lånetilbud til madindkøb ✓ Gratis låneansøgning ✓ Sammenlign lån fra flere forskellige banker ✓ 100% gratis ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån penge til madindkøb - Hurtig overførsel af lånet',
      datePublished: '2022-08-24',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForRent',
    component: LoanForRent,
    meta: {
      title: 'Lån penge til huslejen her - Vi finder de bedste lån for dig',
      description:
        'Sammenlign lån til huslejen ✓ Ansøg online på 2 minutter ✓ Få svar med det samme ✓ Sammenlign lån blandt mange banker, til at dække din husleje ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån penge til huslejen her - Vi finder de bedste lån for dig',
      datePublished: '2022-08-24',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'WorkshopInvoiceInstallments',
    component: WorkshopInvoiceInstallments,
    meta: {
      title: 'Værksted på afbetaling - find de bedste tilbud her',
      description:
        'Sammenligne tilbud på værkstedsslån fra mange banker ✓ Ansøg på 2 minutter og få svar med det samme ✓ 100% online ansøgning og 100% uforpligtende ansøgning ✓',
      isPublic: true,
    },
    props: {
      headline: 'Værksted på afbetaling - find de bedste tilbud her',
      datePublished: '2022-08-24',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForPlasticSurgery',
    component: LoanForPlasticSurgery,
    meta: {
      title: 'Lån til plastikkirurgi - find billig finansiering her',
      description:
        'Sammenlign lån til plastikkirurgi fra velkendte banker og alternative långivere ✓ Ansøg på 2 min. og få svar på ansøgningen nu ✓ 100% uforpligtende ✓',
      isPublic: true,
    },
    props: {
      headline: 'Lån til plastikkirurgi - find billig finansiering her',
      datePublished: '2022-08-24',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForEducation',
    component: LoanForEducation,
    meta: {
      title: 'Lån til din uddannelse billigt - Sammenlign lån her',
      description:
        'Sammenlign lån og finansiering til uddannelsen fra flere udbydere ✓ Ansøg nu og få svar med det samme ✓ 100% online ansøgning og helt uforpligtende → Ansøg Her',
      isPublic: true,
    },
    props: {
      headline: 'Lån til din uddannelse billigt - Sammenlign lån her',
      datePublished: '2022-08-24',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
  {
    name: 'LoanForInvesting',
    component: LoanForInvesting,
    meta: {
      title: 'Investeringslån / Gearing - Sammenlign lån til investering her',
      description:
        'Indhent tilbud på investeringslån fra mange banker ✓ Online ansøgning på 2 minutter ✓ Altid uforpligtende tilbud døgnet rundt ✓ Find den bedste gearing ✓',
      isPublic: true,
    },
    props: {
      headline: 'Investeringslån / Gearing - Sammenlign lån til investering her',
      datePublished: '2022-08-24',
      type: 'Article',
      author: '',
      image: '',
      publisher: {
        type: 'Organization',
        name: 'Saverium.dk',
        url: 'https://saverium.dk',
        logo: '/static/img/saverium_dk/Saverium_white_rgb.png',
      },
    },
  },
]

const rawRoutes = routeUtils.createRawRoutes(langRoutes, localizedPaths)

export default rawRoutes
