<template>
  <div>
    <div class="article">
      <h1 class="center-title">Nye lån</h1>
      <div class="top-text text-container">
        <p>
          Det onlinebaserede lånemarked er under konstant udvikling. Jævnligt introduceres
          nye låneudbydere, ligesom også nye lån bliver introduceret.
        </p>
        <p>
          For dig som forbruger er det udelukkende en fordel, at der altid er nye lån på
          vej. Det resulterer nemlig i en intens konkurrence, der resulterer i bedre
          priser og vilkår for låntagerne. Saverium.dk vil hjælpe dig med at finde og
          <b-link :to="$root.rn.LoanCalculator">beregne det bedste lån</b-link> til dig.
        </p>
        <p>
          De mange nye lån på nettet skal selvfølgelig adskille sig fra de eksisterende
          låneprodukter, hvorfor de eksempelvis introduceres med afdragsfrihed, lav rente
          eller andre fordelagtige parametre, som adskiller dem fra konkurrenterne.
        </p>
        <p>
          Dette er dog ikke ensbetydende med, at alle nye
          <b-link :to="$root.rn.LoanOptions">lånemuligheder</b-link> er bedre, end de
          nuværende. Derfor bør målet ikke være at finde et helt nyt lån, men derimod at
          finde det <b-link :to="$root.rn.CheapLoan">billigste lån</b-link>. Heldigvis har
          den nye lovgivning vedrørende forbrugslån gjort det med sikkert for dig som
          kunde, du kan læse mere om den nye lov
          <a
            href="https://www.forbrug.dk/nyheder/forbrug/2020/nye-regler-skal-beskytte-forbrugerne-mod-dyre-forbrugslaan/"
            target="_blank"
            rel="noopener"
            >her</a
          >.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul v-for="(text, i) in contents" :key="i">
                <li>
                  <a :href="text.link">
                    {{ text.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">Optage nye lån: Indhent nemt lånetilbud med Saverium.dk</h2>
          <p>
            Når du skal optage nye lån, er det essentielt, at du nemt indhenter
            <b-link :to="$root.rn.LoanOffers">lånetilbud</b-link>
            fra forskellige låneudbydere. Priserne på
            <b-link :to="$root.rn.QuickLoan">kviklån</b-link> varierer kraftigt, hvorfor
            du ved udelukkende at udbede dig et enkelt tilbud, øger risikoen for at
            acceptere et dyrt lånetilbud.
          </p>
          <p>
            Heldigvis behøver det ikke at være vanskeligt at indhente en bunke tilbud, du
            kan sammenligne med hinanden. Dette kan du nemlig bruge Saverium.dk. Her på
            siden modtager du tilbud fra flere forskellige låneudbydere, men skal kun
            udfylde en enkel – og simpel – låneansøgning.
          </p>
          <p>
            Du modtager endda et øjeblikkeligt svar på din låneansøgning, hvorfor det ikke
            blot er nemt – det går også hurtigt. Lånetilbuddene kommer fra vores
            samarbejdspartnere, hvilket bl.a. omfatter Basisbank, Express Bank, Resurs
            Bank m.fl.
          </p>
          <p>
            Det er selvfølgelig uforpligtende og gratis at indhente tilbud på online lån
            via Saverium.dk. Du er derfor ikke forpligtet til at acceptere et af de
            modtagne tilbud, hvis ikke de appellerer til dig.
          </p>
          <p>
            Vil du optage et nyt lån via en af
            <b-link :to="$root.rn.OurPartnersView">låneudbyderne</b-link>, vi samarbejder
            med, vil du ikke blive påkrævet at stille sikkerhed for lånebeløbet. Beslutter
            du dig for at acceptere et af de modtagne tilbud, har du selvfølgelig ingen
            pligt til at udtale dig om, hvad lånets formål er.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">Nyt lånefirma øger konkurrencen på lånemarkedet i Danmark</h2>
          <p>
            Hver gang et nyt lånefirma slår dørene op på det danske marked, øges
            mulighederne for at optage nye lån i Danmark ikke blot. Det øger og
            intensivere også konkurrencen blandt de adskillige låneudbydere på markedet.
          </p>
          <p>
            For låntagerne er det derfor fordelagtigt, at der jævnligt er
            <b-link :to="$root.rn.QuickLoan">nye kviklån</b-link>, der finder sin vej til
            det danske marked. Den øgede konkurrence resulterer nemlig i, at långiverne
            føler sig nødsaget til at forbedre deres vilkår og sænker deres priser, i
            håbet om at tiltrække kunderne.
          </p>
          <p>
            I takt med konkurrencen er blevet øget, er mængden af
            <b-link :to="$root.rn.CheapLoan">billige lån</b-link> derfor også forøget.
            Ønsker du at låne penge til den lavest mulige priser, kan du derfor glæder dig
            over, at nye lån nu til dags er billigere, end for blot et par år siden.
          </p>
          <p>
            Hver gang der åbnes et nyt lånefirma i Danmark, øges konkurrencen, hvilket
            medfører, at chancen for at kunne optage et billig nyt lån forøges. Netop
            derfor kan du også vide dig sikker på, at det er ganske overkommeligt at
            optage et hurtigt lån, uden at det koster en mindre formue.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">Optag nye lån hurtigt på nettet – men ikke for hurtigt</h2>
          <p>
            Mange ønsker at optag nye lån hurtigt uden om banken, fordi det går stærkt.
            Man skal dog passe på med, at det ikke går for stærkt. Trods den konstant
            stigende konkurrence og de faldende priser, er der fortsat lån blandt
            mulighederne, der ikke er billige.
          </p>
          <p>
            Til trods for, at du ønsker at optage nye lån hurtigt, er du nødt til at
            undersøge dine muligheder grundigt. Det er ensbetydende med, at det er en
            nødvendighed, at du tager dig tid til at indhente en række forskellige
            lånetilbud, du kan sammenligne med hinanden.
          </p>
          <p>
            Formålet med at indhente forskellige lånetilbud er, at du dermed har
            muligheden for at holde dem op imod hinanden. Det muliggør, at du kan
            sammenligne tilbuddenes priser og vilkår, hvorved du kan udvælge det af de
            modtagne tilbud, der er det mest tiltalende.
          </p>
          <h3>Renten er ikke det eneste afgørende parameter</h3>
          <p>
            Mange deler den opfattelse, at man skal låne penge til en lav pris, så
            <b-link :to="$root.rn.LoansWithLowRate">lån penge med lav rente</b-link>. Om
            end det ofte er et afgørende element i at sikre, at et quicklån er billigt, er
            det ikke det eneste afgørende parameter.
          </p>
          <p>
            Har du intentioner om at optage et nyt lån i Danmark, er det derfor
            essentielt, at du laver en grundig sammenligning af en række forskellige
            tilbud. I forbindelse med lån af penge, er det dog ikke blot renten, der skal
            betales – der er også flere andre omkostninger at tage højde for.
          </p>
          <p>
            I og med, at det kan være vanskeligt at skabe et overblik over de mange
            forskellige omkostninger, er det fordelagtigt at sammenligne ud fra ÅOP. Det
            er en forkortelse for Årlige Omkostninger i Procent, og er et tal, der dækker
            over samtlige omkostninger, der er forbundet med det givne lån.
          </p>
          <p>
            Ved at holde øje med ÅOP i forbindelse med optagelse af et kvik lån, er du
            derfor garanteret, at det ikke er gemte omkostninger, du overser, inden
            låneaftalen er underskrevet. Det er en fordel, da du dermed er bekendt med
            lånets pris på forhånd, og derfor undgår ubehagelige overraskelser.
          </p>
          <p>
            Ydermere har alle lån- og kreditgivere pligt til at skilte tydeligt med en
            given låne- eller kreditaftalens ÅOP, hvorfor tallet altid er nemt at finde.
            Det er også gældende for de mange nye lån online.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">
            Nye lån på vej og få pengene med det samme – kan det lade sig gøre?
          </h2>
          <p>
            Der er adskillige fordele forbundet med nye lån af penge online. En af de mest
            fremtrædende fordele er den korte udbetalingstid. I modsætning til banken, er
            hurtig udbetaling prioriteret hos hovedparten af de onlinebaserede
            lånevirksomheder.
          </p>
          <p>
            Har du brug for penge her og nu, så
            <b-link :to="$root.rn.LoanImmediately">lån penge akut</b-link> i form af et
            quick lån på nettet. Alt afhængigt af lånets størrelse, kan du have pengene
            stående på din konto inden for ganske få timer.
          </p>
          <p>
            Lån af penge med nye lån er dog ikke en decideret nødvendighed, hvis du
            prioriterer, at det går stærkt. Ofte er udbetalingstiden dog et af de
            parametre, der konkurreres på, når en ny låneudbyder træder ind på det danske
            marked – eller en eksisterende långiver introducerer nye lån.
          </p>
          <h3>Hvor hurtigt kan jeg have pengene?</h3>
          <p>
            Det er svært at give et konkret svar på, hvor hurtigt du kan have pengene. Det
            varierer meget fra udbyder til udbyder, men også afhængigt af lånebeløbets
            størrelse. Optager du
            <b-link @click="setLoan(5000)">et lån på 5000 kroner</b-link>, vil du
            sandsynligvis have pengene hurtigere, end ved et
            <b-link @click="setLoan(20000)">lån på 20.000 kroner</b-link>.
          </p>
          <p>
            Under alle omstændigheder er du dog garanteret, at låneproces og
            udbetalingstid er kortere, end det er tilfældet i banken. Optager du et kredit
            lån på nettet, går der sjældent mere end 24 timer, før pengene står på din
            konto.
          </p>
          <p>
            Vil du gerne låne penge, så lån let til, hvad end du drømmer om på nettet. Ved
            at gøre dette, er du ikke blot garanteret en hurtig udbetalingstid, men også,
            at du ikke har pligt til at dokumentere, hvad lånets tiltænkte formål er.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">Nye lån uden sikkerhed hos alle nye låneudbydere</h2>
          <p>
            De nye låneudbydere forsøger så vidt muligt at skille sig ud fra mængden, når
            de slår dørene op på det danske marked. Ofte er det også på baggrund af
            suveræne vilkår, lave priser e.l., at et nyt lån hitter.
          </p>
          <p>
            Det er dog ikke ensbetydende med, at de adskiller sig på alle punkter. Fælles
            for langt de fleste lånemuligheder på nettet er, at du ikke skal stille
            sikkerhed for lånet. Derfor kan du ikke blot
            <b-link :to="$root.rn.CheapLoan">låne penge billigt</b-link> på nettet, men
            også uden at stille pant i værdier og ejendele.
          </p>
          <h3>Hvad er et lån uden sikkerhed?</h3>
          <p>
            Når du optager et lån online, er det yderst sjældent, at du påkræves at stille
            sikkerhed for lånet. Det står i skarp kontrast til bankerne, der har et
            nærmest urokkeligt krav om dette.
          </p>
          <p>
            Men hvad betyder det egentlig, at du kan optage et
            <b-link :to="$root.rn.UnsecuredLoans">lån uden sikkerhed</b-link>?
          </p>
          <p>
            Det vil sige, at du ikke skal stille værdier som garanti for dit lån. Derfor
            behøver du som sådan ikke at eje noget af værdi, der potentielt kan stilles
            som sikkerhed for dit lån.
          </p>
          <p>
            Det nedbringer ikke blot din risiko, men medfører også, at du får lettere ved
            at optage et lån. Ønsker du at optage et
            <b-link :to="$root.rn.LoansWithLowRate">lån med lav rente</b-link> i banken,
            er det som regel en af nedenstående værdier, der kræves, at du stiller
            sikkerhed i:
          </p>
          <ul>
            <li>Bil og bolig</li>
            <li>Pension</li>
            <li>Værdipapirer</li>
          </ul>
          <p>
            Årsagen til, at banken kræver, at der stilles sikkerhed i form af en
            værdigenstand, der helt eller delvist kan dække den ubetalte gæld er, at deres
            risiko dermed er minimal.
          </p>
          <p>
            Det medfører ganske vist, at du får et billigt lån, men det medfører også, at
            du kan risikere at miste den værdigenstand, der er stillet som sikkerhed, hvis
            ikke du kan indfri din gæld. Derfor kan det være en fordel at vælge et
            <b-link :to="$root.rn.UnsecuredLoans">kreditlån uden sikkerhed</b-link> på
            nettet.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">Kan alle optage nye lån online billigt?</h2>
          <p>
            Selvom onlinebaserede lån er uden sikkerhed og dokumentation, er det ikke
            ensbetydende med, at alle kan låne penge billigt uden krav. Det vil sige, at
            ikke alle kan få bevilliget nye lån hos de onlinebaserede långivere.
          </p>
          <p>
            Krav og betingelser varierer fra udbyder til udbyder, men der er især fire
            krav, som de fleste låneudbydere stiller til deres låntagere.
          </p>
          <h3>1. RKI</h3>
          <p>
            Et krav, der er gældende hos alle långivere er, at du ikke må være registreret
            i RKI. Er du registreret som dårlig betaler i RKI eller i Debitor Registret,
            er det ikke muligt at blive godkendt til nye lån – hverken i banken eller på
            nettet.
          </p>
          <p>
            Derfor vil du lede forgæves efter et lån på nettet, såfremt dette er aktuelt
            for dit vedkommende. Før du igen kan indgå låne- og kreditaftale, skal din
            registrering i RKI slettes, hvilket kan opnås på to måder:
          </p>
          <ol>
            <li>Du kan vente fem år, hvorefter din registrering automatisk slettes</li>
            <li>Du kan afbetale gælden, der er registreret under dit navn i RKI</li>
          </ol>
          <h3>2. Indkomst</h3>
          <p>
            Som regel har långiveren også indkomstbaserede krav, om end det varierer
            kraftigt fra udbyder til udbyder, hvor strenge kravene er. Ligeledes er der
            også forskel på, hvordan de vurderer, at din indkomst er tilstrækkelig.
          </p>
          <p>
            De fleste steder accepterer du via din underskrift med Digital Signatur, at
            låneudbyderne får adgang til din skattemappe. Derved kan de ud fra din
            årsindtægt vurdere, hvorvidt din indkomst er tilstrækkelig til at sikre
            tilbagebetaling af det lånte beløb.
          </p>
          <p>
            I forbindelse med lån af mindre beløb, er indkomstkravene sjældent strenge.
            Derfor kan de fleste få bevilliget nye lån online – også selvom indkomsten
            ikke er synderligt høj. Ligeledes er det heller ikke alle aktører, der kræver,
            at der er tale om en lønindkomst.
          </p>
          <p>
            Hos adskillige långivere har det ingen indflydelse, hvorvidt indkomsten består
            af kontanthjælp, dagpenge, SU eller en fast lønindkomst. Det afgørende er, om
            indkomsten er tilstrækkeligt høj til at kunne dække tilbagebetalingen af
            lånet.
          </p>
          <h3>3. Bopæl</h3>
          <p>
            Hovedparten af de långivere, der er aktive på det danske marked, stiller en
            form for krav til bopæl og statsborgerskab. Som regel er det påkrævet, at du
            er bosiddende i Danmark og har statsborgerskab i landet.
          </p>
          <p>
            Dette er også ensbetydende med, at du skal have dansk CPR-nummer, en dansk
            bank samt være i besiddelse af et gyldigt NemID. Enkelte långivere er villige
            til at overføre lånet til en udenlandsk bankkonto, men sjældent uden betaling
            af et gebyr.
          </p>
          <p>Derudover kræver visse udbydere også, at du har et dansk telefonnummer.</p>
          <h3>4. Alder</h3>
          <p>
            Leder du efter nye lån online, er det sjældent, at du oplever, at der ikke er
            sat en form for aldersgrænse hos långiverne. Teoretisk set skal du blot være
            fyldt 18 år, for at få bevilliget et lån. Det er dog kun enkelte udbydere, der
            tilbyder lån til 18-årige.
          </p>
          <p>
            Derimod stiller de fleste krav om, at du er fyldt 21 eller 23 år, hvis du vil
            låne penge uden sikkerhed. Årsagen til dette er, at de fleste personer på 18
            år ikke har en fast indkomst og en stabil økonomi.
          </p>
          <p>
            Er du ældre end 23 år, har du – som udgangspunkt – frit slag på lånemarkedet.
            Er du endnu ikke fyldt 23 år, må du acceptere, at dine lånemuligheder er
            begrænsede. Ligeledes vil det ofte også være dyrere at låne, da mange
            långivere vurdere, at det er mere risikofyldt at låne ud til unge.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'

import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  data() {
    return {
      contents: [
        {
          title: 'Optage nye lån: Indhent nemt lånetilbud med Saverium.dk',
          link: '#1',
        },
        {
          title: 'Nyt lånefirma øger konkurrencen på lånemarkedet i Danmark',
          link: '#2',
        },
        {
          title: 'Optag nye lån hurtigt på nettet – men ikke for hurtigt',
          link: '#3',
        },
        {
          title: 'Nye lån på vej og få pengene med det samme – kan det lade sig gøre?',
          link: '#4',
        },
        {
          title: 'Nye lån uden sikkerhed hos alle nye låneudbydere',
          link: '#5',
        },
        {
          title: 'Kan alle optage nye lån online billigt?',
          link: '#6',
        },
      ],
    }
  },
  methods: {
    setLoan(value) {
      this.loanamount = value
      const key = 'amount'
      this.$store.commit('updateApplication', { key, value })
      this.$router
        .push({
          name: 'DynamicLoanPage',
          params: { loanamount: this.loanamount },
        })
        .catch(() => {})
    },
  },
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
