<template>
  <div>
    <div class="container container-faq col-lg-8 mt-5 mb-5">
      <h1>Saverium.dk Persondatapolitik</h1>
      <p>
        Denne Persondatapolitik regulerer, hvordan LVS Brokers Oy (&quot;LVS Brokers&quot;
        eller &quot;vi&quot;) behandler personoplysninger, der indsamles fra brugerne
        (&quot;Bruger&quot; eller &quot;du&quot;) af de tjenester, som LVS Brokers stiller
        til rådighed på hjemmesiden Saverium.dk, til brug for sammenligning af
        forbrugslån, samt fra de øvrige brugere af hjemmesiden Saverium.dk (hjemmeside og
        tjenester samlet benævnt &quot;Saverium.dk&quot;).
      </p>
      <p>
        Ved at anvende Saverium.dk til at ansøge om et lån bemyndiger du LVS Brokers til
        at anmode om ét eller flere forbrugslånetilbud på dine vegne fra de banker og
        øvrige kreditgivere (&quot;Långiver&quot;), der samarbejder med LVS Brokers.
      </p>
      <p>
        Bemærk venligst, at denne Persondatapolitik kun gælder for din brug af Saverium.dk
        og for LVS Brokers&#39; behandling af personoplysninger af. Før du gør brug af et
        lån, en kredit eller andre tjenester fra Långiverne, opfordrer vi dig til at læse
        deres persondatapolitikker, da disse kan afvige fra vores.
      </p>
      <p>
        Den dataansvarlige for personoplysningerne om Brugerne af Saverium.dk er LVS
        Brokers.
      </p>
      <p>
        Kontaktoplysninger:
      </p>
      <p>
        LVS Brokers Oy<br />
        PL 644 FI-00101 Helsinki<br />
        Finland<br />
        <a href="https://www.saverium.dk">https://www.saverium.dk</a><br />
        Kundeservice: kundeservice@saverium.dk<br />
        Registreringsnummer i det finske handelsregister: 2521965-8
      </p>
      <h3>Kilder til oplysningerne</h3>
      <p>
        Når du fremsender en låneansøgning og giver samtykke ved at sætte kryds i de
        relevante felter i låneansøgningen, bemyndiger du os og Långiverne til at behandle
        de oplysninger, du har fremsendt til os i din låneansøgning, og du bemyndiger
        Långiverne til i overensstemmelse med gældende lov at indhente sådanne yderligere
        oplysninger i eksterne registre, som er nødvendige for at vurdere og verificere
        din ansøgning.
      </p>
      <h3>Indsamlede personoplysninger</h3>
      <p>
        Vi indsamler og behandler de personoplysninger, der er indeholdt i låneansøgningen
        eller i andre formularer, der er tilgængelige på Saverium.dk, til de formål, der
        er beskrevet nedenfor og i overensstemmelse med gældende lov, herunder særligt
        bestemmelserne i EU&#39;s databeskyttelsesforordning (EU-forordning 2016/679) og
        den danske databeskyttelseslov (lov nr. 502 af 23. maj 2018) med senere ændringer
        og/eller tilføjelser. Særligt indsamler vi følgende oplysninger:
      </p>
      <div class="container">
        <p>
          For- og efternavne<br />
          CPR-nummer (DNI/NIE)<br />
          ID-kortnummer<br />
          Adresse<br />
          Telefonnummer/-numre<br />
          E-mailadresse<br />
          Statsborgerskab<br />
          Civilstand<br />
          Antal personer, der er forsørgelsespligt over for<br />
          Alder på personer, der er forsørgelsespligt over for<br />
          Ansættelsesforhold<br />
          Profession<br />
          Bankkonti<br />
          Bankkunde siden<br />
          Tidligere lån og kreditter<br />
          Bank- og kreditkort<br />

          Kredithistorik og -oplysninger<br />
          Indtægts- og udgiftsdata<br />
          Lånetype der ansøges om<br />
          Fravalg og tilvalg af direkte markedsføring<br />
          Finansieringsprodukter og -lån, der ansøges om<br />
          Manglende betalinger<br />
          Antal køretøjer i husstanden og ejeform<br />
          Medlemsskab af A-kasse<br />
        </p>
      </div>
      <p>
        Hvis låneansøgningen eller andre formularer udfyldes på vegne af en
        erhvervsdrivende, indsamler vi der en række oplysninger om den pågældende
        virksomhed, såsom virksomhedens navn, adresse, kontaktoplysninger, CVR-nummer,
        information om omsætning, bankkontonummer og informationer om lån og kreditter i
        virksomhedens navn, herunder tilbud på lån. I den forbindelse indsamler vi meget
        begrænsede personoplysninger om kontaktpersonen hos virksomheden, herunder navn,
        e-mailadresse, telefonnummer og i visse tilfælde CPR-nummer alene til brug for
        håndteringen af virksomhedens låneansøgning.
      </p>
      <p>
        Vi vil kunne indsamle browserdata fra Brugernes browsere, når de interagerer med
        Saverium.dk. Browserdata kan f.eks. være browserens navn, computertypen, dato og
        tidspunkt for besøget, IP-adresse, operativsystem og anvendte internetudbydere
        samt andre lignende tekniske oplysninger. Selvom vi ikke normalt kombinerer
        browserdataene med dine personoplysninger, vil du indimellem kunne blive genkendt
        på grundlag heraf.
      </p>
      <h3>Formål med behandlingen</h3>
      <p>
        Dine personoplysninger behandles med det formål at stille Saverium.dk til rådighed
        for dig. Saverium.dk er en forbrugslånsformidlingstjeneste, som omfatter søgning
        efter passende lånetilbud og præsentation af Långivernes tilbud til Brugerne, samt
        med det formål at forsyne Långiverne med de oplysninger, de skal bruge for at
        kunne vurdere din låneansøgning.
      </p>
      <p>Endvidere vil vi kunne bruge Brugernes personoplysninger til følgende formål:</p>
      <div class="container">
        <p>
          Kundeservice<br />
          Vedligeholdelse af kundekonti i Saverium.dk<br />
          Identifikation / KYC<br />
          Verificering af, at oplysningerne i låneansøgningen er korrekte<br />
          At gøre brugeroplevelsen mere personlig og at videreudvikle Saverium.dk<br />
          Brugeranalyse og -statistik<br />
          Markedsføring af vores produkter og tjenester pr. e-mail, SMS, post, telefon
          m.m.<br />
          Overholdelse af gældende lovkrav<br />
        </p>
      </div>
      <p>
        Vi vil også kunne bruge Brugernes personoplysninger til at tilsende Brugerne
        e-mails og SMS&#39;er, der kan indeholde f.eks. nyheder om Saverium.dk og
        oplysninger om vores tjenester. Vi sikrer os, at kommercielle oplysninger, der
        udsendes i form af elektronisk kommunikation og ved brug af
        terminalkommunikationsenheder, herunder særligt e-mails og mobiltelefoner, kun
        sendes til Brugerne, hvis vi først har indhentet Brugernes særskilte, forudgående,
        frivillige og genkaldelige samtykke til at modtage kommercielle oplysninger på
        denne måde.
      </p>
      <p>
        For det tilfælde at Brugeren på noget tidspunkt ønsker at afmelde sig fra at
        modtage fremtidige e-mails, der indeholder kommercielle oplysninger, indsætter vi
        en detaljeret vejledning til en sådan afmelding i bunden af hver e-mail.
      </p>
      <p>
        Vi vil kun anvende Brugernes personoplysninger til markedsføring af tredjeparters
        tjenester via e-mail, SMS, post, telefon m.m., såfremt Brugerne først har givet
        deres særskilte, forudgående, frivillige og genkaldelige samtykke til sådan
        markedsføring på denne måde.
      </p>
      <p>
        Vi vil kun anvende Brugernes personoplysninger til at gøre brugeroplevelsen
        personlig og videreudvikle Saverium.dk samt til brugeranalyse og -statistik,
        såfremt Brugerne først har givet deres særskilte, forudgående, frivillige og
        genkaldelige samtykke til sådan behandling.
      </p>
      <p>
        Vi opbevarer kun personoplysninger i så lang tid, som er nødvendig til de formål,
        der fremgår af denne Persondatapolitik, eller som er nødvendig for at overholde
        gældende love og forordninger. Det er nuværende tidspunkt ikke muligt at angive,
        hvor længe vi vil opbevare dine personoplysninger. Vores vurdering af hver enkelt
        opbevaringsperiode vil dog blive baseret på, hvorvidt en fortsat opbevaring er
        nødvendig for at retskrav kan fastslås, gøres gældende eller forsvares, eller for
        at overholde en retlig forpligtelse som f.eks. hvidvaskloven. Når oplysningerne
        ikke længere er nødvendige, sletter vi dem så hurtigt som muligt.
      </p>
      <p>
        Alle Brugernes personoplysninger vil blive behandlet i overensstemmelse med
        gældende lov, herunder særligt bestemmelserne i EU&#39;s persondataforordning
        (GDPR) og den danske databeskyttelseslov.
      </p>
      <p>
        Besøg på Saverium.dk hjemmeside medfører ikke automatisk indsamling af dine
        personoplysninger, medmindre du accepterer at give os sådanne oplysninger
        frivilligt (f.eks. ved at indlevere en låneansøgning), eller medmindre du giver
        samtykke til vores behandling af cookie-oplysninger (se vores Cookiepolitik
        nedenfor). Brugernes afgivelse af personoplysninger er frivillig men nødvendig,
        for at vi kan levere vores Saverium.dk-tjenester. Undladelse af at levere de
        ønskede oplysninger kan gøre det umuligt for os at viderebehandle din
        låneansøgning eller præsentere dig for Långivernes tilbud.
      </p>
      <h3>Videregivelse af personoplysninger</h3>
      <p>
        Vi vil kunne dele oplysninger om Brugerne med Långiverne til de formål, der er
        anført ovenfor, men udelukkende i det omfang der er nødvendigt (&quot;need to
        know&quot;).
      </p>
      <p>
        Facit Bank A/S indhenter generelle kreditoplysninger hos kreditoplysningsbureauer
        og advarselsregistre, herunder RKI, Debitor Registeret og IDQ, samt anvender
        automatiske afgørelser, herunder profilering, i forbindelse med Facit Bank A/S’
        kreditvurdering. Som kreditformidler for Facit Bank A/S indhenter L’EASY A/S
        tillige oplysninger nødvendige for kundeforholdets oprettelse og kreditvurderingen
        i Facit Bank A/S. Til brug for kreditvurdering af ansøgere indhenter Facit Bank
        A/S herudover øvrige oplysninger, herunder økonomiske forhold (f.eks. restgæld,
        evt. restancer) hos L’EASY A/S og DER A/S til brug for Facit Bank A/S’
        kreditvurdering. Se Facit Bank A/S’ privatlivspolitik
        <a
          href="https://facitbank.dk/om-banken/privatlivspolitik/"
          target="_blank"
          rel="noopener"
          >her</a
        >.
      </p>
      <p>
        På betingelse af Brugerens særskilte, forudgående, frivillige og genkaldelige
        samtykke vil vi kunne dele oplysninger om Brugeren med vores &quot;white
        label&quot;-partnere.
      </p>
      <p>
        Vi kan anvende eksterne tjenesteudbydere til at stille Saverium.dk til rådighed
        eller til at administrere Saverium.dk-relaterede aktiviteter på vores vegne,
        eksempelvis udbydere af IT-systemer og -tjenester. Vi vil kun dele dine
        oplysninger med disse eksterne parter til disse begrænsede formål.
      </p>
      <p>
        Vi vil kunne videregive personoplysninger til offentlige myndigheder, hvis vi er
        juridisk forpligtet til at gøre det.
      </p>
      <p>
        Vi vil også kunne overføre dine oplysninger til vores koncernselskaber,
        datterselskaber og tilknyttede selskaber samt til efterfølgende eller nye
        ejere/operatører af Saverium.dk, i tilfælde af at Saverium.dk, vi, vores aktier
        og/eller aktiver opkøbes, eller i tilfælde af fusion, omstrukturering, konkurs
        eller anden selskabsmæssig omstrukturering.
      </p>
      <p>
        Vi vil kunne behandle dine personoplysninger i - eller overføre disse til - et
        andet land, hvori vi driver virksomhed eller har medarbejdere eller
        tjenesteudbydere, herunder lande uden for EU eller EØS. En sådan behandling eller
        overførsel vil blive foretaget i overensstemmelse med gældende lov. I tilfælde
        hvor EU- Kommissionen ikke anser databeskyttelsesniveauet for at være
        tilstrækkeligt, sikrer vi os altid gennem kontraktlige eller andre
        foranstaltninger, at der sørges for tilstrækkelig beskyttelse af dine
        personoplysninger som krævet i henhold til gældende love, f.eks. ved at anvende
        EU-Kommissionens standardkontrakter for overførsel af personoplysninger til
        tredjelande. Hvis du ønsker at vide mere om internationale overførsler af dine
        personoplysninger, kan du kontakte os ved brug af ovenstående kontaktoplysninger.
      </p>
      <h3>Retsgrundlag for behandling af personoplysninger</h3>
      <p>
        Der er flere retsgrundlag for vores behandling af dine personoplysninger. Det
        konkrete retsgrundlag afhænger af typen af personoplysninger og formålet med
        behandlingen.
      </p>
      <p>
        Vi vil kunne behandle dine personoplysninger baseret på følgende retsgrundlag:
      </p>
      <ul>
        <li>
          dit samtykke, jf. databeskyttelsesforordningens artikel 6, stk. 1, litra a,
        </li>
        <li>
          for at opfylde en kontrakt, hvor du er part i, jf. databeskyttelsesforordningens
          artikel 6, stk. 1, litra b,
        </li>
        <li>
          for at overholde vores retlige forpligtelser, jf. databeskyttelsesforordningens
          artikel 6, stk. 1, litra c,
        </li>
        <li>
          for at forfølge vores eller en tredjemands legitime interesse, f.eks.
          markedsførings- eller statistikformål, medmindre dine interesser eller
          grundlæggende rettigheder og frihedsrettigheder overstiger denne interesse, jf.
          databeskyttelsesforordningens artikel 6, stk. 1, litra f.
        </li>
      </ul>
      <p>
        Vi vil behandle dit CPR-nummer, såfremt det følger af lovgivningen, jf.
        databeskyttelseslovens § 11, stk. 2, nr. 1, eller hvis behandling er nødvendig,
        for at retskrav kan fastslås, gøres gældende eller forsvares, jf.
        databeskyttelseslovens § 11, stk. 2, nr. 4, og § 7, stk. 1.
      </p>
      <p>
        Såfremt vi behandler personoplysninger vedrørende (potentielle) strafbare
        handlinger, vil en sådan behandling kun finde sted, hvis den er nødvendigt for at
        varetage en legitim interesse, der under de givne omstændigheder vurderes klart at
        overstige hensynet til den pågældende persons interesser.
      </p>
      <h3>Datasikkerhed</h3>
      <p>
        Vi har truffet både tekniske og organisatoriske forebyggende foranstaltninger, der
        er standard for branchen, og vi anvender flere lag sikkerhedsprocedurer for at
        begrænse adgangen til dine personoplysninger og sikre, at de ikke mistes,
        misbruges eller ændres ulovligt. De personoplysninger, du udleverer til
        Saverium.dk, bliver SSL-krypteret. Udvalgte og autoriserede medarbejdere har
        adgang til LVS Brokers filer med personoplysninger, men udelukkende i det omfang
        det er nødvendigt (&quot;need to know&quot;). Alle vores leverandører (inklusive
        Långiverne) er forpligtet til at træffe de datasikkerhedsforanstaltninger, der er
        standard for branchen, når de behandler dine personoplysninger på vores vegne.
        Øvrige foranstaltninger omfatter om fornødent firewalls, sikre faciliteter og
        adgangskontrolsystemer.
      </p>
      <h3>Dine rettigheder</h3>
      <p>
        Du har ret til at få indsigt i, dvs. til at få at vide, hvilke personoplysninger
        vi behandler om dig, ved at sende en anmodning herom til os på den ovenfor anførte
        adresse, og du har ret til at modtage en kopi af sådanne personoplysninger.
      </p>
      <p>
        Såfremt visse betingelser er opfyldt, har du ret til dataportabilitet. Efter
        anmodning vil vi levere de personoplysninger, der er omfattet af denne ret, i et
        almindeligt anvendt og maskinlæsbart format.
      </p>
      <p>
        Du har også ret til at få ukorrekte/upræcise, ufuldstændige, forældede eller
        unødvendige personoplysninger, som vi har opbevaret om dig, berigtiget, udfyldt
        eller slettet ved at kontakte os på den ovenfor anførte adresse. Hvis du anser de
        personoplysninger, som vi har indsamlet om dig, for at være urigtige, eller hvis
        du ikke ønsker dine personoplysninger slettet i tilfælde, hvor behandlingen af
        dine personoplysninger er blevet kendt ulovlig eller personoplysningerne ikke
        længere er nødvendige, eller hvis du har gjort indsigelse med behandlingen, og
        eksistensen af legitime grunde til behandlingen er ved at blive verificeret, kan
        du anmode om at få begrænset behandlingen af dine personoplysninger.
      </p>
      <p>
        Du har ret til at tilbagekalde dit samtykke til at modtage elektroniske direkte
        markedsføringshenvendelser fra os ved at klikke på frameldingslinket, som du kan
        finde i enhver markedsføringshenvendelse fra os, og vælge ikke at modtage
        markedsføringshenvendelser fra os i fremtiden. Du har også ret til at forbyde os
        at bruge dine personoplysninger til direkte markedsføringsformål,
        markedsundersøgelser og profilering ved at kontakte os på de ovenfor anførte
        adresser. Såfremt dine personoplysninger behandles på grundlag af dit samtykke, er
        du til enhver tid berettiget til at trække dit samtykke til en sådan behandling
        tilbage ved at sende en anmodning herom til den ovenfor anførte adresse.
      </p>
      <p>Under visse omstændigheder har du også ret til sletning.</p>
      <p>
        Bemærk venligst, at såfremt du forbyder os at behandle dine personoplysninger, vil
        vi måske ikke kunne fortsætte med at stille Saverium.dk til rådighed for dig.
      </p>
      <p>
        Såfremt du vurderer, at vores aktiviteter til behandling af dine personoplysninger
        er uforenelige med gældende databeskyttelseslovgivning, kan du indsende en klage
        til Datatilsynet.
      </p>
      <h3>Cookies-politik</h3>
      <p>
        Saverium.dk kan bruge &quot;cookies&quot; og andre teknologier, der er standard
        for branchen, som f.eks. pixel tags, web beacons og local shared objects (flash
        cookies) til at forbedre brugeroplevelsen, herunder særligt til at forbedre
        tjenesterne på vores hjemmeside.
      </p>
      <p>
        En cookie er en lille tekstfil, der lagres på Brugerens enhed med det formål at
        registrere oplysninger om Brugerens adfærd (anvendte browsere, besøgte hjemmesider
        osv.). Pixel tags og web beacons er små grafiske billeder, der placeres i
        Saverium.dk, og som gør det muligt for os at afgøre, om du har udført en specifik
        handling.
      </p>
      <p>
        Saverium.dk vil også kunne indeholde reklamer fra tredjeparter, der placerer
        cookies på din enhed (&quot;tredjeparts-cookies&quot;), således at dine
        online-aktiviteter på tværs af tredjepartssider eller online-tjenester kan spores
        og benyttes til reklameformål. Disse tredjepartstjenester er omfattet af særskilte
        persondatapolitikker.
      </p>
      <p>Cookie-filer anvendes af Saverium.dk til følgende formål:</p>
      <div class="container">
        <ol type="a">
          <li>
            at tilpasse indholdet af Saverium.dk til Brugernes præferencer og behov samt
            at optimere brugen af tjenesterne, herunder særligt at genkende Brugerens
            enhed og sikre en korrekt visning af Saverium.dk- siderne,
          </li>
          <li>
            at genkende og forstå Brugernes og de besøgendes brug af Saverium.dk, at skabe
            statistik med henblik på at forbedre Saverium.dks struktur og indhold,
          </li>
          <li>
            at opretholde Bruger-sessionen efter login med henblik på at undgå, at
            Brugeren er nødt til at indtaste login og password hver gang,
          </li>
          <li>at levere reklameindhold, der passer til Brugerens præferencer.</li>
        </ol>
      </div>
      <p><a name="cookies">På Saverium.dk anvender vi følgende typer cookies:</a></p>
      <div class="container">
        <ul>
          <li>
            &quot;persistente cookies&quot; – som er cookies, der forbliver lagret på
            Brugerens terminalenhed, indtil de fjernes af Brugeren, eller indtil de når en
            udløbsdato (defineret i cookie-filens parametre),
          </li>
          <li>
            &quot;sessionscookies&quot; – som er cookies, der automatisk slettes, når
            Brugeren lukker sin browser, slukker for enheden eller logger ud af den
            hjemmeside, der anvender disse cookies,
          </li>
          <li>
            &quot;tredjeparts-cookies&quot; – som er cookies fra de virksomheder, der
            samarbejder med LVS Brokers. Disse cookies gør det muligt at tilpasse
            reklamerne til Brugernes præferencer og adfærd eller at måle effektiviteten af
            reklameaktiviteterne.
          </li>
        </ul>
      </div>
      <p>
        Du kan til enhver tid vælge at indstille din browser til automatisk at blokere for
        cookies, slette dem eller advare dig, når der placeres cookies på din enhed. Hvis
        du gør dette, skal du være opmærksom på, at visse dele af Saverium.dk måske ikke
        fungerer korrekt. For mere specifikke oplysninger om cookies og om, hvordan man
        administrerer dem (f.eks. sletter eller blokerer dem), henviser vi til hjemmesiden
        for de relevante browsere (f.eks. Firefox). Bemærk, at du kan forhindre lagring af
        local shared objects ved at følge de instruktioner, som Adobe giver på
        <a
          href="http://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html"
        >
          http://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html.</a
        >
      </p>
      <p>
        Saverium.dk anvender Google Analytics, som er en tjeneste til analyse af brug af
        hjemmesider. Tjenesten leveres af Google, Inc. og kan anvendes til at udarbejde
        rapporter over de besøgendes adfærd samt til at hjælpe os med at forbedre
        Saverium.dk. For en beskrivelse af Google Analytics henviser vi til
        <a href=" http://www.google.com/analytics/"> http://www.google.com/analytics/.</a>
        Du kan fravælge Google Analytics ved hjælp af dette browser-værktøj:
        <a href="https://tools.google.com/dlpage/gaoptout"
          >https://tools.google.com/dlpage/gaoptout</a
        >
        .
      </p>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
a {
  text-decoration: underline;
}
</style>
