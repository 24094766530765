<template>
  <div>
    <div class="container container-faq col-lg-8 mt-5 mb-5">
      <h1>Tjenestevilkår (Erhvervslån) for Saverium.dk</h1>
      <br />
      <h3>1. Generelt om erhvervslån</h3>
      <p>
        Disse brugervilkår er gældende for den tjeneste til sammenligning af erhvervslån,
        der leveres af LVS Brokers Oy (“LVS Brokers” eller “vi”), samt for Saverium.dk's
        hjemmeside (både hjemmesiden og selve tjenesten Saverium.dk).
      </p>
      <p>
        Ved at besøge hjemmesiden og gøre brug af Saverium.dk's tjenester accepterer du,
        låneansøger, ("låneansøgeren”) disse brugervilkår.
      </p>
      <p>
        LVS Brokers har ikke bemyndigelse til at fremlægge, forhandle eller godkende
        låneaftaler på vegne af långivere eller på anden måde optræde som agent. Bemærk
        venligst, at LVS Brokers kun optræder som mellemled mellem låneansøgerens
        virksomhed og långiveren, og at LVS Brokers ikke selv bevilger lån eller træffer
        beslutninger vedrørende lån eller godkendelse af låneansøgninger.
      </p>
      <p>
        Bemærk venligst, at disse brugervilkår kun gælder for Saverium.dk og ikke for
        långivere.
      </p>
      <h3>2. Brug af Saverium.dk</h3>
      <p>
        Ved at bruge Saverium.dk's tjenester i forbindelse med en låneansøgning giver
        låneansøgeren LVS Brokers tilladelse til på vegne af låneansøgerens virksomhed og
        låneansøgeren at anmode om ét eller flere lånetilbud fra banker og långivere, der
        samarbejder med LVS Brokers. De fleste långivere er autoriseret og under tilsyn af
        det danske finanstilsyn. Se www.finanstilsynet.dk for yderligere oplysninger. LVS
        Brokers er ikke ansvarlig for långivers virksomhed.
      </p>
      <p>
        Det er helt gratis for låneansøgere at bruge Saverium.dk. LVS Brokers kan dog være
        berettiget til at modtage provision fra de banker og långivere, LVS Brokers
        samarbejder med. LVS Brokers' samarbejdspartnere kan findes
        <b-link :to="$root.rn.OurPartnersView">her</b-link>
      </p>
      <p>
        Låneansøgeren skal sikre, at dennes brugernavn, password og andre oplysninger, der
        udleveres i forbindelse med registreringen på hjemmesiden Saverium.dk, holdes
        fortrolige og opbevares sikkert, uden at tredjemand har adgang til dem. Hvis der
        er mistanke om, at tredjemand har fået uautoriseret adgang til dit brugernavn
        og/eller password, skal låneansøgeren omgående underrette LVS Brokers og ændre sit
        brugernavn og/eller password. Låneansøgeren er ansvarlig for sine egne
        ID-oplysninger i forbindelse med brugen af Saverium.dk's hjemmeside og tjenester
        samt for det brugernavn og/eller password, der er tildelt låneansøgeren.
      </p>
      <h3>3. Ansøgninger om erhvervslån</h3>
      <p>
        Låneansøgeren skal sikre, at alle de oplysninger, der udleveres i LVS Brokers'
        låneansøgning, er korrekte. Det er låneansøgerens ansvar at informere os om
        eventuelle ændringer, der måtte forekomme i låneansøgerens virksomhedsoplysninger.
      </p>
      <p>
        Låneansøgeren skal forstå, at dennes personoplysninger som f.eks.
        kontaktoplysninger, økonomiske oplysninger og oplysninger om tidligere lån og
        kreditter vil kunne blive rekvireret fra eksterne registre (f.eks. Experian), der
        er tilgængelige for långivere og LVS Brokers, til brug for bekræftelse af de
        låneansøgninger, som indsendes via tjenesten.
      </p>
      <p>
        LVS Brokers modtager og bekræfter låneansøgerens låneansøgning, videresender den
        til én eller flere långivere og tillader disse at sende et lånetilbud til
        låneansøgerens virksomhed. Låneansøgeren er hermed informeret om, at långiverne
        vil foretage en behandling af oplysningerne i låneansøgerens ansøgning med henblik
        på at evaluere låneansøgernes ansøgning.
      </p>
      <p>
        Vilkårene for lånet (herunder lånebeløb, rente, udgifter og tilbagebetalingsplan)
        fastsættes udelukkende af långiveren. Når låneansøgeren accepterer lånetilbuddet
        fra långiveren, accepterer låneansøgeren også långiverens lånevilkår. LVS Brokers
        er ikke part i låneaftalerne og har således ingen indflydelse på indholdet af
        låneaftalerne.
      </p>
      <p>
        Låneansøgeren gøres hermed opmærksom på,Saverium.dk bliver informeret om
        beslutningerne vedrørende låneansøgningen og lånetilbuddene, såfremt der indgås en
        låneaftale mellem låneansøgeren og långiveren. Långiverens beslutning kan sendes
        online direkte til den e-mailadresse, der er oplyst af låntageren i
        låneansøgningen, hvorfor det vigtigt, at låneansøgeren har oplyst korrekt e-mail
        adresse.
      </p>
      <p>
        Bemærk venligst, at enten vi eller långiverne efter eget skøn kan afvise
        låneansøgerens ansøgning. Långiveren kan også tilbyde låneansøgerens virksomhed et
        andet lån end det lån, som låneansøgeren har ansøgt om i låneansøgningen. Hverken
        vi eller långiveren kan give nogen garanti for, at låneansøgeren kan få bevilget
        et lån. Låneansøgeren kan frit vælge at acceptere eller afvise de lånetilbud,
        låneansøgeren modtager fra långiverne.
      </p>
      <p>
        Når låneansøgeren ansøger om et lån, skal låneansøgeren sørge for at sammenholde
        og estimere låneansøgerens virksomheds nuværende indtægter og udgifter, således at
        låneansøgeren kan beregne et månedligt tilbagebetalingsbeløb, der er realistisk
        for låneansøgerens virksomhed.
      </p>
      <h3>4. Immaterielle rettigheder</h3>
      <p>
        Alle Immaterielle Rettigheder (som defineret nedenfor) i relation til Saverium.dk
        og tilhørende dokumentation og alle dele og genparter deraf forbliver LVS
        Brokers&#39; og/eller dets underleverandørers/licensgiveres ejendom. Ved
        &quot;Immaterielle Rettigheder&quot; forstås ophavsrettigheder og relaterede
        rettigheder (herunder database-, katalog- og fotografirettigheder), patenter,
        brugsmodeller, designrettigheder, varemærker, handelsnavne, knowhow og enhver
        anden form for registreret eller ikke-registreret immateriel rettighed.
      </p>
      <p>
        Du opnår med disse Tjenestevilkår ingen Immaterielle Rettigheder til Saverium.dk,
        og enhver rettighed, som ikke udtrykkeligt gives dig i medfør af disse
        Tjenestevilkår, tilhører LVS Brokers og/eller dets underleverandører/licensgivere.
      </p>
      <h3>5. Ansvar</h3>
      <p>
        LVS Brokers garanterer ikke, at Saverium.dk vil fungere fejlfrit og uden mangler,
        forsinkelser, afbrydelser eller lignende, og LVS Brokers har intet ansvar i
        relation til sådanne fejl, mangler, forsinkelser, afbrydelser,
        (cyber-)sikkerhedsbrud eller lignende (herunder, men ikke begrænset hertil, tab af
        eller skade på data), uanset årsag, medmindre årsagen er grov uagtsomhed eller
        forsætlig misligholdelse fra LVS Brokers&#39; side.
      </p>
      <p>
        Med henvisning til pkt. 3 ovenfor understreges, at LVS Brokers agerer som
        formidler mellem dig og Långiverne, og at en eventuel aftale om lån alene indgås
        mellem dig og Långiveren. LVS Brokers har intet ansvar i relation til den mellem
        dig og Långiveren indgåede låneaftale, herunder, men ikke begrænset hertil, i
        relation til Långivers handlinger og undladelser. LVS Brokers forholder sig ikke
        til Långivernes lånetilbud og/eller låneaftaler og har intet ansvar for indholdet
        deraf eller de deri anførte vilkår og betingelser.
      </p>
      <h3>6. Ret til at træde tilbage fra Aftalen</h3>
      <p>
        LVS Brokers meddeler herved Brugerne, at de personoplysninger, der angives i
        ansøgninger om lånetilbud eller på anden måde indsamles ved brug af Saverium.dk,
        vil blive behandlet i overensstemmelse med gældende databeskyttelseslovgivning.
      </p>
      <h3>7. Øvrige bestemmelser</h3>
      <br />
      <p style="font-style: italic">Ændringer</p>

      <p>
        LVS Brokers har en eksklusiv ret til at bestemme indholdet af Saverium.dk og til
        til enhver tid at ændre eller opdatere indholdet heraf.
      </p>
      <p>
        LVS Brokers kan ændre disse Tjenestevilkår med mindst 30 dages forudgående varsel
        til Brugeren ved meddelelse på LVS Brokers&#39; hjemmeside eller pr. mail (hvis
        muligt). Hvis Brugeren ikke accepterer ændringerne, kan Brugeren ikke længere gør
        brug af Saverium.dk.
      </p>
      <p style="font-style: italic">Force majeure</p>
      <p>
        Hverken LVS Brokers og Brugeren ifalder ansvar for manglende overholdelse af disse
        Tjenestevilkår, hvis den manglende overholdelse skyldes årsager uden for deres
        eller deres leverandørers kontrol, herunder, men ikke begrænset til, krig,
        opstand, oprør eller anden civil ulydighed, karantænerestriktioner, faglig
        konflikt undtagen inden for hver parts organisation, svigt af eller forsinkelse i
        transport, ulykke, oversvømmelse, jordskælv, brand, storm, nogen form for
        myndighedsindgriben, judicielle tiltag eller ændring af lovgivning.
      </p>
      <p style="font-style: italic">Lovvalg og konfliktløsning</p>
      <p>
        Disse Tjenestevilkår er underlagt og skal fortolkes i overensstemmelse med dansk
        ret, bortset fra sådanne regler i dansk lovgivning, der kan føre til anvendelsen
        af retsreglerne i en anden jurisdiktion end Danmark
      </p>
      <p>
        Enhver uoverensstemmelse eller tvist mellem parterne vedrørende disse
        Tjenestevilkår skal afgøres af Københavns Byret.
      </p>
      <p>
        Brugeren har ret til at indgive klage over Saverium.dk ved anvendelse af
        nedenstående procedure. Klageproceduren forhindrer ikke Brugeren i - og begrænser,
        suspenderer eller afskærer heller ikke Brugerens ret til - at benytte sig af
        Brugerens rettigheder i henhold til gældende lovgivning. Klager over Saverium.dk
        kan fremsendes pr. mail til følgende adresse: kundeservice@saverium.dk. LVS
        Brokers vil orientere Brugeren om resultatet af klagesagens behandling ved
        anbefalet brev eller pr. mail til den adresse, Brugeren har anført, inden 14 dage
        fra LVS Brokers&#39; modtagelse af klagen.
      </p>
      <p>
        Eventuelle bemærkninger eller forespørgsler vedrørende Saverius&#39; funktioner
        skal sendes til følgende mailadresse: kundeservice@saverium.dk.
      </p>
      <p>
        Alternativt kan Brugeren gøre brug af alternativ konfliktløsning og henvise en
        tvist i overensstemmelse med Europa-Parlamentets og Rådets forordning (EU) nr.
        524/2013 af 21. maj 2013 om onlinetvistbilæggelse i forbindelse med tvister på
        forbrugerområdet. Forbrugeren kan også indgive klage via EU&#39;s Online Dispute
        Resolution platform, som kan findes
        <a href="http://ec.europa.eu/consumers/odr/" target="_blank">her</a>.
      </p>
      <br />
      <p>
        Disse Tjenestevilkår kan findes på Saverium.dk i et format, der gør det muligt at
        downloade, gemme eller printe dem.
      </p>
      <p>Kommunikation mellem Brugeren og LVS Brokers skal være på Dansk.</p>
      <p>
        LVS Brokers Oy<br />
        PL 644 FI-00101 Helsinki<br />
        Finland<br />
        <a href="https://www.saverium.dk">https://www.saverium.dk</a><br />
        Kundeservice: kundeservice@saverium.dk<br />
        Registreringsnummer i det finske handelsregister: 2521965-8
      </p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: underline;
}
</style>
