import { render, staticRenderFns } from "./Loan50000Kronor.vue?vue&type=template&id=4397c84b&"
import script from "./Loan50000Kronor.vue?vue&type=script&lang=js&"
export * from "./Loan50000Kronor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports