<template>
  <div>
    <div class="sms-container mt-5 mb-5">
      <h5 class="p">{{ $t('we_sent_you_six_digit_code') }} {{ this.phone }}</h5>
      <h6>{{ $t('please_enter_the_code_below') }}</h6>
      <span class="error-text" v-if="error">{{ $t('Invalid') }}</span>

      <div>
        <b-input-group size="md" class="mb-2 code-input-group">
          <b-form-input
            id="first-box"
            type="text"
            inputmode="numeric"
            class="code-input"
            :class="error ? 'has-error' : ''"
            maxlength="1"
            v-on:keyup="$event.target.nextElementSibling.focus()"
            v-model="first"
            @input="removeError()"
          ></b-form-input>
          <b-form-input
            id="second-box"
            type="text"
            inputmode="numeric"
            class="code-input"
            :class="error ? 'has-error' : ''"
            maxlength="1"
            v-on:keyup="$event.target.nextElementSibling.focus()"
            v-model="second"
            @input="removeError()"
          ></b-form-input>
          <b-form-input
            id="third-box"
            type="text"
            inputmode="numeric"
            class="code-input"
            :class="error ? 'has-error' : ''"
            maxlength="1"
            v-on:keyup="$event.target.nextElementSibling.focus()"
            v-model="third"
            @input="removeError()"
          ></b-form-input>
          <b-form-input
            id="fourth-box"
            type="text"
            inputmode="numeric"
            class="code-input"
            :class="error ? 'has-error' : ''"
            maxlength="1"
            v-on:keyup="$event.target.nextElementSibling.focus()"
            v-model="fourth"
            @input="removeError()"
          ></b-form-input>
          <b-form-input
            id="fifth-box"
            type="text"
            inputmode="numeric"
            class="code-input"
            :class="error ? 'has-error' : ''"
            maxlength="1"
            v-on:keyup="$event.target.nextElementSibling.focus()"
            v-model="fifth"
            @input="removeError()"
          ></b-form-input>
          <b-form-input
            id="sixth-box"
            type="text"
            inputmode="numeric"
            maxlength="1"
            class="code-input"
            :class="error ? 'has-error' : ''"
            v-on:keyup="enableContinue()"
            v-model="sixth"
            @input="removeError()"
          ></b-form-input>
        </b-input-group>
      </div>
      <p class="enter-phone" v-b-toggle.phone-collapse>
        {{ $t('click_here_if_your_phone_number_is_incorrect') }}
      </p>
      <b-collapse id="phone-collapse">
        <p style="margin-bottom: 0px">
          {{ $t('please_enter_your_correct_mobile_phone_number') }}
        </p>

        <div class="form-group phone-field row" @input="removeError">
          <form-input
            type="phone"
            storekey="new_phone_number"
            inputmode="numeric"
            name="phone"
            autocomplete="tel"
            prepend="+48"
            id="phone"
            placeholder="np. 123456789"
          >
            {{ $t('phone_number') }}
          </form-input>
          <button
            class="btn btn-primary send-code"
            @click="submitPhoneNumber"
            :disabled="phoneDisabled"
          >
            {{ $t('send') }}
          </button>
        </div>
        <div class="container">
          <span class="error-text" v-if="errorInPhone">{{
            $t('something_went_wrong_pls_try_again_later')
          }}</span
          ><br />
          <span class="error-text" v-if="throttlingOn"
            >{{ $t('sent_too_many_messages_chill') }} {{ initialTime }}
            {{ $t('seconds') }}</span
          >
        </div>
      </b-collapse>
      <div class="text-center" v-if="disableButton">
        <b-spinner class="loading-spinner" label="Loading"></b-spinner>
      </div>
      <button
        v-else
        class="next-btn btn btn-primary"
        name="submitCode"
        id="submitCode"
        @click="submitCode()"
        :disabled="submitDisabled"
      >
        {{ $t('next') }}
      </button>
    </div>
  </div>
</template>

<script>
import FormInput from '@/components/form/FormInput'
export default {
  data() {
    return {
      submitDisabled: true,
      first: null,
      second: null,
      third: null,
      fourth: null,
      fifth: null,
      sixth: null,
      error: false,
      verificationNumber: 0,
      updatedNumber: '',
      errorInPhone: false,
      throttlingOn: false,
      countDown: 0,
      initialTime: 0,
      phoneDisabled: false,
    }
  },
  components: {
    FormInput,
  },
  computed: {
    disableButton: {
      get() {
        return this.$store.state.application.disableButton
      },
      set(disableButton) {
        this.$store.state.application.disableButton = disableButton
      },
    },
    phone: {
      get() {
        return this.$store.state.application.phone
      },
      set(phone) {
        this.$store.state.application.phone = phone
      },
    },
    newPhone: {
      get() {
        return this.$store.state.application.new_phone_number
      },
      set(newPhone) {
        this.$store.state.application.new_phone_number = newPhone
      },
    },
    codeVerified() {
      return this.$store.state.application.code_verified
    },
    waitingTime() {
      return this.$store.state.application.waitingTime
    },
    numberUpdated() {
      return this.$store.state.application.numberUpdated
    },
    throttlingEnabled() {
      return this.$store.state.application.throttlingEnabled
    },
  },
  methods: {
    enableContinue() {
      this.verificationNumber =
        this.first + this.second + this.third + this.fourth + this.fifth + this.sixth
      if (this.verificationNumber.toString().length === 6) {
        this.submitDisabled = false
      } else {
        this.addError()
      }
    },
    submitCode() {
      if (this.verificationNumber.length === 6) {
        this.$store.commit('setValue', { key: 'disableButton', value: true })
        this.$store.commit('setValue', {
          key: 'verificationNumber',
          value: this.verificationNumber,
        })

        this.$store
          .dispatch('verifyPhoneNumber', {
            verificationNumber: this.verificationNumber,
          })
          .then(this.showErrorStatus)
      }
    },
    submitPhoneNumber() {
      this.updatedNumber = this.$store
        .dispatch('updatePhoneNumber', {
          phone: this.newPhone,
        })
        .then(this.showUpdatedStatus)
    },
    addError() {
      this.error = true
      this.submitDisabled = true
    },
    removeError() {
      this.error = false
      if (this.verificationNumber.toString().length === 6) {
        this.submitDisabled = false
      }
      if (this.errorInPhone === true) {
        this.errorInPhone = false
      }
    },
    showErrorStatus() {
      this.submitDisabled = false
      if (this.code_verified === false) {
        this.addError()
      }
    },
    showUpdatedStatus() {
      if (this.numberUpdated === false) {
        this.errorInPhone = true
        if (this.throttlingEnabled) {
          this.throttlingOn = true
          this.phoneDisabled = true
          this.initialTime = parseInt(this.waitingTime)
          setInterval(this.setTime, 1000)
        }
      }
      if (this.numberUpdated === true) {
        this.$store.commit('setValue', { key: 'phone', value: this.newPhone })
        this.$root.$emit('bv::toggle::collapse', 'phone-collapse')
      }
    },
    setTime() {
      this.initialTime -= 1
      if (this.initialTime <= 0) {
        clearInterval(this.waitingTime)
        this.throttlingOn = false
        this.errorInPhone = false
        this.phoneDisabled = false
        this.initialTime = 0
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.sms-container {
  max-width: 90vw;
  text-align: center;
  margin: auto;
}
.code-input {
  margin-right: 1em;
  @media all and (max-width: 328px) {
    margin-right: 0.2em;
  }
}

.code-input-group {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: auto;
  max-width: 300px;
  width: 100%;
}
.enter-phone {
  cursor: pointer;
  text-decoration-line: underline;
}
.next-btn {
  width: 100%;
  max-width: 180px;
  margin-top: 2rem;
}

.btn-primary:disabled {
  color: #fff;
  background-color: #c5c5c5;
  border-color: #bebebe;
  background-image: none;
  cursor: auto;
}
.phone-field {
  margin: auto;
  width: fit-content;
  margin-bottom: 2rem;
  display: inline-flex;
  align-items: flex-end;
}
.send-code {
  height: fit-content;
  margin-left: 0.5rem;
  @media all and (max-width: 350px) {
    margin-left: 0;
  }
}

.has-error {
  border-color: #dc3545;
}
.error-text {
  color: #dc3545;
}
</style>
