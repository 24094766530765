<template>
  <div>
    <div class="article">
      <h1 class="center-title">Erhvervslån</h1>
      <div class="top-text text-container">
        <p>
          Vi hjælper dig med at finde de bedste erhvervslån. Ansøg om lån til erhverv
          gennem os ved at udfylde ansøgningen her på siden. Herefter vil vi på få
          minutter indhente tilbud på erhvervslån blandt vores mange samarbejdspartnere.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#ansoeg-om-erhvervslaan-igennem-os"
                    >Ansøg om erhvervslån igennem os</a
                  >
                </li>
                <li>
                  <a href="#hvordan-finder-man-de-billigste-laan-til-erhverv"
                    >Hvordan finder man de billigste lån til erhverv?</a
                  >
                </li>
                <li>
                  <a href="#hvad-kan-man-bruge-et-erhvervslaan-til"
                    >Hvad kan man bruge et erhvervslån til?</a
                  >
                </li>
                <li><a href="#erhvervslaan-til-opstart">Erhvervslån til opstart</a></li>
                <li>
                  <a href="#hvad-er-loebetiden-paa-erhvervslaan"
                    >Hvad er løbetiden på erhvervslån?</a
                  >
                </li>
                <li>
                  <a href="#erhvervslaan-uden-at-stille-sikkerhed"
                    >Erhvervslån uden at stille sikkerhed</a
                  >
                </li>
                <li>
                  <a href="#hvorfor-benytte-os-til-at-finde-det-rette-erhvervslaan"
                    >Hvorfor benytte os til at finde det rette erhvervslån</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="ansoeg-om-erhvervslaan-igennem-os">Ansøg om erhvervslån igennem os</h2>
          <p>
            Hos os hjælper vi dig, med at finde og sammenligne erhvervslån. Lån til
            erhverv behøver ikke være besværligt eller uoverskueligt. Vi har nemlig bygget
            en platform, hvor udbydere af erhvervslån og ansøgere til erhvervslån mødes.
            Det eneste det kræver, fra dig som erhvervsdrivende med finansieringsbehov, er
            at du udfylder vores korte ansøgningsformular. Herefter vil alle
            erhvervslångivere, som er koblet på vores platform, vende tilbage med
            sammenlignelige lånetilbud til dig.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvordan-finder-man-de-billigste-laan-til-erhverv">
            Hvordan finder man de billigste lån til erhverv?
          </h2>
          <p>
            Hvis du er på udkig efter det billigste og bedste erhvervslån med lav rente,
            kan vores service hjælpe dig. Hvis du vælger os, tager du udgangspunkt i en
            sammenligning af erhvervslån. Vi vil typisk opstille dine muligheder, alt
            efter hvad erhvervslånet koster (AOP), og hvor du får den laveste rente på
            erhvervslånet, da vi mener at det er to vigtigste parameter for et lån.
          </p>
          <p>
            Din rente på erhvervslånet bliver udregnet af vores bankpartnere på baggrund
            af nøgletal fra din virksomhed, samt en risikovurdering af din forretning.
            Derfor oplever vi også, at renten på erhvervslån kan variere meget mere, end
            hvad vi ser på
            <a
              href="https://saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >.
          </p>
          <p>
            En anden almindelig metode for at få bevilliget et erhvervslån, er at tage sin
            forrentningsplan med i banken, og høre om banken ønsker at stille et
            erhvervslån til rådighed for dig. Dette kræver dog du stiller sikkerhed for
            lånet og kan præsentere en meget velforberedt forretningsplan. At opsøge en
            bank for at optage et mindre erhvervslån kan være besværligt, da du skal kunne
            sælge banken din forretningside, og måske er det ikke lige alle, som synes at
            din ide lyder overbevisende. Banken vil desuden også typisk have højere renter
            på deres lån.
          </p>
          <p>
            Du kan også få et erhvervslån uden sikkerhed, og disse tilbydes af flere
            banker såvel finansieringsselskaber. Disse lån kan også kombineres, og sammen
            med et banklån kan du optage et <strong>virksomhedslån</strong> uden
            sikkerhed, som fungerer som sikkerhed eller som yderligere finansiering. Et
            erhvervslån uden sikkerhed hjælper dig med at få finansiering til din
            virksomhed hurtigt og ubesværet.
          </p>
          <p>
            Når du overvejer at finansiere din virksomhed, er det værd at huske at
            overveje de forskellige lånemuligheder og deres betingelser, for at få det
            bedste og billigste erhvervslån.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-kan-man-bruge-et-erhvervslaan-til">
            Hvad kan man bruge et erhvervslån til?
          </h2>
          <p>
            Så snart du har fået udbetalt lånet til din konto, er du herre over hvad lånet
            bruges til. Hvad du ønsker at bruge erhvervslånet til, er helt op til dig
            selv, så længe at det bruges på din virksomhed. Det er vigtigt at et lån til
            erhverv udelukkende bruges i firmasammenhæng. Mm det så er til løn,
            kontorartikler, nye maskiner eller endda et underskud der skal dækkes er
            underordnet.
          </p>
          <h3>Dæk et underskud</h3>
          <p>
            Hvis du optager et erhvervslån, som skal bruges til at dække et underskud, er
            det vigtigt at vurdere om du kan tilbagebetale beløbet, og at nedgangen for
            din virksomhed ikke fortsætter.
          </p>
          <h3>Ekstra personale</h3>
          <p>
            Du kan bruge et erhvervslån på dit personale, for eksempel på at hyre en ny
            medarbejder til virksomheden eller aflønne de nuværende medarbejder.
          </p>
          <h3>Udstyr og inventar</h3>
          <p>
            Måske har du brug for et <strong>firmalån</strong> til ny inventar, så som
            kontorstole, computer eller andet IT relaterede udstyr, som kan forbedre dine
            medarbejders arbejdsforhold, så de kan optimere resultaterne.
          </p>
          <h3>Markedsføring</h3>
          <p>
            Du kan også anvende dit erhvervslån til at markedsføre dine produkter/services
            og dermed øge kendskabet til din virksomhed, hvilket kan resultere i større
            salg og mere overskud.
          </p>
          <h3>Erhvervsejendomme</h3>
          <p>
            En sidste meget populær årsag til at optage et erhvervslån, er for at
            opgradere dine lokaler. Lokaler er en stor investering, som de fleste
            virksomheder ikke selv har mulighed for at købe kontant. Derfor søger mange
            virksomhedsejere eller CFOs, i den forbindelse,
            <strong>lån til erhvervsejendom</strong>. Hvis du har brug for at leje eller
            købe en mindre lokaler, fx som led i en opstartsfasen af din virksomhed, så er
            vores service perfekt til dit formål.
          </p>
        </div>

        <div class="text-container">
          <h2 id="erhvervslaan-til-opstart">Erhvervslån til opstart</h2>
          <p>
            ”Hvad gør jeg hvis jeg vil starte en virksomhed”, “Skal jeg optage et lån for
            at starte en virksomhed?”, tænker man i starten. Det vigtigste ved at starte
            en forretning er i første omgang ikke et lån til at opstarte din virksomhed,
            men derimod en god forretningsplan. Forretningsplanen giver dig mulighed for
            at komme langt med at realisere din vision samt holde dine planer og budget
            under kontrol.
          </p>
          <p>
            Forretningsplanen skal altid være så realistisk som muligt, så store planer
            ikke mislykkes. Herefter kan du begynde at overveje lånet til at opstarte din
            virksomhed, hvis det kræver mere kapital, end du måtte have bootstrappet dig
            til. Derfor er godt at vide, hvad din virksomhed potentielt kan opnå i
            starten, hvem målgruppe er, hvordan virksomhedens forretning vil blive ført,
            og hvor meget hele virksomhedens drift vil koste. Dette kan din
            forretningsplan kan hjælpe dig med at få et overblik over.
          </p>
          <p>
            At starte en virksomhed og skaffe finansiering til din egen idé kan være en
            stor tærskel for mange nystartede. Når du
            <strong>låner som iværksætter</strong>, kræver det ikke blot hårdt arbejde,
            men også meget mod. Med en god forretningsplan er det lettere at træde ind i
            det ukendte og give din forretningside en chance. Mangel på kapital er
            desværre et problem for de fleste nystartede virsomheder, og erhvervslån i
            forbindelse med opstarten er derfor ikke unormal.
          </p>
          <p>
            Vi hjælper dig gerne, uanset om du leder efter et erhvervslån til at opstarte
            en ny virksomhed, eller du allerede er etableret og har brug for et
            <strong>SMV-lån</strong>.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-er-loebetiden-paa-erhvervslaan">
            Hvad er løbetiden på erhvervslån?
          </h2>
          <p>
            Løbetiden, også kaldt lånetiden, er det tidsrum du har til at kunne
            tilbagebetale dit lån. Løbetiden på erhvervslån varierer i høj grad af, hvor
            stort et beløb du ønsker at optage.
          </p>
          <p>
            Som regel gælder det for erhvervslån, at løbetiden skal reflektere
            investeringens levetid. Hvis du eventuelt har
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >lånt pengene</a
            >
            til en firmabil, ny maskine eller ligne, og det forventes at den først skal
            udskiftes efter fem år, så vil løbetiden på dit erhvervslån som regel også
            være fem år.
          </p>
        </div>

        <div class="text-container">
          <h2 id="erhvervslaan-uden-at-stille-sikkerhed">
            Erhvervslån uden at stille sikkerhed
          </h2>
          <p>
            De fleste steder, hvor man som erhvervsdrivende ansøger om et erhvervslån,
            kræver långiveren at der stilles en form for sikkerhed i lånet. Der kan både
            være fordele og ulemper ved et erhvervslån uden sikkerhed og med sikkerhed.
          </p>
          <p>
            Hvis du hæfter personligt for lånet, risikerer du at gå personligt konkurs,
            hvilket er en situation, som de færreste ønsker. Det kan også gå mindre galt,
            men hvis du har stillet sikkerhed i fx din erhvervsejendom eller dit
            produktionsapparat, kan det ligeledes have store konsekvenser for dig og din
            virksomhed, hvis du ikke kan opretholde betalingerne på dit erhvervslån.
          </p>
          <p>
            Hvis du vælger et erhvervslån uden sikkerhed, vil din og din virksomheds
            risiko begrænses. I nogle tilfælde, vil renten på erhvervslånet dog være
            højere, da risikoen for banken / långiveren er større. Det er dog ikke altid,
            at dette er tilfældet. Hvis du har mulighed for at optage et erhvervslån uden
            sikkerhed, som ellers har samme eller bedre vilkår end et tilsvarende
            erhvervslån med sikkerhed, bør du altid vælge erhvervslånet uden sikkerhed.
          </p>
          <h3>Erhvervslån trods RKI</h3>
          <p>
            Hvis du ønsker at låne til erhverv, men du samtidig står i RKI, kan du støde
            ind i problemer. Ofte vil virksomhedens økonomi ofte blive checket af
            långiveren først. Hvis nøgletallene ser fornuftige ud, vil du i nogle tilfælde
            ikke blive checket personligt, og i det tilfælde er et erhvervslån trods RKI
            muligt.
          </p>
          <p>
            Hvis nøgletallene i din virksomhed ikke i sig selv tegner et tydeligt billede
            af en sund virksomhed, vil du som privatperson typisk blive checket for
            RKI-status. I det tilfælde er et erhvervslån på trods af RKI ikke muligt.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvorfor-benytte-os-til-at-finde-det-rette-erhvervslaan">
            Hvorfor benytte os til at finde det rette erhvervslån
          </h2>
          <p>
            Igennem os kan du ansøge om et erhvervslån online uden at stille sikkerhed. Vi
            gør det overskueligt for dig at vælge et erhvervslån med lav rente, som passer
            bedst til din situation, uanset om det er et lån til
            <strong>lån til landbrug</strong>,
            <strong>lån til at åbne en ny butik</strong>,
            <strong>lån til din restaurant</strong>, eller noget helt fjerde
            <strong>B2B-lån</strong>.
          </p>
          <p>
            Når du har færdiggjort din ansøgning, finder vi de bedste tilbud på
            erhvervslån, som vores lånepartner kan tilbyde. Vi samler alle tilbuddene et
            sted med én enkelt ansøgning fra dig, og præsenterer dem på en overskuelig og
            sammenlignelig side. Du kan herefter i ro og mag vælge det hurtigste,
            billigste eller bedste erhvervslån - alt efter dine præferencer.
          </p>
          <p>
            Så hvad venter du på? Vores låneberegner til erhvervslån er klar til brug for
            dig, så du nemt og hurtigt kan finde gode og uforpligtende lånemuligheder til
            dit erhverv og din virksomhed.
          </p>
          <p>
            Det tager blot ét minut at udfylde ansøgningen. Selv hvis du er i tvivl om,
            hvorvidt du ønsker at optage et erhvervslån eller ej, så er det fordelagtigt
            at få beregnet dine muligheder. Hvis du ønsker at sammenligne renter på
            erhvervslån, kan du med fordel starte her.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },

  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
