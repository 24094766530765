export default {
  days: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá', 'Do'],
  months: [
    'Enero.',
    'Feb.',
    'Mar.',
    'Abr.',
    'Mayo.',
    'Jun.',
    'Jul.',
    'Agosto.',
    'Sept.',
    'Oct.',
    'Nov.',
    'Dic.',
  ],
  placeholder: {
    date: 'La fecha',
  },
}
