<template>
  <div>
    <div class="article">
      <h1 class="center-title">Fellow Bank lån 4.000 – 100.000 kr.</h1>
      <div class="top-text text-container">
        <ul>
          <li>Lån mellem 4.000 – 100.000 kr.</li>
          <li>Låneafgørelse indenfor 24 timer på hverdage.</li>
          <li>Lån uden sikkerhed.</li>
          <li>Underskriv hurtigt og enkelt med NemID/MitID.</li>
        </ul>
        <p>
          Fellow Bank tilbyder fleksible og gennemsigtige
          <a
            href="https://www.saverium.dk/laan-penge/forbrugslaan"
            rel="noopener noreferrer"
            target="_blank"
            >forbrugslån</a
          >, der passer til ethvert behov. Hos Fellow Bank er der hverken skjulte
          omkostninger eller ubehagelige overraskelser. Fellow Bank tilbyder lån på mellem
          4.000 og 100.000 kr. med en tilbagebetalingsperiode på mellem 18 og 120 måneder.
          På den måde har du selv indflydelse på lånets rammer, der også under lånets
          løbetid kan tilpasses dine økonomiske behov.
        </p>
        <p>
          Du har til hver en tid mulighed for at tilbagebetale lånet før tid, og dermed er
          du ikke bundet af den løbetid, som er på dit lån. I forbindelse med
          <strong>låneansøgningen </strong>skal du vedlægge dokumentation for, at du har
          en regelmæssig indkomst, og at du dermed har råd til at betale de månedlige
          afdrag.
        </p>
        <p>
          Siden Fellow Bank tilbyder
          <a
            href="https://saverium.dk/laan-penge/privatlaan/uden-sikkerhed"
            rel="noopener noreferrer"
            target="_blank"
            >lån uden sikkerhed</a
          >, bestemmer du selv hvad du vil bruge dit lån på. Du kan bruge lånet til at
          <strong>renovere hus</strong> eller lejlighed, eller endda vælge at tage på den
          <strong>drømmeferie</strong>, som du eller I altid har tænkt på. Dermed er dine
          muligheder for hvad du kan bruge dit nye
          <a
            href="https://www.saverium.dk/laan-penge/privatlaan"
            rel="noopener noreferrer"
            target="_blank"
            >privatlån</a
          >
          på uendelig, så længe du tilbagebetaler lånet til tiden, eller indenfor den
          aftale låneperiode.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#fellow-bank-rente-og-laanevilkaar"
                    >Fellow Bank rente og lånevilkår</a
                  >
                </li>
                <li>
                  <a href="#saadan-ansoeger-du-om-et-laan-hos-fellow-bank"
                    >Sådan ansøger du om et lån hos Fellow Bank</a
                  >
                </li>
                <li><a href="#fellow-bank">Fellow Bank</a></li>
                <li>
                  <a href="#laanevilkaar-hos-fellow-bank">Lånevilkår hos Fellow Bank</a>
                </li>
                <li>
                  <a href="#laaneeksempel-hos-fellow-bank"
                    >Låneeksempel hos Fellow Bank</a
                  >
                </li>
                <li><a href="#fellow-bank-kundeservice">Fellow Bank kundeservice</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="fellow-bank-rente-og-laanevilkaar">Fellow Bank rente og lånevilkår</h2>
          <p><strong>Lånebeløb: </strong>4.000 – 100.000 kr</p>
          <p><strong>Låneperiode: </strong>1 og et halvt år til 10 år</p>
          <p><strong>Variabel rente: </strong>Fra 2,95%, afdrag fra 150 kroner</p>
          <p><strong>Behandlingstid: </strong>Indenfor 24 timer på hverdage</p>
          <p><strong>Flexible lånevilkår: </strong>Ja</p>
          <p></p>
        </div>

        <div class="text-container">
          <h2 id="saadan-ansoeger-du-om-et-laan-hos-fellow-bank">
            Sådan ansøger du om et lån hos Fellow Bank
          </h2>
          <p>
            Udfyld den online ansøgning på få minutter, og sammenlign de forskellige
            tilbud til lån, du skulle modtage på din brugerkonto.
          </p>
          <ul>
            <li>
              <strong>Udfyld ansøgningen med det ønskede beløb og løbetid.</strong> Vælg
              <a
                href="https://www.saverium.dk/laan-penge/beloeb"
                rel="noopener noreferrer"
                target="_blank"
                >lånebeløb</a
              >
              og den låneperiode, der passer til dit ønskede lån.
            </li>
            <li>
              <strong>Angiv dine andre oplysninger.</strong> Udfyld din ansøgning med de
              andre nødvendige oplysninger, som du bliver spurgt om.
            </li>
            <li>
              <strong>Indsend din ansøgning</strong>. Send din udfyldte ansøgning. Vi
              indhenter derefter de skræddersyet
              <a
                href="https://www.saverium.dk/laan-penge/sammenligning"
                rel="noopener noreferrer"
                target="_blank"
                >lånetilbud</a
              >
              til dig fra vores samarbejdspartnere, og du får straks svar på din
              ansøgning.
            </li>
            <li>
              <strong>Accepter det ønskede tilbud</strong>. Sammenlign og accepter det
              ønskede tilbud, der passer til dig. Du vil derefter blive omdirigeret til
              selve
              <a
                href="https://www.saverium.dk/laan-penge/laaneudbydere"
                rel="noopener noreferrer"
                target="_blank"
                >låneudbyderen</a
              >, hvor du skal give samtykke via
              <a
                href="https://www.saverium.dk/laan-penge/privatlaan/laan-med-mitid"
                rel="noopener noreferrer"
                target="_blank"
                >NemID/MitID</a
              >, til at de må lave en <strong>kreditvurdering</strong> af dig
            </li>
            <li>
              <strong>Lånet vil blive overført din konto</strong>. Det ønskede lån, som du
              har accepteret og er blevet godkendt til, vil derefter blive overført til
              din konto hurtigt som muligt, hvis du vælger et lån hos Fellow Bank.
            </li>
          </ul>
        </div>

        <div class="text-container">
          <h2 id="fellow-bank">Fellow Bank</h2>
          <p>
            Fellow Bank blev stiftet i 2013 i Finland og er tidligere kendt som Fellow
            Finance. Fellow Bank er en internetbaseret bank med nordiske rødder, der
            hjælper privatpersoner med finansiering til alle slags behov. Fellow Bank har
            derfor god erfaring indenfor de forskellige lånetyper inkl.
            <a
              href="https://www.saverium.dk/laan-penge/samlelaan"
              rel="noopener noreferrer"
              target="_blank"
              >samlelån</a
            >, og med fleksible lånevilkår kan lånet skræddersyes efter dine behov.
          </p>
          <p>
            Du kan derfor være sikker på at få rigtig god service, når du søger efter et
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >nyt lån</a
            >
            hos Fellow Bank. Derudover finder du lånevilkår som fleksibel tilbagebetaling,
            og der bliver ikke bliver holdt øje med hvad du bruger lånet til.
          </p>
          <p>
            Når du anvender en sammenligningstjenste som vores, kan du få tilbud fra mere
            end 10 forskellige banker og andre velkendte
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere</a
            >
            udover Fellow Bank. Det koster ingenting, og du forpligter dig ikke til at
            acceptere tilbuddet. Du kan indsende en gratis og uforpligtende låneansøgning
            på få minutter, og efter sammenligne
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >lånetilbud fra flere banker</a
            >.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laanevilkaar-hos-fellow-bank">Lånevilkår hos Fellow Bank</h2>
          <p>
            For at kunne leve op til kravene hos Fellow Bank skal du leve op til disse 4
            vilkår:
          </p>
          <ul>
            <li>Du skal have fast bopæl i Danmark og have dansk CPR-nummer.</li>
            <li>Du skal være fyldt 24 år.</li>
            <li>Du skal have et NemID/MitID</li>
            <li>Du må ikke være registreret i Debitor Registret eller RKI.</li>
          </ul>
          <p>
            Udover det vil der som altid blive foretaget en kreditvurdering af din
            økonomi, som i sidste ende vil ligge til grund for den endelige
            lånebeslutning.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laaneeksempel-hos-fellow-bank">Låneeksempel hos Fellow Bank</h2>
          <p>
            <strong>Låneeksempel</strong>: Et lån på 80.000 kr. med 7 års løbetid og en
            årlig rente på 8,00 %, har en effektiv rente på 12,82 % inkl. oprettelse gebyr
            på 5 % af lånekapitalen. Det samlede beløb, der skal betales (inkl.
            amortisering, rente og gebyrer) udgør 113.114,50 kr. fordelt på 84
            afbetalinger, hvilket svarer til 1.346,96 kr./mdr.
          </p>
          <p>
            Eksemplet er beregnet d.23/01-2019 og forudsætter, at renter og gebyrer er
            uændrede i løbet af kreditperioden.
          </p>
        </div>

        <div class="text-container">
          <h2 id="fellow-bank-kundeservice">Fellow Bank kundeservice</h2>
          <p>
            Fellow Bank kan du kontakte alle hverdage klokken 9:00 – 16:00 på telefon +45
            36 39 58 99 eller via deres e-mail
            <a
              href="mailto:kundeservice@fellowbank.dk"
              rel="noopener noreferrer"
              target="_blank"
              >kundeservice@fellowbank.dk</a
            >
          </p>
          <p>
            Skulle du eventuelt have spørgsmål vedrørende din ansøgning, eller hvordan
            hele processen forgår kan du kontakte os i hverdagene mellem 09:00 - 15:00 på
            telefon: 93 75 99 59. Du er os velkommen til at kontakte os ved at skrive til
            os på:
            <a
              href="mailto:kundeservice@saverium.dk"
              rel="noopener noreferrer"
              target="_blank"
              >kundeservice@saverium.dk</a
            >.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
