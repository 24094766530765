<template>
  <div>
    <div class="article">
      <h1 class="center-title">Værkstedsregning på afbetaling</h1>

      <div class="top-text text-container">
        <p>
          Leder du efter et sted hvor du kan få din værkstedsregning på afbetaling? Vi
          finder ikke et værksted med afbetaling for dig, men vi kan hjælpe dig med at
          finde en lang række billige alternativer til et værksted med afbetaling. Hos os
          hjælper vi mange gange dagligt vores brugere med at finde de billigste og bedste
          lån på markedet. Ansøg på 2 minutter, og se hvor godt et lån du kan få.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a
                    href="#hvordan-finder-vi-de-bedste-alternativer-til-vaerksteder-med-afbetaling"
                    >Hvordan finder vi de bedste alternativer til værksteder med
                    afbetaling?</a
                  >
                </li>
                <li>
                  <a
                    href="#hvornaar-har-du-brug-for-at-faa-vaerkstedsregningen-paa-afbetaling"
                    >Hvornår har du brug for at få værkstedsregningen på afbetaling?</a
                  >
                </li>
                <li><a href="#oensker-du-vores-hjaelp">Ønsker du vores hjælp?</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2
            id="hvordan-finder-vi-de-bedste-alternativer-til-vaerksteder-med-afbetaling"
          >
            Hvordan finder vi de bedste alternativer til værksteder med afbetaling?
          </h2>
          <p>
            Når du ansøger om at få din værkstedsregning på afbetaling igennem os,
            indhenter vi tilbud på
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >
            fra en lang række banker, som du kan bruge til at dække din regning.
          </p>
          <p>
            Først og fremmest har vi brug for, at du udfylder vores online ansøgning,
            hvilket som nævnt tager ca. 2 minutter. Herefter kan vi fremsende de
            informationer, som du har opgivet, til vores bankpartnere, så de kan vurdere
            om, og på hvilke vilkår, de kan tilbyde dig et lån, så du kan få bilen fixet
            på værkstedet.
          </p>
          <p>
            Når vi har modtaget tilbud fra alle banker, viser vi dig alle de banker, som
            har vendt tilbage med et tilbud på en overskuelig liste, så du nemt kan finde
            og vælge det bedste og
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >billigste lån</a
            >
            til din situation. Hvis der er noget specielt, som gør sig gældende for det
            ene eller andet lån til til at dække din værkstedsregningsregning, vil det
            også fremgå på denne oversigt.
          </p>
          <p>
            Til sidst er det blot op til dig at trykke på det tilbud, som du ønsker at
            acceptere, hvorefter du bliver sendt over til selve udbyderens hjemmeside, som
            vil verificere og evt. korrigere de oplysninger, som du har opgivet, før de
            kan udbetale lånet.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvornaar-har-du-brug-for-at-faa-vaerkstedsregningen-paa-afbetaling">
            Hvornår har du brug for at få værkstedsregningen på afbetaling?
          </h2>
          <p>
            De fleste, som har brug for at få værkstedsregningen på afbetaling, er folk
            som har været ekstra uheldige med deres bil, som derfor skal repareres for
            mange penge. Alt efter hvilket bilmærke du har, vil der også være stor forskel
            på, hvor meget skaderne koster at udbedre.
          </p>
          <p>
            Har du valgt et af de mærker, som er kendt for at være dyre at vedligeholde og
            reparere, da de er bygget af kvalitetsmaterialer, fx en
            <a
              href="https://www.bmw.dk/da/index.html"
              rel="noopener noreferrer"
              target="_blank"
              >BMW</a
            >, så bliver regningen kun endnu højere, og muligheden for afbetaling endnu
            mere relevant. Den gennemsnitlige skade varierer fra 8.000 kr. til 22.000 kr.,
            alt efter hvilket mærke den beskadiget bil er.
          </p>
          <p>
            Hvis du blot har et punkteret dæk eller ridset en fælg, som skal udskiftes
            eller en vinduesvisker der er knækket, så har du sjældent brug for at
            afbetaling hos dit værksted. Hvis du derimod har problemer med motoren, har
            store rustskader eller er kørt galt og lavet en større skade på bilen, så er
            der ofte brug for at få værkstedsregningen på afbetaling.
          </p>
          <p>
            Hvis du har held i uheld, og har du en god
            <a
              href="https://www.almbrand.dk/produkter/bil-og-baad/bilforsikring/"
              rel="noopener noreferrer"
              target="_blank"
              >bilforsikring</a
            >, så er din bil måske blevet vurderet
            <a
              href="https://fdm.dk/alt-om-biler/bilen-hverdagen/bilforsikring/hvornaar-er-min-bil-totalskadet"
              rel="noopener noreferrer"
              target="_blank"
              >totalskadet</a
            >
            af en taksator, så du undgår at stå med en bil, der har gennemgået omfattende
            reparationer, og derfor har en lavere værdi den dag du ønsker at sælge den
            privat eller til en brugtvognsforhandler. Når din bil vurderes totalskadet,
            kan du nemlig kræve kontantudbetaling af bilens vurderede markedsværdi, frem
            for at få den repareret.
          </p>
          <h3>Ny motor</h3>
          <p>
            En ny motor kan koste alt fra
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/20000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >20.000 kr.</a
            >
            og op til
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/100000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >100.000 kr.</a
            >
            for almindelige personbiler, herunder Audi, Porche og lignende. Vi stortset
            lige så mange, som leder efter at få værkstedsregningen på afbetaling til en
            ny motor til 20.000 kr., som vi ser leder efter at få dækket
            værkstedsregningen til 100.000 kr. I nogle tilfælde giver det måske mere
            mening, at overveje at ansøge om et
            <a
              href="https://www.saverium.dk/laan-penge/billaan"
              rel="noopener noreferrer"
              target="_blank"
              >lån til en ny bil</a
            >, men det vil vi lade være op til dig.
          </p>
          <h3>Ny forrude</h3>
          <p>
            En anden helt almindelige skade er udskiftning af forruden, hvilket typisk
            sker i forbindelse med stenslag. Her kan der være stor variation i prisen, men
            du slipper typisk afsted med en regning på omkring
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/5000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >5.000 kr.</a
            >
            Da denne regning er uforudset, har mange også brug for at finde et værksted
            med afbetaling til at dække denne omkostning i første omgang.
          </p>
          <h3>Bule i bilen</h3>
          <p>
            Hvis du har været uheldig at få en bule i bilen, så behøver du ofte ikke et
            lån til at dække værkstedsregningen. I de fleste tilfælde vil det være i
            omegnen af <strong>1.000 kr.</strong>, som du kommer til at slippe af med, med
            mindre at du også har skadet andres biler i forbindelse med at have foresaget
            skaden på din egen bil, eller at du har lavet yderligere skade på din bil i
            samme uheld, som også skal repareres.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
