<template>
  <div>
    <PreformContainer />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'

export default {
  components: {
    PreformContainer,
  },
  data() {
    return {}
  },
}
</script>
