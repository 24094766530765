<template>
  <div>
    <paragraph-header :value="$t('loan_for_company_title')" :hideForm="true" />
    <p>{{ $t('loan_for_company_text' )}}</p>
    <paragraph-header :value="$t('loan_for_invests_title')" :hideForm="true" />
    <p>{{ $t('loan_for_invests_text_1' )}}</p>
    <p>{{ $t('loan_for_invests_text_2' )}}</p>
    <p>{{ $t('loan_for_invests_text_3' )}}</p>
    <paragraph-header :value="$t('loan_guarantees_title')" :hideForm="true" />
    <p>{{ $t('loan_guarantees_text_1' )}}</p>
    <p>{{ $t('loan_guarantees_text_2' )}}</p>
    <p>{{ $t('loan_guarantees_text_3' )}}</p>
    <paragraph-header :value="$t('loans_from_companies_title')" :hideForm="true" />
    <p>{{ $t('loans_from_companies_text_1' )}}</p>
    <p>{{ $t('loans_from_companies_text_2' )}}</p>
    <p>{{ $t('loans_from_companies_text_3' )}}</p>
    <p>{{ $t('loans_from_companies_text_4' )}}</p>
  </div>
</template>

<script>
import ParagraphHeader from '../ParagraphHeader'

export default {
  components: {
    ParagraphHeader
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .column {
    max-width: 800px;
  }

  .secondary-title {
    text-transform: uppercase;
    font-size: 16px;
  }

  .double-column-container {
    display: flex;
  }

  .main-column {
    flex: 2;
  }

  .side-column {
    flex: 1;
  }

  .link {
    color: rgb(235, 0, 18);
  }
</style>
