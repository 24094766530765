<template>
  <b-container class="container">
    <b-row class="row">
      <b-col cols="12" lg="6" class="mx-auto">
        <h1 class="page-header">Salasanan palautus</h1>
        <div class="well">
          <div class="alert alert-danger alert-dismissable" v-if="this.error">
            <button class="close" type="button" v-on:click="error = false">×</button>
            Jotain meni pieleen. Yritä uudelleen.
          </div>
          <div class="alert alert-info alert-dismissible" v-if="this.success">
            Jos sähköpostilla on asiakastili, kyseiseen sähköpostiin on lähetetty ohjeet
            salasanan palautusta varten.
          </div>
          <form method="post" @submit.prevent="Sent()" v-if="!this.success">
            <label for="email">Sähköposti</label>
            <b-form-input
              type="email"
              class="w-100"
              name="email"
              id="email"
            ></b-form-input>
            <hr />
            <button type="submit" class="btn btn-primary btn-lg btn-block">
              <i class="fa fa-fw fa-sign-in"></i> Lähetä
            </button>
          </form>
        </div>
        <hr />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      success: false,
      error: false,
    }
  },
  components: {},
  computed: {},
  methods: {
    async Sent() {
      const formData = new FormData(document.querySelector('form'))
      formData.append('language', 'fi')
      this.success = false
      this.error = false

      const resp = await this.$store.dispatch('resetPassword', formData)
      if (resp) {
        this.success = true
      } else {
        this.error = true
      }
    },
  },
}
</script>

<style lang="css" scoped></style>
