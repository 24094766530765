<template>
  <div>
    <div class="article">
      <h1 class="center-title">Ferratum Bank lån</h1>

      <div class="top-text text-container">
        <ul>
          <li>Lån mellem 1.500 – 50.000 kr.</li>
          <li>Låneafgørelse med det samme</li>
          <li>Ingen oprettelsesgebyr</li>
          <li>
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/uden-sikkerhed"
              rel="noopener noreferrer"
              target="_blank"
              >Lån uden sikkerhed</a
            >
          </li>
        </ul>
        <p>
          Ferratum Bank er et finsk finansielt selskab, der har eksisteret i Danmark siden
          2005. De tilbyder
          <a
            href="https://www.saverium.dk/laan-penge/forbrugslaan"
            rel="noopener noreferrer"
            target="_blank"
            >forbrugslån</a
          >
          fra 1.500 til 50.000 kroner til sine private kunder. Du kan finde Ferratum Bank
          og modtage
          <a
            href="https://www.saverium.dk/laan-penge/beloeb"
            rel="noopener noreferrer"
            target="_blank"
            >lånetilbud</a
          >
          fra dem, samt mere end 10 andre forskellige anerkendte
          <a
            href="https://www.saverium.dk/laan-penge/laaneudbydere"
            rel="noopener noreferrer"
            target="_blank"
            >låneudbydere</a
          >
          igennem vores gratis online lånesammenligningstjeneste.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#laanevilkaar-og-rente-hos-ferratum-bank"
                    >Lånevilkår og rente hos Ferratum Bank</a
                  >
                </li>
                <li>
                  <a href="#saadan-ansoeger-du-om-et-laan-hos-ferratum-bank"
                    >Sådan ansøger du om et lån hos Ferratum Bank</a
                  >
                </li>
                <li><a href="#ferratum-bank">Ferratum Bank</a></li>
                <li>
                  <a href="#laanevilkaar-hos-ferratum-bank"
                    >Lånevilkår hos Ferratum Bank</a
                  >
                </li>
                <li>
                  <a href="#laaneeksempel-hos-ferratum-bank"
                    >Låneeksempel hos Ferratum Bank</a
                  >
                </li>
                <li>
                  <a href="#ferratums-kundeoplevelser">Ferratums kundeoplevelser</a>
                </li>
                <li>
                  <a href="#ferratum-banks-kundeservice">Ferratum Banks kundeservice</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="laanevilkaar-og-rente-hos-ferratum-bank">
            Lånevilkår og rente hos Ferratum Bank
          </h2>
          <p><strong>Lånebeløb: </strong>1.500 - 50 000 kr.</p>
          <p><strong>Låneperiode: </strong>1 til 10 år</p>
          <p><strong>Fast debitorrente: </strong>22.51%</p>
          <p><strong>Behandlingstid: </strong>Indenfor 45 minutter, døgnet rundt</p>
          <p><strong>Medansøger: </strong>Ja</p>
          <p></p>
        </div>

        <div class="text-container">
          <h2 id="saadan-ansoeger-du-om-et-laan-hos-ferratum-bank">
            Sådan ansøger du om et lån hos Ferratum Bank
          </h2>
          <p>
            Udfyld din online <strong>låneansøgning</strong> nemt på få minutter, og tjek
            de tilbud du skulle modtage på din brugerkonto.
          </p>
          <ul>
            <li>
              <strong>Vælg dit ønskede beløb samt løbetid for lånet.</strong> Vælg det
              beløb og den låneperiode du ønsker for dit
              <a
                href="https://www.saverium.dk/laan-penge"
                rel="noopener noreferrer"
                target="_blank"
                >nye lån</a
              >.
            </li>
            <li>
              <strong>Udfyld dine andre oplysninger.</strong> Udfyld din ansøgning med de
              andre nødvendige oplysninger der bliver efterspurgt.
            </li>
            <li>
              <strong>Indsend din ansøgning</strong>. Indsend din udfyldte ansøgning. Vi
              indsamler herefter tilbud til dig fra de forskellige udbydere af lån. Du får
              svar på din ansøgning med det samme.
            </li>
            <li>
              <strong>Accepter det ønskede tilbud</strong>.
              <a
                href="https://www.saverium.dk/laan-penge/sammenligning"
                rel="noopener noreferrer"
                target="_blank"
                >Sammenlign dine tilbud</a
              >
              og accepter det ønskede tilbud, som lever op til dine forventninger.
            </li>
            <li>
              <strong>Lånet vil blive overført din konto</strong>. Vælger du et lån hos
              Ferratum bank, vil lånet blive udbetalt til din oplyste bankkonto indenfor
              45 minutter.
            </li>
            <li><br /></li>
          </ul>
        </div>

        <div class="text-container">
          <h2 id="ferratum-bank">Ferratum Bank</h2>
          <p>
            Ferratum er et finsk finansieringsselskab og international udbyder af
            elektroniske finansielle tjenester, der startede i 2005. Sammen med sine
            datterselskaber danner de Ferratum Group, der har aktiviteter i mere end tyve
            lande. I Danmark tilbyder Ferratum
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan"
              rel="noopener noreferrer"
              target="_blank"
              >privatlån uden sikkerhed</a
            >
            og
            <a
              href="https://www.saverium.dk/samlelan/"
              rel="noopener noreferrer"
              target="_blank"
              >samlelån</a
            >
            til privatpersoner. De har i alt over 2 millioner kunder rundt om i verden.
          </p>
          <p>
            Med hurtig udbetaling på blot 45 minutter efter låneaftalen er underskrevet,
            kan Ferratum Bank derfor tilbyde et
            <a
              href="https://www.saverium.dk/lan-penge-akut/"
              rel="noopener noreferrer"
              target="_blank"
              >hurtigt lån</a
            >
            hvis man akut står og mangler penge. Hvis du mangler et penge nu, så din bil
            kan komme på værksted, eller hvis du har en
            <strong>tandlægeregning</strong> der skal betales, så vil et lån hos Ferratum
            Bank være oplagt.
          </p>
          <p>
            Når du laver en ansøgning igennem os, kan du sammenligne forskellige tilbud
            via vores online tjeneste, hvor du vil kunne modtage flere forskellige tilbud
            fra mere end 10 andre låneudbydere og banker udover Ferratum Bank. Det koster
            dig ingenting, og du forpligter dig ikke til at acceptere nogle af de tilbud,
            som du modtager på din brugerkonto. Du kan indsende en gratis og uforpligtende
            ansøgning på få minutter, og få svar på din ansøgning med det samme.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laanevilkaar-hos-ferratum-bank">Lånevilkår hos Ferratum Bank</h2>
          <p>
            For at kunne gøre sig gældende til et lån hos Ferratum Bank Danmark, skal du
            leve op til disse 5 punkter:
          </p>
          <ul>
            <li>Du skal være dansk statsborger og have fast bopæl i Danmark.</li>
            <li>Du skal være fyldt 21 år.</li>
            <li>Du skal have en regelmæssig indkomst på din bankkonto.</li>
            <li>Du skal have dansk telefonnummer, e-mail adresse og dansk bankkonto.</li>
            <li>
              Til sidst må du ikke være registeret i nogle gældsregistre som
              <strong>Debitor Registret og RKI</strong>.
            </li>
          </ul>
          <p>
            Udover det bliver der foretaget en kreditvurdering af dig og din økonomi, som
            du skal give samtykke til med dit
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >NemID/MitID</a
            >.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laaneeksempel-hos-ferratum-bank">Låneeksempel hos Ferratum Bank</h2>
          <p>
            <strong>Låneeksempel</strong>: Beløb: 25.000 kr., Løbetid 12 måneder, Samlede
            Kreditomkostninger 3048,66 kr., ÅOP 24.99%, Samlet tilbagebetalingsbeløb
            28.048,66 kr., Fast debitorrente 22.51%, Minimumsbetaling* 2.337,39 kr.
          </p>
          <p>
            *Minimum månedlig betaling: 3.5% af den udstående kredit eller min. 200 kroner
            afhængigt af, hvad der er størst. Minimum tilbagebetaling: 9 måneder. Maksimum
            tilbagebetaling: 199 måneder.
          </p>
          <p>
            Ferratum Kredit er en fleksibel løbende
            <a
              href="https://www.saverium.dk/laan-penge/kassekredit"
              rel="noopener noreferrer"
              target="_blank"
              >kassekredit</a
            >, hvilket betyder, at du blot benytter den
            <a
              href="https://www.saverium.dk/laan-penge/kreditlaan"
              rel="noopener noreferrer"
              target="_blank"
              >kredit</a
            >, du har behov for, og tilbagebetaler den, når der ikke er behov mere.
            Tilbagebetalingsperioden afhænger af din valgte ydelse. Der er 14 dages
            fortrydelsesret på kreditaftalen hos Ferratum Bank.
          </p>
        </div>

        <div class="text-container">
          <h2 id="ferratums-kundeoplevelser">Ferratums kundeoplevelser</h2>
          <p>
            Med ændringer på lånemarkedet har Ferratum også udviklet sine tjenester til at
            opfylde moderne behov. De lån som Ferratum tilbyder, er derfor meget
            sammenlignelige med andre lignende finansieringsløsninger.
          </p>
          <p>
            Ifølge data indsamlet af Trustpilot, der indsamler kundeoplevelser, har en høj
            procent del af Ferratum Danmarks kunder, der har indsendt deres anmeldelser,
            været tilfredse eller meget tilfreds med den service, de har modtaget af
            Ferratum Danmark.
          </p>
        </div>

        <div class="text-container">
          <h2 id="ferratum-banks-kundeservice">Ferratum Banks kundeservice</h2>
          <p>
            Du kan kontakte Ferratum Bank over telefon via deres kundeservice alle
            hverdage fra kl. 09:00 – 17:00. Ring på deres telefon: 70 20 70 46. Du kan
            også skrive til dem på ved at henvende dig over deres e-mail adresse:
            <a
              href="mailto:kundeservice@ferratumbank.dk"
              rel="noopener noreferrer"
              target="_blank"
              >kundeservice@ferratumbank.dk</a
            >.
          </p>
          <p>
            Skulle du have nogle spørgsmål vedrørende hvordan du laver din ansøgning eller
            andre spørgsmål, kan du kontakte os på hverdage mellem 09:00 - 15:00 på
            telefon: 93 75 99 59. Du er os velkommen til at kontakte os direkte på e-mail:
            <a
              href="mailto:kundeservice@saverium.dk"
              rel="noopener noreferrer"
              target="_blank"
              >kundeservice@saverium.dk</a
            >.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
