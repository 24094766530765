<template lang="html">
  <div className="form-container">
    <CompanyLoanForm
      :apiUrl="api"
      tosUrl="/kayttoehdot"
      buttonVariant="loan"
      :initialStep=2
      :initialForm="initialFormData"
      @update="updateForm"
      @nextStep="nextStep"
      @submit="sendForm"
      />
  </div>
</template>

<script>
import CompanyLoanForm from '@lvsbrokers/vue-company-loan-form'
import settings from '@/settings/settings'

export default {
  components: {
    CompanyLoanForm,
  },
  data() {
    return {
      api: settings.API,
    }
  },
  methods: {
    sendForm() {
      this.$store.dispatch('sendApplication')
    },
    nextStep() {
      // pass
    },
    updateForm(form) {
      this.$store.commit('updateApplication', form)
    },
  },
  computed: {
    initialFormData() {
      return this.$store.getters.getInitialForm
    },
  },
}
</script>

<style lang="sass" scoped>
.header-underline
  border-bottom: 1px solid #2a76bc
  padding-bottom: 4px

.btn-loan
  background-color: #2a76bc
  color: #f2f2f2
  font-weight: bold
  font-size: 20px
  &:hover
    background-color: #2f8ade
  &:active
    background-color: #25629a
</style>
