<template>
  <div>
    <div class="container container-faq col-lg-8 mt-5 mb-5">
      <h1>Tjenestevilkår (Forbrugslån) for Saverium.dk</h1>
      <br />
      <h3>1. Baggrund</h3>
      <p>
        Disse tjenestevilkår (“Tjenestevilkårene”) er gældende for den online formidling
        af forbrugslån, der tilbydes af LVS Brokers Oy, et selskab med hjemsted i Finland,
        PL 644, FI- 00101 Helsinki, Finland (“LVS Brokers”), gennem LVS Brokers’
        hjemmeside,
        <a href="https://www.saverium.dk">https://www.saverium.dk</a> (hjemmesiden og
        relaterede tjenester herefter under ét benævnt “Saverium.dk”).
      </p>
      <p>
        Ved at tilgå eller bruge Saverium.dk accepterer du (»du« eller »Brugeren«) de i
        disse Tjenestevilkår anførte vilkår og betingelser. For at kunne indsende
        ansøgning om lånetilbud gennem Saverium.dk skal du først bekræfte, at du har læst
        og accepterer disse Tjenestevilkår, ved at sætte kryds i den relevante boks.
      </p>
      <p>
        LVS Brokers er en uafhængig formidler af forbrugslån. LVS Brokers kan ikke
        repræsentere Långivere (som defineret nedenfor) eller indgå låneaftaler på vegne
        af Långivere. LVS Brokers fungerer alene som mellemled mellem dig og Långiverne og
        yder ikke selv nogen lån eller træffer nogen beslutning vedrørende lånene og/eller
        ansøgningerne om lånetilbud.
      </p>
      <p>
        For god ordens skyld bemærkes, at disse Tjenestevilkår alene regulerer forholdet
        mellem dig og LVS Brokers vedrørende din adgang til og brug af Saverium.dk, ikke
        de eventuelle låneaftaler, du måtte indgå med nogen Långiver.
      </p>
      <h3>2. Brug af Saverium.dk</h3>
      <p>
        Med din accept af disse Tjenestevilkår og indgivelse af en ansøgning om lånetilbud
        gennem Saverium.dk giver du LVS Brokers tilladelse til på dine vegne at ansøge om
        ét eller flere tilbud på forbrugslån hos de banker og øvrige kreditorer (hver af
        disse en »Långiver« og under ét »Långiverne«), der samarbejder med LVS Brokers.
        Alle Långiverne har de efter dansk lov påkrævede tilladelser til at drive den
        virksomhed, der er forbundet med Saverium.dk, i Danmark.
      </p>
      <p>Du skal være fyldt 18 år for at bruge Saverium.dk.</p>
      <p>Saverium.dk er gratis at bruge for Brugeren.</p>
      <p>LVS Brokers kan være berettiget til at modtage provision fra Långiverne.</p>
      <p>
        Brugeren skal sikre, at brugernavn, password og lignende oplysninger modtaget af
        denne i forbindelse med oprettelse i eller brug af Saverium.dk, holdes fortroligt
        og opbevares og bruges på sikker vis og ikke kan tilgås eller bruges af
        tredjemand.
      </p>
      <p>
        Hvis der er mistanke om, at nogen uautoriseret person har fået kendskab til et
        brugernavn og/eller password, skal Brugeren øjeblikkeligt orientere LVS Brokers
        herom og ændre det pågældende brugernavn og/eller password. Brugeren er ansvarlig
        for enhver brug af Saverium.dk og enhver aktivitet ved brug af Brugerens konto,
        brugernavn og/eller password.
      </p>
      <p>For korrekt brug af Saverium.dk skal Brugeren:</p>
      <ol type="a">
        <li>have aktiv internetforbindelse med gensindig kommunikation;</li>
        <li>
          anvende en af følgere browsere korrekt installeret: Nyeste version af Internet
          Explorer eller Firefox eller en af de tre foregående versioner af disse
          browsere;
        </li>
        <li>have cookies og JavaScript aktiveret.</li>
      </ol>
      <p>
        Du accepterer, at der kan være afbrydelser i tilgængeligheden af Saverium.dk,
        f.eks. i tilfælde af dataforbindelses- eller netværksafbrydelser eller afbrydelser
        af tredjemandstjenester.
      </p>
      <p>
        Du må ikke bruge Saverium.dk til noget ulovligt formål, hvorved i særdeles
        bemærkes, at det er forbudt at indsende ulovligt indhold til Saverium.dk.
      </p>
      <p>
        LVS Brokers er berettiget til, frit og efter eget skøn, at nægte Brugeren adgang
        til Saverium.dk uden forudgående varsel til Brugeren, hvis LVS Brokers med
        rimelighed har mistanke om, at Brugeren anvender Saverium.dk i strid med disse
        Tjenestevilkår eller gældende lov.
      </p>
      <p>
        Telefonopkald i mellem du og LVS Brokers kan blive overvåget og optaget. Dette vil
        hjælpe os med at træne medarbejder samt forbedre vores service. Optagelser bruges
        kun under tilstrækkelig og nødvendige situationer under forsigtigt opsyn. Vores
        telefon support er normalt tilgængelig, mandag til fredag fra 09:00 til 15:00.
      </p>
      <h3>3. Ansøgninger om lånetilbud og tilbud om lån</h3>
      <p>
        Brugeren skal sikre, at alle oplysninger afgivet af Brugeren til LVS Brokers i
        låneansøgningen er sandfærdige, fuldstændige og korrekte. Brugeren skal så hurtigt
        som muligt underrette LVS Brokers om ændringer i oplysningerne.
      </p>
      <p>
        Du er indforstået med, at når du indsender en låneansøgning og meddeler relevante
        samtykker til behandling af personoplysninger om dig, eksempelvis
        kontaktoplysninger, økonomiske oplysninger og oplysninger om tidligere lån og
        kreditter, vil disse personoplysninger blive delt med relevante tredjeparter eller
        indhentet fra tredjemandsregistre, som Långiver eller LVS Brokers har adgang til i
        overensstemmelse med gældende lov med henblik på at vurdere ansøgningen og til
        bekræftelse af de oplysninger, du har afgivet. Dine personoplysninger vil blive
        behandlet i overensstemmelse med vores Privatlivspolitik, som findes
        <a target="_blank" href="/persondatapolitik">her</a>.
      </p>
      <p>
        Efter indsendelse af ansøgning om lånetilbud vil Brugeren pr. mail modtage
        bekræftelse på LVS Brokers&#39; modtagelse af ansøgningen samt login-oplysninger
        til og instruktioner i, hvordan du tilgår LVS Brokers&#39; ekstranet, hvor du vil
        kunne se eventuelle lånetilbud fra Långivere og/eller ansøge om yderligere
        lånetilbud. For god ordens skyld bemærkes, at du med indsendelse af en ansøgning
        om lånetilbud ikke forpligter dig til at acceptere tilbuddet.
      </p>
      <p>
        LVS Brokers modtager og verificerer din ansøgning om lånetilbud og videresender
        denne til en eller flere Långivere, der så har mulighed for at sende dig et
        lånetilbud. Det er helt og alene op til hver enkelt Långiver at beslutte, om
        Långiveren ønsker at sende dig et lånetilbud.
      </p>
      <p>
        LVS Brokers skal hverken direkte eller indirekte gennem Saverium.dk eller på anden
        vis anses at have ydet rådgivning eller givet dig nogen anbefaling om at acceptere
        eller afslå eventuelle lånetilbud fra nogen Långiver. LVS Brokers rolle som
        kreditformidler er alene at facilitere og bistå dig med, på grundlag af de
        oplysninger, du afgiver, at ansøge om lånetilbud fra Långivere. LVS Brokers skal
        heller ikke anses at have deltaget i eller være ansvarlig for en eventuel
        kreditrating af dig eller bedømmelse af din kreditværdighed som potentiel
        låntager.
      </p>
      <p>
        Du er indforstået med, at Långiverne behandler de oplysninger, du afgiver til os i
        din ansøgning om lånetilbud, med henblik på at vurdere din ansøgning. Denne
        behandling vil være underlagt Långivernes respektive privatlivspolitikker. Vi
        anbefaler, at du læser deres privatlivspolitikker, da de kan afvige fra vores.
      </p>
      <p>
        Lånetilbuddenes indhold og vilkår (herunder, men ikke begrænset hertil, lånebeløb,
        rente, omkostninger og afdrag) fastsættes udelukkende af Långiver og efter dennes
        skøn. LVS Brokers foretager ikke nogen gennemgang af Långiveres lånetilbud. For
        god ordens skyld bemærkes, at du ved at modtage et lånetilbud ikke forpligter dig
        til at indgå en låneaftale.
      </p>
      <p>
        Brugeren kan vælge at acceptere vilkårene for et specifikt lån, som en Långiver
        måtte tilbyde, hvorefter der indgås låneaftale i overensstemmelse med Långivers
        praksis. LVS Brokers er ikke part i en sådan låneaftale og forholder sig ikke til
        indholdet af den.
      </p>
      <p>
        Ved særskilt, forudgående, frivilligt og genkaldeligt samtykke giver du samtykke
        til, at LVS Brokers fra Långiveren modtager beslutninger vedrørende ansøgninger om
        lånetilbud samt lånetilbuddene og oplysninger om eventuelle låneaftaler indgået
        mellem dig og Långiveren. Brugeren er indforstået med, at Långivers beslutning må
        sendes til den mailadresse, som Brugeren har angivet i ansøgningen om lånetilbud.
      </p>
      <p>
        Bemærk venligst, at LVS Brokers kan afslå ansøgning om lånetilbud på baggrund af
        kriterier fastsat af Långiver. Långiver kan, frit og efter eget skøn, afvise
        ansøgninger om lånetilbud. Långiveren kan også vælge at tilbyde dig et lån, der
        afviger fra det lån, du har bedt om tilbud på. Hverken LVS Brokers eller Långiver
        giver nogen garanti for, at du kan opnå et lån. Du kan acceptere eller afvise
        ethvert lånetilbud, du måtte modtage fra Långiver.
      </p>
      <p>
        Når du ansøger om lån, bedes du foretage en sammenligning og vurdering af dit
        nuværende indkomstniveau og leveomkostninger med henblik på beregning af et
        realistisk månedligt afdrag på lånet.
      </p>
      <h3>4. Immaterielle rettigheder</h3>
      <p>
        Alle Immaterielle Rettigheder (som defineret nedenfor) i relation til Saverium.dk
        og tilhørende dokumentation og alle dele og genparter deraf forbliver LVS
        Brokers&#39; og/eller dets underleverandørers/licensgiveres ejendom. Ved
        &quot;Immaterielle Rettigheder&quot; forstås ophavsrettigheder og relaterede
        rettigheder (herunder database-, katalog- og fotografirettigheder), patenter,
        brugsmodeller, designrettigheder, varemærker, handelsnavne, knowhow og enhver
        anden form for registreret eller ikke-registreret immateriel rettighed.
      </p>
      <p>
        Du opnår med disse Tjenestevilkår ingen Immaterielle Rettigheder til Saverium.dk,
        og enhver rettighed, som ikke udtrykkeligt gives dig i medfør af disse
        Tjenestevilkår, tilhører LVS Brokers og/eller dets underleverandører/licensgivere.
      </p>
      <h3>5. Ansvar</h3>
      <p>
        LVS Brokers garanterer ikke, at Saverium.dk vil fungere fejlfrit og uden mangler,
        forsinkelser, afbrydelser eller lignende, og LVS Brokers har intet ansvar i
        relation til sådanne fejl, mangler, forsinkelser, afbrydelser,
        (cyber-)sikkerhedsbrud eller lignende (herunder, men ikke begrænset hertil, tab af
        eller skade på data), uanset årsag, medmindre årsagen er grov uagtsomhed eller
        forsætlig misligholdelse fra LVS Brokers&#39; side.
      </p>
      <p>
        Med henvisning til pkt. 3 ovenfor understreges, at LVS Brokers agerer som
        formidler mellem dig og Långiverne, og at en eventuel aftale om lån alene indgås
        mellem dig og Långiveren. LVS Brokers har intet ansvar i relation til den mellem
        dig og Långiveren indgåede låneaftale, herunder, men ikke begrænset hertil, i
        relation til Långivers handlinger og undladelser. LVS Brokers forholder sig ikke
        til Långivernes lånetilbud og/eller låneaftaler og har intet ansvar for indholdet
        deraf eller de deri anførte vilkår og betingelser.
      </p>
      <h3>6. Ret til at træde tilbage fra Aftalen</h3>
      <p>
        LVS Brokers meddeler herved Brugerne, at de personoplysninger, der angives i
        ansøgninger om lånetilbud eller på anden måde indsamles ved brug af Saverium.dk,
        vil blive behandlet i overensstemmelse med gældende databeskyttelseslovgivning.
      </p>
      <h3>7. Øvrige bestemmelser</h3>
      <br />
      <p style="font-style: italic">Ændringer</p>

      <p>
        LVS Brokers har en eksklusiv ret til at bestemme indholdet af Saverium.dk og til
        til enhver tid at ændre eller opdatere indholdet heraf.
      </p>
      <p>
        LVS Brokers kan ændre disse Tjenestevilkår med mindst 30 dages forudgående varsel
        til Brugeren ved meddelelse på LVS Brokers&#39; hjemmeside eller pr. mail (hvis
        muligt). Hvis Brugeren ikke accepterer ændringerne, kan Brugeren ikke længere gør
        brug af Saverium.dk.
      </p>
      <p style="font-style: italic">Force majeure</p>
      <p>
        Hverken LVS Brokers og Brugeren ifalder ansvar for manglende overholdelse af disse
        Tjenestevilkår, hvis den manglende overholdelse skyldes årsager uden for deres
        eller deres leverandørers kontrol, herunder, men ikke begrænset til, krig,
        opstand, oprør eller anden civil ulydighed, karantænerestriktioner, faglig
        konflikt undtagen inden for hver parts organisation, svigt af eller forsinkelse i
        transport, ulykke, oversvømmelse, jordskælv, brand, storm, nogen form for
        myndighedsindgriben, judicielle tiltag eller ændring af lovgivning.
      </p>
      <p style="font-style: italic">Lovvalg og konfliktløsning</p>
      <p>
        Disse Tjenestevilkår er underlagt og skal fortolkes i overensstemmelse med dansk
        ret, bortset fra sådanne regler i dansk lovgivning, der kan føre til anvendelsen
        af retsreglerne i en anden jurisdiktion end Danmark
      </p>
      <p>
        Enhver uoverensstemmelse eller tvist mellem parterne vedrørende disse
        Tjenestevilkår skal afgøres af Københavns Byret.
      </p>
      <p>
        Brugeren har ret til at indgive klage over Saverium.dk ved anvendelse af
        nedenstående procedure. Klageproceduren forhindrer ikke Brugeren i - og begrænser,
        suspenderer eller afskærer heller ikke Brugerens ret til - at benytte sig af
        Brugerens rettigheder i henhold til gældende lovgivning. Klager over Saverium.dk
        kan fremsendes pr. mail til følgende adresse: kundeservice@saverium.dk. LVS
        Brokers vil orientere Brugeren om resultatet af klagesagens behandling ved
        anbefalet brev eller pr. mail til den adresse, Brugeren har anført, inden 14 dage
        fra LVS Brokers&#39; modtagelse af klagen.
      </p>
      <p>
        Eventuelle bemærkninger eller forespørgsler vedrørende Saverius&#39; funktioner
        skal sendes til følgende mailadresse: kundeservice@saverium.dk.
      </p>
      <p>
        Alternativt kan Brugeren gøre brug af alternativ konfliktløsning og henvise en
        tvist i overensstemmelse med Europa-Parlamentets og Rådets forordning (EU) nr.
        524/2013 af 21. maj 2013 om onlinetvistbilæggelse i forbindelse med tvister på
        forbrugerområdet. Forbrugeren kan også indgive klage via EU&#39;s Online Dispute
        Resolution platform, som kan findes
        <a href="http://ec.europa.eu/consumers/odr/" target="_blank">her</a>.
      </p>
      <br />
      <p>
        Disse Tjenestevilkår kan findes på Saverium.dk i et format, der gør det muligt at
        downloade, gemme eller printe dem.
      </p>
      <p>Kommunikation mellem Brugeren og LVS Brokers skal være på Dansk.</p>
      <p>
        LVS Brokers Oy<br />
        PL 644 FI-00101 Helsinki<br />
        Finland<br />
        <a href="https://www.saverium.dk">https://www.saverium.dk</a><br />
        Kundeservice: kundeservice@saverium.dk<br />
        Registreringsnummer i det finske handelsregister: 2521965-8
      </p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: underline;
}
</style>
