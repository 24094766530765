<template>
  <div class="main-container">
    <div class="main">
      <main-form-view />
    </div>
    <div class="main">
      <info-row />
      <partners />
      <b-container fluid class="column">
        <loan-comparison-info />
      </b-container>
      <application-steps />
      <b-container fluid class="column">
        <company-loan-info />
      </b-container>
    </div>
  </div>
</template>

<script>
import InfoRow from '@/components/lainaayritykselle_fi/main/InfoRow'
import LoanComparisonInfo from '@/components/lainaayritykselle_fi/main/LoanComparisonInfo'
import CompanyLoanInfo from '@/components/lainaayritykselle_fi/main/CompanyLoanInfo'
import ApplicationSteps from '@/components/lainaayritykselle_fi/main/ApplicationSteps'
import Partners from '@/components/lainaayritykselle_fi/main/Partners'

import MainFormView from '@/components/lainaayritykselle_fi/newForm/MainFormView'

export default {
  metaInfo: {
    meta: [
      {
        name: 'description',
        content:
          'Lainaayritykselle.fi -palvelussa voit kilpailuttaa yrityslainat helposti ja nopeasti. Mukana yli 10 luotonantajaa.',
      },
      { name: 'keywords', content: 'yrityslaina, kilpailutus, yritysrahoitus' },
    ],
  },
  components: {
    InfoRow,
    LoanComparisonInfo,
    CompanyLoanInfo,
    ApplicationSteps,
    MainFormView,
    Partners,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.main {
  width: 100%;
  position: relative;
}

.image {
  width: 100%;
  margin-top: -10vw;
}
@media screen and (min-width: 1800px) {
  .image {
    margin-top: -20vw;
  }
}
@media screen and (min-width: 2100px) {
  .image {
    margin-top: -30vw;
  }
}
</style>
