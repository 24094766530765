<template>
  <b-card-group deck class="mt-5">
    <div
      class="mb-5 article-card"
      v-for="(article, index) in articles
        .slice(Math.max(articles.length - 30, 0))
        .reverse()"
      :key="index"
    >
      <b-card no-body class="card-img-pl" @click="navigate(article.url)">
        <b-card-img-lazy
          style="display:inline-block"
          top
          :src="article.src"
          :alt="article.title"
          :blank-src="article.tiny"
          blank-height="300px"
          blank-width="100%"
          width="100%"
          min-height="300"
        ></b-card-img-lazy>
        <b-card-body
          ><b-link :to="article.url">
            <b-card-title>{{ article.title }}</b-card-title></b-link
          >
          <b-card-text class="black"> {{ article.description }}</b-card-text>
        </b-card-body>
        <template class="footer" #footer>
          <b-link :to="article.url">
            <em
              >{{ $t('continue_reading') }}
              <i class="fal fa-chevron-double-right" style="font-size: 0.8rem"></i
            ></em>
          </b-link>
        </template>
      </b-card>
    </div>
  </b-card-group>
</template>
<script>
export default {
  computed: {
    articles() {
      return this.$store.state.articles.articles.filter(article => article.src)
    },
  },
  methods: {
    navigate(url) {
      this.$router.push(url).catch(() => {})
    },
  },
}
</script>
<style lang="scss">
.card-img-pl {
  border: solid 0.1px rgba(114, 114, 114, 0.548);
  height: 100%;
  min-height: 235px;
  color: black;
  margin-left: auto;
  margin-right: auto;
  a {
    color: black;
  }
  &a:hover {
    text-decoration: underline;
  }
}
.black {
  color: black;
}
.article-card {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;

  max-width: 450px;
  @media (max-width: 930px) {
    margin: auto;
  }
}
.card-footer {
  a {
    color: rgb(44, 44, 44);
    font-size: 0.9rem;
    font-family: 'Montserrat', sans-serif;
  }
  background: white;
  text-align: right;
}
.article-card {
  cursor: pointer;
}
</style>
