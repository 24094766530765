<template lang="html">
  <input
    class="form-control"
    :class="error ? 'is-invalid' : ''"
    type="email"
    :value="value"
    @change="input"
  />
</template>

<script>
export default {
  props: ['value', 'error'],
  data() {
    return {
      // Previously whitelisted domains: com|org|net|edu|info|fi|no|se|dk|pl|eu
      regex:
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[a-z]{2}|com|org|net|edu|info|one)\b/,
      whitespace: /\s+/g,
    }
  },
  methods: {
    input(event) {
      const value = event.target.value.replace(this.whitespace, '').toLowerCase()

      if (value !== this.value) {
        this.$emit('input', value, this.validate(value))
      }
    },
    validate(value) {
      return value.match(this.regex)
    },
  },
}
</script>

<style lang="sass" scoped></style>
