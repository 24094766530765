<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til din have</h1>

      <div class="top-text text-container">
        <p>
          Skal du til og have gjort din have i stand eller bygge en terrasse, men har du
          ikke pengene til det i øjeblikket? Så vil vi anbefale dig at bruge vores gratis
          service, hvor du kan ansøge om et havelån og modtage tilbud fra mange
          forskellige anerkendte banker og pengeinstitutter. På den måde kan du være
          sikker på, du får et godt lån til haven.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#information-vedroerende-laan-til-have"
                    >Information vedrørende lån til have</a
                  >
                </li>
                <li><a href="#laan-til-din-have-online-">Lån til din have online </a></li>
                <li>
                  <a href="#brug-laanet-til-din-droemme-have"
                    >Brug lånet til din drømme have</a
                  >
                </li>
                <li>
                  <a href="#sammenlign-for-finde-dit-nye-laan-til-haven"
                    >Sammenlign for finde dit nye lån til haven</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="information-vedroerende-laan-til-have">
            Information vedrørende lån til have
          </h2>
          <p>
            Drømmer du om at renovere din have, få lavet et nyt drivhus eller eventuelt
            bygge en dam i haven, men din økonomi rækker ikke til prisen på din drøm? Så
            kan du finde et
            <a
              href="https://www.saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtigt lån</a
            >
            til at <strong>finansiere </strong>din drømmehave. Det er vigtigt, at du
            finder ud af, hvordan du ønsker din have skal se ud, og hvad der skal købes,
            inden du optager et
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >nyt lån</a
            >.
          </p>
          <p>
            Vi skal nok sørge for at finde de
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >billigste lånetilbud</a
            >, som vil passe med din økonomi og prisen på din drømme have. Du skal bare
            udfylde en uforpligtende
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låneansøgning</a
            >
            på vores hjemmeside, så skal vi nok sørge for at indhente tilbud fra vores
            mange forskellige samarbejdspartnere.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-din-have-online-">Lån til din have online</h2>
          <p>
            Hvis du ønsker at finde dit lån til have online, kan det være uoverskueligt,
            siden der findes rigtig mange udbydere. En af de nemmeste løsninger for dig,
            som leder efter et lån, er at anvende vores gratis online service, så du ikke
            selv skal ud og kontakte de mange forskellige
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere</a
            >, der findes på det danske lånemarked.
          </p>
          <p>
            Når du har udfyldt en uforpligtende ansøgning på vores hjemmeside, sender vi
            den videre til vores partnere som herefter vil fremsende tilbud til dig, så du
            kan vurdere alle dine
            <a
              href="https://www.saverium.dk/laan-penge/bolig/laanemuligheder"
              rel="noopener noreferrer"
              target="_blank"
              >lånemuligheder</a
            >
            op imod hinanden og sammenligne lånevilkårne på de tilbud du skulle modtage.
          </p>
        </div>

        <div class="text-container">
          <h2 id="brug-laanet-til-din-droemme-have">Brug lånet til din drømme have</h2>
          <p>
            Hvis du vælger at optage et
            <a
              href="https://www.saverium.dk/laan-penge/online-laan"
              rel="noopener noreferrer"
              target="_blank"
              >lån online</a
            >, som du ønsker at bruge på bygge din drømme have, så er en af de helt store
            fordele, at udbyderen ikke har brug for at vide, hvad lånet skal gå til. Hvis
            du skal bygge et nyt drivhus, optage lån til en ny havetraktor så græsplænen
            forbliver nyklippet eller endda omdanne hele haven, kan du nemt gøre dette med
            et online og usikret havelån. Det vigtigste for udbyderen, som du har optaget
            dit lån hos, er at du kan tilbagebetale dit
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >
            indenfor den angivet løbetid på dit lån.
          </p>
          <p>
            <a
              href="https://www.saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              >Lånebeløbet</a
            >
            du optager skal selvfølgelige passe med det, du ønsker at købe til din have.
            Hvis du ønsker at købe et nyt drivhus, kan det sagtens koste op til
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/20000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >20.000 kr.</a
            >, men det kommer selvfølgelige også an på, hvad du ønsker at anvende
            drivhuset til.
          </p>
          <p>
            Det kan være, at du ønsker at man skal kunne slappe af derinde og hygge, eller
            blot ønsker at gro tomater og andre grøntsager eller planter. Beløb skal
            selvfølgelig helst matche det du ønsker at bruge dit drivhus til, så du ikke
            får et for lille beløb udbetalt, i forhold til hvad du har brug for.
          </p>
        </div>

        <div class="text-container">
          <h2 id="sammenlign-for-finde-dit-nye-laan-til-haven">
            Sammenlign for finde dit nye lån til haven
          </h2>
          <p>
            Hvis du ønsker at finde det
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/uden-sikkerhed"
              rel="noopener noreferrer"
              target="_blank"
              >billigste lån uden sikkerhed</a
            >, som du kan bruge til at investere i din drømmehave, er det en god ide at
            undersøge de mange forskellige muligheder, som der findes. Efter som der
            findes mange forskellige pengeinstitutter og banker, som ønsker at du skal
            optage et lån hos dem, kan det være svært at finde ud af, hvor man skal
            starte.
          </p>
          <p>
            Hvis du vælger at bruge vores gratis lånesammenligningstjeneste, sørger vi for
            finde så mange forskellige skræddersyet tilbud, så du kan finde det rette lån,
            på den nemmeste og billigste måde uden besvær. Dermed skal du kun fokusere på
            at vælge det tilbud, der giver mest mening for dig, istedet for du skal bruge
            din tid på at kontakte hver enkelt udbyder.
          </p>
          <p>
            Det eneste du skal gøre er at have adgang til internettet på enten en tablet,
            computer eller mobil og udfyld vores ansøgning med de nødvendige oplysninger.
            Så snart du har udfyldt din ansøgning, hvilket tager nogle få minutter, vil du
            allerede med det samme modtage tilbud på dit havelån, som du kan begynde at
            sammenligne. Det er nu op til dig at vælge lånet til din have, med de
            lånevilkår der er passer til din økonomi og situation.
          </p>
          <p>
            Efter du har valgt ét af de modtagende tilbud, vil vi omdirigere dig til selve
            udbyderens hjemmeside, som du har accepteret tilbuddet fra. Her skal du så
            give samtykke med
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >NemID/MitID</a
            >, for at udbyderen kan lave en endelig kreditvurdering af din privatøkonomi.
            Skulle du blive godkendt af udbyderen til at kunne optage lånet til din have,
            vil vi samt selve udbyderen sende dig en bekræftelse på, at lånet vil blive
            overført til din bankkonto hurtigst muligt.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
