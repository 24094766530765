<template>
  <div>
    <div class="article">
      <h1 class="center-title">Onlinelån - Lån penge online her</h1>
      <div class="top-text text-container">
        <p>
          Ansøg om onlinelån på under to minutter. Vi viser dig tilbud fra flere banker og
          låneudbydere, og du kan klare hele processen online. Start din ansøgning her.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li><a href="#om-onlinelaan">Om onlinelån</a></li>
                <li><a href="#fordele-ved-onlinelaan">Fordele ved onlinelån</a></li>
                <li>
                  <a href="#fordele-ved-at-ansoege-gennem-os"
                    >Fordele ved at ansøge gennem os</a
                  >
                </li>
                <li>
                  <a href="#saadan-fungerer-vores-service"
                    >Sådan fungerer vores service</a
                  >
                </li>
                <li>
                  <a href="#onlinelaan-til-det-du-staar-og-mangler"
                    >Onlinelån til det du står og mangler</a
                  >
                </li>
                <li><a href="#ofte-stillede-spoergsmaal">Ofte stillede spørgsmål</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="om-onlinelaan">Om onlinelån</h2>
          <p>
            I en digitaliseret verden er det også blevet langt nemmere at optage lån
            online. Hos Saverium kan du lave en låneansøgning online, hvor du modtager
            tilbud fra flere forskellige banker og
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere</a
            >. På den måde kan du nemt vælge det lån med de bedste vilkår.
          </p>
          <p>
            Hele processen med at optage et onlinelån kan foretages fra din computer,
            mobil eller tablet. Du skal kun indtaste lidt få oplysninger og verificerer
            med MitID eller NemID. Herefter træffer vi de nødvendige oplysninger, som vi
            sender videre til de forskellige udbydere af onlinelån, som så behandler din
            ansøgning.
          </p>
          <p>
            Hvis du ønsker et onlinelån, kan du gøre det hele via vores hjemmeside. Hele
            processen tager få minutter, og så har du svar fra flere banker og
            låneudbydere.
          </p>
        </div>

        <div class="text-container">
          <h2 id="fordele-ved-onlinelaan">Fordele ved onlinelån</h2>
          <p>
            Hele markedet for lån online er kommet for at gøre det nemmere for forbrugerne
            at
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >. Dette gøres naturligvis med samme store fokus på sikkerhed og
            kreditvurdering af den enkelte lånetager. Herunder kan du læse om nogle af de
            største fordele ved at låne penge online.
          </p>
          <h3>Kan tages alle steder fra</h3>
          <p>
            Hvis vi spoler nogle årtier tilbage, så var det en langt mere omstændig
            proces, hvis man ønskede at låne penge. Man skulle ofte møde op hos banken med
            en hel bunke papirer. Et onlinelån kan tages hjemme fra sofaen, i bussen, på
            ferien eller et fjerde sted. Så længe du har en internetforbindelse kan du
            benytte vores ansøgning til onlinelån.
          </p>
          <h3>Mindre papirarbejde</h3>
          <p>
            Fordi stort set alle vores oplysninger er blevet digitalt registreret, har vi
            mulighed for at lave en digital kreditvurdering. Du behøver ikke udskrive
            lønsedler og årsrapporter, da alle de nødvendige information kan træffes blot
            ved at du verificerer med MitID eller NemID.
          </p>
          <h3>Kortere behandlingstid</h3>
          <p>
            Det siger næsten sig selv. Når rejsetiden og papirarbejdet er fjernet er det
            også blevet langt hurtigere at optage et lån. Dette er relevant for dig, der
            står i en situation, hvor du har brug for et
            <a
              href="https://www.saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtigt lån</a
            >. Det afhænger naturligvis af onlinelånet, men ofte vil der ikke gå mere end
            1-2 hverdage, fra du begynder ansøgningen til du har pengene på din konto.
          </p>
          <h3>Større gennemsigtighed</h3>
          <p>
            Dette punkt omhandler ikke så meget onlinelånet i sig selv, men det faktum at
            de fleste lån efterhånden kan tages online. Det betyder nemlig også, at det er
            blevet muligt at sammenligne lån på tværs af banker og låneudbydere på en
            måde, som ikke før var muligt. Det er her, vores service kommer ind i
            billedet, da vi hjælper dig med at skabe det overblik uden at du skal lave
            flere separate ansøgninger.
          </p>
          <h3>Ingen dokumentation</h3>
          <p>
            Lån på internettet har gjort det muligt at slippe for det omstændige step med
            dokumentation. Fordi det hele sker online, kan vigtige information indhentes
            på få minutter, og der kan laves den samme kreditvurdering af høj kvalitet,
            som før i tiden krævede masser af papirer og snakke med bankrådgivere.
          </p>
        </div>

        <div class="text-container">
          <h2 id="fordele-ved-at-ansoege-gennem-os">Fordele ved at ansøge gennem os</h2>
          <p>
            Der findes et hav af låneudbydere på markedet, og derfor kan det være svært at
            gennemskue og sikre sig det bedste onlinelån. Det er dog her, vi kommer ind i
            billedet, da vi fungerer som mellemmand mellem dig og alle låneselskaberne.
          </p>
          <p>
            Vores service er sat i verden for at give dig en masse fordele, og nogle af
            disse kan du læse om her.
          </p>
          <h3>Du sparer tid</h3>
          <p>
            Det ville hurtige kunne tage en time eller to, hvis du blot skulle finde alle
            de mest attraktive udbydere på markedet for at låne penge online. Dertil kan
            du lægge alt tiden du skulle bruge på at ansøge, afvente på svar og
            sammenlign. Med vores service tager ansøgningen blot få minutter, og du har
            svaret fra alle udbyderne samlet et sted, på kort tid. På den måde kan vi
            hjælpe dig med at spare tid, som du kan bruge på ting, som du bedre kan lide
            end at udfylde kedelige låneansøgninger.
          </p>
          <h3>Et bedre overblik</h3>
          <p>
            Som vi kort nævnte i punktet før, så får du alle tilgængelige tilbud vist i et
            samlet overblik. Du slipper dermed for at skulle holde styr på et hav af
            emails fra forskellige udbydere, og så får du beregningerne leveret i et
            overskueligt format. Det gør det væsentlig nemmere for dig, når du skal tage
            stilling til, hvor du ønsker at låne penge online.
          </p>
          <h3>Spar penge</h3>
          <p>
            Fordi det kan være så tidskrævende at undersøge alle muligheder, får mange kun
            undersøgt et par enkelte (eller taget den første og bedst). Det er langt de
            fleste, som kan spare penge, hvis de vælger at sammenligne lån - på præcis
            samme måde som man kan spare penge på dagligvarer, elektronik, forsikring og
            alt andet, når man sammenligner priser for forskellige udbydere. Så hvis vi
            kun måtte give dig en grund til at benytte vores service er det denne her. Du
            sparer med stor sandsynlighed penge på dit lån og får et højere rådighedsbeløb
            hver måned.
          </p>
          <h3>Større lånebeløb</h3>
          <p>
            Selvom de fleste udbydere deler mange af kriterierne i deres kreditvurdering,
            kan der være enorm forskel på, hvad du bliver godkendt til hos dem. Ansøger du
            eksempelvis om at
            <a
              href="https://www.saverium.dk/laan-penge/beloeb/300000-kr"
              rel="noopener noreferrer"
              target="_blank"
              >låne 300.00 kr.</a
            >, vil nogle udbydere tilbyde dig et lavere lån, andre afvise dig helt og
            nogle godkende dig på stedet. Ansøger du kun hos et par låneudbydere, kan du
            risikere ikke at blive godkendt til det lån, du i virkeligheden har brug for.
            Når du ansøger gennem os, ansøger vi hos så mange partnere, at du al
            sandsynlighed vil få godkendt lånet, hvis det er økonomisk forsvarligt ift.
            din økonomi.
          </p>
          <h3>Mere fleksibilitet</h3>
          <p>
            Hos nogle låneudbydere er man tvunget til at vælge mellem få foruddefinerede
            valg i forhold til lånebeløb eller løbetid. Det kan eksempelvis være, at man
            kun kan
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne pengene</a
            >
            i et, to eller tre år, hvilket også kan være fint i nogle tilfælde. Vi mener
            dog, at løbetid skal passe sammen med jeres mulighed for månedlig afdrag, og
            der kan manglende på fleksibilitet betyde, at man sætter sig for hårdt. Når du
            søger hos os, kan du vælge lige præcis det antal år, du har lyst til - og så
            finder vi låneudbydere, som tilbyder onlinelån med lige præcis den løbetid. Du
            kan vælge en løbetid på helt op til 15 år, hvilket også gælder på mindre
            lånebeløb.
          </p>
        </div>

        <div class="text-container">
          <h2 id="saadan-fungerer-vores-service">Sådan fungerer vores service</h2>
          <p>
            Vi er ikke en udbyder af onlinelån, men vi kan hjælpe dig med at få det bedste
            overblik over de onlinelån, der findes på markedet. På den måde sparer du tid,
            som du kan bruge på noget sjovere end at sidde og udfylde låneansøgninger.
          </p>
          <p>Vores service fungerer så simpelt:</p>
          <h3>1) Udfyld dine oplysninger</h3>
          <p>
            Vi skal bruge lidt stamdata om dig, og så skal du logge ind med NemID eller
            MitID, hvilket giver os mulighed for at indhente alle de oplysninger som
            kræves fra hhv. banker og finansieringsselskaber. Dette sparer dig for meget
            tid og sikrer os korrektheden at oplysningerne, da de automatisk bliver hentet
            direkte fra der, hvor de står.
          </p>
          <h3>2) Vi indhenter tilbud på onlinelån</h3>
          <p>
            Når du blot har lavet den ene ansøgning hos os, sørger vi for at lave
            ansøgningen hos en lang række af Danmarks største og bedste udbydere af
            onlinelån. Denne proces sker helt automatisk og tager så kort tid, at du
            nærmest får svar med det samme.
          </p>
          <h3>3) Du vælger det lån, du ønsker</h3>
          <p>
            Vi præsenterer dig for alle de lånetilbud, hvor udbyderne godkendte dig i
            deres kreditvurdering. Så nu er det blot op til dig at klikke på og acceptere
            det tilbud, som passer bedst ind i din økonomi.
          </p>
          <h3>4) Onlinelånet bliver udbetalt til din konto</h3>
          <p>
            Hvornår pengene præcist er på din konto afhænger af den enkelte udbyder. Hos
            nogle udbydere sker det allerede samme dag, hvor det hos andre kan tage et par
            hverdage. Det er dog fælles for alle sammen, at de har fokus på at udbetale
            pengene så hurtigt som muligt.
          </p>
        </div>

        <div class="text-container">
          <h2 id="onlinelaan-til-det-du-staar-og-mangler">
            Onlinelån til det du står og mangler
          </h2>
          <p>
            En af de store fordele ved onlinelån er, at du kan bruge pengene på præcis
            det, du ønsker. I modsætning til
            <a
              href="https://www.saverium.dk/laan-penge/bolig"
              rel="noopener noreferrer"
              target="_blank"
              >boliglån</a
            >
            og
            <a
              href="https://www.saverium.dk/laan-penge/billaan"
              rel="noopener noreferrer"
              target="_blank"
              >billån</a
            >
            er der ingen, som bestemmer, hvad dit godkendte lån kan bruges til. Herunder
            kan du se nogle klassiske eksempler på, hvad folk vælger at benytte deres
            onlinelån til.
          </p>
          <h3>Bryllup</h3>
          <p>
            Det er populært at
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan/bryllup"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge til bryllup</a
            >, da det for mange er en af de største dage i deres liv og en dag, hvor de
            ikke ønsker at gå for meget på kompromis. Med udgifter til lokaler, mad,
            fotograf, musik, kjole, jakkesæt, pynt, ringe og meget mere, er der også
            rigeligt med udgifter at dække. Onlinelånet er populært til disse anledninger,
            da man ønsker frihed ift., hvordan pengene bruges.
          </p>
          <h3>Ferie</h3>
          <p>
            Det er vigtigt at “lade op”, så man ikke lader hverdagens trummerum køre en
            død for energi. Især gennem de seneste årtier, hvor mental sundhed er blevet
            et emne, som fylder mere og mere. En ferie er en god måde til at lade op, da
            det hjælper en til at glemme hverdagens pligter og stressen på arbejdspladsen.
            Derfor ønsker mange også at
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan/ferie"
              rel="noopener noreferrer"
              target="_blank"
              >låne til ferie</a
            >, så man kan komme afsted til det nødvendige afbræk fra hverdagen.
          </p>
          <h3>Flytning og indskud</h3>
          <p>
            Det kan være dyrt at skifte bolig. Ikke nok med, at der typisk er en masse
            udgifter til indretning, så er der også to udgifter, som nemt kan trække
            tænder ud. Flytning og indskud er to engangsudgifter, som kan være svære at få
            råd til, når man samtidig har så mange andre økonomiske poster. Især indskud
            kan være en hovedpine, da man typisk skal vente på at få indskuddet tilbage
            fra sin tidligere bolig, mens man på forhånd skal betale indskud for den nye
            bolig. Et
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan/indskud"
              rel="noopener noreferrer"
              target="_blank"
              >lån til indskud</a
            >
            kan derfor hjælpe i denne overgangsperiode.
          </p>
          <h3>Jul</h3>
          <p>
            Julen er en af de største højtider i Danmark, og for mange er julen det
            tidspunkt på året, hvor hele familien samles. Derfor har mange også et ønske
            om at julen skal være noget særligt, hvor der både skal være økonomi til god
            mad, hyggelig dekoration, sjove lege og gode gaver. Især gaverne er noget, som
            virkelig blive en kostlig affære. Vi anbefaler naturligvis, at man vælger
            gaver som matcher ens økonomi, og ikke nødvendigvis lader beløbet afgøre,
            hvorvidt det er en god gave eller nej. Dog anerkender vi også, at det er
            koster nogle penge, hvis man skal købe gaver til mange, man holder af.
          </p>
          <h3>Regninger</h3>
          <p>
            Det er også ofte set, at folk har brug for at
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan/regninger"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge til regninger</a
            >, da de fleste hustande har oplevet, at der lige pludselig kommer en uventet
            høj regning. Det kan eksempelvis være en regning i forbindelse med boligen,
            hvor el, varme eller vand pludseligt steg i forhold til året før. Det kan dog
            også være andre regninger som eksempelvis hos mekanikeren, tandlægen eller
            dyrlægen.
          </p>
          <h3>Uddannelse</h3>
          <p>
            En uddannelse er en af de bedste investeringer, man kan gøre sig. Det kan
            enten være de klassiske uddannelser eller efteruddannelse i et område via
            kurser og træning. Lønstatistikkerne taler sit tydelige sprog, og dem med
            uddannelse tjener i gennemsnit langt højere månedslønne. Ønsker du at tage en
            uddannelse, som ikke er gratis for dig, kan et
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan/uddannelse"
              rel="noopener noreferrer"
              target="_blank"
              >lån til uddannelse</a
            >
            være en god investering i fremtiden.
          </p>
        </div>

        <div class="text-container">
          <h2 id="ofte-stillede-spoergsmaal">Ofte stillede spørgsmål</h2>
          <h3>“Hvad koster det at låne penge online?”</h3>
          <p>
            Dette afhænger af så mange faktorer, at man ikke kan give et svar. De primære
            faktorer er eventuelle stiftelsesomkostninger, lånebeløb, løbetid og rente på
            onlinelånet. Debitorrenten på mange onlinelån ligger typiske mellem 5% og 13%.
            Vi anbefaler dog, at du primært kigger på ÅOP og de samlede
            kreditomkostninger, når du sammenligner og vurderer, hvilket lån der er bedst.
          </p>
          <h3>“Hvor kan jeg få onlinelån?”</h3>
          <p>
            Der findes som udgangspunkt to steder, hvor du kan låne penge online. Hos
            banker og låneselskaber. Hos nogle banker kan du dog stadig opleve, at du skal
            komme fysisk ned i banken for at optage lån. Du vil også kunne opleve at få
            tilbudt onlinelån hos mange forskellige butikker, men det vil ofte være en
            låneudbyder, som du rent faktisk optager lånet hos, der så har et samarbejde
            med den pågældende butik.
          </p>
          <h3>“Er det gratis?”</h3>
          <p>
            Rigtig mange ønsker at finde det billigste onlinelån og naturligvis gerne
            gratis, hvis muligt. Kort fortalt, så er det aldrig gratis at låne penge, men
            i nogle situationer kan du få et introduktionstilbud eller handle i en butik,
            som tager omkostningen for dig. Derfor kan du sagtens opleve at få lov til at
            låne penge online, ganske gratis.
          </p>
          <h3>“Hvor meget kan jeg låne online?”</h3>
          <p>
            Dette spørgsmål er vi nødt til at tage i to etaper, for der kan være forskel
            på hvor meget det er muligt at låne online, og hvor meget du kreditvurderes
            til at kunne låne. Førstnævnte er dog rimelig nemt at svare på, da det er
            muligt at låne helt op til 500.000 kr. online. Ift. hvor meget du kan låne,
            afhænger svaret af en lang række faktorer, og det kan varierer fra udbyder til
            udbyder. Når du ansøger gennem os får du dog den bedst mulighed for at finde
            ud af, om du rent faktisk kan låne det beløb, du ønsker.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
