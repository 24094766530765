<template>
  <div class="row article non-landing">
    <h1 class="center-title">Hvor nemt er det at optage et lån?</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            Skulle du gå rundt og tænke på hvor nemt det er at optage et lån, og har brug
            for at låne penge nemt. Så er det heldigvis vigtigt at vide du ikke behøver at
            gå på kompromis med prisen på lånet.
          </p>
          <p>
            Det er nemmere end nogensinde før at finde det billigste lån online, så du har
            ingen grund til at vælge det første og måske bedste tilbud du ser, bare fordi
            det skal være nemt og hurtigt.
          </p>
          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_dk/article-imgs/how-easy-taking-out-loan-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_dk/article-imgs/how-easy-taking-out-loan.jpg"
            alt="Hvor nemt er det at optage et lån?"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul>
              <li>
                <a href="#undersoeg-marked-ved-at-sammenligne">
                  Undersøg marked ved at sammenligne
                </a>
              </li>
              <li>
                <a href="#hvad-er-kravene-for-at-laane-penge">
                  Hvad er kravene for at låne penge?
                </a>
              </li>
              <li>
                <a href="#kom-i-gang-paa-faa-minutter"> Kom i gang på få minutter </a>
              </li>
              <li>
                <a href="#saverium-laver-arbejdet-for-dig-paa-laanemarked-">
                  Saverium laver arbejdet for dig på lånemarked
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container">
          <h2 id="undersoeg-marked-ved-at-sammenligne">
            Undersøg marked ved at sammenligne
          </h2>
          <p>
            Inden du kaster dig over det første lånetilbud du ser, så gør dig selv den
            tjeneste ved at undersøge marked ved at sammenligne lånetilbud fra forskellige
            låneudbydere. Så kan du være sikker på du ikke snyder dig selv fordi du
            tilfældigt fik tilbudt et dyrt lån.
          </p>
          <p>
            Det kan du heldigvis undgå ved at sammenligne lånetilbud fra mere end 10
            forskellige låneudbydere igennem Saverium, og det vil vi gerne hjælpe dig med,
            så længe du bare udfylde en enkelt uforpligtende ansøgningen. Det går hurtigt
            og så kan du sikkert sammenligne dine tilbud indtil du finder det bedste
            tilbud til dig, mens vi gør det hårde arbejde.
          </p>
        </div>
        <div class="text-container">
          <h2 id="hvad-er-kravene-for-at-laane-penge">
            Hvad er kravene for at låne penge?
          </h2>
          <p>
            Der er som regel få krav for du kan låne penge. Her er listet kravene for at
            låne penge:
          </p>
          <ul>
            <li>Du skal være fyldt 18 år</li>
            <li>Have fast bopæl i Danmark</li>
            <li>Have en dansk bankkonto</li>
            <li>Have et gyldigt Nem-ID/MitID</li>
          </ul>
          <p>
            Skulle du leve op til disse krav, kan du optage et lån. Dog skal du være
            opmærksom på at bankerne selv har nogle lovmæssige krav, som de skal give en
            afgørende vurdering på.
          </p>
          <p>
            Alle låneudbydere skal lave en kreditvurdering af dig inden de kan godkende
            dig til at modtage et
            <a
              href="https://www.saverium.dk/nye-lan/"
              rel="noopener noreferrer"
              target="_blank"
              >nyt lån</a
            >.
          </p>
        </div>
        <div class="text-container">
          <h2 id="kom-i-gang-paa-faa-minutter">Kom i gang på få minutter</h2>
          <p>
            Som nævnt tidligere kan du komme i gang på få minutter nu hvor det er super
            nemt at sammenligne lånetilbud online. Du skal bare udfylde en ansøgning, hvor
            du skal udfylde nogle spørgsmål som omhandler din bolig, husstand og
            privatøkonomi. Det vil i alt tage ganske få minutter at besvare de nødvendige
            oplysninger.
          </p>
          <p>
            Når du har udfyldt din låneansøgning og sendt den, så klare vi resten og
            sender din ansøgning til låneudbyderne, hvor du så efter kort tid vil modtage
            <a
              href="https://www.saverium.dk/lanetilbud/"
              rel="noopener noreferrer"
              target="_blank"
              >lånetilbud </a
            >retur.
          </p>
          <p>
            Som regel indenfor 30 minutter vil alle de interesseret låneudbydere have
            svaret på din ansøgning, og dit næste skridt er blot at vælge det lån der
            passer dig bedst. Du har nu muligheden for at sammenligne de modtagende
            lånetilbud. Du bestemmer helt selv om du ønsker at vælge et af de modtagende
            lånetilbud.
          </p>
          <p>
            Har du valgt et af de lånetilbud du skulle have fået tilbudt, så mangler der
            bare en kreditvurdering af låneudbyderen inden lånet kan blive endeligt
            godkendt. Når låneudbyderen har verificeret dine informationer, kan lånet
            blive godkendt og du kan underskrive låneaftalen med Nem-ID/MitID.
          </p>
        </div>
        <div class="text-container">
          <h2 id="saverium-laver-arbejdet-for-dig-paa-laanemarked-">
            Saverium laver arbejdet for dig på lånemarked
          </h2>
          <p>
            Saverium.dk er her for at hjælpe dig med at sammenligne lånemarked for at give
            alle en fair chance for at finde det bedste lånetilbud. Vi sammenligner
            lånetilbud fra flere velkendte banker og låneudbydere med kun én enkelt
            <a
              href="https://www.saverium.dk/ansoegning"
              rel="noopener noreferrer"
              target="_blank"
              >ansøgning</a
            >. Du kan ansøge om et lån fra 3000 - 500.000 kroner alt efter dine egne behov
            med en låneperiode på 1-15 år.
          </p>
          <p></p>
          <p>2022-02-01/Søren</p>
        </div>
      </div>
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
  </div>
</template>
<script>
import LoanWidget from '@/components/saverium_dk/seo_components/LoanWidget.vue'
export default {
  components: {
    LoanWidget,
  },
}
</script>
