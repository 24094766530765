<template>
  <div class="row article non-landing">
    <h1 class="center-title">At sammenligne hjælper os med at træffe det rette valg</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            I dag sammenligner vi stort set alt der har en pris. Om det så er mælk nede
            ved den lokale købmand eller om det er et billigt billån man leder efter. Så
            vil vores mål altid være at finde det vi ønsker, men til den bedste pris som
            muligt.
          </p>

          <b-img-lazy
            width="700"
            height="466"
            class="article-img"
            src="/static/img/saverium_dk/article-imgs/compare-everything.jpg"
            blank-src="/static/img/saverium_dk/article-imgs/compare-everything-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            alt="Med de rigtige økonomiske beslutninger og et par tip kan du stadig vende din økonomi til det bedre. "
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul>
              <li>
                <a href="#fordelen-ved-at-sammenligne"> Fordelen ved at sammenligne </a>
              </li>
              <li>
                <a href="#spar-penge-ved-at-sammenligne-dit-laan">
                  Spar penge ved at sammenligne dit lån
                </a>
              </li>
              <li>
                <a href="#saverium-hjaelper-dig-med-at-sammenligne-">
                  Saverium hjælper dig med at sammenligne
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container">
          <h2 id="fordelen-ved-at-sammenligne">Fordelen ved at sammenligne</h2>
          <p>
            Ved at sammenligne, snyder vi ikke os selv ved at tage imod det første der
            bliver tilbudt os. Fordelen ved at kunne sammenligne lån, rejser, forsikringer
            og meget andet, gør at vi får et større udvalg at vælge i mellem. Det betyder
            som regel altid at vi til sidst ender med at få hvad vi ønsker os, men til en
            billigere pris end hvad vi have fået tilbudt til at starte med.
          </p>
        </div>
        <div class="text-container">
          <h2 id="spar-penge-ved-at-sammenligne-dit-laan">
            Spar penge ved at sammenligne dit lån
          </h2>
          <p>
            Lånemarked er stort og der findes mange udbydere af lån der alle ønsker du
            optager dit nye lån hos dem. Dette kan gøre det overskueligt at finde ud af
            hvor man skal starte, men ved at bruge Saverium.dk hjælper vi dig med at spare
            tid ved at sammenligne lånemarked for dig. Så du kan slappe af mens vi finder
            dig dit skræddersyet lånetilbud.
          </p>
        </div>
        <div class="text-container">
          <h2 id="saverium-hjaelper-dig-med-at-sammenligne-">
            Saverium hjælper dig med at sammenligne
          </h2>
          <p>
            Målet med Saverium.dk er at hjælpe folk med at sammenligne lånemarked for at
            give alle en chance for at finde det perfekte lånetilbud. Vi sammenligner
            lånetilbud fra flere anerkendte banker og låneudbydere med kun én enkelt
            <a
              href="https://www.saverium.dk/ansoegning"
              rel="noopener noreferrer"
              target="_blank"
              >ansøgning</a
            >. Du kan ansøge om et lån på 3000 - 500.000 kroner alt efter dine egne behov
            med en låneperiode på 1-15 år.
          </p>
          <p></p>
          <p>2021-11-29/Søren</p>
        </div>
      </div>
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
  </div>
</template>
<script>
import LoanWidget from '@/components/saverium_dk/seo_components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'

export default {
  components: {
    LoanWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    author: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.image,
        this.type,
        this.author
      )
    },
  },
}
</script>
<style lang="scss"></style>
