export default {
  days: ['Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør', 'Søn'],
  months: [
    'Jan.',
    'Febr.',
    'Marts',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Aug.',
    'Sept.',
    'Okt.',
    'Nov.',
    'Dec.',
  ],
  placeholder: {
    date: 'Vælg en dato',
  },
  formatLocale: {
    monthsShort: [
      'Januar',
      'Februar',
      'Marts',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'December',
    ],
  },
}
