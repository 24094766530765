<template>
  <div class="row article">
    <h1 class="center-title">Kredyt na wykończenie domu – najważniejsze informacje</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            Banki udzielają klientom kredytów celowych, jak np. kredyt hipoteczny czy
            samochodowy, jak i takich o dowolnym przeznaczeniu, czego przykładem jest
            <a
              href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-kalkulator-i-oferty-pozyczek-gotowkowych/"
              rel="noopener noreferrer"
              target="_blank"
              >kredyt gotówkowy</a
            >. Który produkt spełni oczekiwania klientów chcących sfinansować koszty
            wykończenia domu lub mieszkania? Warto zaciągnąć kredyt na wykończenie domu.
            Co można kupić za pieniądze uzyskane od banku?
          </p>
          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/loan-for-finishing-house-tiny.webp"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/loan-for-finishing-house.webp"
            alt="Kredyt na wykończenie domu – najważniejsze informacje"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul class="table-of-contents">
              <li>
                <a href="#co-to-jest-kredyt-na-wykonczenie-domu">
                  Co to jest kredyt na wykończenie domu?
                </a>
              </li>
              <li>
                <a href="#kredyt-hipoteczny-na-wykonczenie-domu">
                  Kredyt hipoteczny na wykończenie domu
                </a>
              </li>
              <li>
                <a href="#kredyt-gotowkowy-na-wykonczenie-domu">
                  Kredyt gotówkowy na wykończenie domu
                </a>
              </li>
              <li>
                <a href="#zalety-kredytu-na-wykonczenie-domu-">
                  Zalety kredytu na wykończenie domu
                </a>
              </li>
              <li>
                <a href="#kredyt-na-wykonczenie-domu-co-mozna-kupic">
                  Kredyt na wykończenie domu – co można kupić?
                </a>
              </li>
              <li>
                <a href="#kredyt-na-wykonczenie-domu-w-stanie-surowym">
                  Kredyt na wykończenie domu w stanie surowym
                </a>
              </li>
              <li>
                <a href="#kredyt-na-wyposazenie-domu"> Kredyt na wyposażenie domu </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container mt-5">
          <h2 id="co-to-jest-kredyt-na-wykonczenie-domu">
            Co to jest kredyt na wykończenie domu?
          </h2>
          <p>
            Zakup lub budowa domu nie kończy kosztów, jakie muszą ponieść właściciele. O
            ile obiekt nie jest kupowany w stanie pod klucz, nadającym się od razu do
            zamieszkania, trzeba liczyć się z pewnymi wydatkami, które rosną
            proporcjonalnie do tego, w jakim stanie technicznym i estetycznym znajduje się
            lokal. Stary dom, ze starymi instalacjami i wykończeniem wnętrz nadaje się do
            generalnego remontu.
          </p>
          <p>
            Koszty wykończenia i wyposażenia domu można pokryć z własnej kieszeni lub
            <strong>zdecydować się na kredyt na wykończenie nieruchomości</strong>. Może
            być to kredyt gotówkowy, który przeznaczyć można na dowolne zakupy, bez
            konieczności zgłaszania ich do banku w ramach wniosku. Jednocześnie na takie
            cele jak wykończenie domu w stanie surowym można zaciągnąć kredyt hipoteczny,
            ale w tym przypadku trzeba już podać do banku, po co na te pieniądze oraz
            ustanowić zabezpieczenie spłaty w postaci wpisu hipoteki na rzecz banku
            kredytującego do księgi wieczystej nieruchomości.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-hipoteczny-na-wykonczenie-domu">
            Kredyt hipoteczny na wykończenie domu
          </h2>
          <p>
            Już podczas ubiegania się w banku o kredyt hipoteczny na budowę lub zakup domu
            można od razu
            <strong
              >powiększyć jego kwotę o środki na wyposażenie domu czy kredyt na
              wykończenie obiektu</strong
            >. Co można kupić w jego ramach? Można zrealizować prace wykończeniowe, jakie
            zaplanowane zostały we wniosku. Także po zakupie domu można wziąć zobowiązanie
            na jego wykończenie. Służyć będzie jednak temu raczej pożyczka hipoteczna niż
            kredyt jako taki.
          </p>
          <p>
            Kredyt hipoteczny może służyć wykańczaniu domu, ale rzadko kiedy bank zgodzi
            się na to, by sfinansować z niego wyposażenie nowego lokum. Pożyczka gotówkowa
            nie ma takich ograniczeń, dlatego spożytkować można ją dowolnie. Możliwe jest
            wówczas zaciągnięcie kredytu nawet na 100 000 zł na wykończenie domu.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-gotowkowy-na-wykonczenie-domu">
            Kredyt gotówkowy na wykończenie domu
          </h2>
          <p>
            Na potrzeby wykończenia domu i uzupełnienia jego wyposażenia można zaciągnąć
            kredyt gotówkowy. To produkt, który nie wymaga podawania celu, na jaki
            przeznaczone zostaną pieniądze udostępnione klientowi przez bank.
            <strong>Kredytobiorca ma swobodę w dysponowaniu środkami</strong>. Taki kredyt
            na wykończenie domu nie ogranicza, co można kupić w ramach finansowania
            zewnętrznego. W praktyce można na kredyt gotówkowy wyposażyć mieszkania w
            sprzęt RTV i AGD, zrobić wykończenie sufitów i podłóg i jeszcze zapłacić za
            nowe komputery dla domowników.
          </p>
          <p>
            Jeśli klient poszukuje środków na wyposażenie domu, zwykle nie ma możliwości
            otrzymania kredytu hipotecznego. Może za to postarać się w dowolnie wybranym
            banku o kredyt gotówkowy, który może mu zostać udzielony na uproszczonych
            warunkach.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="zalety-kredytu-na-wykonczenie-domu-">
            Zalety kredytu na wykończenie domu
          </h2>
          <p>
            Można zastanawiać się, czy wybrać kredyt na wykończenie domu z zabezpieczeniem
            hipotecznym czy bez niego. Najłatwiej można zaciągnąć kredyt gotówkowy na
            wyposażenie domu i na jego wykończenie,
            <strong>a cele tego zobowiązania nie są ściśle określone</strong>. Jednak
            należy powiedzieć, że będzie on droższy od kredytu hipotecznego, który
            przeznaczyć możesz na wykończenie pomieszczeń w nowowybudowanym domu.
          </p>
          <p>
            <strong
              >Zalety kredytu hipotecznego na wykończenie domu można przedstawić w
              następujący sposób:</strong
            >
          </p>
          <ul>
            <li>
              potencjalnie wysoka maksymalna kwota kredytu, ograniczona jedynie zdolnością
              kredytową klienta,
            </li>
            <li>długi okres kredytowania,</li>
            <li>możliwość rozłożenia spłaty długu na wiele, niskich rat,</li>
            <li>
              najtańsza opcja finansowania na rynku w porównaniu z innymi kredytami na
              wykończenie domu.
            </li>
          </ul>
          <p>
            Niestety, kredyt hipoteczny na wykończenie mieszkania wymaga wniesienia wkładu
            własnego, w wysokości 20 proc. wartości planowanych prac wykończeniowych, choć
            połowę wkładu można zastąpić ubezpieczeniem niskiego wkładu. Kredytobiorca
            musi też liczyć się z koniecznością dopełnienia wielu formalności przy
            ubieganiu się o kredyt hipoteczny na wykończenie domu w stanie surowym, np.
            konieczne jest sporządzenie kosztorysu i haromonogramu prac.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-na-wykonczenie-domu-co-mozna-kupic">
            Kredyt na wykończenie domu – co można kupić?
          </h2>
          <p>
            Jeśli zaciągany jest kredyt hipoteczny na wykończenie domu, to można nim
            sfinansować wydatki ponoszone na:
          </p>
          <ul>
            <li>wykańczanie powierzchni ścian i sufitów – malowanie i tynkowanie,</li>
            <li>grodzenie powierzchni użytkowej z użyciem ścianek działowych,</li>
            <li>układanie i wykańczanie podłóg,</li>
            <li>
              montaż instalacji elektrycznej, hydraulicznej, gazowej, kanalizacyjnej,
            </li>
            <li>montaż wszystkich niezbędnych sanitariatów.</li>
          </ul>
          <p>
            Istnieje możliwość pokrycia takim kredytem kosztów robocizny wykonanej przez
            firmę zatrudnioną do przeprowadzania prac. Natomiast jeśli chodzi o zakupy
            realizowane na kredyt hipoteczny na wykończenie mieszkania, to można z niego
            kupić wszelkie materiały budowlano-wykończeniowe. Nie ma możliwości zakupu z
            kredytu z zabezpieczeniem hipotecznym elementów ruchomych, które nie są trwale
            związane z obiektem.
          </p>
          <p>
            Jeśli zaciągany jest kredyt hipoteczny na wykończenie domu w stanie surowym
            czy też kredyt na wykończenie domu z rynku wtórnego,
            <strong
              >ważnym elementem przy wnioskowaniu o niego w banku jest nie tylko wniosek,
              ale również kosztorys prac</strong
            >. Jest to dokument, który pokazuje, jakie cele mają zostać spełnione przy
            użyciu przyznanych środków. Do wniosku trzeba dołączyć zatem szczegółowy
            kosztorys wraz z harmonogramem działań. Będzie to konieczne przy ubieganiu się
            o dodatkowy kredyt na wykończenie domu w ramach zaciąganego kredytu na jego
            zakup, jak i w przypadku oddzielnego zobowiązania zabezpieczonego hipoteką
            przeznaczoną wyłącznie na sfinansowanie prac wykończeniowych.
          </p>
          <p>
            Kosztorys będzie najpewniej zbędny, jeśli klient zdecyduje się na gotówkowy
            kredyt na wykończenie domu lub na pożyczkę hipoteczną. Nie musi on wtedy
            informować banku, na co chce wydać pieniądze.
          </p>
          <p>
            Przy zaciąganiu kredytu na wyposażenie domu w formie pożyczki hipotecznej lub
            kredytu gotówkowego bank nie kontroluje, jak wydawane są takie środki, dlatego
            klient zyskuje pełną swobodę dysponowania nimi.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-na-wykonczenie-domu-w-stanie-surowym">
            Kredyt na wykończenie domu w stanie surowym
          </h2>
          <p>
            Potencjalnie wydatki w przypadku planowania wykończenia domu w stanie surowym
            – otwartym lub zamkniętym, mogą być bardzo wysokie. Zaciągnięcie kredytu
            gotówkowego natomiast może obciążyć klienta wysokimi kosztami, a przy tym
            zadłużenie będzie musiało być spłacone stosunkowo szybko, przy dużych
            miesięcznych ratach kapitałowo-odsetkowych.
          </p>
          <p>
            Znacznie lepszą alternatywą wydaje się
            <strong>kredyt lub pożyczka z zabezpieczeniem hipotecznym</strong>. W
            przypadku kredytu na wykończenie domu w stanie surowym można jednocześnie
            zaciągnąć zobowiązanie na budowę domu, jak i na jego wykończenie. To kredyt
            budowlano-hipoteczny, który po wybudowaniu nieruchomości zabezpieczony jest
            jej hipoteką i wpisem banku do księgi wieczystej. Kredyt hipoteczny na
            wykończenie domu wypłacany jest głównie w transzach, które uruchamiane są w
            miarę postępu w budowie czy wykańczania lokalu mieszkalnego.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="kredyt-na-wyposazenie-domu">Kredyt na wyposażenie domu</h2>
          <p>
            Jeśli planowane są wydatki związane jedynie z wyposażeniem domu w meble i
            sprzęty, które nie są trwale związane z konstrukcją budynku, tj. z podłogą,
            ścianami czy sufitem, wówczas najprawdopodobniej nie będzie możliwości
            skorzystania z kredytu hipotecznego.
            <strong
              >Można za to zaciągnąć prosty kredyt gotówkowy na wykończenie
              mieszkani</strong
            >a. Klient nie musi wnosić wkładu własnego, jak w przypadku kredytowania
            hipotecznego, a po spełnieniu wymogów związanych z odpowiednio wysoką
            zdolnością i dobrą historią kredytowania, może otrzymać w dosyć krótkim czasie
            wypłatę pieniędzy z pożądanego zobowiązania na swoje konto bankowe.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
