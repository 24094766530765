<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån Penge</h1>
      <div class="top-text text-container">
        <p>
          Har du brug for at låne penge hurtigt og billigt? Så led ikke længere. Hos os
          kan du på blot to minutter indhente adskillige, skræddersyede lånetilbud fra
          forskellige danske og internationale banker. Det er gratis, uforpligtende og 100
          % sikkert.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#hvordan-laaner-du-penge-igennem-os"
                    >Hvordan låner du penge igennem os?</a
                  >
                </li>
                <li>
                  <a href="#hvordan-finder-jeg-det-bedste-laan-for-mig-"
                    >Hvordan finder jeg det bedste lån for mig?
                  </a>
                </li>
                <li><a href="#hvordan-laaner-jeg-penge">Hvordan låner jeg penge?</a></li>
                <li>
                  <a href="#hvad-koster-det-at-laane-penge-online-"
                    >Hvad koster det at låne penge online?
                  </a>
                </li>
                <li>
                  <a href="#hvordan-kan-jeg-laane-penge-endnu-billigere"
                    >Hvordan kan jeg låne penge endnu billigere?</a
                  >
                </li>
                <li>
                  <a href="#hvor-mange-penge-kan-jeg-laane"
                    >Hvor mange penge kan jeg låne?</a
                  >
                </li>
                <li><a href="#kan-alle-laane-penge">Kan alle låne penge?</a></li>
                <li><a href="#vores-laanekrav">Vores lånekrav</a></li>
                <li>
                  <a href="#hvad-kan-jeg-laane-penge-til">Hvad kan jeg låne penge til?</a>
                </li>
                <li>
                  <a href="#er-det-nemt-at-laane-penge">Er det nemt at låne penge?</a>
                </li>
                <li>
                  <a href="#hvor-ofte-kommer-der-nye-laan-paa-markedet"
                    >Hvor ofte kommer der nye lån på markedet?</a
                  >
                </li>
                <li>
                  <a href="#laan-penge-med-fast-rente-vs-variabel-rente-hvad-er-bedst"
                    >Lån penge med fast rente vs. variabel rente: Hvad er bedst?</a
                  >
                </li>
                <li>
                  <a href="#skal-vi-hjaelpe-dig-med-din-laaneansoegning"
                    >Skal vi hjælpe dig med din låneansøgning?</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="hvordan-laaner-du-penge-igennem-os">
            Hvordan låner du penge igennem os?
          </h2>
          <p>
            Alt du skal gøre, er at udfylde vores låneformular her på siden. Dernæst får
            du tilsendt en række personlige tilbud på billige lån, præsenteret i en
            overskuelig oversigt. Du kan let sammenligne dine lånemuligheder og frit vælge
            den bank, som du ønsker at låne penge hos.
          </p>
          <p>
            På denne side guider vi dig i, hvordan du nemt, billigt og sikkert kan låne
            penge til dit individuelle lånebehov. Vælg et valgfrit
            <a
              href="https://www.saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              >lånebeløb</a
            >
            mellem 3.000-500.000 kr. til dit personlige lånebehov. Der er ingen krav til,
            at du skal stille sikkerhed, og du vælger selv, om du ønsker et privat
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >
            eller
            <a
              href="https://www.saverium.dk/laan-penge/erhvervslaan"
              rel="noopener noreferrer"
              target="_blank"
              >erhvervslån</a
            >. Kom i gang på to minutter, og lån penge i dag.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvordan-finder-jeg-det-bedste-laan-for-mig-">
            Hvordan finder jeg det bedste lån for mig?
          </h2>
          <p>
            Den 1. juli 2020 trådte nye regler i kraft, som har gjort det billigere for
            dig som låntager at låne penge billigt online. Der findes mange lånemuligheder
            på nettet, og det kan virker uoverskueligt at sortere “fårene fra bukkene”.
            Sådan behøver det ikke at være. Vi har derfor skabt en proces, som gør det let
            og ligetil for dig at finde det bedste og billigste lånetilbud mellem mange
            forskellige udbydere.
          </p>
          <p>
            Inden du udfylder vores låneformular og modtager en oversigt over dine
            personlige lånemuligheder, er der et par forberedelser, der er gode at gøre
            sig inden.
          </p>
          <h3>Tag stilling til dit lånebehov</h3>
          <p>
            Først og fremmest skal du beslutte dig for, hvor mange penge du har brug for
            at låne. Det er en god idé, at overveje hvad dit egentlige lånebehov er, og
            kun låne et beløb, som matcher dette. Vi anbefaler, at du er 100 % ærlig
            overfor dig selv, så du hverken lånere mere eller mindre, end hvad der er brug
            for. Låner du for lidt, kan du være nødsaget til at optage et
            <a
              href="https://www.saverium.dk/laan-penge/kviklaan"
              rel="noopener noreferrer"
              target="_blank"
              >kviklån</a
            >
            senere, hvilket kan blive dyrere end nødvendigt. Låner du for meget, bliver
            det også dyrere, da de løbende renteomkostninger dermed beregnes ud fra et
            højere beløb.
          </p>
          <h3>Afklar dit månedlige rådighedsbeløb</h3>
          <p>
            Inden du optager et lån hos én af vores mange
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere</a
            >, er det vigtigt, at du er opmærksom på, hvor mange penge du kan afdrage med
            hver måned. Dette kaldes dit rådighedsbeløb. Du finder dit månedlige
            rådighedsbeløb ved at klarlægge hvor mange penge du har tilbage på en måned,
            efter alle din faste og variable udgifter er betalt. En god ide er at lægge et
            budget, så du altid har et overblik.
          </p>
          <p>Typiske månedlige udgifter, som bør indgå i dit budget, er;</p>
          <ul>
            <li>husleje</li>
            <li>mad og husholdning</li>
            <li>bil og benzin</li>
            <li>vand, varme og el</li>
            <li>forsikringer</li>
            <li>opsparing</li>
          </ul>
          <p>Når du regner på, hvor mange udgifter du har, så glem ikke poster som;</p>
          <ul>
            <li>tandlæge, medicin mv.</li>
            <li>streamingtjenester, internet og mobil</li>
            <li>fritid og sport</li>
            <li>ferier</li>
            <li>fornøjelser</li>
            <li>tøj og diverse</li>
          </ul>
          <p>
            Hvis du er i tvivl om, hvordan du lægger et holdbart budget, findes der et
            væld af færdiglavede skabeloner på nettet. Disse er ofte ganske gratis.
          </p>
          <h3>Find den rette løbetid</h3>
          <p>
            Lånets løbetid er afgørende for, hvor dyrt det bliver for dig at låne penge.
            Jo hurtigere du betaler pengene tilbage, desto færre renter vil der blive
            pålagt lånet i perioden. Hvad end du vil sikre, at du får den billigste
            <a
              href="https://www.saverium.dk/laan-penge/kassekredit"
              rel="noopener noreferrer"
              target="_blank"
              >kassekredit</a
            >, det billigste
            <a
              href="https://www.saverium.dk/laan-penge/bolig"
              rel="noopener noreferrer"
              target="_blank"
              >boliglån</a
            >
            eller billigste
            <a
              href="https://www.saverium.dk/laan-penge/billaan"
              rel="noopener noreferrer"
              target="_blank"
              >billån</a
            >, vil vi altid anbefale dig at sigte efter så kort en løbetid som muligt. Jo
            kortere løbetid, desto færre løbende låneomkostninger.
          </p>
          <p>
            Det er let at finde den rette løbetid for dit lån, når først du kender dit
            månedlige rådighedsbeløb. I låneformularen her på siden, indsætter du blot,
            hvor mange penge, du ønsker at låne. Herefter kan du justere på løbetiden,
            indtil den månedlige ydelse matcher dit rådighedsbeløb. Føler du, at løbetiden
            er for lang? Så overvej, om der er et reelt behov for at låne det beløb, du
            først har indtastet, eller om du stadig kan indfrie dine ønsker ved at låne
            lidt færre penge.
          </p>
          <h3>Vælg mellem fast og variabel rente</h3>
          <p>
            Overordnet set er der to renteformer at vælge mellem, når du skal låne penge;
            fast eller variabel rente. Når du kigger på billige lån med lav rente, vil du
            opleve, at det som udgangspunkt er en variabel rente, der er billigst. Det er
            ikke ensbetydende med, at du ikke kan få et billigt og fair lån med fast
            rente, hvis du foretrækker dette.
          </p>
          <p>
            Årsagen til, at en variabel rente som regel er den billigste løsning er, at
            den svinger i takt med markedsrenten. Det vil sige, at renten kan falde,
            hvilket vil medføre, at du får et billigere lån. Omvendt kan den også stige,
            hvorfor du kan risikere at få et dyrere lån.
          </p>
          <p>
            En fast rente er derimod, hvad navnet antyder; fast. Det vil sige, at den
            forbliver den samme under hele låneperioden. Når du skal vælge renteform, skal
            du gøre op med dig selv, om du vil satse eller gå den sikre vej.
          </p>
          <p>
            De lånetilbud, du modtager gennem os, er som udgangspunkt med fast rente.
            Dette gør sig stort set altid gældende ved denne type
            <a
              href="https://www.saverium.dk/laan-penge/online-laan"
              rel="noopener noreferrer"
              target="_blank"
              >online lån</a
            >.
          </p>
          <p>Læs mere om fast vs. variabel rente.</p>
          <h3>Lav en tilbagebetalingsplan</h3>
          <p>
            Når du kender dit lånebehov, rådighedsbeløb, lånets løbetid samt renteformen,
            er det en god ide at lave en tilbagebetalingsplan. Dermed
            forventningsafstemmer du med dig selv, hvornår du regner med at have betalt de
            lånte penge tilbage, og hvordan du vil gøre det. Sæt eventuelt dit månedlige
            afdragsbeløb til automatisk betaling, så du ikke skal tage stilling til
            overførslen hver måned.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvordan-laaner-jeg-penge">Hvordan låner jeg penge?</h2>
          <p>
            På blot to minutter kan du få tilsendt en oversigt med låneudbydere, der kan
            matche dine lånebehov. Det vil sige, at du på listen let kan se, hvor mange
            penge låneudbyderen kan låne dig, hvor meget du skal tilbagebetale hver måned,
            samt hvor lang løbetiden er.
          </p>
          <h3>Udfyld låneformularen</h3>
          <p>I låneformularen her på siden indtaster du;</p>
          <ul>
            <li>det ønskede lånebeløb</li>
            <li>den ønskede løbetid</li>
            <li>dine private oplysninger (navn, mail, mobilnummer)</li>
            <li>hovedformålet ved lånet</li>
          </ul>
          <p>
            Herefter skal du læse og accepterer vores tjenestevilkår og privatlivspolitik.
            Når du klikker på “få et lån”, bliver du sendt videre, og du skal herefter
            godkende din formular med dit NemID. Godkendelsen med NemID gør processen
            hurtigere, og giver os mulighed for at udbetale pengene direkte til din
            nem-konto.
          </p>
          <h3>Modtag liste over nye lån</h3>
          <p>
            De udbydere, som kan imødekomme dine behov og låne dig penge, vil fremgå på
            den personlige liste over lånetilbud, som du modtager. Det tager blot to
            minutter, fra du har udfyldt lånefomularen, til du har listen i hænderne.
          </p>
          <h3>Sammenlign dine lånemuligheder</h3>
          <p>
            Nu er det op til dig at vælge den udbyder, som du synes matcher dine lånebehov
            bedst. Kig især på ÅOP’en (årlige omkostninger i procent). Jo lavere en ÅOP,
            desto billigere er det for dig at låne penge. ÅOP dækker nemlig ikke kun over
            renterne, men også øvrige gebyrer som af og til kan være forbundet med at låne
            penge. Her kan eksempelvis være tale om et etableringsgebyr eller
            administrationsgebyr.
          </p>
          <p>
            Når du foretager din sammenligning er det også en god idé at være opmærksom
            på;
          </p>
          <ul>
            <li>om lånetypen er korrekt for dig</li>
            <li>hvor lang låneperioden er</li>
            <li>hvor meget du månedligt skal afdrage med</li>
          </ul>
          <h3>Tak ja til rette tilbud</h3>
          <p>
            Når du har fundet ud af, hvem du gerne vil låne penge hos, sender vi dig
            videre til udbyderen. Her gennemfører du låneansøgningen, hvorefter pengene
            bliver udbetalt til din nem-konto. Som regel vil du have pengene samme dag.
          </p>
          <h3>Må jeg takke nej til et lånetilbud?</h3>
          <p>
            Naturligvis. Det er 100 % uforpligtende at indhente tilbud på at låne penge
            gennem os. Skulle der – mod forventning – ikke være et tilbud, der tiltaler
            dig, kan du blot afvise dem alle. Der er derfor ingen risiko forbundet med at
            indhente lånetilbud her på siden.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-koster-det-at-laane-penge-online-">
            Hvad koster det at låne penge online?
          </h2>
          <p>
            Låneudbydernes priser varierer kraftigt. Når du indhenter tilbud med henblik
            på at låne penge, vil du opleve at parametre som renter, gebyre, ÅOP og
            månedlig ydelse varierer. Det er disse parametre du bør sammenligne dine
            lånetilbud på.
          </p>
          <h3>Lån penge med fast rente</h3>
          <p>
            Renten er som udgangspunkt altid fast, når du låner penge på nettet. Det
            betyder, at renten er forudbestemt og ikke ændrer sig løbende i din
            låneperiode. En fast rente gør det lettere for dig at overskue, hvad det
            koster at låne penge, da renteudgiften er den samme hver måned.
          </p>
          <p>
            Det er dog vigtigt, at du er opmærksom på, at renten ikke er det eneste, der
            har indflydelse på dine låneomkostninger. Derfor er det heller ikke sikkert,
            at den laveste rente er den billigste løsning for dig. Det er faktisk sjældent
            tilfældet.
          </p>
          <p>
            Den faste rentesats for dit pengelån besluttes på baggrund af långivers
            vurdering af din økonomiske situation. Når långiver skal afgøre, hvor meget
            dit lån skal koste, tages der blandt andet udgangspunkt i nedenstående:
          </p>
          <ul>
            <li>
              <strong>Din indkomst:</strong> Långiverne undersøger din indkomst, og bruger
              denne til at vurdere dine muligheder for tilbagebetaling
            </li>
            <li>
              <strong>Dine udgifter:</strong> Det er ikke blot dine indtægter, der er
              interessant for långiverne. Har du udgifter, der minimerer dit
              rådighedsbeløb, medfører det selvfølgelig en lavere kreditværdighed.
            </li>
            <li>
              <strong>Din RKI-status: </strong>Er du registreret i RKI, er der ikke
              mulighed for at låne penge. Derfor er det selvsagt også noget, der bliver
              undersøgt, før et eventuelt lånetilbud bliver afgivet.
            </li>
          </ul>
          <h3>Vær opmærksom på gebyrer</h3>
          <p><strong>Administrationsgebyrer</strong></p>
          <p>
            Nogle låneudbyder opkræver et eller flere gebyrer, når du låner penge hos dem.
            Det kan eksempelvis være et administrationsgebyr, som tillægges på hver
            opkrævning. Det er forskelligt fra aktør til aktør, hvor stort et gebyr, der
            er tale om. Ofte er det på mellem 25 og 30 kr. pr. måned.
          </p>
          <p><strong>Stiftelsesgebyr</strong></p>
          <p>
            Et andet typisk gebyr du vil støde på, er et stiftelsesgebyr. Dette betales
            dog kun en enkelt gang og opkræves i forbindelse med oprettelsen af lånet.
            Stiftelsesgebyret dækker de omkostninger, som långiver har i forbindelse med
            at oprette gebyr.
          </p>
          <p><strong>PBS-gebyr</strong></p>
          <p>
            For at du ikke glemmer at afdrage på dit lån, vil vi altid anbefale dig at
            oprette en PBS-aftale. Hertil hører også et mindre gebyr. Som regel er det dog
            pengene værd, da omkostningerne, ved misligholdelse af lån, er højere.
          </p>
          <p>
            Ovenstående gebyr eksempler er de mest gængse omkostninger forbundet med at
            låne penge. Der kan dog forekomme låneprodukter, der også har yderligere
            omkostninger forbundet med sig. Derfor er det vigtigt, at du tager dig tid til
            at nærstudere dette.
          </p>
          <p>
            Når du skal finde billige lån, kan det derfor være svært at skabe et overblik
            over de mange omkostninger. Heldigvis har långiverne pligt til at fremvise
            låneaftalens ÅOP, hvilket er et tal, der samler alle lånets omkostninger.
          </p>
          <h3>Sammenlign lån ud fra ÅOP</h3>
          <p>
            Når du skal låne penge, bør du altid sammenligne dine tilbud ud fra den
            angivne ÅOP. ÅOP står for “årlige omkostninger i procent”. Dette tal dækker
            dermed ikke blot over lånets rente, men over samtlige af dine omkostninger hos
            långiveren, i forbindelse med at du låner penge. ÅOP er derfor den bedste
            indikator du har for, hvorvidt et lån er billigt eller ej.
          </p>
          <p>
            Når du bruger ÅOP til at sammenligne dine modtagne lånetilbud, er det lettere
            for dig at finde den billigste måde at låne penge på. Holder du udelukkende
            øje med renten, kan du blive grumt overrasket, da omkostninger såsom gebyrer
            ikke regnes med her.
          </p>
          <p>
            Sørg for ikke at falde i samme faldgrube, som mange andre låntagere gør. Brug
            i stedet altid ÅOP, når dine lånetilbud skal sammenlignes – men husk dog, at
            alle tilbud skal have samme løbetid og lånebeløb, hvis sammenligningen skal
            være retvisende. Hvis du er ny til disse ”bank ord” så har
            <a
              href="https://www.finanstilsynet.dk/Penge--og-Pensionspanelet/Gode-raad/Bank/ordbog-banken"
              rel="noopener noreferrer"
              target="_blank"
              >Finanstilsynet.dk</a
            >
            lavet en ordbog som kan hjælpe dig.
          </p>
          <h3>Den månedlige ydelse skal passe til dig</h3>
          <p>
            Et lån af penge kommer med en månedlig ydelse. Dette tal beskriver, hvor meget
            du hver måned skal afdrage med til långiveren. Det er derfor vigtigt, at den
            månedlige ydelse passer med din øvrige økonomi.
          </p>
          <p>
            Som tidligere beskrevet bør du derfor udregne dit rådighedsbeløb før du
            ansøger om at låne penge. Så ved du, hvor mange penge du har hver måned, efter
            alle dine udgifter er betalt. Dermed ved du også, hvor mange penge du
            maksimalt har til din månedlige afdragsordning.
          </p>
          <p>
            Når du benytter vores låneformular, kan du, ved at justere på løbetid og
            lånets størrelse, sørge for at ramme en månedlig ydelse, som du rent faktisk
            kan betale tilbage hver måned. Dette er vigtigt, således at du ikke kommer til
            at låne flere penge, end du har råd til.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvordan-kan-jeg-laane-penge-endnu-billigere">
            Hvordan kan jeg låne penge endnu billigere?
          </h2>
          <p>
            Vi har herunder samlet et par fif til, hvordan din låneoplevelse kan blive
            endnu billigere:
          </p>
          <h3>Tag et samlelån</h3>
          <p>
            Har du en eller flere kreditudbydere i forvejen, kan det være en god idé at
            undersøge muligheden for et
            <a
              href="https://www.saverium.dk/laan-penge/samlelaan"
              rel="noopener noreferrer"
              target="_blank"
              >samlelån</a
            >. I sin korteste forstand betyder det, at alle dine nuværende lån samles hos
            én låneudbydere. Det betyder at du, i stedet for at skulle forholde dig til
            flere forskellige tilbagebetalingsplaner og udløbsperioder, kun skal forholde
            dig til forholdene omkring ét lån. Ved at samle dine lån vil du desuden ofte
            opleve, at den månedlige udgift vil blive lavere.
          </p>
          <h3>Få en medansøger</h3>
          <p>
            Ikke alle har muligheden, men såfremt du har, er det fornuftigt at overveje at
            ansøge om et lån med en medansøger. Hermed øges chancen for at få et billigere
            lån. Der er stor sandsynlighed for, at det vil resultere i et bedre tilbud,
            hvis du ansøger med en anden person, end hvis du ansøger alene.
          </p>
          <p>
            Årsagen til dette er, at låneudbyderen vurderer, at jeres fælles
            kreditværdighed er bedre, da I er to om at dele låneforpligtelserne. Det
            minimerer selvfølgelig långivers risiko, hvorfor de trygt kan sænke
            renteniveauet.
          </p>
          <p><strong>Skal en medansøger være en partner?</strong></p>
          <p>
            Mange tror fejlagtigt, at det er et krav, at medansøgeren er en samleverske.
            Sådan forholder det sig ikke. Derimod kan det være en hvilken som helst
            person, der er din medansøger. En medansøger kan sagtens være en ven, et
            familiemedlem eller af helt tredje relation. Du behøver altså hverken at dele
            adresse eller økonomi med din medansøger.
          </p>
          <p>
            Låner du penge med en medansøger, er det vigtigt at være opmærksom på, at I
            begge hæfter for lånet. Hvis det ene part misligholder lånet, er den anden
            part forpligtet til at betale. Af denne årsag er det vigtigt at vælge en
            medansøger, som du stoler på.
          </p>
          <h3>Kig efter gratis lån</h3>
          <p>
            I få tilfælde er det faktisk muligt at låne penge gratis. Når du sammenligner
            lån, kan du være heldig at finde et gratis lån til 0 procent i rente. Det er
            dog ikke i alle tilfælde. Dog vil du næsten altid kunne låne penge online til
            en lavere rente, end den din bank kan tilbyde dig.
          </p>
          <p>
            Da det er gratis at indhente lånetilbud, vil altid anbefale dig at gøre dette
            — også selvom du har planlagt at låne penge i egen bank. Derfor anbefaler vi
            alle, som skal låne penge, at sammenligne forskellige lån, inden de takker ja
            til noget. Udfyld blot vores låneformular, hvor du efter to minutter modtager
            lånetilbud fra en lang række udbydere, der kan matche dine behov for lån.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvor-mange-penge-kan-jeg-laane">Hvor mange penge kan jeg låne?</h2>
          <p>
            Mange er af den opfattelse, at det kun kan lade sig gøre at låne små beløb på
            nettet. Det er dog slet ikke tilfældet, så hvor meget kan du låne? At optage
            et billigt lån på nettet, udelukker ikke muligheden for større lån.
          </p>
          <p>
            Det er forskelligt hvor mange penge du kan låne, afhængigt af hvilken
            låneudbyder du ender med at vælge. Kreditorerne, som vi samarbejder med, låner
            mellem 3.000 kr. og helt op til 500.000 kr. ud til deres låntagere. I vores
            låneformular vælger du, hvor mange penge du ønsker at låne. Derefter bliver du
            kun præsenteret for udbydere, som kan imødekomme dine behov.
          </p>
          <p>
            Din nuværende økonomiske situation (din kreditværdighed) har også indflydelse
            på, hvor mange penge du kan låne online. Kreditværdighed vurderes ud fra mange
            faktorer — herunder dine indtægter, udgifter og om du har nogle kreditorer i
            forvejen. Jo færre kreditorer du har i forvejen, desto flere penge vil du ofte
            kunne låne.
          </p>
        </div>

        <div class="text-container">
          <h2 id="kan-alle-laane-penge">Kan alle låne penge?</h2>
          <p>
            Du har sandsynligvis hørt, at ønsker man at undgå bankernes mange og strenge
            krav, så kan man med fordel låne penge billigt online. Men er det ensbetydende
            med, at de onlinebaserede lånevirksomheder ikke stiller krav til deres
            låntagere? Nej, bestemt ikke. Dog er kravene både færre og mildere, hvorfor de
            fleste kan få bevilliget et online lån.
          </p>
          <p>
            Kravene til dig som låntager, vil variere fra låneudbyder til låneudbyder. Når
            du udfylder vores låneformular, vil de lånetilbud du modtager matche dig. Det
            vil sige, at vi kun sender dig tilbud fra udbydere, som låner penge ud til din
            aldersgruppe, og til folk med en økonomisk situation som din.
          </p>
          <p>
            Når du ansøger om at låne penge, skal der oplyses nogle økonomiske oplysninger
            om dig. Det skal der, for at der kan foretages en kreditvurdering. Her
            vurderer långiver, hvorvidt din økonomi kan bære det ønskede lån. Ligeledes er
            det også med udgangspunkt i denne, at långiveren fastsætter lånets rente.
          </p>
          <p>
            Da det er en risikovurdering fra långivers side, medfører en bedre
            kreditværdighed selvfølgelig en bedre låneaftale. Derfor vil det afspejle sig
            direkte i lånets rente og vilkår, hvis det vurderes, at du har en god
            kreditværdighed.
          </p>
          <p>
            Også alderskrav er varierende, alt efter hvem du låner penge hos. Du skal dog
            altid være fyldt 18 år og dermed være myndig. Langt fra alle aktører er dog
            villige til at yde lån til 18-årige, hvorfor de i stedet har fastsat en
            minimumsalder på 21, 23 eller 25 år.
          </p>
        </div>

        <div class="text-container">
          <h2 id="vores-laanekrav">Vores lånekrav</h2>
          <p>
            For at kunne benytte dig af vores sammenligningstjeneste i forbindelse med at
            låne penge er kravene altid følgende:
          </p>
          <h3>Du skal være fyldt 18 år</h3>
          <p>
            Lån penge som 18-årig. Selvom mulighederne er færre, så kan det lade sig gøre.
            Selvom du fra lovgivningens side blot skal være fyldt 18 år for at låne penge,
            har de fleste långivere sat deres alderskrav op til 21 eller 23 år.
          </p>
          <p>
            Det skyldes, at du ved denne type lån, låner penge uden at stille sikkerhed.
            De fleste på 18 år ikke har en fast indkomst og en stabil økonomi., og vil
            derfor blive vurderet til at have en lavere kreditværdighed. Dog kan det lade
            sig gøre at låne penge som 18 årig, så tøv ikke med at ansøge.
          </p>
          <h3>Du skal have bopæl i Danmark</h3>
          <p>
            Hovedparten af de långivere, der er aktive på det danske marked, stiller en
            form for krav til bopæl og statsborgerskab. Som regel er det påkrævet, at du
            er bosiddende i Danmark og har statsborgerskab i landet.
          </p>
          <p>
            Dette er også ensbetydende med, at du skal have dansk CPR-nummer, en dansk
            bank samt være i besiddelse af et gyldigt NemID. Enkelte långivere er villige
            til at overføre lånet til en udenlandsk bankkonto, men sjældent uden betaling
            af et gebyr. Derudover kræver visse udbydere også, at du har et dansk
            telefonnummer.
          </p>
          <p>
            Vi har derfor valgt, at du som låneansøger hos os skal være bosat i Danmark
            samt være i besiddelse af et NemID, dansk telefonnummer og et dansk
            CPR-nummer.
          </p>
          <h3>Du må ikke stå registreret i RKI</h3>
          <p>
            Det er umuligt i alle henseende at låne penge, hvis du står registreret som
            dårlig betaling i RKI eller i Debitor Registret. Det er ikke attraktivt for
            vores låneudbydere, ej heller for de regulære banker, at tilbyde lån til folk,
            som er kendt som dårlige betalere. Lån derfor først penge, når du ikke længere
            står registreret i RKI.
          </p>
          <h3>Skal jeg stille sikkerhed for at kunne låne penge?</h3>
          <p>
            Nej. En af de helt store fordele ved at låne penge online er, at du kan låne
            penge uden sikkerhed og dokumentation. Dette står i stærk kontrast til de
            krav, du møder hos den fysiske bank. Her skal du ofte stiller sikkerhed i form
            af din bil, bolig eller andre værdier.
          </p>
          <p>
            Det betyder, at hvis du pludselig ikke kan betale dit månedlige afdrag, vil du
            risikere at skulle sælge den eller de værdigenstande, du har stillet som
            sikkerhed. Denne risiko løber du ikke, når du låne penge online og uden
            sikkerhed.
          </p>
          <h3>Billigt og hurtigt lån trods RKI – kan det lade sig gøre?</h3>
          <p>
            Vil du gøre dig forhåbninger om at låne penge, må du ikke være registreret i
            RKI. Det er ikke blot gældende, hvis du vil optage et hurtigt lån hos en
            onlinebaseret udbyder – det udelukker dig nemlig også fra enhver lånemulighed
            i banken.
          </p>
          <p>
            Derfor er det ikke muligt at optage et lån trods RKI, hvis du er stemplet som
            dårlig betaler i Ribers Kredit Information. Før du kan gøre dig forhåbninger
            om et lån, er du derfor nødt til at få slettet din registrering.
          </p>
          <p>Registreringen i RKI kan fjernes på to måder:</p>
          <ul>
            <li>Du kan betale den gæld, der er skyld i din registrering</li>
            <li>
              Du kan vente fem år, hvorefter din registrering som dårlig betaler
              automatisk fjernes
            </li>
          </ul>
          <p>
            Sidstnævnte løsning lyder sandsynligvis appellerende, men vær dog opmærksom
            på, at den slettede registrering ikke er ensbetydende med, at din gæld er væk.
            Din gæld vil fortsat eksistere – det er blot din registrering i RKI, der
            fjernes.
          </p>
          <h3>Kan jeg låne penge som ung?</h3>
          <p>
            Ja, det kan du. Alder er et af de parametre, som har indflydelse på dine
            lånemuligheder. Du skal naturligvis være myndig og dermed være fyldt 18 år.
            Nogle udbydere stiller yderligere krav til din alder, og låner eksempelvis kun
            penge ud, til ansøgere der er fyldt 21, 23 eller sågar 25 år. Når du ansøger
            om at låne penge hos os, sender vi dig kun tilbud på lån, hvor du opfylder
            alderskravene.
          </p>
          <p>
            Vi appellerer til, at du som ung er ekstra opmærksom på, hvad du låne penge
            til. Det er nemt at lade sig friste til, at låne nemt og forholdsvist billigt,
            men hvis formålet er fest og farver, er det ikke altid det rette valg. Er du
            derimod ung studerende, kan et online lån være en stor hjælp under
            studietiden. I denne periode er din indtjening som regel lav, mens du samtidig
            har ekstra udgifter til bøger, studieture og transport til og fra studiet.
          </p>
          <p>
            Vær opmærksom på, at din alder kan have indvirkning på renten, gebyrer og
            lignende omkostninger i forbindelse med at låne penge. Jo yngre du er, desto
            højere risiko vil låneudbyderne vurderer der er i at bevilge dig et lån.
            Overvej derfor lånet nøje.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-kan-jeg-laane-penge-til">Hvad kan jeg låne penge til?</h2>
          <p>
            Når du udfylder vores låneformular, vil du opleve at vi spørger ind til
            hovedformålet med lånet. Dette er ikke fordi, at vi er interesseret i at
            kontrollere, hvad du låner penge til. Vi tror på, at du er den, der bedst kan
            vurdere eget lånebehov. Grundet risiko for hvidvask af penge, skal vi dog
            spørge,
          </p>
          <p>
            Med andre ord kan du altså låne penge til lige det du ønsker, såfremt du
            overholder almen lovgivning. Når din ansøgning er godkendt og pengene er
            udbetalt, har du fuld råderet over dem. Selvom du oprindeligt lånte penge med
            henblik på at købe en ny computer, må du derfor gerne bruge dem på at købe en
            ny sofa, tage på ferie eller noget helt tredje. Herunder har vi listet nogle
            eksempler på, hvad vores kunder oftest låner penge til.
          </p>
          <h3>Lån penge til forbrug</h3>
          <p>
            Du kan nemt låne penge som et
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >
            gennem en af vores mange udbydere. Denne type lån er det rette for dig, som
            ønsker at låne penge til dit almene private forbrug. Hvis du eksempelvis står
            i en situation lige nu, hvor du har svært ved at betale dine regninger, kan
            dette være løsningen for dig. Mange af vores kunder benytter desuden
            forbrugslån i forbindelse med forestående rejser samt som supplement til S.U.
            eller øvrige offentlige ydelser.
          </p>
          <h3>Lån penge til bil</h3>
          <p>
            Ønsker du at skifte den offentlige transport eller jernhesten ud med en bil?
            Eller drømmer du om at skifte bilen ud med en nyere og mere økonomisk bil? Det
            kan du gøre med et
            <a
              href="https://www.saverium.dk/laan-penge/billaan"
              rel="noopener noreferrer"
              target="_blank"
              >billån</a
            >
            hos en af vores udbydere. Mange tror, at de skal i banken for at låne til en
            ny bil, men det behøver ikke være tilfældet. Du kan sagtens låne penge online
            til netop dette.
          </p>
          <h3>Lån penge til bolig</h3>
          <p>
            Et decideret boliglån kan sjældent klares online. Til gengæld kan du sagtens
            låne penge online og billigt til de udgifter, der uundgåeligt er i forbindelse
            med en flytning. Ansøg om et
            <a
              href="https://www.saverium.dk/laan-penge/bolig"
              rel="noopener noreferrer"
              target="_blank"
              >boliglån</a
            >
            hos os, hvis du mangler penge til indskud, møbler, renovering eller lignende.
            Så hjælper vi dig med at indhente kvalificeret tilbud fra relevante udbydere.
          </p>
          <h3>Lån penge til din virksomhed</h3>
          <p>
            Er du erhvervsdrivende, eller drømmer du om at starte egen virksomhed? Så
            ansøg om et billigt
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >erhvervslån</a
            >
            i dag. Pengene, du låner til opstart eller drift af egen virksomhed, kan være
            en stor hjælp til eksempelvis vareindkøb, stiftelse af selskab, udgifter til
            markedsføring eller lignende.
          </p>
        </div>

        <div class="text-container">
          <h2 id="er-det-nemt-at-laane-penge">Er det nemt at låne penge?</h2>
          <p>
            Når du benytter vores lånesammenligningstjeneste, er det ikke bare nemt men
            også ganske billigt at låne penge. Billige lån har altid været i høj kurs, og
            der findes i dag mange firmaer, som kan tilbyde dig at låne dig penge. Derfor
            er det ekstra vigtigt at
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne lån</a
            >, så du er sikker på at vælge markedets bedste lån ud fra dine behov.
          </p>
          <p>
            Den eneste måde at gøre det på, er ved at indhente tilbud fra de forskellige
            <a
              href="https://saverium.dk/laan-penge/laaneselskaber"
              rel="noopener noreferrer"
              target="_blank"
              >låneselskaber</a
            >, hvilket både er besværligt og tidskrævende, hvis du selv skal gøre det. Af
            denne grund har vi bygget vores service, så du, med én enkelt online ansøgning
            og på blot to minutter, kan indhente lånetilbud fra et hav af forskellige
            banker og långivere - muligvis også udbydere af lån, som du aldrig selv havde
            fundet frem til. Herved kan du låne penge billigt, uden alt besværet.
          </p>
          <h3>Nem og hurtig ansøgningsprocess</h3>
          <p>
            Vores ansøgningsprocess foregår online, så du ikke behøver at indsende papirer
            og fysiske underskrifter for at låne penge. Alle vores lånepartnere tilbyder
            <a
              href="https://saverium.dk/laan-penge/online-laan"
              rel="noopener noreferrer"
              target="_blank"
              >online lån</a
            >, hvilket betyder, at du kan få et
            <a
              href="https://saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtigt lån</a
            >, uanset hvilket lånetilbud og hvilken låneudbyder, du måtte vælge.
          </p>
          <p>
            Vores lånemuligheder spænder bredt. Du kan eksempelvis optage et
            <a
              href="https://saverium.dk/laan-penge/minilaan"
              rel="noopener noreferrer"
              target="_blank"
              >minilån</a
            >, hvis der er opstået et akut behov for at dække en mindre regning såsom
            tandlægeregning eller en cykelreperation. Har du behov for at låne et langt
            større beløb, er dette også en mulighed gennem os. Du kan nemlig ansøge at
            låne op til 500.00 kr. — eksempelvis som en
            <a
              href="https://www.saverium.dk/laan-penge/kassekredit"
              rel="noopener noreferrer"
              target="_blank"
              >kassekredit</a
            >.
          </p>
          <p>
            Uanset hvordan dit lånebehov ser ud, tager det dig kun to minutter at udfylde
            vores låneformular og indhente tilbud fra alle vores relevante udbydere. Når
            du ansøger om at låne penge gennem os, er det altid ganske gratis og
            uforpligtende at modtage tilbuddene.
          </p>
          <h3>Få pengene samme dag</h3>
          <p>
            Lån penge nu og modtag det ønskede beløb i dag. Så simpelt er det. Når du
            ansøger om et pengelån, skal du underskrive din ansøgning med NemID. Herefter
            kan låneudbyderne nemt se, om du lever op til deres lånekrav. Således at du
            gør, vil de med det samme udbetale pengene til din nem-konto. I langt de
            fleste tilfælde vil hele processen udfolde sig samme dag. I få tilfælde tager
            det et par dage, før pengene står på din konto
          </p>
          <p>
            Det er naturligvis gældende, uanset lånebeløbets størrelse. Det vil sige, at
            du sagtens kan få et lån på 100.000 eller et lån på 50.000, uden at
            udbetalingsdatoen udskydes. Det gælder selvfølgelig også, hvis du søger om et
            mindre lån på eksempelvis 5.000 kroner.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvor-ofte-kommer-der-nye-laan-paa-markedet">
            Hvor ofte kommer der nye lån på markedet?
          </h2>
          <p>
            Det onlinebaserede lånemarked er under konstant udvikling. Jævnligt
            introduceres nye låneudbydere, ligesom også nye lånetyper bliver introduceret.
            For dig som forbruger er det udelukkende en fordel, at der altid er nye lån på
            vej. Det skaber nemlig i en intens konkurrence, hvilket resulterer i bedre
            priser og vilkår for låntagerne. Vi ønsker at finde og beregne det bedste lån
            til dig. Derfor sikrer vi, at vores samarbejdspartnere altid er opdateret i
            forhold til markedets udvikling.
          </p>
          <p>
            De mange nye lån på nettet skal selvfølgelig adskille sig fra de eksisterende
            låneprodukter, hvorfor de eksempelvis introduceres med afdragsfrihed, lav
            rente eller andre fordelagtige parametre, som adskiller dem fra
            konkurrenterne. Dette er dog ikke ensbetydende med, at alle nye lånemuligheder
            er bedre, end de nuværende. Derfor bør målet ikke være at finde et helt nyt
            lån, men derimod at finde det billigste lån.
          </p>
          <p>
            I juli 2020 trådte en ny lovgivning vedrørende forbrugslån i kraft. Det har
            gjort det med sikkert for dig som kunde at låne penge på det online
            lånemarked. Du kan læse mere om den nye lov
            <a
              href="https://www.forbrug.dk/nyheder/forbrug/2020/nye-regler-skal-beskytte-forbrugerne-mod-dyre-forbrugslaan/"
              rel="noopener noreferrer"
              target="_blank"
              >her</a
            >.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-penge-med-fast-rente-vs-variabel-rente-hvad-er-bedst">
            Lån penge med fast rente vs. variabel rente: Hvad er bedst?
          </h2>
          <p>
            Når du beslutter dig for at låne penge, skal du blandt andet tage stilling
            til, om du vil optage lån med fast eller variabel rente. Der er selvfølgelig
            fordele og ulemper forbundet med begge dele, hvorfor du skal vurdere, hvilken
            renteløsning, der er den mest fordelagtige for dit vedkommende.
          </p>
          <p>
            Vær opmærksom på, at når du ønsker at låne penge på nettet, vil lånet ofte
            være med en fast rente.
          </p>
          <h3>Fordele ved en fast rente</h3>
          <p>
            Et lån med fast lav rente varierer ikke fra måned til måned. Af denne grund
            har du fra start et overblik over lånets samlede omkostninger samt størrelsen
            på den månedlige ydelse. Foretrækker du at have vished over, hvad det koster
            at låne penge, er det fornuftigt at vælge et lån med fast rente.
          </p>
          <p>
            Lån penge billigt, så vidt det er muligt, men ikke på bekostning af, at du
            mister overblikket. Har du i forvejen svært ved at overskue din økonomi, er du
            bedre stillet med en fast rente, selvom den typisk er en kende højere, end en
            variabel rente.
          </p>
          <h3>Ulemper ved en fast rente</h3>
          <p>
            Der er naturligvis også visse ulemper ved at låne penge med fast rente.
            Spørgsmålet er, om fordelene vejer op for ulemperne, og det kan kun du
            vurdere.
          </p>
          <p>
            Leder du efter et lån med laveste rente, vil du opleve, at det i de fleste
            tilfælde er renten på lånet da er variabel. Det skyldes, at låneudbyderne
            typisk kræver en højere rente, mod at den bliver fast, hvor du så til gengæld
            får et bedre overblik over lånets samlede omkostninger.
          </p>
          <p>
            Vil du være sikker på at få et billigt lån med lav rente, er det som regel
            låneprodukter med variabel rente, du skal holde øje med. Vægter du det højt at
            have kendskab til lånets omkostninger på forhånd, bør du i stedet lede efter
            det billigste lån med fast rente.
          </p>
        </div>

        <div class="text-container">
          <h2 id="skal-vi-hjaelpe-dig-med-din-laaneansoegning">
            Skal vi hjælpe dig med din låneansøgning?
          </h2>
          <p>
            Hvis du på nuværende tidspunkt tænker, at du ønsker at foretage en
            låneansøgningen gennem os, så skal du være meget velkommen. Vi er klar til at
            modtage din låneansøgning døgnet rundt, så du kan få en hurtig afklaring på,
            hvor meget du kan låne. Hvis du benytter vores formular her på siden, kan du
            være færdig med din låneansøgning på 2 minutter.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
