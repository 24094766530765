<template>
  <div class="row article non-landing">
    <h1 class="center-title">
      Kan jeg kombinere nogle af mine lån? - Hvorfor delvis betaling af gæld er det hele
      værd
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            Fik du ikke det lånetilbud, du ønskede? Det er også værd at udnytte et mindre
            lånetilbud til at kombinere gamle lån. Læs her for tip til delvis
            lånekonsolidering.
          </p>
          <picture>
            <source
              srcset="/static/img/saverium_dk/article-imgs/how-can-i-combine-loan.webp"
              type="image/webp"
            />
            <source
              srcset="/static/img/saverium_dk/article-imgs/how-can-i-combine-loan.jpg"
              type="image/jpg"
            />
            <b-img-lazy
              width="700"
              height="466"
              blank-src="/static/img/saverium_dk/article-imgs/how-can-i-combine-loan-tiny.jpeg"
              blank-height="700px"
              blank-width="466px"
              class="article-img"
              src="/static/img/saverium_dk/article-imgs/how-can-i-combine-loan.jpg"
              alt=" Kan jeg kombinere nogle af mine lån? - Hvorfor delvis betaling af gæld er det hele værd"
            />
          </picture>
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul>
              <li>
                <a href="#kan-kun-nogle-af-dine-gamle-laan-kombineres">
                  Kan kun nogle af dine gamle lån kombineres?
                </a>
              </li>
              <li>
                <a href="#hvorfor-skal-gaeld-kombineres-selv-delvis">
                  Hvorfor skal gæld kombineres, selv delvis?
                </a>
              </li>
              <li>
                <a href="#et-kombinationslaan-sparer-omkostninger-og-renter">
                  Et kombinationslån sparer omkostninger og renter
                </a>
              </li>
              <li>
                <a href="#saml-dine-laan-igennem-saveriumdk">
                  Saml dine lån igennem Saverium.dk
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container">
          <h2 id="kan-kun-nogle-af-dine-gamle-laan-kombineres">
            Kan kun nogle af dine gamle lån kombineres?
          </h2>
          <p>
            <a
              href="https://www.saverium.dk/samlelan/"
              rel="noopener noreferrer"
              target="_blank"
              >Har du ansøgt om et samlelån</a
            >, men ikke modtaget så meget det lånetilbud, som du ønskede? Når du ansøger
            om et lån, får du muligvis ikke nøjagtigt det samme lånetilbud fra bankerne.
            For eksempel, hvis du har ansøgt om et lån på 75.000 kr., kan banken tilbyde
            dig et lån på 50.000-60.000 kr. Dette er normalt og kan desværre ikke påvirkes
            af selve lånesammenligningstjenesten.
          </p>
          <p>
            Selvom det tilbudte lånebeløb ikke er nok til at samle alle dine gamle lån, er
            det stadig værd at samle nogle af lånene. Renten på et samlelån er i de fleste
            tilfælde altid lavere end på dyre forbrugslån. Således accepterer et lavere
            samlelånstilbud, selvom lånet kun bruges til at kombinere et eller to mindre
            gamle kviklån eller andre lån du har.
          </p>
        </div>
        <div class="text-container">
          <h2 id="hvorfor-skal-gaeld-kombineres-selv-delvis">
            Hvorfor skal gæld kombineres, selv delvis?
          </h2>
          <p>
            Det er for de fleste værd at kombinere lån, da dette reducerer tilbagebetaling
            af lån og du sparer også penge på andre daglige udgifter.
          </p>
          <p>
            · <strong>Betal nogle af de dyre lån</strong>. Selvom det lånetilbud, du
            modtager, ikke dækker alt din gamle gæld, kan du stadig betale nogle af dine
            gamle gæld.
          </p>
          <p>
            · <strong>Skift til en lavere rente.</strong> Selv i et lille samlelån er
            lånets årlige rente som regel altid lavere end de gamle lån, du vil
            konsolidere.
          </p>
          <p>
            · <strong>Enig om en bedre låneperiode</strong>. Ved at kombinere nogle af de
            gamle lån kan du ændre tilbagebetalingstiden for denne gæld. Saverium.dk
            tilbyder samlelån med en låneperiode på 1-15 år.
          </p>
          <p>
            · <strong>Få bedre overblik over din økonomi</strong>. Når månedlige rater
            falder, er der også penge tilbage til ikke-gældsbetalinger, som kan spares
            eller bruges i hverdagen.
          </p>
          <p></p>
        </div>
        <div class="text-container">
          <h2 id="et-kombinationslaan-sparer-omkostninger-og-renter">
            Et kombinationslån sparer omkostninger og renter
          </h2>
          <p>
            Når du betaler flere lån, skal du altid betale flere gebyr og flere
            forskellige renteudgifter. Kombination af lån, selvom det kun er en brøkdel af
            alle lån, vil hjælpe med at få økonomien til at se bedre ud. Udgifter og
            renter falder som de færre lån du har i alt.
          </p>
          <p>
            Hvis du har mange lån, især kortfristede lån, er renten på disse normalt meget
            høje. Med et samlelån kan du få en betydelig reduktion i renten, især hvis du
            vælger en længere tilbagebetalingsperiode for samlelånet
          </p>
          <p></p>
        </div>
        <div class="text-container">
          <h2 id="saml-dine-laan-igennem-saveriumdk">
            Saml dine lån igennem Saverium.dk
          </h2>
          <p>
            Saverium.dk sammenligner lånetilbud for dig med mere end 7 forskellige banker
            og låneudbydere med kun en enkelt låneansøgning. Du kan ansøge om et lån
            mellem 3000-500.000 kroner så du kan få det helt rigtige beløb. Du kan vælge
            mellem 1 til 15 år for låneperioden.
          </p>
          <p>
            Når du har ansøgt om et lån, modtager du dine lånetilbud direkte til din
            Saverium konto, hvorfra du kan få overblik over dem og vælge det bedst egnede
            til dig. Lånetilbuddet kan accepteres direkte online med Nem-ID. Selvom du
            ikke får et lånetilbud til nøjagtigt det beløb, du har ansøgt om, er det værd
            at overveje, om det lånetilbud, du har modtaget, stadig vil være passende at
            kombinere nogle af de gamle lån.
          </p>
          <p></p>
          <p>2021-06-09/Søren</p>
        </div>
      </div>
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
  </div>
</template>

<script>
import LoanWidget from '@/components/saverium_dk/seo_components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'

export default {
  components: {
    LoanWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
