<template lang="html">
  <select
    class="form-control"
    :class="error ? 'is-invalid' : ''"
    name=""
    @change="input"
    :value="value"
  >
    <option hidden value="">{{ $t('choose') }}</option>
    <option :value="item.value || item" v-for="(item, i) in items" :key="i">{{
      item.text || item + (suffix !== undefined ? suffix : '') || item
    }}</option>
  </select>
</template>

<script>
export default {
  props: ['value', 'items', 'error', 'suffix'],
  methods: {
    input(event) {
      if (event.target.value !== this.value) {
        this.$emit('input', event.target.value)
      }
    },
  },
}
</script>

<style lang="sass" scoped></style>
