<template>
  <b-container class="container">
    <b-row class="row" style="{margin: 0;}">
      <b-col cols="10" md="6" class="mx-auto">
        <h1 class="page-header">{{ $t('login') }}</h1>
        <p v-if="brand === 'saverium_dk'">{{ $t('tip_find_link_in_email') }}</p>
        <div class="well">
          <form method="post" @submit.prevent="Login()">
            <div class="alert alert-danger alert-dismissable" v-if="this.login_error">
              <button class="close" type="button" v-on:click="login_error = false">
                ×
              </button>
              {{ $t('enter_correct_email_address_and_password') }}
            </div>
            <input type="hidden" name="grant_type" value="password" />
            <label for="username">{{ $t('email_address') }}</label>
            <b-form-input
              type="text"
              autocomplete="username"
              class="w-100"
              name="username"
              id="username"
            ></b-form-input>
            <label for="password">{{ $t('password') }}</label>
            <b-form-input
              type="password"
              autocomplete="current-password"
              class="w-100"
              name="password"
              id="password"
            ></b-form-input>
            <hr />
            <button type="submit" class="btn btn-primary btn-lg btn-block">
              <i class="fa fa-fw fa-sign-in"></i> {{ $t('login') }}
            </button>
            <br />
            <b-link :to="$root.rn.ResetPasswordView" class="text-muted">{{
              $t('help_forgotten_my_password')
            }}</b-link>
          </form>
        </div>
        <hr />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Input from '@/components/form/Input'
import settings from '@/settings/settings'
export default {
  data() {
    return {
      login_error: false,
    }
  },
  components: {
    'form-input': Input,
  },
  computed: {
    brand() {
      return settings.BRAND
    },
  },
  methods: {
    async Login() {
      let formData = new FormData(document.querySelector('form'))
      try {
        let resp = await this.$store.dispatch('login', formData)
        if (resp) {
          if (this.$route.query.next) {
            let route = this.$router.matcher.match(this.$route.query.next)
            this.$router.push(route.fullPath)
          } else {
            this.$router.push(this.$root.rn.MyAccountView)
          }
        } else {
          throw 'Login error'
        }
      } catch (e) {
        this.login_error = true
      }
    },
  },
}
</script>

<style>
.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
</style>
