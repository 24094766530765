<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til TV</h1>
      <div class="top-text text-container">
        <p>
          Har du brug for et lån til et tv eller en ny fladskærm? Vi hjælper dig med at
          finde det rette lån, så du slipper så billigt som muligt. Udfyld vores ansøgning
          her på siden, så indhenter vi tilbud fra en lang række banker, så du undgår at
          betale mere end højest nødvendigt.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li><a href="#indledning-til-laan-om-tv">Indledning til lån om TV</a></li>
                <li>
                  <a href="#ansoeg-nemt-online-for-finde-laan-til-fladskaerm"
                    >Ansøg nemt online for finde lån til fladskærm</a
                  >
                </li>
                <li>
                  <a href="#hvilket-tv-kan-du-koebe-for-laanet"
                    >Hvilket TV kan du købe for lånet?</a
                  >
                </li>
                <li>
                  <a href="#hvor-kan-jeg-faa-et-lavrente-laan-til-tv"
                    >Hvor kan jeg få et lavrente lån til TV?</a
                  >
                </li>
                <li>
                  <a href="#find-dit-nye-tv-laan-ved-at-sammenligne"
                    >Find dit nye TV-lån ved at sammenligne</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="indledning-til-laan-om-tv">Indledning til lån om TV</h2>
          <p>
            Har du brug for et nyt TV til børneværelset eller stuen, men ikke har pengene
            til rådighed i øjeblikket, så behøver du ikke at være i tvivl om, at du nemt
            kan ansøge om <strong>finansiering</strong> til et TV online via vores
            hjemmeside. Vi stræber efter at finde de bedste
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >lånetilbud</a
            >
            fra de forskellige
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere</a
            >
            vi samarbejder med, så det eneste du skal fokusere på, er at
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne de lånetilbud</a
            >, som du modtager. Ansøger du om et lån til TV igennem os, behøver du kun
            adgang til internettet på din mobil, tablet eller computer, for at få adgang
            til vores gratis service. Efter at du har sendt din online
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låneansøgning</a
            >
            afsted, indhenter vi skræddersyet tilbud, som du kan bruge til køb af et
            fladskærms TV eller et brugt TV.
          </p>
        </div>

        <div class="text-container">
          <h2 id="ansoeg-nemt-online-for-finde-laan-til-fladskaerm">
            Ansøg nemt online for finde lån til fladskærm
          </h2>
          <p>
            Undersøg dine <strong>lånemuligheder</strong> på nettet ved at udfylde en
            ansøgning igennem vores online lånesammenligningstjeneste. På den måde skaber
            du overblik over de forskellige lån til fladskærm, der findes rundt ved de
            forskellige udbydere, i stedet for at du skal til og kontakte hver enkelt
            udbyder, og endda måske selv opsøge hver enkelt.
          </p>
          <p>
            Det er også muligt at ansøge om et lån i en fysisk bank, dette kan dog være
            tidskrævende, langtrukkent og du skal med stor sikkerhed stille værdi, altså
            sikkerhed for dit
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >nye lån</a
            >. Det slipper du heldigvis for, når du søger online efter et lån til TV.
            Ingen af vores samarbejdspartnere kræver, at du skal stille sikkerhed for at
            kunne få et lån hos dem.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvilket-tv-kan-du-koebe-for-laanet">
            Hvilket TV kan du købe for lånet?
          </h2>
          <p>
            Vælger du at optage et
            <a
              href="https://www.saverium.dk/laan-penge/online-laan"
              rel="noopener noreferrer"
              target="_blank"
              >lån online</a
            >
            til TV, kan du bruge dit lån til netop det TV du har i tankerne. Det er
            ligemeget for selve udbyderen af lånet, om det er en nyt plasma TV, gammelt
            analog TV eller et TV til soveværelset, som du køber. Du kan frit anvende lån
            til TV, til netop det TV du ønsker dig.
          </p>
          <p>
            Der findes mange forskellige mærker af TV´er, og nogle af de største og
            velkendte er Samsung, Sony, LG, Panasonic og Philips. De har i mange år
            domineret salg af TV til folk verden over.
          </p>
          <p>
            Ligesom med alt andet elektronik udstyr, kan det være at du personligt er
            større fan af Samsung end Sony, eller et andet mærke. Det vigtigste for alle
            de TV'er der bliver lavet og solgt nu, er at det er et Smart TV, så man kan få
            lov til at anvende apps som Netflix, Viaplay, Disney, Youtube og mange flere.
            Udover det er det super vigtigt at de TV'er der bliver produceret nu er
            bæredygtigt, og ikke anvender for meget elektricitet samt kan holde i mange
            år.
          </p>
          <p>
            Når du vælger at optage et
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/uden-sikkerhed"
              rel="noopener noreferrer"
              target="_blank"
              >lån uden sikkerhed</a
            >
            til TV, skal du ikke dokumentere hvilket mærke eller hvilken slags TV, du skal
            bruge pengene på. Det vigtigste for udbyderen af dit lån, er at du som
            lånetager kan overholde kravene for dit lån, og betale det tilbage indenfor
            den aftalte låneperiode.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvor-kan-jeg-faa-et-lavrente-laan-til-tv">
            Hvor kan jeg få et lavrente lån til TV?
          </h2>
          <p>
            Du kan helt sikkert finde et
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån med lav rente</a
            >
            mange steder. Dog kan det være som at finde en nål i en høstak, da der findes
            rigtig mange forskellige banker og alternative udbydere på markedet, som
            ønsker at have dig som kunde.
          </p>
          <p>
            Derfor kan det være meget uoverskueligt og energidrænende at skulle undersøge
            det store lånemarked for sig selv, og især hvis man aldrig har haft brug for
            et lån tidligere, og nu skal have fundet sit første lån, så man kan købe et
            TV.
          </p>
          <p>
            Ved at anvende vores online platform kan du nemt skabe dig et overblik over de
            mange forskellige pengeinstitutter, som er udbyder lån. På den måde kan du
            læne dig tilbage, og bare fokusere på at vælge det rette tilbud, som du ønsker
            at gøre brug af, fra den udbydere som giver dig det bedste tilbud. De tilbud
            vi sender til din brugerkonto fra vores partnere, er skræddersyet til din
            økonomi og baseret på de informationer, som du har oplyst i din ansøgning. Du
            kan derfor derfor være sikker på, at vi, samt vores partnere, vil tilbyde dig
            en så lav rente som muligt for dit lån til TV.
          </p>
        </div>

        <div class="text-container">
          <h2 id="find-dit-nye-tv-laan-ved-at-sammenligne">
            Find dit nye TV-lån ved at sammenligne
          </h2>
          <p>
            Find nemt det bedste lån til TV ved at sammenligne tilbud fra forskellige
            udbydere af lån. Det kan du gøre gratis hos os, her på vores hjemmeside, alle
            hverdage og alle weekender. Det tager kun et par minutter af din tid at
            udfylde vores ansøgning, som du får straks svar på, hvem du kan låne hos og
            hvor meget.
          </p>
          <p>
            Når du har udfyldt alle dine oplysninger i ansøgningen og har trykket “Send
            låneansøgning”, tager vi os af resten og fremsender din ansøgning til alle
            vores partnere. Efter nogle få minutter vil vi sende dig en mail med
            forskellige skræddersyet tilbud på dit lån til TV eller fladskærm. Vi skaber
            et overblik for dig, så blot kan vælg det ønskede tilbud.
          </p>
          <p>
            Når du har valgt et af dine tilbud, sender vi dig videre til selve udbyderens
            hjemmeside. Her skal du give samtykke med
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >NemID/MitID</a
            >, for de kan lave en kreditvurdering af din økonomi. Bliver du godkendt til
            at kunne optaget lånet, vil vi samt udbyderen sende dig en bekræftelse på at
            lånet bliver udbetalt til din konto hurtigst muligt.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
