<template>
  <div>
    <h2 v-if="!this.login_error">Logging you in...</h2>
    <div class="alert alert-danger alert-dismissable" v-if="this.login_error">
      <button class="close" type="button" v-on:click="login_error = false">×</button>
      <p>{{ $t('login_failed') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      login_error: false,
    }
  },

  created() {
    this.LoginFromMagicLink()
  },

  computed: {},
  methods: {
    async LoginFromMagicLink() {
      try {
        const resp = await this.$store.dispatch(
          'login_from_magic_link',
          this.$route.query
        )

        if (resp) {
          if (this.$route.query.next) {
            const route = this.$router.matcher.match(this.$route.query.next)
            this.$router.push(route.fullPath)
          } else {
            this.$router.push(this.$root.rn.OffersView)
          }
        } else {
          throw new Error('Login error')
        }
      } catch (e) {
        this.login_error = true
      }
    },
  },
}
</script>
