export const loanAmounts = []
let i = 0

for (i = 3000; i <= 500000; i += 1000) {
  loanAmounts.push(i)
}

export const loanTimeOptions = []
let j = 0
for (j = 1; j <= 15; j++) {
  loanTimeOptions.push(j)
}

export const bankYearOptions = []
let b = 0
for (b = 1; b <= 12; b++) {
  bankYearOptions.push(b)
}

export const smeLoanAmounts = []
let l = 0
for (l = 10000; l <= 1000000; l += 10000) {
  smeLoanAmounts.push(l)
}

export const smeLoanTimeOptions = []
let k = 0
for (k = 1; k <= 24; k++) {
  smeLoanTimeOptions.push(k)
}

export const banks = [
  {
    value: 'Basisbank',
    link: 'https://www.basisbank.dk/docs/BehandlingPersonoplysninger.pdf',
  },
  { value: 'Expressbank', link: 'https://www.expressbank.dk/om/persondatapolitik/' },
  {
    value: 'Resurs Bank',
    link: 'https://www.resursbank.dk/om-os/integritet-og-sikkerhed/gdpr-som-kunde',
  },
  { value: 'Facit Bank A/S', link: 'https://facitbank.dk/om-banken/privatlivspolitik/' },
  {
    value: 'Santander Consumer Bank',
    link: 'https://santanderconsumer.dk/globalassets/falles-indhold/dokumenter/persondatapolitik.pdf',
  },
  {
    value: 'TF Bank',
    link: 'https://tfbank.dk/om-tf-bank/integritetspolitik',
  },
]

export const shareOfCosts = []
let s = 0
for (s = 0; s <= 100; s += 10) {
  shareOfCosts.push(s)
}

export const salusId = 1547006 // Value gotten from Salus, used if no other tracking parameters are present
