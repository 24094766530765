<template>
  <div>
    <div class="article">
      <h1 class="center-title">Basisbank lån</h1>

      <div class="top-text text-container">
        <p>
          Basisbank har meldt ud i Juni 2021 at de opgiver deres banklicens, og derfor kan
          de ikke udbetale lån som de tidligere har kunne. Vi kan til gengæld hjælpe dig
          med at finde bedre og alternative tilbud, hvis du er på udkig efter et lån.
          Udfyld vores gratis ansøgning på to minutter her på siden, så indhenter vi
          tilbud til dig med det samme.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#tidligere-laanevilkaar-og-renter-hos-basisbank"
                    >Tidligere lånevilkår og renter hos Basisbank</a
                  >
                </li>
                <li>
                  <a href="#saadan-ansoeger-du-om-alternativ-laan-til-basisbank"
                    >Sådan ansøger du om alternativ lån til Basisbank</a
                  >
                </li>
                <li><a href="#historien-om-basisbank">Historien om Basisbank</a></li>
                <li>
                  <a href="#tidligere-laanevilkaar-og-ansoegningsprocess-hos-basisbank"
                    >Tidligere lånevilkår og ansøgningsprocess hos Basisbank</a
                  >
                </li>
                <li>
                  <a href="#eksempel-paa-et-laan-hos-basisbank"
                    >Eksempel på et lån hos Basisbank</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="tidligere-laanevilkaar-og-renter-hos-basisbank">
            Tidligere lånevilkår og renter hos Basisbank
          </h2>
          <p>
            <a
              href="https://www.saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              ><strong>Lånebeløb</strong></a
            >: 30.000 - 500 000 kr.
          </p>
          <p><strong>Låneperiode: </strong>2 til 12 år</p>
          <p><strong>Debitorrente: </strong>4,95% - 15,95%</p>
          <p>
            <strong>Andre udgifter: </strong>Månedsgebyr 20 kr. (30.000-74.999 kr.).
            Stiftelsesgebyr 4%-5%.
          </p>
          <p>
            <strong>Behandlingstid: </strong>Svar med det samme og pengene på din konto
            indenfor næste hverdag
          </p>
          <p><strong>Medansøger: </strong>Ja</p>
        </div>

        <div class="text-container">
          <h2 id="saadan-ansoeger-du-om-alternativ-laan-til-basisbank">
            Sådan ansøger du om alternativ lån til Basisbank
          </h2>
          <p>
            Da vi samarbejdede med Basisbank, udfyldte du en uforpligtende ansøgning på
            vores hjemmeside, hvorefter vi indhentede tilbud fra bla. Basisbank. Nedenfor
            vil vi gennemgå, hvordan du ansøger om alle de alternativ, som vi har til
            Basisbank.
          </p>
          <p>
            Først udfylder du vores ansøgningsskema. Her starter du med at fortælle,
            hvilket beløb samt løbetid du ønsker. Dette indkluderer oplysninger om din
            økonomi, dine løn og generelle oplysninger om dig.
          </p>
          <p>
            Når du har gjort dette skal du indsende din ansøgning digitalt, så vi kan
            indhenter tilbud til dig fra vores samarbejdspartnere. Her har Basisbank
            tidligere været en af de udbydere af lån, som du har kunne modtage et tilbud
            fra.
          </p>
          <p>
            Du modtager en e-mail så snart vi har fundet tilbud til dig fra vores
            partnere, herefter kan du sammenligne dine tilbud på din profil, så du kan få
            overblik over lånemarkedet. Herefter vælger du blot dit foretrukne tilbud,
            hvorefter du vil blive vidrestillet til den valgte bank, hvor de muligvis vil
            indhente nogle få yderligere oplysnigner om dig.
          </p>
          <p>Herefter udbetales lånet til din konto så hurtigt som muligt.</p>
        </div>

        <div class="text-container">
          <h2 id="historien-om-basisbank">Historien om Basisbank</h2>
          <p>
            Basisbank startede op i 2000, og var en af de allerførste online banker i
            Danmark. De stod også bag
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbyderne</a
            >
            LånLet, Nordisk Lån, Selene Finans og Nupp. Basisbank tilbød mange forskellige
            online låneløsninger som et
            <a
              href="https://www.saverium.dk/laan-penge/samlelaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtigt samlelån</a
            >,
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan"
              rel="noopener noreferrer"
              target="_blank"
              >billigt privatlån</a
            >
            og
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån uden sikkerhed</a
            >.
          </p>
          <p>
            Grundet ny lovgivning i 2020 blev der pålagt danske udbydere af lån en række
            forskellige restriktioner, som gjorde at indtjeningen blev sænket for hver
            krone som blev lånt ud. Derfor blev det mere vanskeligt at være en bank.
            Derudover kom der året efter i 2021, en helt ny vejledning om hvordan
            låneansøgere skal kreditvurderes. Det har gjort det mere besværligt for
            låneansøgerne at ansøge om <strong>små lån</strong>, og det ville have haft en
            negativ effekt på volumen af lån, som Basisbank vil kunne udbetale i
            fremtiden.
          </p>
          <p>
            I Juni 2021 opgav Basisbank deres licens og dermed stoppede muligheden for at
            du kan søge om lån via dem.
          </p>
          <p>
            Ved at anvende vores online platform kan du
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne lån</a
            >
            fra mange andre velkendte udbydere af lån end Basisbank. Vi sørger for at
            indhente tilbud der passer til dine krav og ønsker, så længe du udfylder vores
            gratis <strong>låneansøgning</strong>.
          </p>
          <p>
            Basisbank var en af de eneste online udbydere af lån hvor du kan låne helt op
            til 500.000 kr. online uden sikkerhed. Ved en traditionel bank vil du som
            regel blive bedt om at skulle stille sikkerhed, når du søger om et så stort
            lån. Basisbank tilbød mange forskellige online låneprodukter med en af
            Danmarks laveste renter. Hos Basisbank kunne du os indfri dit lån før tid uden
            det kostede dig ekstra.
          </p>
          <p>
            Hvis du udfylder en ansøgning via vores hjemmeside, kan du få tilsendt
            forskellige tilbud fra flere forskellige kendte pengeinstitutter og andre
            udbydere af lån. Det uforpligtende og nemt, samt at du ikke behøver at
            acceptere nogle af tilbuddene, hvis de ikke skulle leve op til dine
            forventninger.
          </p>
          <p>
            Du kan indsende en hurtig og gratis ansøgning på få minutter, hvilket giver
            dig mulighed for at sammenligne forskellige tilbud og dermed skabe overblik
            over lånemarkedet inden du vælger dit lån.
          </p>
        </div>

        <div class="text-container">
          <h2 id="tidligere-laanevilkaar-og-ansoegningsprocess-hos-basisbank">
            Tidligere lånevilkår og ansøgningsprocess hos Basisbank
          </h2>
          <p>
            For at du kunne blive godkendt til et Basisbank lån, skulle du leve op til
            disse 4 krav:
          </p>
          <ul>
            <li>Fast bopæl i Danmark.</li>
            <li>Minimum 18 år.</li>
            <li>Fast indtægt</li>
            <li>
              Ikke registreret i hverken <strong>Debitor Registret eller RKI</strong>.
            </li>
          </ul>
          <p>
            Dernæst ville der blive foretaget en kreditvurdering af din økonomi via eSkat,
            og derfor var det vigtigt at have
            <a
              href="https://www.saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >NemID/MitID</a
            >
            klar. Til sidst ville man blive bede om at indsende dokumentation på udvalgte
            udgiftsposter.
          </p>
        </div>

        <div class="text-container">
          <h2 id="eksempel-paa-et-laan-hos-basisbank">
            Eksempel på et lån hos Basisbank
          </h2>
          <p>
            <strong>Låneeksempel</strong>: Ved et samlet lånebeløb på 80.000 kr. over 10
            år, der tilbagebetales via Betalingsservice. Mdl. ydelse: 859 - 1.488 kr.
            Debitorrente: 4,95 - 19,95 % (variabel i lånets løbetid). ÅOP: 5,4 – 20,6 %.
            Samlede kreditomk.: 23.099 – 98.515 kr. Samlet tilbagebet.: 103.099 – 178.515
            kr. Stiftelsesomk.: 2%. Månedsgebyr: 0 kr.
          </p>
          <p>Basisbank kontaktoplysninger</p>
          <p>
            Du kan stadigvæk ringe til Basisbank kundeservice alle hverdage fra kl. 10:00
            – 12:00 på telefon 70 22 09 29, eller skrive til dem på
            <a
              href="mailto:kundecenter@basisbank.dk"
              rel="noopener noreferrer"
              target="_blank"
              >kundecenter@basisbank.dk</a
            >.
          </p>
          <p>
            Skulle du have nogle spørgsmål vedrørende hvordan du udfylder din ansøgning
            hos os, kan du kontakte os i hverdagene mellem 09:00 - 15:00 på telefon: 93 75
            99 59. Du er os velkommen til at kontakte os direkte på e-mail:
            <a
              href="mailto:kundeservice@saverium.dk"
              rel="noopener noreferrer"
              target="_blank"
              >kundeservice@saverium.dk</a
            >.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
