const state = {
  type: 'consumerloan',
}
const actions = {}

const mutations = {
  setApplicationType(state, value) {
    state['type'] = value
  },
}
export default {
  state,
  actions,
  mutations,
}
