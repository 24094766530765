<template>
  <div class="mb-3">
    <b-collapse id="additional-info" v-model="toggle">
      <div v-if="toggle" class="preview-wrapper">
        {{ offer.description }}
      </div>
    </b-collapse>
    <div
      @click="toggle = !toggle"
      :class="toggle ? null : 'collapsed'"
      class="collapse-wrapper col-12 mb-3"
      id="toggle-collapse"
      :aria-expanded="toggle ? 'true' : 'false'"
    >
      <span class="when-closed"> {{ $t('show_more') }} </span>
      <span class="when-opened"> {{ $t('show_less') }} </span>
      <span class="when-opened">
        <i class="far fa-angle-double-down close-collapse" aria-hidden="true"></i
      ></span>
      <span class="when-closed">
        <i class="far fa-angle-double-down toggle-collapse" aria-hidden="true"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['offer'],
  data() {
    return { toggle: false }
  },
}
</script>
<style lang="scss" scoped>
.collapse-wrapper {
  text-align: center;
  margin: auto;
  padding-top: 1rem;
  cursor: pointer;
}
.close-collapse {
  @media all and (max-width: 415px) {
    display: inline-block;
  }
}
</style>
