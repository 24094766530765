<template>
  <div class="row article">
    <h1 class="center-title">
      Spirala zadłużenia – co to jest? Jak nie wpaść i jak wyjść z długów?
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >Potrzeby każdego z nas są różne i w odmienny sposób je realizujemy.
              Mieszkanie możemy kupić na kredyt hipoteczny z banku, a remont przeprowadzić
              ze środków uzyskanych z kredytu gotówkowego. Problem pojawia się, gdy
              zaciągasz coraz więcej zobowiązań, celem realizacji kolejnych swoich celów,
              albo posiłkujesz się
              <b-link :to="$root.rn.OnlinePaydayLoans">chwilówką</b-link> po to, by móc
              zapłacić ratę wcześniej zaciągniętego kredytu. W ten sposób wpadasz w tak
              zwaną spiralę zadłużenia. Czym ona jest i jak z niej wyjść? Dowiedz się
              wszystkiego na tematu spirali zadłużenia.</i
            >
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/debt-spiral-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/debt-spiral.jpg"
            alt="Spirala zadłużenia – co to jest? Jak nie wpaść i jak wyjść z długów?"
          />

          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            Na koniec 2020 roku łączne zaległości płatnicze Polaków wpisanych do bazy
            danych Krajowego Rejestru Długów (KRD) wynosiły aż 48 mld zł, co oznacza
            wzrost rok do roku o prawie 2,4 mld zł. To pokazuje, że wielu Polaków żyje na
            kredyt i nieobce jest im zjawisko spirali zadłużenia.
          </p>
          <p>
            W badaniu „Zadłużenie Polaków” przeprowadzonym na zlecenie KRD BIG 25 proc.
            Polaków przyznało, że miało problem ze spłatą zadłużenia. Dziwi jednak fakt,
            że aż 86 proc. respondentów wyraża opinię, że pożyczać pieniądze można dopiero
            wtedy, gdy ma się pewność, że dokonamy spłaty terminowo.
          </p>
          <p>
            Są i tacy dłużnicy, którzy realizują cele konsumpcyjne z kolejnych pożyczek,
            nie zastanawiając się za bardzo nad tym, czy będą w stanie zwrócić pieniądze.
            Nie planują dobrze swoich finansów, przez co mogą popaść w tarapaty.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Spirala zadłużenia jest terminem ściśle związanym z finansami, głównie osób
            fizycznych prywatnych, choć i przedsiębiorcy mogą wpaść w takie kłopoty. To
            zjawisko polegające na zaciąganiu coraz większej liczby różnego rodzaju
            zobowiązań – kredytów, pożyczek gotówkowych w firmach pozabankowych, pożyczek
            od rodziny czy
            <b-link :to="$root.rn.SocialLoans">pożyczek społecznościowych</b-link>. Kiedy
            już banki ani nawet firmy pożyczkowe nie chcą udzielić kolejnego zobowiązania
            danej osobie, a potrzebuje ona pieniędzy, korzysta z lichwiarskich ofert, co
            jeszcze bardziej pogrąża ją pod względem finansowym. Stare długi spłaca
            nowymi, przez co spirala zadłużenia pogłębia się. Kiedy zadłużony nie jest w
            stanie na bieżąco regulować rat wszystkich zaciągniętych pożyczek i kredytów,
            powstają zaległości i naliczane są odsetki karne. Suma miesięcznych zobowiązań
            z tytułu rat może w pewnym momencie przekroczyć sumę dochodów, po odjęciu
            kosztów „życia”.
          </p>
          <p>
            Prędzej czy później
            <b
              >osoba, która popadła w spiralę zadłużenia będzie musiała zetknąć się z
              windykacją należności</b
            >, a także z komornikiem, który zacznie ściągać długi z jej wynagrodzenia oraz
            z poszczególnych składników majątku. Ostatecznie, może ona stracić dom czy
            mieszkanie i wszystkie swoje dobra. By tak się nie stało, warto wiedzieć, jak
            wydostać się ze spirali zadłużenia i jak spłacić długi.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Impulsywne i niestety lekkomyślne korzystanie z produktów kredytowych i
            pożyczkowych może narazić nas na poważne problemy finansowe i wizyty
            komornika. Najlepiej więc od razu zacząć działać, jeśli tylko uświadomimy
            sobie, że wpadliśmy w spiralę długów. Co wówczas zrobić?
            <b
              >Przede wszystkim należy wiedzieć, że wyjście z pętli zadłużenia jak
              najbardziej jest możliwe</b
            >, ale nie można bać się prosić o pomoc. Są specjaliści, którzy doradzą, jak
            tego dokonać. Można też poradzić sobie samodzielnie, o ile będzie się
            konsekwentnym. <b>Jak wyjść z długów?</b>
          </p>
          <ul>
            <li>
              Po pierwsze – ustalmy wysokość zadłużenia. Możemy samodzielnie spisać na
              kartce papieru lub w tabeli w programie komputerowym Excel, jakie mamy
              miesięczne obciążenia z tytułu np. rat kredytów, pożyczek, karty kredytowej,
              limitu zadłużenia na koncie. Uwzględnić należy też wydatki miesięczne na
              mieszkanie, jedzenie i utrzymanie. Do tego doliczamy inne koszty. Będziemy
              mogli je dokładnie oszacować, kiedy każdy rachunek ze sklepu czy punktu
              usługowego z danego miesiąca pozostawimy sobie i wprowadzimy do tabeli w
              odpowiedniej kategorii przychodów i rozchodów. Istnieją już specjalne
              dedykowane aplikacje służące do zarządzania budżetem domowym.
            </li>
            <li>
              Po drugie – nie zapożyczajmy się więcej! To konieczne do tego, aby wyjść ze
              spirali długów. Powiększając swoje zadłużenie, działamy na swoją niekorzyść,
              nawet jeśli dzięki nowej pożyczce zapłacimy w terminie ratę kredytu.
            </li>
            <li>
              Po trzecie – nie unikajmy rozmów z wierzycielami. Przy wielu kredytach i
              pożyczkach spłacanych na raz mogą pojawić się pewne kłopoty z terminowym
              regulowaniem rat. Najgorsze, co może zrobić dłużnik w takiej sytuacji, to
              unikać kontaktu z wierzycielami. Wręcz przeciwnie, należy porozumieć się z
              nimi i dojść do konsensusu. Być może będziemy mogli rozłożyć spłatę swojego
              długu na większą liczbę rat lub skorzystać z karencji i wakacji kredytowych.
            </li>
            <li>
              Po czwarte – połączmy wszystkie pozostałe długi w jeden większy, z niższą
              ratą, dzięki konsolidacji. Kredyt konsolidacyjny pozwala na połączenie ze
              sobą różnych zobowiązań, a nawet na obniżenie miesięcznej raty, by była ona
              mniejsza niż suma spłacanych przez nas dotychczasowych rat. Pomimo faktu, że
              konsolidacja wymaga od nas zaciągnięcia kolejnego kredytu, to akurat to jest
              dopuszczalne w drodze do wyjścia z pętli długów.
            </li>
            <li>
              Po piąte – zaciśnijmy pasa. Oszczędności możemy znaleźć nawet tam, gdzie się
              tego nie spodziewamy. Warto kupować produkty żywnościowe taniej, na
              promocjach, w większych, zbiorczych opakowaniach w marketach, zamiast w
              niewielkich sklepikach osiedlowych. Najlepiej robić listy zakupowe, ponieważ
              wtedy wkładamy do koszyka mniej niepotrzebnych rzeczy.
            </li>
          </ul>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Najlepszym sposobem na to, jak uniknąć spirali zadłużenia jest niezaciąganie
            kredytów i pożyczek w momencie, gdy nie jesteśmy w stanie ich spłacić. Nadmiar
            zobowiązań prowadzi do zbyt dużego obciążenia domowego budżetu, a stąd już
            niedaleko do spirali długów.
          </p>
          <p>
            <b>Zanim więc zaciągniemy kolejny kredyt, spłaćmy poprzedni.</b> Jeśli zaś nie
            jest nam absolutnie potrzebne nowe auto na kredyt samochodowy, możemy
            zrezygnować w danym roku z wakacji za granicą na korzyść tych spędzonych na
            działce za miastem, to warto stronić od kolejnych długów. Zawsze musimy mieć
            na uwadze, że
            <b
              >każdy zaciągany kredyt lub pożyczka muszą być spłacone w terminie, by nie
              narażać się na odsetki karne i koszty windykacji należności.</b
            >
            Racjonalne podejście do finansów i możliwości spłaty długów w danej chwili
            pozwoli uniknąć spirali zadłużenia.
          </p>
        </div>
      </div>
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  data() {
    return {
      contents: [
        {
          title: 'Polacy dłużnikami – dane z 2020 roku',
          link: '#1',
        },
        {
          title: 'Czym jest spirala zadłużenia?',
          link: '#2',
        },
        {
          title: 'Spirala zadłużenia – jak wyjść z długów?',
          link: '#3',
        },
        {
          title: 'Jak nie wpaść w pętlę zadłużenia?',
          link: '#4',
        },
      ],
    }
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
<style lang="scss" scoped></style>
