<template>
  <div class="mt-5">
    <ul v-for="(article, index) in orderedArticles" :key="index">
      <li>
        <b-link class="black-link" :to="article.url">{{ article.title }}</b-link>
      </li>
    </ul>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  computed: {
    articles() {
      return this.$store.state.articles.articles
    },
    orderedArticles() {
      return _.orderBy(this.articles, 'title')
    },
  },
}
</script>
<style lang="scss"></style>
