<template>
  <div class="row article">
    <h1 class="center-title">
      Odpowiedzialne pożyczanie – jak pożyczać odpowiedzialnie i bezpiecznie?
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >Spontaniczność i kierowanie się nagłym impulsem być może urozmaica nasze
              życie i dodaje mu kolorytu, ale jednocześnie nie jest dobrym kierunkiem w
              przypadku sfery finansowej. Zaciąganie pożyczek i kredytów pod wpływem
              chwili może słono kosztować korzystającego. Dlatego warto wiedzieć, co to
              znaczy odpowiedzialne pożyczanie.</i
            >
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/responsible-borrowing-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/responsible-borrowing.jpg"
            alt="Odpowiedzialne pożyczanie – jak pożyczać odpowiedzialnie i bezpiecznie?"
          />

          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            Każda decyzja dotycząca zaciągnięcia jakiegokolwiek długu powinna być dobrze
            przemyślana. Pośpiech nie jest tu wskazany. To jedna z zasad, jakie
            przyświecają odpowiedzialnemu pożyczaniu, zresztą nie tylko pieniędzy.
          </p>
          <p>
            Co to znaczy odpowiedzialne pożyczanie? Z języka angielskiego to „responsible
            lending”, czyli odpowiedzialność za finanse klienta. Z jednej strony to
            korzystający ze środków parabanku powinien wykazać się dojrzałością w
            korzystaniu z oferty pożyczkowej, ale z drugiej odpowiedzialne pożyczanie
            <b>dotyczy również udostępniających środki</b>. Z ich perspektywy „responsible
            lending” oznacza troskę o finanse klienta w takim rozumieniu, by udzielać mu
            pożyczki
            <b
              >tylko wtedy, gdy rzeczywiście jest on w stanie ją spłacić terminowo, na
              ustalonych wcześniej zasadach</b
            >.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Klient ma obowiązek zachowywać się odpowiedzialnie i nie zapożyczać się ponad
            miarę. Jednak odpowiedzialnością powinna wykazać się także firma
            udostępniająca zobowiązania finansowe klientom. Oznacza to konieczność:
          </p>
          <ul>
            <li>
              weryfikowania
              <b-link :to="$root.rn.CreditWorthiness">zdolności kredytowej</b-link>
              klientów i dopasowywania do niej wysokości ewentualnej pożyczki,
            </li>
            <li>
              informowania klienta o wszystkich najważniejszych parametrach zobowiązania,
            </li>
            <li>działania zgodnie z przepisami ustawy o kredycie konsumenckim.</li>
          </ul>
          <p>
            W ramach „responsible lending”
            <b
              >firma pożyczkowa powinna w sposób jasny i prosty przedstawiać klientom
              zasady uzyskania i spłaty zobowiązania</b
            >. Na tej podstawie klient będzie mógł podjąć racjonalną i odpowiedzialną
            decyzję co do tego, czy skorzystać z takiej oferty. Firma powinna również
            rzetelnie oceniać wiarygodność kredytową klientów i nie udzielać pożyczek
            osobom, które są wpisane do baz danych dłużników.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Każda osoba, która przymierza się do zaciągnięcia kredytu lub pożyczki, w
            pierwszej kolejności powinna odpowiedzieć sobie na pytanie, czy rzeczywiście
            skorzystanie z takiej oferty jest jej bezwzględnie potrzebne? Musi ona również
            przeanalizować swoją sytuację finansową i zastanowić się, czy we wskazanym
            przez firmę terminie będzie w stanie spłacić swoje zobowiązanie wraz z
            naliczonymi opłatami i odsetkami.
          </p>
          <p>
            <b
              >Aby odpowiedzialnie pożyczać pieniądze, nigdy nie należy działać pochopnie.
              Warto:</b
            >
          </p>
          <ul>
            <li>Zastanowić się, czy pożyczka jest koniecznością.</li>
            <li>Porównać ze sobą różne oferty.</li>
            <li>Zgromadzić choć niewielki wkład własny, jeśli planujemy inwestycję.</li>
            <li>
              Upewnić się, czy będziemy w stanie w terminie wskazanym w umowie spłacić
              zobowiązanie.
            </li>
          </ul>
          <p>
            Każdą pożyczkę prędzej czy później będzie trzeba oddać. Klient decydujący się
            na zaciągnięcie zobowiązania musi mieć tego pełną świadomość i samodzielnie
            obliczyć swoją zdolność kredytową. Odpowiedzialny pożyczkobiorca powinien
            dopasować wysokość oczekiwanej kwoty do swoich możliwości spłaty. Jeśli wie,
            że za miesiąc czy dwa nadal nie będzie mógł dokonać zwrotu zobowiązania, w
            ogóle nie powinien starać się o środki. W takiej sytuacji są m.in. osoby,
            które nie mają stałego źródła dochodów.
          </p>
          <p>
            <b> Ważne jest, by pożyczkobiorca znał swoje prawa i obowiązki,</b> wnioskując
            o zobowiązanie. Nie powinien podpisywać żadnej umowy, jeśli nie zapoznał się
            wcześniej z jej treścią.
            <b
              >Szczególnie uważnie trzeba przeczytać informacje dotyczące kosztów
              pożyczki</b
            >
            – wyrażone procentowo i wartościowo. Klient musi wiedzieć, że w ciągu 14 dni
            od chwili uzyskania środków może odstąpić zgodnie z prawem od umowy, bez
            ponoszenia z tego tytułu konsekwencji finansowych.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import LoanWidget from '@/components/LoanWidget.vue'
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'Odpowiedzialność przy pożyczaniu pieniędzy',
          link: '#1',
        },
        {
          title: 'Odpowiedzialność firmy pożyczkowej',
          link: '#2',
        },
        {
          title: 'Odpowiedzialność pożyczkobiorcy',
          link: '#3',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped></style>
