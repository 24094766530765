<template>
  <div>
    <div class="article">
      <h1 class="center-title">Boliglån</h1>
      <div class="top-text text-container">
        <p>
          Hvis du ønsker at låne til din bolig, så kan vi tilbyde hjælp. Vi kan ikke
          hjælpe dig med et decideret boliglån, men vi kan hjælpe dig med at
          <a
            href="https://saverium.dk/laan-penge"
            rel="noopener noreferrer"
            target="_blank"
            >låne penge</a
          >
          til mange andre tiltag i og omkring din bolig. Vi hjælper dig med at indhente
          tilbud på
          <a
            href="https://saverium.dk/laan-penge/forbrugslaan"
            rel="noopener noreferrer"
            target="_blank"
            >forbrugslån</a
          >
          fra en lang række af banker og långivere, som du frit kan råde over. Ønsker du
          fx at <strong>låne til renovering</strong> eller et
          <strong>byggeprojekt</strong>, som kan klares inden for en halv mio. kr., så kan
          du ansøge hos os, og finde ud af, hvad dine
          <a
            href="https://saverium.dk/laan-penge/bolig/laanemuligheder"
            rel="noopener noreferrer"
            target="_blank"
            >lånemuligheder</a
          >
          er, blandt mange banker og alternative långivere.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#laan-til-din-primaere-bolig">Lån til din primære bolig</a>
                </li>
                <li>
                  <a href="#laan-til-andre-boligformer">Lån til andre boligformer</a>
                </li>
                <li><a href="#find-dit-laan">Find dit lån</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="laan-til-din-primaere-bolig">Lån til din primære bolig</h2>
          <p>
            Et boliglån forbinder de fleste med et lån til deres primære bolig. Det er fx
            et <strong>huslån</strong>, en <strong>lejlighed</strong>, herunder
            <strong>ejerlejlighed</strong> og <strong>andelsbolig</strong>, eller et
            <strong>lån til en gård</strong>. Dette vil være den primære boligform for de
            fleste, men der findes også alternative steder at slå sig ned. ‘
          </p>
          <p>
            Hvis du har, eller ønsker at bo på, en <strong>husbåd</strong>, er det også en
            mulighed, men mange vælger også at bo fast i deres
            <strong>kolonihave </strong>eller <strong>sommerhus,</strong> på trods af, at
            det ikke altid er helt lovligt.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-til-andre-boligformer">Lån til andre boligformer</h2>
          <p>
            Det også værd at nævne, at du kan låne til andre boliger. Står du fx med et
            brændende ønske om en <strong>feriebolig</strong> i udlandet, er det også en
            oplagt mulighed for et lån. Måske står du som forældre til børn, som er ved at
            flyve fra reden og skal låne til et <strong>forældrekøb</strong>.
          </p>
          <p>
            Den sidste, og også meget normale, årsag til at låne til boligen er, hvis du
            har fundet <strong>byggegrunden</strong>, som du ønsker at bygge din
            drømmebolig på. Her ønsker du i første omgang måske blot låne til grunden og
            senere placere huset.
          </p>
        </div>

        <div class="text-container">
          <h2 id="find-dit-laan">Find dit lån</h2>
          <p>
            Hvis du blot har behov for den sidste økonomiske hjælp til at få fat i
            drømmeboligen, eller til et <strong>renoveringsprojekt</strong>, uanset, om
            det så er en ny <strong>varmepumpe</strong>,
            <strong>energirenovering</strong>, <strong>solceller </strong>eller noget helt
            fjerde, så kan vi hjælpe dig. Du indsender blot en ansøgning via vores online
            ansøgningsformular, hvorefter du på få minutter vil modtage
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >lånetilbud</a
            >
            fra mange kendte og ukendte
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere</a
            >.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },

  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
