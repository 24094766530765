import { WebStorageStateStore } from 'oidc-client'
const userStore = new WebStorageStateStore({ store: window.localStorage })

const API = process.env.VUE_APP_API
const BRAND = process.env.VUE_APP_BRAND
const AUTH_API = process.env.VUE_APP_AUTH_API
const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN
const SENTRY_ENV = process.env.VUE_APP_SENTRY_ENVIRONMENT
const DEVENV = API.includes('localhost')
let BRAND_SLUG
let GOOGLE_ANALYTICS
let LOCALE
let GTM_ID
let BRAND_NAME
let BASE_URL
let SALUS_SCRIPT
if (BRAND === 'saverium_pl') {
  BRAND_NAME = process.env.VUE_APP_SAVERIUM_PL_BRAND_NAME
  BRAND_SLUG = process.env.VUE_APP_SAVERIUM_PL_BRAND_SLUG
  GOOGLE_ANALYTICS = process.env.VUE_APP_SAVERIUM_PL_GOOGLE_ANALYTICS
  LOCALE = process.env.VUE_APP_SAVERIUM_PL_DEFAULT_LOCALE
  GTM_ID = process.env.VUE_APP_SAVERIUM_PL_GTM_ID
  BASE_URL = process.env.VUE_APP_BASE_URL_PL
  SALUS_SCRIPT = process.env.VUE_APP_PL_SALUS_SCRIPT
} else if (BRAND === 'saverium_es') {
  BRAND_SLUG = process.env.VUE_APP_SAVERIUM_ES_BRAND_SLUG
  BRAND_NAME = process.env.VUE_APP_SAVERIUM_ES_BRAND_NAME
  GOOGLE_ANALYTICS = process.env.VUE_APP_SAVERIUM_ES_GOOGLE_ANALYTICS
  LOCALE = process.env.VUE_APP_SAVERIUM_ES_DEFAULT_LOCALE
  GTM_ID = process.env.VUE_APP_SAVERIUM_ES_GTM_ID
  BASE_URL = process.env.VUE_APP_BASE_URL_ES
} else if (BRAND === 'lainaayritykselle_fi') {
  BRAND_SLUG = process.env.VUE_APP_LAINAAYRITYKSELLE_FI_BRAND_SLUG
  BRAND_NAME = process.env.VUE_APP_LAINAAYRITYKSELLE_FI_BRAND_NAME
  GOOGLE_ANALYTICS = process.env.VUE_APP_LAINAAYRITYKSELLE_FI_GOOGLE_ANALYTICS
  LOCALE = process.env.VUE_APP_LAINAAYRITYKSELLE_FI_DEFAULT_LOCALE
  GTM_ID = process.env.VUE_APP_LAINAAYRITYKSELLE_FI_GTM_ID
  BASE_URL = process.env.VUE_APP_BASE_URL_LAINAAYRITYKSELLE_FI
} else if (BRAND === 'saverium_dk') {
  BRAND_SLUG = process.env.VUE_APP_SAVERIUM_DK_BRAND_SLUG
  BRAND_NAME = process.env.VUE_APP_SAVERIUM_DK_BRAND_NAME
  GOOGLE_ANALYTICS = process.env.VUE_APP_SAVERIUM_DK_GOOGLE_ANALYTICS
  LOCALE = process.env.VUE_APP_SAVERIUM_DK_DEFAULT_LOCALE
  GTM_ID = process.env.VUE_APP_SAVERIUM_DK_GTM_ID
  BASE_URL = process.env.VUE_APP_BASE_URL_DK
  SALUS_SCRIPT = process.env.VUE_APP_DK_SALUS_SCRIPT
}

export default {
  API,
  BRAND,
  AUTH_API,
  BRAND_SLUG,
  BRAND_NAME,
  GOOGLE_ANALYTICS,
  LOCALE,
  GTM_ID,
  DEVENV,
  SENTRY_DSN,
  SENTRY_ENV,
  SALUS_SCRIPT,
}

export const oidcSettings = {
  userStore: userStore,
  authority: process.env.VUE_APP_OIDC_AUTHORITY,
  client_id: process.env.VUE_APP_OIDC_CLIENT_ID,
  redirect_uri: `${BASE_URL}${process.env.VUE_APP_OIDC_REDIRECT_URI}`,
  response_type: process.env.VUE_APP_OIDC_RESPONSE_TYPE,
  scope: process.env.VUE_APP_OIDC_SCOPE,
  loadUserInfo: process.env.VUE_APP_OIDC_LOAD_USER_INFO === 'true',
  post_logout_redirect_uri: `${BASE_URL}${process.env.VUE_APP_OIDC_POST_LOGOUT_REDIRECT_URI}`,
}
