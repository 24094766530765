<template>
  <b-container class="container">
    <b-row>
      <b-col cols="12" sm="6" class="resetPassword">
        <h1 class="page-header">{{ $t('password_recovery') }}</h1>
        <div class="well">
          <div
            class="alert alert-danger alert-dismissable"
            v-if="
              this.errors.length > 0 &&
                !this.errors.new_password1 &&
                !this.errors.new_password2
            "
          >
            <button class="close" type="button" v-on:click="error = false">×</button>
            {{ $t('password_recovery_key_expired') }}
          </div>
          <div class="alert alert-info alert-dismissible" v-if="this.success">
            {{ $t('password_has_been_changed') }}
          </div>
          <router-link :to="$root.rn.MyAccountView" v-if="this.success"
            ><button class="btn btn-primary btn-lg btn-block">
              {{ $t('login') }}
            </button>
          </router-link>
          <form method="post" @submit.prevent="submit()" v-if="!this.success">
            <input type="hidden" name="pk" :value="this.$route.params.pk" />
            <input type="hidden" name="key" :value="this.$route.params.key" />
            <input type="hidden" name="ts" :value="this.$route.params.ts" />
            <label for="new_password1">{{ $t('new_password') }}</label>
            <b-form-input
              type="password"
              :class="{ 'is-invalid': this.errors.new_password1 }"
              name="new_password1"
              id="new_password1"
            ></b-form-input>
            <small class="form-text">
              <ul>
                <li>
                  {{ $t('you_password_cant_be_commonly_used') }}
                </li>
                <li>{{ $t('your_passoword_must_contain_at_least_8_characters') }}</li>
                <li>
                  {{
                    $t(
                      'your_password_cant_be_too_similar_to_your_other_personal_information'
                    )
                  }}
                </li>
                <li>{{ $t('your_password_cant_be_entirely_numeric') }}</li>
              </ul>
            </small>
            <label for="new_password2">{{ $t('new_password_confirmation') }}</label>
            <b-form-input
              type="password"
              name="new_password2"
              :class="{ 'is-invalid': this.errors.new_password2 }"
              id="new_password2"
            ></b-form-input>
            <hr />
            <button type="submit" class="btn btn-primary btn-lg btn-block">
              <i class="fa fa-fw fa-sign-in"></i>
              {{ $t('send') }}
            </button>
          </form>
        </div>
        <hr />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Input from '@/components/form/Input'

export default {
  data() {
    return {
      success: false,
      errors: {},
    }
  },
  components: {
    'form-input': Input,
  },
  computed: {},
  methods: {
    async submit() {
      let formData = new FormData(document.querySelector('form'))
      let response = await this.$store.dispatch('setPassword', formData)
      if (response.success) {
        this.success = true
      } else {
        this.errors = response
      }
    },
  },
}
</script>

<style>
.page-header {
  font-size: 2rem;
}
@media (min-width: 572px) {
  .resetPassword {
    margin-left: 25%;
  }
}
</style>
