<template>
  <div class="row article">
    <h1 class="center-title">
      Bezpieczne pożyczanie pieniędzy przez Internet – na co zwracać uwagę?
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >W sieci prężnie rozwija się sfera finansowa, co pozwala konsumentom na
              korzystanie z pożyczek i kredytów przez internet. Czy jednak takie
              pożyczanie pieniędzy jest bezpieczne? Na co zwrócić uwagę, by nie dać się
              oszukać i otrzymać taką ofertę, jakiej oczekiwaliśmy?</i
            >
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/safe-online-borrowing-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/safe-online-borrowing.jpg"
            alt="Bezpieczne pożyczanie pieniędzy przez Internet – na co zwracać uwagę?"
          />

          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            Pożyczki online w firmach pozabankowych są standardowym elementem oferty.
            Coraz więcej banków również stara się dotrzeć do klientów za pomocą kanałów
            internetowych, ale w tej branży znakomicie odnajdują się przede wszystkim
            firmy oferujące tak zwane chwilówki online, czyli szybkie pożyczki udzielane
            przez internet. Wypracowały one mechanizmy sprawnej weryfikacji klientów oraz
            udostępniania pieniędzy bezpośrednio na wskazane konto bankowe. Wszystko to
            odbywa się w maksymalnie krótkim czasie, przy uproszczeniu procedur
            weryfikacyjnych.
          </p>
          <p>
            Niektóre firmy pożyczkowe skłonne są nawet udostępniać chwilówki bez BIK czy
            oferować je osobom zadłużonym. To propozycja korzystna dla klienta, który ma
            szansę na szybkie uzyskanie pieniędzy w internecie, nawet wtedy, gdy ma on
            negatywne wpisy w Biurze Informacji Kredytowej, które mogłyby wykluczyć go z
            grona kredytobiorców w większości banków.
          </p>
          <p>
            Zanim jednak złożymy wniosek o pożyczkę online, powinniśmy zwrócić uwagę na
            kilka ważnych kwestii, składających się na katalog zasad bezpiecznego
            pożyczania:
          </p>
          <ul>
            <li>Weryfikacja firmy pożyczkowej, z oferty której chcemy skorzystać.</li>
            <li>
              Rezygnacja z pożyczki przez internet, jeśli jej zaciągnięcie wymusza
              dokonania wysokiej wpłaty na konto firmy celem weryfikacji tożsamości
              klienta.
            </li>
            <li>Sprawdzenie wszystkich warunków kredytowania.</li>
            <li>
              Sprawdzenie opinii klientów firmy pożyczkowej – wśród znajomych czy
              internautów.
            </li>
          </ul>
        </div>
        <div class="text-container">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            W sieci nie brakuje ofert szybkich pożyczek gotówkowych. Jednak zanim
            pożyczanie pieniędzy online dojdzie do skutku, powinniśmy dowiedzieć się
            czegoś więcej o firmie, z którą zamierzamy zawrzeć umowę. Warto uzyskać takie
            informacje jak:
          </p>
          <ul>
            <li>
              Jak nazywa się podmiot użyczający pieniędzy klientom i kto jest jego
              właścicielem?
            </li>
            <li>Gdzie znajduje się siedziba firmy?</li>
            <li>Od kiedy istnieje firma pożyczkowa na polskim rynku finansowym?</li>
            <li>
              Czy firma pożyczkowa wpisana jest do rejestru takich podmiotów prowadzonego
              przez Komisję Nadzoru Finansowego?
            </li>
          </ul>
          <p>
            Najlepiej, by firma pożyczająca pieniądze przez internet miała już wieloletnie
            doświadczenie rynkowe, znaną markę i wpis do Rejestru Instytucji Pożyczkowych.
            Wtedy mamy pewność, że podejmiemy współpracę z rzetelnie działającym
            pożyczkodawcą. Rejestr można sprawdzić online na stronie:
            <a
              target="_blank"
              href="https://www.knf.gov.pl/podmioty/rejestr_instytucji_pozyczkowych"
              rel="noopener"
              >https://www.knf.gov.pl/podmioty/rejestr_instytucji_pozyczkowych</a
            >.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Na ogół warunkiem udzielenia pożyczki online jest zweryfikowanie tożsamości
            potencjalnego klienta. Można to zrobić zwykle z wykorzystaniem prostego
            przelewu weryfikacyjnego. Problem w tym, że niektórzy nieuczciwi pożyczkodawcy
            potrafią naciągnąć na wysokie opłaty swoich klientów, bez gwarantowania
            wypłaty pieniędzy przez internet. Jeśli przelew weryfikacyjny nie jest
            symboliczny, np. na kwotę 1 gr lub 1 zł, to najlepiej zrezygnować z takiej
            oferty i znaleźć inną. Zdarza się, że firma żąda wysokiej wpłaty wstępnej,
            która jest bezzwrotna i jeśli pożyczka nie zostanie udzielona, to klienci
            stracą te pieniądze.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Nigdy nie powinniśmy podpisywać dokumentów, z treścią których nie zapoznaliśmy
            się wcześniej. Bezpieczne pożyczanie online również wymaga dokładnego
            przeanalizowania oferty. Nie można ograniczać się tylko do informacji, które
            podkreśla pożyczkodawca w swoich materiałach reklamowych.
          </p>
          <p>
            Starając się o pożyczenie pieniędzy w internecie, musimy znać koszty oferty
            oraz przeczytać umowę. Zapoznajmy się ze wszystkimi warunkami propozycji.
            Lepiej poświęcić na to swój czas, żeby później nie żałować podjęcia decyzji o
            współpracy z daną firmą pozabankową.
          </p>
          <p>
            W warunkach pożyczki przez internet mogą być ujęte pewne dodatkowe opłaty,
            zwiększające całkowite koszty kredytowania, m.in. opłata administracyjna,
            opłata za rozpatrzenie wniosku pożyczkowego czy obowiązkowe ubezpieczenie, z
            czego możemy nie zdawać sobie sprawy. Dobrym pomysłem na bezpieczne pożyczki
            przez internet jest sprawdzenie ich RRSO – Rocznej Rzeczywistej Stopy
            Oprocentowania oraz wyliczenie całkowitego kosztu kredytowania. Uczciwy
            pożyczkodawca powinien przekazać klientowi takie informacje, by ten mógł
            podjąć decyzję o pożyczce, będąc świadomym jej realnych kosztów.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            Zainteresowani pożyczaniem pieniędzy przez internet powinni też zapoznać się z
            opiniami o danej firmie w sieci. Być może wśród naszych znajomych ktoś
            korzystał z oferty pożyczkodawcy i potrafi odpowiedzieć, czy warto zawrzeć z
            nim umowę.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import LoanWidget from '@/components/LoanWidget.vue'
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'Zasady bezpiecznego pożyczania online',
          link: '#1',
        },
        {
          title: 'Weryfikacja firmy pożyczkowej',
          link: '#2',
        },
        {
          title: 'Horrendalnie wysoki przelew weryfikacyjny',
          link: '#3',
        },
        {
          title: 'Czytanie umów pożyczkowych',
          link: '#4',
        },
        {
          title: 'Opinie o pożyczkodawcy',
          link: '#5',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped></style>
