<template>
  <div class="row article non-landing">
    <h1 class="center-title">Renovering af hus og hjem under pandemien</h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            Igennem 2021 har de danske byggemarkeder haft supertravlt under den globale
            pandemi, de har skulle følge med de mange danskers lyst til at begynde at
            renovere hus og hjem under pandemien. Denne trend virker til at fortsætte ind
            i det nye år 2022, eftersom den globale pandemi har gjort at de fleste af os
            dansker er begyndt at arbejde hjemme fra, bruger vi flere timer i vores hjem
            end før. Siden vi er begyndt at bruge flere timer i hjemmet, er tiden fundet
            til at planlægge og udføre alt fra små til større renoveringsprojekter som at
            male stuen, til at skifte døre og vinduer.
          </p>
          <b-img-lazy
            width="700"
            height="466"
            class="article-img"
            src="/static/img/saverium_dk/article-imgs/renovating-under-pandemic.jpg"
            blank-src="/static/img/saverium_dk/article-imgs/renovating-under-pandemic-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            alt="Renovering af hus og hjem under pandemien"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul>
              <li>
                <a href="#travle-haandvaerker-og-lang-ventetid">
                  Travle håndværker og lang ventetid
                </a>
              </li>
              <li>
                <a href="#3-renoverings-raad-til-dig-som-skal-i-gang-">
                  3 renoverings råd til dig som skal i gang
                </a>
              </li>
              <li>
                <a
                  href="#saverium-hjaelper-med-at-finde-dit-nye-laan-til-dit-renoveringsprojekt-"
                >
                  Saverium hjælper med at finde dit nye lån, til dit renoveringsprojekt
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container">
          <h2 id="travle-haandvaerker-og-lang-ventetid">
            Travle håndværker og lang ventetid
          </h2>
          <p>
            Grundet danskernes store trang til at renovere, har det os betydet at der nu
            er mange travle håndværker og lang ventetid, hvis du skulle ønske at skaffe en
            håndværker. Udover travle håndværker, er priserne på byggematerialer og så
            steget. Indtil videre er der ikke noget der tyder på at priserne vil falde
            lige i øjeblikket. Så skulle du selv stå med en ide om at vil renovere
            indenfor den nærmeste fremtid, vil det være en god ide at komme i gang. Siden
            der er mange ting man skal overveje og tage stilling til når man skal til at
            renovere, har vi samlet tre gode råd til dig som vil til at renovere sit hjem.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3-renoverings-raad-til-dig-som-skal-i-gang-">
            3 renoverings råd til dig som skal i gang
          </h2>
          <p>
            Her har vi samlet tre gode og gratis råd, som du kan bruge hvis du skal at
            renovere dit hus og hjem.
          </p>
          <p><strong>1. Koordinere din renovering</strong>:</p>
          <p>
            Der er mange forskellige ting som skal koordineres når du skal renoveres, så
            start i fornuftig tid og skab et overblik over hvilke materialer du skal
            bruge, skal der håndværker til at udføre noget af arbejdet eller kan du nøjes
            med hjælp fra familie samt venner? Det kan eventuelt ske du skal leje udstyr
            til renoveringen, så plan i god tid hvad der skal ske.
          </p>
          <p><strong>2. Skab overblik over dine renoveringsudgifter</strong></p>
          <p>
            Efter du har koordineret hvilke byggematerialer osv. du skal bruge, kan du
            lave en budgetoversigt. Notere ned alle de mulige udgifter der vil komme i
            løbet af renovering af dit kommende projekt. På den måde kan du vurdere hvad
            der kan købes nu og her uden du skulle overskride dit budget for projektet.
          </p>
          <p><strong>3. Bæredygtig renovering</strong></p>
          <p>
            Står du og skal i gang med en renovering, er det vigtigt at tænke indenfor
            bæredygtighed. Bæredygtig renovering holder i de fleste tilfælde sin værdi
            bedre, og med tanken på miljøet vil det være en fornuftig vej at gå med din
            renovering. Det skulle være lige til at finde et brand der producerer døre,
            vinduer eller tag med tanken på miljøet.
          </p>
        </div>
        <div class="text-container">
          <h2
            id="saverium-hjaelper-med-at-finde-dit-nye-laan-til-dit-renoveringsprojekt-"
          >
            Saverium hjælper med at finde dit nye lån, til dit renoveringsprojekt
          </h2>
          <p>
            Saverium.dk er her for at hjælpe folk med at sammenligne lånemarked for at
            kunne give alle en chance for at finde det ønskede lånetilbud. Vi sammenligner
            lånetilbud fra flere velkendte banker og låneudbydere, med kun én enkelt
            <a
              href="https://www.saverium.dk/ansoegning"
              rel="noopener noreferrer"
              target="_blank"
              >ansøgning</a
            >. Du kan søge om et lån fra 3000 - 500.000 kroner alt efter dine egne behov
            med en låneperiode på 1-15 år.
          </p>
          <p></p>
          <p>2022-01-14/Søren</p>
        </div>
      </div>
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
  </div>
</template>
<script>
import LoanWidget from '@/components/saverium_dk/seo_components/LoanWidget.vue'
export default {
  components: {
    LoanWidget,
  },
}
</script>
