import offerActions from '@/store/actions/offerActions'

const state = {
  offers: [],
  acceptedOffers: [],
  verificationCode: '',
  success_status: '',
  showall: false,
}

const getters = {
  getSmsState: state => state.sms_state,
  getSuccessStatus: state => state.success_status,
}

const actions = offerActions

const mutations = {
  setOffers(state, offers) {
    state.offers = offers
  },
  setShowLoginForm(state, value) {
    state.showLoginForm = value
  },
  addAcceptOffer(state, offer) {
    state.acceptedOffers.push(offer)
  },
  verifySMS(state) {
    state.sms_verification = true
  },
  showSuccess(state) {
    state.success_status = 'success'
  },
  showError(state) {
    state.success_status = 'error'
  },
  setShowall(state, value) {
    state.showall = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
