<template>
  <div>
    <DanishConsent v-if="brand === 'saverium_dk'" :consentUrl="consentUrl" />
    <PolishConsent v-if="brand === 'saverium_pl'" :consentUrl="consentUrl" />
  </div>
</template>

<script>
import DanishConsent from '@/components/cookie_popup_contents/ConsentDanish'
import PolishConsent from '@/components/cookie_popup_contents/ConsentPolish'
import settings from '@/settings/settings'

export default {
  components: { DanishConsent, PolishConsent },
  props: { consentUrl: String },
  computed: {
    brand() {
      return settings.BRAND
    },
  },
}
</script>
