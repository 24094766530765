<template>
  <div class="main">
    <div class="container col-lg-8">
      <div class="mt-5 mb-5">
        <div class="alert alert-danger" role="alert" v-if="hasErrors">
          {{ $t('check_the_fields_marked_in_red') }}
        </div>
        <h3 class="mb-5">
          {{ $t('complete_the_loan_application_absolutely_for_free') }}
        </h3>
        <step-one :full-view="true" step="1" />
        <step-two :full-view="true" step="2" />
        <step-three :full-view="true" step="3" />
        <step-four :full-view="true" step="4" />
        <step-five :full-view="true" step="5" />
        <step-six :full-view="true" step="6" />
      </div>
    </div>
  </div>
</template>

<script>
import StepOne from '@/components/saverium_pl/steps/StepOne'
import StepTwo from '@/components/saverium_pl/steps/StepTwo'
import StepThree from '@/components/saverium_pl/steps/StepThree'
import StepFour from '@/components/saverium_pl/steps/StepFour'
import StepFive from '@/components/saverium_pl/steps/StepFive'
import StepSix from '@/components/saverium_pl/steps/StepSix'

export default {
  computed: {
    hasErrors() {
      return Object.keys(this.$store.state.application.errors).length > 0
    },
    stepErrors() {
      return this.$store.state.application.stepErrors
    },
  },
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepSix,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
