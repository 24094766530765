<template>
  <div class="row article">
    <h1 class="center-title">
      BIK, BIG, KRD, ERIF, RDN - co znaczą te skróty? Jak sprawdzić rejestry dłużników?
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >Wbrew temu, że z czasem sytuacja materialna Polaków polepsza się, coraz
              więcej naszych rodaków ma problemy ze spłatą swoich zobowiązań finansowych.
              Problemy z regulowaniem długów to pierwszy krok do trafiania do bazy
              dłużników. W Polsce funkcjonuje kilka tego typu instytucji. Podpowiadamy,
              czym dokładnie, za co można do nich trafić, oraz jak sprawdzić czy dana
              osoba figuruje w bazach BIK, BIG, KRD lub innych.</i
            >
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/what-are-these-tiny.jpeg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/what-are-these.jpeg"
            alt="BIK, BIG, KRD, ERIF, RDN - co znaczą te skróty? Jak sprawdzić rejestry dłużników?"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container mt-5">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            Baza dłużników jest instytucją, która ma na celu sprawdzenie wypłacalności i
            historii kredytowej potencjalnego klienta lub kontrahenta. Z formalnego punktu
            widzenia istnieją dwa rodzaje takich instytucji. Pierwsza funkcjonuje w ramach
            Krajowego Rejestru Sądowego i prowadzona jest przez właściwy sąd rejonowy.
            Mowa tu przede wszystkim o RDN, czyli rejestrze dłużników niewypłacalnych.
          </p>
          <p>
            Drugim rodzajem baz dłużników są tak zwane biura informacji gospodarczej.
            Trafiają do nich informacje od wierzycieli, którymi dzielą się oni między
            innymi z instytucjami finansowymi – bankami lub firmami pożyczkowymi.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            BIK, czyli Biuro Informacji Kredytowej S.A. to rejestr dłużników, który został
            założony przez Związek Banków Polskich. Zgodnie z art. 105 Ustawy i prawie
            bankowym gromadzi on i udostępnia informacje, które mogą
          </p>
          <ul>
            <li>
              pomóc ustalić
              <b-link :to="$root.rn.CreditWorthiness">zdolność kredytową</b-link> klienta
            </li>
            <li>
              być niezbędne dla udzielenia kredytów, pożyczek, poręczeń oraz innych
              produktów finansowych
            </li>
            <li>być niezbędne do wykonywania czynności bankowych</li>
          </ul>
          <p>
            BIK może udzielać informacji podlegających tajemnicy bankowej. Korzystają z
            niego nie tylko banki, ale także finansowe instytucje pozabankowe.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            BIG, czyli biuro informacji gospodarczej to ogólne określenie instytucji,
            które gromadzą informacje o nierzetelnych płatnikach. W naszym kraju nie ma
            jednego centralnego BIG-u. Funkcjonuje za to kilka odrębnych instytucji, które
            prowadzą swoje własne listy. Warto zaznaczyć, że pomiędzy poszczególnymi
            BIG-ami nie ma współpracy a one same są w stosunku do siebie konkurencyjne.
          </p>
          <h3>BIK a BIG - różnice</h3>
          <p>
            Wiele osób zastanawia się jakie są różnice między BIK a BIG. W przypadku BIG
            mamy do czynienia z biurem informacji gospodarczej, BIK to natomiast biuro
            informacji kredytowej. Działalność obydwu tych instytucji oparta jest na
            ściśle regulowanych przepisach konkretnych ustaw. Do BIK–u jednak trafiają
            informacje przede wszystkim z instytucji finansowych, takich jak banki i
            instytucje pozabankowe. Znajdują się tam:
          </p>
          <ul>
            <li>
              informacje o wszelkich zaciągniętych przez nas kredytach, pożyczkach,
              limitach na kartach kredytowych, zakupach na raty oraz poręczonych kredytach
            </li>
            <li>dane wszystkich aktywnych zobowiązań kredytowych</li>
            <li>
              dane wszystkich zamkniętych zobowiązań kredytowych, na których istniało
              opóźnienie w spłacie powyżej 60 dni
            </li>
            <li>
              spłacane terminowo zamknięte zobowiązania kredytowe, na których
              przetwarzanie wyraziliśmy zgodę
            </li>
          </ul>
          <p>
            Do BIG–u informacje mogą przekazywać wszystkie podmioty gospodarcze. W tym
            także operatorzy komórkowi, gminy, dostawcy energii, wierzyciele wtórni etc.
            Znajdziemy więc w nim informacje o:
          </p>
          <ul>
            <li>niespłaconych grzywnach</li>
            <li>zaległych alimentach</li>
            <li>opłatach sądowych</li>
            <li>
              należnościach niespłacanych w okresie powyżej 30 dni, jeżeli ich kwoty
              przekraczają 200 złotych (w przypadku osób fizycznych) lub 500 złotych (w
              przypadku firm)
            </li>
          </ul>
        </div>
        <div class="text-container mt-5">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            KRD, czyli Krajowy Rejestr Długów to jedno z największych i jednocześnie
            pierwsze w naszym kraju Biuro Informacji Gospodarczej. Powstało w 2003 roku.
            Aby trafić na listę w KRD, musimy posiadać zobowiązanie wyższe niż 200 złotych
            (500 złotych w przypadku firm). Musi także upłynąć 30 dni od daty
            wymagalności.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            ENRIF jest jednym z Biur Informacji Gospodarczych, które funkcjonują na
            teranie naszego kraju. Udostępnia ona zarówno pozytywne, jak i negatywne
            informacje na temat znajdujących się w jej bazach podmiotów. Z usług tego
            biura korzystają zarówno klienci indywidualni, jak i różnego rodzaju firmy
            oraz instytucje.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            RDN to skrót od Rejestru Dłużników Niewypłacalnych. Instytucja ta wchodziła w
            skład Krajowego Rejestru Sądowego. Została zlikwidowana w 2018 roku, a jego
            bezpośredni następca nigdy nie został powołany.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="7">{{ contents[6].title }}</h2>
          <p>
            Listy dłużników warto raz na jakiś czas sprawdzać ze względu na to, że pewnych
            drobnych faktów z własnej historii kredytowej po prostu nie musimy być zawsze
            świadomi. Czasami drobne opóźnienie w spłacie zaciągniętej raty lub błahe z
            pozoru, nieporozumienia mogą sprawić, że otrzymanie kredytu, czy
            <b-link :to="$root.rn.MainView">pożyczki online</b-link> stanie się dla nas
            niemożliwe.
          </p>
          <p>
            Właśnie dlatego raz na jakiś czas warto przejrzeć bazy dłużników i sprawdzić,
            czy widniejemy na liście którejś z nich. Nie jest to trudne, a w przypadku,
            kiedy potrzebny jest kredyt, czy
            <b-link :to="$root.rn.OnlineNonbankLoans">pożyczka pozabankowa</b-link> –
            szczególnie ta szybka – może okazać się zbawienne.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="8">{{ contents[7].title }}</h2>
          <p>
            Sprawdzenie tego, czy figurujemy w którejś z baz dłużników nie jest trudne.
            Wystarczy wejść na stronę bazy, która nas interesuje (na przykład BIK lub KRD)
            i zamówić stosowny raport. Zazwyczaj wiąże się to z niewielką opłatą, która
            wynosi od kilku do kilkudziesięciu złotych. Niektóre bazy proponują bezpłatny
            raport, jednak w tej kwestii obowiązują pewne ograniczenia. Za darmo bazy
            można sprawdzić mniej więcej raz na pół roku.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="9">{{ contents[8].title }}</h2>
          <p>
            Sprawdzenie swojej potencjalnej obecności w KRD jest bardzo proste. Wystarczy
            wejść na stronę internetową lub zainstalować na swoim telefonie stosowną
            aplikację. Następnie zakładamy tam konto i na bazie swojego numeru PESEL
            sprawdzamy, czy znajdujemy się na liście.
          </p>
          <p>
            Sprawdzenie baz BIG najlepiej rozpocząć od rejestracji konta na stronie
            big.pl. Tam, za odpowiednią opłatą można sprawdzić zarówno siebie, jak i
            swojego potencjalnego klienta.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="10">{{ contents[9].title }}</h2>
          <p>
            Do baz dłużników trafia się przede wszystkim za niespłacone długi lub
            trudności z płatnością na czas różnego rodzaju rat, faktur lub rachunków. W
            zależności od tego z jaką bazą mamy do czynienia możemy trafić do niej za:
          </p>
          <ul>
            <li>niespłacanie pożyczek</li>
            <li>nieopłacanie rat kredytów</li>
            <li>opóźnienia w ratach kredytów i pożyczek</li>
            <li>nieopłacone faktury</li>
            <li>opóźnienia w opłatach za dostawy energii</li>
            <li>opóźnienia w opłatach za telefony komórkowe i internet</li>
            <li>niespłacone grzywny</li>
            <li>niespłacone opłaty sądowe</li>
            <li>wszelkie nierozliczone zobowiązania u podmiotów gospodarczych</li>
          </ul>
          <p>
            Dodatkowo, aby pojawić się w bazach BIG–u nasz dług musi przekraczać dwieście
            złotych, o ile jesteśmy osobą fizyczną lub pięćset złotych, jeżeli prowadzimy
            działalność gospodarczą.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="11">{{ contents[10].title }}</h2>
          <p>
            Wiele osób, które mają problemy ze spłaceniem swoich zobowiązań zastanawia
            się, kiedy dokładnie ich dane trafią na listy dłużników. Nie ma jednej
            uniwersalnej odpowiedzi na to pytanie, ponieważ wiele zależy od wierzyciela.
            Ustawodawca zadbał jednak o to, aby istniały dolne granice czasowe. Do biura
            informacji kredytowej (BIK) trafiają informacje opóźnieniach w spłacie, które
            wynoszą powyżej 60 dni. Warto także zaznaczyć, że wierzyciel musi uprzedzić
            dłużnika o zamiarze wpisania go do BIK przynajmniej na dwa tygodnie przed
            dokonaniem tego. Do biur informacji gospodarczej trafiają z kolei opóźnienia
            nie krótsze niż 30 dni.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="12">{{ contents[11].title }}</h2>
          <p>
            W Polsce funkcjonuje kilka baz dłużników. Z punktu widzenia podmiotów
            bankowych oraz firm pożyczkowych najważniejszy jest BIK, czyli Biuro
            Informacji Kredytowej. Warto jednak pamiętać, że informacje o naszym
            zadłużeniu mogą trafić także do Biur Informacji Gospodarczej, takich jak KRD,
            czy ENRIF.
          </p>
          <p>
            Nawet jeżeli uważasz, że z Twoją zdolnością kredytową wszystko jest w
            porządku, przynajmniej raz w roku warto zamówić stosowny raport, który pozwoli
            upewnić się, czy przypadkiem nie widniejemy, w którejś z baz dłużników.
            Czasami z powodu banalnych kwestii, takich jak zapomniany rachunek za telefon,
            czy niedopłacona rata za sprzęt AGD, możemy mieć trudności z zaciągnięciem
            <b-link :to="$root.rn.CashLoan">kredytu gotówkowego</b-link> lub
            <b-link :to="$root.rn.InstantInternetLoans">pożyczki od ręki</b-link>.
          </p>
          <p>
            W niektórych bazach sprawdzenie swojej potencjalnej obecności jest bardzo
            proste a w niektórych przypadkach również darmowe. Bazy dłużników to świetny
            sposób na weryfikację nie tylko kredytobiorców, ale także kontrahentów.
            Znalezienie na ich liście potencjalnego współpracownika może pomóc nam uniknąć
            wielu nieopłaconych faktur.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'Czym są bazy dłużników?',
          link: '#1',
        },
        {
          title: 'Co to jest BIK?',
          link: '#2',
        },
        {
          title: 'Co to jest BIG?',
          link: '#3',
        },
        {
          title: 'Co to jest KRD?',
          link: '#4',
        },
        {
          title: 'Co to jest ERIF?',
          link: '#5',
        },
        {
          title: 'Co to jest RDN?',
          link: '#6',
        },
        {
          title: 'Dlaczego warto sprawdzać listy dłużników?',
          link: '#7',
        },
        {
          title: 'Jak sprawdzić, czy jestem w bazie dłużników?',
          link: '#8',
        },
        {
          title: 'Jak sprawdzić, czy jestem w BIK, BIG lub KRD?',
          link: '#9',
        },
        {
          title: 'Za co można trafić do bazy dłużników?',
          link: '#10',
        },
        {
          title: 'Kiedy dane trafiają do listy dłużników?',
          link: '#11',
        },
        {
          title: 'Bazy dłużników - podsumowanie',
          link: '#12',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped></style>
