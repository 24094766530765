<template>
  <div class="row article">
    <h1 class="center-title">
      Kredyt lub pożyczka na wesele – sposób na sfinansowanie przyjęcia
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i>
              Serwis Weeding.pl w 2019 roku przeprowadził badania mające na celu
              oszacowanie średnich kosztów sfinansowania przyjęcia weselnego. Okazało się,
              że na zorganizowanie ślubu i wesela zdecydowana większość Polaków wydaje od
              41 do 60 tys. zł. Co trzecia para młoda mieści się w budżecie od 21 do 40
              tys. zł. Skąd masz wziąć pieniądze na to, by sfinansować wesele marzeń?
            </i>
          </p>

          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/loan-for-wedding-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/loan-for-wedding.jpg"
            alt="Kredyt lub pożyczka na wesele – sposób na sfinansowanie przyjęcia"
          />

          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            Zdajemy sobie sprawę z tego, jak dużym wydatkiem jest zorganizowanie ślubu i
            przyjęcia weselnego marzeń. Narzeczeńska para często odkłada na to pieniądze
            latami, a także posiłkuje się środkami od rodziców. Tradycyjnie to właśnie
            rodzice młodych finansują koszty ślubno-weselne. Jednak ten zwyczaj powoli
            zanika, a i same młode pary wolą samodzielnie sfinansować imprezę, aby mieć
            większy wpływ na to, kogo zaproszą i jak będzie wyglądała cała uroczystość.
          </p>
          <p>
            Czasem oszczędności okazują się niewystarczające, by pokryć liczne wydatki,
            które tym bardziej piętrzą się, im bliżej do dnia ślubu. Opłaty za obrączki,
            ubrania dla państwa młodych, zespół lub DJ-a, salę weselną wraz z jedzeniem,
            kwiaty, samochód do ślubu czy koszty samej uroczystości w kościele bądź w
            urzędzie stanu cywilnego mogą być naprawdę bardzo wysokie. Z pomocą przy
            pokryciu takich wydatków przychodzą produkty kredytowe i pożyczkowe. Można
            zorganizować wesele na kredyt czy choćby zaciągnąć pożyczkę na ślub i wesele,
            która uzupełni posiadane środki na ten cel.
          </p>
          <p>
            Musisz sam sobie odpowiedzieć na pytanie, czy warto brać kredyt na wesele.
            Jeśli jesteś przekonany co do tego, że będziesz mógł swobodnie go spłacić,
            chociażby z tradycyjnego „czepka” weselnego, to czemu nie. Z jednej strony
            zobowiązanie to naraża Cię na pewne dodatkowe koszty, które związane są z
            koniecznością zapłaty odsetek od kredytu ślubnego czy prowizji za
            przystąpienie do niego, ale z drugiej – ślub bierzesz najczęściej tylko raz i
            warto, aby ten dzień wyglądał dokładnie tak, jak tego chcesz.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Możesz wybrać bank lub firmę pozabankową, by postarać się o zewnętrzne
            finansowanie kosztów ślubno-weselnych. Jaki kredyt na wesele możesz zaciągnąć?
            Może to być np. zobowiązanie na wesele i obrączki w postaci
            <b-link :to="$root.rn.CashLoan">kredytu gotówkowego</b-link>, ponieważ w takim
            przypadku nie musisz we wniosku składanym w banku deklarować, w jaki sposób
            spożytkujesz pieniądze. Możesz więc dowolnie rozdysponować środki z kredytu na
            wesele.
          </p>
          <p>
            Jak się starać o zobowiązanie kredytowe w banku? Złóż wniosek wraz z
            wymaganymi załącznikami, m.in. zaświadczeniem o wysokości uzyskiwanych
            dochodów czy ich źródle. Jeśli nie masz czasu ani ochoty gromadzić tylu
            dokumentów, skorzystaj z
            <b-link :to="$root.rn.OnlineNonbankLoans"
              >szybkiej pożyczki pozabankowej</b-link
            >, którą możesz otrzymać w krótkim czasie, z wypłatą bezpośrednio na konto
            bankowe. Kredyt na wesele w 2021 roku wybierz, jeśli masz czas ubiegać się o
            niego w banku lub Twoja instytucja jest w stanie zaoferować Ci uproszczoną
            formę kredytowania.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Przy ubieganiu się o
            <b-link :to="$root.rn.LoansToAccount"
              >pożyczki przez internet na konto bankowe</b-link
            >, a zwykle właśnie taką formę będzie miała pożyczka na ślub i wesele, musisz
            właściwie wypełnić tylko wniosek, podając podstawowe dane na swój temat.
            Niepotrzebne jest zaświadczenie o dochodach, ponieważ firmy udzielające
            pożyczek online bazują na Twoim oświadczeniu – deklaracji dotyczącej tego, ile
            zarabiasz i z jakiego źródła uzyskujesz środki. Możesz ubiegać się o pożyczkę
            na całe wesele, nawet w dosyć wysokiej kwocie, w trybie online, czyli bez
            wychodzenia z domu.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Z pożyczek pozabankowych skorzystać możesz, jeśli spełniasz warunki, jakie
            firma stawia swoim klientom. Wśród nich będą:
          </p>
          <ul>
            <li>wiek co najmniej 18 lat,</li>
            <li>pełna zdolność do czynności prawnych</li>
            <li>polskie obywatelstwo,</li>
            <li>adres zamieszkania na terenie Polski,</li>
            <li>konto bankowe zarejestrowane na swoje dane,</li>
            <li>
              brak złych wpisów w BIK-u czy w bazach danych dłużników, jak BIG InfoMonitor
              czy KRD.
            </li>
          </ul>
          <p>
            Warunkiem skorzystania z pożyczki na całe wesele będzie dopełnienie
            formalności, zwykle obejmujących tylko złożenie wniosku elektronicznego oraz
            weryfikacja tożsamości.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            Jeśli martwisz się o swoje bezpieczeństwo przy korzystaniu z oferty
            <b-link :to="$root.rn.LoanInstallment">pożyczek online na raty</b-link> lub
            pożyczek ze spłatą jednorazową, to wiedz, że większość firm skrupulatnie
            podchodzi do kwestii weryfikacji tożsamości klientów. Stosowane są przelewy na
            symboliczną kwotę 1 zł lub 1 gr oraz weryfikacja za pomocą aplikacji, przez
            którą musisz zalogować się do bankowości elektronicznej. Nie ma zatem
            możliwości, by ktoś się pod Ciebie podszywał i zaciągnął na Twoje dane
            pożyczkę, narażając Cię na straty.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            Pożyczkę na wesele możesz zaciągnąć za pośrednictwem platformy Saverium.
            Polega to na tym, że składasz jeden prosty wniosek, w którym podajesz pożądaną
            kwotę, cel oraz okres kredytowania, jak i swoje dane. W krótkim czasie
            uzyskasz oferty od legalnie działających firm pożyczkowych i banków, a w
            Twojej gestii pozostanie wybór najbardziej atrakcyjnej pożyczki na ślub i
            wesele. Przedstawione propozycje będą zindywidualizowane i łatwo Ci będzie
            porównać je ze sobą. Potrzebujesz pieniądze na wesele w 2021 roku? Skorzystaj
            z oferty Saverium i weź pożyczkę na 30 dni,
            <b-link :to="$root.rn.LoanForTwoMonths">pożyczkę na 60 dni</b-link> lub
            <b-link :to="$root.rn.OnlineLongtermLoans">pożyczkę długoterminową</b-link>.
            Za pośrednictwem naszej platformy, możesz ubiegać się o pożyczkę od 100 do
            nawet 200 000 zł.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'Pożyczki na wesele – czy warto?',
          link: '#1',
        },
        {
          title: 'Pożyczka czy kredyt na wesele?',
          link: '#2',
        },
        {
          title: 'Jak ubiegać się o pożyczkę na wesele?',
          link: '#3',
        },
        {
          title: 'Kto może skorzystać z pożyczki na wesele?',
          link: '#4',
        },
        {
          title: 'Czy pożyczki na wesele są bezpieczne?',
          link: '#5',
        },
        {
          title: 'Weź pożyczkę na wesele z Saverium',
          link: '#6',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped></style>
