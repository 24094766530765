<template>
  <b-container fluid class="application-container">
    <div class="loan-header">{{ $t('applications') }}</div>
    <b-table
      class="applications-table"
      hover
      :items="applications"
      :fields="fields"
      @row-clicked="setCurrentApplication"
      :tbody-tr-class="rowClass"
    ></b-table>
  </b-container>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  computed: {
    fields() {
      return [
        {
          key: 'id',
          label: this.$t('application'),
          formatter: value => {
            return '#' + value
          },
        },
        {
          key: 'loan_amount',
          label: this.$t('loan_amount'),
          formatter: value => {
            return this.$n(value, 'currency')
          },
        },
        {
          key: 'date',
          label: this.$t('date_2'),
          formatter: value => {
            return DateTime.fromISO(value).toFormat('dd.MM.yyyy')
          },
        },
        {
          key: 'status',
          label: this.$t('status'),
          formatter: value => {
            switch (value) {
              case 1:
                return 'Luonnos'
              case 2:
                return 'Käsittelyssä'
              case 3:
                return 'Odottaa'
              case 4:
                return 'Peruttu'
              case 5:
                return 'Käsitelty'
            }
          },
        },
      ]
    },
    applications() {
      return this.$store.state.offers.applications
    },
  },
  methods: {
    setCurrentApplication(application) {
      this.$store.commit('setCurrentApplication', application)
    },
    rowClass(item) {
      if (item.id === this.$store.state.offers.currentApplicationId) {
        return 'table-primary'
      }
      return ''
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.applications-table td {
  cursor: pointer;
}
.applications-table {
  font-size: 0.9rem;
}
</style>
