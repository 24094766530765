import settings from '@/settings/settings'

const BRAND_SLUG = settings.BRAND_SLUG
const BRAND_NAME = settings.BRAND_NAME
function concatAddress(application) {
  const address = [
    application.street,
    application.houseNumber,
    application.apartmentNumber,
  ]
  return address.join(', ')
}
function concatRegisteredAddress(application) {
  const registeredAddress = [
    application.registeredStreet,
    application.registeredHouseNumber,
    application.registeredApartmentNumber,
  ]
  return registeredAddress.join(', ')
}
// Compose body for the BNP extra info
const composeExtraDataBody = (application, sessionKey) => {
  const body = {
    brand_slug: BRAND_SLUG,
    session_key: sessionKey,
    active_non_bank_obligations: application.extraInfo.active_non_bank_obligations,
    document_expiration_date: application.extraInfo.document_expiration_date,
    id_type: application.extraInfo.id_type,
    mother_maiden_name: application.extraInfo.mother_maiden_name,
    total_500_plus_amount: application.extraInfo.total_500_plus_amount,
    house_status: application.extraInfo.house_status,
    house_type: application.extraInfo.house_type,
    employment_sector: application.extraInfo.employment_sector,
    profession_code: application.extraInfo.profession_code,
    'Dziecko 1 - data urodzenia': application.extraInfo.Dziecko1,
    'Dziecko 2 - data urodzenia': application.extraInfo.Dziecko2,
    'Dziecko 3 - data urodzenia': application.extraInfo.Dziecko3,
    'Dziecko 4 - data urodzenia': application.extraInfo.Dziecko4,
    'Dziecko 5 - data urodzenia': application.extraInfo.Dziecko5,
    'Dziecko 6 - data urodzenia': application.extraInfo.Dziecko6,
    'Dziecko 7 - data urodzenia': application.extraInfo.Dziecko7,
    'Dziecko 8 - data urodzenia': application.extraInfo.Dziecko8,
  }
  return body
}

// Compose loan application body for API request
const composeApplicationBody = (application, sessionKey) => {
  const body = {
    brand_name: BRAND_NAME,
    brand_slug: BRAND_SLUG,
    session_key: sessionKey,
    amount: application.amount,
    time_term: application.repayment_time,
    loan_purpose: application.loanPurpose,
    has_summer_house: application.hasSummerHouse,
    has_registered_address: application.hasRegisteredAddress,
    applicants: [
      {
        first_name: application.firstName,
        last_name: application.lastName,
        ssn: application.ssn,
        id_number: application.idnumber,
        email: application.email,
        phone: application.phone,
        marital_status: application.civilstatus,

        monthly_gross_income: application.monthlySalary,
        monthly_net_income: application.monthlyNetIncome,
        monthly_other: application.otherIncome,
        monthly_other_source: application.otherIncomeReason,

        education: application.education,
        dwelling: application.dwelling,
        dwelling_type: application.dwellingType,
        dwelling_expenses: application.costOfLiving,
        dwelling_date: application.dwellingDate,
        dependants: application.dependants,
        co_living: application.numberOfPeople,

        other_expenses: application.otherMonthlyExpenses,
        region: application.region,

        existing_loans: [], // empty list to be populated later
        address: {
          country_code: 'PL', // fetch me from settings
          street: concatAddress(application),
          city: application.city,
          zip_code: application.zipCode,
          region: application.region,
          phone: application.phone,
        },
        registered_address: {
          country_code: 'PL',
          street: concatRegisteredAddress(application),
          city: application.registeredCity,
          zip_code: application.registeredZipCode,
          region: application.registeredRegion,
          phone: application.phone,
        },
        bank_account: {
          iban: application.bankAccount,
          account_type: application.accountType,
          account_holder: application.accountHolder,
          bank_name: application.bankName,
          credit_cards: [], // fill later
        },
        employments: [
          {
            employer: application.employer,
            business_id: application.businessId,
            start: application.employedSince,
            end: application.employedUntil,
            employment_type: application.occupation,
            job_title: application.profession,
            phone: application.workPhone,
            street: application.workStreetAddress,
            city: application.workCity,
            zip_code: application.workZipCode,
          },
        ],
        in_political_position: !application.in_political_position,
      },
    ],
    tos_accepted: application.tos,
    personal_data_policy: application.personalDataPolicy,
    consents: application.consents,
    marketing_consents: application.marketingConsents,
  }

  // Add credit cards
  Object.entries(application.creditCards).forEach(([key, value]) => {
    if (value > 0) {
      body['applicants'][0]['bank_account']['credit_cards'].push({
        issuer: value,
      })
    } else if (value === false) {
      const cardindex =
        body['applicants'][0]['bank_account']['credit_cards'].indexOf(value)
      if (cardindex > -1) {
        body['applicants'][0]['bank_account']['credit_cards'].splice(cardindex, key)
      }
      return body['applicants'][0]['bank_account']['credit_cards']
    }
  })

  Object.entries(application.existingLoans).forEach(([key, item]) => {
    if (item.toBePaid) {
      body['applicants'][0]['existing_loans'].push({
        type: item.type,
        amount: item.toBePaid,
        payment: item.monthlyPayment,
        consider_consolidation: item.considerConsolidation,
      })
    }
  })
  return body
}

export default {
  composeApplicationBody,
  composeExtraDataBody,
}
