<template>
  <div>
    <div class="article">
      <h1 class="center-title">Kviklån</h1>
      <div class="top-text text-container">
        <p>
          Vi hjælper dig med at finde det bedste og billigste kviklån. Med blot én kort
          online ansøgning, kan vi, gratis, uforpligtende og på få minutter, indhente
          tilbud på kviklån uden sikkerhed til dig fra mange danske og internationale
          banker og långivere. Vi forbereder et overblik over dine tilbud, så du kan
          sammenligne og vælge det tilbud der passer dig bedst, uanset om du prioriterer
          hurtig udbetaling eller et billigt kviklån med en lav rente.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li><a href="#hvad-er-et-kviklaan">Hvad er et kviklån</a></li>
                <li><a href="#find-de-billige-kviklaan">Find de billige kviklån</a></li>
                <li>
                  <a href="#vaelge-det-bedste-kviklaan">Vælge det bedste kviklån</a>
                </li>
                <li>
                  <a href="#oversigt-over-kviklaan-i-danmark"
                    >Oversigt over kviklån i Danmark</a
                  >
                </li>
                <li>
                  <a href="#hvad-karakteriserer-et-kviklaan"
                    >Hvad karakteriserer et kviklån</a
                  >
                </li>
                <li>
                  <a href="#kreditvurdering-ved-kviklaan">Kreditvurdering ved kviklån</a>
                </li>
                <li><a href="#stoerrelsen-paa-laanet">Størrelsen på lånet</a></li>
                <li><a href="#historien-om-kviklaanene">Historien om kviklånene</a></li>
                <li>
                  <a href="#quicklaan-er-en-fordanskning">Quicklån er en fordanskning</a>
                </li>
                <li><a href="#skal-vi-hjaelpe-dig">Skal vi hjælpe dig?</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="hvad-er-et-kviklaan">Hvad er et kviklån</h2>
          <p>
            Hvis du er i tvivl om, hvad et kviklån er, så har vi her på siden lavet en
            meget grundig gennemgang af temaet. Det dækker både over hvordan du finder de
            bedste og billigste kviklån, hvad der karakteriserer denne låntype, hvilket
            krav du bliver mødt med, hvis du ønsker at opatage et kviklån, samt et
            overblik over historien om kviklån i Danmark.
          </p>
          <p>
            Hvis du ønsker at vide hvad et kviklån er, så skal du blot læse videre her på
            siden, så vil du med stor sandsynlighed få svar på alle dine spørgsmål. Hvis
            du er mere interesseret i at finde de bedste og billigste kviklån, i stedet
            for at få svar på hvad et kviklån er, så kan vi også hjælpe dig. Du kan blot
            benytte vores ansøgningsformular her på siden, så hjælper vi dig med at finde
            et godt kviklån til dig.
          </p>
          <h3>Med og uden NemID / MitID</h3>
          <p>
            Når du optager et kviklån, kommer du ikke uden om at bruge NemID eller MitID.
            Kviklån uden NemiD eller MitiD er i praksis ikke muligt i Danmark. I
            princippet kan du godt verificere din identitet og indlevere alle de
            dokumenter og den data, som långiveren behøver uden NemID eller MitID, men
            rent praktisk vil det tage meget lang tid, og der er derfor i dag ikke nogle
            steder, hvor du kan få et kviklån uden NemID eller MitID.
          </p>
          <p>
            I dag er det i praksis kun muligt at få et
            <a
              href="https://saverium.dk/laan-penge/privatlaan/laan-med-mitid"
              rel="noopener noreferrer"
              target="_blank"
              >lån med NemID eller MitID</a
            >. Hvis du ikke har NemID eller MitID, eller det er blevet spærret/lukket,
            skal du kontakte din bank, som kan hjælpe dig med at genåbne det for dig. Det
            er ikke kun i forhold til kviklån, at du skal benytte NemID eller MitID.
            Uanset hvordan du har behov for at
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >, kommer du sjældent uden om NemID og MitID.
          </p>
        </div>

        <div class="text-container">
          <h2 id="find-de-billige-kviklaan">Find de billige kviklån</h2>
          <p>
            Har du et ønske om at optage et kviklån nu, er mulighederne mange. Derfor kan
            det virke som en uoverskuelig proces at sikre, at man ikke kun optager et
            hurtigt, men også et billigt kviklån. Heldigvis har vi gjort det let at
            indhente mange tilbud på samme tid, så du kan finde det billigste kviklån. Du
            udfylder blot vores ansøgningsformular her på siden, hvorefter du modtager
            tilbud fra en række forskellige
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >lånevirksomheder</a
            >.
          </p>
          <p>
            Det er ikke blot en nem og enkel måde at indhente tilbud på billige kviklån.
            Det medfører ligeledes, at sammenligningen af de modtagne
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >lånetilbud</a
            >
            bliver betydeligt nemmere, hvilket hjælper dig med at skelne de dyre fra de
            billige kviklån.
          </p>
          <p>
            Hele processen foregår naturligvis på nettet, hvorfor du hverken skal deltage
            i fysiske møder, telefonopkald eller lignende. Du udfylder både ansøgning samt
            modtager og underskriver lånetilbud på nettet. Ydermere modtager du svar på
            din indsendte ansøgning med det samme.
          </p>
          <p>
            Baseret på den udfyldte ansøgningsformular, fremsender vores mange
            samarbejdspartnere et personligt og skræddersyet tilbud til dig. Slutteligt
            bør det også understreges, at du ikke er forpligtet til at acceptere ét af de
            modtagne tilbud. Er der ikke noget, som du synes lyder som et billigt kviklån,
            så kan du frit frasige dig samtlige tilbud. Derfor er der ingen risiko
            forbundet med at bruge os, til at finde frem til de billigste kviklån.
          </p>
          <h3>Renter på kviklån</h3>
          <p>
            En af de første prisparametre, som springer i øjnene i forbindelse med kviklån
            er renterne. Kviklånsrenter har været et omdiskutteret emne i lang tid,
            hvilket gælder uanset om det er kviklån med en lav rente eller en forhodsvis
            høj rente.
          </p>
          <p>
            Hvis vi tager udgangspunkt i situationen som den er i dag, så er renterne på
            kviklån langt fra lige så høje, som de var for blot få år siden inden
            ÅOP-loftet blev indført. Her var det ikke unormalt at renterne lå på 15-20% om
            måneden. I dag er situationen helt anderledes, da alle kviklån har lave
            renter, sammenlignet med de tidligere renteniveauer.
          </p>
          <p>
            Denne låntype kan i bund og grund ikke overleve uden markedsføring, hvilket
            betyder, grundet ÅOP-loftet, at kviklåns renter ikke overstiger 25% ÅOP i
            praksis i 99% af tilfældene. Dette inkluderer alle omkostninger samt renters
            renter. Selv hvis der ikke forekommer noget oprettelsesgebyr, vil renters
            renter på kviklån betyde, at den maksimale månedlige rente på et kviklån vil
            ligge på omkring 2%, hvilket i kroner og ører svarer til ca.100 kr. i renter
            om måneden på et kviklån på fx 5.000 kr.
          </p>
          <p>
            Tidligere var det muligt at finde kviklån helt uden renter de første 30 dage.
            Dette eksisterer ikke mere, da økonomien hos kviklånsudbyderene er blevet
            gevaldigt presset, efter at ÅOP-loftet blev indført, som sænkede renterne på
            kviklånene til en tiendedel.
          </p>
          <p>
            Den laveste rente på kviklån var tidligere 0% - altså et kviklån uden rente
            (for en kort periode). I dag ligger stortset alle kviklån med en lav rente
            grundet renteloftet, som ikke helt er rentefrit, men tæt på, sammenholdt med
            situationen før ÅOP-loftet. Derfor vil det i dag sjældent være brugbart at
            sammenligne kviklån på baggrund af renter, eller vælge et kviklån med lav
            rente uden at skelne til andre parametre.
          </p>
          <h3>ÅOP og de dyre lån</h3>
          <p>
            At finde det bedste og billigste kviklån kan være vanskeligt. Det er dog
            særligt vanskeligt, hvis ikke du ved, hvad du skal sammenlign kviklån ud fra.
            Mange tror, at vejen til det bedste kviklån er at finde et kviklån med lav
            rente. Renten er en betydelig del af de samlede kreditomkostninger, men er
            ikke den eneste. Derfor er der også andre omkostninger at tage højde for i din
            sammenligning.
          </p>
          <p>
            Netop derfor rådes du til at sammenligne lånemulighederne med udgangspunkt i
            ÅOP. Det er en forkortelse for årlige omkostninger i procent, og er et tal,
            der gør det lettere at sammenligne de totale omkostninger ved lån. I
            udregningen af ÅOP er alle omkostninger inkluderet. Det sikrer, at du ikke
            overser et væsentligt gebyr, når du forsøger at skabe overblik over et lånets
            totale kreditomkostninger. Den største fordel ved at sammenligne baseret på
            ÅOP, er at det giver gennemsigtighed og overblik over alle omkostninger.
            Derved undgår du at støde på ubehagelige overraskelser, når du optager et nyt
            kviklån.
          </p>
          <p>
            ÅOP er en betegnelse, som især i forbindelse med de kortfristede kviklån er
            blevet voldsomt misforstået. Tidligere kunne disse lån have en ÅOP, altså en
            årlig omkostning i procent på typisk 700-1.000%. Mange forstod det som om, at
            man kunne komme til at betale 10.000 kr. i omkostninger for et kviklån på
            1.000 kr. bare på det første år. Det er en sejlivet myte, som har været svær
            at aflive.
          </p>
          <p>
            For ikke at skulle skrive en lang teknisk forklaring omkring misforståelsen
            omkring ÅOP, som nu ikke er synderligt relevant efter nye lovingreb på
            området, kan det nævnes, at et lån hos den tidligere kviklånsudbyder
            <strong>Vivus.dk</strong>, havde en ÅOP på ca. 700%. Hvis du optog et lån hos
            dem på fx 1.000 kr., ville du efter et år have betalt 2.400 kr. i totale
            omkostninger, hvilket derefter kunne fratrækkes i Skat, så du endte ud med en
            effektiv årlig rente på 150 til 240%.
          </p>
          <p>
            Dette lyder af en høj rente, men er langt fra de 700%, som 99% af befolkningen
            ellers ville forvente. Samtidig var det kun ca. 1% af låntagerne hos
            kviklånsfirmaerne, som ikke havde tilbagebetalt deres lån efter et år.
            Størstedelen af kunderne var gældfri efter 3 måneder, hvilket betød at
            udgifterne til lånet langt fra løb op i de 150 til 240%.
          </p>
          <p>
            I dag er der ikke langt fra de billigste kviklån til de dyreste grundet
            lovgivningen. Det er dog også blevet langt sværere at blive godkendt til et
            kviklån i dag, og antallet af udbydere er derfor også dalet drastisk.
          </p>
          <h3>Gratis og rentefri kviklån</h3>
          <p>
            Du kan finde mange sider på nettet, som skriver at de kan hjælpe dig med at
            finde rentefri og gratis kviklån i 30 dage. Der kan være to grunde til at de
            omtaler gratis og rentefri kviklån.
          </p>
          <p>
            Den ene grund kan være, at deres materiale er outdated, da der tilbage i
            2015-2020, stadigvæk fandtes mange kviklån, som tilbød 30 dage gratis. Da
            lånene også kun havde en løbetid på 30 dage, kunne man også tillade sig at
            kalde det for rentefri kviklån.
          </p>
          <p>
            Den anden grund kan være, at disse sider prøver at lokke folk ind på deres
            side, ved at love dig kviklån rentefrit i 30 dage. Der er der en god
            sandsynlighed for, da de kan tjene penge på at sende besøgende videre til alle
            mulige andre
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneselskaber</a
            >, på trods af at de ikke kan hjælpe med at finde kviklånsfirmaer, som
            tilbyder hverken gratis kviklån eller kviklån med 30 dages rentefrihed. Står
            man med et ønske om et rentefrit kviklån, som ikke kan opfyldes, kan man let
            fristes af andre <strong>tilbud om lån</strong>.
          </p>
          <p>
            Leder du efter rentefri og gratis kviklån, må vi desværre skuffe dig. Vi håber
            ikke at du går i fælden i din søgen efter at finde et rentefrit kviklån, da de
            ikke længere findes i Danmark.
          </p>
          <p>
            Det nærmeste du kommer et et billigt kviklån, som vi gerne hjælper dig med at
            finde. Heldigvis har regulering af markedet betydet, at billige kviklån er
            muligt at finde i dag, i modsætning til for bare få år siden, hvor den
            månedlige rente typisk var ti gange så høj, som den er i dag.
          </p>
        </div>

        <div class="text-container">
          <h2 id="vaelge-det-bedste-kviklaan">Vælge det bedste kviklån</h2>
          <p>
            Vi har nu været igennem både hvad et billigt kviklån er, renterne på kviklån,
            samt markedet for gratis og rentefri kviklån. Nu er vi ved at være ved vejs
            ende, da vi skal til at vurdere, hvad det bedste kviklån er. Det er nemlig
            ikke altid det samme, som det billigste kviklån.
          </p>
          <p>
            Hvis du ønsker at finde det eller de bedste kviklån, så skal du overveje
            udbetalingshastigheden, mulighed for hurtig afvikling af gæld, mulighed for
            forhøjelse, samt kundetilfredshed.
          </p>
          <h3>Udbetalingshastighed</h3>
          <p>
            En af de vigtigste faktorer, ihvertfald for de fleste, som leder efter det
            bedste kviklån, er om lånet udbetales hurtigt. Hvis du ikke har brug for
            hurtig udbetaling, så er det måske slet ikke et kviklån, som du har behov for.
            De bedste kviklån, hvis vi sammenligner på udbetalingshastighed, overfører med
            straksoverførsel. Straksoverførsel betyder, at uanset om du låner i hverdagen,
            efter normal arbejdstid, i weekenden eller på en helligdag, så står pengene på
            din konto så snart lånet er godkendt. Efter som at låneprocessen er 100%
            digital for alle udbydere af kviklån i dag, behøver du ikke kontakt til en
            medarbejder, og derfor er det muligt at få pengene på alle tidspunkter af
            døgnet året rundt, hvis blot overførslen sker som en straksoverførsel.
          </p>
          <h3>Hurtig gældsafvikling</h3>
          <p>
            Hvis du leder efter det bedste kviklån, så er det ofte prisen og hastigheden,
            der er fokus på. En anden vigtig parameter at holde i mente, hvis du vil finde
            det bedste kviklån er, om du har mulighed for gratis at afvikle dit kviklån
            når som helst, uden at betale et ekstra gebyr. På samme måde som at der kan
            komme uventede udgifter, som kræver et hurtigt kviklån, kan der også komme
            uventede indtægter, som kan bruges på at <strong>afvikle din gæld</strong>.
          </p>
          <h3>Låneforhøjelse</h3>
          <p>
            I modsætning til ovenstående situation, tilbyder de bedste kviklån på markedet
            at du kan forhøje dit lån. Et godt kviklån kan tilpasses dit behov, uanset om
            det skal hæves eller sænkes med kort varsel. Derfor bør du også huske på
            dette, i din søgen efter det bedste kviklån.
          </p>
          <h3>Kundetilfredshed</h3>
          <p>
            Det er nok de færreste, som er opmærksom på, at kviklånsfirmaerne har en høj
            kundetilfredshed - i hvert fald hvis man stoler på
            <a href="https://dk.trustpilot.com/" rel="noopener noreferrer" target="_blank"
              >Trustpilot</a
            >. Hvis du skal finde det bedste kviklån, så er det selvfølgeligt også værd at
            vide, om tidligere kunder generelt er tilfredse med produktet og servicen hos
            den udbyder, som du påtænker at vælge.
          </p>
          <p>
            Det generelle billede på markedet er dog, at langt de fleste udbydere har en
            høj score på Trustpilot. Det er dog vigtigt at nævne, at det ikke kun er
            scoren i sig selv, som har betydning, men også antallet af anmeldelser. Hvis
            du fx har en score på 5/5 baseret på 3 anmeldelser, så er det måske et mere
            sikkert valg at vælge en udbyder med 4,5/5, som har 100 anmeldelser.
          </p>
        </div>

        <div class="text-container">
          <h2 id="oversigt-over-kviklaan-i-danmark">Oversigt over kviklån i Danmark</h2>
          <p>
            Hvis du ønsker at finde det bedste kviklån til dit behov, så er det godt at få
            et overblik over alle kviklån i Danmark. Vi kan i stor udstrækning hjælpe dig
            med en oversigt over kviklånene i Danmark, da vi sammenligner mange af de
            største og bedste kviklån på det danske marked. Hvis du har brug for en
            oversigt over kviklån i Danmark, vil vi derfor anbefale, at du starter med en
            ansøgning hos os.
          </p>
          <p>
            Når du afsender din digitale ansøgning gennem os, videreformidler vi den til
            alle vores samarbejdspartnere indenfor alle typer af lån. Inden for få
            minutter, vil du have modtaget svar på din ansøgning fra alle udbydere, og vi
            kan dermed danne din personlige oversigt over kviklån, samt andre relevante
            <a
              href="https://saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >lånetilbud</a
            >.
          </p>
          <p>
            Hvis du ønsker en sammenligning af kviklån, som skal bruges af dig personligt,
            så hjælper det ikke at sammenligne kviklånene på baggrund af generelle vilkår.
            Du er nødt til at indhente tilbud for hvert lånefirma, med udgangspunkt i dig
            og din økonomi. Når du ansøger gennem os, indhenter du tilbud baseret på dig
            og din økonomi, hvilket er den nemmeste måde at finde personlige tilbud på
            kviklån.
          </p>
          <p>
            Det er også muligt selv at søge på nettet efter de enkelte udbydere, foretage
            den ene efter den anden ansøgning og på den baggrund danne sin egen oversigt
            over alle kviklån i Danmark. Ud fra vores erfaring, er det dog meget
            begrænset, hvad du kan få ud af at lave din egen oversigt over danske kviklån
          </p>
          <p>
            At lave sin egen oversigt over danske kviklån og den vej sammenligne
            kviklånene med hinanden er både er enormt tidskrævende, besværligt og gør det
            betydeligt svære at sammenligne, sammenlignet med vores personlige
            <strong>låneoversigt</strong>. Samtidig vil det være meget begrænset, hvilke
            udbydere der kan tilføjes til din oversigt over kviklån, som vi ikke allerede
            indhenter tilbud fra. Dette vil være tilfældet selv gennem ihærdig søgen på
            nettet.
          </p>
          <p>
            Hvis du absolut vil have en total oversigt over alle kviklån i Danmark, så er
            denne metode nok den eneste mulighed til at finde alle kviklån. Til at starte
            med, kan du spare dig selv meget tid og besvær, ved at udfylde vores ansøgning
            og indhente tilbud fra de første mange udbydere på markedet. Vores ansøgning
            er hurtig, gratis og uforpligtende. Samtidig vil det være præcis de samme
            tilbud du ville kunne indhente, hvis du gik til de enkelte udbydere direkte.
          </p>
          <p>
            Hvis du har brug for en oversigt over kviklån, vil vi foreslå at du starter
            her på siden og evt. supplerer op med din egen research efterfølgende, hvis du
            ikke følger at vores oversigt over kviklån dækker dit behov.
          </p>
          <h3>Nye kviklån</h3>
          <p>
            Vi hjælper dig med at finde de nye kviklån på markedet, hvor du ikke behøver
            at stille sikkerhed. Vi ved selvfølgelig ikke hvilke firmaer, du allerede
            kender til, men hvis du ansøger om et nyt kviklån gennem vores tjeneste, søger
            vi blandt flere små ukendte kviklån, de gamle og velkendte, samt nye kviklån,
            som vi samarbejder med.
          </p>
          <p>
            Hvis du ønsker at finde det bedste kviklån, er det ikke ikke nok at lede
            blandt de nye kviklån, da der også er gode muligheder, og ofte bedre
            muligheder for at blive godkendt blandt de gamle spillere på markedet.
          </p>
          <p>
            Vi ved at der er mange, som specifikt leder efter nye kviklån uden sikkerhed.
            Her kan vi oplyse, at der i Danmark ikke er forskel på de nye kviklån og de
            nye kviklån uden sikkerhed. Faktisk er er der hverken nye eller gamle udbydere
            af kviklån på det danske marked, som kræver sikkerhed for dit lån. Derfor
            behøver du ikke afgrænse din søgning til nye kviklån uden sikkerhed.
          </p>
          <p>
            Vi har lavet en oversigt over alle de kviklån, som vi kender til, på vores
            side om
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere</a
            >. Her finder du også alle de helt nye kviklån, som vi er stødt på, samt
            låneudbydere af andre typer lån. Denne liste, som blandt andet indeholder
            gamle og nye kviklån, samt de små ukendte kviklån, kan du bruge som supplement
            til en ansøgning gennem os, da vi kan indhente mange tilbud på kviklån til
            dig, med blot én ansøgning.
          </p>
          <p>
            Vi opdaterer listen løbende, men kan ikke garantere, at vi har alle de helt
            nye kviklån på listen. Det er dog meget usandsynligt, at der skulle komme
            udbydere med nye kviklån på markedet grundet lovgivningen på området. Vores
            forventning er tværtimod, at flere af de små ukendte kviklån, som vi har
            samlet på listen vil forsvinde, samt en meget begrænset tilførsel af udbydere
            af nye kviklån. Derfor tror vi også, at du kommer til at gå forgæves, hvis du
            leder efter flere nye kviklån efter at have udfyldt en ansøgning gennem os.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-karakteriserer-et-kviklaan">Hvad karakteriserer et kviklån</h2>
          <p>
            Hvis du ikke kender meget til kviklån, men blot har hørt navnet og gerne vil
            vide mere om denne låneform, så vil vi i dette afsnit uddybe, hvad der
            kendetegner online kviklån, og dem du finder i de traditionelle banker.
          </p>
          <h3>Hurtig udbetaling</h3>
          <p>
            Ikke alle er i en situation, hvor de kan vente på lånet. Derfor har de brug
            for et hurtigt kviklån med udbetaling straks. Har du modtaget en regning, der
            skal betales? Eller er du stødt på et godt tilbud, som du ikke vil gå glip af?
            I så fald skal det sandsynligvis gå hurtigt med at få pengene udbetalt til din
            bankkonto.
          </p>
          <p>
            To af de fremtrædende fordele forbundet med et kviklån er det hurtige svar og
            hurtig udbetaling. Det tager sjældent mere end et døgn fra din ansøgning er
            sendt til, at pengene står på kontoen. Som oftest vil kviklånet dog udbetales
            som en straksudbetaling, hvilket betyder at udbetalingen sker med det samme.
          </p>
          <p>
            Hvis du har brug for et kviklån nu, så skal du gå efter kviklån med
            straksudbetaling. Hvis du har lidt mere tid at løbe på, men gerne ser, at dit
            kviklån stadigvæk udbetales relativt hurtigt, så behøver du ikke lede efter
            udbetaling med det samme. Alle udbydere i Danmark udbetaler nemlig lån på alle
            arbejdsdage, og selv med en langsom overførsel, tager det blot én bankdag.
          </p>
          <p>
            Hvis du har brug for et kviklån i weekenden eller på en helligdag, eller der
            er lang tid til næste bankdag, så kan du med fordel vælge kviklån med
            straksudbetaling. Der vil typisk ikke være nogen ekstra omkostning forbundet
            med at få udbetalingen med det samme.
          </p>
          <p>
            De seneste år er straksudbetaling på kviklån, eller straksoverførsler som
            faktisk hedder, blevet mere og mere udbredt, ikke kun blandt denne låntype.
            Derfor er mere reglen end undtagelsen, at kviklån tilbyder hurtig udbetaling i
            dag.
          </p>
          <h3>Kort løbetid</h3>
          <p>
            Kviklån havde tidligere en løbetid på 30 dage. Uanset hvilken udbyder du gik
            til, havde de et 30-dages kviklån. I dag, finder du ikke kviklån med 30 dages
            løbetid længere, da markedet har ændret markant i slutnigenn af 10érne og
            starten af 20´erne.
          </p>
          <p>
            I dag løber lånene typisk over 6-12 mdr., med mulighed for tidligere
            afbetaling. Hvis du kun har behov for et kviklån i 30 dage, afdrager du derfor
            blot dit lån efter én måned. På den måde undgår du at skulle betale renter for
            en lang periode.
          </p>
          <p>
            Tidligere hang 30-dages kviklån og gratis kviklån unægteligt sammen. Derfor
            var der mange, som søgte efter kviklån i 30 dage, som i virkeligheden
            efterspurgte et gratis eller rentefrit kviklån, da det var var muligt at få de
            første 30 dage af kviklånet gratis. Hvis du nåede at tilbagebetale kviklånet
            inden for 30 dage, så var det 100% gratis.
          </p>
          <p>
            Ændringen fra 30 dages kviklån til længere låneperioder er, lige som tidligere
            nævnt, en effekt af den løbende regulering af markedet, og derfor vil du ikke
            længere kunne finde kviklån med en løbetid på blot 30 dage.
          </p>
          <h3>Uden sikkerhed</h3>
          <p>
            De fleste traditionelle banker kræver, at du kan stille sikkerhed for dit lån.
            Det er ikke kun gældende ved større lån, men også de små kviklån i banken.
            Derved er de nemlig garanteret, at du kan tilbagebetale lånet. Som regel
            kræves det, at du stiller sikkerhed for lånet i form af
            <a
              href="https://saverium.dk/laan-penge/bolig"
              rel="noopener noreferrer"
              target="_blank"
              >bolig</a
            >
            eller
            <a
              href="https://saverium.dk/laan-penge/billaan"
              rel="noopener noreferrer"
              target="_blank"
              >bil</a
            >. Ejer du ingen af delene, er det andre ejendele af høj værdi, der kræves.
            Kan du ikke stille sikkerhed, kan du sjældent få lov til at låne penge i
            banken.
          </p>
          <p>
            Det er dog ikke gældende for de små online kviklån. Her kan du sagtens få et
            kviklån uden sikkerhed. Det er i særdeleshed gældende for små kviklån, der
            lyder på mellem 1.000 kroner og 5.000 kroner. Når en långiver udbyder
            <strong>små lån</strong>, så som et kviklån uden sikkerhed og dokumentation,
            har de ingen garanti for, at de får pengene igen. Mister du dit arbejde eller
            kan du af anden årsag ikke betale de månedlige afdrag, kan de ikke kræve et
            pantsat aktivt solgt, som bankerne kan.
          </p>
          <p>
            Netop derfor har kviklån uden sikkerhed tidligere haft en højere rente, end
            hvad du tilbydes i de traditionelle banker, hvor det er påkrævet at stille
            sikkerhed, hvilket ikke altid er gældende i dag, grundet
            <a
              href="https://www.dr.dk/nyheder/penge/efter-uenighed-om-indgreb-mod-kviklaan-minister-lander-nu-en-aftale"
              rel="noopener noreferrer"
              target="_blank"
              >ÅOP-loftet</a
            >. Endvidere kræves det som regel også i banken, at du kan redegøre for lånets
            formål.
          </p>
          <h3>Online</h3>
          <p>
            Det sidste kendetegn ved kviklån er, at de foregår på nettet. Alle kviklån,
            som du støder på i Danmark vil være online kviklån. Da det er vigtigt at
            kviklån er hurtige og billige, er det umuligt at lave dem offline. Alle
            processer som kan foregå online, skal foregå online og så automatiseret som
            muligt, for at det i dag kan betale sig, at yde denne type lån. ‘
          </p>
          <p>
            Efter regeringens vedtagelse af en lov om maksimalt 35% ÅOP (25% ÅOP i
            praksis), vil det være helt umuligt at yde lån kviklån, som ikke var online og
            automatiseret i stor stil. Selv hvis du går i en butik for at optage et
            kviklån til at <strong>finansiere</strong> et køb, vil det være et online
            kviklån.
          </p>
          <p>
            For at sætte tingene i perspektiv, tjener en udbyder af online kviklån 100 kr.
            om måneden, hvis du låner 5.000 kr. Dette beløb vil i oftest være
            tilbagebetalt inden for tre måneder, hvilket giver en indtjening på 300 kr.
            Herfra skal virksomheden fratrække kapitalomkostninger,
            transaktionsomkostninger, omkostninger til opslag hos RKI, Debitor Registret
            mv., samt personale og tab på de kunder, som aldrig betaler deres lån tilbage.
          </p>
        </div>

        <div class="text-container">
          <h2 id="kreditvurdering-ved-kviklaan">Kreditvurdering ved kviklån</h2>
          <p>
            Mange tror fejlagtigt, at firmaer som udbyder kviklån godkender alle. For at
            blive godkendt og få det billigst muligt kviklån, er der dog en række krav,
            som du som låntager skal opfylde.
          </p>
          <p>
            For at have de bedste forudsætninger, for at få bevilliget et lån, skal du
            være en attraktiv låntager. Det vil sige, at du skal kunne imødekomme
            lånevirksomhedens krav og betingelser. Kan du ikke det, vil din låneansøgning
            enten blive afvist, eller det vil medføre yderligere låneomkostninger.
          </p>
          <p>
            Herunder vil vi gennemgå nogle af de områder, som du bliver kreditvurderet på,
            når du ansøger om et kviklån. I Danmark er det direkte ulovligt at bevillige
            kviklån uden kreditvurdering. Derfor er det heller ikke muligt at finde
            kviklån uden kreditvurdering. Hvis din forhåbning var et kviklån uden
            kreditvurdering, må vi derfor desværre skuffe dig.
          </p>
          <p>
            Selv om at kviklån uden kreditvurdering ikke er muligt, så kan du stadigvæk
            forøge dine chancer for at blive godkendt.
          </p>
          <h3>Kreditværdighed</h3>
          <p>
            Har du eksisterende gæld, som du kæmper med at betale tilbage med din
            nuværende indkomst, får du næppe en god rente på dit næste lån. Er din
            nuværende gæld meget stor, vil du end ikke får bevilliget et yderligere lån.
            Har du meget udestående gæld, signalerer det til lånevirksomheden, at du har
            vanskeligt ved at betale dine regninger.
          </p>
          <p>
            Det er ikke blot eksisterende gæld, der inddrages, når din kreditværdighed
            skal vurderes. Her tages der også højde for din indkomst, dit rådighedsbeløb
            m.m. Mange forsøger at finde kviklån uden kreditvurdering, for at undgå
            granskningen af deres økonomiske situation – det kan dog ikke lade sig gøre.
          </p>
          <p>
            Havde du gjort dig forhåbninger om at kunne optage et kviklån uden
            kreditvurdering, kan du derfor godt skrinlægge drømmen igen. Er din
            kreditvurdering ikke positiv, bliver du frasorteret i ansøgningsprocessen, da
            långiverne ikke ønsker at løbe denne risiko.
          </p>
          <h3>Indkomst</h3>
          <p>
            Din indkomst er også en stor del af din kreditværdighed. Det er selvsagt
            vanskeligt at have en god kreditværdighed, hvis ikke du har en god indkomst.
            Jo bedre din økonomi er, desto bedre bliver din kreditværdighed nemlig. Kan du
            dokumentere en høj månedlig indkomst, vil udbyderen ikke blot være mere
            tilbøjelig til at bevillige dig et lån. Det resulterer også i bedre
            forudsætninger for at få bevilliget et kviklån med lav rente og ÅOP.
          </p>
          <p>
            Det skyldes, at låneudbyderen herved kan se, at din økonomiske situation og
            månedlige indkomst garanterer, at de månedlige låneomkostninger kan dækkes.
            Dette er ikke ensbetydende med, at du eksempelvis ikke vil kunne få et kviklån
            på kontanthjælp eller på dagpenge. Det kan godt lade sig gøre, men
            låneudbyderens risiko er større, hvorfor rente- og gebyromkostninger er
            højere, for at kompensere for risikoen. Er du gift eller ansøger om et kviklån
            eller
            <a
              href="https://saverium.dk/laan-penge/kassekredit"
              rel="noopener noreferrer"
              target="_blank"
              >kassekredit</a
            >
            med en partner, vil dette forbedre din kreditværdighed. Derfor vil det også
            resultere i en bedre chance for en lav rente og bedre lånevilkår. Det bedste
            kviklån får man nemlig som regel, hvis han har en medansøger på sin ansøgning.
          </p>
          <h3>Alder</h3>
          <p>
            Er du under 21 år, er dine
            <a
              href="https://saverium.dk/laan-penge/bolig/laanemuligheder"
              rel="noopener noreferrer"
              target="_blank"
              >lånemuligheder</a
            ><strong> </strong>begrænset. Det er kun et fåtal af udbyderne, der bevilliger
            kviklån til personer mellem 18 og 21 år, ligesom lånene ofte har en relativ
            høj rente. Det skyldes, at der er stor risiko forbundet med at yde et kviklån
            til en person på 18 år, 19 år eller 20 år. Søger du fx et kviklån som 18-årig,
            skal du derfor være indstillet på, at mulighederne er begrænsede, og de fleste
            af dine tilbud vil være med en ÅOP på 25%.
          </p>
          <p>
            Selv med et kviklån som 18-årig, vil det nok være de færreste som forventer,
            eller i realiteten beholder lånet i nærheden af ét eller flere år. Samtidig er
            kviklånet måske på 1.000 kr., og den samlede reelle udgift til renter vil
            derfor være begrænset til 100 eller 200 kr.
          </p>
          <p>
            Uanset om du er 18, 19, 20 eller 21 år, så kan du med fordel benytte vores
            ansøgningsformular til at indhente tilbud fra flere udbydere af kviklån, da
            processen vil være stortset den samme hos de enkelte udbydere. Dermed undgår
            du at skulle udfylde mange identiske ansøgninger, som i de fleste tilfælde
            blot fører til et afslag. Husk at en ansøgning om kviklån som 18-årig er ikke
            ensbetydende med afslag.
          </p>
          <h3>RKI</h3>
          <p>
            Er du registreret som dårlig betaler i RKI, skal du ikke forvente at få
            bevilliget et lån. Kviklån trods RKI er meget sjældent muligt. Dette er
            gældende, hvad enten det er et lille kviklån på 1.000 kr. eller et større
            kviklån på 5.000 kr. Årsagen til at et kviklån trods rki, eller et lån i det
            hele taget trods RKI, ikke er muligt, er at lånevirksomhederne løber en stor
            risiko ved at bevillige dig et lån. Det vil sige, at udbyderne ikke kan være
            sikre på, at du kan betale lånet tilbage.
          </p>
          <p>
            Forsøger du eksempelvis at ansøge om et kviklån på 5.000 kroner, til trods for
            en negativ RKI-status, vil det blot resultere i en afvisning. Da
            kreditvurdering processen foregår automatisk hos hovedparten af
            låneselskaberne, modtager du derfor en afvisning med det samme.
          </p>
          <p>
            I enkelte tilfælde er det muligt, hvis du har en kautionist. I et sådant
            tilfælde skal du dog forvente, at du får et kviklån med rente- og gebyrniveau
            i top. I dag er de maksimale omkostninger, som du kan blive tilbudt dog ikke
            synderligt høje - selv ikke for kviklån. Derfor kan det stadigvæk være
            fordelagtigt at ansøge om kviklån med en kautionist.
          </p>
          <p>
            Kviklån trods RKI er derfor ikke altid en hindring, men i de mange tilfælde
            vil det dog være. Der findes dog alternativer til et kviklån på trods af RKI,
            hvilket fx er
            <a
              href="https://saverium.dk/laan-penge/samlelaan"
              rel="noopener noreferrer"
              target="_blank"
              >samlelån</a
            >, <strong>pantelån </strong>og <strong>gældsrådgivning</strong>.
          </p>
          <h3>Kauktionist</h3>
          <p>
            Hos nogle udbydere af kviklån og i nogle situationer, kan man blive bedt om at
            stille en kauktionist. De fordele som du kan opleve, hvis du søger et kviklån
            med kautionist, er at sandsynligheden for godkendelse er højere, hvis din
            kautionist har en god økonomi.
          </p>
          <p>
            Kautionistens formål i forbindelse med et kviklån, eller et andet lån, er at
            være garant for dit lån. På den måde kan lånefirmaet bedre sikre sig at få
            deres penge tilbage, hvis din egen økonomi ikke er tilstrækkelig sikker. Når
            du ansøger om kviklån med kautionist, vil du derfor score bedre i en
            kreditvurdering, da den tager udgangspunkt i din og din kautionists samlede
            økonomi, frem for blot din egen.
          </p>
          <p>
            Et alternativ til et kviklån med kautionist er et
            <strong>lån med en medansøger</strong>. Langt de fleste vælger dog i første
            omgang at søge kviklån uden kautionist og medansøger.
          </p>
        </div>

        <div class="text-container">
          <h2 id="stoerrelsen-paa-laanet">Størrelsen på lånet</h2>
          <p>
            Du er ikke begrænset i forhold til lånets formål, såfremt du optager et
            kviklån. Hvad du anvender dit lånet til, er op til dig. Den store
            fleksibilitet og frihed til at spendere pengene, som du selv ønsker, er en
            medvirkende faktor til at denne lånetype er blevet yderst populær.
          </p>
          <p>
            Ikke alle optager dog denne type lån med henblik på forbrug. Mange beslutter
            at optage et lånet, fordi en uforudset regning er dumpet ind ad brevsprækken.
            Har man ikke midlerne til at betale, kan et af de små kviklån være en fin
            løsning. Mange af de kviklån, som optages i Danmark, bliver dog brugt på
            blandt andet køb af <strong>elektronik, </strong>som
            <strong>lån til ferie</strong> eller <strong>lån til møbler</strong>.
          </p>
          <p>
            Det eneste der begrænser, hvad lånet kan bruges til, er størrelsen af lånet.
            Vil du have frit spil, kræver det selvfølgelige et større lånebeløb. Det er
            dog sjældent, at kviklån overstiger 20.000 kroner. Er beløbet højere end
            dette, vil der være tale om andre låntyper, som vi også kan hjælpe med.
          </p>
          <h3>De store kviklån</h3>
          <p>
            Et typisk stort lån inden for denne kategori, vil ligge omkring 10.000 kr.,
            men der er dog stadigvæk mange, som har behov for kviklån på 15.000 og 20.000
            kr. Disse beløb rækker ofte til <strong>værkstedsregninger</strong>, mindre
            <strong>renoveringer</strong> og <strong>lån til rejser</strong>.
          </p>
          <p>
            Da disse beløb ligger i den høje ende inden for deres kategori, vil der også
            være mange udbydere, som man typisk ikke forbinder med denne form for lån, som
            kan byde ind på din forespørgsel. Derfor kan du også forvente at få mange
            tilbud, hvis du fx søger om et kviklån på 10.000 kr. eller 15.000 kr.
          </p>
          <p>
            Kviklån i størrelsesordnen 6.000 kr., 7.000 kr. og 8.000 kr. vil ofte også
            blive betegnet som store. Der er dog ikke lige så mange udbydere, som ikke er
            specialiseret i kviklån, som kan tilbyde disse lånebeløb. Deres nedre
            lånegrænse vil ofte gå ved 10.000 kr. eller 20.000 kr.
          </p>
          <h3>De små kviklån</h3>
          <p>
            De små kviklån er dem, som de fleste nok vil betegne som rigtige lån inden for
            denne kategori. Her ansøger du fx om et kviklån på 5.000 kr. til at købe en
            erstatning for en af dine
            <a
              href="https://saverium.dk/laan-penge/forbrugslaan/haarde-hvidevarer"
              rel="noopener noreferrer"
              target="_blank"
              >hårde hvidevarer</a
            >, som har kørt sin sidste omgang. Et kviklån på 5.000 kr. kan også bruges på
            en større <strong>tandlægeregning</strong>, hvilket et kviklån på 1.000 kr.
            eller 2.000 kr. måske også kan klare, hvis regningen er knap så stor.
          </p>
          <p>
            Denne type lån kaldes også ofte for et lille kviklån eller et mini kviklån -
            en betegnelse, som nok ikke behøver yderligere forklaring.
          </p>
          <p>
            Uanset om du har behov for et af de små kviklån på 5.000 kr., 3.000 kr. eller
            2.000 kr., så kan vi sagtens hjælpe dig, og du vil ikke se nogen løftet
            pegefinger fra vores side, uanset hvilket låneformål du har. Hvis du har brug
            for et af de små kviklån, så stoler vi på, at du selv har vurderet, at det er
            relevant med lånet. Om det så er et kviklån på 5.000 kr. til en ny
            <strong>cykel</strong> eller et kviklån på 3.000 kr. til et mindre
            <strong>plastikkirurgisk indgreb</strong>, så er det helt op til dig.
          </p>
          <p>
            Vi blander os ikke I hvad vores brugere ønsker at bruge de små kviklån til.
            Faktisk blander vi os aldrig i, hvad vores brugere lånet til.
          </p>
        </div>

        <div class="text-container">
          <h2 id="historien-om-kviklaanene">Historien om kviklånene</h2>
          <p>
            Den danske historie om kviklån startede med at ansøgninger over sms, de
            såkaldte <strong>SMS lån</strong>. Dette var metoden før internettet blev
            allemandseje, hjemmesider blev brugervenlige og før der var noget der hed
            digital signatur. Her kunne man let ansøge om kviklån på 5.000 kr. eller
            10.000 kr. over en besked fra en gammeldags mobiltelefon uden anden
            kreditvurdering end et check i RKI.
          </p>
          <p>
            Omkring begyndelsen af 10érne kom de første online kviklån til Danmark.
            Kreditvurderingen var stadigvæk meget sparsom og det samme var efterspørgslen,
            da det på daværende tidspunkt var et tabu at optage et disse lån. I denne
            periode var
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere/ferratum"
              rel="noopener noreferrer"
              target="_blank"
              ><strong>Ferratum</strong></a
            ><strong> </strong>den største spiller på det danske marked.
          </p>
          <p>
            Midt i 10érne gik et lettisk firma ved navn <strong>4Finance</strong> ind på
            det danske lånemarked med deres kviklånsbrand <strong>Vivus</strong> og satte
            fuld tryk på markedsføringen, hvorefter denne lånetype blev meget mere udbredt
            og i langt højere grad alment accepteret. I denne periode var der også et
            skifte, i forhold til hvem der valgte at optage kviklån, fra at være de mest
            udsatte i landet, til at være en noget bredere palette af danskere, både i
            forhold til indkomst, uddannelse og jobsituation.
          </p>
          <p>
            I slutningen af 10érne var markedet eksploderet og det samme var antallet af
            udbydere, som var oppe at runde 10-15 stykker på samme tid, men
            konkurrencesituationen havde ikke gjort noget ved priserne, som stadigvæk lå
            med den samme rente på ca. 20% om måneden.
          </p>
          <p>
            Grundet den store efterspørgsel og at de almindelige markedskræfter ikke fik
            sænket priserne, valgte folketinget at vedtage en lov, som betød, at man som
            ansøger til et kviklån skulle have 24 timers betænkningstid fra at man blev
            godkendt, til at man uopfordret igen skulle godkende ansøgningen for endeligt
            at få udbetalt lånet. Dette fik udbetalingerne til at bremse lidt op, men ikke
            i den grad, som alle forventede. Denne lovgivning kom kviklånsudbyderne dog
            hurtigt omkring, ved at lave deres lån om til
            <a
              href="https://saverium.dk/laan-penge/kassekredit"
              rel="noopener noreferrer"
              target="_blank"
              >online kassekreditter</a
            >. Dette var ikke blot en måde at undgå lovgivningens hensigt, som allerede
            delvist havde slået fejl, men også en bedre forretningsmodel for udbyderne af
            kviklånene.
          </p>
          <p>
            Et par år senere blev to nye love vedr. kviklån vedtaget, som satte et loft
            over renterne. Den første lov var et ÅOP-loft på 35% for lån helt generelt,
            som dog maksimalt måtte være 25% for låneudbydere, der markedsførte sig selv.
            I praksis er det umuligt at overleve som låneudbyder uden nogen form for
            markedsføring, hvilket betyder, at der i dag ikke findes nogle udbydere af
            kviklån eller andre lån, som har en maksimal ÅOP på over 25%.
          </p>
          <p>
            Den anden lov som blev vedtaget med henblik på at begrænse udlånene gennem
            kviklån var, at man som kviklånsudbyder
            <a
              href="https://financer.com/dk/blog/ny-lov-for-kviklaan-og-forbrugslaan-2020/#:~:text=Der%20m%C3%A5%20ikke%20markedsf%C3%B8res%20kvikl%C3%A5n,spil%20og%20gambling%2C%20uanset%20%C3%85OP."
              rel="noopener noreferrer"
              target="_blank"
              >ikke måtte markedsføre sig i blandt spilfirmaerreklamer</a
            >, hvilket betød at det i praksis blev meget svært at finde nogle steder, hvor
            det var sikkert at markedsføre sig. Hvis en spiludbyder valgte at lægge en
            reklame på Google, hvis folk søgte efter kviklån, ville det være
            kviklånsfirmaet, som også reklamerede på samme tid og sted, som stod med
            ansvaret og en overhængende bødestraf. I praksis blev det i stor stil til et
            markedsføringsstop for udbydere af kviklån, som på daværende tidspunkt
            allerede havde problemer med at få forretningen til at løbe rundt grundet
            ÅOP-loftet.
          </p>
          <p>
            Det sidste dødsstød til den tidligere låneform kom, med indførslen af
            <a
              href="https://www.eba.europa.eu/sites/default/documents/files/documents/10180/2352765/91d39548-fd23-4d8e-854f-5f40d0c6236a/Guidelines%20on%20fraud%20reporting%20%28EBA%20GL-2018-05%29_DA.pdf?retry=1"
              rel="noopener noreferrer"
              target="_blank"
              >PSD2 datakravene</a
            >
            og
            <a
              href="https://taenk.dk/privatoekonomi/gode-raad/raadighedsbeloeb"
              rel="noopener noreferrer"
              target="_blank"
              >kravet til rådighedsbeløbet</a
            >, som kunderne skulle have tilbage, når de havde betalt deres månedlige
            renter og afdrag på lånet. Dette fik langt de fleste kviklånsfirmaer til at
            lave deres produkt om til noget, som minder langt mere om et
            <a
              href="https://saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >forbrugslån</a
            >
            eller helt lukke deres forretning.
          </p>
          <p>
            Regeringen har med stor sandsynlighed haft gode hensigter med
            <a
              href="https://www.regeringen.dk/nyheder/2020/nu-er-dyre-kviklaan-forbudt/"
              rel="noopener noreferrer"
              target="_blank"
              >kviklånsloven</a
            >, samt de andre lovgivninger på området. Lovgivningen har båret præg af
            manglende indsigt fra politisk side, da det var de færreste, som forstod at en
            høj ÅOP på kviklån ikke betød at kunderne blev ruineret, og at man ikke bør
            sammenligne ÅOP og renter én til én på et kviklån, med andre typer af lån med
            længere løbetid og højere
            <a
              href="https://saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              >lånebeløb</a
            >.
          </p>
          <p>
            Samtidig har flere nævneværdige kviklånsfirmaerne ikke været villige til, fri
            at dele ud af al den information og viden de lå inde med omkring kunderne og
            markedet, hvilket nok delvist skyldes en frygt for, at den information ville
            blive drejet, så det gik værst ud over dem selv.
          </p>
          <p>
            Desuden var lovgivningen på kviklånsområdet en nem måde at høste vælgere for
            regeringen, da de fleste, som ikke har været kunder hos et kviklånsfirma, har
            et dårligt indtryk af branchen. Ligeledes har de fleste medier og
            forbrugerrådet også været flittige modstandere af kviklånene, hvilket nok har
            været medvirkende til at skabe en dårlig stemning om kviklånene, og gjort det
            sværre for de politikere, som egentligt ikke havde noget imod disse lån, at
            nedstemme lovforslagene.
          </p>
          <p>
            Set fra vores synspunkt var der helt sikkert behov for regulering på markedet
            for denne type lån. Den manglende indsigt i markedet, samt et dårligt forhold
            mellem kviklånsudbyderne på den ene side og politikere og medier på den anden
            side, har dog resulteret i, at markedet for kviklån blev ødelagt, frem for at
            få en sund regulering. Dette har resultetet i at mange kviklånskunder er
            blevet ført ud i et ureguleret lånemarked på Facebook og lignende, hvilket
            hverken regeringen, låneudbyderne eller kunderne har interesse i.
          </p>
          <h3>Tidligere udbydere</h3>
          <p>
            I forbindelse med tiltagende lovgivning, har flere kviklånsfirmaer været nødt
            til at lukke deres tilbud i Danmark. Det gælder blandt andet for nogle af de
            førende spillere på markedet, bla. <strong>Vivus kviklån</strong>,
            <a
              href="https://saverium.dk/laan-penge/laaneudbydere/basisbank"
              rel="noopener noreferrer"
              target="_blank"
              >Basisbank kviklån</a
            >, <strong>Lendon kviklån</strong> og <strong>Simbo kviklån</strong>.
          </p>
          <p>
            Nogle af de få firmaer, som har overlevet regulering, ved at justere deres
            produkter løbende er <strong>Cashper</strong> og Multitude group, som vi i dag
            også samarbejder med. Når du søger efter kviklån gennem vores platform i dag,
            vil du få tilbud tilbage, som egentligt ikke er kviklånstilbud, men derimod
            lån af alle typer, som har det tilfælles at de er hurtige og uden sikkerhed på
            samme måde som de tidligere kviklån.
          </p>
        </div>

        <div class="text-container">
          <h2 id="quicklaan-er-en-fordanskning">Quicklån er en fordanskning</h2>
          <p>
            Der er mange, som bruger betegnelsen quicklån, men hvad er quicklån egentligt,
            og er quicklån det samme som kviklån, eller er det noget andet?
          </p>
          <p>
            Quicklån lyder meget som kviklån, og derfor bruger mange nok betegnelsen
            quicklån, på trods af at der ikke er noget, som hedder quicklån. Quicklån er
            en blanding af det engelske ord “quick”, som betyder hurtigt, og det danske
            ord “lån”. Alternativt kan man sige at quicklån er en fordanskning af ordet
            “quick loan”. Sætter man quick og lån sammen og oversætter det ordret, bliver
            det til
            <a
              href="https://saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtiglån</a
            >, eller sagt på en anden måde kvik-lån.
          </p>
          <p>
            Der er altså ikke noget der hedder quicklån i Danmark, eller for den sags
            skyld i resten af verdenen. Den rigtige betegnelse er derfor ikke quicklån, på
            trods af at mange stadigvæk søger efter fx quick lån med hurtig udbetaling,
            quick lån uden sikkerhed samt rentefri, nye og billige quicklån.
          </p>
        </div>

        <div class="text-container">
          <h2 id="skal-vi-hjaelpe-dig">Skal vi hjælpe dig?</h2>
          <p>
            Vi håber at du er blevet klogere på disse lån. Hvis du ønsker at ansøge om et
            kviklån, kan du benytte vores låneberegner her på siden. Vi indhenter
            personlige tilbud på kviklån og andre lån til dig med det samme, så snart du
            har afsendt din online ansøgning via vores låneberegner. Vores tjeneste er
            desuden gratis, uforpligtende og tager blot 2-5 minutter.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
