<template>
  <div class="flex-fill front-loan-select" style="width: 100%">
    <div v-if="versionId == 1">
      <div class="form-group separate">
        <label class="label-separate" for="amount_label">
          {{ $t('preferred_loan_amount') }}
          <i
            v-b-tooltip.hover.v-saverium-blue-input
            title="Vi runder dit lånebeløb op til nærmeste tusinde"
            class="fas fa-question-circle"
          ></i>
        </label>
        <vue-numeric
          class="form-control label-separate"
          id="amount_label"
          @input="changeAmount"
          :currency="currency"
          :value="data.amount"
          :separator="separator"
          currency-symbol-position="suffix"
          inputmode="numeric"
        />
      </div>
    </div>
    <div v-else>
      <label class="monthly-rate" for="amount_label">
        {{ $t('preferred_loan_amount') }}
      </label>
      <div class="loan-range">
        <vue-numeric
          :class="`${variant} w-100 loanamount mt-0`"
          id="amount_label"
          @input="changeAmount"
          :currency="currency"
          :value="data.amount"
          :separator="separator"
          currency-symbol-position="suffix"
          inputmode="numeric"
        />
      </div>
      <div class="time-range-dk mt-4">
        <div>
          <slider-input
            id="loanrange"
            type="range"
            min="0"
            :value="data.amount_index"
            :max="this.loanAmounts.length - 1"
            @click="update"
            :update="update"
            showLabel="false"
          ></slider-input>
        </div>
        <div class="d-flex justify-content-between">
          <span class="min">{{ this.loanAmounts[0] }} {{ currency }}</span>
          <span class="max"
            >{{ this.loanAmounts[this.loanAmounts.length - 1] }} {{ currency }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gsap } from 'gsap'
import {
  getApproximateLoanValues,
  findMatchAmount,
  calculateInputRangeStep,
  calculateInputRangeStepSme,
  findMatchAmountSme,
  setSliderColor,
  getMonthlyCosts,
} from '@/utils/utils'
import { loanTimeOptions, smeLoanTimeOptions } from '@/utils/saverium_dk/const'

import Input from '@/components/form/Input'
import VueNumeric from 'vue-numeric'

export default {
  props: ['currency', 'separator', 'loan', 'loanAmounts', 'versionId', 'variant'],
  mounted() {
    this.createSlider()
  },
  beforeDestroy() {
    this.createSlider()
  },
  data() {
    return {
      costMonthly: 0,
      cost: 0,
      current: 0,
      visible: false,
      amount: 0,
      amount_index: 0,
    }
  },
  watch: {
    variant(variant, oldVariant) {
      if (variant !== oldVariant) {
        this.createSlider()
      }
    },
  },
  components: {
    'slider-input': Input,
    VueNumeric,
  },
  computed: {
    type() {
      return this.$store.state.applicationType.type
    },
    data() {
      const data = this.setData()
      const valid_amount = this.getAmount(this.$store.state.application)
      this.setValues(valid_amount)
      return Object.assign(data, this.$data)
    },

    showFullHeader: function () {
      const path = this.$route.path
      return path === this.$root.rn.MainView
    },
    stepErrors() {
      return this.$store.state.application.stepErrors
    },
    computedLoans() {
      const duration = 0.2
      const computed = this.getComputedValues()
      gsap.to(this.$data, { costMonthly: computed.costMonthly, duration: duration })
      gsap.to(this.$data, { cost: computed.cost, duration: duration })
      return this.data
    },
    smeloanAmount() {
      return this.$store.state.application.smeloan.amount
    },
    repaymentTimeSme() {
      return this.$store.state.application.smeloan.repayment_time
    },
    loanAmount() {
      return this.$store.state.application.amount
    },
    repaymentTime() {
      return this.$store.state.application.repayment_time
    },
    smeLoan() {
      return this.$store.state.application.smeloan
    },
    loanApplication() {
      return this.$store.state.application
    },
    variantStyle() {
      const variant = this.variant
      const styleMapping = {
        dark: { first: 'rgb(5, 8, 60)', second: 'rgb(90 93 144)' },
        light: { first: '#ffff', second: '#ffff' },
      }
      return styleMapping[variant]
    },
  },

  methods: {
    setValues(valid_amount) {
      this.step = calculateInputRangeStep(valid_amount)
      this.amount = valid_amount
      this.amount_index = this.loanAmounts.indexOf(valid_amount)
    },
    getComputedValues() {
      if (this.type === 'companyloan') {
        getApproximateLoanValues(this.smeloanAmount, this.repaymentTimeSme)
      } else {
        getApproximateLoanValues(this.loanAmount, this.repaymentTime)
      }
    },
    setData() {
      if (this.type === 'companyloan') {
        return this.smeLoan
      } else {
        return this.loanApplication
      }
    },
    getAmount(value) {
      if (this.type === 'companyloan') {
        return findMatchAmountSme(value.smeloan.amount)
      } else {
        return findMatchAmount(value.amount)
      }
    },
    calculateSteps(value) {
      if (this.type === 'companyloan') {
        return calculateInputRangeStepSme(value)
      } else {
        return calculateInputRangeStep(value)
      }
    },
    update(key, value) {
      if (this.type === 'companyloan' && key === 'loanrange') {
        key = 'smeloan.amount_index'
      } else if (key === 'loanrange') {
        key = 'amount_index'
      }
      this.createSlider(value)
      this.$store.commit('updateApplication', { key, value })
    },
    changeAmount(value) {
      let key
      let maxLoanTime
      if (this.type === 'companyloan') {
        key = 'smeloan.amount'
        maxLoanTime = smeLoanTimeOptions.length
      } else {
        key = 'amount'
        maxLoanTime = loanTimeOptions.length
      }
      getMonthlyCosts(value, maxLoanTime)
      this.$store.commit('updateApplication', { key, value })
    },
    createSlider(value) {
      let val
      if (value) {
        val = value
      } else {
        val = this.data.amount_index
      }
      this.oldVariant = this.variantStyle
      setSliderColor({
        elementId: 'loanrange',
        min: 1,
        max: this.loanAmounts.length,
        value: val,
        firstColor: this.variantStyle?.first,
        secondColor: this.variantStyle?.second,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.loanamount {
  font-size: 46px;
  @media (max-width: 500px) {
    font-size: 36px;
  }
  border: none;
  text-align: center;
}
</style>
