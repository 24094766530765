<template lang="html">
  <div class="mb-5" ref="header">
    <div v-if="location !== '/'">
      <h2 v-if="fullView" class="mb-3 text-center">
        {{ step }}.
        {{ $t('loan_details') }}
      </h2>
      <h1 v-else class="mb-3 text-center">
        {{ $t('loan_details') }}
      </h1>

      <div class="row">
        <div class="form-group col-sm-6">
          <form-input
            storekey="amount"
            :items="loanSumOptions"
            type="dropdown"
            id="loanamount"
          >
            {{ $t('loan_amount') }}
          </form-input>
        </div>
        <div class="form-group col-sm-6">
          <form-input
            storekey="repayment_time"
            :items="loanTimeOptions"
            type="dropdown"
            id="loantime"
          >
            {{ $t('repayment_period') }}
          </form-input>
        </div>
        <div class="form-group col-6">
          <div>
            {{ $t('monthly_installment') }}
            <small>*</small>
            <br />
          </div>

          <div class="cost-field">
            <span id="costMonthly">{{ computedLoans.costMonthly.toFixed(0) }}</span>
            {{ $t('zl') }}
          </div>
        </div>
        <div class="form-group col-6">
          <div>
            {{ $t('interest_and_commissions') }}
            <small>*</small>
            <br />
          </div>

          <div class="cost-field">
            <span id="cost">{{ computedLoans.cost.toFixed(0) }}</span>
            {{ $t('zl') }}
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-end">
      <div class="col-12">
        <form-input
          storekey="loanPurpose"
          type="dropdown"
          :items="loanPurposeOptions"
          id="loanpurpose"
        >
          {{ $t('loan_purpose') }}
        </form-input>
      </div>
      <div class="col-12 pt-4">
        <form-input type="checkbox" storekey="tos" id="tos">
          {{ $t('i_agree_with') }} {{ ' ' }}
          <b-link :to="$root.rn.TosPDF" class="tos-link" target="_blank">
            {{ $t('terms_of_service_of_using_the_website') }}
          </b-link>
          przez Saverium.pl.
        </form-input>
      </div>
    </div>
  </div>
</template>

<script>
import { loanTimeOptions } from '@/utils/saverium_pl/const'
import { generateAmountSteps, getApproximateLoanValues } from '@/utils/utils'
import { gsap } from 'gsap'

import FormInput from '@/components/form/FormInput'

export default {
  created() {
    const uuid = this.$route.params.uuid
    if (uuid) {
      this.$store.dispatch('getLeadInfo', {
        uuid,
      })
    }
  },
  props: ['fullView', 'step'],
  data() {
    return {
      cost: 0,
      costMonthly: 0,
      requiredFields: ['loanPurpose'],
      location: window.location.pathname,
      error: false,
    }
  },
  components: {
    FormInput,
  },
  computed: {
    loanTimeOptions() {
      return loanTimeOptions
    },
    loanPurposeOptions() {
      return this.$store.state.enums.loanPurposeOptions
    },
    loanSumOptions() {
      let options = generateAmountSteps(5000, 100, 100)
      options = options.concat(generateAmountSteps(10000, 5000, 500))
      options = options.concat(generateAmountSteps(200500, 10000, 500))
      return options
    },
    computedLoans() {
      let delay = 0.5
      let computed = getApproximateLoanValues(
        this.$store.state.application.amount,
        this.$store.state.application.repayment_time
      )
      gsap.to(this.$data, { costMonthly: computed.costMonthly, duration: delay })
      gsap.to(this.$data, { cost: computed.cost, duration: delay })
      return this.$data
    },
    stepErrors() {
      return this.$store.state.application.stepErrors
    },
  },
  watch: {
    stepErrors(newV) {
      if (this.fullView && newV[0] === parseInt(this.step, 10)) {
        this.$refs.header.scrollIntoView()
      }
      if (Object.keys(this.errors).some(e => this.requiredFields.includes(e))) {
        this.error = true
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
