<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til dyrlægeregninger</h1>
      <div class="top-text text-container">
        <p>
          Har du brug for et lån til at dække dyrlægeregningen, så er du havnet det
          rigtige sted. Vi hjælper dig med at finde det helt rette lån til dig på få
          minutter, så du kan betale din dyrlægeregning.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#boer-man-laane-til-at-daekke-dyrlaegeregningen"
                    >Bør man låne til at dække dyrlægeregningen</a
                  >
                </li>
                <li>
                  <a href="#faa-hjaelp-til-baade-store-og-smaa-dyrlaegeregninger"
                    >Få hjælp til både store og små dyrlægeregninger</a
                  >
                </li>
                <li>
                  <a href="#hvad-koster-typiske-regninger-fra-dyrlaegen"
                    >Hvad koster typiske regninger fra dyrlægen?</a
                  >
                </li>
                <li><a href="#oensker-du-vores-hjaelp">Ønsker du vores hjælp?</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="boer-man-laane-til-at-daekke-dyrlaegeregningen">
            Bør man låne til at dække dyrlægeregningen
          </h2>
          <p>
            Vi skal lade det være helt op til dig, om du ønsker at låne til regningen fra
            dyrlægen, men for langt de fleste bliver det svært at undgå at sende deres
            kæledyr eller produktionsdyr til dyrlægen, hvis der er behov for det.
          </p>
          <p>
            Dermed kommer der en regning, som skal betales, og hvis ikke regningen kan
            dækkes med din nuværende økonomi, er et lån eller en registrering i RKI ofte
            de to eneste muligheder.
          </p>
          <h3>Lån til kæledyrets dyrlægeregning</h3>
          <p>
            Et kæledyr er for mange familier en del af familien, og mange vil derfor gå
            langt, for at redde deres kæledyr. Det gælder uanset om det er en kat, hest,
            hund, hamster eller kanin.
          </p>
          <p>
            Selv om aflivning eller aflevering på et internat ofte er en billigere
            mulighed, så vælger de fleste at tage deres kæledyr til dyrlægen og betale for
            den medicin eller operation, som dyret har behov for. Dette gælder også hvis
            det indebærer et lån for at kunne betale dyrlægeregningen.
          </p>
          <h3>Lån til dyrlæge for produktionsdyr</h3>
          <p>
            Hvis du har et sygt produktionsdyr så som en ko, et får, høns eller andet, så
            vil der oftes ikke være lige så store følelser forbundet med dyret. Dermed
            ikke sagt, at der ikke kan være det.
          </p>
          <p>
            Her vil der oftere skulle laves en mere kynisk beregning af, om et det kan
            betale sig at holde kreaturet i live, og om omkostningerne til at låne til
            dyrlægeregningen kan betale sig. Hvis produktionsdyret har stor værdi, kan en
            større operation eller dyr antibiotika ofte betale sig.
          </p>
          <p>
            Har en høne derimod brækket benet eller fået en stor skade på vingen, vil det
            ofte bedre kunne betale sig af aflive dyret, og dét vil derfor også ofte kunne
            blive den endelige beslutning.
          </p>
        </div>

        <div class="text-container">
          <h2 id="faa-hjaelp-til-baade-store-og-smaa-dyrlaegeregninger">
            Få hjælp til både store og små dyrlægeregninger
          </h2>
          <p>
            Uanset om du ønsker at låne til dyrlægeregningen, så du kan få aflivet din
            lille kanin, eller låne til en større og omkostningstung operation på vitale
            organer inkl. transport, genoptræning og bedøvelse af fx en stor hest, så er
            vi klar til at hjælpe dig med at finde det rette lån.
          </p>
          <p>
            Hvis du ønsker at
            <a
              href="https://www.saverium.dk/laan-penge/"
              rel="noopener noreferrer"
              target="_blank"
              >låne penge</a
            >, kan vi hjælpe dig med at indhente og
            <a
              href="https://www.saverium.dk/laan-penge/forbrugslaan"
              rel="noopener noreferrer"
              target="_blank"
              >sammenligne tilbud på forbrugslån</a
            >
            fra en lang række banker, som du kan bruge til at dække regningen fra
            dyrlægen. Dermed undgår du at spilde en masse tid på at kontakte en lang række
            banker og prøve at skabe overblik over alle de tilbud og afslag på lån, som
            skal dække din dyrlægeregning.
          </p>
          <p>
            Vi præsenterer alle de tilbud, som vi kan indhente til dig, på en overskuelig
            måde, så du nemt kan vælge det hurtigste og billigste tilbud.
          </p>
        </div>

        <div class="text-container">
          <h2 id="hvad-koster-typiske-regninger-fra-dyrlaegen">
            Hvad koster typiske regninger fra dyrlægen?
          </h2>
          <p>
            Herunder vil vi gennemgå nogle af de mest almindelige dyrlægeregninger for
            kæledyr.
          </p>
          <ul>
            <li>
              Konsultation hos dyrlægen er ofte den første dyrlægeregning du støder på, da
              dit kæledyr har behov for en diagnose, før det kan få behandling. Dette
              koster ofte 500-1.000 kr. alt efter hvilken dyrlæge du benytter.
            </li>
            <li>
              Vaccinationer og chipmærkning er også meget gængse procedurer, som de fleste
              dyr modtager på samme vis som de fleste mennesker (dog ikke chipmærkningen).
              Dette koster ofte 250-1.000 kr. plus prisen på selve vaccinen, som kan
              variere en del mere.
            </li>
            <li>
              Kastration for handyr og sterilization for hundyr ligger på 1.000-3.000 kr.
              for katte og 1.000 til 1.500 kr. højere for hunde. Ønsker du hele pakken med
              C-mærkning i øret, ormekur m.v. skal du forvente endnu et par tusinde kroner
              oven i.
            </li>
            <li>
              Tandrensning er også en af de mest normale procedurer, som foretages hos
              dyrlægen, her skal du forvente at slippe 1.500-2.000 kr.
            </li>
            <li>
              Den sidste typiske udgift er aflivning og kremering. Dette koster normalt
              500-2.000 kr. plus medicin. Det er ofte dyrets vægt, som afgør, om det er i
              den lave eller høje ende af prisskalaen.
            </li>
          </ul>
          <p>
            At have et kæledyr kan hurtigt løbe op i mange tusinde kroner, hvis du skal
            forbi dyrlægen. Derfor bliver et lån til dyrlægeregningen også ofte en
            realitet for mange dyreejere.
          </p>
        </div>

        <div class="text-container">
          <h2 id="oensker-du-vores-hjaelp">Ønsker du vores hjælp?</h2>
          <p>
            Uanset hvilket behov du måtte stå med, så hjælper vi dig gerne med at finde
            det rette lån til at dække din dyrlægeregning. Processen er den samme, uanset
            om det er et lån til dyrlægeregningen eller et lån til noget helt andet, som
            du ønsker. Du benytter blot vores ansøgningsformular her på siden, hvorefter
            vi, med det samme, indhenter tilbud til dig, så du nemt og overskueligt kan
            vælge det lån, som passer til dig og dit behov.
          </p>
          <p>
            Har du brug for hjælp, så kan du med fordel læse vores FAQ, eller kontakte
            vores kundeservice på email eller telefon.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
