<template>
  <div>
    <div class="article">
      <h1 class="center-title">Låneberegner</h1>
      <div class="top-text text-container">
        <p>
          En låneberegner er et basalt værktøj, der kan give dig en fornemmelse af, hvad
          det vil koste at optage et lån hvilket du kan tjekke igennem Saverium.dk.
          Låneberegneren kan derfor bruges, hvis du gerne vil have et estimat over, hvor
          meget et lån vil koste, før du går videre i processen.
        </p>
        <p>
          Husk dog, at sådanne låneberegninger kun er vejledende. Har du lavet en
          låneberegning, er det derfor ikke ensbetydende med, at dit
          <b-link :to="$root.rn.LoanOffers">lånetilbud</b-link> vil være ensartet med
          denne beregning.
        </p>
        <p>
          I beregningen er der nemlig ikke taget højde for dine personlige og økonomiske
          forhold, som i sidste ende er afgørende for, hvad lånet vil koste. Vores
          låneberegner til alle slags lån kan give et fornuftigt billede af prisniveauet,
          men er dog under alle omstændigheder kun vejledende.
        </p>
        <p>
          Vores beregner er utrolig nem at bruge. Du skal blot indstille det ønskede
          lånebeløb ved hjælp af slideren, mens den ønskede løbetid justeres med
          henholdsvis plus- og minusknapper på hver sin side af tallet. Når du har
          indstillet dette, kan du se en forventet månedlig ydelse.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul v-for="(text, i) in contents" :key="i">
                <li>
                  <a :href="text.link">
                    {{ text.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="1">Brug Saverium.dk´s låneberegner til dit personlige lån</h2>
          <p>
            Det behøver ikke at være besværligt eller tidskrævende at indhente en håndfuld
            lånetilbud, som du kan sammenligne med hinanden.
          </p>
          <p>
            Hos Saverium.dk kan du hurtig bruge vores låneberegner og nemt udfylde en
            enkelt låneansøgning og modtage lånetilbud fra flere lånevirksomheder. Vi
            samarbejder med bl.a. Basisbank, Modus Finans, Fellow Finance, Resurs Bank,
            Express Bank og LånLet. Det er derfor dem, du vil modtage tilbud fra samt
            andre.
          </p>
          <p>
            Samtlige lånetilbud er udarbejdet personligt til dig – og naturligvis med
            udgangspunkt i de informationer, der er angivet i din ansøgning. Derfor vil du
            blive præsenteret for en række tilbud, der er skræddersyet til netop dig.
          </p>
          <p>
            Alle lånetilbud du modtager via Saverium.dk er naturligvis uforpligtende og
            gratis. Hvis ikke der er et af de modtagne tilbud, der appellerer til dig, er
            du i din gode ret til at afvise dem alle.
          </p>
          <p>
            Fælles for samtlige lånetilbud er, at der hverken stilles krav om
            sikkerhedsstillelse eller dokumentation. Ønsker du en indikation af, hvad
            koster det at
            <b-link :to="$root.rn.UnsecuredLoans">låne uden sikkerhed</b-link>, kan du
            passende bruges vores låneberegner til at beregne, før du udvælger dig et
            tilbud.
          </p>
        </div>
        <div class="text-container">
          <h2 id="2">
            Få et vejledende overblik over dine låneomkostninger med vores beregner
          </h2>
          <p>
            Når du bruger vores låneberegner til at beregne dine
            <b-link :to="$root.rn.LoanOptions">lånemuligheder</b-link>, er det essentielt,
            at du er opmærksom på, at prisen kun er vejledende. Denne beregning af lån er
            derfor ikke nødvendigvis ens med det eller de tilbud, du modtager.
          </p>
          <p>
            For at kunne en præcis pris, er långiverne nødt til først at behandle din
            ansøgning. I denne sammenhæng udfører de nemlig også en kreditvurdering af
            dig. Det vil sige, at du ikke kan se den reelle pris, før du har modtaget
            konkret tilbud fra udbyderne.
          </p>
          <p>
            Det er dog slet ikke ensbetydende med, at en låneberegner til
            <b-link :to="$root.rn.ConsumerLoans">forbrugslån</b-link> ikke kan bruges til
            noget. En sådan beregner kan bruges til at give en indikation af, hvad det vil
            koste dig at optage et lån. Har du styr på dit budget og dit rådighedsbeløb,
            kan du nemlig via vores forbrugslån beregner danne dig et hurtigt overblik
            over, hvor meget du forventeligt kan låne.
          </p>
          <p>
            Husk dog, at vores låneberegner med fast ydelse ikke tager højde for dine
            personlige forhold, hvorfor det ikke kan garanteres, at de tal, der vises i
            vores beregner, er retvisende for, hvordan dine lånetilbud kommer til at se
            ud.
          </p>
          <p>
            Hvad det vil sige er, vil du gerne have et nogenlunde billede af, hvad det vil
            koste at optage et lån, så beregn et lån som eksempel med vores låneberegner.
            Vil du have et konkret svar på, hvad et lån vil koste, er du nødt til at
            indhente konkrete, personlige lånetilbud.
          </p>
        </div>
        <div class="text-container">
          <h3>Sådan bruger du låneberegneren</h3>
          <p>
            Hvad enten du ønsker at bruge vores låneberegner til
            <b-link :to="$root.rn.CarLoan">billån</b-link>, huslån,
            <b-link :to="$root.rn.Overdraft">kassekredit uden sikkerhed</b-link> eller
            noget helt fjerde, er den utrolig nem at bruge.
          </p>
          <p>
            Du skal intet andet gøre, end at indstille slideren med det ønskede lånebeløb,
            hvorefter den ønskede løbetid kan justeres med hhv. plus- og minusknapperne
            hver sin side af tallet.
          </p>
          <p>
            Når du har indstillet den ønskede løbetid og det ønskede lånebeløb,
            præsenteres du naturligvis for den forventede månedlige ydelse med det samme.
            Derved kan du lynhurtigt få en indikation af, hvad du kan låne uden om banken
            – og hvad det vil koste dig pr måned.
          </p>
          <p>
            I og med, at du med en låneberegner nu kan få et estimat på, hvad et lån
            koster, kan du dermed vurdere, hvorvidt det kan betale sig at udbede dig
            tilbud på det ønskede beløb. Kan du se, at den månedlige ydelse ikke
            harmonerer med dit rådighedsbeløb, giver det naturligvis ikke mening.
          </p>
        </div>
        <div class="text-container">
          <h3>Direkte sammenhæng mellem løbetid og ydelsen</h3>
          <p>
            Uanset hvilken type lån, du har i sinde at optage, er der flere elementer, der
            har indflydelse på den månedlige ydelse og lånets samlede omkostninger. En af
            de ting, der har størst indvirkning på såvel ydelse som omkostninger, er
            lånets samlede løbetid.
          </p>
          <p>
            Som også forholdsvis nemt ses med hjælp fra låneberegneren, falder den
            månedlige ydelse, hvis du beslutter at betale af over længere tid. Det
            medfører, at jo længere løbetid du vælger, jo lavere bliver din månedlige
            ydelse.
          </p>
          <p>
            For de fleste brugere af vores låneberegner virker det sandsynligvis
            attraktivt at vælge en lav månedlig ydelse. Desværre er det dog ikke helt så
            attraktivt, som det ser ud til at være.
          </p>
          <p>
            Ulempen ved at have en lang løbetid på et lån er, at en længere løbetid
            resulterer i, at dine låneomkostninger stiger. Ser man på det samlede
            tilbagebetalingsbeløb, vil dette stige for hvert yderligere afdrag, du
            forlænger aftalen med.
          </p>
          <p>
            Det skyldes, at du betaler renter og gebyrer i flere måneder, end hvis du
            valgte en kortere løbetid. Jo længere løbetiden er, jo større en andel af det
            samlede tilbagebetalte beløb vil være bestående af renter og gebyrer. Dette
            vil du kunne se med fx en ÅOP beregner, der kan beregne ÅOP og vise lånets
            samlede udgifter.
          </p>
          <p>
            Ønsker du at vide, hvad du skal betale hver måned, når du optager et givent
            lån, så beregn ydelse på lån med vores låneberegner, men vær opmærksom på, at
            en lavere ydelse ikke er ensbetydende med et
            <b-link :to="$root.rn.CheapLoan">billigere lån</b-link> – tværtimod.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">Vigtigt at få flere tilbud ved optagelse af lån</h2>
          <p>
            I vores låneberegner ses renter, gebyrer mv. ikke. Derimod vises blot den
            månedlige ydelse. Derfor kan det give en indikation af, hvor meget du har råd
            til at låne, og hvad det koster.
          </p>
          <p>
            Hvad enten du søger et lån til bolig, bil eller forbrug, kræver det konkrete
            tilbud, hvis du vil vide dig sikker på, hvad det koster for netop dig. Et
            enkelt tilbud er dog ikke tilstrækkeligt, da de forskellige låneudbydere har
            forskellige priser.
          </p>
          <p>
            Årsagen til, at det er vigtigt, at du indhenter flere tilbud, som kan
            sammenlignes og holdes op mod hinanden, er for at undgå, at du optager et lån,
            der ganske enkelt er for dyrt.
          </p>
          <p>
            Der kan nemlig være rigtig mange penge at spare, ved at vælge det rette
            lånetilbud, hvad enten du leder efter et boliglån, banklån eller en helt
            tredje type kredit lån. Selv i tilfælde af, at det er mindre lån, kan
            besparelsen lyder på rigtig mange tusinde kroner.
          </p>
          <p>
            Det vidner naturligvis om, at det ikke er tilstrækkeligt at benytte en låne
            beregner, hvorefter du blot indhenter et enkelt tilbud. Du er nødt til at bede
            om flere tilbud – og her kan Saverium.dk heldigvis nemt hjælpe.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">
            Hvor meget kan jeg låne? – Brug vores låneberegner for at finde dit passende
            lån
          </h2>
          <p>
            Når du har brugt vores låneberegner og afsendt din låneansøgning, begynder det
            hele at køre automatisk. Når en onlinebaseret lånevirksomhed skal vurdere,
            hvor meget du kan låne, og hvad det skal koste, er der flere elementer, der
            har betydning. Begge dele afgøres af en kreditvurdering.
          </p>
          <p>
            En kreditvurdering er et redskab, som långiverne bruger til at vurdere dine og
            deres risici ved at indgå en låneaftale. Hvis du skal gøre dig forhåbninger om
            at <b-link :to="$root.rn.MainView">låne penge</b-link> til en lav pris, er det
            essentielt, at du kan bryste dig af en god kreditværdighed.
          </p>
          <p>
            Lidt forsimplet kan det nemlig siges, at jo lavere en risiko långiver
            vurderer, at der er for, at du ikke har råd til at betale lånet tilbage, jo
            mindre vil det koste dig at optage det respektive lån.
          </p>
          <p>
            Når låneudbyderne udarbejder en kreditvurdering, er der flere ting, de kigger
            nærmere på. Nogle af de mest afgørende faktorer er:
          </p>
          <ul>
            <li>Din indkomst</li>
            <li>Din civilstatus (og eventuelle medansøgere)</li>
            <li>Dine udgifter og rådighedsbeløb</li>
            <li>Din eksisterende gæld</li>
            <li>Din RKI-status</li>
          </ul>
          <h3>Din indkomst</h3>
          <p>
            Det første en hvilken som helst långivere kigger på, er din personlige
            indkomst.
          </p>
          <p>
            Din indkomst vil nemlig give den pågældende udbyder et billede af, hvor stor
            en sum penge, der er tilovers, når alle dine faste udgifter er betalt. Derfor
            er det også afgørende for, hvor meget gæld du kan optage i form af et lån.
          </p>
          <p>
            Jo længere løbetid et lån har, jo flere penge kan låneudbyderen tjene. Det
            skyldes, at dit lånebeløb forrentes over en længere periode.
          </p>
          <p>
            Det er forskelligt, hvordan långiverne vurderer din indkomst. Nogle steder
            vurderes din indkomst ud fra de seneste tre måneder, mens andre långivere
            bruger din seneste årsopgørelse.
          </p>
          <h3>Din civilstatus</h3>
          <p>
            Noget der er vanskeligt at tage højde, når du bruger en låneberegner, er din
            civilstatus. Men faktisk er det et parameter, der kan have stor indflydelse
            på, hvad det koster at låne, og hvor meget du kan låne.
          </p>
          <p>
            Din civilstatus har nemlig betydning for din husstandsindkomst, og dermed også
            for, hvor mange penge i som husstand har mulighed for at låne.
          </p>
          <p>
            Hvad det vil sige er, at din partner vil give dig et større økonomisk råderum.
            Det er en fordel, da det vil resultere i et billigere lån. Er du ikke gift, er
            det ikke en mulighed, men der er andre muligheder for at spare penge.
          </p>
          <p>
            Er du ikke gift, kan du i stedet overveje at finde en medansøger. Det vil dig
            en række af de samme fordele, der er forbundet med at ansøge om et lån sammen
            med en partner.
          </p>
          <h3>Dine udgifter og rådighedsbeløb</h3>
          <p>
            Låneudbyderne er naturligvis interesserede i dit rådighedsbeløb. Har du ikke
            et rådighedsbeløb, der er tilstrækkeligt højt til at betale de månedlige
            ydelser, vil din ansøgning blive afvist.
          </p>
          <p>
            Dette bør du dog ikke se som en ulempe. Mange tænker blot: "Hvad kan jeg
            låne?", hvis de skal
            <b-link :to="$root.rn.MainView">låne penge på nettet</b-link>. Sådan bør man
            dog ikke tænke. I stedet skal dit fokus være at optage et lån, der ikke er
            større, end at din økonomi kan bære det.
          </p>
          <p>
            Derfor er det også altafgørende, at du har et godt overblik over såvel
            udgifter som rådighedsbeløb, før du kaster dig ud i at låne penge.
          </p>
          <p>
            Får du lavet en renteberegning af en lånekalkulator eller en låneberegner, er
            dit rådighedsbeløb bl.a. ét af de aspekter, der ikke kan tages højde for.
            Derfor er det selvfølgelig også én af årsagerne til, at en sådan låneberegning
            ikke er hundrede procent retvisende.
          </p>
          <h3>Din eksisterende gæld</h3>
          <p>
            Eksisterende gæld er også et parameter, der inddrages, når låneudbyderne
            udarbejder deres kreditvurdering af dig. Det skyldes, at det har direkte
            betydning for dit rådighedsbeløb, da den eksisterende gæld naturligvis skal
            afbetales.
          </p>
          <p>
            Har du en høj gældsbyrde, kan det derfor være en hæmsko for
            <b-link :to="$root.rn.NewLoan">optagelse af et nyt lån</b-link>. I de fleste
            tilfælde er det ikke en direkte forhindring, men det vil sandsynligvis
            resultere i højere rente- og gebyromkostninger. Dette kan du undersøge ved at
            bruge en låneberegner.
          </p>
          <p>
            Hvis du i forvejen har stiftet meget gæld, er det derfor værd at tage et
            yderligere lån op til genovervejelse. For at forbedre din kreditværdighed,
            skal dit rådighedsbeløb øges, hvilket bedst gøres ved at afvikle dine
            nuværende gældsposter.
          </p>
          <h3>Din RKI-status</h3>
          <p>
            Er du registreret i RKI, er det ikke muligt at optage et lån. Så kort kan det
            siges. Det er gældende, hvad enten du ønsker at optage et mindre kreditlån, et
            stort <b-link :to="$root.rn.BoatLoan">bådlån</b-link> eller en helt tredje
            type lån.
          </p>
          <p>
            Hvis du er blevet registreret i RKI (Ribers Kredit Information), skyldes det,
            at du har ubetalt gæld hos en eller flere kreditorer. Det viser andre
            kreditgivere, at du ikke mestrer at betale din gæld, hvorfor de ikke ønsker at
            bevillige dig et lån. Uheldigvis kan en låneberegner ikke bruges i denne her
            situation til at hjælpe dig.
          </p>
          <p>
            Af den grund kan det heller ikke lade sig gøre at optage et lån trods RKI. Vil
            du låne penge, er der derfor ingen anden udvej, end at komme ud af RKI. Det
            kan du gøre på to måder:
          </p>
          <ol>
            <li>Du kan betale den gæld, der har fået dig registreret i RKI</li>
            <li>
              Du kan vente fem år, hvorefter du automatisk slettes fra registeret. Det
              betyder dog ikke, at din gæld væk – det er kun din registrering
            </li>
          </ol>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  data() {
    return {
      contents: [
        {
          title: 'Brug Saverium.dk´s låneberegner til dit personlige lån',
          link: '#1',
        },
        {
          title:
            'Få et vejledende overblik over dine låneomkostninger med vores beregner',
          link: '#2',
        },
        {
          title: 'Vigtigt at få flere tilbud ved optagelse af lån',
          link: '#3',
        },
        {
          title:
            'Hvor meget kan jeg låne? – Brug vores låneberegner for at finde dit passende lån',
          link: '#4',
        },
      ],
    }
  },
  methods: {
    setLoan(value) {
      this.loanamount = value
      const key = 'amount'
      this.$store.commit('updateApplication', { key, value })
      this.$router
        .push({
          name: 'DynamicLoanPage',
          params: { loanamount: this.loanamount },
        })
        .catch(() => {})
    },
  },
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
