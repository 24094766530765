<template>
  <div class="article-wrapper" data-type="1" data-language="pl">
    <div class="article row col-12">
      <h1 class="center-title">
        Kredyt na umowę zlecenie - kiedy jest możliwe uzyskanie?
      </h1>

      <div class="row col-12">
        <div class="article-container col-12 col-lg-8">
          <div class="text-container">
            <p>
              Przyjęło się, że o kredyt mogą starać się wyłącznie osoby zatrudnione w
              ramach umowy o pracę. Dla banku tego rodzaju kontrakt stanowi najlepsze
              zabezpieczenie kredytu, lecz nie oznacza to, że osoby zatrudnione w ramach
              umowy zlecenie nie mogą pozyskać finansowania. Na jakich warunkach
              przyznawany jest kredyt i kiedy można go otrzymać, jeśli klient posiada
              umowę cywilnoprawną, a nie umowę o pracę?
            </p>

            <b-img-lazy
              width="700"
              height="466"
              blank-src="/static/img/saverium_pl/article-images/contract-loan-tiny.webp"
              blank-height="700px"
              blank-width="466px"
              class="article-img"
              src="/static/img/saverium_pl/article-images/contract-loan.webp"
              alt="Kredyt na umowę zlecenie - kiedy jest możliwe uzyskanie?"
            />
            <div class="toc container">
              <h3>{{ $t('table_of_contents') }}</h3>
              <ul class="table-of-contents">
                <li>
                  <a href="#kredyt-a-umowa-zlecenie-podstawowe-warunki"
                    >Kredyt, a umowa zlecenie - podstawowe warunki</a
                  >
                </li>
                <li>
                  <a href="#jak-przebiega-ocena-zdolnosci-kredytowej-przy-umowie-zlecenie"
                    >Jak przebiega ocena zdolności kredytowej przy umowie zlecenie?</a
                  >
                </li>
                <li>
                  <a href="#jaka-kwote-kredytu-mozna-otrzymac-przy-umowie-zlecenie"
                    >Jaką kwotę kredytu można otrzymać przy umowie zlecenie?</a
                  >
                </li>
                <li>
                  <a href="#umowa-zlecenie-szansa-czy-ryzyko"
                    >Umowa zlecenie - szansa czy ryzyko?</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="text-container">
            <h2 id="kredyt-a-umowa-zlecenie-podstawowe-warunki">
              Kredyt, a umowa zlecenie - podstawowe warunki
            </h2>
            <p>
              Większość klientów, która zainteresowana jest kredytem, zadaje sobie
              pytanie, po jakim czasie dostanę kredyt na umowę zlecenie? Banki wymagają,
              aby okres zatrudnienia wynosił co najmniej 3 miesiące, przy czym niektóre
              instytucje nieco bardziej rygorystycznie podchodzą do klientów, wymagając
              minimum półrocznego zatrudnienia. Ponadto, umowa zlecenie powinna być
              zawarta na czas nieokreślony - w praktyce oznacza to, że w kontrakcie nie
              został jasno sprecyzowany czas jego obowiązywania.
            </p>
            <p>
              Bank jest wtedy gotowy przyznać kredyt przy stałych dochodach na umowie
              zlecenie. Ważne, aby klient przedstawił jedną umowę, która zawarta jest z
              tym samym pracodawcą. Zawieranie co pewien czas nowych umów, w dodatku z
              innymi podmiotami (co często ma miejsce w przypadku tak zwanych spółek
              powiązanych), redukuje szansę na pozyskanie finansowania.
            </p>
          </div>

          <div class="text-container">
            <h2 id="jak-przebiega-ocena-zdolnosci-kredytowej-przy-umowie-zlecenie">
              Jak przebiega ocena zdolności kredytowej przy umowie zlecenie?
            </h2>
            <p>
              Starając się o
              <a
                href="https://www.saverium.pl/artykuly/kredyt-ratalny/"
                rel="noopener noreferrer"
                target="_blank"
                >kredyt ratalny</a
              >, nie należy skupiać się wyłącznie na źródle dochodów - choć jest ono
              istotnym czynnikiem, to z całą pewnością niejedynym. Bardzo często pada
              pytanie, jaki bank udziela kredytu na umowę zlecenie, jednak odpowiedź na to
              pytanie jest prosta - obecnie niemal każda instytucja posiada taką ofertę.
              Różnicuje je przede wszystkim sposób oceny
              <a
                href="https://www.saverium.pl/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/"
                rel="noopener noreferrer"
                target="_blank"
                >zdolności kredytowej klienta</a
              >. Każdy bank bierze pod uwagę:
            </p>
            <ul>
              <li>
                źródło dochodów - w tym przypadku będzie to umowa zlecenie, jednak
                niektóre banki wymagają, aby trwała ona minimum 3 miesiące, inne -
                przynajmniej pół roku,
              </li>
              <li>
                wysokość dochodów - większość banków określa minimalne dochody miesięczne,
                jakie klient musi uzyskiwać,
              </li>
              <li>
                średnie, miesięczne wydatki gospodarstwa domowego - określone są z góry,
                klient nie musi ich dokumentować,
              </li>
              <li>
                pozostałe zobowiązania finansowe - jeśli klient spłaca pożyczkę lub
                kredyt, istnieje ryzyko odmowy udzielenia kolejnego.
              </li>
            </ul>
            <p>
              Dla banków bardzo istotna jest także historia kredytowa klienta. Musi on
              sumiennie spłacać zobowiązania, bowiem
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-z-wpisami-w-bazach/"
                rel="noopener noreferrer"
                target="_blank"
                >kredyt gotówkowy z wpisami w bazach</a
              >
              nie jest produktem, który oferują banki. Klient może złożyć wniosek o kredyt
              przez internet, na przykład za pośrednictwem dedykowanych serwisów.
            </p>
          </div>

          <div class="text-container">
            <h2 id="jaka-kwote-kredytu-mozna-otrzymac-przy-umowie-zlecenie">
              Jaką kwotę kredytu można otrzymać przy umowie zlecenie?
            </h2>
            <p>
              Dla potencjalnego klienta ważna jest przede wszystkim kwota kredytu, jaką
              może on otrzymać. Nie sposób jednoznacznie ocenić, czy w danym przypadku
              bank udzieli finansowania na kilka, czy też kilkadziesiąt tysięcy. Warto
              jednak mieć świadomość, że na wysokość kredytu wpływ ma przede wszystkim
              wartość miesięcznych dochodów oraz czas trwania umowy.
            </p>
            <p>
              Jeśli jesteśmy zatrudnieni od co najmniej kilku miesięcy, a dodatkowo
              uzyskujemy wysokie, stabilne dochody, to z pewnością możemy liczyć na wyższe
              sumy, niż przy kredycie na umowę zlecenie 3-miesięczną. Warto wziąć pod
              uwagę, że jeżeli kontrakt został zawarty na czas określony, to bank udzieli
              kredytu, który będzie musiał być spłacony przed końcem obowiązywania umowy.
              Podobnie jest w przypadku umowy o pracę na czas określony.
            </p>
          </div>

          <div class="text-container">
            <h2 id="umowa-zlecenie-szansa-czy-ryzyko">
              Umowa zlecenie - szansa czy ryzyko?
            </h2>
            <p>
              Obecnie istnieje możliwość otrzymania kredytu gotówkowego przy zatrudnieniu
              w ramach umowy zlecenie, jednak duże znaczenie ma tutaj przede wszystkim
              charakter kontraktu oraz wysokość uzyskiwanych dochodów. Sama nazwa umowy
              ani nie daje większych szans na kredyt, ani też całkowicie ich nie
              przekreśla. Warto mieć świadomość, że wiele umów zlecenie ma charakter umowy
              o pracę, z czego banki doskonale zdają sobie sprawę.
            </p>
            <p>
              Dlatego też coraz częściej można uzyskać
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-na-dowod-bez-umowy-o-prace/"
                rel="noopener noreferrer"
                target="_blank"
                >kredyt gotówkowy na dowód bez umowy o pracę</a
              >, choć oczywiście trzeba też spełnić pozostałe wymagania, stawiane przez
              banki. Alternatywnym rozwiązaniem dla klientów może być
              <a
                href="https://www.saverium.pl/artykuly/pozyczki-bez-zaswiadczen-online-chwilowki-bez-formalnosci/"
                rel="noopener noreferrer"
                target="_blank"
                >pożyczka bez zaświadczeń online</a
              >, jednak ta zwykle udzielana jest przez instytucje pozabankowe - banki
              zawsze, w mniejszym lub większym zakresie, sprawdzają zdolność kredytową
              potencjalnych klientów.
            </p>
          </div>
        </div>
        <div class="container container-widget col-12 col-lg-4">
          <h4>{{ $t('get_a_loan_easily') }}</h4>
          <LoanWidget class="small-range mt-4" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div style="margin: auto">
        <RelatedArticlesComponent />
      </div>
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
export default {
  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
