export default {
  days: ['Niedz.', 'Pon.', 'Wt.', 'Śr.', 'Czw.', 'Piąt.', 'Sob.'],
  months: [
    'Sty.',
    'Lut.',
    'Mar.',
    'Kwi.',
    'Maj',
    'Cze.',
    'Lip.',
    'Sie.',
    'Wrz.',
    'Paź.',
    'Lis.',
    'Gru.',
  ],
  formatLocale: {
    monthsShort: [
      'Styczeń',
      'Luty',
      'Marzec',
      'Kwiecień',
      'Maj',
      'Czerwiec',
      'Lipiec',
      'Sierpień',
      'Wrzesień',
      'Październik',
      'Listopad',
      'Grudzień',
    ],
  },
  placeholder: {
    date: 'Wybierz datę',
  },
}
