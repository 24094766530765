<template lang="html">
  <div class="main">
    <div class="container col-lg-8">
      <h5 class="mt-5 text-center px-2">
        {{ $t('complete_the_loan_application_absolutely_for_free') }}
      </h5>
      <h6 class="mb-5 text-center px-2">
        {{ $t('you_will_reveice_an_immediate_loan_decision') }}
      </h6>
      <div class="form-progress">
        <form-progress :errors="stepErrors" :steps="steps.length" />
      </div>
      <div class="alert alert-danger" ref="hasErrors" role="alert" v-if="hasErrors">
        {{ $t('check_the_fields_marked_in_red') }}
      </div>
      <!--  <component :is="steps[current]" /> -->
      <div class="col-12 mb-5 btn-row">
        <button
          class="back-btn btn btn-back"
          type="button"
          @click="back"
          :key="'back_' + current"
          v-if="current > 0"
        >
          {{ $t('back') }}
        </button>
        <!--    <button
          class="next-btn btn btn-primary"
          type="button"
          @click="next"
          :key="'next_' + current"
          v-if="current < steps.length - 1"
        >
          {{ $t('next') }}
        </button> -->
        <template v-else-if="current === steps.length - 1">
          <div class="spinner-wrapper" v-if="loading">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <!--         <button
            v-else
            class="next-btn btn btn-primary"
            name="submitLoan"
            id="submitLoan"
            @click="submit()"
          >
            {{ $t('apply_for_a_loan_arrow') }}
          </button> -->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import StepOne from '@/components/saverium_pl/steps/StepOne'
import StepTwo from '@/components/saverium_pl/steps/StepTwo'
import StepThree from '@/components/saverium_pl/steps/StepThree'
import StepFour from '@/components/saverium_pl/steps/StepFour'
import StepFive from '@/components/saverium_pl/steps/StepFive'
import StepSix from '@/components/saverium_pl/steps/StepSix'
import FormProgress from '@/components/form/FormProgress'

import { mapState } from 'vuex'

export default {
  data() {
    return {
      stepErrors: [],
      steps: ['StepOne', 'StepTwo', 'StepThree', 'StepFour', 'StepFive', 'StepSix'],
      stepHeaders: [
        ['loanPurpose', 'amount', 'repayment_time', 'tos'],
        [
          'email',
          'phone',
          'firstName',
          'lastName',
          'idnumber',
          'ssn',
          'civilstatus',
          'dependants',
        ],
        [
          'region',
          'street',
          'houseNumber',
          'apartmentNumber',
          'zipCode',
          'city',
          'dwelling',
          'dwellingType',
          'dwellingDate',
          'numberOfPeople',
          'hasSummerHouse',
          'registeredZipCode',
          'registeredCity',
        ],
        [
          'education',
          'occupation',
          'employer',
          'businessId',
          'workPhone',
          'profession',
          'monthlySalary',
          'netMonthlySalary',
          'monthlyNetIncome',
          'otherIncome',
          'otherIncomeReason',
          'lastYearIncome',
          'employedSince',
          'employedUntil',
        ],
        ['costOfLiving', 'otherMonthlyExpenses'],
        [
          'bankAccount',
          'bankName',
          'accountType',
          'accountHolder',
          'personalDataPolicy',
          'consents',
          'marketingConsents',
          'acceptAll',
        ],
      ],
    }
  },
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepSix,
    FormProgress,
  },
  computed: {
    ...mapState({
      errors: state => state.application.errors,
      current: state => state.application.currentStep,
      requiredFields: state => state.application.requiredFields,
      canProceed: state => state.application.formCanProceed,
      loading: state => state.application.sendingApplication,
    }),
    hasErrors() {
      return Object.keys(this.errors).length > 0
    },
    hasErrorsInCurrentStep() {
      let keys = Object.keys(this.errors)
      return this.stepHeaders[this.current].some(v => keys.includes(v))
    },
  },
  watch: {
    hasErrors(newV, oldV) {
      this.checkErrors()
      this.$root.$on('bv::collapse::state', (collapseConsents, isJustShown) => {
        if (isJustShown === false && this.$store.state.application.tos === '0') {
          this.$root.$emit('bv::toggle::collapse', 'collapse-consents')
        }
      })
    },
  },
  created() {
    if (this.hasErrors) {
      this.checkErrors()
    }
  },
  methods: {
    checkErrors() {
      this.max = this.steps.length - 1
      let keys = Object.keys(this.errors)
      this.stepErrors = this.stepHeaders.reduce((acc, cur, index) => {
        let find = cur.some(v => keys.includes(v))
        if (find && !acc.includes(index)) {
          acc.push(index)
        }
        return acc
      }, [])
    },
    back() {
      this.$store.commit('setStepProgress', this.current - 1)
    },
    next() {
      this.$store.dispatch('checkErrors', this.current)
      this.checkErrors()
      if (this.hasErrorsInCurrentStep) {
        return
      }

      this.$store.commit('setStepProgress', this.current + 1)
      window.scrollTo(0, 120)
    },
    submit() {
      this.$store.dispatch('checkErrors', this.current)
      this.checkErrors()
      try {
        if (typeof ga !== 'undefined') {
          ga('send', 'pageview', 'wniosek/sent/')
        }
      } catch (e) {}
      this.$store.dispatch('sendApplication')
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-row {
  height: 40px;
}

.btn,
.submit-btn {
  width: 40%;
}

.next-btn,
.submit-btn {
  float: right;
}

.submit-btn {
  height: 40px;
  .btn {
    width: 100%;
  }
}

.form-progress {
  width: 80%;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}
.spinner-wrapper {
  font-size: 60px;
  text-align: center;
}
</style>
