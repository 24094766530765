<template lang="html">
  <div class="radio-toolbar" :name="name" role="group" @change="input" :value="value">
    <input type="radio" :id="`${id}-1`" :name="name" :value="true" :checked="checked" />
    <label class="radiolabel" :for="`${id}-1`">{{ $t('yes') }}</label>

    <input type="radio" :id="`${id}-2`" :name="name" :value="false" :checked="checked" />
    <label class="radiolabel" :for="`${id}-2`">{{ $t('no') }}</label>
  </div>
</template>

<script>
export default {
  props: ['value', 'placeholder', 'error', 'id', 'name', 'checked'],
  methods: {
    input(event) {
      if (event.target._value !== this.value) {
        this.$emit('input', event.target._value)
      }
    },
  },
}
</script>
