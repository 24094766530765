<template>
  <div>
    <header>
      <section id="smelanding" class="flex-fill">
        <div class="container d-flex main-header-container">
          <div class="left-column flex-fill" style="width: 50%">
            <div class="header-slogan flex-fill">
              {{ $t('reliable_and_fast') }}
            </div>
          </div>
          <b-card class="main-card" id="first-page" no-body>
            <div class="loan-boxes flex-fill">
              <div class="form-box">
                <div class="mt-4">
                  <h1>{{ $t('find_the_best_company_loan') }}</h1>
                  <b-card-text class="blue-mobile">
                    <div class="form-box">
                      <div class="inner d-flex flex-column">
                        <b-alert :show="!draftFound" dismissible fade variant="warning">
                          {{ $t('no_draft_found') }}
                        </b-alert>
                        <SmeMainForm :variant="variant" :versionId="versionId" />
                      </div></div
                  ></b-card-text>
                </div>
              </div>
            </div>
          </b-card>
        </div>
      </section>
    </header>
    <SmeContent />
  </div>
</template>

<script>
import SmeContent from '@/components/saverium_dk/main_page_components/SmeContent'
import SmeMainForm from '@/components/saverium_dk/sme_components/SmeMainForm'

import { mapState } from 'vuex'
import { checkIfInViewport } from '@/utils/utils'

export default {
  mounted() {
    const showPopup = () => {
      const element = document.getElementById('main-card')
      this.showContainerScroller = !checkIfInViewport(element)
    }
    if (window.location.pathname === '/') {
      window.addEventListener('scroll', showPopup)
    }
  },
  created() {
    window.addEventListener('resize', this.checkVariant)
    this.setApplicationType('companyloan')
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkVariant)
  },
  data() {
    return {
      current: 0,
      showNextSmeStep: false,
      debouncedWidth: 0,
      widthTimeout: null,
    }
  },
  components: {
    SmeContent,
    SmeMainForm,
  },

  computed: {
    ...mapState({
      draftFound: state => state.application.draftFound,
      firstName: state => state.application.firstName,
      lastName: state => state.application.lastName,
      email: state => state.application.email,
      loanPurpose: state => state.application.loanPurpose,
      tos: state => state.application.tos,
    }),
    versionId() {
      return this.$store.state.user.versionId
    },
    showFullHeader: function () {
      const path = this.$route.path
      return path === this.$root.rn.MainView
    },

    draftFound() {
      return this.$store.state.application.draftFound
    },

    isAuthenticated() {
      return (
        this.$store.state.oidcStore.oidcIsAuthenticated || this.$store.state.user.loggedIn
      )
    },
    type: {
      get() {
        return this.$store.state.applicationType.type
      },
      set(type) {
        this.$store.state.applicationType.type = type
      },
    },
    variant() {
      return this.checkVariant()
    },
    width: {
      get() {
        return this.debouncedWidth
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.widthTimeout = setTimeout(() => {
          this.debouncedWidth = val
        }, 500)
      },
    },
  },

  methods: {
    setApplicationType(value) {
      this.$store.commit('setApplicationType', value)
    },
    update(key, value) {
      if (key === 'loanrange') {
        key = 'amount_index'
      }
      this.$store.commit('updateApplication', { key, value })
    },

    next() {
      this.$store.dispatch('checkErrors', this.current)
      if (this.hasErrorsInCurrentStep) {
        return
      }

      const fields = [this.firstName, this.lastName, this.email, this.loanPurpose]
      if (!fields.some(field => field === '') && this.tos !== 0) {
        this.$store.commit('setStepProgress', this.current + 1)
        this.$router.push(this.$root.rn.LoanView)
      }
    },
    nextSmeStep() {
      this.showNextSmeStep = true
    },
    checkVariant() {
      this.width = window.innerWidth
      if (this.width > 765) {
        return 'dark'
      } else {
        return 'light'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.desktop {
  display: inline-flex;
}
.mobile {
  display: none;
}
@media all and (max-width: 767px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: contents;
  }
}
.loan-boxes {
  text-align: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
  border-radius: 10px;
  max-width: 800px;
  padding: 1rem;
  background: white;
}
.dropdown-menu:active,
.dropdown-menu:focus {
  border: none;
}
.apply-button {
  text-align: right;
}
.main-header-container {
  min-height: 720px;
}
.alert {
  font-size: 2rem;
}
</style>
