<template lang="html">
  <input
    class="form-control"
    :class="error ? 'is-invalid' : ''"
    type="text"
    :value="value"
    @change="input"
    :placeholder="placeholder"
    :maxlength="maxlength"
  />
</template>

<script>
export default {
  props: ['value', 'placeholder', 'error', 'maxlength'],
  methods: {
    input(event) {
      if (event.target.value !== this.value) {
        this.$emit('input', event.target.value)
      }
    },
  },
}
</script>

<style lang="sass" scoped></style>
