<template>
  <b-col
    class="highlight"
    cols="6"
    sm="6"
    md="6"
    lg="6"
    v-b-modal="`${modalNumber}`"
    :id="`logo-${number}`"
  >
    <b-img class="logo" :src="imgSrc" :alt="title" />
    <div class="logo-modal-wrapper">
      <b-modal :id="modalNumber" centered class="logo-modal">
        <template slot="modal-header">
          <h5>{{ title }}</h5>
          <b-img right class="logo-modal-header" :src="imgSrc" :alt="title" />
        </template>
        <template slot="default">
          <p v-html="$t(`${mainText}`)"></p>
        </template>
        <div slot="modal-footer" slot-scope="{ hide }">
          <b-button variant="primary" size="sm" class="float-right" @click="hide()"
            >Ok</b-button
          >
        </div>
      </b-modal>
    </div>
  </b-col>
</template>

<script>
export default {
  props: {
    imgSrc: { type: String },
    title: { type: String },
    mainText: { type: String },
    modalNumber: { type: String },
    number: { type: Number },
  },
}
</script>
