<template>
  <div>
    <div class="container col-lg-8 mt-5 mb-5">
      <h1>{{ $t('faq_long') }}</h1>
      <p>
        {{ $t('familiarize_with_faq') }}
      </p>
      <h2 class="mt-4 mb-2">{{ $t('loan_application_faq') }}</h2>
      <h3 class="mt-4 mb-2">{{ $t('who_can_apply_loan_faq') }}</h3>
      <p>
        {{ $t('18_year_old_faq') }}
      </p>
      <h3 class="mt-4 mb-2">{{ $t('can_pensioner_faq') }}</h3>
      <p>{{ $t('retirement_faq') }}</p>
      <h3 class="mt-4 mb-2">{{ $t('can_unemployed_faq') }}</h3>
      <p>{{ $t('if_unemployed_faq') }}</p>
      <h2 class="mt-4 mb-2">{{ $t('credit_decision_faq') }}</h2>
      <h3 class="mt-4 mb-2">{{ $t('why_loan_application_rejected_faq') }}</h3>
      <p>{{ $t('rejected_faq') }}</p>
      <h3 class="mt-4 mb-2">{{ $t('can_with_poor_credit_rating') }}</h3>
      <p>{{ $t('poor_rating_faq') }}</p>
      <h3 class="mt-4 mb-2">{{ $t('what_age_limit_faq') }}</h3>
      <p>{{ $t('age_limit_faq') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
}
</script>

<style></style>
