<template lang="html">
  <input
    class="form-control"
    :class="error ? 'is-invalid' : ''"
    type="tel"
    :value="value"
    @change="input"
    :placeholder="placeholder"
    :maxlength="maxlength"
  />
</template>

<script>
export default {
  created() {},
  props: ['value', 'error', 'maxlength', 'placeholder'],
  data() {
    return {
      validator: '',
      firstPart: 0,
    }
  },
  methods: {
    input(event) {
      let val = event.target.value
      if (this.$root.brand === 'saverium_pl') {
        this.validator = /^\d{2}-\d{3}$/

        this.firstPart = 2
      }
      if (!val.includes('-') && val !== this.value) {
        const transform = val => {
          return `${val.substring(0, this.firstPart)}${
            val.length > this.firstPart ? '-' : ''
          }${val.substring(this.firstPart)}`
        }
        let value = transform(val)
        this.$emit('input', value, this.validate(value))
      } else {
        let value = val
        this.$emit('input', value, this.validate(value))
      }
    },
    validate(value) {
      return value.match(new RegExp(this.validator))
    },
  },
}
</script>

<style lang="sass" scoped></style>
