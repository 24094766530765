<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån penge til køb af mad</h1>

      <div class="top-text text-container">
        <p>
          Er din økonomi presset i øjeblikket? Står du og mangler penge til at køb mad
          for? Brug vores gratis online lånesammenligningstjeneste for at modtage nogle af
          de bedste tilbud, som du kan få fra flere velkendte udbydere på det danske
          lånemarked. Udfyld en online ansøgning på vores hjemmeside og sammenlign
          forskellige tilbud, så du kan få det bedste lån til indkøb af mad.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#information-vedroerende-laan-til-mad"
                    >Information vedrørende lån til mad</a
                  >
                </li>
                <li>
                  <a href="#laan-online-til-indkoeb-af-mad"
                    >Lån online til indkøb af mad</a
                  >
                </li>
                <li>
                  <a href="#brug-dit-laan-til-det-mad-du-mangler"
                    >Brug dit lån til det mad du mangler</a
                  >
                </li>
                <li>
                  <a href="#sammenlign-og-vaelg-dit-laan-madindkoeb"
                    >Sammenlign og vælg dit lån madindkøb</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="information-vedroerende-laan-til-mad">
            Information vedrørende lån til mad
          </h2>
          <p>
            Skulle du eller I, stå i den uheldige situation hvor lønnen måske ikke er
            kommet til tiden, eller er der kommet uforudsete regninger, som har gjort at
            kontoen er i minus, og I dermed ikke har råd til at kunne få mad på bordet?
            Frygt ikke! Udfylder du en uforpligtende
            <a
              href="https://www.saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >låneansøgning</a
            >
            igennem os på vores hjemmeside, så skal vi nok sørge for at finde et
            <a
              href="https://www.saverium.dk/laan-penge/hurtiglaan"
              rel="noopener noreferrer"
              target="_blank"
              >hurtigt lån</a
            >, som du kan bruge til at <strong>finansiere </strong>dit indkøb af mad.
          </p>
          <p>
            Uanset hvad du ønsker at bruge dit lån til mad på, så vil vi hjælpe dig med at
            finde de
            <a
              href="https://www.saverium.dk/laan-penge/sammenligning"
              rel="noopener noreferrer"
              target="_blank"
              >billigste lånetilbud</a
            >. Du skal bare vælge beløb, løbetid og udfylde ansøgningen med dine
            oplysninger og afsende den digitalt. Derefter skal du sammenligne og vælge ét
            af de skræddersyet tilbud, som du modtager på din brugerkonto.
          </p>
        </div>

        <div class="text-container">
          <h2 id="laan-online-til-indkoeb-af-mad">Lån online til indkøb af mad</h2>
          <p>
            Leder du efter et
            <a
              href="https://www.saverium.dk/laan-penge/online-laan"
              rel="noopener noreferrer"
              target="_blank"
              >lån online</a
            >, kan det være som at finde en nål i en høstak, især hvis du ønsker at finde
            et
            <a
              href="https://saverium.dk/laan-penge"
              rel="noopener noreferrer"
              target="_blank"
              >nyt lån</a
            >, der passer til dine krav samt økonomi. Der findes rigtig mange forskellige
            velkendte
            <a
              href="https://www.saverium.dk/laan-penge/laaneudbydere"
              rel="noopener noreferrer"
              target="_blank"
              >låneudbydere</a
            >
            på det danske lånemarked, som kunne tænke sig at have som kunde, men det er
            desværre ikke ens betydning med, at alle kan tilbyde dig, hvad du ønsker dig
            for dit kommende lån.
          </p>
          <p>
            Hvis du ønsker at undersøge de forskellige
            <a
              href="https://www.saverium.dk/laan-penge/bolig/laanemuligheder"
              rel="noopener noreferrer"
              target="_blank"
              >lånemuligheder</a
            >
            der findes, så du er sikker på at du ikke siger ja til det første tilbud du
            skulle finde, så er det en god ide at udfylde en gratis ansøgning på vores
            hjemmeside. Vi fremsender din ansøgning til de forskellige banker og
            pengeinstitutter vi arbejder sammen med, så det eneste du skal fortage dig er
            at sammenligne og vælge dit ønskede tilbud.
          </p>
        </div>

        <div class="text-container">
          <h2 id="brug-dit-laan-til-det-mad-du-mangler">
            Brug dit lån til det mad du mangler
          </h2>
          <p>
            Hvis du bestemmer dig for ikke at optage et lån i en fysisk bank, men vælger
            at lede efter et lån online, kan du selv bestemme, hvad du ønsker at bruge dit
            lån til mad på. En af fordelene ved at søge om et online lån, er at selve
            udbyderen ikke behøver at vide, hvad du bruger de lånte penge på. Dermed kan
            du vælge at gå i Bilka, Føtex, Menu eller hvor end du ønsker at handle ind, og
            vælge at købe de madvarer og drikkevarer du mangler derhjemme.
          </p>
          <p>
            Det bedste du kan gøre er at undersøge
            <a href="https://etilbudsavis.dk/" rel="noopener noreferrer" target="_blank"
              >Etilbudsavisen</a
            >
            for hvilket tilbud, som de forskellige supermarkeder kører i øjeblikket, samt
            lave en liste over de ting du mangler. Når du har lavet din indkøbsliste og
            fundet frem til, hvor du skal handle ind, for at få mest ud af dit lån til
            mad, kan du nemt og handle de varer, som du har brug for billigst muligt.
          </p>
          <p>
            Ved at lave en indkøbsliste undgår du også lettere alle de impulskøb af slik,
            sodavand og kager, som du evt. kommer til at købe uden et planlagt madindkøb.
          </p>
          <p>
            Du kan nu udfylde en ansøgning med det
            <a
              href="https://www.saverium.dk/laan-penge/beloeb"
              rel="noopener noreferrer"
              target="_blank"
              >lånebeløb</a
            >, der stemmer overens med de produkter, som du ønsker at købe, så du ikke
            optager et lån med et større beløb end det du rent faktisk mangler mad for.
          </p>
        </div>

        <div class="text-container">
          <h2 id="sammenlign-og-vaelg-dit-laan-madindkoeb">
            Sammenlign og vælg dit lån madindkøb
          </h2>
          <p>
            Hvis du har besluttet dig for at låne penge til dit daglivareindkøb, så lad os
            hjælpe dig med at finde det rette lån.
          </p>
          <p>
            Måden det foregår på, er at du først og fremmest skal udfylde vores
            ansøgningsskema. Denne del af processen tager 2 minutter. Herefter vidresender
            vi de oplysninger, som du har opgivet, til alle vores bankpartnere, som
            vurderer, om de kan tilbyde dig et lån, som du så efterfølgende kan købe mad
            for.
          </p>
          <p>
            Vi opstiller alle de tilbud, som du får, på en nem og overskuelig måde, så du
            hurtigt kan vælge det rette lån til din situation. Når du har fundet den rette
            udbyder, klikker du blot på denne, hvorefter vi sender dig hen på udbyderens
            egen side, som vil verificere de oplysninger du har opgivet. Når dette er
            sket, vil dit dit lån blive udbetalt, så du kan købe mad.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
