<template>
  <div class="article-wrapper" data-type="1" data-language="pl">
    <div class="article row col-12">
      <h1 class="center-title">Wspólny kredyt gotówkowy - co należy wiedzieć?</h1>

      <div class="row col-12">
        <div class="article-container col-12 col-lg-8">
          <div class="text-container">
            <p>
              Wspólny kredyt gotówkowy w małżeństwie nie jest niczym niespotykanym,
              zwłaszcza jeśli para nie zawarła intercyzy. Niemniej jednak
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-kalkulator-i-oferty-pozyczek-gotowkowych/"
                rel="noopener noreferrer"
                target="_blank"
                >kredyt gotówkowy</a
              >
              można zaciągnąć także wraz z inną osobą, niekoniecznie spokrewnioną lub
              spowinowaconą. Jakie warunki należy spełnić, by otrzymać kredyt ze
              współkredytobiorcą i kiedy bank udzieli finansowania?
            </p>

            <b-img-lazy
              width="700"
              height="466"
              blank-src="/static/img/saverium_pl/article-images/joint-cash-loan-tiny.webp"
              blank-height="700px"
              blank-width="466px"
              class="article-img"
              src="/static/img/saverium_pl/article-images/joint-cash-loan.webp"
              alt="Wspólny kredyt gotówkowy - co należy wiedzieć?"
            />
            <div class="toc container">
              <h3>{{ $t('table_of_contents') }}</h3>
              <ul class="table-of-contents">
                <li>
                  <a href="#czym-charakteryzuje-sie-wspolny-kredyt-gotowkowy"
                    >Czym charakteryzuje się wspólny kredyt gotówkowy?</a
                  >
                </li>
                <li>
                  <a href="#jak-ocenia-sie-zdolnosc-kredytowa-przy-kredycie-wspolnym"
                    >Jak ocenia się zdolność kredytową przy kredycie wspólnym?</a
                  >
                </li>
                <li><a href="#splata-wspolnego-kredytu">Spłata wspólnego kredytu</a></li>
                <li>
                  <a href="#kredyt-gotowkowy-razem-czy-osobno"
                    >Kredyt gotówkowy - razem czy osobno?</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="text-container">
            <h2 id="czym-charakteryzuje-sie-wspolny-kredyt-gotowkowy">
              Czym charakteryzuje się wspólny kredyt gotówkowy?
            </h2>
            <p>
              Wspólny kredyt gotówkowy zawsze zaciągają minimum dwie osoby. Mogą być to
              małżonkowie, osoby spokrewnione lub spowinowacone, a także - z punktu
              widzenia prawa - zupełnie sobie obce. Jak się okazuje, banki dopuszczają
              wspólne kredytowanie większej liczby osób - czasami kredytobiorców może być
              aż czterech.
              <a
                href="https://www.saverium.pl/artykuly/kredyt-ratalny/"
                rel="noopener noreferrer"
                target="_blank"
                >Kredyt ratalny</a
              >
              może zostać udzielony klientom, którzy posiadają
              <a
                href="https://www.saverium.pl/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/"
                rel="noopener noreferrer"
                target="_blank"
                >zdolność kredytową</a
              >. Bank ocenia zdolność kredytową wszystkich potencjalnych kredytobiorców.
              Ponadto, odpowiadają oni solidarnie za zobowiązanie, co oznacza, że w
              przypadku braku płatności bank przedstawi roszczenia wobec wszystkich,
              którzy zaciągnęli kredyt. Dla instytucji finansującej nie ma natomiast
              znaczenia, kto tak naprawdę korzysta ze środków pieniężnych i na jakich
              warunkach.
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-na-dowolny-cel/"
                rel="noopener noreferrer"
                target="_blank"
                >Kredyt gotówkowy przyznawany jest na dowolny cel</a
              >, zatem pieniądze można wydatkować w dowolny sposób.
            </p>
          </div>

          <div class="text-container">
            <h2 id="jak-ocenia-sie-zdolnosc-kredytowa-przy-kredycie-wspolnym">
              Jak ocenia się zdolność kredytową przy kredycie wspólnym?
            </h2>
            <p>
              Odpowiedź na pytanie, czy można wziąć kredyt we dwie osoby, jest oczywista -
              banki dopuszczają wspólne kredytowanie także większej liczby osób. Niemniej
              jednak, zanim udzielą finansowanie, badają zdolność kredytową wszystkich
              potencjalnych kredytobiorców. Z jednej strony wymaga się, aby każdy klient
              dostarczył niezbędną dokumentację, z drugiej strony zdolność kredytowa
              wszystkich ubiegających się o finansowanie nie musi być taka sama. Dobrym
              przykładem jest małżeństwo, które chce zaciągnąć kredyt ratalny, przy czym
              tylko jeden z małżonków uzyskuje wysokie dochody, natomiast zarobki drugiego
              kształtują się na niskim poziomie. W takim przypadku bank - przy założeniu,
              że klienci spełniają wszelkie wymogi - udzieli kredytu. Warto jednak
              pamiętać, że szansę na uzyskanie finansowania maleją, jeśli choć jeden z
              kredytobiorców:
            </p>
            <ul>
              <li>posiada złą historię kredytową,</li>
              <li>posiada inne zobowiązania, na przykład kredyt hipoteczny,</li>
              <li>nie posiada dochodów własnych.</li>
            </ul>
            <p>
              Wspólny
              <a
                href="https://www.saverium.pl/artykuly/kredyt-przez-internet/"
                rel="noopener noreferrer"
                target="_blank"
                >kredyt przez internet</a
              >
              to rozwiązanie dla tych, którzy sumiennie spłacają zobowiązania, a na moment
              składania wniosku nie są obciążeni dodatkowymi kredytami czy pożyczkami. W
              przeciwnym razie bank może odmówić finansowania.
            </p>
          </div>

          <div class="text-container">
            <h2 id="splata-wspolnego-kredytu">Spłata wspólnego kredytu</h2>
            <p>
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-bez-zaswiadczen-online/"
                rel="noopener noreferrer"
                target="_blank"
                >Kredyt gotówkowy bez zaświadczeń online</a
              >
              spłaca się w wygodnych, miesięcznych ratach. Co istotne, wszyscy
              kredytobiorcy odpowiadają solidarnie za zobowiązanie, co oznacza, że bank
              może egzekwować płatności od każdego z nich. Mogą oni spłacać kredyt w
              równych częściach, lecz równie dobrze płatnością można obciążyć wyłącznie
              jednego kredytobiorcę - jest to kwestią swobodnych ustaleń pomiędzy
              stronami. Niemniej jednak warto zastanowić się, z kim chcemy zaciągnąć
              kredyt, zwłaszcza jeśli będziemy spłacać go wspólnie. Dobór nieodpowiedniego
              partnera może skutkować tym, że obowiązek spłaty kredytu spocznie wyłącznie
              na nas.
            </p>
          </div>

          <div class="text-container">
            <h2 id="kredyt-gotowkowy-razem-czy-osobno">
              Kredyt gotówkowy - razem czy osobno?
            </h2>
            <p>
              Zaciągnięcie wspólnego zobowiązania wydaje się korzystne, jednak nie zawsze
              takie jest. Czasami bardziej opłaca się wnioskować o kredyt w pojedynkę,
              bowiem wtedy mamy większe szanse na jego otrzymanie. Dobrym przykładem jest
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-na-dowod-bez-umowy-o-prace/"
                rel="noopener noreferrer"
                target="_blank"
                >kredyt gotówkowy na dowód bez umowy o pracę</a
              >
              - jeśli nasz partner nie posiada żadnych dochodów, to nawet z jego
              otrzymaniem możemy mieć problem.
            </p>
            <p>
              Zdolność kredytowa osoby, z którą chcemy zaciągnąć kredyt, czasami może więc
              obniżać naszą zdolność kredytową. Nie sięgajmy po finansowanie wspólnie z
              osobami, których nie znamy bądź którym nie ufamy. Takie działanie może mieć
              przykre konsekwencje, gdyż wbrew ustalonym regułom, będziemy musieli spłacać
              kredyt samodzielnie. Wspólny kredyt gotówkowy najlepiej sprawdza się w
              przypadku par bądź osób, które planują razem w coś zainwestować, na przykład
              otworzyć własną działalność gospodarczą, wspólnie wyremontować dom lub
              mieszkanie czy kupić wymarzony samochód.
            </p>
          </div>
        </div>
        <div class="container container-widget col-12 col-lg-4">
          <h4>{{ $t('get_a_loan_easily') }}</h4>
          <LoanWidget class="small-range mt-4" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div style="margin: auto">
        <RelatedArticlesComponent />
      </div>
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
export default {
  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
