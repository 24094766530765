export const ourPartners = [
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/raiffeisen-small.png',
    title: 'Raiffeisen Digital Bank',
    mainText: 'raiffeisen_text',
    modalNumber: 'modal-0',
    number: 0,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/santander-logo-small.png',
    title: 'Santander Consumer Bank',
    mainText: 'santander_text',
    modalNumber: 'modal-1',
    number: 1,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/lendon-pl-small.png',
    title: 'Lendon.pl',
    mainText: 'lendon_text',
    modalNumber: 'modal-2',
    number: 2,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/hapi-pozyczki-small.png',
    title: 'Hapi pożyzki',
    mainText: 'hapi_pozyczki_text',
    modalNumber: 'modal-3',
    number: 3,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/takto-small.png',
    title: 'Takto',
    mainText: 'takto_text',
    modalNumber: 'modal-4',
    number: 4,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/smartney-small.png',
    title: 'Smartney',
    mainText: 'smartney_text',
    modalNumber: 'modal-5',
    number: 5,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/sg-logo-small.png',
    title: 'Prometeusz Finanse',
    mainText: 'sg_text',
    modalNumber: 'modal-6',
    number: 6,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/aasa-sm.png',
    title: 'Aasa',
    mainText: 'aasa_text',
    modalNumber: 'modal-7',
    number: 7,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/axi-small.png',
    title: 'AXI Card',
    mainText: 'axi_text',
    modalNumber: 'modal-8',
    number: 8,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/vivus-small.png',
    title: 'Vivus',
    mainText: 'vivus_text',
    modalNumber: 'modal-9',
    number: 9,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/kredyt-ok-small.png',
    title: 'Kredyt OK',
    mainText: 'kredyt_ok_text',
    modalNumber: 'modal-10',
    number: 10,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/twino-logo-small.png',
    title: 'NetCredit',
    mainText: 'twino_text',
    modalNumber: 'modal-11',
    number: 11,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/fellow-finance-small.png',
    title: 'Fellow Finance',
    mainText: 'fellow_finance_text',
    modalNumber: 'modal-12',
    number: 12,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/profi-credit-logo-small.png',
    title: 'Profi Credit',
    mainText: 'profi_credit_text',
    modalNumber: 'modal-13',
    number: 13,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/smart-pozyczka-small.png',
    title: 'Smart Pożyczka',
    mainText: 'smart_pozyczka_title',
    modalNumber: 'modal-14',
    number: 14,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/pro-fimo-small.png',
    title: 'PrometeuszFinanse',
    mainText: 'prometeusz_text',
    modalNumber: 'modal-15',
    number: 15,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/bocian-small.png',
    title: 'Bocian',
    mainText: 'bocian_text',
    modalNumber: 'modal-16',
    number: 16,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/pozyczka-plus-small.png',
    title: 'Pożyczka plus',
    mainText: 'pozyczka_plus_text',
    modalNumber: 'modal-17',
    number: 17,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/extraportfel-sm.png',
    title: 'Extraportfel.pl',
    mainText: 'extraportfel_text',
    modalNumber: 'modal-18',
    number: 18,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/miloan-logo.png',
    title: 'Miloan',
    mainText: 'miloan_text',
    modalNumber: 'modal-19',
    number: 19,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/provema-small.png',
    title: 'Provema',
    mainText: 'provema_text',
    modalNumber: 'modal-20',
    number: 20,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/zaplo-logo-sm.png',
    title: 'Zaplo.pl',
    mainText: 'zaplo_text',
    modalNumber: 'modal-21',
    number: 21,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/euroloan-small.png',
    title: 'Euroloan',
    mainText: 'euroloan_text',
    modalNumber: 'modal-22',
    number: 22,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/cardina-logo-small.png',
    title: 'Cardina',
    mainText: 'cardina_text',
    modalNumber: 'modal-23',
    number: 23,
  },
  {
    imgSrc: '/static/img/saverium_pl/partner-logos-sm/provident-logo-small.svg',
    title: 'Provident',
    mainText: 'provident_text',
    modalNumber: 'modal-24',
    number: 24,
  },
]

export const arrangedPartners = [
  {
    src: '/static/img/saverium_pl/partner-logos-sm/raiffeisen-small.png',
    alt: 'Raiffeisen',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/santander-logo-small.png',
    alt: 'Santander Consumer Bank',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/smartney-small.png',
    alt: 'Smartney',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/takto-small.png',
    alt: 'Takto',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/sg-logo-small.png',
    alt: 'Super Grosz',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/aasa-sm.png',
    alt: 'Aasa',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/axi-small.png',
    alt: 'AXI',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/provident-logo-small.svg',
    alt: 'Provident',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/hapi-pozyczki-small.png',
    alt: 'Hapi pożyzki',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/kredyt-ok-small.png',
    alt: 'Kredyt OK',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/lendon-pl-small.png',
    alt: 'Lendon.pl',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/cardina-logo-small.png',
    alt: 'Cardina',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/miloan-logo.png',
    alt: 'Miloan',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/fellow-finance-small.png',
    alt: 'Fellow Finance',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/profi-credit-logo-small.png',
    alt: 'Profi Credit',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/twino-logo-small.png',
    alt: 'Net Credit',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/pozyczka-plus-small.png',
    alt: 'Pożyczka plus',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/smart-pozyczka-small.png',
    alt: 'Smart Pożyczka',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/pro-fimo-small.png',
    alt: 'PrometeuszFinanse',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/provema-small.png',
    alt: 'Provema',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/euroloan-small.png',
    alt: 'Euroloan',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/extraportfel-sm.png',
    alt: 'Extraportfel.pl',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/zaplo-logo-sm.png',
    alt: 'Zaplo.pl',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/bocian-small.png',
    alt: 'Bocian',
  },
  {
    src: '/static/img/saverium_pl/partner-logos-sm/vivus-small.png',
    alt: 'Vivus',
  },
]
