/**
 * Route translation guidelines
 *
 * 1. const translations object is required. It contains all route paths.
 *    - mandatory rotes are Main, Logout, ThankYouView, OffersView, OffersViewWithUUid and DraftApplication
 *    - all other routes should be defined here on langRoutes array
 * 2. let langRoutes array is site spesific routes.
 *    - site may have language spesific routes, but those must also be under language specific components
 *    - path needs to be defined in translation object
 *    - import required component
 *    - name and translation key needs to match
 *    - if you need a route behind login guard, add meta.requireLogin = true to your route
 *      - eg. langRoute.your_route.meta.requireLogin = true
 */

import MainView from '@/views/lainaayritykselle_fi/MainView'
import ThankYouView from '@/views/lainaayritykselle_fi/ThankYouView'
import LoginView from '@/views/lainaayritykselle_fi/LoginView'
import ResetPasswordView from '@/views/lainaayritykselle_fi/ResetPasswordView'
import SetPasswordView from '@/views/lainaayritykselle_fi/SetPasswordView'
import OffersView from '@/views/lainaayritykselle_fi/OffersView'
import FaqView from '@/views/lainaayritykselle_fi/FaqView'
import CompanyLoanView from '@/views/lainaayritykselle_fi/CompanyLoanView'
import CompanyFundingView from '@/views/lainaayritykselle_fi/CompanyFundingView'
import CompanyLoanWithoutGuaranteesView from '@/views/lainaayritykselle_fi/CompanyLoanWithoutGuaranteesView'
import NewFormView from '@/views/lainaayritykselle_fi/FormView'
import Tos from '@/views/lainaayritykselle_fi/TosView'
import LogoutView from '@/views/lainaayritykselle_fi/LogoutView'

import AuthRedirectView from '@/views/AuthRedirectView'

import routeUtils from '@/router/routeUtils'

// Translations for paths, will be used as link urls
const localizedPaths = {
  MainView: '/',
  Tos: '/kayttoehdot',
  LogoutView: '/logout',
  LoginView: '/kirjaudu',
  AuthRedirectView: '/todenna',
  ResetPassword: '/reset_password',
  SetPassword: '/set_password/:pk/:key/:ts',
  LoanView: '/hae-lainaa',
  OffersView: '/tarjoukset',
  OffersViewWithUUid: '/tarjoukset/:application_uuid',
  DraftApplication: '/luonnos/:application_uuid',
  FaqView: '/usein-kysyttya',
  CompanyLoanView: '/yrityslaina',
  CompanyFundingView: '/yrityksen-rahoitus',
  CompanyLoanWithoutGuaranteesView: '/yrityslaina-ilman-vakuuksia',
  ThankYouView: '/kiitos',
}

/**
 * This object defains what site spesific routes you have and translations for those.
 * All routes must also have corresponding translation
 */
const langRoutes = [
  {
    name: 'LogoutView',
    component: LogoutView,
    meta: {
      title: 'Lainaayritykselle.fi',
    },
  },
  {
    name: 'ThankYouView',
    component: ThankYouView,
    meta: {
      title: 'Lainaayritykselle.fi',
    },
  },
  {
    name: 'LoginView',
    component: LoginView,
    meta: {
      title: 'Lainaayritykselle.fi - Kirjaudu sisään',
    },
  },
  {
    name: 'AuthRedirectView',
    component: AuthRedirectView,
    meta: {
      title: 'Lainaayritykselle.fi',
    },
  },
  {
    name: 'ResetPassword',
    component: ResetPasswordView,
    meta: {
      title: 'Lainaayritykselle.fi',
    },
  },
  {
    name: 'SetPassword',
    component: SetPasswordView,
    meta: {
      title: 'Lainaayritykselle.fi',
    },
  },
  {
    name: 'DraftApplication',
  },
  {
    name: 'OffersViewWithUUid',
    component: OffersView,
    meta: {
      requireLogin: true,
      title: 'Lainaayritykselle.fi',
    },
  },
  {
    name: 'OffersView',
    component: OffersView,
    meta: {
      requireLogin: true,
      title: 'Lainaayritykselle.fi',
    },
  },
  {
    name: 'MainView',
    component: MainView,
    meta: {
      title: 'Lainaa yritykselle - Kilpailuta 2000 € - 8 000 000 € yrityslainat',
    },
  },
  {
    name: 'Tos',
    component: Tos,
    meta: {
      title: 'Lainaa yritykselle - Käyttöehdot',
    },
  },
  {
    name: 'LoanView',
    component: NewFormView,
    meta: {
      title: 'Lainaayritykselle.fi',
    },
  },
  {
    name: 'FaqView',
    component: FaqView,
    meta: {
      title: 'Lainaayritykselle.fi - Usein kysyttyä',
    },
  },
  {
    name: 'CompanyLoanView',
    component: CompanyLoanView,
    meta: {
      title: 'Hae yritysluottoa - Kilpailumme yritysluotot yhdellä hakemuksella',
    },
  },
  {
    name: 'CompanyFundingView',
    component: CompanyFundingView,
    meta: {
      title: 'Vertailemme yritysrahoitukset yhdellä hakemuksella',
    },
  },
  {
    name: 'CompanyLoanWithoutGuaranteesView',
    component: CompanyLoanWithoutGuaranteesView,
    meta: {
      title: 'Kilpailuta ja vertaile vakuudeton yrityslaina yhdellä hakemuksella',
    },
  },
]

const rawRoutes = routeUtils.createRawRoutes(langRoutes, localizedPaths)

export default rawRoutes
