import Vue from 'vue'
import Router from 'vue-router'

import NotFoundView from '@/views/NotFoundView'
import OidcCallbackView from '@/views/OidcCallbackView'
import SmsCampaignView from '@/views/saverium_pl/SmsCampaignView'
import settings from '@/settings/settings'
import { routes } from './routes'
import RedirectView from '@/views/RedirectView'
const RouteObj = routes[settings.LOCALE]

Vue.use(Router)
RouteObj.routes.push({
  path: '/redirect/:uuid/',
  name: 'redirectView',
  component: RedirectView,
})

RouteObj.routes.push({
  path: '/informacja-SMS/:id/',
  name: 'smsCampaignView',
  component: SmsCampaignView,
})

// Common 404 route, has to be added last
RouteObj.routes.push({
  path: '*',
  name: 'NotFound',
  component: NotFoundView,
  meta: {
    title: 'Saverium.pl',
  },
})

RouteObj.routes.push({
  path: '/oidc-callback', // Needs to match redirect_uri in you oidcSettings
  name: 'oidcCallback',
  component: OidcCallbackView,
  meta: {
    isPublic: true,
    isOidcCallback: true,
  },
})

const router = new Router({
  mode: 'history',
  routes: RouteObj.routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Middleware for manipulating different scenaris inside app
router.beforeEach((to, from, next) => {
  // set page title from meta
  document.title = to.meta.title

  document.head.querySelector('meta[name=description]').content = to.meta.description
  document.head.querySelector('meta[property="og:description"]').content =
    to.meta.description
  document.head.querySelector('meta[property="og:image"]').content = to.meta.img
  // Check auth if needed

  Vue.saverium.getSessionKeyValue({ query: to.query })
  if (to.meta.requireLogin) {
    Vue.saverium.oidcUserManager.getUser().then(user => {
      if (user) {
        next()
      } else {
        next({
          path: '/login',
          query: { next: to.fullPath },
          replace: true,
        })
      }
    })
  } else {
    next()
  }
})

export default router
