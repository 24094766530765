<template>
  <div id="fp">
    <info-row />
  </div>
</template>

<script>
import InfoRow from '@/components/saverium_es/InfoRowComponent'

export default {
  components: {
    InfoRow,
  },
}
</script>

<style></style>
