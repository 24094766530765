<template>
  <div class="row article">
    <h1 class="center-title">
      Pożyczka bez umowy o pracę – jakie są warunki jej otrzymania?
    </h1>
    <div class="row col-12">
      <div class="article-container col-12 col-lg-8">
        <div class="text-container">
          <p>
            <i
              >Pilna potrzeba finansowa powoduje, że szukasz oferty, która pozwoli Ci
              zaciągnąć możliwie jak najszybciej pożyczki bez zaświadczeń. Jeśli nie masz
              umowy o pracę, nie oznacza to jednocześnie, że nie otrzymasz
              <b-link :to="$root.rn.OnlinePaydayLoans">chwilówki</b-link> w firmie
              pozabankowej. Na jakich warunkach możesz ją uzyskać?
            </i>
          </p>
          <b-img-lazy
            width="700"
            height="466"
            blank-src="/static/img/saverium_pl/article-images/loan-without-employment-contract-tiny.jpg"
            blank-height="700px"
            blank-width="466px"
            class="article-img"
            src="/static/img/saverium_pl/article-images/loan-without-employment-contract.jpg"
            alt="Pożyczka bez umowy o pracę – jakie są warunki jej otrzymania?"
          />
          <div class="container mb-4">
            <h3>{{ $t('table_of_contents') }}</h3>
            <ul v-for="(text, i) in contents" :key="i" class="table-of-contents">
              <li>
                <a :href="text.link">
                  {{ text.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-container mt-5">
          <h2 id="1">{{ contents[0].title }}</h2>
          <p>
            Brak umowy o pracę nie jest jednoznaczny z tym, że nie masz innych źródeł
            dochodów, pozwalających na zbudowanie wystarczającej
            <b-link :to="$root.rn.CreditWorthiness">zdolności kredytowej</b-link> dla
            przyznania Ci chwilówki online. Na ogół firmy pozabankowe udzielą ci takiego
            finansowania, jeżeli spełniasz takie wymogi jak:
          </p>

          <ul>
            <li>Jesteś osobą pełnoletnią, z pełną zdolnością do czynności prawnych,</li>
            <li>Mieszkasz na terenie Polski,</li>
            <li>Masz polskie obywatelstwo,</li>
            <li>Masz ważny dowód osobisty,</li>
            <li>Masz konto w banku założone na własne dane,</li>
            <li>
              Posiadasz dochody pozwalające na spłatę zobowiązania w wyznaczonym terminie.
            </li>
          </ul>
          <p>
            Na ogół chwilówki online są wypłacane tylko w formie bezgotówkowej, czyli
            bezpośrednio po udzielaniu pożyczki przez internet środki trafiają na konto
            wskazane we wniosku.
          </p>
          <p>
            Część firm pożyczkowych udzielających kredytów bez umowy o pracę, czyli w
            istocie
            <b-link :to="$root.rn.LoanInstallment">pożyczek pozabankowych</b-link>,
            sprawdzi twoje dane w dostępnych bazach danych dłużników – w bazach biur
            informacji gospodarczej, jak BIG InfoMonitor, ERIF, KRD, a niekiedy także w
            Biurze Informacji Kredytowej (BIK). Jeśli będziesz figurował w takich
            rejestrach jako osoba, która ma długi niespłacane w terminie, utrudni Ci to
            zaciągnięcie <b-link :to="$root.rn.MainView">pożyczki online</b-link> i
            pozbawi możliwości kredytowania bankowego.
          </p>
        </div>
        <div class="text-container mt-5">
          <h2 id="2">{{ contents[1].title }}</h2>
          <p>
            Pożyczka na dowód bez umowy o pracę ma na ogół formę zobowiązania finansowego,
            które może być spożytkowane przez Ciebie na dowolne cele. Fakt, że nie jesteś
            pracownikiem etatowym, nie musi cię dyskwalifikować z grona pożyczkobiorców.
            Otrzymać możesz też kredyt na dowód bez umowy o pracę, dokumentując w banku
            dochody np. z renty, emerytury czy z prowadzonej przez Ciebie działalności
            gospodarczej. Pożyczkę na dowód osobisty uzyskasz jednak o wiele szybciej,
            nawet w ciągu kilkunastu minut od chwili wydania pozytywnej decyzji.
          </p>

          <p>
            Może Ci zostać przyznana szybka pożyczka bez umowy o pracę nawet wtedy, gdy
            jesteś osobą bezrobotną, ale pobierasz rentę, emeryturę, zapomogi, 500 plus
            czy innego rodzaju świadczenia, które nie zostaną zaakceptowane przez bank
            przy weryfikacji Twojej zdolności kredytowej.
          </p>
        </div>
        <div class="text-container">
          <h2 id="3">{{ contents[2].title }}</h2>
          <p>
            Podobnie jak przy każdej innej pożyczce, także w przypadku tej bez umowy o
            pracę, by ją otrzymać, musisz wypełnić stosowny wniosek elektroniczny lub
            papierowy. Jak wziąć online pożyczkę bez umowy o pracę? Wybierz firmę
            pożyczkową, która przygotuje dla Ciebie najlepszą ofertę, złóż wniosek,
            podając niezbędne dane, a pożyczka szybko zostanie Ci udzielona, po
            zweryfikowaniu twojej tożsamości i zdolności kredytowej. Nie chcesz wnioskować
            w kilku firmach pożyczkowych? Skorzystaj z platformy Saverium - wypełniając
            tylko jeden wniosek na naszej platformie, uzyskasz wiele ofert szybkich
            pożyczek online. Wnioskowanie o pożyczkę za pośrednictwem Saverium jest
            całkowicie bezpłatne i nie zobowiązuje do zaciągnięcia zobowiązania po
            złożeniu wniosku.
          </p>
        </div>
        <div class="text-container">
          <h2 id="4">{{ contents[3].title }}</h2>
          <p>
            Zastanawiasz się, czy
            <b-link :to="$root.rn.LoansInFifteenMinutes"
              >pożyczka online w 15 minut</b-link
            >
            udostępniana osobom, które nie są pracownikami etatowymi, często tylko na
            podstawie okazanego dowodu osobistego jest bezpieczna? Jak najbardziej, bowiem
            każda renomowana firma pożyczkowa stosuje szyfrowanie danych na swojej
            stronie. Nie musisz martwić się o bezpieczeństwo cyfrowe ani o to, że ktoś
            mógłby podszywać się pod Twoją tożsamość i zaciągać pożyczki pozabankowe bez
            zaświadczeń na Twoje konto.
          </p>
          <p>
            Najczęściej firmy udzielają pożyczek chwilówek na dowód sprawdzą tożsamość
            wnioskodawcy, np. poprzez przelew weryfikacyjny na symboliczną kwotę 1 gr lub
            1 zł. Jeśli dane na przelewie i we wniosku będą zgodne ze sobą, możesz
            otrzymać środki.
          </p>
          <p>
            Jednak, czy dostanę pożyczkę bez umowy o pracę i bez przelewu weryfikacyjnego?
            Jest to możliwe i to w bezpieczny sposób, jeśli zweryfikujesz się za pomocą
            aplikacji, takich jak Kontomatik czy GroszConnect.
          </p>
        </div>
        <div class="text-container">
          <h2 id="5">{{ contents[4].title }}</h2>
          <p>
            Chcesz wiedzieć, kto udzieli Ci pożyczki bez umowy o pracę w szybkim trybie,
            bez zbędnych formalności? Która legalnie działająca firma zechce Cię w ten
            sposób finansować? W Saverium możesz złożyć wniosek o pożyczkę w pożądanej
            kwocie, oznaczając potencjalny okres kredytowania i źródło uzyskiwania
            dochodów, a my szybko, po weryfikacji, przedstawimy Ci kilka
            niezobowiązujących ofert. Możesz, ale nie musisz z nich skorzystać. Pamiętaj,
            że jeden wniosek złożony na stronie Saverium.pl umożliwi Ci zarówno rozeznanie
            się w swojej sytuacji pożyczkowej, jak i w potencjalnych opcjach zaciągnięcia
            zobowiązania.
          </p>
        </div>
        <div class="text-container">
          <h2 id="6">{{ contents[5].title }}</h2>
          <p>
            Możesz uzyskać pożyczkę bez umowy o pracę, jeżeli posiadasz inne formy dochodu
            lub po prostu jesteś zatrudniony/a na umowę zlecenie lub umowę o dzieło.
            Kluczowym czynnikiem jest zdolność kredytowa i czy faktycznie jesteś w stanie
            spłacić swoje zobowiązanie w terminie. Chcesz dostać środki szybko,
            bezpiecznie i w pożądanej kwocie? W Saverium, wypełniając jeden prosty
            wniosek, otrzymasz kilka dedykowanych ofert pożyczkowych. Za pośrednictwem
            naszej platformy możesz wnioskować o pożyczkę od 100 do nawet 200 000 zł, z
            okresem spłaty od miesiąca do nawet 120 miesięcy.
          </p>
        </div>
      </div>
      <!-- <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div> -->
    </div>
    <div style="margin: auto">
      <RelatedArticlesComponent />
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    type: { type: String },
    image: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },

  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
  data() {
    return {
      contents: [
        {
          title: 'Pożyczka bez umowy o pracę – dla kogo?',
          link: '#1',
        },
        {
          title: 'Jak działa szybka pożyczka bez umowy o pracę?',
          link: '#2',
        },
        {
          title: 'Jak wziąć pożyczkę bez umowy o pracę?',
          link: '#3',
        },
        {
          title: 'Czy pożyczka bez umowy o pracę jest bezpieczna?',
          link: '#4',
        },
        {
          title: 'Pożyczka bez umowy o pracę – złóż wniosek w Saverium',
          link: '#5',
        },
        {
          title: 'Pożyczka bez umowy o pracę – podsumowanie',
          link: '#6',
        },
      ],
    }
  },
}
</script>
