import { render, staticRenderFns } from "./CancelDraftView.vue?vue&type=template&id=16c57aaa&scoped=true&"
import script from "./CancelDraftView.vue?vue&type=script&lang=js&"
export * from "./CancelDraftView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16c57aaa",
  null
  
)

export default component.exports