<template lang="html">
  <input
    class="form-control"
    :class="error ? 'is-invalid' : ''"
    :value="value"
    @change="input"
    :placeholder="placeholder"
    inputmode="numeric"
  />
</template>

<script>
export default {
  props: ['value', 'error', 'regex', 'placeholder'],
  methods: {
    input(event) {
      const value = event.target.value.replace(/\s+/g, '')

      if (value !== this.value) {
        this.$emit('input', value, this.validate(value))
      }
    },
    validate(value) {
      return value.match(new RegExp(this.regex))
    },
  },
}
</script>

<style lang="sass" scoped></style>
