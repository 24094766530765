<template>
  <div>
    <div class="article">
      <h1 class="center-title">Lån til alle</h1>
      <div class="top-text text-container">
        <p>
          Hvis du leder efter lån til alle, eller lån, som alle kan søge og optage, så er
          du havnet det rigtige sted. Hos os, sammenligner vi nemlig lån blandt mange
          banker og alternative långivere. På den måde kan vi ofte finde lån til alle,
          uanset kan, alder, uddannelse og økonomisk situation.
        </p>
        <p>
          Når du benytter vores låneberegner og vælger dit øksnede lånebeløb, er du
          allerede godt igang. Herefter indtaster du dine oplysninger, som vi bruger til
          at indhente personlige tilbud på lån fra alle vores lånepartnere. Hvis du står i
          en situation, hvor du tænker, at det ikke er alle, som du kan
          <a
            href="https://saverium.dk/laan-penge"
            rel="noopener noreferrer"
            target="_blank"
            >låne penge</a
          >
          af, så er vores service oplagt, da du ansøger hos mange banker og långivere på
          samme tid.
        </p>
      </div>
      <div class="article-preform-container">
        <PreformContainer id="preform-container" variant="dark" :versionId="versionId" />
      </div>
      <PartnerContainer />
      <HowItWorksWidget />
      <div class="article-faq-wrapper">
        <FaqWidget />
      </div>
      <div class="article-text-wrapper">
        <div class="text-container">
          <div class="container">
            <div class="table-of-contents">
              <h2>{{ $t('table_of_contents') }}</h2>
              <ul>
                <li>
                  <a href="#laan-til-alle-befolkningsgrupper"
                    >Lån til alle befolkningsgrupper</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-container">
          <h2 id="laan-til-alle-befolkningsgrupper">Lån til alle befolkningsgrupper</h2>
          <p>
            Hvis du allerede har mange penge, et fast job med en god løn og en stor
            opsparing, så er det ikke svært at finde en bank, som vil give dig et lån. Den
            situation er det desværre langt fra alle, som er i - og nok også langt fra de
            fleste. Derfor er det heldigt, at der også findes långivere, som låner ud til
            befolkningsgrupper, som ikke har den stærkeste økonomi.
          </p>
          <p>
            Hvis du er uheldig at have mistet dit job, findes der udbydere på markedet,
            som <strong>låner til arbejdsløse</strong>. Hvis du er gået på pension, findes
            der ligeledes firmaer, som <strong>låner til pensionister</strong>. En anden
            gruppe, som ofte har svært ved at <strong>låne er de unge</strong>. Som ung
            kan man ofte få et
            <a
              href="https://saverium.dk/laan-penge/laan-til-alle/studerende"
              rel="noopener noreferrer"
              target="_blank"
              >lån som studerende</a
            >, eller et såkaldt <strong>SU-lån</strong>, men det kræver selvfølgelig at
            man er tilmeldt et studie.
          </p>
          <h3>Ansøg med en ven eller et familiemedlem</h3>
          <p>
            Uanset hvad din situation er, vil du kunne forøge dine chancer for at få et
            lån, hvis du <strong>ansøger om lån med en medansøger</strong>. Hvis I er to
            personer, som ansøger om lånet, vil risikoen for banken være meget lavere, og
            kravene til jer som låntager, fra
            <a
              href="https://www.finanstilsynet.dk/"
              rel="noopener noreferrer"
              target="_blank"
              >finanstilsynet</a
            >, være lettere at opnå. Hvis du har mulighed for at finde en anden person at
            ansøge med, vil vi altid anbefale det. Som udgangspunkt ønsker en bank at låne
            til alle, som med stor sandsynlighed kan tilbagebetale deres lån.
          </p>
          <p>
            Hvis du tænker at en ansøgning med en ven eller familiemedlem er en oplagt
            mulighed for dig, men du først skal undersøge, om den anden person også er
            interesseret, kan vi opyse, at vores tjeneste altid er åben. Det gælder uanset
            om du ansøger i hverdagen, i weekenden eller på en helligdag. Det er dog ikke
            altid at vores kundeservice er bemandet, men hvis der opstår problemer, vender
            vi tilbage til dig så snart vi er tilbage på kontoret.
          </p>
        </div>
      </div>
      <AboutUsWidget />
    </div>
    <CTAScrollerComponent elementId="preform-container" />
  </div>
</template>
<script>
import PreformContainer from '@/components/saverium_dk/main_page_components/PreformContainer.vue'
import { generateStructuredArticleData, createStructuredData } from '@/utils/utils'
import CTAScrollerComponent from '@/components/CTAScrollerComponent.vue'
import PartnerContainer from '@/components/saverium_dk/main_page_components/PartnerContainer'
import HowItWorksWidget from '@/components/saverium_dk/seo_components/HowItWorksWidget'
import FaqWidget from '@/components/saverium_dk/seo_components/FaqWidget'
import AboutUsWidget from '@/components/saverium_dk/seo_components/AboutUsWidget'
export default {
  components: {
    PreformContainer,
    CTAScrollerComponent,
    PartnerContainer,
    HowItWorksWidget,
    FaqWidget,
    AboutUsWidget,
  },

  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    createStructuredData(this.structuredData)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    versionId() {
      return this.$store.state.user.versionId
    },
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss"></style>
