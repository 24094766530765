<template>
  <div>
    <paragraph-header :value="$t('save_money_title')" :hideForm="true" />
    <b-row>
      <b-col lg="8">
        <p>{{ $t('save_money_text_1' )}}</p>
        <p>{{ $t('save_money_text_2' )}}</p>
        <p>{{ $t('save_money_text_3' )}}</p>
      </b-col>
      <b-col lg="4">
        <div class="secondary-title">{{ $t('additional_info') }}</div>
        <p>{{ $t('additional_info_text') }}</p>
        <p>
          <b-link :to="$root.rn.CompanyLoanView" class="link">{{ $t('company_loan') }}</b-link><br />
          <b-link :to="$root.rn.CompanyFundingView" class="link">{{ $t('company_funding') }}</b-link><br />
          <b-link :to="$root.rn.CompanyLoanWithoutGuaranteesView" class="link">{{ $t('company_loan_without_guarantees') }}</b-link><br />
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ParagraphHeader from '../ParagraphHeader'

export default {
  components: {
    ParagraphHeader
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .column {
    max-width: 800px;
  }

  .secondary-title {
    text-transform: uppercase;
    font-size: 17px;
    margin-bottom: 20px;
  }

  .double-column-container {
    display: flex;
  }

  .main-column {
    flex: 2;
  }

  .side-column {
    flex: 1;
  }

  .link {
    color: #2a76bc;
    font-weight: 500;
  }
</style>
