<template lang="html">
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">{{ prepend }}</span>
    </div>
    <input
      class="form-control"
      v-bind:class="_class"
      type="text"
      :value="_value"
      @change="input"
      :placeholder="'eg. 678912345'"
    />
  </div>
</template>

<script>
export default {
  props: ['value', 'placeholder'],
  data() {
    return {
      regex: /^[\d-()]{3,15}$/,
      prepend: '+34',
      invalid: false,
    }
  },
  computed: {
    _value() {
      this.value.replace(/[^0-9]+/gi, '')
      if (this.value.startsWith(this.prepend)) {
        return this.value.slice(this.prepend.length)
      }
      return this.value
    },

    _class() {
      if (this.invalid) {
        return 'is-invalid'
      } else {
        return ''
      }
    },
  },
  methods: {
    input(event) {
      let value = event.target.value
      if (value.startsWith(this.prepend)) {
        value = value.slice(this.prepend.length)
      }
      value = value.replace(/[^0-9]+/gi, '')

      if (this.prepend + value !== this.value) {
        this.$emit('input', this.prepend + value)
      }

      // Spanish phone number format
      const formatRegex = /^[6|7|8|9][0-9]{8}$/
      let newValue = undefined
      if (formatRegex.test(value)) {
        newValue = value
        this.invalid = false
      }
      if (formatRegex.test(value) === false) {
        this.invalid = true
      }
    },
  },
}
</script>

<style lang="sass" scoped></style>
