const salusForm = (
  pubid,
  pub1,
  pub2,
  pub3,
  pub4,
  pub5,
  pubid_reference,
  loanamount,
  loantime,
  email,
  phone
) => {
  return `
        var salusgroup = {
        publisher: {},
        prefill: {},
        styles: { width: '100%', height: 2000 },
        };
        salusgroup.publisher.pubid = '${pubid ? pubid.toString() : ''}';
        salusgroup.publisher.pubidsub1 = '${pub1 ? pub1.toString() : ''}';
        salusgroup.publisher.pubidsub2 = '${pub2 ? pub2.toString() : ''}';
        salusgroup.publisher.pubidsub3 = '${pub3 ? pub3.toString() : ''}';
        salusgroup.publisher.pubidsub4 = '${pub4 ? pub4.toString() : ''}';
        salusgroup.publisher.pubidsub5 = '${pub5 ? pub5.toString() : ''}';
        salusgroup.publisher.pubid_reference = '${
          pubid_reference ? pubid_reference.toString() : ''
        }';
        salusgroup.prefill['loan_amount'] = '${loanamount ? loanamount.toString() : ''}';
        salusgroup.prefill['loan_period'] = '${loantime ? loantime.toString() : ''}';
        salusgroup.prefill['email'] = '${email ? email.toString() : ''}';
        salusgroup.prefill['phone'] = '${phone ? phone.toString() : ''}';

     `
}

export default salusForm
