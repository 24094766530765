<template>
  <div class="card-body text-left">
    <div class="ribbon-wrapper-green">
      <div class="ribbon-green">{{ $t('paid_out') }}</div>
    </div>
    <CardInformation :offer="offer" :action="action" :currency="currency" />
    <div class="mb-3" v-if="offer.description">
      <CardFooter :offer="offer" />
    </div>
  </div>
</template>
<script>
import CardInformation from '@/components/account_view_components/CardInformation.vue'
import CardFooter from './CardFooter.vue'

export default {
  props: ['offer', 'action', 'currency'],
  components: {
    CardInformation,
    CardFooter,
  },
  data() {
    return { toggle: false }
  },
}
</script>
<style lang="scss" scoped>
.ribbon-wrapper-green {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
  top: -1px;
  right: -3px;
}

.ribbon-green {
  color: #333;
  text-align: center;
  font-weight: 500;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  left: -5px;
  top: 15px;
  width: 137px;
  background-color: #085e00;

  color: #ffffff;

  &::before {
    left: 0;
    bottom: -2px;
  }
  &::after {
    right: 0;
  }
}
</style>
