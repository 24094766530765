<template>
  <div class="main-container">
    <div class="main">
      <main-form-view />
    </div>
    <div class="main">
      <b-container class="main-container">
        <paragraph-header :value="$t('company_funding')" />
        <p>{{ $t('company_funding_text_1') }}</p>
        <p>{{ $t('company_funding_text_2') }}</p>
        <div class="secondary-title">{{ $t('company_funding_with_aid') }}</div>
        <p>{{ $t('company_funding_with_aid_text_1') }}</p>
        <p>{{ $t('company_funding_with_aid_text_2') }}</p>
        <p>{{ $t('company_funding_with_aid_text_3') }}</p>
        <p>{{ $t('company_funding_with_aid_text_4') }}</p>
        <div class="secondary-title">{{ $t('company_internal_financing') }}</div>
        <p>{{ $t('company_internal_financing_text_1') }}</p>
        <p>{{ $t('company_internal_financing_text_2') }}</p>
        <p>{{ $t('company_internal_financing_text_3') }}</p>
        <div class="secondary-title">{{ $t('company_funding_with_investments') }}</div>
        <p>{{ $t('company_funding_with_investments_text_1') }}</p>
        <p>{{ $t('company_funding_with_investments_text_2') }}</p>
        <p>{{ $t('company_funding_with_investments_text_3') }}</p>
        <div class="secondary-title">{{ $t('reducing_funding_need') }}</div>
        <p>{{ $t('reducing_funding_need_text_1') }}</p>
        <p>{{ $t('reducing_funding_need_text_2') }}</p>
        <p>{{ $t('reducing_funding_need_text_3') }}</p>
      </b-container>
    </div>
  </div>
</template>

<script>
import ParagraphHeader from '@/components/lainaayritykselle_fi/ParagraphHeader'
import MainFormView from '@/components/lainaayritykselle_fi/newForm/MainFormView'

export default {
  metaInfo: {
    meta: [
      {
        name: 'description',
        content:
          'Yritysrahoituksen kilpailuttaminen - samalla hakemuksella yli 10 rahoitustarjousta.'
      },
      { name: 'keywords', content: 'yritysrahoitus, kilpailuttaminen' }
    ]
  },
  components: {
    ParagraphHeader,
    MainFormView
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  width: 100%;
  position: relative;
}
.secondary-title {
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 10px;
  font-weight: 500;
}
</style>
