<template>
  <div class="row article non-landing">
    <div class="col-xl-8 col-12">
      <h1 class="center-title">
        “At havne i en farlig cirkel af gæld kan ske for enhver” - Læs Anne-Katrins
        historie om at tage kontrol over sin økonomi
      </h1>
      <div class="text-container">
        <p>
          <i>
            Anne-Katrin, 34, delte sin historie med os om at finde vej ud af gældsbyrden
            og den lettelse, som samlelån, bragte overblik over hendes økonomi. I denne
            artikel har vi samlet historien om Anne-Katrin og de bedste tips til at
            overtage dine egne finanser og kombinere lån.
          </i>
        </p>
        <picture>
          <source
            srcset="/static/img/saverium_dk/article-imgs/anne-katrin.webp"
            type="image/webp"
          />
          <source
            srcset="/static/img/saverium_dk/article-imgs/anne-katrin.jpg"
            type="image/jpg"
          />
          <img
            width="700"
            height="466"
            class="article-img"
            src="/static/img/saverium_dk/article-imgs/anne-katrin.jpg"
            alt="Anne-Katrin, 34, delte sin historie med os om at finde vej ud af gældsbyrden
            og den lettelse, som samlelån, bragte overblik over hendes økonomi."
          />
        </picture>

        <h2>På trods af to job gik alle pengene til regninger</h2>
        <p>
          Anne-Katrins historie begyndte på samme måde som tusindvis af andre danskere -
          det var så let at køre et kreditkort igennem, når man handlede i en butik og
          bestilte fra onlinebutikker ved hjælp af en betalingsmetode, der opdelte
          betalingen i månedlige rater.
        </p>
        <p>
          Uden selv at bemærke det begyndte fakturaerne at samle sig i en bunke på bordet.
          Anne-Katrin måtte begynde at arbejde aftener og weekender i en nærliggende café
          som ekstra job ved siden af sit kontorjob, bare for at bemærke, at selv den
          samlede løn fra de to jobs næsten ikke dækkede alle de indgående regninger. ”At
          arbejde i mange timer var fysisk udfordrende, mens den ukrontrollernede
          gældsmængde hjemsøgte mig mentalt hele tiden,” beskriver Anne-Katrin.
        </p>
        <p>
          Hun hadede altid lønningsdagen, for hun vidste, at de penge, der optjenes ved
          hårdt arbejde, forsvandt straks. Især i den digitale verden, hvor alle indgående
          fakturaer ikke er i en fysisk form, hvor du rent faktisk kunne se bunken med
          regninger foran dine øjne, er det svært at holde øje med, hvor mange fakturaer
          du får hver måned, og hvor mange åbne kreditter, du har forskellige steder. Som
          det første forsøgte Anne-Katrin at reducere den minimale månedlige betaling af
          afdrag og kreditkortregninger, men det samlede beløb for alle de månedlige
          gældsbetalinger var stadig mere end 5000 kr. hver måned.
        </p>
      </div>
      <div class="text-container">
        <h2>
          "Jeg var bare nødt til at tage beslutningen om at afslutte dette og tage fat på
          situationen ”- Sådan fik Anne-Katrin kontrollen over sin økonomi tilbage
        </h2>
        <p>
          Anne-Katrin samlede alle regninger sammen bare for at se, hvor meget hun betalte
          i renter og andre kreditgebyrer hver måned. Hun besluttede at prøve, om hun
          ville være i stand til at
          <b-link :to="$root.rn.ConsolidationLoan"
            >konsolidere al sin åben gæld forskellige steder til et billigere
            samlelån</b-link
          >. "Jeg beregnede hurtigt, at afdrag og kreditkort nu koster omkring 5000 kr.
          hver måned, og samlelån koster cirka 1400 kr. pr. måned. Det er en enorm mængde
          penge at spare hver måned på renter og udgifter."
        </p>
        <p>
          At kombinere lån hos Saverium.dk er praktisk, sker fuldt ud online og er helt
          gratis. Du kan gennemgå tilbuddene og beslutte, om du vil acceptere et af de
          modtagne tilbud. Ved hjælp af samlelån arrangerede Anne-Katrin alle rater og
          kreditkortgæld til et billigere lån. Hun udelukkede kun billånet, da det
          allerede havde en lav rente. I et samlelån betaler den nye långiver normalt al
          den gamle gæld, så Anne-Katrin behøvede ikke at bekymre sig om at udligne alle
          betalinger på et praktisk niveau. Af hensyn til sikkerheden sluttede Anne-Katrin
          også sine kreditkortkontrakter, så fristelsen til at bruge dem ikke blev for
          stor.
        </p>
      </div>
      <div class="text-container">
        <h2>Her er hvordan Anne-Katrin råder andre til at komme ud af gældsspiralen:</h2>
        <p>
          Saml alle de regninger, gæld og udgifter, du skal betale hver måned. Dette
          inkluderer f.eks. husleje, kreditkort og afdrag samt tilbagebetaling af anden
          gæld. Beregn derefter, hvor meget din indkomst er hver måned (efter skat), og
          træk fra dette beløb de fakturaer og andre udgifter, som du lige har beregnet i
          første trin. Hvor meget bliver du på plussiden? Med dette beløb skal du leve i
          en måned, betale for mad, hobbyer og andre kommende udgifter.
        </p>
        <p>
          Hvis du betaler store beløb på forskellige lån månedligt, kan du overveje at
          kombinere lånene. Opsummerer al udestående gæld på afdrag og
          kreditkortregninger, gå til Saverium.dk hjemmesiden og indtast det lånebeløb,
          der svarer til din gæld. Ved at ændre låneperioden kan du justere, hvilken
          månedlig rate, der passer til din personlige økonomi.
        </p>
        <p>
          Via Saverium.dk modtager du flere lånetilbud til et samlelån. Tilbuddene binder
          dig ikke til noget, og som Anne-Katrin sagde det, ”Du kan med sikkerhed tænke,
          kombination af lån er den rigtige løsning for dig - for mig var det en ganske
          lettelse.”
        </p>
      </div>
      <div class="text-container">
        <h2>Lavere rente for en samlelån - Husk altid at sammenligne lånetilbuddene</h2>
        <p>
          Fordi ét samlelån kombinerer flere mindre rater og lån, er dets rentesats og
          samlede omkostninger typisk også lavere. Dette betyder også lavere månedlig
          betaling, som hjælper med at reducere de samlede omkostninger ved lånet, dette
          letter den månedlige budgettering og frigør penge til at nyde hverdagen, når
          hele lønnen ikke længere går til afbetaling af gæld.
        </p>
        <p>
          Det er værd at huske, at afdrag og lån normalt medfører andre omkostninger end
          blot den månedlige rate og renter. Flere gæld medfører også et antal forskellige
          faktureringsgebyrer og andre udgifter, der kan blive rigtig dyre over måneder og
          år.
        </p>
        <p>
          “Kombination af lån sparer dig penge, fordi kortfristede lån, som afdrag og
          kreditkort, kan have rigtig høje renter! Det kombinerede lån kan forlænge
          betalingsperioden en smule, men det vil også altid være billigere på grund af de
          lavere omkostninger,” forklarer Anne-Katrin.
        </p>
        <p>
          Samlelån ydes af mange forskellige banker på det danske lånemarked. Selv om det
          kan være let at finde og få et lån, er det værd at huske, at lånets renter og
          låneomkostninger også varierer meget mellem banker og långivere. Der kan let
          være 10 procent forskel i renten på et samlelån mellem forskellige banker.
          Derfor giver det altid mening at foretage en sammenligning af lån, når du
          overvejer at tage et lån.
        </p>
        <p>
          Da sammenligningen tager tid, når den udføres manuelt, er det værd at tage hjælp
          fra Saverium.dk, der sammenligner tilbud fra flere banker og långivere for dig
          med kun en applikation.
          <b-link :to="$root.rn.LoanView"
            >Samle din gæld og få styr på din økonomi ved at udfylde en
            konsolideringsansøgning på Saverium.dk og vælg ”samlelån” som formålet med
            lånet</b-link
          >.
        </p>
        <p>2020-09-14 / Søren</p>
      </div>
    </div>
    <div class="col-xl-4 col-12">
      <div class="container container-widget col-12 col-lg-4">
        <h4>{{ $t('get_a_loan_easily') }}</h4>
        <LoanWidget class="small-range mt-4" />
      </div>
    </div>
  </div>
</template>
<script>
import LoanWidget from '@/components/saverium_dk/seo_components/LoanWidget.vue'
import { generateStructuredArticleData } from '@/utils/utils'
export default {
  data() {
    return {}
  },
  components: {
    LoanWidget,
  },
  props: {
    headline: { type: String },
    publisher: { type: Object },
    datePublished: { type: String },
    dateModified: { type: String },
    type: { type: String },
    image: { type: String },
    author: { type: String },
  },
  created() {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = JSON.stringify(this.structuredData)
    script.id = 'structuredData'
    document.head.appendChild(script)
  },
  beforeDestroy() {
    const script = document.getElementById('structuredData')
    document.head.removeChild(script)
  },
  computed: {
    structuredData() {
      return generateStructuredArticleData(
        this.headline,
        this.publisher,
        this.datePublished,
        this.type,
        this.image
      )
    },
  },
}
</script>
<style lang="scss" scoped></style>
