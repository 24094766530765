<template>
  <div class="article-wrapper" data-type="1" data-language="pl">
    <div class="article row col-12">
      <h1 class="center-title">
        Kto może otrzymać kredyt - jakie trzeba spełniać warunki żeby dostać kredyt?
      </h1>
      <div class="row col-12">
        <div class="article-container col-12 col-lg-8">
          <div class="text-container">
            <p>
              Jakie trzeba spełnić warunki, aby dostać kredyt? Czy mam
              <a
                href="https://www.saverium.pl/artykuly/zdolnosc-kredytowa-czym-jest-jak-sprawdzic-i-poprawic-zdolnosc-kredytowa/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>zdolność kredytową</strong></a
              >? Kto nie może dostać kredytu? Te oraz podobne pytania zadają sobie osoby,
              które chciałyby wnioskować o
              <a
                href="https://www.saverium.pl/artykuly/kredyt-gotowkowy-kalkulator-i-oferty-pozyczek-gotowkowych/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>kredyt bankowy</strong></a
              >. Choć każda instytucja określa inne warunki kredytowania, a także
              wymagania wobec potencjalnych kredytobiorców, istnieją pewne ogólne zasady
              udzielania kredytów. Warto je znać, jeśli chcemy ubiegać się o finansowanie.
            </p>
            <b-img-lazy
              width="700"
              height="466"
              blank-src="/static/img/saverium_pl/article-images/who-can-get-a-loan-tiny.webp"
              blank-height="700px"
              blank-width="466px"
              class="article-img"
              src="/static/img/saverium_pl/article-images/who-can-get-a-loan.webp"
              alt="Kredyt gotówkowy na dowolny cel"
            />
            <div class="toc container">
              <h3>{{ $t('table_of_contents') }}</h3>
              <ul class="table-of-contents">
                <li>
                  <a href="#zdolnosc-kredytowa-jak-sie-ja-ocenia"
                    >Zdolność kredytowa - jak się ją ocenia?</a
                  >
                </li>
                <li>
                  <a href="#kwota-kredytu-a-zdolnosc-kredytowa"
                    >Kwota kredytu, a zdolność kredytowa</a
                  >
                </li>
                <li>
                  <a href="#jakie-warunki-trzeba-spelnic-aby-uzyskac-kredyt"
                    >Jakie warunki trzeba spełnić, aby uzyskać kredyt?</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="text-container">
            <h2 id="zdolnosc-kredytowa-jak-sie-ja-ocenia">
              Zdolność kredytowa - jak się ją ocenia?
            </h2>
            <p>
              Zadając sobie pytanie, jakie wymogi do otrzymania kredytu gotówkowego trzeba
              spełnić, warto skupić się przede wszystkim na zdolności kredytowej
              potencjalnego klienta. Banki zawsze oceniają, czy osoba wnioskująca o
              kredyt, będzie w stanie spłacić kapitał wraz z odsetkami w ustalonym
              terminie. Kredyt gotówkowy może otrzymać osoba, która:
            </p>
            <ul>
              <li>posiada stałe źródło dochodu,</li>
              <li>
                jej dochód jest wystarczający na zaspokojenie bieżących potrzeb, a także
                na spłatę kredytu,
              </li>
              <li>nie posiada innych, niespłaconych w terminie długów.</li>
            </ul>
            <p>
              Banki najczęściej przyznają
              <a
                href="https://www.saverium.pl/artykuly/kredyt-konsumencki-vs-kredyt-konsumpcyjny-jakie-sa-roznice/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>kredyt konsumpcyjny</strong></a
              >
              osobom, które zatrudnione są w ramach umowy o pracę na czas nieokreślony.
              Czasami możliwe jest też przyznanie kredytu tym, którzy zatrudnieni są na
              umowę na czas określony. Zwykle jednak kwoty kredytu w takim przypadku będą
              stosunkowo niskie, bowiem umowa na czas określony nie stanowi dla banku tak
              dobrego zabezpieczenia, jak umowa na czas nieokreślony, która z natury ma
              charakter długoterminowy. Potencjalni kredytobiorcy często zadają sobie
              pytanie, jak sprawdzić, czy mam zdolność kredytową? Otóż poszczególne banki
              informują klientów, jakie warunki muszą spełnić, aby otrzymać kredyt. Warto
              uważnie wczytać się w wytyczne, aby wiedzieć, czy w konkretnym przypadku
              mamy szansę na pozyskanie pieniędzy. W internecie dostępne są kalkulatory
              zdolności kredytowej, którymi można posiłkować się, rozważając zaciągnięcie
              kredytu gotówkowego. Należy jednak mieć na względzie, że wynik otrzymany za
              pomocą kalkulatora jest jedynie wskazówką, nie stanowi natomiast konkretnej
              wytycznej dla banków.
            </p>
          </div>

          <div class="text-container">
            <h2 id="kwota-kredytu-a-zdolnosc-kredytowa">
              Kwota kredytu, a zdolność kredytowa
            </h2>
            <p>
              Zasadą jest, że im większą zdolność kredytową ma klient, tym większy kapitał
              może uzyskać w ramach finansowania.
              <a
                href="https://www.saverium.pl/artykuly/kredyt-10-tys-online-jakie-warunki-jak-dostac-kredyt-10-000-zl-przez-internet/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>Kredyt 10 tys. zł</strong></a
              >
              może być przyznany osobie, która posiada inne zobowiązania bądź jej dochody
              kształtują się na niskim poziomie.
              <a
                href="https://www.saverium.pl/artykuly/kredyt-20-tys-zlotych-pozyczki-pozabankowe-do-20-000-zl-przez-internet/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>Kredyt 20 tys</strong></a
              >. uzyska ten, kto posiada lepsze zabezpieczenie (na przykład umowę o pracę
              na czas nieokreślony), natomiast aby uzyskać
              <a
                href="https://www.saverium.pl/artykuly/kredyt-50-tys-online-jakie-warunki-rata/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>kredyt 50 tys zł</strong></a
              >
              należy posiadać wysoką zdolność kredytową. Dlatego też banki podają widełki
              kwot kredytu, jakie może uzyskać klient. Ma on możliwość zawnioskowania o
              dowolną kwotę, jednak bank nie musi przystać na konkretną propozycję,
              udzielając kredytu na niższą sumę.
            </p>
          </div>

          <div class="text-container">
            <h2 id="jakie-warunki-trzeba-spelnic-aby-uzyskac-kredyt">
              Jakie warunki trzeba spełnić, aby uzyskać kredyt?
            </h2>
            <p>
              Co zrobić, aby otrzymać kredyt? Przede wszystkim należy złożyć wniosek w
              banku, który przedstawia najkorzystniejszą ofertę. Można to zrobić
              bezpośrednio w oddziale, przez internet bądź za pomocą portalu
              pośredniczącego w pozyskiwaniu kredytów gotówkowych. We wniosku należy podać
              dane osobowe oraz wyrazić zgodę na przetwarzanie danych osobowych. Klient
              musi też dostarczyć niezbędną dokumentację, za pomocą której zostanie
              oceniona jego zdolność kredytowa. Co istotne, kredytu nie mogą uzyskać osoby
              zadłużone, które nie spłacają terminowo swoich zobowiązań.
            </p>
            <p>
              Banki nie udzielają też finansowania tym, którzy nie posiadają stałego
              źródła dochodu bądź ich dochód kształtuje się na bardzo niskim poziomie.
              Kredyt gotówkowy udzielany jest obywatelom Polski, posiadającym ważny
              dokument tożsamości - dowód osobisty. Czasami kredyt bankowy bywa mylony
              z<strong> </strong
              ><a
                href="https://www.saverium.pl/artykuly/pozyczki-pozabankowe-online-kredyty-pozabankowe-prezez-internet/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>pożyczką pozabankową</strong></a
              >, która przyznawana jest na zupełnie innych zasadach. Warto pamiętać, że
              <a
                href="https://www.saverium.pl/artykuly/kredyt-a-pozyczka-sprawdz-jakie-sa-roznice/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>kredyt a pożyczka</strong></a
              >
              to dwa różne instrumenty finansowe - o ile
              <a
                href="https://www.saverium.pl/artykuly/pozyczki-na-dowod-przez-internet-chwilowki-na-dowod/"
                rel="noopener noreferrer"
                target="_blank"
                ><strong>pożyczki na dowód</strong></a
              >
              może udzielić niemal każdy, o tyle kredyty zarezerwowane są wyłącznie dla
              banków. Dlatego też zawsze wymaga się, aby klient posiadał zdolność
              kredytową, nie był zadłużony oraz spełniał inne warunki formalne, jakie
              poszczególne banki stawiają potencjalnym kredytobiorcom.
            </p>
          </div>
        </div>
        <div class="container container-widget col-12 col-lg-4">
          <h4>{{ $t('get_a_loan_easily') }}</h4>
          <LoanWidget class="small-range mt-4" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div style="margin: auto">
        <RelatedArticlesComponent />
      </div>
    </div>
  </div>
</template>
<script>
import RelatedArticlesComponent from '@/components/saverium_pl/RelatedArticlesComponent'
import LoanWidget from '@/components/LoanWidget.vue'
export default {
  components: {
    RelatedArticlesComponent,
    LoanWidget,
  },
}
</script>
